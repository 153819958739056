/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { catchError, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { UserGroupsDataService } from './groups-data.service';
import { environment } from '../../../../../environments/environment';
import { RestClientService } from '../../../../shared/services/rest-client.service';
import { ObservableResult } from '../../../../shared/store';
import {
  CreateUserGroups,
  UserGroupMember,
  UserGroups,
  UserGroupUploadResult,
} from '../../../../shared/models/admin/group/user-groups.model';
import { Page } from '../../../../shared/models/page';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ApiUserGroupsDataService implements UserGroupsDataService {
  constructor(
    private restClient: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  createUserGroup(request: CreateUserGroups): ObservableResult<UserGroups> {
    return this.restClient.post<UserGroups>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/groups'), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((error) => this.getObservableResultErrorJSONString(error.status, this.getCreateGroupErrorObject)),
    );
  }

  updateUserGroup(userGroupUid: string, request: CreateUserGroups): ObservableResult<UserGroups> {
    return this.restClient
      .patch<UserGroups>(Location.joinWithSlash(environment.apiRootUrl || '', `users/groups/${userGroupUid}`), request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((error) => this.getObservableResultErrorJSONString(error.status, this.getUpdateGroupErrorObject)),
      );
  }

  loadUserGroup(userGroupUid: string, playlistUid?: string): ObservableResult<UserGroups> {
    let url = `users/groups/${userGroupUid}`;
    if (playlistUid) {
      url = `playlists/${playlistUid}/` + url;
    }
    return this.restClient.get<UserGroups>(Location.joinWithSlash(environment.apiRootUrl || '', url)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetUserGroups'))),
    );
  }

  searchUserGroups(page: number, size: number, term?: string, playlistUid?: string): ObservableResult<Page<UserGroups>> {
    const params = {
      page: page.toString(),
      size: size ? size.toString() : '10',
    };
    if (term) {
      params['term'] = term;
    }
    let url = 'users/groups/search';
    if (playlistUid) {
      url = `playlists/${playlistUid}/` + url;
    }
    return this.restClient.get<Page<UserGroups>>(Location.joinWithSlash(environment.apiRootUrl || '', url), params).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetUserGroups'))),
    );
  }

  bulkUploadUsers(csvFile: File, groupId: string): ObservableResult<UserGroupUploadResult> {
    const formData = new FormData();
    formData.append('file', csvFile);
    return this.restClient
      .post<UserGroupUploadResult>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/groups/upload'), formData, {
        groupUid: groupId,
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUploadGroupUsers'))),
      );
  }

  loadSystemGroups(): ObservableResult<string[]> {
    return this.restClient.get<string[]>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/groups/types')).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetUserGroups'))),
    );
  }

  loadReviewerGroupTypes(playlistUid: string): ObservableResult<string[]> {
    return this.restClient
      .get<string[]>(Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/users/groups/reviewer-types`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetUserGroups'))),
      );
  }

  loadUserGroupsMembers(userGroupUid: string, page: number, size: number, term?: string): ObservableResult<Page<UserGroupMember>> {
    const params = {
      page: page.toString(),
      size: size ? size.toString() : '10',
    };
    if (term) {
      params['term'] = term;
    }
    return this.restClient
      .get<Page<UserGroupMember>>(Location.joinWithSlash(environment.apiRootUrl || '', `users/search/groups/${userGroupUid}`), params)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetUserGroups'))),
      );
  }

  getUserGroupsByType(type: string, playlistUid?: string): ObservableResult<UserGroups[]> {
    let url = `users/groups?type=${type}`;
    if (playlistUid) {
      url = `playlists/${playlistUid}/` + url;
    }
    return this.restClient.get<UserGroups[]>(Location.joinWithSlash(environment.apiRootUrl || '', url)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetUserGroups'))),
    );
  }

  getAllUserGroups(playlistUid?: string): ObservableResult<UserGroups[]> {
    let url = 'users/groups';
    if (playlistUid) {
      url = `playlists/${playlistUid}/` + url;
    }
    return this.restClient.get<UserGroups[]>(Location.joinWithSlash(environment.apiRootUrl || '', url)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetUserGroups'))),
    );
  }

  assignMultipleGroupsToMultipleUsers(groupUids: string[], userUids: string[]): ObservableResult<void> {
    return this.restClient
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/groups/assign'), {
        users: userUids,
        groups: groupUids,
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAddMemberToGroup'))),
      );
  }

  removeMultipleGroupsFromMultipleUsers(groupUids: string[], userUids: string[]): ObservableResult<void> {
    return this.restClient
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/groups/unassign'), {
        users: userUids,
        groups: groupUids,
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRemoveMemberFromGroup'))),
      );
  }

  private getCreateGroupErrorObject(errorStatus: number): string {
    if (errorStatus === 409) {
      return this.translocoService.translate('translations.errors.errorGroupExists');
    }
    return this.translocoService.translate('translations.errors.errorCreateGroup');
  }

  private getUpdateGroupErrorObject(errorStatus: number): string {
    if (errorStatus === 409) {
      return this.translocoService.translate('translations.errors.errorGroupExists');
    }
    return this.translocoService.translate('translations.errors.errorUpdateGroup');
  }

  private getObservableResultErrorJSONString(errorStatus: number, errorGetterFunction: (number) => string) {
    return ObservableResult.ofError(JSON.stringify(errorGetterFunction(errorStatus)));
  }
}
