import { InjectionToken } from '@angular/core';
import { ObservableResult } from '../../store';
import { HierarchicalList } from '../../models';

export const OPTION_LIST_DATA_SERVICE = new InjectionToken<OptionListDataService>('[list] optionListDataService');

/** The service handling group list CRUD operations. */
export interface OptionListDataService {
  createOptionList(title: string): ObservableResult<HierarchicalList>;

  addOptionListElement(listUid: string, parentUid: string, optionName: string): ObservableResult<HierarchicalList>;

  updateOptionListElement(listUid: string, parentUid: string, optionName: string): ObservableResult<HierarchicalList>;

  removeOptionListElement(listUid: string, parentUid: string): ObservableResult<void>;
}
