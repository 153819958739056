<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<img
  #image
  *ngIf="uploadedImage"
  class="image"
  [src]="url"
  width="960"
  height="360"
  [attr.aria-label]="
    link
      ? !!uploadedImage
        ? (caption ?? ('translations.global.ariaLabels.previewImage' | transloco))
        : ('translations.global.ariaLabels.uploadImage' | transloco)
      : null
  "
  [class.link]="link"
  [alt]="(caption ? caption : '') + (link ? ' Press enter to open image url ' + link : '')"
  (click)="link ? onImageClick() : null"
  (keydown.enter)="link ? onImageClick() : null"
  (error)="error()"
/>
