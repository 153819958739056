<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="add-framework-container">
  <form [formGroup]="frameworkFormGroup">
    <div class="editor-item" *ngIf="frameworkType === 'STANDARD'">
      <h2 class="editor-item-title small bold">
        {{ 'translations.framework.label.frameworkName' | transloco }}
      </h2>
      <input class="editor-item-input" matInput type="text" required formControlName="title" />
      <mat-error
        *ngIf="
          frameworkFormGroup.controls['title'].invalid &&
          frameworkFormGroup.controls['title'].touched &&
          frameworkFormGroup.controls['title'].hasError('required')
        "
      >
        {{ 'translations.organization.input.error.enterValidName' | transloco }}
      </mat-error>
    </div>

    <div class="editor-item" *ngIf="frameworkType === 'STANDARD'">
      <h2 class="editor-item-title small bold">
        {{ 'translations.framework.label.frameworkDescriptionOptional' | transloco }}
      </h2>
      <textarea class="editor-item-input" matInput type="text" formControlName="description"></textarea>
    </div>
  </form>

  <div class="editor-item">
    <h2 class="editor-item-title small bold">
      {{
        frameworkType === 'STANDARD'
          ? ('translations.framework.label.frameworkAddTags' | transloco)
          : ('translations.framework.label.addCategories' | transloco)
      }}
    </h2>
    <p class="editor-item-description extra-small">
      {{ 'translations.framework.label.frameworkAddTagsInfo' | transloco }}
    </p>

    <div class="input-container">
      <input
        matInput
        class="editor-item-input"
        [matAutocomplete]="auto"
        [placeholder]="
          frameworkType === 'STANDARD'
            ? ('translations.framework.placeholder.tagName' | transloco)
            : ('translations.framework.placeholder.categoryName' | transloco)
        "
        [(ngModel)]="searchString"
        [ngModelOptions]="{ standalone: true }"
        (paste)="onTextPaste($event)"
        (keyup.enter)="onSearchInputEnter($event)"
        (keyup)="onSearchInputChange($event)"
      />

      <mat-autocomplete
        [panelWidth]="450"
        #auto="matAutocomplete"
        [displayWith]="searchAutocompleteFormat"
        (optionSelected)="onSearchResultSelected($event)"
      >
        <mat-option *ngFor="let tag of filteredMainTags" [value]="tag">
          {{ tag.title }}
        </mat-option>
      </mat-autocomplete>
      <mat-spinner class="search-spinner" *ngIf="loadingSearch" [diameter]="20"> </mat-spinner>
    </div>
  </div>

  <div class="framework-tags-container" *ngIf="tags.length">
    <div class="framework-selected-data-subtitle small bold">
      {{
        frameworkType === 'STANDARD'
          ? ('translations.framework.title.tags' | transloco)
          : ('translations.framework.title.categories' | transloco)
      }}
    </div>
    <ptl-frameworks-tags-list-tree
      [frameworkType]="frameworkType"
      (tagDeleted)="onTagDeleted($event)"
      (childTagAdded)="onChildTagAdded($event)"
      [childrenTags]="tags"
      [allTags]="tags"
    >
    </ptl-frameworks-tags-list-tree>
  </div>
</div>
