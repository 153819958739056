<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="page-analytics__container" *ngIf="!showSkeletonView">
  <div class="page-analytics__summary">
    <button
      mat-stroked-button
      class="help-button"
      (click)="openHelpDialog()"
      [attr.aria-label]="'translations.global.ariaLabels.openHelpCenter' | transloco"
    >
      <div class="help-button__content">
        <mat-icon>help_outline</mat-icon>
        <p>{{ 'translations.global.label.helpLabel' | transloco }}</p>
      </div>
    </button>
    <ptl-control-panel-filter-chips
      [filters]="filters"
      [currentFilter]="currentFilter"
      [newFilter]="newFilter"
      [playlistUids]="playlistUids"
      [supportedFilters]="['GROUP', 'ROLE', 'ACTIVITY']"
      (filterSelected)="filterSelected($event)"
      (filterDeleted)="onRemoveFilter($event)"
      (filterSaved)="onSaveFilter($event)"
    >
    </ptl-control-panel-filter-chips>

    <div class="control-panel-buttons">
      <ptl-filter-btn class="filter-button" [labels]="courseFilterLabels" (filterSelected)="filterSelected($event)"> </ptl-filter-btn>
      <!-- TODO: Export button will be handled in next iteration
      <ptl-export-btn class="filter-button">
      </ptl-export-btn>
      -->
    </div>
  </div>

  <div class="metrics-row-3-3-1">
    <div class="metric-card metric-card--main" matTooltip="{{ 'translations.analytics.help.startedUserCoursesDescription' | transloco }}">
      <div class="metric-icon">
        <mat-icon>start</mat-icon>
      </div>
      <div class="metric-value">{{ startedCourseCount }}</div>
      <div class="metric-name">
        {{ 'translations.analytics.metric.startedUserCourses' | transloco }}
      </div>
    </div>

    <div class="metric-card metric-card--main" matTooltip="{{ 'translations.analytics.help.completedUserCoursesDescription' | transloco }}">
      <div class="metric-icon">
        <mat-icon>task_alt</mat-icon>
      </div>
      <div class="metric-value">{{ completedCourseCount }}</div>
      <div class="metric-name">
        {{ 'translations.analytics.metric.completedUserCourses' | transloco }}
      </div>
    </div>

    <div class="metric-card link-card" (click)="openDetailsDialog()" (keydown.enter)="openDetailsDialog()">
      <div class="metric-link-icon">
        <mat-icon>table_chart</mat-icon>
      </div>
      <p class="link-button">{{ 'translations.analytics.button.openTableView' | transloco }}</p>
    </div>
  </div>

  <div class="metrics-row-1-4">
    <div class="metrics-column">
      <div class="metric-card metric-card--small" matTooltip="{{ 'translations.analytics.help.membersCountDescription' | transloco }}">
        <div class="metric-icon">
          <mat-icon>group</mat-icon>
        </div>
        <div class="metric-value">{{ this.membersCount }}</div>
        <div class="metric-name">
          {{
            this.membersCount
              | i18nPlural
                : {
                    '=1': 'translations.analytics.metric.member' | transloco,
                    other: 'translations.analytics.metric.totalMembers' | transloco,
                  }
          }}
        </div>
      </div>
      <div class="metric-card metric-card--small" matTooltip="{{ 'translations.analytics.help.cardsMetricDescription' | transloco }}">
        <div class="metric-icon">
          <mat-icon>note</mat-icon>
        </div>
        <div class="metric-value">{{ this.totalCardsCount }}</div>
        <div class="metric-name">
          {{
            this.totalCardsCount
              | i18nPlural
                : {
                    '=1': 'translations.analytics.metric.card' | transloco,
                    other: 'translations.analytics.metric.cards' | transloco,
                  }
          }}
        </div>
      </div>
    </div>
    <ptl-course-engagements-chart
      class="metric-card--large"
      [individualPlaylistMetrics]="individualPlaylistMetrics"
      [fixedHeight]="true"
      [showPlaceholder]="true"
    >
    </ptl-course-engagements-chart>
  </div>

  <div class="spacer"></div>

  <ng-container *ngIf="metricsBundle.cardEventsInfo.length > 0">
    <div class="page-analytics__summary">
      <p class="page-analytics__summary-title">
        {{ 'translations.events' | transloco }}
      </p>
      <ptl-event-search
        [cardEventsInfo]="metricsBundle.cardEventsInfo"
        [selectedEventId]="selectedEventId"
        (eventSelected)="onEventSelected($event)"
      >
      </ptl-event-search>

      <div class="control-panel-buttons">
        <ptl-control-panel-filter-chips
          [filters]="filters"
          [currentFilter]="currentFilter"
          [newFilter]="newFilter"
          [playlistUids]="playlistUids"
          [supportedFilters]="['ATTENDANCE']"
          (filterSelected)="filterSelected($event)"
          (filterDeleted)="onRemoveFilter($event)"
          (filterSaved)="onSaveFilter($event)"
        >
        </ptl-control-panel-filter-chips>
        <ptl-filter-btn class="filter-button" [labels]="eventFilterLabels" (filterSelected)="filterSelected($event)"> </ptl-filter-btn>
      </div>
    </div>

    <div class="metrics-column">
      <div class="metrics-row-1-1-3">
        <div class="metrics-column metric-card--left">
          <div class="metric-card top-left-radius" matTooltip="{{ 'translations.analytics.help.eventsMetricDescription' | transloco }}">
            <div class="metric-icon">
              <mat-icon>event</mat-icon>
            </div>
            <div class="metric-value">{{ eventCardCount }}</div>
            <div class="metric-name">
              {{
                eventCardCount
                  | i18nPlural
                    : {
                        '=1': 'translations.event' | transloco,
                        other: 'translations.events' | transloco,
                      }
              }}
            </div>
          </div>

          <div
            class="metric-card bottom-left-radius"
            matTooltip="{{ 'translations.analytics.help.registrationsMetricDescription' | transloco }}"
          >
            <div class="metric-icon">
              <mat-icon>person_add</mat-icon>
            </div>
            <div class="metric-value">{{ registeredUsers }}</div>
            <div class="metric-name">
              {{
                registeredUsers
                  | i18nPlural
                    : {
                        '=1': 'translations.analytics.metric.registration' | transloco,
                        other: 'translations.analytics.metric.registrations' | transloco,
                      }
              }}
            </div>
          </div>
        </div>

        <div class="metrics-column metric-card--left">
          <div
            class="metric-card top-right-radius"
            matTooltip="{{ 'translations.analytics.help.participatedMetricDescription' | transloco }}"
          >
            <div class="metric-icon">
              <mat-icon>event_available</mat-icon>
            </div>
            <div class="metric-value">{{ participatedUsers }}</div>
            <div class="metric-name">{{ 'translations.analytics.metric.participated' | transloco }}</div>
          </div>

          <div
            class="metric-card bottom-right-radius"
            matTooltip="{{ 'translations.analytics.help.waitlistedMetricDescription' | transloco }}"
          >
            <div class="metric-icon">
              <mat-icon>hourglass_empty</mat-icon>
            </div>
            <div class="metric-value">{{ waitlistedUsers }}</div>
            <div class="metric-name">{{ 'translations.analytics.metric.waitlisted' | transloco }}</div>
          </div>
        </div>

        <ptl-daily-enrollment-chart
          [dailyEnrollmentCounts]="registrationDatesToCounts"
          [eventIdsToRegistrationCounts]="eventIdsToRegistrationCounts"
          [cardEventsInfo]="metricsBundle.cardEventsInfo"
          [fixedHeight]="true"
          [showPlaceholder]="true"
          [chartType]="EventsChartType.EVENT_REGISTRATIONS"
        >
        </ptl-daily-enrollment-chart>
      </div>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="showSkeletonView">
  <ng-container *ngIf="!hasRelevantPlaylists">
    <div class="page-analytics__no-relevant-playlists">
      <p>{{ 'translations.analytics.info.emptyState' | transloco }}</p>
      <img
        type="image/svg+xml"
        src="assets/placeholder/analytics-chart.svg"
        [attr.aria-label]="'translations.global.ariaLabels.barChart' | transloco"
        alt=""
      />
    </div>
  </ng-container>

  <ng-container *ngIf="hasRelevantPlaylists">
    <div class="page-analytics__loading-warning">
      {{ 'translations.analytics.info.loadingWarning' | transloco }}
    </div>
    <ptl-skeleton-view [state]="{ loading: true }" [viewType]="'action'"></ptl-skeleton-view>
  </ng-container>
</ng-container>
