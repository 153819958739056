<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<ptl-data-forms-error *ngIf="dataForms?.length > 0; else panel" [dataFroms]="dataForms"></ptl-data-forms-error>

<ng-template #panel>
  <mat-card class="error-content" [class.has-top-margin]="hideBackButton">
    <div class="error-content-inner">
      <div class="error-content-left">
        <h1 class="error-title">{{ 'translations.global.message.error.oops' | transloco }}</h1>

        <div class="error-description">
          {{ errorMessage }}
        </div>

        <button mat-raised-button class="back-button" color="accent" *ngIf="!hideBackButton" (click)="backToHome()">
          {{ 'translations.global.button.backToHome' | transloco }}
        </button>
      </div>

      <div class="error-content-right">
        <img src="/assets/404.svg" class="error-content-image" alt="" width="88" height="67" />
      </div>
    </div>
  </mat-card>
</ng-template>
