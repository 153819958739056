<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<div [class.spinner]="state?.loading">
  <mat-spinner class="spinner__loader" *ngIf="state?.loading; else content" [diameter]="spinnerDiameter"></mat-spinner>
</div>

<ng-template #content>
  <ng-content *ngIf="!state?.error"></ng-content>
</ng-template>

<ptl-data-load-error-panel *ngIf="!!state?.error" [errorMessage]="state?.error" [dataForms]="state?.dataForms"></ptl-data-load-error-panel>
