/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Inject, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { PlaylistPublishedStatus } from 'src/app/shared/models/playlist/learner-playlist-summary.model';
import { LEARNER_PLAYLIST_DATA_SERVICE, LearnerPlaylistDataService } from 'src/app/shared/services/learner-playlist/data.service';
import * as BreadcrumbActions from '../../../../shared/breadcrumbs/store/breadcrumbs.actions';
import { ContentHelper } from '../../../../shared/helpers/content-helper';
import { notDevelopmentDomains } from '../../../../shared/helpers/development-domains.helper';
import { SnackbarHelper } from '../../../../shared/helpers/snackbar-helper';
import {
  DisplayConfig,
  EnrollmentActionType,
  Playlist,
  PlaylistCardShort,
  PlaylistStandardSection,
  PlaylistType,
  UserPlaylistSubmissionSummary,
} from '../../../../shared/models';
import { TranslocoService } from '@ngneat/transloco';
import { dataLoadedState, errorState, LoadableState, loadingState } from '../../../../shared/store';
import { UserAuthState, UserAuthStateModel } from '../../../../user-auth/store/user-auth.state';
import { CORE_RESOURCE_DATA_SERVICE, ResourceDataService } from '../../../resource/services/editor/core/data.service';
import { RedirectCardType, RedirectHelper } from '../../../resource/store/editor/content/helpers/redirect.helper';
import { ReviewerDetails } from '../../models';
import { CORE_PLAYLIST_DATA_SERVICE, PlaylistDataService } from '../../services/create/core/data.service';
import * as PlaylistAdminActions from '../create/playlist-creation.actions';
import { SetPublicationSettings } from '../create/publication/playlist-publiction.actions';
import { PROJECT_DATA_SERVICE, ProjectDataService } from './../../../project/services/data.service';
import * as PlaylistViewActions from './playlist-view.state.actions';
import { INITIAL_STATE, PlaylistViewStateModel } from './playlist-view.state.model';
import { GOALS_DATA_SERVICE, GoalsDataService } from '@app/app/page-modules/playlist/services/create/goals/goals-data.service';
import { marker } from '@jsverse/transloco-keys-manager/marker';

@State<PlaylistViewStateModel>({
  name: 'playlistView',
  defaults: INITIAL_STATE,
})
@Injectable()
export class PlaylistViewState {
  @Selector()
  static playlistState({ resource }: PlaylistViewStateModel): LoadableState<Playlist> {
    return resource;
  }

  @Selector()
  static playlistTitle({ resource }: PlaylistViewStateModel): string {
    return resource.data?.title;
  }

  @Selector()
  static playlistId({ resource }: PlaylistViewStateModel): string {
    return resource.data?._id;
  }

  @Selector()
  static playlistUri({ resource }: PlaylistViewStateModel): string {
    return resource.data?.uri;
  }

  @Selector()
  static playlistFormattedUri({ resource }: PlaylistViewStateModel): string {
    return resource.data?.formattedUri;
  }

  @Selector()
  static playlistType({ resource }: PlaylistViewStateModel): PlaylistType {
    return resource.data?.type;
  }

  @Selector()
  static playlistSubmissionDeadline({ resource }: PlaylistViewStateModel): Date {
    return resource.data?.userPlaylistSubmissionSummary.submissionDeadline;
  }

  @Selector()
  static piQuestionnaireCard({ resource }: PlaylistViewStateModel): PlaylistCardShort {
    return resource.data?.mainSection.cards.find((card) => card.type === 'PERSONALITY_INDICATOR');
  }

  @Selector()
  static playlistDisplayConfigState({ resource }: PlaylistViewStateModel): DisplayConfig | undefined {
    return resource.data ? resource.data.authorDetails.displayConfig : undefined;
  }

  @Selector()
  static playlistOrganizationName({ resource }: PlaylistViewStateModel): string | undefined {
    const orgName = resource.data?.authorDetails.organization;
    const changedOrgName = resource.data?.authorDetails.displayConfig.externalAuthor?.name;
    return changedOrgName ? changedOrgName : orgName;
  }

  @Selector()
  static playlistOrganizationLogoUrl({ resource }: PlaylistViewStateModel): string | undefined {
    const orgLogoUrl = resource.data?.authorDetails.orgLogoUrl;
    const uploadedOrgLogoUrl = resource.data?.authorDetails.displayConfig.externalAuthor?.image;
    return uploadedOrgLogoUrl ? uploadedOrgLogoUrl : orgLogoUrl;
  }

  @Selector()
  static playlistAuthorImageUrl({ resource }: PlaylistViewStateModel): string {
    const authorImageUrl = resource.data ? resource.data.authorDetails.userImageUrl : undefined;
    return authorImageUrl ? authorImageUrl : '';
  }

  @Selector()
  static mainSectionCardsState({ resource }: PlaylistViewStateModel): PlaylistCardShort[] | undefined {
    return resource.data ? resource.data.mainSection.cards : undefined;
  }

  @Selector()
  static playlistStatus({ resource }: PlaylistViewStateModel): PlaylistPublishedStatus {
    return resource?.data?.status;
  }

  @Selector()
  static resourceAllCards({ resource }: PlaylistViewStateModel): PlaylistCardShort[] | undefined {
    if (!resource.data) {
      return undefined;
    }

    const mainSectionCards = resource.data.mainSection.cards;
    const standardSectionsCards = [];
    resource.data.standardSections
      .map((section) => {
        return section.cards;
      })
      .forEach((cards) => {
        cards.forEach((card) => {
          standardSectionsCards.push(card);
        });
      });

    return mainSectionCards.concat(standardSectionsCards);
  }

  @Selector()
  static standardSectionState({ resource }: PlaylistViewStateModel): PlaylistStandardSection[] | undefined {
    return resource.data ? resource.data.standardSections : undefined;
  }

  @Selector()
  static resourceAuthorName({ resource }: PlaylistViewStateModel): string | undefined {
    return resource.data?.authorDetails.name;
  }

  @Selector()
  static playlistDefaultReviewers({ resource }: PlaylistViewStateModel): ReviewerDetails[] {
    const collaborators = resource.data?.adminRights ?? [];
    const reviewers = new Map(
      collaborators.map((collaborator) => {
        return [
          // avoid duplicates
          collaborator.uid,
          {
            uid: collaborator.uid,
            firstName: collaborator.firstName,
            lastName: collaborator.lastName,
            email: collaborator.email,
            imageUrl: collaborator.imageUrl,
            isAdmin: true,
            rejected: false,
          },
        ];
      }),
    );
    return [...reviewers.values()];
  }

  @Selector([UserAuthState])
  static isAdmin(playlistViewState: PlaylistViewStateModel, userAuthState: UserAuthStateModel): boolean | undefined {
    const resource = playlistViewState.resource;
    return !!resource && !!resource.data ? resource.data.permission.canEdit : undefined;
  }

  @Selector([UserAuthState])
  static canViewMembers(playlistViewState: PlaylistViewStateModel, userAuthState: UserAuthStateModel): boolean | undefined {
    const resource = playlistViewState.resource;
    // TODO: Replace with `canViewAnalytics`
    return !!resource && !!resource.data ? resource.data.permission.canViewAnalytics : undefined;
  }

  @Selector([UserAuthState])
  static canViewAnalytics(playlistViewState: PlaylistViewStateModel, userAuthState: UserAuthStateModel): boolean | undefined {
    const resource = playlistViewState.resource;
    return !!resource && !!resource.data ? resource.data.permission.canViewAnalytics : undefined;
  }

  @Selector([UserAuthState])
  static canViewReviewerDashboard(playlistViewState: PlaylistViewStateModel, userAuthState: UserAuthStateModel): boolean | undefined {
    if (ContentHelper.isFrameMode() && notDevelopmentDomains(userAuthState?.orgDetails?.data?.domain)) {
      return false;
    }
    const resource = playlistViewState.resource;
    return !!resource && !!resource.data ? resource.data.permission.reviewerDashboardPermission.accessForGroups.length > 0 : undefined;
  }

  @Selector([UserAuthState])
  static canExportMembers(playlistViewState: PlaylistViewStateModel, userAuthState: UserAuthStateModel): boolean | undefined {
    const resource = playlistViewState.resource;
    return !!resource && !!resource.data ? resource.data.permission.canDoMemberExports : undefined;
  }

  @Selector([UserAuthState])
  static canEditGoal(playlistViewState: PlaylistViewStateModel, userAuthState: UserAuthStateModel): boolean | undefined {
    if (ContentHelper.isFrameMode() && notDevelopmentDomains(userAuthState?.orgDetails?.data?.domain)) {
      return false;
    }
    const resource = playlistViewState.resource;
    return !!resource && !!resource.data ? resource.data.permission.canEditGoal : undefined;
  }

  @Selector([UserAuthState])
  static canEditBadge(playlistViewState: PlaylistViewStateModel, userAuthState: UserAuthStateModel): boolean | undefined {
    if (ContentHelper.isFrameMode() && notDevelopmentDomains(userAuthState?.orgDetails?.data?.domain)) {
      return false;
    }
    const resource = playlistViewState.resource;
    return !!resource && !!resource.data ? resource.data.permission.canEditBadge : undefined;
  }

  @Selector([UserAuthState])
  static canPublish(playlistViewState: PlaylistViewStateModel): boolean | undefined {
    const resource = playlistViewState.resource;
    return !!resource && !!resource.data ? resource.data.permission.canPublish : undefined;
  }

  @Selector()
  static isSyndicated({ resource }: PlaylistViewStateModel): boolean | undefined {
    return resource.data ? !!resource.data.syndicated : undefined;
  }

  @Selector()
  static timeRequired({ resource }: PlaylistViewStateModel): number | undefined {
    return resource.data ? resource.data.timeRequired : undefined;
  }

  @Selector()
  static userPlaylistSubmissionSummary({ resource }: PlaylistViewStateModel): UserPlaylistSubmissionSummary | undefined {
    return resource.data ? resource.data.userPlaylistSubmissionSummary : undefined;
  }

  @Selector()
  static playlistHasTicketsRegistration({ resource }: PlaylistViewStateModel): boolean {
    if (resource?.data?.publicationSettings?.enrollment?.actionTrigger?.mode) {
      return resource.data.publicationSettings.enrollment.actionTrigger.mode === EnrollmentActionType.TICKET;
    }
    return false;
  }

  @Selector([UserAuthState])
  static canAddClonedContent(playlistViewState: PlaylistViewStateModel, userAuthState: UserAuthStateModel): boolean | undefined {
    if (ContentHelper.isFrameMode() && notDevelopmentDomains(userAuthState?.orgDetails?.data?.domain)) {
      return false;
    }
    const resource = playlistViewState.resource;
    return !!resource && !!resource.data ? resource.data.permission.canClone : undefined;
  }

  @Selector([UserAuthState])
  static canClonePlaylist({ resource }: PlaylistViewStateModel, userAuthState: UserAuthStateModel): boolean | undefined {
    if ((ContentHelper.isFrameMode() && notDevelopmentDomains(userAuthState?.orgDetails?.data?.domain)) || resource.data?.syndicated) {
      return false;
    }
    if (!resource.data?.permission) {
      return undefined;
    } else {
      const permission = resource.data.permission;
      return permission.canClone;
    }
  }

  @Selector([UserAuthState])
  static canOpenEditPages({ resource }: PlaylistViewStateModel, userAuthState: UserAuthStateModel): boolean | undefined {
    if (!resource.data?.permission) {
      return undefined;
    } else {
      const permission = resource.data.permission;
      return (
        permission.canEdit ||
        permission.canEditBadge ||
        permission.canEditGoal ||
        permission.canPublish ||
        permission.canViewAnalytics ||
        permission.canManageCollaboration ||
        permission.canManageTargeting ||
        permission.reviewerDashboardPermission.accessForGroups.length > 0
      );
    }
  }

  @Selector([UserAuthState])
  static canOpenEditSettings({ resource }: PlaylistViewStateModel, userAuthState: UserAuthStateModel): boolean | undefined {
    if (!resource.data?.permission || resource.data.syndicated) {
      return undefined;
    } else {
      const permission = resource.data.permission;
      return (
        permission.canEdit ||
        permission.canEditBadge ||
        permission.canEditGoal ||
        permission.canPublish ||
        permission.canManageCollaboration ||
        permission.canManageTargeting
      );
    }
  }

  @Selector()
  static cardWasOpened({ cardWasOpened }: PlaylistViewStateModel): boolean | undefined {
    return cardWasOpened;
  }

  constructor(
    @Inject(CORE_RESOURCE_DATA_SERVICE) private resourceDataService: ResourceDataService,
    @Inject(CORE_PLAYLIST_DATA_SERVICE) private playlistDataService: PlaylistDataService,
    @Inject(PROJECT_DATA_SERVICE) private projectDataService: ProjectDataService,
    @Inject(LEARNER_PLAYLIST_DATA_SERVICE) private learnerPlaylistDataService: LearnerPlaylistDataService,
    @Inject(GOALS_DATA_SERVICE) private goalsDataService: GoalsDataService,
    private store: Store,
    private ngZone: NgZone,
    private router: Router,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private translocoService: TranslocoService,
  ) {}

  @Action(PlaylistViewActions.LoadPlaylistViewDetails)
  loadPlaylistViewDetails(
    { patchState }: StateContext<PlaylistViewStateModel>,
    { playlistUri, publisherUri, packageUri, pageUri, languageCode }: PlaylistViewActions.LoadPlaylistViewDetails,
  ) {
    patchState({
      resource: loadingState(),
    });

    return this.playlistDataService.getPlaylistDetails(playlistUri, publisherUri, packageUri, pageUri, languageCode).pipe(
      tap(({ isSuccess, value, error, dataForms }) => {
        this.loadPlaylistDetails(isSuccess, patchState, value, error, dataForms);
      }),
    );
  }

  @Action(PlaylistViewActions.LoadProjectPlaylistViewDetails)
  loadProjectPlaylistViewDetails(
    { patchState }: StateContext<PlaylistViewStateModel>,
    { playlistUri, languageCode }: PlaylistViewActions.LoadProjectPlaylistViewDetails,
  ) {
    patchState({
      resource: loadingState(),
    });

    return this.projectDataService.getProjectDetails(playlistUri, languageCode).pipe(
      tap(({ isSuccess, value, error, dataForms }) => {
        this.loadPlaylistDetails(isSuccess, patchState, value, error, dataForms);
      }),
    );
  }

  private loadPlaylistDetails(
    isSuccess: boolean,
    patchState: (val: Partial<PlaylistViewStateModel>) => PlaylistViewStateModel,
    value: Playlist,
    error: string,
    dataForms: string[],
  ) {
    if (isSuccess) {
      patchState({
        resource: dataLoadedState(value),
      });
      this.store.dispatch(
        new SetPublicationSettings(value._id, value.type, { ...value.publicationSettings, published: value.status === 'PUBLISHED' }),
      );
    } else {
      patchState({
        resource: errorState(error, dataForms),
      });
    }
  }

  @Action(PlaylistViewActions.LoadPlaylistViewDetailsInLanguage)
  loadPlaylistViewDetailsInLanguage(
    { patchState }: StateContext<PlaylistViewStateModel>,
    { playlistUri, publisherUri, packageUri, pageUri, languageCode }: PlaylistViewActions.LoadPlaylistViewDetailsInLanguage,
  ) {
    patchState({
      resource: loadingState(),
    });

    return this.playlistDataService.getPlaylistDetails(playlistUri, publisherUri, packageUri, pageUri, languageCode).pipe(
      tap(({ isSuccess, value, error }) =>
        isSuccess
          ? patchState({
              resource: dataLoadedState(value),
            })
          : patchState({
              resource: errorState(error),
            }),
      ),
    );
  }

  @Action(PlaylistViewActions.RefreshPlaylistViewDetails)
  refreshPlaylistViewDetails(
    { patchState }: StateContext<PlaylistViewStateModel>,
    { playlistUri, publisherUri, packageUri, pageUri, languageCode }: PlaylistViewActions.RefreshPlaylistViewDetails,
  ) {
    return this.playlistDataService.getPlaylistDetails(playlistUri, publisherUri, packageUri, pageUri, languageCode).pipe(
      tap(({ isSuccess, value, error }) =>
        isSuccess
          ? patchState({
              resource: dataLoadedState(value),
            })
          : patchState({
              resource: errorState(error),
            }),
      ),
    );
  }

  @Action(PlaylistViewActions.NavigateToRootPage)
  navigateToRootPage() {
    this.navigateToRoot();
  }

  @Action(PlaylistViewActions.OpenResourceDetails)
  async openResourceDetails(
    { getState }: StateContext<PlaylistViewStateModel>,
    { resource, isEditView, folioPublicId, queryUriParam }: PlaylistViewActions.OpenResourceDetails,
  ) {
    const playlist = getState().resource.data;
    const playlistUri = playlist.uri;
    const isProject = playlist.type === 'PROJECT';

    if (isProject) {
      if (playlistUri) {
        RedirectHelper.redirectByParams(
          this.ngZone,
          this.router,
          this.activatedRoute,
          {
            folioPublicId: folioPublicId,
            playlistUri: playlistUri,
            resourceUri: resource.uri,
            openEditView: isEditView,
          },
          'PROJECT_CARD',
        );
      }
    } else {
      let navigationUrl: string;
      if (resource.type === 'PLAYLIST') {
        navigationUrl = RedirectHelper.getRedirectUrl(
          this.activatedRoute,
          {
            playlistUri: resource.uri,
            formattedUri: resource.formattedUri,
            openEditView: isEditView,
            queryUriParam: queryUriParam,
          },
          'PLAYLIST',
        );
      } else if (resource.type === 'WEBLINK' && !isEditView) {
        this.store.dispatch(new PlaylistViewActions.OpenWebLink(resource));
      } else {
        navigationUrl = RedirectHelper.getRedirectUrl(
          this.activatedRoute,
          {
            playlistUri: playlistUri,
            resourceUri: resource.uri,
            formattedUri: resource.formattedUri,
            pageNumberUri: 'page/1',
            openEditView: isEditView,
            queryUriParam: queryUriParam,
          },
          resource.type as RedirectCardType,
        );
      }

      if (navigationUrl) {
        const extractedPlaylistUri = navigationUrl.split('/')[2];
        const isPlaylistUrl = navigationUrl.split('/').length === 3;
        if (playlistUri === extractedPlaylistUri || isPlaylistUrl) {
          const playlistUrl = RedirectHelper.getRedirectUrl(this.activatedRoute, { playlistUri: playlistUri }, 'PLAYLIST');
          const badgeThumbnail = await this.getPlaylistGoalImage(playlist._id);

          this.store.dispatch(
            new BreadcrumbActions.AddPlaylistBreadcrumb(
              {
                url: playlistUrl,
                label: playlist.title,
                additionalData: {
                  playlistData: {
                    playlistId: playlist._id,
                    userPlaylistSubmissionSummary: playlist.userPlaylistSubmissionSummary,
                    playlistThumbnail: playlist.thumbnail,
                    badgeThumbnail: badgeThumbnail,
                  },
                },
              },
              this.store.selectSnapshot(PlaylistViewState.resourceAllCards),
              navigationUrl,
            ),
          );
        } else {
          this.store.dispatch(new BreadcrumbActions.PopBreadcrumbsByCurrentResourceUrl(navigationUrl));
        }
        RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, navigationUrl);
      }
    }
  }

  @Action(PlaylistViewActions.OpenPlaylistEdit)
  openPlaylistEdit(
    { getState }: StateContext<PlaylistViewStateModel>,
    { playlistUri, formattedUri, editParam }: PlaylistViewActions.OpenPlaylistEdit,
  ) {
    let extraUrlParam = '';
    const state = getState();
    const resourceData = state.resource.data;
    const permissions = resourceData.permission;

    if (editParam) {
      extraUrlParam = editParam;
    } else {
      if (!permissions.canEdit) {
        if (permissions.canPublish) {
          extraUrlParam = 'publish';
        } else if (permissions.canEditGoal || permissions.canEditBadge) {
          extraUrlParam = 'goals';
        } else if (permissions.canManageTargeting) {
          extraUrlParam = 'target-audience';
        } else if (permissions.canManageCollaboration) {
          extraUrlParam = 'collaboration-settings';
        } else if (permissions.reviewerDashboardPermission.accessForGroups.length > 0) {
          extraUrlParam = 'reviews';
        } else {
          extraUrlParam = 'edit';
        }
      } else {
        extraUrlParam = 'edit';
      }
    }
    RedirectHelper.redirectByParams(
      this.ngZone,
      this.router,
      this.activatedRoute,
      {
        playlistUri: playlistUri,
        formattedUri: formattedUri,
        extraUriParam: extraUrlParam,
      },
      'PLAYLIST',
    );
  }

  @Action(PlaylistViewActions.SetPlaylistViewDetails)
  setPlaylistViewDetails({ patchState }: StateContext<PlaylistViewStateModel>, { playlist }: PlaylistViewActions.SetPlaylistViewDetails) {
    patchState({
      resource: dataLoadedState(playlist),
    });
  }

  @Action(PlaylistViewActions.UpdatePlaylistLearnerViewCards)
  updatePlaylistLearnerViewCards(
    { getState, patchState }: StateContext<PlaylistViewStateModel>,
    { cards, sectionUid }: PlaylistViewActions.UpdatePlaylistLearnerViewCards,
  ) {
    const playlist = getState().resource.data;
    if (!sectionUid) {
      playlist.mainSection.cards = cards;
    } else {
      for (const section of playlist.standardSections) {
        if (section.uid === sectionUid) {
          section.cards = cards;
          break;
        }
      }
    }
    patchState({
      resource: dataLoadedState(playlist),
    });
  }

  @Action(PlaylistViewActions.UpdateCardUri)
  updateCardUri({ patchState, getState }: StateContext<PlaylistViewStateModel>, { cardUid, cardUri }: PlaylistViewActions.UpdateCardUri) {
    const playlist = getState().resource.data;
    let updated = false;
    for (const card of playlist.mainSection.cards) {
      if (card._id === cardUid) {
        card.uri = cardUri;
        updated = true;
        break;
      }
    }
    if (!updated) {
      for (const section of playlist.standardSections) {
        for (const card of section.cards) {
          if (card._id === cardUid) {
            card.uri = cardUri;
            updated = true;
            break;
          }
        }
      }
    }
    patchState({
      resource: dataLoadedState(playlist),
    });
  }

  @Action(PlaylistViewActions.OpenWebLink)
  openWebLink(_: StateContext<PlaylistViewStateModel>, { resource }: PlaylistViewActions.OpenWebLink) {
    this.resourceDataService.markWebLinkOpened(resource._id).subscribe(() => {
      const openTarget = resource.webLinkUrl.indexOf(window.location.hostname) !== -1 ? '_self' : '_blank';
      window.open(resource.webLinkUrl, openTarget);
    });
  }

  @Action(PlaylistViewActions.OpenWebLinkEdit)
  openWebLinkEdit({ getState }: StateContext<PlaylistViewStateModel>, { resource }: PlaylistViewActions.OpenWebLinkEdit) {
    RedirectHelper.redirectByParams(
      this.ngZone,
      this.router,
      this.activatedRoute,
      {
        playlistUri: getState().resource.data.uri,
        resourceUri: resource.uri,
        formattedUri: resource.formattedUri,
        pageNumberUri: 'page/1',
        extraUriParam: 'edit',
      },
      'RESOURCE',
    );
  }

  @Action(PlaylistViewActions.CloneCard)
  cloneCard({ getState }: StateContext<PlaylistViewStateModel>, { card, sectionUid }: PlaylistViewActions.CloneCard) {
    const playlist = getState().resource.data;
    if (card.type === 'PLAYLIST') {
      this.playlistDataService.addPlaylistToPlaylist(playlist._id, sectionUid, card._id, true).subscribe(({ isSuccess }) => {
        this.handleSuccessfullyCopyResolve(isSuccess, true, playlist.uri);
      });
    } else {
      this.playlistDataService.addCardToPlaylist(playlist._id, sectionUid, card._id, true).subscribe(({ isSuccess }) => {
        this.handleSuccessfullyCopyResolve(isSuccess, false, playlist.uri);
      });
    }
  }

  @Action(PlaylistViewActions.PlaylistCardWasOpened)
  playlistCardWasOpened({ patchState }: StateContext<PlaylistViewStateModel>, { opened }: PlaylistViewActions.PlaylistCardWasOpened) {
    patchState({
      cardWasOpened: opened,
    });
  }

  @Action(PlaylistViewActions.RefreshUserPlaylistSubmissionSummary)
  refreshUserPlaylistSubmissionSummary(
    { patchState, getState }: StateContext<PlaylistViewStateModel>,
    { playlistUid }: PlaylistViewActions.RefreshUserPlaylistSubmissionSummary,
  ) {
    this.learnerPlaylistDataService.getPlaylistSubmissionSummary(playlistUid).subscribe(({ isSuccess, value }) => {
      if (isSuccess && value) {
        const resource = getState().resource;
        patchState({
          resource: {
            ...resource,
            data: {
              ...resource.data,
              userPlaylistSubmissionSummary: value,
            },
          },
        });
      }
    });
  }

  private handleSuccessfullyCopyResolve(isSuccess: boolean, isPlaylist: boolean, playlistUri: string) {
    if (isSuccess) {
      const publisherUri = this.activatedRoute.snapshot.paramMap.get('publisherUri');
      const packageUri = this.activatedRoute.snapshot.paramMap.get('packageUri');
      const pageUri = this.activatedRoute.snapshot.paramMap.get('pagesUri');

      if (this.router.url.includes('/edit')) {
        this.store.dispatch(new PlaylistAdminActions.RefreshPlaylist(playlistUri, publisherUri, packageUri, pageUri));
      } else {
        this.store.dispatch(new PlaylistViewActions.RefreshPlaylistViewDetails(playlistUri, publisherUri, packageUri, pageUri));
      }
      const translationKey = isPlaylist
        ? marker('translations.clone.messages.playlist.success')
        : marker('translations.clone.messages.card.success');
      SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, this.translocoService.translate(translationKey));
    } else {
      const translationKey = isPlaylist
        ? marker('translations.clone.messages.playlist.error')
        : marker('translations.clone.messages.card.error');
      SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, this.translocoService.translate(translationKey));
    }
  }

  private navigateToRoot() {
    const homePageUri = this.store.selectSnapshot(UserAuthState.homePageUri);
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, homePageUri);
  }

  private async getPlaylistGoalImage(playlistUid: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.goalsDataService.getGoal(playlistUid).subscribe(async ({ value }) => {
        try {
          const playlistGoal = value;
          if (playlistGoal && playlistGoal.connectedBadge?.badgeId) {
            const goalImage = await this.getPlaylistGoalBadgeImage(playlistGoal.connectedBadge.badgeId);
            resolve(goalImage);
          } else {
            resolve('');
          }
        } catch (err) {
          resolve('');
        }
      });
    });
  }

  private async getPlaylistGoalBadgeImage(badgeId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.goalsDataService.getBadge(badgeId).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          const imageUrl = value?.imageUrl;
          resolve(imageUrl);
        } else {
          resolve('');
        }
      });
    });
  }
}
