import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// RxJS
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
// Services
import {
  AI_ASSISTANT_SERVICE,
  AiAssistantService,
  AIAssistantSettingsTemplateDTO,
} from '../../../../../services/assistant/ai-assistant.service';
import { canTriggerSearch } from '../../../../../../shared/helpers/content-helper';

@Component({
  selector: 'ptl-form-ai-search-dialog',
  templateUrl: './form-ai-search-dialog.component.html',
  styleUrls: ['./form-ai-search-dialog.component.scss'],
})
export class FormAiSearchDialogComponent implements OnInit, OnDestroy {
  searchString = '';
  loadingSearch = false;
  templateList: AIAssistantSettingsTemplateDTO[];
  private searchInputSubject$: Subject<void> = new Subject<void>();
  private searchInputSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<FormAiSearchDialogComponent>,
    @Inject(AI_ASSISTANT_SERVICE) private aiAssistantService: AiAssistantService,
    @Inject(MAT_DIALOG_DATA) public data: { cardUid: string },
  ) {
    this.searchInputSubscription = this.searchInputSubject$.pipe(debounceTime(500)).subscribe(() => this.getTemplates());
  }

  ngOnInit() {
    this.getTemplates();
  }

  ngOnDestroy() {
    this.searchInputSubscription.unsubscribe();
  }

  onTextPaste(event: ClipboardEvent): void {
    if (event.type === 'paste') {
      setTimeout(() => {
        if (this.searchString) {
          this.loadingSearch = true;
          this.searchInputSubject$.next();
        }
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent): void {
    if (canTriggerSearch(event)) {
      if (this.searchString) {
        this.loadingSearch = true;
        this.searchInputSubject$.next();
      }
    }
  }

  getTemplates() {
    this.aiAssistantService.searchAIAssistantSettingsTemplates(this.data.cardUid, this.searchString).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.templateList = value.content;
        this.loadingSearch = false;
      }
    });
  }

  selectTemplate(assistantSettingsUid: string) {
    this.dialogRef.close({ assistantSettingsUid: assistantSettingsUid, closed: true });
  }

  onCloseDialog() {
    this.dialogRef.close(true);
  }
}
