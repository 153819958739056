<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="form-section"
  (focusin)="focused = true"
  (focusout)="focused = false"
  (click)="expandForm()"
  (keydown.enter)="$event.stopPropagation()"
>
  <form
    class="form-block"
    [class.has-error]="hasError"
    #checkboxFormHtml
    tabindex="0"
    [class.is_focused]="focused"
    [attr.aria-label]="'translations.global.ariaLabels.thisIsCheckboxForm' | transloco"
    [formGroup]="checkboxForm"
    (ngSubmit)="saveForm()"
  >
    <div class="form-block-inner">
      <div class="form-block-title-box with-checkbox">
        <mat-checkbox color="primary" class="static-checkbox" checked disabled [aria-label]="'disabled option'"></mat-checkbox>
        <input
          #formTitleInput
          type="text"
          class="form-block-input-field no-border full-width no-padding medium bold"
          required
          formControlName="title"
          [placeholder]="'translations.instruction' | transloco"
        />
      </div>
      <ng-container *ngIf="expanded || forceExpand">
        <div class="form-block-help-text-box extra-small"></div>
        <button
          mat-icon-button
          type="button"
          class="form-edit-icon"
          [attr.aria-label]="'translations.global.ariaLabels.icons.edit' | transloco"
        >
          <svg class="svg" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
          </svg>
        </button>
      </ng-container>
      <span class="setting-text extra-small" *ngIf="!(expanded || forceExpand)">{{
        'translations.global.button.settings' | transloco
      }}</span>
    </div>
    <div class="form-block-buttons checkbox-form-buttons" *ngIf="expanded || forceExpand">
      <button
        mat-flat-button
        class="form-button-remove small f_delete-block"
        type="button"
        color="warn"
        title="Remove element"
        *ngIf="canRemoveItem"
        [attr.aria-label]="'translations.global.ariaLabels.deleteTextBoxForm' | transloco"
        (click)="removeForm()"
      >
        <svg class="svg svg-icon icon-left" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
        </svg>
        <span>{{ 'translations.global.button.delete' | transloco }}</span>
      </button>
      <button
        mat-flat-button
        class="form-button-save small btn-with-loader f_update-block"
        title="Save"
        color="primary"
        type="submit"
        [attr.aria-label]="'translations.global.ariaLabels.addTextBoxForm' | transloco"
      >
        <svg class="svg svg-icon icon-left" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
        </svg>
        <span>{{ !form?.uid ? ('translations.addToForm' | transloco) : ('translations.global.button.update' | transloco) }}</span>
        <mat-spinner class="is-right" *ngIf="saveInProgress" [diameter]="20"></mat-spinner>
      </button>
      <button
        mat-flat-button
        class="form-button-save small f_expand-block"
        [title]="'translations.global.button.cancel' | transloco"
        color="accent"
        type="button"
        (click)="collapseForm($event)"
        [attr.aria-label]="'translations.global.ariaLabels.collapseForm' | transloco"
      >
        <span>{{ 'translations.global.button.cancel' | transloco }}</span>
      </button>
    </div>
  </form>
</div>
