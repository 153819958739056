/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { AfterViewInit, Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { PlaylistDataViewModel } from '@app/app/page-modules/pages/components/edit/analytics/page-analytics.model';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';
import { exportToCsv } from '@app/app/shared/utils/analytics.utils';
import { RedirectHelper } from '@app/app/page-modules/resource/store/editor/content/helpers/redirect.helper';
import { ApiContentLinkService } from '@app/app/shared/services/content-link/api-content-link.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ptl-details-dialog',
  templateUrl: './playlist-metrics-table-dialog.component.html',
  styleUrls: ['./playlist-metrics-table-dialog.component.scss'],
})
export class PlaylistMetricsTableDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = [
    'header',
    'totalCards',
    'totalMembers',
    'startedCourseCount',
    'completedCourseCount',
    'totalEventCardRegistrations',
    'totalEnrollmentsCount',
  ];
  dataSource: MatTableDataSource<PlaylistDataViewModel> = new MatTableDataSource();

  private pageHeadline: string;

  sortedColumn: string | null = null;

  constructor(
    private contentLinkService: ApiContentLinkService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
    public dialogRef: MatDialogRef<PlaylistMetricsTableDialogComponent>,
    private translocoService: TranslocoService,
    @Inject(MAT_DIALOG_DATA) public data: { pageHeadline: string; playlistData: PlaylistDataViewModel[] },
  ) {}

  ngOnInit(): void {
    this.dataSource.data = this.data.playlistData;
    this.pageHeadline = this.data.pageHeadline;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  openInNewTab(playlistUid: string): void {
    this.contentLinkService.getPlaylistFormattedUriByPlaylistUid(playlistUid).subscribe(({ isSuccess, value }) => {
      if (isSuccess && value?.formattedUri) {
        RedirectHelper.redirectByParams(
          this.ngZone,
          this.router,
          this.activatedRoute,
          {
            formattedUri: `${value.formattedUri}/analytics`,
          },
          'PLAYLIST',
          '_blank',
        );
      }
    });
  }

  onSortChange(event: { active: string; direction: string }) {
    this.sortedColumn = event.direction ? event.active : null;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  downloadCsvFile(): void {
    const headers = [
      this.translocoService.translate('translations.global.label.course'),
      this.translocoService.translate('translations.analytics.metric.cards'),
      this.translocoService.translate('translations.members.title'),
      this.translocoService.translate('translations.analytics.metric.startedUserCourses'),
      this.translocoService.translate('translations.analytics.metric.completedUserCourses'),
      this.translocoService.translate('translations.analytics.metric.eventCardRegistrations'),
      this.translocoService.translate('translations.analytics.metric.totalEnrollments'),
    ];

    const rows = this.dataSource.data.map((row) => [
      row.header,
      row.totalCards,
      row.totalMembers,
      row.startedCourseCount,
      row.completedCourseCount,
      row.totalEventCardRegistrations,
      row.totalEnrollmentsCount,
    ]);

    const time = moment().format('YYYY-MM-DD_HH-mm-ss');
    const fileName = `${this.pageHeadline || 'metrics'}_courses_${time}.csv`;

    exportToCsv(headers, rows, fileName);
  }
}
