<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<mat-card class="event-tickets__container">
  <div class="enable-ticket-toggle-container">
    <mat-slide-toggle
      class="ptl-slide-toggle"
      [disabled]="!editingEnabled"
      [attr.aria-label]="'translations.global.ariaLabels.eventTicketsEnableDisableToggle' | transloco"
      [checked]="shouldDisplayData()"
      [hidden]="enrollment"
      (change)="switchTicketsToggle($event)"
      (keydown.enter)="switchTicketsToggle($event)"
    >
      <span hidden="true">
        {{ 'translations.eventTicketsEnableDisableToggle' | transloco }}
      </span>
    </mat-slide-toggle>
  </div>

  <div class="event-tickets__container-top">
    <div class="event-tickets__container-description">
      <h2 class="title large" [attr.aria-label]="'translations.global.ariaLabels.eventTicketsTitleNew' | transloco">
        {{ 'translations.eventTicketsTitleNew' | transloco }}
      </h2>
      <p
        *ngIf="!shouldDisplayData()"
        class="description small"
        [attr.aria-label]="'translations.global.ariaLabels.eventTicketsDescriptionDisabled' | transloco"
      >
        {{ 'translations.eventTicketsDescriptionDisabled' | transloco }}
      </p>
      <p
        *ngIf="shouldDisplayData()"
        class="description small"
        [attr.aria-label]="'translations.global.ariaLabels.eventTicketsDescriptionEnabled' | transloco"
      >
        {{ 'translations.eventTicketsDescriptionEnabled' | transloco }}
      </p>
    </div>

    <div *ngIf="shouldDisplayData()">
      <div class="event-tickets_options">
        <div class="field-title small bold" [attr.aria-label]="'translations.global.ariaLabels.eventTicketsNumberOfTickets' | transloco">
          {{ 'translations.eventTicketsNumberOfTickets' | transloco }}
        </div>

        <div class="event-tickets_options-settings">
          <button
            class="event-tickets_option-btn left"
            [class.is_selected]="eventTicketsAvailability === 'UNLIMITED'"
            [attr.aria-label]="'translations.global.ariaLabels.eventTicketsUnlimitedBtnText' | transloco"
            (click)="selectAvailability('UNLIMITED')"
          >
            <svg class="svg" role="img" *ngIf="eventTicketsAvailability === 'UNLIMITED'" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
            </svg>
            <span class="small event-tickets_option-btn-text" [class.is_selected]="eventTicketsAvailability === 'UNLIMITED'">
              {{ 'translations.eventTicketsUnlimitedBtnText' | transloco }}
            </span>
          </button>
          <button
            class="event-tickets_option-btn right"
            [class.is_selected]="eventTicketsAvailability === 'LIMITED'"
            [attr.aria-label]="'translations.global.ariaLabels.eventTicketsLimitedBtnText' | transloco"
            (click)="selectAvailability('LIMITED')"
          >
            <svg class="svg" role="img" *ngIf="eventTicketsAvailability === 'LIMITED'" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
            </svg>
            <span class="small event-tickets_option-btn-text" [class.is_selected]="eventTicketsAvailability === 'LIMITED'">
              {{ 'translations.eventTicketsLimitedBtnText' | transloco }}
            </span>
          </button>
          <input
            *ngIf="eventTicketsAvailability === 'LIMITED'"
            class="event-tickets_quantity-field small"
            type="number"
            min="0"
            placeholder="100"
            [attr.aria-label]="'translations.global.ariaLabels.enterTicketsQuantity' | transloco"
            autocomplete="off"
            (ngModelChange)="onDataUpdate()"
            [(ngModel)]="eventTicketsQuantity"
          />
          <mat-slide-toggle
            *ngIf="eventTicketsAvailability === 'LIMITED'"
            class="ptl-slide-toggle waiting-list-toggle small"
            [attr.aria-label]="'translations.global.ariaLabels.eventTicketsLimitedAddExtraToWaitingList' | transloco"
            [checked]="waitingListEnabled"
            (change)="toggleWaitingList()"
            (keydown.enter)="toggleWaitingList()"
          >
            {{ 'translations.eventTicketsLimitedAddExtraToWaitingList' | transloco }}
          </mat-slide-toggle>
        </div>
      </div>

      <div class="event-date-box">
        <div class="field-item">
          <div class="field-title small bold" [attr.aria-label]="'translations.global.ariaLabels.eventTicketsAvailableFrom' | transloco">
            {{ 'translations.eventTicketsAvailableFrom' | transloco }}
          </div>
          <div class="datepicker-field" [class.error]="dateError || eventTimeError">
            <input
              matInput
              autocomplete="off"
              class="input-field"
              [attr.aria-label]="'translations.global.ariaLabels.enterStartDate' | transloco"
              placeholder="10/10/2020"
              [(ngModel)]="startedDate"
              (ngModelChange)="onDateChanged()"
              (focus)="onDateInputFocus()"
              (blur)="onDateInputBlur()"
              [min]="minDate"
              [matDatepicker]="fromDatepicker"
            />
            <mat-datepicker #fromDatepicker></mat-datepicker>
            <mat-datepicker-toggle class="datepicker-toggle" matSuffix [for]="fromDatepicker"> </mat-datepicker-toggle>
          </div>
        </div>

        <div class="field-item">
          <div
            *ngIf="!endDateActive"
            class="field-title small bold"
            [attr.aria-label]="'translations.global.ariaLabels.eventTimeDefault' | transloco"
          >
            {{ 'translations.eventTimeDefault' | transloco }}
          </div>
          <div
            *ngIf="endDateActive"
            class="field-title small bold"
            [attr.aria-label]="'translations.global.ariaLabels.eventTimeStart' | transloco"
          >
            {{ 'translations.eventTimeStart' | transloco }}
          </div>
          <div class="select-field-box">
            <mat-select
              class="select-field small bold"
              [attr.aria-label]="'translations.global.ariaLabels.selectStartTime' | transloco"
              [class.error]="timeError || eventTimeError"
              [(ngModel)]="startedTime"
              (selectionChange)="onTimeUpdate()"
              placeholder="HH:MM"
            >
              <mat-option [value]="time" *ngFor="let time of timeSelect">{{ time }}</mat-option>
            </mat-select>
          </div>
        </div>

        <div>
          <button
            *ngIf="!endDateActive"
            class="date-button"
            [attr.aria-label]="'translations.global.ariaLabels.eventDateAddEndDateButton' | transloco"
            (click)="showEndDateForm()"
          >
            <svg class="svg" role="img" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#plus"></use>
            </svg>
            <span class="button_description">
              {{ 'translations.eventDateAddEndDateButton' | transloco }}
            </span>
          </button>
        </div>
      </div>

      <div class="event-date-box" *ngIf="endDateActive">
        <div class="field-item">
          <div class="field-title small bold" [attr.aria-label]="'translations.global.ariaLabels.eventDateEndDateLabel' | transloco">
            {{ 'translations.eventDateEndDateLabel' | transloco }}
          </div>
          <div class="datepicker-field" [class.error]="dateError || eventTimeError">
            <input
              matInput
              autocomplete="off"
              class="input-field"
              [attr.aria-label]="'translations.global.ariaLabels.enterEndDate' | transloco"
              placeholder="10/10/2020"
              [(ngModel)]="endedDate"
              (ngModelChange)="onDateChanged()"
              (focus)="onDateInputFocus()"
              (blur)="onDateInputBlur()"
              [min]="startedDate"
              [matDatepicker]="toDatepicker"
            />
            <mat-datepicker #toDatepicker></mat-datepicker>
            <mat-datepicker-toggle class="datepicker-toggle" matPrefix [for]="toDatepicker"> </mat-datepicker-toggle>
          </div>
        </div>

        <div class="field-item">
          <div class="field-title small bold" [attr.aria-label]="'translations.global.ariaLabels.eventDateEndTimeLabel' | transloco">
            {{ 'translations.eventDateEndTimeLabel' | transloco }}
          </div>
          <div class="select-field-box">
            <mat-select
              class="select-field small bold"
              [attr.aria-label]="'translations.global.ariaLabels.selectEndHour' | transloco"
              [class.error]="timeError || eventTimeError"
              [(ngModel)]="endedTime"
              (selectionChange)="onTimeUpdate()"
              placeholder="HH:MM"
            >
              <mat-option [value]="time" *ngFor="let time of timeSelect">{{ time }}</mat-option>
            </mat-select>
          </div>
        </div>

        <button
          *ngIf="endDateActive"
          class="date-button-inline"
          [attr.aria-label]="'translations.global.ariaLabels.eventDateRemoveEndDateButton' | transloco"
          (click)="hideEndDateForm()"
        >
          <mat-icon class="icon-display" aria-hidden="true">clear</mat-icon>
          <span class="button_description">
            {{ 'translations.eventDateRemoveEndDateButton' | transloco }}
          </span>
        </button>
      </div>

      <div class="event-date-box">
        <div class="field-item">
          <div class="field-title small bold" [attr.aria-label]="'translations.global.ariaLabels.eventDateTimezoneLabel' | transloco">
            {{ 'translations.eventDateTimezoneLabel' | transloco }}
          </div>
          <div class="select-field-box timezone">
            <mat-select
              class="select-field small bold"
              [attr.aria-label]="'translations.global.ariaLabels.selectTimezone' | transloco"
              [(ngModel)]="selectedTimezone"
              [matTooltip]="timeZonesMapList && timeZonesMapList[selectedTimezone] ? timeZonesMapList[selectedTimezone] : null"
              (selectionChange)="onDataUpdate()"
            >
              <mat-option [value]="timezone.value" *ngFor="let timezone of timeZones">{{ timezone.title }}</mat-option>
            </mat-select>
          </div>
        </div>
      </div>

      <div class="event-tickets_update-button-box">
        <button
          [hidden]="enrollment"
          [disabled]="isButtonClicked || !dataHasUpdated"
          mat-flat-button
          class="event-tickets_cancel-button uppercase"
          [attr.aria-label]="'translations.global.ariaLabels.eventTicketsCancelButton' | transloco"
          type="button"
          (click)="cancel()"
        >
          <span>
            {{ 'translations.global.button.cancel' | transloco }}
          </span>
        </button>
        <button
          [disabled]="isButtonClicked || !dataHasUpdated"
          mat-flat-button
          class="event-tickets_update-button uppercase"
          [attr.aria-label]="'translations.global.ariaLabels.eventTicketsUpdateButton' | transloco"
          type="button"
          (click)="update()"
        >
          <span>
            {{ 'translations.global.button.update' | transloco }}
          </span>
        </button>
      </div>
    </div>
  </div>
</mat-card>
