<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<mat-card
  class="moment-card"
  [ngClass]="{ inactive: !card.visible && isFolioSection }"
  [attr.aria-label]="'translations.global.ariaLabels.open' | transloco: { title: card?.title }"
  (keydown.enter)="openMomentForView()"
  (click)="openMomentForView()"
  tabindex="0"
  role="button"
  [style.cursor]="card.viewType === 'COLLECTOR' ? 'initial' : ''"
>
  <div class="moment-card__top">
    <div class="moment-card__top__icon-container-wrapper">
      <div class="moment-card__top-left-section">
        <ptl-profile-image [userSummary]="userDetailsData$ | async"></ptl-profile-image>
        <div class="moment-card__info">
          <div class="moment-card__profile-title">
            {{ (userDetailsData$ | async)?.firstName }}
          </div>
          <div class="moment-card__date">
            {{ card.createdAt | dateFormat: 'MMM D, YYYY' }}
          </div>
        </div>
      </div>
      <button
        *ngIf="card.viewType === 'COLLECTOR'"
        mat-icon-button
        class="moment-card__button"
        [title]="selected ? 'Remove' : 'Add'"
        [class.is_selected]="selected"
        (click)="onButtonClick(); $event.stopPropagation()"
      >
        <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.plus' | transloco">
          <use ogSvgUnify="assets/sprite/sprite.svg#plus"></use>
        </svg>
      </button>

      <button *ngIf="isFolioSection && editMode" (click)="onItemVisibilityState(card, $event)" mat-icon-button class="visibility-icon">
        <svg class="svg" role="img" *ngIf="!card.visible" [attr.aria-label]="'translations.global.ariaLabels.icons.openedEye' | transloco">
          <use ogSvgUnify="assets/sprite/sprite.svg#visibility-off"></use>
        </svg>
        <svg class="svg" role="img" *ngIf="card.visible" [attr.aria-label]="'translations.global.ariaLabels.icons.closedEye' | transloco">
          <use ogSvgUnify="assets/sprite/sprite.svg#visibility"></use>
        </svg>
      </button>
    </div>

    <ptl-masonry-action-button
      *ngIf="card.viewType === 'EDIT'"
      [card]="card"
      [archiveMoment]="archiveMoment"
      (cardArchived)="onArchiveMoment($event)"
      (cardRemoved)="onRemoveMoment($event)"
    ></ptl-masonry-action-button>
  </div>

  <div class="moment-card__content">
    <div class="moment-card__description line-clamp-3 serif-medium">
      {{ card?.text }}
    </div>

    <ng-container *ngIf="card?.image">
      <ptl-optimized-image-loader class="moment-card__loader" *ngIf="!card.imageExternal" [src]="card?.image"></ptl-optimized-image-loader>
      <img class="moment-card__loader" *ngIf="card.imageExternal" [src]="card?.image" width="266" height="150" alt="" />
    </ng-container>
  </div>
</mat-card>
