<!--
  ~ Copyright (C) 2025 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="datepicker__container">
  <input
    matInput
    readonly
    autocomplete="off"
    class="datepicker__input"
    [attr.aria-label]="'translations.global.ariaLabels.selectDate' | transloco"
    [(ngModel)]="date"
    (ngModelChange)="onDateChanged()"
    (click)="datePicker.open()"
    [max]="maxDate"
    [matDatepicker]="datePicker"
  />
  <mat-datepicker #datePicker></mat-datepicker>
  <mat-datepicker-toggle class="datepicker__toggle" matPrefix [for]="datePicker"> </mat-datepicker-toggle>
</div>
