import { INITIAL_MEMBERS_STATE, MembersStateModel } from './members.state.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import * as MembersActions from './members.action';

@State<MembersStateModel>({
  name: 'members',
  defaults: INITIAL_MEMBERS_STATE,
})
@Injectable()
export class MembersState {
  @Selector()
  static selectedMembers({ selectedMembers }: MembersStateModel) {
    return selectedMembers;
  }

  @Selector()
  static memberFilters({ memberFilters }: MembersStateModel) {
    return memberFilters;
  }

  @Selector()
  static cardFilters({ cardFilters }: MembersStateModel) {
    return cardFilters;
  }

  @Selector()
  static playlistFilters({ playlistFilters }: MembersStateModel) {
    return playlistFilters;
  }

  @Selector()
  static playlistPagination({ playlistPaginationFilters }: MembersStateModel) {
    return playlistPaginationFilters;
  }

  @Selector()
  static cardPagination({ cardPaginationFilters }: MembersStateModel) {
    return cardPaginationFilters;
  }

  @Selector()
  static memberPaginationFilter({ memberPaginationFilter }: MembersStateModel) {
    return memberPaginationFilter;
  }

  @Selector()
  static playlistReviewFilters({ playlistReviewFilters }: MembersStateModel) {
    return playlistReviewFilters;
  }

  @Selector()
  static cardReviewFilters({ cardReviewFilters }: MembersStateModel) {
    return cardReviewFilters;
  }

  @Selector()
  static highlightedMemberId({ highlightedMemberId }: MembersStateModel): string {
    return highlightedMemberId;
  }

  @Action(MembersActions.UpdateSelectedMembers)
  updateSelectedMembers({ patchState }: StateContext<MembersStateModel>, { selectedMembers }: MembersActions.UpdateSelectedMembers) {
    patchState({
      selectedMembers: selectedMembers,
    });
  }

  @Action(MembersActions.ClearSelectedMembers)
  clearSelectedMembers({ patchState }: StateContext<MembersStateModel>) {
    patchState({
      selectedMembers: [],
    });
  }

  @Action(MembersActions.UpdateMemberFilters)
  updateMemberFilters({ patchState }: StateContext<MembersStateModel>, { filters }: MembersActions.UpdateMemberFilters) {
    patchState({
      memberFilters: filters,
    });
  }

  @Action(MembersActions.UpdateHighlightedMemberId)
  updateHighlightedMemberId(
    { patchState }: StateContext<MembersStateModel>,
    { highlightedMemberId }: MembersActions.UpdateHighlightedMemberId,
  ) {
    patchState({
      highlightedMemberId: highlightedMemberId,
    });
  }

  @Action(MembersActions.UpdateMemberPaginationFilters)
  updateMemberPaginationFilters({ patchState }: StateContext<MembersStateModel>, { filter }: MembersActions.UpdateMemberPaginationFilters) {
    patchState({
      memberPaginationFilter: filter,
    });
  }

  @Action(MembersActions.UpdateCardFilters)
  updateCardFilters({ getState, patchState }: StateContext<MembersStateModel>, { cardUid, filters }: MembersActions.UpdateCardFilters) {
    const currentFilters = getState().cardFilters;
    if (!currentFilters.map((f) => f.cardUid).includes(cardUid)) {
      patchState({
        cardFilters: [...currentFilters, { cardUid: cardUid, value: filters }],
      });
    } else {
      const updatedFilters = currentFilters.map((f) => (f.cardUid === cardUid ? { cardUid: cardUid, value: filters } : f));
      patchState({
        cardFilters: updatedFilters,
      });
    }
  }

  @Action(MembersActions.UpdateCardPaginationFilters)
  UpdateCardPaginationFilters(
    { getState, patchState }: StateContext<MembersStateModel>,
    { cardUid, filter }: MembersActions.UpdateCardPaginationFilters,
  ) {
    const currentFilters = getState().cardPaginationFilters;

    const existingFilterIndex = currentFilters.findIndex((f) => f.cardUid === cardUid);

    if (existingFilterIndex === -1) {
      patchState({
        cardPaginationFilters: [...currentFilters, { cardUid: cardUid, value: filter }],
      });
    } else {
      const updatedFilters = currentFilters.map((f) => (f.cardUid === cardUid ? { cardUid: cardUid, value: filter } : f));
      patchState({
        cardPaginationFilters: updatedFilters,
      });
    }
  }

  @Action(MembersActions.UpdatePlaylistFilters)
  updatePlaylistFilters(
    { getState, patchState }: StateContext<MembersStateModel>,
    { playlistUid, filters }: MembersActions.UpdatePlaylistFilters,
  ) {
    const currentFilters = getState().playlistFilters;
    if (!currentFilters.map((f) => f.playlistUid).includes(playlistUid)) {
      patchState({
        playlistFilters: [...currentFilters, { playlistUid: playlistUid, value: filters }],
      });
    } else {
      const updatedFilters = currentFilters.map((f) => (f.playlistUid === playlistUid ? { playlistUid: playlistUid, value: filters } : f));
      patchState({
        playlistFilters: updatedFilters,
      });
    }
  }

  @Action(MembersActions.UpdatePlaylistPaginationFilters)
  UpdatePlaylistPaginationFilters(
    { getState, patchState }: StateContext<MembersStateModel>,
    { playlistUid, filter }: MembersActions.UpdatePlaylistPaginationFilters,
  ) {
    const currentFilters = getState().playlistPaginationFilters;

    const existingFilterIndex = currentFilters.findIndex((f) => f.playlistUid === playlistUid);

    if (existingFilterIndex === -1) {
      patchState({
        playlistPaginationFilters: [...currentFilters, { playlistUid: playlistUid, value: filter }],
      });
    } else {
      const updatedFilters = currentFilters.map((f) => (f.playlistUid === playlistUid ? { playlistUid: playlistUid, value: filter } : f));
      patchState({
        playlistPaginationFilters: updatedFilters,
      });
    }
  }

  @Action(MembersActions.UpdatePlaylistReviewFilters)
  updatePlaylistReviewFilters(
    { getState, patchState }: StateContext<MembersStateModel>,
    { playlistUid, filters }: MembersActions.UpdatePlaylistReviewFilters,
  ) {
    const currentFilters = getState().playlistReviewFilters;
    if (!currentFilters.map((f) => f.playlistUid).includes(playlistUid)) {
      patchState({
        playlistReviewFilters: [...currentFilters, { playlistUid: playlistUid, value: filters }],
      });
    } else {
      const updatedFilters = currentFilters.map((f) => (f.playlistUid === playlistUid ? { playlistUid: playlistUid, value: filters } : f));
      patchState({
        playlistReviewFilters: updatedFilters,
      });
    }
  }

  @Action(MembersActions.UpdateCardReviewFilters)
  updateCardReviewFilters(
    { getState, patchState }: StateContext<MembersStateModel>,
    { cardUid, filters }: MembersActions.UpdateCardReviewFilters,
  ) {
    const currentFilters = getState().cardReviewFilters;
    if (!currentFilters.map((f) => f.cardUid).includes(cardUid)) {
      patchState({
        cardReviewFilters: [...currentFilters, { cardUid: cardUid, value: filters }],
      });
    } else {
      const updatedFilters = currentFilters.map((f) => (f.cardUid === cardUid ? { cardUid: cardUid, value: filters } : f));
      patchState({
        cardReviewFilters: updatedFilters,
      });
    }
  }
}
