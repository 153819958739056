/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { forkJoin } from 'rxjs';
import { Store } from '@ngxs/store';
import { FileUploadService } from '../../../../../../shared/services/file-upload/file-upload.service';
import { EditorContent, ImageUploadContent, MediaUploadData, SingleUrlContent } from '../../../../../../editor/models';
import { ResourceCardType, UpdateReportSectionResponse } from '../../../../../../shared/models';
import { FileUploadHelper } from '../../../../../../shared/helpers/file-upload-helper';
import { ObservableResult } from '../../../../../../shared/store';
import { INITIAL_STATE, ResourceAdminStateModel } from '../../../admin/resource-admin.state.model';
import * as ResourceAdminActions from '../../../admin/resource-admin.actions';

export class DynamicContentUploadHelper {
  /**
   * Uploads dynamic content to remote storage (AWS S3).
   * If the first dynamic content to upload is the first image in the main section,
   * then it is uploaded with 'open' access,
   * meaning that everyone can see it as it will appear in the thumbnail of the resource card.
   * All the other resource are uploaded as 'protected'
   * meaning that they can only be accessed when a secure URL is generated by the API.
   */
  static uploadDynamicContentFiles(state, enrichedContentFromBackend: UpdateReportSectionResponse, fileUploadService: FileUploadService) {
    const fileUploadObservables: ObservableResult<void>[] = [];
    const responseSectionsContents: EditorContent[][] = [];

    if (enrichedContentFromBackend.dynamicContent) {
      responseSectionsContents.push(enrichedContentFromBackend.dynamicContent);
    }

    if (enrichedContentFromBackend.learningGoalWrapper?.dynamicContent) {
      responseSectionsContents.push(enrichedContentFromBackend.learningGoalWrapper.dynamicContent);
    }

    if (enrichedContentFromBackend.learningGoalWrapper?.competencyLevels?.length) {
      let competencyLevel = enrichedContentFromBackend.learningGoalWrapper.competencyLevels[0];

      if (enrichedContentFromBackend.learningGoalWrapper.levelUidToUpdate) {
        competencyLevel = enrichedContentFromBackend.learningGoalWrapper.competencyLevels.find(
          (item) => item.levelUid === enrichedContentFromBackend.learningGoalWrapper.levelUidToUpdate,
        );
      }

      responseSectionsContents.push(competencyLevel.dynamicContent);
    }

    state.sections.forEach((section, sectionIndex) => {
      section.dynamicContent.forEach((item, index) => {
        if (item.type === 'MEDIA_UPLOAD' && (item as MediaUploadData).file) {
          const uploadedMedia = responseSectionsContents[sectionIndex][index] as MediaUploadData;
          const fullUrl = (uploadedMedia.content as SingleUrlContent).url ?? (uploadedMedia.content as ImageUploadContent).original;
          const file = (item as MediaUploadData).file;
          const uid = item.uuid4 ?? item.uid;
          fileUploadObservables.push(fileUploadService.uploadFile(FileUploadHelper.filePath(fullUrl), file, uid));
        }
      });
    });

    return forkJoin(fileUploadObservables);
  }

  static goBack(
    patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
    store: Store,
    resourceUri: string,
    cardType: ResourceCardType,
  ) {
    patchState({
      ...INITIAL_STATE,
    });
    store.dispatch(new ResourceAdminActions.RedirectToPlaylistOrResourcePage(resourceUri, cardType));
  }
}
