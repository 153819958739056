/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LtiCourseBadgeDataService } from '@app/app/page-modules/lti/services/badges/lti-course-badge-data.service';
import { BadgeIssuedReport, CourseBadge, CourseBadgeDetails, CourseBadgeRequest } from '@app/app/page-modules/lti/models/lti-course.model';
import { ObservableResult } from '@app/app/shared/store';
import { RestClientService } from '@app/app/shared/services/rest-client.service';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '@app/environments/environment';

@Injectable()
export class ApiLtiCourseBadgeDataService implements LtiCourseBadgeDataService {
  private readonly ltiCourseUrl = Location.joinWithSlash(environment.apiRootUrl || '', '/badges/courses');

  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  retrieveCourseBadge(courseUid: string): ObservableResult<CourseBadgeDetails> {
    return this.client.get<CourseBadgeDetails>(`${this.ltiCourseUrl}/${courseUid}`).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorSavingLtiCourseBadge'))),
    );
  }

  connectBadgeWithCourse(request: CourseBadgeRequest): ObservableResult<CourseBadge> {
    return this.client.post<CourseBadge>(this.ltiCourseUrl, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorSavingLtiCourseBadge'))),
    );
  }

  disconnectBadgeFromCourse(request: CourseBadgeRequest): ObservableResult<void> {
    return this.client.delete<void>(this.ltiCourseUrl, null, null, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorSavingLtiCourseBadge'))),
    );
  }

  updateCourseBadge(request: CourseBadgeRequest): ObservableResult<CourseBadge> {
    return this.client.put<CourseBadge>(this.ltiCourseUrl, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorSavingLtiCourseBadge'))),
    );
  }

  issueBadge(courseUid: string, users: string[]): ObservableResult<BadgeIssuedReport> {
    return this.client
      .post<BadgeIssuedReport>(`${this.ltiCourseUrl}/issue`, {
        courseUid: courseUid,
        awardedUsers: users,
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorSavingLtiCourseBadge'))),
      );
  }
}
