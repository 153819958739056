export const IMAGE_UPLOAD_DIALOG_CONFIG = {
  width: '47rem',
  height: '80vh',
  maxHeight: '80vh',
  position: {
    top: '10vh',
  },
  restoreFocus: true,
  panelClass: ['ptl-mat-dialog', 'image-upload-unsplash-dialog'],
  backdropClass: 'dialog-backdrop',
  data: {},
};
