<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="form-preview__update-footer">
  <div class="form-preview__form-info">
    <span *ngIf="submissionDate">
      {{ 'translations.cards.form.footer.updatedOn' | transloco }} {{ submissionDate | dateFormat: 'D MMMM y, HH:mm'
      }}{{ updateAdviceText ? '. ' : '' }}
    </span>

    <span *ngIf="updateAdviceText">
      {{ updateAdviceText }}
    </span>
  </div>

  <ptl-form-review-events-list
    *ngIf="learnerFormAnswer?.progressiveReviewEnabled"
    [reviewEvents]="learnerFormAnswer.reviewEvents"
    [playlistSubmissionDeadline]="playlistSubmissionDeadline"
  >
  </ptl-form-review-events-list>
</div>
