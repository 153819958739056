<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="audio-preview-element
  audio-preview-element-{{ content?.type }}
  audio-preview-element-position-{{ content?.position || 'CENTER' }}"
  *ngIf="content?.type === 'MEDIA_UPLOAD'"
  [ngClass]="'audio-preview-element--' + content?.type"
>
  <audio tabindex="0" class="audio-preview-element--audio" [src]="content?.content.url" controls></audio>
  <div class="audio-preview-element__caption-box" *ngIf="content?.caption">
    <p tabindex="0" class="caption-text">
      {{ content?.caption }}
    </p>
  </div>
</div>
