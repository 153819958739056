/*
 * Copyright (C) 2025 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { PlaylistViewState } from '@app/app/page-modules/playlist/store/view/playlist-view.state';
import { combineLatest, Observable } from 'rxjs';
import { LoadableState } from '@app/app/shared/store';
import { Organization, Playlist, PlaylistTargetGroup, PlaylistTargetSector, PlaylistTargetUser } from '@app/app/shared/models';
import { filter, map, takeUntil } from 'rxjs/operators';
import { PLAYLIST_TARGET_SECTOR_LIST } from '@app/app/page-modules/playlist/models/target-audience.model';
import { discoveryDemo } from '@app/app/shared/helpers/development-domains.helper';
import { UserAuthState } from '@app/app/user-auth/store/user-auth.state';
import { RedirectHelper } from '@app/app/page-modules/resource/store/editor/content/helpers/redirect.helper';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ptl-v3-target-audience-section',
  templateUrl: './v3-target-audience-section.component.html',
  styleUrls: ['./v3-target-audience-section.component.scss'],
})
export class V3TargetAudienceSectionComponent implements OnInit, OnDestroy {
  selectedOrganizationOnly: boolean;
  groups: PlaylistTargetGroup[] = [];
  members: PlaylistTargetUser[] = [];
  sectors: PlaylistTargetSector[] = [];
  playlist: Playlist;
  filteredSectors: PlaylistTargetSector[];
  selectedGroups = false;
  selectedMembers = false;
  selectedSectors = false;
  showOnlyOnJisc: boolean;
  organizationName: string;

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  @Select(PlaylistViewState.playlistState)
  private playlist$: Observable<LoadableState<Playlist>>;

  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    combineLatest([this.playlist$, this.organizationData$])
      .pipe(
        filter(([{ loading, data }, organization]) => !loading && !!data && !!organization),
        map(([playlist, organization]) => ({
          playlist: playlist.data,
          showOnlyOnJisc: discoveryDemo(organization.domain),
          organizationName: organization.name,
        })),
        takeUntil(this.subscriptionEnd$),
      )
      .subscribe(({ playlist, showOnlyOnJisc, organizationName }) => {
        const { target } = playlist.publicationSettings;
        const { groups, users, sectors } = target;

        this.playlist = playlist;
        this.members = users;
        this.groups = groups;
        this.filteredSectors = PLAYLIST_TARGET_SECTOR_LIST.filter((s) => sectors.every((t) => t !== s.code));
        this.sectors = sectors.map((s) => PLAYLIST_TARGET_SECTOR_LIST.find((t) => t.code === s));

        this.organizationName = organizationName;
        this.showOnlyOnJisc = showOnlyOnJisc;

        if (this.selectedOrganizationOnly === undefined) {
          this.selectedOrganizationOnly = !this.groups.length && !this.members.length && !this.sectors.length;

          if (this.groups.length) {
            this.selectedGroups = true;
          }
          if (this.members.length) {
            this.selectedMembers = true;
          }
          if (this.sectors.length && this.showOnlyOnJisc) {
            this.selectedSectors = true;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptionEnd$.emit();
  }

  openTargetAudienceTab() {
    const url = 'playlists/' + this.playlist.uri + '/target-audience';
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, url);
  }
}
