import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ptl-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QRCodeComponent implements OnInit {
  @Input() qrCodeData: string;
  @Input() qrCodeLabel: string;
  qrCode: string;
  ariaLabel: string;
  title: string;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit() {
    this.qrCode = this.qrCodeData;
    this.title = this.translocoService.translate('translations.qrcode.title');
    this.ariaLabel = this.qrCodeLabel ? this.translocoService.translate(this.qrCodeLabel) : this.title;
  }
}
