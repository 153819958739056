<mat-form-field class="user-search-input-field" appearance="outline">
  <svg
    matPrefix
    class="svg"
    role="img"
    *ngIf="showSearchIcon"
    [attr.aria-label]="'translations.global.ariaLabels.icons.search' | transloco"
  >
    <use ogSvgUnify="assets/sprite/sprite.svg#search"></use>
  </svg>
  <mat-label *ngIf="label && !placeholder">{{ 'translations.global.search.label.forMembers' | transloco }}</mat-label>
  <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
  <input
    id="userSearchInput"
    matInput
    [matAutocomplete]="auto"
    placeholder="{{ placeholder ? placeholder : ('translations.global.search.label.addMemberPlaceholder' | transloco) }}"
    (paste)="onTextPaste($event)"
    (keyup)="onSearchInputChange($event)"
  />

  <mat-autocomplete
    #auto="matAutocomplete"
    [panelWidth]="450"
    id="autocomplete-box"
    class="user-search-result-autocomplete"
    [displayWith]="disabledAutocomplete"
    [thresholdPercent]="0.9"
    (optionsScroll)="onAutocompleteScroll()"
    (optionSelected)="onMemberSelected($event)"
  >
    <mat-option *ngFor="let member of members" [value]="member" class="search-result-user-option">
      <div class="search-result-user-image">
        <img
          src="{{ member.image ? member.image : '../../../../assets/profile_image.png' }}"
          alt="{{ member.firstName }} {{ member.lastName }}"
        />
      </div>
      <div class="search-result-user-bio">
        <div class="search-result-user-name">{{ member.firstName }} {{ member.lastName }}</div>
        <div class="search-result-user-email" *ngIf="member.email">
          {{ member.email }}
        </div>
      </div>
      <div class="search-result-user-id" *ngIf="member.externalId">
        {{ member.externalId }}
      </div>
    </mat-option>
    <div class="loading-more-indicator" *ngIf="isLoadingMore">
      <mat-spinner class="loading-more-indicator__spinner" [diameter]="30"></mat-spinner>
    </div>
  </mat-autocomplete>

  <mat-icon aria-hidden="true" matSuffix class="flex">
    <mat-spinner *ngIf="loadingMembers" [diameter]="18"> </mat-spinner>
  </mat-icon>
</mat-form-field>
