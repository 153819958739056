/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { EMBEDS_CONTENT_GENERATOR_SERVICE, EmbedsContentGeneratorService } from '@app/app/editor/services/embeds-content-generator.service';
import { SpecialEmbedsHelper } from '../../helpers/embeds/special-embeds.helper';
import { EditorContent, ExternalContentSummary } from '../../models';
import { DEFAULT_URL_VALIDATION_PATTERN } from '../../constants/constants';

@Component({
  selector: 'ptl-link-dialog',
  templateUrl: './link-dialog.component.html',
  styleUrls: ['./link-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkDialogComponent implements AfterViewInit {
  mediaUrlData: FormGroup;
  canNotGetSummary = false;
  fetchingExternalContentSummary = false;
  triedOnce = false;

  @Output() contentCreated = new EventEmitter<EditorContent>();

  @ViewChild('urlInputField', { read: ElementRef }) urlInputField: ElementRef;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<LinkDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: { cropperShape: string; advancedImageCrop: boolean; resizeToHeight: number; aspectRatio: number },
    @Inject(EMBEDS_CONTENT_GENERATOR_SERVICE) private embedsService: EmbedsContentGeneratorService,
  ) {
    this.mediaUrlData = this.fb.group({
      contentUrl: ['', [Validators.required, Validators.pattern(DEFAULT_URL_VALIDATION_PATTERN)]],
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.urlInputField.nativeElement.focus();
    }, 50);
  }

  onLinkEnter(event: Event): void {
    event.preventDefault();
    this.triedOnce = true;
    if (this.urlIsInvalid) return;

    this.canNotGetSummary = false;
    if (this.mediaUrlData.valid) {
      const url = SpecialEmbedsHelper.correctUrl(this.mediaUrlData.get('contentUrl')?.value);
      this.fetchingExternalContentSummary = true;

      this.embedsService
        .retrieveEmbeddableContentDetails(encodeURI(url))
        .pipe(take(1))
        .subscribe(({ value }) => {
          if (value) {
            const content = {
              ...value,
              type: 'EXTERNAL_CONTENT_SUMMARY',
            } as unknown as ExternalContentSummary;

            this.dialogRef.close(content);
          } else {
            this.canNotGetSummary = true;
          }
          this.fetchingExternalContentSummary = false;
          this.cdr.detectChanges();
        });
    }
  }

  get urlIsInvalid(): boolean {
    return this.mediaUrlData.controls['contentUrl'].invalid;
  }

  detectChanges() {
    this.cdr.detectChanges();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
