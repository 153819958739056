import { Injectable } from '@angular/core';

@Injectable()
export class MaintenanceService {
  private showMaintenanceMessageOverlay = true;
  private showMaintenancePage = false;

  showMaintenanceMessage(): boolean {
    return this.showMaintenanceMessageOverlay;
  }

  enableMaintenanceMode(): boolean {
    return this.showMaintenancePage;
  }
}
