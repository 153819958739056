/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ResourceAuthorDetails } from '../../models';
import { ActivatedRoute, Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { PageContentStoreSummary } from '../../models/content-store/content-store.model';
import { CardClickData } from '../../models/page/page.model';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';

@Component({
  selector: 'ptl-store-pages-card',
  templateUrl: './store-pages-card.component.html',
  styleUrls: ['./store-pages-card.component.scss'],
})
export class StorePagesCardComponent implements OnInit {
  @Input() type: 'VIEW' | 'EDIT';
  /** Card data */
  @Input() card: PageContentStoreSummary;
  /** Card index in cards data which was got from API. */
  @Input() index: number;
  /** Cards layout type. */
  @Input() inHomeLayoutEditor: boolean;

  @Input() isAdmin: boolean;

  @Output() cardClick = new EventEmitter<CardClickData>();

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };

  authorDetails: ResourceAuthorDetails;
  imageUrl: string;
  orgName: string;
  authorNameFirstChar: string;
  hasAuthorImage: boolean;

  constructor(
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.card && this.card.authorDetails) {
      this.authorDetails = this.card.authorDetails;
      this.imageUrl = this.getImageUrl();
      this.authorNameFirstChar = this.getAuthorNameFirstChar();
      this.hasAuthorImage = this.checkIfImageExists();
      this.orgName = this.getOrgName();
    }
  }

  onCardClick() {
    if (this.inHomeLayoutEditor) {
      return;
    }
    this.cardClick.emit({
      playlistUri: '',
      uri: this.card.uri,
      _id: this.card._id,
      pageUid: this.card._id,
      formattedUri: this.card.formattedUri,
    });
  }

  onContextMenu(event: MouseEvent) {
    if (this.card.uri) {
      event.preventDefault();
      const target = event.currentTarget as HTMLElement;
      const rect = target.getBoundingClientRect();
      this.contextMenuPosition.x = event.clientX - rect.x + 'px';
      this.contextMenuPosition.y = event.clientY - rect.y + 'px';
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
  }

  onOpenInNewTab() {
    if (this.card) {
      RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute, { pageUri: this.card.uri }, 'PAGE', '_blank');
    }
  }

  private getOrgName(): string {
    if (this.authorDetails.displayConfig.organizationName) {
      const orgName = this.authorDetails.organization;
      const changedOrgName = this.authorDetails.displayConfig.externalAuthor?.name;
      return changedOrgName ? changedOrgName : orgName;
    } else {
      return this.authorDetails.name;
    }
  }

  private checkIfImageExists() {
    if (this.authorDetails.userImageUrl && this.authorDetails.displayConfig.authorName) {
      return true;
    } else if (this.authorDetails.displayConfig.organizationName) {
      return true;
    }
    return false;
  }

  private getOrgImageUrl(): string {
    const orgImageUrl = this.authorDetails.orgLogoUrl;
    const uploadedOrgImageUrl = this.authorDetails.displayConfig.externalAuthor?.image;

    return uploadedOrgImageUrl ? uploadedOrgImageUrl : orgImageUrl;
  }

  private getImageUrl(): string {
    return this.authorDetails.displayConfig.organizationName ? this.getOrgImageUrl() : this.authorDetails.userImageUrl;
  }

  private getAuthorNameFirstChar(): string {
    return this.authorDetails.name.charAt(0);
  }
}
