/* eslint-disable max-len */
import { ChangeDetectorRef, Component, Inject, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { RefreshStateAfterFormSubmission } from '@app/app/page-modules/resource/store/learner-view.actions';
import { formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LearnerViewState } from '@app/app/page-modules/resource/store/learner-view.state';
import { takeUntil, take, filter } from 'rxjs/operators';
import * as PlaylistViewActions from '@app/app/page-modules/playlist/store/view/playlist-view.state.actions';
import { CollectorFormContent, EditorContent, Form, Organization, UserPlaylistSubmissionSummary } from '@app/app/shared/models';
import {
  CollectorConfig,
  LearnerCollectorSummary,
  LearnerLoggedItem,
  LearnerLoggedItemRequest,
  LearnerLoggedItemRequestTimeLogPeriod,
} from '@app/app/shared/models/editor/collector-content.model';
import { UserAuthState } from '@app/app/user-auth/store/user-auth.state';
import { COLLECTOR_DATA_SERVICE, CollectorDataService } from '@app/app/shared/services/collector/collector-data.service';
import { TranslocoService } from '@ngneat/transloco';
import { DialogService } from '@app/app/shared/helpers/dialog/dialog.service';
import { AddFromMomentsDialogComponent } from '@app/app/shared/components/resource-preview/form-preview/form-collector-preview/add-from-moments-dialog/add-from-moments-dialog.component';
import { SnackbarHelper } from '@app/app/shared/helpers/snackbar-helper';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { PlaylistViewState } from '@app/app/page-modules/playlist/store/view/playlist-view.state';
import { LearnerFormAnswer } from '@app/app/page-modules/resource/models';
import { ContentHelper } from '@app/app/shared/helpers/content-helper';
import { SubmitPlaylistReviewDialogComponent } from 'src/app/page-modules/playlist/shared/view/playlist-action-bar/submit-dialog/submit-dialog.component';
import { LEARNER_VIEW_DATA_SERVICE, LearnerViewDataService } from 'src/app/page-modules/resource/services/data.service';

/* eslint-enable max-len */

@Component({
  selector: 'ptl-collector-preview',
  templateUrl: './form-collector-preview.component.html',
  styleUrls: ['./form-collector-preview.component.scss'],
})
export class FormCollectorPreviewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formData: Form;
  @Input() learnerFormAnswer: LearnerFormAnswer;
  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  @Input() isProjectResource: boolean;
  @Input() playlistUri: string;
  @Input() playlistTitle: string;
  @Input() resourceUri: string;
  @Input() resourceUid: string;
  @Input() publisherUri: string;
  @Input() packageUri: string;
  @Input() pageUri: string;
  @Input() languageCode: string;

  learnerCollectorSummary: LearnerCollectorSummary;
  title: string;
  instruction: EditorContent[] = [];
  expanded = false;
  organization: Organization;
  currentLanguage: string;
  canEdit = false;
  canNotEditReason: string;
  requiresResubmission = false;
  submissionInProgress = false;
  validationInProgress = false;
  validationProcessed = false;
  requiredTable = {
    totalCount: undefined,
    completedCount: undefined,
    remainingCount: undefined,
    type: undefined,
  };

  @Select(UserAuthState.organizationDetails)
  private organization$: Observable<Organization>;

  @Select(LearnerViewState.currentLanguage)
  private currentLanguage$: Observable<string>;

  @Select(PlaylistViewState.playlistId)
  private playlistId$: Observable<string>;

  private collectorId: string;
  private playlistUid: string;
  private subscriptionEnd$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private store: Store,
    private snackBar: MatSnackBar,
    @Inject(COLLECTOR_DATA_SERVICE) private collectorDataService: CollectorDataService,
    @Inject(LEARNER_VIEW_DATA_SERVICE) private dataService: LearnerViewDataService,
    private translocoService: TranslocoService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.organization$.pipe(take(1)).subscribe((org) => (this.organization = org));

    this.playlistId$
      .pipe(
        takeUntil(this.subscriptionEnd$),
        filter((playlistId) => !!playlistId),
      )
      .subscribe((playlistId: string) => {
        this.playlistUid = playlistId;
        if (this.formData && this.formData.content) {
          const collectorContent = this.formData.content as CollectorFormContent;
          this.collectorId = collectorContent.collectorId;
          this.getCollectorSummary(playlistId, collectorContent.collectorId);
        }
      });
    this.checkSubmissionMode();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.playlistUid && this.resourceUid && this.formData?.uid) {
      this.checkSubmissionMode();
    } else {
      if (changes.learnerFormAnswer || changes.userPlaylistSubmissionSummary) {
        this.checkSubmissionMode();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptionEnd$.next();
  }

  addEditLogItem(loggedItem?: LearnerLoggedItem): void {
    const dialogRef = this.dialog.open(AddFromMomentsDialogComponent, {
      width: '75rem',
      maxHeight: '80vh',
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      restoreFocus: true,
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
      data: {
        config: this.learnerCollectorSummary.config,
        summary: this.learnerCollectorSummary,
        loggedItem: loggedItem ? loggedItem : undefined,
      },
    });

    dialogRef.afterClosed().subscribe((data: LearnerLoggedItemRequest) => {
      if (data) {
        if (loggedItem) {
          this.updateExistingLogItem(data, loggedItem);
        } else {
          this.addNewLogItem(data);
        }
      }
    });
  }

  editLoggedItem(loggedItem: LearnerLoggedItem): void {
    this.addEditLogItem(loggedItem);
  }

  openDeleteLogItemPopUp(loggedItem: LearnerLoggedItem): void {
    this.dialogService
      .showConfirmDialog(this.translocoService.translate('translations.dialog.title.removeLog'), this.translocoService)
      .then((confirmed) => {
        if (confirmed) {
          this.deleteLoggedItem(loggedItem);
        }
      });
  }

  deleteLoggedItem(loggedItem: LearnerLoggedItem): void {
    this.collectorDataService.deleteLogItem(this.learnerCollectorSummary.learnerCollectorUid, loggedItem.uid).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        this.learnerCollectorSummary.loggedItems = this.learnerCollectorSummary.loggedItems.filter((item) => item.uid !== loggedItem.uid);
        this.updateLearnerCollectorSummary();
      }
    });
  }

  submit(): void {
    if (ContentHelper.isProgressiveSubmissionMode(this.userPlaylistSubmissionSummary)) {
      const dialogMessage =
        this.userPlaylistSubmissionSummary?.userReviewSummary?.reviewType &&
        this.userPlaylistSubmissionSummary.userReviewSummary.reviewType !== 'AUTOMATIC'
          ? this.translocoService.translate('translations.dialog.title.formSubmissionReviewEnabled')
          : this.translocoService.translate('translations.dialog.title.formSubmission');
      this.dialogService.showConfirmDialog(dialogMessage, this.translocoService).then((confirmed) => {
        if (confirmed) {
          this.processSubmit();
        }
      });
    } else {
      this.processSubmit();
    }
  }

  processSubmit(): void {
    if (ContentHelper.shouldDisplayReviewersDialogOnFormSubmission(this.userPlaylistSubmissionSummary)) {
      this.openReviewersDialog();
    } else {
      this.submitCollector();
    }
  }

  submitCollector(): void {
    this.submissionInProgress = true;
    this.checkSubmissionMode();
    this.collectorDataService
      .submitLearnerCollectorSummary(this.playlistUid, this.learnerCollectorSummary.learnerCollectorUid)
      .subscribe(({ isSuccess }) => {
        if (isSuccess) {
          this.refreshStateAndFinishSubmission();
        }
      });
  }

  expandCollectorInfo(newValue: boolean): void {
    this.expanded = newValue;
  }

  get isSubmissionDisabled(): boolean {
    return !this.learnerCollectorSummary?.isReadyToSubmit || this.submissionInProgress;
  }

  private refreshStateAndFinishSubmission() {
    this.learnerCollectorSummary.status = 'COMPLETED';
    this.store.dispatch(new PlaylistViewActions.RefreshUserPlaylistSubmissionSummary(this.playlistUid));
    this.dataService
      .getCardWithProgress(
        this.isProjectResource,
        this.playlistUri,
        this.resourceUri,
        this.publisherUri,
        this.packageUri,
        this.pageUri,
        this.languageCode,
      )
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.store.dispatch(new RefreshStateAfterFormSubmission(value));
          this.finishSubmission();
        }
      });
  }

  private openReviewersDialog() {
    const dialogRef = this.dialog.open(SubmitPlaylistReviewDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '46.875rem',
      maxHeight: '33.75rem',
      restoreFocus: true,
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
      data: {
        isProjectResource: this.isProjectResource,
        playlistUid: this.playlistUid,
        playlistTitle: this.playlistTitle,
        userPlaylistSubmissionSummary: this.userPlaylistSubmissionSummary,
        mode: 'COLLECTOR',
        resourceUid: this.resourceUid,
        learnerCollectorUid: this.learnerCollectorSummary.learnerCollectorUid,
        playlistUri: this.playlistUri,
        resourceUri: this.resourceUri,
        publisherUri: this.publisherUri,
        packageUri: this.packageUri,
        pageUri: this.pageUri,
        languageCode: this.languageCode,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.finishSubmission();
      }
    });
  }

  private finishSubmission(): void {
    this.expanded = false;
    this.learnerCollectorSummary.status = 'COMPLETED';
    this.submissionInProgress = false;
    this.checkSubmissionMode();
    this.cd.detectChanges();
  }

  private getDateDisplayString(submittedTimeLogPeriod: LearnerLoggedItemRequestTimeLogPeriod): string {
    let result = '-- to --';

    if (submittedTimeLogPeriod && submittedTimeLogPeriod.from && submittedTimeLogPeriod.to) {
      result =
        formatDate(submittedTimeLogPeriod.from, 'yyyy MMMM dd', 'en') +
        ' to ' +
        formatDate(submittedTimeLogPeriod.to, 'yyyy MMMM dd', 'en');
    }

    return result;
  }

  private addNewLogItem(data: LearnerLoggedItemRequest): void {
    this.collectorDataService
      .addLogItem(this.learnerCollectorSummary.learnerCollectorUid, data)
      .subscribe(({ isSuccess, value, error }) => {
        if (isSuccess) {
          this.learnerCollectorSummary.loggedItems.push(this.getSummaryFormData(value));
          this.updateLearnerCollectorSummary();
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      });
  }

  private updateExistingLogItem(data: LearnerLoggedItemRequest, loggedItem: LearnerLoggedItem): void {
    this.collectorDataService
      .updateLogItem(this.learnerCollectorSummary.learnerCollectorUid, loggedItem.uid, data)
      .subscribe(({ isSuccess, value, error }) => {
        if (isSuccess) {
          this.learnerCollectorSummary.loggedItems = this.learnerCollectorSummary.loggedItems.map((item) => {
            if (item.uid === loggedItem.uid) {
              return this.getSummaryFormData(value);
            }
            return item;
          });
          this.updateLearnerCollectorSummary();
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      });
  }

  private getCollectorSummary(playlistUid: string, collectorId: string): void {
    this.currentLanguage$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((lang) => {
      this.currentLanguage = lang;

      this.collectorDataService
        .getLearnerCollectorSummary(playlistUid, collectorId, this.currentLanguage)
        .subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            this.title = LanguageCodeHelper.getDataByUserLanguageCode(value.title, this.organization, this.currentLanguage).value;
            this.instruction = value.instructions;
            this.learnerCollectorSummary = this.getSummaryFormattedData(value);
            if (!this.learnerCollectorSummary.loggedItems.length) {
              this.expanded = true;
            }
            this.updateRequiredCountsData();
            this.checkSubmissionMode();
          }
        });
    });
  }

  private updateLearnerCollectorSummary(): void {
    this.collectorDataService
      .getLearnerCollectorSummary(this.playlistUid, this.collectorId, this.currentLanguage)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.learnerCollectorSummary = this.getSummaryFormattedData(value);
          this.updateRequiredCountsData();
          if (this.learnerCollectorSummary.status === 'IN_PROGRESS') {
            this.expanded = true;
          }
          this.cd.detectChanges();
        }
      });
  }

  private getSummaryFormattedData(data: LearnerCollectorSummary): LearnerCollectorSummary {
    for (const logItem of data.loggedItems) {
      if (logItem.submittedTimeLogPeriod) {
        logItem.submittedTimeLogPeriod.formattedDate = this.getDateDisplayString(logItem.submittedTimeLogPeriod);
      }
    }
    return data;
  }

  private getSummaryFormData(loggedItem: LearnerLoggedItem): LearnerLoggedItem {
    if (loggedItem.submittedTimeLogPeriod) {
      loggedItem.submittedTimeLogPeriod.formattedDate = this.getDateDisplayString(loggedItem.submittedTimeLogPeriod);
    }

    return loggedItem;
  }

  private updateRequiredCountsData(): void {
    const collectorConfig = this.learnerCollectorSummary.config;
    const logType = collectorConfig.type;
    const completedCount = logType === 'TIME_LOG' ? this.completedHours() : this.completedCounts();
    const remainingCount =
      logType === 'TIME_LOG'
        ? this.remainingHours(collectorConfig, completedCount)
        : this.remainingMoments(collectorConfig, completedCount);

    this.requiredTable = {
      totalCount: collectorConfig.requiredNumberOfLogs,
      completedCount: completedCount,
      remainingCount: remainingCount,
      type: logType,
    };
  }

  private completedHours(): number {
    let completedHours = 0;
    for (const item of this.learnerCollectorSummary.loggedItems) {
      if (item.submittedTimeLogPeriod) {
        completedHours += item.submittedTimeLogPeriod.time ? item.submittedTimeLogPeriod.time : 0;
      }
    }
    return completedHours;
  }

  private completedCounts(): number {
    let completedCounts = 0;
    for (const item of this.learnerCollectorSummary.loggedItems) {
      if (item.moments && item.moments.length) {
        completedCounts += item.moments.length;
      }
      if (item.answers && item.answers.length) {
        completedCounts++;
      }
    }
    return completedCounts;
  }

  private remainingHours(collectorConfig: CollectorConfig, completedHours: number): number {
    if (completedHours) {
      const hours = collectorConfig.requiredNumberOfLogs - completedHours;
      return hours < 0 ? 0 : hours;
    }
    return collectorConfig.requiredNumberOfLogs ? collectorConfig.requiredNumberOfLogs : 0;
  }

  private remainingMoments(collectorConfig: CollectorConfig, completedCount: number): number {
    if (completedCount) {
      const count = collectorConfig.requiredNumberOfLogs - completedCount;
      return count < 0 ? 0 : count;
    }
    return collectorConfig.requiredNumberOfLogs ? collectorConfig.requiredNumberOfLogs : 0;
  }

  private checkSubmissionMode(): void {
    if (!this.learnerFormAnswer) {
      if (!this.playlistUid || !this.resourceUid || !this.formData?.uid) {
        return;
      }
      if (!this.validationInProgress && !this.validationProcessed) {
        this.validationInProgress = true;
        this.dataService.validateFormUpdate(this.playlistUid, this.resourceUid, this.formData?.uid).subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            this.canEdit = value.canBeUpdated;
            this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translocoService, value);
            this.requiresResubmission = false;
            this.validationProcessed = true;
          }
          this.checkSubmissionLocked();
          this.cd.detectChanges();
          this.validationInProgress = false;
        });
      }
    } else {
      this.canEdit = this.learnerFormAnswer?.updatePermission?.canBeUpdated;
      this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translocoService, this.learnerFormAnswer?.updatePermission);
      this.requiresResubmission = this.learnerFormAnswer?.requiresResubmission;
      this.checkSubmissionLocked();
      this.cd.detectChanges();
    }
  }

  private checkSubmissionLocked(): void {
    if (this.userPlaylistSubmissionSummary?.submissionsLocked) {
      this.canEdit = false;
      this.expanded = false;
      this.canNotEditReason = this.translocoService.translate('translations.formUpdateDisabledReasons.submissionClosed');
      if (this.learnerCollectorSummary) {
        this.learnerCollectorSummary.isReadyToSubmit = false;
      }
      this.requiresResubmission = false;
    }
  }
}
