import { HttpClient } from '@angular/common/http';
import { TRANSLOCO_LOADER, TranslocoLoader, TRANSLOCO_CONFIG, translocoConfig, TranslocoModule } from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { EnvironmentCode } from '../../environments/environment.model';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE_CODE, FALLBACK_LANGUAGE_CODE } from '../shared/services/languages/language.service';
import { from, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return from(import(`../../locale/messages.${lang}.json`)).pipe(map((res) => res.default));
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: AVAILABLE_LANGUAGES,
        defaultLang: DEFAULT_LANGUAGE_CODE,
        fallbackLang: FALLBACK_LANGUAGE_CODE,
        missingHandler: {
          useFallbackTranslation: true,
        },
        // Remove this option if your application
        // doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment[EnvironmentCode.prod],
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
