/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ObservableResult } from '../../store';
import { RestClientService } from '../rest-client.service';
import { FileUploadService } from './file-upload.service';
import { TranslocoService } from '@ngneat/transloco';
import { HttpEvent, HttpEventType, HttpResponse, HttpUploadProgressEvent } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { FileNameWithUploadProgress, FileUploadPresignedUrlRequest, FileUploadPresignedUrlResponse } from './file-upload.model';
import { getFileName } from '@app/app/shared/helpers/editor-transformer';

export class AwsFileUploadService implements FileUploadService {
  private uploadProgress = new Subject<FileNameWithUploadProgress[]>();
  private uploadedFiles: FileNameWithUploadProgress[] = [];
  castUploadProgress: Observable<FileNameWithUploadProgress[]>;

  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {
    this.castUploadProgress = this.uploadProgress.asObservable();
  }

  /**
   * @deprecated It uses endpoint which allows to upload anything to any directory – this endpoint should be removed
   */
  uploadFile(fileUrl: string, file: File, uuid4: string = '', withRaport: boolean = true): ObservableResult<void> {
    const apiPath = Location.joinWithSlash(environment.apiRootUrl || '', 'cards/presign');
    const request: FileUploadPresignedUrlRequest = {
      fileName: fileUrl,
      contentLength: file.size.toString(),
    };
    return this.client.post<FileUploadPresignedUrlResponse>(apiPath, request).pipe(
      switchMap(({ body }) =>
        withRaport
          ? this.uploadFileDirectly(body.presignedUploadUrl, file as File, uuid4, true)
          : this.uploadFileDirectlyWithoutRaport(body.presignedUploadUrl, file as File, true),
      ),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorSavingImage'))),
    );
  }

  uploadFileDirectly(presignedUploadUrl: string, file: File, uuid4: string = '', waitForOptimizer: boolean): ObservableResult<void> {
    const uniqueFileName = getFileName(presignedUploadUrl);
    this.uploadedFiles.push({ uniqueFileName: uniqueFileName, uploadedProgress: 0, uuid4: uuid4 });

    return this.client
      .putWithReportProgress<void>(presignedUploadUrl, file, {}, { 'cache-control': 'public, max-age=31536000' }, null, true)
      .pipe(
        map((event: HttpEvent<HttpUploadProgressEvent>) => {
          if (event.type === HttpEventType.UploadProgress && event.total) {
            const uploadedProgress = Math.round((100 * event.loaded) / event.total);
            const currentFile = this.uploadedFiles.find((uploadedFile) => uploadedFile.uniqueFileName === uniqueFileName);
            currentFile.uploadedProgress = uploadedProgress;
            this.uploadProgress.next(this.uploadedFiles);
          } else if (event instanceof HttpResponse) {
            this.uploadedFiles = this.uploadedFiles.filter((uploadedFile) => uploadedFile.uploadedProgress !== 100);
          }
        }),
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorSavingImage'))),
        delay(waitForOptimizer ? 1500 : 0), // optimizer is uploading other image sizes
      );
  }

  uploadFileDirectlyWithoutRaport(presignedUploadUrl: string, file: File, waitForOptimizer: boolean): ObservableResult<void> {
    return this.client.put<void>(presignedUploadUrl, file, {}, { 'cache-control': 'public, max-age=31536000' }).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorSavingImage'))),
      delay(waitForOptimizer ? 1500 : 0), // optimizer is uploading other image sizes
    );
  }
}
