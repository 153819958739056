<div>
  <div class="container">
    <div class="text-center">
      <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
        <li [ngClass]="{ disabled: pager.currentPageIndex === pager.firstPage }">
          <a
            role="button"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.goToPreviousPage' | transloco"
            (click)="setPage(pager.currentPageIndex - 1)"
            (keydown.enter)="setPage(pager.currentPageIndex - 1)"
          >
            <mat-icon aria-hidden="true" class="icon pagination-icon">chevron_left</mat-icon>
          </a>
        </li>
        <li *ngIf="pager.dots.beginning">
          <a
            role="button"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.goToPage' | transloco: { pageNumber: 1 }"
            (click)="setPage(isZeroBasedIndex ? 0 : 1)"
            (keydown.enter)="setPage(isZeroBasedIndex ? 0 : 1)"
            >1</a
          >
        </li>
        <li *ngIf="pager.dots.beginning" class="no-clicable">
          <span>...</span>
        </li>
        <li *ngFor="let page of pager.pages" [ngClass]="{ active: pager.currentPageIndex === page }">
          <a
            role="button"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.goToPage' | transloco: { pageNumber: isZeroBasedIndex ? page + 1 : page }"
            (click)="setPage(page)"
            (keydown.enter)="setPage(page)"
            >{{ isZeroBasedIndex ? page + 1 : page }}</a
          >
        </li>
        <li *ngIf="pager.dots.end" class="no-clicable">
          <span>...</span>
        </li>
        <li *ngIf="pager.dots.end">
          <a
            role="button"
            tabindex="0"
            [attr.aria-label]="
              'translations.global.ariaLabels.goToPage' | transloco: { pageNumber: isZeroBasedIndex ? pager.lastPage + 1 : pager.lastPage }
            "
            (click)="setPage(pager.lastPage)"
            (keydown.enter)="setPage(pager.lastPage)"
            >{{ isZeroBasedIndex ? pager.lastPage + 1 : pager.lastPage }}</a
          >
        </li>
        <li [ngClass]="{ disabled: pager.currentPageIndex === pager.totalPageCount }">
          <a
            role="button"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.goToNextPage' | transloco"
            (click)="setPage(pager.currentPageIndex + 1)"
            (keydown.enter)="setPage(pager.currentPageIndex + 1)"
          >
            <mat-icon aria-hidden="true" class="icon pagination-icon">chevron_right</mat-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
