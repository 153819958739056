<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="" [attr.aria-labelledby]="multipleChoice ? 'choiceQuestionAriaMulti' : 'choiceQuestionAria'">
  <div *ngIf="!multipleChoice" class="question-aria" id="choiceQuestionAria">
    {{ 'translations.choiceQuestionAria' | transloco }}
  </div>
  <div *ngIf="multipleChoice" class="question-aria" id="choiceQuestionAriaMulti">
    {{ 'translations.choiceQuestionAriaMulti' | transloco }}
  </div>

  <ptl-resource-preview class="question-description" [previewContent]="[{ type: 'PARAGRAPH', content: instruction, position: 'QUIZ' }]">
  </ptl-resource-preview>
  <div class="question-card__options">
    <div class="question-card__option-container">
      <div
        tabindex="0"
        class="question-card__option"
        [class.question-card__option--selected]="option.uid === answer?.uid"
        *ngFor="let option of question?.options; index as i"
        [attr.aria-label]="
          option.uid === answer?.uid
            ? ('translations.global.ariaLabels.questionSelectedOption'
              | transloco: { number: i + 1, name: getTranslatedValue(option.values) })
            : ('translations.global.ariaLabels.questionOption' | transloco: { number: i + 1, name: getTranslatedValue(option.values) })
        "
        (click)="selectAnswer(option)"
        (keydown.space)="selectAnswer(option)"
        (keydown.enter)="selectAnswer(option)"
      >
        <div class="option-text">
          {{ getTranslatedValue(option.values) }}
        </div>
      </div>
    </div>

    <ng-container *ngIf="answer && answer.instructions?.length && answer.instructions[0].content !== ''">
      <div class="question-card__option-info" [ngClass]="{ 'fade-in': fadeOut }">
        <ptl-resource-preview class="thin-preview" [previewContent]="answer.instructions"></ptl-resource-preview>
      </div>
    </ng-container>
  </div>
</div>
