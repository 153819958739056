/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Breadcrumb, PlaylistCardShort } from '../../models';
import { CategoryItem } from '../../models/page/page.model';
import { Params } from '@angular/router';

export class SetBreadcrumbs {
  static readonly type = '[Breadcrumb] Set breadcrumbs';

  constructor(
    public breadcrumbs: Breadcrumb[],
    public markCategoriesAsNavigated?: boolean,
  ) {}
}

export class ClearAllBreadcrumbsStartFromIndex {
  static readonly type = '[Breadcrumb] Remove all breadcrumbs starts from index';

  constructor(public index: number) {}
}

export class AddBreadcrumb {
  static readonly type = '[Breadcrumb] Add a breadcrumb';

  constructor(
    public breadcrumb: Breadcrumb,
    public categories?: CategoryItem[],
    public params?: Params,
  ) {}
}

export class AddPlaylistBreadcrumb {
  static readonly type = '[Breadcrumb] Add a playlist breadcrumb';

  constructor(
    public breadcrumb: Breadcrumb,
    public cards: PlaylistCardShort[],
    public currentPlaylistUrl: string,
  ) {}
}

export class ActivateBreadcrumbLoader {
  static readonly type = '[Breadcrumb] activate breadcrumb loading';

  constructor(public active: boolean) {}
}

export class PopBreadcrumbsByCurrentResourceUrl {
  static readonly type = '[Breadcrumb] Pop the resource breadcrumbs by current URL';

  constructor(public currentResourceUrl: string) {}
}

export class PopBreadcrumb {
  static readonly type = '[Breadcrumb] Pop last breadcrumb';
}

export class ClearAllBreadcrumbs {
  static readonly type = '[Breadcrumb] Remove all breadcrumbs';
}

export class GetParentPlaylistBreadcrumbData {
  static readonly type = '[Breadcrumb] Get parent playlist breadcrumb from URI';

  constructor(
    public isProject: boolean,
    public playlistUri: string,
    public loadOnlyCards?: boolean,
  ) {}
}
