/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UserAuthState } from '../../store/user-auth.state';
import { Observable } from 'rxjs';
import { Organization } from '../../../shared/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AUTH_SERVICE, BasicAuthService } from '../../services/basic-auth.service';
import { SnackbarHelper } from '../../../shared/helpers/snackbar-helper';
import { TranslocoService } from '@ngneat/transloco';
import { InvisibleReCaptchaComponent, ReCaptchaV3Service, ScriptService } from 'ngx-captcha';
import { environment } from '../../../../environments/environment';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { MaintenanceService } from '../../../shared/services/maintenance/maintenance.service';

@Component({
  templateUrl: './unverified-email.component.html',
  styleUrls: ['./unverified-email.component.scss'],
})
export class UnverifiedEmailComponent implements OnInit {
  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;

  emailForm: FormGroup;
  isLoading: boolean;
  isRegistered: boolean;
  email: string;
  reCaptchaElement = null;
  reCaptchaSiteKeyV2 = environment.reCaptchaSiteKeyV2;
  showMaintenanceOverlay: boolean;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    @Inject(AUTH_SERVICE) private authService: BasicAuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private translocoService: TranslocoService,
    private cdr: ChangeDetectorRef,
    private captchaService: ReCaptchaV3Service,
    private captchaScriptService: ScriptService,
    private maintenanceService: MaintenanceService,
  ) {
    this.showMaintenanceOverlay = this.maintenanceService.showMaintenanceMessage();
    this.emailForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  ngOnInit() {
    this.email = this.activatedRoute.snapshot.queryParamMap.get('email');
    this.isRegistered = !!this.activatedRoute.snapshot.queryParamMap.get('register') && !!this.email;
    if (this.email) {
      this.emailForm.setValue({ email: this.email });
    }
  }

  onLoginClick() {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/signin');
  }

  resendEmailVerification() {
    if (this.emailForm.valid) {
      this.captchaElem.execute();
    }
  }

  resetCaptcha(): void {
    this.cdr.detectChanges();
  }

  proceedIfValid(captchaResponseV2: string) {
    this.proceedResend(captchaResponseV2);
  }

  private captchaCleanup() {
    this.captchaScriptService.cleanup();
    this.captchaElem.resetCaptcha();
  }

  private proceedResend(captchaResponseV2: string) {
    this.isLoading = true;
    this.captchaScriptService.cleanup();
    const email = this.emailForm.get('email').value?.trim();
    const organizationUid = this.store.selectSnapshot(UserAuthState.organizationDetails)._id;
    this.captchaService.execute(environment.reCaptchaSiteKeyV3, 'forgotPassword', (captchaResponseV3: string) => {
      this.authService
        .resendVerificationEmail(email, organizationUid, captchaResponseV3, captchaResponseV2)
        .subscribe(({ isSuccess, error }) => {
          this.isLoading = false;
          this.captchaCleanup();
          if (isSuccess) {
            RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/signin');
            SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translocoService, 'translations.verifyYourAccount', 0);
          } else {
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
          }
        });
    });
  }
}
