/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import {
  CreateOrganizationRoleRequest,
  OrganizationPrivilege,
  OrganizationRoles,
  UpdateOrganizationRoleRequest,
} from '../../../../shared/models/organization';
import { RolesDataService } from './roles-data.service';
import { ObservableResult } from '../../../../shared/store';
import { UserRoleType } from '../../../../user-auth/models';
import { Page } from '../../../../shared/models/page';
import { Members } from '../../../../shared/models/admin/members.model';
import { DEFAULT_LANGUAGE_CODE } from '../../../../shared/services/languages/language.service';

@Injectable()
export class MockRolesDataService implements RolesDataService {
  readonly organizationRoles: OrganizationRoles[] = [
    {
      type: 'SUPER_ADMIN',
      _id: '123123',
      organizationUid: 'organizationUid',
      privileges: [],
      name: [{ languageCode: DEFAULT_LANGUAGE_CODE, value: 'name' }],
    },
    {
      type: 'ADMIN',
      _id: '123123',
      organizationUid: 'organizationUid',
      privileges: [],
      name: [{ languageCode: DEFAULT_LANGUAGE_CODE, value: 'name' }],
    },
  ];

  readonly organizationRolePrivilege: OrganizationPrivilege[] = [
    {
      _id: 'SCg8GfOHeS',
      name: 'can_add_member_to_any_playlist',
      label: [
        {
          languageCode: DEFAULT_LANGUAGE_CODE,
          value: 'Force-add members to any playlist',
        },
      ],
      privilegeGroup: 'ADMIN',
      assigned: false,
    },
    {
      _id: 'OVrMfYlaHB',
      name: 'can_edit_card_requirements',
      label: [
        {
          languageCode: DEFAULT_LANGUAGE_CODE,
          value: 'Edit card requirements',
        },
      ],
      privilegeGroup: 'CREATOR',
      assigned: true,
    },
  ];

  loadOrganizationRoles(organizationUid: string): ObservableResult<OrganizationRoles[]> {
    return ObservableResult.ofSuccess(this.organizationRoles);
  }

  loadOrganizationRoleById(roleUid: string): ObservableResult<OrganizationRoles> {
    return ObservableResult.ofSuccess(this.organizationRoles[0]);
  }

  updateOrganizationRole(roleUid: string, request: UpdateOrganizationRoleRequest): ObservableResult<OrganizationRoles> {
    return ObservableResult.ofSuccess(this.organizationRoles[0]);
  }

  createOrganizationRole(request: CreateOrganizationRoleRequest): ObservableResult<OrganizationRoles> {
    return ObservableResult.ofSuccess(this.organizationRoles[0]);
  }

  loadOrganizationPrivileges(roleType: UserRoleType): ObservableResult<OrganizationPrivilege[]> {
    return ObservableResult.ofSuccess(this.organizationRolePrivilege);
  }

  assignRoleToUser(roleUid: string, userUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeRoleFromUser(roleUid: string, userUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  assignRoleToMultipleUsers(roleUid: string, request: string[]): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  assignMultipleRolesToMultipleUsers(roleUids: string[], userUids: string[]): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeRoleFromMultipleUsers(roleUid: string, request: string[]): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeMultipleRolesFromMultipleUsers(roleUids: string[], userUids: string[]): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  loadMembersByRoleId(roleUid: string, page: number, size: number, term?: string): ObservableResult<Page<Members>> {
    return ObservableResult.ofSuccess();
  }

  loadRolesByPage(page: number, size: number): ObservableResult<Page<OrganizationRoles>> {
    return ObservableResult.ofSuccess();
  }

  loadRolesByPageWithSearch(searchText: string, page: number, size: number): ObservableResult<Page<OrganizationRoles>> {
    return ObservableResult.ofSuccess();
  }
}
