/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { ObservableResult } from '../../store';
import { catchError, switchMap } from 'rxjs/operators';
import { RestClientService } from '../rest-client.service';
import { LanguagesDataService } from './languages-data.service';
import { CreateLanguage, Language, OrganizationLanguageSummary } from '../../models/languages/languages.model';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ApiLanguagesDataService implements LanguagesDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  getLanguages(): ObservableResult<Language[]> {
    return this.client.get<Language[]>(Location.joinWithSlash(environment.apiRootUrl || '', 'languages')).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetLanguages'))),
    );
  }

  getActiveOrganizationLanguages(organizationId: string): ObservableResult<Language[]> {
    return this.client
      .get<Language[]>(Location.joinWithSlash(environment.apiRootUrl || '', `/organizations/${organizationId}/languages/active`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) =>
          ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetOrganizationLanguages')),
        ),
      );
  }

  getOrganizationLanguages(organizationId: string): ObservableResult<OrganizationLanguageSummary> {
    return this.client
      .get<OrganizationLanguageSummary>(Location.joinWithSlash(environment.apiRootUrl || '', `/organizations/${organizationId}/languages`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) =>
          ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetOrganizationLanguages')),
        ),
      );
  }

  createOrganizationLanguages(organizationId: string, request: CreateLanguage): ObservableResult<OrganizationLanguageSummary> {
    return this.client
      .post<OrganizationLanguageSummary>(
        Location.joinWithSlash(environment.apiRootUrl || '', `/organizations/${organizationId}/languages`),
        request,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) =>
          ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorCreateOrganizationLanguages')),
        ),
      );
  }

  deleteOrganizationLanguages(organizationId: string, code: string): ObservableResult<void> {
    return this.client
      .delete<void>(Location.joinWithSlash(environment.apiRootUrl || '', `/organizations/${organizationId}/languages/${code}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) =>
          ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorDeleteOrganizationLanguages')),
        ),
      );
  }

  languageActivation(organizationId: string, code: string, isActive: boolean): ObservableResult<void> {
    return this.client
      .post<void>(
        Location.joinWithSlash(
          environment.apiRootUrl || '',
          `/organizations/${organizationId}/languages/${code}/activation?isActive=${isActive}`,
        ),
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorActivateLanguage'))),
      );
  }

  setDefaultLanguage(organizationId: string, code: string): ObservableResult<void> {
    return this.client
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `/organizations/${organizationId}/languages/${code}/default`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorSetDefaultLanguage'))),
      );
  }
}
