import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BooleanFilterType } from '@app/app/shared/models/admin/boolean-filters.model';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ptl-filter-btn',
  templateUrl: './filter-btn.component.html',
  styleUrls: ['./filter-btn.component.scss'],
})
export class FilterBtnComponent {
  @Input() labels: { type: BooleanFilterType; value: string }[];
  @Output() filterSelected = new EventEmitter<BooleanFilterType>();

  expanded = false;

  constructor(private translocoService: TranslocoService) {}

  expand() {
    this.expanded = !this.expanded;
  }

  selectFilter(type: BooleanFilterType) {
    this.filterSelected.emit(type);
  }

  getTooltipMessage(type: string): string | null {
    switch (type) {
      case 'ACTIVITY':
        return this.translocoService.translate('translations.filter.activity.tooltip');
      default:
        return null;
    }
  }
}
