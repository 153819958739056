/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { PlaylistMemberMetrics } from '@app/app/shared/models/analytics/analytics.model';
import { PlaylistMetadata } from '@app/app/page-modules/pages/components/edit/analytics/page-analytics.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectHelper } from '@app/app/page-modules/resource/store/editor/content/helpers/redirect.helper';
import { ApiContentLinkService } from '@app/app/shared/services/content-link/api-content-link.service';

@Component({
  selector: 'ptl-course-engagements-chart',
  templateUrl: './course-engagements-chart.component.html',
  styleUrls: ['../common-analytics-chart-styles.scss', './course-engagements-chart.component.scss'],
})
export class CourseEngagementsChartComponent implements OnChanges {
  @Input() individualPlaylistMetrics: { playlistMetadata: PlaylistMetadata; metrics: PlaylistMemberMetrics }[] = [];
  @Input() fixedHeight = false;
  @Input() showPlaceholder = false;

  courseEngagementData: { playlistId: string; header: string; totalEngagements: number }[] = [];
  sortOrder: 'asc' | 'desc' = 'desc';
  maxTotalEngagements = 0;

  constructor(
    private contentLinkService: ApiContentLinkService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['individualPlaylistMetrics']) {
      this.updateCourseEngagementData();
    }
  }

  toggleSortOrder(): void {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.sortCourseEngagementData();
  }

  openInNewTab(playlistUid: string): void {
    this.contentLinkService.getPlaylistFormattedUriByPlaylistUid(playlistUid).subscribe(({ isSuccess, value }) => {
      if (isSuccess && value?.formattedUri) {
        RedirectHelper.redirectByParams(
          this.ngZone,
          this.router,
          this.activatedRoute,
          {
            formattedUri: `${value.formattedUri}/analytics`,
          },
          'PLAYLIST',
          '_blank',
        );
      }
    });
  }

  private updateCourseEngagementData(): void {
    const aggregatedData: { playlistId: string; header: string; totalEngagements: number }[] = [];

    this.individualPlaylistMetrics.forEach((playlist) => {
      const totalEngagements = Object.values(playlist.metrics.cardMemberMetrics.cardEngagements).reduce((sum, value) => sum + value, 0);
      aggregatedData.push({
        playlistId: playlist.playlistMetadata.id,
        header: playlist.playlistMetadata.header,
        totalEngagements,
      });
    });

    this.courseEngagementData = aggregatedData;
    this.maxTotalEngagements = Math.max(...aggregatedData.map((data) => data.totalEngagements), 0);
    this.sortCourseEngagementData();
  }

  private sortCourseEngagementData(): void {
    this.courseEngagementData.sort((a, b) => {
      return this.sortOrder === 'desc' ? b.totalEngagements - a.totalEngagements : a.totalEngagements - b.totalEngagements;
    });
  }
}
