/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

// TODO imo it can be removed, but needs more investigation
export class RefreshFavoritePlaylists {
  static readonly type = '[Summaries] Refresh favorite playlists';
}
