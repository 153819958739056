import { AfterViewInit, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { canTriggerSearch } from '../../helpers/content-helper';
import {
  UNSPLASH_IMAGES_DATA_SERVICE,
  UnsplashImagesDataService,
} from '../../../page-modules/admin/services/images/unsplash-images-data.service';
import { UnsplashPhoto, UnsplashPhotoList } from '../../models/images/unsplash-images.model';
import { debounceTime, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from '@app/app/shared/services/toast-notifications/toast-service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ptl-unsplash-images-search-box',
  templateUrl: './unsplash-images-search-box.component.html',
  styleUrls: ['./unsplash-images-search-box.component.scss'],
})
export class UnsplashImagesSearchBoxComponent implements AfterViewInit {
  @Input() initialPhrase: string;

  @Output() photoSelected = new EventEmitter<File>();

  photos: UnsplashPhotoList;
  searchString: string;
  pageNumber = 1;
  isLoadingMore: boolean;
  isLoading = false;
  errorOccurred = false;
  showSpinner: boolean;

  private debounceTimer: ReturnType<typeof setTimeout>;

  constructor(
    private http: HttpClient,
    private translocoService: TranslocoService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
    @Inject(UNSPLASH_IMAGES_DATA_SERVICE) private unsplashService: UnsplashImagesDataService,
  ) {}

  ngAfterViewInit() {
    if (this.initialPhrase) {
      this.searchString = this.initialPhrase;
      this.triggerSearch();
    }
  }

  onSearchInputChange(event: KeyboardEvent): void {
    if (canTriggerSearch(event)) {
      this.pageNumber = 1;
      this.searchString = (event.target as HTMLInputElement).value;
      this.triggerSearch();
    }
  }

  onImageClicked(photo: UnsplashPhoto) {
    this.errorOccurred = false;
    this.showSpinner = true;

    this.unsplashService.download(photo.links.download_location).subscribe(
      (result) => {
        if (!result.isSuccess) {
          this.handleError();
        }

        if (result.value?.url) {
          this.http.get(result.value.url, { responseType: 'blob' }).subscribe((blob) => {
            if (!blob) {
              this.handleError();
              return;
            }

            const mimeType = blob.type;
            const extension = mimeType?.split('/')[1];
            let fileName = `${photo.links.download_location}`;

            if (extension) {
              fileName = fileName + '.' + extension;
            }
            const file = new File([blob], fileName, { type: blob.type });

            if (file) {
              this.photoSelected.emit(file);
            } else {
              this.handleError();
            }
          });
        }
      },
      null,
      () => {
        this.showSpinner = false;
      },
    );
  }

  onShowMore() {
    this.isLoading = true;
    this.isLoadingMore = true;
    this.pageNumber += 1;
    this.errorOccurred = false;
    this.unsplashService
      .search(this.searchString, this.pageNumber)
      .pipe(take(1), debounceTime(500))
      .subscribe((response) => {
        if (response.value.results) {
          this.photos.results.push(...response.value.results);
          this.isLoadingMore = false;
          this.isLoading = false;
        }
      });
  }

  private handleError() {
    this.errorOccurred = true;
    this.toastService.showFail(this.translocoService.translate('translations.uploadImageError'));
  }

  private triggerSearch(): void {
    // The `debounceTime` operator may cause issues in Firefox due to its event handling implementation.
    // In some cases, Firefox's handling of certain event streams can lead to unexpected behavior or missed emissions.
    // Using a manual debounce with `setTimeout` ensures consistency across all browsers, including Firefox.
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.isLoading = true;
      this.errorOccurred = false;
      this.unsplashService
        .search(this.searchString, this.pageNumber)
        .pipe(take(1))
        .subscribe((response) => {
          this.isLoading = false;
          this.photos = response.value;
        });
    }, 500);
  }
}
