<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ptl-new-moment-section-dialog
  [submitLabel]="'translations.addPost' | transloco"
  [loading]="loading"
  [moment]="moment"
  [isExpanded]="true"
  [paddingReduced]="true"
  (momentDataChanged)="onChange($event)"
  (actionButtonClicked)="onMomentSave()"
></ptl-new-moment-section-dialog>
