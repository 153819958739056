import { duration, Duration } from 'moment';

export interface BookableAppointmentRequest {
  name: string;
  type?: BookableAppointmentType;
  duration: Duration;
  bookableInterval: Duration;
  bufferTimeBefore: Duration;
  bufferTimeAfter: Duration;
  bookingWindowStart: Duration;
  bookingWindowEnd?: Duration;
  cancellationThreshold: Duration;
  enabled?: boolean;
}

export interface BookedAppointmentsRequest {
  dateTimeFrom: string;
  dateTimeTo: string;
}

export interface BookableAppointment {
  id: string;
  organizationUid: string;
  apiServiceUid: string;
  name: string;
  duration: Duration;
  bookableInterval: Duration;
  bufferTimeBefore: Duration;
  bufferTimeAfter: Duration;
  bookingWindowStart: Duration;
  bookingWindowEnd: Duration;
  cancellationThreshold: Duration;
  enabled: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum BookableAppointmentType {
  FIXED = 'FIXED',
}

export interface BookableResourceRequest {
  bookableAppointmentUid: string;
  maxSimultaneousBookings: number;
  userUid: string;
}

export interface BookableResource {
  id: string;
  bookableAppointment: BookableAppointmentSummaryDTO;
  organizationUid: string;
  targetResourceSummary?: TargetResourceSummaryDTO | null;
}

export interface TargetResourceSummaryDTO {
  uid: string;
  name: string;
  email: string;
  imageUrl: string | null;
  bookableResourceUid?: string | null;
}

export interface BookableAppointmentSummaryDTO {
  id: string;
  name: string;
}

export class BookableSchedule {
  id: string;
  bookableResource: BookableResource;
  startsAt: Date;
  endsAt?: Date | null;
  createdAt: Date;
  lastUpdatedAt?: Date | null;

  constructor(
    id: string,
    bookableResource: BookableResource,
    startsAt: Date,
    endsAt: Date | null = null,
    createdAt: Date,
    lastUpdatedAt: Date | null = null,
  ) {
    this.id = id;
    this.bookableResource = bookableResource;
    this.startsAt = startsAt;
    this.endsAt = endsAt;
    this.createdAt = createdAt;
    this.lastUpdatedAt = lastUpdatedAt;
  }
}

enum ScheduleType {
  RECURRING = 'RECURRING',
  BLOCK = 'BLOCK',
}

export interface ScheduleRequest {
  type: ScheduleType;
}

export class RecurringScheduleRequest implements ScheduleRequest {
  type: ScheduleType = ScheduleType.RECURRING;
  startDate: Date;
  endDate?: Date | null;

  constructor(startDate: Date, endDate: Date | null = null) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}

export class BlockScheduleRequest implements ScheduleRequest {
  type: ScheduleType = ScheduleType.BLOCK;
  startsAt: Date;
  endsAt: Date;
  isAvailable: boolean;

  constructor(startsAt: Date, endsAt: Date, isAvailable: boolean) {
    this.startsAt = startsAt;
    this.endsAt = endsAt;
    this.isAvailable = isAvailable;
  }
}

export class ScheduleAvailabilityRequest {
  weekday: string;
  startTime: string;
  endTime?: string | null;

  constructor(weekday: string, startTime: string, endTime: string) {
    this.weekday = weekday;
    this.startTime = startTime;
    this.endTime = endTime;
  }
}

export interface BookableInterval {
  start: string;
  end: string;
}

export interface AvailableSlot {
  resource: TargetResourceSummaryDTO;
  slots: BookableSlot[];
}

export class BookableSlot {
  bufferStartsAt: string;
  startsAt: string;
  endsAt: string;
  bufferEndsAt: string;

  constructor(bufferStartsAt: string, startsAt: string, endsAt: string, bufferEndsAt: string) {
    this.bufferStartsAt = bufferStartsAt;
    this.startsAt = startsAt;
    this.endsAt = endsAt;
    this.bufferEndsAt = bufferEndsAt;
  }
}

export interface MyContentGroupedBookedAppointments {
  startsAt?: Date;
  bookedAppointments: BookedAppointment[];
}

export enum BookingUserRoleType {
  HOST = 'HOST',
  GUEST = 'GUEST',
}

export interface CheckedBookedAppointmentData {
  id: string;
  someOtherId?: string;
}

export class BookedAppointment {
  id: string;
  bookableResource: BookableResource;
  userUid: string;
  createdAt: Date;
  lastUpdatedAt?: Date | null;
  isCanceled: boolean;
  startsAt?: Date | null;
  endsAt?: Date | null;
  canceledAt?: Date | null;
  bookedByUser?: BookedAppointmentUser;
  card?: BookedAppointmentCard;

  constructor(
    id: string,
    bookableResource: BookableResource,
    userUid: string,
    createdAt: Date,
    lastUpdatedAt: Date | null = null,
    isCanceled: boolean = false,
    startsAt: Date | null = null,
    endsAt: Date | null = null,
    canceledAt: Date | null = null,
    bookedByUser: BookedAppointmentUser | null = null,
    card: BookedAppointmentCard | null = null,
  ) {
    this.id = id;
    this.bookableResource = bookableResource;
    this.userUid = userUid;
    this.createdAt = createdAt;
    this.lastUpdatedAt = lastUpdatedAt;
    this.isCanceled = isCanceled;
    this.startsAt = startsAt;
    this.endsAt = endsAt;
    this.canceledAt = canceledAt;
    this.bookedByUser = bookedByUser;
    this.card = card;
  }
}

export interface BookedAppointmentCard {
  uri: string;
  cardUid: string;
  formTitle: string;
  cardTitle: string;
}

export interface BookedAppointmentUser {
  uid: string;
  name?: string;
  imageUrl?: string;
}

export class BookingRequest {
  start: string;
  end: string;
  timeZone: string;

  constructor(start: string, end: string, timeZone: string) {
    this.start = start;
    this.end = end;
    this.timeZone = timeZone;
  }
}

export interface ScheduleWithSlots {
  schedule: BookableSchedule;
  slots: BookableSlotAdmin[];
}

export interface BookableSlotAdmin {
  id?: string;
  weekday: string;
  startTime: string;
  endTime?: string | null;
}

export interface BulkCancelBookingsRequest {
  bookedAppointmentsToCancel: string[];
}

export enum BookingFormCheckboxType {
  USER,
}

export class BookableSlotAdminRequest {
  weekday: string;
  startTime: string;
  endTime: string;
  id?: string;

  constructor(weekday: string, startTime: string, endTime: string, id?: string) {
    this.weekday = weekday;
    this.startTime = startTime;
    this.endTime = endTime;
    this.id = id;
  }
}

export const DEFAULT_DURATION = 30;
export const DEFAULT_BUFFER = 0;
export const DEFAULT_CANCELLATION = 24;

export const DEFAULT_APPOINTMENT_REQUEST: BookableAppointmentRequest = {
  name: 'Appointment',
  type: BookableAppointmentType.FIXED,
  duration: duration({ minutes: DEFAULT_DURATION }),
  bookableInterval: duration({ minutes: DEFAULT_DURATION }),
  bufferTimeBefore: duration({ minutes: DEFAULT_BUFFER }),
  bufferTimeAfter: duration({ minutes: DEFAULT_BUFFER }),
  bookingWindowStart: duration({ days: DEFAULT_BUFFER }),
  bookingWindowEnd: duration({ days: 365 }),
  cancellationThreshold: duration({ hours: DEFAULT_CANCELLATION }),
  enabled: true,
};
