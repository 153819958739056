/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component } from '@angular/core';

@Component({
  selector: 'ptl-skeleton-action-item',
  templateUrl: './skeleton-action-item.component.html',
  styleUrls: ['./skeleton-action-item.component.scss'],
})
export class SkeletonActionItemComponent {}
