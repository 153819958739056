<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="table-dynamic-content-wrapper">
  <ptl-tinymce-editor-dir #tinyEditor class="tinymce-editor" [(editorModel)]="editorInput" [options]="editorOptions">
  </ptl-tinymce-editor-dir>
</div>
