<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<mat-table
  #table
  class="table-questions"
  cdkDropList
  [dataSource]="tableData"
  [cdkDropListData]="tableData"
  (cdkDropListDropped)="dropTable($event)"
>
  <!-- Sort Column -->
  <ng-container matColumnDef="sort">
    <mat-header-cell *matHeaderCellDef class="table-questions__sort-cell table-questions__header-cell"></mat-header-cell>
    <mat-cell *matCellDef="let question" class="table-questions__sort-cell">
      <svg class="svg svg-icon" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#drag-indicator"></use>
      </svg>
    </mat-cell>
  </ng-container>

  <!-- Question Column -->
  <ng-container matColumnDef="question">
    <mat-header-cell
      *matHeaderCellDef
      class="table-questions__header-cell"
      [ngClass]="this.isQuiz ? 'table-questions__question-cell--quiz' : 'table-questions__question-cell'"
      tabindex="0"
      [attr.aria-label]="'translations.global.ariaLabels.questionsTableTitleQuestion' | transloco"
    >
      {{ 'translations.questionsTableTitleQuestion' | transloco }}</mat-header-cell
    >
    <mat-cell
      *matCellDef="let question"
      [ngClass]="this.isQuiz ? 'table-questions__question-cell--quiz' : 'table-questions__question-cell'"
    >
      <div class="table-questions__question-cell__title" tabindex="0" [attr.aria-label]="question.title" [innerHTML]="question.title"></div>
    </mat-cell>
  </ng-container>

  <!-- Attribute Column -->
  <ng-container matColumnDef="attribute">
    <mat-header-cell
      *matHeaderCellDef
      class="table-questions__tags-cell table-questions__header-cell"
      tabindex="0"
      [attr.aria-label]="'translations.global.ariaLabels.questionsTableTitleLearningGoal' | transloco"
    >
      {{ 'translations.questionsTableTitleLearningGoal' | transloco }}</mat-header-cell
    >
    <mat-cell *matCellDef="let question" class="table-questions__tags-cell">
      <div
        class="question"
        *ngFor="let tag of question?.tags; let last = last"
        tabindex="0"
        [attr.aria-label]="tag.title + ' ' + (last ? '' : ', ')"
      >
        {{ tag.title }}{{ last ? '' : ', ' }}
      </div>
    </mat-cell>
  </ng-container>

  <!-- Type Column -->
  <ng-container matColumnDef="type">
    <mat-header-cell
      *matHeaderCellDef
      class="table-questions__header-cell"
      tabindex="0"
      [attr.aria-label]="'translations.global.ariaLabels.questionsTableTitleType' | transloco"
    >
      {{ 'translations.questionsTableTitleType' | transloco }}</mat-header-cell
    >
    <mat-cell *matCellDef="let question" tabindex="0" [attr.aria-label]="prettyStatus[question.type]">
      {{ prettyStatus[question.type] }}
    </mat-cell>
  </ng-container>

  <!-- Range Column -->
  <ng-container matColumnDef="range">
    <mat-header-cell
      *matHeaderCellDef
      class="table-questions__header-cell"
      tabindex="0"
      [attr.aria-label]="'translations.global.ariaLabels.questionsTableTitleScoringRange' | transloco"
    >
      {{ 'translations.questionsTableTitleScoringRange' | transloco }}</mat-header-cell
    >
    <mat-cell
      *matCellDef="let question"
      tabindex="0"
      [attr.aria-label]="'translations.global.ariaLabels.fromTo' | transloco: { start: question.min, end: question.max }"
    >
      {{ question.min }} - {{ question.max }}
    </mat-cell>
  </ng-container>

  <!-- Logic select Column -->
  <ng-container matColumnDef="required">
    <mat-header-cell
      *matHeaderCellDef
      style="display: none"
      tabindex="0"
      [attr.aria-label]="'translations.global.ariaLabels.questionsTableTitleLogic' | transloco"
    >
      {{ 'translations.questionsTableTitleLogic' | transloco }}</mat-header-cell
    >
    <mat-cell *matCellDef="let question" class="table-questions__select-cell" style="display: none">
      <mat-select> </mat-select>
    </mat-cell>
  </ng-container>

  <!-- Edit button Column -->
  <ng-container matColumnDef="edit">
    <mat-header-cell *matHeaderCellDef class="table-questions__action-cell"></mat-header-cell>
    <mat-cell *matCellDef="let question; let i = index" class="table-questions__action-cell">
      <button
        class="hover"
        mat-button
        type="button"
        [attr.aria-label]="'translations.global.ariaLabels.editQuestion' | transloco: { title: question.title }"
        (click)="editQuestion(i)"
      >
        <svg class="svg svg-icon" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
        </svg>
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="copy">
    <mat-header-cell *matHeaderCellDef class="table-questions__action-cell"></mat-header-cell>
    <mat-cell *matCellDef="let question; let i = index" class="table-questions__action-cell">
      <button
        class="hover"
        mat-button
        type="button"
        [class.is_disabled]="!isDefaultLanguage"
        [attr.aria-label]="'translations.global.ariaLabels.copyQuestion' | transloco: { title: question.title }"
        (click)="onCopyQuestion(i, question.uid)"
      >
        <svg class="svg svg-icon" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#copy"></use>
        </svg>
      </button>
    </mat-cell>
  </ng-container>

  <!-- Remove button Column -->
  <ng-container matColumnDef="remove">
    <mat-header-cell *matHeaderCellDef class="table-questions__action-cell"></mat-header-cell>
    <mat-cell *matCellDef="let question" class="table-questions__action-cell">
      <button
        class="hover"
        mat-button
        type="button"
        [class.is_disabled]="!isDefaultLanguage"
        [attr.aria-label]="'translations.global.ariaLabels.removeQuestion' | transloco: { title: question.title }"
        (click)="removeQuestion(question)"
      >
        <svg class="svg svg-icon" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
        </svg>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    cdkDrag
    cdkDragLockAxis="y"
    class="table-questions__row"
    *matRowDef="let row; columns: displayedColumns"
    [cdkDragData]="row"
    (keydown.enter)="drag(row)"
    (keydown.space)="drag(row)"
    tabindex="0"
    [attr.aria-label]="'translations.global.ariaLabels.changeTheItemPosition' | transloco: { title: row.title }"
  >
  </mat-row>
</mat-table>

<div
  class="empty-table"
  *ngIf="!tableData?.length"
  tabindex="0"
  [attr.aria-label]="'translations.global.ariaLabels.noQuestionsPresent' | transloco"
>
  {{ 'translations.noQuestionsPresent' | transloco }}
</div>
