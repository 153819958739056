/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Page } from 'src/app/shared/models/page';
import { FrameworkTrackerSectionTag, SectionReorder } from 'src/app/shared/models/page/page.model';
import { ObservableResult } from 'src/app/shared/store';
import { JobEntity } from '../components/view/shared/jobs-section/jobs.model';
import { Moment } from '../models/moment.model';
import {
  AchievementCertificateBlock,
  FolioBadges,
  FolioCoverUpdateRequest,
  FolioModel,
  FolioMyActivity,
  FolioSection,
  FolioSectionItemRequest,
  FolioSectionRequest,
  FolioShowcaseRequest,
  PublicFolioBadge,
} from '../store/folio.state.model';
import { FolioDataService } from './data.service';
import { FolioProject } from '../../project/models/project.model';
import { FolioLearningExperience, LearningExperience } from '../../learning-experiences/learning-experience.model';

export class MockFolioDataService implements FolioDataService {
  getUserFolio(): ObservableResult<FolioModel> {
    return ObservableResult.ofSuccess();
  }

  updateUserFolioShowcase(request: FolioShowcaseRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateUserFolioCoverImage(request: FolioCoverUpdateRequest): ObservableResult<{ uploadUrl: string }> {
    return ObservableResult.ofSuccess({ uploadUrl: '' });
  }

  deleteUserFolioCoverImage(): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  reorderFolioSections(request: SectionReorder): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateFolioSection(sectionUid: string, request: FolioSectionRequest, languageCode?: string): ObservableResult<FolioSection> {
    return ObservableResult.ofSuccess();
  }

  enableFolioSectionItemVisibility(sectionUid: string, request: FolioSectionItemRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  disableFolioSectionItemVisibility(sectionUid: string, request: FolioSectionItemRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  deleteCertificate(uid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addMomentToFolioSection(sectionUid: string, momentUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addTagToFolioSection(sectionUid: string, tagUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeProjectFromFolioSection(sectionUid: string, playlistUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeMomentFromFolioSection(sectionUid: string, momentUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeTagFromFolioSection(sectionUid: string, tagUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  getUserFolioProjects(folioPublicId: string, page: number, size: number): ObservableResult<Page<FolioProject>> {
    return ObservableResult.ofSuccess();
  }

  getFolioProjects(page: number, size: number): ObservableResult<Page<FolioProject>> {
    return ObservableResult.ofSuccess({
      currentPageNumber: 0,
      pageSize: 1,
      totalNumberOfElement: 1,
      content: [],
    });
  }

  getFolioMoments(sectionUid: string, page: number, size: number): ObservableResult<Page<Moment>> {
    return ObservableResult.ofSuccess({
      currentPageNumber: 0,
      pageSize: 1,
      totalNumberOfElement: 1,
      content: [],
    });
  }

  getFolioActivities(
    page: number,
    size: number,
    order: 'ASC' | 'DESC',
    dataRange: string,
    tags: string[] | null,
  ): ObservableResult<Page<FolioMyActivity>> {
    return ObservableResult.ofSuccess({
      currentPageNumber: 0,
      pageSize: 1,
      totalNumberOfElement: 1,
      content: [],
    });
  }

  getFolioSkillsActivities(page: number, size: number, tagId: string): ObservableResult<Page<FolioMyActivity>> {
    return ObservableResult.ofSuccess({
      currentPageNumber: 0,
      pageSize: 1,
      totalNumberOfElement: 1,
      content: [],
    });
  }

  getFolioBadges(sectionUid: string, page: number, size: number): ObservableResult<FolioBadges[]> {
    return ObservableResult.ofSuccess([]);
  }

  getPublicFolioCertificates(
    publicId: string,
    sectionUid: string,
    page: number,
    size: number,
  ): ObservableResult<Page<AchievementCertificateBlock>> {
    return ObservableResult.ofSuccess({
      currentPageNumber: 0,
      pageSize: 1,
      totalNumberOfElement: 1,
      content: [],
    });
  }

  getFolioGrowths(sectionUid: string, page: number, size: number): ObservableResult<Page<FrameworkTrackerSectionTag>> {
    return ObservableResult.ofSuccess();
  }

  getPublicUserFolio(publicId: string, languageCode?: string): ObservableResult<FolioModel> {
    return ObservableResult.ofSuccess();
  }

  getPublicFolioGrowths(
    publicId: string,
    sectionUid: string,
    page: number,
    size: number,
  ): ObservableResult<Page<FrameworkTrackerSectionTag>> {
    return ObservableResult.ofSuccess();
  }

  getPublicFolioMoments(publicId: string, sectionUid: string, page: number, size: number): ObservableResult<Page<Moment>> {
    return ObservableResult.ofSuccess();
  }

  getPublicFolioProjects(publicId: string, page: number, size: number): ObservableResult<Page<FolioProject>> {
    return ObservableResult.ofSuccess();
  }

  getPublicFolioBadges(publicId: string, sectionUid: string, page: number, size: number): ObservableResult<FolioBadges[]> {
    return ObservableResult.ofSuccess([]);
  }

  getFolioUserPublicBadge(userBadgeId: string): ObservableResult<PublicFolioBadge> {
    return ObservableResult.ofSuccess();
  }

  getFolioJobs(page: number, languageCode?: string): ObservableResult<JobEntity[]> {
    return ObservableResult.ofSuccess();
  }
}
