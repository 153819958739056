<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="moment-card__actions">
  <button
    mat-icon-button
    class="moment-card__actions-btn"
    title="Settings"
    type="button"
    (click)="$event.stopPropagation()"
    [matMenuTriggerFor]="cardMenu"
  >
    <mat-icon aria-hidden="true" [inline]="true">more_vert</mat-icon>
  </button>

  <mat-menu #cardMenu="matMenu">
    <ng-template matMenuContent>
      <button mat-menu-item (click)="onEditClick()">{{ 'translations.global.button.edit' | transloco }}</button>
      <button mat-menu-item class="add-to-folio" *ngIf="!card.momentAddedToFolio" (click)="onAddToFolioClick()">
        {{ 'translations.folio.button.addToFolio' | transloco }}
      </button>
      <button mat-menu-item class="remove-from-folio" *ngIf="card.momentAddedToFolio" (click)="onRemoveMomentFromFolioClick()">
        {{ 'translations.folio.button.removeFromFolio' | transloco }}
      </button>
      <button mat-menu-item (click)="removeCard()" *ngIf="!archiveMoment">{{ 'translations.global.button.remove' | transloco }}</button>
      <button mat-menu-item (click)="archiveCard()" *ngIf="archiveMoment">{{ 'translations.global.button.archive' | transloco }}</button>
    </ng-template>
  </mat-menu>
</div>
