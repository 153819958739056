import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { UpdateNotificationsCount } from 'src/app/app-frame/store/header-counts.action';
import { PushNotificationMessage, WebSocketTopic } from '../../models/websocket/websocket.model';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from '../../services/toast-notifications/toast-service';
import { WEBSOCKET_SERVICE, WebsocketService } from '../../services/websocket/websocket.service';

@Component({
  selector: 'ptl-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
})
export class ToastContainerComponent implements OnInit, OnDestroy {
  private defaultTopicSubscription: Subscription;

  constructor(
    private store: Store,
    @Inject(TOAST_NOTIFICATION_SERVICE) public toastService: ToastService,
    @Inject(WEBSOCKET_SERVICE) private websocketService: WebsocketService,
  ) {}

  ngOnInit() {
    this.defaultTopicSubscription = this.websocketService.subscribeToTopic(WebSocketTopic.DEFAULT).subscribe((message) => {
      this.store.dispatch(new UpdateNotificationsCount());
      const pushNotificationMessage: PushNotificationMessage = JSON.parse(message.body);
      this.toastService.showPushNotification(pushNotificationMessage);
    });
  }

  ngOnDestroy() {
    this.defaultTopicSubscription?.unsubscribe();
  }
}
