<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="form-preview"
  tabindex="0"
  [attr.aria-label]="'translations.global.ariaLabels.bookingForm' | transloco"
  [class.form-preview--active]="submitted && canEdit"
  [class.form-preview--saved]="submitted"
>
  <div class="form-preview__top">
    <div class="form-booking-name">
      <div class="title medium bold">
        {{ bookingName }}
      </div>
    </div>

    <div class="form-preview__top-icon-box">
      <div *ngIf="submitted" class="form-preview__top-icon non-editable" [matTooltip]="canNotEditReason">
        <mat-icon *ngIf="canEdit" aria-hidden="true">check</mat-icon>
        <svg *ngIf="!canEdit" class="svg" [attr.aria-label]="'translations.global.ariaLabels.icons.lock' | transloco">
          <use xlink:href="assets/sprite/sprite.svg#lock"></use>
        </svg>
      </div>
      <div *ngIf="!submitted && !canEdit" class="form-preview__top-icon non-saved non-editable" [matTooltip]="canNotEditReason">
        <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.lock' | transloco">
          <use xlink:href="assets/sprite/sprite.svg#lock"></use>
        </svg>
      </div>
      <div *ngIf="canEdit" class="form-preview__top-icon editable">
        <svg class="svg" [attr.aria-label]="'translations.global.ariaLabels.icons.edit' | transloco">
          <use xlink:href="assets/sprite/sprite.svg#edit"></use>
        </svg>
      </div>
      <div *ngIf="isLoading" class="form-preview__top-icon non-editable">
        <mat-spinner [attr.aria-label]="'translations.global.ariaLabels.icons.loading' | transloco" [diameter]="16"> </mat-spinner>
      </div>
      <div
        *ngIf="existingUserAnswer && !bookedAppointment?.isCanceled && !isLoading"
        class="form-preview__top-icon editable calendar-icon"
        [matTooltip]="'translations.bookings.label.preview.exportEvent' | transloco"
        [attr.aria-label]="'translations.bookings.label.preview.exportEvent' | transloco"
        (click)="downloadEvent(bookedAppointment.id)"
        (keydown.enter)="downloadEvent(bookedAppointment.id)"
      >
        <svg class="svg" [attr.aria-label]="'translations.global.ariaLabels.icons.calendar' | transloco">
          <use xlink:href="assets/sprite/sprite.svg#calendar"></use>
        </svg>
      </div>
    </div>
  </div>
  <div class="form-preview-content" [class.is_disabled]="this.userPlaylistSubmissionSummary?.submissionsLocked">
    <ng-container *ngIf="!existingUserAnswer || canReBook">
      <ng-container *ngIf="!availableSlots?.length && !learnerFormAnswer?.answer && !this.isLoading">
        <span class="small">{{ 'translations.bookings.label.preview.noBookingSlots' | transloco }}</span>
      </ng-container>
      <ng-container *ngIf="availableSlots?.length > 0">
        <div class="resources-list">
          <div
            class="resource-available clickable"
            *ngFor="let slot of availableSlots"
            (click)="chooseBookableResource(slot.resource.bookableResourceUid)"
            (keydown.enter)="chooseBookableResource(slot.resource.bookableResourceUid)"
            [class.resource-active]="slot.resource.bookableResourceUid === chosenBookableResourceUid"
          >
            <img
              class="resource-user-image"
              src="{{ slot.resource.imageUrl ? slot.resource.imageUrl : '../../../../../../../assets/profile_image.png' }}"
              alt="{{ slot.resource.name }}"
            />
            <span class="small">{{ slot.resource.name }}</span>
          </div>
        </div>
        <ng-container *ngIf="availableSlots?.length > 0 && this.chosenBookableResourceUid">
          <div class="slots-box">
            <div class="slots-column">
              <mat-calendar
                class="calendar"
                #calendar
                [maxDate]="toDate(defaultTo)"
                [minDate]="toDate(defaultFrom)"
                [dateClass]="dateClass"
                (selectedChange)="onCalendarChange($event)"
                *ngIf="formData?.content?.bookableAppointmentId"
              >
              </mat-calendar>
            </div>
            <div class="slots-column time-slot">
              <div class="time-slot-top-info">
                <div class="slots-title small bold" *ngIf="visibleSlots.length === 1">
                  {{ 'translations.bookings.label.preview.availableSlot' | transloco }}
                </div>
                <div class="slots-title small bold" *ngIf="visibleSlots.length > 1">
                  {{ 'translations.bookings.label.preview.availableSlots' | transloco }}
                </div>
                <div class="slots-picked-date small">{{ calendarActiveDate | date: 'd MMMM yyyy' }}</div>
              </div>
              <div class="time-slot-container">
                <span
                  class="time-slot-available clickable small"
                  *ngFor="let slot of visibleSlots"
                  (click)="chooseAvailableDateTimeSlot(slot.startsAt, slot.endsAt)"
                  (keydown.enter)="chooseAvailableDateTimeSlot(slot.startsAt, slot.endsAt)"
                  [class.resource-active]="slot.startsAt === chosenStart && slot.endsAt === chosenEnd"
                  >{{ slot.startsAt | date: 'HH:mm' }}</span
                >
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <div class="form-preview-booked-view" *ngIf="learnerFormAnswer?.answer?.length > 0 && bookedAppointment && existingUserAnswer">
      <div class="small" [class.grayed-out]="bookedAppointment?.isCanceled" *ngIf="!canReBook">
        {{
          'translations.bookings.label.preview.sessionInfo'
            | transloco
              : {
                  hour: getBookedAppointmentDuration,
                  date: bookedAppointment.startsAt | date: 'dd MMMM yyyy',
                }
        }}
      </div>
      <div class="small" *ngIf="!canReBook" [class.grayed-out]="bookedAppointment?.isCanceled">
        {{ bookedAppointment.startsAt | date: 'HH:mm' }} - {{ bookedAppointment.endsAt | date: 'HH:mm' }}
      </div>
      <div class="booking-info-container" *ngIf="!canReBook">
        <div class="resource-available">
          <img
            class="resource-user-image"
            [alt]="bookedAppointment.bookableResource.targetResourceSummary.name"
            src="{{
              bookedAppointment.bookableResource.targetResourceSummary.imageUrl
                ? bookedAppointment.bookableResource.targetResourceSummary.imageUrl
                : '../../../../../../../assets/profile_image.png'
            }}"
          />
          <span class="small" [class.grayed-out]="bookedAppointment?.isCanceled">
            {{ bookedAppointment.bookableResource.targetResourceSummary.name }}
          </span>
        </div>

        <div class="right-side">
          <div class="form-preview__top-title">
            <div class="title small bold" *ngIf="!existingUserAnswer || isLoading">
              {{ 'translations.bookings.label.preview.formTitle' | transloco }}
            </div>
            <div class="title small bold" *ngIf="existingUserAnswer && !bookedAppointment?.isCanceled && !isLoading">
              {{ 'translations.bookings.label.preview.success.bookedMessage' | transloco }}
            </div>
            <div class="title small bold" *ngIf="existingUserAnswer && bookedAppointment?.isCanceled && !isLoading">
              {{ 'translations.bookings.label.preview.success.cancelledMessage' | transloco }}
            </div>
          </div>

          <span
            class="booking-cancellation"
            *ngIf="!bookedAppointment?.isCanceled"
            (click)="cancelBooking(bookedAppointment.id)"
            (keydown.enter)="cancelBooking(bookedAppointment.id)"
            >{{ 'translations.bookings.label.preview.cancel' | transloco }}</span
          >
        </div>
      </div>
      <button
        mat-stroked-button
        class="rebooking-button uppercase"
        *ngIf="!canReBook && bookedAppointment?.isCanceled"
        (click)="enableReBooking()"
        (keydown.enter)="enableReBooking()"
      >
        {{ 'translations.bookings.label.preview.placeAnotherBooking' | transloco }}
      </button>
    </div>
  </div>

  <div class="form-preview-error-box">
    <div *ngIf="hasAppointmentLoadingError">
      <mat-error>
        {{ 'translations.bookings.label.errors.appointmentError' | transloco }}
      </mat-error>
    </div>
    <div>
      <mat-error *ngIf="hasBookingPlacementError">
        {{ 'translations.bookings.label.errors.bookingError' | transloco }}
      </mat-error>
    </div>
  </div>

  <div class="form-preview-notes" *ngIf="formData?.content?.notes?.length">
    <div class="note-item" *ngFor="let note of formData?.content?.notes" tabindex="0">
      <div class="note-item-inner">
        <div class="note-message small">
          <ptl-resource-preview class="thin-preview" [previewContent]="note.message"> </ptl-resource-preview>
        </div>
      </div>
      <div class="note-added-by extra-small italic">
        {{ note.addedBy }}, {{ getLocalDateTime(note.addedOn) | date: 'dd MMM yyyy HH:mm' }}
      </div>
    </div>
  </div>

  <div class="form-preview__footer" *ngIf="(!submitted && canEdit) || canReBook">
    <ng-container *ngIf="availableSlots?.length > 0 && this.chosenBookableResourceUid && this.calendarActiveDate">
      <button
        mat-stroked-button
        class="booking-button uppercase"
        *ngIf="!existingUserAnswer || canReBook"
        (click)="placeBooking($event)"
        (keydown.enter)="placeBooking($event)"
        [disabled]="!chosenBookableResourceUid || !chosenStart || !chosenEnd"
      >
        {{ 'translations.bookings.label.preview.placeBooking' | transloco }}
      </button>
    </ng-container>
  </div>
</div>
<ptl-form-update-footer
  [submitted]="submitted"
  [canEdit]="canEdit"
  [learnerFormAnswer]="learnerFormAnswer"
  [formType]="formData?.content?.type"
  [playlistSubmissionDeadline]="userPlaylistSubmissionSummary?.submissionDeadline"
>
</ptl-form-update-footer>
