/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Select } from '@ngxs/store';
import { PageState } from '@app/app/page-modules/pages/store/pages.state';
import { forkJoin, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { PlaylistMetadata, RefreshActionType } from '@app/app/page-modules/pages/components/edit/analytics/page-analytics.model';
import { LoadableState } from '@app/app/shared/store';
import { Page } from '@app/app/shared/models/page/page.model';
import { CORE_PLAYLIST_DATA_SERVICE, PlaylistDataService } from '@app/app/page-modules/playlist/services/create/core/data.service';
import { AndRequest, BooleanFilter, BooleanFilterType, BooleanQueryType } from '@app/app/shared/models/admin/boolean-filters.model';
import { CardEventRegistrations, PlaylistMemberMetrics } from '@app/app/shared/models/analytics/analytics.model';
import { Chart } from 'chart.js';
import { TranslocoService } from '@ngneat/transloco';
import { RequirementsMode } from '@app/app/page-modules/playlist/models';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { MembersViewAvailableFilters } from '@app/app/page-modules/admin/components/members/admin-member-control-panel/admin-member-control-panel.component';
import { countEventCardRegistrations, createEmptyQuery } from '@app/app/shared/utils/analytics.utils';
import { MatDialog } from '@angular/material/dialog';
import { PlaylistMetricsTableDialogComponent } from '@app/app/page-modules/pages/components/edit/analytics/details-dialog/playlist-metrics-table-dialog.component';
import { EventsChartType } from '@app/app/shared/components/analytics/daily-enrollment-chart/daily-enrollment-chart.component';
import { AnalyticsHelpComponent, AnalyticsHelpType } from '@app/app/shared/components/analytics/analytics-help/analytics-help.component';

@Component({
  selector: 'ptl-page-analytics',
  templateUrl: './page-analytics.component.html',
  styleUrls: ['./page-analytics.component.scss'],
  providers: [DatePipe],
})
export class PageAnalyticsComponent implements OnInit, OnDestroy {
  @Select(PageState.page)
  page$: Observable<LoadableState<Page>>;

  readonly courseFilterLabels = this.buildCourseFilterLabels();
  readonly eventFilterLabels = this.buildEventFilterLabels();

  filters: BooleanFilter[] = [];
  currentFilter: MembersViewAvailableFilters;
  newFilter: MembersViewAvailableFilters;
  requirementsMode: RequirementsMode = 'PART_OF';
  relevantPlaylists: PlaylistMetadata[];
  showHelp = false;
  showSkeletonView = true;
  hasRelevantPlaylists = true;
  metricsBundle: PlaylistMemberMetrics;
  playlistUids: string[];
  selectedEventId: string = null;
  registeredUsers: number;
  participatedUsers: number;
  waitlistedUsers: number;
  eventCardCount: number;
  startedCourseCount: number;
  completedCourseCount: number;
  membersCount: number;
  totalCardsCount: number;
  registrationDatesToCounts: Record<string, number> = {};
  eventIdsToRegistrationCounts: Record<string, number>;
  individualPlaylistMetrics: { playlistMetadata: PlaylistMetadata; metrics: PlaylistMemberMetrics }[] = [];

  private subscriptionEnd$ = new EventEmitter<void>();
  private page: Page;
  private currentRequest: AndRequest;
  private refreshActionType: RefreshActionType = 'REFRESH_ALL';

  protected readonly EventsChartType = EventsChartType;
  private readonly filterTypeMap: Record<RefreshActionType, BooleanFilterType[]> = {
    REFRESH_COURSES: ['GROUP', 'ROLE', 'ACTIVITY', 'REVIEW_STATUS'],
    REFRESH_EVENTS: ['ATTENDANCE'],
    REFRESH_ALL: ['GROUP', 'ROLE', 'ACTIVITY', 'REVIEW_STATUS', 'ATTENDANCE'],
  };

  constructor(
    @Inject(CORE_PLAYLIST_DATA_SERVICE) private playlistDataService: PlaylistDataService,
    private translocoService: TranslocoService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.showSkeletonView = true;

    this.page$
      .pipe(
        map((value) => value.data),
        filter((data) => !!data),
        distinctUntilChanged(),
        take(1),
        takeUntil(this.subscriptionEnd$),
      )
      .subscribe((page) => {
        this.page = page;
        this.initializePlaylists();
      });
    Chart.register(ChartDataLabels);
  }

  ngOnDestroy(): void {
    this.subscriptionEnd$.emit();
    Chart.unregister(ChartDataLabels);
  }

  openHelpDialog() {
    this.dialog.open(AnalyticsHelpComponent, {
      width: '70vw',
      data: { analyticsHelpType: AnalyticsHelpType.PAGE_ANALYTICS },
      panelClass: 'analytics-help-dialog',
    });
  }

  filterSelected(filterType: BooleanFilterType): void {
    this.currentFilter = filterType as MembersViewAvailableFilters;
    if (!this.filters.map((f) => f.type).includes(this.currentFilter)) {
      this.newFilter = this.currentFilter;
    }
  }

  onRemoveFilter(removedFilterType: BooleanFilterType): void {
    this.currentFilter = undefined;
    this.newFilter = undefined;
    const initialFilterCount = this.filters.length;
    this.filters = this.filters.filter((f) => f.type !== removedFilterType);

    if (this.filters.length < initialFilterCount) {
      this.refreshActionType = this.getRefreshActionType(removedFilterType);
      this.applyFilters();
    }
  }

  onSaveFilter(savedFilter: BooleanFilter): void {
    this.currentFilter = undefined;
    this.newFilter = undefined;
    this.filters = this.filters.filter((currentFilter) => currentFilter.type !== savedFilter.type);
    this.filters = [...this.filters, savedFilter].sort((f1, f2) => {
      const order = ['GROUP', 'ROLE', 'ATTENDANCE', 'REVIEW_STATUS', 'ACTIVITY', 'COMPLETION'];
      const idx1 = order.findIndex((t) => f1.type === t);
      const idx2 = order.findIndex((t) => f2.type === t);
      return idx1 - idx2;
    });

    this.refreshActionType = this.getRefreshActionType(savedFilter.type);
    this.applyFilters();
  }

  openDetailsDialog(): void {
    const transformedData = this.individualPlaylistMetrics.map(({ playlistMetadata, metrics }) => ({
      playlistId: playlistMetadata.id,
      header: playlistMetadata.header,
      totalCards: metrics.summary.totalCards,
      totalMembers: metrics.summary.totalMembers,
      startedCourseCount: metrics.summary.startedCourseCount,
      completedCourseCount: metrics.summary.completedCourseCount,
      totalEventCardRegistrations: countEventCardRegistrations(metrics.cardMemberMetrics.cardEventRegistrations),
      totalEnrollmentsCount: metrics.summary.totalEnrollmentsCount,
    }));

    this.dialog.open(PlaylistMetricsTableDialogComponent, {
      width: '94vw',
      data: {
        pageHeadline: this.page.headline,
        playlistData: transformedData,
      },
      panelClass: 'analytics-playlists-table-dialog',
    });
  }

  onEventSelected(selectedEventId: string): void {
    this.selectedEventId = selectedEventId;
    this.updateEventMetrics();
  }

  private applyFilters(): void {
    const supportedTypes = this.filterTypeMap[this.refreshActionType] || [];
    const args = this.filters.filter((f) => supportedTypes.includes(f.type)).flatMap((f) => f.request);

    const request = {
      type: BooleanQueryType.AND,
      args: args,
    } as AndRequest;

    if (!this.metricsBundle || this.filtersHaveChanged(request)) {
      this.metricsBundle = this.initializeMetricsBundle();
    }

    this.fetchPlaylistMetrics(request);
  }

  private filtersHaveChanged(newRequest: AndRequest): boolean {
    return JSON.stringify(newRequest) !== JSON.stringify(this.currentRequest);
  }

  private initializeMetricsBundle(): PlaylistMemberMetrics {
    return {
      summary: {
        totalCards: 0,
        totalMembers: 0,
        startedCourseCount: 0,
        completedCourseCount: 0,
        totalEnrollmentsCount: 0,
      },
      cardMemberMetrics: {
        cardCompletions: {},
        relevantCardLevelsCompletions: {},
        cardEngagements: {},
        userEngagements: {},
        cardEventRegistrations: [],
      },
      dailyEnrollmentCounts: {},
      usersInfo: [],
      cardEventsInfo: [],
      cardHeaders: [],
    };
  }

  private buildCourseFilterLabels(): { type: string; value: string }[] {
    return [
      { type: 'GROUP', value: this.translocoService.translate('translations.reviews.filter.group') },
      { type: 'ROLE', value: this.translocoService.translate('translations.filter.role.name') },
      { type: 'ACTIVITY', value: this.translocoService.translate('translations.filter.activity.nameAlt') },
    ];
  }

  private buildEventFilterLabels(): { type: string; value: string }[] {
    return [{ type: 'ATTENDANCE', value: this.translocoService.translate('translations.filter.attendance.name') }];
  }

  private initializePlaylists(): void {
    this.relevantPlaylists = this.getPlaylistsBySectionType(this.page, ['FEATURED', 'CALLOUT_FEATURED']);
    if (this.relevantPlaylists.length === 0) {
      this.hasRelevantPlaylists = false;
    }

    this.playlistUids = this.relevantPlaylists.map((playlist) => playlist.id);
    this.fetchPlaylistMetrics();
  }

  private fetchPlaylistMetrics(queryRequest?: AndRequest): void {
    const request = { query: queryRequest || createEmptyQuery() };
    this.currentRequest = queryRequest;

    const playlistMetrics$ = this.relevantPlaylists.map((playlist) =>
      this.playlistDataService.getPlaylistMetrics(playlist.id, request).pipe(
        map((result) => ({ playlistMetadata: playlist, result })),
        takeUntil(this.subscriptionEnd$),
      ),
    );

    forkJoin(playlistMetrics$).subscribe((results) => {
      const aggregatedMetrics = this.initializeMetricsBundle();
      const individualMetrics = [];

      results.forEach(({ playlistMetadata, result }) => {
        if (result.isSuccess) {
          this.aggregatePlaylistMetric(result.value, aggregatedMetrics);
          individualMetrics.push({ playlistMetadata: playlistMetadata, metrics: result.value });
        }
      });

      this.metricsBundle = aggregatedMetrics;
      if (this.refreshActionType === 'REFRESH_COURSES') {
        this.individualPlaylistMetrics = individualMetrics;
        this.updateCourseMetrics();
      } else if (this.refreshActionType === 'REFRESH_EVENTS') {
        this.updateEventMetrics();
      } else {
        this.individualPlaylistMetrics = individualMetrics;
        this.updateCourseMetrics();
        this.updateEventMetrics();
      }
      this.showSkeletonView = false;
    });
  }

  private aggregatePlaylistMetric(newMetric: PlaylistMemberMetrics, targetMetrics: PlaylistMemberMetrics): void {
    targetMetrics.summary.totalCards += newMetric.summary.totalCards;
    targetMetrics.summary.totalMembers += newMetric.summary.totalMembers;
    targetMetrics.summary.startedCourseCount += newMetric.summary.startedCourseCount;
    targetMetrics.summary.completedCourseCount += newMetric.summary.completedCourseCount;
    targetMetrics.summary.totalEnrollmentsCount += newMetric.summary.totalEnrollmentsCount;

    for (const [key, value] of Object.entries(newMetric.cardMemberMetrics.userEngagements)) {
      targetMetrics.cardMemberMetrics.userEngagements[key] = (targetMetrics.cardMemberMetrics.userEngagements[key] || 0) + value;
    }

    for (const [key, value] of Object.entries(newMetric.dailyEnrollmentCounts)) {
      targetMetrics.dailyEnrollmentCounts[key] = (targetMetrics.dailyEnrollmentCounts[key] || 0) + value;
    }

    newMetric.usersInfo.forEach((user) => {
      if (!targetMetrics.usersInfo.some((existingUser) => existingUser.userId === user.userId)) {
        targetMetrics.usersInfo.push(user);
      }
    });

    newMetric.cardEventsInfo.forEach((cardEvent) => {
      if (!targetMetrics.cardEventsInfo.some((existingEvent) => existingEvent.eventCardId === cardEvent.eventCardId)) {
        targetMetrics.cardEventsInfo.push(cardEvent);
      }
    });

    newMetric.cardMemberMetrics.cardEventRegistrations.forEach((registration) => {
      if (
        !targetMetrics.cardMemberMetrics.cardEventRegistrations.some(
          (existingRegistration) =>
            existingRegistration.eventCardId === registration.eventCardId &&
            existingRegistration.learnerCardId === registration.learnerCardId,
        )
      ) {
        targetMetrics.cardMemberMetrics.cardEventRegistrations.push(registration);
      }
    });
  }

  private getPlaylistsBySectionType(page: Page, types: string[]): PlaylistMetadata[] {
    const sections = page.sections.filter((section) => types.includes(section.type));

    const uniquePlaylists = new Map<string, PlaylistMetadata>();
    sections.forEach((section) => {
      section.items.forEach((item) => {
        if (item._id && !uniquePlaylists.has(item._id)) {
          uniquePlaylists.set(item._id, {
            id: item._id,
            uid: item.uid,
            header: item.header || item.headline,
          });
        }
      });
    });

    return Array.from(uniquePlaylists.values());
  }

  private updateCourseMetrics(): void {
    if (!this.metricsBundle) {
      return;
    }

    this.startedCourseCount = this.metricsBundle.summary.startedCourseCount;
    this.completedCourseCount = this.metricsBundle.summary.completedCourseCount;
    this.membersCount = this.metricsBundle.usersInfo.length;
    this.totalCardsCount = this.metricsBundle.summary.totalCards;
  }

  private updateEventMetrics(): void {
    if (!this.metricsBundle) {
      return;
    }

    const registrations = this.metricsBundle.cardMemberMetrics.cardEventRegistrations || [];

    const isValidRegistration = (reg: CardEventRegistrations) =>
      reg?.userEventSummary != null && (this.selectedEventId === null || reg.eventCardId === this.selectedEventId);

    const countByField = (field: keyof (typeof registrations)[0]['userEventSummary']) =>
      registrations.filter((reg) => isValidRegistration(reg) && reg.userEventSummary?.[field] !== null).length;

    this.eventCardCount = this.selectedEventId !== null ? 1 : this.metricsBundle.cardEventsInfo.length || 0;

    this.registeredUsers = countByField('registeredOn');
    this.participatedUsers = countByField('participatedOn');
    this.waitlistedUsers = countByField('waitlistedOn');

    this.updateDailyEnrollmentCounts();
  }

  private updateDailyEnrollmentCounts(): void {
    const registrations = this.metricsBundle?.cardMemberMetrics?.cardEventRegistrations || [];

    this.registrationDatesToCounts = registrations.reduce(
      (acc, { eventCardId, userEventSummary }) => {
        const { registeredOn } = userEventSummary || {};

        if (registeredOn && (this.selectedEventId === null || eventCardId === this.selectedEventId)) {
          const date = new Date(registeredOn).toISOString().split('T')[0];
          acc[date] = (acc[date] || 0) + 1;
        }

        return acc;
      },
      {} as Record<string, number>,
    );

    if (this.selectedEventId !== null) {
      this.eventIdsToRegistrationCounts = null;
      return;
    }

    this.eventIdsToRegistrationCounts = registrations.reduce(
      (acc, { eventCardId, userEventSummary }) => {
        const { registeredOn } = userEventSummary || {};

        if (registeredOn) {
          acc[eventCardId] = (acc[eventCardId] || 0) + 1;
        } else if (!(eventCardId in acc)) {
          acc[eventCardId] = 0;
        }

        return acc;
      },
      {} as Record<string, number>,
    );
  }

  private getRefreshActionType(filterType: BooleanFilterType): RefreshActionType {
    if (filterType === 'ATTENDANCE') {
      return 'REFRESH_EVENTS';
    } else {
      return 'REFRESH_COURSES';
    }
  }
}
