/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { CardClickData } from '../../models/page/page.model';
import { ResourcePublishedStatus } from '../../models';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageCodeHelper } from '../../helpers/language-code-helper';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { ProjectHelper } from '../../../page-modules/project/helpers/project.helper';

@Component({
  selector: 'ptl-playlist-featured-card',
  templateUrl: './playlist-featured-card.component.html',
  styleUrls: ['./playlist-featured-card.component.scss'],
})
export class PlaylistFeaturedCardComponent implements OnInit, OnChanges {
  @Input() type: Type;
  /** Card data */
  @Input() card;
  /** Card index in cards data which was got from API. */
  @Input() index: number;
  /** Cards layout type. */
  @Input() cardsLayoutType;
  @Input() size: CardSize;
  @Input() inHomeLayoutEditor: boolean;
  @Input() treatAsPlaylistSectionCard: boolean;
  @Input() disabled = false;
  @Input() isFolioEditMode = false;
  @Input() projectVisible = false;

  /** Emits when a card is removed. */
  @Output() cardRemove = new EventEmitter<string>();
  /** Emits on headline or image click. */
  @Output() cardClick = new EventEmitter<CardClickData>();
  /** Emits on menu duplicate click */
  @Output() cardDuplicate = new EventEmitter();
  /** Emits on folio project visibility change */
  @Output() projectVisibilityChanged = new EventEmitter();

  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };

  cardSubheader: string;
  completedCount: number;
  totalCount: number;
  cardStatus: ResourcePublishedStatus;
  isProject = false;
  isPublicProjectView = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    this.isProject = ProjectHelper.isProjectByUrl(this.router);
    this.isPublicProjectView = ProjectHelper.isProjectPublicView();
    this.completedCount = this.getCardCompletedCount();
    this.totalCount = this.getCardTotalCount();
    this.cardSubheader = this.getCardSubheader();
    this.cardStatus = this.getCardStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cardStatus = this.getCardStatus();
  }

  getCardSubheader(): string {
    return this.card?.subHeader ? LanguageCodeHelper.getDataByLanguageCode(this.card?.subHeader).value.trim() : '';
  }

  getCardStatus(): ResourcePublishedStatus {
    const status = this.card?.status;
    if (typeof this.card?.status === 'string') {
      return status;
    }
    return status ? status.type : undefined;
  }

  onCardClick() {
    if (!this.disabled) {
      this.cardClick.emit({
        playlistUri: this.card.playlistUri,
        uri: this.card.uri,
        _id: this.card._id ? this.card._id : this.card.id,
        playlistUid: this.card.playlistId ? this.card.playlistId : this.card._id,
        formattedUri: this.card.formattedUri,
      });
    }
  }

  onRemoveClick() {
    this.cardRemove.next(this.card.playlistId);
  }

  onContextMenu(event: MouseEvent) {
    if (this.card?.uri && !this.treatAsPlaylistSectionCard) {
      event.preventDefault();
      const target = event.currentTarget as HTMLElement;
      const rect = target.getBoundingClientRect();
      this.contextMenuPosition.x = event.clientX - rect.x + 'px';
      this.contextMenuPosition.y = event.clientY - rect.y + 'px';
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
  }

  get cardTitle(): string {
    return this.card?.header ?? this.card?.title;
  }

  onOpenInNewTab() {
    if (this.card) {
      RedirectHelper.redirectByParams(
        this.ngZone,
        this.router,
        this.activatedRoute,
        {
          playlistUri: this.card.uri,
          formattedUri: this.card.formattedUri,
        },
        'PLAYLIST',
        '_blank',
      );
    }
  }

  onProjectVisibilityChanged() {
    this.projectVisibilityChanged.emit();
  }

  private getCardCompletedCount() {
    if (this.card?.completedItemsCount !== undefined) {
      return this.card?.completedItemsCount;
    }
    if (this.card?.completedCardsCount !== undefined) {
      return this.card?.completedCardsCount;
    }
    return 0;
  }

  private getCardTotalCount() {
    if (this.card?.totalItemsCount !== undefined) {
      return this.card?.totalItemsCount;
    }
    if (this.card?.totalCardsCount !== undefined) {
      return this.card?.totalCardsCount;
    }
    return 0;
  }
}

type Type = 'VIEW' | 'EDIT' | 'FOLIO_PROJECT';
type CardSize = 'STANDARD' | 'SLIM';
