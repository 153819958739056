/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { EditorContent, EditorContentRemovalEvent, EditorContentUpdateEvent } from '@app/app/shared/models';

@Component({
  selector: 'ptl-grid-question-type',
  templateUrl: './grid-question-type.component.html',
  styleUrls: ['../add-question-section-new.component.scss', './grid-question-type.component.scss'],
})
export class GridQuestionTypeComponent implements OnChanges {
  @Input() question: FormGroup;
  @Input() languageCode: string | undefined;
  @Input() isDefaultLanguage: boolean;

  gridOptionsPerRow: number[] = [1, 2, 3, 4, 5];
  isNoneOfTheseActive: boolean;

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
  ) {}

  removeOption(index: number) {
    if (this.question.value.options[index].deselectOthers) {
      this.isNoneOfTheseActive = false;
    }
    this.questionOptions.removeAt(index);
  }

  addNewOption(focus: boolean = false) {
    this.questionOptions.push(this.addGridOption());
    this.sortQuestionOptions();

    if (focus) {
      setTimeout(() => {
        const lastElement = this.question.controls['options']['controls'].length - 1;
        const inputElement = this.renderer.selectRootElement(`#input${lastElement}`);

        inputElement.focus();
      }, 500);
    }
  }

  dropOption(moveEvent: CdkDragDrop<FormArray>) {
    moveItemInArray(this.questionOptions.controls, moveEvent.previousIndex, moveEvent.currentIndex);
    this.sortQuestionOptions();
    this.questionOptions.updateValueAndValidity();
  }

  onNoneOfTheseSelected() {
    if (this.isNoneOfTheseActive) {
      this.questionOptions.push(this.addNoneOfTheseOption());
    } else {
      this.removeNoneOfTheseOption();
    }
  }

  ngOnChanges() {
    if (this.question) {
      this.populateForm();
      this.checkIfNoneOfTheseExists(this.question);
    }
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  makeEditableFieldActive(item: any, event: Event, isActive: boolean = true) {
    if (!isActive) {
      item.editable = false;
      return;
    }

    item.editable = true;
    const targetElement = event.target as Element;
    const questionTitleContainer = targetElement.closest('.f_add-question-section-title');

    if (!questionTitleContainer) {
      return;
    }

    setTimeout(() => {
      const questionTitleInput = questionTitleContainer.querySelector('.f_question-title-input') as HTMLElement;
      questionTitleInput?.focus();
    }, 0);
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const maxOptions = this.question.controls['options']['controls']?.length || 0;

    if (Number(inputElement.value) > maxOptions) {
      inputElement.value = String(maxOptions);
      this.question.get('selectionLimit').setValue(maxOptions);
    }
  }

  onContentUpdate({ index, update }: EditorContentUpdateEvent, level: EditorContent[], questionIndex: number) {
    const content = level.map((editorContent, i) => (i === index ? update.newContent : editorContent));
    const optionsArray = this.question.get('options') as FormArray;
    const optionControl = optionsArray.at(questionIndex) as FormGroup;

    if (update.newContent.content === '<p><br></p>') {
      optionControl.patchValue({ instructions: [{ type: 'PARAGRAPH', content: '' }] });
      return;
    }

    optionControl.patchValue({ instructions: content });
  }

  onContentRemoval({ index }: EditorContentRemovalEvent, level: EditorContent[], questionIndex: number) {
    const optionsArray = this.question.get('options') as FormArray;
    const optionControl = optionsArray.at(questionIndex) as FormGroup;
    optionControl.patchValue({ instructions: [{ type: 'PARAGRAPH', content: '' }] });
  }

  private populateForm() {
    // if no uid, clear current arrays and add a blank one
    if (!this.question.value.uid) {
      while (this.questionOptions.length !== 0) {
        this.questionOptions.removeAt(0);
      }
      this.addNewOption();
    }
  }

  private addGridOption(): FormGroup {
    const dynamicContentRequest = this.fb.array([
      this.fb.group({
        content: '',
        type: 'PARAGRAPH',
        uid: '',
      }),
    ]);

    return this.fb.group({
      type: 'CHOICE_OPTION',
      value: this.fb.group({
        languageCode: this.languageCode,
        value: [undefined, Validators.required],
      }),
      instructions: dynamicContentRequest,
      deselectOthers: false,
      score: [undefined, Validators.required],
    });
  }

  private get questionOptions() {
    return this.question.get('options') as FormArray;
  }

  private addNoneOfTheseOption() {
    const dynamicContentRequest = this.fb.array([
      this.fb.group({
        content: '',
        type: 'PARAGRAPH',
        uid: '',
      }),
    ]);

    return this.fb.group({
      type: 'CHOICE_OPTION',
      value: this.fb.group({
        languageCode: this.languageCode,
        value: ['None of these', Validators.required],
      }),
      instructions: dynamicContentRequest,
      deselectOthers: true,
      score: [0, Validators.required],
    });
  }

  private sortQuestionOptions() {
    this.questionOptions.controls.sort((a, b) => a.value.deselectOthers - b.value.deselectOthers);
  }

  private removeNoneOfTheseOption() {
    const options = this.question.value.options;
    for (let i = 0; i < options.length; i++) {
      if (options[i].deselectOthers) {
        this.removeOption(i);
        break;
      }
    }
  }

  private checkIfNoneOfTheseExists(question: FormGroup) {
    const options = question.value.options;
    for (const option of options) {
      if (option.deselectOthers) {
        this.isNoneOfTheseActive = true;
        break;
      }
    }
  }
}
