/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

/* eslint-disable max-len */
/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
import { InjectionToken } from '@angular/core';
import { ObservableResult } from '../../store';
import { CreateLanguage, Language, OrganizationLanguageSummary } from '../../models/languages/languages.model';

export const LANGUAGES_DATA_SERVICE = new InjectionToken<LanguagesDataService>('[Languages] LanguagesDataService');

/** The service handling competence CRUD operations. */
export interface LanguagesDataService {
  getLanguages(): ObservableResult<Language[]>;

  getActiveOrganizationLanguages(organizationId: string): ObservableResult<Language[]>;

  getOrganizationLanguages(organizationId: string): ObservableResult<OrganizationLanguageSummary>;

  createOrganizationLanguages(organizationId: string, code: CreateLanguage): ObservableResult<OrganizationLanguageSummary>;

  deleteOrganizationLanguages(organizationId: string, code: string): ObservableResult<void>;

  languageActivation(organizationId: string, code: string, isActive: boolean): ObservableResult<void>;

  setDefaultLanguage(organizationId: string, code: string): ObservableResult<void>;
}
