/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult, SuccessResult } from '../../shared/store';
import { Injectable } from '@angular/core';
import { EmbeddableContentDetails, EmbedsContentGeneratorService } from './embeds-content-generator.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const IFRAMELY_PARAMS = ['_horizontal=true', 'iframe=card-small'].join('&');

/** Integates with Iframely's API. */
@Injectable()
export class IframelyEmbedsContentGeneratorService implements EmbedsContentGeneratorService {
  constructor(private httpClient: HttpClient) {}

  retrieveEmbeddableContentDetails(sourceUrl: string, isFromModalCardEditor = false): ObservableResult<EmbeddableContentDetails> {
    const isPanopto = sourceUrl.toLowerCase().indexOf('panopto') !== -1;
    const iframelyUrl = isPanopto
      ? `https://iframe.ly/api/oembed?api_key=${environment.iframelyApiKey}&url=${sourceUrl}`
      : `https://iframe.ly/api/iframely?api_key=${environment.iframelyApiKey}&url=${sourceUrl}&${IFRAMELY_PARAMS}`;
    return this.httpClient.get<EmbeddableContentDetails>(iframelyUrl).pipe(
      map((data) => new SuccessResult(this.getFormattedEmbeddedContent(data, isPanopto, isFromModalCardEditor))),
      catchError((error) => this.getObservableResultErrorJSONString(error.status, error?.message, this.getIframelyCreateError)),
    );
  }

  private getFormattedEmbeddedContent(data, isPanopto, isFromModalCardEditor = false): EmbeddableContentDetails {
    if (!isPanopto) {
      return {
        url: data.url,
        authorName: data.meta.author,
        logoUrl: data.links.icon ? data.links.icon[0].href : undefined,
        title: data.meta.title ? data.meta.title : 'External media',
        description: data.meta.description,
        thumbnailUrl: data.links.thumbnail ? data.links.thumbnail[0].href : data.links.image ? data.links.image[0].href : undefined,
        tags: data.meta.keywords ? this.extractTagsFromKeywords(data.meta.keywords) : [],
        publicationDate: data.meta.date ? new Date(data.meta.date) : undefined,
        siteName: data.meta.site,
        html:
          data.links && data.links.player
            ? data.links.player[0].html
            : this.getHtmlContent(data.html, data.links.summary, data.url, isFromModalCardEditor),
      };
    } else {
      return {
        url: data.url,
        authorName: data.provider_name,
        logoUrl: undefined,
        title: data.title ? data.title : 'External media',
        description: '',
        thumbnailUrl: data.thumbnail_url ? data.thumbnail_url : undefined,
        tags: [],
        publicationDate: undefined,
        siteName: '',
        html: data.html,
      };
    }
  }

  private extractTagsFromKeywords(keywords: string): string[] {
    let keywordTagStartIndex = 0;
    return keywords
      .split(',')
      .splice(0, 3)
      .filter((keyword) => keyword.trim())
      .map((keyword) => {
        keywordTagStartIndex = keyword.indexOf(':');

        return keyword.substring(keywordTagStartIndex + 1).trim();
      });
  }

  private getHtmlContent(html, summaryHtml, link, isFromModalCardEditor = false): string {
    if (html) {
      return html;
    }
    if (summaryHtml) {
      return summaryHtml[0].html;
    }
    if (link.indexOf('potential.ly') !== -1 || isFromModalCardEditor) {
      return '<a class="medium no-content" href=' + link + ' target="_blank">' + link + '</a>';
    }
    throw new Error('No content found');
  }

  private getIframelyCreateError(errorStatus: number, errorMessage: string) {
    if (errorStatus === 404) {
      return 'URL not found or embed is restricted by publisher.';
    }
    return errorMessage;
  }

  private getObservableResultErrorJSONString(errorStatus: number, errorMessage: string, errorGetterFunction: (number, string) => string) {
    return ObservableResult.ofError(errorGetterFunction(errorStatus, errorMessage));
  }
}
