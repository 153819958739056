/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { MembersDataService } from './members-data.service';
import { ObservableResult } from '../../../../shared/store';
import { RestClientService } from '../../../../shared/services/rest-client.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import {
  MemberRecord,
  MemberRecordLearnerPlaylistCardsResponse,
  MemberRecordLearnerPlaylist,
  MemberRecordPlaylistsRequest,
  MembersSearchResponse,
  MemberRecordPlaylistCardResponse,
  MemberInvitationResponse,
  MemberInvitationRequest,
  UserSearch,
  MembersInvitationBulkResponse,
  ValidateEmailsFromCsvFileResponse,
} from '../../../../shared/models/admin/members.model';
import { Page } from '../../../../shared/models/page';
import { MemberReview } from '../../../../shared/models';
import { TranslocoService } from '@ngneat/transloco';
import { StatsData, StatsSummary } from '../../../../shared/models/analytics/analytics.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MembersBooleanSearchRequest, ReviewsSearchRequest } from '../../../../shared/models/admin/boolean-filters.model';
import {
  AssessmentDatapointMetrics,
  AssessmentMemberMetricsSummary,
} from '@app/app/page-modules/resource/components/editor/analytics/assessment-analytics.model';
import {
  OrganizationAllTimeMetrics,
  OrganizationMembersMetrics,
  OrganizationPageSummary,
} from '@app/app/page-modules/admin/components/analytics/admin-analytics.model';
import { marker } from '@jsverse/transloco-keys-manager/marker';

@Injectable()
export class ApiMembersDataService implements MembersDataService {
  constructor(
    private restClient: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  addMembersToGroup(userGroupUid: string, request: string[]): ObservableResult<void> {
    return this.restClient
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `users/groups/${userGroupUid}/assign`), request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAddMemberToGroup'))),
      );
  }

  removeMembersFromGroup(userGroupUid: string, request: string[]): ObservableResult<void> {
    return this.restClient
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `users/groups/${userGroupUid}/unassign`), request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRemoveMemberFromGroup'))),
      );
  }

  loadMemberRecord(userUid?: string, playlistUid?: string): ObservableResult<MemberRecord> {
    const url = userUid ? (playlistUid ? `records/${userUid}?playlistUid=${playlistUid}` : `records/${userUid}`) : 'records';
    return this.restClient.get<MemberRecord>(Location.joinWithSlash(environment.apiRootUrl || '', url)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetMemberRecord'))),
    );
  }

  getLearnerPlaylistCards(learnerPlaylistId: string): ObservableResult<MemberRecordLearnerPlaylistCardsResponse> {
    return this.restClient
      .get<MemberRecordLearnerPlaylistCardsResponse>(
        Location.joinWithSlash(environment.apiRootUrl || '', `playlists/users/${learnerPlaylistId}`),
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetMemberPlaylistCards'))),
      );
  }

  getPlaylistLearnerCardsByUserId(playlistUid: string, userUid: string): ObservableResult<MemberRecordPlaylistCardResponse[]> {
    return this.restClient
      .get<
        MemberRecordPlaylistCardResponse[]
      >(Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/users/${userUid}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetMemberPlaylistCards'))),
      );
  }

  getLearnerCard(playlistUid: string, userUid: string, cardUid: string): ObservableResult<MemberRecordPlaylistCardResponse> {
    return this.restClient
      .get<MemberRecordPlaylistCardResponse>(
        Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/users/${userUid}/cards/${cardUid}`),
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetMemberPlaylistCard'))),
      );
  }

  getLearnerCardForReviewer(userUid: string, cardUid: string): ObservableResult<MemberRecordPlaylistCardResponse> {
    return this.restClient
      .get<MemberRecordPlaylistCardResponse>(
        Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/users/${userUid}/reviewer`),
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetMemberPlaylistCard'))),
      );
  }

  getMemberPlaylistsByType(userUid: string, request: MemberRecordPlaylistsRequest): ObservableResult<Page<MemberRecordLearnerPlaylist>> {
    return this.restClient
      .get<
        Page<MemberRecordLearnerPlaylist>
      >(Location.joinWithSlash(environment.apiRootUrl || '', `playlists/users/${request.type}/${userUid}?page=${request.page}&size=${request.pageSize}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() =>
          ObservableResult.ofError(
            this.translocoService.translate(
              request.type === 'completed'
                ? marker('translations.errors.errorGetMemberPlaylistCompleted')
                : marker('translations.errors.errorGetMemberPlaylistUnCompleted'),
            ),
          ),
        ),
      );
  }

  searchNonAdminUsers(page: number, pageSize: number, searchString: string): ObservableResult<Page<UserSearch>> {
    return this.restClient
      .get<
        Page<UserSearch>
      >(Location.joinWithSlash(environment.apiRootUrl || '', `users/search/nonadmins?name=${searchString}&page=${page}&size=${pageSize}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetMembers'))),
      );
  }

  searchUsers(page: number, pageSize: number, searchString: string, organization?: string): ObservableResult<Page<UserSearch>> {
    const params = {
      name: searchString,
      page: page.toString(),
      size: pageSize.toString(),
    };
    if (organization) {
      params['organization'] = organization;
    }
    return this.restClient.get<Page<UserSearch>>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/search/name'), params).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetMembers'))),
    );
  }

  getAllInvitationsList(page: number, pageSize: number, email?: string): ObservableResult<Page<MemberInvitationResponse>> {
    const url = `users/invitations?page=${page}&size=${pageSize}` + (email ? `&email=${encodeURIComponent(email)}` : '');
    return this.restClient.get<Page<MemberInvitationResponse>>(Location.joinWithSlash(environment.apiRootUrl || '', url)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetInvitations'))),
    );
  }

  getAcceptedInvitationsList(page: number, pageSize: number): ObservableResult<Page<MemberInvitationResponse>> {
    return this.restClient
      .get<
        Page<MemberInvitationResponse>
      >(Location.joinWithSlash(environment.apiRootUrl || '', `users/invitations/accepted?page=${page}&size=${pageSize}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetAcceptedInvitations'))),
      );
  }

  getNotAcceptedInvitationsList(page: number, pageSize: number): ObservableResult<Page<MemberInvitationResponse>> {
    return this.restClient
      .get<
        Page<MemberInvitationResponse>
      >(Location.joinWithSlash(environment.apiRootUrl || '', `users/invitations/invited?page=${page}&size=${pageSize}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetNotAcceptedInvitations'))),
      );
  }

  inviteNewMembers(request: MemberInvitationRequest): ObservableResult<MembersInvitationBulkResponse> {
    return this.restClient
      .post<MembersInvitationBulkResponse>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/invitations'), request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorInviteNewMembers'))),
      );
  }

  importNewMembers(
    csvFile: File,
    groupIdsQueryParam: string,
    playlistIdsQueryParam: string,
  ): ObservableResult<MembersInvitationBulkResponse> {
    const formData = new FormData();
    formData.append('file', csvFile);
    return this.restClient
      .post<MembersInvitationBulkResponse>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/invitations/upload'), formData, {
        groups: groupIdsQueryParam ? groupIdsQueryParam : null,
        playlists: playlistIdsQueryParam ? playlistIdsQueryParam : null,
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorInviteNewMembers'))),
      );
  }

  resendInvitation(invitationId: string): ObservableResult<void> {
    return this.restClient
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `users/invitations/${invitationId}/resend`))
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorResendInvitation'))),
      );
  }

  validateUsersFromCsvFile(csvFile: File, playlistUid: string): ObservableResult<ValidateEmailsFromCsvFileResponse> {
    const formData = new FormData();
    formData.append('file', csvFile);
    return this.restClient
      .post<ValidateEmailsFromCsvFileResponse>(
        Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/validate/users/email/csv`),
        formData,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorValidateUsersFromCsvFile'))),
      );
  }

  getActiveUsersNowCount(): ObservableResult<number> {
    const url = `${Location.joinWithSlash(environment.apiRootUrl || '', 'analytics/metrics/active-users-now')}`;

    return this.restClient.get<number>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetNewMembersStatistics'))),
    );
  }

  getOrganizationAllTimeMetrics(): ObservableResult<OrganizationAllTimeMetrics> {
    const url = `${Location.joinWithSlash(environment.apiRootUrl || '', 'analytics/metrics/all-time')}`;

    return this.restClient.get<OrganizationAllTimeMetrics>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetNewMembersStatistics'))),
    );
  }

  getOrganizationPagesSummary(): ObservableResult<OrganizationPageSummary[]> {
    const url = `${Location.joinWithSlash(environment.apiRootUrl || '', 'analytics/metrics/pages')}`;

    return this.restClient.get<OrganizationPageSummary[]>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetNewMembersStatistics'))),
    );
  }

  getOrganizationMembersMetrics(
    request: MembersBooleanSearchRequest,
    from: string,
    to: string,
  ): ObservableResult<OrganizationMembersMetrics> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '',
      `analytics/metrics?startDate=${encodeURIComponent(from)}&endDate=${encodeURIComponent(to)}`,
    );

    return this.restClient.post<OrganizationMembersMetrics>(url, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetNewMembersStatistics'))),
    );
  }

  getStatisticsNewMembers(from: string, to: string): ObservableResult<StatsData[]> {
    return this.restClient
      .get<StatsData[]>(Location.joinWithSlash(environment.apiRootUrl || '', `analytics/members/stats/new?from=${from}&to=${to}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetNewMembersStatistics'))),
      );
  }

  getStatisticsActiveMembers(from: string, to: string): ObservableResult<StatsData[]> {
    return this.restClient
      .get<StatsData[]>(Location.joinWithSlash(environment.apiRootUrl || '', `analytics/members/stats/active?from=${from}&to=${to}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetActiveMembersStatistics'))),
      );
  }

  getStatisticsSummary(): ObservableResult<StatsSummary> {
    return this.restClient.get<StatsSummary>(Location.joinWithSlash(environment.apiRootUrl || '', 'analytics/members/stats/summary')).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetStatisticsSummary'))),
    );
  }

  private handleErrorResponse(err: HttpErrorResponse, errorMessage: string) {
    if (err.status === 403) {
      return ObservableResult.ofError('403');
    }
    return ObservableResult.ofError(errorMessage);
  }

  searchMembers(request: MembersBooleanSearchRequest, page: number, size: number): ObservableResult<MembersSearchResponse> {
    return this.restClient
      .post<MembersSearchResponse>(
        Location.joinWithSlash(environment.apiRootUrl || '', `analytics/members/search/bool?page=${page}&size=${size}`),
        request,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) => this.handleErrorResponse(err, this.translocoService.translate('translations.errors.errorGetMembers'))),
      );
  }

  searchCardMembers(
    request: MembersBooleanSearchRequest,
    playlistUid: string,
    cardUid: string,
    page: number,
    size: number,
  ): ObservableResult<MembersSearchResponse> {
    return this.restClient
      .post<MembersSearchResponse>(
        Location.joinWithSlash(
          environment.apiRootUrl || '',
          `analytics/members/search/bool/playlists/${playlistUid}/cards/${cardUid}?page=${page}&size=${size}`,
        ),
        request,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) => this.handleErrorResponse(err, this.translocoService.translate('translations.errors.errorGetMembers'))),
      );
  }

  searchPlaylistMembers(
    request: MembersBooleanSearchRequest,
    playlistUid: string,
    page: number,
    size: number,
  ): ObservableResult<MembersSearchResponse> {
    return this.restClient
      .post<MembersSearchResponse>(
        Location.joinWithSlash(
          environment.apiRootUrl || '',
          `analytics/members/search/bool/playlists/${playlistUid}?page=${page}&size=${size}`,
        ),
        request,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) => this.handleErrorResponse(err, this.translocoService.translate('translations.errors.errorGetMembers'))),
      );
  }

  booleanExportMembers(request: MembersBooleanSearchRequest): ObservableResult<string> {
    return this.restClient
      .post<string>(Location.joinWithSlash(environment.apiRootUrl || '', 'analytics/members/export/bool'), request, {}, {}, 'text')
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorExportMembers'))),
      );
  }

  booleanExportPlaylistMembers(request: MembersBooleanSearchRequest, playlistUid: string): ObservableResult<string> {
    return this.restClient
      .post<string>(
        Location.joinWithSlash(environment.apiRootUrl || '', `analytics/members/export/bool/playlists/${playlistUid}`),
        request,
        {},
        {},
        'text',
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorExportPlaylistMembers'))),
      );
  }

  booleanExportCardMembers(request: MembersBooleanSearchRequest, playlistUid: string, cardUid: string): ObservableResult<string> {
    return this.restClient
      .post<string>(
        Location.joinWithSlash(environment.apiRootUrl || '', `analytics/members/export/bool/playlists/${playlistUid}/cards/${cardUid}`),
        request,
        {},
        {},
        'text',
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorExportCardMembers'))),
      );
  }

  booleanExportEventCardMembers(request: MembersBooleanSearchRequest, playlistUid: string, eventUid: string): ObservableResult<string> {
    return this.restClient
      .post<string>(
        Location.joinWithSlash(environment.apiRootUrl || '', `analytics/members/export/bool/playlists/${playlistUid}/event/${eventUid}`),
        request,
        {},
        {},
        'text',
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorExportEventCardMembers'))),
      );
  }

  searchPlaylistReviews(
    request: ReviewsSearchRequest,
    playlistUid: string,
    page: number,
    size: number,
  ): ObservableResult<Page<MemberReview>> {
    return this.restClient
      .post<
        Page<MemberReview>
      >(Location.joinWithSlash(environment.apiRootUrl || '', `analytics/reviews/playlists/${playlistUid}/search?page=${page}&size=${size}`), request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) => this.handleErrorResponse(err, this.translocoService.translate('translations.errors.errorGetMembers'))),
      );
  }

  searchCardReviews(request: ReviewsSearchRequest, cardUid: string, page: number, size: number): ObservableResult<Page<MemberReview>> {
    return this.restClient
      .post<
        Page<MemberReview>
      >(Location.joinWithSlash(environment.apiRootUrl || '', `analytics/reviews/cards/${cardUid}/search?page=${page}&size=${size}`), request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) => this.handleErrorResponse(err, this.translocoService.translate('translations.errors.errorGetMembers'))),
      );
  }

  getAssessmentMetrics(
    playlistUid: string,
    cardUid: string,
    from: string,
    to: string,
    request: MembersBooleanSearchRequest,
  ): ObservableResult<AssessmentDatapointMetrics> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '',
      `analytics/metrics/playlists/${playlistUid}/cards/${cardUid}?startDate=${encodeURIComponent(from)}&endDate=${encodeURIComponent(to)}`,
    );

    return this.restClient.post<AssessmentDatapointMetrics>(url, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGettingCardStatistics'))),
    );
  }

  getAssessmentMetricsSummary(
    playlistUid: string,
    cardUid: string,
    request: MembersBooleanSearchRequest,
  ): ObservableResult<AssessmentMemberMetricsSummary> {
    return this.restClient
      .post<AssessmentMemberMetricsSummary>(
        Location.joinWithSlash(environment.apiRootUrl || '', `analytics/metrics/playlists/${playlistUid}/cards/${cardUid}/summary`),
        request,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGettingCardStatistics'))),
      );
  }
}
