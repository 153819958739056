/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaUrlData } from '../../../models';

@Component({
  selector: 'ptl-resource-audio-preview',
  templateUrl: './audio-preview.component.html',
  styleUrls: ['./audio-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceAudioPreviewComponent {
  /** Receives the EditorContent object */
  @Input() content!: MediaUrlData;

  downloadInProgress = false;

  openUrl() {
    const content = this.content;

    if (content.url) {
      window.open(content.url, content.openUrlInTab ? '_blank' : '_self');
    }
  }
}
