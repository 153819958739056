<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  id="addQuestionContainer"
  class="add-question"
  [ngClass]="{ 'questions__is_floating-content': isEditingQuestion, 'quiz-question-container': isQuiz }"
>
  <form class="add-question__form" [formGroup]="questionForm" (ngSubmit)="saveQuestion()">
    <div
      class="question-editing-title"
      *ngIf="!currentLanguage?.isDefault"
      [innerHTML]="'translations.editingQuestionInLanguage' | transloco: { name: currentLanguage?.language?.name }"
    ></div>

    <div class="add-new-question-select-box" *ngIf="newQuestion">
      <mat-form-field class="add-new-question-select" appearance="outline">
        <mat-select formControlName="type" [placeholder]="'Select Question Type'">
          <mat-select-trigger style="width: 100%">
            <img
              class="mat-select-icon"
              [attr.src]="prettyStatus[questionForm.value.type].image"
              width="20"
              height="20"
              alt="Option image"
            />
            {{ prettyStatus[questionForm.value.type].name }}
          </mat-select-trigger>
          <mat-option value="CHOICE_QUESTION" *ngIf="activeTypes['CHOICE_QUESTION']">
            <img class="mat-select-icon" src="../../../../../../../assets/icons/circles.svg" width="20" height="20" alt="Option image" />
            <span tabindex="0" [attr.aria-label]="'translations.global.ariaLabels.ratingLikert' | transloco">
              {{ 'translations.ratingLikert' | transloco }}</span
            >
          </mat-option>
          <mat-option value="RATING_QUESTION" *ngIf="activeTypes['RATING_QUESTION']">
            <img class="mat-select-icon" src="../../../../../../../assets/icons/circles.svg" width="20" height="20" alt="Option image" />
            <span tabindex="0" [attr.aria-label]="'translations.global.ariaLabels.trueFalse' | transloco">
              {{ 'translations.trueFalse' | transloco }}</span
            >
          </mat-option>
          <mat-option value="MULTIPLE_CHOICE_QUESTION" *ngIf="activeTypes['MULTIPLE_CHOICE_QUESTION']">
            <img
              class="mat-select-icon"
              [src]="'../../../../../../../assets/icons/new/list-bullet.svg'"
              width="20"
              height="20"
              alt="Option image"
            />
            <span tabindex="0" [attr.aria-label]="'translations.global.ariaLabels.multipleChoice' | transloco">
              {{ 'translations.multipleChoice' | transloco }}</span
            >
          </mat-option>
          <mat-option value="SLIDER_QUESTION" *ngIf="activeTypes['SLIDER_QUESTION']">
            <img class="mat-select-icon" src="../../../../../../../assets/icons/line.svg" width="20" height="20" alt="Option image" />
            <span tabindex="0" [attr.aria-label]="'translations.global.ariaLabels.slider' | transloco">
              {{ 'translations.slider' | transloco }}</span
            >
          </mat-option>
          <!--TODO: Hidden until DRAGDROP_QUESTION logic gets fixed and redesigned -->
          <!--          <mat-option value="DRAGDROP_QUESTION" *ngIf="activeTypes['DRAGDROP_QUESTION']">
            <img class="mat-select-icon"
                 [src]="'../../../../../../../assets/icons/new/drag-drop.svg'"
                 width="20"
                 height="20"
                 alt="Option image">
            <span
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.dragAndDrop' | transloco">
                {{ 'translations.dragAndDrop' | transloco }}</span>
          </mat-option>-->
          <mat-option value="GRID_QUESTION" *ngIf="activeTypes['GRID_QUESTION']">
            <img class="mat-select-icon" src="../../../../../../../assets/icons/grid.svg" width="20" height="20" alt="Option image" />
            <span tabindex="0" [attr.aria-label]="'translations.global.ariaLabels.grid' | transloco">
              {{ 'translations.grid' | transloco }}</span
            >
          </mat-option>
          <!-- info question -->
          <mat-option value="INFO_QUESTION" *ngIf="activeTypes['INFO_QUESTION']">
            <img
              class="mat-select-icon"
              src="../../../../../../../assets/icons/info-circle.svg"
              width="20"
              height="20"
              alt="Option image"
            />
            <span tabindex="0" [attr.aria-label]="'translations.global.ariaLabels.info' | transloco">
              {{ 'translations.info' | transloco }}</span
            >
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="quiz-instruction-box" formGroupName="instruction">
      <h2 class="small bold title" tabindex="0" [attr.aria-label]="'translations.global.ariaLabels.newQuestion' | transloco">
        {{ 'translations.newQuestion' | transloco }}
      </h2>
      <ptl-editor
        class="quiz-instruction-textarea no-outline"
        [class.has-error]="questionEmpty"
        [class.focused]="ptlFocused"
        [link]="false"
        [upload]="false"
        [separator]="false"
        [content]="[content]"
        (inputFocus)="ptlFocused = true"
        (inputBlur)="ptlFocused = false"
        (contentUpdate)="onMediumEditorUpdate($event)"
      >
      </ptl-editor>
      <div class="mat-error question-error" *ngIf="questionEmpty">
        {{ 'translations.questionRequiredError' | transloco }}
      </div>
    </div>

    <div class="add-question__section">
      <ptl-group-question-type
        *ngIf="questionForm.value.type === 'INFO_QUESTION'"
        [question]="questionForm"
        (mediumEditorChanged)="onInfoQuestionValueChange($event)"
      >
      </ptl-group-question-type>

      <ptl-grid-question-type
        *ngIf="questionForm.value.type === 'GRID_QUESTION'"
        [languageCode]="languageCode"
        [isDefaultLanguage]="currentLanguage?.isDefault"
        [question]="questionForm"
      >
      </ptl-grid-question-type>

      <ptl-quiz-slider-question-type
        *ngIf="questionForm.value.type === 'SLIDER_QUESTION'"
        [isQuiz]="isQuiz"
        [isDefaultLanguage]="currentLanguage?.isDefault"
        [languageCode]="languageCode"
        [question]="questionForm"
      >
      </ptl-quiz-slider-question-type>

      <ptl-quiz-drag-drop-question-type
        *ngIf="questionForm.value.type === 'DRAGDROP_QUESTION'"
        [isDefaultLanguage]="currentLanguage?.isDefault"
        [languageCode]="languageCode"
        [isQuiz]="isQuiz"
        [question]="questionForm"
      >
      </ptl-quiz-drag-drop-question-type>

      <ptl-choice-question-type
        *ngIf="questionForm.value.type === 'CHOICE_QUESTION'"
        [languageCode]="languageCode"
        [isDefaultLanguage]="currentLanguage?.isDefault"
        [isEditingForm]="isEditingQuestion"
        [question]="questionForm"
      >
      </ptl-choice-question-type>

      <ptl-app-true-or-false-choice-question-type
        *ngIf="questionForm.value.type === 'RATING_QUESTION'"
        [languageCode]="languageCode"
        [isDefaultLanguage]="currentLanguage?.isDefault"
        [question]="questionForm"
      >
      </ptl-app-true-or-false-choice-question-type>

      <ptl-multiple-choice-question-type
        *ngIf="questionForm.value.type === 'MULTIPLE_CHOICE_QUESTION'"
        [isDefaultLanguage]="currentLanguage?.isDefault"
        [languageCode]="languageCode"
        [question]="questionForm"
      >
      </ptl-multiple-choice-question-type>
    </div>

    <div class="feedback-container" formGroupName="feedback" *ngIf="isQuiz">
      <div class="feedback-container-title">
        <h2 class="small bold title" tabindex="0" [attr.aria-label]="'translations.global.ariaLabels.feedback' | transloco">
          {{ 'translations.feedback' | transloco }}
        </h2>
        <div class="extra-small">
          {{ 'translations.feedbackDescription' | transloco }}
        </div>
        <button
          mat-icon-button
          type="button"
          class="clear-button"
          *ngIf="editableQuestion?.uid"
          tabindex="0"
          [attr.aria-label]="'translations.global.ariaLabels.clear' | transloco: { title: '' }"
          (click)="clearFeedback()"
        >
          <svg class="svg svg-icon" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
          </svg>
        </button>
      </div>
      <ptl-editor
        class="feedback-container-textarea no-outline"
        [class.focused]="ptlFeedbackFocused"
        [link]="false"
        [disableAutoFocus]="true"
        [upload]="false"
        [separator]="false"
        [placeholder]=""
        [content]="feedback.dynamicContentRequest"
        (inputFocus)="ptlFeedbackFocused = true"
        (inputBlur)="ptlFeedbackFocused = false"
        (contentUpdate)="onFeedbackUpdate($event, feedback)"
      >
      </ptl-editor>
    </div>

    <ptl-add-framework-tags
      class="add-framework-tags-container"
      *ngIf="frameworkTagsPanelOpen"
      [momentTags]="questionTags"
      [isDialogPage]="true"
      (closeFrameworkTags)="onToggleFrameworkTagsPanel($event)"
      (addTagBulk)="onAddTagBulk($event)"
    >
    </ptl-add-framework-tags>

    <div class="add-question-footer-box">
      <button
        mat-stroked-button
        class="remove-button small"
        type="button"
        [attr.aria-label]="'translations.global.ariaLabels.closeQuestion' | transloco"
        (click)="onCloseForm()"
      >
        <svg class="svg svg-icon icon-left" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
        </svg>
        <span>{{ 'translations.global.button.cancel' | transloco }}</span>
      </button>

      <button
        mat-stroked-button
        class="save-button small"
        title="Save"
        type="submit"
        [attr.aria-label]="'translations.global.ariaLabels.applyQuestion' | transloco"
      >
        <svg class="svg svg-icon icon-left" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
        </svg>
        <span>{{ 'translations.global.button.apply' | transloco }}</span>
      </button>
    </div>
  </form>

  <div
    tabindex="0"
    [attr.aria-label]="'translations.global.ariaLabels.closeForm' | transloco"
    class="add-question__form-backdrop"
    (click)="onCloseForm()"
    (keydown.enter)="onCloseForm()"
  ></div>
</div>
