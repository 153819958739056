import { Component, EventEmitter, Inject, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from 'src/app/page-modules/account/services/account-data.service';
import { USER_GROUPS_DATA_SERVICE, UserGroupsDataService } from 'src/app/page-modules/admin/services/groups/groups-data.service';
import { GroupSearchAutocompleteComponent } from 'src/app/shared/components/group-search-autocomplete/group-search-autocomplete.component';
import { UserGroups } from 'src/app/shared/models/admin/group/user-groups.model';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from 'src/app/shared/services/toast-notifications/toast-service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  templateUrl: './alumni-csv-upload-dialog.component.html',
  styleUrls: ['./alumni-csv-upload-dialog.component.scss'],
})
export class AlumniCsvUploadDialogComponent implements OnDestroy {
  csvFile: File;
  fileUploaded = false;
  filesHovered = false;
  uploadedFileName: string;

  groupInvalid = false;
  csvInvalid = false;
  isLoading = false;

  loadingGroups: boolean;
  searchString: string;
  selectedGroup: UserGroups;
  searchedGroups: UserGroups[] = [];

  private groupsSearchPage = 0;
  private groupsSearchPageSize = 10;
  private searchInputSubscription: Subscription;
  private searchInputSubject = new Subject<string>();

  @ViewChild('groupSearchAutocomplete') private groupSearchAutocomplete: GroupSearchAutocompleteComponent;

  @Output() alumniUploadFinished = new EventEmitter<number>();

  constructor(
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
    @Inject(USER_GROUPS_DATA_SERVICE) public userGroupsService: UserGroupsDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
    private dialogRef: MatDialogRef<AlumniCsvUploadDialogComponent>,
    private translocoService: TranslocoService,
  ) {
    this.searchInputSubscription = this.searchInputSubject.pipe(debounceTime(500)).subscribe(() => this.fireGroupSearch(true));
  }

  ngOnDestroy() {
    this.searchInputSubscription?.unsubscribe();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  uploadAlumniUsers() {
    this.groupInvalid = false;
    this.csvInvalid = false;
    if (!this.selectedGroup || !this.csvFile) {
      this.groupInvalid = !this.selectedGroup;
      this.csvInvalid = !this.csvFile;
      return;
    }
    if (!this.validCsvFile()) {
      this.csvInvalid = true;
      return;
    }
    const request = { groupUid: this.selectedGroup._id, file: this.csvFile };
    this.isLoading = true;
    this.accountService.alumniUploadCsv(request).subscribe(({ isSuccess, value, error }) => {
      this.isLoading = false;
      this.closeDialog();
      if (isSuccess) {
        this.alumniUploadFinished.emit(value ? value.processed : 10);
        const processed = value ? value.processed.toString() : '';
        const message = this.translocoService
          .translate('translations.members.alumni.message.success.csvFileAdded')
          .replace('{number}', processed);
        this.toastService.showSuccess(message);
      } else {
        this.toastService.showFail(error);
      }
    });
  }

  deleteGroupItem() {
    this.selectedGroup = undefined;
  }

  removeSelectedGroup() {
    this.selectedGroup = undefined;
  }

  onSearchInputChange(searchValue: string) {
    this.searchString = searchValue;
    this.loadingGroups = true;
    this.searchInputSubject.next(searchValue);
  }

  onGroupSelected(data: UserGroups): void {
    this.selectedGroup = data;
    this.groupInvalid = false;
  }

  fireGroupSearch(override: boolean) {
    this.groupsSearchPage = override ? 0 : this.groupsSearchPage + 1;
    if (!this.searchString) {
      this.searchString = '';
    }
    this.userGroupsService
      .searchUserGroups(this.groupsSearchPage, this.groupsSearchPageSize, this.searchString)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          if (override) {
            this.searchedGroups = value.content;
          } else {
            this.searchedGroups = this.searchedGroups.concat(value.content);
          }
        }
        this.groupSearchAutocomplete.canLoadMore = value.totalNumberOfElement > this.searchedGroups.length;
        this.groupSearchAutocomplete.isLoadingMore = false;
        this.loadingGroups = false;
      });
  }

  onSearchLoadingMore(): void {
    this.fireGroupSearch(false);
  }

  onFilesDropped(fileList: FileList): void {
    if (fileList.length === 1) {
      this.uploadFile(fileList.item(0));
    } else {
      this.toastService.showFail(this.translocoService.translate('translations.uploadFileCountLimitError'));
    }
  }

  onFileChanged(eventData: Event): void {
    this.uploadFile((eventData.target as HTMLInputElement).files[0]);
  }

  private validCsvFile() {
    /* eslint-disable */
    const pattern = '^.+\.(xlsx|xls|csv)$';
    /* eslint-enable */
    const csvRegexp = RegExp(pattern);
    return csvRegexp.test(this.csvFile.name);
  }

  private uploadFile(file: File) {
    this.csvInvalid = false;
    this.fileUploaded = true;
    if (file) {
      this.fileUploaded = true;
      this.uploadedFileName = file.name;
    } else {
      this.fileUploaded = false;
      this.uploadedFileName = '';
    }
    this.csvFile = file;
  }
}
