/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

export enum FeatureFlag {
  LMS_SKILLS = 'lms_skills',
  AI_ASSISTANT = 'ai_assistant',
  EDITOR_JS = 'editor_js',
  PROFILE_CERTS = 'profile_certs',
  ENROLLMENT = 'enrollment',
  EMAIL_NOTIFICATIONS = 'email_notifications',
  PAGE_ANALYTICS = 'page_analytics',
  CATEGORIES = 'categories',
  EVENTS_TOP_BAR_HIDDEN = 'events_top_bar_hidden',
  TABLEAU_V2_ENABLED = 'tableau_v2_enabled',
  LMS_COURSE_BADGES = 'course_badges',
  HELP_CENTER = 'help_center',
  EDITOR_JS_FORCE = 'editor_js_force',
  SCORM = 'scorm',
  MENTORS = 'mentors',
}
