import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageSectionType, PageSectionItem, SelectedTabs } from '../../models/pages/page-section.model';
import { marker } from '@jsverse/transloco-keys-manager/marker';

@Component({
  templateUrl: './add-page-template.component.html',
  styleUrls: ['./add-page-template.component.scss'],
})
export class AddPageTemplateComponent {
  selectedTab: SelectedTabs;
  pagesSectionItems: PageSectionItem[] = [
    {
      title: marker('translations.pages.newSection.label.imageBlockPages'),
      image: 'assets/page-sections/image-block-page.png',
      type: 'ICBP',
    },
    {
      title: marker('translations.pages.newSection.label.headlinePages'),
      image: 'assets/page-sections/headline-page.png',
      type: 'HP',
    },
    {
      title: marker('translations.pages.newSection.label.imagePages'),
      image: 'assets/page-sections/image-page.png',
      type: 'IP',
    },
    {
      title: marker('translations.pages.newSection.label.blockPages'),
      image: 'assets/page-sections/block-page.png',
      type: 'CBP',
    },
    {
      title: marker('translations.pages.newSection.label.calloutPages'),
      image: 'assets/page-sections/callout-page.png',
      type: 'CP',
    },
  ];
  basicBlockSectionItems: PageSectionItem[] = [
    {
      title: marker('translations.pages.newSection.label.imageBlock'),
      image: 'assets/page-sections/image-block.png',
      type: 'ICB',
    },
    {
      title: marker('translations.pages.newSection.label.image'),
      image: 'assets/page-sections/image.png',
      type: 'I',
    },
    {
      title: marker('translations.pages.newSection.label.block'),
      image: 'assets/page-sections/block.png',
      type: 'CB',
    },
    {
      title: marker('translations.pages.newSection.label.headline'),
      image: 'assets/page-sections/headline.png',
      type: 'H',
    },
    {
      title: marker('translations.pages.newSection.label.featured'),
      image: 'assets/page-sections/featured-section.png',
      type: 'F',
    },
    {
      title: marker('translations.pages.newSection.label.calloutPages'),
      image: 'assets/page-sections/callout-page.png',
      type: 'CP',
    },
    {
      title: marker('translations.pages.newSection.label.tracker'),
      image: 'assets/page-sections/tracker.png',
      type: 'FTS',
    },
  ];
  automaticSectionItems: PageSectionItem[] = [
    {
      title: marker('translations.pages.newSection.label.continueSection'),
      image: 'assets/page-sections/continue-section.png',
      type: 'CS',
    },
    {
      title: marker('translations.pages.newSection.label.whatsNewSection'),
      image: 'assets/page-sections/whats-new-section.png',
      type: 'WNS',
    },
    {
      title: marker('translations.pages.newSection.label.upcomingSection'),
      image: 'assets/page-sections/upcoming-section.png',
      type: 'US',
    },
    {
      title: marker('translations.pages.newSection.label.yourPlaylists'),
      image: 'assets/page-sections/your-playlists-section.png',
      type: 'YPS',
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<AddPageTemplateComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: AddPageTemplateData,
  ) {
    switch (data.addType) {
      case 'template':
        this.showTab('PAGES');
        break;
      case 'block':
        this.showTab('BASIC_BLOCK');
        break;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  showTab(selectedTab: SelectedTabs): void {
    this.selectedTab = selectedTab;
  }

  onSectionClick(sectionType: PageSectionType): void {
    this.dialogRef.close(sectionType);
  }
}

export type AddPageTemplateData = {
  addType: 'template' | 'block';
  title: string;
};
