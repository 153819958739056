<!--
  ~ Copyright (C) 2021 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="search-playlist-container-title">
  {{ 'translations.discoveryShareSelectedPlaylistDescription' | transloco }}
</div>

<div class="share-discovery-playlists-content">
  <div class="card-box">
    <mat-card class="card">
      <div class="card__center">
        <img class="center__image" alt="" width="266" height="150" [src]="playlist?.thumbnail" />
      </div>
      <h3 class="card__description">
        <span>{{ playlist?.title }}</span>
      </h3>
      <p class="card__description">
        <span>{{ playlist?.subHeader }}</span>
      </p>
    </mat-card>

    <div class="card-info-box">
      <div class="card-info-box-title">{{ 'translations.discoveryShareSelectedPlaylistContentLabel' | transloco }}</div>
      <ul class="card-info-box-description">
        <li>5 cards</li>
        <li>1 assessment</li>
        <li>3 events</li>
        <li>2 badge</li>
      </ul>
    </div>

    <div class="card-info-box">
      <div class="card-info-box-title">{{ 'translations.discoveryShareSelectedPlaylistLanguageLabel' | transloco }}</div>
      <ul class="card-info-box-description">
        <li>English</li>
      </ul>
    </div>
  </div>
  <div class="author-box">
    <div class="author-info">
      <h2>{{ 'translations.framework.list.label.author' | transloco }}</h2>
      <ptl-profile-image [userSummary]="userSummary"></ptl-profile-image>
      <span class="author-name">{{ playlist.authorDetails?.name }}</span>
    </div>
    <div class="section-item">
      <h2>{{ 'translations.category.title.main' | transloco }}</h2>
      <span>{{ 'translations.categoryTargetQuestion' | transloco }}</span>
      <div class="review-await__tag-box">
        <span>{{ 'translations.framework.label.browse' | transloco }} </span>
        <span
          class="review-await__framework-btn"
          tabindex="0"
          (click)="frameworkPanelOpen = true"
          (keydown.enter)="frameworkPanelOpen = true"
          [attr.aria-label]="'translations.global.ariaLabels.browseFrameworks' | transloco"
        >
          <span class="review-await__framework-icon"></span>
          <span>{{ 'translations.framework.button.frameworks' | transloco }}</span>
        </span>
        <span> {{ 'translations.framework.label.or' | transloco }} </span>
        <button mat-button class="review-await__add-new-category-btn">{{ 'translations.category.button.newCategory' | transloco }}</button>
        <div *ngIf="frameworkPanelOpen" class="review-await__framework-panel">
          <ptl-add-framework-tags [isDialogPage]="false" (closeFrameworkTags)="frameworkPanelOpen = false"> </ptl-add-framework-tags>
        </div>
      </div>
      <div class="review-await__tags-container">
        <span class="review-await__tag" *ngFor="let tag of ['ssss', 'sdsasas']">
          <span class="title" [title]="tag">{{ tag }}</span>
          <span class="remove" title="Remove" tabindex="0" [attr.aria-label]="'translations.global.ariaLabels.removeTag' | transloco"
            >x</span
          >
        </span>
      </div>
    </div>

    <div class="section-item">
      <h2>{{ 'translations.discoveryShareSelectedPlaylistPermissionsLabel' | transloco }}</h2>

      <div class="checkboxes-block">
        <mat-checkbox class="checkbox-item" [aria-label]="'Permissions Copy'" color="primary">
          <span [innerHTML]="'translations.discoveryShareSelectedPlaylistPermissionsCopyLabel' | transloco"></span>
        </mat-checkbox>

        <mat-checkbox class="checkbox-item" [aria-label]="'Permissions Embed'" color="primary">
          <span [innerHTML]="'translations.discoveryShareSelectedPlaylistPermissionsEmbedLabel' | transloco"></span>
        </mat-checkbox>
      </div>
    </div>

    <div class="section-item">
      <h2>{{ 'translations.discoveryShareSelectedPlaylistCommunityAgreementLabel' | transloco }}</h2>

      <div class="checkboxes-block">
        <mat-checkbox class="checkbox-item" [aria-label]="'Community agreement guidelines'" color="primary">
          <span [innerHTML]="'translations.discoveryShareSelectedPlaylistCommunityAgreementGuidelines' | transloco"></span>
        </mat-checkbox>

        <mat-checkbox class="checkbox-item" [aria-label]="'Community agreement content sensitive'" color="primary">
          <span [innerHTML]="'translations.discoveryShareSelectedPlaylistCommunityAgreementContentSensitive' | transloco"></span>
        </mat-checkbox>
      </div>
    </div>

    <div class="note-box">
      <h2>{{ 'translations.cards.review.title.addNote' | transloco }}</h2>
      <textarea></textarea>
    </div>
  </div>
</div>

<div class="share-discovery-playlists-footer-content">
  <button mat-raised-button class="uppercase cancel-btn" (click)="cancelShare()">
    {{ 'translations.global.button.cancel' | transloco }}
  </button>
  <button mat-flat-button class="uppercase share-btn" (click)="submitShare()">{{ 'translations.discoverySubmitShare' | transloco }}</button>
</div>
