<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<form class="add-question__section" [formGroup]="question">
  <div class="add-question__section__grid">
    <div formArrayName="options">
      <div class="add-question__section-table add-question__section-table--align">
        <div class="add-question__section-column add-question__section-column--grid">
          <h2 tabindex="0" [attr.aria-label]="'translations.global.ariaLabels.options' | transloco">
            {{ 'translations.options' | transloco }}
          </h2>
          <p
            class="add-question__section-col add-question__section-col--gray"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.pleaseAddOptionsBelow' | transloco"
          >
            {{ 'translations.pleaseAddOptionsBelow' | transloco }}
          </p>
        </div>
        <div class="add-question__section-column">
          <h2
            class="add-question__section-col"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.testValidation' | transloco"
          >
            {{ 'translations.testValidation' | transloco }}
          </h2>
          <p
            class="add-question__section-col add-question__section-col--gray"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.markTrueFalse' | transloco"
          >
            {{ 'translations.markTrueFalse' | transloco }}
          </p>
        </div>
      </div>
      <div cdkDropList [cdkDropListData]="question.controls['options']['controls']" (cdkDropListDropped)="dropOption($event)">
        <div
          cdkDrag
          cdkDragLockAxis="y"
          class="add-question__section-table add-question-section-table-list add-question__section-table--align"
          *ngFor="let item of question.controls['options']['controls']; let i = index"
          [formGroupName]="i"
          [cdkDragData]="item"
        >
          <div class="icons" [class.is_disabled]="!isDefaultLanguage">
            <button
              mat-icon-button
              cdkDragHandle
              class="add-question__section-column add-question__section-column--icons add-question__section-column-icon-grid add-question__section-column__hoverable"
            >
              <svg class="svg svg-icon" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#drag-indicator"></use>
              </svg>
            </button>
          </div>
          <div class="add-question__section-column add-question__section-column--width" formGroupName="value">
            <mat-form-field class="add-question__section-field no-padding color-gray small-field" appearance="outline">
              <input
                matInput
                type="text"
                id="input{{ i }}"
                [attr.aria-label]="'translations.global.ariaLabels.enterOptionTitle' | transloco"
                formControlName="value"
              />
              <mat-error *ngIf="item.controls['value'].controls['value'].hasError('required')">
                {{ 'translations.optionRequiredError' | transloco }}
              </mat-error>
              <mat-error *ngIf="item.controls['value'].controls['value'].hasError('maxlength')">
                {{ 'translations.maxLengthError' | transloco }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="icons" [class.is_disabled]="!isDefaultLanguage">
            <div class="add-question__section-column">
              <div class="add-question__section-field no-padding color-gray">
                <ng-container *ngIf="item.controls['correct'].hasError('required') && (item.touched || item.dirty); else valid">
                  <button
                    mat-button
                    type="button"
                    class="question-button correct-button"
                    [ngClass]="
                      item.controls['correct'].hasError('required') && !item.controls['value'].controls['value'].touched
                        ? ''
                        : 'not-selected'
                    "
                    [attr.aria-label]="'translations.global.ariaLabels.markOptionAsCorrect' | transloco"
                    (click)="correctQuestion(item)"
                  >
                    <svg class="svg svg-icon icon-left" aria-hidden="true">
                      <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
                    </svg>
                    <span>Right</span>
                  </button>
                  <button
                    mat-button
                    type="button"
                    class="question-button wrong-button"
                    [ngClass]="
                      item.controls['correct'].hasError('required') && !item.controls['value'].controls['value'].touched
                        ? ''
                        : 'not-selected'
                    "
                    [attr.aria-label]="'translations.global.ariaLabels.markOptionAsWrong' | transloco"
                    (click)="falseQuestion(item)"
                  >
                    <span>Wrong</span>
                  </button>
                </ng-container>
                <ng-template #valid>
                  <button
                    mat-button
                    type="button"
                    class="question-button correct-button"
                    [ngClass]="item.controls['correct'].value ? 'correct' : ''"
                    [attr.aria-label]="'translations.global.ariaLabels.markOptionAsCorrect' | transloco"
                    (click)="correctQuestion(item)"
                  >
                    <svg class="svg svg-icon icon-left" aria-hidden="true">
                      <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
                    </svg>
                    <span>Right</span>
                  </button>
                  <button
                    mat-button
                    type="button"
                    class="question-button wrong-button"
                    [ngClass]="item.controls['correct'].value === undefined ? '' : item.controls['correct'].value === false ? 'wrong' : ''"
                    [attr.aria-label]="'translations.global.ariaLabels.markOptionAsWrong' | transloco"
                    (click)="falseQuestion(item)"
                  >
                    <span>Wrong</span>
                  </button>
                </ng-template>
              </div>
            </div>
            <span
              #removeCategory
              class="cdk-visually-hidden"
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.removeOption' | transloco"
            >
              {{ 'translations.removeOption' | transloco }}</span
            >
            <button
              mat-icon-button
              type="button"
              class="add-question__section-column add-question__section-column-remove"
              *ngIf="i > 0"
              [title]="removeCategory.innerHTML"
              [attr.aria-label]="'translations.global.ariaLabels.removeOption' | transloco"
              (click)="removeOption(i)"
            >
              <svg class="svg svg-icon" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <button
        *ngIf="question.value.options.length < 7"
        mat-button
        [class.is_disabled]="!isDefaultLanguage"
        class="add-new-question-btn"
        type="button"
        [attr.aria-label]="'translations.global.ariaLabels.addOption' | transloco"
        (click)="addNewOption()"
      >
        <svg class="svg svg-icon icon-left" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#plus"></use>
        </svg>
        <span>{{ 'translations.clickToAddOption' | transloco }}</span>
      </button>
    </div>
    <ng-content></ng-content>
  </div>
</form>
