/*
 * Copyright (C) 2023 - present by Potentially
 *
 * Please see distribution for license.
 */

import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import * as moment from 'moment';
import { filter, fromEvent, Observable, Subject, takeUntil, map, zip } from 'rxjs';
import { EVENT_CARDS_DATA_SERVICE, EventCardsDataService } from 'src/app/page-modules/event-cards/services/data.service';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from 'src/app/shared/services/toast-notifications/toast-service';
import { AndRequest, BooleanQueryType, MembersBooleanSearchRequest } from '@app/app/shared/models/admin/boolean-filters.model';
import {
  CardHeaders,
  CheckedMembersData,
  FormHeaders,
  Members,
  MembersSearchOrder,
  MembersSearchResponse,
} from '@app/app/shared/models/admin/members.model';
import { PageTitleService } from '@app/app/shared/services/page-title/page-title.service';
import { TranslocoService } from '@ngneat/transloco';
import { LoadableState, ObservableResult } from '@app/app/shared/store';
import { MEMBERS_DATA_SERVICE, MembersDataService } from '../../services/members/members-data.service';
import { AdminMemberListComponent } from './admin-member-list/admin-member-list.component';
import { AdminMembersAction, MembersListColumns } from './model/members.model';
import { UserAuthState } from 'src/app/user-auth/store/user-auth.state';
import { Organization, Playlist, Resource } from 'src/app/shared/models';
import { ResourceAdminState } from '../../../resource/store/admin/resource-admin.state';
import * as ResourceAdminActions from '../../../resource/store/admin/resource-admin.actions';
import { DialogService } from '@app/app/shared/helpers/dialog/dialog.service';
import { EventCardTime } from '../../../resource/store/admin/resource-event-admin.state.model';
import { EventCardsHelper } from '@app/app/shared/helpers/event-cards-helper';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from '../../../account/services/account-data.service';
import { UsersUploadCsvError } from '@app/app/shared/models/account/account.model';
import { MatDialog } from '@angular/material/dialog';
import { AlertType } from '@app/app/shared/models/alert/alert.model';
import { UsersCsvUploadErrorsDialogComponent } from './admin-member-control-panel/users-csv-upload-errors-dialog/users-csv-upload-errors-dialog.component';
import { LoaderService } from '@app/app/shared/services/loader/loader.service';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { LTI_COURSE_DATA_SERVICE, LTI_COURSE_UID, LtiCourseDataService } from '@app/app/page-modules/lti/services/lti-course-data.service';
import { ContentHelper } from '@app/app/shared/helpers/content-helper';
import { CORE_PLAYLIST_DATA_SERVICE, PlaylistDataService } from '@app/app/page-modules/playlist/services/create/core/data.service';
import {
  CardPaginationFilter,
  PaginationFilter,
  PlaylistPaginationFilter,
} from '@app/app/page-modules/admin/components/members/store/members.state.model';
import { MembersState } from '@app/app/page-modules/admin/components/members/store/members.state';
import {
  UpdateCardPaginationFilters,
  UpdateMemberPaginationFilters,
  UpdatePlaylistPaginationFilters,
} from '@app/app/page-modules/admin/components/members/store/members.action';
import { PlaylistViewState } from '../../../playlist/store/view/playlist-view.state';

@Component({
  selector: 'ptl-admin-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
})
export class AdminMembersComponent implements OnInit, AfterViewInit, OnDestroy {
  private static readonly EMPTY_REQ: MembersBooleanSearchRequest = {
    query: {
      type: BooleanQueryType.AND,
      args: [],
    } as AndRequest,
  };

  @Input() isPlaylistPage: boolean;
  @Input() isCardPage: boolean;
  @Input() isAssessment: boolean;
  @Input() isEvent: boolean;
  @Input() cardUid: string;
  @Input() playlistUid: string;
  @Input() resourceUri: string;
  @Input() formHeaders: FormHeaders[];
  @Input() exportEnabled: boolean;
  @Input() isInDialogMode: boolean;

  @ViewChild('membersList') membersList: AdminMemberListComponent;

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @Select(ResourceAdminState.resource)
  private resource$: Observable<Resource>;

  @Select(PlaylistViewState.playlistState)
  private playlist$: Observable<LoadableState<Playlist>>;

  DEFAULT_PAGE_SIZE = 25;

  cardHeaders: CardHeaders[];
  queryRequest: AndRequest;
  columns: MembersListColumns[];
  members: Members[];
  membersTotalCount: number;
  membersAllCount = 0;
  membersListLoaded: boolean;
  skeletonViewActive = true;
  showNotPermittedMessage = false;
  isWaitingListEnabled = false;
  isWaitingListOperationDisabled = true;
  order: MembersSearchOrder;
  eventFinished = false;
  alertMessage: string;
  alertType: AlertType = 'success';
  alertTextClickable = false;
  showAlert: boolean;
  paginationLength = 0;
  checkedMembers: CheckedMembersData[] = [];
  isIframeMode: boolean;
  courseUid: string;
  page: number;
  pageSize: number;
  contentReviewEnabled: boolean;

  private subscriptionEnd$ = new EventEmitter<void>();

  private resource: Resource;

  private usersUploadCsvHeader: string[] = [];
  private usersUploadCsvErrors: UsersUploadCsvError[] = [];
  private filterChangeSubject = new Subject<{
    queryRequest: AndRequest;
    instantRefresh: boolean;
    allMembers: boolean;
  }>();

  @Select(MembersState.playlistPagination)
  private playlistPagination$: Observable<PlaylistPaginationFilter[]>;

  @Select(MembersState.cardPagination)
  private cardPagination$: Observable<CardPaginationFilter[]>;

  @Select(MembersState.memberPaginationFilter)
  private $memberPagination: Observable<PaginationFilter>;

  constructor(
    private store: Store,
    private titleService: PageTitleService,
    private translocoService: TranslocoService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private loadingService: LoaderService,
    @Inject(MEMBERS_DATA_SERVICE) private membersDataService: MembersDataService,
    @Inject(EVENT_CARDS_DATA_SERVICE) private eventCardsDataService: EventCardsDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
    @Inject(LTI_COURSE_DATA_SERVICE) private ltiCourseDataService: LtiCourseDataService,
    @Inject(CORE_PLAYLIST_DATA_SERVICE) private dataService: PlaylistDataService,
  ) {
    this.isIframeMode = ContentHelper.isFrameMode();
  }

  ngOnInit() {
    if (this.isIframeMode) {
      this.courseUid = localStorage.getItem(LTI_COURSE_UID);
    }
    this.showNotPermittedMessage = false;
    this.skeletonViewActive = true;
    this.titleService.setTitle('Members');
    this.page = 0;
    this.pageSize = this.DEFAULT_PAGE_SIZE;
    this.order = {
      fieldName: 'lastActiveOn',
      direction: 'DESC',
    };

    if (this.isPlaylistPage) {
      this.playlist$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((value) => {
        this.contentReviewEnabled = value?.data?.userPlaylistSubmissionSummary?.userReviewSummary?.reviewType !== 'AUTOMATIC';
      });
    }

    if (this.isCardPage) {
      this.resource$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((resource) => {
        this.resource = resource;
        this.isWaitingListEnabled = this.isEvent && resource.tickets?.waitingList;
        this.isWaitingListOperationDisabled = this.isWaitingListEnabled && resource.tickets?.remainingTickets > 0;
        this.contentReviewEnabled = this.resource.approvalMethod.type !== 'AUTOMATIC';
        if (this.isEvent) {
          this.setEventFinished(this.resource.time);
        }
      });
    }

    this.queryRequest = AdminMembersComponent.EMPTY_REQ.query as AndRequest;
    this.columns = this.getColumns();

    this.filterChangeSubject.pipe(takeUntil(this.subscriptionEnd$)).subscribe(({ queryRequest, instantRefresh, allMembers }) => {
      this.updateListByBooleanFilter(queryRequest, instantRefresh, allMembers);
    });

    this.initPagination();
  }

  ngOnDestroy() {
    this.subscriptionEnd$?.emit();
    this.toggleParentHeadersOverlay(false);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.addParentHeaderClickListeners();
    }, 0);
  }

  onCloseDialog(event: MouseEvent): void {
    // Check if the event target is the parent itself
    if (this.isInDialogMode && event.target === event.currentTarget) {
      this.disableDialogMode();
    }
  }

  disableDialogMode(): void {
    if (!this.isInDialogMode) {
      return;
    }
    this.isInDialogMode = false;
    this.toggleParentHeadersOverlay(false);
  }

  expandTable(): void {
    this.isInDialogMode = true;
    this.toggleParentHeadersOverlay(true);
  }

  toggleParentHeadersOverlay(add: boolean): void {
    document.querySelector('.f_page-header-tab-bar')?.classList.toggle('hide-with-overlay', add);
    document.querySelector('.f_header')?.classList.toggle('hide-with-overlay', add);
    document.querySelector('.f_main-content')?.classList.toggle('dialog-mode', add);
  }

  changeListOrderingByBooleanFilter(update: { order: MembersSearchOrder; page: number; pageSize: number }, allMembers = false) {
    this.page = update.page;
    this.pageSize = update.pageSize;
    this.order = update.order;

    this.filterChangeSubject.next({ queryRequest: this.queryRequest, instantRefresh: true, allMembers });
  }

  onFilterChanged(data: { andRequest: AndRequest; resetPageNumber: boolean }): void {
    this.queryRequest = data.andRequest;

    if (data.resetPageNumber) {
      this.page = 0;
      if (this.membersList) {
        this.membersList.setCurrentPage(0);
      }

      this.resetPageNumber();
    }

    this.filterChangeSubject.next({ queryRequest: this.queryRequest, instantRefresh: true, allMembers: false });
  }

  resetPageNumber(): void {
    const paginationFilter: PaginationFilter = {
      page: 0,
      pageSize: this.pageSize,
      order: this.order,
    };

    if (this.isCardPage) {
      this.store.dispatch(new UpdateCardPaginationFilters(this.cardUid, paginationFilter));
    } else if (this.isPlaylistPage) {
      this.store.dispatch(new UpdatePlaylistPaginationFilters(this.playlistUid, paginationFilter));
    } else {
      this.store.dispatch(new UpdateMemberPaginationFilters(paginationFilter));
    }
  }

  updateListByBooleanFilter(queryRequest: AndRequest, instantRefresh: boolean, allMembers = false) {
    this.queryRequest = queryRequest;
    const data = {
      query: this.queryRequest,
      order: this.order,
    };

    if (this.membersList && instantRefresh) {
      this.membersList.setIsLoaderActive(true);
    }

    const membersObservable = this.getMembersObservable(data);
    // if not on iframe do:
    this.combineSearchResultWithAllMembersCount(membersObservable, data.query, !allMembers).subscribe(({ isSuccess, value, error }) => {
      if (isSuccess) {
        if (this.isPlaylistPage) {
          value.members = this.setCardTitleForEachMember(value.members, value.cardHeaders);
          this.cardHeaders = value.cardHeaders;
        }
        this.members = this.preselectCheckedMembers(value.members);
        this.membersTotalCount = value.total;
        this.membersAllCount = value.allMembers;
        this.membersListLoaded = true;
        this.paginationLength = value.total / value.pageSize;
      } else {
        this.showErrorView(error);
      }
      this.skeletonViewActive = false;
      if (this.membersList) {
        this.membersList.setIsLoaderActive(false);
      }
    });
  }

  private combineSearchResultWithAllMembersCount(
    membersResult$: ObservableResult<MembersSearchResponse>,
    queryRequest: AndRequest,
    reuseExistingCount: boolean,
  ): ObservableResult<MembersSearchResponseWithAllMembersCount> {
    const members$ = membersResult$.pipe(ObservableResult.unwrapResult());

    if (queryRequest.args.length === 0) {
      return members$.pipe(
        map((response) => ({
          ...response,
          allMembers: response.total,
        })),
        ObservableResult.restoreResult(),
      );
    }

    if (reuseExistingCount && this.membersAllCount !== 0) {
      return members$.pipe(
        map((response) => ({ ...response, allMembers: this.membersAllCount })),
        ObservableResult.restoreResult(),
      );
    }

    const allMembers$ = this.getMembersObservable(AdminMembersComponent.EMPTY_REQ, 0, 1).pipe(
      ObservableResult.unwrapResult(),
      map((result) => result.total),
    );

    return zip([members$, allMembers$]).pipe(
      map(([members, allMembers]) => ({
        ...members,
        allMembers,
      })),
      ObservableResult.restoreResult(),
    );
  }

  onMembersChecked(checkedMembers: CheckedMembersData[]) {
    this.checkedMembers = [...checkedMembers];
  }

  handleMemberAction(eventAction: AdminMembersAction) {
    const membersToUpdateCount = eventAction.memberIds.length;
    const allMembersRefresh = eventAction.allMembersRefresh;

    switch (eventAction.type) {
      case 'HAVE_ATTENDED':
        if (!this.eventFinished) {
          this.dialogService
            .showConfirmDialog(this.translocoService.translate('translations.dialog.title.eventNotFinished'), this.translocoService)
            .then((confirmed) => {
              if (confirmed) {
                this.participateInEvent(eventAction, membersToUpdateCount, allMembersRefresh);
              }
            });
        } else {
          this.participateInEvent(eventAction, membersToUpdateCount, allMembersRefresh);
        }
        break;
      case 'WAITING_LIST':
        this.eventCardsDataService
          .adminMoveToWaitingListEventBulk(this.cardUid, eventAction.memberIds)
          .subscribe(({ isSuccess, value, error }) => {
            this.refreshTableWithDelay(membersToUpdateCount, allMembersRefresh);
            if (isSuccess) {
              this.store.dispatch(new ResourceAdminActions.SetEventTickets(value));
              this.toastService.showSuccess(this.translocoService.translate('translations.successes.successAdminMoveToWaitingListEvent'));
            } else {
              this.toastService.showFail(error);
            }
          });
        break;
      case 'WILL_ATTEND':
        this.eventCardsDataService
          .adminRegisterToEventBulk(this.cardUid, { usersUid: eventAction.memberIds, playlistUid: this.playlistUid })
          .subscribe(({ isSuccess, value, error }) => {
            this.refreshTableWithDelay(membersToUpdateCount, allMembersRefresh);
            if (isSuccess) {
              this.store.dispatch(new ResourceAdminActions.SetEventTickets(value));
              this.toastService.showSuccess(this.translocoService.translate('translations.successes.successAdminRegisterToEvent'));
            } else {
              this.toastService.showFail(error);
            }
          });
        break;
      case 'WILL_NOT_ATTEND':
        this.eventCardsDataService
          .adminUnregisterFromEventBulk(this.cardUid, eventAction.memberIds)
          .subscribe(({ isSuccess, value, error }) => {
            this.refreshTableWithDelay(membersToUpdateCount, allMembersRefresh);
            if (isSuccess) {
              this.store.dispatch(new ResourceAdminActions.SetEventTickets(value));
              this.toastService.showSuccess(this.translocoService.translate('translations.successes.successAdminUnregisterFromEvent'));
            } else {
              this.toastService.showFail(error);
            }
          });
        break;
      case 'REGISTERED': {
        const memberId = eventAction.memberIds?.[0];
        this.dataService.registerUserToPlaylist(this.playlistUid, memberId).subscribe(({ isSuccess, value, error }) => {
          this.refreshTableWithDelay(membersToUpdateCount, allMembersRefresh);
          if (isSuccess) {
            this.toastService.showSuccess(this.translocoService.translate('translations.successes.successAdminRegisterToPlaylist'));
          } else {
            this.toastService.showFail(error);
          }
        });
        break;
      }
      case 'CANCELED': {
        const memberId = eventAction.memberIds?.[0];
        this.dataService.unregisterUserToPlaylist(this.playlistUid, memberId).subscribe(({ isSuccess, value, error }) => {
          this.refreshTableWithDelay(membersToUpdateCount, allMembersRefresh);
          if (isSuccess) {
            this.toastService.showSuccess(
              this.translocoService.translate('translations.successes.successAdminCancelRegistrationToPlaylist'),
            );
          } else {
            this.toastService.showFail(error);
          }
        });
        break;
      }
    }
  }

  onReviewersUpdate() {
    this.checkedMembers = [];

    if (this.membersList?.defaultTable) {
      this.membersList?.defaultTable.clearCheckedMembers();
    }

    this.refreshTableWithDelay(0, true);
  }

  refreshList(changedUsersSize: number) {
    this.refreshTableWithDelay(changedUsersSize, true);
  }

  refreshTableWithDelay(numberOfMembers: number, allMembers: boolean) {
    this.membersList.setIsLoaderActive(true);
    setTimeout(
      () => {
        this.updateListByBooleanFilter(this.queryRequest, false, allMembers);
      },
      1000 + numberOfMembers * 350,
    );
  }

  onTriggerUserImport(data: { file: File; headers: string[] }) {
    const request = { file: data.file };
    this.usersUploadCsvHeader = data.headers;
    this.alertTextClickable = false;
    this.hideAlertMessage();
    this.loadingService.show();
    this.accountService.usersUploadCsv(request).subscribe(({ isSuccess, value, error }) => {
      this.loadingService.hide();
      this.showAlertMessage();
      if (isSuccess) {
        this.alertType = 'success';
        this.refreshList(value.successCount);
        const successCount = value ? value.successCount.toString() : '';
        let message = this.translocoService
          .translate('translations.membersNew.controlPanel.usersUpload.message.success.csvFileAdded')
          .replace('{number}', successCount);
        if (value.errors && value.errors.length) {
          this.usersUploadCsvErrors = value.errors;
          message = this.translocoService
            .translate('translations.membersNew.controlPanel.usersUpload.message.success.csvFileAddedWithFailedUsers')
            .replace('{completedNumber}', successCount)
            .replace('{failureNumber}', value.errors.length.toString());
          this.alertType = 'warning';
          this.alertTextClickable = true;
        }
        this.alertMessage = message;
      } else {
        this.alertType = 'error';
        this.alertMessage = error;
      }
    });
  }

  onActionClicked() {
    this.dialog.open(UsersCsvUploadErrorsDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '46.875rem',
      maxHeight: '80vh',
      restoreFocus: true,
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
      data: {
        csvHeader: this.usersUploadCsvHeader,
        uploadErrors: this.usersUploadCsvErrors,
      },
    });
  }

  hideAlertMessage() {
    this.showAlert = false;
    this.alertMessage = '';
    this.alertType = undefined;
    this.alertTextClickable = undefined;
  }

  showAlertMessage() {
    this.showAlert = true;
  }

  private initPagination(): void {
    if (this.isCardPage) {
      this.cardPagination$
        .pipe(
          takeUntil(this.subscriptionEnd$),
          filter((data) => !!data),
        )
        .subscribe((filters) => {
          const cardPagination = filters.find((cardFilter) => cardFilter.cardUid === this.cardUid)?.value;

          if (cardPagination) {
            this.changeListOrderingByBooleanFilter({
              pageSize: cardPagination.pageSize,
              page: cardPagination.page,
              order: cardPagination.order,
            });
          }
        });
    } else if (this.isPlaylistPage) {
      this.playlistPagination$
        .pipe(
          takeUntil(this.subscriptionEnd$),
          filter((data) => !!data),
        )
        .subscribe((filters) => {
          const playlistPagination = filters.find((playlistFilter) => playlistFilter.playlistUid === this.playlistUid)?.value;

          if (playlistPagination) {
            this.changeListOrderingByBooleanFilter({
              pageSize: playlistPagination.pageSize,
              page: playlistPagination.page,
              order: playlistPagination.order,
            });
          }
        });
    } else {
      this.$memberPagination
        .pipe(
          takeUntil(this.subscriptionEnd$),
          filter((data) => !!data),
        )
        .subscribe((filters) => {
          this.changeListOrderingByBooleanFilter({
            pageSize: filters.pageSize,
            page: filters.page,
            order: filters.order,
          });
        });
    }
  }

  private addParentHeaderClickListeners(): void {
    const header = document.querySelector('.f_header');
    const pageHeader = document.querySelector('.f_page-header-tab-bar');

    if (header) {
      fromEvent(header, 'click')
        .pipe(takeUntil(this.subscriptionEnd$))
        .subscribe((event: Event) => {
          const target = event.currentTarget as HTMLElement;
          if (target?.classList.contains('hide-with-overlay')) {
            this.disableDialogMode();
          }
        });
    }

    if (pageHeader) {
      fromEvent(pageHeader, 'click')
        .pipe(takeUntil(this.subscriptionEnd$))
        .subscribe((event: Event) => {
          const target = event.currentTarget as HTMLElement;
          if (target?.classList.contains('hide-with-overlay')) {
            this.disableDialogMode();
          }
        });
    }
  }

  private getMembersObservable(
    data: MembersBooleanSearchRequest,
    page?: number,
    pageSize?: number,
  ): ObservableResult<MembersSearchResponse> {
    if (this.isCardPage) {
      return this.membersDataService.searchCardMembers(data, this.playlistUid, this.cardUid, page || this.page, pageSize || this.pageSize);
    }

    if (this.isPlaylistPage && !this.isIframeMode) {
      return this.membersDataService.searchPlaylistMembers(data, this.playlistUid, page || this.page, pageSize || this.pageSize);
    }

    if (this.isPlaylistPage && this.isIframeMode && this.courseUid) {
      return this.ltiCourseDataService.searchPlaylistMembers(
        data,
        this.playlistUid,
        page || this.page,
        pageSize || this.pageSize,
        this.courseUid,
      );
    }

    return this.membersDataService.searchMembers(data, page || this.page, pageSize || this.pageSize);
  }

  private setCardTitleForEachMember(members: Members[], cardHeaders: CardHeaders[]) {
    for (let i = 0; i < cardHeaders.length; i++) {
      for (const member of members) {
        if (member.cards && member.cards.length) {
          member.cards[i].header = cardHeaders[i].header;
          if (member.cards[i].groupCards && member.cards[i].groupCards.length) {
            for (let j = 0; j < member.cards[i].groupCards.length; j++) {
              if (cardHeaders[i].childrenHeaders && cardHeaders[i].childrenHeaders[j].header) {
                member.cards[i].groupCards[j].header = cardHeaders[i].childrenHeaders[j].header;
              }
            }
          }
        }
      }
    }
    return members;
  }

  private participateInEvent(eventAction: AdminMembersAction, membersToUpdateCount: number, allMembersRefresh: boolean) {
    this.eventCardsDataService
      .adminAttendToEventBulk(this.cardUid, {
        usersUid: eventAction.memberIds,
        playlistUid: this.playlistUid,
        checkInMethod: 'MANUAL',
      })
      .subscribe(({ isSuccess, value, error }) => {
        this.refreshTableWithDelay(membersToUpdateCount, allMembersRefresh);
        if (isSuccess) {
          this.store.dispatch(new ResourceAdminActions.SetEventTickets(value));
          this.toastService.showSuccess(this.translocoService.translate('translations.successes.successAdminAttendToEvent'));
        } else {
          this.toastService.showFail(error);
        }
      });
  }

  private getColumns(): MembersListColumns[] {
    const commonColumns: MembersListColumns[] = ['SELECT', 'EXTERNAL_ID', 'FIRST_NAME', 'LAST_NAME', 'ROLES', 'EMAIL', 'GROUPS'];
    if (this.isEvent) {
      return commonColumns
        .concat(['WILL_ATTEND', 'RESPONSE_DATE', 'HAS_ATTENDED', 'CHECK_IN_METHOD', 'CHECK_IN_TIME'])
        .concat(this.contentReviewEnabled ? ['REVIEW_STATUS'] : []);
    } else if (this.isCardPage) {
      return commonColumns
        .concat(['STATUS', 'STARTED', 'COMPLETED', 'LAST_ACTIVE'])
        .concat(this.contentReviewEnabled ? ['REVIEW_STATUS'] : []);
    } else if (this.isPlaylistPage) {
      return commonColumns
        .concat(['STATUS', 'STARTED', 'COMPLETED', 'LAST_ACTIVE', 'ENROL'])
        .concat(this.contentReviewEnabled ? ['REVIEW_STATUS'] : []);
    } else {
      // admin section
      return commonColumns.concat(['LAST_ACTIVE', 'JOIN_DATE']);
    }
  }

  private preselectCheckedMembers(members: Members[]) {
    for (const member of members) {
      if (this.checkedMembers.find((m) => m._id === member._id)) {
        member.checked = true;
      }
    }
    return members;
  }

  private showErrorView(error: string) {
    if (error === '403') {
      this.showNotPermittedMessage = true;
    }
  }

  private setEventFinished(eventTime: EventCardTime) {
    if (!eventTime) {
      this.eventFinished = false;
    } else {
      const hoursToSubtract = EventCardsHelper.getHoursToSubtract(cloneDeep(eventTime.timeZone));
      const times = EventCardsHelper.getStartAndEndTimes(cloneDeep(eventTime.start), cloneDeep(eventTime.end), hoursToSubtract);
      const date = new Date().toString();
      const timezone = date.substr(date.indexOf('GMT') + 3, 5);
      if (moment(times.start).utcOffset(timezone, true).isAfter(moment().utcOffset(timezone, true))) {
        this.eventFinished = false;
      } else if (moment(times.end).utcOffset(timezone, true).isBefore(moment().utcOffset(timezone, true))) {
        this.eventFinished = true;
      } else {
        this.eventFinished = false;
      }
    }
  }
}

type MembersSearchResponseWithAllMembersCount = MembersSearchResponse & { allMembers: number };
