/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Organization } from '../../../../shared/models/organization';
import { TriggerUserAccountCreation } from '../../../store/user-auth.actions';
import { UserAuthState } from '../../../store/user-auth.state';
import { EnvironmentCode } from '../../../../../environments/environment.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from '../../../../page-modules/account/services/account-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectHelper } from '../../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { getClientId } from '../../../../shared/helpers/development-domains.helper';
import { DEFAULT_PASSWORD_REGEXP } from '../../../../shared/constants/constants';

@Component({
  templateUrl: './classof-signup.component.html',
  styleUrls: ['./classof-signup.component.scss', '../classof-auth.component.scss'],
})
export class ClassofSignupComponent implements OnInit, OnDestroy {
  @Select(UserAuthState.isLoginInProgress)
  loginInProgress$: Observable<boolean>;

  @Select(UserAuthState.isAccountCreationInProgress)
  accountCreationInProgress$: Observable<boolean>;

  @Select(UserAuthState.accountCreationError)
  accountCreationError$: Observable<string>;

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  organization: Organization;
  registerFrom: FormGroup;
  showRegisterPasswordSelected: boolean;
  passwordFocusState = false;

  private organizationDataSubscription: Subscription;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
  ) {
    this.registerFrom = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.pattern(DEFAULT_PASSWORD_REGEXP)])],
      termsOfService: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.organizationDataSubscription = this.organizationData$.subscribe((data) => {
      this.organization = data;
    });
  }

  ngOnDestroy() {
    this.organizationDataSubscription?.unsubscribe();
  }

  onSignUpClick() {
    if (this.registerFrom.valid) {
      this.store.dispatch(
        new TriggerUserAccountCreation({
          email: this.registerFrom.get('email').value?.trim(),
          password: this.registerFrom.get('password').value?.trim(),
          firstName: this.registerFrom.get('firstName').value?.trim(),
          lastName: this.registerFrom.get('lastName').value?.trim(),
        }),
      );
    } else {
      this.registerFrom.get('termsOfService').markAsDirty();
    }
  }

  signInUsingLinkedIn() {
    this.redirectToIdpViaRealm('linkedin');
  }

  signInUsingGoogle() {
    this.redirectToIdpViaRealm('google');
  }

  switchLoginForm() {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/signin/classof2020');
  }

  private redirectToIdpViaRealm(idp: string) {
    document.location.href =
      `${environment.realmUrl}protocol/openid-connect/auth?` +
      `client_id=${getClientId(this.organization.domain)}&` +
      'response_type=token&' +
      `nonce=${environment.nonce}&` +
      `kc_idp_hint=${idp}&` +
      `redirect_uri=${this.getRedirectUri()}`;
  }

  private getRedirectUri() {
    if ([EnvironmentCode.dev, EnvironmentCode.prod].includes(environment.env) && window.location.hostname.includes('localhost')) {
      return 'http://localhost:4200';
    }
    return `https://${this.organization.domain}`;
  }
}
