<table class="collector-preview__section-list-table" *ngIf="learnerCollectorSummary">
  <thead>
    <tr>
      <th class="collector-preview__log-moments-cell" colspan="6">
        {{ 'translations.collector.list.loggedItems' | transloco }}
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let loggedItem of learnerCollectorSummary.loggedItems; let i = index">
      <tr class="collector-preview__log-row">
        <td class="moment-cell">
          <div class="moment-cell-inner" *ngIf="loggedItem.moments?.length">
            <a
              class="image-box"
              href="javascript:void(0)"
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.showLoggedMoment' | transloco: { title: loggedItem.title }"
              [class.no-image]="!loggedItem.moments[0].imageUrl"
              (click)="showLoggedItemMoment(loggedItem.moments[0], loggedItem)"
              (keydown.enter)="showLoggedItemMoment(loggedItem.moments[0], loggedItem)"
            >
              <img
                class="moment-preview-image"
                alt="moment"
                *ngIf="loggedItem.moments[0].imageUrl"
                [src]="loggedItem.moments[0].imageUrl"
              />
              <mat-icon aria-hidden="true" class="icon" *ngIf="!loggedItem.moments[0].imageUrl">image</mat-icon>
            </a>
            <div class="moment-info-box">
              <a
                class="log-title"
                href="javascript:void(0)"
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.showLoggedMoment' | transloco: { title: loggedItem.title }"
                (click)="showLoggedItemMoment(loggedItem.moments[0], loggedItem)"
                (keydown.enter)="showLoggedItemMoment(loggedItem.moments[0], loggedItem)"
                [title]="loggedItem.moments[0].title"
              >
                {{ loggedItem.title }}
              </a>
              <a
                class="moment-title"
                href="javascript:void(0)"
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.showLoggedMoment' | transloco: { title: loggedItem.title }"
                (click)="showLoggedItemMoment(loggedItem.moments[0], loggedItem)"
                (keydown.enter)="showLoggedItemMoment(loggedItem.moments[0], loggedItem)"
                [title]="loggedItem.moments[0].title"
              >
                {{ loggedItem.moments[0].title ? loggedItem.moments[0].title : '---' }}
              </a>
              <a
                class="moment-date"
                href="javascript:void(0)"
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.expand' | transloco: { title: loggedItem.title }"
                (click)="loggedItem.moments.length > 1 ? expandLog(i) : ''"
                (keydown.enter)="loggedItem.moments.length > 1 ? expandLog(i) : ''"
                [title]="loggedItem.submittedTimeLogPeriod.formattedDate"
                [class.has-moments]="loggedItem.moments.length > 1"
              >
                {{
                  loggedItem.submittedTimeLogPeriod.from
                    ? loggedItem.submittedTimeLogPeriod.formattedDate
                    : (loggedItem.loggedOn | dateFormat: 'D MMMM y, HH:mm')
                }}
                <mat-icon aria-hidden="true" class="icon" [class.active]="expandedIndex === i" *ngIf="loggedItem.moments.length > 1"
                  >expand_more
                </mat-icon>
              </a>
            </div>
          </div>

          <div class="moment-cell-inner" *ngIf="loggedItem.answers?.length">
            <div class="moment-info-box">
              <div class="moment-title" *ngIf="loggedItem.title">
                <a
                  class="form-link-title"
                  href="javascript:void(0)"
                  tabindex="0"
                  [attr.aria-label]="'translations.global.ariaLabels.showLoggedForm' | transloco: { title: loggedItem.title }"
                  (click)="showLoggedItemForm(loggedItem)"
                  (keydown.enter)="showLoggedItemForm(loggedItem)"
                  [title]="loggedItem.title"
                >
                  {{ loggedItem.title }} <br />
                </a>
                <span class="form-date">{{ loggedItem.loggedOn | dateFormat: 'D MMMM y, HH:mm' }}</span>
              </div>
              <a
                class="form-link-title"
                *ngIf="!loggedItem.title"
                href="javascript:void(0)"
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.showLoggedForm' | transloco: { title: loggedItem.title }"
                (click)="showLoggedItemForm(loggedItem)"
                (keydown.enter)="showLoggedItemForm(loggedItem)"
                [title]="loggedItem.title"
              >
                {{ loggedItem.loggedOn | dateFormat: 'D MMMM y, HH:mm' }}
              </a>
            </div>
          </div>
        </td>
        <td class="date-cell">
          <div class="moment-cell-inner" *ngIf="loggedItem.answers?.length"></div>
        </td>
        <td class="tag-cell">
          <span class="tag-item" *ngFor="let tag of loggedItem.tags">{{ tag.title }}</span>
        </td>
        <td class="count-cell">{{ loggedItem.moments?.length ? loggedItem.moments.length : 1 }}</td>
        <td class="hours-cell">{{ loggedItem.submittedTimeLogPeriod.time }}</td>
        <td
          class="action-cell"
          *ngIf="!staticView && (learnerCollectorSummary?.status !== 'COMPLETED' || requiresResubmission)"
          title="Edit"
          tabindex="0"
          [attr.aria-label]="'translations.global.ariaLabels.editLoggedItem' | transloco: { title: loggedItem.title }"
          (click)="editLoggedItem(loggedItem)"
          (keydown.enter)="editLoggedItem(loggedItem)"
        >
          <mat-icon aria-hidden="true" class="icon">edit</mat-icon>
        </td>
        <td
          class="action-cell delete"
          title="Delete"
          *ngIf="!staticView && (learnerCollectorSummary?.status !== 'COMPLETED' || requiresResubmission)"
          tabindex="0"
          [attr.aria-label]="'translations.global.ariaLabels.deleteLoggedItem' | transloco: { title: loggedItem.title }"
          (click)="deleteLoggedItem(loggedItem)"
          (keydown.enter)="deleteLoggedItem(loggedItem)"
        >
          <mat-icon aria-hidden="true" class="icon">delete</mat-icon>
        </td>
      </tr>
      <tr class="collector-preview__log-nested-row" *ngIf="expandedIndex === i">
        <td class="moment-cell" colspan="5" *ngIf="loggedItem.moments?.length">
          <a
            class="moment-cell-inner"
            href="javascript:void(0)"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.showLoggedItem' | transloco: { title: loggedItem.title }"
            *ngFor="let moment of loggedItem.moments | slice: 1"
            (click)="showLoggedItemMoment(moment, loggedItem)"
            (keydown.enter)="showLoggedItemMoment(moment, loggedItem)"
          >
            <div class="image-box" [class.no-image]="!moment.imageUrl">
              <img class="moment-preview-image" alt="" width="100" height="80" *ngIf="moment.imageUrl" [src]="moment.imageUrl" />
              <mat-icon aria-hidden="true" class="icon" *ngIf="!moment.imageUrl">image</mat-icon>
            </div>
            <div class="moment-info-box">
              <span class="moment-title" [title]="moment.title">{{ moment.title ? moment.title : '---' }}</span>
            </div>
          </a>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
