/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DATA_SERVICE, QuizDataService } from '@app/app/editor/services/data.service';
import * as PlaylistViewActions from '@app/app/page-modules/playlist/store/view/playlist-view.state.actions';
import { LearnerFormAnswer } from '@app/app/page-modules/resource/models';
import { RefreshLearnerViewCardStatus } from '@app/app/page-modules/resource/store/learner-view.actions';
import { LearnerViewState } from '@app/app/page-modules/resource/store/learner-view.state';
import { ContentHelper } from '@app/app/shared/helpers/content-helper';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { LocalTimeHelper } from '@app/app/shared/helpers/local-time-helper';
import { Form, Organization, QuizFormContent, UserPlaylistSubmissionSummary } from '@app/app/shared/models';
import { Quiz, QuizSummary } from '@app/app/shared/models/editor/quiz-content.model';
import { DEFAULT_LANGUAGE_CODE } from '@app/app/shared/services/languages/language.service';
import { UserAuthState } from '@app/app/user-auth/store/user-auth.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { LEARNER_VIEW_DATA_SERVICE, LearnerViewDataService } from 'src/app/page-modules/resource/services/data.service';
import { QuizDialogComponent } from '../../../../../page-modules/resource/components/view/quizzes/quiz-dialog/quiz-dialog.component';
import { QuizResultsDialogComponent } from '../../../../../page-modules/resource/components/view/quizzes/quiz-results-dialog/quiz-results-dialog.component';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ptl-form-preview-quiz',
  templateUrl: './form-preview-quiz.component.html',
  styleUrls: ['./form-preview-quiz.component.scss'],
})
export class FormPreviewQuizComponent implements OnInit, OnDestroy, OnChanges {
  @Input() content: Form<QuizFormContent>;
  @Input() learnerFormAnswer: LearnerFormAnswer;
  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  @Input() playlistUri: string;
  @Input() playlistUid: string;
  @Input() resourceUri: string;
  @Input() publisherUri: string;
  @Input() packageUri: string;
  @Input() pageUri: string;
  @Input() languageCode: string;

  @Select(LearnerViewState.currentLanguage)
  private currentLanguage$: Observable<string>;

  @Select(UserAuthState.organizationDetails)
  private organization$: Observable<Organization>;

  quiz: Quiz;
  latestVersionNumber: number;
  quizTaken: boolean;
  resourceUid: string;
  resultUid: string = undefined;
  disabled = true;
  summary: QuizSummary;
  canRetake: boolean;
  canEdit = false;
  canNotEditReason: string;
  resume = false;
  reloadPending = false;
  currentLanguage: string;
  title: string;
  organization: Organization;
  quizButtonStateMessage: string;
  validationInProgress = false;
  validationProcessed = false;
  correctAnswersPercent: number;
  requiredToPassPercent: number;
  quizPassed: boolean;
  quizCompleted: boolean;

  private subscriptionEnd$ = new Subject<void>();

  constructor(
    @Inject(DATA_SERVICE) private quizDataService: QuizDataService,
    @Inject(LEARNER_VIEW_DATA_SERVICE) private dataService: LearnerViewDataService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private translocoService: TranslocoService,
  ) {
    this.organization$.pipe(take(1)).subscribe((org) => (this.organization = org));
    this.resourceUid = this.store.selectSnapshot(LearnerViewState.resourceId);
  }

  ngOnInit() {
    this.getSummary();
  }

  ngOnDestroy() {
    this.subscriptionEnd$.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.playlistUid && this.resourceUid && this.content?.uid) {
      this.checkSubmissionMode();
    } else {
      if (changes.learnerFormAnswer || changes.userPlaylistSubmissionSummary) {
        this.checkSubmissionMode();
      }
    }
    this.quizCompleted = this.learnerFormAnswer?.submitted;
  }

  getSummary() {
    this.currentLanguage$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((language) => {
      this.currentLanguage = language ? language : DEFAULT_LANGUAGE_CODE;
      this.quizDataService.getQuizSummary(this.resourceUid, this.content.content.quizId, language).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.title = LanguageCodeHelper.getDataByUserLanguageCode(value.title, this.organization, language).value;
          if (!value.quizResponsesUid) {
            this.quizTaken = false;
          }
          if (value.retake) {
            if (!value.completed) {
              this.resume = value.answeredQuestions !== 0;
              this.quizTaken = false;
            } else {
              this.resume = false;
              this.quizTaken = true;
            }
            this.canRetake = true;
          } else {
            if (!value.completed) {
              this.resume = value.answeredQuestions !== 0;
            } else {
              this.quizTaken = true;
            }
            this.canRetake = false;
          }
          this.resultUid = value.quizResponsesUid;
          this.latestVersionNumber = value.lastVersionNumber;
          this.summary = value;
          if (value.totalQuestions > 0) {
            this.correctAnswersPercent = Math.round((value.correctAnswersCount / value.totalQuestions) * 100);
            this.requiredToPassPercent = Math.round((value.totalRequired / value.totalQuestions) * 100);
            this.quizPassed = value.correctAnswersCount >= value.totalRequired;
          }
          if (this.reloadPending) {
            this.showDialog();
            this.reloadPending = false;
          }
          this.checkSubmissionMode();
          this.quizButtonStateMessage = this.getQuizButtonStateButton();

          this.cdr.detectChanges();
        }
      });
    });
  }

  retake() {
    this.reloadPending = true;
    this.quizTaken = false;
    this.latestVersionNumber += 1;
    this.quizDataService
      .retakeQuiz(this.playlistUid, this.content.content.quizId, this.resultUid, this.latestVersionNumber)
      .subscribe(() => this.getSummary());
  }

  showDialog(): void {
    if (!this.quizTaken) {
      this.showQuizDialog();
    } else {
      this.showResultDialog();
    }
  }

  getQuizButtonStateButton(): string {
    let message: string;

    if (!this.quizTaken) {
      if (this.resume) {
        message = this.translocoService.translate('translations.resume');
      } else {
        message = this.translocoService.translate('translations.start');
      }
    } else if (this.canRetake && !this.resume) {
      message =
        this.translocoService.translate('translations.retake') + ' or ' + this.translocoService.translate('translations.viewResults');
    } else {
      message = this.translocoService.translate('translations.viewResults');
    }

    return message;
  }

  getLocalDateTime(date: Date): string {
    return LocalTimeHelper.getLocalDateTime(date).toString();
  }

  private showQuizDialog(): void {
    const dialogRef = this.dialog.open(QuizDialogComponent, {
      width: '100%',
      height: '100%',
      position: {
        top: '0vh',
      },
      panelClass: ['quiz-dialog'],
      backdropClass: 'dialog-backdrop',
      restoreFocus: true,
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      data: {
        quizId: this.content.content.quizId,
        quizTaken: this.quizTaken,
        resourceUid: this.resourceUid,
        resultUid: this.resultUid,
        latestVersionNumber: this.latestVersionNumber,
        retake: this.summary.retake,
        summary: this.summary,
        isReviewResult: false,
      },
    });

    const sub = dialogRef.componentInstance.action.subscribe();

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        timer(1000).subscribe(() => {
          this.store.dispatch(new PlaylistViewActions.RefreshUserPlaylistSubmissionSummary(this.playlistUid));
          this.store.dispatch(
            new RefreshLearnerViewCardStatus(
              this.playlistUri,
              this.resourceUri,
              this.publisherUri,
              this.packageUri,
              this.pageUri,
              this.languageCode,
            ),
          );
        });

        if (result.event === 'showReport') {
          this.quizTaken = true;
          this.resultUid = result.resultUid;
          this.cdr.detectChanges();
          this.getSummary();
          this.showDialog();
        }

        if (result.event === 'save') {
          this.resultUid = result.resultUid || undefined;
          this.getSummary();
        }
        if (result.event === 'retake') {
          this.resultUid = result.resultUid;
          this.quizTaken = true;
          this.retake();
        }
      } else {
        this.getSummary();
      }
      sub.unsubscribe();
    });
  }

  private showResultDialog(): void {
    const dialogRef = this.dialog.open(QuizResultsDialogComponent, {
      width: '50%',
      height: '80%',
      position: {
        top: '5vh',
      },
      panelClass: 'quiz-dialog',
      backdropClass: 'dialog-backdrop',
      restoreFocus: true,
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      data: {
        quizId: this.content.content.quizId,
        quizTaken: this.quizTaken,
        resourceUid: this.resourceUid,
        resultUid: this.resultUid,
        latestVersionNumber: this.latestVersionNumber,
        summary: this.summary,
        retake: this.summary?.retake,
        isReviewResult: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.event === 'retake') {
        this.resultUid = result.resultUid;
        this.quizTaken = true;
        this.retake();
      }
    });
  }

  private checkSubmissionMode(): void {
    if (!this.learnerFormAnswer) {
      if (!this.playlistUid || !this.resourceUid || !this.content?.uid) {
        return;
      }
      if (!this.validationInProgress && !this.validationProcessed) {
        this.validationInProgress = true;
        this.dataService.validateFormUpdate(this.playlistUid, this.resourceUid, this.content?.uid).subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            this.canEdit = value.canBeUpdated;
            this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translocoService, value);
            this.validationProcessed = true;
          }
          this.checkSubmissionLocked();
          this.cdr.detectChanges();
          this.validationInProgress = false;
        });
      }
    } else {
      this.canEdit = this.learnerFormAnswer?.updatePermission?.canBeUpdated;
      this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translocoService, this.learnerFormAnswer?.updatePermission);
      this.checkSubmissionLocked();
      this.cdr.detectChanges();
    }
  }

  private checkSubmissionLocked(): void {
    if (this.userPlaylistSubmissionSummary?.submissionsLocked) {
      this.canEdit = false;
      this.canNotEditReason = this.translocoService.translate('translations.formUpdateDisabledReasons.submissionClosed');
    }
  }
}
