/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserDetails } from '../../../../../user-auth/models';
import {
  EditorContent,
  EditorContentRemovalEvent,
  EditorContentUpdate,
  EditorContentUpdateEvent,
  ExternalContentSummary,
  MediumEditorData,
  ResourceTag,
} from '../../../../../shared/models';
import { Moment } from '../../../models';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { take } from 'rxjs/operators';
import { uniqWith, isEqual } from 'lodash-es';
import {
  EmbeddableContentDetails,
  EMBEDS_CONTENT_GENERATOR_SERVICE,
  EmbedsContentGeneratorService,
} from '../../../../../editor/services/embeds-content-generator.service';
import { CORE_TAG_DATA_SERVICE, TagDataService } from '../../../../../shared/services/tags/tag-data.service';
import { EditorHelper } from '../../../../../shared/helpers/editor.helper';
import { MomentCommonHelpers } from '../../services/common.helpers';
import { SpecialEmbedsHelper } from '../../../../../shared/helpers/embeds/special-embeds.helper';

const URL_VALIDATION_PATTERN = /(http[s]?:\/\/)[^\s(["<,>]*\.[^\s[",><]*/gim;

@Component({
  selector: 'ptl-folio-new-moment-section',
  template: '',
})
export class FolioNewMomentSectionComponent {
  /** Receives the moment data. */
  @Input() moment?: Moment;
  /** True when the editor state is being loaded(i.e. a moment being loaded or created) */
  @Input() loading: boolean;
  /** The label to display on the form submit button. */
  @Input() submitLabel = 'Add Post';
  /** Defines if form is expanded on init, else it expands on medium-editor focus. */
  @Input() isExpanded?: boolean;

  @Input() paddingReduced: boolean;

  @Output() momentDataChanged = new EventEmitter<Moment>();
  @Output() actionButtonClicked = new EventEmitter<void>();

  @Select(UserAuthState.userDetailsData)
  userDetailsData$: Observable<UserDetails>;

  private uploadedUrlTryCount = 0;

  constructor(
    @Inject(EMBEDS_CONTENT_GENERATOR_SERVICE) protected embedsService: EmbedsContentGeneratorService,
    @Inject(CORE_TAG_DATA_SERVICE) protected tagService: TagDataService,
  ) {}

  onAddTag(tag: ResourceTag) {
    const concatenatedTags = this.moment.tags.concat(tag);
    const uniquesTags = uniqWith(concatenatedTags, isEqual);
    this.moment = { ...this.moment, tags: uniquesTags };
    this.momentDataChanged.next(this.moment);
  }

  onAddTagBulk(tags: ResourceTag[]) {
    for (const tag of tags) {
      this.onAddTag(tag);
    }
  }

  onRemoveTag(tagIndex: number) {
    this.moment = { ...this.moment, tags: this.moment.tags.filter((_, i) => i !== tagIndex) };
    this.momentDataChanged.next(this.moment);
  }

  saveMoment() {
    this.actionButtonClicked.emit();
  }

  clearMoment() {
    this.moment = {
      title: '',
      tags: [],
      content: [],
      addedToFolio: false,
    };

    this.momentDataChanged.next(this.moment);
  }

  onContentUpdate({ index, update }: EditorContentUpdateEvent) {
    if (this.newMomentIsCreated) {
      this.extractTagsAfterEveryNewLine(update);
    }
    this.moment = {
      ...this.moment,
      content: this.moment.content.map((editorContent, i) => (i === index ? update.newContent : editorContent)),
    };

    this.updateMomentAndTitle(this.moment);

    if (update.newContent.type === 'PARAGRAPH') {
      this.checkForURLsInContent((update.newContent as MediumEditorData).content);
    }
  }

  onContentRemoval({ index }: EditorContentRemovalEvent) {
    const filteredContent = this.moment.content.filter((_, i) => i !== index);
    this.moment = {
      ...this.moment,
      content: EditorHelper.joinSuccessiveParagraphContent(filteredContent),
    };
    this.updateMomentAndTitle(this.moment);
  }

  onContentCreation(newContent) {
    this.moment = {
      ...this.moment,
      tags: this.getMomentModifiedTags(this.moment.tags, newContent.tags),
      content: this.moment.content.concat(newContent),
    };

    this.addEmptyParagraphAfterIFramelyAddedItem(newContent.type);
    this.updateMomentAndTitle(this.moment);
    this.uploadedUrlTryCount = 0;
  }

  protected get newMomentIsCreated(): boolean {
    return !this.moment._id;
  }

  protected extractTagsAfterEveryNewLine(update: EditorContentUpdate) {
    const newContent = update.newContent;
    if (newContent.type === 'PARAGRAPH') {
      const newHtmlContent = (newContent as MediumEditorData).content;
      const oldHtmlContent = (update.oldContent as MediumEditorData).content;
      const currentParagraphsCount = this.paragraphsCount(newHtmlContent);
      if (currentParagraphsCount > 1 && currentParagraphsCount > this.paragraphsCount(oldHtmlContent)) {
        const text = MomentCommonHelpers.textExtractedFrom(newHtmlContent);
        this.tagService.extractTags(text).subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            value.forEach((title) => {
              const newTag = { title: title } as ResourceTag;
              this.onAddTag(newTag);
            });
          }
        });
      }
    }
  }

  protected paragraphsCount(newHtmlContent): number {
    return (newHtmlContent.match(/<p>/g) || []).length;
  }

  protected getMomentModifiedTags(existingTags, tagsArray: string[]) {
    if (tagsArray && tagsArray.length) {
      return existingTags.concat(
        tagsArray.map((tag) => {
          return { title: tag.trim() };
        }),
      );
    }

    return existingTags;
  }

  protected checkForURLsInContent(html: string) {
    const regex = new RegExp(URL_VALIDATION_PATTERN);
    const regexMatches = html.match(regex);
    if (this.uploadedUrlTryCount >= 1) {
      return;
    }
    if (regexMatches) {
      this.uploadedUrlTryCount++;
      const lastUrl = regexMatches[regexMatches.length - 1].replace(/&nbsp;/g, '');
      let urlAlreadyPresent = false;
      for (const contentItem of this.moment.content) {
        if (contentItem.type === 'EXTERNAL_CONTENT_SUMMARY') {
          const url = (contentItem as ExternalContentSummary).url;
          if (url === lastUrl || lastUrl.includes(url)) {
            urlAlreadyPresent = true;
            break;
          }
        }
      }

      if (!urlAlreadyPresent) {
        this.createExternalContentSummary(lastUrl);
      } else {
        this.uploadedUrlTryCount = 0;
      }
    }
  }

  protected createExternalContentSummary(url: string) {
    const correctUrl = SpecialEmbedsHelper.correctUrl(url);
    return this.embedsService
      .retrieveEmbeddableContentDetails(encodeURI(correctUrl))
      .pipe(take(1))
      .subscribe(({ isSuccess, value }) => {
        let contentDetails: EmbeddableContentDetails;

        if (isSuccess) {
          contentDetails = value;
        } else {
          contentDetails = SpecialEmbedsHelper.generateEmbedFromUrl(correctUrl);
        }

        if (contentDetails) {
          const newExternalContentData = {
            type: 'EXTERNAL_CONTENT_SUMMARY',
            url: contentDetails.url,
            authorName: contentDetails.authorName,
            logoUrl: contentDetails.logoUrl,
            title: contentDetails.title,
            description: contentDetails.description,
            publicationDate: contentDetails.publicationDate,
            thumbnailUrl: contentDetails.thumbnailUrl,
            siteName: contentDetails.siteName,
            position: 'CENTER',
            tags: contentDetails.tags,
            html: contentDetails.html,
          } as ExternalContentSummary;

          this.onContentCreation(newExternalContentData);
        }
      });
  }

  private updateMomentAndTitle(moment: Moment) {
    const title = MomentCommonHelpers.getTitleFromMomentContent(moment);

    this.moment = {
      ...this.moment,
      title,
    };

    this.momentDataChanged.next(this.moment);
  }

  protected addEmptyParagraphAfterIFramelyAddedItem(newContentType): void {
    if (newContentType === 'EXTERNAL_CONTENT_SUMMARY' || newContentType === 'MEDIA_UPLOAD') {
      this.moment = {
        ...this.moment,
        content: this.moment.content.concat({ type: 'PARAGRAPH', content: '' } as EditorContent),
      };
    }
  }
}
