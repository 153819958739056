<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<div class="collector-detail-dialog__container">
  <div class="collector-detail-dialog__header">
    <div class="collector-detail-dialog__logo">
      <mat-icon aria-hidden="true">card_travel</mat-icon>
    </div>
    <div class="collector-detail-dialog__info">
      <div>
        {{ 'translations.collector.label.timeLogged' | transloco: { date: loggedDate | dateFormat: 'DD/MM/yyyy' } }}
        <ng-container *ngIf="timestamp?.from && timestamp?.to">
          {{
            'translations.collector.label.timeStampFromTo'
              | transloco
                : {
                    fromDate: timestamp?.from | dateFormat: 'DD/MM/yyyy',
                    toDate: timestamp?.to | dateFormat: 'DD/MM/yyyy',
                  }
          }}
        </ng-container>
      </div>
      <div *ngIf="tags?.length">
        {{ 'translations.framework.label.tags' | transloco }}
        <span *ngFor="let tag of tags; let i = index"> {{ tag.title + (i !== tags.length - 1 ? ',' : '') }}</span>
      </div>
    </div>
    <button mat-button [mat-dialog-close]="false" class="collector-detail-dialog__close">
      <i class="material-icons" aria-hidden="true">close</i>
    </button>

    <div class="collector-detail-dialog__title-form-title" *ngIf="logTitle || momentTitle" tabindex="0">
      {{ logTitle ? logTitle : momentTitle }}
    </div>

    <ng-container *ngIf="moment">
      <div class="collector-detail-dialog__view-moment">
        <ptl-resource-preview [previewContent]="moment?.content"> </ptl-resource-preview>

        <div class="collector-detail-dialog__view-moment-tags-container" *ngIf="moment?.tags && moment?.tags.length">
          <ul class="collector-detail-dialog__view-moment-tags-list">
            <li class="collector-detail-dialog__view-moment-tags-list-item" *ngFor="let tag of moment?.tags">
              {{ tag.title }}
            </li>
          </ul>
        </div>
      </div>
    </ng-container>

    <ptl-resource-preview *ngIf="logTemplate" [previewContent]="logTemplate"> </ptl-resource-preview>
  </div>
</div>
