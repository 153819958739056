/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ResourceDataService } from '../../../../services/editor/core/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgZone } from '@angular/core';
import { of } from 'rxjs';
import { ObservableResult } from '../../../../../../shared/store';
import { SnackbarHelper } from '../../../../../../shared/helpers/snackbar-helper';
import { TranslocoService } from '@ngneat/transloco';
import { ResourceAdminStateModel } from '../../../admin/resource-admin.state.model';
import * as ResourceAdminActions from '../../../admin/resource-admin.actions';

export class BasicActionHelpers {
  // static updateHeadline(
  //   state: ResourceEditorContentStateModel,
  //   patchState: (p: Partial<ResourceEditorContentStateModel>) => Partial<ResourceEditorContentStateModel>,
  //   { headline }: UpdateHeadline
  // ) {
  //
  //   patchState({
  //     content: {
  //       ...state.content,
  //       headline,
  //       contentChanged: true,
  //     },
  //     saveStatus: {
  //       ...state.saveStatus,
  //       saveFailureReason: '',
  //     },
  //     validationErrors: [],
  //   });
  // }
  //
  // static deleteResource(
  //   playlistUid: string,
  //   sectionUid: string,
  //   resourceUid: string,
  //   dataService: ResourceDataService,
  //   patchState: (p: Partial<ResourceEditorContentStateModel>) => Partial<ResourceEditorContentStateModel>,
  //   store: Store,
  //   snackBar: MatSnackBar,
  //   ngZone: NgZone,
  //   translocoService: TranslocoService,
  // ) {
  //
  //   // Call api only if sectionUid is provided
  //   const observable$: ObservableResult<void> = sectionUid ?
  //     dataService.deleteResource(playlistUid, sectionUid, resourceUid) :
  //     of({ isSuccess: true, error: undefined });
  //
  //   return observable$.pipe(
  //     tap(({ isSuccess, error }) => {
  //       if (isSuccess) {
  //         SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translocoService, 'translations.cardDeleted');
  //         store.dispatch(new RedirectToPlaylistOrResourcePage());
  //         store.dispatch(new ResetState());
  //       } else {
  //         SnackbarHelper.showSnackBar(ngZone, snackBar, error);
  //       }
  //     })
  //   );
  // }

  static updateResourceHeadline(
    state: ResourceAdminStateModel,
    patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
    headline: string,
  ) {
    patchState({
      resource: {
        ...state.resource,
        content: {
          ...state.resource.content,
          headline: headline,
        },
      },
      contentChanged: true,
      saveStatus: {
        ...state.saveStatus,
        saveFailureReason: '',
      },
    });
  }

  static deleteResourceAdmin(
    playlistUid: string,
    sectionUid: string,
    resourceUid: string,
    dataService: ResourceDataService,
    store: Store,
    snackBar: MatSnackBar,
    ngZone: NgZone,
    translocoService: TranslocoService,
  ) {
    // Call api only if sectionUid is provided
    const observable$: ObservableResult<void> = sectionUid
      ? dataService.deleteResource(playlistUid, sectionUid, resourceUid)
      : of({ isSuccess: true, error: undefined });

    return observable$.pipe(
      tap(({ isSuccess, error }) => {
        if (isSuccess) {
          SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translocoService, 'translations.cardDeleted');
          store.dispatch(new ResourceAdminActions.RedirectToPlaylistOrResourcePage());
          store.dispatch(new ResourceAdminActions.ResetResourceAdminState());
        } else {
          SnackbarHelper.showSnackBar(ngZone, snackBar, error);
        }
      }),
    );
  }
}
