/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Playlist, Resource } from 'src/app/shared/models';
import { ObservableResult } from 'src/app/shared/store';
import { PlaylistWebLinkCreationRequest } from '../../playlist/models';
import { ProjectCreationRequest } from '../models/project.model';
import { ProjectDataService } from './data.service';
import { CreatePlaylistResponse } from '../../playlist/services/create/core/models/create-playlist-response';

export class MockProjectDataService implements ProjectDataService {
  createProject(request: ProjectCreationRequest): ObservableResult<CreatePlaylistResponse> {
    return ObservableResult.ofSuccess();
  }

  getProjectDetails(playlistUri: string, languageCode?: string): ObservableResult<Playlist> {
    return ObservableResult.ofSuccess();
  }

  createEmptyCard(playlistUid: string, sectionUid: string): ObservableResult<Resource> {
    return ObservableResult.ofSuccess();
  }

  addWebLinkToPlaylistSection(
    playlistUid: string,
    sectionUid: string,
    requestBody: PlaylistWebLinkCreationRequest,
  ): ObservableResult<Resource> {
    return ObservableResult.ofSuccess();
  }

  publishAndShowProjectOnFolio(projectUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  unPublishAndHideProjectVisibleOnFolio(projectUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
