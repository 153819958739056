<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="languages-main-container">
  <mat-card class="version-new-card">
    <div class="header-new">
      <h1 class="large header-title" tabindex="0" [attr.aria-label]="'translations.global.languages.languageTitle' | transloco">
        {{ 'translations.global.languages.headline' | transloco }}
      </h1>
      <p class="info small">{{ 'translations.global.languages.information.descriptionNew' | transloco }}</p>
    </div>

    <div class="add-new-languages-container" *ngIf="availableOrgLanguages && availableOrgLanguages.length">
      <div class="add-new-language" *ngIf="!showNewLanguages">
        <button
          mat-raised-button
          class="add-new-language-btn"
          type="button"
          color="primary"
          tabindex="0"
          [attr.aria-label]="'translations.global.ariaLabels.tapToShowAddNewLanguage' | transloco"
          *ngIf="availableOrgLanguages?.length > 0"
          (click)="showNewLanguages = !showNewLanguages"
        >
          <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.plus' | transloco">
            <use ogSvgUnify="assets/sprite/sprite.svg#plus"></use>
          </svg>
          <span>{{ 'translations.global.languages.button.add' | transloco }}</span>
        </button>
      </div>

      <div class="add-language-select" *ngIf="showNewLanguages">
        <mat-form-field>
          <mat-label>Language</mat-label>
          <mat-select [formControl]="chosenLanguage">
            <mat-option
              *ngFor="let languageOption of availableOrgLanguages"
              [value]="languageOption.language.code"
              [attr.aria-label]="
                'translations.global.ariaLabels.tapToSelectLanguage'
                  | transloco
                    : {
                        language: languageOption.language.name,
                      }
              "
            >
              {{ languageOption.language.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <button
          mat-raised-button
          class="uppercase"
          type="button"
          color="primary"
          [attr.aria-label]="'translations.global.ariaLabels.save' | transloco"
          (click)="onLanguageSave()"
        >
          <span class="publish-card__footer-btn-text">
            <span>{{ 'translations.global.button.save' | transloco }}</span>
          </span>
        </button>

        <button
          mat-button
          (click)="showNewLanguages = !showNewLanguages"
          class="uppercase"
          type="button"
          [attr.aria-label]="'translations.global.ariaLabels.cancel' | transloco"
          color="primary"
        >
          <span>
            <span>{{ 'translations.global.button.cancel' | transloco }}</span>
          </span>
        </button>
      </div>
    </div>
  </mat-card>

  <mat-card class="version-new-card languages-section">
    <div class="languages-list-header">
      <div class="languages-list-header-item small bold">
        {{ 'translations.global.languages.label.enable' | transloco }}
      </div>
      <div class="languages-list-header-item language-item small bold">
        {{ 'translations.global.languages.label.language' | transloco }}
      </div>
      <div class="languages-list-header-item small bold">
        {{ 'translations.global.languages.label.remove' | transloco }}
      </div>
    </div>

    <div class="languages-list">
      <ptl-skeleton-view [state]="{ loading: true }" [viewType]="'table'" *ngIf="skeletonViewActive"> </ptl-skeleton-view>

      <ng-container *ngIf="!skeletonViewActive">
        <div
          class="languages-item"
          *ngFor="let supportedLanguage of activeLanguages; let last = last; let i = index"
          [ngClass]="{ is_last: last && i > 0 }"
        >
          <div class="languages-item-column">
            <mat-slide-toggle
              color="primary"
              [attr.aria-label]="
                checkIfIsEnabled(supportedLanguage)
                  ? ('translations.global.ariaLabels.tapToDisableLanguage'
                    | transloco
                      : {
                          language: supportedLanguage,
                        })
                  : ('translations.global.ariaLabels.tapToEnableLanguage'
                    | transloco
                      : {
                          language: supportedLanguage,
                        })
              "
              class="language-toggle"
              [checked]="checkIfIsEnabled(supportedLanguage)"
              (change)="onToggleEnable($event, supportedLanguage.language.code)"
              [disabled]="supportedLanguage.isDefault"
              [tabIndex]="0"
              [aria-label]="
                (checkIfIsEnabled(supportedLanguage)
                  ? ('translations.global.ariaLabels.pressSpace'
                    | transloco: { action: ('translations.global.languages.toggle.disable' | transloco) })
                  : ('translations.global.ariaLabels.pressSpace'
                    | transloco: { action: ('translations.global.languages.toggle.enable' | transloco) })) +
                ': ' +
                supportedLanguage.language.name
              "
            >
            </mat-slide-toggle>
          </div>
          <div class="languages-item-column language-title">
            <span class="language__title small"> {{ supportedLanguage.language.name }}</span>
          </div>

          <div class="languages-item-column language-remove">
            <button
              mat-icon-button
              type="button"
              class="remove"
              *ngIf="i > 0"
              [attr.aria-label]="'translations.global.ariaLabels.remove' | transloco: { name: supportedLanguage.language.name }"
              (click)="onLanguageRemove(supportedLanguage.language.code)"
            >
              <svg class="svg svg-icon" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
              </svg>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-card>

  <mat-card class="version-new-card how-it-works-section">
    <h3 class="large">{{ 'translations.global.languages.information.howItWorks' | transloco }}</h3>

    <div class="how-it-works-img"></div>
  </mat-card>
</div>
