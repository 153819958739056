/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { DiagnosticQuestionResponse, LanguageValue } from '..';
import { QuizReportResponse, QuizResponse } from '../../../page-modules/resource/models';
import { DEFAULT_LANGUAGE_CODE } from '../../services/languages/language.service';

export interface Quiz {
  _id?: string;
  title?: LanguageValue[];
  instruction?: LanguageValue; // request
  instructions?: LanguageValue[]; // response
  questions?: DiagnosticQuestionResponse[];
  responses?: QuizResponse;
  latestVersionNumber: number;
  settings?: QuizSettings;
  creatorUid?: string;
  cardId?: string;
  syndicated?: boolean;
  syndication?: {
    publisher: { organization?: string };
    license: { masterId?: string };
    status: string;
    activatedOn: Date;
    deactivatedOn: Date;
  };
}

export interface UpdateQuizRequest {
  title?: LanguageValue;
  instruction?: LanguageValue;
  settings?: QuizSettings;
}

export interface QuizSettings {
  passCriteria: {
    criteria: 'ALL_CORRECT' | 'PARTOF_CORRECT';
    partOf: number;
  };
  submissionCriteria: {
    mustPass: boolean;
  };
  retakeCriteria: 'UNTIL_PASSED' | 'UNLIMITED' | 'ONCE';
  resultDisplayPolicy: 'DETAILED' | 'SUMMARY';
}

export function createQuiz(): Quiz {
  const languageCode = DEFAULT_LANGUAGE_CODE;
  return {
    title: [{ languageCode: languageCode, value: 'Quiz' }],
    instructions: [],
    questions: [
      {
        uid: 'MthzX8AOP9oXegoR',
        instructions: [{ languageCode: languageCode, value: '<p>Lorem ipsum</p>' }],
        values: [{ languageCode: languageCode, value: '' }],
        type: 'CHOICE_QUESTION',
        tags: [],
        selectionLimit: 1,
        optionsPerRow: 1,
        allowEvidence: false,
        feedback: [],
        required: false,
        options: [
          {
            type: 'CHOICE_OPTION',
            uid: 'IgGhYx95gVGvDuig',
            values: [{ languageCode: languageCode, value: 'Lorem ipsum' }],
            score: 0,
            deselectOthers: false,
            correct: true,
            direction: 'NONE',
          },
          {
            type: 'CHOICE_OPTION',
            uid: 'PKKitkXLTmOolIxO',
            values: [{ languageCode: languageCode, value: 'Lorem ipsum 2' }],
            score: 0,
            deselectOthers: false,
            correct: false,
            direction: 'NONE',
          },
        ],
      },
    ],
    latestVersionNumber: 1,
    settings: {
      passCriteria: {
        criteria: 'ALL_CORRECT',
        partOf: 0,
      },
      submissionCriteria: {
        mustPass: true,
      },
      retakeCriteria: 'UNTIL_PASSED',
      resultDisplayPolicy: 'SUMMARY',
    },
    syndicated: false,
    syndication: {
      publisher: { organization: 'YUNA' },
      license: { masterId: '123456' },
      status: 'ACTIVE',
      activatedOn: undefined,
      deactivatedOn: undefined,
    },
  };
}

export function quizQuestion(): QuizReportResponse {
  const languageCode = DEFAULT_LANGUAGE_CODE;
  return {
    questionUid: 'zGlszCHRAfeb2IPQ',
    instructions: [{ languageCode: languageCode, value: '<p>rtmtmurymu</p>' }],
    type: 'SLIDER_QUESTION',
    answeredCorrectly: true,
    options: [
      {
        optionUid: 'nDLZliEDnT6m1q7Z',
        value: [{ languageCode: languageCode, value: 'aw daw daw ' }],
        correct: false,
        selected: true,
      },
    ],
    feedback: [],
  };
}

export interface QuizSummary {
  answeredQuestions: number;
  lastVersionNumber: number;
  passed: boolean;
  quizResponsesUid: string;
  retake: boolean;
  title: LanguageValue[];
  totalQuestions: number;
  completed: boolean;
  totalRequired: number;
  correctAnswersCount: number;
}

export interface QuizResultDialogData {
  quizId: string;
  userUid: string;
  quizTaken: boolean;
  resourceUid: string;
  resultUid: string;
  latestVersionNumber: number;
  retake: boolean;
  summary: QuizSummary;
  isReviewResult: boolean;
}
