/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SidenavSettings, SidenavTreeSettings, SidenavTreeSettingUpdatedItem } from '../../models';
import * as SideNavActions from './side-nav.actions';

/** The sidenav state definition. */
export interface SideNavStateModel {
  settings: SidenavSettings;
  tree: SidenavTreeSettings;
}

@State<SideNavStateModel>({
  name: 'sidenav',
  defaults: {
    settings: {
      isFolioPage: false,
      isContentStorePage: false,
      isDiscoveryPage: false,
      expandRecentPlaylists: true,
      expandFavoritePlaylists: true,
      expandYourPlaylists: true,
      scrollPosition: 0,
    },
    tree: {
      showSidebarTree: true,
      showSidebarWorkspaceNav: true,
      resetSidebarTree: false,
      updatedItem: {
        uid: '',
        options: {
          title: '',
          uri: '',
          oldUri: '',
          published: undefined,
        },
      },
      updatedTreeItem: {
        uid: '',
        updateParent: false,
      },
    },
  },
})
@Injectable()
export class SideNavState {
  @Selector()
  static settings({ settings }: SideNavStateModel): SidenavSettings {
    return settings;
  }

  @Selector()
  static isDiscoveryPage({ settings }: SideNavStateModel): boolean {
    return settings.isDiscoveryPage;
  }

  @Selector()
  static isContentStorePage({ settings }: SideNavStateModel): boolean {
    return settings.isContentStorePage;
  }

  @Selector()
  static isFolioPage({ settings }: SideNavStateModel): boolean {
    return settings.isFolioPage;
  }

  @Selector()
  static showSidebarTree({ tree }: SideNavStateModel): boolean {
    return tree.showSidebarTree;
  }

  @Selector()
  static showSidebarWorkspaceNav({ tree }: SideNavStateModel): boolean {
    return tree.showSidebarWorkspaceNav;
  }

  @Selector()
  static resetSidebarTree({ tree }: SideNavStateModel): boolean {
    return tree.resetSidebarTree;
  }

  @Selector()
  static removeSidebarViewStackAfterId({ tree }: SideNavStateModel): string {
    return tree.removableItemUidFromStack;
  }

  @Selector()
  static getUpdatedItemData({ tree }: SideNavStateModel): SidenavTreeSettingUpdatedItem {
    return {
      uid: tree.updatedItem.uid,
      options: {
        title: tree.updatedItem.options.title,
        uri: tree.updatedItem.options.uri,
        oldUri: tree.updatedItem.options.oldUri,
        published: tree.updatedItem.options.published,
      },
    };
  }

  @Selector()
  static onSearchItemInTree({ tree }: SideNavStateModel): string {
    return tree.searchableItemUid;
  }

  @Selector()
  static getItemIdForUpdatingChildren({ tree }: SideNavStateModel): { uid: string; updateParent: boolean } {
    return { uid: tree.updatedTreeItem.uid, updateParent: tree.updatedTreeItem.updateParent };
  }

  @Action(SideNavActions.ShowHideSidebarTree)
  showHideSidebarTree({ getState, patchState }: StateContext<SideNavStateModel>, { show }: SideNavActions.ShowHideSidebarTree) {
    const state = getState();
    const updatedSettings: SidenavTreeSettings = {
      ...state.tree,
      showSidebarTree: show,
    };

    patchState({
      tree: updatedSettings,
    });
  }

  @Action(SideNavActions.ShowHideSidebarWorkspaceNav)
  showHideSidebarWorkspaceNav(
    { getState, patchState }: StateContext<SideNavStateModel>,
    { show }: SideNavActions.ShowHideSidebarWorkspaceNav,
  ) {
    const state = getState();
    const updatedSettings: SidenavTreeSettings = {
      ...state.tree,
      showSidebarWorkspaceNav: show,
    };

    patchState({
      tree: updatedSettings,
    });
  }

  @Action(SideNavActions.ResetSidebarTree)
  resetSidebarTree({ getState, patchState }: StateContext<SideNavStateModel>, { reset }: SideNavActions.ResetSidebarTree) {
    const state = getState();
    const updatedSettings: SidenavTreeSettings = {
      ...state.tree,
      resetSidebarTree: reset,
    };

    patchState({
      tree: updatedSettings,
    });
  }

  @Action(SideNavActions.UpdateSidebarItemData)
  updateSidebarItemData(
    { getState, patchState }: StateContext<SideNavStateModel>,
    { uid, updatedOptions }: SideNavActions.UpdateSidebarItemData,
  ) {
    const state = getState();
    const updatedSettings: SidenavTreeSettings = {
      ...state.tree,
      updatedItem: {
        uid: uid,
        options: {
          title: updatedOptions.title,
          uri: updatedOptions.uri,
          oldUri: updatedOptions.oldUri,
          published: updatedOptions.published,
        },
      },
    };
    patchState({
      tree: updatedSettings,
    });
  }

  @Action(SideNavActions.ResetSidebarItemUpdateData)
  resetSidebarItemUpdateData({ getState, patchState }: StateContext<SideNavStateModel>) {
    const state = getState();
    const updatedSettings: SidenavTreeSettings = {
      ...state.tree,
      updatedItem: undefined,
    };

    patchState({
      tree: updatedSettings,
    });
  }

  @Action(SideNavActions.FindItemInSidebarTree)
  findItemInSidebarTree({ getState, patchState }: StateContext<SideNavStateModel>, { uid }: SideNavActions.FindItemInSidebarTree) {
    const state = getState();
    const updatedSettings: SidenavTreeSettings = {
      ...state.tree,
      searchableItemUid: uid,
    };

    patchState({
      tree: updatedSettings,
    });
  }

  @Action(SideNavActions.ResetFindItemInSidebarTree)
  resetFindItemInSidebarTree({ getState, patchState }: StateContext<SideNavStateModel>) {
    const state = getState();
    const updatedSettings: SidenavTreeSettings = {
      ...state.tree,
      searchableItemUid: undefined,
    };

    patchState({
      tree: updatedSettings,
    });
  }

  @Action(SideNavActions.UpdateSidebarItemChildren)
  updateSidebarItemChildren(
    { getState, patchState }: StateContext<SideNavStateModel>,
    { uid, updateParent }: SideNavActions.UpdateSidebarItemChildren,
  ) {
    const state = getState();
    const updatedSettings: SidenavTreeSettings = {
      ...state.tree,
      updatedTreeItem: {
        uid: uid,
        updateParent: updateParent,
      },
    };

    patchState({
      tree: updatedSettings,
    });
  }

  @Action(SideNavActions.ResetSidebarItemChildrenUpdate)
  ResetSidebarItemChildrenUpdate({ getState, patchState }: StateContext<SideNavStateModel>) {
    const state = getState();
    const updatedSettings: SidenavTreeSettings = {
      ...state.tree,
      updatedTreeItem: undefined,
    };

    patchState({
      tree: updatedSettings,
    });
  }

  @Action(SideNavActions.RemoveSidebarViewStackAfterId)
  removeSidebarViewStackAfterId(
    { getState, patchState }: StateContext<SideNavStateModel>,
    { uid }: SideNavActions.RemoveSidebarViewStackAfterId,
  ) {
    const state = getState();
    const updatedSettings: SidenavTreeSettings = {
      ...state.tree,
      removableItemUidFromStack: uid,
    };

    patchState({
      tree: updatedSettings,
    });
  }

  @Action(SideNavActions.ResetRemoveSidebarViewStackAfterId)
  resetSidebarRemoveViewStackAfterId({ getState, patchState }: StateContext<SideNavStateModel>) {
    const state = getState();
    const updatedSettings: SidenavTreeSettings = {
      ...state.tree,
      removableItemUidFromStack: undefined,
    };

    patchState({
      tree: updatedSettings,
    });
  }

  @Action(SideNavActions.EnableDiscoveryPage)
  enableDiscoveryPage({ getState, patchState }: StateContext<SideNavStateModel>) {
    const state = getState();
    const updatedSettings: SidenavSettings = {
      ...state.settings,
      isDiscoveryPage: true,
    };

    patchState({
      settings: updatedSettings,
    });
  }

  @Action(SideNavActions.DisableDiscoveryPage)
  disableDiscoveryPage({ getState, patchState }: StateContext<SideNavStateModel>) {
    const state = getState();
    const updatedSettings: SidenavSettings = {
      ...state.settings,
      isDiscoveryPage: false,
    };

    patchState({
      settings: updatedSettings,
    });
  }

  @Action(SideNavActions.EnableContentStorePage)
  enableContentStorePage({ getState, patchState }: StateContext<SideNavStateModel>) {
    const state = getState();
    const updatedSettings: SidenavSettings = {
      ...state.settings,
      isContentStorePage: true,
    };

    patchState({
      settings: updatedSettings,
    });
  }

  @Action(SideNavActions.DisableContentStorePage)
  disableContentStorePage({ getState, patchState }: StateContext<SideNavStateModel>) {
    const state = getState();
    const updatedSettings: SidenavSettings = {
      ...state.settings,
      isContentStorePage: false,
    };

    patchState({
      settings: updatedSettings,
    });
  }

  @Action(SideNavActions.EnableFolioPage)
  enableFolioPage({ getState, patchState }: StateContext<SideNavStateModel>) {
    const state = getState();
    const updatedSettings: SidenavSettings = {
      ...state.settings,
      isFolioPage: true,
    };

    patchState({
      settings: updatedSettings,
    });
  }

  @Action(SideNavActions.DisableFolioPage)
  disableFolioPage({ getState, patchState }: StateContext<SideNavStateModel>) {
    const state = getState();
    const updatedSettings: SidenavSettings = {
      ...state.settings,
      isFolioPage: false,
    };

    patchState({
      settings: updatedSettings,
    });
  }
}
