<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<main
  class="main"
  [ngStyle]="{
    'background-color': (organizationData$ | async)?.landingPage.useBackgroundColourFill
      ? (organizationData$ | async)?.landingPage.backgroundColour
      : '',
  }"
  [class.dynamic-background-color]="(organizationData$ | async)?.landingPage.useBackgroundColourFill"
>
  <mat-card class="main__account-verification">
    <h3>{{ 'translations.public.title.verifyingYourAccount' | transloco }}</h3>

    <div class="account-verification__spinner">
      <mat-spinner></mat-spinner>
    </div>
  </mat-card>
</main>
