/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { AndRequest, BooleanQueryType } from '@app/app/shared/models/admin/boolean-filters.model';
import { CardEventRegistrations } from '@app/app/shared/models/analytics/analytics.model';

export function createEmptyQuery(): AndRequest {
  return {
    type: BooleanQueryType.AND,
    args: [],
  } as AndRequest;
}

/**
 * Use only when data is already fully loaded on the frontend; otherwise, handle CSV export on the backend.
 */
export function exportToCsv(headers: string[], rows: (string | number)[][], fileName: string): void {
  const wrapInQuotes = (field: string | number): string => {
    const fieldStr = String(field);
    return `"${fieldStr.replace(/"/g, '""')}"`;
  };

  const csvContent = [headers, ...rows].map((row) => row.map(wrapInQuotes).join(',')).join('\n');

  const csvWithBOM = '\uFEFF' + csvContent;
  const blob = new Blob([csvWithBOM], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.click();
}

export function countEventCardRegistrations(cardEventRegistrations: CardEventRegistrations[]): number {
  return cardEventRegistrations.filter((registration) => registration.userEventSummary && registration.userEventSummary.registeredOn)
    .length;
}
