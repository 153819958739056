/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { EditorHelper } from '../../../../../../shared/helpers/editor.helper';
import { ResourceDataService } from '../../../../services/editor/core/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngxs/store';
import { SnackbarHelper } from '../../../../../../shared/helpers/snackbar-helper';
import { NgZone } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ResourceAdminStateModel } from '../../../admin/resource-admin.state.model';
import * as ResourceAdminActions from '../../../admin/resource-admin.actions';
import { Resource } from '../../../../../../shared/models';
import { marker } from '@jsverse/transloco-keys-manager/marker';

export const ResourceAdminExistingSectionActionHelpers = {
  addDynamicContentToExistingSection: (
    state: ResourceAdminStateModel,
    patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
    { sectionUid, content, languageCode }: ResourceAdminActions.AddDynamicContentToExistingSection,
    store: Store,
  ) => {
    const resourceState = {
      ...state.resource,
      content: {
        ...state.resource.content,
        sections: state.resource.content.sections.map((section) =>
          section.uid === sectionUid
            ? {
                ...section,
                dynamicContent: EditorHelper.addDynamicContentWithBreakpointCheck(section.dynamicContent, content),
              }
            : section,
        ),
      },
    };

    ResourceAdminExistingSectionActionHelpers.updateState(patchState, resourceState);

    store.dispatch(new ResourceAdminActions.SaveSection(sectionUid, resourceState, languageCode));
  },

  updateDynamicContentOfExistingSection: (
    state: ResourceAdminStateModel,
    patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
    { contentIndex, sectionUid, updatedContent, oldContent, languageCode }: ResourceAdminActions.UpdateDynamicContentOfExistingSection,
    store: Store,
  ) => {
    const resourceState = {
      ...state.resource,
      content: {
        ...state.resource.content,
        sections: state.resource.content.sections.map((section) =>
          section.uid === sectionUid
            ? {
                ...section,
                dynamicContent: section.dynamicContent.map((content, idx) => (idx === contentIndex ? updatedContent : content)),
              }
            : section,
        ),
      },
    };

    ResourceAdminExistingSectionActionHelpers.updateState(patchState, resourceState);

    if (updatedContent.type === 'PARAGRAPH' || updatedContent.type === 'TABLE') {
      store.dispatch(new ResourceAdminActions.InitAutoSaveSections(sectionUid, resourceState, languageCode));
    } else {
      store.dispatch(new ResourceAdminActions.SaveSection(sectionUid, resourceState, languageCode));
    }
  },

  removeDynamicContentFromExistingSection: (
    state: ResourceAdminStateModel,
    patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
    action: ResourceAdminActions.RemoveDynamicContentFromExistingSection,
    store: Store,
  ) => {
    const { sectionUid, languageCode, dynamicContentIndex, dynamicContentUid } = action;

    const existingSectionsWithContentRemovedFromTargetSection = state.resource.content.sections.map((section) =>
      section.uid === sectionUid
        ? {
            ...section,
            dynamicContent: EditorHelper.removeDynamicContentFromExistingSectionAndJoinParagraph(
              dynamicContentUid,
              dynamicContentIndex,
              section.dynamicContent,
            ),
          }
        : section,
    );

    const resourceState = {
      ...state.resource,
      content: {
        ...state.resource.content,
        sections: existingSectionsWithContentRemovedFromTargetSection,
      },
    };

    ResourceAdminExistingSectionActionHelpers.updateState(patchState, resourceState);

    store.dispatch(new ResourceAdminActions.SaveSection(sectionUid, resourceState, languageCode));
  },

  removeAllContentFromExistingSection: (
    state: ResourceAdminStateModel,
    patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
    { sectionUid, languageCode }: ResourceAdminActions.RemoveAllContentFromExistingSection,
    store: Store,
  ) => {
    const resourceState = {
      ...state.resource,
      content: {
        ...state.resource.content,
        sections: state.resource.content.sections.map((existingSection) =>
          existingSection.uid === sectionUid ? { ...existingSection, dynamicContent: [] } : existingSection,
        ),
      },
    };

    ResourceAdminExistingSectionActionHelpers.updateState(patchState, resourceState);

    store.dispatch(new ResourceAdminActions.SaveSection(sectionUid, resourceState, languageCode));
  },

  deleteExistingSection: (
    state: ResourceAdminStateModel,
    patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
    { sectionUid }: ResourceAdminActions.DeleteExistingSection,
    resourceDataService: ResourceDataService,
    snackBar: MatSnackBar,
    ngZone: NgZone,
    translocoService: TranslocoService,
  ) => {
    const resourceId = state.resource._id;

    return resourceDataService.deleteSection(resourceId, sectionUid).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translocoService, marker('translations.pageDeleted'));
        patchState({
          resource: {
            ...state.resource,
            content: {
              ...state.resource.content,
              sections: state.resource.content.sections.filter(({ uid }) => uid !== sectionUid),
            },
          },
          contentChanged: true,
        });
      } else {
        SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translocoService, 'translations.pageDeletedError');
      }
    });
  },

  createNewEmptySection: (
    state: ResourceAdminStateModel,
    patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
    resourceDataService: ResourceDataService,
    snackBar: MatSnackBar,
    ngZone: NgZone,
    translocoService: TranslocoService,
  ) => {
    const resourceId = state.resource._id;

    return resourceDataService.createSection(resourceId).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translocoService, marker('translations.cardPageAdded'));
        patchState({
          resource: {
            ...state.resource,
            content: {
              ...state.resource.content,
              sections: state.resource.content.sections.concat(value),
            },
          },
          contentChanged: true,
        });
      } else {
        SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translocoService, marker('translations.pageDeletedError'));
      }
    });
  },

  updateState: (patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>, resourceState: Resource) => {
    patchState({
      resource: resourceState,
      contentChanged: true,
    });
  },
};
