/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  LearnerPlaylistReport,
  LearnerPlaylistReportCheckboxForm,
  LearnerPlaylistReportCollectorForm,
  LearnerPlaylistReportForm,
  LearnerPlaylistReportItem,
  LearnerPlaylistReportQuizForm,
  LearnerPlaylistReportTextForm,
  LearnerPlaylistReportUploadForm,
  ReportFormNoteDetail,
  ReportFormType,
} from '../../../../../../shared/models/playlist/playlist-report.model';
import { Quiz } from '../../../../../../shared/models/editor/quiz-content.model';
import { QuizReportResponse } from '../../../../../resource/models';
import { EditorContent, GeneratedReport, GeneratedReportConditionalSection, GeneratedReportSection } from '../../../../../../shared/models';
import { ImageUrlMapping, PrintPageHelper } from '../../../../../../shared/helpers/print-page/print-page.helper';

@Component({
  selector: 'ptl-playlist-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class PlaylistReportComponent implements OnInit, AfterViewInit {
  @Input() report: LearnerPlaylistReport;
  @Output() pdfDownloaded = new EventEmitter<void>();
  @Output() pdfError = new EventEmitter<void>();

  @ViewChild('reportView') reportView: ElementRef<HTMLElement>;

  currentTime: Date;
  submittedOn: string;
  playlistTitle = 'playlist';
  playlistUri = 'playlist';
  imageUrlMapping: ImageUrlMapping = {};

  private readonly QUIZ_RESPONSE_MIN_SIZE = 30;
  private readonly QUIZ_RESPONSE_OPTION_MIN_SIZE = 5;
  private readonly MOMENT_SIZE = 20;
  private readonly ACTIVITY_MIN_SIZE = 10;
  private readonly SUBMISSION_HEAD_SIZE = 15;
  private readonly ROW_MIN_SIZE = 7;
  private readonly CONDITIONAL_SECTION_MIN_SIZE = 20;

  private fetchPromises: Promise<Response>[] = [];

  pages: GeneratedPage[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.report?.reportItems?.length) {
      this.playlistTitle = this.report?.reportItems[0].title;
      this.playlistUri = this.report?.reportItems[0].uri;
      this.submittedOn = this.report?.reportItems[0].submittedOn;
      this.setPages(this.report?.reportItems);
    }
  }

  ngAfterViewInit(): void {
    PrintPageHelper.fetchImageDataUrls(this.fetchPromises).then((mapping) => {
      this.imageUrlMapping = mapping;
      this.currentTime = new Date();
      this.downloadPDF();
    });
  }

  downloadPDF(): void {
    this.currentTime = new Date();
    this.cd.detectChanges();

    setTimeout(() => {
      const pages = this.reportView.nativeElement.querySelectorAll('.print-page');
      PrintPageHelper.addPages(pages)
        .then((doc) => {
          doc.save(`${this.playlistUri}.pdf`);
          this.pdfDownloaded.emit();
        })
        .catch(() => {
          this.pdfError.emit();
        });
    }, 2000);
  }

  private getQuizResponseSize(response: QuizReportResponse): number {
    let size = 0;

    if (response.options?.length) {
      size += this.QUIZ_RESPONSE_OPTION_MIN_SIZE * response.options.length;
    }

    if (response.instructions?.length) {
      size += PrintPageHelper.getHtmlContentSize(response.instructions[0]?.value);
    }

    if (response.feedback?.length) {
      response.feedback.forEach((item) => (size += PrintPageHelper.getHtmlContentSize(item.content)));
    }

    return Math.max(this.QUIZ_RESPONSE_MIN_SIZE, size);
  }

  private getConditionalSectionSize(section: GeneratedReportConditionalSection): number {
    let size = 0;

    if (section.learningGoalWrapper?.dynamicContent?.length) {
      size = PrintPageHelper.getHtmlContentSize(section.learningGoalWrapper.dynamicContent[0].content);
    }

    if (section.learningGoalWrapper?.competencyLevels[0]?.dynamicContent?.length) {
      size += PrintPageHelper.getHtmlContentSize(section.learningGoalWrapper.competencyLevels[0].dynamicContent[0].content);
    }

    return Math.max(size, this.CONDITIONAL_SECTION_MIN_SIZE);
  }

  private getPageUsedSize(page: GeneratedPage): number {
    let size = 0;

    for (const submission of page.activities) {
      size += this.ACTIVITY_MIN_SIZE;

      if (submission.playlist) {
        size += this.SUBMISSION_HEAD_SIZE;
      }

      if (submission.review) {
        size += this.ROW_MIN_SIZE;
      }

      if (submission.comments?.length) {
        size += this.ROW_MIN_SIZE * submission.comments.length;
      }

      if (submission.submissions.length) {
        if (submission.type === 'TEXT_FORM') {
          size += PrintPageHelper.getHtmlContentSize(submission.submissions[0].value as string);
        }

        if (submission.type === 'FILE_UPLOAD_FORM' || submission.type === 'CHECKBOX_FORM') {
          size += this.ROW_MIN_SIZE;
        }

        if (submission.type === 'QUIZ_FORM') {
          const report = submission.submissions[0].value as { responses: QuizReportResponse[]; quiz: Quiz };
          report?.responses?.forEach((response) => (size += this.getQuizResponseSize(response)));
        }

        if (submission.type === 'COLLECTOR_FORM') {
          for (const log of submission.submissions) {
            const summary = log.value as {
              moments: { imageUrl: string; title: string }[];
              answers: string[];
            };

            const momentsCount = summary.moments?.length;
            summary.answers?.forEach((answer) => size + PrintPageHelper.getHtmlContentSize(answer));
            size += this.MOMENT_SIZE * (momentsCount ? momentsCount : 0);
          }
        }

        if (submission.type === 'ASSESSMENT') {
          const report = submission.submissions[0].value as GeneratedReport;

          if (report.sections) {
            for (const section of report.sections as GeneratedReportConditionalSection[]) {
              size += this.getConditionalSectionSize(section);
            }
          }
        }
      }
    }

    return size;
  }

  private addNewPageWithActivity(activityType: ReportFormType | 'ASSESSMENT'): GeneratedPage {
    const lastSubmission = {
      type: activityType,
      submissions: [],
    };

    const lastPage = {
      activities: [lastSubmission],
    };

    this.pages.push(lastPage);

    return lastPage;
  }

  private formHasData(form: LearnerPlaylistReportForm): boolean {
    // if (!form.submittedOn) {
    //   return false;
    // }
    let checked: boolean;
    let quizForm: LearnerPlaylistReportQuizForm;
    switch (form?.type) {
      case 'TEXT_FORM':
        return !!(form as LearnerPlaylistReportTextForm).value;
      case 'CHECKBOX_FORM':
        checked = (form as LearnerPlaylistReportCheckboxForm).checked;
        return checked === true || checked === false;
      case 'FILE_UPLOAD_FORM':
        return !!(form as LearnerPlaylistReportUploadForm).files;
      case 'QUIZ_FORM':
        quizForm = form;
        return !!quizForm.report?.first && !!quizForm.report?.second?.responses?.length;
      case 'COLLECTOR_FORM':
        return !!(form as LearnerPlaylistReportCollectorForm).summary?.loggedItems?.find(
          (item) => item?.answers?.length || item?.moments?.length,
        );
      default:
        return false;
    }
  }

  private addForms(forms: LearnerPlaylistReportForm[], playlistName: string, cardTitle: string): void {
    for (const form of forms) {
      let lastPage = this.pages[this.pages.length - 1];
      let lastPageSize = this.getPageUsedSize(lastPage);

      if (lastPageSize > 85) {
        lastPage = {
          activities: [],
        };

        this.pages.push(lastPage);
        lastPageSize = this.ACTIVITY_MIN_SIZE;
      }

      let lastActivity: PageActivity = {
        playlist: playlistName,
        card: cardTitle,
        type: form.type,
        submissions: [],
      };

      lastPage.activities.push(lastActivity);

      const comments = form.noteDetails?.map((item, i, arr) => {
        return {
          note: item,
          index: arr.length > 1 ? i + 1 : undefined,
        };
      });

      const review = form.requiresResubmit ? 'RESUBMIT' : form.acceptedOn ? 'APPROVED' : 'EMPTY';
      const reviewDate = form.acceptedOn;

      if (this.formHasData(form)) {
        this.addForm(form, lastPage, lastActivity, lastPageSize);
      } else {
        lastActivity.empty = true;
      }

      lastPage = this.pages[this.pages.length - 1];
      lastActivity = lastPage.activities[lastPage.activities.length - 1];

      lastActivity.comments = comments;
      lastActivity.review = review;
      lastActivity.reviewDate = reviewDate;
    }
  }

  private addForm(form: LearnerPlaylistReportForm, lastPage: GeneratedPage, lastActivity: PageActivity, lastPageSize: number): void {
    switch (form.type) {
      case 'TEXT_FORM':
        this.addTextForm(form as LearnerPlaylistReportTextForm, lastPage, lastActivity, lastPageSize);
        break;
      case 'CHECKBOX_FORM':
        this.addCheckboxForm(form as LearnerPlaylistReportCheckboxForm, lastPage, lastActivity, lastPageSize);
        break;
      case 'FILE_UPLOAD_FORM':
        this.addUploadForm(form as LearnerPlaylistReportUploadForm, lastPage, lastActivity, lastPageSize);
        break;
      case 'QUIZ_FORM':
        this.addQuizForm(form as LearnerPlaylistReportQuizForm, lastPage, lastActivity, lastPageSize);
        break;
      case 'COLLECTOR_FORM':
        this.addCollectorForm(form as LearnerPlaylistReportCollectorForm, lastPage, lastActivity, lastPageSize);
        break;
    }
  }

  private addTextForm(
    textForm: LearnerPlaylistReportTextForm,
    lastPage: GeneratedPage,
    lastActivity: PageActivity,
    lastPageSize: number,
  ): void {
    let textContents = [textForm.value];

    if (PrintPageHelper.getHtmlContentSize(textForm.value) + lastPageSize > 100) {
      textContents = PrintPageHelper.splitHtmlInToFragments(textForm.value, 100 - lastPageSize);
    }

    let trimmedFromStart = false;

    for (const textContent of textContents) {
      if (trimmedFromStart) {
        lastPage = this.addNewPageWithActivity(textForm.type);
        lastActivity = lastPage.activities[0];
        lastPageSize = this.ACTIVITY_MIN_SIZE;
      }

      lastActivity.submissions = [
        {
          trimmedFromStart,
          submittedOn: textForm.submittedOn,
          value: textContent,
          name: textForm.title,
        },
      ];

      trimmedFromStart = true;
      lastPageSize += PrintPageHelper.getHtmlContentSize(textContent);
    }
  }

  private addCheckboxForm(
    checkboxForm: LearnerPlaylistReportCheckboxForm,
    lastPage: GeneratedPage,
    lastActivity: PageActivity,
    lastPageSize: number,
  ): void {
    lastActivity.submissions.push({
      submittedOn: checkboxForm.submittedOn,
      value: checkboxForm.checked,
      name: checkboxForm.title,
    });
  }

  private addUploadForm(
    uploadForm: LearnerPlaylistReportUploadForm,
    lastPage: GeneratedPage,
    lastActivity: PageActivity,
    lastPageSize: number,
  ): void {
    lastActivity.submissions.push({
      value: undefined,
      submittedOn: uploadForm.submittedOn,
      files: uploadForm.files,
      name: uploadForm.title,
    });
  }

  private addQuizForm(
    quizForm: LearnerPlaylistReportQuizForm,
    lastPage: GeneratedPage,
    lastActivity: PageActivity,
    lastPageSize: number,
  ): void {
    let lastReport: { responses: QuizReportResponse[]; quiz: Quiz } = {
      responses: [],
      quiz: quizForm.report.first,
    };

    let trimmedFromStart = false;

    for (const response of quizForm.report.second.responses) {
      const responseSize = this.getQuizResponseSize(response);

      if (responseSize + lastPageSize > 100) {
        lastPage = this.addNewPageWithActivity(quizForm.type);
        lastActivity = lastPage.activities[0];
        lastPageSize = this.ACTIVITY_MIN_SIZE;

        if (lastReport.responses.length) {
          trimmedFromStart = true;
        }

        lastReport = {
          responses: [],
          quiz: quizForm.report.first,
        };
      }

      lastReport.responses.push(response);
      lastActivity.submissions = [
        {
          trimmedFromStart,
          submittedOn: quizForm.submittedOn,
          value: lastReport,
          name: quizForm.title,
        },
      ];

      lastPageSize += responseSize;
    }
  }

  private addCollectorForm(
    collectorForm: LearnerPlaylistReportCollectorForm,
    lastPage: GeneratedPage,
    lastActivity: PageActivity,
    lastPageSize: number,
  ): void {
    let submissionsNumber = 1;

    for (const loggedItem of collectorForm.summary.loggedItems) {
      let submissionsIncrement = 0;
      let lastLog: {
        moments: { imageUrl: string; title: string }[];
        answers: string[];
      } = {
        moments: [],
        answers: [],
      };

      let trimmedFromStart = false;

      if (loggedItem.moments) {
        for (const moment of loggedItem.moments) {
          if (!this.imageUrlMapping.hasOwnProperty(moment.imageUrl)) {
            this.fetchPromises.push(fetch(moment.imageUrl));
            this.imageUrlMapping[moment.imageUrl] = undefined;
          }

          if (this.MOMENT_SIZE + lastPageSize > 100) {
            if (lastLog.moments.length) {
              submissionsIncrement = 1;
              lastActivity.submissions.push({
                submittedOn: loggedItem.loggedOn as unknown as string,
                name: loggedItem.title,
                index: submissionsNumber,
                value: lastLog,
                trimmedFromStart: trimmedFromStart,
              });

              trimmedFromStart = true;
            }

            lastPage = this.addNewPageWithActivity(collectorForm.type);
            lastActivity = lastPage.activities[0];
            lastPageSize = this.ACTIVITY_MIN_SIZE;
            lastLog = {
              moments: [],
              answers: [],
            };
          }

          lastLog.moments.push(moment);
          lastPageSize += this.MOMENT_SIZE;
        }
      }

      const answers = loggedItem.answers?.filter((answer) => !!answer.answer);

      if (answers) {
        for (const answer of answers) {
          if (PrintPageHelper.getHtmlContentSize(answer.answer) + lastPageSize > 100) {
            if (lastLog.answers.length) {
              submissionsIncrement = 1;
              lastActivity.submissions.push({
                submittedOn: loggedItem.loggedOn as unknown as string,
                name: loggedItem.title,
                index: submissionsNumber,
                value: lastLog,
                trimmedFromStart: trimmedFromStart,
              });

              trimmedFromStart = true;
            }

            lastPage = this.addNewPageWithActivity(collectorForm.type);
            lastActivity = lastPage.activities[0];
            lastPageSize = this.ACTIVITY_MIN_SIZE;
            lastLog = {
              moments: [],
              answers: [],
            };
          }

          lastLog.answers.push(answer.answer);
          lastPageSize += PrintPageHelper.getHtmlContentSize(answer.answer);
        }
      }

      if (lastLog.moments.length || lastLog.answers.length) {
        submissionsIncrement = 1;
        lastActivity.submissions.push({
          submittedOn: loggedItem.loggedOn as unknown as string,
          name: loggedItem.title,
          index: submissionsNumber,
          value: lastLog,
          trimmedFromStart: trimmedFromStart,
        });
      }

      submissionsNumber += submissionsIncrement;
    }
  }

  private extractAndJoinTextParagraphs(content: EditorContent[], maxSize: number): EditorContent[] {
    const result: EditorContent[] = [];
    const filteredTextContent = content?.filter((item) => item.type === 'PARAGRAPH');

    if (filteredTextContent) {
      let html = '';

      for (const paragraph of filteredTextContent) {
        html += paragraph.content;
      }

      if (html.length) {
        if (PrintPageHelper.getHtmlContentSize(html) > maxSize) {
          html = PrintPageHelper.splitHtmlInToFragments(html, maxSize)[0] + '<p>...</p>';
        }

        result.push({
          type: 'PARAGRAPH',
          content: html,
        });
      }
    }
    return result;
  }

  private addAssessmentReport(report: GeneratedReport, playlistName: string, cardTitle: string, submittedOn: string): void {
    if (report.sections) {
      const conditionalSections = report.sections
        .filter((section: GeneratedReportSection): section is GeneratedReportConditionalSection => section.type === 'CONDITIONAL_SECTION')
        .filter((section: GeneratedReportConditionalSection) => !!section.learningGoalWrapper?.competencyLevels)
        .map((section: GeneratedReportConditionalSection) => {
          const sectionContent = this.extractAndJoinTextParagraphs(section.learningGoalWrapper.dynamicContent, 30);
          const competencyContent = this.extractAndJoinTextParagraphs(section.learningGoalWrapper.competencyLevels[0].dynamicContent, 60);

          return {
            ...section,
            learningGoalWrapper: {
              ...section.learningGoalWrapper,
              dynamicContent: sectionContent,
              competencyLevels: section.learningGoalWrapper?.competencyLevels.map((level) => ({
                ...level,
                dynamicContent: competencyContent,
              })),
            },
          };
        });

      if (conditionalSections.length) {
        let lastPage = this.pages[this.pages.length - 1];
        let lastPageSize = this.getPageUsedSize(lastPage);

        if (lastPageSize > 85) {
          lastPage = {
            activities: [],
          };

          this.pages.push(lastPage);
          lastPageSize = this.ACTIVITY_MIN_SIZE;
        }

        let lastActivity: PageActivity = {
          playlist: playlistName,
          card: cardTitle,
          type: 'ASSESSMENT',
          submissions: [],
        };

        lastPage.activities.push(lastActivity);

        let trimmedFromStart = false;

        let lastReport: GeneratedReport = {
          ...report,
          sections: [],
        };

        for (const section of conditionalSections) {
          if (this.getConditionalSectionSize(section) + lastPageSize > 100) {
            lastPage = this.addNewPageWithActivity('ASSESSMENT');
            lastActivity = lastPage.activities[0];
            lastPageSize = this.ACTIVITY_MIN_SIZE;

            if (lastReport.sections.length) {
              trimmedFromStart = true;
            }

            lastReport = {
              ...report,
              sections: [],
            };
          }

          lastReport.sections.push(section);
          lastActivity.submissions = [
            {
              trimmedFromStart,
              submittedOn,
              value: lastReport,
              name: cardTitle,
            },
          ];

          lastPageSize += this.getConditionalSectionSize(section);
        }

        lastActivity.review = 'EMPTY';
      }
    }
  }

  private setPages(reportItems: LearnerPlaylistReportItem[], playlistName?: string): void {
    if (this.pages.length === 0) {
      this.pages.push({
        activities: [],
      });
    }

    for (const reportItem of reportItems) {
      if (reportItem.type !== 'PLAYLIST') {
        if (reportItem.forms) {
          this.addForms(reportItem.forms, playlistName, reportItem.title);
        }

        if (reportItem.report) {
          this.addAssessmentReport(reportItem.report, playlistName, reportItem.title, reportItem.completedOn);
        }
      } else if (reportItem.cards) {
        if (reportItem.type === 'PLAYLIST') {
          playlistName = reportItem.title;
        }

        this.setPages(reportItem.cards, playlistName);
      }
    }
  }
}

interface GeneratedPage {
  activities: PageActivity[];
}

interface PageActivity {
  empty?: boolean;
  type: ReportFormType | 'ASSESSMENT';
  playlist?: string;
  card?: string;
  activityName?: string;
  submissions: {
    submittedOn: string;
    index?: number;
    files?: string[];
    value:
      | string
      | boolean
      | GeneratedReport
      | { responses: QuizReportResponse[]; quiz: Quiz }
      | {
          moments: { imageUrl: string; title: string }[];
          answers: string[];
        };
    trimmedFromStart?: boolean;
    name: string;
  }[];
  review?: 'APPROVED' | 'RESUBMIT' | 'EMPTY';
  reviewDate?: string;
  comments?: {
    note: ReportFormNoteDetail;
    index?: number;
  }[];
}
