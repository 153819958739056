/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploadBoxForm, FileUploadBoxFormContent } from '../../../../models';
import { FILE_UPLOAD_DEFAULT_SIZE, FILE_UPLOAD_SUPPORTED_TYPES } from '@app/app/shared/constants/constants';
import { Select } from '@ngxs/store';
import { ResourceAdminState } from '@app/app/page-modules/resource/store/admin/resource-admin.state';
import { Observable, takeUntil } from 'rxjs';

@Component({
  selector: 'ptl-form-file-upload',
  templateUrl: './form-file-upload.component.html',
  styleUrls: ['../form.component.scss'],
})
export class FormFileUploadComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  /** Receives the input data object */
  private _form: FileUploadBoxForm;
  private _canRemoveItem: boolean | undefined;
  private _position: number | string | undefined;

  @Input()
  set form(value: FileUploadBoxForm) {
    if (typeof value === 'string') {
      this._form = JSON.parse(decodeURIComponent(value));
    } else {
      this._form = value;
    }
  }

  get form() {
    return this._form;
  }

  /** Position in the Form sections */
  @Input()
  set position(value: number | string | undefined) {
    if (typeof value === 'string') {
      this._position = JSON.parse(decodeURIComponent(value));
    } else {
      this._position = value;
    }
  }

  get position() {
    return this._position;
  }

  @Input()
  set canRemoveItem(value) {
    if (typeof value === 'string') {
      this._canRemoveItem = JSON.parse(decodeURIComponent(value));
    } else {
      this._canRemoveItem = value;
    }
  }

  get canRemoveItem() {
    return this._canRemoveItem;
  }

  /** Emits content data on saveForm() */
  @Output() formElementAdded = new EventEmitter<FileUploadBoxForm>();

  /** Emits removing event of this form with index */
  @Output() formElementRemoved = new EventEmitter<void>();

  @ViewChild('formTitleInput') private formTitleInput: ElementRef<HTMLInputElement>;

  @Select(ResourceAdminState.contentStateChanged)
  contentStateChanged$: Observable<boolean>;

  fileUploadForm: FormGroup;
  expanded = true;
  hasError = false;
  focused = false;
  forceExpand = false;

  defaultUploadSize = FILE_UPLOAD_DEFAULT_SIZE;
  fileUploadSizes = [5, 10, 25];
  saveInProgress = false;

  private supportedFileTypes = FILE_UPLOAD_SUPPORTED_TYPES;
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(private fb: FormBuilder) {
    this.fileUploadForm = this.fb.group({
      title: ['', [Validators.required]],
      maxFileSizeMb: [this.defaultUploadSize, [Validators.required]],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form.content?.title || this.form.content?.maxFileSizeMb) {
      this.fileUploadForm.setValue({
        title: this.form.content.title || '',
        maxFileSizeMb: this.form.content.maxFileSizeMb || this.defaultUploadSize,
      });
    }
  }

  ngOnInit() {
    if (this.form && !!this.form.uid) {
      const content = this.form.content as FileUploadBoxFormContent;

      this.expanded = this.form.newAddedForm ?? false;
      this.fileUploadForm.setValue({
        title: content.title,
        maxFileSizeMb: content.maxFileSizeMb,
      });
    }

    this.contentStateChanged$.pipe(takeUntil(this.subscriptionEnd$)).subscribe(() => {
      this.saveInProgress = false;
    });
    this.forceExpand = this.form?.content?.forceExpand;
  }

  ngAfterViewInit(): void {
    const titleInput = this.formTitleInput?.nativeElement;
    if (this.forceExpand && titleInput) {
      setTimeout(() => {
        const titleLength = titleInput.value.length;
        titleInput.focus();
        titleInput.setSelectionRange(titleLength, titleLength);
      });
    }
  }

  ngOnDestroy() {
    this.subscriptionEnd$?.emit();
  }

  saveForm() {
    this.hasError = false;
    if (this.fileUploadForm.valid) {
      this.expanded = false;
      this.forceExpand = false;
      this.outputData();
      this.saveInProgress = true;
    } else {
      this.hasError = true;
    }
  }

  expandForm() {
    this.expanded = true;
  }

  collapseForm(event: PointerEvent) {
    event.stopPropagation();
    this.expanded = false;
    this.forceExpand = false;
  }

  private outputData() {
    const outputData: FileUploadBoxForm = {
      ...this.form,
      newAddedForm: false,
      content: {
        ...this.fileUploadForm.value,
        type: 'USER_FILE_UPLOAD',
        supportedFileTypes: this.supportedFileTypes,
      } as FileUploadBoxFormContent,
    };
    this.formElementAdded.emit(outputData);
  }

  removeForm() {
    this.formElementRemoved.emit();
  }

  getSupportedFileTypes() {
    return this.supportedFileTypes.join(', ').replace(/\./g, '');
  }
}
