/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Organization } from '../../../../shared/models';
import { TriggerUserLogin } from '../../../store/user-auth.actions';
import { UserAuthState } from '../../../store/user-auth.state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from '../../../../page-modules/account/services/account-data.service';
import { environment } from '../../../../../environments/environment';
import { EnvironmentCode } from '../../../../../environments/environment.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarHelper } from '../../../../shared/helpers/snackbar-helper';
import { TranslocoService } from '@ngneat/transloco';
import { RedirectHelper } from '../../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { getClientId } from '../../../../shared/helpers/development-domains.helper';

@Component({
  templateUrl: './classof-login.component.html',
  styleUrls: ['./classof-login.component.scss', '../classof-auth.component.scss'],
})
export class ClassOfLoginComponent implements OnInit, OnDestroy {
  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  @Select(UserAuthState.isLoginInProgress)
  loginInProgress$: Observable<boolean>;

  organization: Organization;
  loginFrom: FormGroup;
  forgotPasswordForm: FormGroup;
  showPasswordSelected: boolean;
  isForgotForm = false;
  forgotIsProgress: boolean;

  private organizationDataSubscription: Subscription;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private router: Router,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private translocoService: TranslocoService,
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
  ) {
    this.loginFrom = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });

    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  ngOnInit() {
    this.organizationDataSubscription = this.organizationData$.subscribe((data) => {
      this.organization = data;
    });
  }

  ngOnDestroy() {
    this.organizationDataSubscription?.unsubscribe();
  }

  onSaveClick() {
    if (this.loginFrom.valid) {
      this.store.dispatch(new TriggerUserLogin(this.loginFrom.get('email').value?.trim(), this.loginFrom.get('password').value?.trim()));
    }
  }

  onBackClicked() {
    this.isForgotForm = false;
  }

  signInUsingLinkedIn() {
    this.redirectToIdpViaRealm('linkedin');
  }

  signInUsingGoogle() {
    this.redirectToIdpViaRealm('google');
  }

  switchRegisterForm() {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/signup/classof2020');
  }

  switchForgotForm() {
    this.isForgotForm = true;
  }

  onForgotSubmit() {
    if (this.forgotPasswordForm.valid) {
      this.forgotIsProgress = true;
      const email = this.forgotPasswordForm.get('email').value;
      this.accountService.resetPasswordByEmail(email).subscribe(({ isSuccess, error }) => {
        this.forgotIsProgress = false;
        if (isSuccess) {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone,
            this.snackBar,
            this.translocoService,
            'translations.resetPasswordInstructionSend',
          );
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      });
    }
  }

  private redirectToIdpViaRealm(idp: string) {
    document.location.href =
      `${environment.realmUrl}protocol/openid-connect/auth?` +
      `client_id=${getClientId(this.organization.domain)}&` +
      'response_type=token&' +
      `nonce=${environment.nonce}&` +
      `kc_idp_hint=${idp}&` +
      `redirect_uri=${this.getRedirectUri()}`;
  }

  private getRedirectUri() {
    if ([EnvironmentCode.dev, EnvironmentCode.prod].includes(environment.env) && window.location.hostname.includes('localhost')) {
      return 'http://localhost:4200';
    }
    return `https://${this.organization.domain}`;
  }
}
