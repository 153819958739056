/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ExternalContentSummary, MediaContent } from '../../../../shared/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  EmbeddableContentDetails,
  EMBEDS_CONTENT_GENERATOR_SERVICE,
  EmbedsContentGeneratorService,
} from '../../../services/embeds-content-generator.service';
import { take } from 'rxjs/operators';
import { SpecialEmbedsHelper } from '../../../../shared/helpers/embeds/special-embeds.helper';
import { DEFAULT_URL_VALIDATION_PATTERN } from '../../../../shared/constants/constants';

@Component({
  selector: 'ptl-link-input',
  templateUrl: './link-input.component.html',
  styleUrls: ['./link-input.component.scss'],
})
export class LinkInputComponent implements OnInit {
  private _showLinkField = false;

  @Input()
  set showLinkField(value: boolean) {
    if (typeof value === 'string') {
      this._showLinkField = JSON.parse(decodeURIComponent(value));
    } else {
      this._showLinkField = value;
    }
  }

  get showLinkField() {
    return this._showLinkField;
  }

  /** Emits data onEnter key or file upload */
  @Output() mediaContentAdded = new EventEmitter<MediaContent>();
  @Output() regularLinkAdded = new EventEmitter<string>();

  inputActive = false;
  mediaUrlData?: FormGroup;
  mediaEmbedableContentLoading = false;

  constructor(
    private fb: FormBuilder,
    @Inject(EMBEDS_CONTENT_GENERATOR_SERVICE) public embedsService: EmbedsContentGeneratorService,
  ) {}

  ngOnInit() {
    this.mediaUrlData = this.fb.group({
      contentUrl: ['', [Validators.required, Validators.pattern(DEFAULT_URL_VALIDATION_PATTERN)]],
    });
    if (this.showLinkField) {
      this.inputActive = true;
    }
  }

  addLink() {
    this.inputActive = true;
  }

  onEnter(event: KeyboardEvent): void {
    event.stopPropagation();
    if (this.mediaUrlData?.valid) {
      this.mediaEmbedableContentLoading = true;
      const url = SpecialEmbedsHelper.correctUrl(`${this.mediaUrlData.get('contentUrl')?.value}`);

      this.embedsService
        .retrieveEmbeddableContentDetails(encodeURI(url))
        .pipe(take(1))
        .subscribe(({ value }) => {
          let contentDetails: EmbeddableContentDetails | undefined;

          if (value) {
            contentDetails = value;
          } else {
            contentDetails = SpecialEmbedsHelper.generateEmbedFromUrl(url);
          }

          if (contentDetails) {
            this.mediaContentAdded.emit({
              type: 'EXTERNAL_CONTENT_SUMMARY',
              url: contentDetails.url,
              authorName: contentDetails.authorName,
              logoUrl: contentDetails.logoUrl,
              title: contentDetails.title,
              description: contentDetails.description,
              publicationDate: contentDetails.publicationDate,
              thumbnailUrl: contentDetails.thumbnailUrl,
              siteName: contentDetails.siteName,
              html: contentDetails.html,
            } as ExternalContentSummary);
          } else {
            this.regularLinkAdded.emit(url);
          }
        });
    }
  }
}
