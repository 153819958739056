/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../shared/store';
import { InjectionToken } from '@angular/core';

export interface EmbeddableContentDetails {
  authorName: string;
  logoUrl: string | null;
  url: string | null;
  title: string;
  description: string | null;
  thumbnailUrl: string | null;
  tags: string[];
  publicationDate: Date;
  siteName: string;
  html: string;
}

/** Injection token for the embedable content generator service. */
export const EMBEDS_CONTENT_GENERATOR_SERVICE = new InjectionToken<EmbedsContentGeneratorService>('[Embeds]');

export interface EmbedsContentGeneratorService {
  /**
   * Retrieve embeddable content details.
   *
   * @param url the url to parse
   */
  retrieveEmbeddableContentDetails(url: string, isFromModalCardEditor?: boolean): ObservableResult<EmbeddableContentDetails>;
}
