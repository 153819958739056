<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="data-collection-dialog__content-container">
  <div class="data-collection-dialog__back-container" *ngIf="!isMainStep">
    <i
      tabindex="0"
      class="material-icons back-container__icon"
      [attr.aria-label]="'translations.global.ariaLabels.goBack' | transloco"
      (click)="isMainStep = isLocationStep = !isLocationStep"
      (keydown.enter)="isMainStep = isLocationStep = !isLocationStep"
    >
      keyboard_backspace
    </i>
  </div>

  <div class="data-collection-dialog__header">
    <div class="data-collection-dialog__header-buttons">
      <span
        class="nav-button"
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.setMainStep' | transloco"
        [class.active]="isMainStep"
        (click)="isMainStep = true"
        (keydown.enter)="isMainStep = true"
      >
      </span>
      <span
        class="nav-button"
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.goToNext' | transloco"
        [class.active]="!isMainStep && !isLocationStep"
        (click)="onNext()"
        (keydown.enter)="onNext()"
      ></span>
      <span
        *ngIf="affiliation !== 'INDEPENDENT'"
        class="nav-button"
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.goToNext' | transloco"
        [class.active]="!isMainStep && isLocationStep"
        (click)="onNext(true)"
        (keydown.enter)="onNext(true)"
      ></span>
    </div>
    <h3 class="data-collection-dialog__header-label">{{ 'translations.collection.title.completeRegistration' | transloco }}</h3>
  </div>

  <div class="data-collection-dialog__body">
    <div class="data-collection-dialog__main-step" *ngIf="isMainStep">
      <h1 class="data-collection-dialog__step-title">
        {{ 'translations.collection.title.exclusiveContentAndOpportunities' | transloco }}
      </h1>
      <p class="data-collection-dialog__main-step-description">
        {{ 'translations.collection.description.exclusiveContentDescription' | transloco }}
      </p>
      <img alt="Ice cream - doodle image" src="assets/ice-cream-doodle.svg" width="16" height="16" />
      <p class="data-collection-dialog__main-step-description">
        {{ 'translations.collection.description.affiliationDescription' | transloco }}
      </p>

      <div class="data-collection-dialog__affiliation-select">
        <mat-radio-group [(ngModel)]="affiliation">
          <mat-radio-button
            class="data-collection-dialog__main-mat-radio-btn"
            value="SCHOOL"
            name="affiliation"
            tabIndex="0"
            color="primary"
          >
            <span class="data-collection-dialog__main-mat-radio-btn-text">
              {{ 'translations.collection.option.iAmAtSchoolCollege' | transloco }}
            </span>
          </mat-radio-button>
          <mat-radio-button
            class="data-collection-dialog__main-mat-radio-btn"
            value="STUDENT"
            name="affiliation"
            tabIndex="0"
            color="primary"
          >
            <span class="data-collection-dialog__main-mat-radio-btn-text">
              {{ 'translations.collection.option.iAmStudyingAtUniversityCollege' | transloco }}
            </span>
          </mat-radio-button>
          <mat-radio-button
            class="data-collection-dialog__main-mat-radio-btn"
            value="GRADUATE"
            name="affiliation"
            tabIndex="0"
            color="primary"
          >
            <span class="data-collection-dialog__main-mat-radio-btn-text">
              {{ 'translations.collection.option.iHaveGraduatedUniversity' | transloco }}
            </span>
          </mat-radio-button>
          <mat-radio-button
            class="data-collection-dialog__main-mat-radio-btn"
            value="INDEPENDENT"
            name="affiliation"
            tabIndex="0"
            color="primary"
          >
            <span class="data-collection-dialog__main-mat-radio-btn-text">
              {{ 'translations.collection.option.iAmNotInSchoolEducationOrWork' | transloco }}
            </span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div *ngIf="!isMainStep && !!onBoardDataForm">
      <form class="login-form" [formGroup]="onBoardDataForm" (submit)="onSubmit()" novalidate>
        <div [style.display]="isLocationStep || affiliation === 'INDEPENDENT' ? 'none' : ''">
          <ng-container *ngIf="affiliation === 'SCHOOL'">
            <h1 class="data-collection-dialog__step-title">
              {{ 'translations.collection.title.connectToSchoolCollege' | transloco }}
            </h1>
            <mat-form-field class="form__field" appearance="outline">
              <mat-label #schoolName>{{ 'translations.collection.label.schoolCollegeName' | transloco }}</mat-label>
              <input
                matInput
                required
                type="text"
                name="schoolName"
                [attr.aria-label]="'translations.global.ariaLabels.enterSchoolName' | transloco"
                [placeholder]="schoolName.innerHTML"
                formControlName="schoolName"
              />
              <mat-error *ngIf="onBoardDataForm.controls.schoolName?.hasError('required')">
                {{ 'translations.collection.message.error.enterValidSchoolName' | transloco }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="form__field" appearance="outline">
              <mat-label #schoolCity>{{ 'translations.collection.label.city' | transloco }}</mat-label>
              <input
                matInput
                required
                type="text"
                [attr.aria-label]="'translations.global.ariaLabels.enterSchoolOrCollegeCity' | transloco"
                name="schoolCity"
                [placeholder]="schoolCity.innerHTML"
                formControlName="schoolCity"
              />
              <mat-error *ngIf="onBoardDataForm.controls.schoolCity?.hasError('required')">
                {{ 'translations.collection.message.error.enterValidSchoolOrCollegeCity' | transloco }}
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngIf="affiliation === 'STUDENT' || affiliation === 'GRADUATE'">
            <h1 *ngIf="affiliation === 'STUDENT'" class="data-collection-dialog__step-title">
              {{ 'translations.collection.title.iAmStudentAt' | transloco }}
            </h1>
            <h1 *ngIf="affiliation === 'GRADUATE'" class="data-collection-dialog__step-title">
              {{ 'translations.collection.title.iHaveGraduatedOf' | transloco }}
            </h1>
            <mat-form-field class="form__field" appearance="outline">
              <mat-label #institutionName>{{ 'translations.collection.label.institutionName' | transloco }}</mat-label>
              <input
                matInput
                required
                type="text"
                [attr.aria-label]="'translations.global.ariaLabels.enterInstruction' | transloco"
                name="institutionName"
                [placeholder]="institutionName.innerHTML"
                formControlName="institutionName"
              />
              <mat-error *ngIf="onBoardDataForm.controls.institutionName?.hasError('required')">
                {{ 'translations.collection.message.error.enterValidInstitution' | transloco }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="form__field" appearance="outline">
              <mat-label #institutionWebsite>{{ 'translations.collection.label.website' | transloco }}</mat-label>
              <input
                matInput
                type="text"
                [attr.aria-label]="'translations.global.ariaLabels.enterWebsite' | transloco"
                name="institutionWebsite"
                [placeholder]="institutionWebsite.innerHTML"
                formControlName="institutionWebsite"
              />
              <mat-error *ngIf="onBoardDataForm.controls.institutionWebsite?.hasError('pattern')">
                {{ 'translations.collection.message.error.enterValidWebsite' | transloco }}
              </mat-error>
            </mat-form-field>

            <ng-container *ngIf="affiliation === 'STUDENT'">
              <div class="form__field-group">
                <mat-form-field class="form__field" appearance="outline">
                  <mat-label #startYear>{{ 'translations.collection.label.yearYouStarted' | transloco }}</mat-label>
                  <input
                    min="1990"
                    max="2100"
                    matInput
                    required
                    type="number"
                    [attr.aria-label]="'translations.global.ariaLabels.enterStartYear' | transloco"
                    name="startYear"
                    [placeholder]="startYear.innerHTML"
                    formControlName="startYear"
                  />
                  <mat-error *ngIf="onBoardDataForm.controls.startYear?.hasError('required')">
                    {{ 'translations.collection.message.error.enterValidStartYear' | transloco }}
                  </mat-error>
                  <mat-error
                    *ngIf="onBoardDataForm.controls.startYear?.hasError('min') || onBoardDataForm.controls.startYear?.hasError('max')"
                  >
                    {{ 'translations.collection.message.error.enterValidYear' | transloco }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="form__field" appearance="outline">
                  <mat-label #expectedGraduationYear>{{ 'translations.collection.label.expectedYearOfGraduation' | transloco }}</mat-label>
                  <input
                    min="1990"
                    max="2100"
                    matInput
                    required
                    [attr.aria-label]="'translations.global.ariaLabels.enterExpectedGraduationYear' | transloco"
                    type="number"
                    name="expectedGraduationYear"
                    [placeholder]="expectedGraduationYear.innerHTML"
                    formControlName="expectedGraduationYear"
                  />
                  <mat-error *ngIf="onBoardDataForm.controls.expectedGraduationYear?.hasError('required')">
                    {{ 'translations.collection.message.error.enterValidExpectedGraduationYear' | transloco }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      onBoardDataForm.controls.expectedGraduationYear?.hasError('min') ||
                      onBoardDataForm.controls.expectedGraduationYear?.hasError('max') ||
                      onBoardDataForm.controls.expectedGraduationYear?.hasError('smaller')
                    "
                  >
                    {{ 'translations.collection.message.error.enterValidYear' | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>
              <mat-form-field class="form__field" appearance="outline">
                <mat-label #personalInstitutionEmail>{{
                  'translations.collection.label.personalInstitutionEmailAddressOptional' | transloco
                }}</mat-label>
                <input
                  matInput
                  type="email"
                  [attr.aria-label]="'translations.global.ariaLabels.enterEmail' | transloco"
                  name="personalInstitutionEmail"
                  [placeholder]="personalInstitutionEmail.innerHTML"
                  formControlName="personalInstitutionEmail"
                />
                <mat-error *ngIf="onBoardDataForm.controls.personalInstitutionEmail?.hasError('email')">
                  {{ 'translations.global.input.error.enterEmail' | transloco }}
                </mat-error>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="affiliation === 'GRADUATE'">
              <mat-form-field class="form__field" appearance="outline">
                <mat-label #degree>{{ 'translations.collection.label.degree' | transloco }}</mat-label>
                <input
                  matInput
                  required
                  type="text"
                  [attr.aria-label]="'translations.global.ariaLabels.enterDegree' | transloco"
                  name="degree"
                  [placeholder]="degree.innerHTML"
                  formControlName="degree"
                />
                <mat-error *ngIf="onBoardDataForm.controls.degree?.hasError('required')">
                  {{ 'translations.collection.message.error.enterValidDegree' | transloco }}
                </mat-error>
              </mat-form-field>
              <div class="form__field-group">
                <mat-form-field class="form__field" appearance="outline">
                  <mat-label #graduationYear>{{ 'translations.collection.label.yearOfGraduation' | transloco }}</mat-label>
                  <input
                    min="1950"
                    max="2100"
                    matInput
                    required
                    type="number"
                    [attr.aria-label]="'translations.global.ariaLabels.enterGraduationYear' | transloco"
                    name="graduationYear"
                    [placeholder]="graduationYear.innerHTML"
                    formControlName="graduationYear"
                  />
                  <mat-error *ngIf="onBoardDataForm.controls.graduationYear?.hasError('required')">
                    {{ 'translations.collection.message.error.enterValidGraduationYear' | transloco }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      onBoardDataForm.controls.graduationYear?.hasError('min') || onBoardDataForm.controls.graduationYear?.hasError('max')
                    "
                  >
                    {{ 'translations.collection.message.error.enterValidYear' | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div
          class="data-collection-dialog__location-section"
          [style.display]="isLocationStep || affiliation === 'INDEPENDENT' ? '' : 'none'"
        >
          <mat-form-field class="form__field" appearance="outline">
            <mat-label #yourCity>{{ 'translations.collection.label.yourLocation' | transloco }}</mat-label>
            <input
              matInput
              required
              type="text"
              name="yourCity"
              [attr.aria-label]="'translations.global.ariaLabels.enterCity' | transloco"
              [placeholder]="yourCity.innerHTML"
              formControlName="yourCity"
            />
            <mat-error *ngIf="onBoardDataForm.controls.yourCity?.hasError('required')">
              {{ 'translations.collection.message.error.enterValidCity' | transloco }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label #yourCountry>{{ 'translations.collection.label.country' | transloco }}</mat-label>
            <input
              matInput
              required
              type="text"
              name="yourCountry"
              [attr.aria-label]="'translations.global.ariaLabels.enterCountry' | transloco"
              [matAutocomplete]="auto"
              [placeholder]="yourCountry.innerHTML"
              formControlName="yourCountry"
            />
            <mat-error *ngIf="onBoardDataForm.controls.yourCountry?.hasError('required')">
              {{ 'translations.collection.message.error.enterValidCountry' | transloco }}
            </mat-error>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option [value]="country" *ngFor="let country of countryResults">{{ country }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <button mat-raised-button type="submit" class="data-collection-dialog__btn uppercase" color="green">
            {{ 'translations.collection.button.completeRegistration' | transloco }}
          </button>
        </div>
      </form>
    </div>
    <button
      *ngIf="isMainStep || !(isLocationStep || (affiliation === 'INDEPENDENT' && !isMainStep))"
      mat-raised-button
      type="button"
      class="data-collection-dialog__btn uppercase"
      color="green"
      (click)="onNext(!isMainStep)"
    >
      {{ 'translations.global.button.next' | transloco }}
    </button>
  </div>
</div>
