<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="resource-item-border" tabindex="0" [class.member-active]="resource.id === activeResource">
  <div class="resource-item bookable-resource" (click)="activateResource(resource.id)" (keydown.enter)="activateResource(resource.id)">
    <img
      class="resource-user-image"
      [alt]="resource.targetResourceSummary.name"
      src="{{
        resource.targetResourceSummary.imageUrl ? resource.targetResourceSummary.imageUrl : '../../../../../../../assets/profile_image.png'
      }}"
    />
    {{ resource.targetResourceSummary.name }}
    <span
      tabindex="0"
      *ngIf="activeResource === resource.id"
      (click)="onDisassociateResource(resource.id, $event)"
      (keydown.enter)="onDisassociateResource(resource.id, $event)"
      [attr.aria-label]="'translations.global.ariaLabels.icons.remove' | transloco"
      class="resource-user-icon"
    >
      <svg class="svg resource-user-icon" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
      </svg>
    </span>
    <span tabindex="0" *ngIf="activeResource !== resource.id" class="resource-user-icon">
      <svg class="svg resource-user-icon" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#plus"></use>
      </svg>
    </span>
  </div>
  <div class="row" *ngIf="scheduleHasError">
    <div class="col interval-padding">
      <mat-error>
        {{ 'translations.bookings.label.errors.scheduleError' | transloco }}
      </mat-error>
    </div>
  </div>
  <div *ngIf="activeResource === resource.id" class="interval-padding">
    <div class="booking-form__timetable-row" *ngFor="let dayValue of dayValues; let iterator = index">
      <div class="booking-form-inner-info">
        <div class="booking-form__timetable-column-checkboxes">
          <!--
          t(translations.bookings.label.weekdaysPlural.monday)
          t(translations.bookings.label.weekdaysPlural.tuesday)
          t(translations.bookings.label.weekdaysPlural.wednesday)
          t(translations.bookings.label.weekdaysPlural.thursday)
          t(translations.bookings.label.weekdaysPlural.friday)
          t(translations.bookings.label.weekdaysPlural.saturday)
          t(translations.bookings.label.weekdaysPlural.sunday)
          -->
          <div
            class="checkmark"
            [class.is_checked]="checkIfAlreadyChecked(dayValue)"
            [attr.aria-label]="'translations.bookings.label.weekdaysPlural.' + dayValue.day.toLowerCase() | transloco"
            (click)="activateDay(resource.id, dayValue)"
            (keydown.enter)="activateDay(resource.id, dayValue)"
          >
            <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.check' | transloco">
              <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
            </svg>
          </div>
        </div>
        <div
          class="booking-form__timetable-column-days"
          (click)="activateDay(resource.id, dayValue)"
          (keydown.enter)="activateDay(resource.id, dayValue)"
        >
          <span>{{ 'translations.bookings.label.weekdaysPlural.' + dayValue.day.toLowerCase() | transloco }}</span>
        </div>
        <div class="booking-form__timetable-column-time">
          <div class="hours-select" *ngIf="dayValue.slots.length === 0">
            <mat-select [disabled]="true" class="interval-select"> </mat-select>
            -
            <mat-select [disabled]="true" class="interval-select"> </mat-select>
          </div>
          <div class="hours-select" *ngFor="let time of dayValue.slots; let timeIterator = index">
            <mat-select
              #matSelectRef
              *ngIf="time"
              class="interval-select"
              [(value)]="time.startTime"
              [class.validation-error]="isIntervalOverlapping(time, dayValue.day)"
              (selectionChange)="onIntervalSelection()"
            >
              <mat-option
                *ngFor="let option of timeIntervals"
                [disabled]="disableIfBetweenIntervals(option.start, dayValue.day)"
                [value]="option.start"
              >
                {{ option.start }}
              </mat-option>
            </mat-select>
            -
            <mat-select
              *ngIf="time"
              class="interval-select"
              [class.validation-error]="isIntervalOverlapping(time, dayValue.day)"
              (selectionChange)="onIntervalSelection()"
              [(value)]="time.endTime"
            >
              <mat-option
                *ngFor="let option of timeIntervals"
                [disabled]="disableBeforeStartTime(option.end, time.startTime)"
                [value]="option.end"
              >
                {{ option.end }}
              </mat-option>
            </mat-select>
            <button
              *ngIf="checkIfDayIncluded(dayValue.day) && dayValue.slots.length > 1"
              (click)="removeTimeSlot($event, dayValue.day, timeIterator)"
              [title]="'translations.global.button.remove' | transloco"
              (keydown.enter)="removeTimeSlot($event, dayValue.day, timeIterator)"
              class="booking-form__button-round booking-form__button-box"
            >
              <svg class="svg svg-icon minus-button" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#remove-circle"></use>
              </svg>
            </button>
            <button
              *ngIf="checkIfDayIncluded(dayValue.day) && timeIterator + 1 === dayValue.slots.length"
              class="booking-form__button-round booking-form__button-box"
              [title]="'translations.global.button.add' | transloco"
              (click)="addNewTimeSlot($event, dayValue.day)"
              (keydown.enter)="addNewTimeSlot($event, dayValue.day)"
            >
              <svg class="svg svg-icon" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#add-circle"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="booking-form__timetable-column" *ngIf="iterator === 0">
        <button
          class="booking-form__button-approve-all"
          mat-button
          [title]="'translations.bookings.label.applyButtonTitle' | transloco"
          [disabled]="mondayHasValidationError"
          (click)="applyToAll($event)"
          (keydown.enter)="applyToAll($event)"
        >
          {{ 'translations.bookings.label.applyButtonTitle' | transloco }}
        </button>
      </div>
    </div>
  </div>
</div>
