<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="team-role-chart__content">
  <div class="team-role-chart__content-inner" *ngIf="!showStaticData && piReport">
    <div class="team-role-chart">
      <div
        *ngFor="let chart of chartData?.teamRoleScores; let i = index"
        class="team-role-chart__item team-role-chart__item-{{ chart.key }}"
      >
        <div class="chart-progress">
          <div class="chart-progress-main" [style.height]="chart.value + '%'">
            <span class="chart-progress-value">{{ chart.value }}</span>
          </div>
          <div class="chart-progress-secondary" [style.height]="chart.previousValue + '%'"></div>
        </div>

        <div class="chart-info">
          <div class="chart-icon"></div>
          <div
            class="chart-name"
            tabindex="0"
            [attr.aria-label]="
              chart.name +
              ', ' +
              ('translations.global.ariaLabels.currentProgress' | transloco: { currentProgress: ': ' + chart.value }) +
              (chart.previousValue
                ? ', ' + ('translations.global.ariaLabels.previousProgress' | transloco: { previousProgress: ': ' + chart.previousValue })
                : '')
            "
          >
            {{ chart.name | upperFirst }}
          </div>
        </div>

        <div class="team-role-chart__item-description">
          <div class="team-role-chart__item-description-title">{{ chart.name | upperFirst }}</div>
          <div class="team-role-chart__item-short-description" [innerHTML]="chart.shortDescription[0].value"></div>
        </div>
      </div>
    </div>
    <div class="team-role-chart__dates" *ngIf="completionDate2">
      <span class="team-role-chart__date"> {{ completionDate1 | dateFormat: 'DD/MM/yyyy, HH:mm' }}</span>
      <span class="team-role-chart__date">{{ completionDate2 | dateFormat: 'DD/MM/yyyy, HH:mm' }}</span>
    </div>
  </div>

  <div class="team-role-chart__static-content" *ngIf="showStaticData">
    <img src="../assets/charts/team-role.png" alt="team role chart" width="600" height="315" />
  </div>
</div>
