/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

import { PlaylistPublicationSettings, PlaylistType } from '../../../../../shared/models';

// -------------------------------------------------------------------------
export class PublishPlaylist {
  static readonly type = '[Playlist Resource edit] Publish playlist';
}

// -------------------------------------------------------------------------
export class UnpublishPlaylist {
  static readonly type = '[Playlist Resource edit] Unpublish playlist';
}

// -------------------------------------------------------------------------
export class SetPublicationSettings {
  static readonly type = '[Playlist Resource edit] Set publication settings';

  constructor(
    public playlistId: string,
    public playlistType: PlaylistType,
    public settings: PlaylistPublicationSettings,
  ) {}
}
