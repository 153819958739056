/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditorContent, EditorContentRemovalEvent, EditorContentUpdateEvent, ResourceTag } from '../../../../../../shared/models';
import { Moment } from '../../../../models';

@Component({
  selector: 'ptl-moment-details-form',
  templateUrl: './moment-details-form.component.html',
  styleUrls: ['./moment-details-form.component.scss'],
})
export class MomentDetailsFormComponent implements OnChanges {
  /** Receives the moment data. */
  @Input() moment?: Moment;
  /** The name to display in the editor placeholder. */
  @Input() userFirstName?: string;
  /** The label to display on the form submit button. */
  @Input() submitLabel = 'Add Post';
  /** True when the editor state is being loaded(i.e. a moment being loaded or created) */
  @Input() loading = false;
  /** Defines if form is expanded on init, else it expands on medium-editor focus. */
  @Input() isExpanded?: boolean;
  @Input() paddingReduced = false;

  /** Emits adding of a new tag. */
  @Output() addTag = new EventEmitter<ResourceTag>();
  /** Emits adding of a new tags. */
  @Output() addTagBulk = new EventEmitter<ResourceTag[]>();
  /** Emits the index of the tag to remove. */
  @Output() removeTag = new EventEmitter<number>();
  /** Emits the save event. */
  @Output() momentSave = new EventEmitter<string>();
  /** Emits the clear event. */
  @Output() momentClear = new EventEmitter<void>();
  /** Emits on content update. */
  @Output() contentUpdate = new EventEmitter<EditorContentUpdateEvent>();
  /** Emits on content removal. */
  @Output() contentRemoval = new EventEmitter<EditorContentRemovalEvent>();
  /** Emits data on new content from child components */
  @Output() contentCreated = new EventEmitter<EditorContent>();

  momentForm: FormGroup;
  formExpanded = false;
  frameworkTagsPanelOpen = false;
  placeholderMessage = '';

  constructor(private fb: FormBuilder) {
    this.momentForm = this.fb.group({
      title: ['', [Validators.required]],
    });
  }

  ngOnChanges() {
    if (this.moment) {
      this.momentForm.setValue({ title: this.moment.title });
    }

    if (this.isExpanded) {
      this.formExpanded = true;
    }

    setTimeout(() => {
      (document.querySelector('.editor-content-container') as HTMLElement)?.click();
    }, 100);
  }

  onAddTag(tag: ResourceTag) {
    this.addTag.emit(tag);
  }

  onAddTagBulk(tags: ResourceTag[]) {
    this.addTagBulk.emit(tags);
  }

  onRemoveTag(tagIndex: number) {
    this.removeTag.emit(tagIndex);
  }

  saveMoment() {
    this.momentSave.emit();
  }

  clearMoment() {
    this.momentClear.emit();
  }

  onContentUpdate(contentUpdate: EditorContentUpdateEvent) {
    this.contentUpdate.next(contentUpdate);
  }

  onContentRemoval(contentRemoval: EditorContentRemovalEvent) {
    this.contentRemoval.next(contentRemoval);
  }

  onContentCreation(content: EditorContent) {
    this.contentCreated.next(content);
  }

  onFocus() {
    this.formExpanded = true;
  }

  onToggleFrameworkTagsPanel(value: boolean) {
    this.frameworkTagsPanelOpen = value;
  }
}
