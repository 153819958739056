/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { EditorContent, EditorContentRemovalEvent, EditorContentUpdateEvent } from '../../../../../../shared/models';

@Component({
  selector: 'ptl-quiz-drag-drop-question-type',
  templateUrl: './quiz-drag-drop-question-type.component.html',
  styleUrls: ['../add-question-section.component.scss', './quiz-drag-drop-question-type.component.scss'],
})
export class QuizDragDropQuestionTypeComponent implements OnChanges {
  /** Receives the question to edit */
  @Input() question: FormGroup;

  @Input() languageCode: string | undefined;
  @Input() isDefaultLanguage: boolean;
  @Input() isQuiz: boolean;

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
  ) {}

  ngOnChanges() {
    if (this.question) {
      this.populateForm();
    }
  }

  addGridOption(): FormGroup {
    const dynamicContentRequest = this.fb.array([
      this.fb.group({
        content: '',
        type: 'PARAGRAPH',
        uid: '',
      }),
    ]);

    return this.fb.group({
      type: 'CHOICE_OPTION',
      instructions: dynamicContentRequest,
      value: this.fb.group({
        languageCode: this.languageCode,
        value: [undefined, Validators.required],
      }),
      score: 1,
      correct: [false],
    });
  }

  removeOption(index) {
    (this.question.controls['options'] as FormArray).removeAt(index);
    this.question.controls['selectionLimit'].setValue((this.question.controls['options'] as FormArray).length);
  }

  addNewOption() {
    (this.question.controls['options'] as FormArray).push(this.addGridOption());
    this.question.controls['selectionLimit'].setValue((this.question.controls['options'] as FormArray).length);
    setTimeout(() => {
      const lastElement = this.question.controls['options']['controls'].length - 1;
      const inputElement = this.renderer.selectRootElement(`#input${lastElement}`);
      if (lastElement > 0) {
        inputElement.focus();
      }
    }, 0);
  }

  dropOption(moveEvent: CdkDragDrop<FormArray>) {
    moveItemInArray((this.question.controls['options'] as FormArray).controls, moveEvent.previousIndex, moveEvent.currentIndex);
    this.question.controls['options'].updateValueAndValidity();
  }

  populateForm() {
    // if no uid, clear current arrays and add a blank one
    if (!this.question.value.uid) {
      while ((this.question.controls['options'] as FormArray).length !== 0) {
        (this.question.controls['options'] as FormArray).removeAt(0);
      }
      this.addNewOption();
    }
  }

  onContentUpdate({ index, update }: EditorContentUpdateEvent, level: EditorContent[], questionIndex: number) {
    const content = level.map((editorContent, i) => (i === index ? update.newContent : editorContent));
    const optionsArray = this.question.get('options') as FormArray;
    const optionControl = optionsArray.at(questionIndex) as FormGroup;
    optionControl.patchValue({ instructions: content });
  }

  onContentRemoval({ index }: EditorContentRemovalEvent, level: EditorContent[], questionIndex: number) {
    const optionsArray = this.question.get('options') as FormArray;
    const optionControl = optionsArray.at(questionIndex) as FormGroup;
    optionControl.patchValue({ instructions: [{ type: 'PARAGRAPH', content: '' }] });
  }

  onRankChange(value: number, scoreControl: FormControl) {
    const maxRankSize = (this.question.controls['options'] as FormArray).length;
    if (!value) {
      scoreControl.setValue(0);
    }
    if (value > maxRankSize) {
      scoreControl.setValue(maxRankSize);
    }
  }
}
