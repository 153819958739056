import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotDisplayableGroupTypes, UserGroups } from '../../models/admin/group/user-groups.model';
import { canTriggerSearch } from '../../helpers/content-helper';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'ptl-group-search-autocomplete',
  templateUrl: './group-search-autocomplete.component.html',
  styleUrls: ['./group-search-autocomplete.component.scss'],
})
export class GroupSearchAutocompleteComponent {
  @Input() groups: UserGroups[] = [];
  @Input() placeholder: string;
  @Input() loadingGroups: boolean;
  @Input() disabled: boolean;

  @Output() searchInputChanged = new EventEmitter<string>();
  @Output() searchLoadedMore = new EventEmitter<string>();
  @Output() groupSelected = new EventEmitter<UserGroups>();

  canLoadMore: boolean;
  isLoadingMore: boolean;

  private searchString: string;

  onFocus(): void {
    if (this.searchString === undefined) {
      this.searchString = '';
      this.searchInputChanged.emit('');
    }
  }

  onTextPaste(event: ClipboardEvent) {
    if (event.type === 'paste') {
      setTimeout(() => {
        this.searchString = (event.target as HTMLInputElement).value;
        this.searchInputChanged.emit(this.searchString);
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent) {
    if (canTriggerSearch(event)) {
      this.searchString = (event.target as HTMLInputElement).value;
      this.searchInputChanged.emit(this.searchString);
    }
  }

  disabledAutocomplete(): string {
    return '';
  }

  onGroupSelected({ option }: MatAutocompleteSelectedEvent) {
    const data = option.value as UserGroups;
    this.groupSelected.emit(data);
  }

  onAutocompleteScroll() {
    if (!this.canLoadMore) {
      return;
    }
    if (!this.isLoadingMore) {
      this.searchLoadedMore.emit(this.searchString);
      this.isLoadingMore = true;
    }
  }

  showGroupType(type: string): boolean {
    return !NotDisplayableGroupTypes.includes(type.toUpperCase());
  }
}
