/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { RestClientService } from '../rest-client.service';
import { ObservableResult } from '../../store';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { Page as Pagination } from '../../models/page';
import { UpcomingEventCard, UpcomingEventFilter, UpcomingEventsTag } from '../../models/event-card/event-card.model';
import { SharedUpcomingDataService } from './upcoming-data.service';
import { TranslocoService } from '@ngneat/transloco';
import { Resource } from '@app/app/shared/models';

@Injectable()
export class ApiSharedUpcomingDataService implements SharedUpcomingDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  getUpcomingEventsCards(
    page: number,
    size: number,
    frameworkId?: string,
    tagId?: string,
    languageCode?: string,
  ): ObservableResult<Pagination<UpcomingEventCard>> {
    const apiPath = frameworkId
      ? `/events/upcomings-cards/?page=${page}&size=${size}&frameworkUid=${frameworkId}&tagId=${tagId}`
      : `/events/upcomings-cards?page=${page}&size=${size}`;
    return this.client
      .get<
        Pagination<UpcomingEventCard>
      >(Location.joinWithSlash(environment.apiRootUrl || '', apiPath), null, languageCode ? { 'Accept-Language': languageCode } : null)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorLoadingUpcomingEvents'))),
      );
  }

  getUpcomingEventsTags(page: number, size: number, term?: string): ObservableResult<Pagination<UpcomingEventsTag>> {
    const params = {
      page: page.toString(),
      size: size ? size.toString() : '10',
    };
    if (term) {
      params['term'] = term;
    }
    return this.client
      .get<Pagination<UpcomingEventsTag>>(Location.joinWithSlash(environment.apiRootUrl || '', '/events/upcomings/tags'), params)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorLoadingUpcomingEvents'))),
      );
  }

  hasUpcomingEvents(): ObservableResult<boolean> {
    const uri = '/events/upcomings/filtered?page=0&size=1';
    const request = {
      name: undefined,
      eventTypes: undefined,
      skills: undefined,
      dateBefore: undefined,
      dateAfter: undefined,
    };
    return this.client.post<Pagination<UpcomingEventCard>>(Location.joinWithSlash(environment.apiRootUrl || '', uri), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body.hasContent)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorLoadingUpcomingEvents'))),
    );
  }

  getUpcomingEventsByFilter(page: number, size: number, filter: UpcomingEventFilter): ObservableResult<Pagination<UpcomingEventCard>> {
    const uri = `/events/upcomings/filtered?page=${page}&size=${size}`;
    const request = {
      name: filter.nameFilter,
      eventTypes: filter.typeFilter ? { first: filter.typeFilter.types, second: filter.typeFilter.filterType } : undefined,
      skills: filter.skillFilter ? { first: filter.skillFilter.skills, second: filter.skillFilter.filterType } : undefined,
      dateBefore: filter.dateFilter?.beforeDate,
      dateAfter: filter.dateFilter?.afterDate,
    };

    return this.client.post<Pagination<UpcomingEventCard>>(Location.joinWithSlash(environment.apiRootUrl || '', uri), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorLoadingUpcomingEvents'))),
    );
  }

  getEventsEngagements(page: number, size: number, filter: UpcomingEventFilter): ObservableResult<Pagination<UpcomingEventCard>> {
    const uri = `/events/engagements?page=${page}&size=${size}`;
    const request = {
      name: filter.nameFilter,
      eventTypes: filter.typeFilter
        ? {
            first: filter.typeFilter.types,
            second: filter.typeFilter.filterType,
          }
        : undefined,
      skills: filter.skillFilter
        ? {
            first: filter.skillFilter.skills,
            second: filter.skillFilter.filterType,
          }
        : undefined,
      dateBefore: filter.dateFilter?.beforeDate,
      dateAfter: filter.dateFilter?.afterDate,
    };

    return this.client.post<Pagination<UpcomingEventCard>>(Location.joinWithSlash(environment.apiRootUrl || '', uri), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorLoadingUpcomingEvents'))),
    );
  }
}
