/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MEMBERS_DATA_SERVICE, MembersDataService } from '@app/app/page-modules/admin/services/members/members-data.service';
import { BooleanQueryType, MembersBooleanSearchRequest, OrRequest } from '@app/app/shared/models/admin/boolean-filters.model';
import { UserInfo } from '@app/app/shared/models/analytics/analytics.model';

@Component({
  selector: 'ptl-member-search-autocomplete',
  templateUrl: './member-search-autocomplete.component.html',
  styleUrls: ['./member-search-autocomplete.component.scss'],
})
export class MemberSearchAutocompleteComponent {
  @Input() placeholder = 'Add new member';

  @Output() memberSelected = new EventEmitter<UserInfo>();
  canLoadMore: boolean;
  isLoadingMore: boolean;
  protected users: UserInfo[] = [];
  protected loading = false;
  private name = '';

  constructor(@Inject(MEMBERS_DATA_SERVICE) private membersDataService: MembersDataService) {}

  onTextPaste(event: ClipboardEvent) {
    setTimeout(() => {
      this.name = (event.target as HTMLInputElement).value;
      this.applyNameFilter();
    }, 0);
  }

  onSearchInputChange(event: KeyboardEvent) {
    const previousValue = this.name;
    const newValue = (event.target as HTMLInputElement).value.trim();

    if (previousValue !== newValue) {
      this.name = newValue;
      this.applyNameFilter();
    }
  }

  disabledAutocomplete(): string {
    return '';
  }

  onMemberSelected({ option }: MatAutocompleteSelectedEvent) {
    const data = option.value as UserInfo;
    this.memberSelected.emit(data);
  }

  onAutocompleteScroll() {
    if (!this.canLoadMore) {
      return;
    }
    if (!this.isLoadingMore) {
      this.isLoadingMore = true;
    }
  }

  private applyNameFilter() {
    if (!this.name) {
      this.users = [];
      return;
    }

    this.loading = true;

    const firstNameRequest = {
      type: BooleanQueryType.FIRST_NAME,
      firstName: this.name,
    };
    const lastNameRequest = {
      type: BooleanQueryType.LAST_NAME,
      lastName: this.name,
    };
    const externalIdRequest = {
      type: BooleanQueryType.EXTERNAL_ID,
      externalId: this.name,
    };
    const emailRequest = {
      type: BooleanQueryType.EMAIL,
      email: this.name,
    };
    const request = {
      type: BooleanQueryType.OR,
      args: [firstNameRequest, lastNameRequest, externalIdRequest, emailRequest],
    } as OrRequest;

    this.membersDataService
      .searchMembers({ query: request, order: { fieldName: 'firstName', direction: 'ASC' } } as MembersBooleanSearchRequest, 0, 10)
      .subscribe({
        next: (result) => {
          this.users = result.isSuccess
            ? result.value.members.map((member) => ({
                userId: member._id,
                imageUrl: member.imageUrl,
                firstName: member.firstName,
                lastName: member.lastName,
              }))
            : [];
        },
        error: () => (this.users = []),
        complete: () => (this.loading = false),
      });
  }
}
