import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Mentee, Mentor } from '@app/app/page-modules/admin/components/mentors/admin-mentors.model';

@Component({
  selector: 'ptl-mentor-mentee-table',
  templateUrl: './mentor-mentee-table.component.html',
  styleUrls: ['./mentor-mentee-table.component.scss'],
})
export class MentorMenteeTableComponent implements OnInit, OnChanges {
  @Input() data: (Mentor | Mentee)[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() viewMode: 'mentors' | 'mentees' = 'mentors';
  @Input() isMyHub = false;

  @Output() viewDetails = new EventEmitter<Mentor | Mentee>();
  @Output() sortChange = new EventEmitter<Sort>();

  processedData: (Mentor | Mentee)[] = [];

  ngOnInit(): void {
    this.processData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] || changes['entityType']) {
      this.processData();
    }
  }

  isPendingApproval(user: Mentor): boolean {
    return this.isMyHub && this.viewMode === 'mentors' && user.status === 'PENDING';
  }

  updateTable(newData: (Mentor | Mentee)[]) {
    this.data = [...newData];
  }

  processData(): void {
    if (this.viewMode === 'mentors') {
      this.processedData = (this.data as Mentor[])?.map((mentor) => ({
        ...mentor,
        status: mentor.status,
      }));
    } else {
      this.processedData = (this.data as Mentee[]) ? [...this.data] : [];
    }
  }

  onSortChange(sort: Sort) {
    this.sortChange.emit(sort);
  }

  getStatusDisplayName(status: string): string {
    switch (status.toLowerCase()) {
      case 'active':
        return 'Active';
      case 'inactive':
        return 'Inactive';
      case 'archived':
        return 'Archived';
      default:
        return '';
    }
  }
}
