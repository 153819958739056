/*
 * Copyright (C) 2023 - present by Potentially
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Select } from '@ngxs/store';
import { Organization, Resource, PlaylistPublicationSettings, Playlist, UserSubmissionMode } from '@app/app/shared/models';
import { Observable, Subscription, combineLatest, takeUntil, of } from 'rxjs';
import { MembersBooleanSearchRequest } from '@app/app/shared/models/admin/boolean-filters.model';
import { CheckedMembersData } from '@app/app/shared/models/admin/members.model';
import { TranslocoService } from '@ngneat/transloco';
import { LoadableState, ObservableResult } from '@app/app/shared/store';
import { UserAuthState } from '@app/app/user-auth/store/user-auth.state';
import { RedirectHelper } from '../../../../../resource/store/editor/content/helpers/redirect.helper';
import { AdminMembersAction, AdminMembersActionType, MembersListColumns } from '../../model/members.model';
import { AlumniCsvUploadDialogComponent } from '../../admin-member-control-panel/alumni-csv-upload-dialog/alumni-csv-upload-dialog.component';
import { CheckInDialogComponent } from '../../admin-member-control-panel/check-in-dialog/check-in-dialog.component';
import { PlaylistResourceEditorPublicationState } from 'src/app/page-modules/playlist/store/create/publication/playlist-publication.state';
import { PlaylistViewState } from '../../../../../playlist/store/view/playlist-view.state';
import { ContentHelper } from '@app/app/shared/helpers/content-helper';
import { ReviewAction, ReviewAdminRequest } from '@app/app/page-modules/reviews/models';
import { EditRoleDialogComponent } from '@app/app/page-modules/admin/components/members/admin-member-control-panel/edit-role-dialog/edit-role-dialog.component';
import { EditGroupDialogComponent } from '@app/app/page-modules/admin/components/members/admin-member-control-panel/edit-group-dialog/edit-group-dialog.component';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { ResourceAdminState } from '@app/app/page-modules/resource/store/admin/resource-admin.state';
import { DialogService } from '@app/app/shared/helpers/dialog/dialog.service';
import { REVIEWS_DATA_SERVICE, ReviewsDataService } from '@app/app/page-modules/reviews/services/data.service';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from '@app/app/shared/services/toast-notifications/toast-service';
import { CloseReviewsModalComponent } from '@app/app/page-modules/reviews/components/view/submissions-controls/close-reviews-modal/close-reviews-modal.component';
import { SendMessageDialogComponent } from '@app/app/page-modules/admin/components/members/admin-member-control-panel/send-message-dialog/send-message-dialog.component';
/* eslint-disable max-len */
import { UsersCsvUploadDialogComponent } from '@app/app/page-modules/admin/components/members/admin-member-control-panel/users-csv-upload-dialog/users-csv-upload-dialog.component';

@Component({
  selector: 'ptl-v3-admin-action-buttons',
  templateUrl: './v3-admin-action-buttons.component.html',
  styleUrls: ['./v3-admin-action-buttons.component.scss'],
})
export class V3AdminActionButtonsComponent implements OnInit, OnDestroy {
  @Input() columns: MembersListColumns[];
  @Input() isAdminPage: boolean;
  @Input() isPlaylistPage: boolean;
  @Input() isCardPage: boolean;
  @Input() isAssessment: boolean;
  @Input() isEvent: boolean;
  @Input() checkedMembers: CheckedMembersData[] = [];
  @Input() totalMembersCount: number;
  @Input() allMembersCount: number;
  @Input() booleanSearchRequest: MembersBooleanSearchRequest;
  @Input() playlistUid: string;
  @Input() cardUid: string;
  @Input() exportEnabled: boolean;
  @Input() exportName: string;
  @Input() isWaitingListEnabled: boolean;
  @Input() eventFinished: boolean;
  @Input() isAllSelected: boolean;
  @Input() isWaitingListOperationDisabled: boolean;
  @Input() courseUid?: string;

  @Output() reviewersChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() csvFileUploadFinished: EventEmitter<number> = new EventEmitter<number>();
  @Output() triggerUserImport: EventEmitter<{ file: File; headers: string[] }> = new EventEmitter<{ file: File; headers: string[] }>();

  @Output() refreshList = new EventEmitter<number>();
  @Output() memberActionTriggered = new EventEmitter<AdminMembersAction>();

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @Select(UserAuthState.canManageUserImportPrivileges)
  canManageUserImportPrivileges$: Observable<boolean>;

  @Select(ResourceAdminState.resource)
  private resource$: Observable<Resource>;

  @Select(UserAuthState.userHasSuperAdminRole)
  private isUserSuperAdmin$: Observable<boolean>;

  @Select(UserAuthState.userHasAdminRole)
  private isUserAdmin$: Observable<boolean>;

  @Select(PlaylistResourceEditorPublicationState.publicationSettings)
  private playlistPublicationSettings$: Observable<PlaylistPublicationSettings>;

  @Select(PlaylistViewState.playlistState)
  private playlist$: Observable<LoadableState<Playlist>>;

  reviewActions = ReviewAction;

  advancedExportAvailable = false;
  importDisabledTooltipText: string;

  cardPublished = false;
  playlistPublished = false;
  limitedTickets = false;
  remainingTickets: number;
  ticketsQuantity: number;
  canManageReviews: boolean;
  isIframeMode: boolean;
  isImportDisabled = false;

  private alumniUploadDialogRef: MatDialogRef<AlumniCsvUploadDialogComponent>;

  private usersUploadDialogRef: MatDialogRef<UsersCsvUploadDialogComponent>;

  private subscriptionEnd$ = new EventEmitter<void>();
  private resource: Resource;
  private contentSubscription: Subscription;
  private organizationDataSubscription: Subscription;
  private orgDomain: string;
  private playlistSubmissionMode: UserSubmissionMode;

  constructor(
    private activatedRoute: ActivatedRoute,
    private translocoService: TranslocoService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    @Inject(REVIEWS_DATA_SERVICE) private reviewsDataService: ReviewsDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
  ) {
    this.isIframeMode = ContentHelper.isFrameMode();
  }

  ngOnInit(): void {
    combineLatest([this.isUserAdmin$, this.isUserSuperAdmin$])
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe((data) => {
        this.advancedExportAvailable = this.checkIfUserCanViewExports(data[0], data[1]);
      });

    this.playlist$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((playlist) => {
      if (playlist && playlist.data) {
        this.canManageReviews = playlist?.data?.userPlaylistSubmissionSummary?.userReviewSummary?.reviewType !== 'AUTOMATIC';
        this.playlistSubmissionMode = playlist?.data?.userPlaylistSubmissionSummary?.submissionMode;
      }
    });

    this.playlistPublicationSettings$.subscribe((publicationSettings) => {
      if (publicationSettings) {
        this.playlistPublished = publicationSettings.published;
        if (this.playlistPublished) {
          this.importDisabledTooltipText = undefined;
        } else {
          this.importDisabledTooltipText = this.translocoService.translate(
            'translations.membersNew.controlPanel.import.importDisabledTooltipTextPlaylist',
          );
        }
        this.isImportDisabled = !this.playlistPublished;
        this.cdr.detectChanges();
      }
    });
    if (this.isCardPage) {
      this.contentSubscription = this.resource$.subscribe((resource) => {
        if (resource) {
          this.resource = resource;
          this.cardPublished = resource.status === 'PUBLISHED';
          if (this.cardPublished) {
            this.importDisabledTooltipText = undefined;
          } else {
            this.importDisabledTooltipText = this.translocoService.translate(
              'translations.membersNew.controlPanel.import.importDisabledTooltipText',
            );
          }
          if (resource.tickets?.availability === 'LIMITED') {
            this.limitedTickets = true;
            this.remainingTickets = resource.tickets.remainingTickets;
            this.ticketsQuantity = resource.tickets.quantity;
          }
          this.isImportDisabled = !this.cardPublished;
          this.cdr.detectChanges();
        }
      });
    }
    this.organizationDataSubscription = this.organizationData$.subscribe((data) => {
      this.orgDomain = data.domain;
    });
  }

  ngOnDestroy(): void {
    this.contentSubscription?.unsubscribe();
    this.organizationDataSubscription?.unsubscribe();
    this.subscriptionEnd$.emit();
  }

  async reviewAction(operationType: string): Promise<void> {
    if (!this.getCheckedReviewUids()) {
      return;
    }

    let response: ObservableResult<void>;

    if (operationType !== ReviewAction.CLOSE) {
      const isConfirmed = await this.showConfirmDialog(operationType);
      if (!isConfirmed) {
        return;
      }

      if (this.isAllSelected) {
        response = this.doAllItemsOperation(operationType);
      } else {
        response = this.doSelectedItemsOperation(operationType);
      }
      response.subscribe(({ isSuccess, value, error }) => {
        if (isSuccess) {
          this.reviewersChanged.emit();
        } else {
          this.toastService.showFail(error);
        }
      });
    } else {
      this.handleCloseAction(operationType);
    }
  }

  doAllItemsOperation(operationType: string) {
    if (operationType === ReviewAction.REOPEN) {
      return this.reviewsDataService.reOpenReview({ reviewUids: this.getCheckedReviewUids() });
    }

    return of();
  }

  doSelectedItemsOperation(operationType: string) {
    if (operationType === ReviewAction.REOPEN) {
      return this.reviewsDataService.reOpenReview({ reviewUids: this.getCheckedReviewUids() });
    }

    return of();
  }

  showConfirmDialog(key) {
    const keyLowerCase = key.toLowerCase();
    const upperCaseFirstLetter = keyLowerCase.charAt(0).toUpperCase() + keyLowerCase.slice(1);
    const message = this.translocoService.translate('translations.dialog.title.reviewsBulk' + upperCaseFirstLetter);
    const config = {};
    return this.dialogService.showConfirmDialog(message, this.translocoService, config);
  }

  private getCheckedMembersIds(): string[] {
    return this.checkedMembers.map((item) => item._id);
  }

  private getCheckedReviewUids(): string[] {
    return this.checkedMembers.filter((item) => !!item.reviewUid).map((item) => item.reviewUid);
  }

  private handleCloseAction(operationType: string): void {
    const dialogRef = this.dialog.open(CloseReviewsModalComponent, {
      width: '37.5rem',
      disableClose: true,
      maxHeight: '80vh',
      position: {
        top: '37vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: ['ptl-mat-dialog'],
      backdropClass: 'dialog-backdrop',
      data: {
        playlistSubmissionMode: this.playlistSubmissionMode,
        playlistId: this.playlistUid,
      },
    });

    const dialogSubscription = dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (data.confirmed) {
          if (data.reviewCloseReasonSettings) {
            data.reviewCloseReasonSettings.reviewUids = this.getCheckedReviewUids();
          }
          const closeResponse = this.handleCloseWithAcceptance(data.reviewCloseReasonSettings, data.withAcceptance);
          closeResponse.subscribe((value) => {
            if (value) {
              this.reviewersChanged.emit();
            }
          });
        }
      }
      dialogSubscription.unsubscribe();
    });
  }

  openEditRoleDialog(): void {
    if (!this.checkedMembers.length) {
      return;
    }

    this.ngZone.run(() => {
      const dialogRef = this.dialog.open(EditRoleDialogComponent, {
        width: '90vw',
        minWidth: '15.625rem',
        maxWidth: '46.875rem',
        maxHeight: '80vh',
        restoreFocus: true,
        position: {
          top: '10vh',
        },
        direction: LanguageCodeHelper.getBodyLanguageDir(),
        panelClass: 'ptl-mat-dialog',
        backdropClass: 'dialog-backdrop',
        data: {
          checkedMemberIds: this.getCheckedMembersIds(),
        },
      });
      dialogRef.componentInstance.refreshList.subscribe(() => this.reviewersChanged.emit());
      const dialogSubscription = dialogRef.afterClosed().subscribe(() => {
        dialogSubscription.unsubscribe();
      });
    });
  }

  openEditGroupDialog(): void {
    if (!this.checkedMembers.length) {
      return;
    }

    this.ngZone.run(() => {
      const dialogRef = this.dialog.open(EditGroupDialogComponent, {
        width: '90vw',
        minWidth: '15.625rem',
        maxWidth: '46.875rem',
        maxHeight: '80vh',
        restoreFocus: true,
        position: {
          top: '10vh',
        },
        direction: LanguageCodeHelper.getBodyLanguageDir(),
        panelClass: 'ptl-mat-dialog',
        backdropClass: 'dialog-backdrop',
        data: {
          checkedMemberIds: this.getCheckedMembersIds(),
        },
      });
      dialogRef.componentInstance.refreshList.subscribe((value) => this.reviewersChanged.emit());
      const dialogSubscription = dialogRef.afterClosed().subscribe(() => {
        dialogSubscription.unsubscribe();
      });
    });
  }

  invokeEventAction(actionType: AdminMembersActionType): void {
    if (!this.checkedMembers.length) {
      return;
    }

    if (this.isEvent) {
      this.memberActionTriggered.emit({
        type: actionType,
        memberIds: this.getCheckedMembersIds(),
        allMembersRefresh: false,
      });
    }
  }

  openAlumniUploadCsv(): void {
    this.alumniUploadDialogRef = this.dialog.open(AlumniCsvUploadDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '46.875rem',
      maxHeight: '80vh',
      restoreFocus: true,
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
    });
    this.alumniUploadDialogRef.componentInstance.alumniUploadFinished.subscribe((value) => this.csvFileUploadFinished.emit(value));
  }

  openUsersUploadCsv(): void {
    this.usersUploadDialogRef = this.dialog.open(UsersCsvUploadDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '46.875rem',
      maxHeight: '80vh',
      restoreFocus: true,
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
    });
    this.usersUploadDialogRef.componentInstance.usersUploadFinished.subscribe((value) => this.triggerUserImport.emit(value));
  }

  private handleCloseWithAcceptance(closeReviewData: ReviewAdminRequest, withAcceptance: boolean): ObservableResult<void> {
    return this.reviewsDataService.closeReview(closeReviewData, withAcceptance);
  }

  private checkIfUserCanViewExports(isAdmin: boolean, isSuperAdmin: boolean): boolean {
    return isAdmin || isSuperAdmin;
  }

  openCheckInDialog(): void {
    const start = this.resource.tickets ? this.resource.tickets.time.start : null;
    const end = this.resource.tickets ? this.resource.tickets.time.end : null;
    this.dialog.open(CheckInDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '46.875rem',
      maxHeight: '80vh',
      restoreFocus: true,
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
      data: {
        eventTitle: this.resource.content.headline,
        eventUrl: this.getEventUrl(),
        eventStartDate: start ? new Date(start.split(',')[0]) : null,
        eventEndDate: end ? new Date(end.split(',')[0]) : null,
      },
    });
  }

  private getEventUrl(): string {
    const publisherUri = this.activatedRoute.snapshot.paramMap.get('publisherUri');
    const packageUri = this.activatedRoute.snapshot.paramMap.get('packageUri');
    const playlistUri = this.activatedRoute.snapshot.paramMap.get('playlistUri');
    const resourceUri = this.activatedRoute.snapshot.paramMap.get('resourceUri');
    const params = {
      publisherUri: publisherUri,
      packageUri: packageUri,
      playlistUri: playlistUri,
      resourceUri: resourceUri,
      pageNumberUri: 'page/1',
    };
    if (this.resource.participationCode) {
      params['queryUriParam'] = `?code=${this.resource.participationCode}`;
    }
    const eventUri = RedirectHelper.getRedirectUrl(this.activatedRoute, params, 'EVENT');
    return `https://${this.orgDomain}${eventUri}`;
  }

  openSendMessageDialog(): void {
    this.ngZone.run(() => {
      this.dialog.open(SendMessageDialogComponent, {
        width: '90vw',
        minWidth: '15.625rem',
        maxWidth: '46.875rem',
        maxHeight: '80vh',
        restoreFocus: true,
        position: {
          top: '10vh',
        },
        direction: LanguageCodeHelper.getBodyLanguageDir(),
        panelClass: 'ptl-mat-dialog',
        backdropClass: 'dialog-backdrop',
        data: {
          checkedMemberIds: this.getCheckedMembersIds(),
          query: this.booleanSearchRequest.query,
          allMembers: this.totalMembersCount === this.allMembersCount,
          filteredCount: this.totalMembersCount,
          playlistUid: this.playlistUid,
          cardUid: this.cardUid,
        },
      });
    });
  }
}
