/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

export interface UserGroups {
  title: string;

  _id: string;

  organizationId: string;

  memberCount: number;

  type: string;

  lastUpdatedOn: Date;

  // front-end specific
  isEditing?: boolean;
}

export interface MemberGroups {
  id: string;
  name: string;
  type?: string;
}

export const NotDisplayableGroupTypes = ['STAFF', 'STUDENTS', 'STANDARD'];

export interface UserGroupMember {
  firstName: string;
  lastName: string;
  email: string;
  uid: string;
}

export interface CreateUserGroups {
  title: string;
}

export interface UserGroupUploadResult {
  processedUsers: string[];
  failedUsers: string[];
  successCount: number;
  failureCount: number;
}
