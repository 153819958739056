/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { CompetenceDataService } from './competence-data.service';
import {
  AddCompetenceLevelRequest,
  CompetenceLevel,
  CompetenceModel,
  CreateCompetenceRequest,
} from '../../models/competence/competence.model';
import { ObservableResult } from '../../store';
import { DEFAULT_LANGUAGE_CODE } from '../languages/language.service';

@Injectable()
export class MockCompetenceDataService implements CompetenceDataService {
  competence: CompetenceModel = {
    _id: '123',
    creatorUid: 'someUid1',
    organizationUid: 'someOrgUid2',
    global: true,
    title: { languageCode: DEFAULT_LANGUAGE_CODE, value: 'Some-Competence-Title' },
    levels: [
      {
        uid: '333',
        title: [
          { languageCode: DEFAULT_LANGUAGE_CODE, value: 'Some' },
          { languageCode: DEFAULT_LANGUAGE_CODE, value: 'Competence' },
          { languageCode: DEFAULT_LANGUAGE_CODE, value: 'Title' },
        ],
        description: [{ languageCode: DEFAULT_LANGUAGE_CODE, value: 'Some description for Competence' }],
      },
    ],
  };

  createCompetence(request: CreateCompetenceRequest): ObservableResult<CompetenceModel> {
    return ObservableResult.ofSuccess(this.competence);
  }

  updateCompetence(competenceUid: string, request: CreateCompetenceRequest): ObservableResult<CompetenceModel> {
    return ObservableResult.ofSuccess(this.competence);
  }

  addCompetenceLevel(competenceUid: string, request: AddCompetenceLevelRequest): ObservableResult<CompetenceModel> {
    return ObservableResult.ofSuccess(this.competence);
  }

  updateCompetenceLevel(
    competenceUid: string,
    competenceLevelUid: string,
    request: AddCompetenceLevelRequest,
  ): ObservableResult<CompetenceLevel> {
    return ObservableResult.ofSuccess(this.competence.levels[0]);
  }

  getCompetenceById(competenceUid: string): ObservableResult<CompetenceModel> {
    return ObservableResult.ofSuccess(this.competence);
  }

  getOrganizationCompetenciesIncludingGlobal(global: boolean): ObservableResult<CompetenceModel[]> {
    return ObservableResult.ofSuccess([this.competence]);
  }

  connectCompetenceToTag(tagUid: string, competencyUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
