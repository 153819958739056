<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="trait-chart__content">
  <ng-container *ngIf="!showStaticData && piReport">
    <div class="trait-chart__circle-content">
      <div class="trait-chart__circle-core"></div>
      <div class="trait-chart__circle-comfort"></div>
      <div class="trait-chart__circle-stretch"></div>
    </div>

    <div class="trait-chart__content-inner">
      <div class="trait-chart__core-content">
        <div class="trait-chart__core-inner">
          <div
            class="trait-chart__items-list"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.thisIsCoreTraits' | transloco"
          >
            <div class="trait-chart__item" *ngFor="let coreTrait of chartData?.highlyDevelopedTraits">
              <div class="trait-chart__item-info">
                <span class="trait-chart__item-circle"></span>
                <span class="trait-chart__item-name" tabindex="0">{{ coreTrait.name | upperFirst }}</span>
              </div>
              <div class="trait-chart__item-description">
                <div class="trait-chart__item-description-title">{{ coreTrait.name | upperFirst }}</div>
                <div class="trait-chart__item-short-description" [innerHTML]="coreTrait.shortDescription[0].value"></div>
              </div>
            </div>
          </div>
          <div class="trait-chart__core-title">{{ 'translations.pi.chart.trait.label.core' | transloco }}</div>
        </div>
      </div>

      <div class="trait-chart__comfort-content">
        <div class="trait-chart__comfort-inner">
          <div
            class="trait-chart__items-list"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.thisIsComfortTraits' | transloco"
          >
            <div class="trait-chart__item" *ngFor="let comfortTrait of chartData?.coreTraits">
              <div class="trait-chart__item-info">
                <span class="trait-chart__item-circle"></span>
                <span class="trait-chart__item-name" tabindex="0">{{ comfortTrait.name | upperFirst }}</span>
              </div>
              <div class="trait-chart__item-description">
                <div class="trait-chart__item-description-title">{{ comfortTrait.name | upperFirst }}</div>
                <div class="trait-chart__item-short-description" [innerHTML]="comfortTrait.shortDescription[0].value"></div>
              </div>
            </div>
          </div>
          <div class="trait-chart__comfort-title">{{ 'translations.pi.chart.trait.label.comfort' | transloco }}</div>
        </div>
      </div>

      <div class="trait-chart__stretch-content">
        <div class="trait-chart__stretch-inner">
          <div
            class="trait-chart__items-list"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.thisIsStretchTraits' | transloco"
          >
            <div class="trait-chart__item" *ngFor="let stretchTrait of chartData?.lessDevelopedTraits">
              <div class="trait-chart__item-info">
                <span class="trait-chart__item-circle" tabindex="0"></span>
                <span class="trait-chart__item-name">{{ stretchTrait.name | upperFirst }}</span>
              </div>
              <div class="trait-chart__item-description">
                <div class="trait-chart__item-description-title">{{ stretchTrait.name | upperFirst }}</div>
                <div class="trait-chart__item-short-description" [innerHTML]="stretchTrait.shortDescription[0].value"></div>
              </div>
            </div>
          </div>
          <div class="trait-chart__stretch-title">{{ 'translations.pi.chart.trait.label.stretch' | transloco }}</div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="trait-chart__static-content" *ngIf="showStaticData">
    <img src="../assets/charts/trait.png" alt="trait chart" />
  </div>
</div>

<div class="trait-chart__tabs-container" *ngIf="!showStaticData && piReport">
  <div class="trait-chart__tabs">
    <div
      class="trait-chart__tab-item core-tab-item"
      [class.active]="activeTab === 'CORE'"
      tabindex="0"
      [attr.aria-label]="'translations.global.ariaLabels.showCoreTraits' | transloco"
      (click)="showChartData('CORE')"
      (keydown.enter)="showChartData('CORE')"
    >
      <mat-icon aria-hidden="true">favorite_border</mat-icon>
      <span>{{ 'translations.pi.chart.trait.tab.core' | transloco }}</span>
    </div>
    <div
      class="trait-chart__tab-item conform-tab-item"
      tabindex="0"
      [class.active]="activeTab === 'COMFORT'"
      [attr.aria-label]="'translations.global.ariaLabels.showComfortZone' | transloco"
      (click)="showChartData('COMFORT')"
      (keydown.enter)="showChartData('COMFORT')"
    >
      <mat-icon aria-hidden="true">thumb_up</mat-icon>
      <span>{{ 'translations.pi.chart.trait.tab.comfort' | transloco }}</span>
    </div>
    <div
      class="trait-chart__tab-item stretch-tab-item"
      [class.active]="activeTab === 'STRETCH'"
      tabindex="0"
      [attr.aria-label]="'translations.global.ariaLabels.showStretchTraits' | transloco"
      (click)="showChartData('STRETCH')"
      (keydown.enter)="showChartData('STRETCH')"
    >
      <mat-icon aria-hidden="true">fitness_center</mat-icon>
      <span>{{ 'translations.pi.chart.trait.tab.stretch' | transloco }}</span>
    </div>
  </div>
  <div class="trait-chart__tab-descriptions">
    <div class="trait-chart__tab-description-item" *ngIf="activeTab === 'CORE'">
      <h1 class="static-title" tabindex="0">{{ 'translations.pi.chart.trait.tab.core' | transloco }}</h1>
      <div class="static-description" tabindex="0">{{ 'translations.pi.chart.trait.description.core' | transloco }}</div>
    </div>

    <div class="trait-chart__tab-description-item" *ngIf="activeTab === 'COMFORT'">
      <h1 class="static-title" tabindex="0">{{ 'translations.pi.chart.trait.tab.comfort' | transloco }}</h1>
      <div class="static-description" tabindex="0">{{ 'translations.pi.chart.trait.description.comfort' | transloco }}</div>
    </div>

    <div class="trait-chart__tab-description-item" *ngIf="activeTab === 'STRETCH'">
      <h1 class="static-title" tabindex="0">{{ 'translations.pi.chart.trait.tab.stretch' | transloco }}</h1>
      <div class="static-description" tabindex="0">{{ 'translations.pi.chart.trait.description.stretch' | transloco }}</div>
    </div>

    <hr class="separator" />

    <div class="trait-chart__tab-description-item" *ngFor="let trait of activeTabChartData">
      <h2 class="title" tabindex="0">{{ trait.name | upperFirst }}</h2>
      <ptl-resource-preview class="description" [previewContent]="trait.description"> </ptl-resource-preview>
    </div>
  </div>
</div>
