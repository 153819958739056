import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LearnerFormAnswer } from '../../../../../page-modules/resource/models';
import { ConnectedTextBoxFormContent, Form, MediaUrlData, UserPlaylistSubmissionSummary } from '../../../../models';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ContentHelper } from '../../../../helpers/content-helper';

@Component({
  selector: 'ptl-form-preview-connected-textbox',
  templateUrl: './form-connected-textbox.component.html',
  styleUrls: ['./form-connected-textbox.component.scss'],
})
export class FormConnectedTextboxComponent implements OnInit, OnDestroy {
  @Input() formData: Form<ConnectedTextBoxFormContent>;
  @Input() learnerFormAnswer: LearnerFormAnswer;
  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;

  existingUserAnswer: string;
  submitButtonEnabled: boolean;
  submitted = false;

  inputFocused: boolean;
  isCheckboxForm = false;
  isCheckboxChecked: boolean;

  private autoSaveSubscription: Subscription;
  private autoSaveSubject = new Subject<void>();

  constructor() {
    this.autoSaveSubscription = this.autoSaveSubject.pipe(debounceTime(500)).subscribe();
  }

  ngOnInit() {
    if (this.formData && (this.formData.content as ConnectedTextBoxFormContent).userAnswer) {
      const answer = (this.formData.content as ConnectedTextBoxFormContent).userAnswer;

      this.isCheckboxForm = answer === 'true' || answer === 'false';
      if (this.isCheckboxForm) {
        this.isCheckboxChecked = answer === 'true';
      }
      const content = this.formData.content as ConnectedTextBoxFormContent;
      if (content.formIsActive) {
        this.inputFocused = true;
      }
      if (content.userAnswer.length > 0) {
        this.existingUserAnswer = JSON.parse(JSON.stringify(content.userAnswer));
        this.submitButtonEnabled = true;
        this.submitted = this.userPlaylistSubmissionSummary?.submittedOn ? true : content.submitted;
      }
    }
  }

  get submittedAnswerContent() {
    return {
      content: this.existingUserAnswer,
      type: 'PARAGRAPH',
    } as Pick<MediaUrlData, 'position' | 'type'> | undefined;
  }

  get userAnswerText(): string {
    if (this.existingUserAnswer) {
      return ContentHelper.stripHtmlTags(this.existingUserAnswer);
    } else {
      return '';
    }
  }

  ngOnDestroy() {
    this.autoSaveSubscription.unsubscribe();
  }
}
