/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { EmbeddableContentDetails } from '../../../editor/services/embeds-content-generator.service';
import { cloneDeep } from 'lodash-es';

interface SpecialWebSite {
  baseDomains: string[];
  protocol: string;
  contentDetails: EmbeddableContentDetails;
  correctUrl: (url: string) => string;
}

const SPECIAL_WEBSITES: SpecialWebSite[] = [
  {
    baseDomains: ['blackboard.com', 'bbcollab.com'],
    protocol: 'https://',
    contentDetails: {
      authorName: 'Blackboard Collaborate',
      logoUrl: 'assets/embed-images/bbcollaborate-logo.png',
      url: 'https://eu.bbcollab.com',
      title: 'Blackboard Collaborate',
      description: 'A simple and reliable virtual classroom solution to power ' + 'your online teaching and web conferencing needs.',
      thumbnailUrl: 'assets/embed-images/bbcollaborate-thumb.png',
      tags: ['blackboard', 'collaborate', 'online learning', 'virtual classroom'],
      publicationDate: new Date(),
      siteName: 'Blackboard Collaborate',
      html: '',
    },
    correctUrl: (url) => url,
  },
  {
    baseDomains: ['docs.google.com'],
    protocol: 'https://',
    contentDetails: {
      authorName: 'Google',
      logoUrl: null,
      url: null,
      title: 'Google Docs',
      description: null,
      thumbnailUrl: null,
      tags: ['Google', 'online documents', 'Google disk', 'Google docs'],
      publicationDate: new Date(),
      siteName: 'Google Docs',
      html: '',
    },
    correctUrl: (url) => {
      if (url.match(/^https:\/\/docs\.google\.com\/(document|spreadsheets?|presentation|forms?)\//)) {
        const link = url.split(/[#?]/)[0];
        const index = link.lastIndexOf('/edit');

        if (index !== -1) {
          return link.substring(0, index) + '/preview';
        } else if (!link.endsWith('/preview') && !link.endsWith('/viewform')) {
          return link + (link.endsWith('/') ? '' : '/') + (link.startsWith('https://docs.google.com/forms/') ? 'viewform' : 'preview');
        }

        return link;
      }

      return url;
    },
  },
];

export class SpecialEmbedsHelper {
  static correctUrl(url: string): string {
    const searchResult = this.findSpecialWebsite(url);
    return searchResult?.specialWebsite
      ? searchResult.specialWebsite.correctUrl(searchResult.specialWebsite.protocol + searchResult.domain + searchResult.path)
      : url;
  }

  static generateEmbedFromUrl(url: string): EmbeddableContentDetails | undefined {
    const searchResult = this.findSpecialWebsite(url);
    const specialWebsite = searchResult?.specialWebsite;

    if (specialWebsite && searchResult) {
      const domain = searchResult.domain;
      const path = searchResult.path;
      const protocol = specialWebsite.protocol;
      const result = cloneDeep(specialWebsite.contentDetails);

      result.url = protocol + domain + path;

      const embedElement = document.createElement('div');
      embedElement.style.display = 'inline-block';
      embedElement.style.width = '100%';
      embedElement.style.height = '12.5rem';
      embedElement.style.backgroundColor = 'white';
      embedElement.style.textAlign = 'left';

      const linkElement = document.createElement('a');
      linkElement.href = result.url;
      linkElement.target = '_self';
      linkElement.title = result.title;
      linkElement.style.display = 'initial';
      linkElement.style.overflow = 'initial';

      const imageElement = document.createElement('img');
      imageElement.alt = 'Thumbnail image';
      imageElement.style.objectFit = 'contain';
      imageElement.style.maxWidth = '50%';
      imageElement.style.height = '100%';
      imageElement.style.float = 'left';
      imageElement.style.marginRight = '1.25rem';
      imageElement.src = result.thumbnailUrl ?? '';

      const titleLinkElement = document.createElement('a');
      titleLinkElement.href = result.url;
      titleLinkElement.target = '_self';
      titleLinkElement.innerText = result.title;
      titleLinkElement.title = result.title;
      titleLinkElement.style.display = 'block';
      titleLinkElement.style.margin = '0';
      titleLinkElement.style.padding = '1.875rem 0.625rem 0.938rem 0';
      titleLinkElement.style.color = 'inherit';
      titleLinkElement.style.textDecoration = 'inherit';
      titleLinkElement.style.fontWeight = 'bold';
      titleLinkElement.style.fontSize = '1.063rem';
      titleLinkElement.style.overflow = 'hidden';
      titleLinkElement.style.textOverflow = 'ellipsis';
      titleLinkElement.style.whiteSpace = 'nowrap';

      const descriptionElement = document.createElement('p');
      descriptionElement.innerText = result.description ?? '';
      descriptionElement.style.fontSize = '0.938rem';
      /* eslint-disable */
      descriptionElement.style.wordWrap = 'break-word';
      /* eslint-enable */
      descriptionElement.style.margin = '0';
      descriptionElement.style.padding = '0 0.625rem 0.938rem 0';
      descriptionElement.style.overflow = 'hidden';
      descriptionElement.style.maxHeight = '3.75rem';

      const logoLinkElement = document.createElement('a');
      logoLinkElement.href = result.url;
      logoLinkElement.target = '_self';
      logoLinkElement.innerText = result.title;
      logoLinkElement.title = result.title;
      logoLinkElement.style.display = 'inline-block';
      logoLinkElement.style.overflow = 'initial';
      logoLinkElement.style.textDecoration = 'inherit';
      logoLinkElement.style.color = '#999';
      logoLinkElement.style.fontSize = '0.875rem';
      logoLinkElement.style.overflow = 'hidden';
      logoLinkElement.style.textOverflow = 'ellipsis';
      logoLinkElement.style.whiteSpace = 'nowrap';
      logoLinkElement.style.maxWidth = '30%';
      logoLinkElement.style.height = '1rem';
      logoLinkElement.style.lineHeight = '1.188rem';

      const logoImageElement = document.createElement('img');
      logoImageElement.alt = 'Logo image';
      logoImageElement.style.objectFit = 'contain';
      logoImageElement.style.width = '1rem';
      logoImageElement.style.height = '1rem';
      logoImageElement.style.marginRight = '0.313rem';
      logoImageElement.style.verticalAlign = 'top';
      logoImageElement.src = result.logoUrl ?? '';

      embedElement.appendChild(linkElement);
      embedElement.appendChild(titleLinkElement);
      embedElement.appendChild(descriptionElement);
      embedElement.appendChild(logoLinkElement);

      linkElement.appendChild(imageElement);
      logoLinkElement.prepend(logoImageElement);

      result.html = embedElement.outerHTML;
      return result;
    } else {
      return undefined;
    }
  }

  private static findSpecialWebsite(url: string): { specialWebsite?: SpecialWebSite; domain: string; path?: string } | undefined {
    const match = url.match(/^(https?:\/\/)?(www\.)?(\b[\w.-]+\b)(\/.*)?$/);
    const domain = match ? match[3]?.toLowerCase() : '';
    const path = match ? (match[4] ? match[4] : '') : undefined;

    const specialWebsite = SPECIAL_WEBSITES.find((website) => {
      return !!domain && !!website.baseDomains.find((baseDomain) => domain.endsWith(baseDomain));
    });

    return { specialWebsite, domain, path };
  }
}
