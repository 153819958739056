/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { Injectable } from '@angular/core';
import { PIDataService } from './pi-data.service';
import { PIQuestionData, PIReport, PIReportResponse, PISummary } from '../../models/pi/pi.model';

@Injectable()
export class MockPIDataService implements PIDataService {
  private piSummary: PISummary = {
    retake: false,
    lastVersion: 1,
    indicatorId: '123',
    completed: false,
    completedOn: new Date(),
    startedOn: new Date(),
    completions: [
      {
        version: 1,
        completedOn: new Date(),
      },
    ],
    inProgress: true,
  };

  loadPISummary(resourceUid: string): ObservableResult<PISummary> {
    return ObservableResult.ofSuccess(this.piSummary);
  }

  getPIQuestion(resourceUid: string, version: number): ObservableResult<PIQuestionData> {
    return ObservableResult.ofSuccess(createPiQuestionData());
  }

  addPIQuestionAnswer(resourceUid: string, version: number, request: PIQuestionData): ObservableResult<PIQuestionData> {
    return ObservableResult.ofSuccess(createPiQuestionData());
  }

  getPIReports(resourceUid: string): ObservableResult<PIReportResponse> {
    return ObservableResult.ofSuccess();
  }

  getPIReportByVersion(resourceUid: string, version: number): ObservableResult<PIReport> {
    return ObservableResult.ofSuccess();
  }
}

export function createPiQuestionData(): PIQuestionData {
  return {
    id: '123',
    indicatorUid: '123',
    userUid: '123',
    progress: {
      completed: 10,
      outOf: 50,
    },
    questionResponse: {
      question: {
        type: 'CIRCLE',
        questionId: '123',
        instructions: [],
        options: ['value 1', 'value 2'],
      },
      response: undefined,
    },
  };
}
