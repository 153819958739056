/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, Input, NgZone, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CloseMobileNavMenu } from '../../../app-frame/store/app-frame.actions';
import { RedirectCardType, RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { LanguageCodeHelper } from '../../helpers/language-code-helper';
import { SidebarTreeHelper } from '../../helpers/sidebar-tree/sidebar-tree-helper';
import { SidebarCard } from '../../models/sidebar-tree/sidebar-tree.model';
import { SHARED_CARD_DATA_SERVICE, SharedCardDataService } from '../../services/card/card-data.service';
import { SIDEBAR_TREE_DATA_SERVICE, SidebarTreeDataService } from '../../services/sidebar-tree/sidebar-tree-data.service';
import { ProjectHelper } from '../../../page-modules/project/helpers/project.helper';
import { ADMIN_DATA_SERVICE, AdminDataService } from '../../../page-modules/admin/services/data.service';

import { ExploreHelper } from '../../../page-modules/explore/helpers/explore.helper';
import { TranslationHelper } from '@app/app/shared/helpers/translation-helper';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ptl-side-nav-tree-item',
  templateUrl: './side-nav-tree-item.component.html',
  styleUrls: ['./side-nav-tree-item.component.scss'],
})
export class SideNavTreeItemComponent implements OnInit {
  @Select(UserAuthState.homePageUri)
  homePageUri$: Observable<string>;

  @Input() cards: SidebarCard[];

  @Input() level: number;

  @Input() stackView: boolean;

  @Input() isExploreSideNav: boolean;

  @Output() sidebarItemClicked = new EventEmitter<{ card: SidebarCard; level: number }>();

  cardsExpanded: boolean;
  languageDirection = LanguageCodeHelper.getBodyLanguageDir();
  categoryFilter: string;

  private homePageUri;

  constructor(
    private store: Store,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService,
    @Inject(SHARED_CARD_DATA_SERVICE) private cardService: SharedCardDataService,
    @Inject(SIDEBAR_TREE_DATA_SERVICE) private sidebarTreeService: SidebarTreeDataService,
    @Inject(ADMIN_DATA_SERVICE) private adminDataService: AdminDataService,
  ) {}

  ngOnInit(): void {
    if (this.cards) {
      this.translateSideNavCards(this.cards);
    }
    this.homePageUri$.pipe(filter((data) => !!data)).subscribe((data) => (this.homePageUri = data));
  }

  getPadding(level: number): string {
    if (this.stackView) {
      return '1.25rem';
    }
    return 1.25 * level + 'rem';
  }

  isActiveOrExpanded(card: SidebarCard): boolean {
    if (
      (card.type === 'HOME_PAGE' && this.router?.url === '/') ||
      (card.formattedUri.endsWith(this.homePageUri) && this.router?.url?.endsWith(this.homePageUri))
    ) {
      return true;
    }
    if (ProjectHelper.isProjectByUrl(this.router)) {
      if (card.type === 'PROJECT') {
        return this.router.isActive(card.formattedUri, true);
      } else {
        return location.pathname.indexOf(card.formattedUri) !== -1;
      }
    }

    if (card.type === 'CATEGORY') {
      const categoryUIds = ExploreHelper.getCategoriesFromUrl(this.route.snapshot);
      if (categoryUIds?.length) {
        const lastCategory = categoryUIds[categoryUIds?.length - 1];
        if (!this.router.url.includes('/playlists/')) {
          if (card._id === lastCategory && categoryUIds?.length > 1 && categoryUIds.includes(card.parentId)) {
            return true;
          }
          if (categoryUIds?.length === 1 && card._id === lastCategory) {
            return true;
          }
        }
      }
    }

    if (card.type === 'PLAYLIST' && this.isExploreSideNav) {
      return this.getRelativeUrl() === card.formattedUri;
    }

    let url = card.formattedUri;
    if (location.pathname.startsWith('/my-content')) {
      url = '/my-content' + url;
    }
    if (url.startsWith('/pub/') && !this.router?.url.startsWith('/pub/')) {
      const routerUrl = this.router?.url?.replace(/\/page\/\d+/, '');
      return url.endsWith(routerUrl);
    }
    const exact = !(url.indexOf('/playlists/') !== -1 && url.indexOf('/cards/') !== -1);
    return this.router.isActive(url, exact);
  }

  private getRelativeUrl(): string {
    const fullUrl = window.location.href;
    const url = new URL(fullUrl);
    const relativeUrl = url.pathname.substring(1) + url.search;
    return relativeUrl;
  }

  onSideBarNestedItemClicked(event): void {
    this.sidebarItemClicked.emit(event);
  }

  onExpandTree(card: SidebarCard, level: number): void {
    if (!card.cards?.length && card.hasChild) {
      SidebarTreeHelper.loadChildData(
        this.sidebarTreeService,
        card,
        () => {
          this.sidebarItemClicked.emit({ card: card, level: level });
          card.expanded = !card.expanded;
        },
        this.adminDataService,
      );
    } else {
      this.sidebarItemClicked.emit({ card: card, level: level });
      card.expanded = !card.expanded;
    }
  }

  onSideBarItemClicked(card: SidebarCard, cards: SidebarCard[], level: number): void {
    if (this.stackView) {
      this.cardsExpanded = false;
      this.handleShowHideSiblings(card, cards);
      if (card.expanded) {
        card.expanded = false;
      }
      this.sidebarItemClicked.emit({ card: card, level: level });
    } else {
      card.expanded = true;
    }

    if ((!card.cards?.length && card.formattedUri.includes('explore/category')) || (!card.cards?.length && card.hasChild)) {
      if (card.formattedUri.includes('explore/category') && card.type === 'RESOURCE') {
        card.type = 'PLAYLIST';
      }
      SidebarTreeHelper.loadChildData(
        this.sidebarTreeService,
        card,
        () => {
          this.sidebarItemClicked.emit({ card: card, level: level });
        },
        this.adminDataService,
      );
    }
    if (window.innerWidth <= 900) {
      this.store.dispatch(new CloseMobileNavMenu());
    }
    this.redirectTo(card);
  }

  onSideBarSiblingsExpand(cards: SidebarCard[]): void {
    this.cardsExpanded = !this.cardsExpanded;
    // removing last /page/${number} for location
    const locationFormattedUri = location.pathname.replace(/\/page\/\d+(?!.+\/page\/\d+)/, '');

    const visibleItems = this.findFourSiblingItemsByUri(locationFormattedUri, cards);

    for (let i = 0; i < cards.length; i++) {
      const item = cards[i];
      if (!this.cardsExpanded) {
        if (visibleItems.includes(i)) {
          item.class = '';
        } else {
          item.class = 'is_hidden';
        }
      } else {
        item.class = '';
      }
    }
  }

  private redirectTo(card: SidebarCard): void {
    let navigationUrl: string;
    if (card.type === 'HOME_PAGE') {
      navigationUrl = '/';
    } else if (card.type === 'PAGE') {
      navigationUrl = RedirectHelper.getRedirectUrl(
        this.route,
        {
          formattedUri: card.formattedUri,
        },
        'PAGE',
      );
    } else if (card.type === 'PLAYLIST') {
      navigationUrl = RedirectHelper.getRedirectUrl(
        this.route,
        {
          formattedUri: card.formattedUri,
        },
        'PLAYLIST',
      );
    } else if (card.type === 'WEBLINK') {
      this.cardService.registerCardOpen(card._id).subscribe(() => {
        RedirectHelper.redirectByUrl(this.ngZone, this.router, this.route, card.formattedUri, null, '_blank');
      });
      return;
    } else if (card.type === 'CATEGORY') {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.route, card.formattedUri);
      return;
    } else if (card.type === 'PROJECT') {
      const folioPublicId = this.route.snapshot.paramMap.get('folioPublicId');
      navigationUrl = RedirectHelper.getRedirectUrl(
        this.route,
        {
          folioPublicId: folioPublicId,
          formattedUri: card.formattedUri,
        },
        'PROJECT',
      );
    } else {
      const isProjectView = ProjectHelper.isProjectByUrl(this.router);
      const folioPublicId = this.route.snapshot.paramMap.get('folioPublicId');
      const queryUriParam = ExploreHelper.generateCategoryUidQueryString([this.extractQueryParam(card.formattedUri)]);
      const baseUrl = this.extractBaseUrl(card.formattedUri);
      navigationUrl = RedirectHelper.getRedirectUrl(
        this.route,
        {
          folioPublicId: folioPublicId,
          formattedUri: baseUrl,
          pageNumberUri: 'page/1',
          queryUriParam: queryUriParam,
        },
        isProjectView ? 'PROJECT_CARD' : (card.type as RedirectCardType),
      );
    }

    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.route, navigationUrl);
  }

  private extractBaseUrl(url: string): string {
    const baseUrl = url.split('?')[0];
    return baseUrl;
  }

  private extractQueryParam(url: string): string | null {
    const queryString = url.split('?')[1];
    if (!queryString) {
      return null;
    }
    const params = new URLSearchParams(queryString);
    return params.get('categoryUid');
  }

  private handleShowHideSiblings(card: SidebarCard, cards: SidebarCard[]): void {
    if (card.type === 'HOME_PAGE' || card.uri === this.homePageUri) {
      return;
    }

    if (card.type === 'PAGE') {
      if (cards && cards.length) {
        for (let i = 0; i < cards.length; i++) {
          const item = cards[i];
          if (item._id === card._id || item.type === 'HOME_PAGE') {
            item.class = '';
          } else {
            item.class = 'is_hidden';
          }
          if (item.cards && item.cards.length) {
            this.handleHideChildCards(item.cards);
          }
        }
      }
      return;
    }

    if (cards.length < 6) {
      cards.map((item) => (item.class = ''));
      return;
    }

    const visibleItems = this.findFourSiblingItemsByUri(card.formattedUri, cards);

    if (cards && cards.length) {
      for (let i = 0; i < cards.length; i++) {
        const item = cards[i];
        if (visibleItems.includes(i)) {
          item.class = '';
        } else {
          item.class = 'is_hidden';
        }
        if (item.cards && item.cards.length) {
          this.handleHideChildCards(item.cards);
        }
      }
    }
  }

  private handleHideChildCards(cards: SidebarCard[]): void {
    if (cards && cards.length) {
      for (const card of cards) {
        card.expanded = false;
        card.class = 'is_hidden';
        if (card.cards && card.cards.length) {
          this.handleHideChildCards(card.cards);
        }
      }
    }
  }

  private translateSideNavCards(cards: SidebarCard[]) {
    cards.map((card) => {
      if (card.titleId) {
        card.title = TranslationHelper.translateWithFallback(
          `translations.sideNav.title.${card.titleId}`,
          card.title,
          this.translocoService,
        );
      }
    });
  }

  private findFourSiblingItemsByUri(formattedUri: string, cards: SidebarCard[]): number[] {
    const index = cards.findIndex((card) => formattedUri === card.formattedUri);
    if (index === -1) {
      return []; // Item not found in the array
    }

    // Determine the indexes of the four siblings
    const numPrevious = Math.min(index, 2);
    const numNext = Math.min(cards.length - 1 - index, 2);

    const siblingIndexes: number[] = [];

    for (let i = index - numPrevious; i <= index + numNext; i++) {
      siblingIndexes.push(i);
    }

    return siblingIndexes;
  }
}
