/*
 * Copyright (C) 2021 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Organization, Playlist, ResourceTag, UserSummary } from '../../../../../shared/models';
import { CORE_PLAYLIST_DATA_SERVICE, PlaylistDataService } from '../../../../playlist/services/create/core/data.service';
import {
  ContentStoreCardData,
  ContentStorePlaylistSummary,
  ContentType,
  PageContentStoreSummary,
  SubmitContentStoreRequest,
} from '../../../../../shared/models/content-store/content-store.model';
import { SearchPage } from '../../../../../shared/models/page/page.model';
import { PAGES_DATA_SERVICE, PageDataService } from '../../../services/data.service';

@Component({
  selector: 'ptl-content-store-prepare-submit',
  templateUrl: './content-store-prepare-submit.component.html',
  styleUrls: ['./content-store-prepare-submit.component.scss'],
})
export class ContentStorePrepareSubmitComponent implements OnInit {
  @Input() playlist: Playlist;

  @Input() page: SearchPage;

  @Input() organization: Organization;

  @Input() contentType: ContentType;

  @Output() reviewContent = new EventEmitter<SubmitContentStoreRequest>();

  @Output() canceledSharePlaylist = new EventEmitter<void>();

  playlistSummary: ContentStorePlaylistSummary;
  contentCardInfo: ContentStoreCardData;
  pageSummary: PageContentStoreSummary;

  userSummary: UserSummary;
  frameworkPanelOpen = false;
  tags: ResourceTag[] = [];

  linkedPageContentExpandIndex: number;

  request: SubmitContentStoreRequest = {
    itemUid: '',
    isSensitive: false,
    permission: 'SYNDICATE_ONLY',
    type: undefined,
  };

  constructor(
    @Inject(CORE_PLAYLIST_DATA_SERVICE) private playlistService: PlaylistDataService,
    @Inject(PAGES_DATA_SERVICE) private pageDataService: PageDataService,
  ) {}

  ngOnInit() {
    if (this.contentType === 'PLAYLIST') {
      this.getSummaryOfPlaylist();
    } else if (this.contentType === 'PAGE') {
      this.getSummaryOfPage();
    }
  }

  cancelShare() {
    this.canceledSharePlaylist.emit();
  }

  submitShare() {
    this.reviewContent.emit(this.request);
  }

  expandLinkedPagesContent(index: number) {
    if (this.linkedPageContentExpandIndex === index) {
      this.linkedPageContentExpandIndex = -1;
    } else {
      this.linkedPageContentExpandIndex = index;
    }
  }

  addTag(tags: ResourceTag[]) {
    const newTags = tags.filter((tag) => !this.tags.some((existTag) => tag._id === existTag._id));

    this.tags = this.tags.concat(newTags);

    if (this.contentType === 'PLAYLIST') {
      newTags.forEach((tag) => {
        this.playlistService.associateTag(this.playlist._id, { tagUid: tag._id }).subscribe();
      });
    } else if (this.contentType === 'PAGE') {
      newTags.forEach((tag) => {
        this.pageDataService.addTag(this.page._id, tag._id).subscribe();
      });
    }
  }

  removeTag(index: number) {
    const tag = this.tags[index];

    this.tags.splice(index, 1);
    if (this.contentType === 'PLAYLIST') {
      this.playlistService.dissociateTag(this.playlist._id, tag._id).subscribe();
    } else if (this.contentType === 'PAGE') {
      this.pageDataService.removeTag(this.page._id, tag._id).subscribe();
    }
  }

  private getSummaryOfPlaylist() {
    this.userSummary = {
      firstName: this.playlist.authorDetails?.name,
      lastName: '',
      profileImage: this.playlist.authorDetails?.userImageUrl,
      email: '',
      _id: '',
    };

    this.request.itemUid = this.playlist.playlistId;
    this.request.type = 'PLAYLIST';
    this.playlistService.getPlaylistSummaryByUid(this.playlist.playlistId).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.playlistSummary = value;

        this.tags = this.playlistSummary.tags;
        this.setContentCardInfoFromPlaylist(this.playlistSummary);
      }
    });
  }

  private getSummaryOfPage() {
    this.request.itemUid = this.page._id;
    this.request.type = 'PAGE';

    this.pageDataService.getPageSummary(this.page._id).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.pageSummary = value;

        this.tags = this.pageSummary.tags;
        this.setContentCardInfoFromPage(this.pageSummary);
        this.userSummary = {
          firstName: this.pageSummary.authorDetails?.name,
          lastName: '',
          profileImage: this.pageSummary.authorDetails?.userImageUrl,
          email: '',
          _id: '',
        };
      }
    });
  }

  private setContentCardInfoFromPlaylist(summary: ContentStorePlaylistSummary) {
    this.contentCardInfo = {
      type: 'PLAYLIST',
      title: summary.title,
      subLine: summary.subheader,
      image: this.playlist.image,
      languages: summary.languages,
      thumbnail: this.playlist.thumbnail,
      badgesNumber: summary.badgesNumber,
      eventsNumber: summary.eventsNumber,
      resourcesNumber: summary.resourcesNumber,
      assessmentsNumber: summary.assessmentsNumber,
    };
  }

  private setContentCardInfoFromPage(summary: PageContentStoreSummary) {
    this.contentCardInfo = {
      type: 'PAGE',
      title: summary?.title,
      subLine: summary?.subHeader,
      image: summary.thumbnail,
      languages: summary.availableLanguages,
      thumbnail: summary?.thumbnail,
      playlistsNumber: summary?.playlistCount,
      pagesNumber: summary?.pageCount,
    };
  }
}
