/*
 * Copyright (C) 2021 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, Inject, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Organization, Playlist } from '../../../../shared/models';
import { take } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';
import {
  CONTENT_PACKAGE_DATA_SERVICE,
  ContentPackageDataService,
} from '../../../../shared/services/content-package/content-package-data.service';
import { ContentType, SubmitContentStoreRequest } from '../../../../shared/models/content-store/content-store.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarHelper } from '../../../../shared/helpers/snackbar-helper';
import { SearchPage } from '../../../../shared/models/page/page.model';

@Component({
  templateUrl: './content-store-add-dialog.component.html',
  styleUrls: ['./content-store-add-dialog.component.scss'],
})
export class ContentStoreAddDialogComponent {
  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  activePage = 0;
  selectedPlaylist: Playlist;
  selectedPage: SearchPage;
  contentType: ContentType;

  organization: Organization;

  constructor(
    private dialogRef: MatDialogRef<ContentStoreAddDialogComponent>,
    @Inject(CONTENT_PACKAGE_DATA_SERVICE) private contentPackageService: ContentPackageDataService,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
  ) {
    this.organizationData$.pipe(take(1)).subscribe((organization) => {
      this.organization = organization;
    });
  }

  onCloseDialog(reload = false) {
    this.dialogRef.close(reload);
  }

  onContentSelected(data: { content: Playlist | SearchPage; type: ContentType }) {
    if (data.type === 'PAGE') {
      this.selectedPage = data.content as SearchPage;
    } else {
      this.selectedPlaylist = data.content as Playlist;
    }
    this.contentType = data.type;
    this.setPage(1);
  }

  requestReviewContent(request: SubmitContentStoreRequest) {
    this.contentPackageService.submitPlaylist(request).subscribe(({ isSuccess, error }) => {
      if (isSuccess) {
        this.setPage(2);
      } else {
        SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
      }
    });
  }

  setPage(page: number) {
    this.activePage = page;
  }
}
