/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class DialogService {
  showConfirmDialog(content: string | HTMLElement, translocoService: TranslocoService, config?: ConfirmDialogConfig) {
    if (!config) {
      config = {};
    }
    return Swal.mixin({
      didOpen: (modal) => {
        const confirmBtn = modal.querySelector('.swal2-confirm') as HTMLInputElement | HTMLSelectElement;
        if (confirmBtn) {
          setTimeout(() => {
            confirmBtn.focus();
          }, 0);
        }
      },
    })
      .fire({
        text: content instanceof HTMLElement ? undefined : content,
        html: content instanceof HTMLElement ? content : undefined,
        icon: undefined,
        showCancelButton: true,
        reverseButtons: true,
        allowOutsideClick: false,
        closeButtonHtml: '<svg class="svg" role="img" aria-hidden="true"><use xlink:href="assets/sprite/sprite.svg#close"></use></svg>',
        showCloseButton: config.showCloseButton !== undefined && config.showCloseButton !== null ? config.showCloseButton : true,
        customClass: { popup: 'new-version f_inside-dialog' },
        cancelButtonText: config.cancelBtnText ? config.cancelBtnText : translocoService.translate('translations.global.button.cancel'),
        confirmButtonText: config.confirmBtnText ? config.confirmBtnText : translocoService.translate('translations.global.button.confirm'),
      })
      .then((result) => {
        return result.value;
      });
  }
}

export interface ConfirmDialogConfig {
  hideIcon?: boolean;
  showCloseButton?: boolean;
  confirmBtnText?: string;
  cancelBtnText?: string;
}
