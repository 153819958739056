import * as DOMPurify from 'dompurify';

export class HtmlSanitizer {
  static sanitizeEmbedCode(code: string): string {
    const attrs = [
      'allow',
      'allowfullscreen',
      'alt',
      'aria-hidden',
      'autoplay',
      'class',
      'controls',
      'decoding',
      'default',
      'frameborder',
      'height',
      'kind',
      'label',
      'loading',
      'loop',
      'muted',
      'playsinline',
      'poster',
      'preload',
      'sandbox',
      'scrolling',
      'sizes',
      'src',
      'srclang',
      'srcset',
      'type',
      'width',
    ];

    const tags = ['audio', 'embed', 'iframe', 'img', 'source', 'track', 'video'];

    // FORCE_BODY is required to prevent pushing script tags into <head>
    const config = { ADD_TAGS: tags, ADD_ATTR: attrs, FORCE_BODY: true, USE_PROFILES: { html: true } };

    return DOMPurify.sanitize(code, config);
  }

  static stripAllTags(userInput: string): string {
    return DOMPurify.sanitize(userInput, {
      ALLOWED_TAGS: [],
    });
  }
}
