/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { OrganizationsDataService, UpdateOrganizationResponse } from './data.service';
import { ObservableResult } from '../../../../../shared/store';
import { RestClientService } from '../../../../../shared/services/rest-client.service';
import { CreateOrganizationRequest, UpdateOrganizationRequest, StatusUpdateRequest } from '../models';
import { Organization, Playlist } from '../../../../../shared/models';
import { environment } from '../../../../../../environments/environment';
import { Location } from '@angular/common';
import { catchError, switchMap } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { Page } from '../../../../../shared/models/page';

export class ApiOrganizationDataService implements OrganizationsDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  createOrganization(request: CreateOrganizationRequest): ObservableResult<Organization> {
    return this.client.post<Organization>(Location.joinWithSlash(environment.apiRootUrl || '', 'organizations'), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorCreateOrganization'))),
    );
  }

  updateOrganization(id: string, request: UpdateOrganizationRequest): ObservableResult<UpdateOrganizationResponse> {
    return this.client.patch<Organization>(Location.joinWithSlash(environment.apiRootUrl || '', `organizations/${id}`), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(({ error }) => ObservableResult.ofError(error?.message)),
    );
  }

  addOwnerForOrganization(organizationId: string, ownerId: string): ObservableResult<Organization> {
    return this.client
      .post<Organization>(Location.joinWithSlash(environment.apiRootUrl || '', `organizations/${organizationId}/owner`), { ownerId })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAddOwnerToOrganization'))),
      );
  }

  loadOrganizations(): ObservableResult<Organization[]> {
    return this.client.get<Organization[]>(Location.joinWithSlash(environment.apiRootUrl || '', 'organizations')).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetOrganizations'))),
    );
  }

  updateOrganizationStatus(id: string, status: StatusUpdateRequest): ObservableResult<void> {
    return this.client.patch<void>(Location.joinWithSlash(environment.apiRootUrl || '', `organizations/${id}/status`), status).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdateOrganizationStatus'))),
    );
  }

  getOrganizationDetails(id: string): ObservableResult<Organization> {
    return this.client.get<Organization>(Location.joinWithSlash(environment.apiRootUrl || '', `organizations/${id}`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetOrganization'))),
    );
  }

  getGlobalPlaylists(term: string, page: number, size: number): ObservableResult<Page<Playlist>> {
    return this.client
      .get<Page<Playlist>>(Location.joinWithSlash(environment.apiRootUrl || '', `playlists/global?term=${term}&page=${page}&size=${size}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorSearchPlaylists'))),
      );
  }
}
