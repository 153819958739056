<div class="cover-image-upload-dialog-container">
  <div class="dialog-top-info">
    <span class="upload-header">{{ 'translations.folio.label.addImage' | transloco }}</span>
    <button
      class="close-dialog-btn"
      mat-icon-button
      color="primary"
      (click)="closeDialog()"
      [attr.aria-label]="'translations.global.ariaLabels.closeDialog'"
    >
      <svg class="svg" role="img" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>
  <div class="cover-image-tabs-container">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab class="upload-tabs">
        <ng-template mat-tab-label class="upload-tabs-single">
          <svg matPrefix class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.search' | transloco">
            <use ogSvgUnify="assets/sprite/sprite.svg#search"></use>
          </svg>
          {{ 'translations.global.ariaLabels.icons.search' | transloco }}
        </ng-template>
        <ptl-unsplash-images-search-box
          (photoSelected)="onPhotoSelected($event)"
          [initialPhrase]="'colorful background'"
        ></ptl-unsplash-images-search-box>
      </mat-tab>
      <mat-tab class="upload-tabs">
        <ng-template mat-tab-label class="upload-tabs-single">
          <svg matPrefix class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.upload' | transloco">
            <use ogSvgUnify="assets/sprite/sprite.svg#upload"></use>
          </svg>
          {{ 'translations.global.ariaLabels.icons.upload' | transloco }}
        </ng-template>
        <div
          class="upload-centered-container"
          ogFileDrop
          (filesDropped)="onFilesDropped($event)"
          (filesHovered)="filesHovered = $event"
          [class.upload-centered-container--active]="filesHovered"
        >
          <div class="upload-centered-button">
            <input
              #uploadFolioInput
              id="uploadFolioCover"
              type="file"
              class="file-input"
              [accept]="'image/*'"
              (change)="uploadUserImage($event)"
            />
            <button class="upload-browse-files" (click)="triggerUpload()" (keydown.enter)="triggerUpload()" mat-flat-button>
              <svg matPrefix class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.upload' | transloco">
                <use ogSvgUnify="assets/sprite/sprite.svg#upload"></use>
              </svg>
              <span>{{ 'translations.folio.label.browseFiles' | transloco }}</span>
            </button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
