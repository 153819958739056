<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<ng-container *ngIf="!!linkData">
  <mat-card
    [ngClass]="{
      'link-card': linkData.linkStyle === 'TEXT_COLOR_BACKGROUND',
      card: linkData.linkStyle !== 'TEXT_COLOR_BACKGROUND',
      'edit-view': type === 'EDIT',
      disabled: disabled,
    }"
    [class.link-card--view]="type === 'VIEW'"
    role="button"
    [class.link-card--slim]="size === 'SLIM'"
    [style.background]="linkData.linkStyle === 'TEXT_COLOR_BACKGROUND' ? linkData.backgroundColor : ''"
    (click)="type === 'EDIT' ? $event.stopPropagation() : openLink(false)"
    (keydown.enter)="type === 'EDIT' ? $event.stopPropagation() : openLink(false)"
    (contextmenu)="onContextMenu($event)"
    tabindex="0"
    [ngStyle]="{ cursor: inHomeLayoutEditor ? 'default' : '' }"
    [attr.aria-label]="
      'translations.global.ariaLabels.open'
        | transloco
          : {
              title: linkData.headline?.length ? linkData.headline : linkData.text,
            }
    "
  >
    <button
      mat-icon-button
      role="button"
      class="remove-btn"
      *ngIf="(linkData.destination === 'WEBLINK' || linkData.destination === 'PAGE') && type === 'EDIT' && !disabled"
      [attr.aria-label]="
        'translations.global.ariaLabels.remove'
          | transloco
            : {
                name: linkData.headline?.length ? linkData.headline : linkData.text,
              }
      "
      (click)="onRemoveClick($event)"
    >
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>

    <button
      mat-icon-button
      class="edit-btn"
      role="button"
      *ngIf="linkData.destination === 'WEBLINK' && type === 'EDIT' && !disabled"
      [attr.aria-label]="
        ('translations.global.ariaLabels.edit' | transloco) + ' ' + (linkData.headline?.length ? linkData.headline : linkData.text)
      "
      (click)="onEditClick()"
    >
      <mat-icon aria-hidden="true">edit</mat-icon>
    </button>

    <div
      class="context-menu"
      [ngStyle]="{ left: contextMenuPosition.x, top: contextMenuPosition.y }"
      [matMenuTriggerFor]="contextMenu"
    ></div>
    <mat-menu #contextMenu="matMenu">
      <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="openLink(true)">
          <span>{{ 'translations.openLinkInANewTab' | transloco }}</span>
        </button>
      </ng-template>
    </mat-menu>

    <div
      class="link-card__center"
      *ngIf="linkData.linkStyle === 'TEXT_COLOR_BACKGROUND'"
      [style.color]="linkData.fontColor"
      [style.font-size]="linkData.fontSize / 16 + 'rem'"
    >
      {{ linkData.text }}
    </div>

    <div *ngIf="linkData.linkStyle !== 'TEXT_COLOR_BACKGROUND'" [class.text-on-image]="linkData.linkStyle === 'TEXT_IMAGE_BACKGROUND'">
      <div class="card__center">
        <img
          class="center__image"
          alt=""
          width="266"
          height="150"
          [src]="
            linkData.previewImage
              ? linkData.previewImage
              : linkData.thumbnail
                ? linkData.thumbnail
                : '../../../../assets/card-placeholders/card-placeholder-' + ((index % 4) + 1) + '.png'
          "
        />
      </div>
      <div class="card-info-box">
        <div class="card__header-box">
          <span class="card__header small bold">
            {{ linkData.headline }}
          </span>
        </div>
        <div class="card__description extra-small">
          {{ linkData.subLine }}
        </div>
      </div>
    </div>

    <div class="disabled-overlay" *ngIf="disabled"></div>
  </mat-card>
</ng-container>
