import { InjectionToken } from '@angular/core';
import { ToastInfo } from '../../models/toast-notifications/toast-info';
import { PushNotificationMessage } from '../../models/websocket/websocket.model';

export const TOAST_NOTIFICATION_SERVICE = new InjectionToken<ToastService>('[resource] ToastService');

export interface ToastService {
  showPushNotification(pushNotification: PushNotificationMessage): void;
  showSuccess(message: string): void;
  showFail(message: string): void;
  remove(createdAt: Date): void;
  getToastList(): ToastInfo[];
}
