/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import {
  CreateExternalLearningExperienceRequest,
  ExternalLearningExperience,
  LearningExperience,
  UpdateLearningExperienceRequest,
} from '@app/app/page-modules/learning-experiences/learning-experience.model';
import { LearningExperiencesService } from '@app/app/shared/services/learning-experiences/learning-experiences.service';
import { Observable } from 'rxjs';
import { RestClientService } from '@app/app/shared/services/rest-client.service';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from '@app/environments/environment';
import {
  FileUploadPresignedUrlRequest,
  FileUploadPresignedUrlResponseDetailed,
} from '@app/app/shared/services/file-upload/file-upload.model';
import { Page } from '@app/app/shared/models/page';

@Injectable()
export class ApiLearningExperiencesService implements LearningExperiencesService {
  constructor(private client: RestClientService) {}

  getLearningExperiences(curated: boolean, page: number, size: number): Observable<Page<LearningExperience>> {
    return this.client
      .get<Page<LearningExperience>>(Location.joinWithSlash(environment.apiRootUrl || '', `learning-experiences/search`), {
        curated: curated.toString(),
        page: page.toString(),
        pageSize: size.toString(),
      })
      .pipe(map((response) => response.body));
  }

  getPublicLearningExperiences(publicFolioId: string, page: number, size: number): Observable<Page<LearningExperience>> {
    return this.client
      .get<Page<LearningExperience>>(
        Location.joinWithSlash(environment.apiRootUrl || '', `learning-experiences/public/${publicFolioId}/search`),
        {
          page: page.toString(),
          pageSize: size.toString(),
        },
      )
      .pipe(map((response) => response.body));
  }

  createExternalLearningExperience(request: CreateExternalLearningExperienceRequest): Observable<ExternalLearningExperience> {
    const apiPath = Location.joinWithSlash(environment.apiRootUrl || '', '/learning-experiences/external/create');

    return this.client.post<ExternalLearningExperience>(apiPath, request).pipe(map((response) => response.body));
  }

  removeLearningExperience(learningExperienceUid: string): Observable<void> {
    const apiPath = Location.joinWithSlash(environment.apiRootUrl || '', `/learning-experiences/${learningExperienceUid}`);

    return this.client.delete<void>(apiPath).pipe(map((response) => response.body));
  }

  updateLearningExperience(learningExperienceUid: string, request: UpdateLearningExperienceRequest): Observable<LearningExperience> {
    const apiPath = Location.joinWithSlash(environment.apiRootUrl || '', `/learning-experiences/${learningExperienceUid}`);

    return this.client.post<LearningExperience>(apiPath, request).pipe(map((response) => response.body));
  }

  generateAttachmentUploadUrl(attachments: File[]): Observable<FileUploadPresignedUrlResponseDetailed[]> {
    const apiPath = Location.joinWithSlash(environment.apiRootUrl || '', '/learning-experiences/attachments/upload-url');
    const request: FileUploadPresignedUrlRequest[] = attachments.map((attachmentFile) =>
      this.toFileUploadPresignedUrlRequest(attachmentFile),
    );

    return this.client.post<FileUploadPresignedUrlResponseDetailed[]>(apiPath, request).pipe(map((response) => response.body));
  }

  setCurateForLearningExperiences(curated: boolean, lerExpUids: string[]): Observable<void> {
    return this.client
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', '/learning-experiences/curate'), lerExpUids, {
        curated: curated.toString(),
      })
      .pipe(map((response) => response.body));
  }

  private toFileUploadPresignedUrlRequest(file: File): FileUploadPresignedUrlRequest {
    return {
      fileName: file.name,
      contentLength: file.size.toString(),
    };
  }
}
