<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="send-message-dialog-content">
  <div class="dialog-top-info">
    <h1 class="dialog-title title">{{ 'translations.membersNew.actionBar.sendMessageDialog.title' | transloco }}</h1>
    <p class="dialog-description medium">
      <span *ngIf="checkedMemberIds.length">
        {{
          'translations.membersNew.actionBar.sendMessageDialog.description.selected' | transloco: { selectedCount: checkedMemberIds.length }
        }}
      </span>
      <span *ngIf="!checkedMemberIds.length && allMembers">
        {{ 'translations.membersNew.actionBar.sendMessageDialog.description.all' | transloco }}
      </span>
      <span *ngIf="!checkedMemberIds.length && !allMembers && filteredCount">
        {{ 'translations.membersNew.actionBar.sendMessageDialog.description.selected' | transloco: { selectedCount: filteredCount } }}
      </span>
    </p>
    <button
      class="close-dialog-btn"
      mat-icon-button
      color="primary"
      (click)="closeDialog()"
      [attr.aria-label]="'translations.global.ariaLabels.closeDialog' | transloco"
    >
      <svg class="svg" role="img" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>

  <div class="send-message-content">
    <div class="send-message__subject-box">
      <div class="label small bold">{{ 'translations.membersNew.actionBar.sendMessageDialog.subject' | transloco }}</div>
      <input
        matInput
        class="small subject-input"
        type="text"
        autocomplete="off"
        [placeholder]="'translations.membersNew.actionBar.sendMessageDialog.subjectPlaceholder' | transloco"
        [class.has-error]="subjectError"
        [attr.aria-label]="'translations.global.ariaLabels.sendMessageSubject' | transloco"
        (focus)="onDataUpdate('SUBJECT')"
        (ngModelChange)="onDataUpdate('SUBJECT')"
        [(ngModel)]="subject"
      />
    </div>

    <div class="send-message__content-box">
      <div class="label small bold">{{ 'translations.membersNew.actionBar.sendMessageDialog.messageContent' | transloco }}</div>

      <ptl-editor
        class="message-input no-outline"
        [class.has-error]="contentError"
        [class.focus]="contentFocused"
        [placeholder]="'translations.membersNew.actionBar.sendMessageDialog.messageContentPlaceholder' | transloco"
        [content]="content"
        (contentUpdate)="onContentUpdate($event, content)"
        (contentRemoval)="onContentRemoval($event, content)"
        (contentCreated)="onContentCreation($event, content)"
        (inputFocus)="contentFocused = true"
        (inputBlur)="contentFocused = false"
      >
      </ptl-editor>
    </div>

    <div class="send-message__send-email-toggle-box">
      <mat-slide-toggle
        class="mat-slide-custom"
        [attr.aria-label]="'translations.global.ariaLabels.sendMessageEmailToggle' | transloco"
        [checked]="sendEmail"
        (change)="toggleSendEmail()"
        (keydown.enter)="toggleSendEmail()"
      >
        <span class="extra-small">{{ 'translations.membersNew.actionBar.sendMessageDialog.sendEmailToggle' | transloco }}</span>
      </mat-slide-toggle>
    </div>
  </div>

  <div class="dialog-footer-buttons">
    <button mat-flat-button type="button" class="cancel-button" color="accent" (click)="closeDialog()">
      {{ 'translations.global.button.cancel' | transloco }}
    </button>
    <button mat-flat-button type="submit" class="submit-button" color="primary" (click)="sendMessage()">
      {{ 'translations.membersNew.actionBar.sendMessageDialog.sendButtonText' | transloco }}
    </button>
  </div>
</div>
