/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { InjectionToken } from '@angular/core';
import { ApprovalRequest, DiagnosticsWithProgress } from '../../models';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const SHARED_CARD_DATA_SERVICE = new InjectionToken<SharedCardDataService>('[resource] SharedCardDataService');

/** The service handling tag CRUD operations. */
export interface SharedCardDataService {
  /**
   * Fires when resource is opened.
   */
  registerCardOpen(resourceUid: string): ObservableResult<void>;

  getCardDetailWithProgress(cardUri: string): ObservableResult<DiagnosticsWithProgress>;

  associateTag(resourceUid: string, tagUid: string): ObservableResult<void>;

  dissociateTag(resourceUid: string, tagUid: string): ObservableResult<void>;

  updateCardUri(resourceUid: string, newUri: string): ObservableResult<void>;

  publishCard(resourceUid: string): ObservableResult<void>;

  unpublishCard(resourceUid: string): ObservableResult<void>;

  archiveCard(resourceUid: string): ObservableResult<void>;

  updateApprovalMethod(resourceUid: string, request: ApprovalRequest): ObservableResult<void>;

  getReviewsCount(cardId: string): ObservableResult<number>;

  associateReviewer(resourceUid: string, userUid: string): ObservableResult<void>;

  dissociateReviewer(resourceUid: string, userUid: string): ObservableResult<void>;
}
