/*
 * Copyright (C) 2025 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { PlaylistTargetGroup, PlaylistTargetSector, PlaylistTargetUser } from '@app/app/shared/models';

@Component({
  selector: 'ptl-audience-chip-list',
  templateUrl: './audience-chip-list.component.html',
  styleUrls: ['./audience-chip-list.component.scss'],
})
export class AudienceChipListComponent {
  @Input() limit = 2;
  @Input() menu: MatMenu;
  @Input() items: PlaylistTargetGroup[] | PlaylistTargetUser[] | PlaylistTargetSector[] = [];
}
