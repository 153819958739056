<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ptl-form-preview-textbox
  class="form-preview-type"
  *ngIf="formContent?.content.type === 'TEXTBOX'"
  [formData]="formContent"
  [learnerFormAnswer]="learnerFormAnswer"
  [userPlaylistSubmissionSummary]="userPlaylistSubmissionSummary"
  [isProjectResource]="isProjectResource"
  [playlistUri]="playlistUri"
  [playlistUid]="playlistUid"
  [languageCode]="languageCode"
  [resourceUri]="resourceUri"
  [resourceUid]="resourceUid"
  [publisherUri]="publisherUri"
  [packageUri]="packageUri"
  [pageUri]="pageUri"
>
</ptl-form-preview-textbox>

<ptl-form-question-preview
  class="form-preview-type"
  *ngIf="formContent?.content.type === 'QUESTION_FORM'"
  [formData]="formContent"
  [learnerFormAnswer]="learnerFormAnswer"
  [userPlaylistSubmissionSummary]="userPlaylistSubmissionSummary"
  [isProjectResource]="isProjectResource"
  [playlistUri]="playlistUri"
  [playlistUid]="playlistUid"
  [languageCode]="languageCode"
  [resourceUri]="resourceUri"
  [resourceUid]="resourceUid"
  [publisherUri]="publisherUri"
  [packageUri]="packageUri"
  [pageUri]="pageUri"
>
</ptl-form-question-preview>

<ptl-form-preview-connected-textbox
  class="form-preview-type"
  *ngIf="formContent?.content.type === 'CONNECTED_TEXTBOX'"
  [formData]="formContent"
  [learnerFormAnswer]="learnerFormAnswer"
  [userPlaylistSubmissionSummary]="userPlaylistSubmissionSummary"
>
</ptl-form-preview-connected-textbox>

<ptl-form-preview-private-note
  class="form-preview-type"
  *ngIf="formContent?.content.type === 'PRIVATE_NOTE'"
  [formData]="formContent"
  [learnerFormAnswer]="learnerFormAnswer"
  [userPlaylistSubmissionSummary]="userPlaylistSubmissionSummary"
  [playlistUri]="playlistUri"
  [playlistUid]="playlistUid"
  [languageCode]="languageCode"
  [resourceUri]="resourceUri"
  [resourceUid]="resourceUid"
  [publisherUri]="publisherUri"
  [packageUri]="packageUri"
  [pageUri]="pageUri"
>
</ptl-form-preview-private-note>

<ptl-form-preview-checkbox
  class="form-preview-type"
  *ngIf="formContent?.content.type === 'CHECKBOX'"
  [formData]="formContent"
  [learnerFormAnswer]="learnerFormAnswer"
  [userPlaylistSubmissionSummary]="userPlaylistSubmissionSummary"
  [isProjectResource]="isProjectResource"
  [playlistUri]="playlistUri"
  [playlistUid]="playlistUid"
  [resourceUri]="resourceUri"
  [resourceUid]="resourceUid"
  [publisherUri]="publisherUri"
  [packageUri]="packageUri"
  [pageUri]="pageUri"
  [languageCode]="languageCode"
>
</ptl-form-preview-checkbox>

<ptl-form-preview-file-upload
  class="form-preview-type"
  *ngIf="formContent?.content.type === 'USER_FILE_UPLOAD'"
  [formData]="formContent"
  [learnerFormAnswer]="learnerFormAnswer"
  [userPlaylistSubmissionSummary]="userPlaylistSubmissionSummary"
  [isProjectResource]="isProjectResource"
  [playlistUri]="playlistUri"
  [playlistUid]="playlistUid"
  [resourceUri]="resourceUri"
  [resourceUid]="resourceUid"
  [publisherUri]="publisherUri"
  [packageUri]="packageUri"
  [pageUri]="pageUri"
  [languageCode]="languageCode"
>
</ptl-form-preview-file-upload>

<ptl-collector-preview
  class="form-preview-type"
  *ngIf="formContent?.content.type === 'COLLECTOR'"
  [formData]="formContent"
  [learnerFormAnswer]="learnerFormAnswer"
  [userPlaylistSubmissionSummary]="userPlaylistSubmissionSummary"
  [isProjectResource]="isProjectResource"
  [playlistUri]="playlistUri"
  [resourceUri]="resourceUri"
  [resourceUid]="resourceUid"
  [publisherUri]="publisherUri"
  [packageUri]="packageUri"
  [pageUri]="pageUri"
  [languageCode]="languageCode"
>
</ptl-collector-preview>

<ptl-form-preview-quiz
  class="form-preview-type"
  *ngIf="formContent?.content?.type === 'QUIZ'"
  [content]="formContent"
  [learnerFormAnswer]="learnerFormAnswer"
  [userPlaylistSubmissionSummary]="userPlaylistSubmissionSummary"
  [playlistUri]="playlistUri"
  [playlistUid]="playlistUid"
  [resourceUri]="resourceUri"
  [publisherUri]="publisherUri"
  [packageUri]="packageUri"
  [pageUri]="pageUri"
  [languageCode]="languageCode"
>
</ptl-form-preview-quiz>

<ptl-form-preview-booking
  class="form-preview-type"
  *ngIf="formContent?.content.type === 'BOOKING'"
  [formData]="formContent"
  [learnerFormAnswer]="learnerFormAnswer"
  [userPlaylistSubmissionSummary]="userPlaylistSubmissionSummary"
  [playlistUri]="playlistUri"
  [playlistUid]="playlistUid"
  [resourceUri]="resourceUri"
  [resourceUid]="resourceUid"
  [publisherUri]="publisherUri"
  [packageUri]="packageUri"
  [pageUri]="pageUri"
  [languageCode]="languageCode"
>
</ptl-form-preview-booking>
