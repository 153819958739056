/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { RestClientService } from '../rest-client.service';
import { ObservableResult } from '../../store';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { PIQuestionData, PIReport, PIReportResponse, PISummary } from '../../models/pi/pi.model';
import { PIDataService } from './pi-data.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ApiPIDataService implements PIDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  loadPISummary(resourceUid: string): ObservableResult<PISummary> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `pi/${resourceUid}/summary`);
    return this.client.get<PISummary>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetPISummary'))),
    );
  }

  getPIQuestion(resourceUid: string, version: number): ObservableResult<PIQuestionData> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `pi/${resourceUid}/cursor/${version}`);
    return this.client.post<PIQuestionData>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetPIQuestion'))),
    );
  }

  addPIQuestionAnswer(resourceUid: string, version: number, request: PIQuestionData): ObservableResult<PIQuestionData> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `pi/${resourceUid}/cursor/${version}`);
    return this.client.post<PIQuestionData>(url, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetPIQuestion'))),
    );
  }

  getPIReports(resourceUid: string): ObservableResult<PIReportResponse> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `pi/${resourceUid}/reports`);
    return this.client.get<PIReportResponse>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetPIReports'))),
    );
  }

  getPIReportByVersion(resourceUid: string, version: number): ObservableResult<PIReport> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `pi/${resourceUid}/reports/${version}`);
    return this.client.get<PIReport>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetPIReport'))),
    );
  }
}
