<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="expanded">
  <div class="quiz-container" #quizHtml tabindex="0" [attr.aria-label]="'translations.global.ariaLabels.thisIsQuizForm' | transloco">
    <div class="quiz-container-inner" [class.is_focused]="focused">
      <div class="quiz-container-top-line">
        <div class="quiz-container-title large bold">{{ 'translations.quiz.title.quiz' | transloco }}</div>
        <div class="quiz-container-icon">
          <svg class="svg" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#quiz"></use>
          </svg>
        </div>
      </div>

      <div class="quiz-questions-container">
        <ptl-quiz-questions-section
          [questions]="quiz.questions"
          [quizUid]="quiz._id"
          [languageCode]="languageCode"
          [currentLanguage]="currentLanguage"
          [organization]="organization"
          (questionsChanged)="quizChanged = true"
        >
        </ptl-quiz-questions-section>
      </div>

      <div class="quiz-settings-container">
        <div class="headline large">{{ 'translations.quiz.title.quizSettings' | transloco }}</div>

        <div class="quiz-settings-title-box">
          <div class="title small bold">{{ 'translations.quiz.label.quizTitle' | transloco }}</div>
          <div class="description extra-small">{{ 'translations.quiz.description.quizTitle' | transloco }}</div>
          <input
            class="quiz-title-field"
            type="text"
            placeholder="Enter quiz title"
            [(ngModel)]="quizTitle.value"
            (ngModelChange)="onQuizTitleUpdate($event)"
          />
        </div>
        <ptl-quiz-instructions
          class="quiz-settings-instructions-box"
          [instructions]="quiz.instructions"
          [languageCode]="languageCode"
          [organization]="organization"
          (mediumEditorChanged)="onInstructionValueChanged($event)"
        >
        </ptl-quiz-instructions>

        <ptl-quiz-settings
          class="quiz-settings-box"
          [settings]="quiz.settings"
          [questionsCount]="quiz.questions.length"
          (settingsChanged)="onSettingsValueChanged($event)"
        >
        </ptl-quiz-settings>
      </div>

      <div class="quiz-footer-buttons">
        <button
          mat-flat-button
          class="remove-button small"
          type="button"
          color="accent"
          *ngIf="canRemoveItem"
          [attr.aria-label]="'translations.global.ariaLabels.deleteQuiz' | transloco"
          (click)="onQuizElementRemoved()"
        >
          <svg class="svg svg-icon icon-left" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
          </svg>
          <span>{{ 'translations.global.button.delete' | transloco }}</span>
        </button>
        <button
          mat-flat-button
          class="save-button small btn-with-loader"
          title="Save"
          type="button"
          color="primary"
          (click)="saveQuiz()"
          [attr.aria-label]="'translations.global.ariaLabels.updateQuiz' | transloco"
        >
          <svg class="svg svg-icon icon-left" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
          </svg>
          <span>{{ 'translations.global.button.update' | transloco }}</span>
          <mat-spinner class="is-right" *ngIf="saveInProgress" [diameter]="20"></mat-spinner>
        </button>
        <button
          mat-flat-button
          class="form-button-save small"
          title="Save"
          color="accent"
          type="button"
          (click)="expanded = false"
          [attr.aria-label]="'translations.global.ariaLabels.collapseForm' | transloco"
        >
          <span>{{ 'translations.global.button.cancel' | transloco }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-container>

<div class="form-section" *ngIf="!expanded" (click)="expanded = true" (keydown.enter)="$event.stopPropagation()">
  <form class="form-block">
    <div class="form-block-inner">
      <div class="form-block-title-box medium bold">
        {{ !quizTitle?.value ? ('translations.quiz.title.quiz' | transloco) : quizTitle?.value }}
      </div>
      <div class="form-block-help-text-box extra-small"></div>

      <span class="setting-text extra-small" *ngIf="!expanded">{{ 'translations.global.button.settings' | transloco }}</span>
    </div>
  </form>
</div>
