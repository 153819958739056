<!--
  ~ Copyright (C) 2019 - present by Potentially
  ~
  ~ Please see distribution for license.
  -->

<div class="create-moment__action">
  <button
    *ngIf="!inputActive"
    mat-icon-button
    class="create-moment__action-btn"
    title="Add file"
    type="button"
    [attr.aria-label]="'translations.global.ariaLabels.attacheFile' | transloco"
    (click)="showInput()"
  >
    <i class="material-icons rotate-45" aria-hidden="true">attachment</i>
  </button>

  <div
    tabindex="0"
    role="button"
    *ngIf="inputActive"
    class="create-moment__action-upload"
    ogFileDrop
    (click)="fileInput.click()"
    (keydown.enter)="fileInput.click()"
    (filesDropped)="onFilesDropped($event)"
    (filesHovered)="filesHovered = $event"
    [class.create-moment__action-upload--active]="filesHovered"
  >
    <span>{{ 'translations.folio.button.uploadFileOrImage' | transloco }}</span>
  </div>

  <input
    type="file"
    multiple
    accept="image/*,video/*,audio/*,.doc,.docx,.pdf,.ppt,.pptx"
    (change)="onFileChanged($event)"
    hidden
    #fileInput
  />
</div>
