import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MENTOR_MENTEE_DATA_SERVICE,
  MentorMenteeDataService,
} from '@app/app/page-modules/admin/services/mentor-mentee/mentor-mentee-data.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Mentor, MentorshipStatus } from '@app/app/page-modules/admin/components/mentors/admin-mentors.model';

@Component({
  selector: 'ptl-mentor-search-dialog',
  templateUrl: './mentor-search-dialog.component.html',
  styleUrls: ['./mentor-search-dialog.component.scss'],
})
export class MentorSearchDialogComponent implements OnInit, OnDestroy {
  submitButtonLabel: string;
  selectedUser: Mentor;
  isLoading: boolean;
  mentors: Mentor[];
  pageIndex = 0;
  pageSize = 10;
  searchQuery: string;
  protected users: Mentor[] = [];
  private nameRequestSubject$ = new Subject<void>();
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      submitButtonLabel: string;
      statuses?: MentorshipStatus[];
      hiddenMentorsUids?: string[];
    },
    @Inject(MENTOR_MENTEE_DATA_SERVICE) private mentorMenteeService: MentorMenteeDataService,
    public dialogRef: MatDialogRef<MentorSearchDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.submitButtonLabel = this.data.submitButtonLabel;
    this.nameRequestSubject$.pipe(debounceTime(500)).subscribe(() => this.onSearchChange());
    this.applyNameFilter();
  }

  onSearchChangeDebounced() {
    this.nameRequestSubject$.next();
  }

  ngOnDestroy(): void {
    this.subscriptionEnd$.emit();
  }

  selectUser(user: Mentor) {
    this.selectedUser = user;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  applyNameFilter() {
    this.isLoading = true;

    this.mentorMenteeService.findMentors(this.pageIndex, this.pageSize, this.searchQuery, this.data.statuses).subscribe((result) => {
      if (result.isSuccess) {
        this.mentors = this.excludeHiddenMentors(result.value.content);
      }
      this.isLoading = false;
    });
  }

  deselectUser() {
    this.selectedUser = null;
  }

  onSubmitClick() {
    this.dialogRef.close(this.selectedUser);
  }

  private excludeHiddenMentors(mentors: Mentor[]): Mentor[] {
    const hidden = this.data.hiddenMentorsUids ?? [];
    return mentors.filter((mentor) => !hidden.includes(mentor.id));
  }

  private onSearchChange() {
    this.applyNameFilter();
  }
}
