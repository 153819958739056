/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LearningExperiencesService } from '@app/app/shared/services/learning-experiences/learning-experiences.service';
import {
  CreateExternalLearningExperienceRequest,
  ExternalLearningExperience,
  LearningExperience,
  UpdateLearningExperienceRequest,
} from '@app/app/page-modules/learning-experiences/learning-experience.model';
import { FileUploadPresignedUrlResponseDetailed } from '@app/app/shared/services/file-upload/file-upload.model';
import { Page } from '@app/app/shared/models/page';
import { Location } from '@angular/common';
import { environment } from '@app/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class MockLearningExperiencesService implements LearningExperiencesService {
  getLearningExperiences(curated: boolean, page: number, size: number): Observable<Page<LearningExperience>> {
    return of({
      content: [],
      totalNumberOfElement: 0,
      pageSize: size,
      currentPageNumber: 0,
    });
  }
  getPublicLearningExperiences(publicFolioId: string, page: number, size: number): Observable<Page<LearningExperience>> {
    return undefined;
  }
  createExternalLearningExperience(request: CreateExternalLearningExperienceRequest): Observable<ExternalLearningExperience> {
    return undefined;
  }
  removeLearningExperience(learningExperienceUid: string): Observable<void> {
    return undefined;
  }
  updateLearningExperience(learningExperienceUid: string, request: UpdateLearningExperienceRequest): Observable<LearningExperience> {
    return undefined;
  }
  generateAttachmentUploadUrl(attachments: File[]): Observable<FileUploadPresignedUrlResponseDetailed[]> {
    return undefined;
  }
  setCurateForLearningExperiences(curated: boolean, lerExpUids: string[]): Observable<void> {
    return undefined;
  }
}
