/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ItemWithUploadUrl } from '../../../../../shared/models/ItemWithUploadUrl';
import { ObservableResult } from '../../../../../shared/store';
import { CardTitleUpdateRequest, ResourceSectionRequest, ResourceThumbnailUpdateRequest } from '../../../models/editor';
import { InjectionToken } from '@angular/core';
import { EditorContent, PlaylistCardShort, Resource, ResourceSection } from '../../../../../shared/models';
import { CardsStatsSummary, StatsData } from '../../../../../shared/models/analytics/analytics.model';
import { Page } from '../../../../../shared/models/page';
import { AllContentCard, AllContentPage } from '../../../../../shared/models/all-content/all-content.model';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const CORE_RESOURCE_DATA_SERVICE = new InjectionToken<ResourceDataService>('[resource-editor] CoreResourceDataService');

/** The service handling resource CRUD operations. */
export interface ResourceDataService {
  /**
   * Updates a card's headline
   *
   * @param cardUid the UID of the resource to update
   * @param request the card details to use when updating
   * @param languageCode
   */
  updateCardHeadline(cardUid: string, request: CardTitleUpdateRequest, languageCode?: string): ObservableResult<Resource>;

  /**
   * Marks a weblink as opened
   *
   * @param resourceUid the UID of the resource to mark as open
   */
  markWebLinkOpened(resourceUid: string): ObservableResult<void>;

  /**
   * Gets the details for a resource with a given URI.
   */
  getResourceDetails(
    isProject: boolean,
    playlistUri: string,
    resourceUri: string,
    publisherUri: string,
    packageUri: string,
    pageUri: string,
    languageCode?: string,
  ): ObservableResult<Resource>;

  /**
   * Deletes a resource with a given playlistUid, sectionUid and resourceUid.
   *
   * @param playlistUid the UID of the resource parent playlist
   * @param sectionUid the UID of the resource current section
   * @param resourceUid the UID of the resource
   */
  deleteResource(playlistUid: string, sectionUid: string, resourceUid: string): ObservableResult<void>;

  /**
   * Creates a resource section with a given resourceUid.
   *
   * @param resourceUid the UID of the resource
   */
  createSection(resourceUid: string): ObservableResult<ResourceSection>;

  /**
   * Creates an editor block within a specified card and section.
   * @param cardUid The unique identifier of the card where the block will be created.
   * @param sectionUid The unique identifier of the section within the card where the block will be created.
   * @param data The data to be inserted into the editor block.
   * @param languageCode (Optional) The language code indicating the language of the data.
   * @returns An observable result containing the response of the resource update operation.
   */
  createEditorBlock(cardUid: string, sectionUid: string, data: EditorContent, languageCode?: string): ObservableResult<BlockUpdateResponse>;

  /**
   * Updates an existing editor block within a specified card and section.
   * @param cardUid The unique identifier of the card containing the block to be updated.
   * @param sectionUid The unique identifier of the section within the card containing the block to be updated.
   * @param data The new data to replace the existing content of the editor block.
   * @param languageCode (Optional) The language code indicating the language of the data.
   * @returns An observable result containing the response of the resource update operation.
   */
  updateEditorBlock(cardUid: string, sectionUid: string, data: EditorContent, languageCode?: string): ObservableResult<BlockUpdateResponse>;

  /**
   * Deletes an existing editor block within a specified card and section.
   * @param cardUid The unique identifier of the card containing the block to be deleted.
   * @param sectionUid The unique identifier of the section within the card containing the block to be deleted.
   * @param blockUid The unique identifier of the block to be deleted.
   * @returns An observable result indicating the success or failure of the deletion operation.
   */
  deleteEditorBlock(cardUid: string, sectionUid: string, blockUid: string): ObservableResult<void>;

  /**
   * Deletes a resource section with a given sectionUid and resourceUid.
   *
   * @param resourceUid the UID of the resource
   * @param sectionUid the UID of the resource current section
   */
  deleteSection(resourceUid: string, sectionUid: string): ObservableResult<Resource>;

  /**
   * Updates a resource section with a given sectionUid, resourceUid, languageCode and requstData.
   *
   * @param sectionUid the UID of the resource current section
   * @param resourceUid the UID of the resource
   * @param languageCode the language code of current tab
   * @param data request data of update
   */
  updateSection(
    resourceUid: string,
    sectionUid: string,
    data: ResourceSectionRequest,
    languageCode?: string,
  ): ObservableResult<ResourceUpdateSectionResponse>;

  /**
   * Updates a resource time required.
   *
   * @param resourceUid the UID of the resource
   * @param timeRequired the required time
   */
  updateTimeRequired(resourceUid: string, timeRequired: number): ObservableResult<void>;

  getResourceTimeRequiredSuggestion(resourceUid: string, languageCode?: string): ObservableResult<number>;

  changeResourceThumbnail(resourceUid: string, request: ResourceThumbnailUpdateRequest): ObservableResult<ItemWithUploadUrl<Resource>>;

  removeResourceThumbnail(resourceUid: string): ObservableResult<void>;

  archiveResource(resourceUid: string): ObservableResult<void>;

  addLanguage(cardUid: string, languageCode: string): ObservableResult<void>;

  removeLanguage(cardUid: string, languageCode: string): ObservableResult<void>;

  enableLanguage(cardUid: string, languageCode: string): ObservableResult<void>;

  disableLanguage(cardUid: string, languageCode: string): ObservableResult<void>;

  getResourceStartedStatistics(cardUid: string, playlistUid: string, from: string, to: string): ObservableResult<StatsData[]>;

  getResourceCompletedStatistics(cardUid: string, playlistUid: string, from: string, to: string): ObservableResult<StatsData[]>;

  getResourceStatisticsSummary(cardUid: string, playlistUid: string): ObservableResult<CardsStatsSummary>;

  getResourceCardsByTags(page: number, size: number, tags: string[]): ObservableResult<Page<PlaylistCardShort>>;

  getFormPlayListByUid(playlistUid: string, term: string, page: number, size: number): ObservableResult<Page<AllContentPage>>;

  getFormCardsByPlayListUid(playlistUid: string, selectedPlaylistUid: string): ObservableResult<AllContentCard[]>;

  getFormsList(
    playlistUid: string,
    selectedPlaylistUid: string,
    selectedCardUid: string,
  ): ObservableResult<{ title: string; id: string }[]>;

  getFormTitlesByPlayListUid(playlistUid: string): ObservableResult<{ title: string }>;

  getFormCardTitlesByCardUid(playlistUid: string, cardUid: string): ObservableResult<{ title: string }>;
}

export type ResourceUpdateSectionResponse = ItemWithUploadUrl<ResourceSection>;

export type BlockUpdateResponse = ItemWithUploadUrl<EditorContent>;
