<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<mat-dialog-content class="dialog-content">
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort class="custom-table" (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="header">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="header-content">
            <span class="header-text">
              {{ 'translations.global.label.course' | transloco }}
            </span>
            <mat-icon class="unsorted-icon" [style.opacity]="sortedColumn !== 'header' ? '1' : '0'"> swap_vert </mat-icon>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="clickable"
          (keyup.enter)="openInNewTab(element.playlistId)"
          (click)="openInNewTab(element.playlistId)"
        >
          {{ element.header }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalCards">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="header-content">
            <span class="header-text">
              {{ 'translations.analytics.metric.cards' | transloco }}
            </span>
            <mat-icon class="unsorted-icon" [style.opacity]="sortedColumn !== 'totalCards' ? '1' : '0'"> swap_vert </mat-icon>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.totalCards }}</td>
      </ng-container>

      <ng-container matColumnDef="totalMembers">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="header-content">
            <span class="header-text">
              {{ 'translations.members.title' | transloco }}
            </span>
            <mat-icon class="unsorted-icon" [style.opacity]="sortedColumn !== 'totalMembers' ? '1' : '0'"> swap_vert </mat-icon>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.totalMembers }}</td>
      </ng-container>

      <ng-container matColumnDef="startedCourseCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="header-content">
            <span class="header-text">
              {{ 'translations.analytics.metric.startedUserCourses' | transloco }}
            </span>
            <mat-icon class="unsorted-icon" [style.opacity]="sortedColumn !== 'startedCourseCount' ? '1' : '0'"> swap_vert </mat-icon>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.startedCourseCount }}</td>
      </ng-container>

      <ng-container matColumnDef="completedCourseCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="header-content">
            <span class="header-text">
              {{ 'translations.analytics.metric.completedUserCourses' | transloco }}
            </span>
            <mat-icon class="unsorted-icon" [style.opacity]="sortedColumn !== 'completedCourseCount' ? '1' : '0'"> swap_vert </mat-icon>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.completedCourseCount }}</td>
      </ng-container>

      <ng-container matColumnDef="totalEventCardRegistrations">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="header-content">
            <span class="header-text">
              {{ 'translations.analytics.metric.eventCardRegistrations' | transloco }}
            </span>
            <mat-icon class="unsorted-icon" [style.opacity]="sortedColumn !== 'totalEventCardRegistrations' ? '1' : '0'">
              swap_vert
            </mat-icon>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.totalEventCardRegistrations }}</td>
      </ng-container>

      <ng-container matColumnDef="totalEnrollmentsCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="header-content">
            <span class="header-text">
              {{ 'translations.analytics.metric.totalEnrollments' | transloco }}
            </span>
            <mat-icon class="unsorted-icon" [style.opacity]="sortedColumn !== 'totalEnrollmentsCount' ? '1' : '0'"> swap_vert </mat-icon>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.totalEnrollmentsCount }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</mat-dialog-content>

<div class="dialog-header-footer">
  <p
    class="close-link"
    [attr.aria-label]="'translations.global.ariaLabels.closeDialog'"
    (keydown.enter)="closeDialog()"
    (click)="closeDialog()"
  >
    {{ 'translations.global.button.close' | transloco }}
  </p>
  <button
    class="export-button"
    mat-stroked-button
    [attr.aria-label]="'translations.global.button.downloadCsv' | transloco"
    (click)="downloadCsvFile()"
  >
    <div class="button-elements">
      <mat-icon aria-hidden="true">file_download</mat-icon>
      <p>{{ 'translations.global.button.downloadCsv' | transloco }}</p>
    </div>
  </button>
</div>
