<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<mat-card
  class="moment-text-card moment-card-bg"
  [ngClass]="'moment-card-bg--' + colorId"
  (click)="onViewClick()"
  [attr.aria-label]="'translations.global.ariaLabels.open' | transloco: { title: card.text }"
  tabindex="0"
  [style.cursor]="card.viewType === 'COLLECTOR' ? 'initial' : ''"
>
  <div class="moment-text-card__top">
    <div class="moment-text-card__top-author">
      <ptl-profile-image [userSummary]="userDetailsData$ | async"></ptl-profile-image>
      <div class="moment-text-card__name">
        {{ (userDetailsData$ | async)?.firstName }}
      </div>
    </div>

    <button
      *ngIf="card.viewType === 'REMOVE'"
      mat-icon-button
      class="moment-card__remove-button"
      [title]="'Remove'"
      (click)="onRemoveMoment(card._id); $event.stopPropagation()"
    >
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>

    <ptl-masonry-action-button
      [card]="card"
      *ngIf="card.viewType === 'EDIT'"
      [archiveMoment]="archiveMoment"
      (cardRemoved)="onRemoveMoment($event)"
      (cardArchived)="onArchiveMoment($event)"
      (click)="$event.stopPropagation()"
    >
    </ptl-masonry-action-button>
    <button
      *ngIf="card.viewType === 'COLLECTOR'"
      mat-icon-button
      class="moment-card__button"
      [title]="selected ? 'Remove' : 'Add'"
      [class.is_selected]="selected"
      (click)="onButtonClick()"
    >
      <mat-icon aria-hidden="true" [inline]="true">add</mat-icon>
    </button>
  </div>

  <div class="moment-text-card__main">
    <p [style.cursor]="card.viewType === 'COLLECTOR' ? 'initial' : ''">
      {{ card.text }}
    </p>
  </div>
</mat-card>
