/*
 * Copyright (C) 2021 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Playlist } from '../../../../../../shared/models';
import { PAGES_DATA_SERVICE, PageDataService } from '../../../../services/data.service';
import { LearnerPlaylistSummary } from '../../../../../../shared/models/playlist/learner-playlist-summary.model';
import { canTriggerSearch } from '../../../../../../shared/helpers/content-helper';

@Component({
  selector: 'ptl-discovery-share-playlists',
  templateUrl: './share-discovery-playlists.component.html',
  styleUrls: ['./share-discovery-playlists.component.scss'],
})
export class ShareDiscoveryPlaylistsComponent {
  @Output() playlistSelected = new EventEmitter<Playlist>();

  searchString: '';
  loadingSearch: boolean;
  playlists: LearnerPlaylistSummary[] = [];

  private page = 0;
  private size = 10;

  private searchInputSubject$ = new Subject<void>();
  private searchInputSubscription: Subscription;

  constructor(@Inject(PAGES_DATA_SERVICE) private pageDataService: PageDataService) {
    this.searchInputSubscription = this.searchInputSubject$.pipe(debounceTime(500)).subscribe(() => this.fireSearch());
  }

  selectPlaylist(playlist) {
    this.playlistSelected.emit(playlist);
  }

  onTextPaste(event: ClipboardEvent) {
    if (event.type === 'paste') {
      setTimeout(() => {
        if (this.searchString) {
          this.loadingSearch = true;
          this.searchInputSubject$.next();
        }
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent) {
    if (canTriggerSearch(event)) {
      if (this.searchString) {
        this.loadingSearch = true;
        this.searchInputSubject$.next();
      }
    }
  }

  private fireSearch() {
    this.pageDataService.getYourPlaylists(this.page, this.size, this.searchString, null).subscribe(({ value }) => {
      if (value) {
        this.playlists = value.content;
      }
      this.loadingSearch = false;
    });
  }
}
