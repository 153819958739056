/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnInit } from '@angular/core';
import { GeneratedReportConditionalSection } from '@app/app/shared/models';

@Component({
  selector: 'ptl-diagnostics-report-conditional-section',
  templateUrl: './conditional-section.component.html',
  styleUrls: ['./conditional-section.component.scss'],
})
export class DiagnosticsReportConditionalSectionComponent implements OnInit {
  @Input() section: GeneratedReportConditionalSection;

  @Input() isFirst: boolean;

  radius = 54;
  circumference = 2 * Math.PI * this.radius;
  progressColor = '';

  ngOnInit() {
    this.progressColor = this.section.chartColor;
  }

  get scoredRatio() {
    return this.section.learningGoalWrapper.scored / this.section.learningGoalWrapper.maxScore;
  }

  get scoredPercentage() {
    return (this.section.learningGoalWrapper.scored * 100) / this.section.learningGoalWrapper.maxScore;
  }

  get dashoffset() {
    return this.circumference * (1 - this.scoredRatio);
  }

  get svgStroke() {
    return this.progressColor ? this.progressColor : `url(${window.location.pathname}#gradient)`;
  }
}
