<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div *ngIf="completionsData.length > 0" class="chart-dashboard">
  <div class="header-row">
    <p class="chart-dashboard-title">
      {{ 'translations.analytics.charts.mostCompletions.title' | transloco }}
    </p>
    <button class="btn-sort" (click)="toggleSortOrder()" [attr.aria-label]="'translations.global.ariaLabels.toggleSorting' | transloco">
      <mat-icon>{{ sortOrder === 'desc' ? 'arrow_downward' : 'arrow_upward' }}</mat-icon>
    </button>
  </div>
  <div class="chart-wrapper">
    <div class="completions-chart">
      <div *ngFor="let item of completionsData" class="chart-bar">
        <div
          class="chart-label clickable"
          (keyup.enter)="openInNewTab(item.label)"
          (click)="openInNewTab(item.label)"
          matTooltip="{{ item.label }}"
        >
          {{ item.label }}
        </div>
        <div class="chart-bar-wrapper" matTooltip="{{ item.label }}">
          <div class="chart-bar-fill" [style.width.%]="(item.count / maxCompletion) * 100"></div>
        </div>
        <div class="chart-bar-value">{{ item.count }}</div>
      </div>
    </div>
  </div>
</div>
