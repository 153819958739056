import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from './component/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { BreadcrumbsState } from './store/breadcrumbs.state';
import { RestClientService } from '../services/rest-client.service';
import { TranslocoService } from '@ngneat/transloco';
import { CORE_PLAYLIST_DATA_SERVICE } from '../../page-modules/playlist/services/create/core/data.service';
import * as SharedFactoryService from '../shared-service-factory';
import { TranslocoRootModule } from '../../transloco/transloco-root.module';
import { PlaylistBreadcrumbComponent } from '@app/app/shared/breadcrumbs/component/playlist-breadcrumb/playlist-breadcrumbs.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PlaylistBreadcrumbItemComponent } from '@app/app/shared/breadcrumbs/component/playlist-breadcrumb/playlist-breadcrumb-item/playlist-breadcrumb-item.component';

@NgModule({
  declarations: [BreadcrumbsComponent, PlaylistBreadcrumbComponent, PlaylistBreadcrumbItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoRootModule,
    NgxsModule.forFeature([BreadcrumbsState]),
    MatProgressSpinnerModule,
    MatTooltipModule,
  ],
  exports: [BreadcrumbsComponent, PlaylistBreadcrumbComponent, PlaylistBreadcrumbItemComponent],
  providers: [
    {
      provide: CORE_PLAYLIST_DATA_SERVICE,
      useFactory: SharedFactoryService.playlistServiceFactory,
      deps: [RestClientService, TranslocoService],
    },
  ],
})
export class BreadcrumbsModule {}
