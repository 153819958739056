<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="like-info-container">
  <button
    mat-button
    [tabindex]="editMode ? -1 : 0"
    class="like-info-button"
    [disabled]="disabled"
    [attr.aria-label]="
      !(vote && !editMode)
        ? ('translations.global.ariaLabels.like' | transloco: { name: title })
        : ('translations.global.ariaLabels.unlike' | transloco: { name: title })
    "
    (click)="onVote(); $event.stopPropagation()"
    (keydown.enter)="$event.stopPropagation()"
  >
    <svg class="svg" aria-hidden="true" *ngIf="!(vote && !editMode)">
      <use ogSvgUnify="assets/sprite/sprite.svg#thumb-up"></use>
    </svg>

    <svg class="svg" aria-hidden="true" *ngIf="vote && !editMode">
      <use ogSvgUnify="assets/sprite/sprite.svg#thumb-up-active"></use>
    </svg>

    <span class="like-count extra-small" *ngIf="totalLikes">
      {{ totalLikes }}
    </span>
  </button>
</div>
