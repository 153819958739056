/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, OnChanges, Input, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'ptl-app-true-or-false-choice-question-type',
  templateUrl: './true-or-false-choice-question-type.component.html',
  styleUrls: ['../add-question-section.component.scss', './true-or-false-choice-question-type.component.scss'],
})
export class TrueOrFalseChoiceQuestionTypeComponent implements OnChanges {
  /** Receives the question to edit */
  @Input() question: FormGroup;

  @Input() languageCode: string | undefined;
  @Input() isDefaultLanguage: boolean;

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
  ) {}

  addGridOption(): FormGroup {
    return this.fb.group({
      type: 'CHOICE_OPTION',
      value: this.fb.group({
        languageCode: this.languageCode,
        value: ['', [Validators.required, Validators.maxLength(33)]],
      }),
      score: [0],
      correct: [true, Validators.required],
    });
  }

  removeOption(index) {
    (this.question.controls['options'] as FormArray).removeAt(index);
  }

  addNewOption() {
    (this.question.controls['options'] as FormArray).push(this.addGridOption());
    setTimeout(() => {
      const lastElement = this.question.controls['options']['controls'].length - 1;
      const inputElement = this.renderer.selectRootElement(`#input${lastElement}`);
      if (lastElement > 0) {
        inputElement.focus();
      }
    }, 0);
  }

  dropOption(moveEvent: CdkDragDrop<FormArray>) {
    moveItemInArray((this.question.controls['options'] as FormArray).controls, moveEvent.previousIndex, moveEvent.currentIndex);
    this.question.controls['options'].updateValueAndValidity();
  }

  ngOnChanges() {
    if (this.question) {
      this.populateForm();
    }
  }

  populateForm() {
    // if no uid, clear current arrays and add a blank one
    if (!this.question.value.uid) {
      while ((this.question.controls['options'] as FormArray).length !== 0) {
        (this.question.controls['options'] as FormArray).removeAt(0);
      }
      this.addNewOption();
    }
  }

  correctQuestion(form: FormGroup) {
    form.get('correct').setValue(true);
  }
  falseQuestion(form: FormGroup) {
    form.get('correct').setValue(false);
  }
}
