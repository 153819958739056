/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { CardWithProgress, LearnerFormAnswerRequest } from '../models';
import { Resource } from '../../../shared/models';

// -------------------------------------------------------------------------
export class RefreshLearnerViewCardStatus {
  static readonly type = '[Learner view] Refresh Card Completion Status';

  constructor(
    public playlistUri: string,
    public resourceUri: string,
    public publisherUri: string,
    public packageUri: string,
    public pageUri: string,
    public languageCode: string,
  ) {}
}

// -------------------------------------------------------------------------
export class LoadLearnerViewDetails {
  static readonly type = '[Learner view] Load resource details';

  constructor(
    public isProjectResource: boolean,
    public playlistUri: string,
    public resourceUri: string,
    public publisherUri: string,
    public packageUri: string,
    public pageUri: string,
    public languageCode: string,
  ) {}
}

// -------------------------------------------------------------------------
export class UpdateLearnerViewDetailsWithProgress {
  static readonly type = '[Learner view] Update Resource Details With Progress';

  constructor(public cardWithProgress: CardWithProgress) {}
}

// -------------------------------------------------------------------------
export class NavigateToRootPage {
  static readonly type = '[Learner view] Navigate to root page';
}

// -------------------------------------------------------------------------
export class UpdateLearnerViewDetails {
  static readonly type = '[Learner view] Update Resource Details';

  constructor(public data: Resource) {}
}

// -------------------------------------------------------------------------
export class SaveFormAnswer {
  static readonly type = '[Learner view] Save form answer';

  constructor(
    public playlistUid: string,
    public formAnswer: LearnerFormAnswerRequest,
    public formUid?: string,
  ) {}
}

export class SubmitForm {
  static readonly type = '[Learner view] Submit form';

  constructor(
    public playlistUid: string,
    public formId: string,
  ) {}
}

export class RefreshStateAfterFormSubmission {
  static readonly type = '[Learner view] Refresh state after form submission';

  constructor(public cardWithProgress: CardWithProgress) {}
}

// -------------------------------------------------------------------------
export class EditCurrentResource {
  static readonly type = '[Learner view] Open resource edit';

  constructor(
    public isProjectResource: boolean,
    public playlistUri: string,
    public folioPublicId?: string,
    public editSection?: string,
    public page = 1,
  ) {}
}

// -------------------------------------------------------------------------
export class LoadCompletionActivities {
  static readonly type = '[Learner view] Load completion activities';

  constructor(
    public playlistUri: string,
    public resourceUri: string,
  ) {}
}

export class SetCardCompletionRequired {
  static readonly type = '[Learner view] Set card completion required property';

  constructor(
    public playlistUid: string,
    public resourceUid: string,
    public required: boolean,
  ) {}
}

export class SetActivityRequired {
  static readonly type = '[Learner view] Set card completion activity required property';

  constructor(
    public playlistId: string,
    public resourceUid: string,
    public activityId: string,
    public required: boolean,
  ) {}
}

// -------------------------------------------------------------------------
export class UpdateLearnerParticipationStatus {
  static readonly type = '[Learner view event card] Update learner participation status';

  constructor(public event: Resource) {}
}

// -------------------------------------------------------------------------
export class ParticipateToEvent {
  static readonly type = '[event card edit] Participate to event';

  constructor(public participated: boolean) {}
}

export class SetCurrentViewLanguage {
  static readonly type = '[Learner view] Set current view language';

  constructor(public languageCode: string) {}
}

// -------------------------------------------------------------------------
export class UpdateLearnerViewOpenCard {
  static readonly type = '[Learner view] Update card opening';

  constructor(public cardOpened: boolean) {}
}
