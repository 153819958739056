<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<main
  class="main"
  [ngStyle]="{
    'background-image': (organizationData$ | async)?.landingPage.backgroundImage
      ? 'url(&quot;' + (organizationData$ | async)?.landingPage.backgroundImage + '&quot;)'
      : '',
    'background-color': (organizationData$ | async)?.landingPage.useBackgroundColourFill
      ? (organizationData$ | async)?.landingPage.backgroundColour
      : '',
  }"
  [class.dynamic-background-color]="(organizationData$ | async)?.landingPage.useBackgroundColourFill"
  [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'"
>
  <div class="maintenance-layer" *ngIf="showMaintenanceOverlay">
    <div class="maintenance-layer-inner" [innerHTML]="'translations.maintenance.overlayText' | transloco"></div>
  </div>

  <div class="password__form-container" [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
    <mat-card class="main__password" [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
      <img
        class="logo__image"
        width="240"
        height="80"
        [class.full-background]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
        src="{{ (organizationData$ | async)?.landingPage.landingLogo || (organizationData$ | async)?.logo }}"
        alt="{{ (organizationData$ | async)?.name }} Logo"
      />
      <div class="password__university-info" [class.reset-password-expire]="isResetPasswordExpire">
        <h1 class="title">
          {{
            (isResetPasswordExpire ? 'translations.public.title.resetPasswordExpire' : 'translations.public.title.forgotPassword')
              | transloco
          }}
        </h1>
        <p class="description">
          {{
            (isResetPasswordExpire
              ? 'translations.public.description.resetPasswordExpire'
              : 'translations.public.description.forgotPassword'
            ) | transloco
          }}
        </p>
        <p class="error-message" *ngIf="isResetPasswordExpire && errorText">{{ errorText }}</p>
      </div>

      <div class="login__form-inner" [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
        <form
          class="password-form"
          [formGroup]="forgotPasswordForm"
          (ngSubmit)="sendRecoveryRequest()"
          [attr.aria-label]="'translations.global.ariaLabels.forgotPasswordForm' | transloco"
          novalidate
        >
          <mat-form-field class="form__field" appearance="outline">
            <mat-label [attr.aria-label]="'translations.global.ariaLabels.emailField' | transloco">{{
              'translations.global.input.label.email' | transloco
            }}</mat-label>
            <input matInput required type="email" name="email" autocomplete="off" formControlName="email" />
            <mat-error *ngIf="forgotPasswordForm?.controls.email.hasError('required')">
              {{ 'translations.global.input.error.enterEmail' | transloco }}
            </mat-error>
          </mat-form-field>
          <ngx-invisible-recaptcha
            #captchaElem
            [siteKey]="reCaptchaSiteKeyV2"
            (success)="proceedIfValid($event)"
            (reset)="resetCaptcha()"
            [ngModel]="reCaptchaElement"
            [ngModelOptions]="{ standalone: true }"
            [useGlobalDomain]="false"
          >
          </ngx-invisible-recaptcha>
          <div class="password-form__actions">
            <button
              mat-flat-button
              type="submit"
              class="password-form__submit"
              title="Reset password"
              color="primary"
              [disabled]="loginInProgress"
            >
              <span class="uppercase" *ngIf="!loginInProgress">{{ 'translations.public.button.resetPassword' | transloco }}</span>
              <span class="submit__loading" *ngIf="loginInProgress">
                <span class="submit__logging-in">{{ 'translations.public.button.resettingPassword' | transloco }}</span>
                <mat-spinner [diameter]="20"></mat-spinner>
              </span>
            </button>
            <a
              tabindex="0"
              *ngIf="!isResetPasswordExpire"
              class="actions__sign-up"
              title="Already a member?"
              href="javascript:void(0)"
              (click)="onLoginClick()"
              (keydown.enter)="onLoginClick()"
            >
              {{ 'translations.public.button.alreadyAMember' | transloco }}
            </a>
          </div>
        </form>
      </div>
      <div
        *ngIf="false"
        class="password__powered-content"
        [attr.aria-label]="'translations.global.ariaLabels.poweredBy' | transloco: { organizationName: (organizationData$ | async)?.name }"
        [class.full-background]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
      >
        {{ 'translations.global.label.poweredBy' | transloco }}
        <a
          href="https://potential.ly"
          [class.is_light]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
          class="login__powered-content-link"
          [attr.aria-label]="'translations.global.ariaLabels.open' | transloco: { title: (organizationData$ | async)?.name }"
        >
          <img
            class="login__powered-image"
            width="40"
            height="40"
            src="https://static.yuna.potential.ly/public/images/icons/potentially_logo.png"
            alt=""
          />
          {{ 'translations.public.title.logoText' | transloco }}
        </a>
      </div>
    </mat-card>
  </div>
</main>
