/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Directive, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserAuthState } from '../../user-auth/store/user-auth.state';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Organization } from '../models';
import { filter } from 'rxjs/operators';
import { developmentDomains } from '../helpers/development-domains.helper';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Directive({
  selector: '[ogBetaFeature]',
})
export class BetaFeatureDirective implements OnDestroy {
  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  private organizationDataSubscription: Subscription;

  constructor(
    private tmpRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
    this.organizationDataSubscription = this.organizationData$.pipe(filter((data) => !!data)).subscribe((data) => {
      if (developmentDomains(data.domain)) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.tmpRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }

  ngOnDestroy() {
    this.organizationDataSubscription?.unsubscribe();
  }
}

@Directive({
  selector: '[ogNotBetaFeature]',
})
export class NotBetaFeatureDirective implements OnDestroy {
  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  private organizationDataSubscription: Subscription;

  constructor(
    private tmpRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
    this.organizationDataSubscription = this.organizationData$.pipe(filter((data) => !!data)).subscribe((data) => {
      if (!developmentDomains(data.domain)) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.tmpRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }

  ngOnDestroy() {
    this.organizationDataSubscription?.unsubscribe();
  }
}
