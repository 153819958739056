import { Injectable } from '@angular/core';
import { GroupListDataService } from './group-list-data.service';
import { ObservableResult } from '../../store';
import { HierarchicalList } from '../../models';
import { DEFAULT_LANGUAGE_CODE } from '../languages/language.service';

@Injectable()
export class MockGroupListDataService implements GroupListDataService {
  groupList: HierarchicalList = {
    _id: 'someUid1',
    title: [
      {
        languageCode: DEFAULT_LANGUAGE_CODE,
        value: 'test title',
      },
    ],
    creatorUid: 'someUid2',
    createdAt: new Date('2022-01-11T11:00:00.001'),
    lastUpdatedAt: new Date('2022-01-11T11:00:00.001'),
    root: {
      uid: 'someUid3',
      value: 'testRootValue',
      sublist: [],
    },
  };

  createGroupList(title: string): ObservableResult<HierarchicalList> {
    return ObservableResult.ofSuccess(this.groupList);
  }

  addGroupListElement(listUid: string, parentUid: string, groupUid: string): ObservableResult<HierarchicalList> {
    return ObservableResult.ofSuccess(this.groupList);
  }

  removeGroupListElement(listUid: string, groupUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  uploadCsv(file: File): ObservableResult<HierarchicalList> {
    return ObservableResult.ofSuccess(this.groupList);
  }
}
