<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="count-field small" tabindex="0">
  <span>{{ 'translations.quiz.settings.passCriteria.options.partOfCorrect' | transloco }}</span>
  <input
    matInput
    type="number"
    class="input"
    pattern="^\d+$"
    min="0"
    max="{{ questionsCount }}"
    [ngModel]="value.partOf"
    (ngModelChange)="onValueChange($event)"
  />
</div>
