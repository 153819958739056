<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  role="form"
  tabindex="0"
  class="question-form-preview"
  [attr.aria-label]="
    'translations.global.ariaLabels.checkboxFormTitle'
      | transloco
        : {
            title: listTitle,
            state: (submitted ? 'translations.global.ariaLabels.update' : 'translations.global.ariaLabels.markAsDone') | transloco,
          }
  "
  [class.question-form-preview--saved]="submitted"
  [class.form-preview--requires-resubmission]="requiresResubmission"
>
  <div class="form-content">
    <div class="question-form-preview-inner" [ngClass]="presentationMode?.toLowerCase()">
      <h2 class="main-title title medium bold" [class.stretched-height-title]="presentationMode === 'SELECTABLE_DROP_DOWN'">
        {{ listTitle }}
      </h2>
      <div class="options-list" [class.is_disabled]="this.userPlaylistSubmissionSummary?.submissionsLocked">
        <ng-container *ngIf="presentationMode === 'RADIO'">
          <mat-radio-group>
            <mat-radio-button
              [disabled]="!canEdit"
              [checked]="listItem.isChecked"
              *ngFor="let listItem of formSubList"
              class="mat-radio-btn"
              [attr.aria-label]="listItem.value | getDataByLangCode"
              (click)="onRadioOptionChange(listItem.uid)"
              [value]="listItem.uid"
            >
              <span class="small bold"> {{ listItem.value | getDataByLangCode }}</span>
            </mat-radio-button>
          </mat-radio-group>
        </ng-container>

        <ng-container *ngIf="presentationMode === 'CHECKBOX'">
          <mat-checkbox
            *ngFor="let listItem of formSubList; let idx = index"
            #checkbox
            color="primary"
            [disabled]="!canEdit || listItem.isDisabled"
            [checked]="listItem.isChecked"
            [value]="listItem.uid"
            [attr.aria-label]="listItem.value | getDataByLangCode"
            (click)="onOptionChange(listItem.uid, checkbox, listItem.isDisabled)"
          >
            <span class="small bold">{{ listItem.value | getDataByLangCode }}</span>
          </mat-checkbox>
        </ng-container>

        <ng-container *ngIf="presentationMode === 'SELECTABLE_DROP_DOWN'">
          <div class="select-field-box control-item multi-selectable-item" *ngIf="canEdit">
            <mat-select
              #select
              [(ngModel)]="defaultSelectedValues"
              multiple
              [placeholder]="'translations.editor.toolbarNew.option.questionForm.placeholder' | transloco"
              class="select-field"
              [class.disabled]="!canEdit"
              [disabled]="!canEdit"
            >
              <mat-select-trigger style="width: 100%">
                <span class="placeholder-text">{{ 'translations.editor.toolbarNew.option.questionForm.placeholder' | transloco }}</span>
              </mat-select-trigger>

              <mat-option
                *ngFor="let listItem of formSubList"
                [disabled]="listItem.isDisabled"
                class="mat-multi-select-custom"
                [attr.aria-label]="listItem.value | getDataByLangCode"
                (click)="onMultipleOptionChange(listItem.uid, select, listItem.isDisabled)"
                [value]="listItem.uid"
              >
                <span class="small bold">{{ listItem.value | getDataByLangCode }}</span>
              </mat-option>
            </mat-select>
          </div>

          <div class="chips-list" *ngIf="formSubList">
            <ng-container *ngFor="let tag of formSubList; let i = index">
              <div
                class="chip-item chip-interactive"
                [class.has-action-btn]="canEdit"
                *ngIf="tag.isChecked"
                [matTooltip]="tag.value | getDataByLangCode"
              >
                <span class="chip-text">{{ tag.value | getDataByLangCode }}</span>
                <span
                  *ngIf="canEdit"
                  tabindex="0"
                  class="chip-item-icon"
                  (click)="removeTag(tag)"
                  (keydown.enter)="removeTag(tag)"
                  [attr.aria-label]="'translations.global.ariaLabels.removeTag' | transloco"
                >
                  <svg class="svg" role="img" aria-hidden="true">
                    <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
                  </svg>
                </span>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="question-form-preview-controls">
      <h2 class="controls-title medium bold">{{ listTitle }}</h2>
      <button
        *ngIf="canEdit"
        mat-stroked-button
        color="accent"
        class="form-preview__footer-submit uppercase"
        [title]="(submitted ? 'translations.cards.form.button.update' : 'translations.cards.form.button.markAsDone') | transloco"
        type="button"
        (click)="submit()"
        [disabled]="isSubmissionDisabled || multiSelectedItems.size === 0"
      >
        <span *ngIf="requiresResubmission">{{ 'translations.cards.form.button.resubmit' | transloco }}</span>
        <span *ngIf="!requiresResubmission && !submitted">{{ 'translations.cards.form.button.markAsDone' | transloco }}</span>
        <span *ngIf="!requiresResubmission && submitted">{{ 'translations.cards.form.button.update' | transloco }}</span>
      </button>

      <div class="question-form-preview__top-icon-box">
        <button
          *ngIf="!canEdit"
          class="question-form-preview__top-icon non-saved non-editable"
          [attr.aria-label]="'translations.global.ariaLabels.icons.lock' | transloco"
          [matTooltip]="canNotEditReason"
        >
          <svg class="svg" role="img" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
          </svg>
        </button>
        <button
          *ngIf="canEdit"
          class="question-form-preview__top-icon editable"
          [attr.aria-label]="'translations.global.ariaLabels.icons.edit' | transloco"
        >
          <svg class="svg" role="img" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="form-preview-notes" *ngIf="formData?.content?.notes?.length">
    <div class="note-item" *ngFor="let note of formData?.content?.notes">
      <div class="note-item-inner">
        <div class="note-message small">
          <ptl-resource-preview class="thin-preview" [previewContent]="note.message"> </ptl-resource-preview>
        </div>
      </div>
      <div class="note-added-by extra-small italic">{{ note.addedBy }}, {{ note.addedOn | dateFormat: 'DD MMM yyyy HH:mm' }}</div>
    </div>
  </div>
</div>
<ptl-form-update-footer
  [submitted]="submitted"
  [canEdit]="canEdit"
  [learnerFormAnswer]="learnerFormAnswer"
  [formType]="formData?.content?.type"
  [playlistSubmissionDeadline]="userPlaylistSubmissionSummary?.submissionDeadline"
>
</ptl-form-update-footer>
