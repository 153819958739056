import { Injectable } from '@angular/core';
import { GroupListDataService } from './group-list-data.service';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { RestClientService } from '../rest-client.service';
import { ObservableResult } from '../../store';
import { HierarchicalList } from '../../models';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class ApiGroupListDataService implements GroupListDataService {
  private groupListsUrl = Location.joinWithSlash(environment.apiRootUrl || '', 'group-lists');

  constructor(private client: RestClientService) {}

  createGroupList(title: string): ObservableResult<HierarchicalList> {
    const request = {
      title: title,
    };
    return this.client.post<HierarchicalList>(this.groupListsUrl, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(JSON.stringify(err))),
    );
  }

  addGroupListElement(listUid: string, parentUid: string, groupUid: string): ObservableResult<HierarchicalList> {
    const request = {
      parentUid: parentUid,
      value: groupUid,
    };
    return this.client.post<HierarchicalList>(`${this.groupListsUrl}/${listUid}/elements`, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(JSON.stringify(err))),
    );
  }

  removeGroupListElement(listUid: string, elementUid: string): ObservableResult<void> {
    return this.client.delete<void>(`${this.groupListsUrl}/${listUid}/elements/${elementUid}`).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(JSON.stringify(err))),
    );
  }

  uploadCsv(file: File): ObservableResult<HierarchicalList> {
    const formData = new FormData();
    formData.append('file', file);
    return this.client.post<HierarchicalList>(`${this.groupListsUrl}/upload`, formData).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(JSON.stringify(err))),
    );
  }
}
