/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MediaContent, MediaUrlData } from '../../../../models';

@Component({
  selector: 'ptl-media-embeds',
  templateUrl: './media-embeds.component.html',
  styleUrls: ['./media-embeds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaEmbedsComponent implements AfterViewInit, OnChanges {
  private _contentItem: MediaUrlData | undefined;
  private _canRemoveItem = true;

  @Input()
  set contentItem(value: MediaUrlData | undefined) {
    if (typeof value === 'string') {
      this._contentItem = JSON.parse(decodeURIComponent(value));
    } else {
      this._contentItem = value;
    }
  }

  get contentItem(): MediaUrlData | undefined {
    return this._contentItem;
  }

  @Input()
  set canRemoveItem(value) {
    if (typeof value === 'string') {
      this._canRemoveItem = JSON.parse(decodeURIComponent(value));
    } else {
      this._canRemoveItem = value;
    }
  }

  get canRemoveItem() {
    return this._canRemoveItem;
  }

  @Input() newEditor = false;

  /** Outputs removing of the component */
  @Output() removeMediaContent = new EventEmitter<void>();

  /** Outputs the new position of the HTML element. */
  @Output() updateMediaPosition = new EventEmitter<string>();

  @Output() mediaContentReplaced = new EventEmitter<MediaContent>();

  @Output() regularLinkReplaced = new EventEmitter<string>();

  @ViewChild('oembedEl', { static: true }) public oembedEl: ElementRef | undefined;

  constructor(private renderer2: Renderer2) {}

  ngAfterViewInit() {
    if (this.contentItem && !!(this.contentItem as MediaUrlData).contentHtml) {
      this.parseAndAppendHtml((this.contentItem as MediaUrlData).contentHtml);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.oembedEl && this.contentItem && !!(this.contentItem as MediaUrlData).contentHtml) {
      // if contentHtml from previousValue is different from currentValue, remove el and append again
      if (
        changes &&
        changes.contentItem &&
        !!changes.contentItem.previousValue &&
        (changes.contentItem.previousValue as MediaUrlData).contentHtml !== (changes.contentItem.currentValue as MediaUrlData).contentHtml
      ) {
        this.renderer2.removeChild(this.oembedEl.nativeElement, this.oembedEl.nativeElement.firstChild);
        this.parseAndAppendHtml((this.contentItem as MediaUrlData).contentHtml);
      }
    }
  }

  parseAndAppendHtml(html: string) {
    // createContextualFragment enables the execution of <script> if present in our html
    if (this.oembedEl) {
      const fragment = document.createRange().createContextualFragment(html);
      this.oembedEl.nativeElement.appendChild(fragment);
    }
  }

  removeMedia(): void {
    this.removeMediaContent.emit();
  }

  onUpdateMediaPosition(position: string): void {
    this.updateMediaPosition.emit(position);
  }

  onReplaceMediaContent(media: MediaContent): void {
    this.mediaContentReplaced.emit(media);
  }

  onReplaceRegularLink(link: string): void {
    this.regularLinkReplaced.emit(link);
  }
}
