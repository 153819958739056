import { Notification } from '../notifications/notifications.model';

export enum WebSocketTopic {
  DEFAULT = '/user/queue/default',
  REVIEW_NUMBER_REFRESH = '/user/queue/reviewer/review_number_refresh',
}

export class PushNotificationMessage {
  header: string;
  message: string;
  notification?: Notification;
}
