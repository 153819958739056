/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../shared/store';
import { Injectable } from '@angular/core';
import {
  FolioAccountVerificationRequest,
  PersonalDataRequest,
  UserNotificationsSettingsRequest,
  UserPrivacySettingsRequest,
  AlumniRequest,
  AlumniUploadCsvRequest,
  AlumniUploadCsvResponse,
  TokenVerification,
  EmailResponse,
  UsersUploadCsvRequest,
  UsersUploadCsvResponse,
} from '../../../shared/models/account/account.model';
import { AccountDataService } from './account-data.service';
import { DEFAULT_LANGUAGE_CODE } from '../../../shared/services/languages/language.service';
import { UserDetailsSummary } from '../../../user-auth/models';

@Injectable()
export class MockAccountDataService implements AccountDataService {
  private personalData: UserDetailsSummary = {
    uid: 'userUid',
    email: 'some@emai.com',
    firstName: 'User first name',
    lastName: 'User last name',
    imageUrl: 'assets/profile_image.png',
    eptid: '1231qbwer2312b3452234',
    description: [
      {
        languageCode: DEFAULT_LANGUAGE_CODE,
        value: 'Who I am?',
      },
    ],
    recoveryEmail: 'recovery@email.com',
    userSettings: {
      privacy: {
        folioPrivacyMode: undefined,
        folioCommentsMode: undefined,
      },
      notifications: {
        eventEmailSettings: {
          enabled: undefined,
          groups: undefined,
        },
        periodicEmailSettings: {
          enabled: undefined,
          contents: undefined,
        },
      },
      language: DEFAULT_LANGUAGE_CODE,
    },
    roles: [
      {
        type: 'SUPER_ADMIN',
        _id: '123123',
        name: [{ languageCode: DEFAULT_LANGUAGE_CODE, value: 'name' }],
        privileges: [
          {
            assigned: true,
            name: 'some-name',
            _id: 'someId',
            label: [{ languageCode: DEFAULT_LANGUAGE_CODE, value: 'label' }],
            privilegeGroup: 'SUPER_ADMIN',
          },
        ],
      },
      {
        type: 'ADMIN',
        _id: '123123',
        name: [{ languageCode: DEFAULT_LANGUAGE_CODE, value: 'name' }],
        privileges: [
          {
            assigned: true,
            name: 'some-name',
            _id: 'someId',
            label: [{ languageCode: DEFAULT_LANGUAGE_CODE, value: 'label' }],
            privilegeGroup: 'ADMIN',
          },
        ],
      },
    ],
    workspaces: [
      {
        name: 'Lboro',
        domain: 'lboro.yuna.dev.potential.ly',
        logo: '',
        site: 'lboro.yuna.dev.potential.ly',
        organizationUid: 'organizationUid',
        type: 'EDUCATIONAL',
        memberDetails: {
          email: 'admin@potantial.ly',
          firstName: 'Admin',
          lastName: 'Potentially',
          imageUrl: '',
          status: 'ACTIVE',
          hasUsedCpd: false,
          isCpdConnected: false,
        },
      },
    ],
    organization: { _id: 'org', name: 'Lboro' },
  };

  getPersonalData(): ObservableResult<UserDetailsSummary> {
    return ObservableResult.ofSuccess(this.personalData);
  }

  updatePersonalData(userData: PersonalDataRequest): ObservableResult<UserDetailsSummary> {
    return ObservableResult.ofSuccess();
  }

  resetUserPassword(): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  resetPasswordByEmail(email: string, captchaResponseV3?: string, captchaResponseV2?: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  resetUserEmail(): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  getEmailByToken(tokenVerification: TokenVerification): ObservableResult<EmailResponse> {
    return ObservableResult.ofSuccess();
  }

  resetUserRecoveryEmail(email: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateUserPassword(password: string, captchaResponseV3?: string, captchaResponseV2?: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateUserEmail(email: string, captchaResponseV3?: string, captchaResponseV2?: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateUserRecoveryEmail(token: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateUserPrivacy(request: UserPrivacySettingsRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateUserNotifications(request: UserNotificationsSettingsRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  connectUserToWorkspace(email: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  connectUniversityEmail(universityEmail: string, folioAccountInitialization: boolean): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  leaveUserFromWorkspace(organizationUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  verifyWorkspaceEmail(request: FolioAccountVerificationRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  confirmWorkspaceEmailWithPassword(request: FolioAccountVerificationRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  alumniRequest(request: AlumniRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  alumniUploadCsv(request: AlumniUploadCsvRequest): ObservableResult<AlumniUploadCsvResponse> {
    return ObservableResult.ofSuccess();
  }

  usersUploadCsv(request: UsersUploadCsvRequest): ObservableResult<UsersUploadCsvResponse> {
    return ObservableResult.ofSuccess();
  }

  setLanguage(code: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateJiscUsage(hasUsedCpd: boolean): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
