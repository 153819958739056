/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { JiscOrganization, Organization, OrganizationAuthType } from '../../../shared/models/organization';
import { TriggerUserLogin } from '../../store/user-auth.actions';
import { UserAuthState } from '../../store/user-auth.state';
import { filter } from 'rxjs/operators';
import { USER_IS_LOGGED_IN } from '../../services/api-basic-auth.service';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import {
  ORGANIZATION_RETRIEVAL_DATA_SERVICE,
  OrganizationRetrievalDataService,
} from '@app/app/shared/services/organization-retrieval/organization-retrieval-data.service';
import { canTriggerSearch } from '../../../shared/helpers/content-helper';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { getClientId } from '../../../shared/helpers/development-domains.helper';
import { REDIRECT_AFTER_LOGIN } from '../../../shared/constants/constants';
import { CookieHelper } from '../../../shared/helpers/cookie-helper';
import { MaintenanceService } from '../../../shared/services/maintenance/maintenance.service';

@Component({
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss', './jisc-login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  @Select(UserAuthState.isLoginInProgress)
  loginInProgress$: Observable<boolean>;

  @Select(UserAuthState.userPreviouslyLoggedIn)
  private userPreviouslyLoggedIn$: Observable<boolean>;
  userPreviouslyLoggedIn: boolean;

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @Select(UserAuthState.homePageUri)
  homePageUri$: Observable<string>;

  /** Emits when users click 'Not a member?'. */
  @Output() showSignupForm = new EventEmitter<void>();

  /** The authentication form details. */
  authenticationDetails: FormGroup;

  // Defines if the password should be shown to the user
  showPasswordSelected = false;

  organization: Organization;
  organizations: JiscOrganization[] = [];

  isSamlAuthType: boolean;
  isFormAuthType: boolean;
  isSocialAuthType: boolean;
  isHiddenLoginPage: boolean;
  allowAlumniLogin: boolean;
  isJiscLogin: boolean;
  loadingOrganizations: boolean;
  isLoadingMore: boolean;
  jiscSelectedOrganization: JiscOrganization;

  showMaintenanceOverlay: boolean;

  private subscription: Subscription;
  private previousLoginSubscription: Subscription;
  private homePageUri: string;

  private searchString: string;
  private organizationPage = 0;
  private organizationSize = 10;
  private canLoadMore: boolean;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private maintenanceService: MaintenanceService,
    @Inject(ORGANIZATION_RETRIEVAL_DATA_SERVICE) private organizationRetrievalService: OrganizationRetrievalDataService,
  ) {
    this.showMaintenanceOverlay = this.maintenanceService.showMaintenanceMessage();

    this.authenticationDetails = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.homePageUri$.pipe(filter((data) => !!data)).subscribe((data) => (this.homePageUri = data));
    this.subscription = combineLatest([this.route.queryParams, this.organizationData$])
      .pipe(filter((data) => !!data[0] && !!data[1]))
      .subscribe((data) => {
        const params = data[0];
        this.organization = data[1];

        this.isHiddenLoginPage = document.location.pathname === '/support/signin';
        this.isSocialAuthType = this.organization?.authType === OrganizationAuthType.SOCIAL;
        this.isFormAuthType = this.checkIsFormAuthType();
        this.isSamlAuthType = this.checkIsSamlAuthType();
        this.allowAlumniLogin = this.checkAlumniLogin(this.organization?.domain);
        this.isJiscLogin = this.isJiscPage(this.organization?.domain);

        // Preventing jisc login view for support signin users
        if (this.isHiddenLoginPage && this.isJiscLogin) {
          this.isJiscLogin = false;
        }
        if (this.isJiscLogin) {
          this.loadingOrganizationsList(true);
        }

        if (params['email']) {
          this.authenticationDetails.controls['email'].setValue(params['email']);
        }

        if (!this.isHiddenLoginPage) {
          if (this.organization?.authType === OrganizationAuthType.SAML && (params['idp'] === 'true' || params['idp'] === '1')) {
            if (localStorage.getItem(USER_IS_LOGGED_IN) === 'true') {
              RedirectHelper.redirectToHomePage(this.ngZone, this.router, this.route, this.homePageUri);
            }
            return this.redirectToSAML();
          }
          if (this.organization?.authType === OrganizationAuthType.SAML_SP && (params['idp'] === 'true' || params['idp'] === '1')) {
            if (localStorage.getItem(USER_IS_LOGGED_IN) === 'true') {
              RedirectHelper.redirectToHomePage(this.ngZone, this.router, this.route, this.homePageUri);
            }
            return this.redirectToSAMLSP();
          }
        }
      });

    this.previousLoginSubscription = this.userPreviouslyLoggedIn$.subscribe((data) => {
      if (this.userPreviouslyLoggedIn !== false) {
        this.userPreviouslyLoggedIn = data;
      }
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.previousLoginSubscription?.unsubscribe();
  }

  onSaveClick() {
    if (this.isFormAuthType) {
      return this.submitForm();
    }

    this.checkAndSetJiscDeepLink();
    switch (this.organization.authType) {
      case OrganizationAuthType.SAML:
        return this.redirectToSAML();
      case OrganizationAuthType.SAML_SP:
        if (this.isJiscLogin) {
          return this.redirectToJiscSAMLSP();
        } else {
          return this.redirectToSAMLSP();
        }
      case OrganizationAuthType.SOCIAL:
        // TODO:
        return;
    }
  }

  signInUsingLinkedIn() {
    this.redirectToIdpViaRealm('linkedin', this.organization.domain);
  }

  signInUsingGoogle() {
    this.redirectToIdpViaRealm('google', this.organization.domain);
  }

  onNotMemberClick() {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.route, '/signup');
  }

  onForgotPassword() {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.route, '/forgot-password');
  }

  openAlumniLogin() {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.route, '/graduate/signin');
  }

  openPrearrivalsLogin() {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.route, '/prearrivals/signin');
  }

  onTextPaste(event: ClipboardEvent) {
    if (event.type === 'paste') {
      setTimeout(() => {
        this.loadingOrganizations = true;
        this.searchString = (event.target as HTMLInputElement).value;
        this.loadingOrganizationsList(true);
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent) {
    if (canTriggerSearch(event)) {
      this.loadingOrganizations = true;
      this.searchString = (event.target as HTMLInputElement).value;
      this.loadingOrganizationsList(true);
    }
  }

  displayAutocomplete(value: Organization): string {
    return value.name;
  }

  onOrganizationSelected({ option }: MatAutocompleteSelectedEvent) {
    this.jiscSelectedOrganization = option.value;
  }

  onAutocompleteScroll() {
    if (!this.canLoadMore) {
      return;
    }
    if (!this.isLoadingMore) {
      this.isLoadingMore = true;
      this.loadingOrganizationsList(false);
    }
  }

  private checkIsFormAuthType(): boolean {
    if (this.organization?.authType === OrganizationAuthType.FORM || this.organization?.authType === OrganizationAuthType.LDAP) {
      return true;
    }
    if (this.isHiddenLoginPage || this.isSocialAuthType) {
      return true;
    }
    return false;
  }

  private checkIsSamlAuthType(): boolean {
    return this.organization?.authType === OrganizationAuthType.SAML || this.organization?.authType === OrganizationAuthType.SAML_SP;
  }

  private checkAndSetJiscDeepLink() {
    if (this.isJiscLogin && this.jiscSelectedOrganization.jiscDeepLink) {
      CookieHelper.SetCookie(REDIRECT_AFTER_LOGIN, this.jiscSelectedOrganization.jiscDeepLink, 1);
    }
  }

  private loadingOrganizationsList(override: boolean) {
    this.organizationPage = override ? 0 : this.organizationPage + 1;
    this.organizationRetrievalService
      .searchJiscOrganizations(this.organizationPage, this.organizationSize, this.searchString)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          if (override) {
            this.organizations = value.content;
          } else {
            this.organizations = this.organizations.concat(value.content);
          }
          this.canLoadMore = value.totalNumberOfElement > this.organizations.length;
        }
        this.loadingOrganizations = false;
        this.isLoadingMore = false;
      });
  }

  private checkAlumniLogin(domain: string) {
    if (!domain) {
      return false;
    }
    return [
      'cardiff.yuna.dev.potential.ly',
      'cardiff.potential.ly',
      'cardiffmet.yuna.dev.potential.ly',
      'cardiffmet.potential.ly',
      'lboro.yuna.dev.potential.ly',
      'lboro.potential.ly',
      'bcu.yuna.dev.potential.ly',
      'bcu.potential.ly',
    ].includes(domain);
  }

  private isJiscPage(domain: string) {
    if (!domain) {
      return false;
    }
    return ['jisc.yuna.dev.potential.ly', 'jisc.yuna.potential.ly', 'jisc.potential.ly'].includes(domain);
  }

  private submitForm() {
    if (this.authenticationDetails.valid) {
      this.checkAndSetJiscDeepLink();
      this.store.dispatch(
        new TriggerUserLogin(
          this.authenticationDetails.get('email').value?.trim(),
          this.authenticationDetails.get('password').value?.trim(),
        ),
      );
    }
  }

  private redirectToSAML() {
    const idp = this.organization.idp || this.organization.domain;
    this.redirectToIdpViaRealm(idp, this.organization.domain);
  }

  private redirectToIdpViaRealm(idp: string, orgDomain: string) {
    document.location.href =
      `${environment.realmUrl}protocol/openid-connect/auth?` +
      `client_id=${getClientId(orgDomain)}&` +
      'response_type=token&' +
      `nonce=${environment.nonce}&` +
      `kc_idp_hint=${idp}&` +
      `redirect_uri=${this.getRedirectUri(orgDomain)}`;
  }

  private redirectToSAMLSP() {
    const orgId = this.organization._id;
    this.redirectToIdpViaRealmSamlSP(orgId);
  }

  private redirectToJiscSAMLSP() {
    if (this.jiscSelectedOrganization) {
      if (this.jiscSelectedOrganization.authType === 'FORM') {
        document.location.href = `https://${this.jiscSelectedOrganization.domain}/signin`;
      } else if (this.jiscSelectedOrganization.authType === 'SAML') {
        const idp = this.jiscSelectedOrganization.idp || this.jiscSelectedOrganization.domain;
        this.redirectToIdpViaRealm(idp, this.jiscSelectedOrganization.domain);
      } else {
        this.redirectToIdpViaRealmSamlSP(this.jiscSelectedOrganization.id);
      }
    }
  }

  private redirectToIdpViaRealmSamlSP(orgId: string) {
    document.location.href = `${environment.samlSpHost}saml/auth?orgId=${orgId}`;
  }

  private getRedirectUri(orgDomain: string) {
    if (window.location.hostname.includes('localhost')) {
      return 'http://localhost:4200';
    }
    return `https://${orgDomain}`;
  }
}
