<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="file-upload-dialog-container">
  <div class="dialog-top-info">
    <h1 *ngIf="dialogTypeMapping[type]" class="dialog-title">
      {{ dialogTypeMapping[type] | transloco }}
    </h1>
    <button
      class="close-dialog-btn"
      mat-icon-button
      color="primary"
      (click)="closeDialog()"
      [attr.aria-label]="'translations.global.ariaLabels.closeDialog'"
    >
      <svg class="svg" role="img">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>
  <div
    ogFileDrop
    class="upload-centered-container"
    [class.upload-centered-container--active]="filesHovered"
    (filesDropped)="onFileDropped($event)"
    (filesHovered)="filesHovered = $event"
    (click)="triggerFileSelection()"
    (keydown.enter)="triggerFileSelection()"
  >
    <div class="upload-centered-icon">
      <svg class="svg svg-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <use ogSvgUnify="assets/sprite/sprite.svg#file-upload"></use>
      </svg>
    </div>
    <div class="upload-centered-description">
      <input #uploadFolioInput id="uploadFolioCover" type="file" class="file-input" [accept]="accept" (change)="onFileSelected($event)" />
      <p class="">
        <span class="upload-centered-description--highlight">{{ 'translations.dialog.title.clickToUpload' | transloco }}</span>
        {{ 'translations.dialog.title.orDragAndDrop' | transloco }}
      </p>
      <p class="upload-centered-description--supported-formats">
        {{
          'translations.dialog.title.supportedFormats'
            | transloco
              : {
                  threeSupportedFormats: threeSupportedFormats,
                  fourthSupportedFormat: fourthSupportedFormat,
                }
        }}
        ({{ 'translations.dialog.title.maxSize' | transloco: { maxFileSizeMB: maxFileSizeMB } }})
      </p>

      <p class="upload-centered-description--error-text" *ngIf="uploadErrorText">{{ uploadErrorText }}</p>
    </div>
  </div>
  <div class="dialog-footer-buttons">
    <button mat-flat-button type="button" class="cancel-button" color="accent" (click)="closeDialog()">
      {{ 'translations.global.button.cancel' | transloco }}
    </button>
  </div>
</div>
