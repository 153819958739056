<!--
  ~ Copyright (C) 2025 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="dialog-top-info">
  <button
    class="close-dialog-btn"
    mat-icon-button
    color="primary"
    (click)="closeDialog()"
    [attr.aria-label]="'translations.global.ariaLabels.closeDialog' | transloco"
  >
    <svg class="svg" role="img" aria-hidden="true">
      <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
    </svg>
  </button>
</div>
<ptl-add-framework-tags class="framework-tracker-dialog-container" [isDialogPage]="true" (addTagBulk)="onAddTags($event)">
</ptl-add-framework-tags>
