<div class="users-csv-upload-errors-dialog-content">
  <div class="dialog-top-info">
    <h1 class="dialog-title">{{ 'translations.membersNew.controlPanel.usersUpload.csvUploadErrors' | transloco }}</h1>
    <button
      class="close-dialog-btn"
      mat-icon-button
      color="primary"
      (click)="closeDialog()"
      [attr.aria-label]="'translations.global.ariaLabels.closeDialog'"
    >
      <svg class="svg" role="img" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>

  <div class="users-csv-upload-errors-content">
    <div class="errors-content" *ngIf="csvHeader && uploadErrors.length">
      <div class="table">
        <div class="table-row">
          <div class="table-cell reason-cell">{{ 'translations.membersNew.controlPanel.usersUpload.reason' | transloco }}</div>
          <div class="table-cell" *ngFor="let header of csvHeader">{{ header }}</div>
        </div>
        <div class="table-row" *ngFor="let row of csvRows; let i = index">
          <div class="table-cell reason-cell">{{ uploadErrors[i].reason }}</div>
          <div class="table-cell" *ngFor="let item of row">{{ item }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-footer-buttons">
    <button mat-flat-button type="button" class="cancel-button" color="accent" (click)="closeDialog()">
      {{ 'translations.global.button.cancel' | transloco }}
    </button>

    <button mat-flat-button type="button" class="submit-button" color="warn" (click)="downloadErrorsCSV()">
      {{ 'translations.membersNew.controlPanel.usersUpload.button.downloadErrorsCSV' | transloco }}
    </button>
  </div>
</div>
