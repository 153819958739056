import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { LocalTimeHelper } from '../../../helpers/local-time-helper';
import { ActivityRequest, BooleanFilter, BooleanQueryRequest, BooleanQueryType } from '../../../models/admin/boolean-filters.model';
import { TranslocoService } from '@ngneat/transloco';
import { ComponentDisplayMode } from '@app/app/shared/components/date-range-picker/date-range-picker.component';
import { marker } from '@jsverse/transloco-keys-manager/marker';

@Component({
  selector: 'ptl-activity-date-filter',
  templateUrl: './activity-date-filter.component.html',
  styleUrls: ['./activity-date-filter.component.scss'],
})
export class ActivityDateFilterComponent implements OnInit {
  @Input() selectedFilters: BooleanFilter[];
  @Output() filterDeleted = new EventEmitter<void>();
  @Output() filterSaved = new EventEmitter<BooleanFilter>();

  expanded = true;
  activityIs = true;
  filterType: 'SINCE' | 'FOR_THE_LAST' | 'IN_RANGE' = 'SINCE';

  date: Date;
  periodType: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR' = 'DAY';
  quantity = 10;

  startDate: Date;
  endDate: Date;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit() {
    this.date = new Date(new Date().setUTCHours(0, 0, 0, 0));
    this.getSettingsFromFilters();
  }

  dateChanged(date: Date) {
    this.date = date;
  }

  deleteFilter() {
    this.filterDeleted.emit();
  }

  setActivityIs() {
    this.activityIs = true;
  }

  setActivityIsNot() {
    this.activityIs = false;
  }

  setFilterType(filterType: 'SINCE' | 'FOR_THE_LAST' | 'IN_RANGE') {
    this.filterType = filterType;
  }

  applyFilter() {
    const request = this.getRequest();
    const message = this.getMessage();
    this.filterSaved.emit({ request: request, message: message, type: 'ACTIVITY' });
  }

  onDateRangeSelected($event: { startDate: Date; endDate: Date }) {
    this.startDate = $event.startDate;
    this.endDate = $event.endDate;

    this.applyFilter();
  }

  private getRequest(): BooleanQueryRequest {
    const request: ActivityRequest = {
      type: BooleanQueryType.ACTIVITY,
      operator: this.activityIs ? 'AFTER' : 'BEFORE',
      time: this.getRequestTime().format(),
      startDate: this.filterType === 'IN_RANGE' ? moment(this.startDate).format() : undefined,
      endDate: this.filterType === 'IN_RANGE' ? moment(this.endDate.setHours(23, 59, 59, 59)).format() : undefined,
      frontendType: this.filterType,
      periodType: this.periodType,
      quantity: this.quantity,
    };
    return request as BooleanQueryRequest;
  }

  private getMessage(): string {
    const activityTranslation = this.activityIs ? marker('translations.filter.has') : marker('translations.filter.hasNot');

    const pre = `${this.translocoService.translate(activityTranslation)} ${this.translocoService.translate('translations.filter.activity.beenActive')}`;

    switch (this.filterType) {
      case 'SINCE':
        return `${pre} ${this.translocoService.translate('translations.filter.activity.since')} ${LocalTimeHelper.getLocalDateTime(
          this.date,
        ).format('D MMM YYYY')}`;

      case 'FOR_THE_LAST':
        return `${pre} ${this.translocoService.translate('translations.filter.activity.forTheLast')} ${this.quantity} ${this.getForTheLastMessageEnding()}`;

      case 'IN_RANGE':
        return this.translocoService
          .translate(
            this.activityIs
              ? marker('translations.filter.activity.hasBeenActive')
              : marker('translations.filter.activity.hasNotBeenActive'),
          )
          .replace('{startDate}', this.formatDate(this.startDate))
          .replace('{endDate}', this.formatDate(this.endDate));

      default:
        return '';
    }
  }

  private formatDate(date: Date | null): string {
    if (!date) return '';
    return LocalTimeHelper.getLocalDateTime(date).format('MMM D, YYYY');
  }

  private getForTheLastMessageEnding(): string {
    const singularPluralPostfix = this.quantity === 1 ? '' : 's';
    switch (this.periodType) {
      case 'DAY':
        return this.translocoService.translate(marker('translations.filter.activity.day') + singularPluralPostfix);
      case 'WEEK':
        return this.translocoService.translate(marker('translations.filter.activity.week') + singularPluralPostfix);
      case 'MONTH':
        return this.translocoService.translate(marker('translations.filter.activity.month') + singularPluralPostfix);
      case 'YEAR':
        return this.translocoService.translate(marker('translations.filter.activity.year') + singularPluralPostfix);
    }
  }

  private getRequestTime() {
    switch (this.filterType) {
      case 'SINCE':
        return moment(this.date);
      case 'FOR_THE_LAST':
        return moment().startOf('day').subtract(this.quantity, this.getPeriodDuration());
      case 'IN_RANGE':
        return moment();
    }
  }

  private getPeriodDuration(): 'days' | 'weeks' | 'months' | 'years' {
    return (this.periodType.toLowerCase() + 's') as 'days' | 'weeks' | 'months' | 'years';
  }

  private getSettingsFromFilters() {
    if (this.selectedFilters) {
      const activityFilter = this.selectedFilters.find((f) => f.type === 'ACTIVITY');
      if (activityFilter) {
        const booleanRequest = activityFilter.request as ActivityRequest;
        this.activityIs = booleanRequest.operator === 'AFTER';
        if (booleanRequest.frontendType === 'FOR_THE_LAST') {
          this.filterType = 'FOR_THE_LAST';
          this.periodType = booleanRequest.periodType;
          this.quantity = booleanRequest.quantity;
        } else if (booleanRequest.frontendType === 'SINCE') {
          this.filterType = 'SINCE';
          this.date = new Date(booleanRequest.time);
        } else if (booleanRequest.frontendType === 'IN_RANGE') {
          this.filterType = 'IN_RANGE';
          this.startDate = new Date(booleanRequest.startDate);
          this.endDate = new Date(booleanRequest.endDate);
        }
      }
    }
  }

  protected readonly ComponentDisplayMode = ComponentDisplayMode;
}
