/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { SideNavItemIcon } from '../side-nav/side-nav.model';

export interface SidebarSection {
  /** The section title. */
  title?: string;
  /** The section title id. used for getting translation working with title */
  titleId?: string;
  /** The section icon. */
  icon?: SideNavItemIcon;
  /** Defines if a line separator should be displayed at the top of the side nav section. */
  displayTopLineSeparator?: boolean;
  /** Defines if a line separator should be displayed at the bottom of the side nav section. */
  displayBottomLineSeparator?: boolean;
  /** The section children. */
  cards?: SidebarCard[];
  /** The section static links. */
  staticLinks?: SidebarStaticLinks[];
  /** The section allowed link roles */
  allowedRoles?: string[];
  /** The section additional class */
  class?: string;
  /** The section show more button. */
  hasShowMore?: boolean;

  /** The section child level. */
  level?: number;
}

export interface SidebarCard extends LeftNavItemSummary {
  // frontend specific
  parentId: string;
  expanded?: boolean;
  hasChild?: boolean;
  class?: string;
  cards?: SidebarCard[];
  titleId?: string;
  uri?: string;
  isActive?: boolean;
  displayTopLineSeparator?: boolean;
}

export interface LeftNavItemSummary {
  _id: string;
  title: string;
  formattedUri: string;
  type: LeftNavItemType;
  showOnLeftNav: boolean;
  published: boolean;
  // using this uri to send api request for loading children
  requestUri?: string;
}

export interface SidebarStaticLinks {
  title: string;
  titleId: string;
  uri: string;
  icon?: SideNavItemIcon;
  preventRedirection?: boolean;
  allowedRoles?: string[];
  allowedPermissions?: string[];
  versionCondition?: VersionCondition;
  allowedPrivileges?: string[];
  hasLoader?: boolean;
}

export type LeftNavItemType =
  | 'PAGE'
  | 'HOME_PAGE'
  | 'RESOURCE'
  | 'ASSESSMENT'
  | 'GROUP'
  | 'PERSONALITY_INDICATOR'
  | 'PLAYLIST'
  | 'WEBLINK'
  | 'EVENT'
  | 'PROJECT'
  | 'CATEGORY'
  | 'EXPLORE_FILTER';

export enum VersionConditionType {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
  LESS_OR_EQUAL = 'LESS_OR_EQUAL',
}

export interface VersionCondition {
  type: VersionConditionType;
  version: number;
}
