/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { createQuiz, Quiz, QuizSettings, UpdateQuizRequest } from '@app/app/shared/models/editor/quiz-content.model';
import { DATA_SERVICE, QuizDataService } from '../../../services/data.service';
import { Select, Store } from '@ngxs/store';
import { Form, LanguageValue, Organization, QuizFormContent } from '@app/app/shared/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarHelper } from '@app/app/shared/helpers/snackbar-helper';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { Observable } from 'rxjs';
import { CurrentLanguage } from '@app/app/page-modules/resource/store/admin/resource-event-admin.state.model';
import { take, takeUntil } from 'rxjs/operators';
import { UserAuthState } from '@app/app/user-auth/store/user-auth.state';
import { SupportedLanguage } from '@app/app/shared/models/languages/languages.model';
import { ResourceAdminState } from '@app/app/page-modules/resource/store/admin/resource-admin.state';

@Component({
  selector: 'ptl-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['../form/form.component.scss', './quiz.component.scss'],
})
export class QuizComponent implements OnInit, OnChanges, OnDestroy {
  @Input() quizContent: Form<QuizFormContent>;

  private _canRemoveItem: boolean | undefined;

  @Input()
  set form(value: Form<QuizFormContent> | undefined) {
    if (typeof value === 'string') {
      this.quizContent = JSON.parse(decodeURIComponent(value));
    } else {
      this.quizContent = value;
    }
  }

  get form() {
    return this.quizContent;
  }

  @Input()
  set canRemoveItem(value) {
    if (typeof value === 'string') {
      this._canRemoveItem = JSON.parse(decodeURIComponent(value));
    } else {
      this._canRemoveItem = value;
    }
  }

  get canRemoveItem() {
    return this._canRemoveItem;
  }

  @Select(ResourceAdminState.currentLanguage)
  private currentLanguage$: Observable<CurrentLanguage>;

  @Select(UserAuthState.organizationDetails)
  private organization$: Observable<Organization>;

  /** Emits removing of the quizContent event */
  @Output() removeQuizContent = new EventEmitter<void>();

  /** Emits the update of the quizContent event */
  @Output() updateQuizContent = new EventEmitter<Form<QuizFormContent>>();

  quiz: Quiz = createQuiz();
  languageCode: string;
  currentLanguage: SupportedLanguage;
  quizTitle: LanguageValue;
  quizChanged = false;
  resourceUid: string;
  titleChanged = false;
  expanded = false;
  organization: Organization;
  focused = false;
  saveInProgress = false;
  forceExpand = false;

  private subscriptionEnd$ = new EventEmitter<void>();
  private quizLoaded = false;

  constructor(
    @Inject(DATA_SERVICE) private dataService: QuizDataService,
    private cd: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private store: Store,
  ) {
    this.resourceUid = this.store.selectSnapshot(ResourceAdminState.resourceUid);
  }

  ngOnInit() {
    this.currentLanguage$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((language) => {
      if (language) {
        this.currentLanguage = language.supportedLanguage;
        this.languageCode = language.supportedLanguage?.language?.code;
        if (this.quizContent?.content?.quizId && !this.quizLoaded) {
          this.getQuizData(this.quizContent.content.quizId);
          this.quizLoaded = true;
        }
      }
    });
    this.organization$.pipe(take(1)).subscribe((org) => (this.organization = org));
  }

  ngOnDestroy() {
    this.subscriptionEnd$.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.quizContent && changes.quizContent.previousValue && changes.quizContent.currentValue) {
      const currentQuizUid = changes.quizContent?.currentValue?.content.quizId;
      const previousQuizUid = changes.quizContent?.previousValue?.content.quizId;

      // Handle language change
      if (this.quizLoaded && currentQuizUid === previousQuizUid) {
        this.getQuizData(currentQuizUid);
      }
      // Handle case when previous state undefined e.g. creating new quiz
      if (previousQuizUid === undefined && previousQuizUid !== currentQuizUid) {
        this.getQuizData(currentQuizUid);
      }
    }
  }

  saveQuiz() {
    if (this.quizChanged) {
      this.quizChanged = false;
      if (this.titleChanged) {
        this.titleChanged = false;
        this.quizContent = {
          type: 'FORM',
          newAddedForm: false,
          uid: this.quizContent.uid ? this.quizContent.uid : null,
          content: {
            title: this.quizTitle ? this.quizTitle['value'] : null,
            quizId: this.quiz._id,
            type: 'QUIZ',
            submitted: false,
            formIsActive: false,
          },
        };

        this.updateQuizContent.emit(this.quizContent);
      }
      const request = {
        title: this.quizTitle,
        instruction: this.quiz.instruction,
        settings: this.quiz.settings,
      } as UpdateQuizRequest;
      this.saveInProgress = true;
      this.dataService.updateQuiz(this.resourceUid, this.quiz._id, request, this.languageCode).subscribe(({ isSuccess, value, error }) => {
        this.saveInProgress = false;
        if (isSuccess) {
          this.quiz = value;
          this.quizTitle = LanguageCodeHelper.getDataByUserLanguageCode(
            this.quiz.title as LanguageValue[],
            this.organization,
            this.languageCode,
          );
          this.expanded = false;
          this.forceExpand = false;
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error, 4000);
          this.quizChanged = true;
        }
      });
    }

    this.expanded = false;
    this.forceExpand = false;
  }

  onQuizElementRemoved() {
    if (this.quiz._id) {
      this.dataService.deleteQuiz(this.resourceUid, this.quiz._id).subscribe();
    }
    this.removeQuizContent.emit();
  }

  onQuizTitleUpdate(value: string) {
    this.titleChanged = true;
    this.quizChanged = true;
    Object.assign(this.quiz.title, {
      languageCode: this.languageCode,
      value,
    });
  }

  onInstructionValueChanged(value: LanguageValue) {
    this.quizChanged = true;
    this.quiz.instruction = value;
  }

  onSettingsValueChanged(value: QuizSettings) {
    this.quizChanged = true;
    this.quiz.settings = value;
  }

  private getQuizData(quizUid: string) {
    this.dataService.getQuiz(this.resourceUid, quizUid, this.languageCode).subscribe(({ isSuccess, value }) => {
      this.quizLoaded = true;
      if (isSuccess) {
        this.quiz = value;
        this.quizTitle = LanguageCodeHelper.getDataByUserLanguageCode(
          this.quiz.title as LanguageValue[],
          this.organization,
          this.languageCode,
        );

        if (this.quiz.instructions.length === 0) {
          this.expanded = true;
        }
      } else {
        this.quizTitle = {
          languageCode: '',
          value: '',
        };
      }
    });
  }
}
