<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngFor="let card of sectionCards; let i = index">
  <ptl-playlist-featured-card
    *ngIf="card.type !== 'LINK'"
    class="cards"
    role="listitem"
    [inHomeLayoutEditor]="inHomeLayoutEditor"
    [card]="card"
    [type]="'VIEW'"
    [size]="size"
    [index]="i"
    (cardClick)="onCardClick($event)"
  >
  </ptl-playlist-featured-card>

  <ptl-playlist-featured-link
    *ngIf="card.type === 'LINK' && (card.isOpenPage || card.hasTargetedAccess || isEditPage)"
    class="cards"
    role="listitem"
    [index]="i"
    [inHomeLayoutEditor]="inHomeLayoutEditor"
    [type]="'VIEW'"
    [size]="size"
    [linkData]="card"
  >
  </ptl-playlist-featured-link>
</ng-container>
