/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  NgZone,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  EmbeddableContentDetails,
  EMBEDS_CONTENT_GENERATOR_SERVICE,
  EmbedsContentGeneratorService,
} from '../../../../../../editor/services/embeds-content-generator.service';
import { ResourceTag } from '../../../../../../shared/models';
import { cloneDeep } from 'lodash-es';
import { CORE_TAG_DATA_SERVICE, TagDataService } from '../../../../../../shared/services/tags/tag-data.service';
import { SpecialEmbedsHelper } from '../../../../../../shared/helpers/embeds/special-embeds.helper';
import { MatDialog } from '@angular/material/dialog';
import { CORE_RESOURCE_DATA_SERVICE, ResourceDataService } from '../../../../../resource/services/editor/core/data.service';
import { FileUploadService, RESOURCES_FILE_UPLOAD_DATA_SERVICE } from '../../../../../../shared/services/file-upload/file-upload.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropDialogComponent } from '../../../../../../shared/components/image-crop-dialog/image-crop-dialog.component';
import { ResourceThumbnailUpdateRequest } from '../../../../../resource/models/editor';
import { ImageUploadNameResolver } from '../../../../../../shared/helpers/image-upload-name-resolver';
import { FileUploadHelper } from '../../../../../../shared/helpers/file-upload-helper';
import { SnackbarHelper } from '../../../../../../shared/helpers/snackbar-helper';
import { TranslocoService } from '@ngneat/transloco';
import { LanguageCodeHelper } from '../../../../../../shared/helpers/language-code-helper';
import { ProjectHelper } from '../../../../../project/helpers/project.helper';
import { FormsHelper } from '@app/app/shared/helpers/forms-helper';

@Component({
  selector: 'ptl-web-link-card-editor',
  templateUrl: './web-link-card-editor.component.html',
  styleUrls: ['./web-link-card-editor.component.scss'],
})
export class WebLinkCardEditorComponent implements OnChanges, AfterViewInit {
  @Input() isInCardEditPage: boolean;
  @Input() content: EmbeddableContentDetails;
  @Input() headline: string;
  @Input() subtitle: string;
  @Input() showUrlError: boolean;
  @Input() isProject: boolean;

  @Output() tagsChanged = new EventEmitter<ResourceTag[]>();
  @Output() subtitleChanged = new EventEmitter<string>();
  @Output() headlineChanged = new EventEmitter<string>();
  @Output() thumbnailChanged = new EventEmitter<File>();
  @Output() externalContentChanged = new EventEmitter<EmbeddableContentDetails>();
  @Output() isButtonDisabled = new EventEmitter<boolean>();

  @ViewChild('titleInput', { static: true }) public titleInput: ElementRef;

  cardHeadline = '';
  cardSubtitle = '';
  imageUrl: string;
  linkUrl = '';
  tags: ResourceTag[] = [];
  frameworkTagsPanelOpen: boolean;
  externalContent: EmbeddableContentDetails;
  thumbnailUrl: string;
  resourceUid: string;

  linkInserted = false;
  linkIsEmpty = false;
  titleIsEmpty = false;

  private linkEdited = false;
  private titleEdited = false;
  private subtitleEdited = false;
  private titleTimeout;
  private subheaderTimeout;
  private oldLinkUrl;

  constructor(
    @Inject(EMBEDS_CONTENT_GENERATOR_SERVICE) private embedsService: EmbedsContentGeneratorService,
    @Inject(CORE_RESOURCE_DATA_SERVICE) private resourceDataService: ResourceDataService,
    @Inject(RESOURCES_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService,
    @Inject(CORE_TAG_DATA_SERVICE) private tagService: TagDataService,
    private translocoService: TranslocoService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
  ) {}

  ngAfterViewInit() {
    if (this.isInCardEditPage && !this.headline) {
      this.titleIsEmpty = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.content?.currentValue && !this.linkEdited) {
      this.externalContent = changes.content.currentValue;

      if (this.externalContent) {
        this.imageUrl = this.externalContent.thumbnailUrl;
        this.linkUrl = this.externalContent.url;
        this.linkInserted = !!this.linkUrl;
        this.oldLinkUrl = cloneDeep(this.externalContent.url);
      }
    }

    if (changes.showUrlError) {
      this.linkIsEmpty = changes.showUrlError.currentValue;
    }

    if (changes.headline && !this.titleEdited) {
      this.cardHeadline = changes.headline.currentValue;
    }

    if (changes.subtitle && !this.subtitleEdited) {
      this.cardSubtitle = changes.subtitle.currentValue;
    }

    if (changes.isInCardEditPage?.currentValue) {
      this.getResourceUidAndThumbnail();
    }
  }

  onTitleChanged(event: InputEvent) {
    const title = (event.target as HTMLInputElement).value.trim();
    this.titleEdited = true;
    this.titleIsEmpty = title === '';
    if (this.isInCardEditPage) {
      clearTimeout(this.titleTimeout);
      this.titleTimeout = setTimeout(() => {
        this.headlineChanged.emit(title);
        this.titleEdited = false;
      }, 500);
    } else {
      this.headlineChanged.emit(title);
      this.titleEdited = false;
    }
  }

  onSubtitleChanged(event: InputEvent) {
    const element = event.target as HTMLInputElement;
    this.subtitleEdited = true;
    if (this.isInCardEditPage) {
      clearTimeout(this.subheaderTimeout);
      this.subheaderTimeout = setTimeout(() => {
        this.subtitleChanged.emit(element.value.trim());
        this.subtitleEdited = false;
      }, 500);
    } else {
      this.subtitleChanged.emit(element.value.trim());
      this.subtitleEdited = false;
    }
  }

  onLinkChanged() {
    if (this.externalContent) {
      this.linkEdited = true;
      this.externalContent.url = this.linkUrl;
    }
    this.linkIsEmpty = !this.linkUrl;
  }

  onInsertLink() {
    if (!this.linkUrl) {
      this.linkEdited = true;
      this.linkInserted = false;
      return;
    }
    this.onLinkInput();
  }

  onLinkInput() {
    this.linkIsEmpty = false;
    const correctUlr = SpecialEmbedsHelper.correctUrl(this.linkUrl);
    this.embedsService.retrieveEmbeddableContentDetails(encodeURI(correctUlr), true).subscribe(({ isSuccess, value, error }) => {
      let externalContent: EmbeddableContentDetails;

      if (isSuccess) {
        externalContent = value;
      } else {
        externalContent = SpecialEmbedsHelper.generateEmbedFromUrl(correctUlr);
      }
      this.isButtonDisabled.emit(false);
      if (!externalContent) {
        const isValidUrl = FormsHelper.isValidUrlWithPattern(this.linkUrl);

        if (!isValidUrl) {
          this.linkIsEmpty = true;
          this.isButtonDisabled.emit(true);
          this.linkUrl = this.oldLinkUrl;
        }
        externalContent = this.createEmptyExternalContent();
        SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
      }
      this.linkInserted = true;
      this.externalContent = externalContent;
      this.cardHeadline = this.externalContent.title;
      this.cardSubtitle = this.externalContent.description ? this.externalContent.description : '';
      this.imageUrl = this.externalContent.thumbnailUrl;

      if (!this.isInCardEditPage) {
        this.headlineChanged.emit(this.cardHeadline);
        this.subtitleChanged.emit(this.cardSubtitle);
      }
      this.externalContentChanged.emit(this.externalContent);

      if (!this.isInCardEditPage) {
        for (const tagTitle of externalContent.tags) {
          this.addNewTag(tagTitle);
        }
      }

      this.focusTitleField();
    });
  }

  onNewTagInput(event: KeyboardEvent) {
    const tagTitle = (event.target as HTMLInputElement).value;
    (event.target as HTMLInputElement).value = '';
    this.addNewTag(tagTitle);
  }

  onAddTags(tags: ResourceTag[]) {
    for (const tag of tags) {
      if (this.tags.length < 10) {
        if (!this.tags.find((value) => value._id === tag._id)) {
          this.tags.push(tag);
        }
      } else {
        break;
      }
    }

    this.tagsChanged.emit(this.tags);
  }

  onRemoveTags(index: number) {
    this.tags.splice(index, 1);
    this.tagsChanged.emit(this.tags);
  }

  onThumbnailUpload(eventData: Event) {
    const selectedImage: File = (eventData.target as HTMLInputElement).files[0];

    const dialogRef = this.dialog.open(ImageCropDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '37.5rem',
      maxHeight: '80vh',
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
      data: {
        imageFile: selectedImage,
        cropperShape: 'rectangle',
        imageFormat: 'jpeg',
        aspectRatio: 30 / 17,
        resizeToHeight: 300,
      },
    });

    const dialogSubscription = dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (!this.isInCardEditPage) {
          this.thumbnailUrl = data.croppedImageDataUrl;
          this.thumbnailChanged.emit(data.croppedImageFile);
          return;
        }
        const request: ResourceThumbnailUpdateRequest = {
          imageName: ImageUploadNameResolver.resolveImageName(data.croppedImageFile.name),
          contentLength: data.croppedImageFile.size,
        };

        this.resourceDataService.changeResourceThumbnail(this.resourceUid, request).subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            this.fileUploadService.uploadFile(FileUploadHelper.filePath(value.uploadUrl), data.croppedImageFile).subscribe(() => {
              this.thumbnailUrl = data.croppedImageDataUrl;
              SnackbarHelper.showTranslatableSnackBar(
                this.ngZone,
                this.snackBar,
                this.translocoService,
                'translations.thumbnailImageUpdated',
              );
            });
          }
        });
      }

      (eventData.target as HTMLInputElement).value = '';
      dialogSubscription.unsubscribe();
    });
  }

  onThumbnailRemove() {
    if (!this.isInCardEditPage) {
      this.thumbnailUrl = undefined;
      this.thumbnailChanged.emit(undefined);
      return;
    }
    this.resourceDataService.removeResourceThumbnail(this.resourceUid).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        this.thumbnailUrl = undefined;
        SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translocoService, 'translations.thumbnailImageRestored');
      }
    });
  }

  private focusTitleField() {
    this.titleInput.nativeElement.focus();
    if (!this.cardHeadline) {
      this.titleIsEmpty = true;
    }
  }

  private createEmptyExternalContent(): EmbeddableContentDetails {
    return {
      authorName: '',
      logoUrl: '',
      url: this.linkUrl,
      title: this.cardHeadline,
      description: this.cardSubtitle,
      thumbnailUrl: this.thumbnailUrl,
      tags: [],
      publicationDate: new Date(),
      siteName: '',
      html: '',
    };
  }

  private getResourceUidAndThumbnail() {
    const isProjectResource = ProjectHelper.isProjectByUrl(this.router);
    const playlistUri = this.route.snapshot.paramMap.get('playlistUri');
    const resourceUri = this.route.snapshot.paramMap.get('resourceUri');
    const publisherUri = this.route.snapshot.paramMap.get('publisherUri');
    const packageUri = this.route.snapshot.paramMap.get('packageUri');
    const pageUri = this.route.snapshot.paramMap.get('pagesUri');
    this.resourceDataService
      .getResourceDetails(isProjectResource, playlistUri, resourceUri, publisherUri, packageUri, pageUri)
      .subscribe(({ value, isSuccess }) => {
        if (isSuccess) {
          this.thumbnailUrl = value.thumbnail;
          this.resourceUid = value._id;
        }
      });
  }

  private addNewTag(tagTitle: string) {
    this.tagService.createTag({ title: tagTitle }).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.onAddTags([value]);
      } else {
        this.tagService.findTagsWithTitleTerm(tagTitle).subscribe((result) => {
          if (result.isSuccess) {
            for (const tag of result.value) {
              if (tag.title === tagTitle) {
                this.onAddTags([tag]);
                break;
              }
            }
          }
        });
      }
    });
  }
}
