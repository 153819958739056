/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { DOCUMENT } from '@angular/common';
import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { PageTitleService } from '../../../shared/services/page-title/page-title.service';
import { AccessTokenService } from '../../../user-auth/services/access-token.service';
import { AUTH_SERVICE, BasicAuthService } from '../../../user-auth/services/basic-auth.service';
import {
  GetHomePageTitleAndUri,
  LoadOrganizationDetails,
  LoadOrganizationPublisher,
  RedirectToAuthenticatedApp,
  SendOnBoardingData,
  RedirectToAuthenticatedAppFailure,
} from '../../../user-auth/store/user-auth.actions';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { MatDialog } from '@angular/material/dialog';
import { DataCollectionDialogComponent } from '../../../shared/components/data-collection/data-collection-dialog/data-collection-dialog.component';
import { Observable, Subscription } from 'rxjs';
import { SnackbarHelper } from '../../../shared/helpers/snackbar-helper';
import { CookieHelper } from '../../../shared/helpers/cookie-helper';
import { USER_IS_LOGGED_IN } from '../../../user-auth/services/api-basic-auth.service';
import { ContentHelper } from '../../../shared/helpers/content-helper';
import { VersionHelper } from '../../../shared/helpers/version.helper';
import { CloseMobileNavMenu } from '../../../app-frame/store/app-frame.actions';
import * as LanguagesActions from '../../../page-modules/admin/store/languages/languages.actions';
import { Organization, OrganizationUi } from '../../../shared/models';
import { MAINTENANCE_MODE_FLAG } from '../../../shared/constants/constants';
import { MaintenanceService } from '../../../shared/services/maintenance/maintenance.service';
import { LanguageCodeHelper } from '../../../shared/helpers/language-code-helper';

@Component({
  selector: 'ptl-index-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss', './iframe.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  @Select(UserAuthState.onBoardDataIsSubmitted)
  private onBoardDataIsSubmitted$: Observable<boolean>;
  private onBoardDataSubscription: Subscription;

  private workspaceSubscription: Subscription;
  private dataCollectionDialogOpened = false;

  loading = true;
  isIframeMode: boolean;

  maintenanceModeEnabled: boolean;

  constructor(
    private store: Store,
    private titleService: PageTitleService,
    @Inject(DOCUMENT) private document: Document,
    private readonly accessTokenService: AccessTokenService,
    @Inject(AUTH_SERVICE) private readonly authService: BasicAuthService,
    private readonly snackBar: MatSnackBar,
    private maintenanceService: MaintenanceService,
    private ngZone: NgZone,
    private dialog: MatDialog,
  ) {
    // CHECKS MAINTENANCE MODE STATUS
    if (this.maintenanceService.enableMaintenanceMode()) {
      const canaryFlag = window.sessionStorage.getItem(MAINTENANCE_MODE_FLAG);
      this.maintenanceModeEnabled = canaryFlag !== '1';
      if (!this.maintenanceModeEnabled) {
        this.checkIfUserCookieTokenSet();
      }
    }
    const href = document.location.href;
    const hostname = document.location.hostname;
    if (hostname.startsWith('yuna.dev.potential.ly')) {
      this.document.location.href = href.replace('yuna.dev.potential.ly', 'my.yuna.dev.potential.ly');
      return;
    } else if (hostname.startsWith('yuna.potential.ly')) {
      this.document.location.href = href.replace('yuna.potential.ly', 'my.potential.ly');
      return;
    } else if (hostname.startsWith('jisc.yuna.potential.ly')) {
      this.document.location.href = href.replace('jisc.yuna.potential.ly', 'jisc.potential.ly');
      return;
    }

    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        document.body.classList.add('show-focus');
      }
    });
    document.addEventListener(
      'click',
      (event: MouseEvent) => {
        document.body.classList.remove('show-focus');
        if ((event.target as HTMLElement).closest('.mat-drawer-backdrop')) {
          this.store.dispatch(new CloseMobileNavMenu());
        }
      },
      false,
    );
  }

  ngOnInit(): void {
    // DISABLE APP FUNCTIONAL FOR MAINTENANCE MODE
    if (this.maintenanceModeEnabled) {
      return;
    }
    this.isIframeMode = ContentHelper.isFrameMode();
    ContentHelper.setOrgDiscoveryUrl();
    this.store.dispatch(new LoadOrganizationDetails()).subscribe(() => {
      const orgDetails = this.store.selectSnapshot(UserAuthState.organizationDetails);
      this.checkVersionFeature(orgDetails?.ui);
      this.checkDefaultLanguageOrientation(orgDetails);
      if (!this.isIframeMode && orgDetails?.authType !== 'SAML' && orgDetails?.authType !== 'SAML_SP') {
        if (!document.getElementById('cookieControlLoadScript')) {
          const html = '<script id="cookieControlLoadScript">CookieControl.load(config);</script>';
          const fragment = document.createRange().createContextualFragment(html);
          document.body.appendChild(fragment);
        }
      }
      this.handleRedirection(this.store);
      this.titleService.setOrganizationTitle();
      this.store.dispatch(new LanguagesActions.LoadLanguages());
    });
    this.onBoardDataSubscription = this.onBoardDataIsSubmitted$.subscribe((data) => {
      if (!this.dataCollectionDialogOpened) {
        const hostname = document.location.hostname;
        const allowedDomain = hostname === 'my.yuna.dev.potential.ly' || hostname === 'my.potential.ly' || hostname === 'localhost';

        if (data === false && allowedDomain) {
          this.dataCollectionDialogOpened = true;
          setTimeout(() => {
            this.showDataCollectionDialog();
          }, 1000);
        } else if (data !== undefined) {
          this.dataCollectionDialogOpened = true;
        }
      }
    });
  }

  ngOnDestroy() {
    this.onBoardDataSubscription?.unsubscribe();
    this.workspaceSubscription?.unsubscribe();
  }

  private checkIfUserCookieTokenSet() {
    const token = CookieHelper.GetCookie('FolioAccessToken');
    if (token) {
      this.accessTokenService.saveAccessToken(token);
      localStorage.setItem(USER_IS_LOGGED_IN, 'true');
      CookieHelper.DeleteCookie('FolioAccessToken');
    }
  }

  private handleRedirection(store: Store): void {
    const token = this.getTokenProvidedByIdp();
    if (this.checkIfLtiPage() || this.checkIfSamlSPPage()) {
      return;
    }
    if (token) {
      this.accessTokenService.saveAccessToken(token);
      this.authService.finalize().subscribe(({ isSuccess, error }) => {
        if (isSuccess) {
          this.redirectToAuthenticatedApp(store);
        } else if (error) {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      });
    } else {
      const userToken = this.accessTokenService.getAccessToken();
      if (userToken) {
        this.redirectToAuthenticatedApp(store);
      } else {
        store
          .dispatch(new RedirectToAuthenticatedAppFailure())
          .toPromise()
          .then(() => {
            this.loading = false;
          });
      }
    }
  }

  private getTokenProvidedByIdp(): string | null {
    return new URLSearchParams(location.hash).get('access_token');
  }

  private checkIfLtiPage(): boolean {
    return (
      document.location.href.includes('lti/launch') ||
      document.location.href.includes('lti/deeplink') ||
      document.location.href.includes('lti/playlists/edit') ||
      document.location.href.includes('lti/registration/error')
    );
  }

  private checkIfSamlSPPage(): boolean {
    return document.location.href.includes('saml/signin') || document.location.href.includes('saml/signin-error');
  }

  private redirectToAuthenticatedApp(store: Store): void {
    store
      .dispatch(new RedirectToAuthenticatedApp())
      .toPromise()
      .then(() => {
        store.dispatch(new LoadOrganizationPublisher());
        store
          .dispatch(new GetHomePageTitleAndUri())
          .toPromise()
          .then(() => {
            this.loading = false;
          });
      });
  }

  private showDataCollectionDialog(): void {
    const dialogConfig = {
      width: '27.5rem',
      maxHeight: '90vh',
      position: {
        top: '5vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      backdropClass: 'dialog-backdrop',
      disableClose: true,
    };

    const dialogRef = this.dialog.open(DataCollectionDialogComponent, dialogConfig);

    const dialogSubscription = dialogRef.afterClosed().subscribe((data) => {
      dialogSubscription.unsubscribe();

      if (data) {
        this.store.dispatch(new SendOnBoardingData(data));
      }
    });
  }

  private checkVersionFeature(version: OrganizationUi = 'v2'): void {
    this.clearVersionClasses();
    document.body.classList.add(`${version}-enabled`);
    VersionHelper.setVersionEnabled(version, true);
  }

  private clearVersionClasses(): void {
    const versionClasses = ['v1-enabled', 'v2-enabled', 'v3-enabled'];
    document.body.classList.remove(...versionClasses);
  }

  private checkDefaultLanguageOrientation(organization: Organization) {
    if (!organization) {
      return;
    }
    const defaultLang = organization.defaultLanguage;
    const dir = organization.languages.find((lang) => lang.code === defaultLang)?.orientation;
    if (dir) {
      LanguageCodeHelper.setBodyLanguageDir(dir);
      document.body.setAttribute('dir', dir === 'LEFT_TO_RIGHT' ? 'ltr' : 'rtl');
    }
  }
}
