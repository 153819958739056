/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import { Playlist, ResourceTag } from '../../../shared/models';
import {
  CreateFrameworkRequest,
  Framework,
  FrameworkCategoriesTree,
  FrameworkDetails,
  OrganizationFramework,
  UpdateFrameworkRequest,
} from '../../../shared/models/admin/admin.model';
import { Page as Pagination } from '../../../shared/models/page';
import {
  CategoryItemCreationRequest,
  CategoryItemCreationResponse,
  Page,
  PageRequest,
  Section,
  SectionReorder,
  SectionRequest,
} from '../../../shared/models/page/page.model';
import { ObservableResult } from '../../../shared/store';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const ADMIN_DATA_SERVICE = new InjectionToken<AdminDataService>('[Admin] DataService');

/** The admin data service. */
export interface AdminDataService {
  /** Loads the frameworks for a user. */
  loadFrameworks(): ObservableResult<Framework[]>;

  /** Loads the content store frameworks for a user. */
  loadContentStoreFrameworks(): ObservableResult<Framework>;

  /** Loads the discovery frameworks for a user. */
  loadFrameworkCategories(): ObservableResult<Framework>;

  loadFrameworkCategoriesTree(): ObservableResult<FrameworkCategoriesTree[]>;

  /** Loads the explore categories for a user. */
  loadCategoriesDescendantsTags(): ObservableResult<ResourceTag[]>;

  loadCategoryChildren(categoryTagUid: string): ObservableResult<ResourceTag[]>;

  /** Loads the framework for a user by framework id. */
  loadFrameworkById(frameworkId: string): ObservableResult<FrameworkDetails>;

  /** Loads the framework tags descendants for a user by framework id. */
  loadFrameworkDescendants(frameworkId: string): ObservableResult<ResourceTag[]>;

  /** Loads the framework tag descendants for a user by tag id. */
  loadFrameworkTagDescendants(frameworkId: string, tagId: string): ObservableResult<ResourceTag[]>;

  /** Updates the framework. */
  updateFramework(frameworkId: string, frameworkParams: UpdateFrameworkRequest): ObservableResult<Framework>;

  /** Creates a new framework. */
  createFramework(frameworkParams: CreateFrameworkRequest): ObservableResult<void>;

  /** Set the framework published or unpublished. */
  publishFramework(frameworkId: string, publish: boolean): ObservableResult<Framework>;

  /** Loads all frameworks for all organizations. */
  loadOrganizationFrameworks(): ObservableResult<OrganizationFramework[]>;

  /** Adds framework to Organization. */
  addFrameworkToOrganization(frameworkId: string): ObservableResult<OrganizationFramework>;

  /** Removes framework from Organization. */
  removeFrameworkFromOrganization(frameworkId: string): ObservableResult<void>;

  /** Sets organization framework status. */
  setOrganizationFrameworkStatus(frameworkId: string, status: boolean): ObservableResult<OrganizationFramework>;

  /** Updates the page. */
  updatePage(pageUid: string, pageBody: PageRequest): ObservableResult<Page>;

  /** Creates a new section. */
  addSection(pageUid: string, sectionBody: SectionRequest, languageCode?: string): ObservableResult<Section>;

  /** Removes the section. */
  removeSection(pageUid: string, sectionUid: string): ObservableResult<void>;

  /** Updates the section. */
  updateSection(pageUid: string, sectionUid: string, section: SectionRequest, languageCode?: string): ObservableResult<Section>;

  /** Reorders sections on the page. */
  reorderPageSections(pageUid: string, section: SectionReorder): ObservableResult<Section[]>;

  /** Returns latest playlists for given user from organization. */
  getLatestPlaylists(
    page: number,
    size: number,
    frameworkId?: string,
    tagId?: string,
    languageCode?: string,
  ): ObservableResult<Pagination<Playlist>>;

  getPlaylistsByTags(page: number, size: number, tagIds?: string, languageCode?: string): ObservableResult<Pagination<Playlist>>;

  /** Returns uncompleted learners playlists list */
  getUncompletedPlaylists(page: number, size: number, languageCode?: string): ObservableResult<Pagination<Playlist>>;

  getYourPlaylists(page: number, size: number, term?: string, languageCode?: string): ObservableResult<Pagination<Playlist>>;

  /** Add category. */
  addCategory(pageUid: string, sectionUid: string, request: CategoryItemCreationRequest): ObservableResult<CategoryItemCreationResponse>;

  /** Update category. */
  updateCategory(
    pageUid: string,
    sectionUid: string,
    categoryId: string,
    request: CategoryItemCreationRequest,
  ): ObservableResult<CategoryItemCreationResponse>;

  /** Remove category. */
  removeCategory(pageUid: string, sectionUid: string, categoryId: string): ObservableResult<void>;

  cloneSection(pageUid: string, sectionUid: string): ObservableResult<Section>;

  createLtiFramework(requestId: string, organizationUid: string, frameworkParams: CreateFrameworkRequest): ObservableResult<void>;

  loadLtiFrameworks(requestId: string, organizationUid: string): ObservableResult<Framework[]>;
}
