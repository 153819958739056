/*
 * Copyright (C) 2018 - present by Potentially
 *
 * Please see distribution for license.
 */

import * as MediumEditor from 'medium-editor';
import 'vanilla-color-picker/dist/vanilla-color-picker.js';

declare const vanillaColorPicker;

/**
 * Custom `color picker` extension
 * Source: https://github.com/yabwe/medium-editor/issues/523#issuecomment-312204485
 */
export const COLOR_PICKER_FORM = MediumEditor.Extension.extend({
  name: 'colorPicker',

  init: function () {
    this.button = this.document.createElement('button');
    this.button.classList.add('medium-editor-action');
    this.button.classList.add('editor-color-picker');
    this.button.title = 'text color';
    this.button.innerHTML = '<i class="material-icons" aria-hidden="true">invert_colors</i>';

    this.on(this.button, 'click', this.handleClick.bind(this));
  },

  getButton: function () {
    return this.button;
  },

  handleClick: function (e) {
    e.preventDefault();
    e.stopPropagation();

    this.selectionState = this.base.exportSelection();

    // If no text selected, stop here.
    if (this.selectionState && this.selectionState.end - this.selectionState.start === 0) {
      return;
    }

    // colors.scss for picker
    const pickerColors = [
      '#e6194B',
      '#3cb44b',
      '#ffe119',
      '#4363d8',
      '#f58231',
      '#911eb4',
      '#42d4f4',
      '#f032e6',
      '#bfef45',
      '#fa9b9d',
      '#469990',
      '#e6beff',
      '#9A6324',
      '#800000',
      '#808000',
      '#000075',
      '#595959',
      '#a9a9a9',
      '#000000',
    ];

    const picker = vanillaColorPicker(this.document.querySelector('.medium-editor-toolbar-active .editor-color-picker').parentNode);

    picker.set('customColors', pickerColors);
    picker.set('positionOnTop');
    picker.openPicker();
    picker.on(
      'colorChosen',
      function (color) {
        // @ts-ignore: Example from https://github.com/yabwe/medium-editor/issues/523#issuecomment-312204485
        this.base.importSelection(this.selectionState);
        // @ts-ignore Example from https://github.com/yabwe/medium-editor/issues/523#issuecomment-312204485
        this.document.execCommand('styleWithCSS', false, true);
        // @ts-ignore Example from https://github.com/yabwe/medium-editor/issues/523#issuecomment-312204485
        this.document.execCommand('foreColor', false, color);
      }.bind(this),
    );
  },
});
