/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../shared/store';
import { InjectionToken } from '@angular/core';
import { Page } from '../../../shared/models/page';

/** AI assistant service. */
export const AI_ASSISTANT_SERVICE = new InjectionToken<AiAssistantService>('[Embeds]');

export interface AiAssistantService {
  exchange(request: ExchangeRequest): ObservableResult<OpenAiMessage>;

  getAIAssistantSettingsLearnerView(aiAssistantSettingsUid: string): ObservableResult<LearnerAIAssistantSettings>;

  createAssistantSettings(cardUid: string, request: AIAssistantSettingsRequest): ObservableResult<AIAssistantSettings>;

  getAssistantSettingsById(cardUid: string, assistantSettingsUid: string): ObservableResult<AIAssistantSettings>;

  updateAssistantSettings(
    cardUid: string,
    assistantSettingsUid: string,
    request: AIAssistantSettingsRequest,
  ): ObservableResult<AIAssistantSettings>;

  deleteAssistantSettings(cardUid: string, assistantSettingsUid: string): ObservableResult<void>;

  searchAIAssistantSettingsTemplates(cardUid: string, phrase: string): ObservableResult<Page<AIAssistantSettingsTemplateDTO>>;

  createAIAssistantSettingsFromTemplate(cardUid: string, aiAssistantSettingsTemplateUid: string): ObservableResult<AIAssistantSettings>;
}

export interface ExchangeRequest {
  sentence: string;
  actionUid: string;
  assistantSettingsUid: string;
  wordLimit: number;
}

export interface OpenAiMessage {
  role: string;
  content: string;
  id?: string;
}

export class AIAssistantSettingsRequest {
  constructor(
    public title: string,
    public instructions: string,
    public prompt: string,
    public actions: AIAssistantAction[],
    public isTemplate: boolean,
    public _id: string | null,
  ) {}
}

export class AIAssistantAction {
  constructor(
    public uid: string,
    public description: string,
    public prompt: string,
  ) {}
}

export interface AIAssistantSettings {
  _id: string | null;
  creatorUid: string;
  organizationUid: string;
  title: string;
  instructions: string;
  prompt: string;
  actions: AIAssistantAction[];
  isTemplate: boolean;
}

export interface LearnerAIAssistantSettings {
  uid: string | null;
  title: string;
  instructions: string;
  actions: AIAssistantAction[];
}

export interface AIAssistantSettingsTemplateDTO {
  assistantSettingsUid: string;
  title: string;
  instructions: string;
}
