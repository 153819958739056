/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { ObservableResult } from '../../../shared/store';
import { RestClientService } from '../../../shared/services/rest-client.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import {
  AlumniRequest,
  AlumniUploadCsvRequest,
  AlumniUploadCsvResponse,
  EmailResponse,
  FolioAccountVerificationRequest,
  PersonalDataRequest,
  TokenVerification,
  UserNotificationsSettingsRequest,
  UserPrivacySettingsRequest,
  UsersUploadCsvRequest,
  UsersUploadCsvResponse,
} from '../../../shared/models/account/account.model';
import { AccountDataService } from './account-data.service';
import { TranslocoService } from '@ngneat/transloco';
import { UserDetailsSummary } from '../../../user-auth/models';

/* eslint-disable max-len */
@Injectable()
export class ApiAccountDataService implements AccountDataService {
  constructor(
    private restClient: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  getPersonalData(): ObservableResult<UserDetailsSummary> {
    return this.restClient.get<UserDetailsSummary>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/details')).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetPersonalData'))),
    );
  }

  updatePersonalData(userData: PersonalDataRequest): ObservableResult<UserDetailsSummary> {
    return this.restClient.patch<UserDetailsSummary>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/details'), userData).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatePersonalData'))),
    );
  }

  resetUserPassword(): ObservableResult<void> {
    return this.restClient.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/details/password/reset')).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorResetPersonalDataPassword'))),
    );
  }

  resetPasswordByEmail(email: string, captchaResponseV3?: string, captchaResponseV2?: string): ObservableResult<void> {
    return this.restClient
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', '/users/reset/password'), {
        email: email,
        captchaResponseV3: captchaResponseV3,
        captchaResponseV2: captchaResponseV2,
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) => {
          if (err.status === 500) {
            const translation = this.translocoService.translate('translations.errors.errorResetPersonalDataPasswordEmailNotFound');
            const message = translation.replace('{email}', email);
            return ObservableResult.ofError(message);
          }
          return ObservableResult.ofError(this.translocoService.translate('translations.errors.errorResetPersonalDataPasswordFailed'));
        }),
      );
  }

  resetUserEmail(): ObservableResult<void> {
    return this.restClient.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/details/email/reset')).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorResetPersonalDataEmail'))),
    );
  }

  getEmailByToken(tokenVerification: TokenVerification): ObservableResult<EmailResponse> {
    return this.restClient
      .post<EmailResponse>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/tokens/emails'), tokenVerification)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetEmailByToken'))),
      );
  }

  resetUserRecoveryEmail(email: string): ObservableResult<void> {
    return this.restClient
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/details/email/recovery'), { email: email })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() =>
          ObservableResult.ofError(this.translocoService.translate('translations.errors.errorResetPersonalDataRecoveryEmail')),
        ),
      );
  }

  updateUserPassword(password: string, token: string, captchaResponseV3?: string, captchaResponseV2?: string): ObservableResult<void> {
    return this.restClient
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `users/reset/password/token/${token}`), {
        newPassword: password,
        captchaResponseV3: captchaResponseV3,
        captchaResponseV2: captchaResponseV2,
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) => ObservableResult.ofError(err.error?.message)),
      );
  }

  updateUserEmail(email: string, token: string, captchaResponseV3?: string, captchaResponseV2?: string): ObservableResult<void> {
    return this.restClient
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `users/reset/email/token/${token}`), {
        newEmail: email,
        captchaResponseV3: captchaResponseV3,
        captchaResponseV2: captchaResponseV2,
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) => {
          if (err.status === 409) {
            return ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatePersonalDataEmail'));
          }
          return ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatePersonalDataEmailExpired'));
        }),
      );
  }

  updateUserRecoveryEmail(token: string): ObservableResult<void> {
    return this.restClient.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `users/confirm/email/${token}`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => {
        if (err.status === 500) {
          return ObservableResult.ofError(
            this.translocoService.translate('translations.errors.errorUpdatePersonalDataRecoveryEmailExpired'),
          );
        }
        return ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatePersonalDataRecoveryEmail'));
      }),
    );
  }

  updateUserPrivacy(request: UserPrivacySettingsRequest): ObservableResult<void> {
    return this.restClient.patch<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/settings/privacy'), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatePersonalDataPrivacy'))),
    );
  }

  updateUserNotifications(request: UserNotificationsSettingsRequest): ObservableResult<void> {
    return this.restClient.patch<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/settings/notifications'), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatePersonalDataNotifications')),
      ),
    );
  }

  connectUserToWorkspace(email: string): ObservableResult<void> {
    return this.restClient
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `users/workspaces/common-folio?folioEmail=${email}`))
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError((err) => ObservableResult.ofError(err)),
      );
  }

  connectUniversityEmail(universityEmail: string, folioAccountInitialization: boolean): ObservableResult<void> {
    return this.restClient
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', '/users/workspaces/pre-arrivals'), {
        email: universityEmail,
        folioAccountInitialization: folioAccountInitialization,
      })
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError((err) => ObservableResult.ofError(err)),
      );
  }

  leaveUserFromWorkspace(organizationUid: string): ObservableResult<void> {
    return this.restClient
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `users/details/workspaces/${organizationUid}/leave`))
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorLeaveWorkspace'))),
      );
  }

  verifyWorkspaceEmail(request: FolioAccountVerificationRequest): ObservableResult<void> {
    return this.restClient
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/workspaces/common-folio/finalizations'), request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorFolioAccountVerify'))),
      );
  }

  confirmWorkspaceEmailWithPassword(request: FolioAccountVerificationRequest): ObservableResult<void> {
    return this.restClient.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/confirm/folio-email'), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(err.error?.message)),
    );
  }

  alumniRequest(request: AlumniRequest): ObservableResult<void> {
    return this.restClient.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/alumni'), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAlumniRequest'))),
    );
  }

  alumniUploadCsv(request: AlumniUploadCsvRequest): ObservableResult<AlumniUploadCsvResponse> {
    const formData = new FormData();
    formData.append('file', request.file);
    formData.append('groupUid', request.groupUid);
    return this.restClient
      .post<AlumniUploadCsvResponse>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/alumni/upload'), formData)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAlumniRequest'))),
      );
  }

  usersUploadCsv(request: UsersUploadCsvRequest): ObservableResult<UsersUploadCsvResponse> {
    const formData = new FormData();
    formData.append('file', request.file);
    return this.restClient
      .post<UsersUploadCsvResponse>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/import'), formData)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUploadUsers'))),
      );
  }

  setLanguage(code: string): ObservableResult<void> {
    const params = {
      languageCode: code,
    };
    return this.restClient.patch<void>(Location.joinWithSlash(environment.apiRootUrl || '', '/users/settings/languages'), params).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdateLanguage'))),
    );
  }

  updateJiscUsage(hasUsedCpd: boolean): ObservableResult<void> {
    return this.restClient
      .patch<void>(Location.joinWithSlash(environment.apiRootUrl || '', '/users/map/usage'), { hasUsedCpd: hasUsedCpd })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatePersonalData'))),
      );
  }
}
