<!--
~ Copyright (C) 2019 - Potentially Ltd
~
~ Please see distribution for license.
-->

<div
  class="form-section"
  (focusin)="focused = true"
  (focusout)="focused = false"
  (click)="expandForm()"
  (keydown.enter)="$event.stopPropagation()"
>
  <form
    class="form-block"
    tabindex="0"
    [class.has-error]="hasError"
    [class.is_focused]="focused"
    [attr.aria-label]="'translations.global.ariaLabels.thisIsTextboxForm' | transloco"
    [formGroup]="connectedTextboxForm"
    (ngSubmit)="saveForm()"
  >
    <div class="form-block-inner">
      <div class="form-block-title-box">
        <input
          #formTitleInput
          formControlName="title"
          type="text"
          class="form-block-input-field no-border full-width no-padding medium bold"
          (input)="hasError = false"
          [placeholder]="'translations.instruction' | transloco"
        />
      </div>
      <ng-container *ngIf="expanded || forceExpand">
        <div class="form-block-help-text-box extra-small"></div>

        <div formGroupName="connection" class="form-control-items">
          <div class="playlist-section control-item">
            <label class="small bold" for="play-list">Playlists</label>
            <div class="input-wrapper">
              <input
                id="play-list"
                matInput
                [matAutocomplete]="auto"
                type="text"
                required
                [value]="searchTerm"
                placeholder="Search Playlists"
                (paste)="onTextPaste($event)"
                (keydown)="onSearchInputChange($event)"
                [attr.aria-label]="'translations.global.ariaLabels.addNewTag' | transloco"
              />

              <mat-spinner class="loader" *ngIf="searchFormPlayListInProgress" [diameter]="16"> </mat-spinner>
            </div>
            <mat-autocomplete #auto="matAutocomplete" [panelWidth]="450" (optionSelected)="getSelectedPlaylistUid($event)">
              <mat-option *ngFor="let formPlayList of formPlayLists" [id]="formPlayList.id" [value]="formPlayList.title">
                {{ formPlayList.title }}
              </mat-option>
            </mat-autocomplete>
          </div>

          <div class="select-field-box control-item">
            <label class="small bold" for="card-list">Cards</label>
            <mat-select
              id="card-list"
              class="select-field small"
              [ngClass]="{ disabled: !formCardLists || formCardLists?.length === 0 }"
              formControlName="cardUid"
              (selectionChange)="getSelectedCardUid($event)"
              placeholder="-"
              [(value)]="selectedCardUid"
            >
              <mat-option *ngFor="let formCardList of formCardLists" [value]="formCardList.id">
                {{ formCardList.title }}
              </mat-option>
            </mat-select>
          </div>

          <div class="select-field-box control-item">
            <label class="small bold" for="form-list">Forms</label>
            <mat-select
              id="form-list"
              class="select-field small"
              [ngClass]="{ disabled: !formLists || formLists?.length === 0 }"
              formControlName="formUid"
              (selectionChange)="getSelectedFormUid($event)"
              placeholder="-"
              [(value)]="selectedFormUid"
            >
              <mat-option *ngFor="let formList of formLists" [value]="formList.id">{{ formList.title }}</mat-option>
            </mat-select>
          </div>
        </div>

        <button
          mat-icon-button
          type="button"
          class="form-edit-icon"
          [attr.aria-label]="'translations.global.ariaLabels.icons.edit' | transloco"
        >
          <svg class="svg" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
          </svg>
        </button>
      </ng-container>
      <span class="setting-text extra-small" *ngIf="!(expanded || forceExpand)">{{
        'translations.global.button.settings' | transloco
      }}</span>
    </div>
    <div class="form-block-buttons" *ngIf="expanded || forceExpand">
      <button
        mat-flat-button
        class="form-button-remove small f_delete-block"
        type="button"
        [title]="'translations.global.ariaLabels.deleteTextBoxForm' | transloco"
        color="warn"
        *ngIf="canRemoveItem"
        [attr.aria-label]="'translations.global.ariaLabels.deleteTextBoxForm' | transloco"
        (click)="removeForm()"
      >
        <svg class="svg svg-icon icon-left" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
        </svg>
        <span>{{ 'translations.global.button.delete' | transloco }}</span>
      </button>
      <button
        mat-flat-button
        class="form-button-save small btn-with-loader f_update-block"
        [title]="'translations.global.ariaLabels.addTextBoxForm' | transloco"
        color="primary"
        type="submit"
        [disabled]="!connectedTextboxForm.valid && connectedTextboxForm.touched"
        [attr.aria-label]="'translations.global.ariaLabels.addTextBoxForm' | transloco"
      >
        <svg class="svg svg-icon icon-left" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
        </svg>
        <span>
          {{ !form?.uid ? ('translations.addToForm' | transloco) : ('translations.global.button.update' | transloco) }}
        </span>
        <mat-spinner class="is-right" *ngIf="saveInProgress" [diameter]="20"></mat-spinner>
      </button>
      <button
        mat-flat-button
        class="form-button-save small f_cancel-block"
        [title]="'translations.global.button.cancel' | transloco"
        color="accent"
        type="button"
        (click)="collapseForm($event)"
        [attr.aria-label]="'translations.global.ariaLabels.collapseForm' | transloco"
      >
        <span>{{ 'translations.global.button.cancel' | transloco }}</span>
      </button>
    </div>
  </form>
</div>
