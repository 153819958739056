/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { RedirectHelper } from '../../../../resource/store/editor/content/helpers/redirect.helper';
import { EventCardTime } from '../../../../resource/store/admin/resource-event-admin.state.model';
import { EventCardsHelper } from '../../../../../shared/helpers/event-cards-helper';
import { UpcomingEventCard } from '@app/app/shared/models/event-card/event-card.model';
import { ResourceAuthorDetails } from '@app/app/shared/models';

@Component({
  selector: 'ptl-upcoming-event-card',
  templateUrl: './upcoming-event-card.component.html',
  styleUrls: ['./upcoming-event-card.component.scss'],
})
export class UpcomingEventCardComponent implements OnInit {
  @Input() type: 'VIEW' | 'EDIT';
  @Input() event: UpcomingEventCard;
  @Input() index: number;
  @Input() inHomeLayoutEditor: boolean;
  @Input() isAdmin: boolean;

  eventText: string;
  authorDetails: ResourceAuthorDetails;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    if (this.event) {
      this.eventText = this.getEventText(this.event.time);
      this.authorDetails = this.event.authorDetails;
    }
  }

  onCardClick() {
    if (this.inHomeLayoutEditor) {
      return;
    }
    RedirectHelper.redirectByParams(
      this.ngZone,
      this.router,
      this.activatedRoute,
      {
        playlistUri: this.event.playlistUri,
        resourceUri: this.event.cardUri,
        pageNumberUri: 'page/1',
      },
      'RESOURCE',
    );
  }

  private getEventText(eventTime: EventCardTime): string {
    return EventCardsHelper.getEventStartingText(eventTime, this.translocoService);
  }
}
