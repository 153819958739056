/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { InjectionToken } from '@angular/core';
import { MultipleNotificationsReadRequest, Notification, NotificationFilterType } from '../../models/notifications/notifications.model';
import { Page } from '../../models/page';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const NOTIFICATIONS_DATA_SERVICE = new InjectionToken<NotificationsDataService>('[Notifications] NotificationsDataService');

/** The service handling tag CRUD operations. */
export interface NotificationsDataService {
  loadNotifications(page: number, size: number, filter: NotificationFilterType): ObservableResult<Page<Notification>>;

  loadNotificationsSnapshot(): ObservableResult<Notification[]>;

  markNotificationAsRead(notificationId: string, read: boolean): ObservableResult<void>;

  markMultiNotificationsAsRead(request: MultipleNotificationsReadRequest): ObservableResult<void>;
}
