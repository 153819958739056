<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="submit-dialog-content">
  <div class="submit-dialog-content-inner" #dialogWrapper tabindex="0" role="dialog">
    <div class="dialog-top-info">
      <h2 class="large dialog-title">
        {{
          mode === 'PLAYLIST'
            ? ('translations.playlist.submissions.title.submitAnswersToReviewersTitle' | transloco)
            : ('translations.playlist.submissions.title.submitFormAndStartReview' | transloco)
        }}
      </h2>
      <p class="dialog-description medium">
        {{
          playlistSubmissionMode === 'PROGRESSIVE'
            ? ('translations.playlist.submissions.label.progressiveReviewProcessExplanation' | transloco)
            : ''
        }}
        {{ 'translations.playlist.submissions.label.submitAnswersToReviewersDescription' | transloco }}
      </p>
      <button
        class="close-dialog-btn"
        mat-icon-button
        color="primary"
        (click)="closeDialog()"
        [attr.aria-label]="'translations.global.ariaLabels.closeDialog'"
      >
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
        </svg>
      </button>
    </div>

    <div class="dialog-reviewers-box">
      <h3 class="small bold title">{{ 'translations.playlist.submissions.label.selectYourReviewerTitle' | transloco }}</h3>
      <p class="extra-small description">{{ 'translations.playlist.submissions.label.selectYourReviewerDescription' | transloco }}</p>
      <ptl-user-search-autocomplete-new
        class="search-field"
        #userSearchAutocomplete
        [loadingMembers]="loadingMembers"
        [members]="members"
        [disabled]="!!maxReviewersCount && selectedReviewers.length >= maxReviewersCount"
        (searchInputChanged)="onSearchInputChange($event)"
        (searchLoadedMore)="onSearchLoadingMore($event)"
        (memberSelected)="onMemberSelected($event)"
      >
      </ptl-user-search-autocomplete-new>
      <div class="selected-reviewers-box chips-list" *ngIf="selectedReviewers?.length">
        <div tabindex="0" *ngFor="let reviewer of selectedReviewers; let i = index" class="chip-item has-action-btn chip-interactive">
          <span class="chip-text"> {{ reviewer.firstName }} {{ reviewer.lastName }} </span>
          <span tabindex="0" class="chip-item-icon" (click)="onRemoveCollaborator(i)" (keydown.enter)="onRemoveCollaborator(i)">
            <svg class="svg" role="img" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
            </svg>
          </span>
        </div>
        <p class="selected-reviewers-max" *ngIf="!!maxReviewersCount && selectedReviewers.length >= maxReviewersCount">
          <span *ngIf="maxReviewersCount !== 1">
            {{ 'translations.playlist.submissions.message.error.maxReviewCount' | transloco: { count: maxReviewersCount } }}
          </span>
          <span *ngIf="maxReviewersCount === 1">
            {{ 'translations.playlist.submissions.message.error.maxReviewCountOne' | transloco }}
          </span>
        </p>
      </div>

      <div class="selected-reviewers-box chips-list rejected-list" *ngIf="disabledReviewers?.length">
        <h3 class="small bold title">{{ 'translations.playlist.submissions.label.reviewersWhoRejectedReviewRequest' | transloco }}</h3>
        <div tabindex="0" *ngFor="let reviewer of disabledReviewers; let i = index" class="chip-item chip-warning">
          {{ reviewer.firstName }} {{ reviewer.lastName }}
        </div>
      </div>
    </div>

    <div class="dialog-comment-box">
      <h3 class="small bold title">{{ 'translations.playlist.submissions.label.submitReviewerAddNote' | transloco }}</h3>

      <textarea class="text-area-box" matInput [(ngModel)]="reviewNote"></textarea>
    </div>

    <div class="dialog-footer-buttons">
      <button class="cancel-button" mat-flat-button color="accent" (click)="closeDialog()">
        {{ 'translations.global.button.cancel' | transloco }}
      </button>
      <button class="submit-button" mat-flat-button color="primary" (click)="submitForReview()">
        {{
          mode === 'PLAYLIST'
            ? ('translations.playlist.submissions.button.submitForReview' | transloco)
            : ('translations.playlist.submissions.button.submitAndInitializeReview' | transloco)
        }}
      </button>
    </div>
  </div>
</div>
