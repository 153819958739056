<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="circle-progress-box" [class.completed]="completed" [class.progressed]="progress === 100 && !completed">
  <svg
    width="32"
    role="img"
    [attr.aria-label]="'translations.global.ariaLabels.icons.progressCircle' | transloco"
    height="32"
    viewBox="0 0 32 32"
    class="progress__svg"
    *ngIf="progress"
    [class.hidden]="completed || progress === 100"
  >
    <circle [attr.r]="radius - 2" cx="16" cy="16" stroke-width="3" stroke-dasharray="0 2" stroke-linecap="round" class="progress__meter" />
    <circle
      [style.strokeDasharray]="circumference"
      [style.strokeDashoffset]="svgProgress"
      [attr.r]="radius - 2"
      cx="16"
      cy="16"
      stroke-width="3"
      class="progress__value"
    />
  </svg>

  <span class="completed-count small bold">{{ progress !== 100 && !completed && showOrder ? index + 1 : '' }}</span>
</div>
