/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

/* eslint-disable max-len */
import { LoadableState, dataLoadedState, loadingState, ObservableResult } from '../../../../../shared/store';
import { Moment } from '../../../models';
import { State, Selector, Store, Action, StateContext } from '@ngxs/store';
import { CORE_MOMENT_DATA_SERVICE, MomentDataService } from '../../../shared-moment-module/services/data.service';
import { FileUploadService, RESOURCES_FILE_UPLOAD_DATA_SERVICE } from '../../../../../shared/services/file-upload/file-upload.service';
import { Inject, Injectable, NgZone } from '@angular/core';
import { OpenMomentForUpdate, LoadMomentToUpdate, MomentUpdateChange, TriggerMomentUpdate } from './moment-update.actions';
import { switchMap, tap } from 'rxjs/operators';
import { EditorTransformer } from '../../../../../shared/helpers/editor-transformer';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { ActivatedRoute, Router } from '@angular/router';
import { MomentFileUploadHelpers } from '../../../shared-moment-module/services/moment-view-all-file-upload.helpers';
import { CORE_TAG_DATA_SERVICE, MultiTagCreationRequest, TagDataService } from '../../../../../shared/services/tags/tag-data.service';
import { TagRequest } from '../../../../playlist/models';
import { MomentViewAllState } from '../moment-view-all/moment-view-all.state';
import { LoadMoments } from '../moment-view-all/moment-view-all.actions';
import { RedirectHelper } from '../../../../resource/store/editor/content/helpers/redirect.helper';

/* eslint-enable max-len */

/** The moment update related state. */
interface MomentUpdateViewStateModel {
  /** The details of the moment to update. */
  momentToUpdate: LoadableState<Moment>;
  /** True when moment update is in progress. */
  inProgress?: boolean;
  /** The update error. */
  updateError?: string;
  /** Boolean if content changed. */
  contentChanged?: boolean;
}

const INITIAL_UPDATE_VIEW_STATE = {
  momentToUpdate: loadingState(),
};

@State<MomentUpdateViewStateModel>({
  name: 'momentUpdateView',
  defaults: INITIAL_UPDATE_VIEW_STATE,
})
@Injectable()
export class MomentUpdateViewState {
  @Selector()
  static momentDetails({ momentToUpdate }: MomentUpdateViewStateModel): LoadableState<Moment> {
    return momentToUpdate;
  }

  @Selector()
  static updateInProgress({ inProgress }: MomentUpdateViewStateModel): boolean {
    return inProgress;
  }

  constructor(
    @Inject(CORE_MOMENT_DATA_SERVICE) private dataService: MomentDataService,
    @Inject(RESOURCES_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService,
    @Inject(CORE_TAG_DATA_SERVICE) private tagService: TagDataService,
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
  ) {}

  @Action(OpenMomentForUpdate)
  openMomentForUpdate({ patchState }: StateContext<MomentUpdateViewStateModel>, { id }: OpenMomentForUpdate) {
    const storedMoments = this.store.selectSnapshot(MomentViewAllState.moments);
    const moment = storedMoments.data?.content.find(({ _id }) => _id === id);
    patchState({
      momentToUpdate: dataLoadedState(moment),
    });
  }

  @Action(LoadMomentToUpdate)
  loadMomentToUpdate({ patchState, getState }: StateContext<MomentUpdateViewStateModel>, { momentUid }: LoadMomentToUpdate) {
    patchState({
      momentToUpdate: loadingState(),
      inProgress: undefined,
      updateError: undefined,
      contentChanged: undefined,
    });

    return this.dataService.getMomentDetails(momentUid).pipe(
      tap(({ isSuccess, value }) => {
        if (isSuccess) {
          patchState({
            momentToUpdate: dataLoadedState({ ...value }),
          });
        }
      }),
    );
  }

  @Action(MomentUpdateChange)
  momentUpdateChange({ patchState, getState }: StateContext<MomentUpdateViewStateModel>, { momentEditorState }: MomentUpdateChange) {
    const state = getState();

    patchState({
      momentToUpdate: dataLoadedState({
        ...state.momentToUpdate.data,
        ...momentEditorState,
      }),
      contentChanged: true,
    });
  }

  @Action(TriggerMomentUpdate)
  triggerMomentUpdate({ patchState, getState }: StateContext<MomentUpdateViewStateModel>) {
    if (!getState().contentChanged) {
      return RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, 'folio');
    }

    if (!getState().momentToUpdate.data.title) {
      return undefined;
    }

    patchState({
      inProgress: true,
    });

    const momentDetails = getState().momentToUpdate.data;
    const request = {
      type: 'STANDARD',
      tags: momentDetails.tags.map((tag) => ({ title: tag.title }) as TagRequest),
    } as MultiTagCreationRequest;

    return this.tagService.createTags(request).pipe(
      switchMap((tagsResult) => {
        // todo: basically duplicate of logic in MomentViewAllState
        if (tagsResult.isSuccess) {
          return this.dataService
            .updateMoment(momentDetails._id, {
              title: momentDetails.title,
              tags: tagsResult.value,
              content: momentDetails.content.map((content) => EditorTransformer.transformEditorContentToMatchApiExpectations(content)),
              addedToFolio: momentDetails.addedToFolio,
            })
            .pipe(
              tap(({ isSuccess, value }) => {
                if (isSuccess) {
                  this.uploadMediaFiles(momentDetails, value)
                    .toPromise()
                    .then(() => {
                      this.store
                        .dispatch(new LoadMoments(0, 10))
                        .toPromise()
                        .then(() => {
                          patchState({
                            inProgress: false,
                          });
                          RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, 'folio');
                        });
                    });
                } else {
                  patchState({
                    inProgress: false,
                  });
                }
              }),
            );
        } else {
          return undefined;
        }
      }),
    );
  }

  private uploadMediaFiles(momentDetails: Moment, resultMoment: Moment): ObservableResult<void> {
    const userDetails = this.store.selectSnapshot(UserAuthState.userDetailsData);
    return MomentFileUploadHelpers.uploadMomentFiles(
      userDetails.uid,
      userDetails.organization._id,
      momentDetails.content,
      this.fileUploadService,
      resultMoment.content,
    );
  }
}
