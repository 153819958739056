/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { catchError, switchMap } from 'rxjs/operators';
import { PublicationDataService } from './publication-data.service';
import { ObservableResult } from '../../../../../shared/store';
import { RestClientService } from '../../../../../shared/services/rest-client.service';
import { environment } from '../../../../../../environments/environment';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ApiPublicationDataService implements PublicationDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  publishPlaylist(playlistUid: string): ObservableResult<void> {
    return this.client.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/publication`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorPublishPlaylist'))),
    );
  }

  unpublishPlaylist(playlistUid: string): ObservableResult<void> {
    return this.client.delete<void>(Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/publication`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUnPublishPlaylist'))),
    );
  }
}
