/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { EditorContent, ResourceSection } from '@app/app/shared/models';
import { ResourceDataService } from '@app/app/page-modules/resource/services/editor/core/data.service';
import { FileUploadService } from '@app/app/shared/services/file-upload/file-upload.service';
import { ToastService } from '@app/app/shared/services/toast-notifications/toast-service';
import { TranslocoService } from '@ngneat/transloco';
import { ResourceAdminStateModel } from '@app/app/page-modules/resource/store/admin/resource-admin.state.model';
import { switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { FileUploadHelper } from '@app/app/shared/helpers/file-upload-helper';
import { cloneDeep } from 'lodash-es';

export class BlockSaveHelper {
  static saveBlockItem(
    cardUid: string,
    sectionUid: string,
    blockContent: EditorContent,
    languageCode: string,
    resourceDataService: ResourceDataService,
    fileUploadService: FileUploadService,
    toastService: ToastService,
    translocoService: TranslocoService,
    getState: () => ResourceAdminStateModel,
    patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
  ) {
    let waitsForBackendResponse = blockContent.waitsForBackendResponse;
    const hasFile = !!blockContent['file'];
    blockContent.waitsForBackendResponse = false;

    return resourceDataService.createEditorBlock(cardUid, sectionUid, blockContent, languageCode).pipe(
      switchMap(({ isSuccess, value }) => {
        if (isSuccess) {
          return FileUploadHelper.uploadBlockObservable(blockContent['file'], fileUploadService, value.uploadUrl, value.item.uid).pipe(
            tap(() => {
              const state = getState();

              const sections: ResourceSection[] = state.resource.content.sections;
              const sectionIndex = this.getSectionIndex(state.resource._id, sectionUid, sections);
              const section = sections[sectionIndex];

              const updatedBlock: EditorContent = {
                ...value.item,
                waitsForBackendResponse: waitsForBackendResponse,
                hasFile: hasFile,
              };

              // we are re-setting this value here as we only need to perform single backend response update
              waitsForBackendResponse = false;

              const appendToBlockIndex = section.dynamicContent.findIndex((item) => item.uid === updatedBlock.appendToBlock);
              if (updatedBlock.appendToBlock && appendToBlockIndex === -1)
                throw new Error(`Can not find block to append ${updatedBlock.appendToBlock} on card ${cardUid} and section ${sectionUid}`);

              const updatedSections = cloneDeep(sections) as ResourceSection[];

              const newBlockIndex =
                appendToBlockIndex === -1 ? updatedSections[sectionIndex].dynamicContent.length : appendToBlockIndex + 1;
              updatedSections[sectionIndex].dynamicContent.splice(newBlockIndex, 0, updatedBlock);
              if (hasFile) {
                updatedSections[sectionIndex].dynamicContent = this.removeDuplicates(updatedSections[sectionIndex].dynamicContent);
              }

              patchState({
                resource: {
                  ...state.resource,
                  content: {
                    ...state.resource.content,
                    sections: updatedSections,
                  },
                },
                contentChanged: false,
                saveStatus: {
                  autoSaveInProgress: false,
                },
              });
            }),
          );
        } else {
          toastService.showFail(translocoService.translate('translations.errors.errorSavingBlockItem'));
          return this.handleFailure(patchState);
        }
      }),
    );
  }

  static deleteBlockItem(
    cardUid: string,
    sectionUid: string,
    blockUid: string,
    resourceDataService: ResourceDataService,
    toastService: ToastService,
    translocoService: TranslocoService,
    getState: () => ResourceAdminStateModel,
    patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
  ) {
    return resourceDataService.deleteEditorBlock(cardUid, sectionUid, blockUid).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          const state = getState();

          const sections: ResourceSection[] = state.resource.content.sections;
          const sectionIndex = this.getSectionIndex(state.resource._id, sectionUid, sections);
          const blockIndex = this.getDynamicContentIndex(state.resource._id, sections[sectionIndex], blockUid);

          const updatedSections = cloneDeep(sections) as ResourceSection[];
          updatedSections[sectionIndex].dynamicContent.splice(blockIndex, 1);

          patchState({
            resource: {
              ...state.resource,
              content: {
                ...state.resource.content,
                sections: updatedSections,
              },
            },
            contentChanged: false,
            saveStatus: {
              autoSaveInProgress: false,
            },
          });
        } else {
          toastService.showFail(translocoService.translate('translations.errors.errorDeleteBlockItem'));
          this.handleFailure(patchState);
        }
      }),
    );
  }

  static updateBlockItem(
    cardUid: string,
    sectionUid: string,
    blockContent: EditorContent,
    languageCode: string,
    resourceDataService: ResourceDataService,
    fileUploadService: FileUploadService,
    toastService: ToastService,
    translocoService: TranslocoService,
    getState: () => ResourceAdminStateModel,
    patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
  ) {
    let waitsForBackendResponse = blockContent.waitsForBackendResponse;
    const hasFile = !!blockContent['file'];
    blockContent.waitsForBackendResponse = false;

    return resourceDataService.updateEditorBlock(cardUid, sectionUid, blockContent, languageCode).pipe(
      switchMap(({ isSuccess, value }) => {
        if (isSuccess) {
          return FileUploadHelper.uploadBlockObservable(blockContent['file'], fileUploadService, value.uploadUrl, value.item.uid).pipe(
            tap(() => {
              const state = getState();

              const sections: ResourceSection[] = state.resource.content.sections;
              const sectionIndex = this.getSectionIndex(state.resource._id, sectionUid, sections);
              const section = sections[sectionIndex];

              const updatedSections = cloneDeep(sections) as ResourceSection[];

              const updatedBlock: EditorContent = {
                ...value.item,
                waitsForBackendResponse: waitsForBackendResponse,
                hasFile: hasFile,
              };

              // we are re-setting this value here as we only need to perform single backend response update
              waitsForBackendResponse = false;

              const blockIndex = this.getDynamicContentIndex(cardUid, section, blockContent.uid);

              updatedSections[sectionIndex].dynamicContent.splice(blockIndex, 1, updatedBlock);

              patchState({
                resource: {
                  ...state.resource,
                  content: {
                    ...state.resource.content,
                    sections: updatedSections,
                  },
                },
                contentChanged: false,
                saveStatus: {
                  autoSaveInProgress: false,
                },
              });
            }),
          );
        } else {
          toastService.showFail(translocoService.translate('translations.errors.errorUpdatingBlockItem'));
          return this.handleFailure(patchState);
        }
      }),
    );
  }

  static backendResponseAppliedToBlocks(
    blocksUid: string[],
    sectionUid: string,
    getState: () => ResourceAdminStateModel,
    patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
  ) {
    const state = getState();

    const sections: ResourceSection[] = state.resource.content.sections;
    const sectionIndex = this.getSectionIndex(state.resource._id, sectionUid, sections);

    const updatedSections = cloneDeep(sections) as ResourceSection[];

    updatedSections[sectionIndex].dynamicContent.forEach((blockContent: EditorContent, index: number) => {
      if (blocksUid.includes(blockContent.uid)) {
        updatedSections[sectionIndex].dynamicContent[index] = { ...blockContent, waitsForBackendResponse: false };
      }
    });

    patchState({
      resource: {
        ...state.resource,
        content: {
          ...state.resource.content,
          sections: updatedSections,
        },
      },
    });
  }

  // That method is necessary for handling multiple responses from file upload
  private static removeDuplicates(dynamicContent: EditorContent[]): EditorContent[] {
    return dynamicContent.filter(
      (editorContent, editorContentIndex, self) => editorContentIndex === self.findIndex((block) => block.uid === editorContent.uid),
    );
  }

  private static getSectionIndex(cardUid: string, sectionUid: string, sections: ResourceSection[]): number {
    const sectionIndex = sections.findIndex((section) => section.uid === sectionUid);
    if (sectionIndex === -1) throw new Error(`Section ${sectionUid} do not exists on card ${cardUid}!`);
    return sectionIndex;
  }

  private static getDynamicContentIndex(cardUid: string, section: ResourceSection, blockUid: string): number {
    const blockIndex = section.dynamicContent.findIndex((dynamicContent) => dynamicContent.uid === blockUid);
    if (blockIndex === -1) throw new Error(`Block with id ${blockUid} not found on card ${cardUid} and section ${section.uid}!`);
    return blockIndex;
  }

  private static handleFailure(patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>) {
    patchState({
      contentChanged: false,
      saveStatus: {
        autoSaveInProgress: false,
      },
    });
    return of(null); // Handle failure case
  }
}
