import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BookingFormContent, Form, numericOnlyValidator } from '../../../../models';
import { BOOKING_DATA_SERVICE, BookingDataService } from '../../../../../shared/services/booking/booking-data.service';
import {
  BookableAppointment,
  BookableAppointmentRequest,
  BookableInterval,
  BookableResource,
  BookableResourceRequest,
  BookableSlotAdmin,
  BookableSlotAdminRequest,
  DEFAULT_APPOINTMENT_REQUEST,
  DEFAULT_BUFFER,
  DEFAULT_CANCELLATION,
  DEFAULT_DURATION,
} from '../../../../../shared/models/editor/booking-content.model';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { duration } from 'moment';
import { Select } from '@ngxs/store';
import { combineLatest, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { UserSearch } from '../../../../../shared/models/admin/members.model';
import { MEMBERS_DATA_SERVICE, MembersDataService } from '../../../../../page-modules/admin/services/members/members-data.service';
import { UserSearchAutocompleteComponent } from '../../../../../shared/components/user-search-autocomplete/user-search-autocomplete.component';
import { CurrentLanguage } from '../../../../../page-modules/resource/store/admin/resource-event-admin.state.model';
import { DEFAULT_LANGUAGE_CODE } from '../../../../../shared/services/languages/language.service';
import { ObservableResult } from '../../../../../shared/store';
import { Resource } from '../../../../../shared/models';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from '../../../../../shared/services/toast-notifications/toast-service';
import { ResourceAdminState } from '../../../../../page-modules/resource/store/admin/resource-admin.state';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ptl-form-booking',
  styleUrls: ['./form-booking.component.scss'],
  templateUrl: './form-booking.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormBookingComponent implements OnInit, OnChanges, OnDestroy {
  /** Receives the input data object */
  private _form: Form | undefined;
  private _canRemoveItem: boolean | undefined;
  private _position: number | string | undefined;

  @Input()
  set form(value: Form | undefined) {
    if (typeof value === 'string') {
      this._form = JSON.parse(decodeURIComponent(value));
    } else {
      this._form = value;
    }
  }

  get form() {
    return this._form;
  }

  /** Position in the Form sections */
  @Input()
  set position(value: number | string | undefined) {
    if (typeof value === 'string') {
      this._position = JSON.parse(decodeURIComponent(value));
    } else {
      this._position = value;
    }
  }

  get position() {
    return this._position;
  }

  @Input()
  set canRemoveItem(value) {
    if (typeof value === 'string') {
      this._canRemoveItem = JSON.parse(decodeURIComponent(value));
    } else {
      this._canRemoveItem = value;
    }
  }

  get canRemoveItem() {
    return this._canRemoveItem;
  }

  /** Emits content data on saveForm() */
  @Output() formElementAdded = new EventEmitter<Form>();

  /** Emits removing event of this form with index */
  @Output() formElementRemoved = new EventEmitter<void>();

  @Select(ResourceAdminState.resource)
  private resource$: Observable<Resource>;

  @Select(ResourceAdminState.currentLanguage)
  private currentLanguage$: Observable<CurrentLanguage>;

  @ViewChild('userSearchAutocomplete') private userSearchAutocomplete: UserSearchAutocompleteComponent;

  nameFormControl: FormControl;
  bookingForm: FormGroup;
  appointmentForm: FormGroup;
  expanded = true;
  loadingMembers: boolean;
  members: UserSearch[];
  appointment: BookableAppointment;
  bookableResources: BookableResource[] = [];
  currentLanguage: string;
  timeIntervals: BookableInterval[];
  cardUid: string = null;
  scheduleIntervalSaved = false;
  isLoading = false;
  appointmentHasError = false;
  resourceHasError = false;
  isUserTypeSelected = true;
  showUserResourceStep = true;
  resourcesToUpdate: { resourceUid: string; scheduleUid: string; slots: BookableSlotAdmin[] }[] = [];
  isSaved = false;
  hasErrorName: boolean;
  forceExpand = false;

  private userSearchPage = 0;
  private userSearchPageSize = 20;
  private searchInputSubscription: Subscription;
  private resourcesLoadingSubscription: Subscription;
  private appointmentLoadingSubscription: Subscription;
  private resourceCreationSubscription: Subscription;
  private appointmentCreationSubscription: Subscription;
  private appointmentUpdateSubscription: Subscription;
  private searchInputSubject = new Subject<string>();
  private subscriptionEnd$ = new EventEmitter<void>();
  private scheduleAvailabilitySubscription: Subscription;
  private userSearchSubscription: Subscription;
  private changesSubmissionSubscription: Subscription;
  private languagesWithResourcesSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    @Inject(BOOKING_DATA_SERVICE) private bookingDataService: BookingDataService,
    @Inject(MEMBERS_DATA_SERVICE) private membersDataService: MembersDataService,
    private translocoService: TranslocoService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
  ) {
    this.searchInputSubscription = this.searchInputSubject
      .pipe(debounceTime(500))
      .subscribe((searchValue) => this.fireSearch(searchValue, true));

    this.bookingForm = this.fb.group({
      title: ['', [Validators.required]],
    });
    this.nameFormControl = new FormControl('', [Validators.required]);
    this.resourcesToUpdate = [];
    this.appointmentForm = this.fb.group({
      duration: ['', [Validators.required, numericOnlyValidator()]],
      bookableInterval: ['', [Validators.required, numericOnlyValidator()]],
      bufferTimeBefore: ['', [Validators.required, numericOnlyValidator()]],
      bufferTimeAfter: ['', [Validators.required, numericOnlyValidator()]],
      bookingWindowStart: ['', [Validators.required, numericOnlyValidator()]],
      bookingWindowEnd: ['', [Validators.required, numericOnlyValidator()]],
      cancellationThreshold: ['', [Validators.required, numericOnlyValidator()]],
    });
  }

  ngOnChanges(): void {
    this.bookingForm.patchValue({
      title: (this.form?.content as BookingFormContent)?.title,
    });
  }

  ngOnInit() {
    const content = this.form?.content as BookingFormContent;
    this.languagesWithResourcesSubscription = combineLatest([this.currentLanguage$, this.resource$])
      .pipe(
        filter((data) => !!data[0] && !!data[1]),
        takeUntil(this.subscriptionEnd$),
      )
      .subscribe(([currentLanguage, resource]) => {
        if (currentLanguage && resource) {
          this.cardUid = resource._id;
          this.currentLanguage =
            currentLanguage.supportedLanguage != null ? currentLanguage.supportedLanguage?.language?.code : DEFAULT_LANGUAGE_CODE;
          const bookableAppointmentUid = content?.bookableAppointmentId;
          if (bookableAppointmentUid !== null) {
            this.onRequestProcessing();
            this.appointmentLoadingSubscription = this.bookingDataService
              .getBookableAppointment(bookableAppointmentUid, this.currentLanguage)
              .subscribe(({ isSuccess, value }) => {
                if (isSuccess) {
                  this.appointment = value;

                  this.nameFormControl.patchValue(value.name);

                  this.appointmentForm.setValue({
                    duration: moment.duration(this.appointment?.duration).asMinutes().toString(),
                    bookableInterval: moment.duration(this.appointment?.bookableInterval).asMinutes().toString(),
                    bufferTimeBefore: moment.duration(this.appointment?.bufferTimeBefore).asMinutes().toString(),
                    bufferTimeAfter: moment.duration(this.appointment?.bufferTimeAfter).asMinutes().toString(),
                    bookingWindowStart: moment.duration(this.appointment?.bookingWindowStart).asDays().toString(),
                    bookingWindowEnd: moment.duration(this.appointment?.bookingWindowEnd).asDays().toString(),
                    cancellationThreshold: moment.duration(this.appointment?.cancellationThreshold).asHours().toString(),
                  });
                  this.prepareTimeIntervals();
                  this.showUserResourceStep = true;
                  this.isLoading = false;
                  this.cd.detectChanges();
                } else {
                  this.onAppointmentError();
                }
              });
            this.loadResources(bookableAppointmentUid);
          } else {
            this.initializeFormWithDefaultValues();
            this.activateResourceStep();
            this.cd.detectChanges();
          }
        }
      });
    if (this.form && !!this.form?.uid) {
      this.expanded = this.form.newAddedForm ?? false;
      this.bookingForm.patchValue({
        title: content.title.length > 0 ? content.title : this.translocoService.translate('translations.bookings.label.title'),
      });
    }
    this.forceExpand = this.form?.content?.forceExpand;
  }

  ngOnDestroy() {
    this.subscriptionEnd$?.emit();
    this.searchInputSubscription?.unsubscribe();
    this.resourcesLoadingSubscription?.unsubscribe();
    this.appointmentLoadingSubscription?.unsubscribe();
    this.resourceCreationSubscription?.unsubscribe();
    this.appointmentCreationSubscription?.unsubscribe();
    this.appointmentUpdateSubscription?.unsubscribe();
    this.scheduleAvailabilitySubscription?.unsubscribe();
    this.userSearchSubscription?.unsubscribe();
    this.changesSubmissionSubscription?.unsubscribe();
    this.languagesWithResourcesSubscription?.unsubscribe();
  }

  saveForm() {
    if (this.bookingForm.valid && this.nameFormControl.valid) {
      this.emitOutputData();
    }
  }

  expandForm() {
    this.expanded = true;
  }

  collapseForm(event: PointerEvent) {
    event.stopPropagation();
    this.expanded = false;
    this.forceExpand = false;
  }

  emitOutputData() {
    if (this.form && this.bookingForm) {
      this.expanded = true;
      const outputData: Form = {
        ...this.form,
        newAddedForm: false,
        content: {
          ...this.bookingForm.value,
          bookableAppointmentId: (this.form.content as BookingFormContent).bookableAppointmentId,
          type: 'BOOKING',
        } as BookingFormContent,
      };
      this.formElementAdded.emit(outputData);
    }
  }

  removeForm() {
    this.formElementRemoved.emit();
  }

  onSearchInputChange(searchValue: string) {
    this.searchInputSubject.next(searchValue);
  }

  onSearchLoadingMore(searchValue: string) {
    this.fireSearch(searchValue, false);
  }

  onMemberSelected(data: UserSearch) {
    // TODO: Make the maxSimultaneousBookings parameter customizable
    if (this.bookableResources.some((resource) => resource.targetResourceSummary.uid === data.uid)) {
      this.onResourceError();
    } else {
      const request: BookableResourceRequest = {
        bookableAppointmentUid: (this.form.content as BookingFormContent).bookableAppointmentId,
        maxSimultaneousBookings: 1,
        userUid: data.uid,
      };
      this.onRequestProcessing();
      this.resourceCreationSubscription = this.bookingDataService
        .createBookableResource(request, this.cardUid, this.currentLanguage)
        .subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            this.toastService.showSuccess(this.translocoService.translate('translations.bookings.label.success.resourceSaved'));
            this.isLoading = false;
            this.bookableResources.push(value);
            this.cd.detectChanges();
          } else {
            this.onResourceError();
          }
        });
    }
  }

  saveAppointmentForm() {
    if (this.appointment?.id) {
      this.updateAppointment(DEFAULT_APPOINTMENT_REQUEST);
    } else {
      this.createNewAppointment(DEFAULT_APPOINTMENT_REQUEST);
    }
  }

  activateResourceStep() {
    this.showUserResourceStep = true;
    if (this.isUserTypeSelected) {
      if (!this.appointment) {
        this.saveAppointmentForm();
      }
    }
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
    this.cd.detectChanges();
  }

  updateResourcesRequests(request: { resourceUid: string; scheduleUid: string; slots: BookableSlotAdmin[] }) {
    const existingIndex = this.resourcesToUpdate.findIndex((resource) => resource.resourceUid === request.resourceUid);
    if (existingIndex !== -1) {
      this.resourcesToUpdate[existingIndex] = request;
    } else {
      this.resourcesToUpdate.push(request);
    }
  }

  submitChanges(): void {
    if (!this.nameFormControl.valid) {
      this.hasErrorName = true;

      this.toastService.showFail(this.translocoService.translate('translations.bookings.label.errors.bookingNameError'));
      return;
    }

    const request = DEFAULT_APPOINTMENT_REQUEST;
    request.name = this.nameFormControl.value;

    this.updateAppointment(request);

    if (this.resourcesToUpdate.length > 0) {
      const observables = this.resourcesToUpdate.map((resource) => {
        return this.updateResourceSlots(resource.resourceUid, resource.scheduleUid, resource.slots);
      });
      this.onRequestProcessing();
      this.changesSubmissionSubscription = forkJoin(observables).subscribe(
        (_) => {
          /* tslint:disable:no-empty */
        },
        (_) => {
          this.onResourceError();
        },
        () => {
          this.resourcesToUpdate = [];
          this.onSuccessfulSave();
          this.cd.detectChanges();
        },
      );
    } else {
      this.onSuccessfulSave();
      this.cd.detectChanges();
      return;
    }
  }

  updateResourceSlots(resourceUid: string, scheduleUid: string, slots: BookableSlotAdmin[]): ObservableResult<BookableSlotAdmin[]> {
    const requests = slots.map(
      (item) => new BookableSlotAdminRequest(item.weekday.toUpperCase(), item.startTime + ':00', item.endTime + ':00', item.id),
    );
    return this.bookingDataService.updateResourceSlots(this.cardUid, resourceUid, scheduleUid, requests, this.currentLanguage);
  }

  onResourceRefresh() {
    this.loadResources(this.appointment.id);
  }

  private loadResources(bookableAppointmentUid: string) {
    this.resourcesLoadingSubscription = this.bookingDataService
      .getAppointmentResources(bookableAppointmentUid, this.cardUid, this.currentLanguage)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.bookableResources = value;
          this.selectResourceType();
          this.cd.detectChanges();
        } else {
          this.onResourceError();
        }
      });
  }

  private selectResourceType() {
    this.isUserTypeSelected = true;
  }

  private prepareTimeIntervals() {
    const startTime = moment().startOf('day');
    const endTime = moment().endOf('day');
    const intervalDuration = moment.duration(this.appointment?.bookableInterval);
    const intervals = [];

    const currentIntervalStart = startTime.clone();
    while (currentIntervalStart.isBefore(endTime)) {
      const currentIntervalEnd = currentIntervalStart.clone().add(intervalDuration);
      const interval = {
        start: currentIntervalStart.format('HH:mm'),
        end: currentIntervalEnd.format('HH:mm'),
      };
      intervals.push(interval);
      currentIntervalStart.add(intervalDuration);
    }
    this.timeIntervals = intervals;
  }

  private fireSearch(searchValue: string, override: boolean) {
    this.userSearchPage = override ? 0 : this.userSearchPage + 1;
    this.loadingMembers = true;
    if (!searchValue) {
      this.loadingMembers = false;
      return;
    }
    this.cd.detectChanges();
    this.userSearchSubscription = this.membersDataService
      .searchUsers(this.userSearchPage, this.userSearchPageSize, searchValue)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          if (override) {
            this.members = value.content;
          } else {
            this.members = this.members.concat(value.content);
          }
        }
        this.userSearchAutocomplete.canLoadMore = value.totalNumberOfElement > this.members.length;
        this.userSearchAutocomplete.isLoadingMore = false;
        this.loadingMembers = false;
        this.cd.detectChanges();
      });
  }

  private createNewAppointment(request: BookableAppointmentRequest) {
    this.onRequestProcessing();
    this.appointmentCreationSubscription = this.bookingDataService
      .createBookableAppointment(request, this.cardUid, this.currentLanguage)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.appointment = value;
          this.prepareTimeIntervals();
          (this.form.content as BookingFormContent).bookableAppointmentId = value ? value.id : null;
          if (this.bookingForm.valid) {
            this.emitOutputData();
          }
          this.isLoading = false;
          this.cd.detectChanges();
        } else {
          this.onAppointmentError();
        }
      });
  }

  private initializeFormWithDefaultValues() {
    this.appointmentForm.setValue({
      duration: duration({ minutes: DEFAULT_DURATION }).asMinutes().toString(),
      bookableInterval: duration({ minutes: DEFAULT_DURATION }).asMinutes().toString(),
      bufferTimeBefore: duration({ minutes: DEFAULT_BUFFER }).asMinutes().toString(),
      bufferTimeAfter: duration({ minutes: DEFAULT_BUFFER }).asMinutes().toString(),
      bookingWindowStart: duration({ days: DEFAULT_BUFFER }).asDays().toString(),
      bookingWindowEnd: duration({ days: DEFAULT_DURATION }).asDays().toString(),
      cancellationThreshold: duration({ hours: DEFAULT_CANCELLATION }).asHours().toString(),
    });
  }

  private updateAppointment(request: BookableAppointmentRequest) {
    this.onRequestProcessing();
    this.appointmentUpdateSubscription = this.bookingDataService
      .updateBookableAppointment(this.appointment?.id, request, this.cardUid, this.currentLanguage)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.appointment = value;
          this.prepareTimeIntervals();
          this.isLoading = false;
          this.cd.detectChanges();
        } else {
          this.onAppointmentError();
        }
      });
  }

  private onAppointmentError() {
    this.appointmentHasError = true;
    this.onRequestError();
  }

  private onResourceError() {
    this.resourceHasError = true;
    this.onRequestError();
  }

  private onRequestProcessing() {
    this.isLoading = true;
    this.appointmentHasError = false;
  }

  private onRequestError() {
    this.scheduleIntervalSaved = false;
    this.isLoading = false;
    this.cd.detectChanges();
  }

  private onSuccessfulSave() {
    this.isLoading = false;
    this.isSaved = true;
    this.expanded = false;
    this.forceExpand = false;
    this.toastService.showSuccess(this.translocoService.translate('translations.bookings.label.success.scheduleSaved'));
  }
}
