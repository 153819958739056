<!--
  ~ Copyright (C) 2025 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  ogFileDrop
  class="file-dropzone-container"
  [class.hovered]="filesHovered"
  (filesDropped)="onFileDropped($event)"
  (filesHovered)="filesHovered = $event"
  (click)="triggerFileSelection()"
  (keydown.enter)="triggerFileSelection()"
>
  <div class="file-dropzone__icon">
    <svg class="svg svg-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <use ogSvgUnify="assets/sprite/sprite.svg#file-upload"></use>
    </svg>
  </div>
  <div class="file-dropzone__input-container">
    <input
      #dropzoneFileInput
      type="file"
      class="file-dropzone__input"
      [multiple]="maxFilesCount > 1"
      [accept]="acceptedFileTypes"
      (change)="onFileSelected($event)"
    />
    <p class="file-dropzone__input-text" tabindex="0" role="button">
      <span class="file-dropzone__input-text--highlight">{{ 'translations.fileDropzone.clickToUpload' | transloco }}</span>
      <span class="file-dropzone__input-text">{{ 'translations.fileDropzone.orDragAndDrop' | transloco }}</span>
    </p>
    <p class="file-dropzone__input-text" *ngIf="type !== UploadFileType.ALL_TYPES && type !== UploadFileType.CSV">
      {{
        'translations.fileDropzone.supportedFormats'
          | transloco
            : {
                threeSupportedFormats: threeSupportedFormats,
                fourthSupportedFormat: fourthSupportedFormat,
              }
      }}
      ({{ 'translations.fileDropzone.maxSize' | transloco: { maxFileSizeMB: maxFileSizeMB } }})
    </p>
    <p class="file-dropzone__input-text" *ngIf="type === UploadFileType.ALL_TYPES">
      {{ 'translations.fileDropzone.uploadAllFileTypes' | transloco }} ({{
        'translations.fileDropzone.maxSize' | transloco: { maxFileSizeMB: maxFileSizeMB }
      }})
    </p>
    <p class="file-dropzone__input-text--error-text" *ngIf="uploadErrorText">{{ uploadErrorText }}</p>
  </div>
</div>
