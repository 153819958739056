/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TinyMceEditorDirective } from '../tinymce-editor/tinymce-editor-dir/tinymce-editor.dir';
import { Subject, Subscription } from 'rxjs';
import { MediumEditorData } from '../../../../../shared/models';
import { TABLE_EDITOR_OPTIONS } from './table.options';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'ptl-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('tinyEditor', { read: TinyMceEditorDirective, static: true }) tinyEditor: TinyMceEditorDirective;

  /** Receives the MediumEditorData */
  @Input() contentData: MediumEditorData;

  /** Outputs the various click and change events */
  @Output() tinymceEditorUpdate = new EventEmitter<MediumEditorData>();
  /** Emits removing of the embed content event */
  @Output() removeEmbedContent = new EventEmitter<void>();

  editorInput = '';
  editorOptions = TABLE_EDITOR_OPTIONS;

  private editorUpdateSubject$ = new Subject<MediumEditorData>();

  private editorOnInitSubscription: Subscription;
  private editorKeyupSubscription: Subscription;
  private editorBlurSubscription: Subscription;
  private editorUpdateSubscription: Subscription;
  private editorOnChangeSubscription: Subscription;
  private tableEditorChangeSubscription: Subscription;

  constructor() {
    this.tableEditorChangeSubscription = this.editorUpdateSubject$
      .pipe(
        debounceTime(100),
        distinctUntilChanged((data: MediumEditorData) => data.content === this.editorInput),
      )
      .subscribe((value: MediumEditorData) => this.tinymceEditorUpdate.emit(value));
  }

  ngOnInit(): void {
    if (this.contentData && !!this.contentData.content) {
      let content = JSON.stringify(this.contentData.content);
      const hasOpenedDivTag = content.indexOf('<div>') !== -1;
      const hasClosedDivTag = content.indexOf('</div>') !== -1;
      if ((!hasOpenedDivTag && hasClosedDivTag) || (hasOpenedDivTag && !hasClosedDivTag)) {
        content = content.replace(/<\/div>/g, '').replace(/<div>/g, '');
      }

      this.editorInput = JSON.parse(content);
    }
  }

  ngAfterViewInit(): void {
    this.loadSubscriptions();

    this.editorOnInitSubscription = this.tinyEditor.editorBox.onInit?.subscribe(({ event, editor }) => {
      if (!this.contentData || !this.contentData.content) {
        this.tinyEditor?.editorBox.editor?.execCommand('mceInsertTable', false, { rows: 2, columns: 2 });
        this.tinyEditor?.editableTableInput();
      } else {
        if (editor.iframeElement.contentWindow) {
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          const tinymceInstance: any = editor.iframeElement.contentWindow['tinymce'];

          if (tinymceInstance) {
            this.deleteUnusedElements(tinymceInstance);
          }
        }
      }
    });
  }

  loadSubscriptions(): void {
    this.editorUpdateSubscription = this.tinyEditor?.editorModelChange.subscribe(() => {
      this.editorUpdateSubject$.next({
        uid: this.contentData?.uid,
        type: 'TABLE',
        content: this.editorInput,
      } as MediumEditorData);
    });

    this.editorKeyupSubscription = this.tinyEditor.editorBox.onKeyUp?.subscribe((data, editor) => {
      setTimeout(() => this.tinyEditor.editableTableInput());
    });

    this.editorBlurSubscription = this.tinyEditor.editorBox.onBlur?.subscribe(({ event, editor }) => {
      if (editor.iframeElement.contentWindow) {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        const tinymceInstance: any = editor.iframeElement.contentWindow['tinymce'];

        if (tinymceInstance) {
          this.deleteUnusedElements(tinymceInstance);
        }
      }
    });

    this.editorOnChangeSubscription = this.tinyEditor.editorBox.onChange?.subscribe(({ event, editor }) => {
      if (editor.iframeElement.contentWindow) {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        const tinymceInstance: any = editor.iframeElement.contentWindow['tinymce'];

        if (tinymceInstance) {
          setTimeout(() => {
            this.deleteUnusedElements(tinymceInstance);
          }, 0);
        }
      }

      setTimeout(() => this.tinyEditor.editableTableInput());
    });
  }

  ngOnChanges() {
    if (this.contentData.content) {
      this.editorInput = JSON.parse(JSON.stringify(this.contentData.content));
    }
  }

  ngOnDestroy() {
    this.editorOnInitSubscription?.unsubscribe();
    this.editorKeyupSubscription?.unsubscribe();
    this.editorUpdateSubscription?.unsubscribe();
    this.editorOnChangeSubscription?.unsubscribe();
    this.tableEditorChangeSubscription?.unsubscribe();
    this.editorBlurSubscription?.unsubscribe();
  }

  private deleteUnusedElements(targetElm: HTMLElement) {
    const children = targetElm.children;
    const listToDelete = [];

    for (let i = 0; i < children.length; i++) {
      if (children[i].tagName === 'TABLE') {
        continue;
      }
      if (children[i].classList.contains('mce-resizehandle')) {
        continue;
      }

      listToDelete.push(children[i]);
    }

    listToDelete.map((item) => item.remove());

    if (children.length === 0) {
      this.removeEmbedContent.emit();
    }
  }
}
