<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="members-list__container-outer">
  <ptl-admin-member-table
    #defaultTable
    [columns]="columns"
    [isPlaylistPage]="isPlaylistPage"
    [isCardPage]="isCardPage"
    [isEvent]="isEvent"
    [members]="members"
    [booleanSearchRequest]="booleanSearchRequest"
    [membersTotalCount]="membersTotalCount"
    [isWaitingListEnabled]="isWaitingListEnabled"
    [isWaitingListOperationDisabled]="isWaitingListOperationDisabled"
    [currentPage]="currentPage"
    [pageSize]="pageSize"
    [paginationLength]="paginationLength"
    [isLoaderActive]="isLoaderActive"
    [orderInput]="orderInput"
    [expanded]="false"
    (orderChanged)="orderChangedTrigger($event)"
    (membersChecked)="membersCheckedTrigger($event)"
    (memberActionTriggered)="memberActionTrigger($event)"
    (currentPageChanged)="setCurrentPage($event)"
    (pageSizeChanged)="setPageSize($event)"
    (openUserRecord)="openUserRecord($event)"
  >
  </ptl-admin-member-table>
</div>
