/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ResourceTag } from '../../../models';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';
import { UserDetails } from '../../../../user-auth/models';
import { Framework, FrameworkType } from '../../../models/admin/admin.model';
import { TagsList } from '../frameworks-models/tag-list.model';

export interface TagChangeEmitterData {
  checked: boolean;
  tag: ResourceTag;
}

@Component({
  selector: 'ptl-framework-tags-list',
  templateUrl: './framework-tags-list.component.html',
  styleUrls: ['./framework-tags-list.component.scss'],
})
export class FrameworkTagsListComponent implements OnChanges {
  /** The selected framework tags */
  @Input() frameworkTags: TagsList[];

  @Input() frameworkNestedTags: TagsList[];

  @Input() selectedFramework: Framework;

  /** Is only a single selection allowed */
  @Input() singleSelection: boolean;

  /** Selected tag ids for default checking */
  @Input() selectedTagIds: string[];

  /** Is Tag disabled */
  @Input() isTagDisabled = false;

  /** Show admin actions */
  @Input() type: 'DEFAULT' | 'ADMIN' = 'DEFAULT';
  /** Framework type to be managed */
  @Input() frameworkType: FrameworkType = 'STANDARD';

  /** Emits when tag checkbox is checked/unchecked. */
  @Output() addRemoveTag = new EventEmitter<TagChangeEmitterData>();

  @Output() loadNestedTags = new EventEmitter<string>();

  @Output() backToPreviousTags = new EventEmitter<void>();

  tags: TagsList[];

  @Select(UserAuthState.userDetailsData)
  userDetailsData$: Observable<UserDetails>;

  @Select(UserAuthState.userHasSuperAdminRole)
  isUserSuperAdmin$: Observable<boolean>;

  @Select(UserAuthState.userHasAdminRole)
  isUserAdmin$: Observable<boolean>;

  private loadedTagId: string;

  ngOnChanges(): void {
    if (this.frameworkTags && this.selectedTagIds) {
      this.setTagsAsChecked(this.frameworkTags);
    }

    if (!this.frameworkNestedTags) {
      this.tags = this.frameworkTags;
    } else {
      if (this.loadedTagId) {
        const tagsFound = this.findTagsById(this.tags, this.loadedTagId);
        if (tagsFound.length) {
          tagsFound.map((item) => (item.childTags = this.frameworkNestedTags));
        }
        this.setTagsAsChecked(tagsFound);
        this.loadedTagId = undefined;
      } else {
        this.tags = this.frameworkNestedTags;
      }
    }
  }

  onTagItemClick(tag: ResourceTag & { checked?: boolean }) {
    tag.checked = !tag.checked;
    this.addRemoveTag.emit({
      checked: tag.checked,
      tag,
    });

    if (this.singleSelection) {
      for (const tagItem of this.frameworkTags) {
        if (tagItem._id !== tag._id) {
          tagItem.checked = false;
          this.addRemoveTag.emit({
            checked: tagItem.checked,
            tag: tagItem,
          });
        }
      }
    }
  }

  loadNestedFramework(event, tagId: string) {
    event.preventDefault();
    event.stopPropagation();
    this.loadedTagId = tagId;
    this.loadNestedTags.emit(tagId);
  }

  closeNestedTags() {
    this.backToPreviousTags.emit();
  }

  findTagsById(tags: TagsList[], tagId: string): TagsList[] {
    const foundTags: TagsList[] = [];

    for (const item of tags) {
      if (item._id === tagId) {
        foundTags.push(item);
      }

      if (item.childTags) {
        const foundInChildren = this.findTagsById(item.childTags, tagId);
        foundTags.push(...foundInChildren); // Concatenate arrays
      }
    }

    return foundTags;
  }

  private setTagsAsChecked(tags: TagsList[]): void {
    tags.forEach((tag: TagsList) => {
      if (tag.childTags && tag.childTags.length > 0) {
        this.setTagsAsChecked(tag.childTags);
      } else if (this.selectedTagIds && this.selectedTagIds.includes(tag._id)) {
        tag.checked = true;
        this.addRemoveTag.emit({
          checked: tag.checked,
          tag,
        });
      }
    });
  }
}
