import { NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';

export class SnackbarHelper {
  static showTranslatableSnackBar(
    ngZone: NgZone,
    snackBar: MatSnackBar,
    translocoService: TranslocoService,
    key: string,
    duration: number = 3000,
    vPos: 'top' | 'bottom' = 'bottom',
    panelClass: string = '',
  ) {
    ngZone.run(() => {
      snackBar.open(translocoService ? translocoService.translate(key) : key, '', {
        panelClass,
        duration: duration,
        horizontalPosition: 'center',
        verticalPosition: vPos,
      });
    });
  }

  static showSnackBar(ngZone: NgZone, snackBar: MatSnackBar, message: string, duration: number = 3000, vPos: 'top' | 'bottom' = 'bottom') {
    ngZone.run(() => {
      snackBar.open(message, '', { duration: duration, horizontalPosition: 'center', verticalPosition: vPos });
    });
  }
}
