/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { Injectable } from '@angular/core';
import { SharedCardDataService } from './card-data.service';
import { ApprovalRequest, DiagnosticsWithProgress } from '../../models';

@Injectable()
export class MockSharedCardDataService implements SharedCardDataService {
  registerCardOpen(): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  getCardDetailWithProgress(cardUri: string): ObservableResult<DiagnosticsWithProgress> {
    return ObservableResult.ofSuccess();
  }

  associateTag(resourceUid: string, tagUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  dissociateTag(resourceUid: string, tagUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateCardUri(resourceUid: string, newUri: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  publishCard(resourceUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  unpublishCard(resourceUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  archiveCard(resourceUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateApprovalMethod(resourceUid: string, request: ApprovalRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  getReviewsCount(cardId: string): ObservableResult<number> {
    return ObservableResult.ofSuccess();
  }

  associateReviewer(resourceUid: string, userUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  dissociateReviewer(resourceUid: string, userUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
