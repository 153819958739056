<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="add-section-dialog">
  <div class="dialog-header">
    <div class="dialog-header__top">
      <h1 class="dialog-header-title" tabindex="0" [attr.aria-label]="data.title | transloco">
        {{ data.title | transloco }}
      </h1>

      <i
        class="material-icons dialog-header__close-icon"
        [attr.aria-label]="'translations.global.ariaLabels.closePage' | transloco"
        (click)="closeDialog()"
        (keydown.enter)="closeDialog()"
        tabindex="0"
        >close</i
      >
    </div>

    <div class="add-section-tabs">
      <ng-container [ngSwitch]="data.addType">
        <ng-container *ngSwitchCase="'template'">
          <!-- Just a single tab, don't show anything -->
        </ng-container>

        <ng-container *ngSwitchCase="'block'">
          <div
            class="tab-item small"
            [class.is_active]="selectedTab === 'BASIC_BLOCK'"
            tabindex="0"
            [attr.aria-label]="'translations.pages.newSection.tabs.basicBlocks' | transloco"
            (click)="showTab('BASIC_BLOCK')"
            (keydown.enter)="showTab('BASIC_BLOCK')"
          >
            <svg class="svg" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#image"></use>
            </svg>
            <span class="title">{{ 'translations.pages.newSection.tabs.basicBlocks' | transloco }}</span>
          </div>

          <div
            class="tab-item small"
            [class.is_active]="selectedTab === 'AUTOMATIC'"
            tabindex="0"
            [attr.aria-label]="'translations.pages.newSection.tabs.automation' | transloco"
            (click)="showTab('AUTOMATIC')"
            (keydown.enter)="showTab('AUTOMATIC')"
          >
            <svg class="svg" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#advanced"></use>
            </svg>
            <span class="title">{{ 'translations.pages.newSection.tabs.automation' | transloco }}</span>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="add-section-dialog-body">
      <ng-container [ngSwitch]="selectedTab">
        <div class="section-items-list" *ngSwitchCase="'PAGES'">
          <div
            class="section-item"
            *ngFor="let item of pagesSectionItems"
            tabindex="0"
            [attr.aria-label]="item.title | transloco"
            (click)="onSectionClick(item.type)"
            (keydown.enter)="onSectionClick(item.type)"
          >
            <div class="section-image">
              <img alt="{{ item.title | transloco }}" src="{{ item.image }}" width="" />
            </div>
            <div class="section-title small">
              {{ item.title | transloco }}
            </div>
          </div>
        </div>

        <div class="section-items-list" *ngSwitchCase="'BASIC_BLOCK'">
          <div
            class="section-item"
            *ngFor="let item of basicBlockSectionItems"
            tabindex="0"
            [attr.aria-label]="item.title | transloco"
            (click)="onSectionClick(item.type)"
            (keydown.enter)="onSectionClick(item.type)"
          >
            <div class="section-image">
              <img alt="{{ item.title | transloco }}" src="{{ item.image }}" width="" />
            </div>
            <div class="section-title small">
              {{ item.title | transloco }}
            </div>
          </div>
        </div>

        <div class="section-items-list" *ngSwitchCase="'AUTOMATIC'">
          <div
            class="section-item"
            *ngFor="let item of automaticSectionItems"
            tabindex="0"
            [attr.aria-label]="item.title | transloco"
            (click)="onSectionClick(item.type)"
            (keydown.enter)="onSectionClick(item.type)"
          >
            <div class="section-image">
              <img alt="{{ item.title | transloco }}" src="{{ item.image }}" width="" />
            </div>
            <div class="section-title small">
              {{ item.title | transloco }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
