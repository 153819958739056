<!--
  ~ Copyright (C) 2021 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="share-discovery-review-content">
  <div class="share-discovery-review-image">
    <img src="../../../../../../../assets/discovery-share-success.jpg" alt="" />
  </div>

  <div class="share-discovery-review-description" [innerHTML]="'translations.discoveryShareReviewSuccess' | transloco"></div>

  <div class="button-box">
    <button mat-flat-button class="uppercase" (click)="closeDialog()">{{ 'translations.global.button.back' | transloco }}</button>
  </div>
</div>
