import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ReviewEventType } from '../../../models';
import {
  BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType,
  NotRequest,
  OrRequest,
  ReviewStatusRequest,
} from '../../../models/admin/boolean-filters.model';
import { SubmissionMode } from '../../../../page-modules/playlist/models/submission-logic.model';

@Component({
  selector: 'ptl-review-status-filter',
  templateUrl: './review-status-filter.component.html',
  styleUrls: ['./review-status-filter.component.scss'],
})
export class ReviewStatusFilterComponent implements OnInit {
  @Input() submissionMode: SubmissionMode;
  @Input() selectedFilters: BooleanFilter[];
  @Output() filterDeleted = new EventEmitter<void>();
  @Output() filterSaved = new EventEmitter<BooleanFilter>();
  activeStatuses: ReviewEventType[] = [];
  expanded = true;
  statusIs = true;
  statuses: { type: ReviewEventType; value: boolean; label: string }[] = [];

  constructor(private translocoService: TranslocoService) {}

  ngOnInit() {
    this.getSettingsFromFilters();
    this.initStatuses();
  }

  setStatusIs() {
    this.statusIs = true;
  }

  setStatusIsNot() {
    this.statusIs = false;
  }

  changeStatus(statusType: ReviewEventType) {
    this.statuses = this.statuses.map((status) => {
      if (statusType === status.type) {
        status.value = !status.value;
      }
      return status;
    });
  }

  deleteFilter() {
    this.filterDeleted.emit();
  }

  applyFilter() {
    const selectedStatuses = this.statuses.filter((status) => status.value);
    const request = this.getRequest(selectedStatuses.map((status) => status.type));
    const message = this.getMessage(selectedStatuses.map((status) => status.label));
    this.filterSaved.emit({ request: request, message: message, type: 'REVIEW_STATUS' });
  }

  private getRequest(events: ReviewEventType[]): BooleanQueryRequest {
    const reviewRequests: ReviewStatusRequest[] = events.flatMap((event) => {
      const request: ReviewStatusRequest = { type: BooleanQueryType.REVIEW_STATUS, status: event };
      if (event === ReviewEventType.SUBMITTED_FOR_REVIEW) {
        const reOpenedRequest = { type: BooleanQueryType.REVIEW_STATUS, status: ReviewEventType.REOPENED };
        return [request, reOpenedRequest] as ReviewStatusRequest[];
      } else {
        return request;
      }
    });
    if (this.statusIs) {
      return { type: BooleanQueryType.OR, args: reviewRequests } as BooleanQueryRequest;
    } else {
      return { type: BooleanQueryType.NOT, arg: { type: BooleanQueryType.OR, args: reviewRequests } } as BooleanQueryRequest;
    }
  }

  private getMessage(labels: string[]): string {
    if (this.statusIs) {
      return (
        this.translocoService.translate('translations.reviews.filter.status') +
        ' ' +
        this.translocoService.translate('translations.reviews.filter.is') +
        ' ' +
        labels.join(' ' + this.translocoService.translate('translations.reviews.filter.or') + ' ')
      );
    } else {
      return (
        this.translocoService.translate('translations.reviews.filter.status') +
        ' ' +
        this.translocoService.translate('translations.reviews.filter.isNot') +
        ' ' +
        labels.join(' ' + this.translocoService.translate('translations.reviews.filter.andNot') + ' ')
      );
    }
  }

  private getSettingsFromFilters() {
    if (this.selectedFilters) {
      const statusFilter = this.selectedFilters.find((f) => f.type === 'REVIEW_STATUS');
      if (statusFilter) {
        const booleanRequest = statusFilter.request as BooleanQueryRequest;
        if (booleanRequest.type === BooleanQueryType.NOT) {
          this.statusIs = false;
          this.activeStatuses = ((booleanRequest as NotRequest).arg as OrRequest).args.map((r) => (r as ReviewStatusRequest).status);
        } else {
          this.statusIs = true;
          this.activeStatuses = (booleanRequest as OrRequest).args.map((r) => (r as ReviewStatusRequest).status);
        }
      }
    }
  }

  private initStatuses() {
    const statuses: { type: ReviewEventType; value: boolean; label: string }[] = [
      {
        type: ReviewEventType.SUBMITTED_FOR_REVIEW,
        value: this.activeStatuses.includes(ReviewEventType.SUBMITTED_FOR_REVIEW),
        label: this.translocoService.translate('translations.reviews.status.dashboard.needsReview'),
      },
      {
        type: ReviewEventType.ACCEPTED,
        value: this.activeStatuses.includes(ReviewEventType.ACCEPTED),
        label: this.translocoService.translate('translations.reviews.status.dashboard.approved'),
      },
      {
        type: ReviewEventType.REJECTED_TO_REVIEW,
        value: this.activeStatuses.includes(ReviewEventType.REJECTED_TO_REVIEW),
        label: this.translocoService.translate('translations.reviews.status.playlist.cannotReview'),
      },
      {
        type: ReviewEventType.CLOSED,
        value: this.activeStatuses.includes(ReviewEventType.CLOSED),
        label: this.translocoService.translate('translations.reviews.status.dashboard.closed'),
      },
    ];
    if (this.submissionMode === 'SINGLE') {
      statuses.push(
        {
          type: ReviewEventType.RESUBMITTED,
          value: this.activeStatuses.includes(ReviewEventType.RESUBMITTED),
          label: this.translocoService.translate('translations.reviews.status.dashboard.resubmitted'),
        },
        {
          type: ReviewEventType.REQUIRES_RESUBMISSION,
          value: this.activeStatuses.includes(ReviewEventType.REQUIRES_RESUBMISSION),
          label: this.translocoService.translate('translations.reviews.status.dashboard.changesRequested'),
        },
      );
    }
    this.statuses = statuses;
  }
}
