<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="review-event" [class.reviews-list-style]="reviewsListStyle" [ngSwitch]="type">
  <div class="review-event-info" *ngSwitchCase="ReviewEventType.SUBMITTED_FOR_REVIEW">
    <mat-icon aria-hidden="true" class="mat-icon">forward</mat-icon>
    {{ text }}
  </div>

  <div class="review-event-info" *ngSwitchCase="ReviewEventType.ACCEPTED">
    <svg class="svg positive" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.check' | transloco">
      <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
    </svg>
    {{ text }}
  </div>

  <div class="review-event-info" *ngSwitchCase="ReviewEventType.REQUIRES_RESUBMISSION">
    <mat-icon aria-hidden="true" class="mat-icon warn">loop</mat-icon>
    {{ text }}
  </div>

  <div class="review-event-info" *ngSwitchCase="ReviewEventType.RESUBMITTED">
    <mat-icon aria-hidden="true" class="mat-icon highlight">replay</mat-icon>
    {{ text }}
  </div>

  <div class="review-event-info" *ngSwitchCase="ReviewEventType.REJECTED_TO_REVIEW">
    <mat-icon aria-hidden="true" class="mat-icon grey">close</mat-icon>
    {{ text }}
  </div>

  <div class="review-event-info" *ngSwitchCase="ReviewEventType.REMINDED_TO_SUBMIT">
    <mat-icon aria-hidden="true" class="mat-icon highlight">flash_on</mat-icon>
    {{ text }}
  </div>

  <div class="review-event-info" *ngSwitchCase="ReviewEventType.REOPENED">
    <svg class="svg interactive" role="img">
      <use ogSvgUnify="assets/sprite/sprite.svg#refresh"></use>
    </svg>
    {{ text }}
  </div>

  <div class="review-event-info" *ngSwitchCase="ReviewEventType.CLOSED">
    <svg class="svg error" role="img">
      <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
    </svg>
    {{ text }}
  </div>

  <div class="review-event-info" *ngSwitchCase="ReviewEventType.NOTE">
    <svg class="svg interactive" role="img">
      <use ogSvgUnify="assets/sprite/sprite.svg#rate-review"></use>
    </svg>
    {{ text }}
  </div>

  <div class="review-event-info" *ngSwitchCase="ReviewEventType.CANCEL_DECISION">
    <svg class="svg error-light" role="img">
      <use ogSvgUnify="assets/sprite/sprite.svg#revert"></use>
    </svg>
    {{ text }}
  </div>

  <div class="review-event-date">
    <div class="late-submission-indicator" *ngIf="isLateSubmission">
      ({{ 'translations.reviews.status.dateLabel.lateSubmission' | transloco }})
    </div>
    {{ reviewEventDate }}
  </div>
</div>
