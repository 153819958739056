/*
 * Copyright (C) 2021 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ptl-discovery-share-review',
  templateUrl: './share-discovery-review.component.html',
  styleUrls: ['./share-discovery-review.component.scss'],
})
export class ShareDiscoveryReviewComponent {
  @Output() shareFinished = new EventEmitter<void>();

  closeDialog() {
    this.shareFinished.emit();
  }
}
