<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="big-six-chart__content">
  <div class="big-six-chart__content-inner" *ngIf="!showStaticData && piReport">
    <div class="big-six-chart">
      <ul class="big-six-chart-legend">
        <li class="big-six-chart-legend-item" *ngFor="let label of chartDataLabels; let i = index">
          <span *ngIf="isPIReport"><img src="../assets/charts/summary-graph/graph_{{ i }}.png" alt="" width="36" height="36" /></span>
          <span
            [style.border-bottom-color]="colors[i]"
            tabindex="0"
            [attr.aria-label]="
              label + ', ' + ('translations.global.ariaLabels.progress' | transloco: { chartScores: ': ' + chartScores[i] })
            "
            >{{ label }}</span
          >
        </li>
      </ul>

      <div class="big-six-chart-canvas">
        <canvas baseChart [data]="chartDatasets" [options]="chartOptions" [type]="'polarArea'"> </canvas>
        <div class="user-image" *ngIf="(userDetailsData$ | async)?.imageUrl">
          <img [src]="(userDetailsData$ | async)?.imageUrl" alt="{{ (userDetailsData$ | async)?.firstName }}" />
        </div>
        <div class="user-no-image" *ngIf="!(userDetailsData$ | async)?.imageUrl">
          {{ (userDetailsData$ | async)?.firstName[0] }}
        </div>
      </div>
    </div>

    <div class="big-six-chart__descriptions">
      <div class="big-six-chart__item" *ngFor="let trait of chartData?.traitGroupScores; let i = index">
        <div class="big-six-chart__item-circle-box">
          <div class="big-six-chart__item-circle" [style.transform]="'rotate(' + (chartScores[i] * 180) / 100 + 'deg)'">
            <span class="fill" [style.background-color]="colors[i]"></span>
            <span class="transparent" [style.background-color]="transparentColors[i]"></span>
          </div>
        </div>
        <div class="big-six-chart__item-info">
          <h2 class="big-six-chart__item-name" tabindex="0">{{ trait.name | upperFirst }}</h2>
          <ptl-resource-preview [previewContent]="trait.description"></ptl-resource-preview>
        </div>
      </div>
    </div>
  </div>

  <div class="big-six-chart__static-content" *ngIf="showStaticData">
    <img src="../assets/charts/big-six.png" alt="big six chart" />
  </div>
</div>
