/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Moment } from '../../models';
import { ExternalContentSummary, ImageUploadContent, MediaUploadData, MediumEditorData } from '../../../../shared/models';
import { DEFAULT_URL_VALIDATION_PATTERN } from '../../../../shared/constants/constants';

const MOMENT_INTRO_MAX_LENGTH = 500;
const TITLE_MAX_LENGTH = 40;

export class MomentCommonHelpers {
  /** Extract image url and text from a Moment. */
  static extractMomentImageAndText(moment: Moment): { url: string; external: boolean; textOnly: boolean; text: string } {
    // filter paragraphs content only and stringify
    const mainTextContent = moment.content
      .filter((momentContent) => momentContent.type === 'PARAGRAPH')
      .map((momentParagraph) => (momentParagraph as MediumEditorData).content)
      .toString();

    let trimmedContent = this.textExtractedFrom(mainTextContent);

    // if content longer then MOMENT_INTRO_MAX_LENGTH, trim it and add '...' at the end
    if (trimmedContent.length > MOMENT_INTRO_MAX_LENGTH) {
      trimmedContent = trimmedContent.substr(0, trimmedContent.lastIndexOf(' ', MOMENT_INTRO_MAX_LENGTH)) + ' ...';
    }

    const mainImageContent = moment.content.find(
      (momentContent) => !!(momentContent as MediaUploadData).main || momentContent.type === 'EXTERNAL_CONTENT_SUMMARY',
    );

    if (mainImageContent) {
      return mainImageContent.type === 'EXTERNAL_CONTENT_SUMMARY'
        ? {
            url: (mainImageContent as ExternalContentSummary).thumbnailUrl,
            external: true,
            textOnly: false,
            text: trimmedContent,
          }
        : {
            url: ((mainImageContent as MediaUploadData).content as ImageUploadContent).original,
            external: false,
            textOnly: false,
            text: trimmedContent,
          };
    }

    return {
      url: undefined,
      external: false,
      textOnly: true,
      text: trimmedContent,
    };
  }

  /** Parse html content and return a text string. */
  static textExtractedFrom(html: string): string {
    return (
      html
        .replace(/<\/p><p>/g, ' ') // replace new line with a space
        .replace(/<br ?\/?>/g, ' ') // replace Shift-Enter with a space
        .replace(/<[^>]+>/g, '') // remove html tags
        // remove non-breaking space which is added when there are multiple spaces and at the end of a line
        .replace(/&nbsp;/g, '')
    );
  }

  static getTitleFromMomentContent(moment: Moment) {
    let title = moment.title;
    for (const contentItem of moment.content) {
      if (contentItem.type === 'PARAGRAPH') {
        const htmlContent = (contentItem as MediumEditorData).content;
        const trimmedString = this.textExtractedFrom(htmlContent).trim();
        if (!this.isUrl(trimmedString) && trimmedString.length > TITLE_MAX_LENGTH) {
          title = trimmedString.substr(0, trimmedString.lastIndexOf(' ', TITLE_MAX_LENGTH));
          break;
        }
        if (!this.isUrl(trimmedString) && this.checkIfStringIsNotEmpty(trimmedString)) {
          title = trimmedString;
          break;
        }
      }
      if (contentItem.type === 'EXTERNAL_CONTENT_SUMMARY' && (contentItem as ExternalContentSummary).title) {
        const externalContentSummaryTitle = (contentItem as ExternalContentSummary).title;
        if (this.checkIfStringIsNotEmpty(externalContentSummaryTitle)) {
          title = (contentItem as ExternalContentSummary).title;
          break;
        }
      }
    }
    return title;
  }
  private static isUrl(str: string) {
    const pattern = new RegExp(DEFAULT_URL_VALIDATION_PATTERN, 'i');
    return !!pattern.test(str);
  }

  private static checkIfStringIsNotEmpty(title: string) {
    return title.match('^(?!\\s*$).+');
  }
}
