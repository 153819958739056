/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

/* eslint-disable max-len */
import { delay } from 'rxjs/operators';
import { EditorContent, MediaType } from '../../../../shared/models';
import { Page } from '../../../../shared/models/page';
import { ObservableResult } from '../../../../shared/store';
import { Moment, MomentRequest } from '../../models';
import { FolioBadges, UploadCertificateRequest } from '../../store/folio.state.model';
import { MomentDataService } from './data.service';
import { ItemWithUploadUrl } from '../../../../shared/models/ItemWithUploadUrl';

export class MockMomentDataService implements MomentDataService {
  private moments: Moment[] = [
    {
      _id: 'moment1',
      title: 'Moment example title',
      tags: [],
      content: [
        {
          type: 'MEDIA_UPLOAD',
          uid: 'S6zlyes5CfPSQoQV',
          mediaType: 'IMAGE' as MediaType,
          content: {
            type: 'IMAGE_UPLOAD_CONTENT',
            original:
              'https://s3-eu-west-1.amazonaws.com/resources-yuna-dev/public/open/Test_Company/hwom5QkpX6uIoVm1/university_original_small.png',
            small:
              'https://s3-eu-west-1.amazonaws.com/resources-yuna-dev/public/open/Test_Company/hwom5QkpX6uIoVm1/university_original_small.png',
            medium:
              'https://s3-eu-west-1.amazonaws.com/resources-yuna-dev/public/open/Test_Company/hwom5QkpX6uIoVm1/university_original_small.png',
            large:
              'https://s3-eu-west-1.amazonaws.com/resources-yuna-dev/public/open/Test_Company/hwom5QkpX6uIoVm1/university_original_small.png',
            extraLarge:
              'https://s3-eu-west-1.amazonaws.com/resources-yuna-dev/public/open/Test_Company/hwom5QkpX6uIoVm1/university_original_small.png',
          },
        } as EditorContent,
      ],
      createdAt: new Date(),
      addedToFolio: false,
    },
  ];

  createMoment(request: MomentRequest): ObservableResult<Moment> {
    const moment = {
      _id: `${this.moments.length + 1}`,
      title: 'foo',
      tags: [],
      content: [],
      createdAt: new Date(),
      addedToFolio: false,
    };

    return ObservableResult.ofSuccess({ ...moment }).pipe(delay(1500));
  }

  /**
   * Updates a moment
   *
   * @param momentUid the UID of the moment to update
   * @param request the moment details to use when updating
   */
  updateMoment(momentUid: string, request: MomentRequest): ObservableResult<Moment> {
    return ObservableResult.ofSuccess();
  }

  /**
   * Gets the details for a moment with a given UID.
   *
   * @param momentUid the UID of the moment to retrieve
   */
  getMomentDetails(momentUid: string): ObservableResult<Moment> {
    return ObservableResult.ofSuccess(this.moments[0]);
  }

  /**
   * Deletes a moment with a given UID.
   *
   * @param momentUid the UID of the moment to delete
   */
  deleteMoment(momentUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  /**
   * Restores a moment with a given UID.
   *
   * @param momentUid the UID of the moment to restore
   */
  restoreMoment(momentUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  /**
   * Fetches all moments for the user.
   */
  getMoments(): ObservableResult<Page<Moment>> {
    return ObservableResult.ofSuccess({
      currentPageNumber: 0,
      pageSize: 1,
      totalNumberOfElement: 1,
      content: this.moments,
    }).pipe(delay(2000));
  }

  getBadges(languageCode?: string): ObservableResult<FolioBadges[]> {
    return ObservableResult.ofSuccess([]);
  }

  /**
   * Searches all moments for the user.
   */
  searchMoments(): ObservableResult<Page<Moment>> {
    return ObservableResult.ofSuccess({
      currentPageNumber: 0,
      pageSize: 1,
      totalNumberOfElement: 1,
      content: this.moments,
    }).pipe(delay(2000));
  }

  getArchivedMoments(orderBy: string, searchKey: string, page: number, size: number): ObservableResult<Page<Moment>> {
    return ObservableResult.ofSuccess({
      currentPageNumber: 0,
      pageSize: 1,
      totalNumberOfElement: 1,
      content: this.moments,
    }).pipe(delay(2000));
  }

  uploadExternalCertificateFile(
    request: EditorContent,
    languageCode?: string,
  ): ObservableResult<ItemWithUploadUrl<UploadCertificateRequest>> {
    return ObservableResult.ofSuccess();
  }

  removeCertificate(uid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
