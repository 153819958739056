/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { ChartEditorContent, PIReport, PITraitChartType, PITraitScore, PITraitScoreReport } from '../../../../shared/models/pi/pi.model';
import { PI_DATA_SERVICE, PIDataService } from '../../../../shared/services/pi/pi-data.service';
import { ChartsHelper } from '../../../../shared/helpers/charts-helper';

@Component({
  selector: 'ptl-pi-trait-chart',
  templateUrl: './pi-trait-chart.component.html',
  styleUrls: ['./pi-trait-chart.component.scss'],
})
export class PersonalIndicatorTraitChartComponent implements OnInit {
  @Input() content: ChartEditorContent;

  @Input() showStaticData: boolean;

  piReport: PIReport;
  chartData: PITraitScoreReport;
  activeTab: PITraitChartType = 'CORE';
  activeTabChartData: PITraitScore[] = [];

  constructor(
    @Inject(PI_DATA_SERVICE) private piDataService: PIDataService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (!this.showStaticData && this.content) {
      this.piDataService.getPIReports(this.content.piCardId).subscribe(({ isSuccess, value }) => {
        if (isSuccess && value.reports.length) {
          this.piReport = value.reports[0];
          this.formatData(this.piReport.traitScoreReport);
        }
      });
    }
  }

  showChartData(chartType: PITraitChartType) {
    this.activeTab = chartType;
    if (this.activeTab === 'CORE') {
      this.activeTabChartData = this.chartData.highlyDevelopedTraits;
    } else if (this.activeTab === 'COMFORT') {
      this.activeTabChartData = this.chartData.coreTraits;
    } else if (this.activeTab === 'STRETCH') {
      this.activeTabChartData = this.chartData.lessDevelopedTraits;
    }
  }

  private formatData(data: PITraitScoreReport) {
    this.replaceNames(data.coreTraits);
    this.replaceNames(data.highlyDevelopedTraits);
    this.replaceNames(data.lessDevelopedTraits);
    this.chartData = data;
    this.activeTabChartData = this.chartData.highlyDevelopedTraits;
    this.cd.detectChanges();
  }

  private replaceNames(traits: PITraitScore[]) {
    for (let i = 0; i < traits.length; i++) {
      const trait = traits[i];
      const descriptions = ChartsHelper.getPIChartsDescriptionsByLanguageCode(trait.name, this.content);
      trait.description = descriptions.full;
      trait.shortDescription = descriptions.short;
      if (!trait.shortDescription.length) {
        traits.splice(i, 1);
        i--;
        continue;
      }
      trait.name = trait.name.replace(/_/g, ' ');
    }
  }
}
