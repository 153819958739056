/* eslint-disable max-len */

import { FavoritePlaylist, FavoritePlaylistSummary, Playlist, Resource, SearchPlaylist } from '../shared/models';
import { LearnerPlaylistSummary } from '../shared/models/playlist/learner-playlist-summary.model';
import { DEFAULT_LANGUAGE_CODE } from '../shared/services/languages/language.service';

export function createDummyPlaylist(id: string, title: string = 'My Playlist'): Playlist {
  return {
    _id: id,
    title: title,
    authorDetails: {
      uid: 'author1',
      name: 'Adam Smith',
      organization: 'abc',
      displayConfig: {
        authorName: true,
        organizationName: true,
      },
      orgLogoUrl: 'https://static.yuna.potential.ly/public/images/icons/potentially_logo.png',
      userImageUrl: 'assets/profile_image.png',
    },
    mainSection: {
      cardsLayoutType: 'CardView',
      cards: [],
      content: [],
      imageUrl: {
        type: 'IMAGE_UPLOAD_CONTENT',
        original: 'foo',
        small: 'bar',
        medium: '',
        large: '',
        extraLarge: '',
      },
      title: 'My Playlist',
      layoutType: 'ASIDE_BANNER',
      uid: 'main1',
    },
    standardSections: [
      {
        cardsLayoutType: 'CardView',
        title: 'Suggestion for you',
        uid: 'standard1',
        content: [],
        cards: [
          {
            _id: '1',
            header: 'Firewalk Tutorial',
            image: 'http://50plusachievers.club/media/k2/items/cache/37a06e4a72d6cb27621f1ed829bbee81_XL.jpg',
            thumbnail: 'http://50plusachievers.club/media/k2/items/cache/37a06e4a72d6cb27621f1ed829bbee81_XL.jpg',
            completed: false,
            started: false,
            uri: '1',
            formattedUri: '1',
            status: 'NEW',
            blurb: 'Some testing blurb',
            likes: {
              vote: true,
              totalLikes: 11,
            },
            authorDetails: {
              uid: '1234',
              name: 'Author',
              organization: 'Organization',
              displayConfig: {
                authorName: false,
                organizationName: true,
                externalAuthor: {
                  image: '',
                  name: 'Sam',
                },
              },
              orgLogoUrl: '',
              userImageUrl: '',
            },
            tags: [],
            completedItemsCount: 0,
            totalItemsCount: 0,
            subHeader: [],
          },
        ],
      },
    ],
    status: 'PUBLISHED',
    permission: {
      canEdit: false,
      canPublish: true,
      canDoMemberExports: true,
      canViewAnalytics: true,
    },
    completedCardsCount: 0,
    totalCardsCount: 0,
    adminRights: [],
    uri: 'uri',
    formattedUri: 'uri',
    tags: [
      {
        _id: '0011',
        title: 'tag01',
      },
    ],
    categories: [],
    type: 'STANDARD',
    completed: true,
    completedOn: '2020-03-04T15:34:27.291',
    draft: null,
    likes: {
      vote: true,
      totalLikes: 17,
    },
    embedded: false,
    favorite: false,
    subHeader: null,
    lastModifiedDate: '2020-03-04T15:34:27.291',
    lastUpdatedOn: '2020-03-04T15:34:27.291',
    dataForms: [],
    userPlaylistSubmissionSummary: null,
  };
}

export function createDummyResource(id: string, title: string = 'My Resource'): Resource {
  return {
    _id: `${id}`,
    organization: {
      _id: '1l8ijFYL77QWEEL1',
      name: 'Potential.ly',
    },
    cardType: 'RESOURCE',
    content: {
      headline: `${title}`,
      sections: [
        {
          uid: 'FNhnCmn4hrkiIaLO',
          dynamicContent: [
            {
              uid: '1QnUZpUufP83PDNc',
              content:
                '<p><a href="https://outlook.office365.com/owa/calendar/HenleyCareersEmployerRelations@livereadingac.onmicrosoft.com/bookings/" target="_blank">https://outlook.office365.com/owa/calendar/HenleyCareersEmployerRelations@livereadingac.onmicrosoft.com/bookings/</a></p>',
              type: 'PARAGRAPH',
            },
          ],
        },
      ],
    },
    thumbnail: null,
    tags: [],
    settings: {
      publication: null,
    },
    adminRights: [],
    metadata: {
      createdOn: new Date(),
      lastUpdatedOn: new Date(),
    },
    formattedUri: '',
    syndication: {
      publisher: {
        organization: {
          _id: '1l8ijFYL77QWEEL1',
          name: 'Potential.ly',
        },
        publisherProfileUid: null,
      },
      license: {
        masterId: 'RyQetHtSu7aqfXet',
        copyOf: 'Q6cQeaETffFO1NXg',
        permission: 'SYNDICATE_ONLY',
        isSensitive: false,
        termsAccepted: true,
      },
      status: 'INACTIVE',
      activatedOn: null,
      deactivatedOn: null,
    },
    syndicated: false,
    status: 'PUBLISHED',
    approvalMethod: {
      type: 'AUTOMATIC',
      playlistUid: null,
      minReviewsRequired: 1,
      reviewers: [],
    },
    likes: {
      vote: false,
      totalLikes: 0,
    },
    subHeader: null,
    permission: {
      canEdit: true,
      canPublish: true,
      canEditGoal: true,
      canEditBadge: true,
      canManageTargeting: true,
      canViewAnalytics: true,
      canDoMemberExports: true,
      canClone: true,
    },
    parentPlaylistUri: 'surens-playlist',
    lastUpdatedOn: '2020-03-04T15:34:27.291',
    childOfInstitutionalLibrary: false,
    availableLanguages: [
      {
        code: DEFAULT_LANGUAGE_CODE,
        enabled: true,
      },
    ],
  };
}

export function createDummyLearnerPlaylist(id: string): LearnerPlaylistSummary {
  return {
    ...createDummyPlaylist(id),
    playlistId: id,
    playlistLastUpdatedOn: new Date(),
    targetGroups: ['one', 'two'],
    status: {
      type: 'PUBLISHED',
      by: 'Admin',
      on: new Date(),
    },
    syndicationStatus: 'INACTIVE',
  };
}

export function createDummyFavoritePlaylist(id: string): FavoritePlaylist {
  return {
    image:
      'https://s3-eu-west-1.amazonaws.com/resources-yuna-dev/public/open/Test_Company/playlists/93s0HWIJYZNWX6yy/asking_smart_questions_original.jpg',
    playlistId: id,
    title: 'ASKING SMART QUESTIONS',
    organizationName: 'Super organization',
    favorite: true,
    completed: false,
    uri: 'uri',
  };
}

export function createDummySearchPlaylist(id: string): SearchPlaylist {
  return {
    title: 'ASKING SMART QUESTIONS',
    uri: 'uri',
    description: '',
    subHeader: 'subheader',
    id: id,
    status: 'PUBLISHED',
    syndicated: true,
    hasAdminRights: true,
    thumbnail:
      'https://s3-eu-west-1.amazonaws.com/resources-yuna-dev/public/open/Test_Company/playlists/93s0HWIJYZNWX6yy/asking_smart_questions_original.jpg',
  };
}

export function createDummyFavoritePlaylistSummary(): FavoritePlaylistSummary {
  return {
    title: 'ASKING SMART QUESTIONS',
    uri: 'uri',
    formattedUri: 'uri',
  };
}
