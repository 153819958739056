<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="link-add-dialog-container">
  <div class="dialog-top-info">
    <h1 class="dialog-title">{{ 'translations.dialog.title.addLink' | transloco }}</h1>
    <button
      class="close-dialog-btn"
      mat-icon-button
      color="primary"
      (click)="closeDialog()"
      [attr.aria-label]="'translations.global.ariaLabels.closeDialog'"
    >
      <svg class="svg" role="img">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>

  <div class="link-add-container">
    <div class="error-messages">
      <mat-error *ngIf="urlIsInvalid && triedOnce">
        {{ 'translations.mediaContent.message.error.invalidUrl' | transloco }}
      </mat-error>
      <mat-error *ngIf="canNotGetSummary">
        {{ 'translations.mediaContent.message.error.unavailableExternalContentSummary' | transloco }}
      </mat-error>
    </div>
    <form class="link-input-form-box" [formGroup]="mediaUrlData">
      <mat-form-field class="link-input-form-field" appearance="outline">
        <input
          #urlInputField
          matInput
          type="url"
          formControlName="contentUrl"
          placeholder="{{ 'translations.mediaContent.label.uploadImagePlaceholder' | transloco }}"
          (keydown.enter)="onLinkEnter($event)"
          (paste)="detectChanges()"
          (input)="detectChanges()"
          required
        />
      </mat-form-field>
    </form>
  </div>

  <div class="dialog-footer-buttons">
    <button
      mat-flat-button
      type="button"
      class="cancel-button"
      color="accent"
      title="{{ 'translations.global.button.cancel' | transloco }}"
      [attr.aria-label]="'translations.global.button.cancel' | transloco"
      (click)="closeDialog()"
    >
      {{ 'translations.global.button.cancel' | transloco }}
    </button>

    <button
      mat-flat-button
      color="primary"
      disabled="true"
      [disabled]="triedOnce && (urlIsInvalid || fetchingExternalContentSummary)"
      type="submit"
      class="form-button-save small btn-with-loader link-add-actions--add-link"
      title="{{ 'translations.global.button.addLink' | transloco }}"
      [attr.aria-label]="'translations.global.ariaLabels.addTextBoxForm' | transloco"
      (click)="onLinkEnter($event)"
    >
      {{ 'translations.global.button.addLink' | transloco }}
    </button>
  </div>
</div>
