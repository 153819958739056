/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FolioShareSocialItem } from '../../../page-modules/folio/store/folio.state.model';
import { ShareService } from '@app/app/shared/services/share/share.service';
import { SocialShareType } from '@app/app/shared/models/public-share/public-share.model';

@Component({
  templateUrl: './share-badge-url.component.html',
  styleUrls: ['./share-badge-url.component.scss'],
})
export class ShareBadgeUrlComponent implements OnInit {
  shareUrl: string;
  socialShareType = SocialShareType;
  socialShareItems: FolioShareSocialItem[] = [
    {
      name: SocialShareType.FACEBOOK,
      title: 'Facebook',
    },
    {
      name: SocialShareType.LINKEDIN,
      title: 'LinkedIn',
    },
    {
      name: SocialShareType.TWITTER,
      title: 'Twitter',
    },
  ];
  embedCodeVisible: boolean;
  contentCode: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      shareUrl: string;
    },
    private dialogRef: MatDialogRef<ShareBadgeUrlComponent>,
    private shareService: ShareService,
  ) {}

  ngOnInit(): void {
    this.shareUrl = this.data.shareUrl;
    this.contentCode = this.generateEmbedCode(this.shareUrl);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  shareAction(name: SocialShareType): void {
    this.shareService.shareAction(name, this.shareUrl);
  }

  copyAction(): void {
    this.shareService.shareAction('copy', this.contentCode);
  }

  private generateEmbedCode(url: string): string {
    return (
      '<iframe src="' +
      url +
      '" ' +
      'style="max-width: 100%" ' +
      'width="400" ' +
      'height="150" ' +
      'scrolling="no" ' +
      'allowtransparency="true" ' +
      'frameborder="0" ' +
      'allowfullscreen ' +
      'sandbox="allow-scripts allow-same-origin"> </iframe>'
    );
  }
}
