/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { CreateLanguage } from '../../../../shared/models/languages/languages.model';

export class LoadLanguages {
  static readonly type = '[Languages] Load languages';
}

export class LoadOrganizationLanguages {
  static readonly type = '[Languages] Load organization languages';

  constructor(public organizationId: string) {}
}

export class LoadOrganizationActiveLanguages {
  static readonly type = '[Languages] Load organization active languages';

  constructor(public organizationId: string) {}
}

export class CreateOrganizationLanguages {
  static readonly type = '[Languages] Create organization language';

  constructor(
    public organizationId: string,
    public code: CreateLanguage,
  ) {}
}

export class RemoveOrganizationLanguages {
  static readonly type = '[Languages] Remove organization language';

  constructor(
    public organizationId: string,
    public code: string,
  ) {}
}

export class ActivateOrganizationLanguages {
  static readonly type = '[Languages] Activate organization language';

  constructor(
    public organizationId: string,
    public code: string,
    public isActive: boolean,
  ) {}
}

export class SetDefaultOrganizationLanguages {
  static readonly type = '[Languages] Set default organization language';

  constructor(
    public organizationId: string,
    public code: string,
  ) {}
}
