/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../store/user-auth.state';
import { Observable } from 'rxjs';
import { Organization } from '../../../../shared/models';

@Component({
  templateUrl: './saml-sp-error.component.html',
  styleUrls: ['./saml-sp-error.component.scss'],
})
export class SamlSpErrorComponent {
  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;
}
