<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<mat-card
  class="upcoming-event__container"
  [class.disabled]="!event?.prerequisitesCompleted"
  (click)="event?.prerequisitesCompleted ? redirectToEvent() : false"
>
  <div class="upcoming-event__image-box">
    <img
      #eventBoxImage
      class="event-box-image"
      alt="Event box image"
      (load)="loadImg()"
      [style.object-fit]="isPortraitImage ? 'contain' : 'fill'"
      [src]="event && event.imageUrl ? event.imageUrl : '/assets/card-placeholders/card-placeholder-' + ((index % 4) + 1) + '.png'"
    />
  </div>
  <div class="upcoming-event__content-box">
    <div class="upcoming-event__content-box-headline-date-location">
      <div class="event-headline medium bold">
        {{ getHeadline() }}
      </div>
      <div *ngIf="dateDescription" class="event-date">
        <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.calendar' | transloco">
          <use ogSvgUnify="assets/sprite/sprite.svg#calendar"></use>
        </svg>
        <span class="small">{{ dateDescription }}</span>
      </div>
      <div *ngIf="locationDescription" class="event-location">
        <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.location' | transloco">
          <use ogSvgUnify="assets/sprite/sprite.svg#location"></use>
        </svg>
        <span class="medium">{{ locationDescription }}</span>
      </div>
    </div>

    <div class="upcoming-event__content-box-organization-logo-tickets-left-participation">
      <div>
        <div class="organization-logo">
          <img class="organization-logo-image" alt="" [src]="event ? event.organizationLogoUrl : ''" />
        </div>
      </div>

      <div
        *ngIf="event && (event.participationStatus === 'NONE' || !event.participationStatus)"
        [ngSwitch]="ticketsLeftLabel"
        class="tickets-left-label-box"
      >
        <span *ngSwitchCase="'ALMOST_FULL'" class="tickets-left-label small almost-full">
          {{ 'translations.upcomingEventsV2.almostFull' | transloco }}
        </span>

        <span *ngSwitchCase="'FULL'" class="tickets-left-label small full">
          {{ 'translations.upcomingEventsV2.full' | transloco }}
        </span>
      </div>

      <div
        *ngIf="
          event &&
          (event.participationStatus === 'WAITLISTED' ||
            event.participationStatus === 'REGISTERED' ||
            event.participationStatus === 'PARTICIPATED')
        "
        [ngSwitch]="event.participationStatus"
        class="participation-status"
      >
        <div *ngSwitchCase="'WAITLISTED'" class="participation-status-content">
          <svg class="svg greyed" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.loading' | transloco">
            <use ogSvgUnify="assets/sprite/sprite.svg#time-loading"></use>
          </svg>
          <span class="small">{{ 'translations.upcomingEventsV2.onTheWaitingList' | transloco }}</span>
        </div>
        <div *ngSwitchCase="'REGISTERED'" class="participation-status-content">
          <svg class="svg positive" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.check' | transloco">
            <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
          </svg>
          <span class="small">{{ 'translations.upcomingEventsV2.imGoing' | transloco }}</span>
        </div>
        <div *ngSwitchCase="'PARTICIPATED'" class="participation-status-content">
          <svg class="svg positive" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.check' | transloco">
            <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
          </svg>
          <span class="small">{{ 'translations.upcomingEventsV2.attended' | transloco }}</span>
        </div>
      </div>
    </div>
  </div>
</mat-card>
