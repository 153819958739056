/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

export interface ImageUrlMapping {
  [url: string]: string;
}

export class PrintPageHelper {
  static splitHtmlInToFragments(
    html: string | HTMLElement,
    maxSize: number,
    computedData = { result: [''] as string[], addAncestorTags: false },
  ): string[] {
    if (!(html instanceof HTMLElement)) {
      const element = document.createElement('div');
      element.id = 'temporary_root';
      element.innerHTML = html;
      html = element;
    }

    for (let i = 0; i < html.childNodes.length; ++i) {
      if (html.childNodes[i] instanceof HTMLOListElement) {
        const list = html.childNodes[i] as HTMLOListElement;
        list.style.listStyle = 'none';
        list.style.counterReset = 'ol-counter';
      } else if (html.childNodes[i].parentElement instanceof HTMLOListElement) {
        const list = html.childNodes[i].parentElement as HTMLOListElement;
        const item = html.childNodes[i] as HTMLLIElement;
        list.style.counterReset = 'ol-counter ' + i;
        item.classList.add('uses-counter');
      }

      if (computedData.addAncestorTags) {
        computedData.result.push(this.extractElementOpeningTags(html.childNodes[i], true));
        computedData.addAncestorTags = false;
        maxSize = 100;
      } else {
        computedData.result[computedData.result.length - 1] += this.extractElementOpeningTags(html.childNodes[i]);
      }

      if (html.childNodes[i] instanceof HTMLElement && !(html.childNodes[i] instanceof HTMLBRElement)) {
        this.splitHtmlInToFragments(html.childNodes[i] as HTMLElement, maxSize, computedData);
      }

      if (this.getHtmlContentSize(computedData.result[computedData.result.length - 1]) >= maxSize) {
        if (!computedData.addAncestorTags) {
          maxSize = 100;
          computedData.addAncestorTags = true;
          computedData.result[computedData.result.length - 1] += this.extractElementClosingTags(html.childNodes[i], true);
        }
      } else {
        computedData.result[computedData.result.length - 1] += this.extractElementClosingTags(html.childNodes[i]);
      }
    }

    return computedData.result;
  }

  static getHtmlContentSize(html: string, width = 950): number {
    let size = 5;

    if (html) {
      const element = document.createElement('div');
      element.className = 'preview-element';
      element.style.position = 'fixed';
      element.style.width = `${width}px`;
      element.style.top = '0';
      element.style.left = '0';
      element.innerHTML = html;
      document.body.appendChild(element);
      size += element.clientHeight / 13;
      document.body.removeChild(element);
    }

    return size;
  }

  static fetchImageDataUrls(fetchPromises: Promise<Response>[]): Promise<ImageUrlMapping> {
    return new Promise<ImageUrlMapping>((mappingResolve) => {
      /* eslint-disable */
      Promise.all(fetchPromises.map((p) => p.catch(() => null))).then((responses) => {
        const blobPromises = responses
          .filter((response) => response?.status === 200)
          .map((response) => {
            return {
              blob: response.blob(),
              url: response.url,
            };
          });

        Promise.all(
          blobPromises.map((blob) =>
            blob.blob.then((res) => {
              return {
                blob: res,
                url: blob.url,
              };
            }),
          ),
        ).then((blobs) => {
          const dataUrlPromises: Promise<{ result: any; url: string }>[] = blobs.map((blob) => {
            return new Promise((resolve) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob.blob);
              reader.onload = () => resolve({ result: reader.result, url: blob.url });
            });
          });

          Promise.all(dataUrlPromises).then((dataUrls) => {
            const imageUrlMapping: ImageUrlMapping = {};

            for (const dataUrl of dataUrls) {
              imageUrlMapping[dataUrl.url] = dataUrl.result;
            }

            mappingResolve(imageUrlMapping);
          });
        });
      });
      /* eslint-enable */
    });
  }

  static addPages(pages: NodeListOf<Element>): Promise<jsPDF> {
    return new Promise<jsPDF>((pdfResolve, pdfReject) => {
      const promises: Promise<{ canvas: HTMLCanvasElement; page: HTMLElement }>[] = [];

      pages.forEach((page) => {
        const promise: Promise<{ canvas: HTMLCanvasElement; page: HTMLElement }> = new Promise((resolve, reject) => {
          html2canvas(page as HTMLElement, {
            scrollX: 0,
            scrollY: 0,
            windowWidth: 2000,
            windowHeight: 3000,
            removeContainer: true,
            scale: 1,
          })
            .then((canvas) => {
              resolve({ canvas, page: page as HTMLElement });
            })
            .catch((error) => reject(error));
        });
        promises.push(promise);
      });

      Promise.all(promises)
        .then((canvases) => {
          const doc = new jsPDF();

          for (let i = 0; i < canvases.length; ++i) {
            const canvas = canvases[i].canvas;
            const links = canvases[i].page.getElementsByTagName('a');
            doc.addImage({
              imageData: canvas.toDataURL('image/jpeg'),
              x: 10,
              y: 10,
              width: canvas.width / 6,
              height: canvas.height / 6,
            });
            for (let index = 0; index < links.length; ++index) {
              const pageRect = canvases[i].page.getBoundingClientRect();
              const linkRect = links[index].getBoundingClientRect();
              const x = (linkRect.x - pageRect.x + 175) / 6 - 1;
              const y = (linkRect.y - pageRect.y + 70) / 6 - 1;
              const width = linkRect.width / 6 + 2;
              const height = linkRect.height / 6 + 2;
              const href = links[index].href;

              doc.link(x, y, width, height, { url: href });
            }

            if (i !== canvases.length - 1) {
              doc.addPage();
            }
          }

          pdfResolve(doc);
        })
        .catch((error) => {
          pdfReject(error);
        });
    });
  }

  private static extractElementOpeningTags(html: HTMLElement | Node, ancestors = false): string {
    const ancestorTagsString =
      ancestors &&
      (!(html instanceof HTMLElement) || html.id !== 'temporary_root') &&
      html.parentElement &&
      html.parentElement.id !== 'temporary_root'
        ? this.extractElementOpeningTags(html.parentElement, ancestors)
        : '';

    if (html instanceof HTMLElement) {
      const strings = html.outerHTML.split(/[<>]/gm);
      return ancestorTagsString + '<' + strings[1] + '>';
    } else {
      const span = document.createElement('span');
      span.innerText = html.textContent;
      return ancestorTagsString + span.innerHTML;
    }
  }

  private static extractElementClosingTags(html: HTMLElement | Node, ancestors = false): string {
    const ancestorTagsString =
      ancestors &&
      (!(html instanceof HTMLElement) || html.id !== 'temporary_root') &&
      html.parentElement &&
      html.parentElement.id !== 'temporary_root'
        ? this.extractElementClosingTags(html.parentElement, ancestors)
        : '';

    if (html instanceof HTMLElement) {
      if (html instanceof HTMLBRElement) {
        return ancestorTagsString;
      }

      const tag = html.tagName.toLocaleLowerCase();
      return '</' + tag + '>' + ancestorTagsString;
    } else {
      return ancestorTagsString;
    }
  }
}
