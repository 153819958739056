/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Output,
  OnDestroy,
} from '@angular/core';
import { FileUploadService, RESOURCES_FILE_UPLOAD_DATA_SERVICE } from '../../services/file-upload/file-upload.service';
import { takeUntil } from 'rxjs';
import { MediaUploadData } from '../../models';

@Component({
  selector: 'ptl-progress-bar-upload',
  templateUrl: './progress-bar-upload.component.html',
  styleUrls: ['./progress-bar-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ProgressBarUploadComponent implements OnInit, OnDestroy {
  @Input() content: MediaUploadData;
  @Input() fileName: string;
  @Output() contentUploadProgressEmitter: EventEmitter<number> = new EventEmitter<number>();

  uploadProgress: number;

  private subscriptionEnd$: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    @Inject(RESOURCES_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.content) {
      this.fileName = this.getFileNameFromString(this.content?.fileName) || this.getFileNameFromString(this.content?.realName);
    }
    this.fileUploadService.castUploadProgress.pipe(takeUntil(this.subscriptionEnd$)).subscribe((uploadedFiles) => {
      const currentFile = uploadedFiles.find(
        (uploadedFile) => uploadedFile.uuid4 === (this.content?.uuid4 || this.content?.uid || this.fileName),
      );
      if (currentFile) {
        this.uploadProgress = currentFile.uploadedProgress;
        this.contentUploadProgressEmitter.emit(this.uploadProgress);
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptionEnd$.emit();
    this.subscriptionEnd$.complete();
  }

  private getFileNameFromString(filename: string): string | null {
    if (filename && !filename.startsWith('http')) {
      return filename;
    } else {
      return null;
    }
  }
}
