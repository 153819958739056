/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { EditorContent, EmbedCode, ExternalContentSummary, Form, FormQuestionContent, MediaContent, QuizFormContent } from '../../models';
import { ToolbarButtonFormType, ToolbarButtonType, ToolbarButtonUploadType } from './editor-toolbar-option/editor-toolbar-option.component';
import { SpecialEmbedsHelper } from '../../../shared/helpers/embeds/special-embeds.helper';
import { take } from 'rxjs/operators';
import {
  EmbeddableContentDetails,
  EMBEDS_CONTENT_GENERATOR_SERVICE,
  EmbedsContentGeneratorService,
} from '../../services/embeds-content-generator.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DEFAULT_URL_VALIDATION_PATTERN,
  FILE_UPLOAD_DEFAULT_SIZE,
  FILE_UPLOAD_SUPPORTED_TYPES,
  TEXT_FORM_MAX_WORDS_LIMIT,
  TEXT_FORM_MIN_WORDS_LIMIT,
} from '../../../shared/constants/constants';
import { Router } from '@angular/router';
import { ProjectHelper } from '../../../page-modules/project/helpers/project.helper';
import { TranslocoService } from '@ngneat/transloco';
import { marker } from '@jsverse/transloco-keys-manager/marker';

@Component({
  selector: 'ptl-editor-toolbar',
  templateUrl: './editor-toolbar.component.html',
  styleUrls: ['./editor-toolbar.component.scss'],
})
export class EditorToolbarComponent implements OnInit {
  /** Enables url toolbar button */
  @Input() link = false;
  /** Enables upload toolbar button */
  @Input() upload = false;
  /** Enables upload toolbar button */
  @Input() table = false;
  /** Enables form toolbar button */
  @Input() form = false;
  /** Enables embed code toolbar button */
  @Input() embed = false;
  /** Enables text form toolbar button */
  @Input() textFormOnly = false;
  /** Enables separator toolbar button */
  @Input() separator = false;
  /** Enables quiz toolbar button */
  @Input() quiz = false;
  /** Enables chart toolbar button */
  @Input() chart = false;
  /** Enables insert learnerCollectorSummary toolbar button */
  @Input() collector = false;

  /** Emits data on new content from child components */
  @Output() contentCreated = new EventEmitter<EditorContent>();
  /** Emits data on new regular link insertion from child components */
  @Output() regularLinkCreated = new EventEmitter<string>();
  /** Emits data on toolbar open */
  @Output() toolbarOpened = new EventEmitter<boolean>();

  @Output() linkAdded = new EventEmitter<boolean>();

  toolbarVisible = false;
  linkInputActive: boolean;
  mediaEmbeddableContentLoading: boolean;
  mediaUrlData?: FormGroup;

  selectedOptionButtonType: ToolbarButtonType;
  selectedOptionSubType: ToolbarButtonUploadType | ToolbarButtonFormType;
  tooltipVisible = false;

  tooltipOptions = [
    {
      buttonType: 'LINK',
      image: this.getTooltipImageByType('LINK', null),
      title: marker('translations.editor.toolbarNew.option.link.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.link.tooltipDescription'),
      subType: null,
    },
    {
      buttonType: 'UPLOAD',
      image: this.getTooltipImageByType('UPLOAD', 'IMAGE'),
      title: marker('translations.editor.toolbarNew.option.image.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.image.tooltipDescription'),
      subType: 'IMAGE',
    },
    {
      buttonType: 'UPLOAD',
      image: this.getTooltipImageByType('UPLOAD', 'VIDEO'),
      title: marker('translations.editor.toolbarNew.option.video.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.video.tooltipDescription'),
      subType: 'VIDEO',
    },
    {
      buttonType: 'UPLOAD',
      image: this.getTooltipImageByType('UPLOAD', 'AUDIO'),
      title: marker('translations.editor.toolbarNew.option.audio.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.audio.tooltipDescription'),
      subType: 'AUDIO',
    },
    {
      buttonType: 'UPLOAD',
      image: this.getTooltipImageByType('UPLOAD', 'FILE'),
      title: marker('translations.editor.toolbarNew.option.file.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.file.tooltipDescription'),
      subType: 'FILE',
    },
    {
      buttonType: 'EMBED',
      image: this.getTooltipImageByType('EMBED', null),
      title: marker('translations.editor.toolbarNew.option.embed.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.embed.tooltipDescription'),
      subType: null,
    },
    {
      buttonType: 'CHART',
      image: this.getTooltipImageByType('CHART', null),
      title: marker('translations.editor.toolbarNew.option.chart.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.chart.tooltipDescription'),
      subType: null,
    },
    {
      buttonType: 'SEPARATOR',
      image: this.getTooltipImageByType('SEPARATOR', null),
      title: marker('translations.editor.toolbarNew.option.separator.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.separator.tooltipDescription'),
      subType: null,
    },
    {
      buttonType: 'FORM',
      image: this.getTooltipImageByType('FORM', 'TEXTBOX'),
      title: marker('translations.editor.toolbarNew.option.textForm.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.textForm.tooltipDescription'),
      subType: 'TEXTBOX',
    },
    {
      buttonType: 'FORM',
      image: this.getTooltipImageByType('FORM', 'CONNECTED_TEXTBOX'),
      title: marker('translations.editor.toolbarNew.option.connectedTextForm.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.connectedTextForm.tooltipDescription'),
      subType: 'CONNECTED_TEXTBOX',
    },
    {
      buttonType: 'FORM',
      image: this.getTooltipImageByType('FORM', 'QUESTION_FORM'),
      title: marker('translations.editor.toolbarNew.option.questionForm.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.questionForm.tooltipDescription'),
      subType: 'QUESTION_FORM',
    },
    {
      buttonType: 'FORM',
      image: this.getTooltipImageByType('FORM', 'PRIVATE_NOTE'),
      title: marker('translations.editor.toolbarNew.option.privateNote.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.privateNote.tooltipDescription'),
      subType: 'PRIVATE_NOTE',
    },
    {
      buttonType: 'FORM',
      image: this.getTooltipImageByType('FORM', 'CHECKBOX'),
      title: marker('translations.editor.toolbarNew.option.checkboxForm.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.checkboxForm.tooltipDescription'),
      subType: 'CHECKBOX',
    },
    {
      buttonType: 'FORM',
      image: this.getTooltipImageByType('FORM', 'USER_FILE_UPLOAD'),
      title: marker('translations.editor.toolbarNew.option.fileForm.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.fileForm.tooltipDescription'),
      subType: 'USER_FILE_UPLOAD',
    },
    {
      buttonType: 'FORM',
      image: this.getTooltipImageByType('FORM', 'BOOKING'),
      title: marker('translations.editor.toolbarNew.option.bookingForm.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.bookingForm.tooltipDescription'),
      subType: 'BOOKING',
    },
    {
      buttonType: 'FORM',
      image: this.getTooltipImageByType('FORM', 'QUIZ'),
      title: marker('translations.editor.toolbarNew.option.quizForm.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.quizForm.tooltipDescription'),
      subType: 'QUIZ',
    },
    {
      buttonType: 'FORM',
      image: this.getTooltipImageByType('FORM', 'COLLECTOR'),
      title: marker('translations.editor.toolbarNew.option.collectorForm.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.collectorForm.tooltipDescription'),
      subType: 'COLLECTOR',
    },
    {
      buttonType: 'TABLE',
      image: this.getTooltipImageByType('TABLE', null),
      title: marker('translations.editor.toolbarNew.option.table.tooltipTitle'),
      description: marker('translations.editor.toolbarNew.option.table.tooltipDescription'),
      subType: 'TABLE',
    },
  ];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private translocoService: TranslocoService,
    @Inject(EMBEDS_CONTENT_GENERATOR_SERVICE) public embedsService: EmbedsContentGeneratorService,
  ) {}

  ngOnInit(): void {
    // TODO Remove after implementation of project submissions
    const isProjectResource = ProjectHelper.isProjectByUrl(this.router);
    if (isProjectResource) {
      this.quiz = false;
      this.chart = false;
      this.collector = false;
      this.form = false;
    }
  }

  toggleToolbar(): void {
    this.toolbarVisible = !this.toolbarVisible;
    this.linkInputActive = false;
    this.linkAdded.emit(false);
    this.toolbarOpened.emit(this.toolbarVisible);

    if (this.toolbarVisible) {
      this.adjustModalPosition();
    }
  }

  onOptionSelected(type: ToolbarButtonType): void {
    switch (type) {
      case 'SEPARATOR':
        this.onAddSeparator();
        break;
      case 'EMBED':
        this.onInsertEmbedCode();
        break;
      case 'TABLE':
        this.onAddTable();
        break;
    }
    this.toggleToolbar();
  }

  onMediaContentAdded(mediaContentData: MediaContent) {
    this.toggleToolbar();
    this.contentCreated.emit(mediaContentData);
  }

  onRegularLinkAdded(link: string) {
    this.toggleToolbar();
    this.regularLinkCreated.emit(link);
  }

  onLinkAdd(): void {
    this.linkInputActive = !this.linkInputActive;
    this.toolbarVisible = false;
    this.tooltipVisible = false;
    this.linkAdded.emit(true);
    this.mediaUrlData = this.fb.group({
      contentUrl: ['', [Validators.required, Validators.pattern(DEFAULT_URL_VALIDATION_PATTERN)]],
    });
  }

  addEmptyForm() {
    this.toggleToolbar();
    this.contentCreated.emit({
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'TEXTBOX',
        title: this.translocoService.translate('translations.editor.toolbarNew.option.defaultFormTitle'),
        wordLimit: TEXT_FORM_MAX_WORDS_LIMIT,
        minWordsLimit: TEXT_FORM_MIN_WORDS_LIMIT,
        answerType: 'RICH',
      },
    } as EditorContent);
  }

  addEmptyConnectedTextBoxForm() {
    this.toggleToolbar();
    this.contentCreated.emit({
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'CONNECTED_TEXTBOX',
        answerType: 'RICH',
        title: this.translocoService.translate('translations.editor.toolbarNew.option.defaultFormTitle'),
        wordLimit: TEXT_FORM_MAX_WORDS_LIMIT,
        connection: {
          playlistUid: '',
          cardUid: '',
          formUid: '',
        },
      },
    } as EditorContent);
  }

  addEmptyQuestionForm() {
    this.toggleToolbar();
    this.contentCreated.emit({
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'QUESTION_FORM',
        answerType: 'RICH',
        title: this.translocoService.translate('translations.editor.toolbarNew.option.defaultFormTitle'),
        wordLimit: TEXT_FORM_MAX_WORDS_LIMIT,
      },
    } as EditorContent);
  }

  addEmptyPrivateForm() {
    this.toggleToolbar();
    this.contentCreated.emit({
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'PRIVATE_NOTE',
        title: this.translocoService.translate('translations.editor.toolbarNew.option.defaultFormTitle'),
        wordLimit: 0,
        answerType: 'RICH',
      },
    } as EditorContent);
  }

  addUploadForm() {
    this.toggleToolbar();
    this.contentCreated.emit({
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'USER_FILE_UPLOAD',
        title: this.translocoService.translate('translations.editor.toolbarNew.option.defaultFormTitle'),
        maxFileSizeMb: FILE_UPLOAD_DEFAULT_SIZE,
        supportedFileTypes: FILE_UPLOAD_SUPPORTED_TYPES,
      },
    } as EditorContent);
  }

  addCheckboxForm() {
    this.toggleToolbar();
    this.contentCreated.emit({
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'CHECKBOX',
        title: this.translocoService.translate('translations.editor.toolbarNew.option.defaultFormTitle'),
      },
    } as EditorContent);
  }

  onAddSeparator() {
    const separator = '<div class="separator-container"><hr class="separator"/></div>';
    this.toggleToolbar();
    this.contentCreated.emit({ type: 'SEPARATOR', content: separator } as EditorContent);
  }

  onAddQuiz() {
    this.toggleToolbar();
    const quizContent = {
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'QUIZ',
        title: this.translocoService.translate('translations.editor.toolbarNew.option.quizForm.defaultTitle'),
      },
    } as Form<QuizFormContent>;
    this.contentCreated.emit(quizContent);
  }

  addQuestionForm() {
    this.toggleToolbar();
    const questionFormContent = {
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'QUESTION_FORM',
        uid: null,
        optionsListUid: null,
        title: this.translocoService.translate('translations.editor.toolbarNew.option.defaultFormTitle'),
        presentationMode: 'RADIO',
        optionOrder: 'DOCUMENT',
        maxSelection: 1,
      },
    } as Form<FormQuestionContent>;
    this.contentCreated.emit(questionFormContent);
  }

  onAddChart() {
    this.toggleToolbar();
    this.contentCreated.emit({ type: 'CHART' } as EditorContent);
  }

  onInsertCollector() {
    this.toggleToolbar();
    this.contentCreated.emit({
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'COLLECTOR',
        title: this.translocoService.translate('translations.editor.toolbarNew.option.collectorForm.defaultTitle'),
      },
    } as EditorContent);
  }

  onInsertEmbedCode() {
    this.toggleToolbar();
    this.contentCreated.emit({ type: 'EMBED_CODE', code: '' } as EmbedCode);
  }

  onAddTable() {
    this.toggleToolbar();
    this.contentCreated.emit({ type: 'TABLE', code: '' } as EditorContent);
  }

  setToolbarHidden(): void {
    this.toolbarVisible = false;
    this.linkInputActive = false;
    this.linkAdded.emit(false);
    this.toolbarOpened.emit(this.toolbarVisible);
  }

  onLinkEnter() {
    if (this.mediaUrlData?.valid) {
      this.mediaEmbeddableContentLoading = true;
      const url = SpecialEmbedsHelper.correctUrl(`${this.mediaUrlData.get('contentUrl')?.value}`);

      this.embedsService
        .retrieveEmbeddableContentDetails(encodeURI(url))
        .pipe(take(1))
        .subscribe(({ value }) => {
          let contentDetails: EmbeddableContentDetails | undefined;

          if (value) {
            contentDetails = value;
          } else {
            contentDetails = SpecialEmbedsHelper.generateEmbedFromUrl(url);
          }

          if (contentDetails) {
            this.onMediaContentAdded({
              type: 'EXTERNAL_CONTENT_SUMMARY',
              url: contentDetails.url,
              authorName: contentDetails.authorName,
              logoUrl: contentDetails.logoUrl,
              title: contentDetails.title,
              description: contentDetails.description,
              publicationDate: contentDetails.publicationDate,
              thumbnailUrl: contentDetails.thumbnailUrl,
              siteName: contentDetails.siteName,
              html: contentDetails.html,
            } as ExternalContentSummary);
          } else {
            this.onRegularLinkAdded(url);
          }
        });
    }
  }

  addBookingForm() {
    this.toggleToolbar();
    this.contentCreated.emit({
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'BOOKING',
        title: this.translocoService.translate('translations.editor.toolbarNew.option.defaultFormTitle'),
        bookableAppointmentId: null,
        submitted: false,
        formIsActive: true,
      },
    } as EditorContent);
  }

  showTooltip(buttonType: ToolbarButtonType, uploadType: ToolbarButtonUploadType | ToolbarButtonFormType) {
    if (!this.tooltipVisible) {
      this.selectedOptionButtonType = buttonType;
      this.selectedOptionSubType = uploadType;
      this.tooltipVisible = true;
    }
  }

  hideTooltip() {
    this.tooltipVisible = false;
  }

  private adjustModalPosition(): void {
    const toolbarHeight = 350;
    const toolbarButton = document.getElementById('toolbarButton');
    const windowHeight = window.innerHeight;
    const modalBottomPosition = toolbarButton.getBoundingClientRect().bottom;

    if (modalBottomPosition + toolbarHeight > windowHeight) {
      toolbarButton.closest('.f_editor-toolbar-container').classList.add('reverse');
    } else {
      toolbarButton.closest('.f_editor-toolbar-container').classList.remove('reverse');
    }
  }

  private getTooltipImageByType(type: ToolbarButtonType, uploadType: ToolbarButtonUploadType | ToolbarButtonFormType): string {
    if (type === 'UPLOAD') {
      return this.getFileUploadImage(uploadType as ToolbarButtonUploadType);
    }
    if (type === 'FORM') {
      return this.getFormTypeImage(uploadType as ToolbarButtonFormType);
    }
    if (type === 'LINK') {
      return '/assets/toolbar/link.png';
    }
    if (type === 'EMBED') {
      return '/assets/toolbar/code.png';
    }
    if (type === 'SEPARATOR') {
      return '/assets/toolbar/divider.png';
    }
    if (type === 'CHART') {
      return '/assets/toolbar/chart.png';
    }
    if (type === 'TABLE') {
      return '/assets/toolbar/table.png';
    }
    return '';
  }

  private getFileUploadImage(uploadType: ToolbarButtonUploadType): string {
    if (uploadType === 'IMAGE') {
      return '/assets/toolbar/image.png';
    }
    if (uploadType === 'FILE') {
      return '/assets/toolbar/file.png';
    }
    if (uploadType === 'AUDIO') {
      return '/assets/toolbar/audio.png';
    }
    if (uploadType === 'VIDEO') {
      return '/assets/toolbar/video.png';
    }
    return '';
  }

  private getFormTypeImage(formType: ToolbarButtonFormType): string {
    if (formType === 'TEXTBOX' || formType === 'CONNECTED_TEXTBOX' || formType === 'QUESTION_FORM') {
      return '/assets/toolbar/text-box.png';
    }
    if (formType === 'PRIVATE_NOTE') {
      return '/assets/toolbar/text-box.png';
    }
    if (formType === 'CHECKBOX') {
      return '/assets/toolbar/check-box.png';
    }
    if (formType === 'USER_FILE_UPLOAD') {
      return '/assets/toolbar/file-form.png';
    }
    if (formType === 'COLLECTOR') {
      return '/assets/toolbar/quiz.png';
    }
    if (formType === 'QUIZ') {
      return '/assets/toolbar/quiz.png';
    }
    return '';
  }
}
