/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import { Playlist, Resource } from 'src/app/shared/models';
import { ObservableResult } from 'src/app/shared/store';
import { PlaylistWebLinkCreationRequest } from '../../playlist/models';
import { ProjectCreationRequest } from '../models/project.model';
import { CreatePlaylistResponse } from '../../playlist/services/create/core/models/create-playlist-response';

export const PROJECT_DATA_SERVICE = new InjectionToken<ProjectDataService>('[Project] ProjectDataService');

export interface ProjectDataService {
  createProject(request: ProjectCreationRequest): ObservableResult<CreatePlaylistResponse>;

  getProjectDetails(playlistUri: string, languageCode?: string): ObservableResult<Playlist>;

  createEmptyCard(playlistUid: string, sectionUid: string): ObservableResult<Resource>;

  addWebLinkToPlaylistSection(
    playlistUid: string,
    sectionUid: string,
    requestBody: PlaylistWebLinkCreationRequest,
  ): ObservableResult<Resource>;

  publishAndShowProjectOnFolio(projectUid: string): ObservableResult<void>;

  unPublishAndHideProjectVisibleOnFolio(projectUid: string): ObservableResult<void>;
}
