/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

/* eslint-disable max-len */
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { INITIAL_MOMENT_STATE, MomentEditorState, MomentViewStateModel } from './moment-view-all-state.model';
import { Inject, Injectable, NgZone } from '@angular/core';
import { CORE_MOMENT_DATA_SERVICE, MomentDataService } from '../../../shared-moment-module/services/data.service';
import { dataLoadedState, errorState, LoadableState, loadingState } from '../../../../../shared/store';
import { Moment } from '../../../models';
import {
  AddMomentToFolio,
  DeleteMoment,
  LoadMoments,
  LoadMomentsOnFolio,
  MomentChange,
  RemoveMomentFromFolio,
  SearchMoments,
  TriggerMomentCreation,
  UpdateMoment,
  UpdateMomentsList,
} from './moment-view-all.actions';
import { switchMap, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditorTransformer } from '../../../../../shared/helpers/editor-transformer';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { MomentFileUploadHelpers } from '../../../shared-moment-module/services/moment-view-all-file-upload.helpers';
import { FileUploadService, RESOURCES_FILE_UPLOAD_DATA_SERVICE } from '../../../../../shared/services/file-upload/file-upload.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenMomentForUpdate } from '../moment-update/moment-update.actions';
import { CORE_TAG_DATA_SERVICE, MultiTagCreationRequest, TagDataService } from '../../../../../shared/services/tags/tag-data.service';
import { TagRequest } from '../../../../playlist/models';
import { Page } from '../../../../../shared/models/page';
import { SnackbarHelper } from '../../../../../shared/helpers/snackbar-helper';
import { TranslocoService } from '@ngneat/transloco';
import { RedirectHelper } from '../../../../resource/store/editor/content/helpers/redirect.helper';
import { marker } from '@jsverse/transloco-keys-manager/marker';

/* eslint-enable max-len */

@State<MomentViewStateModel>({
  name: 'momentView',
  defaults: INITIAL_MOMENT_STATE,
})
@Injectable()
export class MomentViewAllState {
  @Selector()
  static moments({ moments }: MomentViewStateModel): LoadableState<Page<Moment>> {
    return moments;
  }

  @Selector()
  static momentEditor({ momentEditorState }: MomentViewStateModel): MomentEditorState {
    return momentEditorState;
  }

  @Selector()
  static momentCreationInProgress({ momentCreationState }: MomentViewStateModel): boolean {
    return momentCreationState.inProgress;
  }

  @Selector()
  static totalMoments({ moments }: MomentViewStateModel): number {
    return (moments.data && moments.data.totalNumberOfElement) || 0;
  }

  constructor(
    @Inject(CORE_MOMENT_DATA_SERVICE) private dataService: MomentDataService,
    @Inject(RESOURCES_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService,
    @Inject(CORE_TAG_DATA_SERVICE) private tagService: TagDataService,
    private translocoService: TranslocoService,
    private snackBar: MatSnackBar,
    private store: Store,
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  @Action(LoadMoments)
  loadMoments({ patchState, getState }: StateContext<MomentViewStateModel>, { page, size }: LoadMoments) {
    const oldMoments = page === 0 ? [] : (getState().moments.data && getState().moments.data.content) || [];

    if (oldMoments.length === 0) {
      patchState({
        moments: loadingState(),
      });

      patchState({
        momentCreationState: {
          inProgress: true,
        },
      });
    }

    return this.dataService.getMoments(page, size).pipe(
      tap(({ isSuccess, value, error }) => {
        if (isSuccess) {
          patchState({
            moments: dataLoadedState({
              ...value,
              content: oldMoments.concat(value.content),
            }),
            momentCreationState: {
              inProgress: false,
            },
          });
        } else {
          patchState({
            moments: errorState(error),
            momentCreationState: {
              inProgress: false,
            },
          });
        }
      }),
    );
  }

  @Action(AddMomentToFolio)
  addMomentToFolio({ patchState, getState }: StateContext<MomentViewStateModel>, { momentUid }: AddMomentToFolio) {
    const momentToAddToFolio = getState().moments.data.content.find(({ _id }) => _id === momentUid);
    return this.dataService
      .updateMoment(momentToAddToFolio._id, {
        title: momentToAddToFolio.title,
        tags: momentToAddToFolio.tags,
        content: momentToAddToFolio.content.map((content) => EditorTransformer.transformEditorContentToMatchApiExpectations(content)),
        addedToFolio: true,
      })
      .pipe(
        tap(({ isSuccess }) => {
          if (isSuccess) {
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone,
              this.snackBar,
              this.translocoService,
              marker('translations.folio.message.success.addedYourFolio'),
            );
            patchState({
              moments: dataLoadedState({
                ...getState().moments.data,
                content: getState().moments.data.content.map((moment) =>
                  moment._id === momentUid ? { ...moment, addedToFolio: true } : moment,
                ),
              }),
            });
          }
        }),
      );
  }

  @Action(RemoveMomentFromFolio)
  removeMomentToFolio({ patchState, getState }: StateContext<MomentViewStateModel>, { momentUid }: RemoveMomentFromFolio) {
    const momentToAddToFolio = getState().moments.data.content.find(({ _id }) => _id === momentUid);
    return this.dataService
      .updateMoment(momentToAddToFolio._id, {
        title: momentToAddToFolio.title,
        tags: momentToAddToFolio.tags,
        content: momentToAddToFolio.content.map((content) => EditorTransformer.transformEditorContentToMatchApiExpectations(content)),
        addedToFolio: false,
      })
      .pipe(
        tap(({ isSuccess }) => {
          if (isSuccess) {
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone,
              this.snackBar,
              this.translocoService,
              'translations.folio.message.success.momentRemovedFromYourFolio',
            );
            patchState({
              moments: dataLoadedState({
                ...getState().moments.data,
                content: getState().moments.data.content.map((moment) =>
                  moment._id === momentUid ? { ...moment, addedToFolio: false } : moment,
                ),
              }),
            });
          }
        }),
      );
  }

  @Action(DeleteMoment)
  deleteMoment({ patchState, getState }: StateContext<MomentViewStateModel>, { momentId }: DeleteMoment) {
    return this.dataService.deleteMoment(momentId).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          patchState({
            moments: dataLoadedState({
              ...getState().moments.data,
              content: getState().moments.data.content.filter(({ _id }) => _id !== momentId),
            }),
          });
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone,
            this.snackBar,
            this.translocoService,
            marker('translations.folio.message.success.yourMomentRemoved'),
          );
        }
      }),
    );
  }

  @Action(UpdateMoment)
  openMomentForUpdate({ getState }: StateContext<MomentViewStateModel>, { id }: UpdateMoment) {
    this.store.dispatch(new OpenMomentForUpdate(id));
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, `folio/moments/${id}/edit`);
  }

  @Action(TriggerMomentCreation)
  triggerMomentCreation({ patchState, getState }: StateContext<MomentViewStateModel>, { shouldAddToFolio }: TriggerMomentCreation) {
    const state = getState();
    const momentEditorState = state.momentEditorState;
    patchState({
      momentCreationState: {
        inProgress: true,
      },
    });
    const { tags, content, title, addedToFolio } = momentEditorState;
    const request = {
      // todo: basically duplicate of logic in AppFrameState
      type: 'STANDARD',
      tags: tags.map((tag) => ({ title: tag.title }) as TagRequest),
    } as MultiTagCreationRequest;
    return this.tagService.createTags(request).pipe(
      switchMap((tagsResult) => {
        if (tagsResult.isSuccess) {
          return this.dataService
            .createMoment({
              title,
              tags: tagsResult.value,
              content: content.map((item) => EditorTransformer.transformEditorContentToMatchApiExpectations(item)),
              addedToFolio,
            })
            .pipe(
              switchMap(({ isSuccess, value }) => {
                if (isSuccess) {
                  return this.uploadMomentMediaFiles(patchState, state, value, shouldAddToFolio);
                } else {
                  return undefined;
                }
              }),
            );
        } else {
          return undefined;
        }
      }),
    );
  }

  private uploadMomentMediaFiles(
    patchState: (val: Partial<MomentViewStateModel>) => Partial<MomentViewStateModel>,
    state: MomentViewStateModel,
    resultMoment: Moment,
    shouldAddToFolio: boolean,
  ) {
    const { uid: userUid, organization } = this.store.selectSnapshot(UserAuthState.userDetailsData);

    return MomentFileUploadHelpers.uploadMomentFiles(
      userUid,
      organization._id,
      state.momentEditorState.content,
      this.fileUploadService,
      resultMoment.content,
      () =>
        patchState({
          ...state,
          momentEditorState: {
            ...INITIAL_MOMENT_STATE.momentEditorState,
          },
          momentCreationState: {
            inProgress: false,
          },
        }),
    ).pipe(
      tap(() => {
        SnackbarHelper.showTranslatableSnackBar(
          this.ngZone,
          this.snackBar,
          this.translocoService,
          marker('translations.folio.message.success.yourMomentCreated'),
        );
        this.store
          .dispatch(new LoadMoments(0, 10))
          .toPromise()
          .then(() => {
            if (shouldAddToFolio) {
              this.store.dispatch(new AddMomentToFolio(resultMoment._id));
            }
          });
      }),
    );
  }

  @Action(MomentChange)
  momentUpdateChange({ patchState, getState }: StateContext<MomentViewStateModel>, { editorState }: MomentChange) {
    const state = getState();
    patchState({
      momentEditorState: {
        ...state.momentEditorState,
        ...editorState,
      },
    });
  }

  @Action(UpdateMomentsList)
  updateCurrentMoment({ patchState, getState }: StateContext<MomentViewStateModel>, { moment }: UpdateMomentsList) {
    patchState({
      moments: dataLoadedState({
        ...getState().moments.data,
        content: getState().moments.data.content.map((item) => (item._id === moment._id ? moment : item)),
      }),
    });
  }

  @Action(SearchMoments)
  searchMoments(
    { patchState, getState }: StateContext<MomentViewStateModel>,
    { momentType, orderBy, searchKey, page, size }: SearchMoments,
  ) {
    const oldMoments = page === 0 ? [] : (getState().moments.data && getState().moments.data.content) || [];

    if (oldMoments.length === 0) {
      patchState({
        moments: loadingState(),
      });
    }

    return this.dataService.searchMoments(momentType, orderBy, searchKey, page, size).pipe(
      tap(({ isSuccess, value, error }) => {
        if (isSuccess) {
          patchState({
            moments: dataLoadedState({
              ...value,
              content: oldMoments.concat(value.content),
            }),
          });
        } else {
          patchState({
            moments: errorState(error),
          });
        }
      }),
    );
  }

  @Action(LoadMomentsOnFolio)
  loadMomentsOnFolio() {
    // fire moments loading only if url on folio page
    const urlMatch = new RegExp(/folio/);
    if (urlMatch.test(this.router.url)) {
      this.store.dispatch(new LoadMoments(0, 10));
    }
  }
}
