<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<main class="class-of__login-main" style="background-image: url('../assets/classof/bg.jpg')">
  <div class="class-of__login-container">
    <div class="class-of__login-header">
      <div class="logo-box">
        <a class="logo-link" href="https://www.classof2020.org.uk/">
          <img src="../assets/classof/logo.png" width="200" height="148" alt="class of 2020 logo" />
        </a>
        <div
          *ngIf="false"
          class="class-of__powered-content"
          [attr.aria-label]="'translations.global.ariaLabels.poweredBy' | transloco: { organizationName: 'Potential.ly' }"
        >
          {{ 'translations.global.label.poweredBy' | transloco }}
          <a
            href="https://potential.ly"
            class="login__powered-content-link"
            [attr.aria-label]="'translations.global.ariaLabels.openPotential.ly' | transloco"
          >
            <img
              class="login__powered-image"
              width="40"
              height="40"
              src="https://static.yuna.potential.ly/public/images/icons/potentially_logo.png"
              alt=""
            />
            {{ 'translations.public.title.logoText' | transloco }}
          </a>
        </div>
      </div>
    </div>

    <div class="maintenance-layer" *ngIf="showMaintenanceOverlay">
      <div class="maintenance-layer-inner" [innerHTML]="'translations.maintenance.overlayText' | transloco"></div>
    </div>

    <ng-content></ng-content>

    <div class="class-of__login-social-container">
      <ul class="social-items">
        <li class="social-item">
          <a
            href="https://twitter.com/classof2020hq"
            class="social-link"
            [attr.aria-label]="'translations.global.ariaLabels.openTwitter' | transloco"
          >
            <svg
              enable-background="new 0 0 430.117 430.117"
              fill="#ffffff"
              viewBox="0 0 430.117 430.117"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                d="m381.384 198.639c24.157-1.993 40.543-12.975 46.849-27.876-8.714 5.353-35.764 11.189-50.703 5.631-.732-3.51-1.55-6.844-2.353-9.854-11.383-41.798-50.357-75.472-91.194-71.404 3.304-1.334 6.655-2.576 9.996-3.691 4.495-1.61 30.868-5.901 26.715-15.21-3.5-8.188-35.722 6.188-41.789 8.067 8.009-3.012 21.254-8.193 22.673-17.396-12.27 1.683-24.315 7.484-33.622 15.919 3.36-3.617 5.909-8.025 6.45-12.769-32.726 20.907-51.843 63.057-67.314 103.944-12.148-11.773-22.915-21.044-32.574-26.192-27.097-14.531-59.496-29.692-110.355-48.572-1.561 16.827 8.322 39.201 36.8 54.08-6.17-.826-17.453 1.017-26.477 3.178 3.675 19.277 15.677 35.159 48.169 42.839-14.849.98-22.523 4.359-29.478 11.642 6.763 13.407 23.266 29.186 52.953 25.947-33.006 14.226-13.458 40.571 13.399 36.642-45.816 47.323-118.05 43.845-159.529 4.264 108.299 147.572 343.716 87.274 378.799-54.866 26.285.224 41.737-9.105 51.318-19.39-15.144 2.57-37.094-.086-48.733-4.933z"
              />
            </svg>
          </a>
        </li>
        <li class="social-item">
          <a
            href="https://facebook.com/classof2020hq"
            class="social-link"
            [attr.aria-label]="'translations.global.ariaLabels.openFacebook' | transloco"
          >
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 155.139 155.139"
              style="enable-background: new 0 0 155.139 155.139"
              xml:space="preserve"
              aria-hidden="true"
            >
              <g>
                <path
                  id="f_1_"
                  style="fill: #ffffff"
                  d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184
                  c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452
                  v20.341H37.29v27.585h23.814v70.761H89.584z"
                />
              </g>
            </svg>
          </a>
        </li>
        <li class="social-item">
          <a
            href="https://linkedin.com/company/43211106"
            class="social-link"
            [attr.aria-label]="'translations.global.ariaLabels.openLinkedin' | transloco"
          >
            <svg
              fill="#ffffff"
              enable-background="new 0 0 430.117 430.117"
              viewBox="0 0 430.117 430.117"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                stroke="#ffffff"
                d="m430.117 261.543v159.017h-92.188v-148.367c0-37.271-13.334-62.707-46.703-62.707-25.473 0-40.632 17.142-47.301 33.724-2.432 5.928-3.058 14.179-3.058 22.477v154.873h-92.219s1.242-251.285 0-277.32h92.21v39.309c-.187.294-.43.611-.606.896h.606v-.896c12.251-18.869 34.13-45.824 83.102-45.824 60.673-.001 106.157 39.636 106.157 124.818zm-377.934-251.985c-31.548 0-52.183 20.693-52.183 47.905 0 26.619 20.038 47.94 50.959 47.94h.616c32.159 0 52.159-21.317 52.159-47.94-.606-27.212-20-47.905-51.551-47.905zm-46.706 411.002h92.184v-277.32h-92.184z"
              />
            </svg>
          </a>
        </li>
        <li class="social-item">
          <a
            href="https://instagram.com/classof2020hq"
            class="social-link"
            [attr.aria-label]="'translations.global.ariaLabels.openInstagram' | transloco"
          >
            <svg
              version="1.1"
              fill="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background: new 0 0 512 512"
              xml:space="preserve"
              aria-hidden="true"
            >
              <g>
                <g>
                  <path
                    d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160
                    C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48
                    h192c61.76,0,112,50.24,112,112V352z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336
                    c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <circle cx="393.6" cy="118.4" r="17.056" />
                </g>
              </g>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  </div>
</main>
