import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class AddFromMomentsDialogDateAdapter extends NativeDateAdapter {
  public override parse(value): Date | undefined {
    if (typeof value === 'string' && value.indexOf('/') > -1) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      const result = new Date(year, month, date);
      const minDate = new Date(1970, 0, 1);
      const maxDate = new Date(2100, 0, 1);

      if (result < minDate) {
        return minDate;
      } else if (result > maxDate) {
        return maxDate;
      } else {
        return result;
      }
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? undefined : new Date(timestamp);
  }

  public override format(date: Date, displayFormat: string): string {
    const minDate = new Date(1970, 0, 1);
    const maxDate = new Date(2100, 0, 1);

    if (date < minDate) {
      date = minDate;
    } else if (date > maxDate) {
      date = maxDate;
    }

    if (displayFormat === 'input') {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return this.toTwoDigit(day) + '/' + this.toTwoDigit(month) + '/' + year;
    } else if (displayFormat === 'inputMonth') {
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return this.toTwoDigit(month) + '/' + year;
    } else {
      return date.toDateString();
    }
  }

  private toTwoDigit(n: number) {
    return ('00' + n).slice(-2);
  }
}

export const ADD_FROM_MOMENTS_DIALOG_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: 'inputMonth',
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};
