import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType,
  GroupsRequest,
  NotRequest,
} from '../../../models/admin/boolean-filters.model';
import { UserGroups } from '../../../models/admin/group/user-groups.model';
import { USER_GROUPS_DATA_SERVICE, UserGroupsDataService } from '../../../../page-modules/admin/services/groups/groups-data.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GroupSearchAutocompleteComponent } from '../../group-search-autocomplete/group-search-autocomplete.component';
import { Page } from '@app/app/shared/models/page';
import { Result } from '@app/app/shared/store';

@Component({
  selector: 'ptl-group-filter',
  templateUrl: './group-filter.component.html',
  styleUrls: ['./group-filter.component.scss'],
})
export class GroupFilterComponent implements OnInit, OnDestroy {
  @Input() selectedFilters: BooleanFilter[];
  @Input() playlistUid: string;
  @Input() playlistUids: string[];
  @Output() filterDeleted = new EventEmitter<void>();
  @Output() filterSaved = new EventEmitter<BooleanFilter>();

  @ViewChild('groupSearchAutocomplete') private groupSearchAutocomplete: GroupSearchAutocompleteComponent;

  activeGroups: UserGroups[] = [];
  foundGroups: UserGroups[] = [];
  expanded = true;
  groupIs = true;
  loadingGroups = false;
  DEFAULT_PAGE_SIZE = 25;

  private groupTerm = '';
  private searchInputSubject$ = new Subject<void>();
  private searchInputSubscription: Subscription;
  private page = 0;

  constructor(
    @Inject(USER_GROUPS_DATA_SERVICE) private userGroupsService: UserGroupsDataService,
    private translocoService: TranslocoService,
    private cdr: ChangeDetectorRef,
  ) {
    this.searchInputSubscription = this.searchInputSubject$.pipe(debounceTime(500)).subscribe(() => this.fireSearch(true));
  }

  ngOnInit(): void {
    if (this.playlistUid !== null) {
      this.playlistUids = [this.playlistUid];
    }
    this.getSettingsFromFilters();
  }

  ngOnDestroy(): void {
    this.searchInputSubscription?.unsubscribe();
  }

  setGroupIs(): void {
    this.groupIs = true;
  }

  setGroupIsNot(): void {
    this.groupIs = false;
  }

  deleteFilter(): void {
    this.filterDeleted.emit();
  }

  applyFilter(): void {
    const request = this.getRequest(this.activeGroups.map((group) => group._id));
    const message = this.getMessage(this.activeGroups.map((group) => group.title));
    this.filterSaved.emit({ request: request, message: message, type: 'GROUP', activeUserGroups: this.activeGroups });
  }

  private getRequest(groups: string[]): BooleanQueryRequest {
    const request: GroupsRequest = { type: BooleanQueryType.GROUPS, groups: groups };
    if (this.groupIs) {
      return request as BooleanQueryRequest;
    } else {
      return { type: BooleanQueryType.NOT, arg: request } as BooleanQueryRequest;
    }
  }

  private getMessage(labels: string[]): string {
    if (this.groupIs) {
      return (
        this.translocoService.translate('translations.reviews.filter.group') +
        ' ' +
        this.translocoService.translate('translations.reviews.filter.is') +
        ' ' +
        labels.join(' ' + this.translocoService.translate('translations.reviews.filter.or') + ' ')
      );
    } else {
      return (
        this.translocoService.translate('translations.reviews.filter.group') +
        ' ' +
        this.translocoService.translate('translations.reviews.filter.isNot') +
        ' ' +
        labels.join(' ' + this.translocoService.translate('translations.reviews.filter.andNot') + ' ')
      );
    }
  }

  private getSettingsFromFilters(): void {
    if (this.selectedFilters) {
      const statusFilter = this.selectedFilters.find((f) => f.type === 'GROUP');
      if (statusFilter) {
        const booleanRequest = statusFilter.request as BooleanQueryRequest;
        const activeUserGroups = statusFilter.activeUserGroups;
        let groups: string[];

        if (booleanRequest.type === BooleanQueryType.NOT) {
          this.groupIs = false;
          groups = ((booleanRequest as NotRequest).arg as GroupsRequest).groups;
        } else {
          this.groupIs = true;
          groups = (booleanRequest as GroupsRequest).groups;
        }

        if (activeUserGroups) {
          this.addActiveGroupsFromFilter(activeUserGroups, groups);
        } else {
          this.loadGroupsFromService(groups);
        }
      }
    }
  }

  addActiveGroupsFromFilter(activeUserGroups: UserGroups[], groups: string[]): void {
    activeUserGroups.forEach((group) => {
      if (groups.includes(group._id)) {
        this.activeGroups.push(group);
        this.cdr.detectChanges();
      }
    });
  }

  loadGroupsFromService(groups: string[]): void {
    groups.forEach((group) => {
      this.playlistUids.forEach((uid) => {
        this.userGroupsService.loadUserGroup(group, uid).subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            this.activeGroups.push(value);
            this.cdr.detectChanges();
          }
        });
      });
    });
  }

  onSearchInputChange(term: string): void {
    this.groupTerm = term;
    this.loadingGroups = true;
    this.searchInputSubject$.next();
  }

  onGroupSelected(group: UserGroups): void {
    if (!this.activeGroups.some((item) => item._id === group._id)) {
      this.activeGroups.push(group);
    }
  }

  onGroupRemoved(i: number): void {
    this.activeGroups.splice(i, 1);
  }

  onGroupScroll(): void {
    this.fireSearch(false);
  }

  private fireSearch(override: boolean): void {
    this.page = override ? 0 : this.page + 1;
    this.loadingGroups = true;

    const searchCallback = (result: Result<Page<UserGroups>, string, string[]>) => {
      if (result.isSuccess) {
        const { value } = result;
        this.loadingGroups = false;
        this.foundGroups = override ? value.content : this.foundGroups.concat(value.content);
        this.groupSearchAutocomplete.canLoadMore = value.totalNumberOfElement > this.foundGroups.length;
        this.groupSearchAutocomplete.isLoadingMore = false;
        this.cdr.detectChanges();
      }
    };

    if (this.playlistUids.length > 0) {
      this.playlistUids.forEach((playlistUid) => {
        this.userGroupsService.searchUserGroups(this.page, this.DEFAULT_PAGE_SIZE, this.groupTerm, playlistUid).subscribe(searchCallback);
      });
    } else {
      this.userGroupsService.searchUserGroups(this.page, this.DEFAULT_PAGE_SIZE, this.groupTerm).subscribe(searchCallback);
    }
  }
}
