import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CurrentLanguage } from '../../../page-modules/playlist/store/create/playlist-creation.state.model';
import { SupportedLanguage } from '../../models/languages/languages.model';

@Component({
  selector: 'ptl-language-tabs',
  templateUrl: './language-tabs.component.html',
  styleUrls: ['./language-tabs.component.scss'],
})
export class LanguageTabsComponent implements OnChanges {
  @Input() supportedLanguages: SupportedLanguage[];
  @Input() currentIndex: number;
  @Output() tabChange = new EventEmitter<CurrentLanguage>();
  notSingleDefaultLanguage: boolean;

  ngOnChanges(changes: SimpleChanges) {
    this.notSingleDefaultLanguage = !(this.supportedLanguages?.length === 1 && this.supportedLanguages[0].isDefault);
  }

  onTabChangeButton(code: string) {
    const languageCode = code;
    const supportedLanguage = this.supportedLanguages.find((it) => it.language.code === languageCode);
    this.tabChange.emit({ supportedLanguage, index: this.currentIndex });
  }
}
