<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<button
  mat-button
  class="favorite-button"
  *ngIf="favorite !== undefined && favorite !== null"
  [attr.aria-label]="
    favorite
      ? ('translations.global.ariaLabels.remove' | transloco: { name: title }) +
        ' ' +
        ('translations.global.ariaLabels.fromFavorites' | transloco)
      : ('translations.global.ariaLabels.add' | transloco: { name: title }) +
        ' ' +
        ('translations.global.ariaLabels.toFavorites' | transloco)
  "
  [title]="
    !editMode
      ? favorite
        ? ('translations.global.ariaLabels.remove' | transloco: { name: title }) +
          ' ' +
          ('translations.global.ariaLabels.fromFavorites' | transloco)
        : ('translations.global.ariaLabels.add' | transloco: { name: title }) +
          ' ' +
          ('translations.global.ariaLabels.toFavorites' | transloco)
      : ''
  "
  (click)="onFavoriteToggleClick(); $event.stopPropagation()"
  (keydown.enter)="$event.stopPropagation()"
>
  <svg class="svg" aria-hidden="true" *ngIf="!(favorite && !editMode)">
    <use ogSvgUnify="assets/sprite/sprite.svg#save"></use>
  </svg>

  <svg class="svg" aria-hidden="true" *ngIf="favorite && !editMode">
    <use ogSvgUnify="assets/sprite/sprite.svg#save-active"></use>
  </svg>

  <span class="extra-small title">{{ 'translations.playlist.favorite.button.save' | transloco }}</span>
</button>
