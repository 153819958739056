<div class="card-box" *ngIf="cardData">
  <mat-card class="card">
    <div class="card__center">
      <img
        class="center__image"
        alt=""
        width="266"
        height="150"
        [src]="
          cardData.thumbnail ? cardData.thumbnail : cardData.image ? cardData.image : '/assets/card-placeholders/card-placeholder-1.png'
        "
      />
    </div>
    <h3 class="card__description">
      <span>{{ cardData.title }}</span>
    </h3>
    <p class="card__description">
      <span>{{ cardData.subLine }}</span>
    </p>
  </mat-card>

  <div class="card-info-box" *ngIf="cardData.type === 'PLAYLIST'">
    <div class="card-info-box-title">{{ 'translations.contentStore.summary.content.title' | transloco }}</div>
    <ul class="card-info-box-description">
      <li *ngIf="cardData.playlistsNumber">
        {{ 'translations.contentStore.summary.content.totalBadges' | transloco: { count: cardData.playlistsNumber } }}
      </li>
      <li *ngIf="cardData.pagesNumber">
        {{ 'translations.contentStore.summary.content.totalPages' | transloco: { count: cardData.pagesNumber } }}
      </li>
      <li *ngIf="cardData.resourcesNumber">
        {{ 'translations.contentStore.summary.content.totalCards' | transloco: { count: cardData.resourcesNumber } }}
      </li>
      <li *ngIf="cardData.assessmentsNumber">
        {{ 'translations.contentStore.summary.content.totalAssessments' | transloco: { count: cardData.assessmentsNumber } }}
      </li>
      <li *ngIf="cardData.eventsNumber">
        {{ 'translations.contentStore.summary.content.totalEvents' | transloco: { count: cardData.eventsNumber } }}
      </li>
      <li *ngIf="cardData.badgesNumber">
        {{ 'translations.contentStore.summary.content.totalBadges' | transloco: { count: cardData.badgesNumber } }}
      </li>
    </ul>
  </div>

  <div class="card-info-box">
    <div class="card-info-box-title">{{ 'translations.contentStore.summary.languages.title' | transloco }}</div>
    <ul class="card-info-box-description">
      <li *ngFor="let language of cardData.languages">
        {{
          (language.code === 'en'
            ? 'translations.contentStore.summary.languages.english'
            : 'translations.contentStore.summary.languages.welsh'
          ) | transloco
        }}
      </li>
    </ul>
  </div>
</div>
