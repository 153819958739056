/* eslint-disable max-len */

export interface TmiType {
  shortDescription: string;
  atWorkParagraphs: {
    effectiveness: string[];
    teamLeader: string[];
    conflicts: string[];
    stressAdvisor?: string[];
    workEnvironment: string[];
    underStress?: string[];
    underSevereStress?: string[];
    teamLeaderFlipSide?: string[];
    weakSpotsWithOthers?: string[];
  };
  atWorkTagLine: string;
  atWorkSneakPeek: string;
  withOthersTagLine: string;
  withOthersSneakPeek: string;
  withOthersParagraphs: {
    strengths: string[];
    weakSpotsWithOthers: string[];
  };
  typeTagLine: string;
  typeSneakPeek: string;
  typeKeywords: string[];
  typePersonalStrengths: string[];
  typeWeakSpots: string[];
  description: string;
  individualTypeByLetters: string;
  individualDescription: string;
}

export const tmiTypesReport: {
  [trait: string]: TmiType;
} = {
  ENFJ: {
    shortDescription: 'Giver',
    atWorkParagraphs: {
      effectiveness: [
        'Placing a higher emphasis on task completion',
        'Being more inquisitive, sceptical and critical',
        'Being less loyal to people or causes that might not deserve it',
        'Acting spontaneously more often',
        'Being less bossy to people they care for, allowing them to make their own mistakes and learn from them',
        'Spending less time on interpersonal issues',
        'Building on their strength to provide structure and organization to their team',
        'Building on their strength to achieve consensus through clarification, collaboration and cooperation',
        "Realising that theory doesn't always work",
        'Focusing on the positives rather than the negatives',
        'Recognising that decisions sometimes have to be made with incomplete information',
      ],
      teamLeader: [
        'Energise and inspire the team with enthusiasm and humor',
        'Has an egalitarian leadership style',
        'Place equal weight to the process as well as the content',
        'Create a clear team organization',
        'Ensure the team is kept on track by making use of agendas, schedules and task accomplishments',
        'Listen to all team members and considers each opinion',
        "Facilitate cooperation and makes effective use of all resources at the team's disposal",
        'Lead their team through insight and sensitivity',
      ],
      conflicts: [
        'They are likely to be irritated by team members who come unprepared to meetings',
        "May be likely to conflict with people whom they perceive as being uncommitted to solving the team's problems",
        "Less likely to get on well with people who show disrespect for others' time by coming late and leaving early",
        "Could more likely conflict with people who don't make enough effort to collaborate",
        "May have a low tolerance for people who don't consider the impact of their decisions on others",
        "Could be more likely to be bothered by people who don't allow enough time for interpersonal pleasantries",
      ],
      stressAdvisor: [
        'Becoming increasingly critical and finding fault with everything',
        'Become fixated on making sure that they are well liked by everyone and to gain their approval',
        'Find themselves becoming bossy with a need to organize everything',
      ],
      workEnvironment: [
        'Prefers a busy, interactive environment and seeks out harmony',
        'Prefers to have a plan, a routine and stick to it',
      ],
      underStress: [],
      underSevereStress: [],
    },
    atWorkTagLine: 'Energises and inspires the team with enthusiasm and humor',
    atWorkSneakPeek: 'One way they can enhance their work effectiveness is by being more inquisitive, sceptical and critical',
    withOthersTagLine: 'Outgoing, friendly and sociable',
    withOthersSneakPeek:
      '%fname might feel the most comfortable taking up the following roles in their team: The Organizer, The Idea Generator, The Moderator',
    withOthersParagraphs: {
      strengths: [
        'Outgoing, friendly and sociable',
        'Popular, sympathetic',
        'Expressive',
        'Gives encouragement and praise freely',
        'Enjoy exchanging small talk',
        'Individual style but always has the group in mind',
      ],
      weakSpotsWithOthers: [
        'Hypersensitive to conflict',
        'May need encouragement and recognition',
        'May need time for interpersonal pleasantries',
        'Might see criticism where none was intended',
        'Unlikely to act spontaneously',
      ],
    },
    typeTagLine: 'Able to find patterns and meanings behind the surface',
    typeSneakPeek: "%fname's type is called ENFJ Some associated keywords are sympathetic, harmonious, and catalyst.",
    typeKeywords: [
      '1. Cooperative',
      '2. Sympathetic',
      '3. Search for self',
      '4. Catalyst',
      '5. Relationship builder',
      '6. Possibilities',
      '7. Compassionate',
      '8. Organized',
      '9. Detail oriented',
      '10. Harmony',
      '11. Sensitive',
    ],
    typePersonalStrengths: [
      'Able to find patterns and meanings behind the surface',
      'Good at facilitating communication',
      'Creative, with a strong imagination',
      'Responsible approach to work',
      'Good at presenting',
      'Good at leading discussions with ease and tact',
      'Responsive to praise and criticism',
      'Dependable, trustworthy and loyal',
      'Good at attending to details and organizing the world around them.',
      'Keen on working for the common good, natural co-operator',
      'Warm, sensitive, understanding and empathic',
      'Able to get things done',
      'Highly attuned to the emotions, needs and motivations of others',
      'Eager to bring out the best in everyone',
      'Able to draw on theoretical concepts in an orderly and methodical manner',
      'Sound grasp of theoretical concepts and fundamental principles',
      'Able to approach problems in a systematic manner',
    ],
    typeWeakSpots: [
      'Strongly dislikes discord and disagreement',
      'Does not find supervising others easy',
      'Prefers a well-defined team role',
      'Can sometimes lose sight of practical issues',
      'Might feel hurt or rejected easily',
    ],
    description:
      'The ENFJ is an enthusiastic, personable and responsive individual with a strong focus on relationships and interaction. Striving to find harmony they strongly believe in people, sometimes to a fault. The ENFJ is warm and altruistic, full of charisma and can get people to follow their lead. They are natural, easy communicators who will stand up for what they believe in. Reliable and organized they have a desire to deliver, but without losing sight of the needs of others.',
    individualTypeByLetters: 'Giver',
    individualDescription:
      'As a Giver, they are likely to be an enthusiastic, personable and responsive individual with a strong focus on relationships and interaction. Striving to find harmony they tend to strongly believe in people, sometimes to a fault. They are likely to be warm and altruistic, full of charisma and able to get people to follow their lead.  They are likely to be a natural, easy communicator who stands up for what they believe in. Reliable and organized they have a desire to deliver, but ensure this is without losing sight of the needs of others.',
  },
  ENFP: {
    shortDescription: 'Inspirer',
    atWorkParagraphs: {
      effectiveness: [
        'Not over-personalising decisions',
        'Remembering that the execution of their ideas might be more complex and take longer than coming up with those ideas',
        'Being more pragmatic',
        'Questioning their own intuitive insights more often',
        "Making sure they haven't overlooked some key facts",
        'Listening more carefully to the quiet members on their team',
        'Developing their assertiveness',
        'Taking a more critical stance towards other points of view',
        'Taking enough time to pause and reflect before moving into action',
        "Trying to say 'no' more often",
        'Not taking criticism personally',
        "Making sure they don't promise more than is reasonable or possible to achieve",
        'Being mindful not to get the team off track by being overly talkative or randomly throwing in ideas',
      ],
      teamLeader: [
        'Inspire others with their enthusiasm',
        'Make sure every voice on the team gets heard and understood. Carefully negotiate between differing opinions on their team',
        'Emphasise areas of agreement and makes sure overall agreement is reached',
        'Develop personal relationships with each team member',
        'Empower team members to share, recognise and validate their contributions',
        'Solicit ideas and visions from everyone on the team when developing strategies for the future',
        'Present positive alternatives for consideration in the decision-making process',
        'Seek to find development opportunities for every team member',
      ],
      conflicts: [
        'May encounter conflict with team members whom they perceive as having too narrow focus',
        'May be irritated by people who appear to be too negative or constantly pessimistic',
        'Less likely to get on well with people who they perceive as being overly focused on details',
        "Could get frustrated by people who don't include everyone in the team process",
        'Likely to be irritated by people who spend too much time arguing about what needs to be accomplished',
      ],
      stressAdvisor: [
        "May have difficulty saying 'no'",
        'May take on too many projects without any practical way of completing them',
        'Under severe stress might find it difficult to pull away from a project to attend practical or physical need',
      ],
      workEnvironment: [
        'An interactive, sociable environment',
        'Working towards something meaningful and use their imagination and creativity',
      ],
      underStress: [
        'Can become increasingly avoidant of detail, and get confused with the facts',
        'Likely someone who ends up retreating and withdrawing from a situation, becoming negative and pessimistic',
        'Finds it difficult to focus and follow through on routine procedures',
      ],
      underSevereStress: ['Under severe stress finds it difficult to pull away from a project to attend practical or physical needs'],
    },
    atWorkTagLine: '---',
    atWorkSneakPeek: 'One way %fname can enhance their work effectiveness is by not over-personalizing decisions',
    withOthersTagLine: '---',
    withOthersSneakPeek:
      '%fname might feel the most comfortable taking up the following roles in a team: The Idea Generator, The Moderator, or The Boundary Spanner',
    withOthersParagraphs: {
      strengths: [
        'Sociable and outgoing',
        'Easily liked, and popular',
        'Shows great interest in the people around them',
        'Typically perceived as a warm, empathic and caring individual',
        "Evaluates others' point of view with sensitivity and tact",
        'Makes friends easily',
        'Free-thinking and open-minded',
        'Harmonious',
        'Accommodating',
        'Values integrity highly',
      ],
      weakSpotsWithOthers: [
        'May have difficulty confronting others',
        'May accommodate others at their own expense',
        'May become resentful if they perceive they have been taken advantage of',
        'May become disheartened if their ideas and opinions are not valued',
        'Others may perceive them as being a little disorganized',
      ],
    },
    typeTagLine: '--',
    typeSneakPeek: "%fname's type is called ENFP. Some associated keywords are enthusiastic, visionary, or warm.",
    typeKeywords: [
      '1. Enthusiastic',
      '2. Energic',
      '3. Supportive',
      '4. Sociable',
      '5. Visionary',
      '6. People oriented',
      '7. Creative',
      '8. Caring',
      '9. Warm',
      '10. Sympathetic',
      '11. Spontaneous',
      '12. Flexible',
    ],
    typePersonalStrengths: [
      'Enthuses and motivates others',
      'Sees life as full of possibilities',
      'Facilitates communication between people',
      'Creative',
      'Develops novel solutions for problems',
      'Perceives what matters to other people',
      'Improvisation',
      'Often verbally gifted',
      'Able to see the Big Picture',
      'Able to make connections between events and information very quickly',
      'Thinks in metaphors',
      'Natural at brainstorming',
      'Naturally co-operative and keen to work for the common good',
      'Strong people skills',
      'Adaptable to change',
      'Good at explaining and helping people understand',
      'Good at promoting change through relationship building',
      'Idealistic, positive outlook on life',
    ],
    typeWeakSpots: [
      'Hypersensitive to conflict',
      'Requires a lot of affirmation from others',
      'Sometimes interprets too much and may draw connections between events and information too quickly',
      'Desires constant encouragement and recognition',
      'Enthusiastic at the beginning but might lack follow-through',
      'Might delay making a decision to keep options open',
      'Finds it difficult to prepare in advance',
      'Difficulty attending to details',
      'Intolerant of routine',
    ],
    description:
      "The ENFP is a sociable individual, and their enthusiasm means that they find themselves frequently in the company of others. They are likely to be described as the life and soul of the party. The ENFP is curious and independent, enjoying change and flexibility however loses focus easily. The ENFP has a lot of 'big ideas' and seeks action. They want to get everyone involved in their ideas and plans to add to the creativity and innovation. They care deeply about others and what other people think, and are able to brighten the lives of those around them.",
    individualTypeByLetters: 'Inspirer',
    individualDescription:
      "As an Inspirer, they are likely to be a sociable individual, and their enthusiasm means that they find themselves frequently in the company of others. They are likely to be described as the life and soul of the party. Curious and independent they tend to enjoy change and flexibility however they can lose focus easily. They are likely to have a lot of 'big ideas' and seek action, wanting to get everyone involved in their ideas and plans to add to the creativity and innovation. They are likely to care deeply about others and what other people think, and are able to brighten the lives of those around them.",
  },
  ENTJ: {
    shortDescription: 'Executive',
    atWorkParagraphs: {
      effectiveness: [
        'Being more patient with people who might need more time than they for understanding complex, abstract ideas',
        'Developing a higher tolerance for the perceived incompetence or inefficiency of others',
        'Being less critical and more accepting of others strengths and weaknesses',
        'Not setting unachievable standards',
        "Acknowledging others' needs for emotional support",
        'Finding constructive ways of criticising',
        'Developing a healthy respect for details',
        'Trying to listen more and say less',
        'Making more time for private reflection',
      ],
      teamLeader: [
        'Take on a co-ordinating role',
        'Enhance understanding and completion of a task by providing models',
        'Communicate their vision in a way that energises others into active participation',
        'Be more objective to direct the efforts and activities of others towards an overall goal or objective',
        'Provide a clear sense of overall objectives',
        'Be open-minded and as a result likely to value contributions from any source',
        'Be democratic and encourages participation',
        'Take responsibility for their decisions',
        'Have total dedication to the task',
        'Motivate others by setting high standards',
      ],
      teamLeaderFlipSide: [
        'Sometimes be perceived as uncompetitive, not forceful enough or un-ambitious.',
        'Sometimes be perceived as reserved or detached',
        'Not pause to gather information or explore other alternatives.',
        'Can come across as too controlling',
      ],
      conflicts: [
        'Might be bothered by people whom they perceive as being incompetent',
        "Might be frustrated by people who show a lack of commitment to the team's goals",
        'More likely to be bothered by people who exhibit a casual attitude and come across as too disorganized.',
        'More likely to find it difficult to work with people who continue to discuss an issue even though clarity has already been achieved',
        'May be irritated by people who get caught up in discussing details that they perceive as irrelevant to contributing to the goal',
        'Likely to conflict with people who lack a systematic perspective and approach to use of time and resources',
      ],
      stressAdvisor: [],
      workEnvironment: ['Structured and organized', 'Future oriented', 'Goal orientated'],
      underStress: [
        'They might become hypercritical of themselves and others',
        'They might get angry and controlling without considering the impact of their behaviour on people too much',
      ],
      underSevereStress: ['They might feel trapped and over-emotional', 'They might hear innocent comments as personal attacks'],
    },
    atWorkTagLine: '---',
    atWorkSneakPeek:
      'One way %fname can enhance their work effectiveness is by being less critical and more accepting of others strengths and weaknesses',
    withOthersTagLine: '---',
    withOthersSneakPeek:
      '%fname might feel most comfortable taking up the following roles in their team: The Organizer, The Idea Generator, The Critic',
    withOthersParagraphs: {
      strengths: [
        'Sociable and outgoing',
        'Logic and analysis are paramount',
        'Critical and quick to question',
        'Ready to challenge themselves and others',
        'Assertive, decisive',
        'Communicates directly',
        'Likes to organize people and take charge',
        'Dependable and reliable',
        'Value justice',
      ],
      weakSpotsWithOthers: [
        'Less assertive people might perceive them as overpowering, opinionated or dogmatic',
        'They might appear as holding themselves in too high regard',
        'Some people might resent their tendency to take charge of situations',
        'Might come across as cool and impersonal',
      ],
    },
    typeTagLine: '--',
    typeSneakPeek: "%fname's type is called ENTJ. Some associated keywords are planner, frank or enthusiastic",
    typeKeywords: [
      '1. Planner',
      '2. Visionary',
      '3. Organizer',
      '4. Logical',
      '5. Analytical',
      '6. Theoriser',
      '7. Nonconformist',
      '8. Argumentative',
      '9. Hearty and frank',
      '10. Decisive',
      '11. Objective',
      '12. Enthusiastic',
    ],
    typePersonalStrengths: [
      'Being goal-oriented, organized and delivering results as projected',
      'Taking a long-term view when solving problems by concentrating on ideas relating to the big picture',
      'Identifying illogical and inefficient procedures and policies',
      'Analysing and bringing a logical order to problems',
      'Developing new theories/models/ideas/systems to solve problems',
      'Objectively criticising potential solutions',
      'Implementing solutions',
      'Bringing energy to a task',
      'Learning new things, expanding their knowledge, and passing it on to others',
      'Presenting ideas',
      'Giving expert advice',
      'Focusing on what might be, not what it is',
      'Conceptualising and abstract thinking',
      'Making decisions',
      'Applying knowledge',
    ],
    typeWeakSpots: [
      'Can sometimes appear more positive and confident than their experience in an area warrants',
      'May sometimes appear overpowering and controlling',
      'Might sometimes push too hard to get the job done',
      'Might irritate others by wanting all the work to be completed immediately',
    ],
    description:
      'The ENTJ is an honest, decisive individual. They are delivery focused and organized, with an eye for the big picture and long term strategic planning.  ENTJs are often very charismatic and can get people to follow their lead. They choose to be direct and purposeful in their interactions, avoiding getting too involved or close and personal. The ENTJ strives to be efficient, and is unlikely to give up at a task, striving to rise above each obstacle that gets in their way.',
    individualTypeByLetters: 'Executive',
    individualDescription:
      'As an Executive, they are likely to be an honest and decisive individual. They tend to be particularly delivery focused and organized, with an eye for the bigger picture and long term strategic planning. As an Executive, they are likely to be charismatic and able to get people to follow their lead; choosing to be direct and purposeful in their interactions, and avoiding getting too involved or close and personal. They are likely to strive to be efficient and are unlikely to give up at a task rising above any obstacles in their way.',
  },
  ENTP: {
    shortDescription: 'Visionary',
    atWorkParagraphs: {
      effectiveness: [
        'Contributing their ingenuity but not overwhelming people with too many possibilities',
        'Not stretching their energy across too many projects at the same time',
        'Developing their self-discipline',
        'Using their analytical skills to find the direction they want to follow',
        'Working on their decision-making skills',
        'Reminding themselves that implementing an idea is more complicated than imagining it',
        'Working on their follow-through by keeping notes of what they promised',
        'Allowing others to take the stage and shine more often',
        'Not reducing everything to a conceptual model',
        'Paying attention to information that is not part of a logical domain',
        'Prioritising alternative possibilities, potentially with a higher focus on people',
        "Accepting they may not 'know-it-all'",
        'Improving time-management techniques',
        'Considering colleagues interpersonal needs more often',
        'Focusing more on practicalities',
      ],
      teamLeader: [
        'Be visionary, generating new ideas with ease',
        'Motivate others by setting high standards',
        'Expect others to really challenge themselves, and break new ground by going beyond the required tasks',
        'Involve and inspires the best people to work with them',
        'Blend the interests of the team members into a plan for action',
        'Allow a dynamic interplay between process and content',
        'Question and critique various possibilities',
        'Offer different perspectives as well as information to find the best, most objective solution',
      ],
      conflicts: [
        'Likely to be irritated by people who refuse to consider new possibilities',
        "May be bothered by people who can't understand that process and content are equally important",
        'More likely to be irritated by people who are unable to separate the essential from the trivial',
        'May have conflicts with people who lack an achievement orientation and a can-do attitude',
      ],
      stressAdvisor: [
        "Takes on too many projects that can't be finished",
        'Avoids details and becomes agitated and frantic',
        'Eventually ends up retreating from a situation and finds that they need lots of support',
      ],
      workEnvironment: [
        'Involves less repetition and routine, with lots of opportunities to converse and collaborate with others',
        'Involves imagination and innovation where ideas are debated',
      ],
    },
    atWorkTagLine: '---',
    atWorkSneakPeek:
      'One way %fname can enhance their work effectiveness is by not wasting their energy across too many projects at the same time',
    withOthersTagLine: '---',
    withOthersSneakPeek:
      '%fname might feel the most comfortable taking up the following roles in their team: The Idea Generator, The Maker or The Critic',
    withOthersParagraphs: {
      strengths: [
        'Gregarious and sociable',
        'Stimulating company, alert and outspoken.',
        'Questioning and quick to challenge',
        'Exudes high energy and humor',
        'Contagious enthusiasm',
        'Spontaneous',
        'Sympathetic and friendly',
        'May argue for fun on either side of a question',
        'Values others because of perceived intelligence and ability, rather than because of status',
        'Able to see different points of views',
        'Good at reading other people',
        'Flexible, adaptable and open-minded',
        'Easy-going and casual',
        'Non-conformist, individualist',
      ],
      weakSpotsWithOthers: [
        'Might sometimes appear insensitive to emotional issues',
        'Might turn others off by their preference for debate and intellectual sparring',
        'High need for autonomy',
        'Might overwhelm others with bursts of ideas',
      ],
    },
    typeTagLine: '--',
    typeSneakPeek: "%fname's Type is called ENTP. Some associated keywords are visionary, entrepreneur, or achievement",
    typeKeywords: [
      '1. Logical',
      '2. Ingenious',
      '3. Free-thinking',
      '4. Enthusiastic',
      '5. Visionary',
      '6. Possibilities',
      '7. Big Picture person',
      '8. Entrepreneurial',
      '9. Imaginative',
      '10. Adaptable',
      '11. Competent',
      '12. Achievement oriented',
    ],
    typePersonalStrengths: [
      'Quick, logical, ingenious and good at many things',
      "An 'If they can imagine it, they can do it' mindset",
      'Loves solving complex problems and approaching them as challenges',
      'Excellent at perceiving patterns and seeing the bigger picture',
      'Excellent at uncovering underlying principles of a problem',
      'Good at solving problems with long-term fixes',
      'Imagines the possibilities of a situation and then strategically analyses them to determine which course of action would be the most effective to pursue',
      'Often skilled in noticing trends and forecasting the future',
      'High achievement orientation, they enjoy challenging themselves and others',
      'Good at motivating others by setting high standards',
      'Able to improvise and respond to the needs of the moment',
      'Driven by inspiration',
      'Natural conceptualizer',
      'Tends to work in bursts of energy and enthusiasm',
      'Can be a dynamic visionary, an architect of the future',
      'Good at taking charge of change efforts',
      'High intellectual curiosity',
      'Good at making objective observations',
      "Doesn't believe in rules and will navigate around them to achieve their goal",
      'Self-directed and independent of authority',
      'Able to work independently',
      'Good at applying knowledge and utilising competencies',
      'Innovator, planner and designer, who enjoys complexity',
      'Strong abilities in developing new theories, models, ideas or systems',
      'Wordsmith',
      'Good at analysing things with both logic and empathy',
      'Original thinker',
      'Able to argue a point well',
      'Eternal optimist',
      'Good at forming and clarifying ideas with creative new insights',
      'Good at providing analysis and synthesis',
    ],
    typeWeakSpots: [
      'Impatient with a low tolerance when things move too slowly',
      'Tends to take on too many things at the same time',
      'Bored by routine and unchallenging work, and will seldom do the same thing the same way',
      'Values competence and achievement highly, expecting too much of others',
      'Likely to set high standards for themselves that might lead to situations of self-doubt',
    ],
    description:
      "The ENTP is a pioneer of change and resourcefulness. Thriving on flexibility and innovation, they are sociable individuals and enjoy debating ideas and concepts, often playing devil's advocate to get other people thinking about new and diverse ways of doing things. Energetic and chatty, the ENTP seeks out new environments for themselves to explore to locate new intellectual challenges. They dislike monotony. Repetition leads them to spread themselves too thinly or do things differently in order to avoid boredom and inject variety and excitement into a task.",
    individualTypeByLetters: 'Visionary',
    individualDescription:
      "As a Visionary, they are likely to be a pioneer of change and resourcefulness. They thrive on flexibility and seek innovation, they are sociable, enjoying debates of ideas and concepts. They may play devil's advocate in order to get other people thinking about new and diverse ways of doing things. Energetic and chatty, they are likely to seek out new environments to explore in order to locate new intellectual challenges. A fear of monotony and repetition leads them to be at risk of spreading themselves too thinly, and they also try to do things differently in order to avoid boredom and inject variety and excitement into a task.",
  },
  ESFJ: {
    shortDescription: 'Provider',
    atWorkParagraphs: {
      effectiveness: [
        'Reminding themselves to be more concise and to the point',
        'Learning not to take criticism or rejection to heart, but acknowledging the objective facts underlying it',
        'Acting less quickly on others behalf',
        'Letting others make their own mistakes',
        'Reminding themselves not to talk an issue to death, letting the trivial things go more easily',
        'Placing less emphasis on consensus',
        'Accepting that sometimes it is necessary to re-evaluate agreed decisions in light of additional information, even if this means starting over',
        'Accepting that plans and processes are not set in stone',
        'Being more accepting of others intuitions',
        'Accepting that ambiguity is part of life and decisions sometimes have to be made in uncertainty',
      ],
      teamLeader: [
        'Set a clear agenda and achieve consensus and closure by honouring the time commitments',
        'Create an atmosphere where everyone understands their importance to the team and feels encouraged to contribute',
        'Cooperatively work out what needs to be done, and who is best suited to address these tasks',
        'Motivate others by using persuasive arguments that take people’s feelings into account',
        'Focus on practical ways to bring the team together in problem-solving',
        'Clarify open issues by asking the right questions, rather than by giving all the answers themselves',
      ],
      conflicts: [
        'May encounter conflict with people whom they perceive as being passive',
        'More likely to have conflict with people whom they see as assuming no responsibility for resolving problems',
        'May be irritated by people who refuse to stick to the set agenda and waste time',
        'May be bothered by people who upset consensus by playing the devil’s advocate',
        'Likely to have a low tolerance towards people who slow down progress by constantly interrupting the flow',
        "More likely to have conflict with people who don't complete tasks accurately and on time",
      ],
      stressAdvisor: [],
      workEnvironment: [],
    },
    atWorkTagLine: '---',
    atWorkSneakPeek: 'One way they can enhance their work effectiveness is by letting others make their own mistakes',
    withOthersTagLine: '---',
    withOthersSneakPeek:
      'They might feel the most comfortable taking up the following roles in their team: The Organizer, The Maker, The Precisian',
    withOthersParagraphs: {
      strengths: [
        'Warm-hearted, friendly and supportive',
        'Talkative, popular',
        'Caring',
        'Harmonious',
        'Tends to have a good sense of social responsibility, and altruistic',
        'Respectful, well-behaved',
        'Sympathetic character that makes decisions based on values',
        'Thoughtful',
        'Strong sense of duty',
        'Loyal to friends and colleagues',
        'Values traditions',
      ],
      weakSpotsWithOthers: [
        'Tends to take objective criticism personally',
        'Severely dislikes discord, shying away from conflict',
        'Particularly seeks appreciation',
        'Can be too rigid, not adapting easily to change',
        'Imagining slights where none were intended',
      ],
    },
    typeTagLine: '--',
    typeSneakPeek: "%fname's type is called ESFJ. Some associated keywords are Harmonic, Sympathetic, or Orderly",
    typeKeywords: [
      '1. Harmony',
      '2. Warm',
      '3. Orderly',
      '4. Cooperative',
      '5. Caring',
      '6. Detailed',
      '7. Sympathetic',
      '8. Dutiful',
      '9. Down-to-earth',
      '10. Systematic and organized',
      '11. Plans',
    ],
    typePersonalStrengths: [
      'Positive and optimistic attitude',
      'Providing comfort',
      'Good at smoothing conflict',
      'Loyal to established systems and good at preserving traditions',
      'Takes work seriously and believes others should too',
      'Conscientious and excellent attention to detail',
      'Brings an aura of warmth to everything they do',
      'Very likely to follow through even in small matters',
      'Attentive they notice what others need in their day-to-day lives and try to provide it.',
      'Great team-player, co-operative',
      'Good at creating a harmonious environment',
      'Good at organizing the world around them',
      'Able to get things done on time and to the required standard',
      'Realistic, fact-based outlook',
      'Good at defining goal-oriented modes of working together',
      'Responsible approach to work, able to find a way to be useful',
      'Good at planning in detail',
      'Good at offering a systematic, practical perspective',
      'Good at organizing and motivating people to contribute',
      'Good at being inclusive and at acknowledging where people have different ideas',
      'Good at adding harmony to the team through careful organization, enthusiasm and humor',
      "Strong interest in things that directly and visibly affect people's lives",
      'Strong customer service orientation, likes to make people feel valued',
    ],
    typeWeakSpots: [
      'Strong desire for harmony, tending to avoid conflicts where possible',
      "Works best with encouragement and praise, but might hold back if they don't receive it",
      'Little interest for abstract concepts, where the implications for real people are not immediate',
      'Needs an orderly work environment',
      'Tends to strongly dislike change, instability and crises',
      'Supports existing order and might be overly authority dependent. Takes established order and rules too much for granted',
      'Longs for a sense of belonging. Can become disenchanted and de-motivated if they are not appreciated for who they are and what they contribute',
      'Might overvalue (their own) status and seniority as opposite to (technical) competence',
      'Needs a clearly defined team-role, and clearly defined goals',
      'Focus on detail might be interpreted as a lack of imagination by some colleagues',
    ],
    description:
      'The ESFJ is a compassionate individual, who is focused on supporting others and creating harmony. The ESFJ is conscientious and relationship oriented, seeing the positive in people. Extremely loyal, with a commitment to their team, they concentrate on delivery and are willing to lend a hand to help others out. The ESFJ likes to maintain harmony within the team, without compromising on getting things done by looking for solutions that are pleasing to everyone.',
    individualTypeByLetters: 'Provider',
    individualDescription:
      'As a Provider, they are likely to be a compassionate individual, who is focused on supporting others and creating harmony.  they are likely to be conscientious and relationship oriented, seeing the positive in people. They tend to be extremely loyal, with a commitment to their team, concentrating on delivery and are willing to lend a hand to help others out. As a Provider, they are likely to maintain the harmony within the team, without compromising on getting things done by looking for solutions that are pleasing to everyone.',
  },
  ESFP: {
    shortDescription: 'Performer',
    atWorkParagraphs: {
      effectiveness: [
        'Moving away from their focus on the current, towards a more task-focused approach',
        'Developing a better appreciation of Big Picture thinking',
        'Getting the balance right between liveliness and flippancy',
        'Expressing their own views more assertively',
        'Emphasising task considerations',
        "Being more assertive and saying 'no' to unreasonable demands",
        'Focusing on the detailed requirements of the tasks',
        'Not taking criticism personally',
        'Acknowledging the value of logical analysis',
      ],
      teamLeader: [
        'Try more to get the entire process started and summarize the decisions that have been made',
        "Encourage collaboration among team members and ensure that everybody's strengths and contributions are appreciated",
        'Encourage wide participation and make sure all opinions and suggestions have been considered before a decision is made',
        'Encourage others to enable positive results',
      ],
      conflicts: [
        'Might be frustrated by people whom they perceive as being overly negative, critical, blaming and pessimistic',
        'May be bothered by people who talk in a very highbrow way without making a point',
        'May be irritated by people who just focus on the task, and become insensitive to the (real-not really necessary) people around them',
        "Tending to find it difficult to work with people who are single-minded and don't appreciate different viewpoints and contributions",
      ],
      stressAdvisor: [],
      workEnvironment: ['Hands-on', 'Practical', 'Energising, and fun', 'Where there is freedom and space', 'Non-routine'],
      underSevereStress: [
        'They might be perceived as being too compromising, personalising too much and not paying enough attention to detail, theory or the big picture.',
      ],
      weakSpotsWithOthers: [
        'Under severe stress they might find it difficult to pull away from a project to attend practical or physical needs',
      ],
    },
    atWorkTagLine: '---',
    atWorkSneakPeek: "One way they can enhance their work effectiveness is by developing a better appreciation of 'big picture' thinking",
    withOthersTagLine: '---',
    withOthersSneakPeek:
      'They might feel the most comfortable taking up the following roles in their team: The Maker, The Moderator, or The Boundary Spanner',
    withOthersParagraphs: {
      strengths: [
        'Outgoing',
        'Warm',
        'Friendly',
        'Supportive',
        'Fun-loving',
        'Spontaneous',
        'Tolerant',
        'Open-minded',
        'Lively',
        'Talkative',
        'Sympathetic',
        'Tactful',
        'Cooperative',
        'Egalitarian',
        'Easy-going',
        'Sensitive to the needs of others',
      ],
      weakSpotsWithOthers: [
        'Sometimes too compromising',
        'Sometimes too touchy-feely',
        'Might be perceived as being a bit disorganized',
        'May take criticism too personally',
        'Can over interpret a situation',
        'Might try to do too many things at the same time',
      ],
    },
    typeTagLine: '--',
    typeSneakPeek: "%fname's Type is called ESFP. Some associated keywords are energetic, experience, or realistic",
    typeKeywords: [
      '1. Enthusiastic',
      '2. Optimistic',
      '3. Action-orientated',
      '4. Energetic',
      '5. Pragmatic',
      '6. Tolerant',
      '7. Feeling',
      '8. Realistic',
      '9. Down-to-earth',
      '10. New activities, new people',
      '11. Experience',
      '12. Adaptable',
    ],
    typePersonalStrengths: [
      'A high level of energy',
      'Finding practical solutions to concrete, real-world problems',
      "Developing solutions that take others' needs and feelings into account",
      'Empathetic and understanding of people, ready to consider others point of view',
      'Not stuck to long established approaches, but ready to adapt',
      'Goal-directed, and ready to follow the direct path to success as opposed to spending unnecessary time and effort',
      'Ready and eager to make things happen',
      'Likes action and making things happen',
      "Knows what's going on and joins in eagerly",
      'Brings common sense and a realistic approach to problem-solving',
      'Good at remembering facts',
      'Good at responding to crises',
      'Good at smoothing conflict',
      'Good at being inclusive',
      'Energises others with positive thinking',
    ],
    typeWeakSpots: [
      'May be considered as having a too personal approach',
      'May appear as not taking matters seriously enough',
      'May irritate others by dismissing abstract and conceptual debate',
      'Tendency to shy away from conflict',
      'Tendency to dislike routine and find it challenging to adhere to set procedures and systems',
      'Likely not to spend enough time on mastering theories, but preferring instead to dive straight into action',
      'Continuous high attention to detail is something that does not necessarily come naturally to them',
    ],
    description:
      'The ESFP tends to enjoy things that are aesthetically pleasing. They are likely to be engaging and optimistic, and encouraging of others, being particularly sensitive to their needs. Friendly and practical, co-operation and harmony is important to the ESFP, as they tend to enjoy practical activities and prefer action and buzz to keep them engaged and interested. Bringing hope and optimism, and a can-do attitude, the ESFP is likely to bring a cooperative and personal atmosphere to a situation, along with purpose and action.',
    individualTypeByLetters: 'Performer',
    individualDescription:
      'As a Performer, they tend to enjoy things that are aesthetically pleasing. They are likely to be engaging and optimistic, and encouraging of others, being particularly sensitive to their needs. Being friendly and practical, with co-operation and harmony is important to them as they tend to enjoy practical activities and prefer action and a buzz to keep them engaged and interested. Bringing hope and optimism, and a can-do attitude, they are likely to bring a cooperative and personal atmosphere to a situation along with purpose and action.',
  },
  ESTJ: {
    shortDescription: 'Guardian',
    atWorkParagraphs: {
      effectiveness: [
        "Becoming more sensitive to people's needs",
        'Looking beyond facts towards the big picture',
        'Listening more to others and give opposing views greater weight',
        'Making sure they have gathered enough information before rushing to complete',
        'Delegating more and allowing others to develop their leadership skills',
        'Understanding that the process is crucial for delivering a good product Learning how to move faster than one step at a time',
        "Being more open to alternative points of view even if they don't seem to make much sense to them at first",
        'Emphasising personal, as well as task considerations',
        'Allowing others enough space to be innovative and promote creativity',
        'Developing their sense of tact and diplomacy',
        'Being more open to experimentation',
        'Suspending critical judgement when brainstorming',
        'Increasing their tolerance of uncertainty',
      ],
      teamLeader: [
        'Be good at providing direction',
        'Be forceful in implementing plans',
        'Be good at managing behaviour with strict principles',
        'Develop detailed, result-oriented plans',
        'Be good at defining and clarifying issues',
        'Give each team member a well-defined task and responsibility',
        'Constantly challenge their team to do their best',
        'Be good at ensuring people stick to rules and policies',
        'Display high energy and commitment',
        'Be good at getting things done',
      ],
      conflicts: [
        'More likely to be irritated by team members who in their view lack focus, stray off the task and waste time',
        "May be irritated by team members who are unprepared and don't follow up on tasks",
        'More likely to be frustrated by meetings that they perceive as lacking clear objectives, agenda or organization',
        'Can be dissatisfied when working in a role that lacks clear definition and goals',
        'May clash with team-members that are often late, absent or present but not participating',
      ],
      underStress: [
        'Under stress: May become hypercritical both of themselves and others.',
        'May feel unappreciated or may hear innocent comments as personal attacks.',
        'Under great stress: ESTJs may also become angry and controlling, issuing directives without thinking about the consequence to other people',
      ],
      stressAdvisor: [
        'Under stress: May become hypercritical both of themselves and others.',
        'May feel unappreciated or may hear innocent comments as personal attacks.',
        'Under great stress: ESTJs may also become angry and controlling, issuing directives without thinking about the consequences to other people.',
      ],
      workEnvironment: [
        'Allowing for concentration on details and plans, where responsibilities are well defined',
        'Where people are frequently around, with opportunities where they can take charge',
      ],
    },
    atWorkTagLine: '---',
    atWorkSneakPeek: "One way they can enhance their work effectiveness is by becoming more sensitive to people's interpersonal needs.",
    withOthersTagLine: '---',
    withOthersSneakPeek:
      '%fname might feel the most comfortable taking up the following roles in their team: The Maker, The Organizer, or The Precisian',
    withOthersParagraphs: {
      strengths: [
        'Sociable, lively and outgoing',
        'Confident and self-assured',
        'Quick to express their own views',
        'Plain-speaking, direct, no- nonsense manner',
        'Responsible and accountable',
        'Values efficiency and effectiveness in other people',
        "Will openly critique others who don't meet their own personal standards",
        'Willing to supervise others to see the natural, practical order of things being implemented',
        'Pro-active in making new social contacts',
        'Tough-minded',
        'Comfortable in issuing directives',
      ],
      weakSpotsWithOthers: [
        'Understanding others feelings might not come naturally',
        'Might be perceived as being too blunt',
        'May come across as being too controlling. Tendency to make decisions for others without seeking consent first',
        'Too strong a desire to be certain might make it difficult to accept different perspectives and observations that oppose their own',
        'May be considered by others as autocratic',
        'Tendency to make decisions too quickly without gathering enough information',
        'Might come across as making decisions just for the sake of getting things settled',
        'Might appear to be inflexible',
        'Can miss the human factor in getting things done',
        'Might be perceived as being overly critical and impatient',
        'Might not be inclusive enough',
        'A focus on details and practicalities may mean they are regarded as lacking in imagination',
      ],
    },
    typeTagLine: '--',
    typeSneakPeek: "%fname's type is called ESTJ. Some associated keywords are decisive, logical, or managerial",
    typeKeywords: [
      '1. Decisive',
      '2. Tough-minded',
      '3. Orderly',
      '4. Precise',
      '5. Responsible',
      '6. Procedures',
      '7. Logical',
      '8. Managerial',
      '9. Organized',
      '10. Fact-based',
      '11. Analytical',
      '12. Principled',
    ],
    typePersonalStrengths: [
      'Good at analysing and bringing order to the world',
      'Good at organizing projects and people',
      'Conscientious and works energetically to complete tasks',
      'Strong focus on getting immediate, visible results in the most efficient way possible',
      'Pragmatic, realistic, hands-on approach',
      'Deals with problems and others in a direct, no- nonsense manner',
      'Strong logical thinking',
      'Strong work ethic',
      'Good at taking care of routine details',
      'Strong at planning',
      'Decisive and quick at implementing decisions',
      'Good at documenting procedures and information',
      'Excellent attention to detail and good at catching and correcting mistakes',
      'Good at improving things through small steps',
      'Likes to take charge',
      'Good keeper of traditions, loyal to the system and existing structures',
      'Dependable, taking responsibilities seriously',
      'Good at compiling things',
      'Good at enforcing rules and policies',
      'Realistic outlook, with clear focus on what needs to be done next',
      'Typically has a clear set of logical standards and systematically follows them',
      'Good at urging caution',
      'Systematic and methodical approach',
    ],
    typeWeakSpots: [
      'Low tolerance of uncertainty would rather be sure of their convictions and wrong than deal with uncertainty',
      'Reluctant to change existing structures and traditions. Might value stability over innovation',
      'Might be too focused on the immediate and neglect longer-term consequences',
      'Inflexible to changes of plan',
      'Strong focus on details might limit ability for big picture thinking',
      "Might stifle their own and other's creativity by discarding ideas lacking immediate use",
      'Might overvalue the importance of hierarchy, formal power and authority',
      "Might forget to consider others' feelings and points of view in decision-making",
      'Sometimes overly cautious',
    ],
    description:
      "The ESTJ is likely to be energetic and delivery focused. Whilst not necessarily particularly flexible, the ESTJ tends to prefer planning and purpose, and is happy to take charge of a situation. ESTJ's rely on tried and tested methods, working with factual information and systematic processes. As an extroverted type, they are happy to be the centre of attention, debating their ideas and concepts. Working better with direction and a plan to aim for, ESTJ's are able to create order out of chaos.",
    individualTypeByLetters: 'Guardian',
    individualDescription:
      'As a Guardian they are likely to be energetic and delivery focused.  Whilst not necessarily particularly flexible, they tend to prefer planning and purpose, and they are usually happy to take charge of a situation. As a Guardian, they tend to rely on tried and tested methods, working with factual information and systematic processes.  As an extroverted type, they are likely to be happy to be the centre of attention, debating their ideas and concepts. Working better with direction and a plan to aim for, they are likely to be able to create order out of chaos.',
  },
  ESTP: {
    shortDescription: 'Doer',
    atWorkParagraphs: {
      effectiveness: [
        'Building on their strength in presenting facts and taking a realistic view',
        'Relying less on improvisation (although they are very good at it) and more on preparation',
        'Taking more time before acting',
        'Using their good sense of humor, but being aware of the negative impact sarcastic remarks can have on others',
        'Seeking to avoid a crisis, even though they secretly like the rush it gives they',
        'Being willing to compromise when appropriate to get the team moving',
        'Practising their listening skills',
        'Accepting and encouraging innovation in others',
        'Giving more consideration to a colleague’s interpersonal needs',
      ],
      teamLeader: [
        'Give their team a clear direction and purpose',
        'Articulate the problem, gather views, discuss alternatives and make a practical decision that works',
        'Get their team working together in the most effective way',
        'Motivate their people into action',
        'Adapt quickly to changes in the environment and reinforce or modify their strategy',
        'Be non-judgemental towards others suggestions as long as they are logical',
      ],
      conflicts: [
        'Can be irritated by team members that they perceive as being passive and uncommitted',
        'May have a low tolerance of people who they perceive as being unfocused and unrealistic',
        "More likely to have issues with team members who don't see the logical path and get distracted by side-goals with no immediate pay off",
        'More likely to be irritated by people who complain, whine, and negate too much',
        'May be irritated by those who lack a sense of humor',
      ],
      stressAdvisor: [
        'Increasingly resist the rules and become competitive',
        'Take on too many projects and becomes spontaneous and even reckless',
        'Think before talking, as otherwise they can run the risk of coming across as blunt and uncaring',
      ],
      workEnvironment: [
        'Preferring opportunities for flexibility and ideas',
        'Disliking being constrained by too many rules and regulations',
      ],
    },
    atWorkTagLine: '---',
    atWorkSneakPeek: 'One way %fname can enhance their work effectiveness is by moving more gently into action',
    withOthersTagLine: '---',
    withOthersSneakPeek:
      '%fname might feel the most comfortable taking up the following roles in their team: The Boundary Spanner, The Maker, or The Moderator',
    withOthersParagraphs: {
      strengths: [
        'Sociable and gregarious',
        'Spontaneous and enthusiastic',
        'Open minded and tolerant',
        'Good sense of humor',
        'Valued for down-to-earth realism',
        'Enthusiastic',
        'Adventurous and fun-loving',
        'Assertive',
      ],
      weakSpotsWithOthers: [
        'Might hurt others unintentionally by expressing opinions directly',
        'Often too eager to discard systems that offer others stability',
        'Might be impatient with the interpersonal dimensions of a task',
      ],
    },
    typeTagLine: '--',
    typeSneakPeek: "%fname's type is called ESTP. Some associated keywords are logical, fact-focused, or adaptable",
    typeKeywords: [
      '1. Spontaneous',
      '2. Open minded',
      '3. Action oriented',
      '4. Logical',
      '5. Hands-on',
      '6. Improviser',
      '7. Trouble-shooter',
      '8. Adventurous',
      '9. Energetic',
      '10. Humorous',
      '11. Adaptable',
      '12. Fact-based',
    ],
    typePersonalStrengths: [
      'Good at on the spot, practical problem-solving',
      'Natural trouble-shooter and good at removing obstacles or resolving difficulties',
      'Positive attitude and an enthusiastic determination to succeed',
      'Good during crisis situations',
      'Adaptable, tolerant and flexible',
      'Strongly action-orientated and good at enthusing others into action',
      'Realistic and down-to-earth',
      'Good at analysing logical decision making',
      'Good with real things that can be worked, handled, taken apart or put together',
      'Quick to form new relationships',
      'Ready to circumvent established systems and protocol in search for solutions',
    ],
    typeWeakSpots: [
      'Tends to dislike long, theoretical explanations',
      'Gets bored by too much stability',
      'Tends to rush off into action too quickly',
      'Attending to the detailed requirements of a task',
      'Prefers to learn through hands-on experience than through private study or reflection',
    ],
    description:
      'The ESTP is likely to have a strong love of action, and enjoy a fast-paced environment. They are likely to be an enthusiastic individual, who comes across as charming and direct. The ESTP is likely to be more focused on practical activities and planning, although they enjoy the variety and challenges and the flexibilities that these bring, they find it difficult to cope with mundane repetitive tasks. The ESTP needs to be wary of trying to do too many things at once and taking too many risks, as they can end up creating chaos. They are likely to put rationality and thought before the feelings of others, preferring to deal with tangible, specific details.',
    individualTypeByLetters: 'Doer',
    individualDescription:
      'As a Doer, they are likely to have a strong love of action, and enjoy a fast-paced environment. They are likely to be an enthusiastic individual who comes across as charming and direct. They tend to be more focused on practical activities and planning, enjoying the variety and challenges and the flexibilities that these bring, but finding it difficult to cope with mundane, repetitive tasks. As a Doer, they need to be wary of trying to do too many things at once and taking too many risks as this can end up creating chaos. They are likely to put rationality and thought before the feelings of others, preferring to deal with tangible, specific details.',
  },
  INFJ: {
    shortDescription: 'Protector',
    atWorkParagraphs: {
      effectiveness: [
        'Expressing their personal thoughts, feelings, and values more freely',
        'Not sticking to an idea too stubbornly',
        'Developing their networking skills and making casual friendships',
        'Recognising the importance of small talk',
        "Making sure they don't ignore the present because they are too focused on future possibilities",
        'Learning how to sell themselves better',
        'Practicing assertiveness techniques',
        'Reminding themselves that people are more likely to implement an idea when they have been given the chance to give input',
        'Recognising that it is important not to withdraw from people in times of stress',
        "Not setting unachievable standards for one's self and others",
      ],
      teamLeader: [
        'Sees the big picture and develops vision',
        'Deals with team members individually and expects each member to accomplish assigned tasks',
        'Stimulates the team with creative and intuitive insights',
        'Compromises with the majority, unless there is a conflict with their personal values',
        'Successfully manages the interpersonal drama within groups',
        'Assesses the group dynamics and provides overviews and in-depth summaries on how to move forward',
        'Brings closure to meetings',
      ],
      conflicts: [
        "Likely to be frustrated by people perceive as hostile, impatient and pessimistic or those who don't appreciate others",
        'Likely to have issues with people who give premature and irrelevant advice, and are unwilling to look at issues in depth',
        'May be irritated by people who are very chatty',
        'May be bothered by people who fail to contribute to the work at hand',
        "Likely to conflict with people whose sole focus is on content, and who don't pay any attention to process",
      ],
      stressAdvisor: [
        'May become excessively cooperative, feeling the need to please at every opportunity',
        'Finds criticism hard to handle',
        'Feels the need to solve problems on their own',
      ],
      workEnvironment: [
        'Where there are opportunities to work on the bigger picture, be creative, with the opportunity to be flexible.',
        'Where there are less routine tasks and schedules',
      ],
    },
    atWorkTagLine: '---',
    atWorkSneakPeek: 'One way you can enhance your work effectiveness is by not sticking to ideas too stubbornly',
    withOthersTagLine: '---',
    withOthersSneakPeek:
      '%fname might feel the most comfortable taking up the following roles in a team: The Precisian, The Idea Generator, The Moderator',
    withOthersParagraphs: {
      strengths: [
        'Quiet, friendly and sociable',
        'Strongly values the people and relationships in their life',
        'Sympathetic',
        'Quietly forceful',
        'Emotionally deep',
        'Unlikely to open up to people until they know them well',
        'Sensitive to the needs of others',
        'Enjoys harmony between people',
        'Caring and considerate',
        'Firm principles',
      ],
      weakSpotsWithOthers: [
        'Hypersensitive to conflict',
        'Needs encouragement and recognition',
        'Needs time for interpersonal pleasantries',
        'Might see criticism where none was intended',
        'Might be overly cautious and fail to act spontaneously',
      ],
    },
    typeTagLine: '--',
    typeSneakPeek: "%fname's type is called INFJ. Some associated keywords are persisting, caring, friendly",
    typeKeywords: [
      '1. Principled',
      '2. Creative',
      '3. Thoughtful',
      '4. Organized',
      '5. Persistent',
      '6. Conscientious',
      '7. Sympathetic',
      '8. Friendly',
      '9. Caring',
      '10. Responsible',
      '11. Harmonious',
    ],
    typePersonalStrengths: [
      'Good at looking at problems from a global perspective',
      'Good at providing credibility through ideas and insights',
      'Good at inspiring others through a positive, humorous, can-do approach',
      'Good at conceptual and abstract thinking',
      'Often good at creative expression',
      'A conscientious approach to work',
      'Structured and methodical',
      'Has a strong attention to detail',
      'Puts their best efforts into their work',
      'Persistently tries to achieve success',
      'Good at setting goals',
      'Gets things done and will do whatever is needed or wanted',
      'Decisive in implementing their vision',
      'A strong interest to serve the common good',
      'Good at promoting change through relationships',
      'Good at teaching and helping people understand',
      'A strong sense of duty and responsibility',
      'Realises the importance of sticking to the rules',
      'Completes tasks on schedule and to a high standard',
      'Good at understanding theoretical principles that underlie problems',
    ],
    typeWeakSpots: [
      'Embracing or being more open in social settings',
      'Being more selective about what the degree to which decisions need thorough thinking through versus taking action sooner',
      'Being less avert to conflict and less focused on achieving consensus',
      'Getting more comfortable with communicating to larger groups',
      'Embracing the discomfort of taking difficult, unpopular decisions',
    ],
    description:
      "The INFJ puts value on compassion and cooperation, tending to be good problem solvers due to their innovation and passion for keeping things organized and completed. As an introverted type, the INFJ tends to be sensitive and reserved, and likely to keep their feelings to themselves. However, they show a great deal of compassion for other people's welfare, and are likely to have a deeply caring nature. Combined with the INFJ's desire to keep things organized, this means that they are likely to be able to complete tasks without losing their personable and delicate approach.  Being able to feel that they are improving things is extremely important to the INFJ.",
    individualTypeByLetters: 'Protector',
    individualDescription:
      "As a Protector, %fname puts value on compassion and cooperation. %fname is likely to be a good problem solver, due to their innovation and passion for keeping things organized and getting things done. As an introverted type, %fname tends to be sensitive and reserved, and tends to keep their feelings to oneself. However, %fname shows a great deal of compassion for other people's welfare, and is likely to have a deeply caring nature. Combined with their desire to keep things organized, they are likely to be able to complete tasks without losing their personable and delicate approach. Being able to feel like they are improving things is extremely important to %fname.",
  },
  INFP: {
    shortDescription: 'Idealist',
    atWorkParagraphs: {
      effectiveness: [
        'Learning and practising how to be assertive',
        'Not withdrawing from people when things get stressful',
        'Improving their time-management and prioritising skills',
        'Realising others may value quick results, even if those results are not yet ideal',
        'Developing a healthy respect for details and practicalities',
        'Recognising the value of casual friendships and networking',
        'Not overcomplicating or over-intellectualising problems',
        "Realising that they don't have to please everyone",
        'Planning ahead',
        'Showing more decisiveness',
        'Exciting others with their ideas on the Big Picture possibilities',
      ],
      teamLeader: [
        'Provides inspirational motivation',
        'Focuses on common ideals and underlying issues',
        'Promotes team spirit and group effort by creating a positive atmosphere',
        'Looks for group consensus to facilitate goals',
        "Has a strong awareness of peoples' strengths and weaknesses",
        'Pushes people to reach their potential',
        'Challenges and stimulates people',
        'Encourages their teams to look at new possibilities',
        "Maintains a high level of morale and a good sense of cooperation because of their ability to 'read' others",
      ],
      conflicts: [
        'Might have issues with people who take themselves too seriously',
        'May be irritated by people who require too much detail or whose technical logic gets in the way of understanding',
        "May be irritated by people who won't admit when they don't understand an idea",
        "May be irritated by people who won't consider all opinions",
      ],
      stressAdvisor: [
        'Has an increasing need for perfectionism',
        'Becomes blinkered to only seeing what they think is important',
        'Loses realism and simplicity when completing a task',
      ],
      workEnvironment: ['Is future-oriented', 'Reflects their own values', 'Will allow for working in bursts of energy'],
      underStress: [],
      underSevereStress: [],
    },
    atWorkTagLine: 'Highly collaborative',
    atWorkSneakPeek: 'One way they can enhance their work effectiveness is by learning and practising how to be assertive',
    withOthersTagLine: '',
    withOthersSneakPeek: '',
    withOthersParagraphs: {
      strengths: [
        'Friendly',
        'Tolerant',
        'Collaborative',
        'Supportive',
        'Sympathetic',
        'Mysterious',
        'Generous with positive feedback',
        'Considerate, thoughtful and caring',
        'Very loyal to the people you care about',
        'Accepting',
        'Genuine concern',
      ],
      weakSpotsWithOthers: [
        'Requires their fair share of encouragement and recognition',
        'Prefers relationships to be harmonic and dislike conflict',
        'When they get too absorbed in what they are doing they become unsociable',
        'Tends to take on too much at once',
        'Tends not to deal well with people who threaten their personal values',
        "Overly sensitive to others' feelings and the team’s mood",
      ],
    },
    typeTagLine: 'open-minded, free-thinking, intellectually curious',
    typeSneakPeek: 'Their Type is called INFP. They have a tendency not to deal well with people that threaten their personal values',
    typeKeywords: [
      '1. Open-minded ',
      '2. Free-thinking',
      '3. Intellectually curious',
      '4. Eager to learn',
      '5. Imaginative',
      '6. Optimistic',
      '7. Value-driven',
      '8. Self-developing',
      '9. Explorer',
      '10. Supportive',
      '11. Thoughtful',
      "12. 'Big Picture'",
    ],
    typePersonalStrengths: [
      'Full of enthusiasm',
      'Flexible and adaptable',
      'An independent working style',
      'Responsible',
      'Highly collaborative',
      'A strong team spirit',
      'Ready to contribute for the greater good',
      'Insightful and creative',
      'Cares deeply about anything they are involved in',
      'Quick to see possibilities',
      'A catalyst for turning ideas into reality',
      'Able to get many things done close to the deadline',
      'Good at promoting change',
      'Avid contributor to team unity and harmony',
    ],
    typeWeakSpots: [
      'Not seeking perfectionism or not being too idealistic',
      'Being more grounded',
      'Softening up on personal values when they are not held by others',
    ],
    description:
      'The INFP is likely to be warm and compassionate, and they are driven by causes that are close to their heart, fighting for what they believe in.  Working with personal values is likely to be is extremely important for the INFP, and when not driven by an ideal or in search of a deeper meaning, they tend to procrastinate and dream.  The INFP is likely to be curious and open minded, with a flexible approach, enjoying working within a team so that they can help to introduce new ideas and help others develop and grow.  Once recruited to a cause that they believe in, the INFP is likely to be extremely loyal and committed.',
    individualTypeByLetters: 'Idealist',
    individualDescription:
      'As an Idealist, %fname is likely to be a warm and compassionate individual, driven by causes that are close to their heart, and fighting for what they believe in. Working with personal values is likely to be extremely important to %fname. When not driven by an ideal or in search of a deeper meaning, %fname tends to procrastinate and dream.  %fname is likely to be curious and open minded, with a flexible approach, and enjoys working within a team so that they can help introduce new ideas and help others develop and grow.  Once recruited to a cause they believe in, %fname is likely to be extremely loyal and committed.',
  },
  INTJ: {
    shortDescription: 'Scientist',
    atWorkParagraphs: {
      effectiveness: [
        'Accepting networking as a necessary part of their professional life, including actively making acquaintances and occasional small talk',
        'Being diplomatic in the way they phrase their feedback, so others will not become too defensive',
        'Not trying to do everything themselves (even if they feel they might do a better job) but delegating more to others',
        'Paying attention to interpersonal needs- including their own',
        'Making sure they give enough space to others to provide input into the process',
        "Communicating clearly that they appreciate others' contributions",
        'Not placing too high a value on having all the information before taking a decision but accept some decisions are better taken on the spur of the moment.',
      ],
      teamLeader: [
        'Bonds the team together with strong ideas and convictions',
        'Helps the team to clearly define what it wants to achieve',
        'Makes sure that the team achieves its goal and stays on its course of action',
        'Motivates the team by setting high standards',
        'Provides the team with an efficient and structured work environment',
        'Mediates any conflicts in an objective, logical manner',
        'Keeps a close eye on the Big Picture and takes charge of changing their strategy if required',
      ],
      teamLeaderFlipSide: [
        'Might be perceived as being too argumentative, too task focused or too controlling',
        'Might also come across as being too reserved and detached',
      ],
      conflicts: [
        'Might have issues with people who leave some of the agreed-upon tasks uncompleted',
        "Might be irritated by people who seem not to pay enough attention or who don't give enough weight to their questions or proposals",
        'Might be irritated by people who are not quick enough when following their ideas and proposals',
        'Likely to be frustrated by people who tend to focus on unimportant details or demand instant answers',
      ],
      workEnvironment: [
        'Offers independence',
        'Places a high value on efficiency',
        'Is structured and task-focused',
        'Is not routine, but provides opportunity to change and redesign things',
        'Creates intellectual stimulation',
      ],
      stressAdvisor: [
        'May become increasingly inflexible, and progressively critical',
        'May be rigid in their opinions and viewpoints, find it difficult to accept if they are wrong',
        'May become impatient and seek out errors even if there are none',
      ],
    },
    atWorkTagLine: '---',
    atWorkSneakPeek: 'One way they can enhance their work effectiveness is by not trying to do everything themselves',
    withOthersTagLine: '---',
    withOthersSneakPeek:
      'They might feel the most comfortable taking up the following roles in their team: The Precisian, The Idea Generator, The Critic',
    withOthersParagraphs: {
      strengths: [
        'Independent',
        'Sceptical',
        'Critical',
        'High standards of competence and performance for themselves and others',
        'Quiet',
        'Observant',
        'Determined',
        'Principled',
        'Argumentative',
        'Reliable',
        'Thoughtful',
        'Logical',
      ],
      weakSpotsWithOthers: [
        'Can sometimes be too introspective',
        "Understanding others' feelings might not always come naturally",
        'As someone likely perceived as calm, when they express their opinions strongly colleagues might be surprised',
        'Can be overly stubborn sometimes',
      ],
    },
    typeTagLine: '--',
    typeSneakPeek: 'Their Type is called INTJ. Others may describe them as persisting, caring, or friendly',
    typeKeywords: [
      '1. Logical',
      '2. Competent',
      '3. Dutiful',
      '4. Goal-oriented',
      '5. Driven',
      '6. Ideas-person',
      '7. Methodical',
      '8. Organized',
      '9. Reliable',
      '10. Individualistic',
      '11. Principled',
    ],
    typePersonalStrengths: [
      'Good at giving advice',
      'Gets things done',
      'Goal-oriented',
      'Delivers results as projected',
      'Takes a long-term perspective and solves problems with long-term fixes',
      'Strong sense of duty and responsibility',
      'Strong in systematic, structured thinking',
      'Strong at objective, logic analysis',
      'Able to learn by themselves',
      'Methodical and organized approach to solving problems',
      'Setting high standards for themselves and others',
      'Able to make tough decisions',
      'Original',
      'Able to see things through',
      'Good at developing new theories, models, ideas or systems',
      'Good at completing tasks to a high standard, on schedule',
      'Good at adhering to established methods and procedures',
      'Detail-conscious',
      'Working with theoretical concepts and systems',
      'Reliable',
      'Non-conformist',
      'Intellectually curious',
    ],
    typeWeakSpots: [
      'Might irritate others by being too narrowly focused in the pursuit of a task or objective',
      'Prefers to be the one who is responsible, sometimes taking on a heavier burden than is sustainable',
    ],
    description:
      'The INTJ is likely to be quick and imaginative with a strategic mind. They are likely to enjoy spending their time thinking and analysing information in a careful, considered manner.  System and strategy are likely to be important to the INTJ, with organization and planning a key part of helping them understand how things work.  Abstract ideas and logic problems appeal to the INTJ and they will spend time considering and questioning these concepts.  Focusing on the tangible and rational, the INTJ has vision and innovation and is able to work towards the outcomes. The INTJ does not tend to share their emotions with others readily.',
    individualTypeByLetters: 'Scientist',
    individualDescription:
      'As a Scientist, %fname is likely to be quick and imaginative with a strategic mind. %fname is likely to enjoy spending time thinking and analysing information in a careful, considered manner. System and strategy are likely to be important to them, and organization and planning a key part of helping them understand how things work.  Abstract ideas and logic problems appeal to %fname, who is likely to spend time considering and questioning these concepts.  Focusing on the tangible and rational, %fname is likely to have vision and innovation and the ability to work towards the outcomes. %fname does not tend to share emotions with others readily.',
  },
  INTP: {
    shortDescription: 'Thinker',
    atWorkParagraphs: {
      effectiveness: [
        'Making a conscious effort to reach out to people more often',
        'Recognising the value of engaging in small talk',
        'Using more plain speaking',
        'Reducing things to a logical hypothesis',
        "Considering peoples' feelings and emotions more often in the analysis of issues and in task considerations",
        'Learning time management techniques',
        'Focusing on practicalities',
      ],
      teamLeader: [
        'Uses logic and reason to steer team members into the right direction',
        'Puts frameworks in place that enables all team members to use their skills',
        'Is seen as a source of knowledge and competence, acting as an active resource to others',
        'Sets the agenda and keeps others focused on that agenda',
        'Provides options so that decisions can reflect different opinions, and in turn results in the best and most acceptable solution',
        'Excels at summarising and generalising',
        'Motivates their team by setting high standards',
      ],
      conflicts: [
        'Likely to be irritated by team members who are unfocused and non-logical in their approach',
        'Likely to find team members who argue out of ignorance challenging',
        'Likely to be frustrated by team members who in their view take objective comments personally, and are too easily hurt or offended by what is logical',
        'Likely to have difficulties when working with people who lack vision and investment in the project',
        'May have a low tolerance for people who talk a lot and tend to focus on non-essential issues to the detriment of the larger mission',
      ],
      stressAdvisor: [
        'May become sarcastic and impatient',
        'May increasingly over analyse things, and have endless debates',
        'Can be rigid in their viewpoints. May become increasingly independent and negative',
      ],
      workEnvironment: [
        'An environment that encourages ideas and creativity',
        'Where there are less interruptions and involves minimal supervision',
      ],
    },
    atWorkTagLine: '---',
    atWorkSneakPeek:
      'One way %fname can enhance their work effectiveness is by making a conscious effort to reach out to people more often.',
    withOthersTagLine: '---',
    withOthersSneakPeek:
      '%fname might feel the most comfortable taking up the following roles in their team: The Idea Generator, The Critic, The Organizer',
    withOthersParagraphs: {
      strengths: [
        'Enjoys challenging themselves and others',
        'Prefers talking about ideas to small talk',
        'Open-minded, free-thinking and tolerant',
        'Values logic and rational thought',
        'Can be enthusiastic',
        'Friendly and insightful',
        'Thoughtful',
        'Prefers to communicate in writing',
        'Likely to be viewed as a deep thinker',
      ],
      weakSpotsWithOthers: [
        'May at first appear quiet and reserved',
        'May come across as too argumentative',
        'May appear as cool and closed',
        'May be perceived as being impractical, disorganized and absent-minded',
      ],
    },
    typeTagLine: '--',
    typeSneakPeek: 'Their Type is called INTP Some associated keywords are Visionary, Competitive, Analytical',
    typeKeywords: [
      '1. Visionary',
      '2. Competence',
      '3. Curiosity',
      '4. Expert',
      '5. Knowledgeable',
      '6. Logical',
      '7. Enthusiastic',
      '8. Competitive',
      '9. Thoughtful',
      '10. Flexible',
      '11. Analytical',
      '12. Big picture person',
    ],
    typePersonalStrengths: [
      'Good at applying knowledge',
      'Good at solving problems with long-term fixes',
      'A high achiever',
      'Likely to become an expert in a specific area',
      'Often has an unusual ability to solve complex problems in their area of interest',
      'Good at perceiving patterns, relationships and seeing the Big Picture',
      'Strong in developing new theories, models, ideas or systems',
      'Good at providing both critical vision and intellectual insight',
      'Strong conceptual problem-solving abilities',
      'Good at gathering data in an impartial manner',
      'Highly analytical',
      'Creative and imaginative',
      'Enjoys questioning and critiquing the order of things',
      'Flexible and adaptable',
      'Enjoys opportunities to be creative',
      'Enjoys complexity',
      'Independent',
      'Architect of change',
      'System designer',
    ],
    typeWeakSpots: [
      'Showing less scepticism and criticism',
      'Communicating more and more open or friendly',
      'Explore other interests. Being in a career which enables them to put their interests to good use',
      'Accepting more people who are not as competent',
      'Conforming more in some situations',
      'May sometimes encounter self-doubt',
      'Might prefer to work on their own',
      'Good at finding creative, novel solutions, but less enthusiastic when responsible for implementation',
    ],
    description:
      'The INTP is likely to enjoy thinking and problem solving due to their tendency to have a good eye for detail, and aptitude for understanding abstract ideas and logical problems.  The INTP is likely to be quiet and thoughtful, preferring to keep their feelings to themselves. As an objective and realistic individual they tend to enjoy debates with others, which allows them to question and find the truth, however they can sometimes come across as being vague or can overlook the needs of others if they are too caught up in what they see as important.',
    individualTypeByLetters: 'Thinker',
    individualDescription:
      'As a Thinker, %fname is likely to enjoy thinking and problem solving due to a tendency to have a good eye for detail, and aptitude for understanding abstract ideas and logical problems.  %fname is likely to be quiet and thoughtful, preferring to keep their feelings to themselves.  As an objective and realistic individual %fname tends to enjoy debates with others, which allows them to question and find the truth. However %fname can sometimes come across as being vague or can overlook the needs of others if too caught up in what %fname sees as important.',
  },
  ISFJ: {
    shortDescription: 'Nurturer',
    atWorkParagraphs: {
      effectiveness: [
        'Not being too sensitive and concerned with harmony',
        "Praising their own as well as other people's skills and contributions more often, rather than downplaying them",
        'Being ready to deliver a tough message when necessary',
        "Not taking other peoples' remarks too seriously or personally, but letting them slide by more often",
        'Sticking with their own opinion if they are convinced they are right, rather than going along with the majority for the sake of team harmony',
      ],
      teamLeader: [
        'Works hard and is always prepared',
        'Tendency to be good at listening to all ideas, bringing everybody together and gaining overall support for the solution',
        'Gets others to work together within the system and stick to the rules they set',
        'Encourages others to develop their own leadership skills whenever possible',
        'Insists on precision and exactness',
      ],
      conflicts: [
        "Likely to be irritated by team members who in their view talk too much, interrupt or won't listen to others",
        'Likely to be bothered by team members who put down other people and their ideas',
        'May be irritated by people who in their view spend too much time on personal agendas and consequently neglect team tasks and meeting objectives',
        'May have conflict with people who in their view lack common sense',
        'Will probably struggle with people who in their view are not willing to collaborate or share pertinent information',
      ],
      stressAdvisor: [
        'Finds it really difficult to say no, and becomes increasingly pessimistic if things are not going their way',
        'Becomes increasingly anxious, and consequently becomes more rigid in their thinking, in order to try to control the situation',
        'Can feel discouraged if they are not made to feel appreciated',
      ],
      workEnvironment: [
        'A harmonious environment is preferred, in order to avoid conflict',
        'Preference for a structured organization with defined roles and tasks',
      ],
    },
    atWorkTagLine: '---',
    atWorkSneakPeek: 'One way %fname can enhance their work effectiveness is by being ready to deliver a tough message when necessary.',
    withOthersTagLine: '---',
    withOthersSneakPeek:
      '%fname might feel most comfortable taking up the following roles in a team: The Precisian, The Organizer, The Critic',
    withOthersParagraphs: {
      strengths: [
        'Friendly and responsible',
        'Considerate and caring, and always there when others need them',
        'Quiet warmth and gentle humor',
        'Remembers details about people who are important to them',
        'Good at smoothing conflict',
        'Loyal, considerate and perceptive',
        'Concerned with how other people feel',
        'Quietly thoughtful, empathic and sensitive',
        'Like to be precise in conversations, and expect the same from others',
      ],
      weakSpotsWithOthers: [
        'High desire for stability',
        'Might be overly sensitive',
        'Might focus too much on here and now',
        'Strong dislike of conflict, therefore a keen preference for harmonious relationships',
        'Sensitive to the feelings of others and the mood of the team',
      ],
    },
    typeTagLine: '--',
    typeSneakPeek: "%fname's Type is called ISFJ. Some associated keywords are Practical, Orderly, Precise",
    typeKeywords: [
      '1. Practical',
      '2. Systematic',
      '3. Reliable',
      '4. Realistic',
      '5. Caring',
      '6. Sensitive',
      '7. Detailed',
      '8. Orderly',
      '9. Decisive',
      '10. Friendly',
      '11. Stable',
      '12. Precise',
    ],
    typePersonalStrengths: [
      'Ready to do what needs to be done then and there',
      'Practical with good common sense',
      'Good at completing tasks to a high standard',
      'Takes work seriously and has a strong sense of responsibility',
      'Realistic observer of the environment',
      'Prefers learning by doing',
      'Strong attention to detail',
      'A good memory and strong command of facts',
      'Patient to gather necessary details',
      'Good organization skills',
      'Gives stability to any project or group',
      'A systematic, thorough approach',
      'Decisive',
      'Highly loyal when made to feel like they belong',
      'Good at creating order',
      'Good at providing background information responsively and conscientiously',
    ],
    typeWeakSpots: [
      'Trying to be more open or friendly',
      'Being more embracing of conflict and not trying to always seek harmony',
      'Be less authority-dependent',
      'Embrace more change',
      'Accept that they are sometimes uncomfortable with disarray and uncertainty',
    ],
    description:
      'The ISFJ is likely to be a caring and compassionate individual who seeks to feel useful and helpful. They are dedicated and patient; someone who will not give up at the first hurdle. However, they are likely to be quite shy and a quiet individual. The ISFJ thinks of others before themselves and gives friendly, practical support, preferring a more personal approach. They like to take care of the practical day to day details, and avoid drawing attention to themselves.',
    individualTypeByLetters: 'Nurturer',
    individualDescription:
      'As a Nurturer, %fname is likely to be considered a caring and compassionate individual. %fname likes to feel useful and helpful, and is likely to be dedicated and patient; someone who will not give up at the first hurdle.  %fname is likely to be quite shy and a quiet individual, thinking of others before themselves, and giving friendly, practical support, preferring a more personal approach.  %fname is likely to take care of the practical day to day details and avoid drawing attention to themselves.',
  },
  ISFP: {
    shortDescription: 'Artist',
    atWorkParagraphs: {
      effectiveness: [
        'Not being too modest and taking credit when credit is due',
        'Not downplaying their own skills and contributions',
        "Not taking other people's comments so seriously, recognising that some people don't think before they speak",
        'Continuing to be pleasant, while learning how to show some teeth when necessary',
        'Not being so concerned with harmony',
        'Learning how to deliver a tough message when required',
        'Not being so self-critical',
        'Learning time-management techniques',
        "Learning how to say 'No'",
        'Practising giving negative feedback',
      ],
      teamLeader: [
        'Inspires others to follow their code of working hard and always being prepared',
        'Carefully listens to all ideas and concerns, and then gains overall support for a solution with gentle but effective persuasion',
        'Shows a sincere interest in other points of view',
        'Allows others a high degree of freedom to set their own targets',
        'Only moves forward with decisions if consensus has been reached',
      ],
      conflicts: [
        'Likely to be bothered by people who talk too much',
        'Likely to clash with people who put down other people and their ideas',
        'May be irritated by people who lack common sense',
        'May be upset by people who hold the team back by not sharing pertinent information',
        "Doesn't appreciate people who are selfish and don't want to cooperate",
      ],
      stressAdvisor: [
        'May become increasingly disorganized, having difficulty with time management and letting others take control to avoid responsibility',
        'May take criticism personally, even if it is just a case of differing opinions',
        'Takes time to come to a decision, changing their mind frequently',
      ],
      workEnvironment: [
        'A flexible environment with little supervision',
        'Prefers a supportive and meaningful setting in order to feel at home',
      ],
    },
    atWorkTagLine: '---',
    atWorkSneakPeek: 'One way %fname can enhance their work effectiveness is by not downplaying their own skills and contributions.',
    withOthersTagLine: '---',
    withOthersSneakPeek:
      '%fname might feel most comfortable taking up the following roles in a team: The Moderator, The Organizer, The Precisian',
    withOthersParagraphs: {
      strengths: [
        'Quietly friendly, warm and supportive',
        'Loyal and committed to the people who are important',
        'Sensitive',
        'Kind',
        'Open-minded',
        'Exhibits gentle humor',
        'Not judgemental or opinionated',
        'Thoughtful',
        'Not likely to needlessly criticise others',
        'Does not force their opinions or values on others',
      ],
      weakSpotsWithOthers: [
        'Might take a while to open up to new people',
        'Shuns disagreements',
        'Impulsive',
        'Not very outspoken or assertive',
        'Might withdraw from people in a situation of stress',
      ],
    },
    typeTagLine: '--',
    typeSneakPeek: "%fname's type is called ISFP. Some associated keywords are Practical, Considerate, Flexible",
    typeKeywords: [
      '1. Practical',
      '2. Open-minded',
      '3. Independent',
      '4. Gentle',
      '5. Positive',
      '6. Considerate',
      '7. Relaxed',
      '8. Hands-on',
      '9. Flexible',
      '10. Realistic',
      '11. Loyal',
    ],
    typePersonalStrengths: [
      'Prefers action over talking',
      'Concerned with finding practical, concrete solutions to real-world problems',
      "Tolerant, open-minded and accepting of others' points of view",
      'Able to work independently and within their own time frame',
      "Flexible and adaptable, doesn't cling to outmoded ideas",
      'Loyal and committed',
      'Good as getting others to work together within the system',
      'Good at providing background information responsively and conscientiously',
      'Enjoys hands-on experience',
      'Realistic',
      'Good at responding to a crisis and troubleshooting',
      'Considerate and good at anticipating the impact of actions on other people',
      'Independent minded',
      'Positive attitude',
      'Good at being inclusive and smoothing conflict',
      'Prefers to work in an egalitarian manner',
      'Holds strong values and ideals',
      'Good at one-to-one communication',
      'Good at seeing the broader picture',
      'Ready to circumvent the rules when necessary',
      'Good at insightful observation',
    ],
    typeWeakSpots: [
      'Have more urgency about getting things done',
      'Be more open, or share thoughts',
      'Appreciate more situations of disagreement and conflicts. Try not to always seek compromise and consensus',
      'Appreciate routine and regulations, and less freedom or space',
      'Be less modest about their abilities',
      'Take the lead more',
      'Pay more attention to detail',
    ],
    description:
      'The ISFP is an easy-going, quiet and friendly individual. They are co-operative, preferring a supporting role to promote team harmony.  Action oriented, they prefer hands on activities in order to put their creativity to good use, however they are likely to avoid organization and planning activities, preferring a more flexible and spontaneous approach and shying away from future projections.  Good listeners, tolerant and sensitive to the needs of others, they will give practical advice and support where possible.',
    individualTypeByLetters: 'Artist',
    individualDescription:
      'As an Artist, %fname is likely to be an easy-going, quiet and friendly individual. %fname tends to be co-operative, enjoying a supporting role in order to promote team harmony.  %fname is more action oriented, tending to prefer hands on activities to put their creativity to good use. However, they are likely to avoid organization and planning activities, preferring a more flexible and spontaneous approach, shying away from making future plans.  %fname is likely a good listener, tolerant and sensitive to the needs of others, comfortable giving practical advice and support whenever possible.',
  },
  ISTJ: {
    shortDescription: 'Inspector',
    atWorkParagraphs: {
      effectiveness: [
        'Making sure they keep people informed and up-to-date',
        'Developing their networking skills',
        'Trying to create enjoyable moments during the work day',
        'Recognising the value of small talk',
        'Asking for feedback more often',
        'Encouraging and appreciating creativity',
        'Developing interpersonal communication skills',
        'Reminding themselves not to become too absorbed by individual details losing the overall meaning of the task',
        'Looking beyond established systems',
        'Delegating more and micro-managing less',
        'Engaging in conversations at the beginning of a process, and offering their viewpoints earlier on rather than waiting until it is too late.',
        'Suspending critical judgement during brainstorming',
      ],
      teamLeader: [
        'Instils trust in their team with logical arguments, detailed knowledge of their subject matter and a realistic outlook',
        'Is open to facts and logical arguments',
        'Organizes and maintains an effective structure without having to say too much',
        'Runs very efficient, focused meetings',
        'Is clear with what is expected, what tasks need completion and to what standard',
      ],
      conflicts: [
        'Likely to be bothered by team members whom they perceive as being too talkative and chatty',
        'May be irritated by people they perceive as being unreliable and lacking follow-through',
        'Likely to have a low tolerance for people who want to discuss interpersonal or unrelated issues at work',
      ],
      stressAdvisor: [
        'May become particularly focused and inflexible, requesting that rules and structures are followed',
        'May find it difficult to accept that they could be wrong, and also not giving enough praise to others when and where it is deserved',
        'May become pessimistic and overly fussy when it comes to the details',
      ],
      workEnvironment: [
        'Prefers work with clear goals and procedures in place',
        'Prefers to work on one task or project at a time, with minimal supervision',
      ],
    },
    atWorkTagLine: '---',
    atWorkSneakPeek: 'One way %fname can enhance their work effectiveness is by recognising the value of small talk',
    withOthersTagLine: '---',
    withOthersSneakPeek:
      '%fname might feel the most comfortable taking up the following roles in a team: The Critic, The Precisian, The Organizer',
    withOthersParagraphs: {
      strengths: [
        'Values traditions and loyalty',
        'A sense of duty',
        'Down-to-earth',
        'Cool, and independent',
        'Reliable and dependable',
        'Ready to be helpful',
        'Steadfast in opinions',
        'Prefers to communicate in writing rather than have lengthy discussions',
        'Treats people with respect',
      ],
      weakSpotsWithOthers: [
        'Might appear to be reserved and unemotional',
        'Not personable, preferring to get things done than to engage in interpersonal pleasantries',
        'Difficult to connect and relate emotionally',
        'Might be perceived as being unimaginative and rigid',
        'Slow to adapt and react to change',
      ],
    },
    typeTagLine: '--',
    typeSneakPeek: "%fname's type is called ISTJ. Some associated keywords are stable, objective, decisive",
    typeKeywords: [
      '1. Precise',
      '2. Decisive',
      '3. Rational',
      '4. Responsible',
      '5. Respectful',
      '6. Stable',
      '7. Detailed',
      '8. Dependable',
      '9. Methodical',
      '10. Friendly',
      '11. Informed',
      '12. Objective',
    ],
    typePersonalStrengths: [
      'Orderly and organized, both at home and at work',
      'Responsible approach to work',
      'Committed to get things done',
      'Completes tasks thoroughly',
      'A practical, realistic outlook',
      'Good at solving problems with common sense',
      'Works steadily towards achieving goals, regardless of distractions',
      'Decisive',
      'Objective and rational',
      'Strong analytical skills',
      'Committed to people and the organization they are part of',
      'Disciplined and enjoys developing and following a routine',
      'Precise and excellent command of facts',
      'Excellent attention to detail',
      'Ready to work independently',
      'Values adhering to established process and procedures',
      'Good at enforcing rules and policies',
      'Good at documenting procedures and information',
      'Likes to improve things through small steps',
      'High quality standards. Good at identifying and correcting mistakes',
      'Good at bringing high accountability to any team',
      'Hard working, and inspires others to work hard to get the task done on time',
    ],
    typeWeakSpots: ['Appreciating or accepting more instability and disorder', 'Accept more change and innovation', 'Be less rule-bound'],
    description:
      'The ISTJ is a systematic individual, who is particularly loyal and dependable.  They take pride in the work that they do, and tend to be comfortable with routine tasks.  ISTJs are serious and thoughtful people, who enjoy creating and enforcing order.  They are likely to be precise, systematic and responsible, enjoying dealing with the facts. Responsibilities are welcomed, and the ISTJ will take charge of the task at hand, sticking to deadlines and having little tolerance for indecisiveness and laziness.  Duty is important to the ISTJ and once they have committed themselves to a project, they will follow through on actions, ensuring that decisions are based in fact and logic.',
    individualTypeByLetters: 'Inspector',
    individualDescription:
      'As an Inspector, %fname is likely to be a systematic individual, who is particularly loyal and dependable. %fname takes pride in the work that they do, and tends to be comfortable with routine tasks. %fname may be seen as a serious and thoughtful person, who enjoys creating and enforcing order; likely to be precise, systematic, responsible, and enjoy dealing with the facts.  %fname probably welcome responsibilities and takes charge of the task at hand, adhering to deadlines and having little tolerance for indecisiveness and laziness.  Duty is likely to be important to them and once they have committed themselves to a project, they tend to follow through on actions, ensuring that decisions are based on fact and logic.',
  },
  ISTP: {
    shortDescription: 'Mechanic',
    atWorkParagraphs: {
      effectiveness: [
        'Taking care not to work on too many tasks simultaneously.  Complete key tasks before moving on to something new',
        'Being less picky about specific details',
        'Being careful not to give others the impression they are indifferent',
        'Focusing their energy on solving one crisis at a time',
        'Establishing a tight structure that limits options of operation',
        'Spending more time preparing in advance',
        'Preparing an agenda for meetings',
        'Practising their networking skills',
        'Considering colleagues interpersonal needs more often',
        'Attending more closely to details',
        'Recognising the value of small talk',
      ],
      teamLeader: [
        'Takes a pragmatic approach to leadership',
        'Leads the team with a quiet, indirect authority',
        'Evaluates all views, but with the clear intention of moving into action',
        'Encourages the team to persevere',
        'Handles crises and change very well',
        'Knows where they can get the required information',
        'Solves problems by applying logic and looking at the facts first',
      ],
      conflicts: [
        'Likely to find it difficult working with team members whom they perceive as being overly emotional instead of being objective',
        "Likely to struggle with people whom they perceive as wasting precious energy on tasks outside the team's scope",
        'May be irritated by team members who frequently hold informational meetings where the practical purpose is not clear',
      ],
      stressAdvisor: [
        'Can be prone to rash, emotional outbursts if under stress and pressure',
        'May find it difficult to follow through and complete a task',
        'Overly concerned only with the current details, failing to consider future possibilities',
      ],
      workEnvironment: [
        'Seeks flexibility to routine and requires little supervision as likes to act spontaneously',
        'Enjoys playing with ideas and coming up with novel ways of solving things',
      ],
    },
    atWorkTagLine: '---',
    atWorkSneakPeek: 'One way %fname can enhance their work effectiveness is by being less focused on the specifics',
    withOthersTagLine: '---',
    withOthersSneakPeek:
      '%fname might feel most comfortable taking up the following roles in their team: The Critic, The Precisian, The Organizer',
    withOthersParagraphs: {
      strengths: [
        'Tolerant and open-minded',
        'Unexpected flashes of original humor',
        'Tends to analyse life with curiosity',
        'Enjoys living life in the moment',
        'Action oriented and spontaneous',
        'Fun loving',
        'Deep',
        'Thoughtful',
        'Reflective',
        'Self-sufficient',
        'Might prefer to communicate in writing',
        'Fair-minded and objective',
      ],
      weakSpotsWithOthers: [
        'Might be perceived as being too impulsive',
        'Might feel restricted too easily',
        'Might sit back initially before being ready to contribute',
        'More emotional people will have difficulty with their very rational approach',
        'Might be perceived as being reserved',
      ],
    },
    typeTagLine: '--',
    typeSneakPeek: "%fname's type is called ISTP. Some associated keywords are objective, logical, or tolerant",
    typeKeywords: [
      '1. Down-to-earth',
      '2. Logical',
      '3. Analytical',
      '4. Factual',
      '5. Adaptable',
      '6. Pragmatic',
      '7. Tolerant',
      '8. Independent',
      '9. Objective',
      '10. Fair-minded',
      '11. Problem-solver',
    ],
    typePersonalStrengths: [
      'Good at understanding how things work',
      'Good at moving the team into action',
      'Strong focus on practical solutions',
      'Natural trouble-shooter: quick at finding workable solutions to problems',
      'Good at using analytical skills to organize work efficiently',
      'Flexible and adaptable',
      'Good with organizing facts and using logical principles',
      'Pragmatic, realistic, hands-on approach',
      'Strong logical thinking',
      'Good at planning',
      'Ready for excitement and adventure',
      'Good at responding to crises',
      'Values logic and rational thought',
      'Ready to take action',
      'Responsive and persevering',
      'Good at working independently',
      "Ready to break the rules if they don't make sense",
      'Solution focused realist',
      'Comfortable when working with facts and hard data',
    ],
    typeWeakSpots: [
      'Try not to stand back and observe',
      'Trying to appear less detached',
      'Not ignoring routines in pursuit of variety and excitement',
      'Having more interest in abstract theories',
    ],
    description:
      'The ISTP is likely to be drawn to a role that requires action.  They are likely to be hands on, and highly enthusiastic and energetic about a project.  The ISTP is a fairly relaxed individual, preferring flexibility rather than organization and allows their curiosity take them to new places. Tending to be logical and practical, with a thirst for knowledge the ISTP is not particularly comfortable with planning and organization, preferring a more flexible approach.',
    individualTypeByLetters: 'Mechanic',
    individualDescription:
      'As a Mechanic, %fname is likely to be drawn to a role that requires action.  %fname likes to be hands on, and highly enthusiastic and energetic about a project. They tend to be a fairly relaxed individual, preferring flexibility rather than organization and allowing their curiosity to take they to new places.  %fname is more logical and practical, with a thirst for knowledge and not particularly comfortable with planning or organization. %fname prefers a more flexible approach.',
  },
};

export const tmiTypeGroups: { name: string; types: string[] }[] = [
  {
    name: 'Artisans',
    types: ['ISFP', 'ESTP', 'ESFP', 'ISTP'],
  },
  {
    name: 'Guardians',
    types: ['ESTJ', 'ISTJ', 'ESFJ', 'ISFJ'],
  },
  {
    name: 'Idealists',
    types: ['ENFJ', 'INFJ', 'ENFP', 'INFP'],
  },
  {
    name: 'Rationals',
    types: ['ENTJ', 'INTJ', 'ENTP', 'INTP'],
  },
];

const lightningImageUrl = 'assets/charts/summary-graph/graph_0.png';
const peopleImageImageUrl = 'assets/charts/summary-graph/graph_1.png';
const compassImageUrl = 'assets/charts/summary-graph/graph_2.png';
const shuffleImageUrl = 'assets/charts/summary-graph/graph_3.png';
const lightBulbImageUrl = 'assets/charts/summary-graph/graph_4.png';
const courtImageUrl = 'assets/charts/summary-graph/graph_5.png';

export const spectrumTraits: {
  [trait: string]: {
    imageUrl: string;
    left: {
      title: string;
      description: string;
    };
    right: {
      title: string;
      description: string;
    };
  };
} = {
  OPPORTUNITY_AWARENESS: {
    imageUrl: lightningImageUrl,
    left: {
      title: 'Opportunity aware',
      description: 'Identifies possibilities and opportunities',
    },
    right: {
      title: 'Categorical',
      description: 'Sees things as definite and clear cut',
    },
  },
  ACTION_ORIENTATION: {
    imageUrl: lightningImageUrl,
    left: {
      title: 'Action oriented',
      description: 'Self-starter, energetic, spring into action',
    },
    right: {
      title: 'Tentative',
      description: 'Consider before acting, patient',
    },
  },
  DRIVE: {
    imageUrl: lightningImageUrl,
    left: {
      title: 'Accepting',
      description: 'Regular, less demanding',
    },
    right: {
      title: 'Achievement driven',
      description: 'Ambitious, challenging, high pressure',
    },
  },
  SELF_EFFICACY: {
    imageUrl: lightningImageUrl,
    left: {
      title: 'Concerned',
      description: 'May perceive challenging goals as out of reach and non achievable',
    },
    right: {
      title: 'Confident',
      description: 'Believes in own competency to address new challenges',
    },
  },
  PERSISTENCE: {
    imageUrl: courtImageUrl,
    left: {
      title: 'Immediate',
      description: 'Seek immediate, achievable wins',
    },
    right: {
      title: 'Persistent',
      description: 'Pursue difficult tasks, may neglect here and now',
    },
  },
  ORDERLINESS: {
    imageUrl: courtImageUrl,
    left: {
      title: 'Conscientious',
      description: 'Well organised, planned, orderly',
    },
    right: {
      title: 'Flexible',
      description: 'Adhoc, expedient, pragmatic',
    },
  },
  CAUTIOUSNESS: {
    imageUrl: courtImageUrl,
    left: {
      title: 'Cautious',
      description: 'Safe, careful, traditional, routine',
    },
    right: {
      title: 'Impulsive',
      description: 'Experimental, challenging, risky, informal',
    },
  },
  DUTIFULNESS: {
    imageUrl: courtImageUrl,
    left: {
      title: 'Dutiful',
      description: 'Complete tasks diligently, follow rules',
    },
    right: {
      title: 'Adjusting',
      description: 'View duties more flexible, and as open to adjustment',
    },
  },
  ALTRUISM: {
    imageUrl: shuffleImageUrl,
    left: {
      title: 'Altruistic',
      description: 'Kind, generous, common good',
    },
    right: {
      title: 'Correct',
      description: "Like for like, 'a favour for a favour', equal exchange",
    },
  },
  COOPERATION: {
    imageUrl: shuffleImageUrl,
    left: {
      title: 'Co-operative',
      description: 'Harmonious, compromising, accommodating',
    },
    right: {
      title: 'Competitive',
      description: 'Stubborn, less willing to compromise',
    },
  },
  ETHICS: {
    imageUrl: shuffleImageUrl,
    left: {
      title: 'Candid',
      description: 'Natural, frank, see no need to pretend',
    },
    right: {
      title: 'Guarded',
      description: 'Polished, polite, believe a certain amount of pretending is necessary',
    },
  },
  ASSERTIVENESS: {
    imageUrl: peopleImageImageUrl,
    left: {
      title: 'Accommodating',
      description: 'Headstrong, make their positions clear',
    },
    right: {
      title: 'Assertive',
      description: 'Headstrong, make their positions clear',
    },
  },
  EMOTIONALITY: {
    imageUrl: compassImageUrl,
    left: {
      title: 'Compassionate',
      description: 'Show sympathy for other’s feelings, caring',
    },
    right: {
      title: 'Calm',
      description: 'Unaffected by feelings, indifferent',
    },
  },
  GROUNDEDNESS: {
    imageUrl: compassImageUrl,
    left: {
      title: 'Practical',
      description: 'Tangible, concrete, grounded, clear',
    },
    right: {
      title: 'Intellectually curious',
      description: 'Conceptual, complex, enjoy debate, abstract thinking',
    },
  },
  IMAGINATION: {
    imageUrl: lightBulbImageUrl,
    left: {
      title: 'Restrictive',
      description: 'Methodical, automated, less creative',
    },
    right: {
      title: 'Imaginative',
      description: 'Creative, playful, ideas-oriented',
    },
  },
  FRIENDLINESS: {
    imageUrl: peopleImageImageUrl,
    left: {
      title: 'Sober',
      description: 'Serious, might be perceived as less approachable',
    },
    right: {
      title: 'Friendly',
      description: 'Approachable, easy to deal with',
    },
  },
  INDIVIDUALITY: {
    imageUrl: peopleImageImageUrl,
    left: {
      title: 'Group oriented',
      description: "Find it easier to 'fit in', and to adhere to group behaviour",
    },
    right: {
      title: 'Individualistic',
      description: 'Self-sufficient, independent, unique',
    },
  },
  SOCIABILITY: {
    imageUrl: peopleImageImageUrl,
    left: {
      title: 'Private',
      description: 'Enjoy time by themselves, reserved',
    },
    right: {
      title: 'Sociable',
      description: 'Outgoing, talkative, enjoy social buzz',
    },
  },
};
