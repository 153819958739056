import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { ResourceTag } from '../../../../../../shared/models';
import { Select, Store } from '@ngxs/store';
import { MomentTagState } from '../../../../store/moment/moment-tag/moment-tag.state';
import * as MomentTagActions from '../../../../store/moment/moment-tag/moment-tag.actions';
import { FrameWorkState } from '../../../../../admin/store/framework.state';
import { LoadableState } from '../../../../../../shared/store';
import { Framework } from '../../../../../../shared/models/admin/admin.model';
import { LoadFrameworks } from '../../../../../admin/store/framework.actions';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'ptl-moment-add-tags',
  templateUrl: './moment-add-tags.component.html',
  styleUrls: ['./moment-add-tags.component.scss'],
})
export class MomentAddTagsComponent implements OnInit, OnDestroy {
  @Select(MomentTagState.filteredTags)
  filteredTags$: Observable<ResourceTag[]>;

  @Select(FrameWorkState.frameworks)
  frameworks$: Observable<LoadableState<Framework[]>>;

  /** Receives the resource's tags array. */
  @Input() tags: ResourceTag[];

  /** Outputs adding of a new tag. */
  @Output() addTag = new EventEmitter<ResourceTag>();

  /** Outputs the index of the tag to remove. */
  @Output() removeTag = new EventEmitter<number>();

  /** Emits event to open framework tags table. */
  @Output() openFrameworkTags = new EventEmitter<boolean>();

  @ViewChild('tagInputElement') private tagInputElement: ElementRef;

  @ViewChild('tagFormElement') private tagFormElement: ElementRef;

  tagForm: FormGroup;

  addingTag = false;
  addingNewTag = false;

  loadingTags: boolean;

  resourceTags: ResourceTag[] = [];

  private frameworksSubscriber: Subscription;
  private tagSubsciber: Subscription;

  isFrameworksEmpty: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private store: Store,
  ) {
    this.store.dispatch(new LoadFrameworks());
  }

  ngOnInit(): void {
    this.tagSubsciber = this.filteredTags$.subscribe((data) => {
      if (data) {
        this.resourceTags = data;
      }
    });

    this.frameworksSubscriber = this.frameworks$.pipe(filter(({ loading, data }) => !loading && !!data)).subscribe((frameworks) => {
      if (frameworks.data.length > 0) {
        this.isFrameworksEmpty = false;
      } else {
        this.isFrameworksEmpty = true;
      }
    });

    this.tagForm = this.formBuilder.group({
      tagName: ['', Validators.required],
    });

    if (this.tagForm) {
      this.tagForm.controls.tagName.valueChanges.pipe(debounceTime(500)).subscribe((text) => {
        this.fireSearch(text);
      });
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.addingTag = false;
    }
  }

  ngOnDestroy(): void {
    this.frameworksSubscriber?.unsubscribe();
    this.tagSubsciber?.unsubscribe();
  }

  submitTag(): void {
    const formElement: HTMLFormElement = this.tagFormElement.nativeElement;
    const title: string = this.tagForm.controls.tagName.value;

    if (this.tagForm.controls.tagName.valid) {
      this.updateMomentTags({ title: title });
      this.resetForm(formElement);
      this.tagInputElement.nativeElement.blur();
      this.addingTag = false;
    }
  }

  onTagSelect(event: MatAutocompleteSelectedEvent): void {
    const formElement: HTMLFormElement = this.tagFormElement.nativeElement;
    const title: string = event.option.value;

    this.updateMomentTags({ title: title });
    this.resetForm(formElement);
    this.tagInputElement.nativeElement.blur();
    this.addingTag = false;
    this.resourceTags = [];
  }

  disabledAutocomplete(): string {
    return '';
  }

  deleteTag(tagIndex: number): void {
    this.removeTag.emit(tagIndex);
  }

  onBlurTagInput() {
    const title: string = this.tagForm.controls.tagName.value;
    if (!title || title.trim() === '') {
      this.resetForm(this.tagFormElement.nativeElement);
      this.addingNewTag = false;
    }
  }

  onFrameworkBtnClick() {
    this.openFrameworkTags.emit(true);
  }

  onNewTagBtnClick() {
    this.addingNewTag = true;
    setTimeout(() => {
      if (this.tagInputElement) {
        this.tagInputElement.nativeElement.focus();
      }
    });
  }

  private updateMomentTags(tag: ResourceTag): void {
    this.addTag.emit(tag);
  }

  private resetForm(formElement): void {
    formElement.reset();
    this.tagForm.reset();
  }

  private fireSearch(text: string): void {
    if (text && text.trim() !== '') {
      this.loadingTags = true;
      this.store
        .dispatch(new MomentTagActions.FindTagsByTitle(text))
        .toPromise()
        .then(() => {
          this.loadingTags = false;
        });
    }
  }
}
