/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { PlaylistResourceModel } from '../../models/playlist-resource.model';
import { ResourceAuthorDetails, ResourceTag } from '../../../../shared/models';
import { PaletteAction } from '../../components/create/playlist-palette/playlist-palette.component';
import { SupportedLanguage } from '@app/app/shared/models/languages/languages.model';
import { LoadableState } from '@app/app/shared/store';

export interface PlaylistResourceContentStateParentDetails {
  uri: string;
  uid: string;
  sectionUid: string;
}

export type ValidationError = 'EMPTY_HEADLINE';

/** The learner view main state model. */

export interface CurrentLanguage {
  supportedLanguage: SupportedLanguage;
  index: number;
}

export interface PlaylistPaletteActionModel {
  sectionIndex: number;
  type: PaletteAction;
}

export interface PlaylistCreationStateModel {
  /** Defines the playlist being edited, undefined if a new playlist is being created */
  currentLanguage: CurrentLanguage;
  playlist: LoadableState<PlaylistResourceModel>;
  hasNewCard: boolean;
  newCard: {
    sectionIndex: number;
  };
  paletteAction: PlaylistPaletteActionModel;
  /** Contains details about the parent playlist. */
  parentPlaylistDetails?: PlaylistResourceContentStateParentDetails;
  /** Contains details about the resource loading status. */
  resourceLoadingStatus: ResourceLoadingStatus;
  /** Changed when resource is being saved. */
  saveStatus: SaveStatus;
  /** Changed when resource is being deleted. */
  deletionStatus: ResourceDeletionStatus;
  /** Contains any errors due to which the resource cannot be saved (e.g. missing headline). */
  authorDetails: ResourceAuthorDetails;
  validationErrors: ValidationError[];
  tags: ResourceTag[];
  filteredTags: ResourceTag[];
  feedTags: ResourceTag[];
  filteredFeedTags: ResourceTag[];
  categories: ResourceTag[];
  filteredCategories: ResourceTag[];
  activePaletteAction: boolean;
}

/** Initial empty state. */
export const INITIAL_STATE: PlaylistCreationStateModel = {
  currentLanguage: null,
  playlist: {
    loading: false,
    data: {
      _id: undefined,
      mainSection: {
        cards: [],
        content: [],
        uid: undefined,
        cardsLayoutType: undefined,
        title: undefined,
        imageUrl: undefined,
        layoutType: undefined,
      },
      pageId: undefined,
      pageSectionId: undefined,
      standardSections: [],
      contentChanged: false,
      uri: undefined,
      permission: undefined,
      thumbnail: undefined,
      syndicated: undefined,
      targetGroups: [],
      timeRequired: undefined,
      formattedUri: undefined,
      type: undefined,
      subheader: undefined,
      title: undefined,
      authorDetails: undefined,
      organization: undefined,
      status: undefined,
      adminRights: undefined,
      completed: undefined,
      completedOn: undefined,
      likes: undefined,
      favorite: undefined,
      embedded: undefined,
      draft: null,
      tags: [],
      feedTags: [],
      categories: [],
      completedCardsCount: undefined,
      totalCardsCount: undefined,
      subHeader: undefined,
      lastUpdatedOn: undefined,
      availableLanguages: null,
      dataForms: null,
      userPlaylistSubmissionSummary: null,
    },
  },
  hasNewCard: false,
  newCard: {
    sectionIndex: undefined,
  },
  paletteAction: {
    sectionIndex: undefined,
    type: undefined,
  },
  resourceLoadingStatus: {
    errorLoadingResourceDetails: undefined,
    loadingResourceDetails: false,
  },
  saveStatus: {
    saveInProgress: false,
    autoSaveInProgress: false,
  },
  deletionStatus: {
    deletionInProgress: false,
    deletionFailure: undefined,
  },
  authorDetails: undefined,
  validationErrors: [],
  tags: [],
  filteredTags: [],
  feedTags: [],
  filteredFeedTags: [],
  categories: [],
  filteredCategories: [],
  activePaletteAction: undefined,
};

/** The resource save status. */
export interface SaveStatus {
  /** True when resource is being saved. */
  saveInProgress: boolean;
  /** True when resource is being auto saved. */
  autoSaveInProgress: boolean;
  /** Save failure reason. */
  saveFailureReason?: SaveStatusFailureReason;
}

export type SaveStatusFailureReason = 'duplication' | 'error' | '';

export interface ResourceLoadingStatus {
  /** True is an error occurred while loading resource details. */
  errorLoadingResourceDetails?: string;
  /** True while resource details are being loaded. */
  loadingResourceDetails: boolean;
}

export interface ResourceDeletionStatus {
  /** True when a resource is being deleted. */
  deletionInProgress: boolean;
  /** Contains failure reason if deletion fails. */
  deletionFailure?: string;
}
