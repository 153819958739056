<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="print-page-footer">
  <div class="summary-note" *ngIf="noteType === 'REPORT'">
    <span class="info-icon"></span>
    <p>{{ 'translations.report.description.footer' | transloco }}</p>
  </div>
  <div class="pdf-footer">
    <span class="copyright-text">
      <span>{{ 'translations.report.description.copyright' | transloco: { organizationName: (organizationData$ | async)?.name } }}</span>
    </span>
    <span class="copyright-text">
      <span>{{ 'translations.report.description.poweredBy' | transloco }}</span>
    </span>
    <span class="page-number">{{ pageNumber }}</span>
  </div>
</div>
