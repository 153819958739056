<ng-container *ngFor="let item of cards; let last = last">
  <div class="sidebar-item-separator {{ item.class }}" *ngIf="item.displayTopLineSeparator"></div>
  <div
    class="sidebar-item {{ item.class }}"
    role="listitem"
    [matTooltip]="item.title"
    [matTooltipClass]="'sidebar-tooltip'"
    [ngStyle]="{
      'padding-left': languageDirection === 'ltr' ? getPadding(level) : '',
      'padding-right': languageDirection === 'rtl' ? getPadding(level) : '',
    }"
    [class.is_active]="isActiveOrExpanded(item)"
    [class.is_expanded]="item.expanded"
  >
    <a
      [tabindex]="0"
      role="button"
      class="sidebar-link small"
      (click)="onSideBarItemClicked(item, cards, level)"
      (keydown.enter)="onSideBarItemClicked(item, cards, level)"
    >
      <ng-container *ngIf="stackView">
        <i class="material-icons icon" *ngIf="level !== 1 && (item.cards?.length || item.hasChild)">expand_less</i>
        <i class="no-icon icon" *ngIf="level !== 1 && !item.cards?.length && !item.hasChild"></i>
      </ng-container>
      <ng-container *ngIf="!stackView">
        <i
          [tabindex]="0"
          role="button"
          class="material-icons icon"
          *ngIf="item.cards?.length || item.hasChild"
          (click)="$event.stopPropagation(); onExpandTree(item, level)"
          (keydown.enter)="$event.stopPropagation(); onExpandTree(item, level)"
          >expand_more</i
        >
        <i class="no-icon icon" *ngIf="!item.cards?.length && !item.hasChild"></i>
      </ng-container>
      <span>{{ item.title }}</span>
      <i class="material-icons icon published-icon" *ngIf="item.published !== true">visibility_off</i>
    </a>
  </div>
  <div *ngIf="item.cards?.length" role="listitem">
    <ptl-side-nav-tree-item
      class="side-nav-tree-nested-item"
      role="list"
      [class.animation-on]="item.expanded"
      [cards]="item.cards"
      [stackView]="stackView"
      [level]="level + 1"
      [isExploreSideNav]="isExploreSideNav"
      (sidebarItemClicked)="onSideBarNestedItemClicked($event)"
    ></ptl-side-nav-tree-item>
  </div>
  <ng-container *ngIf="level > 1 && stackView && last && cards.length > 5">
    <div class="sidebar-item-expand" role="listitem">
      <div
        tabindex="0"
        role="button"
        [attr.aria-label]="
          cardsExpanded ? ('translations.global.ariaLabels.showLess' | transloco) : ('translations.global.ariaLabels.showMore' | transloco)
        "
        (click)="onSideBarSiblingsExpand(cards)"
        (keydown.enter)="onSideBarSiblingsExpand(cards)"
      >
        <div class="sidebar-item-expand-text small" *ngIf="!cardsExpanded">
          <svg class="svg" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#unfold-more"></use>
          </svg>
          {{ 'translations.sideNav.title.showMore' | transloco }}
        </div>
        <div class="sidebar-item-expand-text small" *ngIf="cardsExpanded">
          <svg class="svg" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#unfold-less"></use>
          </svg>
          {{ 'translations.sideNav.title.showLess' | transloco }}
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
