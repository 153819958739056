/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { BlockUpdateResponse, ResourceDataService, ResourceUpdateSectionResponse } from './data.service';
import { CardTitleUpdateRequest, ResourceSectionRequest, ResourceThumbnailUpdateRequest } from '../../../models/editor';
import { RestClientService } from '../../../../../shared/services/rest-client.service';
import { ObservableResult } from '../../../../../shared/store';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../../../../../../environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { EditorContent, PlaylistCardShort, Resource, ResourceSection } from '../../../../../shared/models';
import { ItemWithUploadUrl } from '../../../../../shared/models/ItemWithUploadUrl';
import { TranslocoService } from '@ngneat/transloco';
import { LanguageCodeHelper } from '../../../../../shared/helpers/language-code-helper';
import { CardsStatsSummary, StatsData } from '../../../../../shared/models/analytics/analytics.model';
import { Page } from '../../../../../shared/models/page';
import { AllContentCard, AllContentPlaylist } from '../../../../../shared/models/all-content/all-content.model';
import { Observable } from 'rxjs';
import { RequestQueueManagerService } from '@app/app/shared/services/request-queue-manager/request-queue-manager';

@Injectable()
export class ApiResourceDataService implements ResourceDataService {
  private cardsUrl = Location.joinWithSlash(environment.apiRootUrl || '', 'cards');
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
    private requestQueueManager: RequestQueueManagerService<unknown>,
  ) {}

  updateCardHeadline(resourceUid: string, request: CardTitleUpdateRequest, languageCode?: string): ObservableResult<Resource> {
    const url = `${this.cardsUrl}/${resourceUid}`;
    return this.client
      .patch<Resource>(url, request, null, languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(({ status }) => ObservableResult.ofError(status)),
      );
  }

  markWebLinkOpened(resourceUid: string): ObservableResult<void> {
    return this.client.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/progress/openings`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(({ status }) => ObservableResult.ofError(status)),
    );
  }

  getResourceDetails(
    isProject: boolean,
    playlistUri: string,
    resourceUri: string,
    publisherUri: string,
    packageUri: string,
    pageUri: string,
    languageCode?: string,
  ): ObservableResult<Resource> {
    const projectUrlPrefix = `projects/${playlistUri}`;
    const playlistUrlPrefix = `playlists/${playlistUri}`;
    const prefix = isProject ? projectUrlPrefix : playlistUrlPrefix;
    let url = `${prefix}/cards/${resourceUri}`;
    // if (pageUri) {
    //   url = `pages/${pageUri}/${url}`
    // }
    //
    // if (publisherUri && packageUri) {
    //   url = `pub/${publisherUri}/${packageUri}/${url}`
    // }

    if (publisherUri && packageUri) {
      url = `pub/${publisherUri}/${packageUri}/pages/${pageUri}/${url}`;
    }

    return this.client
      .get<Resource>(
        Location.joinWithSlash(environment.apiRootUrl || '', url),
        null,
        languageCode ? { 'Accept-Language': languageCode } : null,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetResource'))),
      );
  }

  deleteResource(playlistUid: string, sectionUid: string, resourceUid: string): ObservableResult<void> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '',
      `playlists/${playlistUid}/sections/${sectionUid}/cards/${resourceUid}`,
    );
    return this.client.delete(url).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorDeleteResource'))),
    );
  }

  createEditorBlock(
    cardUid: string,
    sectionUid: string,
    data: EditorContent,
    languageCode?: string,
  ): ObservableResult<BlockUpdateResponse> {
    const url = `${this.cardsUrl}/${cardUid}/sections/${sectionUid}/blocks`;

    return new Observable((observer) => {
      const request$ = this.client
        .put<BlockUpdateResponse>(
          url,
          data,
          undefined,
          languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
        )
        .pipe(
          switchMap(({ body }) => ObservableResult.ofSuccess(body)),
          catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatingSection'))),
        );

      this.requestQueueManager.addRequest(() =>
        request$.pipe(
          switchMap((response) => {
            observer.next(response);
            observer.complete();
            return this.requestQueueManager.executeNextRequest();
          }),
        ),
      );
    });
  }

  updateEditorBlock(
    cardUid: string,
    sectionUid: string,
    data: EditorContent,
    languageCode?: string,
  ): ObservableResult<BlockUpdateResponse> {
    const url = `${this.cardsUrl}/${cardUid}/sections/${sectionUid}/blocks/${data.uid}`;

    return new Observable((observer) => {
      const request$ = this.client
        .patch<BlockUpdateResponse>(
          url,
          data,
          undefined,
          languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
        )
        .pipe(
          switchMap(({ body }) => ObservableResult.ofSuccess(body)),
          catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatingSection'))),
        );

      this.requestQueueManager.addRequest(() =>
        request$.pipe(
          switchMap((response) => {
            observer.next(response);
            observer.complete();
            return this.requestQueueManager.executeNextRequest();
          }),
        ),
      );
    });
  }

  deleteEditorBlock(cardUid: string, sectionUid: string, blockUid: string): ObservableResult<void> {
    const url = `${this.cardsUrl}/${cardUid}/sections/${sectionUid}/blocks/${blockUid}`;

    return new Observable((observer) => {
      const request$ = this.client.delete<void>(url).pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRemoveThumbnail'))),
      );

      this.requestQueueManager.addRequest(() =>
        request$.pipe(
          switchMap((response) => {
            observer.next(response);
            observer.complete();
            return this.requestQueueManager.executeNextRequest();
          }),
        ),
      );
    });
  }

  createSection(resourceUid: string): ObservableResult<ResourceSection> {
    const url = `${this.cardsUrl}/${resourceUid}/sections`;
    return this.client.post<ResourceSection>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorCreateSection'))),
    );
  }

  deleteSection(resourceUid: string, sectionUid: string): ObservableResult<Resource> {
    const url = `${this.cardsUrl}/${resourceUid}/sections/${sectionUid}`;
    return this.client.delete<Resource>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRemovingSection'))),
    );
  }

  updateSection(
    resourceUid: string,
    sectionUid: string,
    data: ResourceSectionRequest,
    languageCode?: string,
  ): ObservableResult<ResourceUpdateSectionResponse> {
    const url = `${this.cardsUrl}/${resourceUid}/sections/${sectionUid}`;
    return this.client
      .put<ResourceUpdateSectionResponse>(
        url,
        data,
        undefined,
        languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatingSection'))),
      );
  }

  updateTimeRequired(resourceUid: string, timeRequired: number): ObservableResult<void> {
    const url = `${this.cardsUrl}/${resourceUid}/settings/time/required`;
    return this.client.patch<void>(url, { timeRequired: timeRequired }).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatingTimeRequired'))),
    );
  }

  getResourceTimeRequiredSuggestion(resourceUid: string, languageCode?: string): ObservableResult<number> {
    return this.client
      .get<number>(
        Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/settings/time/required/suggestion`),
        {},
        languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() =>
          ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGettingTimeRequiredSuggestions')),
        ),
      );
  }

  getFormPlayListByUid(playlistUid: string, term: string, page: number, size: number): ObservableResult<Page<AllContentPlaylist>> {
    const params = {
      page: page.toString(),
      size: size ? size.toString() : '10',
    };
    if (term) {
      params['term'] = term;
    }

    return this.client
      .get<Page<AllContentPlaylist>>(Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/list`), params)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetPlaylists'))),
      );
  }

  getFormCardsByPlayListUid(playlistUid: string, selectedPlaylistUid: string = ''): ObservableResult<AllContentCard[]> {
    const params = { selectedPlaylistUid };
    const apiEndPoint = Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/cards-list`);

    return this.client.get<AllContentCard[]>(apiEndPoint, params).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetPlaylists'))),
    );
  }

  getFormsList(
    playlistUid: string,
    selectedPlaylistUid: string = '',
    selectedCardUid: string = '',
  ): ObservableResult<{ title: string; id: string }[]> {
    const apiEndPoint = Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/forms-list`);

    return this.client
      .get<{ title: string; id: string }[]>(`${apiEndPoint}?selectedPlaylistUid=${selectedPlaylistUid}&selectedCardUid=${selectedCardUid}`)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetPlaylists'))),
      );
  }

  getFormTitlesByPlayListUid(playlistUid: string): ObservableResult<{ title: string }> {
    const apiEndPoint = Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/titles`);
    return this.client.get<{ title: string }>(apiEndPoint).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetPlaylists'))),
    );
  }

  getFormCardTitlesByCardUid(playlistUid: string, cardUid: string): ObservableResult<{ title: string }> {
    const apiEndPoint = Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/cards/${cardUid}/titles`);
    return this.client.get<{ title: string }>(apiEndPoint).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetPlaylists'))),
    );
  }

  changeResourceThumbnail(resourceUid: string, request: ResourceThumbnailUpdateRequest): ObservableResult<ItemWithUploadUrl<Resource>> {
    return this.client
      .post<
        ItemWithUploadUrl<Resource>
      >(Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/settings/thumbnails`), request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdateThumbnail'))),
      );
  }

  removeResourceThumbnail(resourceUid: string): ObservableResult<void> {
    return this.client.delete<void>(Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/settings/thumbnails`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRemoveThumbnail'))),
    );
  }

  archiveResource(resourceUid: string): ObservableResult<void> {
    return this.client
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/settings/publishing/archive`), {})
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorDeleteResource'))),
      );
  }

  addLanguage(cardUid: string, languageCode: string): ObservableResult<void> {
    return this.client.patch<void>(Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/languages/${languageCode}`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatingLanguage'))),
    );
  }

  removeLanguage(cardUid: string, languageCode: string): ObservableResult<void> {
    return this.client
      .delete<void>(Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/languages/${languageCode}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatingLanguage'))),
      );
  }

  enableLanguage(cardUid: string, languageCode: string): ObservableResult<void> {
    return this.client
      .patch<void>(Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/languages/${languageCode}/enable`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatingLanguage'))),
      );
  }

  disableLanguage(cardUid: string, languageCode: string): ObservableResult<void> {
    return this.client
      .patch<void>(Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/languages/${languageCode}/disable`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatingLanguage'))),
      );
  }

  getResourceStartedStatistics(cardUid: string, playlistUid: string, from: string, to: string): ObservableResult<StatsData[]> {
    return this.client
      .get<StatsData[]>(Location.joinWithSlash(environment.apiRootUrl || '', `/analytics/members/stats/cards/${cardUid}/started`), {
        from: from,
        to: to,
        playlistId: playlistUid,
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() =>
          ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGettingResourceStatedStatistics')),
        ),
      );
  }

  getResourceCompletedStatistics(cardUid: string, playlistUid: string, from: string, to: string): ObservableResult<StatsData[]> {
    return this.client
      .get<StatsData[]>(Location.joinWithSlash(environment.apiRootUrl || '', `/analytics/members/stats/cards/${cardUid}/completed`), {
        from: from,
        to: to,
        playlistId: playlistUid,
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() =>
          ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGettingResourceCompletedStatistics')),
        ),
      );
  }

  getResourceStatisticsSummary(cardUid: string, playlistUid: string): ObservableResult<CardsStatsSummary> {
    return this.client
      .get<CardsStatsSummary>(
        Location.joinWithSlash(environment.apiRootUrl || '', `analytics/members/stats/cards/${cardUid}/summary?playlistId=${playlistUid}`),
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetStatisticsSummary'))),
      );
  }

  getResourceCardsByTags(page: number, size: number, tags: string[]): ObservableResult<Page<PlaylistCardShort>> {
    const url = `${this.cardsUrl}/latest/tags`;
    const params = {
      page: page.toString(),
      size: size.toString(),
    };
    if (tags.length) {
      params['tags'] = tags.join(',');
    }
    return this.client.get<Page<PlaylistCardShort>>(url, params).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetCardList'))),
    );
  }
}
