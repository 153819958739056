<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="interval-padding" *ngIf="appointment?.id">
  <h4 class="title">{{ 'translations.bookings.label.bookableResources' | transloco }}</h4>
  <p class="info">{{ 'translations.bookings.label.assignResource' | transloco }}</p>
  <ptl-user-search-autocomplete-new
    #userSearchAutocomplete
    [loadingMembers]="loadingMembers"
    [members]="members"
    [placeholder]="'translations.global.ariaLabels.icons.search' | transloco"
    (searchLoadedMore)="onSearchLoadingMore($event)"
    (searchInputChanged)="onSearchInputChange($event)"
    (memberSelected)="onMemberSelected($event)"
  >
  </ptl-user-search-autocomplete-new>

  <div class="row" *ngIf="resourceHasError">
    <div class="col interval-padding">
      <mat-error>
        {{ 'translations.bookings.label.errors.resourceError' | transloco }}
      </mat-error>
    </div>
  </div>
  <div class="row">
    <div class="col interval-padding">
      <mat-error *ngIf="appointmentHasError">
        {{ 'translations.bookings.label.errors.appointmentError' | transloco }}
      </mat-error>
    </div>
  </div>
</div>

<div *ngIf="bookableResources?.length > 0" class="members-bar">
  <div class="resources-list">
    <ng-container *ngFor="let resource of bookableResources">
      <ptl-form-booking-resource-item
        [resource]="resource"
        [appointment]="appointment"
        [timeIntervals]="timeIntervals"
        [cardUid]="cardUid"
        [currentLanguage]="currentLanguage"
        [saveInProgress]="isSaved"
        (isLoading)="setIsLoading($event)"
        (scheduleUpdated)="updateResourcesRequests($event)"
        (resourceDeleted)="onResourceDeleted($event)"
        (resourceUpdated)="onResourceRefresh()"
      ></ptl-form-booking-resource-item>
    </ng-container>
  </div>
</div>
