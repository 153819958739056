/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { Injectable } from '@angular/core';
import { HelpCenterDataService } from './help-center-data.service';
import { Page as Pagination } from '../../models/page';
import { PlaylistCardShort } from '../../models';

@Injectable()
export class MockHelpCenterDataService implements HelpCenterDataService {
  getHelpCenter(page: number, size: number): ObservableResult<Pagination<PlaylistCardShort>> {
    return ObservableResult.ofSuccess();
  }

  getHelpCenterTopics(page: number, size: number, tagId?: string, term?: string): ObservableResult<Pagination<PlaylistCardShort>> {
    return ObservableResult.ofSuccess();
  }
}
