/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Moment } from '../../page-modules/folio/models';
import { ApplicationMode } from './app-frame.state';

// -------------------------------------------------------------------------
export class CloseMobileNavMenu {
  static readonly type = '[App Frame] Close mobile navigation menu';
}

// -------------------------------------------------------------------------
export class OpenMobileNavMenu {
  static readonly type = '[App Frame] Open mobile navigation menu';
}

// -------------------------------------------------------------------------
export class SwitchApplicationMode {
  static readonly type = '[App Frame] Switch application mode.';

  constructor(public mode: ApplicationMode) {}
}

export class SetIsMobileState {
  static readonly type = '[App Frame] Set mobile state';

  constructor(public isMobile: boolean) {}
}

export class CreateMoment {
  static readonly type = '[App Frame] Create Moment';

  constructor(public moment: Moment) {}
}

export class SetDisplayHeader {
  static readonly type = '[App Frame] Set Display Header';

  constructor(public displayHeader: boolean) {}
}

// -------------------------------------------------------------------------
export class SetDisplaySidenav {
  static readonly type = '[App Frame] Set Display Sidenav';

  constructor(public displaySidenav: boolean) {}
}

export class RegisterCardOpening {
  static readonly type = '[App Frame] Register card as opened';

  constructor(public cardId: string) {}
}

export class ViewMoment {
  static readonly type = '[App Frame] View moment';

  constructor(public id: string) {}
}
