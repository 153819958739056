/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../shared/store';
import { InjectionToken } from '@angular/core';
import {
  AssessmentSummary,
  CreateReportConditionalSectionRequest,
  CreateReportResourceSectionRequest,
  CreateReportStandardChartSectionRequest,
  CreateReportStandardSectionRequest,
  DiagnosticDescriptionQuestionResponse,
  DiagnosticLogic,
  DiagnosticQuestionRequest,
  DiagnosticQuestionResponse,
  Diagnostics,
  DiagnosticsRetake,
  GeneratedReport,
  LearningGoalIconRequest,
  LogicGoal,
  LogicGoalLevel,
  MyPagesAssessmentsResponse,
  QuestionEditor,
  QuestionResponseRequest,
  QuestionResponses,
  Report,
  ReportMode,
  ReportReordering,
  ShowReportLogoRequest,
  UpdateReportConditionalSectionRequest,
  UpdateReportResourceSectionRequest,
  UpdateReportSectionResponse,
  UpdateReportStandardSectionRequest,
  UpdateReportTitleRequest,
} from '../../../shared/models';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const DIAGNOSTICS_DATA_SERVICE = new InjectionToken<DiagnosticsDataService>('[Diagnostics] DataService');

/** The discovery data service. */
export interface DiagnosticsDataService {
  createQuestion(
    assessmentUid: string,
    questionData: DiagnosticQuestionRequest,
    languageCode?: string,
  ): ObservableResult<DiagnosticQuestionResponse>;

  updateQuestion(
    assessmentUid: string,
    questionUid: string,
    questionData: DiagnosticQuestionRequest,
    languageCode?: string,
  ): ObservableResult<DiagnosticQuestionResponse>;

  updateQuestionDescription(
    assessmentUid: string,
    questionUid: string,
    questionData: QuestionEditor,
    languageCode?: string,
  ): ObservableResult<DiagnosticDescriptionQuestionResponse>;

  copyQuestion(assessmentUid: string, questionUid: string): ObservableResult<DiagnosticQuestionResponse>;

  deleteQuestion(assessmentUid: string, questionUid: string): ObservableResult<void>;

  reorderQuestions(assessmentUid: string, questions: string[]): ObservableResult<DiagnosticQuestionResponse[]>;

  getDiagnosticsLogicGoals(assessmentUid: string): ObservableResult<DiagnosticLogic[]>;

  updateLogicGoal(assessmentUid: string, goalUid: string, goalData: LogicGoal): ObservableResult<Diagnostics>;

  updateLogicGoalLevel(assessmentUid: string, goalUid: string, levelUid: string, levelData: LogicGoalLevel): ObservableResult<Diagnostics>;

  updateAssessmentChartIcon(
    assessmentUid: string,
    sectionUid: string,
    dynamicContentUid: string,
    type: 'MAX' | 'MIN',
    iconData: LearningGoalIconRequest,
    file: File,
  ): ObservableResult<void>;

  updateLearningGoalIcon(
    assessmentUid: string,
    goalUid: string,
    type: 'MAIN' | 'MAX' | 'MIN',
    iconData: LearningGoalIconRequest,
    file: File,
  ): ObservableResult<void>;

  removeLearningGoalIcon(assessmentUid: string, goalUid: string, type: 'MAIN' | 'MAX' | 'MIN'): ObservableResult<void>;

  removeAssessmentChartIcon(
    assessmentUid: string,
    sectionUid: string,
    dynamicContentUid: string,
    type: 'MAX' | 'MIN',
  ): ObservableResult<void>;

  getQuestionResponses(assessmentUid: string): ObservableResult<QuestionResponses>;

  addQuestionResponse(assessmentUid: string, version: number, request: QuestionResponseRequest): ObservableResult<QuestionResponses>;

  updateDiagnosticsRetake(assessmentUid: string, request: DiagnosticsRetake): ObservableResult<QuestionResponses>;

  randomizeQuestions(assessmentUid: string): ObservableResult<QuestionResponses>;

  deleteRandomizeQuestions(assessmentUid: string): ObservableResult<QuestionResponses>;

  getAssessmentSummary(assessmentUid: string, languageCode?: string): ObservableResult<AssessmentSummary>;

  getAssessmentSummaryAdmins(assessmentUid: string, userUid: string, languageCode?: string): ObservableResult<AssessmentSummary>;

  getDiagnosticsQuestions(assessmentUid: string, languageCode?: string): ObservableResult<DiagnosticQuestionResponse[]>;

  /** Reports. */

  createReportStandardSection(
    assessmentUid: string,
    request: CreateReportStandardSectionRequest,
    languageCode?: string,
  ): ObservableResult<UpdateReportSectionResponse>;

  createReportStandardChartSection(
    assessmentUid: string,
    request: CreateReportStandardChartSectionRequest,
    languageCode?: string,
  ): ObservableResult<UpdateReportSectionResponse>;

  createReportConditionalSection(
    assessmentUid: string,
    request: CreateReportConditionalSectionRequest,
    languageCode?: string,
  ): ObservableResult<UpdateReportSectionResponse>;

  createReportResourceSection(
    assessmentUid: string,
    request: CreateReportResourceSectionRequest,
    languageCode?: string,
  ): ObservableResult<UpdateReportSectionResponse>;

  updateReportTitle(assessmentUid: string, request: UpdateReportTitleRequest, languageCode?: string): ObservableResult<void>;

  updateReportStandardSection(
    assessmentUid: string,
    sectionUid: string,
    request: UpdateReportStandardSectionRequest,
    languageCode?: string,
  ): ObservableResult<UpdateReportSectionResponse>;

  updateReportConditionalSection(
    assessmentUid: string,
    sectionUid: string,
    request: UpdateReportConditionalSectionRequest,
    languageCode?: string,
  ): ObservableResult<UpdateReportSectionResponse>;

  updateReportResourceSection(
    assessmentUid: string,
    sectionUid: string,
    request: UpdateReportResourceSectionRequest,
    languageCode?: string,
  ): ObservableResult<UpdateReportSectionResponse>;

  reorderReportSections(assessmentUid: string, request: ReportReordering): ObservableResult<Report>;

  deleteReportSection(assessmentUid: string, sectionUid: string): ObservableResult<void>;

  getReport(assessmentUid: string, responsesUid: string, languageCode?: string): ObservableResult<GeneratedReport>;

  showReportTitle(assessmentUid: string): ObservableResult<void>;

  hideReportTitle(assessmentUid: string): ObservableResult<void>;

  showHideReportOrgLogo(assessmentUid: string, request: ShowReportLogoRequest): ObservableResult<void>;

  updateReportMode(assessmentUid: string, mode: ReportMode): ObservableResult<void>;

  addReportResourceTag(assessmentUid: string, sectionUid: string, tagUid: string): ObservableResult<void>;

  getAssessmentsResponses(): ObservableResult<MyPagesAssessmentsResponse[]>;

  deleteReportResourceTag(assessmentUid: string, sectionUid: string, tagUid: string): ObservableResult<void>;
}
