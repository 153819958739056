/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { copyToClipboard } from '../../../../resource/store/editor/content/helpers/copy-clipboard.helpers';

@Component({
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
})
export class ShareDialogComponent implements OnInit {
  shareLink: string;

  constructor(private dialogRef: MatDialogRef<ShareDialogComponent>) {}

  ngOnInit() {
    this.shareLink = window.location.href;
  }

  onCopy() {
    copyToClipboard(this.shareLink);
  }
}
