/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../../shared/store';
import { InjectionToken } from '@angular/core';
import {
  MemberInvitationRequest,
  MemberInvitationResponse,
  MemberRecord,
  MemberRecordLearnerPlaylist,
  MemberRecordLearnerPlaylistCardsResponse,
  MemberRecordPlaylistCardResponse,
  MemberRecordPlaylistsRequest,
  MembersInvitationBulkResponse,
  MembersSearchResponse,
  UserSearch,
  ValidateEmailsFromCsvFileResponse,
} from '../../../../shared/models/admin/members.model';
import { Page } from '../../../../shared/models/page';
import { MemberReview } from '../../../../shared/models';
import { StatsData, StatsSummary } from '../../../../shared/models/analytics/analytics.model';
import { MembersBooleanSearchRequest, ReviewsSearchRequest } from '../../../../shared/models/admin/boolean-filters.model';
import {
  AssessmentDatapointMetrics,
  AssessmentMemberMetricsSummary,
} from '@app/app/page-modules/resource/components/editor/analytics/assessment-analytics.model';
import {
  OrganizationAllTimeMetrics,
  OrganizationMembersMetrics,
  OrganizationPageSummary,
} from '@app/app/page-modules/admin/components/analytics/admin-analytics.model';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const MEMBERS_DATA_SERVICE = new InjectionToken<MembersDataService>('[Admin Members] DataService');

/** The admin data service. */
export interface MembersDataService {
  addMembersToGroup(userGroupUid: string, request: string[]): ObservableResult<void>;

  removeMembersFromGroup(userGroupUid: string, request: string[]): ObservableResult<void>;

  loadMemberRecord(userUid?: string, playlistUid?: string): ObservableResult<MemberRecord>;

  getLearnerPlaylistCards(learnerPlaylistId: string): ObservableResult<MemberRecordLearnerPlaylistCardsResponse>;

  getPlaylistLearnerCardsByUserId(playlistUid: string, userUid: string): ObservableResult<MemberRecordPlaylistCardResponse[]>;

  getMemberPlaylistsByType(userUid: string, request: MemberRecordPlaylistsRequest): ObservableResult<Page<MemberRecordLearnerPlaylist>>;

  searchNonAdminUsers(page: number, pageSize: number, searchString: string): ObservableResult<Page<UserSearch>>;

  searchUsers(page: number, pageSize: number, searchString: string, organization?: string): ObservableResult<Page<UserSearch>>;

  getAllInvitationsList(page: number, pageSize: number, email?: string): ObservableResult<Page<MemberInvitationResponse>>;

  getAcceptedInvitationsList(page: number, pageSize: number): ObservableResult<Page<MemberInvitationResponse>>;

  getNotAcceptedInvitationsList(page: number, pageSize: number): ObservableResult<Page<MemberInvitationResponse>>;

  inviteNewMembers(request: MemberInvitationRequest): ObservableResult<MembersInvitationBulkResponse>;

  importNewMembers(
    csvFile: File,
    groupIdsQueryParam: string,
    playlistIdsQueryParam: string,
  ): ObservableResult<MembersInvitationBulkResponse>;

  resendInvitation(invitationId: string): ObservableResult<void>;

  validateUsersFromCsvFile(csvFile: File, playlistUid: string): ObservableResult<ValidateEmailsFromCsvFileResponse>;

  getLearnerCard(playlistUid: string, userUid: string, cardUid: string): ObservableResult<MemberRecordPlaylistCardResponse>;

  getLearnerCardForReviewer(userUid: string, cardUid: string): ObservableResult<MemberRecordPlaylistCardResponse>;

  getStatisticsNewMembers(from: string, to: string): ObservableResult<StatsData[]>;

  getStatisticsActiveMembers(from: string, to: string): ObservableResult<StatsData[]>;

  getStatisticsSummary(): ObservableResult<StatsSummary>;

  searchMembers(request: MembersBooleanSearchRequest, page: number, size: number): ObservableResult<MembersSearchResponse>;

  searchCardMembers(
    request: MembersBooleanSearchRequest,
    playlistUid: string,
    cardUid: string,
    page: number,
    size: number,
  ): ObservableResult<MembersSearchResponse>;

  searchPlaylistMembers(
    request: MembersBooleanSearchRequest,
    playlistUid: string,
    page: number,
    size: number,
  ): ObservableResult<MembersSearchResponse>;

  booleanExportMembers(request: MembersBooleanSearchRequest): ObservableResult<string>;

  booleanExportPlaylistMembers(request: MembersBooleanSearchRequest, playlistUid: string): ObservableResult<string>;

  booleanExportCardMembers(request: MembersBooleanSearchRequest, playlistUid: string, cardUid: string): ObservableResult<string>;

  booleanExportEventCardMembers(request: MembersBooleanSearchRequest, playlistUid: string, eventUid: string): ObservableResult<string>;

  searchPlaylistReviews(
    request: ReviewsSearchRequest,
    playlistUid: string,
    page: number,
    size: number,
  ): ObservableResult<Page<MemberReview>>;

  searchCardReviews(request: ReviewsSearchRequest, cardUid: string, page: number, size: number): ObservableResult<Page<MemberReview>>;

  getActiveUsersNowCount(): ObservableResult<number>;

  getOrganizationAllTimeMetrics(): ObservableResult<OrganizationAllTimeMetrics>;

  getOrganizationPagesSummary(): ObservableResult<OrganizationPageSummary[]>;

  getOrganizationMembersMetrics(
    request: MembersBooleanSearchRequest,
    from: string,
    to: string,
  ): ObservableResult<OrganizationMembersMetrics>;

  getAssessmentMetrics(
    playlistUid: string,
    cardUid: string,
    from: string,
    to: string,
    request: MembersBooleanSearchRequest,
  ): ObservableResult<AssessmentDatapointMetrics>;

  getAssessmentMetricsSummary(
    playlistUid: string,
    cardUid: string,
    request: MembersBooleanSearchRequest,
  ): ObservableResult<AssessmentMemberMetricsSummary>;
}
