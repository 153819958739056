<!--
  ~ Copyright (C) 2020 - present by Potentially
  ~
  ~ Please see distribution for license.
  -->

<div class="playlist-report__view" #reportView>
  <div class="print-page" *ngFor="let page of pages; let i = index">
    <ng-container *ngIf="i === 0">
      <div class="playlist-report-info">
        <h1 class="playlist-title extra-large">
          {{
            'translations.playlistPdfReviewTitle'
              | transloco
                : {
                    playlistTitle: playlistTitle,
                    date: currentTime | dateFormat: 'DD MMMM yyyy',
                  }
          }}
        </h1>
        <div class="playlist-description">
          <div class="large" *ngIf="submittedOn">
            {{
              'translations.playlistSubmittedByAndDate'
                | transloco
                  : {
                      userName: report.userName,
                      date: submittedOn | dateFormat: 'DD MMMM yyyy',
                    }
            }}
          </div>
          <div class="large">
            {{ 'translations.exportedOn' | transloco: { date: currentTime | dateFormat: 'DD MMMM yyyy' } }}
          </div>
        </div>
      </div>
      <span class="playlist-report__empty-message" *ngIf="!page.activities?.length">{{ 'translations.noSubmissions' | transloco }}</span>
    </ng-container>

    <div
      class="playlist-report__activity"
      *ngFor="let activity of page.activities"
      [ngClass]="{ 'trimmed-from-top': !activity.playlist, 'trimmed-from-bottom': !activity.review }"
    >
      <div class="playlist-report__activity-item-inner">
        <div class="step-row medium" *ngIf="activity.playlist">
          {{ activity.card }}
        </div>

        <ng-container *ngIf="!activity.empty; else emptySubmission">
          <ng-container *ngFor="let submissionVersion of activity.submissions">
            <div class="large bold title-row" *ngIf="submissionVersion.name">
              {{ submissionVersion.name }}
            </div>

            <div class="form-value">
              <ptl-resource-preview
                *ngIf="activity.type === 'TEXT_FORM'"
                [previewContent]="[{ type: 'PARAGRAPH', content: submissionVersion.value }]"
              >
              </ptl-resource-preview>
              <div class="checkbox" *ngIf="activity.type === 'CHECKBOX_FORM'">
                <mat-icon aria-hidden="true" *ngIf="submissionVersion.value">check</mat-icon>
              </div>
              <ng-container *ngIf="activity.type === 'FILE_UPLOAD_FORM'">
                <div class="file-names" *ngIf="submissionVersion.files">
                  <ng-container *ngFor="let submissionValue of submissionVersion.files; let i = index">
                    <div class="file-name">{{ submissionValue }}</div>
                    <div class="space" *ngIf="i < submissionVersion.files.length - 1">,</div>
                  </ng-container>
                </div>
              </ng-container>
              <ptl-quiz-results-report
                *ngIf="activity.type === 'QUIZ_FORM'"
                [quiz]="submissionVersion.value.quiz"
                [responses]="submissionVersion.value.responses"
                [playlistDownloadReportView]="true"
              >
              </ptl-quiz-results-report>
              <div *ngIf="activity.type === 'COLLECTOR_FORM'">
                <div class="collector-log" *ngFor="let moment of submissionVersion.value.moments; let i = index">
                  <ng-container *ngIf="imageUrlMapping[moment.imageUrl]">
                    <h3>{{ moment.title ? moment.title : 'Moment ' + (i + 1) }}</h3>
                    <img alt="" [src]="imageUrlMapping[moment.imageUrl]" width="266" height="150" />
                  </ng-container>
                  <a [href]="moment.imageUrl" *ngIf="!imageUrlMapping[moment.imageUrl]">{{
                    moment.title ? moment.title : 'Moment ' + (i + 1)
                  }}</a>
                </div>
                <ptl-resource-preview
                  *ngFor="let answer of submissionVersion.value.answers"
                  [previewContent]="[{ type: 'PARAGRAPH', content: answer }]"
                >
                </ptl-resource-preview>
              </div>
              <div *ngIf="activity.type === 'ASSESSMENT'">
                <ptl-diagnostics-report-conditional-section *ngFor="let section of submissionVersion.value.sections" [section]="section">
                </ptl-diagnostics-report-conditional-section>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #emptySubmission>
          <div class="medium step-row">
            <span class="medium">{{ 'translations.submission' | transloco }} </span>
            <span class="medium" [ngSwitch]="activity.type">
              <span *ngSwitchCase="'TEXT_FORM'">{{ 'translations.formNotSubmitted' | transloco }}</span>
              <span *ngSwitchCase="'CHECKBOX_FORM'">{{ 'translations.formNotSubmitted' | transloco }}</span>
              <span *ngSwitchCase="'FILE_UPLOAD_FORM'">{{ 'translations.fileNotUploaded' | transloco }}</span>
              <span *ngSwitchCase="'QUIZ_FORM'">{{ 'translations.quizNotTaken' | transloco }}</span>
              <span *ngSwitchCase="'COLLECTOR_FORM'">{{ 'translations.collector.label.notCompleted' | transloco }}</span>
              <span *ngSwitchCase="'ASSESSMENT'">{{ 'translations.noConditionalSectionsToShow' | transloco }}</span>
            </span>
          </div>
        </ng-template>

        <div class="submission-row" *ngIf="activity.review !== undefined && activity.review !== 'EMPTY'">
          <div class="submission-cell">{{ 'translations.global.label.review' | transloco }}</div>
          <div class="submission-cell">
            <span class="review-status approved" *ngIf="activity.review === 'APPROVED'">{{ 'translations.approved' | transloco }}</span>
            <span class="review-status" *ngIf="activity.review === 'RESUBMIT'">{{ 'translations.resubmit' | transloco }}</span>
            <span> {{ activity.reviewDate | dateFormat: 'DD MMMM yyyy' }}</span>
          </div>
        </div>
        <div class="submission-row" *ngFor="let comment of activity.comments">
          <div class="submission-cell">
            <span>{{ 'translations.comment' | transloco }}</span>
            <span *ngIf="comment.index"> {{ comment.index }} </span>
          </div>
          <div class="submission-cell">
            {{ comment.note.message }}
          </div>
        </div>
      </div>
    </div>
    <div class="submission-extension"></div>
    <ptl-print-page-footer class="print-page-footer" [noteType]="'NONE'" [pageNumber]="i + 1"></ptl-print-page-footer>
  </div>
</div>
