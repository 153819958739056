/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { DEFAULT_LANGUAGE_CODE } from '../services/languages/language.service';
import { LocalTimeHelper } from '@app/app/shared/helpers/local-time-helper';

export class CalendarHelper {
  static prepareICalFileDownload(data: string, filePrefix: string) {
    const url = 'data:text/calendar,' + encodeURIComponent(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${filePrefix}.ics`;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }

  static getDefaultCalendarRequestHeaders(languageCode?: string) {
    return {
      Accept: 'text/calendar',
      'Content-Language': languageCode ? languageCode : DEFAULT_LANGUAGE_CODE,
    };
  }

  static formatRange(startDate: Date, endDate: Date): string {
    if (!startDate || !endDate) {
      return '';
    }
    const start = LocalTimeHelper.getLocalDateTime(startDate);
    const end = LocalTimeHelper.getLocalDateTime(endDate);
    const formatDateRange = (startFormat: string, endFormat: string) => `${start.format(startFormat)} - ${end.format(endFormat)}`;
    if (start.year() === end.year()) {
      if (start.month() === end.month()) {
        if (start.date() === end.date()) {
          return end.format('D MMM YYYY');
        }
        return formatDateRange('D', 'D MMM YYYY');
      }
      return formatDateRange('D MMM', 'D MMM YYYY');
    }
    return formatDateRange('D MMM YYYY', 'D MMM YYYY');
  }
}
