<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<section class="summary-section">
  <div class="tabs-container" *ngIf="supportedLanguages.length && supportedLanguages.length > 1">
    <ptl-language-tabs
      [supportedLanguages]="supportedLanguages"
      [currentIndex]="currentIndex"
      [class.is_disabled]="languageTabChanged"
      (tabChange)="onTabChange($event)"
    >
    </ptl-language-tabs>
  </div>

  <div class="summary-container">
    <ptl-skeleton-view *ngIf="skeletonViewActive; else elseTemplate" [state]="{ loading: true }" [viewType]="'action'"> </ptl-skeleton-view>

    <ng-template #elseTemplate>
      <div class="summary-container-inner">
        <div class="summary-content">
          <h1 class="summary-content-title extra-large" [attr.aria-label]="'translations.global.ariaLabels.summary' | transloco">
            {{ 'translations.summary.title.main' | transloco }}
          </h1>

          <ptl-headers-editor
            [subheader]="subheader$ | async"
            [pageUid]="pageUid"
            [uri]="uri"
            [authorDetails]="authorDetails"
            [isDisabled]="page?.organizationUid !== page?.syndicationDetails?.publisher.organization._id"
            [currentLanguage]="currentLanguage"
            [visibleAuthor]="visibleAuthor"
            (uriUpdated)="onUriChanged($event)"
            (authorUpdate)="onAuthorUpdate($event)"
            [canEditTime]="true"
            (subheaderUpdated)="onSubheaderUpdate($event)"
          >
          </ptl-headers-editor>

          <div class="tags-section">
            <div class="tags-section-content">
              <h2 class="tags-section-title small bold" [attr.aria-label]="'translations.global.ariaLabels.tags' | transloco">
                {{ 'translations.pages.publish.title.addTags' | transloco }}
              </h2>

              <p
                *ngIf="page?.organizationUid === page?.syndicationDetails?.publisher.organization._id"
                class="tags-section-description extra-small"
                [attr.aria-label]="'translations.global.ariaLabels.addTagsToPage' | transloco"
              >
                {{ 'translations.framework.label.addTagsToPage' | transloco }}
              </p>

              <div class="tags-section-form-content">
                <div
                  class="tags-section-add-tag-form"
                  *ngIf="page?.organizationUid === page?.syndicationDetails?.publisher.organization._id"
                >
                  <div class="tags-section-add-tag-field">
                    <input
                      matInput
                      [matAutocomplete]="auto"
                      type="text"
                      placeholder="Add new tags"
                      [attr.aria-label]="'translations.global.ariaLabels.addNewTags' | transloco"
                      (paste)="onTextPaste($event)"
                      (keyup)="onSearchInputChange($event)"
                      (keydown.enter)="onSearchInputEnter($event)"
                      (focus)="onShowFrameworkTagsPanel(false)"
                    />

                    <mat-autocomplete
                      [panelWidth]="450"
                      #auto="matAutocomplete"
                      [displayWith]="disabledAutocomplete"
                      (optionSelected)="onTagSelect($event)"
                    >
                      <mat-option *ngFor="let option of filteredTags" [value]="option">
                        {{ option.title }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-spinner *ngIf="loadingTags" [diameter]="14"></mat-spinner>
                  </div>
                </div>
                <button
                  mat-stroked-button
                  color="potentially-secondary"
                  class="tags-section-frameworks-button"
                  tabindex="0"
                  [attr.aria-label]="'translations.global.ariaLabels.addFromFramework' | transloco"
                  (click)="onShowFrameworkTagsPanel(true)"
                >
                  <svg class="svg svg-icon icon-left" aria-hidden="true">
                    <use ogSvgUnify="assets/sprite/sprite.svg#framework"></use>
                  </svg>
                  <span>{{ 'translations.framework.button.framework' | transloco }}</span>
                </button>
              </div>

              <div class="tags-section-selected-items chips-list">
                <div class="tags-section-selected-item has-action-btn chip-item chip-interactive" *ngFor="let tag of tags">
                  <span class="chip-text">
                    {{ tag.title }}
                  </span>
                  <span
                    tabindex="0"
                    class="chip-item-icon"
                    [attr.aria-label]="'translations.global.ariaLabels.removeTag' | transloco"
                    (click)="removeTag(tag)"
                    (keydown.enter)="removeTag(tag)"
                    *ngIf="page?.organizationUid === page?.syndicationDetails?.publisher.organization._id"
                  >
                    <svg class="svg" role="img" aria-hidden="true">
                      <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
                    </svg>
                  </span>
                </div>

                <div *ngIf="showFrameworkTagsPanel" class="tags-section-framework-panel">
                  <ptl-add-framework-tags
                    [isDialogPage]="false"
                    (closeFrameworkTags)="onShowFrameworkTagsPanel(false)"
                    (addTagBulk)="onSubmitTags($event); onShowFrameworkTagsPanel(false)"
                  ></ptl-add-framework-tags>
                </div>
              </div>
            </div>
          </div>

          <div class="navigation-section" *ngIf="page?.type === 'STANDARD_PAGE'">
            <h2 class="small bold">{{ 'translations.pages.publish.title.navigationPanel' | transloco }}</h2>

            <mat-slide-toggle
              color="primary"
              tabindex="0"
              class="enable-page-navbar-toggle"
              [attr.aria-label]="'translations.global.ariaLabels.pageVisibility' | transloco"
              [(ngModel)]="showOnLeftNav"
              (change)="updatePageVisibilityInNav($event)"
            >
              {{ 'translations.pages.publish.label.visible' | transloco }}
            </mat-slide-toggle>
          </div>

          <div class="switch-section" *ngIf="page?.type === 'STANDARD_PAGE'">
            <h2 class="small bold">{{ 'translations.pages.publish.title.switch' | transloco }}</h2>
            <div
              class="extra-small link"
              tabindex="0"
              (click)="switchToHomePage()"
              (keydown.enter)="switchToHomePage()"
              [attr.aria-label]="'translations.global.ariaLabels.switchToHomapage' | transloco"
            >
              {{ 'translations.pages.publish.label.switchToHomePage' | transloco }}
            </div>
          </div>
        </div>
      </div>

      <div class="thumbnail-settings">
        <mat-card class="card-box">
          <button
            *ngIf="thumbnailUrl"
            mat-icon-button
            class="remove-btn"
            title="Remove thumbnail"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.removeThumbnail' | transloco"
            (click)="onThumbnailRemove()"
          >
            <mat-icon aria-hidden="true" [inline]="true">clear</mat-icon>
          </button>
          <div class="card-box-image-box" [attr.aria-label]="'translations.global.ariaLabels.thumbnailImage' | transloco">
            <img
              class="card-box-image"
              alt=""
              width="270"
              height="150"
              [src]="thumbnailUrl ? thumbnailUrl : '../../../../assets/card-placeholders/card-placeholder-1.png'"
            />
          </div>

          <div class="card-box-info-box">
            <div class="card-box-title small bold" [attr.aria-label]="title$ | async">
              {{ title$ | async }}
            </div>
            <div class="card-box-description extra-small" [attr.aria-label]="subheader$ | async">
              {{ subheader$ | async }}
            </div>
          </div>
        </mat-card>
        <div class="upload-thumbnail-buttons" *ngIf="page?.organizationUid === page?.syndicationDetails?.publisher.organization._id">
          <button
            mat-stroked-button
            class="change-thumbnail-btn uppercase"
            tabindex="0"
            [attr.aria-label]="'translations.pages.publish.label.updateThumbImage' | transloco"
            (click)="onImageUploadClick()"
          >
            {{ 'translations.pages.publish.label.updateThumbImage' | transloco }}
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</section>

<ptl-help-center [viewsType]="'page_admin_summary'"></ptl-help-center>
