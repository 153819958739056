import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DateRange, MatDatepickerInputEvent, MatDateRangePicker } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { AssessmentExportComparisonType, AssessmentExportData } from '../../model/members.model';
import { CalendarHelper } from '@app/app/shared/helpers/calendar-helper';

@Component({
  templateUrl: './assessment-export-dialog.component.html',
  styleUrls: ['./assessment-export-dialog.component.scss'],
})
export class AssessmentExportDialogComponent {
  @ViewChild('firstDatePicker') firstDatePicker: MatDateRangePicker<Date>;
  @ViewChild('secondDatePicker') secondDatePicker: MatDateRangePicker<Date>;

  includeDistanceTravel = true;
  includePercentage = true;

  firstComparisonType: AssessmentExportComparisonType = 'LATEST';
  firstBetweenRange: DateRange<Date>;
  firstBetweenStart: Date;
  firstBetweenEnd: Date;
  firstRangeInitialized = false;
  firstRangeError = false;

  secondComparisonType: AssessmentExportComparisonType = 'EARLIEST';
  secondBetweenRange: DateRange<Date>;
  secondBetweenStart: Date;
  secondBetweenEnd: Date;
  secondRangeInitialized = false;
  secondRangeError = false;

  @Output() exportCalled = new EventEmitter<AssessmentExportData>();

  constructor(private dialogRef: MatDialogRef<AssessmentExportDialogComponent>) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  toggleIncludeDistanceTravel() {
    this.includeDistanceTravel = !this.includeDistanceTravel;
    this.firstRangeError = false;
    this.secondRangeError = false;
    this.updateDateRange();
  }

  export() {
    if (this.includeDistanceTravel) {
      this.validateDateRangesInitialized();
      if (this.firstRangeError || this.secondRangeError) {
        return;
      }
    }
    this.exportCalled.emit({
      distanceTravelEnabled: this.includeDistanceTravel,
      includePercentage: this.includePercentage,
      firstType: this.firstComparisonType,
      firstStartDate: this.firstBetweenStart,
      firstEndDate: this.firstBetweenEnd,
      secondType: this.secondComparisonType,
      secondStartDate: this.secondBetweenStart,
      secondEndDate: this.secondBetweenEnd,
    });
    this.closeDialog();
  }

  firstDateRangeChanged(event: MatDatepickerInputEvent<DateRange<Date>>) {
    const range = event.value as DateRange<Date>;
    if (range) {
      const { start, end } = range;
      if (start && end) {
        this.firstBetweenStart = start;
        this.firstBetweenEnd = end;
        this.refreshFirstDateRange();
        this.firstRangeInitialized = true;
      }
    }
  }

  secondDateRangeChanged(event: MatDatepickerInputEvent<DateRange<Date>>) {
    const range = event.value as DateRange<Date>;
    if (range) {
      const { start, end } = range;
      if (start && end) {
        this.secondBetweenStart = start;
        this.secondBetweenEnd = end;
        this.refreshSecondDateRange();
        this.secondRangeInitialized = true;
      }
    }
  }

  onSecondComparisonTypeChange(event): void {
    this.secondComparisonType = event.value;
  }

  onFirstComparisonTypeChange(event): void {
    this.firstComparisonType = event.value;
  }

  firstStartDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.firstBetweenStart = event.value;
    this.updateDateRange();
  }

  firstEndDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.firstBetweenEnd = event.value;
    this.updateDateRange();
  }

  secondStartDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.secondBetweenStart = event.value;
    this.updateDateRange();
  }

  secondEndDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.secondBetweenEnd = event.value;
    this.updateDateRange();
  }

  private validateDateRangesInitialized() {
    this.firstRangeError = !this.firstRangeInitialized;
  }

  private updateDateRange() {
    if (this.firstBetweenStart && this.firstBetweenEnd) {
      this.refreshFirstDateRange();
      this.firstRangeInitialized = true;
    }
    if (this.secondBetweenStart && this.secondBetweenEnd) {
      this.refreshSecondDateRange();
      this.secondRangeInitialized = true;
    }
  }

  private refreshFirstDateRange() {
    this.firstRangeError = false;
    this.firstBetweenRange = new DateRange(this.firstBetweenStart, this.firstBetweenEnd);
  }

  private refreshSecondDateRange() {
    this.secondRangeError = false;
    this.secondBetweenRange = new DateRange(this.secondBetweenStart, this.secondBetweenEnd);
  }

  formatRange(firstBetweenStart: Date, firstBetweenEnd: Date): string {
    return CalendarHelper.formatRange(firstBetweenStart, firstBetweenEnd);
  }
}
