export class CookieHelper {
  static SetCookie(key: string, value: string, expiry: number) {
    const domain = window.location.hostname.endsWith('potential.ly') ? 'potential.ly' : window.location.hostname;
    const expires = new Date();
    expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000);
    let cookieString = key + '=' + value + ';expires=' + expires.toUTCString();
    if (domain) {
      cookieString += ';domain=.' + domain + ';path=/;SameSite=None;Secure';
    }
    document.cookie = cookieString;
  }

  static GetCookie(key: string) {
    const keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
  }

  static DeleteCookie(key: string) {
    const keyValue = this.GetCookie(key);
    this.SetCookie(key, keyValue, -1);
  }
}
