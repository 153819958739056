/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { createLineChartConfig } from '@app/app/page-modules/playlist/components/create/analytics/chart-config.utils';
import { CardEventInfo } from '@app/app/shared/models/analytics/analytics.model';
import { RedirectHelper } from '@app/app/page-modules/resource/store/editor/content/helpers/redirect.helper';
import { ApiContentLinkService } from '@app/app/shared/services/content-link/api-content-link.service';
import { ActivatedRoute, Router } from '@angular/router';

export enum EventsChartType {
  EVENT_REGISTRATIONS = 'EVENT_REGISTRATIONS',
  PLAYLIST_ENROLLMENTS = 'PLAYLIST_ENROLLMENTS',
}

@Component({
  selector: 'ptl-daily-enrollment-chart',
  templateUrl: './daily-enrollment-chart.component.html',
  styleUrls: ['../common-analytics-chart-styles.scss', './daily-enrollment-chart.component.scss'],
})
export class DailyEnrollmentChartComponent implements OnChanges {
  @Input() dailyEnrollmentCounts: Record<string, number>;
  @Input() eventIdsToRegistrationCounts: Record<string, number>;
  @Input() cardEventsInfo: CardEventInfo[];
  @Input() fixedHeight = false;
  @Input() showPlaceholder = false;
  @Input() chartType = EventsChartType.EVENT_REGISTRATIONS;

  chartConfig: ChartConfiguration<'line'>;
  sortedEventRegistrationData: { eventId: string; eventHeader: string; count: number }[] = [];
  sortOrder: 'asc' | 'desc' = 'desc';
  maxRegistrationCount = 0;
  protected readonly EventsChartType = EventsChartType;

  constructor(
    private contentLinkService: ApiContentLinkService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventIdsToRegistrationCounts']) {
      this.updateEventRegistrationData();
    }
    if (changes['dailyEnrollmentCounts'] && !this.eventIdsToRegistrationCounts) {
      this.buildChartConfig();
    }
  }

  toggleSortOrder(): void {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.sortEventRegistrationData();
  }

  openInNewTab(eventId: string): void {
    this.contentLinkService.getCardFormattedUriByCardUid(eventId).subscribe(({ isSuccess, value }) => {
      if (isSuccess && value?.formattedUri) {
        RedirectHelper.redirectByParams(
          this.ngZone,
          this.router,
          this.activatedRoute,
          {
            formattedUri: `${value.formattedUri}`,
          },
          'EVENT',
          '_blank',
        );
      }
    });
  }

  private updateEventRegistrationData(): void {
    if (!this.eventIdsToRegistrationCounts) {
      this.sortedEventRegistrationData = [];
      return;
    }

    const eventHeadersMap = this.cardEventsInfo.reduce(
      (map, info) => {
        map[info.eventCardId] = info.eventHeader;
        return map;
      },
      {} as Record<string, string>,
    );

    this.sortedEventRegistrationData = Object.entries(this.eventIdsToRegistrationCounts).map(([eventId, count]) => ({
      eventId: eventId,
      eventHeader: eventHeadersMap[eventId] || eventId,
      count,
    }));

    this.maxRegistrationCount = Math.max(...this.sortedEventRegistrationData.map((data) => data.count), 0);
    this.sortEventRegistrationData();
  }

  private sortEventRegistrationData(): void {
    this.sortedEventRegistrationData.sort((a, b) => {
      return this.sortOrder === 'desc' ? b.count - a.count : a.count - b.count;
    });
  }

  private buildChartConfig(): void {
    if (!this.dailyEnrollmentCounts) {
      this.chartConfig = null;
      return;
    }

    const sortedCounts = Object.entries(this.dailyEnrollmentCounts).sort(
      ([dateA], [dateB]) => new Date(dateA).getTime() - new Date(dateB).getTime(),
    );

    this.chartConfig = createLineChartConfig('Daily Registrations');
    this.chartConfig.data.labels = sortedCounts.map(([date]) =>
      new Date(date).toLocaleDateString(undefined, { month: 'short', day: 'numeric' }),
    );
    this.chartConfig.data.datasets[0].data = sortedCounts.map(([, count]) => count);
  }
}
