<!--
  ~ Copyright (C) 2025 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="user-search-dialog-container">
  <div class="dialog-top-info">
    <h1 class="dialog-title">{{ 'translations.playlist.mentorAccess.manageAccess' | transloco }}</h1>
    <button
      class="close-dialog-btn"
      mat-icon-button
      color="primary"
      (click)="closeDialog()"
      [attr.aria-label]="'translations.global.ariaLabels.closeDialog'"
    >
      <svg class="svg" role="img">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>

  <div>
    <p class="medium-big bold">{{ 'translations.playlist.mentorAccess.shareWith' | transloco }}</p>
    <div class="admin-mentors__search" *ngIf="!selectedUser">
      <ptl-user-search-autocomplete-new
        class="search-field"
        [loadingMembers]="isLoading"
        [members]="filteredMentors"
        [placeholder]="'translations.playlist.mentorAccess.selectMentorToShare' | transloco"
        (searchLoadedMore)="onSearchChanged($event)"
        (searchInputChanged)="onSearchChanged($event)"
        (memberSelected)="onMemberSelected($event)"
      >
      </ptl-user-search-autocomplete-new>
    </div>
  </div>

  <div *ngIf="selectedUser">
    <div class="user-item-selected">
      <div class="user-left">
        <img
          [src]="selectedUser.image || 'assets/profile_image.png'"
          alt="{{ selectedUser.firstName }} {{ selectedUser.lastName }}"
          class="avatar"
        />
        <span class="user-name">{{ selectedUser.firstName }} {{ selectedUser.lastName }}</span>
      </div>
      <div class="dialog-footer-buttons">
        <button
          mat-flat-button
          type="button"
          color="accent"
          [attr.aria-label]="'translations.global.button.cancel' | transloco"
          (click)="deselectUser()"
          (keydown.enter)="deselectUser()"
        >
          {{ 'translations.global.button.cancel' | transloco }}
        </button>
        <button
          mat-flat-button
          type="button"
          color="accent"
          [attr.aria-label]="'translations.playlist.mentorAccess.giveAccess' | transloco"
          (click)="shareSubmissionWithMentor()"
          (keydown.enter)="shareSubmissionWithMentor()"
        >
          {{ 'translations.playlist.mentorAccess.giveAccess' | transloco }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="mentorsWithAccess?.length">
    <p class="medium-big bold">{{ 'translations.playlist.mentorAccess.mentorsWithAccess' | transloco }}</p>
    <div class="access-list-scroll">
      <div class="user-item-selected" *ngFor="let mentor of mentorsWithAccess">
        <div class="user-left">
          <img [src]="mentor.image || 'assets/profile_image.png'" alt="{{ mentor.firstName }} {{ mentor.lastName }}" class="avatar" />
          <span class="user-name">{{ mentor.firstName }} {{ mentor.lastName }}</span>
        </div>
        <button
          mat-flat-button
          type="button"
          color="accent"
          [attr.aria-label]="'translations.playlist.mentorAccess.revokeAccess' | transloco"
          (click)="revokeAccess(mentor)"
          (keydown.enter)="revokeAccess(mentor)"
        >
          {{ 'translations.playlist.mentorAccess.revokeAccess' | transloco }}
        </button>
      </div>
    </div>
  </div>
</div>
