/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, OnInit, Input } from '@angular/core';
import { QuizReportResponse } from '@app/app/page-modules/resource/models';
import { Quiz } from '@app/app/shared/models/editor/quiz-content.model';
import { EditorContent, Organization } from '@app/app/shared/models';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';

@Component({
  selector: 'ptl-quiz-results-report',
  templateUrl: './quiz-results-report.component.html',
  styleUrls: ['./quiz-results-report.component.scss'],
})
export class QuizResultsReportComponent implements OnInit {
  @Input() reportType: string;
  @Input() responses: QuizReportResponse[] = [];
  @Input() quiz: Quiz;
  @Input() isReviewResult: boolean;
  @Input() currentLanguage: string;
  @Input() organization: Organization;
  @Input() playlistDownloadReportView: boolean;

  filteredResponses: QuizReportResponse[] = [];

  ngOnInit(): void {
    this.responses.forEach((response) => {
      this.filteredResponses.push({
        ...response,
        options:
          !this.isReviewResult && this.reportType === 'BRIEF'
            ? response.options.filter((option) => option.selected === true)
            : response.options,
        feedback: this.getFeedback(response.feedback),
      });
    });
  }

  getTranslatedValue(instructions): string {
    return LanguageCodeHelper.getDataByUserLanguageCode(instructions, this.organization, this.currentLanguage).value;
  }

  private getFeedback(feedback: EditorContent[]): EditorContent[] {
    return feedback?.length && (feedback.length > 1 || feedback[0].content !== '') ? feedback : [];
  }
}
