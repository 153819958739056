/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import { ObservableResult } from '../../store';
import { Observable } from 'rxjs';
import { FileNameWithUploadProgress } from './file-upload.model';

/** Injection token for the page assembly data service. */
export const RESOURCES_FILE_UPLOAD_DATA_SERVICE = new InjectionToken<FileUploadService>(
  '[File-Upload] The resources file upload data service',
);
export const ORGANIZATIONS_FILE_UPLOAD_DATA_SERVICE = new InjectionToken<FileUploadService>(
  '[File-Upload] The organizations file upload data service',
);

export interface FileUploadService {
  uploadFile(fileUrl: string, file: File, uuid4?: string, withRaport?: boolean): ObservableResult<void>;
  uploadFileDirectly(presignedUrl: string, file: File, uuid4: string, waitForOptimizer: boolean): ObservableResult<void>;
  castUploadProgress: Observable<FileNameWithUploadProgress[]>;
}
