/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import { ObservableResult } from '../../../../shared/store';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const IMPORT_USERS_SERVICE = new InjectionToken<ImportUsersService>('[Admin Members] Import Users');

/** The import users service. */
export interface ImportUsersService {
  initializeLearnerCardForUsers(playlistUid: string, cardUid: string, usersUid: string[]): ObservableResult<void>;

  initializeLearnerPlaylistForUsers(playlistUid: string, usersUid: string[]): ObservableResult<void>;
}
