/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CORE_PLAYLIST_DATA_SERVICE, PlaylistDataService } from '../../../page-modules/playlist/services/create/core/data.service';
import { AccessTokenService } from '../../services/access-token.service';
import { USER_IS_LOGGED_IN } from '../../services/api-basic-auth.service';
import { AUTH_SERVICE, BasicAuthService } from '../../services/basic-auth.service';
import * as UserAuthActions from '../../store/user-auth.actions';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';

@Component({
  template: '',
})
export class SamlSPLaunchComponent implements OnInit {
  constructor(
    private store: Store,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    @Inject(AUTH_SERVICE) private authService: BasicAuthService,
    @Inject(CORE_PLAYLIST_DATA_SERVICE) private playlistDataService: PlaylistDataService,
    private router: Router,
    private readonly accessTokenService: AccessTokenService,
  ) {
    this.accessTokenService.removeAccessToken();
  }

  ngOnInit() {
    const requestId = this.activatedRoute.snapshot.queryParamMap.get('requestId');

    if (requestId) {
      this.authService.getSamlSPToken(requestId).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.accessTokenService.saveAccessToken(value.access_token);
          localStorage.setItem(USER_IS_LOGGED_IN, 'true');
          this.store.dispatch([new UserAuthActions.FetchUserDetails(), new UserAuthActions.RedirectToAuthenticatedAppSuccess()]);
        } else {
          RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/saml/signin-error');
        }
      });
    } else {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/saml/signin-error');
    }
  }
}
