import { DEFAULT_LANGUAGE_CODE } from '../../services/languages/language.service';
import { Organization, OrganizationAuthType, OrganizationStatus } from './organization.model';
import { DEFAULT_THEME } from '../../constants/constants';

export function getDummyOrganizationData(): Organization {
  return {
    _id: '122',
    name: 'Org 1',
    logo: '',
    site: 'org1.nice.com',
    domain: 'org1.domain.com',
    landingPage: {
      landingLogo: '',
      welcomeMessage: 'WelcomeMessage',
      backgroundImage: '',
      layout: 'SPLIT_SCREEN',
      backgroundColour: '',
      useBackgroundColourFill: false,
    },
    discoveryPage: {
      status: OrganizationStatus.INACTIVE,
      uri: 'discovery',
      id: 'discovery',
      title: 'Discovery',
    },
    status: OrganizationStatus.INACTIVE,
    languages: [
      {
        code: DEFAULT_LANGUAGE_CODE,
        name: 'English',
        orientation: 'LEFT_TO_RIGHT',
        default: true,
        active: true,
      },
    ],
    type: 'FOLIO',
    whitelist: [],
    blacklist: [],
    authType: OrganizationAuthType.FORM,
    preArrivals: false,
    enabledFeatureFlags: [],
    allowRegistrations: false,
    allowFolioConnection: true,
    jiscDeepLink: '',
    ui: 'v2',
    pointClick: DEFAULT_THEME,
  };
}
