/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { RestClientService } from '../rest-client.service';
import { ObservableResult } from '../../store';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { SubheaderDataService } from './subheader-data.service';
import { ResourceSubheaderUpdateRequest } from '../../models';
import { TranslocoService } from '@ngneat/transloco';
import { LanguageCodeHelper } from '../../helpers/language-code-helper';

@Injectable()
export class ApiSubheaderDataService implements SubheaderDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  updatePlaylistSubheader(playlistUid: string, subheader: ResourceSubheaderUpdateRequest, languageCode?: string): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/headers`);
    return this.client
      .post<void>(url, subheader, null, languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorUpdatingPlaylistSubheader'))),
      );
  }

  updateCardSubheader(cardUid: string, subheader: ResourceSubheaderUpdateRequest, languageCode?: string): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/settings/headers`);
    return this.client
      .post<void>(url, subheader, null, languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorUpdatingCardSubheader'))),
      );
  }
}
