<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div
  class="framework-tracker-view__header"
  *ngIf="title"
  tabindex="0"
  [attr.aria-label]="'translations.global.ariaLabels.section' | transloco: { title: title }"
>
  <h1>{{ title }}</h1>
</div>

<ng-container *ngIf="edit || section?.learnerTags?.length">
  <div class="framework-tracker-view__content">
    <div class="left-side">
      <ng-container *ngIf="section && section?.learnerTags?.length; else elseTemplate">
        <div class="framework-tracker-view__item" *ngFor="let tag of section?.learnerTags; let i = index">
          <div class="framework-tracker-view__item-head">
            <span class="framework-tracker-view__item-score-info" tabindex="0">{{ tag.tag.title }} ({{ tag.userScore }})</span>
            <button
              *ngIf="edit"
              mat-icon-button
              type="button"
              class="remove-btn"
              [attr.aria-label]="'translations.global.ariaLabels.remove' | transloco"
              (click)="onRemoveTag(i)"
            >
              <svg class="svg svg-icon" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
              </svg>
            </button>
          </div>
          <div class="framework-tracker-view__item-progress-box">
            <div class="framework-tracker-view__item-progress-bar" [ngStyle]="{ 'background-color': generateColorStyle(i) }">
              <div
                class="framework-tracker-view__item-progress-bar-inner"
                [ngStyle]="{
                  width: viewInitialized ? tag.progress + '%' : '',
                  'background-color': generateColorStyle(i),
                }"
              ></div>
            </div>
          </div>
          <span class="framework-tracker-view__item-progress-info" tabindex="0"
            >{{ tag.scaleLevel.name }}
            <span *ngIf="tag.nextScaleLevel"> - {{ tag.nextScaleLevel.minPoints - tag.userScore }} to {{ tag.nextScaleLevel.name }}</span>
          </span>
        </div>
      </ng-container>
      <ng-template #elseTemplate>
        <div class="no-data-container">
          <div class="medium">
            {{ 'translations.framework.label.noFrameworksMessage' | transloco }}
          </div>
        </div>
      </ng-template>
    </div>

    <ng-container *ngIf="edit">
      <div class="right-side">
        <button
          class="framework-tracker-dialog__framework-btn"
          mat-flat-button
          color="primary"
          [attr.aria-label]="'translations.global.ariaLabels.browseFrameworks' | transloco"
          (click)="openFrameworkPanel()"
          (keydown.enter)="openFrameworkPanel()"
          [class.is_disabled]="tags.length >= 10"
        >
          {{ 'translations.framework.label.selectTagsOrSkills' | transloco }}
        </button>
        <div class="tags-max-count" *ngIf="tags?.length >= 10">
          {{ 'translations.framework.label.tagsMaxCount' | transloco: { number: 10 } }}
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
