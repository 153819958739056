/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { ObservableResult } from '../../../../shared/store';
import { RestClientService } from '../../../../shared/services/rest-client.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { AllContentDataService } from './all-content-data.service';
import { TranslocoService } from '@ngneat/transloco';
import { Page } from '../../../../shared/models/page';
import { AllContentCard, AllContentPage, AllContentPlaylist } from '../../../../shared/models/all-content/all-content.model';

@Injectable()
export class ApiAllContentDataService implements AllContentDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  loadAllPages(page: number, size: number, term: string): ObservableResult<Page<AllContentPage>> {
    const params = {
      page: page.toString(),
      size: size.toString(),
    };
    if (term) {
      params['term'] = term;
    }
    return this.client.get<Page<AllContentPage>>(Location.joinWithSlash(environment.apiRootUrl || '', 'pages/list'), params).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetAllContentPages'))),
    );
  }

  loadAllPlaylists(page: number, size: number, term: string): ObservableResult<Page<AllContentPlaylist>> {
    const params = {
      page: page.toString(),
      size: size.toString(),
    };
    if (term) {
      params['term'] = term;
    }
    return this.client.get<Page<AllContentPlaylist>>(Location.joinWithSlash(environment.apiRootUrl || '', 'playlists/list'), params).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetAllContentPlaylists'))),
    );
  }

  loadAllCards(page: number, size: number, term: string): ObservableResult<Page<AllContentCard>> {
    const params = {
      page: page.toString(),
      size: size.toString(),
    };
    if (term) {
      params['term'] = term;
    }
    return this.client.get<Page<AllContentCard>>(Location.joinWithSlash(environment.apiRootUrl || '', 'cards/list'), params).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetAllContentCards'))),
    );
  }
}
