/*
 * Copyright (C) 2021 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, Input } from '@angular/core';
import { ContentStoreCardData } from '../../../../../../shared/models/content-store/content-store.model';

@Component({
  selector: 'ptl-content-store-prepare-submit-card-info',
  templateUrl: './content-store-prepare-submit-card-info.component.html',
  styleUrls: ['./content-store-prepare-submit-card-info.component.scss'],
})
export class ContentStorePrepareSubmitCardInfoComponent {
  @Input() cardData: ContentStoreCardData;
}
