import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ExternalContentSummary } from '../../../models';
import { iframely } from '@iframely/embed.js';

@Component({
  selector: 'ptl-external-preview',
  templateUrl: './external-preview.component.html',
  styleUrls: ['./external-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalPreviewComponent implements AfterViewInit {
  @Input() content: ExternalContentSummary;
  @Input() folioAttachmentPreviewView = false;

  @ViewChild('htmlContent', { static: true }) public htmlContent: ElementRef;

  constructor() {
    setTimeout(() => {
      iframely.load();
    }, 500);
  }

  ngAfterViewInit() {
    if (this.content && this.content.html) {
      const fragment = document.createRange().createContextualFragment(this.wrapHtmlInStyleContainer(this.content.html));

      this.htmlContent.nativeElement.prepend(fragment);
      setTimeout(() => {
        const iframe = (this.htmlContent.nativeElement as HTMLElement).querySelector('iframe');
        if (iframe) {
          iframe.setAttribute('title', this.content.title ? this.content.title : 'no title');
        }
      }, 500);
    }
  }

  openUrl(): void {
    if (this.content?.url) {
      window.open(this.content.url, '_self');
    }
  }

  private wrapHtmlInStyleContainer(html: string): string {
    const htmlWrapperOpening = '<div class="content-media__html">';
    const htmlWrapperClosing = '</div>';
    if (html.indexOf('iframely-embed') === -1) {
      return htmlWrapperOpening + html + htmlWrapperClosing;
    }

    return html;
  }
}
