import { Injectable } from '@angular/core';
import { ToastInfo } from '../../models/toast-notifications/toast-info';
import { PushNotificationMessage } from '../../models/websocket/websocket.model';
import { ToastService } from '@app/app/shared/services/toast-notifications/toast-service';

@Injectable()
export class MockToastService implements ToastService {
  showPushNotification(pushNotification: PushNotificationMessage): void {
    return;
  }

  showSuccess(message: string): void {
    return;
  }

  showFail(message: string): void {
    return;
  }

  remove(createdAt: Date): void {
    return;
  }

  getToastList(): ToastInfo[] {
    return [];
  }
}
