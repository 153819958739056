/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { RestClientService } from '../rest-client.service';
import { SidebarTreeDataService } from './sidebar-tree-data.service';
import { ObservableResult } from '../../store';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { LeftNavItemSummary } from '../../models/sidebar-tree/sidebar-tree.model';

@Injectable()
export class ApiSidebarTreeDataService implements SidebarTreeDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  loadPageChildren(pageUri: string, publisherUri: string, contentPackageUri: string): ObservableResult<LeftNavItemSummary[]> {
    const url = `${pageUri}/children`;
    return this.client.get<LeftNavItemSummary[]>(Location.joinWithSlash(environment.apiRootUrl || '', url)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetPageChildren'))),
    );
  }

  loadPlaylistChildren(
    playlistUri: string,
    pageUri?: string,
    publisherUri?: string,
    contentPackageUri?: string,
  ): ObservableResult<LeftNavItemSummary[]> {
    const url = `${playlistUri}/children`;
    return this.client.get<LeftNavItemSummary[]>(Location.joinWithSlash(environment.apiRootUrl || '', url)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetPlaylistChildren'))),
    );
  }

  loadProjectChildren(playlistUri: string): ObservableResult<LeftNavItemSummary[]> {
    const url = `${playlistUri}/children`;
    return this.client.get<LeftNavItemSummary[]>(Location.joinWithSlash(environment.apiRootUrl || '', url)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetProjectChildren'))),
    );
  }
}
