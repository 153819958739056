/*
 * Copyright (C) 2022 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { DataFormDataService } from './data-form-data.service';
import { ObservableResult } from '../../store';
import {
  AnswerRequest,
  CreateDataFormRequest,
  DataForm,
  DataFormSummary,
  FormElementRequest,
  UserDataForm,
} from '../../models/data-form/data-form.model';
import { ItemWithUploadUrls } from '../../models/ItemWithUploadUrl';
import { Page } from '../../models/page';

@Injectable()
export class MockDataFormDataService implements DataFormDataService {
  dataForm: DataForm = {
    uid: '123',
    title: 'test title',
    creatorUid: 'someUid1',
    organizationUid: 'someOrgUid2',
    createdAt: new Date('2022-02-11T11:00:00.001'),
    lastUpdatedAt: new Date('2022-02-11T11:00:00.001'),
    mandatory: true,
    elements: [],
    instructions: [],
    targetGroups: [],
  };

  userForm: UserDataForm = {
    _id: '456',
    userUid: 'testUserUid',
    dataFormUid: '123',
    dataFormTitle: 'test title',
    organizationUid: 'someOrgUid2',
    createdAt: new Date('2022-03-11T11:00:00.001'),
    updatedAt: new Date('2022-03-11T11:00:00.001'),
    answers: [],
    status: 'NOT_STARTED',
  };

  getDataForm(dataFormUid: string): ObservableResult<DataForm> {
    return ObservableResult.ofSuccess(this.dataForm);
  }

  getDataForms(page: number): ObservableResult<Page<DataForm>> {
    return ObservableResult.ofSuccess();
  }

  createDataForm(request: CreateDataFormRequest): ObservableResult<ItemWithUploadUrls<DataForm>> {
    return ObservableResult.ofSuccess({ item: this.dataForm, uploadUrls: [] });
  }

  updateDataForm(dataFormUid: string, request: CreateDataFormRequest): ObservableResult<ItemWithUploadUrls<DataForm>> {
    return ObservableResult.ofSuccess({ item: this.dataForm, uploadUrls: [] });
  }

  deleteDataForm(dataFormUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addFormElement(dataFormUid: string, request: FormElementRequest): ObservableResult<DataForm> {
    return ObservableResult.ofSuccess(this.dataForm);
  }

  updateFormElement(dataFormUid: string, formElementUid: string, request: FormElementRequest): ObservableResult<DataForm> {
    return ObservableResult.ofSuccess(this.dataForm);
  }

  deleteFormElement(dataFormUid: string, formElementUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  reorderFormElements(dataFormUid: string, request: { elements: string[] }): ObservableResult<DataForm> {
    return ObservableResult.ofSuccess(this.dataForm);
  }

  getUserForm(dataFormUid: string): ObservableResult<UserDataForm> {
    return ObservableResult.ofSuccess(this.userForm);
  }

  getUserForms(page: number, size: number, status?: string): ObservableResult<Page<UserDataForm>> {
    return ObservableResult.ofSuccess();
  }

  createUserForm(dataFormUid: string): ObservableResult<UserDataForm> {
    return ObservableResult.ofSuccess(this.userForm);
  }

  updateUserForm(userFormUid: string, request: { answers: AnswerRequest[] }): ObservableResult<UserDataForm> {
    return ObservableResult.ofSuccess(this.userForm);
  }

  getSummaries(dataFormUids: string[]): ObservableResult<DataFormSummary[]> {
    return ObservableResult.ofSuccess();
  }

  getDataFormsDetail(dataFormUids: string[]): ObservableResult<DataForm[]> {
    return ObservableResult.ofSuccess();
  }
}
