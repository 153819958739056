/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

export const LANGUAGE_KEY = 'language_key';
export const ORGANIZATION_LANGUAGE_KEY = 'organization_language_key';
export const CONTENT_LANGUAGE_CODE = 'content_language_code';
export const FALLBACK_LANGUAGE_CODE = 'en';
export let DEFAULT_LANGUAGE_CODE = FALLBACK_LANGUAGE_CODE;
export const AVAILABLE_LANGUAGES = ['en', 'cy', 'de', 'ar', 'fr'];

@Injectable()
export class LanguageService {
  private language: string | null | undefined;

  constructor(private translocoService: TranslocoService) {}

  setLanguage(lang: string) {
    this.translocoService.setDefaultLang(lang);
    this.translocoService.setActiveLang(lang);
    this.translocoService.setFallbackLangForMissingTranslation({ fallbackLang: FALLBACK_LANGUAGE_CODE });
    localStorage.setItem(LANGUAGE_KEY, lang);
    document.documentElement.lang = lang;
  }

  getLanguage() {
    this.language = localStorage.getItem(LANGUAGE_KEY);
    if (!this.language) {
      this.language = DEFAULT_LANGUAGE_CODE;
      this.setLanguage(this.language);
    }
    return this.language;
  }

  setOrganizationLanguage(lang: string) {
    localStorage.setItem(ORGANIZATION_LANGUAGE_KEY, lang);
  }

  setOrganizationDefaultLanguage(lang: string) {
    DEFAULT_LANGUAGE_CODE = lang;
  }

  getOrganizationLanguage() {
    const lang = localStorage.getItem(ORGANIZATION_LANGUAGE_KEY);
    return lang ? lang : DEFAULT_LANGUAGE_CODE;
  }
}
