import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Form, TextBoxFormContent } from '../../../../../shared/models';
import { forkJoin, Observable, of, Subject, takeUntil } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { ResourceAdminState } from '../../../../../page-modules/resource/store/admin/resource-admin.state';
import { TEXT_FORM_MAX_WORDS_LIMIT } from '../../../../../shared/constants/constants';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CORE_RESOURCE_DATA_SERVICE, ResourceDataService } from '../../../../../page-modules/resource/services/editor/core/data.service';
import { PlaylistViewState } from '../../../../../page-modules/playlist/store/view/playlist-view.state';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelectChange } from '@angular/material/select';
import { AllContentPage, AllContentPlaylist } from '../../../../../shared/models/all-content/all-content.model';
import { canTriggerSearch } from '../../../../../shared/helpers/content-helper';

@Component({
  selector: 'ptl-form-connected-textbox',
  templateUrl: './form-connected-textbox.component.html',
  styleUrls: ['../form.component.scss', './form-connected-textbox.component.scss'],
})
export class FormConnectedTextboxComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Receives the input data object */
  private _form: Form;
  private _canRemoveItem: boolean | undefined;
  private _position: number | string | undefined;

  @Input()
  set form(value: Form) {
    if (typeof value === 'string') {
      this._form = JSON.parse(decodeURIComponent(value));
    } else {
      this._form = value;
    }
  }

  get form() {
    return this._form;
  }

  /** Position in the Form sections */
  @Input()
  set position(value: number | string | undefined) {
    if (typeof value === 'string') {
      this._position = JSON.parse(decodeURIComponent(value));
    } else {
      this._position = value;
    }
  }

  get position() {
    return this._position;
  }

  @Input()
  set canRemoveItem(value) {
    if (typeof value === 'string') {
      this._canRemoveItem = JSON.parse(decodeURIComponent(value));
    } else {
      this._canRemoveItem = value;
    }
  }

  get canRemoveItem() {
    return this._canRemoveItem;
  }

  /** Emits content data on saveForm() */
  @Output() formElementAdded: EventEmitter<Form> = new EventEmitter<Form>();

  /** Emits removing event of this form with index */
  @Output() formElementRemoved = new EventEmitter<void>();

  @ViewChild('formTitleInput') private formTitleInput: ElementRef<HTMLInputElement>;

  @Select(ResourceAdminState.contentStateChanged)
  contentStateChanged$: Observable<boolean>;

  @Select(PlaylistViewState.playlistId)
  private playlistId$: Observable<string>;

  expanded = true;
  formPlayLists: AllContentPlaylist[];
  formCardLists: AllContentPage[];
  formLists: { title: string; id: string }[];
  forceExpand = false;

  selectedPlaylistUid: string;
  selectedCardUid = '';
  selectedFormUid = '';
  focused = false;
  hasError = false;
  connectedTextboxForm: FormGroup;
  saveInProgress = false;
  searchTerm = '';
  cardTitle = '';
  formTitle = '';
  searchFormPlayListInProgress = false;
  selected = '-';

  private playlistId: string;
  private subscriptionEnd$ = new EventEmitter<void>();
  private searchInputSubject$ = new Subject<string>();

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    @Inject(CORE_RESOURCE_DATA_SERVICE) private resourceDataService: ResourceDataService,
  ) {
    this.connectedTextboxForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      wordLimit: [TEXT_FORM_MAX_WORDS_LIMIT, [Validators.required]],
      answerType: ['RICH', [Validators.required]],
      connection: this.formBuilder.group({
        playlistUid: [''],
        cardUid: ['', [Validators.required]],
        formUid: ['', [Validators.required]],
      }),
    });
  }

  ngOnInit(): void {
    this.handleFormContent();

    this.connectedTextboxForm.patchValue({ ...this.form?.content });

    this.playlistId$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((playlistId: string) => {
      if (playlistId) {
        this.playlistId = playlistId;
        this.setSelectedFields(this.playlistId);
      }
    });

    this.contentStateChanged$.pipe(takeUntil(this.subscriptionEnd$)).subscribe(() => {
      this.saveInProgress = false;
    });

    this.searchInputSubject$
      .pipe(debounceTime(300), takeUntil(this.subscriptionEnd$))
      .subscribe((searchValue: string) => this.getPlayList(searchValue));
    this.forceExpand = this.form?.content?.forceExpand;
  }

  ngAfterViewInit(): void {
    const titleInput = this.formTitleInput?.nativeElement;
    if (this.forceExpand && titleInput) {
      setTimeout(() => {
        const titleLength = titleInput.value.length;
        titleInput.focus();
        titleInput.setSelectionRange(titleLength, titleLength);
      });
    }
  }

  saveForm(): void {
    this.hasError = false;
    if (this.isTextBoxIsValid()) {
      this.expanded = false;
      this.forceExpand = false;
      this.outputData();
      this.saveInProgress = true;
    } else {
      this.hasError = true;
    }
  }

  expandForm(): void {
    this.expanded = true;
  }

  collapseForm(event: PointerEvent): void {
    event.stopPropagation();
    this.expanded = false;
    this.forceExpand = false;
  }

  outputData(): void {
    const outputData: Form = {
      ...this.form,
      newAddedForm: false,
      content: {
        ...this.connectedTextboxForm?.value,
        connection: {
          playlistUid: this.selectedPlaylistUid,
          cardUid: this.selectedCardUid,
          formUid: this.selectedFormUid,
        },
        type: 'CONNECTED_TEXTBOX',
      } as TextBoxFormContent,
    };

    this.formElementAdded.emit(outputData);
    this.updateConnectedTextboxForm(this.searchTerm, this.selectedCardUid, this.selectedFormUid);
  }

  removeForm(): void {
    this.formElementRemoved.emit();
  }

  onTextPaste(event: ClipboardEvent): void {
    if (event.type === 'paste') {
      setTimeout(() => {
        this.searchTerm = (event.target as HTMLInputElement).value.trim();
        this.searchInputSubject$.next(this.searchTerm);
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent): void {
    this.playlistId = this.playlistId ?? this.store.selectSnapshot(PlaylistViewState.playlistId);
    if (canTriggerSearch(event)) {
      this.searchFormPlayListInProgress = true;
      const searchTerm = (event.target as HTMLInputElement).value.trim();

      if (searchTerm) {
        this.searchInputSubject$.next(searchTerm);
      }
    }
  }

  getSelectedPlaylistUid(event: MatAutocompleteSelectedEvent): void {
    this.selectedPlaylistUid = event.option.id;
    this.searchTerm = event.option.value;
    this.resetCardsAndFormsFields();
    this.getFormCards(this.selectedPlaylistUid);
  }

  getSelectedCardUid(event: MatSelectChange): void {
    this.selectedCardUid = event.value;
    this.getForms(this.selectedCardUid);
  }

  getSelectedFormUid(event: MatSelectChange): void {
    this.selectedFormUid = event.value;
  }

  getFormCards(selectedPlaylistUid: string): void {
    this.resetSelectedValues(true);

    this.resourceDataService
      .getFormCardsByPlayListUid(this.playlistId, selectedPlaylistUid)
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.formCardLists = value || [];

          if (value.length === 1) {
            this.selectedCardUid = value[0].id;
            this.getForms(this.selectedCardUid);
          }
        }
      });
  }

  getForms(selectedCardUid: string): void {
    this.resetSelectedValues();

    this.resourceDataService
      .getFormsList(this.playlistId, this.selectedPlaylistUid, selectedCardUid)
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.formLists = value || [];

          if (value.length === 1) {
            this.selectedFormUid = value[0].id;
          }
        }
      });
  }

  getPlayList(searchTerm: string): void {
    this.resourceDataService
      .getFormPlayListByUid(this.playlistId, searchTerm, 0, 20)
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe(({ isSuccess, value }) => {
        this.searchFormPlayListInProgress = false;
        if (isSuccess) {
          this.formPlayLists = value?.content || [];
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptionEnd$?.emit();
  }

  setSelectedFields(playlistId: string): void {
    const connectionForm = this.connectedTextboxForm.get('connection');
    this.selectedCardUid = connectionForm.get('cardUid').value;
    this.selectedPlaylistUid = connectionForm.get('playlistUid').value;
    this.selectedFormUid = connectionForm.get('formUid').value;

    if (!this.selectedPlaylistUid) {
      return;
    }

    forkJoin({
      formCards: this.resourceDataService.getFormCardsByPlayListUid(playlistId, this.selectedPlaylistUid),
      formsList: this.resourceDataService.getFormsList(this.playlistId, this.selectedPlaylistUid, this.selectedCardUid),
      formTitles: this.resourceDataService.getFormTitlesByPlayListUid(this.selectedPlaylistUid),
    })
      .pipe(
        takeUntil(this.subscriptionEnd$),
        switchMap(({ formCards, formsList, formTitles }) => {
          if (formCards.isSuccess) {
            this.formCardLists = formCards.value || [];
          }

          if (formsList.isSuccess) {
            this.formLists = formsList.value || [];
          }

          if (formTitles.isSuccess) {
            this.searchTerm = formTitles.value.title;
          }

          return of(null);
        }),
      )
      .subscribe();
  }

  private resetSelectedValues(resetCard = false): void {
    if (resetCard) {
      this.selectedCardUid = null;
    }

    this.selectedFormUid = null;
    this.formLists = null;
  }

  private handleFormContent(): void {
    const content = this.form ? (this.form.content as TextBoxFormContent) : null;

    if (this.form && (this.form.uid || content?.title)) {
      this.setFormContent(content, this.form.newAddedForm);
    }
  }

  private updateConnectedTextboxForm(searchTerm: string, selectedCardUid: string, selectedFormUid: string): void {
    this.connectedTextboxForm.get('connection.playlistUid').setValue(searchTerm);
    this.connectedTextboxForm.get('connection.cardUid').setValue(selectedCardUid);
    this.connectedTextboxForm.get('connection.formUid').setValue(selectedFormUid);
  }

  private resetCardsAndFormsFields(): void {
    this.connectedTextboxForm.get('connection.cardUid').reset();
    this.connectedTextboxForm.get('connection.formUid').reset();
  }

  private setFormContent(content: TextBoxFormContent, newAddedForm: boolean): void {
    this.expanded = newAddedForm ?? false;
    this.connectedTextboxForm.setValue({
      title: content.title,
      wordLimit: content.wordLimit,
      answerType: 'RICH',
      connection: {
        playlistUid: this.selectedPlaylistUid || '',
        cardUid: this.selectedCardUid || '',
        formUid: this.selectedFormUid || '',
      },
    });
  }

  private isTextBoxIsValid(): boolean {
    return this.selectedPlaylistUid && this.connectedTextboxForm?.valid;
  }
}
