/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { catchError, switchMap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Page } from '../../../shared/models/page';
import { ReviewerSummary, UserReviewsCount } from '../../../shared/models/review/review.model';
import { RestClientService } from '../../../shared/services/rest-client.service';
import { TranslocoService } from '@ngneat/transloco';
import { ObservableResult } from '../../../shared/store';
import { ReviewSettingsDetails } from '../../playlist/models/reviews-settings.model';
import {
  CommentRequest,
  ReviewAdminRequest,
  ReviewItem,
  ReviewRequest,
  ReviewSummaryDetailed,
  ReviewerAdminRequest,
  SubmissionsReviewRequest,
} from '../models';
import { ReviewsDataService } from './data.service';

@Injectable()
export class ApiReviewsDataService implements ReviewsDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  getReviews(page: number, pageSize: number, term?: string): ObservableResult<Page<ReviewItem>> {
    const termUpdated = term?.length > 0 ? term : undefined;

    const url = termUpdated
      ? `reviews/items?term=${term}&page=${page}&pageSize=${pageSize}`
      : `reviews/items?page=${page}&pageSize=${pageSize}`;

    return this.client.get<Page<ReviewItem>>(Location.joinWithSlash(environment.apiRootUrl || '', url)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGettingReviews'))),
    );
  }

  getReviewItem(itemUri: string): ObservableResult<ReviewItem> {
    return this.client.get<ReviewItem>(Location.joinWithSlash(environment.apiRootUrl || '', `reviews/items/${itemUri}`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGettingReviews'))),
    );
  }

  getReviewSummaryDetailed(reviewItemUri: string, learnerUserUid: string): ObservableResult<ReviewSummaryDetailed> {
    return this.client
      .get<ReviewSummaryDetailed>(
        Location.joinWithSlash(environment.apiRootUrl || '', `reviews/items/${reviewItemUri}/users/${learnerUserUid}/details`),
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGettingReviews'))),
      );
  }

  addComment(reviewUid: string, cardUid: string, formUid: string, request: CommentRequest): ObservableResult<void> {
    return this.client
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `reviews/${reviewUid}/cards/${cardUid}/notes/${formUid}`), request)
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAddingComment'))),
      );
  }

  editComment(reviewUid: string, formUid: string, request: CommentRequest): ObservableResult<void> {
    return this.client
      .patch<void>(Location.joinWithSlash(environment.apiRootUrl || '', `reviews/${reviewUid}/notes/${formUid}`), request)
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorEditingComment'))),
      );
  }

  deleteComment(reviewUid: string, noteUid: string): ObservableResult<void> {
    return this.client.delete<void>(Location.joinWithSlash(environment.apiRootUrl || '', `reviews/${reviewUid}/notes/${noteUid}`)).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorDeletingComment'))),
    );
  }

  rejectReview(reviewUid: string, request: ReviewRequest): ObservableResult<void> {
    return this.client.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `reviews/${reviewUid}/rejection`), request).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRejectingReview'))),
    );
  }

  approveReview(reviewUid: string, request: ReviewRequest): ObservableResult<void> {
    return this.client.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `reviews/${reviewUid}/acceptance`), request).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorApprovingReview'))),
    );
  }

  requestChangeOfReview(reviewUid: string, playlistUid: string, request: ReviewRequest): ObservableResult<void> {
    return this.client
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `reviews/${reviewUid}/submissions/${playlistUid}`), request)
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRequestChangeOfReview'))),
      );
  }

  requestResubmission(request: SubmissionsReviewRequest): ObservableResult<void> {
    return this.client.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'reviews/submissions'), request).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRequestChangeOfReview'))),
    );
  }

  requestAllResubmission(reviewUid: string, request: SubmissionsReviewRequest): ObservableResult<void> {
    return this.client
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `reviews/submissions/${reviewUid}/all`), request)
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRequestChangeOfReview'))),
      );
  }

  acceptReviews(request: SubmissionsReviewRequest): ObservableResult<void> {
    return this.client.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'reviews/acceptance'), request).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorApprovingReview'))),
    );
  }

  acceptAllReviews(reviewUid: string, request: SubmissionsReviewRequest): ObservableResult<void> {
    return this.client
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `reviews/acceptance/${reviewUid}/all`), request)
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorApprovingReview'))),
      );
  }

  rejectReviewRequest(request: SubmissionsReviewRequest): ObservableResult<void> {
    return this.client.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'reviews/rejections'), request).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRejectingReview'))),
    );
  }

  rejectAllReviewRequest(reviewUid: string, request: SubmissionsReviewRequest): ObservableResult<void> {
    return this.client
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `reviews/rejections/${reviewUid}/all`), request)
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRejectingReview'))),
      );
  }

  closeReview(request: ReviewAdminRequest, isAccepted: boolean): ObservableResult<void> {
    return this.client
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `reviews/admin/close?withAcceptance=${isAccepted}`), request)
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorPerformingAdminReviewAction'))),
      );
  }

  reOpenReview(request: ReviewAdminRequest): ObservableResult<void> {
    return this.client.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'reviews/admin/reopen'), request).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorPerformingAdminReviewAction'))),
    );
  }

  updateReviewers(reviewUid: string, request: ReviewerAdminRequest): ObservableResult<void> {
    return this.client
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `reviews/${reviewUid}/admin/reviewers`), request)
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorPerformingAdminReviewAction'))),
      );
  }

  retrieveReviewers(reviewUid: string): ObservableResult<ReviewerSummary[]> {
    return this.client
      .get<ReviewerSummary[]>(Location.joinWithSlash(environment.apiRootUrl || '', `reviews/${reviewUid}/admin/reviewers`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorPerformingAdminReviewAction'))),
      );
  }

  getUserReviewsCount(): ObservableResult<UserReviewsCount> {
    return this.client.get<UserReviewsCount>(Location.joinWithSlash(environment.apiRootUrl || '', 'reviews/count')).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGettingReviews'))),
    );
  }

  getReviewSettingsDetail(playlistUid: string): ObservableResult<ReviewSettingsDetails> {
    return this.client
      .get<ReviewSettingsDetails>(Location.joinWithSlash(environment.apiRootUrl || '', `/playlists/${playlistUid}/review_settings`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetPlaylistReviewSettings'))),
      );
  }

  undoReview(reviewUid: string): ObservableResult<void> {
    return this.client.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `/reviews/${reviewUid}/undo`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorPerformingAdminReviewAction'))),
    );
  }

  cancelDecision(reviewUid: string, reviewEventUid: string, commentForLearner?: string): ObservableResult<void> {
    return this.client
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `/reviews/${reviewUid}/cancel_decision`), {
        reviewEventUid: reviewEventUid,
        commentForLearner: commentForLearner,
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorCancelReviewDecision'))),
      );
  }
}
