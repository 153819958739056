<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="workspaces-nav">
  <div class="workspaces-nav-container">
    <ul class="workspaces-nav-container-list" role="list" [attr.aria-label]="'translations.global.ariaLabels.workspaceMenu' | transloco">
      <ng-container *ngFor="let workspace of userData?.workspaces">
        <ng-container *ngIf="workspace.type !== 'FOLIO' || organization?.type === 'FOLIO'">
          <li class="workspaces-nav-container-list-item" [class.is_active]="workspace.domain === domain">
            <a
              class="workspaces-nav-container-list-item-link"
              role="link"
              tabindex="0"
              [style.backgroundImage]="'url(' + workspace.logo + ')'"
              [attr.aria-label]="'translations.global.ariaLabels.switchToWorkspace' | transloco: { workspace: workspace.name }"
              (click)="loadWorkspace(workspace.domain, workspace.organizationUid)"
              (keydown.enter)="loadWorkspace(workspace.domain, workspace.organizationUid)"
            ></a>
          </li>
        </ng-container>
      </ng-container>
      <li class="workspaces-nav-container-list-divider" role="separator" aria-hidden="true"></li>

      <li
        *ngIf="organization?.contentStoreEnabled && ((isUserAdmin$ | async) || (isUserSuperAdmin$ | async))"
        class="workspaces-nav-container-list-item static-list-item"
      >
        <a
          class="workspaces-nav-container-list-item-link"
          role="link"
          tabindex="0"
          [style.backgroundImage]="'url(https://static.yuna.potential.ly/public/images/icons/potentially_logo.png)'"
          [attr.aria-label]="'translations.global.ariaLabels.openContentStore' | transloco"
          (click)="navigateTo('/content-store')"
          (keydown.enter)="navigateTo('/content-store')"
          [routerLink]="'/content-store'"
          [routerLinkActive]="'is_active'"
        ></a>
      </li>
      <li class="workspaces-nav-container-list-item static-list-item">
        <a
          class="workspaces-nav-container-list-item-link folio-link"
          role="link"
          tabindex="0"
          [attr.aria-label]="('translations.global.ariaLabels.openFolio' | transloco) + ' ' + ('translations.sideNav.title.me' | transloco)"
          (click)="navigateTo('/folio')"
          (keydown.enter)="navigateTo('/folio')"
          [routerLink]="'/folio'"
          [routerLinkActive]="'is_active'"
          [style.backgroundImage]="!isFolioMainPage ? 'url(' + userData?.imageUrl + ')' : ''"
        >
          <div class="workspaces-nav-container-list-item-title" *ngIf="isFolioMainPage">
            {{ 'translations.sideNav.title.me' | transloco }}
          </div>
        </a>
      </li>
      <li
        *ngIf="(isV3Enabled && (isUserAdmin$ | async)) || (isUserSuperAdmin$ | async)"
        class="workspaces-nav-container-list-item static-list-item admin-members"
      >
        <a
          class="workspaces-nav-container-list-item-link folio-link"
          role="link"
          tabindex="0"
          [attr.aria-label]="'translations.global.ariaLabels.openFolio' | transloco"
          (click)="navigateTo('/admin/members')"
          (keydown.enter)="navigateTo('/admin/members')"
          [routerLink]="'/admin/members'"
          [routerLinkActive]="'is_active'"
        >
          <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.tune' | transloco">
            <use ogSvgUnify="assets/sprite/sprite.svg#settings"></use>
          </svg>
        </a>
      </li>
    </ul>
  </div>
</div>
