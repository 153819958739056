/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ptl-pass-criteria-popup',
  templateUrl: './pass-criteria-popup.component.html',
  styleUrls: ['./pass-criteria-popup.component.scss'],
})
export class PassCriteriaPopupComponent {
  @Input() value: {
    criteria: 'ALL_CORRECT' | 'PARTOF_CORRECT';
    partOf: number;
  };

  @Input() questionsCount: number;

  @Output() selectedValue = new EventEmitter<{ criteria: 'ALL_CORRECT' | 'PARTOF_CORRECT' }>();

  clickAll() {
    this.value.criteria = 'ALL_CORRECT';
    this.selectedValue.emit(this.value);
  }

  clickMin() {
    this.value.criteria = 'PARTOF_CORRECT';
    this.selectedValue.emit(this.value);
  }

  onValueChange(value: number) {
    /* eslint-disable-next-line */
    if (value != null) {
      this.value.partOf = value < 0 ? Math.abs(value) : +value;
      if (value > this.questionsCount) {
        this.value.partOf = this.questionsCount;
      }
      this.selectedValue.emit(this.value);
    }
  }
}
