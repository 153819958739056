/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { ObservableResult } from '../../store';
import { ContentLinkService, FormattedUriResponse } from './content-link.service';
import { Location } from '@angular/common';
import { environment } from '@app/environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { RestClientService } from '@app/app/shared/services/rest-client.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ApiContentLinkService implements ContentLinkService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  getPlaylistFormattedUriByPlaylistUid(playlistUid: string): ObservableResult<FormattedUriResponse> {
    return this.client
      .get<FormattedUriResponse>(Location.joinWithSlash(environment.apiRootUrl || '', `playlists/formattedUri/${playlistUid}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetFormattedUri'))),
      );
  }

  getCardFormattedUriByCardUid(cardUid: string): ObservableResult<FormattedUriResponse> {
    return this.client
      .get<FormattedUriResponse>(Location.joinWithSlash(environment.apiRootUrl || '', `cards/formattedUri/${cardUid}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetFormattedUri'))),
      );
  }
}
