<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<div class="add-from-moments-dialog__container">
  <div class="add-from-moments-dialog__header">
    <div class="add-from-moments-dialog__logo">
      <mat-icon aria-hidden="true">card_travel</mat-icon>
    </div>
    <h2 class="add-from-moments-dialog__title">{{ 'translations.collector.title.log' | transloco }}</h2>
    <button
      mat-button
      [mat-dialog-close]="false"
      class="add-from-moments-dialog__close"
      [attr.aria-label]="'translations.global.ariaLabels.closeLogItem' | transloco"
    >
      <i class="material-icons" aria-hidden="true">close</i>
    </button>
    <div class="add-from-moments-dialog__top-container">
      <div class="field-block" *ngIf="minTime !== undefined">
        <div class="field-info">
          <div *ngIf="collectorConfig?.timeLogConfig.requiredTimeUnit === 'HOURS'" class="field-title">
            {{ 'translations.collector.label.logHours' | transloco }}
          </div>
          <div *ngIf="collectorConfig?.timeLogConfig.requiredTimeUnit === 'DAYS'" class="field-title">
            {{ 'translations.collector.label.logDays' | transloco }}
          </div>
          <div class="field-description">
            <ng-container *ngIf="collectorConfig?.timeLogConfig.requiredTimeUnit === 'HOURS'">
              <span *ngIf="!maxTime">{{ 'translations.collector.description.logMinHours' | transloco: { minTime: minTime } }}</span>
              <span *ngIf="maxTime">{{
                'translations.collector.description.logMinMaxHours' | transloco: { minTime: minTime, maxTime: maxTime }
              }}</span>
            </ng-container>
            <ng-container *ngIf="collectorConfig?.timeLogConfig.requiredTimeUnit === 'DAYS'">
              <span *ngIf="!maxTime">{{ 'translations.collector.description.logMinDays' | transloco: { minTime: minTime } }}</span>
              <span *ngIf="maxTime">{{
                'translations.collector.description.logMinMaxDays' | transloco: { minTime: minTime, maxTime: maxTime }
              }}</span>
            </ng-container>
          </div>
        </div>
        <div class="field-box">
          <input
            class="field-input"
            [class.has-error]="timeError"
            autocomplete="off"
            matInput
            type="number"
            [value]="time"
            [min]="minTime"
            [max]="maxTime"
            (change)="onTimeChanged($event)"
          />
        </div>
      </div>

      <div class="field-block" *ngIf="collectorTags.length || collectorFrameworks.length">
        <div class="field-info">
          <div class="field-title">{{ 'translations.collector.label.mapAgainst' | transloco }}</div>
          <div class="field-description" *ngIf="minimumTagsCount">
            {{ 'translations.collector.description.mapAgainstMinTags' | transloco: { minimumTagsCount: minimumTagsCount } }}
          </div>
        </div>
        <div class="field-box" *ngIf="collectorFrameworks.length">
          <mat-select
            class="select-field"
            [value]="selectedFramework"
            [placeholder]="'translations.collector.select.label.selectFramework' | transloco"
            [class.has-error]="frameworkError"
            (selectionChange)="onFrameworkSelectionChange($event)"
          >
            <mat-option [value]="item" *ngFor="let item of collectorFrameworks">
              {{ item.framework.title }}
            </mat-option>
          </mat-select>
        </div>
        <div class="field-box" *ngIf="collectorTags.length">
          <form [formGroup]="tagSelectForm">
            <mat-select
              class="select-field"
              formControlName="selectField"
              [placeholder]="'translations.collector.select.label.selectTag' | transloco"
              [class.has-error]="tagsError"
              (selectionChange)="onTagSelectionChange($event)"
            >
              <mat-option [value]="tag" *ngFor="let tag of collectorTags">
                {{ tag.title }}
              </mat-option>
            </mat-select>
          </form>
        </div>
        <div class="tag-selected-items">
          <div class="tag-selected-item" *ngFor="let tag of selectedTags">
            <span>{{ tag.title }}</span>
            <span
              class="tag-selected-item-remove"
              title="Remove"
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.removeTag' | transloco"
              (click)="deleteTag(tag)"
              (keydown.enter)="deleteTag(tag)"
              >x</span
            >
          </div>
        </div>
      </div>

      <div class="field-block" *ngIf="timestampActive">
        <div class="field-info">
          <div class="field-title">{{ 'translations.collector.label.timeStamp' | transloco }}</div>
        </div>
        <div class="field-box">
          <div class="datepicker-field">
            <input
              matInput
              autocomplete="off"
              class="add-from-moments-dialog__from-input"
              [class.error]="dateError"
              [title]="dateError ? dateErrorMessage : ''"
              placeholder="from ---"
              [(ngModel)]="startedDate"
              (ngModelChange)="onDateChanged()"
              (focus)="onDateInputFocus()"
              (blur)="onDateInputBlur()"
              [max]="endedDate"
              [matDatepicker]="fromDatepicker"
            />
            <mat-datepicker-toggle
              class="add-from-moments-dialog__datepicker-toggle"
              matSuffix
              [for]="fromDatepicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #fromDatepicker></mat-datepicker>
          </div>
          <div class="datepicker-field">
            <input
              matInput
              autocomplete="off"
              class="add-from-moments-dialog__to-input"
              [class.error]="dateError"
              [title]="dateError ? dateErrorMessage : ''"
              placeholder="to ---"
              [(ngModel)]="endedDate"
              (ngModelChange)="onDateChanged()"
              (focus)="onDateInputFocus()"
              (blur)="onDateInputBlur()"
              [min]="startedDate"
              [matDatepicker]="toDatepicker"
            />
            <mat-datepicker-toggle
              class="add-from-moments-dialog__datepicker-toggle"
              matSuffix
              [for]="toDatepicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #toDatepicker></mat-datepicker>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="add-from-moments-dialog__title-box">
    <div class="form-field">
      <input
        tabindex="0"
        id="addFromMomentsContainer"
        class="form-field__input ptl-input"
        [attr.aria-label]="'translations.global.ariaLabels.enterLogTitle' | transloco"
        type="text"
        placeholder="Log title"
        [(ngModel)]="logTitle"
      />
    </div>
  </div>

  <ng-container *ngIf="evidenceTypeIsMoments">
    <div class="add-from-moments-dialog__selected-moments">
      <h2 class="add-from-moments-dialog__selected-moments-title">{{ 'translations.collector.title.selectedMoments' | transloco }}</h2>
      <div class="add-from-moments-dialog__selected-moments-container">
        <section class="add-from-moments__masonry-section" *ngIf="selectedMoments.length">
          <div class="add-from-moments__masonry-section-card" *ngFor="let moment of selectedMoments">
            <ng-container *ngIf="getMomentCard(moment)">
              <ptl-masonry-card
                [selected]="true"
                [archiveMoment]="false"
                [card]="getMomentCard(moment)"
                *ngIf="!getMomentCard(moment).textOnly"
                (cardClick)="onCardClick($event)"
              ></ptl-masonry-card>
              <ptl-masonry-text-card
                [selected]="true"
                [archiveMoment]="false"
                [card]="getMomentCard(moment)"
                *ngIf="getMomentCard(moment).textOnly"
                (cardClick)="onCardClick($event)"
              ></ptl-masonry-text-card>
            </ng-container>
          </div>
        </section>
      </div>
    </div>
    <div class="add-from-moments-dialog__available-moments">
      <div class="masonry__section-actions__row">
        <mat-form-field class="masonry__section-actions__row-field mat-no-border">
          <mat-select
            [(ngModel)]="momentType"
            (selectionChange)="filterMoments()"
            [attr.aria-label]="'translations.global.ariaLabels.filterByMomentsType' | transloco"
          >
            <mat-option value="ALL">{{ 'translations.folio.options.allMoments' | transloco }}</mat-option>
            <mat-option value="POSTS">{{ 'translations.folio.options.myPosts' | transloco }}</mat-option>
            <mat-option value="LINKS">{{ 'translations.folio.options.weblinks' | transloco }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="masonry__section-actions__row-field mat-no-border">
          <mat-select
            [(ngModel)]="orderBy"
            (selectionChange)="filterMoments()"
            [attr.aria-label]="'translations.global.ariaLabels.momentsOrderBy' | transloco"
          >
            <mat-option value="RECENT">{{ 'translations.folio.options.recentFirst' | transloco }}</mat-option>
            <mat-option value="OLDEST">{{ 'translations.folio.options.oldestFirst' | transloco }}</mat-option>
            <mat-option value="LAST_UPDATED">{{ 'translations.folio.options.lastUpdated' | transloco }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="masonry__section-actions__row-field mat-no-border">
          <span [attr.aria-label]="'translations.global.ariaLabels.search' | transloco" class="cdk-visually-hidden" #search>{{
            'translations.global.label.search' | transloco
          }}</span>
          <mat-icon matPrefix aria-hidden="true" [inline]="true">search</mat-icon>
          <input
            type="text"
            matInput
            [attr.aria-label]="'translations.global.ariaLabels.searchMoments' | transloco"
            [placeholder]="search.innerHTML"
          />
        </mat-form-field>
      </div>
      <section class="add-from-moments__masonry-section" *ngIf="availableMomentCards.length">
        <div class="add-from-moments__masonry-section-card" *ngFor="let card of availableMomentCards; let i = index">
          <ptl-masonry-card [card]="card" [archiveMoment]="false" *ngIf="!card.textOnly" (cardClick)="onCardClick($event)">
          </ptl-masonry-card>
          <ptl-masonry-text-card [card]="card" [archiveMoment]="false" *ngIf="card.textOnly" (cardClick)="onCardClick($event)">
          </ptl-masonry-text-card>
        </div>
      </section>
    </div>
  </ng-container>

  <ng-container *ngIf="!evidenceTypeIsMoments">
    <ptl-resource-preview [previewContent]="loggedTemplate" [hideTextFormButtons]="true"></ptl-resource-preview>
  </ng-container>

  <div class="add-from-moments-dialog__footer">
    <div class="add-from-moments-dialog__button-container">
      <button mat-button class="add-from-moments-dialog__cancel-btn uppercase" title="Cancel" (click)="onCancel()" color="warn">
        {{ 'translations.global.button.cancel' | transloco }}
      </button>
      <button mat-button class="add-from-moments-dialog__add-btn uppercase" title="Add" (click)="onSave()">
        {{ 'translations.global.button.add' | transloco }}
      </button>
    </div>
  </div>
</div>
