/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { InjectionToken } from '@angular/core';
import { Page as Pagination } from '../../models/page';
import { UpcomingEventCard, UpcomingEventFilter, UpcomingEventsTag } from '../../models/event-card/event-card.model';

export const SHARED_UPCOMING_DATA_SERVICE = new InjectionToken<SharedUpcomingDataService>('[Upcoming] SharedUpcomingDataService');

export interface SharedUpcomingDataService {
  getUpcomingEventsCards(
    page: number,
    size: number,
    frameworkId?: string,
    tagId?: string,
    languageCode?: string,
  ): ObservableResult<Pagination<UpcomingEventCard>>;

  getUpcomingEventsTags(page: number, size: number, term?: string): ObservableResult<Pagination<UpcomingEventsTag>>;

  hasUpcomingEvents(): ObservableResult<boolean>;

  getUpcomingEventsByFilter(page: number, size: number, filter: UpcomingEventFilter): ObservableResult<Pagination<UpcomingEventCard>>;

  getEventsEngagements(page: number, size: number, filter: UpcomingEventFilter): ObservableResult<Pagination<UpcomingEventCard>>;
}
