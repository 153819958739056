/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { UserAuthState } from '../../store/user-auth.state';
import { Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Organization } from '../../../shared/models';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from '../../../page-modules/account/services/account-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarHelper } from '../../../shared/helpers/snackbar-helper';
import { FormsHelper } from '../../../shared/helpers/forms-helper';
import { ObservableResult } from '../../../shared/store';
import { TranslocoService } from '@ngneat/transloco';
import { TokenVerification } from '../../../shared/models/account/account.model';
import { InvisibleReCaptchaComponent, ReCaptchaV3Service, ScriptService } from 'ngx-captcha';
import { environment } from '../../../../environments/environment';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { DEFAULT_PASSWORD_REGEXP } from '../../../shared/constants/constants';
import { TokenType } from '../../models';
import { MaintenanceService } from '../../../shared/services/maintenance/maintenance.service';
import { marker } from '@jsverse/transloco-keys-manager/marker';

@Component({
  templateUrl: './password-confirmation-and-recovery.component.html',
  styleUrls: ['./password-confirmation-and-recovery.component.scss'],
})
export class PasswordConfirmationAndRecoveryComponent implements OnInit, OnDestroy {
  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;

  passwordForm: FormGroup;
  showPassword: boolean;
  showConfirmPassword: boolean;
  passwordFocusState = false;
  confirmPasswordFocusState = false;
  isEmailConfirmationPage: boolean;
  reCaptchaElement = null;
  reCaptchaSiteKeyV2 = environment.reCaptchaSiteKeyV2;
  tokenType: TokenType;
  showMaintenanceOverlay: boolean;

  private organizationType: string;
  private organizationTypeSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
    private router: Router,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private translocoService: TranslocoService,
    private captchaService: ReCaptchaV3Service,
    private scriptService: ScriptService,
    private cdr: ChangeDetectorRef,
    private maintenanceService: MaintenanceService,
  ) {
    this.showMaintenanceOverlay = this.maintenanceService.showMaintenanceMessage();
  }

  ngOnInit() {
    this.organizationTypeSubscription = this.organizationData$.subscribe((organization) => {
      if (organization) {
        this.organizationType = organization.type;
      }
    });
    this.isEmailConfirmationPage =
      this.router.url.includes('verify-personal-email') ||
      this.router.url.includes('verify-alumni-email') ||
      this.router.url.includes('verify-organisation-email') ||
      this.router.url.includes('verify-organisation-email-with-folio');
    const token = this.activatedRoute.snapshot.queryParamMap.get('token');

    if (this.isEmailConfirmationPage) {
      this.getEmailFromToken(token);

      this.passwordForm = this.fb.group(
        {
          password: ['', Validators.compose([Validators.required, Validators.pattern(DEFAULT_PASSWORD_REGEXP)])],
          newPassword: ['', Validators.compose([Validators.required, Validators.pattern(DEFAULT_PASSWORD_REGEXP)])],
          email: ['', Validators.compose([Validators.required, Validators.email])],
        },
        {
          validator: FormsHelper.isEqual('password', 'newPassword'),
        },
      );
    } else {
      this.passwordForm = this.fb.group(
        {
          password: ['', Validators.compose([Validators.required, Validators.pattern(DEFAULT_PASSWORD_REGEXP)])],
          newPassword: ['', Validators.compose([Validators.required, Validators.pattern(DEFAULT_PASSWORD_REGEXP)])],
        },
        {
          validator: FormsHelper.isEqual('password', 'newPassword'),
        },
      );
    }
  }

  ngOnDestroy() {
    this.organizationTypeSubscription.unsubscribe();
  }

  onSubmitPassword() {
    if (this.passwordForm.valid) {
      this.captchaElem.execute();
    }
  }

  resetCaptcha(): void {
    this.cdr.detectChanges();
  }

  proceedIfValid(captchaResponseV2: string) {
    this.proceedSubmission(captchaResponseV2);
    setTimeout(() => {
      this.scriptService.cleanup();
      this.captchaElem.resetCaptcha();
    }, 1500);
  }

  private proceedSubmission(captchaResponseV2: string) {
    this.scriptService.cleanup();
    const password = this.passwordForm.get('password').value?.trim();
    const token = this.activatedRoute.snapshot.queryParamMap.get('token');
    const hostname = document.location.hostname;
    let observable: ObservableResult<void>;
    const captchaActionName = this.isEmailConfirmationPage ? 'folioEmailConfirmation' : 'passwordChange';
    this.captchaService.execute(environment.reCaptchaSiteKeyV3, captchaActionName, (captchaResponseV3: string) => {
      if (this.isEmailConfirmationPage) {
        observable = this.accountService.confirmWorkspaceEmailWithPassword({
          token: token,
          password: password,
          captchaResponseV3: captchaResponseV3,
          captchaResponseV2: captchaResponseV2,
          type: this.tokenType,
        });
      } else {
        observable = this.accountService.updateUserPassword(password, token, captchaResponseV3, captchaResponseV2);
      }
      observable.subscribe(({ isSuccess, error }) => {
        if (isSuccess) {
          const extraParams = this.isEmailConfirmationPage
            ? {
                queryParams: {
                  email: this.passwordForm.controls['email'].value?.trim(),
                },
              }
            : undefined;
          if (this.isEmailConfirmationPage) {
            this.checkHostnameAndRedirectToLogin(hostname, extraParams);
          } else {
            RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/signin', extraParams);
          }
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone,
            this.snackBar,
            this.translocoService,
            this.isEmailConfirmationPage
              ? marker('translations.public.message.success.folioEmailConfirmed')
              : marker('translations.public.message.success.passwordUpdated'),
          );
        } else {
          if (this.isEmailConfirmationPage) {
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
          } else {
            RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/forgot-password', {
              queryParams: { expire: 1, message: error },
            });
          }
        }
      });
    });
  }

  private checkHostnameAndRedirectToLogin(hostname: string, extraParams: { queryParams: { email: string } }) {
    if (!this.isFolioDomain(hostname) && this.organizationType === 'EDUCATIONAL') {
      if (this.tokenType === TokenType.ALUMNI_EMAIL_VERIFICATION) {
        RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/graduate/signin');
      } else {
        RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/signin', extraParams);
      }
    } else {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/signin', extraParams);
    }
  }

  private isFolioDomain(hostname: string): boolean {
    return hostname.startsWith('yuna.dev.potential.ly') || hostname.startsWith('yuna.potential.ly') || hostname.startsWith('localhost');
  }

  private getEmailFromToken(token: string) {
    const requestTokenType = this.getRequestTokenType();
    const requestParams: TokenVerification = { type: requestTokenType, token: token };
    this.accountService.getEmailByToken(requestParams).subscribe(({ value }) => {
      if (value) {
        this.passwordForm.controls['email'].setValue(value.email);
        this.tokenType = value.tokenType;
      }
    });
  }

  private getRequestTokenType() {
    let requestTokenType = null;
    if (this.router.url.includes('verify-personal-email')) {
      requestTokenType = TokenType.FOLIO_EMAIL_VERIFICATION;
    }
    if (this.router.url.includes('verify-alumni-email')) {
      requestTokenType = TokenType.ALUMNI_EMAIL_VERIFICATION;
    }
    if (this.router.url.includes('verify-organisation-email')) {
      requestTokenType = TokenType.PRE_ARRIVAL_ACCOUNT_INVITATION;
    }
    if (this.router.url.includes('verify-organisation-email-with-folio')) {
      requestTokenType = TokenType.PRE_ARRIVAL_ACCOUNT_INVITATION_WITH_FOLIO;
    }
    return requestTokenType;
  }
}
