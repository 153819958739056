<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->
<div class="role-filter-container">
  <mat-card *ngIf="expanded" class="role-filter-settings">
    <div class="role-filter-settings__header">
      <span class="small">{{ 'translations.filter.role.name' | transloco }}</span>
      <button mat-icon-button [matMenuTriggerFor]="contextMenu" class="isBtn">
        <span>
          {{ roleIs ? ('translations.filter.is' | transloco) : ('translations.filter.isNot' | transloco) }}
        </span>
        <mat-icon aria-hidden="true" class="icon">expand_more</mat-icon>
      </button>
      <span class="role-filter-delete" (click)="deleteFilter()" (keydown.enter)="deleteFilter()">
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
        </svg>
      </span>
    </div>
    <div class="role-filter-settings__content">
      <div *ngFor="let role of roles" class="role-filter-settings__item">
        <div
          class="role-status-filter-settings__checkmark checkmark"
          [class.is_checked]="role.value"
          [attr.aria-label]="
            role.value
              ? ('translations.global.ariaLabels.uncheckRoleFilter' | transloco: { roleName: role.label })
              : ('translations.global.ariaLabels.checkRoleFilter' | transloco: { roleName: role.label })
          "
          (click)="changeRole(role.role)"
          (keydown.enter)="deleteFilter()"
        >
          <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.check' | transloco">
            <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
          </svg>
        </div>
        <span class="role-status-filter-settings__label">{{ role.label }}</span>
      </div>
    </div>
    <div class="role-filter-settings__footer">
      <button
        mat-stroked-button
        class="role-filter-settings__btn"
        [attr.aria-label]="'translations.global.button.apply' | transloco"
        (click)="applyFilter()"
      >
        {{ 'translations.global.button.apply' | transloco }}
      </button>
    </div>
  </mat-card>
</div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      (click)="setRoleIs()"
      tabindex="0"
      class="is-item"
      [attr.aria-label]="'translations.global.ariaLabels.roleIs' | transloco"
    >
      <span>
        {{ 'translations.filter.is' | transloco }}
      </span>
    </button>
    <button
      mat-menu-item
      (click)="setRoleIsNot()"
      tabindex="0"
      class="is-item"
      [attr.aria-label]="'translations.global.ariaLabels.roleIsNot' | transloco"
    >
      <span>
        {{ 'translations.filter.isNot' | transloco }}
      </span>
    </button>
  </ng-template>
</mat-menu>
