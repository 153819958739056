<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="control-panel-filter-chips chips-list">
  <ng-container *ngFor="let filter of filters">
    <ng-container *ngIf="isFilterSupported(filter.type)">
      <div
        class="chip-item has-action-btn chip-interactive chip-filter menu-selector"
        (click)="filterSelected.emit(filter.type)"
        (keydown.enter)="filterSelected.emit(filter.type)"
      >
        <span class="chip-text" [matTooltip]="filter.message" matTooltipClass="medium-tooltip">
          {{ filter.message }}
        </span>
        <span class="chip-item-icon">
          <svg class="svg" role="img" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#chevron-down"></use>
          </svg>
        </span>

        <ng-container *ngIf="currentFilter === filter.type">
          <ng-container [ngSwitch]="filter.type">
            <ptl-review-status-filter
              *ngSwitchCase="'REVIEW_STATUS'"
              class="filter-settings"
              (filterDeleted)="filterDeleted.emit('REVIEW_STATUS')"
              (filterSaved)="filterSaved.emit($event)"
              [submissionMode]="submissionMode"
              [selectedFilters]="filters"
            >
            </ptl-review-status-filter>

            <ptl-group-filter
              *ngSwitchCase="'GROUP'"
              class="filter-settings"
              (filterDeleted)="filterDeleted.emit('GROUP')"
              (filterSaved)="filterSaved.emit($event)"
              [selectedFilters]="filters"
              [playlistUid]="playlistUid"
              [playlistUids]="playlistUids"
            >
            </ptl-group-filter>

            <ptl-role-filter
              *ngSwitchCase="'ROLE'"
              class="filter-settings"
              (filterDeleted)="filterDeleted.emit('ROLE')"
              (filterSaved)="filterSaved.emit($event)"
              [selectedFilters]="filters"
            >
            </ptl-role-filter>

            <ptl-attendance-filter
              *ngSwitchCase="'ATTENDANCE'"
              class="filter-settings"
              (filterDeleted)="filterDeleted.emit('ATTENDANCE')"
              (filterSaved)="filterSaved.emit($event)"
              [selectedFilters]="filters"
            >
            </ptl-attendance-filter>

            <ptl-activity-date-filter
              *ngSwitchCase="'ACTIVITY'"
              class="filter-settings"
              (filterDeleted)="filterDeleted.emit('ACTIVITY')"
              (filterSaved)="filterSaved.emit($event)"
              [selectedFilters]="filters"
            >
            </ptl-activity-date-filter>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="newFilter && isFilterSupported(newFilter)">
    <div class="chip-item has-action-btn chip-interactive menu-selector">
      <span class="chip-text">
        {{ (getFilterText(newFilter) | transloco) + ' ' + ('translations.filter.is' | transloco) + '...' }}
      </span>
      <span class="chip-item-icon">
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#chevron-down"></use>
        </svg>
      </span>
      <ng-container [ngSwitch]="newFilter">
        <ptl-review-status-filter
          *ngSwitchCase="'REVIEW_STATUS'"
          class="filter-settings"
          (filterDeleted)="filterDeleted.emit('REVIEW_STATUS')"
          (filterSaved)="filterSaved.emit($event)"
          [submissionMode]="submissionMode"
          [selectedFilters]="filters"
        >
        </ptl-review-status-filter>

        <ptl-group-filter
          *ngSwitchCase="'GROUP'"
          class="filter-settings"
          (filterDeleted)="filterDeleted.emit('GROUP')"
          (filterSaved)="filterSaved.emit($event)"
          [selectedFilters]="filters"
          [playlistUid]="playlistUid"
        >
        </ptl-group-filter>

        <ptl-role-filter
          *ngSwitchCase="'ROLE'"
          class="filter-settings"
          (filterDeleted)="filterDeleted.emit('ROLE')"
          (filterSaved)="filterSaved.emit($event)"
          [selectedFilters]="filters"
        >
        </ptl-role-filter>

        <ptl-attendance-filter
          *ngSwitchCase="'ATTENDANCE'"
          class="filter-settings"
          (filterDeleted)="filterDeleted.emit('ATTENDANCE')"
          (filterSaved)="filterSaved.emit($event)"
          [selectedFilters]="filters"
        >
        </ptl-attendance-filter>

        <ptl-activity-date-filter
          *ngSwitchCase="'ACTIVITY'"
          class="filter-settings"
          (filterDeleted)="filterDeleted.emit('ACTIVITY')"
          (filterSaved)="filterSaved.emit($event)"
          [selectedFilters]="filters"
        >
        </ptl-activity-date-filter>
      </ng-container>
    </div>
  </ng-container>
</div>
