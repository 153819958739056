/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import * as moment from 'moment';
import { EventCardsHelper } from 'src/app/shared/helpers/event-cards-helper';
import { UpcomingEventCard } from 'src/app/shared/models/event-card/event-card.model';

@Component({
  selector: 'ptl-upcoming-event-list',
  templateUrl: './upcoming-event-list.component.html',
  styleUrls: ['./upcoming-event-list.component.scss'],
})
export class UpcomingEventListComponent implements OnInit, OnChanges {
  @Input() events: UpcomingEventCard[] = [];
  @Input() showLoader = false;

  eventsOngoing: UpcomingEventCard[] = [];
  eventsIn7Days: UpcomingEventCard[] = [];
  eventsIn30Days: UpcomingEventCard[] = [];
  eventsOther: UpcomingEventCard[] = [];

  ngOnInit() {
    this.initializeEvents();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initializeEvents();
  }

  private initializeEvents() {
    this.eventsOngoing = [];
    this.eventsIn7Days = [];
    this.eventsIn30Days = [];
    this.eventsOther = [];

    for (const event of this.events) {
      const hoursToSubtract = EventCardsHelper.getHoursToSubtract(cloneDeep(event.time.timeZone));
      const eventTimes = EventCardsHelper.getStartAndEndTimes(cloneDeep(event.time.start), cloneDeep(event.time.end), hoursToSubtract);
      const startDate = new Date(eventTimes.start);
      const now = moment();
      if (eventTimes.end) {
        const endDate = new Date(eventTimes.end);
        if (moment(cloneDeep(startDate)).isBefore(cloneDeep(now)) || moment(cloneDeep(endDate)).isBefore(cloneDeep(now))) {
          this.eventsOngoing.push(event);
        } else if (
          moment(cloneDeep(startDate)).isBefore(cloneDeep(now).add(7, 'days')) ||
          moment(cloneDeep(endDate)).isBefore(cloneDeep(now).add(7, 'days'))
        ) {
          this.eventsIn7Days.push(event);
        } else if (
          moment(cloneDeep(startDate)).isBefore(cloneDeep(now).add(30, 'days')) ||
          moment(cloneDeep(endDate)).isBefore(cloneDeep(now).add(30, 'days'))
        ) {
          this.eventsIn30Days.push(event);
        } else {
          this.eventsOther.push(event);
        }
      } else {
        if (moment(cloneDeep(startDate)).isBefore(cloneDeep(now))) {
          this.eventsOngoing.push(event);
        } else if (moment(cloneDeep(startDate)).isBefore(cloneDeep(now).add(7, 'days'))) {
          this.eventsIn7Days.push(event);
        } else if (moment(cloneDeep(startDate)).isBefore(cloneDeep(now).add(30, 'days'))) {
          this.eventsIn30Days.push(event);
        } else {
          this.eventsOther.push(event);
        }
      }
    }
    this.sortEventByDate();
  }

  private sortEventByDate() {
    this.sortEventArrayByStartDate(this.eventsIn7Days);
    this.sortEventArrayByStartDate(this.eventsIn30Days);
    this.sortEventArrayByStartDate(this.eventsOther);
  }

  private sortEventArrayByStartDate(eventArray: UpcomingEventCard[]) {
    eventArray.sort((first, second) => {
      const firstHoursToSubtract = EventCardsHelper.getHoursToSubtract(cloneDeep(first.time.timeZone));
      const firstEventTimes = EventCardsHelper.getStartAndEndTimes(
        cloneDeep(first.time.start),
        cloneDeep(first.time.end),
        firstHoursToSubtract,
      );

      const secondHoursToSubtract = EventCardsHelper.getHoursToSubtract(cloneDeep(second.time.timeZone));
      const secondEventTimes = EventCardsHelper.getStartAndEndTimes(
        cloneDeep(second.time.start),
        cloneDeep(second.time.end),
        secondHoursToSubtract,
      );

      return firstEventTimes.start.localeCompare(secondEventTimes.start);
    });
  }
}
