<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="web-link-editor__container" [style.height]="frameworkTagsPanelOpen ? '50rem' : ''">
  <div class="web-link-editor__content" [class.in-card-editor]="isInCardEditPage">
    <div class="web-link-editor__link-input-container">
      <div class="web-link-editor__link-input-container-inner">
        <div class="web-link-editor__link-icon">
          <i class="material-icons" aria-hidden="true">link</i>
        </div>
        <div class="web-link-editor__link-input-box">
          <input
            class="web-link-editor__link-input"
            matInput
            type="text"
            placeholder="Paste link and press Enter"
            [class.error]="linkIsEmpty"
            [(ngModel)]="linkUrl"
            (keydown.enter)="onLinkInput()"
            (blur)="onInsertLink()"
            (input)="onLinkChanged()"
          />
          <mat-icon aria-hidden="true" *ngIf="!linkIsEmpty && linkInserted">checkmark</mat-icon>
        </div>
        <div class="web-link-editor__error-message" *ngIf="linkIsEmpty">
          {{ 'translations.urlRequiredError' | transloco }}
        </div>
      </div>
    </div>
    <div class="web-link-editor__card-preview-container">
      <div class="web-link-editor__card-preview">
        <button *ngIf="thumbnailUrl" mat-button class="button__remove" title="Remove thumbnail" (click)="onThumbnailRemove()">
          <mat-icon aria-hidden="true" [inline]="true">clear</mat-icon>
        </button>
        <div class="web-link-editor__card-thumb-image">
          <img
            class="web-link-editor__card-thumb-image-inner"
            alt=""
            width="266"
            height="150"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.thumbnailImage' | transloco"
            [src]="thumbnailUrl ? thumbnailUrl : imageUrl ? imageUrl : '/assets/card-placeholders/card-placeholder-1.png'"
          />
        </div>
        <h3
          [title]="cardHeadline.trim()"
          class="web-link-editor__card-headline"
          tabindex="0"
          [attr.aria-label]="cardHeadline.trim() ? cardHeadline.trim() : isInCardEditPage ? 'No title' : 'Weblink'"
        >
          {{ cardHeadline.trim() ? cardHeadline.trim() : isInCardEditPage ? 'No title' : 'Weblink' }}
        </h3>
        <span class="web-link-editor__card-subtitle">{{ cardSubtitle }}</span>
      </div>
      <button
        mat-button
        [class.is_disabled]="!externalContent"
        class="web-link-editor__change-thumbnail-btn uppercase"
        color="primary"
        (click)="fileInput.click()"
      >
        {{ 'translations.completion.button.updateThumbnailImage' | transloco }}
      </button>
      <input #fileInput type="file" hidden [accept]="'image/*'" (change)="onThumbnailUpload($event)" />
    </div>
    <div class="web-link-editor__card-edit" [class.is_disabled]="!externalContent && !isInCardEditPage">
      <input
        class="web-link-editor__headline-input"
        matInput
        type="text"
        placeholder="Change title"
        autocomplete="off"
        #titleInput
        (input)="onTitleChanged($event)"
        [title]="titleIsEmpty ? 'Empty title' : ''"
        [(ngModel)]="cardHeadline"
      />
      <input
        class="web-link-editor__subtitle-input"
        matInput
        type="text"
        placeholder="Change subheader"
        autocomplete="off"
        (input)="onSubtitleChanged($event)"
        [(ngModel)]="cardSubtitle"
      />
      <div class="web-link-editor__add-tags" *ngIf="!isProject && !isInCardEditPage">
        <h2 tabindex="0" [attr.aria-label]="'translations.mediaContent.title.whatIsThisCardAbout' | transloco">
          {{ 'translations.mediaContent.title.whatIsThisCardAbout' | transloco }}
        </h2>
        <span tabindex="0" [attr.aria-label]="'translations.mediaContent.label.addATagToLetOthersKnowWhatThisCardIsAbout' | transloco">
          {{ 'translations.mediaContent.label.addATagToLetOthersKnowWhatThisCardIsAbout' | transloco }}</span
        >
        <div class="web-link-editor__tag-box" [class.is_disabled]="tags.length >= 10">
          <span>{{ 'translations.framework.label.browse' | transloco }} </span>
          <span
            class="web-link-editor__framework-btn"
            tabindex="0"
            (click)="frameworkTagsPanelOpen = true"
            (keydown.enter)="frameworkTagsPanelOpen = true"
            [attr.aria-label]="'translations.global.ariaLabels.browseFrameworks' | transloco"
          >
            <span class="web-link-editor__framework-icon"></span>
            <span>{{ 'translations.framework.button.frameworks' | transloco }}</span>
          </span>
          <span> {{ 'translations.framework.label.or' | transloco }} </span>
          <input
            matInput
            type="text"
            autocomplete="off"
            tabindex="0"
            class="web-link-editor__add-new-tag-input"
            placeholder="Add tag"
            (keydown.enter)="onNewTagInput($event)"
          />
          <div class="web-link-editor__framework-panel" *ngIf="frameworkTagsPanelOpen">
            <ptl-add-framework-tags
              [isDialogPage]="false"
              (closeFrameworkTags)="frameworkTagsPanelOpen = false"
              (addTagBulk)="onAddTags($event)"
            >
            </ptl-add-framework-tags>
          </div>
        </div>
        <div class="web-link-editor__tags-container">
          <span class="web-link-editor__tag" *ngFor="let tag of tags; index as i">
            <span class="title" [title]="tag.title">{{ tag.title }}</span>
            <span
              class="remove"
              title="Remove"
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.removeTag' | transloco"
              (click)="onRemoveTags(i)"
              (keydown.enter)="onRemoveTags(i)"
              >x</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
