/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { marker } from '@jsverse/transloco-keys-manager/marker';
import { ReviewEventType } from '../models';

@Pipe({
  name: 'learnerReviewStatus',
})
export class ReviewStatusPipe implements PipeTransform {
  transform(value: ReviewEventType, type: 'dateLabel' | 'badge' | 'dashboard' | 'text', reviewEventStyle: boolean = false): string {
    if (type === 'dateLabel') {
      switch (value) {
        case ReviewEventType.RESUBMITTED:
          if (reviewEventStyle) {
            return marker('translations.reviews.status.dateLabel.resubmittedOn');
          } else {
            return marker('translations.reviews.status.dateLabel.2ndVersionSentOn');
          }
        case ReviewEventType.REJECTED_TO_REVIEW:
          return marker('translations.reviews.status.dateLabel.reviewRequestRejectedOn');
        case ReviewEventType.ACCEPTED:
          return marker('translations.reviews.status.dateLabel.approvedOn');
        case ReviewEventType.CLOSED:
          return marker('translations.reviews.status.dateLabel.closedOn');
        case ReviewEventType.REOPENED:
          return marker('translations.reviews.status.dateLabel.reopenedOn');
        case ReviewEventType.SUBMITTED_FOR_REVIEW:
          return marker('translations.reviews.status.dateLabel.submittedOn');
        case ReviewEventType.REQUIRES_RESUBMISSION:
          return marker('translations.reviews.status.dateLabel.changesRequested');
        case ReviewEventType.CANCEL_DECISION:
          return marker('translations.reviews.status.dateLabel.canceledDecision');
      }
    }

    if (type === 'text') {
      // @jsverse/transloco
      /**
       * t(translations.reviews.status.text.open)
       * t(translations.reviews.status.text.submitted_for_review)
       * t(translations.reviews.status.text.accepted)
       * t(translations.reviews.status.text.requires_resubmission)
       * t(translations.reviews.status.text.resubmitted)
       * t(translations.reviews.status.text.rejected_to_review)
       * t(translations.reviews.status.text.closed)
       * t(translations.reviews.status.text.reopened)
       * t(translations.reviews.status.text.cancel_decision)
       */
      return 'translations.reviews.status.text.' + value.toLowerCase();
    }

    if (type === 'badge') {
      if (reviewEventStyle) {
        return '';
      } else {
        switch (value) {
          case ReviewEventType.SUBMITTED_FOR_REVIEW:
          case ReviewEventType.RESUBMITTED:
            return marker('translations.reviews.status.badge.needsReview');
          case ReviewEventType.REQUIRES_RESUBMISSION:
            return marker('translations.reviews.status.badge.changesRequested');
          default:
            return '';
        }
      }
    }

    if (type === 'dashboard') {
      switch (value) {
        case ReviewEventType.SUBMITTED_FOR_REVIEW:
          return marker('translations.reviews.status.dashboard.needsReview');
        case ReviewEventType.RESUBMITTED:
          return marker('translations.reviews.status.dashboard.needsAnotherReview');
        case ReviewEventType.REQUIRES_RESUBMISSION:
          return marker('translations.reviews.status.dashboard.changesRequested');
        case ReviewEventType.ACCEPTED:
          return marker('translations.reviews.status.dashboard.approved');
        case ReviewEventType.REJECTED_TO_REVIEW:
          return marker('translations.reviews.status.dashboard.reviewRequestRejected');
        case ReviewEventType.CLOSED:
          return marker('translations.reviews.status.dashboard.closed');
        case ReviewEventType.REOPENED:
          return marker('translations.reviews.status.dashboard.reOpened');
        case ReviewEventType.CANCEL_DECISION:
          return marker('translations.reviews.status.dashboard.canceledDecision');
        default:
          return '';
      }
    }

    return '';
  }
}
