/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

export class TimezoneHelper {
  static getTimeZoneDisplayList(): TimeZoneDisplay[] {
    const timeZoneList = TimezoneHelper.getTimeZoneList();
    const timeZones: { title: string; value: string }[] = [];

    for (let i = 0; i < timeZoneList.length; i++) {
      timeZones.push({
        title: `GMT ${timeZoneList[i].offset}:00 ${timeZoneList[i].location}`,
        value: `GMT${timeZoneList[i].offset}00`,
      });
    }

    return timeZones;
  }

  static getMapFormattedDisplayTimezoneList(): TimeZoneMap {
    const timezoneList = this.getTimeZoneList();
    const formattedMapTimezone: TimeZoneMap = {};

    timezoneList.forEach((item) => {
      const timezoneKey = 'GMT' + item.offset + '00';
      formattedMapTimezone[timezoneKey] = 'GMT ' + item.offset + ':00 ' + item.location;
    });

    return formattedMapTimezone;
  }

  private static getTimeZoneList(): TimeZone[] {
    const timeZoneList = this.getStaticTimeZoneArray();
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localGmtOffset = this.getLocalGmtOffset();

    const formattedLocalGmtOffset = localGmtOffset.substring(0, localGmtOffset.length - 2);

    const currentItemFromList = timeZoneList.find((timezone) => {
      return timezone.offset === formattedLocalGmtOffset;
    });

    if (currentItemFromList) {
      currentItemFromList.location = localTimezone;
    }

    return timeZoneList;
  }

  private static getLocalGmtOffset(): string {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    return (
      (offset > 0 ? '-' : '+') + String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0') + String(Math.abs(offset) % 60).padStart(2, '0')
    );
  }

  private static getStaticTimeZoneArray(): TimeZone[] {
    return [
      { offset: '+12', location: 'Pacific/Auckland' },
      { offset: '+11', location: 'Pacific/Guadalcanal' },
      { offset: '+10', location: 'Australia/Sydney' },
      { offset: '+09', location: 'Asia/Tokyo' },
      { offset: '+08', location: 'Asia/Shanghai' },
      { offset: '+07', location: 'Asia/Bangkok' },
      { offset: '+06', location: 'Asia/Dhaka' },
      { offset: '+05', location: 'Asia/Karachi' },
      { offset: '+04', location: 'Asia/Dubai' },
      { offset: '+03', location: 'Asia/Riyadh' },
      { offset: '+02', location: 'Africa/Johannesburg' },
      { offset: '+01', location: 'Europe/Berlin' },
      { offset: '+00', location: 'Europe/London' },
      { offset: '-01', location: 'Atlantic/Cape Verde' },
      { offset: '-03', location: 'America/Sao Paulo' },
      { offset: '-04', location: 'America/Caracas' },
      { offset: '-05', location: 'America/New York' },
      { offset: '-06', location: 'America/Mexico City' },
      { offset: '-07', location: 'America/Denver' },
      { offset: '-08', location: 'America/Los Angeles' },
      { offset: '-09', location: 'America/Anchorage' },
      { offset: '-10', location: 'Pacific/Honolulu' },
      { offset: '-11', location: 'Pacific/Pago Pago' },
    ];
  }
}

export interface TimeZone {
  offset: string;
  location: string;
}

export interface TimeZoneDisplay {
  title: string;
  value: string;
}

export interface TimeZoneMap {
  [key: string]: string;
}
