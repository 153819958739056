/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { ObservableResult } from '../../../../shared/store';
import { RestClientService } from '../../../../shared/services/rest-client.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { UnsplashImagesDataService } from './unsplash-images-data.service';
import { UnsplashPhotoList, UsableUnsplashPhoto } from '../../../../shared/models/images/unsplash-images.model';

@Injectable()
export class ApiUnsplashImagesDataService implements UnsplashImagesDataService {
  constructor(
    private restClient: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  search(phrase: string, page: number): ObservableResult<UnsplashPhotoList> {
    return this.restClient
      .get<UnsplashPhotoList>(Location.joinWithSlash(environment.apiRootUrl || '', `cards/images/search?query=${phrase}&page=${page}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.unableToLoadImage'))),
      );
  }

  download(url: string): ObservableResult<UsableUnsplashPhoto> {
    return this.restClient
      .post<UsableUnsplashPhoto>(Location.joinWithSlash(environment.apiRootUrl || '', 'cards/images/downloads'), { url: url })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.unableToLoadImage'))),
      );
  }
}
