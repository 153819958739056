<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="check-in-dialog-content">
  <div class="dialog-top-info">
    <h1 class="dialog-title">{{ 'translations.qrcode.checkIn' | transloco }}</h1>
    <p class="dialog-description medium">{{ 'translations.qrcode.dialog.show' | transloco }}</p>
    <button
      class="close-dialog-btn"
      mat-icon-button
      color="primary"
      (click)="closeDialog()"
      [attr.aria-label]="'translations.global.ariaLabels.closeDialog'"
    >
      <svg class="svg" role="img" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>
  <div class="check-in-data" #checkInData>
    <div class="check-in-data__header">
      <p *ngIf="startDate" class="dialog-date medium">
        <span>{{ startDate | date: 'dd MMM yyyy' }}</span>
        <span *ngIf="endDate">{{ ' - ' + (endDate | date: 'dd MMM yyyy') }}</span>
      </p>
      <h2 class="dialog-subtitle">{{ title }}</h2>
      <p class="dialog-scan-instructions medium">{{ 'translations.qrcode.dialog.scan' | transloco }}</p>
    </div>
    <ptl-qr-code [qrCodeData]="url" [qrCodeLabel]="'qrcode.eventUrl'"></ptl-qr-code>
  </div>
  <div class="check-in-information small">
    <div>
      <span>
        {{ 'translations.qrcode.information.scanningQrCode' | transloco }}
      </span>
      <span class="small bold">
        {{ 'translations.qrcode.information.beforeStartOfEvent' | transloco }}
      </span>
      <span>
        {{ 'translations.qrcode.information.marksUserAsParticipated' | transloco }}
      </span>
    </div>
    <div>
      <span>
        {{ 'translations.qrcode.information.scanningQrCode' | transloco }}
      </span>
      <span class="small bold">
        {{ 'translations.qrcode.information.upTo24HoursAfterEventEndDate' | transloco }}
      </span>
      <span>
        {{ 'translations.qrcode.information.marksUserAsParticipated' | transloco }}
      </span>
    </div>
  </div>
  <button mat-button (click)="downloadQRCode()" class="download-btn">
    <svg class="svg svg-icon icon-left" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.download' | transloco">
      <use ogSvgUnify="assets/sprite/sprite.svg#download"></use>
    </svg>
    <span>{{ 'translations.qrcode.download' | transloco }}</span>
  </button>
  <div id="download">
    <!-- eslint-disable-next-line -->
    <img #canvas />
    <!-- eslint-disable-next-line -->
    <a #downloadLink></a>
  </div>
</div>
