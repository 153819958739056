/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  Organization,
  DiagnosticQuestionResponse,
  LanguageValue,
  DiagnosticQuestionOptionWithSelection,
  EditorContent,
} from '../../../../../../shared/models';
import { LanguageCodeHelper } from '../../../../../../shared/helpers/language-code-helper';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'ptl-diagnostics-view-slider-question-type',
  templateUrl: './slider-question-type.component.html',
  styleUrls: ['./slider-question-type.component.scss'],
})
export class DiagnosticsViewSliderQuestionTypeComponent implements OnInit, AfterViewInit {
  /** Receives the question to answer */
  @Input() question: DiagnosticQuestionResponse;
  @Input() answeredQuestions;
  @Input() disabled: boolean;
  @Input() isQuiz: boolean;
  @Input() currentLanguage: string;
  @Input() organization: Organization;

  /** Emits the answer when selected. */
  @Output() sliderChange = new EventEmitter<{ value: number }>();

  @ViewChild('ariaMiddle') private ariaMiddle: ElementRef;
  @ViewChild('ariaLeft') private ariaLeft: ElementRef;
  @ViewChild('ariaRight') private ariaRight: ElementRef;
  @ViewChild('ariaLeftEnd') private ariaLeftEnd: ElementRef;
  @ViewChild('ariaRightEnd') private ariaRightEnd: ElementRef;

  leftText: string;
  rightText: string;
  leftScore: number;
  rightScore: number;
  selectedValue: number;
  ariaLabelText: string;
  sliderHasChanged = false;
  leftInstruction: EditorContent[];
  rightInstruction: EditorContent[];

  private currentValue: number;
  private previousValue: number;

  constructor(private cdref: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (this.question) {
      this.parseText(this.question.options);

      if (this.leftScore !== undefined && this.rightScore !== undefined) {
        this.selectedValue = (this.rightScore + this.leftScore) / 2;
        this.previousValue = this.selectedValue;
      }

      let selectedOption;

      if (!this.isQuiz) {
        const selectedOptions = this.answeredQuestions[this.question.uid];

        if (selectedOptions) {
          selectedOption = selectedOptions && selectedOptions[0] ? selectedOptions[0] : '';
        }
      } else {
        selectedOption = this.answeredQuestions.selectedOptions[0];
      }

      if (selectedOption && selectedOption.optionUid) {
        for (const option of this.question.options) {
          if (selectedOption.optionUid === option.uid) {
            this.selectedValue = selectedOption.userScore;
            this.currentValue = selectedOption.userScore;
            this.previousValue = selectedOption.userScore;
            this.sliderChanged({ value: selectedOption.userScore, source: undefined }, true);
            break;
          }
        }
      }
    }
  }

  public ngAfterViewInit(): void {
    this.updateSliderAriaLabel();
    this.cdref.detectChanges();
  }

  public sliderChanged(range: MatSliderChange, override = false): void {
    this.sliderHasChanged = true;
    if (this.disabled && !override) {
      return;
    }
    this.updateSliderAriaLabel(range.value);
    this.sliderChange.emit(range);
  }

  formatLabel(value: number): string {
    console.log(value);
    console.log(this.question);
    if (this.question?.showScore) {
      return value.toString();
    }
    return '';
  }

  getTranslatedValue(values: LanguageValue[]) {
    return LanguageCodeHelper.getDataByUserLanguageCode(values, this.organization, this.currentLanguage).value;
  }

  private isLeftSlide(value): boolean {
    const isLeft = this.previousValue > value;
    this.previousValue = value;
    return isLeft;
  }

  private getDefaultAriaLabel(): string {
    return (this.ariaMiddle.nativeElement as HTMLElement).innerText;
  }

  private getAriaLabelBySide(isLeftSide: boolean): string {
    let ariaText: string;
    if (isLeftSide) {
      ariaText = (this.ariaLeft?.nativeElement as HTMLElement)?.innerText;
      if (this.currentValue === this.leftScore) {
        ariaText = (this.ariaLeftEnd?.nativeElement as HTMLElement)?.innerText;
      }
    } else {
      ariaText = (this.ariaRight?.nativeElement as HTMLElement)?.innerText;
      if (this.currentValue === this.rightScore) {
        ariaText = (this.ariaRightEnd?.nativeElement as HTMLElement)?.innerText;
      }
    }
    return ariaText;
  }

  private updateSliderAriaLabel(range?: number): void {
    const isLeft = range ? this.isLeftSlide(range) : undefined;

    if (range) {
      this.currentValue = range;
    }

    const slider = document.getElementsByClassName('question-card__slider')[0];
    // @ts-ignore
    slider?.setAttribute('aria-valuenow', null);
    slider?.removeAttribute('aria-valuemax');
    slider?.removeAttribute('aria-valuemin');
    if (range) {
      this.ariaLabelText = this.getAriaLabelBySide(isLeft);
    } else {
      this.ariaLabelText = this.getDefaultAriaLabel();
    }
    // @ts-ignore
    slider?.setAttribute('aria-valuenow', Number(this.selectedValue));
    slider?.setAttribute('aria-label', this.ariaLabelText);
    slider?.setAttribute('aria-valuetext', this.ariaLabelText);
  }

  private parseText(options: DiagnosticQuestionOptionWithSelection[]) {
    options.forEach((option) => {
      if (option.direction === 'LEFT') {
        this.leftText = this.getTranslatedValue(option.values);
        if (option.instructions) {
          this.leftInstruction = option.instructions;
        }
        this.leftScore = option.score;
      }

      if (option.direction === 'RIGHT') {
        this.rightText = this.getTranslatedValue(option.values);
        if (option.instructions) {
          this.rightInstruction = option.instructions;
        }
        this.rightScore = option.score;
      }
    });
  }
}
