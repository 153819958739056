/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { DiagnosticLogic, Diagnostics } from '../../../../shared/models';
import { filter } from 'rxjs/operators';
import { ChartContent, ChartItem, ChartType } from '../../../../shared/models/editor/chart-content.model';
import { TranslocoService } from '@ngneat/transloco';
import { ResourceAdminState } from '../../../../page-modules/resource/store/admin/resource-admin.state';
import { DialogService } from '../../../../shared/helpers/dialog/dialog.service';

type formattedLearningGoals = {
  id: string;
  checked: boolean;
  title: string;
  color: string;
  maxIcon?: string;
  minIcon?: string;
  tagUid?: string;
};

@Component({
  selector: 'ptl-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnDestroy {
  @Select(ResourceAdminState.resource)
  private resource$: Observable<Diagnostics>;

  private _content: ChartContent | undefined;
  private _canRemoveItem = true;

  @Input()
  set content(value: ChartContent | undefined) {
    if (typeof value === 'string') {
      this._content = JSON.parse(decodeURIComponent(value));
    } else {
      this._content = value;
    }
  }

  get content() {
    return this._content;
  }

  @Input()
  set canRemoveItem(value) {
    if (typeof value === 'string') {
      this._canRemoveItem = JSON.parse(decodeURIComponent(value));
    } else {
      this._canRemoveItem = value;
    }
  }

  get canRemoveItem() {
    return this._canRemoveItem;
  }

  @Output() removeChartContent = new EventEmitter<void>();
  @Output() updateChartContent = new EventEmitter<ChartContent>();

  private subscriptionEnd$ = new EventEmitter<void>();

  isEditing = true;
  selectedChartTitle = 'Radial';
  selectedChartType: ChartType = 'RADIAL';
  percentageShown = false;
  checkedItemIds = {};
  chartContentRequest: ChartContent;
  savedData: ChartItem[] = [];

  charts = [
    {
      type: 'RADIAL',
      title: 'Radial',
    },
    {
      type: 'BAR',
      title: 'Bar',
    },
  ];

  private resourceSubscription: Subscription;
  public learnerGoals: formattedLearningGoals[];

  constructor(
    private translocoService: TranslocoService,
    private dialogService: DialogService,
  ) {
    this.isEditing = false;
  }

  ngOnInit() {
    this.resourceSubscription = this.resource$.pipe(filter((data) => !!data)).subscribe((card) => {
      this.learnerGoals = card.logic ? this.getLearningGoals(card.logic.learningGoals) : [];

      if (!this.content.uid || this.content.shouldSaveChart) {
        this.saveChart();
      }
    });
  }

  ngOnDestroy() {
    this.resourceSubscription?.unsubscribe();
    this.subscriptionEnd$?.emit();
  }

  onChartChange(type: ChartType) {
    for (const cart of this.charts) {
      if (cart.type === type) {
        this.selectedChartTitle = cart.title;
        this.selectedChartType = type;
        break;
      }
    }
  }

  saveChart() {
    this.isEditing = false;
    this.savedData = [];
    for (const learningGoal of this.learnerGoals) {
      if (this.checkedItemIds[learningGoal.id]) {
        this.savedData.push({
          itemUid: learningGoal.id,
          color: learningGoal.color,
        });
      }
    }
    this.chartContentRequest = {
      ...this.content,
      showPercentage: this.percentageShown,
      chartType: this.selectedChartType,
      items: this.savedData,
    };
    this.updateChartContent.emit(this.chartContentRequest);
    this.content.shouldSaveChart = false;
  }

  deleteChart() {
    this.dialogService
      .showConfirmDialog(this.translocoService.translate('translations.dialog.title.removeSection'), this.translocoService)
      .then((confirmed) => {
        if (confirmed) {
          this.removeChartContent.emit();
        }
      });
  }

  editChart() {
    this.isEditing = true;
  }

  onCheckboxChange(item) {
    item.checked = !item.checked;

    if (item.checked) {
      this.checkedItemIds[item.id] = true;
    } else {
      this.checkedItemIds[item.id] = false;
    }
  }

  dropOption(moveEvent: CdkDragDrop<DiagnosticLogic[]>) {
    moveItemInArray(this.learnerGoals, moveEvent.previousIndex, moveEvent.currentIndex);
  }

  private getLearningGoals(learningGoals: DiagnosticLogic[]) {
    if (this.content && this.content.items) {
      this.setSelectedChartType(this.content);
      this.percentageShown = !!this.content.showPercentage;
      return this.getSelectedLearningGoals(learningGoals);
    } else {
      const formattedLearningGoals = [];
      for (const learningGoal of learningGoals) {
        formattedLearningGoals.push({
          id: learningGoal.tagUid,
          checked: true,
          title: learningGoal.tagTitle,
          color: this.generateRandomHexColor(),
        });
        this.checkedItemIds[learningGoal.tagUid] = true;
      }
      return formattedLearningGoals;
    }
  }

  private getSelectedLearningGoals(learningGoals: DiagnosticLogic[]) {
    const selectedArray = [];
    const unselectedArray = [];
    const learningGoalsArr = [...learningGoals];
    for (const cherItem of this.content.items) {
      for (let i = 0; i < learningGoalsArr.length; i++) {
        if (cherItem.itemUid === learningGoalsArr[i].tagUid) {
          selectedArray.push({
            id: learningGoalsArr[i].tagUid,
            checked: true,
            title: learningGoalsArr[i].tagTitle,
            color: cherItem.color,
          });
          this.checkedItemIds[learningGoalsArr[i].tagUid] = true;
          learningGoalsArr.splice(i, 1);
          i = 0;
          break;
        }
      }
    }
    for (const learningGoal of learningGoalsArr) {
      unselectedArray.push({
        id: learningGoal.tagUid,
        checked: false,
        title: learningGoal.tagTitle,
        color: this.generateRandomHexColor(),
      });
    }
    return selectedArray.concat(unselectedArray);
  }

  private setSelectedChartType(content: ChartContent) {
    this.selectedChartTitle = 'Radial';
    this.selectedChartType = content.chartType;
    if (this.selectedChartType === 'RADIAL') {
      this.selectedChartTitle = 'Radial';
    } else if (this.selectedChartType === 'BAR') {
      this.selectedChartTitle = 'Bar';
    }
  }

  private generateRandomHexColor(): string {
    let length = 6;
    const chars = '0123456789ABCDEF';
    let hex = '#';
    while (length--) {
      /* eslint-disable no-bitwise */
      hex += chars[(Math.random() * 16) | 0];
    }
    return hex;
  }
}
