import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType,
  ReviewSubmissionDateRequest,
} from '../../../models/admin/boolean-filters.model';
import { TranslocoService } from '@ngneat/transloco';
import { DateRange } from '@angular/material/datepicker';
import { LocalTimeHelper } from '../../../helpers/local-time-helper';
import { CalendarHelper } from '@app/app/shared/helpers/calendar-helper';

@Component({
  selector: 'ptl-submission-date-filter',
  templateUrl: './submission-date-filter.component.html',
  styleUrls: ['./submission-date-filter.component.scss'],
})
export class SubmissionDateFilterComponent implements OnInit {
  @Input() selectedFilters: BooleanFilter[];
  @Output() filterDeleted = new EventEmitter<void>();
  @Output() filterSaved = new EventEmitter<BooleanFilter>();

  expanded = true;
  submissionIs: 'BETWEEN' | 'BEFORE' | 'AFTER' = 'BETWEEN';

  // before or after
  date: Date;

  // between
  betweenRange: DateRange<Date>;
  betweenStart: Date;
  betweenEnd: Date;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit() {
    this.date = new Date(new Date().setUTCHours(0, 0, 0, 0));
    this.betweenStart = new Date(new Date().setUTCHours(0, 0, 0, 0));
    this.betweenEnd = new Date(this.betweenStart.getTime());
    this.refreshDateRange();
    this.getSettingsFromFilters();
  }

  private refreshDateRange() {
    this.betweenRange = new DateRange(this.betweenStart, this.betweenEnd);
  }

  dateChanged(date) {
    this.date = date;
  }

  dateRangeChanged(date: Date) {
    if (this.betweenStart.getTime() !== this.betweenEnd.getTime() || date <= this.betweenStart) {
      this.betweenStart = new Date(date);
      this.betweenEnd = new Date(date);
    } else {
      this.betweenEnd = new Date(date);
    }
    this.refreshDateRange();
  }

  deleteFilter() {
    this.filterDeleted.emit();
  }

  setSubmissionDate(submissionIs: 'BETWEEN' | 'BEFORE' | 'AFTER') {
    if (this.submissionIs !== submissionIs) {
      if (submissionIs === 'BETWEEN') {
        this.betweenStart = new Date(this.date);
        this.betweenEnd = new Date(this.date);
        this.refreshDateRange();
      } else if (this.submissionIs === 'BETWEEN') {
        this.date = new Date(this.betweenStart);
      }
      this.submissionIs = submissionIs;
    }
  }

  applyFilter() {
    this.handleSameDay();
    const request = this.getRequest();
    const message = this.getMessage();
    this.filterSaved.emit({ request: request, message: message, type: 'REVIEW_SUBMISSION_DATE' });
  }

  private handleSameDay() {
    if (this.submissionIs === 'BETWEEN' && this.betweenEnd.getTime() === this.betweenStart.getTime()) {
      this.betweenEnd.setDate(new Date(this.betweenStart.getTime()).getDate() + 1);
    }
  }

  private getRequest(): BooleanQueryRequest {
    const request: ReviewSubmissionDateRequest = { type: BooleanQueryType.REVIEW_SUBMISSION_DATE };
    if (this.submissionIs === 'BEFORE') {
      request.before = this.date;
    } else if (this.submissionIs === 'AFTER') {
      request.after = this.date;
    } else {
      request.before = this.betweenEnd;
      request.after = this.betweenStart;
    }
    return request as BooleanQueryRequest;
  }

  private getMessage(): string {
    if (this.submissionIs === 'BEFORE') {
      return (
        this.translocoService.translate('translations.reviews.filter.submission') +
        ' ' +
        this.translocoService.translate('translations.reviews.filter.isBefore') +
        ' ' +
        LocalTimeHelper.getLocalDateTime(this.date).format('D MMM YYYY')
      );
    } else if (this.submissionIs === 'AFTER') {
      return (
        this.translocoService.translate('translations.reviews.filter.submission') +
        ' ' +
        this.translocoService.translate('translations.reviews.filter.isAfter') +
        ' ' +
        LocalTimeHelper.getLocalDateTime(this.date).format('D MMM YYYY')
      );
    } else {
      return (
        this.translocoService.translate('translations.reviews.filter.submission') +
        ' ' +
        this.translocoService.translate('translations.reviews.filter.isBetween') +
        ' ' +
        CalendarHelper.formatRange(this.betweenStart, this.betweenEnd)
      );
    }
  }

  private getSettingsFromFilters() {
    if (this.selectedFilters) {
      const submissionDateFilter = this.selectedFilters.find((f) => f.type === 'REVIEW_SUBMISSION_DATE');
      if (submissionDateFilter) {
        const booleanRequest = submissionDateFilter.request as ReviewSubmissionDateRequest;
        if (booleanRequest.before && booleanRequest.after) {
          this.submissionIs = 'BETWEEN';
          this.betweenEnd = booleanRequest.before;
          this.betweenStart = booleanRequest.after;
          this.refreshDateRange();
        } else if (booleanRequest.before) {
          this.submissionIs = 'BEFORE';
          this.date = booleanRequest.before;
        } else if (booleanRequest.after) {
          this.submissionIs = 'AFTER';
          this.date = booleanRequest.after;
        }
      }
    }
  }
}
