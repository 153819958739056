<table mat-table [dataSource]="processedData" class="mentor-mentee-table" matSort (matSortChange)="onSortChange($event)">
  <ng-container matColumnDef="avatar">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let user">
      <img [src]="user.imageUrl || 'assets/profile_image.png'" class="avatar" alt="" />
    </td>
  </ng-container>

  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'translations.global.label.firstName' | transloco }}</th>
    <td mat-cell *matCellDef="let user">{{ user.firstName }}</td>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'translations.global.label.lastName' | transloco }}</th>
    <td mat-cell *matCellDef="let user">{{ user.lastName }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'translations.global.label.status' | transloco }}</th>
    <td mat-cell *matCellDef="let mentor">
      <div class="mentor-mentee-table__status-container">
        <span class="status-dot" [ngClass]="mentor.status.toLowerCase()"></span>
        <span>{{ getStatusDisplayName(mentor.status) }}</span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="menteesCount">
    <th mat-header-cell *matHeaderCellDef class="mentor-mentee-table__count">
      {{ 'translations.mentors.table.assignedMentees' | transloco }}
    </th>
    <td mat-cell *matCellDef="let user">
      <div class="mentor-mentee-table__count">
        <span>{{ user.menteesCount }}</span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="mentorsCount">
    <th mat-header-cell *matHeaderCellDef class="mentor-mentee-table__count">
      {{ 'translations.mentors.table.assignedMentors' | transloco }}
    </th>
    <td mat-cell *matCellDef="let user">
      <div class="mentor-mentee-table__count">
        <span>{{ user.mentorsCount }}</span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="assignedAt">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'translations.global.label.assignedAt' | transloco }}
    </th>
    <td mat-cell *matCellDef="let user">
      <ng-container *ngIf="isPendingApproval(user); else showDate">
        {{ 'translations.global.label.pendingApproval' | transloco }}
      </ng-container>
      <ng-template #showDate>
        {{ user.since | dateFormat: 'DD MMM yyyy HH:mm' }}
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="manage">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let user">
      <button *ngIf="!isMyHub" mat-button color="primary" (click)="viewDetails.emit(user)">
        {{ 'translations.global.button.manage' | transloco }}
      </button>
      <!--TODO: To be implemented in the next iteration-->
      <!--<button *ngIf="isMyHub" mat-button color="primary" (click)="viewDetails.emit(user)">View profile</button>-->
    </td>
  </ng-container>

  <thead>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  </thead>
  <tbody>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.pending-approval]="isPendingApproval(row)"></tr>
  </tbody>
</table>
