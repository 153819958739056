/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import {
  Component,
  Output,
  EventEmitter,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Quiz, QuizResultDialogData } from '../../../../../../shared/models/editor/quiz-content.model';
import {
  DiagnosticQuestionResponse,
  QuestionResponseSelectedOption,
  QuestionResponseRequest,
  DiagnosticFormattedQuestion,
  Organization,
} from '../../../../../../shared/models';
import { DATA_SERVICE, QuizDataService } from '../../../../../../editor/services/data.service';
import { QuizReport, QuizReportResponse } from '../../../../models';
import { LanguageCodeHelper } from '../../../../../../shared/helpers/language-code-helper';
import { Select } from '@ngxs/store';
import { LearnerViewState } from '../../../../store/learner-view.state';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil, take, filter } from 'rxjs/operators';
import { UserAuthState } from '../../../../../../user-auth/store/user-auth.state';
import { PlaylistViewState } from '../../../../../playlist/store/view/playlist-view.state';

@Component({
  templateUrl: './quiz-dialog.component.html',
  styleUrls: ['./quiz-dialog.component.scss'],
})
export class QuizDialogComponent implements OnInit, OnDestroy {
  @Output() action = new EventEmitter();

  @ViewChild('questionProgress') private questionProgress: ElementRef;

  @Select(LearnerViewState.currentLanguage)
  private currentLanguage$: Observable<string>;

  @Select(UserAuthState.organizationDetails)
  private organization$: Observable<Organization>;

  @Select(PlaylistViewState.playlistId)
  private playlistId$: Observable<string>;

  currentPage: number;
  resultUid: string = undefined;
  selectedOptionUid: string = undefined;
  showChooseOptionMessage = false;
  questionsCompleted = false;
  quizTaken: boolean;
  pageOpenedFirstTime: boolean;
  canRetake = false;
  quizId = undefined;
  resourceUid: string;
  isReviewResult: boolean;

  summary;
  report: QuizReport;
  responses: QuizReportResponse[];
  questions: DiagnosticQuestionResponse[];
  selectedOptions: QuestionResponseSelectedOption[] = undefined;
  savedOptions = [];
  quiz: Quiz;

  // Version number to post to api.
  latestVersionNumber = 1;

  // progress indicator
  radius = 54;
  circumference = 2 * Math.PI * this.radius;

  reportTypes = {
    DETAILED: 'DETAILED',
    SUMMARY: 'BRIEF',
  };
  reportType;
  currentLanguage: string;
  organization: Organization;

  private playlistId: string;
  private userUid: string;
  private questionTimeTaken = 0;
  private secondsCounterInterval;
  private playlistIdSubscribtion: Subscription;
  private subscriptionEnd$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuizResultDialogData,
    @Inject(DATA_SERVICE) private dataService: QuizDataService,
    private dialogRef: MatDialogRef<QuizDialogComponent>,
    private ref: ChangeDetectorRef,
    private renderer2: Renderer2,
  ) {
    this.pageOpenedFirstTime = true;
    this.quizId = this.data.quizId;
    this.resourceUid = this.data.resourceUid;
    this.quizTaken = this.data.quizTaken;
    this.userUid = this.data.userUid;
    this.latestVersionNumber = this.data.latestVersionNumber ? this.data.latestVersionNumber : 1;
    this.resultUid = this.data.resultUid;
    this.summary = this.data.summary;
    this.currentPage = 0;
    this.isReviewResult = this.data.isReviewResult;
    this.organization$.pipe(take(1)).subscribe((org) => (this.organization = org));
  }

  ngOnInit() {
    this.playlistIdSubscribtion = this.playlistId$
      .pipe(filter((playlistId) => !!playlistId))
      .subscribe((playlistId: string) => (this.playlistId = playlistId));
    this.loadQuiz();
    if (this.quizTaken) {
      this.resultUid = this.data.resultUid;
      this.currentLanguage$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((currentLanguage) => {
        this.dataService.getQuizReport(this.playlistId, this.quizId, this.resultUid, currentLanguage).subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            this.report = value;
            this.responses = value.responses;
          } else {
            this.close();
          }
        });
      });
    }

    setTimeout(function () {
      document.getElementById('quizView')?.focus();
    }, 1000);

    this.secondsCounterInterval = setInterval(() => {
      this.questionTimeTaken += 1000;
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.secondsCounterInterval);
    this.playlistIdSubscribtion?.unsubscribe();
    this.subscriptionEnd$.next();
  }

  get dashoffset() {
    const responsesCount = this.savedOptions.filter((option) => !!option.selectedOptions.length).length;
    const progress = responsesCount / this.questions.length;
    return this.circumference * (1 - progress);
  }

  get svgStroke() {
    return `url(${window.location.pathname}#gradient)`;
  }

  onSelectedAnswer(question) {
    this.selectedOptions = this.getSelectedOptions(question.answer);
    this.selectedOptionUid = question.uid;
    this.savedOptions[this.currentPage].selectedOptions = this.selectedOptions;
  }

  onSliderChange(range, question) {
    this.selectedOptions = [this.getSelectedRangeSideOption(question.options, range.value)];
    this.selectedOptionUid = question.uid;
    this.savedOptions[this.currentPage].selectedOptions = this.selectedOptions;
  }

  close() {
    if (this.currentPage === this.questions?.length) {
      this.quizTaken = true;
    }
    this.dialogRef.close();
  }

  retake() {
    this.dialogRef.close({ event: 'retake', resultUid: this.resultUid });
  }

  previousPage() {
    this.selectedOptionUid = undefined;
    this.currentPage--;
    this.questionTimeTaken = 0;
    this.pageOpenedFirstTime = false;
    this.showChooseOptionMessage = false;
    (this.questionProgress.nativeElement as HTMLElement).focus();
    this.checkViewReportVisibility();
  }

  nextPage() {
    if (!this.questionsCompleted) {
      if (this.questions[this.currentPage] && this.questions[this.currentPage].type === 'INFO_QUESTION') {
        this.currentPage = Math.min(this.currentPage + 1, this.questions.length - 1);
        return;
      }

      if (!this.selectedOptions || !this.selectedOptions.length) {
        this.showChooseOptionMessage = true;
        return;
      }

      this.currentPage++;
      const request = {
        questionUid: this.selectedOptionUid,
        duration: this.questionTimeTaken,
        selectedOptions: this.selectedOptions,
      } as QuestionResponseRequest;
      delete this.selectedOptions;
      this.pageOpenedFirstTime = false;
      this.showChooseOptionMessage = false;
      this.dataService
        .postQuizResponse(this.playlistId, this.quiz._id, this.latestVersionNumber, request)
        .subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            this.resultUid = value._id;
          }
          (this.questionProgress.nativeElement as HTMLElement).focus();
        });

      this.checkViewReportVisibility();
    } else {
      this.currentPage += 1;
    }
    setTimeout(() => {
      (this.questionProgress.nativeElement as HTMLElement).focus();
    }, 10);
  }

  toReport() {
    this.dialogRef.close({ event: 'showReport', resultUid: this.resultUid });
  }

  back() {
    clearInterval(this.secondsCounterInterval);
    this.dialogRef.close({ event: 'save', resultUid: this.resultUid });
  }

  private checkViewReportVisibility(): void {
    this.questionsCompleted = this.questions.length === this.currentPage;
    if (this.questionsCompleted) {
      const viewReportButton = document.getElementById('viewReportButton');
      if (viewReportButton) {
        this.renderer2.selectRootElement(viewReportButton).focus();
      }
    }
  }

  private loadQuiz() {
    return this.currentLanguage$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((currentLanguage) => {
      this.currentLanguage = currentLanguage;
      this.dataService.getQuiz(this.resourceUid, this.quizId, currentLanguage).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          let formattedQuestions = value.questions.filter((question) => !!question);
          formattedQuestions = formattedQuestions.map(
            (question: DiagnosticQuestionResponse) =>
              ({
                ...question,
                instruction: LanguageCodeHelper.getDataByUserLanguageCode(question.instructions, this.organization, this.currentLanguage),
                value: LanguageCodeHelper.getDataByUserLanguageCode(question.values, this.organization, this.currentLanguage),
                choices: question.options.map((option) => ({
                  ...option,
                  optionUid: option.uid,
                  value: LanguageCodeHelper.getDataByUserLanguageCode(question.values, this.organization, this.currentLanguage),
                })),
                title: LanguageCodeHelper.getDataByUserLanguageCode(question.instructions, this.organization, this.currentLanguage).value,
                feedback: question.feedback,
              }) as DiagnosticFormattedQuestion,
          );
          value.questions = formattedQuestions;
          value.instruction = LanguageCodeHelper.getDataByUserLanguageCode(value.instructions, this.organization, this.currentLanguage);
          this.quiz = value;
          this.questions = this.quiz.questions;

          this.reportType = this.reportTypes[this.quiz.settings.resultDisplayPolicy];
          this.questions.map((question) => {
            this.savedOptions.push({ questionUid: question.uid, selectedOptions: [] });
          });
          if (!this.quizTaken) {
            this.loadResponse();
          }
          this.ref.detectChanges();
        }
      });
    });
  }

  private loadResponse() {
    if (this.summary && this.summary.quizResponsesUid) {
      this.dataService.getQuizResponse(this.playlistId, this.quizId, this.summary.quizResponsesUid).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          if (value.responses.length < this.questions.length) {
            value.responses.map((response) => {
              this.savedOptions = this.savedOptions.map((savedOption) => {
                if (savedOption.questionUid === response.questionUid) {
                  return response;
                }
                return savedOption;
              });
            });
          } else {
            this.savedOptions = value.responses;
          }
          this.currentPage = this.savedOptions.findIndex((item) => !item.selectedOptions?.length);
        } else {
          this.questions.map((question) => {
            this.savedOptions.push({ questionUid: question.uid, selectedOptions: [] });
          });
        }
      });
    }
  }

  private getSelectedOptions(options) {
    const selectedOption = [];
    if (options instanceof Array) {
      for (const option of options) {
        if (option.selected) {
          selectedOption.push({ optionUid: option.uid, userScore: option.score });
        }
      }
    } else {
      selectedOption.push({ optionUid: options.uid, userScore: options.score });
    }
    return selectedOption;
  }

  private getSelectedRangeSideOption(options, value: number) {
    let leftScore = 0;
    let rightScore = 0;
    let rightOption;
    let leftOption;
    options.forEach((option) => {
      if (option.direction === 'LEFT') {
        leftScore = option.score;
        leftOption = option;
      }
      if (option.direction === 'RIGHT') {
        rightScore = option.score;
        rightOption = option;
      }
    });
    let selectedOption = leftOption;
    if (value > (leftScore + rightScore) / 2) {
      selectedOption = rightOption;
    }
    return { optionUid: selectedOption.uid, userScore: value };
  }
}
