/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import { Page } from '../../../shared/models/page';
import { ReviewerSummary, UserReviewsCount } from '../../../shared/models/review/review.model';
import { ObservableResult } from '../../../shared/store';
import { ReviewSettingsDetails } from '../../playlist/models/reviews-settings.model';
import {
  CommentRequest,
  ReviewAdminRequest,
  ReviewerAdminRequest,
  ReviewItem,
  ReviewRequest,
  ReviewSummaryDetailed,
  SubmissionsReviewRequest,
} from '../models';

export const REVIEWS_DATA_SERVICE = new InjectionToken<ReviewsDataService>('[Reviews] DataService');

export interface ReviewsDataService {
  getReviews(page: number, pageSize: number, term?: string): ObservableResult<Page<ReviewItem>>;

  getReviewItem(itemUri: string): ObservableResult<ReviewItem>;

  getReviewSummaryDetailed(reviewItemUri: string, learnerUserUid: string): ObservableResult<ReviewSummaryDetailed>;

  addComment(reviewUid: string, cardUid: string, formUid: string, request: CommentRequest): ObservableResult<void>;

  editComment(reviewUid: string, noteUid: string, request: CommentRequest): ObservableResult<void>;

  deleteComment(reviewUid: string, noteUid: string): ObservableResult<void>;

  rejectReview(reviewUid: string, request: ReviewRequest): ObservableResult<void>;

  approveReview(reviewUid: string, request: ReviewRequest): ObservableResult<void>;

  requestChangeOfReview(reviewUid: string, playlistUid: string, request: ReviewRequest): ObservableResult<void>;

  requestResubmission(request: SubmissionsReviewRequest): ObservableResult<void>;

  requestAllResubmission(reviewUid: string, request: SubmissionsReviewRequest): ObservableResult<void>;

  acceptReviews(request: SubmissionsReviewRequest): ObservableResult<void>;

  acceptAllReviews(reviewUid: string, request: SubmissionsReviewRequest): ObservableResult<void>;

  rejectReviewRequest(request: SubmissionsReviewRequest): ObservableResult<void>;

  rejectAllReviewRequest(reviewUid: string, request: SubmissionsReviewRequest): ObservableResult<void>;

  closeReview(request: ReviewAdminRequest, isAccepted: boolean): ObservableResult<void>;

  reOpenReview(request: ReviewAdminRequest): ObservableResult<void>;

  updateReviewers(reviewUid: string, request: ReviewerAdminRequest): ObservableResult<void>;

  retrieveReviewers(reviewUid: string): ObservableResult<ReviewerSummary[]>;

  getUserReviewsCount(): ObservableResult<UserReviewsCount>;

  getReviewSettingsDetail(playlistUid: string): ObservableResult<ReviewSettingsDetails>;

  undoReview(reviewUid: string): ObservableResult<void>;

  cancelDecision(reviewUid: string, reviewEventUid: string, commentForLearner?: string): ObservableResult<void>;
}
