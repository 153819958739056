/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { InjectionToken } from '@angular/core';
import { TagRequest, TagTypeResponse } from '../../../page-modules/playlist/models';
import { ResourceTag } from '../../models';

export interface TagInfo {
  id: string;
  title: string;
}

export interface MultiTagCreationRequest {
  tags: TagRequest[];
  type?: 'STANDARD';
}

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const CORE_TAG_DATA_SERVICE = new InjectionToken<TagDataService>('[playlist-editor] CoreTagDataService');

/** The service handling tag CRUD operations. */
export interface TagDataService {
  /**
   * Creates a tag.
   *
   * @param request the details of the tag to create
   */
  createTag(request: TagRequest): ObservableResult<ResourceTag>;

  /**
   * Creates a set of tags.
   *
   * @param request the details of the tags to create
   */
  createTags(request: MultiTagCreationRequest): ObservableResult<ResourceTag[]>;

  findTagsWithTitleTerm(text: string): ObservableResult<ResourceTag[]>;

  findLtiTagsWithTitleTerm(organizationUid: string, requestId: string, text: string): ObservableResult<ResourceTag[]>;

  extractTags(text: string): ObservableResult<string[]>;

  getTraitTags(page?: number, size?: number): ObservableResult<TagTypeResponse[]>;

  getTeamRoleTags(page?: number, size?: number): ObservableResult<TagTypeResponse[]>;

  getJungianTypeTags(page?: number, size?: number): ObservableResult<TagTypeResponse[]>;

  getBigSixTags(page?: number, size?: number): ObservableResult<TagTypeResponse[]>;

  findCategoriesWithTitleTerm(text: string): ObservableResult<ResourceTag[]>;

  createCategory(request: TagRequest): ObservableResult<ResourceTag>;

  findCategories(categoriesUid: string[]): ObservableResult<TagInfo[]>;
}
