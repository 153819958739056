/*
 * Copyright (C) 2023 - present by Potentially
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AdminMembersControlPanelComponent } from '@app/app/page-modules/admin/components/members/admin-member-control-panel/admin-member-control-panel.component';

@Component({
  selector: 'ptl-v3-admin-member-control-panel',
  templateUrl: './v3-admin-member-control-panel.component.html',
  styleUrls: ['./v3-admin-member-control-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class V3AdminMembersControlPanelComponent extends AdminMembersControlPanelComponent {}
