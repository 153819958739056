import { BlockToolType } from '@app/app/shared/editor-js/model/editor-js.model';
import { marker } from '@jsverse/transloco-keys-manager/marker';

export interface TooltipOption {
  image: string;
  title: string;
  description: string;
  type: BlockToolType;
}

export const TooltipOptions: TooltipOption[] = [
  {
    image: '/assets/toolbar/text-box.png',
    title: marker('translations.editor.toolbarNew.option.textForm.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.textForm.tooltipDescription'),
    type: BlockToolType.ParagraphBlockTool,
  },
  {
    image: '/assets/toolbar/link.png',
    title: marker('translations.editor.toolbarNew.option.link.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.link.tooltipDescription'),
    type: BlockToolType.ExternalContentBlockTool,
  },
  {
    image: '/assets/toolbar/file.png',
    title: marker('translations.editor.toolbarNew.option.file.tooltipTitleDocument'),
    description: marker('translations.editor.toolbarNew.option.file.tooltipDescription'),
    type: BlockToolType.FileBlockTool,
  },
  {
    image: '/assets/toolbar/video.png',
    title: marker('translations.editor.toolbarNew.option.video.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.video.tooltipDescription'),
    type: BlockToolType.StreamVideoBlockTool,
  },
  {
    image: '/assets/toolbar/audio.png',
    title: marker('translations.editor.toolbarNew.option.audio.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.audio.tooltipDescription'),
    type: BlockToolType.AudioBlockTool,
  },
  {
    image: '/assets/toolbar/image.png',
    title: marker('translations.editor.toolbarNew.option.image.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.image.tooltipDescription'),
    type: BlockToolType.ImageBlockTool,
  },
  {
    image: '/assets/toolbar/code.png',
    title: marker('translations.editor.toolbarNew.option.embed.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.embed.tooltipDescription'),
    type: BlockToolType.EmbedCodeBlockTool,
  },
  {
    image: '/assets/toolbar/chart.png',
    title: marker('translations.editor.toolbarNew.option.chart.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.chart.tooltipDescription'),
    type: BlockToolType.ChartBlockTool,
  },
  {
    image: '/assets/toolbar/divider.png',
    title: marker('translations.editor.toolbarNew.option.separator.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.separator.tooltipDescription'),
    type: BlockToolType.SeparatorBlockTool,
  },
  {
    image: '/assets/toolbar/text-box.png',
    title: marker('translations.editor.toolbarNew.option.textForm.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.textForm.tooltipDescription'),
    type: BlockToolType.TextboxFormBlockTool,
  },
  {
    image: '/assets/toolbar/connected-textbox.png',
    title: marker('translations.editor.toolbarNew.option.connectedTextForm.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.connectedTextForm.tooltipDescription'),
    type: BlockToolType.ConnectedTextboxFormBlockTool,
  },
  {
    image: '/assets/toolbar/question-form.png',
    title: marker('translations.editor.toolbarNew.option.questionForm.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.questionForm.tooltipDescription'),
    type: BlockToolType.QuestionFormBlockTool,
  },
  {
    image: '/assets/toolbar/private.png',
    title: marker('translations.editor.toolbarNew.option.privateNote.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.privateNote.tooltipDescription'),
    type: BlockToolType.PrivateNoteFormBlockTool,
  },
  {
    image: '/assets/toolbar/check-box.png',
    title: marker('translations.editor.toolbarNew.option.checkboxForm.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.checkboxForm.tooltipDescription'),
    type: BlockToolType.CheckboxFormBlockTool,
  },
  {
    image: '/assets/toolbar/file-form.png',
    title: marker('translations.editor.toolbarNew.option.fileForm.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.fileForm.tooltipDescription'),
    type: BlockToolType.UserFileUploadFormBlockTool,
  },
  {
    image: '/assets/toolbar/booking.png',
    title: marker('translations.editor.toolbarNew.option.bookingForm.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.bookingForm.tooltipDescription'),
    type: BlockToolType.BookingFormBlockTool,
  },
  {
    image: '/assets/toolbar/quiz.png',
    title: marker('translations.editor.toolbarNew.option.quizForm.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.quizForm.tooltipDescription'),
    type: BlockToolType.QuizFormBlockTool,
  },
  {
    image: '/assets/toolbar/log.png',
    title: marker('translations.editor.toolbarNew.option.collectorForm.tooltipTitle'),
    description: marker('translations.editor.toolbarNew.option.collectorForm.tooltipDescription'),
    type: BlockToolType.CollectorFormBlockTool,
  },
];
