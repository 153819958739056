/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, Input, OnInit } from '@angular/core';
import { ChartsHelper } from '../../../helpers/charts-helper';

@Component({
  selector: 'ptl-card-progress-circle',
  templateUrl: './card-progress-circle.component.html',
  styleUrls: ['./card-progress-circle.component.scss'],
})
export class CardProgressCircleComponent implements OnInit {
  @Input() index: number;
  @Input() showOrder: boolean;
  @Input() totalCount: number;
  @Input() completedCount: number;

  @Input() completed: boolean;
  @Input() started: boolean;

  radius = 16;
  circumference = 2 * Math.PI * this.radius;
  svgProgress = 0;
  progress = 0;
  showProgress: boolean;

  ngOnInit() {
    if (this.totalCount === 0 || (this.completed && this.totalCount <= 0)) {
      this.showProgress = false;
    } else {
      this.showProgress = true;
    }
    this.calculateProgress();
  }

  private calculateProgress() {
    let svgProgress = 0;
    let progress = 0;
    if (this.completedCount === 0 && this.totalCount === 0) {
      // use -100 to reset progress if score is 0
      svgProgress = -100;
      progress = 0;
    } else {
      progress = ChartsHelper.calculateProgress(this.completedCount, this.totalCount);
      svgProgress = this.circumference * (1 - this.completedCount / this.totalCount);
    }

    this.progress = progress;
    this.svgProgress = svgProgress;
  }
}
