/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, mergeMap, map, pluck, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from '../../../../../shared/services/languages/language.service';
import { TranslocoService } from '@ngneat/transloco';
import { CurrentLanguage } from '../../../../playlist/store/create/playlist-creation.state.model';
import { PlaylistThumbnailUpdateRequest } from '../../../../playlist/models';
import { ImageUploadNameResolver } from '../../../../../shared/helpers/image-upload-name-resolver';
import { FileUploadHelper } from '../../../../../shared/helpers/file-upload-helper';
import { SnackbarHelper } from '../../../../../shared/helpers/snackbar-helper';
import { AvailableLanguage, Organization, ResourceTag } from '../../../../../shared/models';
import { LoadableState } from '../../../../../shared/store';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { OrganizationLanguageSummary, SupportedLanguage } from '../../../../../shared/models/languages/languages.model';
import { ActivatedRoute, Router } from '@angular/router';
import { LANGUAGES_DATA_SERVICE, LanguagesDataService } from '../../../../../shared/services/languages/languages-data.service';
import { FileUploadService, ORGANIZATIONS_FILE_UPLOAD_DATA_SERVICE } from '../../../../../shared/services/file-upload/file-upload.service';
import { PageState } from '../../../store/pages.state';
import { Page, PageAuthorDetails, PageStatus } from '../../../../../shared/models/page/page.model';
import {
  LoadPageByUriAndLanguageCode,
  PageAddTag,
  PageRemoveTag,
  ResetPageCurrentLanguage,
  SetPageCurrentLanguage,
  SwitchToHomePage,
  UpdatePageSubheader,
  UpdatePageUri,
  UpdatePageSidenavVisibility,
  UpdatePageAuthorVisibility,
} from '../../../store/pages.actions';
import { PAGES_DATA_SERVICE, PageDataService } from '../../../services/data.service';
import { CORE_TAG_DATA_SERVICE, TagDataService } from '@app/app/shared/services/tags/tag-data.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { canTriggerSearch } from '@app/app/shared/helpers/content-helper';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { DialogService } from '@app/app/shared/helpers/dialog/dialog.service';
import { RedirectHelper } from '../../../../resource/store/editor/content/helpers/redirect.helper';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CoverImageUploadDialogComponent } from '@app/app/shared/components/cover-image-upload-dialog/cover-image-upload-dialog.component';
import { IMAGE_UPLOAD_DIALOG_CONFIG } from '@app/app/shared/models/config/dialog-config.model';

@Component({
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class PageSummaryComponent implements OnInit, OnDestroy {
  @Input() isDialog: boolean;

  @Select(PageState.headline)
  title$: Observable<string>;

  @Select(PageState.subheader)
  subheader$: Observable<string>;

  @Select(PageState.status)
  status$: Observable<PageStatus>;

  @Select(PageState.authorDetails)
  authorDetails$: Observable<PageAuthorDetails>;

  @Select(PageState.currentLanguage)
  private currentLanguage$: Observable<CurrentLanguage>;

  @Select(PageState.page)
  private page$: Observable<LoadableState<Page>>;

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  @Select(UserAuthState.homePageUri)
  homePageUri$: Observable<string>;

  updateInProgress: boolean;
  saveInProgress: boolean;
  showFrameworkTagsPanel: boolean;
  thumbnailUploadInProgress: boolean;
  loadingTags: boolean;

  organizationLanguageSummary: OrganizationLanguageSummary;
  currentLanguage: SupportedLanguage;
  supportedLanguages: SupportedLanguage[] = [];
  currentIndex: number;
  filteredTags: ResourceTag[] = [];

  uri: string;
  subscription: Subscription;
  thumbnailUrl: string;
  tags: ResourceTag[];

  page: Page;
  authorDetails: PageAuthorDetails;
  pageUid: string;
  showOnLeftNav: boolean;
  languageTabChanged = false;
  skeletonViewActive: boolean;
  visibleAuthor: number;

  private pagesUri: string;
  private newTagCreateInProgress: boolean;
  private subscriptionEnd$ = new Subject<void>();
  private searchInputSubscription: Subscription;
  private tagsAddSubscription: Subscription;
  private searchInputSubject = new Subject<string>();
  private homePageUri: string;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private ngZone: NgZone,
    private dialogService: DialogService,
    @Inject(LANGUAGES_DATA_SERVICE) private languagesDataService: LanguagesDataService,
    @Inject(PAGES_DATA_SERVICE) private pageDataService: PageDataService,
    @Inject(ORGANIZATIONS_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService,
    @Inject(CORE_TAG_DATA_SERVICE) private tagService: TagDataService,
    private languageService: LanguageService,
    private translocoService: TranslocoService,
  ) {
    this.searchInputSubscription = this.searchInputSubject.pipe(debounceTime(500)).subscribe((searchValue) => this.fireSearch(searchValue));
  }

  ngOnInit() {
    this.skeletonViewActive = true;
    if (this.isDialog) {
      this.skeletonViewActive = false;
    }

    this.homePageUri$.pipe(filter((data) => !!data)).subscribe((data) => (this.homePageUri = data));
    this.pagesUri = this.route.snapshot.paramMap.get('pagesUri');
    const publisherUri = this.route.snapshot.paramMap.get('publisherUri');
    const packageUri = this.route.snapshot.paramMap.get('packageUri');
    this.organizationData$
      .pipe(
        pluck('_id'),
        mergeMap((orgId) => this.languagesDataService.getOrganizationLanguages(orgId)),
        takeUntil(this.subscriptionEnd$),
      )
      .subscribe((result) => {
        if (result.isSuccess) {
          this.organizationLanguageSummary = result.value;
          let languageIndex = 0;
          this.subscription = this.page$
            .pipe(
              filter(({ loading, data }) => !loading && !!data),
              map((page) => page.data),
            )
            .subscribe((page) => {
              this.page = page;
              this.pageUid = page._id;
              this.thumbnailUrl = page.thumbnail;
              this.tags = page.tags;
              this.uri = page.uri;
              this.showOnLeftNav = this.page.showOnLeftNav;
              page.availableLanguages = LanguageCodeHelper.orderAvailableLanguages(
                result.value.supportedLanguages,
                page.availableLanguages,
              );
              languageIndex = LanguageCodeHelper.getActiveLanguageTabIndex(
                result.value.supportedLanguages,
                page.availableLanguages,
                this.languageService,
              );
              this.skeletonViewActive = false;
              this.languageTabChanged = false;
              this.supportedLanguages = result.value.supportedLanguages.filter((language) =>
                this.page.availableLanguages.map((lang: AvailableLanguage) => lang.code).includes(language.language.code),
              );

              this.currentLanguage = this.supportedLanguages[languageIndex];
              this.currentIndex = languageIndex;
            });
          this.store.dispatch(new SetPageCurrentLanguage(this.supportedLanguages[languageIndex], languageIndex));
        }
      });

    this.authorDetails$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((authorDetails) => {
      if (authorDetails) {
        this.authorDetails = authorDetails;
        this.visibleAuthor = authorDetails.displayConfig.organizationName === true ? 1 : 2;
      }
    });

    this.currentLanguage$
      .pipe(
        filter((it) => !!it),
        takeUntil(this.subscriptionEnd$),
      )
      .subscribe((currentLanguage) => {
        this.currentLanguage = currentLanguage.supportedLanguage;
        this.currentIndex = currentLanguage.index;
        if (!this.isDialog) {
          this.store.dispatch(
            new LoadPageByUriAndLanguageCode(this.pagesUri, publisherUri, packageUri, null, null, this.currentLanguage?.language.code),
          );
        }
      });
  }

  onTabChange(currentLanguage: CurrentLanguage): void {
    this.languageTabChanged = true;
    this.skeletonViewActive = true;
    this.store.dispatch(new SetPageCurrentLanguage(currentLanguage.supportedLanguage, currentLanguage.index));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetPageCurrentLanguage());
    this.subscription?.unsubscribe();
    this.searchInputSubscription?.unsubscribe();
    this.tagsAddSubscription?.unsubscribe();
    this.subscriptionEnd$.next();
  }

  onTextPaste(event: ClipboardEvent) {
    if (event.type === 'paste') {
      setTimeout(() => {
        const searchValue = (event.target as HTMLInputElement).value;
        this.searchInputSubject.next(searchValue);
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent) {
    if (canTriggerSearch(event)) {
      const searchValue = (event.target as HTMLInputElement).value;
      this.searchInputSubject.next(searchValue);
    }
  }

  onSearchInputEnter(event: KeyboardEvent) {
    const searchValue = (event.target as HTMLInputElement).value;
    this.newTagCreateInProgress = true;
    this.loadingTags = true;
    this.tagService.createTag({ title: searchValue }).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.store
          .dispatch(new PageAddTag(value))
          .toPromise()
          .then(() => {
            this.loadingTags = false;
            (event.target as HTMLInputElement).value = '';
          });
      } else {
        this.loadingTags = false;
      }
      this.newTagCreateInProgress = false;
    });
  }

  onShowFrameworkTagsPanel(show: boolean) {
    this.showFrameworkTagsPanel = show;
  }

  disabledAutocomplete(): string {
    return '';
  }

  onImageUploadClick() {
    const dialogRef = this.dialog.open(CoverImageUploadDialogComponent, {
      ...IMAGE_UPLOAD_DIALOG_CONFIG,
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      data: {
        resizeToHeight: 300,
        cropperShape: 'rectangle',
        advancedImageCrop: true,
        aspectRatio: 1.77,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data?.croppedImageFile) {
        this.handleUploadedCoverImage(data);
      }
    });
  }

  handleUploadedCoverImage(data) {
    this.thumbnailUploadInProgress = true;
    const request: PlaylistThumbnailUpdateRequest = {
      imageName: ImageUploadNameResolver.resolveImageName(data.croppedImageFile.name),
      contentLength: data.croppedImageFile.size,
    };

    this.pageDataService
      .changePageThumbnail(this.pageUid, request, this.currentLanguage?.language.code)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.fileUploadService.uploadFile(FileUploadHelper.filePath(value.url), data.croppedImageFile).subscribe(() => {
            this.thumbnailUrl = data.croppedImageDataUrl;
            this.thumbnailUploadInProgress = false;
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone,
              this.snackBar,
              this.translocoService,
              'translations.thumbnailImageUpdated',
            );
          });
        }
      });
  }

  onThumbnailRemove() {
    this.pageDataService.removePageThumbnail(this.pageUid, this.currentLanguage?.language.code).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        this.thumbnailUrl = undefined;
        SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translocoService, 'translations.thumbnailImageRestored');
      }
    });
  }

  onSubheaderUpdate(newSubheader: string): void {
    this.store.dispatch(new UpdatePageSubheader(newSubheader, this.currentLanguage.language.code));
  }

  switchToHomePage(): void {
    const element = document.createElement('div');
    element.innerHTML = this.translocoService.translate('translations.dialog.title.confirmSwitchToHomePage');

    this.dialogService.showConfirmDialog(element, this.translocoService).then((confirmed) => {
      if (confirmed) {
        this.store
          .dispatch(new SwitchToHomePage(this.pageUid))
          .toPromise()
          .then(() => {
            if (this.isDialog) {
              RedirectHelper.redirectToHomePage(this.ngZone, this.router, this.route, this.homePageUri);
            }
          });
      }
    });
  }

  removeTag(tag: ResourceTag) {
    this.store.dispatch(new PageRemoveTag(tag._id));
    this.showFrameworkTagsPanel = false;
  }

  onTagSelect({ option }: MatAutocompleteSelectedEvent) {
    const tag: ResourceTag = option.value;
    this.filteredTags = [];
    this.loadingTags = true;
    this.store
      .dispatch(new PageAddTag(tag))
      .toPromise()
      .then(() => {
        this.loadingTags = false;
      });
  }

  onSubmitTags(tags: ResourceTag[]) {
    if (tags.length) {
      const tag = tags.shift();
      this.tagsAddSubscription = this.store.dispatch(new PageAddTag(tag)).subscribe(() => {
        this.onSubmitTags(tags);
      });
    }
  }

  onUriChanged(newUri: string): void {
    if (newUri === '') {
      return;
    }
    this.store.dispatch(new UpdatePageUri(newUri));
  }

  onAuthorUpdate(value: number): void {
    const request = {
      authorName: value === 2,
      organizationName: value === 1,
    };
    this.store.dispatch(new UpdatePageAuthorVisibility(request));
  }

  updatePageVisibilityInNav(data: MatSlideToggleChange) {
    if (!data.checked) {
      this.dialogService
        .showConfirmDialog(this.translocoService.translate('translations.dialog.title.confirmHidePageInSidenav'), this.translocoService)
        .then((confirmed) => {
          if (confirmed) {
            this.store
              .dispatch(new UpdatePageSidenavVisibility(data.checked))
              .toPromise()
              .then(() => {
                this.showOnLeftNav = false;
              });
          } else {
            this.showOnLeftNav = true;
          }
        });
    } else {
      this.store
        .dispatch(new UpdatePageSidenavVisibility(data.checked))
        .toPromise()
        .then(() => {
          this.showOnLeftNav = data.checked;
        });
    }
  }

  private fireSearch(searchValue: string) {
    this.loadingTags = true;
    if (!searchValue) {
      this.loadingTags = false;
      return;
    }
    if (this.newTagCreateInProgress) {
      return;
    }
    this.tagService.findTagsWithTitleTerm(searchValue).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.filteredTags = value;
      }
      this.loadingTags = false;
    });
  }
}
