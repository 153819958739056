<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="video-upload-dialog-container">
  <div class="dialog-top-info">
    <h1 class="dialog-title">{{ 'translations.editor.toolbar.label.uploadVideo' | transloco }}</h1>
    <p class="dialog-description small">
      {{
        'translations.global.label.supportedFormats'
          | transloco
            : {
                supportedFormats: supportedFormats,
              }
      }}
    </p>
    <p class="dialog-description small">
      {{
        'translations.global.label.maxSize'
          | transloco
            : {
                maxFileSizeMB: maxFileSizeMB,
              }
      }}
    </p>

    <p class="error-text" *ngIf="uploadErrorText">{{ uploadErrorText }}</p>
    <button
      class="close-dialog-btn"
      mat-icon-button
      color="primary"
      (click)="closeDialog()"
      [attr.aria-label]="'translations.global.ariaLabels.closeDialog'"
    >
      <svg class="svg" role="img">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>

  <div
    class="upload-centered-container"
    ogFileDrop
    (filesDropped)="onFileDropped($event)"
    (filesHovered)="filesHovered = $event"
    [class.upload-centered-container--active]="filesHovered"
  >
    <div class="upload-centered-button">
      <input #uploadFolioInput id="uploadFolioCover" type="file" class="file-input" accept="video/*" (change)="onFileSelected($event)" />
      <button mat-button class="browse-files-btn" (click)="triggerFileSelection()" (keydown.enter)="triggerFileSelection()">
        <svg
          matPrefix
          class="svg svg-icon icon-left"
          role="img"
          [attr.aria-label]="'translations.global.ariaLabels.icons.upload' | transloco"
        >
          <use ogSvgUnify="assets/sprite/sprite.svg#upload"></use>
        </svg>
        <span>{{ 'translations.folio.label.browseFiles' | transloco }}</span>
      </button>
    </div>
  </div>

  <div class="dialog-footer-buttons">
    <button mat-flat-button type="button" class="cancel-button" color="accent" (click)="closeDialog()">
      {{ 'translations.global.button.cancel' | transloco }}
    </button>
  </div>
</div>
