<!--
  ~ Copyright (C) 2025 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="chip-item chip-interactive no-margin" *ngFor="let item of items.slice(0, limit)">
  <span class="chip-text">{{ item.name }}</span>
</div>
<ng-container *ngIf="items.length > limit">
  <button class="more-btn small bold" [matMenuTriggerFor]="menu" [attr.aria-label]="'translations.global.button.more' | transloco">
    +{{ items.length - limit }} {{ 'translations.global.button.more' | transloco }}
  </button>
  <mat-menu #menu="matMenu">
    <div class="menu-chips chips-list">
      <div class="chip-item chip-interactive no-margin" *ngFor="let item of items.slice(limit, items.length)">
        <span class="chip-text">{{ item.name }}</span>
      </div>
    </div>
  </mat-menu>
</ng-container>
