/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ReviewEvent, ReviewEventType } from '../../models';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ptl-review-event',
  templateUrl: './review-event.component.html',
  styleUrls: ['./review-event.component.scss'],
})
export class ReviewEventComponent implements OnInit {
  @Input() reviewEvent: ReviewEvent;
  @Input() playlistSubmissionDeadline: Date;
  @Input() userUid: string;
  @Input() reviewsListStyle: boolean;

  @Input() shouldIncludeFormIndicator = false;
  @Input() reviewLearnerItemId: string; // needed when shouldIncludeFormIndicator is true

  text: string;
  type: ReviewEventType;
  isLateSubmission: boolean;
  reviewEventDate: string;

  readonly ReviewEventType = ReviewEventType;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    if (this.reviewEvent) {
      this.type = this.reviewEvent.type;
      this.text = this.getTranslatedText();
      this.setReviewEventDate();
      this.setIsLateSubmission();
    }
  }

  getTranslatedText(): string {
    let firstName: string;
    let lastName: string;
    if (this.reviewEvent.reviewer.uid === this.userUid) {
      firstName = this.translocoService.translate('translations.you');
      lastName = '';
    } else {
      firstName = this.reviewEvent.reviewer.firstName;
      lastName = this.reviewEvent.reviewer.lastName;
    }
    const withFormIndicator = this.shouldIncludeFormIndicator ? this.reviewEvent.itemId !== this.reviewLearnerItemId : false;
    const reviewEventTypeTranslation = this.getReviewEventTypeTranslation();

    // @jsverse/transloco
    /**
     * t(translations.cards.form.footer.reviewEvents.submittedForReview)
     * t(translations.cards.form.footer.reviewEvents.approved)
     * t(translations.cards.form.footer.reviewEvents.resubmissionRequested)
     * t(translations.cards.form.footer.reviewEvents.resubmitted)
     * t(translations.cards.form.footer.reviewEvents.rejectedToReview)
     * t(translations.cards.form.footer.reviewEvents.remindedToSubmit)
     * t(translations.cards.form.footer.reviewEvents.closed)
     * t(translations.cards.form.footer.reviewEvents.reOpened)
     * t(translations.cards.form.footer.reviewEvents.noteAdded)
     * t(translations.cards.form.footer.reviewEvents.canceledDecision)
     * t(translations.cards.form.footer.reviewEvents.withFormIndicator.submittedForReview)
     * t(translations.cards.form.footer.reviewEvents.withFormIndicator.approved)
     * t(translations.cards.form.footer.reviewEvents.withFormIndicator.resubmissionRequested)
     * t(translations.cards.form.footer.reviewEvents.withFormIndicator.resubmitted)
     * t(translations.cards.form.footer.reviewEvents.withFormIndicator.rejectedToReview)
     * t(translations.cards.form.footer.reviewEvents.withFormIndicator.remindedToSubmit)
     * t(translations.cards.form.footer.reviewEvents.withFormIndicator.closed)
     * t(translations.cards.form.footer.reviewEvents.withFormIndicator.reOpened)
     * t(translations.cards.form.footer.reviewEvents.withFormIndicator.noteAdded)
     * t(translations.cards.form.footer.reviewEvents.withFormIndicator.canceledDecision)
     */
    const translationEntry =
      'translations.cards.form.footer.reviewEvents.' + (withFormIndicator ? 'withFormIndicator.' : '') + reviewEventTypeTranslation;

    return this.translocoService.translate(translationEntry) + ' ' + firstName + ' ' + lastName;
  }

  private getReviewEventTypeTranslation(): string {
    switch (this.reviewEvent.type) {
      case ReviewEventType.SUBMITTED_FOR_REVIEW:
        return 'submittedForReview';
      case ReviewEventType.ACCEPTED:
        return 'approved';
      case ReviewEventType.REQUIRES_RESUBMISSION:
        return 'resubmissionRequested';
      case ReviewEventType.RESUBMITTED:
        return 'resubmitted';
      case ReviewEventType.REJECTED_TO_REVIEW:
        return 'rejectedToReview';
      case ReviewEventType.REMINDED_TO_SUBMIT:
        return 'remindedToSubmit';
      case ReviewEventType.CLOSED:
        return 'closed';
      case ReviewEventType.REOPENED:
        return 'reOpened';
      case ReviewEventType.NOTE:
        return 'noteAdded';
      case ReviewEventType.CANCEL_DECISION:
        return 'canceledDecision';
      default:
        return null;
    }
  }

  private setReviewEventDate(): void {
    this.reviewEventDate = moment.utc(this.reviewEvent.createdAt).local().format('D MMMM YYYY, HH:mm');
  }

  private setIsLateSubmission(): void {
    this.isLateSubmission =
      (this.reviewEvent.type === ReviewEventType.SUBMITTED_FOR_REVIEW || this.reviewEvent.type === ReviewEventType.RESUBMITTED) &&
      this.reviewEvent.createdAt > this.playlistSubmissionDeadline;
  }
}
