/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { CardHeaders } from '@app/app/shared/models/admin/members.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiContentLinkService } from '@app/app/shared/services/content-link/api-content-link.service';
import { RedirectHelper } from '@app/app/page-modules/resource/store/editor/content/helpers/redirect.helper';

@Component({
  selector: 'ptl-most-engagements-chart',
  templateUrl: './most-engagements-chart.component.html',
  styleUrls: ['./most-engagements-chart.component.scss', '../common-analytics-chart-styles.scss'],
})
export class MostEngagementsChartComponent implements OnChanges {
  @Input() cardEngagements: Record<string, number>;
  @Input() cardHeaders: CardHeaders[];

  engagementsData: { label: string; count: number }[] = [];
  maxEngagement = 0;
  sortOrder: 'asc' | 'desc' = 'desc';

  constructor(
    private contentLinkService: ApiContentLinkService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cardEngagements']) {
      this.buildMostEngagementsData();
    }
  }

  toggleSortOrder(): void {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.engagementsData = this.sortData(this.engagementsData);
  }

  openInNewTab(header: string): void {
    const card = this.cardHeaders.find((c) => c.header === header);

    if (!card) {
      throw new Error('Failed to find card.');
    }

    this.contentLinkService.getCardFormattedUriByCardUid(card.cardId).subscribe(({ isSuccess, value }) => {
      if (isSuccess && value?.formattedUri) {
        RedirectHelper.redirectByParams(
          this.ngZone,
          this.router,
          this.activatedRoute,
          {
            formattedUri: `${value.formattedUri}`,
          },
          'RESOURCE',
          '_blank',
        );
      }
    });
  }

  private buildMostEngagementsData(): void {
    if (!this.cardEngagements) {
      this.engagementsData = [];
      this.maxEngagement = 0;
      return;
    }

    const engagements = Object.entries(this.cardEngagements).map(([label, count]) => ({ label, count }));
    this.engagementsData = this.sortData(engagements);
    this.maxEngagement = Math.max(...engagements.map((e) => e.count), 0);
  }

  private sortData(data: { label: string; count: number }[]): { label: string; count: number }[] {
    return data.sort((a, b) => (this.sortOrder === 'desc' ? b.count - a.count : a.count - b.count));
  }
}
