import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MESSAGES_DATA_SERVICE, MessagesDataService } from 'src/app/page-modules/messages/services/messages-data.service';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from 'src/app/shared/services/toast-notifications/toast-service';
import { TranslocoService } from '@ngneat/transloco';
import { AndRequest } from '../../../../../../shared/models/admin/boolean-filters.model';
import { EditorContent, EditorContentRemovalEvent, EditorContentUpdateEvent, MediaUploadData } from '../../../../../../shared/models';
import { EditorHelper } from '../../../../../../shared/helpers/editor.helper';
import { MembersMessageRequest, Message, MessageRequest } from '../../../../../../shared/models/messages/messages.model';
import { ObservableResult } from '../../../../../../shared/store';
import { ItemWithUploadUrls } from '../../../../../../shared/models/ItemWithUploadUrl';
import {
  FileUploadService,
  ORGANIZATIONS_FILE_UPLOAD_DATA_SERVICE,
} from '../../../../../../shared/services/file-upload/file-upload.service';
import { FileUploadHelper } from '../../../../../../shared/helpers/file-upload-helper';
import { EditorTransformer } from '../../../../../../shared/helpers/editor-transformer';

@Component({
  templateUrl: './send-message-dialog.component.html',
  styleUrls: ['./send-message-dialog.component.scss'],
})
export class SendMessageDialogComponent {
  checkedMemberIds: string[];
  query: AndRequest;
  allMembers: boolean;
  filteredCount: number;
  playlistUid?: string;
  cardUid?: string;

  subject: string;
  content: EditorContent[] = [];
  sendEmail = false;
  contentFocused: boolean;

  subjectError = false;
  contentError = false;

  constructor(
    private translocoService: TranslocoService,
    private dialogRef: MatDialogRef<SendMessageDialogComponent>,
    @Inject(MESSAGES_DATA_SERVICE) private messagesDataService: MessagesDataService,
    @Inject(ORGANIZATIONS_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      checkedMemberIds: string[];
      query: AndRequest;
      playlistUid?: string;
      cardUid?: string;
      allMembers: boolean;
      filteredCount: number;
    },
  ) {
    if (this.data) {
      this.checkedMemberIds = data.checkedMemberIds;
      this.query = data.query;
      this.playlistUid = data.playlistUid;
      this.cardUid = data.cardUid;
      this.allMembers = data.allMembers;
      this.filteredCount = data.filteredCount;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onContentUpdate({ index, update }: EditorContentUpdateEvent, level: EditorContent[]) {
    this.content = level.map((editorContent, i) => (i === index ? update.newContent : editorContent));
  }

  onContentRemoval({ index }: EditorContentRemovalEvent, level: EditorContent[]) {
    const filteredContent = level.filter((_, i) => i !== index);
    this.content = EditorHelper.joinSuccessiveParagraphContent(filteredContent);
  }

  onContentCreation(content: EditorContent, level: EditorContent[]) {
    this.content = EditorHelper.addDynamicContentWithBreakpointCheck(level, content);
  }

  onDataUpdate(type: 'SUBJECT' | 'CONTENT') {
    if (type === 'SUBJECT') {
      this.subjectError = false;
    } else if (type === 'CONTENT') {
      this.contentError = false;
    }
  }

  validateInputs() {
    if (!this.subject) {
      this.subjectError = true;
    }
    if (!this.content) {
      this.contentError = true;
    }
  }

  sendMessage() {
    this.validateInputs();
    if (!this.subjectError && !this.contentError) {
      if (this.checkedMemberIds.length) {
        this.sendMessageToSelected();
      } else {
        this.sendMessageByFilters();
      }
    }
  }

  sendMessageToSelected() {
    const request: MessageRequest = {
      recipients: this.checkedMemberIds,
      subject: this.subject,
      content: this.content.map((content) => EditorTransformer.transformEditorContentToMatchApiExpectations(content)),
      sendEmail: this.sendEmail,
      ...(this.playlistUid ? { playlistUid: this.playlistUid } : {}),
      ...(this.cardUid ? { cardUid: this.cardUid } : {}),
    };

    const files = this.content.filter((item) => item.type.endsWith('UPLOAD')).map((item) => (item as MediaUploadData).file);
    this.messagesDataService.createMessage(request).subscribe(({ isSuccess, value, error }) => {
      if (isSuccess) {
        this.toastService.showSuccess(this.translocoService.translate('translations.successes.successSentMessageToUsers'));
        files.forEach((file, index) => {
          this.uploadFile(value.uploadUrls[index], file);
        });
        this.closeDialog();
      } else {
        this.toastService.showFail(error);
      }
    });
  }

  sendMessageByFilters() {
    const request: MembersMessageRequest = {
      query: this.query,
      subject: this.subject,
      content: this.content.map((content) => {
        content = EditorTransformer.transformEditorContentToMatchApiExpectations(content);
        return content;
      }),
      sendEmail: this.sendEmail,
    };

    const files = this.content.filter((item) => item.type.endsWith('UPLOAD')).map((item) => (item as MediaUploadData).file);
    let membersMessageResult: ObservableResult<ItemWithUploadUrls<Message>>;
    if (this.cardUid) {
      membersMessageResult = this.messagesDataService.sendMessageToCardMembers(request, this.playlistUid, this.cardUid);
    } else if (this.playlistUid) {
      membersMessageResult = this.messagesDataService.sendMessageToPlaylistMembers(request, this.playlistUid);
    } else {
      membersMessageResult = this.messagesDataService.sendMessageToMembers(request);
    }
    membersMessageResult.subscribe(({ isSuccess, value, error }) => {
      if (isSuccess) {
        this.toastService.showSuccess(this.translocoService.translate('translations.successes.successSentMessageToUsers'));
        files.forEach((file, index) => {
          this.uploadFile(value.uploadUrls[index], file);
        });
        this.closeDialog();
      } else {
        this.toastService.showFail(error);
      }
    });
  }

  toggleSendEmail() {
    this.sendEmail = !this.sendEmail;
  }

  private uploadFile(url: string, file: File) {
    const filePath = FileUploadHelper.filePath(url);
    this.fileUploadService.uploadFile(filePath, file).subscribe();
  }
}
