/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ALLOWED_DOCUMENT_EXTENSIONS } from '@app/app/editor/components/editor-toolbar/file-upload/file-upload.component';
import { TranslocoService } from '@ngneat/transloco';

const BYTE_MULTIPLIER = 1024 * 1024;
const THREE_SUPPORTED_FORMATS = {
  IMAGE: 'Something, something, something',
  STREAM_VIDEO: 'MP4, MOV, AVI',
  AUDIO: 'MP3, WAV, OGG',
  DOCUMENT: 'PDF, DOC, DOCX',
};
const FOURTH_SUPPORTED_FORMAT = {
  IMAGE: 'Something',
  STREAM_VIDEO: 'WebM',
  AUDIO: 'FLAC',
  DOCUMENT: 'XLS',
};
const MIME_TYPES = {
  IMAGE: ['image/*'],
  STREAM_VIDEO: [
    'video/mp4',
    'application/mp4',
    'application/x-mp4',
    'video/quicktime',
    'video/x-quicktime',
    'video/webm',
    'video/x-m4v',
    'video/x-msvideo',
    'video/vnd.avi',
    'video/avi',
    'video/msvideo',
    'video/m4v',
  ],
  AUDIO: ['audio/*'],
  CSV: ['text/csv'],
  DOCUMENT: [ALLOWED_DOCUMENT_EXTENSIONS],
};

export enum UploadFileType {
  ALL_TYPES = 'ALL_TYPES',
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  STREAM_VIDEO = 'STREAM_VIDEO',
  DOCUMENT = 'DOCUMENT',
  CSV = 'CSV',
}

@Component({
  selector: 'ptl-file-dropzone',
  templateUrl: './file-dropzone.component.html',
  styleUrls: ['./file-dropzone.component.scss'],
})
export class FileDropzoneComponent implements OnInit {
  @Input() type: UploadFileType;
  @Input() maxFilesCount: number;
  @Input() maxFileSizeMB: number;

  @Output() filesSelected: EventEmitter<File[]> = new EventEmitter();

  filesHovered = false;
  uploadErrorText: string | null = null;
  acceptedFileTypes: string;
  threeSupportedFormats: string;
  fourthSupportedFormat: string;

  @ViewChild('dropzoneFileInput') dropzoneFileInput: ElementRef;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.acceptedFileTypes = this.getAcceptedFileTypes(this.type);
    if (this.type !== UploadFileType.ALL_TYPES) {
      this.threeSupportedFormats = THREE_SUPPORTED_FORMATS[this.type];
      this.fourthSupportedFormat = FOURTH_SUPPORTED_FORMAT[this.type];
    }
  }

  triggerFileSelection(): void {
    this.uploadErrorText = null;
    this.dropzoneFileInput.nativeElement.click();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length && this.validateFiles(input.files)) {
      this.emitLimitedNumberOfFiles(input.files);
    }
  }

  onFileDropped(fileList: FileList): void {
    this.filesHovered = false;
    if (this.validateFiles(fileList)) {
      this.emitLimitedNumberOfFiles(fileList);
    }
  }

  emitLimitedNumberOfFiles(fileList: FileList): void {
    const files: File[] = [];
    for (let i = 0; i < fileList.length && i < this.maxFilesCount; i++) {
      files.push(fileList[i]);
    }
    this.filesSelected.emit(files);
  }

  private getAcceptedFileTypes(type: UploadFileType): string {
    if (this.type === UploadFileType.ALL_TYPES) {
      return (
        MIME_TYPES[UploadFileType.IMAGE] +
        ', ' +
        MIME_TYPES[UploadFileType.AUDIO] +
        ', ' +
        MIME_TYPES[UploadFileType.STREAM_VIDEO] +
        ', ' +
        MIME_TYPES[UploadFileType.DOCUMENT]
      );
    } else {
      return MIME_TYPES[type]?.join(', ');
    }
  }

  private validateFiles(fileList: FileList): boolean {
    if (fileList.length > this.maxFilesCount) {
      this.uploadErrorText = this.translocoService.translate('translations.fileDropzone.errors.errorTooManyFiles');
      return false;
    }

    for (let i = 0; i < fileList.length; i++) {
      if (!this.isValidFile(fileList[i])) {
        return false;
      }
    }

    return true;
  }

  private isValidFile(file: File): boolean {
    if (!this.isValidFileType(file)) {
      this.uploadErrorText = this.translocoService.translate('translations.fileDropzone.errors.errorUnsupportedFileType');
      return false;
    }
    if (file.size > this.maxFileSizeMB * BYTE_MULTIPLIER) {
      this.uploadErrorText = this.translocoService.translate('translations.fileDropzone.errors.errorFileSizeExceedsLimit');
      return false;
    }
    return true;
  }

  private isValidFileType(file: File): boolean {
    if (this.type === UploadFileType.ALL_TYPES) {
      return (
        this.isValidMimeType(file, UploadFileType.IMAGE) ||
        this.isValidMimeType(file, UploadFileType.AUDIO) ||
        this.isValidMimeType(file, UploadFileType.STREAM_VIDEO) ||
        this.isValidMimeType(file, UploadFileType.DOCUMENT)
      );
    } else {
      return this.isValidMimeType(file, this.type);
    }
  }

  private isValidMimeType(file: File, type: UploadFileType): boolean {
    const mimeType = file.type;
    if (type === UploadFileType.DOCUMENT) {
      const extension = file.name.split('.').pop()?.toLowerCase();
      return extension && MIME_TYPES[type][0].split(',')?.includes(`.${extension}`);
    }

    return MIME_TYPES[type].some(
      (validMimeType) => validMimeType === mimeType || (validMimeType.includes('/*') && mimeType.startsWith(validMimeType.split('/')[0])),
    );
  }

  protected readonly UploadFileType = UploadFileType;
}
