/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { Injectable } from '@angular/core';
import { TagDataService, MultiTagCreationRequest, TagInfo } from './tag-data.service';
import { TagRequest, TagTypeResponse } from '../../../page-modules/playlist/models';
import { ResourceTag } from '../../models';

@Injectable()
export class MockTagDataService implements TagDataService {
  private tag: ResourceTag = { _id: 'tagId', title: 'AWS' };

  createTag(request: TagRequest): ObservableResult<ResourceTag> {
    return ObservableResult.ofSuccess(this.tag);
  }

  createTags(request: MultiTagCreationRequest): ObservableResult<ResourceTag[]> {
    return ObservableResult.ofSuccess([this.tag]);
  }

  findTagsWithTitleTerm(text: string): ObservableResult<ResourceTag[]> {
    return ObservableResult.ofSuccess([this.tag]);
  }

  findLtiTagsWithTitleTerm(organizationUid: string, requestId: string, text: string): ObservableResult<ResourceTag[]> {
    return ObservableResult.ofSuccess([this.tag]);
  }

  extractTags(text: string): ObservableResult<string[]> {
    return ObservableResult.ofSuccess(['tag1', 'tag2']);
  }

  getTraitTags(page: number, size: number): ObservableResult<TagTypeResponse[]> {
    return ObservableResult.ofSuccess();
  }

  getTeamRoleTags(page: number, size: number): ObservableResult<TagTypeResponse[]> {
    return ObservableResult.ofSuccess();
  }

  getJungianTypeTags(page: number, size: number): ObservableResult<TagTypeResponse[]> {
    return ObservableResult.ofSuccess();
  }

  getBigSixTags(page: number, size: number): ObservableResult<TagTypeResponse[]> {
    return ObservableResult.ofSuccess();
  }

  findCategoriesWithTitleTerm(text: string): ObservableResult<ResourceTag[]> {
    return ObservableResult.ofSuccess();
  }

  createCategory(request: TagRequest): ObservableResult<ResourceTag> {
    return ObservableResult.ofSuccess();
  }

  findCategories(categoriesUid: string[]): ObservableResult<TagInfo[]> {
    return ObservableResult.ofSuccess();
  }
}
