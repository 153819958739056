/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { MediaType } from '@app/app/shared/models';
import { ALLOWED_DOCUMENT_EXTENSIONS } from '@app/app/editor/components/editor-toolbar/file-upload/file-upload.component';
import { marker } from '@jsverse/transloco-keys-manager/marker';

const MAX_FILE_SIZES_MB = {
  STREAM_VIDEO: 500,
  AUDIO: 200,
  DOCUMENT: 200,
};
const BYTE_MULTIPLIER = 1024 * 1024;
const THREE_SUPPORTED_FORMATS = {
  STREAM_VIDEO: 'MP4, MOV, AVI',
  AUDIO: 'MP3, WAV, OGG',
  DOCUMENT: 'PDF, DOC, DOCX',
};
const FOURTH_SUPPORTED_FORMAT = {
  STREAM_VIDEO: 'WebM',
  AUDIO: 'FLAC',
  DOCUMENT: 'XLS',
};
const MIME_TYPES = {
  STREAM_VIDEO: ['video/*'],
  AUDIO: ['audio/*'],
  DOCUMENT: [ALLOWED_DOCUMENT_EXTENSIONS],
};

@Component({
  selector: 'ptl-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss'],
})
export class FileUploadDialogComponent implements OnInit {
  @ViewChild('uploadFolioInput') uploadFolioInput?: ElementRef;

  filesHovered = false;
  uploadErrorText: string | null = null;
  type: string;
  accept: string;
  maxFileSizeMB = '';
  threeSupportedFormats = '';
  fourthSupportedFormat = '';

  readonly dialogTypeMapping = {
    STREAM_VIDEO: marker('translations.editor.toolbar.label.uploadVideo'),
    AUDIO: marker('translations.editor.toolbar.label.uploadAudio'),
    DOCUMENT: marker('translations.editor.toolbar.label.uploadFile'),
  };
  constructor(
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    private translocoService: TranslocoService,
    @Inject(MAT_DIALOG_DATA) public data: { type: MediaType },
  ) {
    this.type = this.data.type;
  }

  ngOnInit(): void {
    this.initializeTypeSettings();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  triggerFileSelection(): void {
    this.uploadErrorText = null;
    this.uploadFolioInput?.nativeElement.click();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.validateAndUploadFile(input.files[0]);
    }
  }

  onFileDropped(fileList: FileList): void {
    if (fileList.length === 1) {
      this.validateAndUploadFile(fileList.item(0));
    }
  }

  private initializeTypeSettings(): void {
    this.accept = MIME_TYPES[this.type]?.join(', ');
    this.maxFileSizeMB = MAX_FILE_SIZES_MB[this.type];
    this.threeSupportedFormats = THREE_SUPPORTED_FORMATS[this.type];
    this.fourthSupportedFormat = FOURTH_SUPPORTED_FORMAT[this.type];
  }

  private validateAndUploadFile(file: File): void {
    this.uploadErrorText = null;
    this.filesHovered = false;
    if (file && this.isValidFile(file)) {
      this.dialogRef.close(file);
    }
  }

  private isValidFile(file: File): boolean {
    if (!this.isValidMimeType(file)) {
      this.uploadErrorText = this.translocoService.translate('translations.errors.errorUnsupportedFileType');
      return false;
    }
    if (file.size > MAX_FILE_SIZES_MB[this.type] * BYTE_MULTIPLIER) {
      this.uploadErrorText = this.translocoService.translate('translations.errors.errorFileSizeExceedsLimit');
      return false;
    }
    return true;
  }

  private isValidMimeType(file: File): boolean {
    const mimeType = file.type;
    if (this.type === 'DOCUMENT') {
      const extension = file.name.split('.').pop()?.toLowerCase();
      return extension && MIME_TYPES[this.type][0].split(',')?.includes(`.${extension}`);
    }

    return MIME_TYPES[this.type].some((type) => type === mimeType || (type.includes('/*') && mimeType.startsWith(type.split('/')[0])));
  }
}
