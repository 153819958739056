/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MediumEditorData, MediumEditorToolbarEvent } from '../../../../models';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TinyMceEditorDirective } from './tinymce-editor-dir/tinymce-editor.dir';
import { canTriggerSearch } from '../../../../../shared/helpers/content-helper';

@Component({
  selector: 'ptl-tinymce-editor',
  templateUrl: './tinymce-editor.component.html',
  styleUrls: ['./tinymce-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TinymceEditorComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() contentData: MediumEditorData;

  @Input() lastEditor: boolean;

  @Input() firstEditor: boolean;

  @Input() placeholder: string;

  @Input() editorOptions;

  /** Outputs the various click and change events */
  @Output() tinymceEditorUpdate = new EventEmitter<MediumEditorData>();

  @Output() removeEditorContent = new EventEmitter<void>();
  /** Emits editor-toolbar show/hide event */
  @Output() toolbarUpdate = new EventEmitter<MediumEditorToolbarEvent>();
  /** Outputs the various click and change events */
  @Output() inputFocus = new EventEmitter<void>();
  /** Outputs the various click and change events */
  @Output() inputBlur = new EventEmitter<void>();

  @ViewChild('tinyEditor', { read: TinyMceEditorDirective, static: true }) tinyEditor: TinyMceEditorDirective;

  editorInput = '';

  private editorUpdateSubject$ = new Subject<MediumEditorData>();
  private editorInputSubscription: Subscription;
  private editorUpdateSubscription: Subscription;
  private editorKeyupSubscription: Subscription;
  private editorClickSubscription: Subscription;
  private editorBlurSubscription: Subscription;
  private editorFocusSubscription: Subscription;
  private editorOnChangeSubscription: Subscription;
  private clearBackspaceCount = 0;
  private targetElement = null;
  private brTagRegex = /<br(\s+[^>]*)?>/;

  private boundClickHandler = this.handleClickListener.bind(this);

  constructor(private renderer: Renderer2) {
    this.editorInputSubscription = this.editorUpdateSubject$
      .pipe(
        debounceTime(100),
        distinctUntilChanged((data: MediumEditorData) => data.content === this.editorInput),
      )
      .subscribe((value: MediumEditorData) => this.tinymceEditorUpdate.emit(value));
  }

  handleClickListener(event: MouseEvent) {
    this.targetElement = event.target;
  }

  ngOnInit(): void {
    document.addEventListener('mousedown', this.boundClickHandler);

    if (this.contentData && !!this.contentData.content) {
      let content = JSON.stringify(this.contentData.content);
      const hasOpenedDivTag = content.indexOf('<div>') !== -1;
      const hasClosedDivTag = content.indexOf('</div>') !== -1;
      if ((!hasOpenedDivTag && hasClosedDivTag) || (hasOpenedDivTag && !hasClosedDivTag)) {
        content = content.replace(/<\/div>/g, '').replace(/<div>/g, '');
      }

      this.editorInput = JSON.parse(content);
    }
  }

  ngOnDestroy(): void {
    this.editorUpdateSubscription?.unsubscribe();
    this.editorInputSubscription?.unsubscribe();
    this.editorKeyupSubscription?.unsubscribe();
    this.editorClickSubscription?.unsubscribe();
    this.editorBlurSubscription?.unsubscribe();
    this.editorFocusSubscription?.unsubscribe();
    this.editorOnChangeSubscription?.unsubscribe();
    document.removeEventListener('mousedown', this.boundClickHandler);
  }

  ngAfterViewInit(): void {
    this.loadSubscriptions();
  }

  ngOnChanges() {
    this.editorInput = JSON.parse(JSON.stringify(this.contentData.content));
  }

  loadSubscriptions(): void {
    this.editorUpdateSubscription = this.tinyEditor?.editorModelChange.subscribe(() => {
      this.editorUpdateSubject$.next({
        uid: this.contentData.uid,
        type: 'PARAGRAPH',
        content: this.editorInput,
      } as MediumEditorData);
    });

    this.editorKeyupSubscription = this.tinyEditor.editorBox.onKeyUp?.subscribe((data, editor) => {
      // on keypress Enter, show the toolbar, else clear everything
      if (data.event.key !== 'Backspace') {
        this.clearBackspaceCount = 0;
      }
      if (data.event.key === 'Enter') {
        this.showToolbar(data.editor.targetElm);
      } else {
        if (canTriggerSearch(data.event)) {
          this.toolbarUpdate.emit({ type: 'HIDE_TOOLBAR' });
          this.emitUpdate();
        }
      }
      if (data.event.key === 'Backspace') {
        this.toolbarUpdate.emit({ type: 'HIDE_TOOLBAR' });
        if (data.editor.getContent() === '') {
          this.clearBackspaceCount++;
          if (this.clearBackspaceCount > 1) {
            this.removeEditorContent.emit();
            this.clearBackspaceCount = 0;
          }
        }
      }
    });

    this.editorClickSubscription = this.tinyEditor.editorBox.onClick?.subscribe((data) => {
      this.showToolbar(data.editor.targetElm);
    });

    this.editorBlurSubscription = this.tinyEditor.editorBox.onBlur?.subscribe(({ event, editor }) => {
      if (event.type === 'blur' && !!document.getElementById('toolbarFloatingButtonsContainer')) {
        return;
      }

      setTimeout(() => {
        if (this.targetElement && !this.targetElement.closest('.f_editor-container')?.querySelector('.f_editor-toolbar-add-button')) {
          this.toolbarUpdate.emit({ type: 'HIDE_TOOLBAR' });
          this.inputBlur.emit();
        }
      }, 0);
    });

    this.editorOnChangeSubscription = this.tinyEditor.editorBox.onChange?.subscribe(({ event, editor }) => {
      setTimeout(() => this.tinyEditor.editableInput());
    });
    // subscribe to medium-editor's focus event
    this.editorFocusSubscription = this.tinyEditor.editorBox.onFocus?.subscribe(() => {
      this.inputFocus.emit();
    });
  }

  insertBreakpoint(closestParagraph: Element) {
    if (closestParagraph.parentElement && closestParagraph.parentElement.querySelector('split')) {
      return;
    }

    const newElement = this.renderer.createElement('split');
    this.renderer.insertBefore(closestParagraph.parentElement, newElement, closestParagraph);

    this.tinymceEditorUpdate.emit({
      uid: this.contentData.uid,
      type: 'PARAGRAPH',
      content: this.tinyEditor.editorBox.editor.targetElm?.innerHTML,
    } as MediumEditorData);
  }

  insertLinkBeforeBreakpoint(link: string) {
    if (this.tinyEditor.getElement()) {
      // select all <split> elements
      const allSplitEl = this.tinyEditor.getElement().querySelectorAll('split');

      const paragraphElement = document.createElement('p');
      const linkElement = document.createElement('a');

      linkElement.innerText = link;

      if (!link.startsWith('http://') && !link.startsWith('https://')) {
        link = 'http://' + link;
      }

      const openTarget = link.indexOf(window.location.hostname) !== -1 ? '_self' : '_blank';
      linkElement.href = encodeURI(link);
      linkElement.target = openTarget;
      paragraphElement.appendChild(linkElement);

      if (allSplitEl.length > 0) {
        this.renderer.insertBefore(allSplitEl[0].parentElement, paragraphElement, allSplitEl[0]);
      } else {
        const parentElement = this.tinyEditor.getElement().firstElementChild;

        if (parentElement) {
          this.renderer.appendChild(parentElement, paragraphElement);
        }
      }
      setTimeout(() => this.tinyEditor.editableInput());
    }
  }

  private showToolbar(html: HTMLElement): void {
    const toolbarEventObject: MediumEditorToolbarEvent = {
      type: 'SHOW_TOOLBAR',
    };

    // selection element where caret is present
    const selection = document.getSelection();
    const caretEl = selection.anchorNode;
    // if caretEl present and has <br> OR it is the initial wrapper (.mce-content-body), make the magic
    const caretElValidator =
      caretEl &&
      (this.brTagRegex.test((caretEl as HTMLElement).innerHTML) ||
        (caretEl && (caretEl as HTMLElement).className && (caretEl as HTMLElement).className.includes('mce-content-body')));
    if (caretElValidator || selection.anchorOffset >= 0) {
      const isText = this.isTextNode(caretEl);
      if (!isText) {
        if ((caretEl as HTMLElement)?.closest('table')) {
          this.toolbarUpdate.emit({ type: 'HIDE_TOOLBAR' });
          return;
        }
      }

      const closestParagraph = isText
        ? selection.anchorNode.parentElement
        : (caretEl as HTMLElement).closest('.mce-content-body div,p,h1,h2,h3,h4,h5,h6');

      toolbarEventObject.offsetTop = isText ? (closestParagraph as HTMLElement).offsetTop : (caretEl as HTMLElement).offsetTop;

      toolbarEventObject.contentUid = this.contentData.uid;
      toolbarEventObject.element = closestParagraph;

      this.toolbarUpdate.emit(toolbarEventObject);
    } else {
      this.toolbarUpdate.emit({ type: 'HIDE_TOOLBAR' });
    }
  }

  private isTextNode(element: Node) {
    return element && element.nodeType === 3;
  }

  private emitUpdate() {
    this.tinyEditor.editableInput();
  }
}
