/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { RestClientService } from '../../../../shared/services/rest-client.service';
import { TranslocoService } from '@ngneat/transloco';
import { ObservableResult } from '../../../../shared/store';
import { ImportUsersService } from './import-users.service';

@Injectable()
export class ApiImportUsersService implements ImportUsersService {
  constructor(
    private restClient: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  initializeLearnerCardForUsers(playlistUid: string, cardUid: string, usersUid: string[]): ObservableResult<void> {
    return this.restClient
      .post<void>(
        Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/learner-card/initialize/${cardUid}`),
        usersUid,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorImportingUsers'))),
      );
  }

  initializeLearnerPlaylistForUsers(playlistUid: string, usersUid: string[]): ObservableResult<void> {
    return this.restClient
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/learner-playlist/initialize`), usersUid)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorImportingUsers'))),
      );
  }
}
