<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="collector-section" (click)="expandForm()" (keydown.enter)="$event.stopPropagation()">
  <form
    class="collector-section__expanded"
    *ngIf="expanded"
    tabindex="0"
    #collectorHtml
    [attr.aria-label]="'translations.global.ariaLabels.collectorForm' | transloco"
    [formGroup]="collectorForm"
    (ngSubmit)="updateCollector()"
  >
    <div class="collector-section__editor">
      <div class="collector-section__head">
        <mat-form-field class="collector-section__title mat-no-border mat-full-width">
          <mat-label>{{ 'translations.collector.input.label.addLogTitle' | transloco }}</mat-label>
          <input matInput class="collector-section__title-input" type="text" formControlName="title" autocomplete="off" required />
          <mat-error *ngIf="collectorForm.controls['title'].hasError('required')">
            {{ 'translations.headlineRequiredError' | transloco }}
          </mat-error>
        </mat-form-field>
        <ptl-editor
          class="collector-section__instructions-editor"
          [content]="instruction?.dynamicContent"
          [placeholder]="'translations.collector.input.label.addLogInstruction' | transloco"
          (contentUpdate)="onContentUpdate($event, instruction)"
          (contentRemoval)="onContentRemoval($event, instruction)"
          (contentCreated)="onContentCreation($event, instruction)"
        >
        </ptl-editor>
      </div>
      <div class="collector-section__form">
        <ptl-collector-form-content
          #collectorFormContent
          [collectorConfig]="collectorConfig"
          [collectorTags]="collectorTags"
          [collectorFrameworks]="collectorFrameworks"
          (collectorFormContent)="onCollectorFieldsUpdate($event)"
        ></ptl-collector-form-content>
      </div>
    </div>
    <div class="collector-section__actions">
      <div class="collector-section__actions-right">
        <button
          mat-button
          class="collector-section__footer-btn f_delete-block"
          color="warn"
          title="Remove element"
          type="button"
          *ngIf="canRemoveItem"
          [attr.aria-label]="'translations.global.ariaLabels.deleteLogItem' | transloco"
          (click)="removeCollector()"
        >
          {{ 'translations.global.button.delete' | transloco }}
        </button>
        <button
          [disabled]="isButtonClicked"
          mat-flat-button
          class="collector-section__footer-btn uppercase btn-with-loader"
          color="primary"
          title="Update"
          [attr.aria-label]="'translations.global.ariaLabels.updateLogItem' | transloco"
          type="submit"
        >
          {{ 'translations.global.button.update' | transloco }}
          <mat-spinner class="is-right" *ngIf="saveInProgress" [diameter]="20"></mat-spinner>
        </button>
        <button
          mat-button
          class="collector-section__footer-btn"
          color="accent"
          title="Remove element"
          type="button"
          [attr.aria-label]="'translations.global.ariaLabels.collapseForm' | transloco"
          (click)="collapseForm($event)"
        >
          {{ 'translations.global.button.cancel' | transloco }}
        </button>
      </div>
    </div>
  </form>
  <div class="collector-section__contracted" *ngIf="!expanded">
    <div class="collector-section__contracted-text">
      <span class="collector-section__contracted-label uppercase">{{ 'translations.collector.title.log' | transloco }}</span>
      <span>{{ collectorForm?.value.title }}</span>
    </div>
    <div class="collector-section__contracted-actions">
      <span class="setting-text extra-small" *ngIf="!expanded">{{ 'translations.global.button.settings' | transloco }}</span>
    </div>
  </div>
</div>
