import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { COLLECTOR_DATA_SERVICE, CollectorDataService } from '../../../../services/collector/collector-data.service';
import { MemberRecordPlaylistCardLearnerAnswers } from '../../../../models/admin/members.model';
import { LearnerCollectorSummary } from '../../../../models/editor/collector-content.model';
import { Select } from '@ngxs/store';
import { PlaylistViewState } from '../../../../../page-modules/playlist/store/view/playlist-view.state';
import { Observable, Subscription, filter } from 'rxjs';

@Component({
  selector: 'ptl-collector-review',
  templateUrl: './collector-review.component.html',
  styleUrls: ['./collector-review.component.scss'],
})
export class CollectorReviewComponent implements OnInit, OnDestroy {
  @Input() formData: MemberRecordPlaylistCardLearnerAnswers;

  @Input() reviewUid: string;

  @Select(PlaylistViewState.playlistId)
  private playlistId$: Observable<string>;

  private playlistId: string;
  private playlistIdSubscribtion: Subscription;
  learnerCollectorSummary: LearnerCollectorSummary;

  constructor(@Inject(COLLECTOR_DATA_SERVICE) private collectorDataService: CollectorDataService) {}

  ngOnInit() {
    if (this.formData && this.reviewUid) {
      this.playlistIdSubscribtion = this.playlistId$
        .pipe(filter((playlistId) => !!playlistId))
        .subscribe((playlistId: string) => (this.playlistId = playlistId));
      this.collectorDataService
        .getLearnerCollectorSummaryAsReviewer(this.playlistId, this.formData.collectorId, this.reviewUid)
        .subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            this.learnerCollectorSummary = value;
          }
        });
    }
  }

  ngOnDestroy() {
    this.playlistIdSubscribtion?.unsubscribe();
  }
}
