/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';
import { UserDetails } from '../../../../user-auth/models';
import { ChartEditorContent, PIReport, PITraitGroupScore, PITraitGroupScoreReport } from '../../../../shared/models/pi/pi.model';
import { PI_DATA_SERVICE, PIDataService } from '../../../../shared/services/pi/pi-data.service';
import { ChartsHelper } from '../../../../shared/helpers/charts-helper';

@Component({
  selector: 'ptl-pi-big-six-chart',
  templateUrl: './pi-big-six-chart.component.html',
  styleUrls: ['./pi-big-six-chart.component.scss'],
})
export class PersonalIndicatorBigSixChartComponent implements OnInit, OnDestroy {
  @Input() content: ChartEditorContent;

  @Input() showStaticData: boolean;

  @Input() isPIReport: boolean;

  @Input() reports: PIReport[];

  @Select(UserAuthState.userDetailsData)
  userDetailsData$: Observable<UserDetails>;

  chartDatasets;
  chartScores: number[] = [];
  colors: string[] = [];
  transparentColors: string[] = [];
  chartDataLabels: string[];
  piReport: PIReport;
  chartData: PITraitGroupScoreReport;

  private readonly reportColors: string[] = ['#fd66c6', '#f9674a', '#5381bc', '#f4940f', '#66b8fd'];

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: false,
        external: function (context) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartTooltip');
          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartTooltip';
            tooltipEl.className = 'chart-tooltip';
            tooltipEl.innerHTML = '<div class="tooltip-content"></div>';
            document.body.appendChild(tooltipEl);
          }
          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            const bodyLines = tooltipModel.body.map(getBody);
            let innerHtml = '<div class="tooltip-content-inner">';
            bodyLines.forEach((body, i) => {
              innerHtml += '<span class="tooltip-title">' + body + '</span>';
            });
            innerHtml += '</div>';
            const tableRoot = tooltipEl.querySelector('div');
            tableRoot.innerHTML = innerHtml;
          }
          // `this` will be the overall tooltip
          // @ts-ignore: Official example from https://www.chartjs.org/docs/latest/configuration/tooltip.html#external-custom-tooltips
          const position = context.chart.canvas.getBoundingClientRect();
          // Display, position, and set styles for font
          tooltipEl.style.opacity = '1';
          tooltipEl.style.left = (position.left + window.pageXOffset + tooltipModel.caretX) / 16 + 'rem';
          tooltipEl.style.top = (position.top + window.pageYOffset + tooltipModel.caretY) / 16 + 'rem';
        },
      },
    },
    elements: { arc: { borderWidth: 2, borderColor: '#ffffff' } },
    scales: {
      r: {
        ticks: {
          display: false,
        },
      },
    },
  };

  constructor(
    @Inject(PI_DATA_SERVICE) private piDataService: PIDataService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (!this.showStaticData && this.content) {
      if (this.isPIReport && this.reports) {
        this.setReportData(this.reports);
        return;
      }
      this.piDataService.getPIReports(this.content.piCardId).subscribe(({ isSuccess, value }) => {
        if (isSuccess && value.reports.length) {
          this.setReportData(value.reports);
        }
      });
    }
  }

  ngOnDestroy() {
    document.getElementById('chartTooltip')?.remove();
  }

  private setReportData(reports: PIReport[]) {
    this.piReport = reports[0];
    this.formatData(this.piReport.traitGroupScoreReport.traitGroupScores);
    this.chartData = this.piReport.traitGroupScoreReport;
    this.cd.detectChanges();
  }

  private formatData(traits: PITraitGroupScore[]) {
    const scores = [];
    const labels = [];

    for (let i = 0; i < traits.length; i++) {
      const trait = traits[i];
      const descriptions = ChartsHelper.getPIChartsDescriptionsByLanguageCode(trait.name, this.content);
      trait.description = descriptions.full;
      trait.shortDescription = descriptions.short;
      if (!trait.description.length) {
        traits.splice(i, 1);
        i--;
        continue;
      }

      trait.value = +trait.value.toFixed(0);
      trait.name = trait.name.replace(/_/g, ' ');
      trait.color = this.isPIReport ? this.reportColors[i] : this.generateRandomHexColor();

      labels.push(this.upperFirst(trait.name));
      scores.push(trait.value);
      this.transparentColors.push(this.getTransparentColor(trait.color, 0.4));
      this.colors.push(trait.color);
    }

    this.chartScores = scores;
    this.chartDataLabels = labels;

    this.chartDatasets = {
      labels: this.chartDataLabels,
      datasets: [
        {
          data: this.chartScores,
          backgroundColor: this.transparentColors,
        },
      ],
    };
  }

  private upperFirst(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  private getTransparentColor(color: string, opacity?: number) {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const alpha = opacity ? opacity : 0.4;
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  }

  private generateRandomHexColor(): string {
    let length = 6;
    const chars = '0123456789ABCDEF';
    let hex = '#';
    while (length--) {
      /* eslint-disable no-bitwise */
      hex += chars[(Math.random() * 16) | 0];
    }
    return hex;
  }
}
