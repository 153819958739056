<div class="pagination-block">
  <div class="per-page-box" *ngIf="showPageSize">
    <div class="per-page-prefix">
      {{ 'translations.global.pagination.showOnPage' | transloco }}
    </div>

    <mat-select
      [(ngModel)]="itemsPerPage"
      [attr.aria-label]="'translations.global.ariaLabels.showItemsPerPage' | transloco"
      (selectionChange)="onSizeChange()"
    >
      <mat-option [value]="10">{{ 'translations.global.pagination.showPerPage.show10' | transloco }}</mat-option>
      <mat-option [value]="25">{{ 'translations.global.pagination.showPerPage.show25' | transloco }}</mat-option>
      <mat-option [value]="50">{{ 'translations.global.pagination.showPerPage.show50' | transloco }}</mat-option>
    </mat-select>
  </div>
  <ptl-pagination
    *ngIf="isPaginationVisible"
    class="pagination"
    [totalItems]="totalItems"
    [isZeroBasedIndex]="isZeroBasedIndex"
    [totalPageCount]="totalPageCount"
    [currentPageIndex]="currentPageIndex"
    [itemsPerPage]="itemsPerPage"
    (pageChange)="onPageChange($event)"
  ></ptl-pagination>
</div>
