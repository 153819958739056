import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BOOKING_DATA_SERVICE, BookingDataService } from '../../../../../../../shared/services/booking/booking-data.service';
import {
  BookableAppointment,
  BookableInterval,
  BookableResource,
  BookableSchedule,
  BookableSlotAdmin,
  BookingFormCheckboxType,
  RecurringScheduleRequest,
} from '../../../../../../../shared/models/editor/booking-content.model';
import { Store } from '@ngxs/store';
import { Subject, Subscription } from 'rxjs';
import { UserSearch } from '../../../../../../../shared/models/admin/members.model';
import { MEMBERS_DATA_SERVICE, MembersDataService } from '../../../../../../../page-modules/admin/services/members/members-data.service';
import { UserSearchAutocompleteComponent } from '../../../../../../../shared/components/user-search-autocomplete/user-search-autocomplete.component';
import { MatSelect } from '@angular/material/select';
import * as moment from 'moment';

@Component({
  selector: 'ptl-form-booking-resource-item',
  styleUrls: ['./form-booking-resource-item.component.scss'],
  templateUrl: './form-booking-resource-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormBookingResourceItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() resource: BookableResource;
  @Input() timeIntervals: BookableInterval[];
  @Input() currentLanguage: string;
  @Input() cardUid: string;
  @Input() appointment: BookableAppointment;
  @Input() saveInProgress: boolean;
  @Output() resourceUpdated = new EventEmitter<boolean>();
  @Output() resourceDeleted = new EventEmitter<string>();
  @ViewChildren('matSelectRef') matSelectRefs: QueryList<MatSelect>;

  bookingForm: FormGroup;
  appointmentForm: FormGroup;
  loadingMembers: boolean;
  members: UserSearch[];
  @ViewChild('userSearchAutocomplete') private userSearchAutocomplete: UserSearchAutocompleteComponent;
  activeResource: string;
  scheduleUid: string = null;
  weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  @Output() appointmentHasError = new EventEmitter<boolean>();
  @Output() resourceHasError = new EventEmitter<boolean>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() scheduleUpdated = new EventEmitter<{ resourceUid: string; scheduleUid: string; slots: BookableSlotAdmin[] }>();
  activeSlots: BookableSlotAdmin[] = [];
  isUserTypeSelected = true;
  userType: BookingFormCheckboxType = BookingFormCheckboxType.USER;
  dayValues: { slots: BookableSlotAdmin[]; day: string }[] = [];
  scheduleHasError = false;
  mondayHasValidationError = false;

  private autoSaveSubscription: Subscription;
  private autoSaveSubject$ = new Subject<void>();

  private scheduleLoadingSubscription: Subscription;
  private scheduleAvailabilitySubscription: Subscription;
  private scheduleCreationSubscription: Subscription;
  private resourceDisassociationSubscription: Subscription;
  private slotDeletionSubscription: Subscription;
  private nameUpdateSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private cd: ChangeDetectorRef,
    @Inject(BOOKING_DATA_SERVICE) private bookingDataService: BookingDataService,
    @Inject(MEMBERS_DATA_SERVICE) private membersDataService: MembersDataService,
  ) {}

  ngOnInit() {
    this.activateResource(this.resource.id);
  }

  ngOnDestroy() {
    this.scheduleLoadingSubscription?.unsubscribe();
    this.scheduleAvailabilitySubscription?.unsubscribe();
    this.resourceDisassociationSubscription?.unsubscribe();
    this.scheduleCreationSubscription?.unsubscribe();
    this.slotDeletionSubscription?.unsubscribe();
    this.autoSaveSubscription?.unsubscribe();
    this.nameUpdateSubscription?.unsubscribe();
  }

  ngOnChanges() {
    if (this.saveInProgress) {
      this.activeResource = null;
      this.cd.detectChanges();
    }
  }

  activateResource(resourceUid: string) {
    if (this.saveInProgress || resourceUid === this.activeResource) {
      this.updateScheduleAvailability();
      this.activeResource = null;
      return;
    }
    this.activeResource = resourceUid;
    this.onRequestProcessing();
    this.scheduleLoadingSubscription = this.bookingDataService
      .getSchedule(this.cardUid, resourceUid, this.currentLanguage)
      .subscribe(({ isSuccess, value }) => {
        if (value) {
          this.activateSchedule(value.schedule, value.slots);
        } else {
          this.createSchedule(new RecurringScheduleRequest(new Date(), null), resourceUid);
        }
        this.isLoading.emit(false);
      });
  }

  updateScheduleAvailability() {
    const updatedResourceRequest = {
      resourceUid: this.resource.id,
      scheduleUid: this.scheduleUid,
      slots: this.dayValues.flatMap((value) => value.slots).filter((value) => value.startTime !== null || value.endTime !== null),
    };
    this.scheduleUpdated.emit(updatedResourceRequest);
  }

  activateDay(resourceUid: string, dayValue: { slots: BookableSlotAdmin[]; day: string }) {
    const isCheckedAlready = this.checkIfAlreadyChecked(dayValue);
    if (this.dayValues.find((value) => value.day === dayValue.day)?.slots?.length === 0) {
      this.addNewTimeSlot(null, dayValue.day);
    }
    if (isCheckedAlready) {
      this.dayValues.find((value) => value.day === dayValue.day).slots = [];
    }
    this.updateScheduleAvailability();
    this.cd.detectChanges();
  }

  addNewTimeSlot(event: MouseEvent, day: string) {
    event?.preventDefault();
    const defaultTimeSlot = { id: null, weekday: day, startTime: '09:00', endTime: '17:00' };
    const defaultEmptyTimeSlot = { id: null, weekday: day, startTime: null, endTime: null };
    const slots = this.dayValues.find((value) => value.day === day).slots;
    if (slots.length === 0) {
      slots.push(defaultTimeSlot);
    } else {
      slots.push(defaultEmptyTimeSlot);
    }
    this.updateScheduleAvailability();
    this.cd.detectChanges();
  }

  onDisassociateResource(resourceUid: string, event: MouseEvent) {
    event.preventDefault();
    this.resourceDeleted.emit(resourceUid);
    this.onRequestProcessing();
    this.resourceDisassociationSubscription = this.bookingDataService
      .disassociate(this.cardUid, this.appointment?.id, resourceUid)
      .subscribe(({ isSuccess }) => {
        if (isSuccess) {
          this.activeResource = null;
          this.onRequestProcessingEnd();
        } else {
          this.onResourceError();
        }
      });
  }

  trimSeconds(time: string) {
    if (time.length > 5) {
      return time.substring(0, 5);
    } else {
      return time;
    }
  }

  checkIfDayIncluded(day: string): boolean {
    return this.dayValues !== null ? this.dayValues.some((slot) => slot.day.toLowerCase() === day.toLowerCase()) : false;
  }

  deleteBookingSlot(slotUid: string) {
    this.slotDeletionSubscription = this.bookingDataService
      .deleteScheduleAvailability(this.activeResource, this.scheduleUid, this.cardUid, slotUid)
      .subscribe(({ isSuccess }) => {
        if (isSuccess) {
          this.refreshSchedule();
        } else {
          this.onRequestError();
        }
      });
  }

  getDayValues() {
    this.dayValues = [];
    this.weekDays.map((weekDay) => {
      this.dayValues.push({
        day: weekDay,
        slots: this.activeSlots
          ? this.activeSlots
              .filter((i) => i.weekday.toLowerCase() === weekDay.toLowerCase())
              .map((slot) => {
                return {
                  weekday: weekDay.toUpperCase(),
                  startTime: this.trimSeconds(slot.startTime),
                  endTime: this.trimSeconds(slot.endTime),
                  id: slot.id,
                };
              })
          : [],
      });
    });
  }

  removeTimeSlot(event: MouseEvent, day: string, index: number) {
    event.preventDefault();
    this.dayValues.find((value) => value.day === day).slots.splice(index, 1);
    this.cd.detectChanges();
    this.updateScheduleAvailability();
  }

  onIntervalSelection() {
    this.updateScheduleAvailability();
    this.cd.detectChanges();
  }

  disableBeforeStartTime(optionEndTime: string, startTime: string): boolean {
    const momentTime = moment(optionEndTime, 'HH:mm');
    const momentStartTime = moment(startTime, 'HH:mm');
    const isBeforeStartTime = momentTime.isBefore(momentStartTime, 'minute');
    return isBeforeStartTime || optionEndTime === startTime;
  }

  disableIfBetweenIntervals(optionTime: string, day: string) {
    const daySlots = this.dayValues.filter((slot) => slot.day.toLowerCase() === day.toLowerCase()).flatMap((item) => item.slots);
    if (daySlots.length <= 1) {
      return false;
    } else if (daySlots.some((slot) => slot.endTime === optionTime)) {
      return false;
    } else {
      return this.disableIfAlreadyIncluded(optionTime, day);
    }
  }

  disableIfAlreadyIncluded(optionTime: string, day: string): boolean {
    const momentTime = moment(optionTime, 'HH:mm');
    return this.dayValues
      .filter((slot) => slot.day.toLowerCase() === day.toLowerCase())
      .flatMap((item) => item.slots)
      .some((time) => {
        const startTime = moment(time.startTime, 'HH:mm');
        const endTime = moment(time.endTime, 'HH:mm');
        return momentTime.isBetween(startTime, endTime, 'minute', '[]');
      });
  }

  checkIfAlreadyChecked(dayValue: { slots: BookableSlotAdmin[]; day: string }) {
    return dayValue.slots.length > 0;
  }

  applyToAll(event: MouseEvent) {
    event.preventDefault();
    const mondayValue = this.weekDays[0].toLowerCase();
    const mondaySlots = this.dayValues.find((dayValue) => dayValue.day.toLowerCase() === mondayValue).slots;
    this.dayValues.forEach((dayValue) => {
      dayValue.slots = mondaySlots.map((slot) => {
        return {
          id: dayValue.day.toLowerCase() === mondayValue ? slot.id : null,
          weekday: dayValue.day,
          endTime: slot.endTime,
          startTime: slot.startTime,
        };
      });
    });
    this.cd.detectChanges();
    this.updateScheduleAvailability();
  }

  checkIfHasValidationError(time: string, day: string): boolean {
    if (day.toLowerCase() === this.weekDays[0].toLowerCase()) {
      this.mondayHasValidationError = this.disableIfBetweenIntervals(time, day);
    }
    return this.disableIfBetweenIntervals(time, day);
  }

  isIntervalOverlapping(dayValue: BookableSlotAdmin, day: string): boolean {
    const otherSlots = this.dayValues
      .filter((value) => value.day === day)
      .flatMap((value) => value.slots)
      .filter((value) => value !== dayValue);
    const isOverlapping = otherSlots.some((slot) => {
      return (
        (dayValue.startTime >= slot.startTime && dayValue.startTime < slot.endTime) ||
        (dayValue.endTime > slot.startTime && dayValue.endTime <= slot.endTime)
      );
    });
    if (day.toLowerCase() === this.weekDays[0].toLowerCase()) {
      this.mondayHasValidationError = isOverlapping;
    }
    return isOverlapping;
  }

  onNameInputChange() {
    this.autoSaveSubject$.next();
  }

  private createSchedule(scheduleRequest: RecurringScheduleRequest, resourceUid: string) {
    this.scheduleCreationSubscription = this.bookingDataService
      .createSchedule(scheduleRequest, resourceUid, this.cardUid, this.currentLanguage)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.isLoading.emit(false);
          this.activateSchedule(value, []);
        } else {
          this.onResourceError();
        }
      });
  }

  private refreshSchedule() {
    this.scheduleLoadingSubscription = this.bookingDataService
      .getSchedule(this.cardUid, this.activeResource, this.currentLanguage)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.activateSchedule(value.schedule, value.slots);
        } else {
          this.onResourceError();
        }
      });
  }

  private onResourceError() {
    this.resourceHasError.emit(true);
    this.onRequestError();
  }

  private onRequestProcessing() {
    this.scheduleHasError = false;
    this.isLoading.emit(true);
    this.appointmentHasError.emit(false);
  }

  private onRequestProcessingEnd() {
    this.isLoading.emit(false);
    this.resourceUpdated.emit(true);
    this.cd.detectChanges();
  }

  private onRequestError() {
    this.isLoading.emit(false);
    this.appointmentHasError.emit(true);
    this.cd.detectChanges();
  }

  private activateSchedule(activeSchedule: BookableSchedule, activeSlots: BookableSlotAdmin[]) {
    this.scheduleUid = activeSchedule.id;
    this.activeSlots = activeSlots;
    this.getDayValues();
    this.cd.detectChanges();
  }
}
