import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AI_ASSISTANT_SERVICE,
  AIAssistantSettings,
  AIAssistantSettingsRequest,
} from '../../../../../services/assistant/ai-assistant.service';
import { ApiAiAssistantDataService } from '../../../../../services/assistant/ai-assistant-api-data.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'ptl-form-ai-assistant-settings',
  templateUrl: './form-ai-assistant-settings.component.html',
  styleUrls: ['./form-ai-assistant-settings.component.scss'],
})
export class FormAIAssistantSettingsComponent implements OnInit, OnChanges {
  @Input() assistantId: string;
  @Input() updatedAssistantSettings: AIAssistantSettings;
  @Input() aiAssistantData: AIAssistantSettings;
  @Input() cardUid: string;

  @Output() formChanged: EventEmitter<null | string> = new EventEmitter<null | string>();
  @Output() saveAsTemplate: EventEmitter<boolean> = new EventEmitter<boolean>();

  assistantSettingsForm: FormGroup;
  initialAssistantSettingsForm: AIAssistantSettings | null = null;
  isSaveClicked: boolean;
  isTemplate: boolean;
  isFormHasChange = false;
  displayToggleTemplate: boolean;

  constructor(
    private fb: FormBuilder,
    @Inject(AI_ASSISTANT_SERVICE) private aiAssistantService: ApiAiAssistantDataService,
  ) {
    this.assistantSettingsForm = this.fb.group({
      title: ['', [Validators.required]],
      instructions: ['', [Validators.required]],
      prompt: ['', [Validators.required]],
      actions: this.fb.array([]),
      isTemplate: [false],
      _id: [''],
    });
  }

  ngOnInit() {
    this.assistantSettingsForm.valueChanges.subscribe(() => {
      this.isFormHasChange = this.isFormChanged();
      if (this.isFormHasChange && this.initialAssistantSettingsForm._id) {
        this.formChanged.emit(null);
      } else {
        this.formChanged.emit(this.initialAssistantSettingsForm?._id || this.assistantSettingsForm?.value?._id);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initialAssistantSettingsForm = null;

    if (changes.updatedAssistantSettings && this.updatedAssistantSettings?._id) {
      this.updateFormValues(this.updatedAssistantSettings);
      this.isTemplate = this.updatedAssistantSettings?.isTemplate;
    }

    if (changes?.aiAssistantData?.currentValue) {
      this.aiAssistantData = changes.aiAssistantData.currentValue;
      this.updateFormValues(this.aiAssistantData);
    }

    if (changes?.aiAssistantData?.currentValue === null) {
      const actionsArray = this.assistantSettingsForm.get('actions') as FormArray;
      actionsArray.clear();
      this.assistantSettingsForm.reset();
    }
  }

  onSaveAsTemplate(event: MatSlideToggleChange) {
    this.assistantSettingsForm.patchValue({
      isTemplate: event.checked,
    });

    this.saveAsTemplate.emit(event.checked);
  }

  getValidAssistantSettings(): AIAssistantSettingsRequest | null {
    this.isSaveClicked = true;

    if (!this.assistantSettingsForm.valid) {
      return null;
    }

    return {
      _id: this.assistantSettingsForm.value._id,
      title: this.assistantSettingsForm.value.title,
      instructions: this.assistantSettingsForm.value.instructions,
      prompt: this.assistantSettingsForm.value.prompt,
      isTemplate: this.assistantSettingsForm.value.isTemplate,
      actions: this.assistantSettingsForm.value.actions.map((action) => {
        return {
          uid: action.uid,
          description: action.description,
          prompt: action.prompt,
          isTemplate: action.isTemplate,
        };
      }),
    };
  }

  actions(): FormArray {
    return this.assistantSettingsForm.get('actions') as FormArray;
  }

  addAction(): void {
    this.actions().push(
      this.fb.group({
        uid: [null],
        description: [''],
        prompt: [''],
      }),
    );
  }

  removeAction(index: number): void {
    this.actions().removeAt(index);
  }

  private isFormChanged(): boolean {
    if (this.initialAssistantSettingsForm) {
      return !(JSON.stringify(this.initialAssistantSettingsForm) === JSON.stringify(this.assistantSettingsForm.value));
    } else {
      return false;
    }
  }

  private updateFormValues(value) {
    this.assistantSettingsForm.patchValue({
      title: value.title,
      instructions: value.instructions,
      prompt: value.prompt,
      isTemplate: value.isTemplate,
      _id: value._id,
    });

    const actionsArray = this.assistantSettingsForm.get('actions') as FormArray;
    actionsArray.clear();

    if (value.actions && value.actions.length > 0) {
      value.actions.forEach((action) => {
        actionsArray.push(
          this.fb.group({
            uid: [action.uid, [Validators.required]],
            description: [action.description],
            prompt: [action.prompt],
          }),
        );
      });
    }

    if (this.initialAssistantSettingsForm === null) {
      this.initialAssistantSettingsForm = { ...this.initialAssistantSettingsForm, ...this.updatedAssistantSettings };
    }
  }
}
