/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ReportSectionIcons } from '../../../../../shared/models';

@Component({
  selector: 'ptl-segment-bar',
  templateUrl: './segment-bar.component.html',
  styleUrls: ['./segment-bar.component.scss'],
})
export class SegmentBarComponent implements OnInit, OnChanges {
  BAR_MIN_COUNT = 2;

  @Input() isView = false;
  @Input() isPercentageEnabled: boolean;
  @Input() sectionIcons: ReportSectionIcons;
  @Input() score: number;
  @Input() maxScore: number;
  @Input() levels = [];

  barItemsCount = 0;
  percentageValue: number;

  ngOnInit() {
    this.percentageValue = Math.floor((this.score * 100) / this.maxScore);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.levels) {
      this.calculateBarCounts(this.levels);
    }
  }

  calculateBarCounts(levels) {
    if (levels.length <= 1) {
      this.barItemsCount = this.BAR_MIN_COUNT;
      return;
    }

    this.barItemsCount = levels.length;
  }

  calculateWidth(index: number) {
    const percentageToCount = (this.percentageValue / 100) * this.barItemsCount;
    const fullItemsCount = Math.floor(percentageToCount);
    const remainsNumber = percentageToCount - fullItemsCount;

    if (index < fullItemsCount) {
      return 100;
    } else if (index === fullItemsCount) {
      return remainsNumber * 100;
    }
    return 0;
  }
}
