/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  DiagnosticQuestionOptionRequest,
  QuestionResponseSelectedOption,
  Organization,
  DiagnosticQuestionResponse,
  DiagnosticQuestionOptionWithSelection,
  LanguageValue,
  DiagnosticQuestionOptionResponse,
} from '../../models';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { LanguageCodeHelper } from '../../helpers/language-code-helper';

@Component({
  selector: 'ptl-diagnostics-view-drag-drop-question-type',
  templateUrl: './drag-drop-question-type.component.html',
  styleUrls: ['./drag-drop-question-type.component.scss'],
})
export class DiagnosticsViewDragDropQuestionTypeComponent implements OnInit, OnDestroy {
  /** Receives the question to answer */
  @Input() question: DiagnosticQuestionResponse;
  @Input() answeredQuestions: object;
  @Input() disabled: boolean;
  @Input() isQuiz: boolean;
  @Input() currentLanguage: string;
  @Input() organization: Organization;

  /** Emits the answer when selected. */
  @Output() selectedAnswer = new EventEmitter<{
    uid: string;
    answer: DiagnosticQuestionOptionResponse[];
    question: DiagnosticQuestionResponse;
  }>();

  @ViewChild('droppedContainer') private droppedContainer: ElementRef;

  notSelectedAnswers: DiagnosticQuestionOptionWithSelection[] = [];
  selectedAnswers: DiagnosticQuestionOptionWithSelection[] = [];
  options: DiagnosticQuestionOptionWithSelection[] = [];
  draggedOption: DiagnosticQuestionOptionRequest;
  draggedOptionWasSelected: boolean;

  selectAnswer(answer: DiagnosticQuestionOptionWithSelection, override = false) {
    if (this.disabled && !override) {
      return;
    }

    if (this.question.selectionLimit === 1) {
      for (const ans of this.selectedAnswers) {
        if (ans.uid === answer.uid) {
          ans.selected = !ans.selected;
        } else {
          ans.selected = false;
        }
      }
    } else {
      answer.selected = !answer.selected;
    }

    this.setScoreBasedOnIndex();
    if (this.notSelectedAnswers.length !== 0) {
      return;
    }
    this.selectedAnswer.emit({ uid: this.question.uid, answer: this.selectedAnswers, question: this.question });
  }

  ngOnInit() {
    // add fix-scroll-lock class to fix cdk drop bug in mat-dialog
    document.documentElement.classList.add('fix-scroll-lock');

    if (this.question) {
      this.options = [...this.question.options].map((answer) => ({ ...answer, selected: false }));
      this.notSelectedAnswers = Object.assign([], this.options);
      this.selectedAnswers = [];
      const selectedAnswers = this.answeredQuestions[this.question.uid]
        ? this.answeredQuestions[this.question.uid]
        : this.answeredQuestions['selectedOptions']
          ? this.answeredQuestions['selectedOptions']
          : '';

      if (selectedAnswers) {
        this.updateColumns(selectedAnswers);
        for (const selectedAnswer of selectedAnswers) {
          for (const answer of this.selectedAnswers) {
            if (selectedAnswer.optionUid === answer.uid) {
              this.selectAnswer(answer, true);
              break;
            }
          }
        }
      }
    }
  }

  ngOnDestroy() {
    // remove fix-scroll-lock class to disable cdk drop fix, to ensure that it doesn't break other components
    document.documentElement.classList.remove('fix-scroll-lock');
  }

  onDragEnter(item: DiagnosticQuestionOptionRequest, selected: boolean) {
    this.draggedOption = item;
    this.draggedOptionWasSelected = selected;
    (this.droppedContainer.nativeElement as HTMLElement).focus();
  }

  onDragFinished(i) {
    const selectedAnswer = this.draggedOption;
    if (!this.draggedOptionWasSelected) {
      this.notSelectedAnswers = this.notSelectedAnswers.filter((item) => item.uid !== selectedAnswer.uid);
      this.selectAnswer(this.draggedOption);
      if (!this.selectedAnswers[i]) {
        this.selectedAnswers.push(this.draggedOption);
      } else {
        this.selectedAnswers.splice(i, 0, selectedAnswer);
      }
    } else {
      this.selectedAnswers = this.selectedAnswers.filter((item) => item.uid !== selectedAnswer.uid);
      this.selectedAnswers.splice(i, 0, selectedAnswer);
      this.setScoreBasedOnIndex();
    }
    this.draggedOptionWasSelected = undefined;
    this.draggedOption = undefined;
    if (this.notSelectedAnswers.length !== 0) {
      this.focusNotSelectedAnswers();
      return;
    }
    this.focusSelectedAnswers();
    (document.getElementById('cdk-drop-list-1').querySelector('.question-card__option') as HTMLElement).focus();
    this.selectedAnswer.emit({ uid: this.question.uid, answer: this.selectedAnswers, question: this.question });
  }

  onListDrop(event: CdkDragDrop<DiagnosticQuestionOptionRequest[]>, type: string) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }

    if (event.previousContainer !== event.container) {
      this.selectAnswer(event.item.data);
    }

    if (type === 'selected' && event.previousContainer === event.container) {
      this.setScoreBasedOnIndex();
      if (this.notSelectedAnswers.length !== 0) {
        return;
      }
      this.selectedAnswer.emit({ uid: this.question.uid, answer: this.selectedAnswers, question: this.question });
    }
  }

  getTranslatedValue(values: LanguageValue[]) {
    return LanguageCodeHelper.getDataByUserLanguageCode(values, this.organization, this.currentLanguage).value;
  }

  private setScoreBasedOnIndex() {
    for (let i = 0; i < this.selectedAnswers.length; i++) {
      this.selectedAnswers[i].score = i + 1;
    }
  }

  private focusNotSelectedAnswers() {
    setTimeout(() => {
      (document.getElementById('cdk-drop-list-0').querySelector('.question-card__option') as HTMLElement).focus();
    }, 0);
  }

  private focusSelectedAnswers() {
    setTimeout(() => {
      (document.getElementById('cdk-drop-list-1').querySelector('.question-card__option') as HTMLElement).focus();
    }, 0);
  }

  private updateColumns(selectedAnswers: QuestionResponseSelectedOption[]) {
    this.notSelectedAnswers = Object.assign([], this.options);
    this.selectedAnswers = [];

    for (const selectedAnswer of selectedAnswers) {
      this.notSelectedAnswers = this.notSelectedAnswers.filter((item) => item.uid === selectedAnswer.optionUid);
      const answer = this.options.find((item) => item.uid === selectedAnswer.optionUid);
      this.selectedAnswers.push(answer);
    }
  }
}
