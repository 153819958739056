/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { Injectable } from '@angular/core';
import { SidebarTreeDataService } from './sidebar-tree-data.service';
import { LeftNavItemSummary } from '../../models/sidebar-tree/sidebar-tree.model';

@Injectable()
export class MockSidebarTreeDataService implements SidebarTreeDataService {
  loadPageChildren(pageUri: string, publisherUri: string, contentPackageUri: string): ObservableResult<LeftNavItemSummary[]> {
    return ObservableResult.ofSuccess();
  }

  loadPlaylistChildren(
    playlistUri: string,
    pageUri?: string,
    publisherUri?: string,
    contentPackageUri?: string,
  ): ObservableResult<LeftNavItemSummary[]> {
    return ObservableResult.ofSuccess();
  }

  loadProjectChildren(playlistUri: string): ObservableResult<LeftNavItemSummary[]> {
    return ObservableResult.ofSuccess();
  }
}
