/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { RestClientService } from '../../../shared/services/rest-client.service';
import { ObservableResult } from '../../../shared/store';
import { EventCardsDataService } from './data.service';
import { Resource } from '../../../shared/models';
import {
  EventCardTime,
  EventCardTypeRequest,
  EventReminderRequest,
  EventTickets,
  EventTicketsRequest,
} from '../../resource/store/admin/resource-event-admin.state.model';
import { TranslocoService } from '@ngneat/transloco';
import { EventTicketAdminParticipateRequest, EventTicketAdminRegisterRequest } from 'src/app/shared/models/admin/members.model';
import { Observable } from 'rxjs';
import { CalendarHelper } from '../../../shared/helpers/calendar-helper';

@Injectable()
export class ApiEventCardsDataService implements EventCardsDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  setEventCardTypes(eventUid: string, request: EventCardTypeRequest): ObservableResult<EventCardTypeRequest> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `events/${eventUid}/types`);
    return this.client.put<EventCardTypeRequest>(url, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdateEventType'))),
    );
  }

  setEventCardDates(eventUid: string, request: EventCardTime): ObservableResult<Resource> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `events/${eventUid}/times`);
    return this.client.put<Resource>(url, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdateEventTimes'))),
    );
  }

  setEventTickets(eventUid: string, request: EventTicketsRequest): ObservableResult<EventTickets> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `events/${eventUid}/tickets`);
    return this.client.put<EventTickets>(url, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdateEventTickets'))),
    );
  }

  deleteEventTickets(eventUid: string): ObservableResult<EventTickets> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `events/${eventUid}/tickets`);
    return this.client.delete<EventTickets>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorDeleteEventTickets'))),
    );
  }

  setEventReminders(eventUid: string, request: EventReminderRequest): ObservableResult<Resource> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `events/${eventUid}/reminders`);
    return this.client.put<Resource>(url, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdateEventReminders'))),
    );
  }

  registerToEvent(eventUid: string): ObservableResult<Resource> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `events/${eventUid}/tickets/register`);
    return this.client.post<Resource>(url, {}).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRegisterToEvent'))),
    );
  }

  unregisterFromEvent(eventUid: string): ObservableResult<Resource> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `events/${eventUid}/tickets/unregister`);
    return this.client.post<Resource>(url, {}).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUnRegisterFromEvent'))),
    );
  }

  attendToEvent(eventUid: string): ObservableResult<Resource> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `events/${eventUid}/tickets/participate`);
    return this.client.post<Resource>(url, {}).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAttendToEvent'))),
    );
  }

  attendToEventFromQrCode(eventUid: string, participationCode: string): ObservableResult<Resource> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `events/${eventUid}/tickets/participate?code=` + participationCode);
    return this.client.post<Resource>(url, {}).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAttendToEvent'))),
    );
  }

  adminRegisterToEventBulk(eventUid: string, request: EventTicketAdminRegisterRequest): ObservableResult<EventTickets> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `events/${eventUid}/tickets/bulk/register`);
    return this.client.post<EventTickets>(url, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAdminRegisterToEvent'))),
    );
  }

  adminUnregisterFromEventBulk(eventUid: string, usersUids: string[]): ObservableResult<EventTickets> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `events/${eventUid}/tickets/bulk/unregister`);
    return this.client.post<EventTickets>(url, usersUids).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAdminUnregisterFromEvent'))),
    );
  }

  adminAttendToEventBulk(eventUid: string, request: EventTicketAdminParticipateRequest): ObservableResult<EventTickets> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `events/${eventUid}/tickets/bulk/participate`);
    return this.client.post<EventTickets>(url, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAdminAttendToEvent'))),
    );
  }

  adminMoveToWaitingListEventBulk(eventUid: string, usersUids: string[]): ObservableResult<EventTickets> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `events/${eventUid}/tickets/bulk/waiting-list`);
    return this.client.post<EventTickets>(url, usersUids).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAdminMoveToWaitingList'))),
    );
  }

  exportEventCalendarEvent(eventUid: string, languageCode?: string): Observable<string> {
    const headers = CalendarHelper.getDefaultCalendarRequestHeaders(languageCode);
    return this.client
      .post<string>(Location.joinWithSlash(environment.apiRootUrl || '', `events/${eventUid}/export/ics`), {}, {}, headers, 'text')
      .pipe(
        map((response) => response.body as string),
        catchError((error) => {
          console.error('Failed to download ICS', error);
          throw error;
        }),
      );
  }
}
