/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { CardHeaders } from '@app/app/shared/models/admin/members.model';
import { RedirectHelper } from '@app/app/page-modules/resource/store/editor/content/helpers/redirect.helper';
import { ApiContentLinkService } from '@app/app/shared/services/content-link/api-content-link.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ptl-most-completions-chart',
  templateUrl: './most-completions-chart.component.html',
  styleUrls: ['../common-analytics-chart-styles.scss', './most-completions-chart.component.scss'],
})
export class MostCompletionsChartComponent implements OnChanges {
  @Input() cardCompletions: Record<string, number>;
  @Input() totalMembers: number;
  @Input() cardHeaders: CardHeaders[];

  completionsData: { label: string; count: number; percentage: string }[] = [];
  maxCompletion = 0;
  sortOrder: 'asc' | 'desc' = 'desc';

  constructor(
    private contentLinkService: ApiContentLinkService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cardCompletions'] || changes['totalMembers']) {
      this.buildCompletionsData();
    }
  }

  openInNewTab(header: string): void {
    const card = this.cardHeaders.find((c) => c.header === header);

    if (!card) {
      throw new Error('Failed to find card.');
    }

    this.contentLinkService.getCardFormattedUriByCardUid(card.cardId).subscribe(({ isSuccess, value }) => {
      if (isSuccess && value?.formattedUri) {
        RedirectHelper.redirectByParams(
          this.ngZone,
          this.router,
          this.activatedRoute,
          {
            formattedUri: `${value.formattedUri}`,
          },
          'RESOURCE',
          '_blank',
        );
      }
    });
  }

  private buildCompletionsData(): void {
    if (!this.cardCompletions || this.totalMembers <= 0) {
      this.completionsData = [];
      this.maxCompletion = 0;
      return;
    }

    const completions = Object.entries(this.cardCompletions).map(([label, count]) => ({
      label,
      count,
      percentage: ((count * 100) / this.totalMembers).toFixed(1) + '%',
    }));

    this.completionsData = completions.sort((a, b) => (this.sortOrder === 'desc' ? b.count - a.count : a.count - b.count));

    this.maxCompletion = Math.max(...completions.map((item) => item.count), 0);
  }

  toggleSortOrder(): void {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.buildCompletionsData();
  }
}
