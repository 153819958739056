import { Observable, forkJoin } from 'rxjs';
import { FileAnswer, ImageUploadContent, MediaType, MediaUploadData, ResourceSection, SingleUrlContent } from '../models';
import { FileUploadService } from '../services/file-upload/file-upload.service';
import { ObservableResult, Result } from '../store';
import { ImageUploadNameResolver } from '@app/app/shared/helpers/image-upload-name-resolver';
import { v4 as uuidv4 } from 'uuid';

export class FileUploadHelper {
  static uploadObservable(
    sectionWithFile: ResourceSection,
    fileUploadService: FileUploadService,
    uploadUrl: string,
  ): Observable<Result<void>[]> {
    const fileUploadObservables: ObservableResult<void>[] = [];
    const contentWithFile = sectionWithFile.dynamicContent.find(
      (content) => content.type === 'MEDIA_UPLOAD' && !!(content as MediaUploadData).file,
    );
    const file = (contentWithFile as MediaUploadData)?.file;
    if (file) {
      fileUploadObservables.push(fileUploadService.uploadFileDirectly(uploadUrl, file, (contentWithFile as MediaUploadData).uuid4, false));
    }
    return forkJoin(fileUploadObservables);
  }

  static uploadBlockObservable(file: File, fileUploadService: FileUploadService, uploadUrl: string, uuid4: string): ObservableResult<void> {
    if (file) {
      return fileUploadService.uploadFileDirectly(uploadUrl, file, uuid4, false);
    } else {
      return ObservableResult.ofSuccess();
    }
  }

  static formatAnswersStructure(answer: string) {
    const answers = answer.split(';');

    return answers.map((item) => {
      const fileStringPath = item.split('?')[0];
      const fileStringParams = fileStringPath.split('/');
      const filePublicPath = FileUploadHelper.filePath(item);
      const fileName = this.getNameWithoutOriginalSuffix(fileStringParams[fileStringParams.length - 1]);

      return {
        fileName: fileName,
        path: filePublicPath,
        fullPath: item,
      } as FileAnswer;
    });
  }

  static getNameWithoutOriginalSuffix(name: string): string {
    return name.replace('_original', '');
  }

  static filePath(fullUrl: string) {
    // Impacts upload file to S3 bucket, verify upload options like file-upload-form, videos, images
    const startIndex = fullUrl.indexOf('public');
    const endIndex = fullUrl.includes('master.m3u8') ? fullUrl.indexOf('/master.m3u8') : fullUrl.indexOf('?');
    return fullUrl.substring(startIndex, endIndex > 0 ? endIndex : undefined);
  }

  static getFullUrl(uploadedMedia: MediaUploadData): string {
    if (uploadedMedia.mediaType === 'IMAGE') {
      const urls = uploadedMedia.content as ImageUploadContent;
      return urls.original;
    } else {
      return (uploadedMedia.content as SingleUrlContent).url;
    }
  }

  static formatFileNameForUpload(fileName: string): string {
    return fileName.replace(/[^\p{L}\p{N}\-.]+/gmu, '_');
  }

  static convertDataUrlToFile(dataUrl: string, fileName?: string, fileType?: string): File | null {
    let result: File | null = null;

    try {
      const base64Splitted = dataUrl.split(',');
      const base64HeadSplitted = base64Splitted[0].split(/[:;/]/);
      const fileExtension = base64HeadSplitted[2];
      const newFileName = fileName ? fileName : new Date().getTime() + '.' + fileExtension;
      const newFileType = fileType ? fileType : base64HeadSplitted[1] + '/' + base64HeadSplitted[2];
      const base64Body = atob(base64Splitted[1]);
      let n = base64Body.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = base64Body.charCodeAt(n);
      }

      result = new File([u8arr], newFileName, { type: newFileType });
    } catch (error) {
      console.log(error);
    }

    return result;
  }

  static convertFileToMediaUpload(file: File, mediaType: MediaType, replace: boolean = false): MediaUploadData {
    const uuid = uuidv4();
    let formattedName = FileUploadHelper.formatFileNameForUpload(file.name);
    if (mediaType === 'IMAGE') {
      formattedName = ImageUploadNameResolver.resolveImageName(file.name);
    } else {
      formattedName = `${uuid}_${formattedName}`;
    }
    return {
      type: 'MEDIA_UPLOAD',
      file: file,
      mediaType: mediaType,
      replace: replace,
      realName: file.name,
      contentLength: file.size,
      name: formattedName,
      hasFile: true,
      uuid4: uuid,
    } as MediaUploadData;
  }
}
