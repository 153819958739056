/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ResourceTag } from '@app/app/shared/models';

@Component({
  templateUrl: './add-framework-tags-dialog.component.html',
  styleUrls: ['./add-framework-tags-dialog.component.scss'],
})
export class AddFrameworkTagsDialogComponent {
  constructor(private dialogRef: MatDialogRef<AddFrameworkTagsDialogComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }

  onAddTags(tags: ResourceTag[]) {
    this.dialogRef.close(tags);
  }
}
