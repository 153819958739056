import { EditorContent, MediumEditorData } from '../models';
import { cloneDeep } from 'lodash-es';

export class EditorHelper {
  /**
   * Joins successive contents of type PARAGRAPH.
   *
   * Loops through the dynamic content in reverse and checks if two consecutive contents
   * are of type PARAGRAPH. If so, it concats both paragraph's HTML content in the pre-last
   * item while removing the last one.
   *
   * @param dynamicContent all the dynamic content
   * @return updated dynamic content
   */

  static joinSuccessiveParagraphContent(dynamicContent: EditorContent[]): EditorContent[] {
    const updatedDynamicContent = [...dynamicContent];

    updatedDynamicContent.reduceRight((_, item, index, array) => {
      const currentItem: EditorContent = array[index];
      const previousItem: EditorContent = array[index - 1];

      if (previousItem && previousItem.type === currentItem.type && currentItem.type === 'PARAGRAPH') {
        array[index - 1] = {
          type: currentItem.type,
          uid: previousItem.uid ? previousItem.uid : currentItem.uid ? currentItem.uid : '',
          content: (array[index - 1] as MediumEditorData).content.concat('', (currentItem as MediumEditorData).content),
        } as EditorContent;

        array.splice(index, 1);
      }

      return array;
    }, [] as EditorContent[]);

    return updatedDynamicContent;
  }

  /**
   * Adds dynamic content with additional checking for break-points and joining of PARAGRAPH.
   *
   * Loops through the dynamic content and checks if breakpoints <split> are present in content
   * type PARAGRAPH. If so, we split the html content in 2, and push addedContent in between
   * and update the dynamic content array. If no breakpoints are present, concat it at the end.
   *
   * @param existingContent, all the dynamic content
   * @param addedContent, new content added
   * @return updated dynamic content
   */
  static addDynamicContentWithBreakpointCheck(existingContent: EditorContent[], addedContent: EditorContent): EditorContent[] {
    if (!existingContent) {
      existingContent = [];
    }

    let updatedDynamicContent: EditorContent[] = [];
    if (!addedContent.forceIndex) {
      existingContent.some((content: EditorContent, index: number) => {
        const mediumEditorContent = (content as MediumEditorData).content;
        const regex = /<split[^>]*><\/split>/g;

        const shouldSplitItem = content.type === 'PARAGRAPH' && regex.test(mediumEditorContent);

        if (shouldSplitItem) {
          const mediumEditorContentToUpdateArray = mediumEditorContent.split(regex).map((c) => {
            const params = { type: 'PARAGRAPH', content: c };
            if (content.uid && c.trim() !== '') {
              params['uid'] = content.uid;
              content.uid = undefined;
            }
            return params;
          });
          (mediumEditorContentToUpdateArray as EditorContent[]).splice(1, 0, addedContent);

          updatedDynamicContent = [...existingContent];
          if (mediumEditorContentToUpdateArray[1].type === 'SEPARATOR' && mediumEditorContentToUpdateArray[0].content === '') {
            mediumEditorContentToUpdateArray.splice(0, 1);
          }
          updatedDynamicContent.splice(index, 1, ...(mediumEditorContentToUpdateArray as EditorContent[]));
          return true;
        } else {
          return undefined;
        }
      });
    }

    if (updatedDynamicContent.length) {
      return EditorHelper.joinSuccessiveParagraphContent(updatedDynamicContent);
    } else {
      if (addedContent.hasOwnProperty('index') && addedContent.index !== undefined) {
        const newContent = cloneDeep(existingContent);
        newContent.splice(addedContent.index, 0, addedContent);
        return EditorHelper.joinSuccessiveParagraphContent(newContent);
      } else {
        return EditorHelper.joinSuccessiveParagraphContent(existingContent.concat(addedContent));
      }
    }
  }

  /** Filter the dynamic content based on index and join type PARAGRAPH. */
  static removeDynamicContentByIndexAndJoinParagraphContent(contentIndex: number, dynamicContent: EditorContent[]): EditorContent[] {
    return dynamicContent.filter((_, idx) => idx !== contentIndex);
  }

  /** Filter the dynamic content from existing section based on UID and join type PARAGRAPH. */
  static removeDynamicContentFromExistingSectionAndJoinParagraph(
    dynamicContentUid: string,
    dynamicContentIndex: number,
    dynamicContent: EditorContent[],
  ): EditorContent[] {
    const filteredDynamicContent = dynamicContent.filter(
      (content, idx) => idx !== dynamicContentIndex && (!dynamicContentUid || dynamicContentUid !== content.uid),
    );

    return EditorHelper.joinSuccessiveParagraphContent(filteredDynamicContent as EditorContent[]);
  }

  static isSameContent(newContent: EditorContent, oldContent: EditorContent) {
    if (!newContent || !oldContent) {
      return false;
    }
    if (newContent?.type === 'EMBED_CODE' && oldContent?.type === 'EMBED_CODE') {
      return false;
    }
    if (typeof newContent.content === 'string' && typeof oldContent.content === 'string') {
      const formattedNewContent = newContent?.content?.replace(/<split[^>]*><\/split>/g, '');
      const formattedOldContent = oldContent?.content?.replace(/<split[^>]*><\/split>/g, '');
      return formattedNewContent === formattedOldContent;
    }
    return false;
  }
}
