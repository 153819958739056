<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="editor-toolbar-container f_editor-toolbar-container">
  <button
    mat-icon-button
    id="toolbarButton"
    class="f_editor-toolbar-add-button editor-toolbar-add-button"
    [title]="toolbarVisible ? 'Close menu' : 'Add an image, video, embed, or new form'"
    [attr.aria-label]="
      toolbarVisible
        ? ('translations.global.ariaLabels.closeMenu' | transloco)
        : ('translations.global.ariaLabels.addAnImageVideoEmbedOrNewForm' | transloco)
    "
    [class.is-opened]="toolbarVisible"
    (click)="toggleToolbar()"
  >
    <mat-icon aria-hidden="true" [inline]="true">add</mat-icon>
  </button>

  <div class="floating-link-box" *ngIf="linkInputActive">
    <form class="link-input-form-box" [formGroup]="mediaUrlData">
      <mat-form-field class="link-input-form-field">
        <input
          matInput
          type="url"
          required
          formControlName="contentUrl"
          [placeholder]="'translations.mediaContent.label.uploadImagePlaceholder' | transloco"
          [readonly]="mediaEmbeddableContentLoading"
          (keydown.enter)="onLinkEnter()"
        />
        <mat-error *ngIf="mediaUrlData.controls['contentUrl'].invalid"
          >{{ 'translations.mediaContent.message.error.invalidUrl' | transloco }}
        </mat-error>
        <mat-spinner class="link-input-form-spinner" *ngIf="mediaEmbeddableContentLoading" [diameter]="25"></mat-spinner>
      </mat-form-field>
    </form>
  </div>

  <div class="editor-toolbar-floating-container" id="toolbarFloatingButtonsContainer" *ngIf="toolbarVisible">
    <div class="editor-toolbar-floating-container-inner">
      <div class="floating-container-title medium bold">{{ 'translations.editor.toolbarNew.title.addNewElement' | transloco }}</div>

      <div class="floating-container-options">
        <div class="floating-container-options-title small" *ngIf="form || quiz || collector">
          {{ 'translations.editor.toolbarNew.title.basicItems' | transloco }}
        </div>

        <ptl-editor-toolbar-option
          *ngIf="link"
          tabindex="0"
          class="floating-container-option-item"
          (mouseover)="showTooltip('LINK', null)"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.link.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#link'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.link.buttonAriaLabel' | transloco"
          [buttonType]="'LINK'"
          (optionSelected)="onLinkAdd()"
        >
        </ptl-editor-toolbar-option>

        <ptl-editor-toolbar-option
          *ngIf="upload"
          class="floating-container-option-item f_image-upload"
          (mouseover)="showTooltip('UPLOAD', 'IMAGE')"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.image.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#image'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.image.buttonAriaLabel' | transloco"
          [buttonType]="'UPLOAD'"
          [uploadType]="'IMAGE'"
          (mediaContentAdded)="onMediaContentAdded($event)"
        >
        </ptl-editor-toolbar-option>

        <ptl-editor-toolbar-option
          *ngIf="table"
          class="floating-container-option-item table-option"
          (mouseover)="showTooltip('TABLE', 'TABLE')"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.table.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#table'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.table.buttonAriaLabel' | transloco"
          [buttonType]="'TABLE'"
          (optionSelected)="onOptionSelected($event)"
        >
        </ptl-editor-toolbar-option>

        <ptl-editor-toolbar-option
          *ngIf="upload"
          class="floating-container-option-item"
          (mouseover)="showTooltip('UPLOAD', 'VIDEO')"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.video.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#play-circle'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.video.buttonAriaLabel' | transloco"
          [buttonType]="'UPLOAD'"
          [uploadType]="'VIDEO'"
          (mediaContentAdded)="onMediaContentAdded($event)"
        >
        </ptl-editor-toolbar-option>

        <ptl-editor-toolbar-option
          *ngIf="upload"
          class="floating-container-option-item"
          (mouseover)="showTooltip('UPLOAD', 'AUDIO')"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.audio.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#audio'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.audio.buttonAriaLabel' | transloco"
          [buttonType]="'UPLOAD'"
          [uploadType]="'AUDIO'"
          (mediaContentAdded)="onMediaContentAdded($event)"
        >
        </ptl-editor-toolbar-option>

        <ptl-editor-toolbar-option
          *ngIf="upload"
          class="floating-container-option-item file-option"
          (mouseover)="showTooltip('UPLOAD', 'FILE')"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.file.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#file'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.file.buttonAriaLabel' | transloco"
          [buttonType]="'UPLOAD'"
          [uploadType]="'FILE'"
          (mediaContentAdded)="onMediaContentAdded($event)"
        >
        </ptl-editor-toolbar-option>

        <ptl-editor-toolbar-option
          *ngIf="embed"
          class="floating-container-option-item"
          (mouseover)="showTooltip('EMBED', null)"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.embed.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#embed-code'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.embed.buttonAriaLabel' | transloco"
          [buttonType]="'EMBED'"
          (optionSelected)="onOptionSelected($event)"
        >
        </ptl-editor-toolbar-option>

        <ptl-editor-toolbar-option
          *ngIf="chart"
          class="floating-container-option-item"
          (mouseover)="showTooltip('CHART', null)"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.chart.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#chart'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.chart.buttonAriaLabel' | transloco"
          [buttonType]="'CHART'"
          (optionSelected)="onAddChart()"
        >
        </ptl-editor-toolbar-option>

        <ptl-editor-toolbar-option
          *ngIf="separator"
          class="floating-container-option-item"
          (mouseover)="showTooltip('SEPARATOR', null)"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.separator.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#separator'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.separator.buttonAriaLabel' | transloco"
          [buttonType]="'SEPARATOR'"
          (optionSelected)="onOptionSelected($event)"
        >
        </ptl-editor-toolbar-option>
      </div>

      <div class="floating-container-options" *ngIf="form">
        <div class="floating-container-options-title small">
          {{ 'translations.editor.toolbarNew.title.forms' | transloco }}
        </div>

        <ptl-editor-toolbar-option
          *ngIf="form"
          class="floating-container-option-item"
          (mouseover)="showTooltip('FORM', 'TEXTBOX')"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.textForm.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#text-box'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.textForm.buttonAriaLabel' | transloco"
          [buttonType]="'FORM'"
          [formType]="'TEXTBOX'"
          (optionSelected)="addEmptyForm()"
        >
        </ptl-editor-toolbar-option>

        <ptl-editor-toolbar-option
          *ngIf="form"
          class="floating-container-option-item"
          (mouseover)="showTooltip('FORM', 'CONNECTED_TEXTBOX')"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.connectedTextForm.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#text-box'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.connectedTextForm.buttonAriaLabel' | transloco"
          [buttonType]="'FORM'"
          [formType]="'CONNECTED_TEXTBOX'"
          (optionSelected)="addEmptyConnectedTextBoxForm()"
        >
        </ptl-editor-toolbar-option>

        <ptl-editor-toolbar-option
          class="floating-container-option-item"
          (mouseover)="showTooltip('FORM', 'QUESTION_FORM')"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.questionForm.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#text-box'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.questionForm.buttonAriaLabel' | transloco"
          [buttonType]="'FORM'"
          [formType]="'QUESTION_FORM'"
          (optionSelected)="addQuestionForm()"
        >
        </ptl-editor-toolbar-option>

        <ptl-editor-toolbar-option
          *ngIf="form"
          class="floating-container-option-item"
          (mouseover)="showTooltip('FORM', 'PRIVATE_NOTE')"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.privateNote.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#text-box'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.privateNote.buttonAriaLabel' | transloco"
          [buttonType]="'FORM'"
          [formType]="'PRIVATE_NOTE'"
          (optionSelected)="addEmptyPrivateForm()"
        >
        </ptl-editor-toolbar-option>

        <ptl-editor-toolbar-option
          *ngIf="form && !textFormOnly"
          class="floating-container-option-item"
          (mouseover)="showTooltip('FORM', 'CHECKBOX')"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.checkboxForm.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#check-box'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.checkboxForm.buttonAriaLabel' | transloco"
          [buttonType]="'FORM'"
          [formType]="'CHECKBOX'"
          (optionSelected)="addCheckboxForm()"
        >
        </ptl-editor-toolbar-option>

        <ptl-editor-toolbar-option
          *ngIf="form && !textFormOnly"
          class="floating-container-option-item"
          (mouseover)="showTooltip('FORM', 'USER_FILE_UPLOAD')"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.fileForm.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#upload'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.fileForm.buttonAriaLabel' | transloco"
          [buttonType]="'FORM'"
          [formType]="'USER_FILE_UPLOAD'"
          (optionSelected)="addUploadForm()"
        >
        </ptl-editor-toolbar-option>
        <ptl-editor-toolbar-option
          *ngIf="form && !textFormOnly"
          class="floating-container-option-item"
          (mouseover)="showTooltip('FORM', 'BOOKING')"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.bookingForm.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#calendar'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.bookingForm.buttonAriaLabel' | transloco"
          [buttonType]="'FORM'"
          [formType]="'BOOKING'"
          (optionSelected)="addBookingForm()"
        >
        </ptl-editor-toolbar-option>
      </div>

      <div class="floating-container-options" *ngIf="quiz || collector">
        <div class="floating-container-options-title small">
          {{ 'translations.editor.toolbarNew.title.advanced' | transloco }}
        </div>

        <ptl-editor-toolbar-option
          *ngIf="quiz"
          class="floating-container-option-item"
          (mouseover)="showTooltip('FORM', 'QUIZ')"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.quizForm.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#advanced'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.quizForm.buttonAriaLabel' | transloco"
          [buttonType]="'FORM'"
          [formType]="'QUIZ'"
          (optionSelected)="onAddQuiz()"
        >
        </ptl-editor-toolbar-option>

        <ptl-editor-toolbar-option
          *ngIf="collector"
          class="floating-container-option-item"
          (mouseover)="showTooltip('FORM', 'COLLECTOR')"
          (mouseleave)="hideTooltip()"
          (focus)="(null)"
          [buttonTitle]="'translations.editor.toolbarNew.option.collectorForm.buttonTitle' | transloco"
          [buttonIcon]="'assets/sprite/sprite.svg#rate-review'"
          [buttonAriaLabel]="'translations.editor.toolbarNew.option.collectorForm.buttonAriaLabel' | transloco"
          [buttonType]="'FORM'"
          [formType]="'COLLECTOR'"
          (optionSelected)="onInsertCollector()"
        >
        </ptl-editor-toolbar-option>
      </div>

      <div class="floating-container-description small" *ngIf="form || quiz || collector">
        {{ 'translations.editor.toolbarNew.label.interactiveComponents' | transloco }}
      </div>
    </div>
  </div>

  <div class="toolbar-options-container" [class.is_active]="tooltipVisible">
    <ng-container *ngFor="let option of tooltipOptions">
      <div
        class="toolbar-option-tooltip-box"
        [class.is_active]="option.buttonType === selectedOptionButtonType && option.subType === selectedOptionSubType"
      >
        <div class="toolbar-option-tooltip-image" *ngIf="option.image">
          <img src="{{ option.image }}" alt="" />
        </div>
        <div class="toolbar-option-tooltip-text">
          <div class="title medium bold">
            {{ option.title | transloco }}
          </div>
          <div class="description small">
            {{ option.description | transloco }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
