/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'ptl-pagination-block',
  templateUrl: './pagination-block.component.html',
  styleUrls: ['./pagination-block.component.scss'],
})
export class PaginationBlockComponent {
  @Output() sizeChanged = new EventEmitter<number>();
  @Output() pageChanged = new EventEmitter<number>();

  @Input() showPageSize = true;
  @Input() totalItems: number;
  @Input() itemsPerPage: number;
  @Input() totalPageCount: number;
  @Input() currentPageIndex: number;
  @Input() isZeroBasedIndex = false;
  @Input() isPaginationVisible = true;

  onPageChange(page: number): void {
    if (page !== this.currentPageIndex) {
      this.pageChanged.emit(page);
    }
  }

  onSizeChange(): void {
    this.sizeChanged.emit(this.itemsPerPage);
  }
}
