/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { JiscOrganization, Organization } from '../../models';
import { InjectionToken } from '@angular/core';
import { Page } from '../../models/page/page.model';
import { Page as Pagination } from '../../../shared/models/page';
import { Publisher } from '../../models/content-store/content-store.model';

/** Injection token for the organization retrieval data service. */
export const ORGANIZATION_RETRIEVAL_DATA_SERVICE = new InjectionToken<OrganizationRetrievalDataService>(
  '[Organization-retrieval] Data service',
);

/** The service handling organization retrieval operations. */
export interface OrganizationRetrievalDataService {
  /**
   * Find organization for domain.
   *
   * @param request the details of the organization to create
   */
  findOrganizationForDomain(domain: string): ObservableResult<Organization>;

  addDomainToWhitelist(domain: string): ObservableResult<void>;

  removeDomainFromWhitelist(domain: string): ObservableResult<void>;

  clearWhitelistDomains(): ObservableResult<void>;

  addDomainToBlacklist(domain: string): ObservableResult<void>;

  removeDomainFromBlacklist(domain: string): ObservableResult<void>;

  clearBlacklistDomains(): ObservableResult<void>;

  searchJiscOrganizations(page: number, size: number, term: string): ObservableResult<Pagination<JiscOrganization>>;

  /** Gets the default home page of the organization. */
  getHomePage(isAlumni?: boolean, languageCode?: string): ObservableResult<Page>;

  getCurrentOrganizationPublisher(): ObservableResult<Publisher>;
}

export interface FeatureCheckResponse {
  isSuccess: boolean;
  value?: boolean;
  error?: string | { message: string; code?: number };
}
