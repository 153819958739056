/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MomentCard } from '../../../../../models';
import { Store } from '@ngxs/store';
import { DeleteMoment } from '../../../../../store/moment/moment-view-all/moment-view-all.actions';
import { ViewMoment } from '../../../../../../../app-frame/store/app-frame.actions';

@Component({
  selector: 'ptl-masonry-card',
  templateUrl: './masonry-card.component.html',
  styleUrls: ['./masonry-card.component.scss'],
})
export class MasonryCardComponent {
  /** Card data. */
  @Input() card: MomentCard;
  /** define if moment should be archived. */
  @Input() archiveMoment: boolean;
  @Input() selected;

  @Output() cardClick = new EventEmitter<MomentCard>();
  @Output() removeCard = new EventEmitter<string>();

  constructor(private store: Store) {}

  onRemoveMoment(id: string) {
    this.removeCard.emit(id);
  }

  onArchiveMoment(id: string) {
    this.store.dispatch(new DeleteMoment(id));
  }

  openMomentForView() {
    if (this.card.viewType !== 'COLLECTOR' && !this.card.isDisabled) {
      this.store.dispatch(new ViewMoment(this.card._id));
    }
  }

  onButtonClick() {
    this.cardClick.emit(this.card);
  }
}
