/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { EnrollmentActionType, PlaylistTargetSector, EnrollmentMode } from '@app/app/shared/models';
import { marker } from '@jsverse/transloco-keys-manager/marker';

export type TargetAudienceSearchType = 'GROUP' | 'MEMBER';

export type TargetAudienceType = 'ORGANIZATION' | 'GROUPS' | 'MEMBERS' | 'SECTORS';

export enum TargetAudienceSelectType {
  WORKSPACE = 'WORKSPACE',
  GROUPS = 'GROUPS',
  MEMBERS = 'MEMBERS',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export interface TargetAudienceItem {
  type: EnrollmentMode;
  iconName: string;
  text: string;
}

export interface EnrollmentAudienceItem {
  type: EnrollmentActionType;
  iconName: string;
  text: string;
}

export const TARGET_AUDIENCE_LIST: TargetAudienceItem[] = [
  {
    type: EnrollmentMode.PRIVATE,
    iconName: 'person',
    text: marker('translations.playlist.target.label.privateOnlyYou'),
  },
  {
    type: EnrollmentMode.GROUP,
    iconName: 'teams',
    text: marker('translations.playlist.target.label.selectedGroup'),
  },
  {
    type: EnrollmentMode.ANYONE,
    iconName: 'organisation',
    text: marker('translations.playlist.target.label.visibilityForWorkspace'),
  },
  {
    type: EnrollmentMode.INVITE,
    iconName: 'add-circle',
    text: marker('translations.playlist.target.label.invitedMembers'),
  },
  {
    type: EnrollmentMode.PUBLIC,
    iconName: 'earth',
    text: marker('translations.playlist.target.label.public'),
  },
];

export const ENROLLMENT_AUDIENCE_LIST: EnrollmentAudienceItem[] = [
  {
    type: EnrollmentActionType.ENGAGEMENT,
    iconName: 'open',
    text: marker('translations.playlist.enrollment.anyone.actionTriggers.open'),
  },
  {
    type: EnrollmentActionType.TICKET,
    iconName: 'tickets',
    text: marker('translations.playlist.enrollment.anyone.actionTriggers.tickets'),
  },
  {
    type: EnrollmentActionType.CUSTOM_GATE,
    iconName: 'bricks',
    text: marker('translations.playlist.enrollment.anyone.actionTriggers.customGate'),
  },
  {
    type: EnrollmentActionType.ADMIN_ENROLLED,
    iconName: 'ship',
    text: marker('translations.playlist.enrollment.anyone.actionTriggers.adminEnrolled'),
  },
];

export const PLAYLIST_TARGET_SECTOR_LIST: PlaylistTargetSector[] = [
  {
    code: 'HE',
    name: 'Higher Education',
  },
  {
    code: 'FE',
    name: 'Further Education',
  },
  {
    code: 'HE_FE',
    name: 'Higher & Further Education',
  },
  {
    code: 'HE_MIXED',
    name: 'High Education - Mixed',
  },
  {
    code: 'FE_MIXED',
    name: 'Further Education - Mixed',
  },
  {
    code: 'OTHER',
    name: 'Other',
  },
];
