/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Page } from '../../models/page';
import { LearnerPlaylistSummary } from '../../models/playlist/learner-playlist-summary.model';
import { ObservableResult } from '../../store';
import { RestClientService } from '../rest-client.service';
import { LearnerPlaylistDataService } from './data.service';
import { LearnerPlaylistReportItem, SubmitPlaylistSummary } from '../../models/playlist/playlist-report.model';
import { TranslocoService } from '@ngneat/transloco';
import { FavoritePlaylistSummary, UserPlaylistSubmissionSummary } from '../../models';
import { ReviewerDetails } from '../../../page-modules/playlist/models';
import { UserSearch } from '@app/app/shared/models/admin/members.model';

export class ApiLearnerPlaylistDataService implements LearnerPlaylistDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  addPlaylistToFavorites(playlistId: string) {
    return this.client.patch<void>(Location.joinWithSlash(environment.apiRootUrl, `playlists/${playlistId}/favorites`)).pipe(
      switchMap((res) => ObservableResult.ofSuccess(res.body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorMarkPlaylistFavorite'))),
    );
  }

  removePlaylistFromFavorites(playlistId: string) {
    return this.client.delete<void>(Location.joinWithSlash(environment.apiRootUrl, `playlists/${playlistId}/favorites`)).pipe(
      switchMap((res) => ObservableResult.ofSuccess(res.body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorUnMarkPlaylistFavorite'))),
    );
  }

  getRecentPlaylists(page: number, pageSize?: number): ObservableResult<Page<LearnerPlaylistSummary>> {
    return this.client
      .get<Page<LearnerPlaylistSummary>>(Location.joinWithSlash(environment.apiRootUrl, 'playlists/engagements'), {
        page: page.toString(),
        size: pageSize ? pageSize.toString() : '10',
      })
      .pipe(
        switchMap((res) => ObservableResult.ofSuccess(res.body)),
        catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetRecentPlaylist'))),
      );
  }

  getFavoritePlaylists(page: number, pageSize: number): ObservableResult<Page<FavoritePlaylistSummary>> {
    return this.client
      .get<Page<FavoritePlaylistSummary>>(Location.joinWithSlash(environment.apiRootUrl || '', 'playlists/favorites'), {
        page: page.toString(),
        size: pageSize.toString(),
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetPlaylists'))),
      );
  }

  getReport(playlistId: string): ObservableResult<LearnerPlaylistReportItem[]> {
    return this.client
      .get<LearnerPlaylistReportItem[]>(Location.joinWithSlash(environment.apiRootUrl, `playlists/${playlistId}/downloads`))
      .pipe(
        switchMap((res) => ObservableResult.ofSuccess(res.body)),
        catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetLearnerPlaylistReport'))),
      );
  }

  getReportByUserUid(playlistId: string, userUid: string): ObservableResult<LearnerPlaylistReportItem[]> {
    return this.client
      .get<LearnerPlaylistReportItem[]>(Location.joinWithSlash(environment.apiRootUrl, `playlists/${playlistId}/downloads/u/${userUid}`))
      .pipe(
        switchMap((res) => ObservableResult.ofSuccess(res.body)),
        catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetLearnerPlaylistReport'))),
      );
  }

  getPlaylistSubmissionSummary(playlistId: string): ObservableResult<UserPlaylistSubmissionSummary> {
    return this.client
      .get<UserPlaylistSubmissionSummary>(Location.joinWithSlash(environment.apiRootUrl, `playlists/${playlistId}/submission/summary`))
      .pipe(
        switchMap((res) => ObservableResult.ofSuccess(res.body)),
        catchError(() =>
          ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetPlaylistSubmissionSummary')),
        ),
      );
  }

  submitPlaylistSummary(playlistId: string, request?: SubmitPlaylistSummary): ObservableResult<void> {
    return this.client
      .post<void>(Location.joinWithSlash(environment.apiRootUrl, `playlists/${playlistId}/submit`), request ? request : undefined)
      .pipe(
        switchMap((res) => ObservableResult.ofSuccess(res.body)),
        catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorSubmitPlaylistSummary'))),
      );
  }

  resubmitPlaylist(playlistId: string): ObservableResult<void> {
    return this.client.post<void>(Location.joinWithSlash(environment.apiRootUrl, `playlists/${playlistId}/resubmit`)).pipe(
      switchMap((res) => ObservableResult.ofSuccess(res.body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorResubmitPlaylistSummary'))),
    );
  }

  loadPlaylistReviewers(playlistId: string, page: number, size: number, term?: string): ObservableResult<Page<ReviewerDetails>> {
    const params = {
      page: page.toString(),
      size: size ? size.toString() : '10',
    };
    if (term) {
      params['term'] = term;
    }
    return this.client
      .get<Page<ReviewerDetails>>(Location.joinWithSlash(environment.apiRootUrl, `playlists/${playlistId}/reviewers/search`), params)
      .pipe(
        switchMap((res) => ObservableResult.ofSuccess(res.body)),
        catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetPlaylistReviewers'))),
      );
  }

  shareSubmissionWithMentor(playlistId: string, mentorUserUid: string): ObservableResult<void> {
    return this.client
      .patch<void>(Location.joinWithSlash(environment.apiRootUrl, `playlists/${playlistId}/invited-mentors/${mentorUserUid}`))
      .pipe(
        switchMap((res) => ObservableResult.ofSuccess(res.body)),
        catchError(() =>
          ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorUpdatePlaylistSubmissionSettings')),
        ),
      );
  }

  findMentorsWithSubmissionAccess(learnerPlaylistUid: string): ObservableResult<UserSearch[]> {
    return this.client
      .get<UserSearch[]>(Location.joinWithSlash(environment.apiRootUrl, `playlists/${learnerPlaylistUid}/invited-mentors`))
      .pipe(
        switchMap((res) => ObservableResult.ofSuccess(res.body)),
        catchError(() =>
          ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorGetMentorsWithSubmissionAccess')),
        ),
      );
  }

  removeMentorAccess(playlistId: string, mentorUserUid: string): ObservableResult<void> {
    return this.client
      .delete<void>(Location.joinWithSlash(environment.apiRootUrl, `playlists/${playlistId}/invited-mentors/${mentorUserUid}`))
      .pipe(
        switchMap((res) => ObservableResult.ofSuccess(res.body)),
        catchError(() =>
          ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorUpdatePlaylistSubmissionSettings')),
        ),
      );
  }
}
