/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { MomentTagState } from '../store/moment/moment-tag/moment-tag.state';
import { FrameWorkState } from '../../admin/store/framework.state';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '../../../shared/shared.module';
import { PtlEditorModule } from '../../../editor/editor.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RestClientService } from '../../../shared/services/rest-client.service';
import { environment } from '../../../../environments/environment';
import { ApiMomentDataService } from './services/api-data.service';
import { MockMomentDataService } from './services/mock-data.service';
import { CORE_MOMENT_DATA_SERVICE, MomentDataService } from './services/data.service';
import { FolioNewMomentSectionComponent } from './components/new-moment-section/new-moment-section.component';
import { MomentDetailsFormComponent } from './components/new-moment-section/moment-details-form/moment-details-form.component';
import { MomentAddTagsComponent } from './components/new-moment-section/moment-add-tags/moment-add-tags.component';
import { NewMomentDialogComponent } from './components/new-moment-dialog/new-moment-dialog.component';
import { NewMomentExpandBtnComponent } from './components/new-moment-section/new-moment-expand-btn/new-moment-expand-btn.component';
import { MomentActionsComponent } from './components/new-moment-section/moment-actions/moment-actions.component';
import { NewMomentSectionDialogComponent } from './components/new-moment-section/new-moment-section-dialog/new-moment-section-dialog.component';
import { NewMomentSectionStaticComponent } from './components/new-moment-section/new-moment-section-static/new-moment-section-static.component';

import { NgxsModule } from '@ngxs/store';
import { AdminDataService, ADMIN_DATA_SERVICE } from '../../admin/services/data.service';
import { CORE_TAG_DATA_SERVICE, TagDataService } from '../../../shared/services/tags/tag-data.service';
import { ApiFrameWorkDataService } from '../../admin/services/api-data.service';
import { MockAdminDataService } from '../../admin/services/mock-data.service';
import { MockTagDataService } from '../../../shared/services/tags/mock-tag-data.service';
import { ApiTagDataService } from '../../../shared/services/tags/api-tag-data.service';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoRootModule } from '../../../transloco/transloco-root.module';
import { PublisherDialogComponent } from '../../publisher/components/publisher-dialog/publisher-dialog.component';
import { CONTENT_PACKAGE_DATA_SERVICE } from '../../../shared/services/content-package/content-package-data.service';
import * as SharedFactoryService from '../../../shared/shared-service-factory';

const MATERIAL_MODULES = [
  MatCheckboxModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatIconModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatCardModule,
  MatSnackBarModule,
  MatButtonModule,
  MatInputModule,
];

@NgModule({
  declarations: [
    FolioNewMomentSectionComponent,
    MomentDetailsFormComponent,
    NewMomentDialogComponent,
    PublisherDialogComponent,
    MomentActionsComponent,
    MomentAddTagsComponent,
    NewMomentExpandBtnComponent,
    NewMomentSectionDialogComponent,
    NewMomentSectionStaticComponent,
  ],
  imports: [
    CommonModule,
    MATERIAL_MODULES,
    SharedModule,
    PtlEditorModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    NgxsModule.forFeature([MomentTagState, FrameWorkState]),
  ],
  providers: [
    { provide: ADMIN_DATA_SERVICE, useFactory: serviceFactory, deps: [RestClientService, TranslocoService] },
    { provide: CORE_TAG_DATA_SERVICE, useFactory: tagServiceFactory, deps: [RestClientService, TranslocoService] },
    {
      provide: CONTENT_PACKAGE_DATA_SERVICE,
      useFactory: SharedFactoryService.contentStoreFactory,
      deps: [RestClientService, TranslocoService],
    },
    {
      provide: CORE_MOMENT_DATA_SERVICE,
      useFactory: momentServiceFactory,
      deps: [RestClientService, TranslocoService],
    },
  ],
  exports: [NewMomentDialogComponent, NewMomentSectionDialogComponent, NewMomentSectionStaticComponent],
  entryComponents: [NewMomentDialogComponent, PublisherDialogComponent],
})
export class SharedMomentModule {}

// -------------------------------------------------------------------------
/** Provides the core moment data service. */
export function momentServiceFactory(client: RestClientService, translocoService: TranslocoService): MomentDataService {
  if (environment.dataProvider === 'mock') {
    return new MockMomentDataService();
  }

  return new ApiMomentDataService(client, translocoService);
}

/** Provides the data service. */
export function serviceFactory(client: RestClientService, translocoService: TranslocoService): AdminDataService {
  if (environment.dataProvider === 'mock') {
    return new MockAdminDataService();
  }

  return new ApiFrameWorkDataService(client, translocoService);
}

export function tagServiceFactory(client: RestClientService, translocoService: TranslocoService): TagDataService {
  if (environment.dataProvider === 'mock') {
    return new MockTagDataService();
  }

  return new ApiTagDataService(client, translocoService);
}
