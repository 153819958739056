/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, OnInit, Output, Input, OnChanges, SimpleChanges } from '@angular/core';

interface DotsVisibility {
  beginning: boolean;
  end: boolean;
}

interface Pager {
  totalItems: number;
  currentPageIndex: number;
  itemsPerPage: number;
  totalPageCount: number;
  startPage: number;
  endPage: number;
  firstPage: number;
  lastPage: number;
  pages: number[];
  dots: DotsVisibility;
}

@Component({
  selector: 'ptl-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Output() pageChange = new EventEmitter<number>();

  @Input() totalItems: number;
  @Input() totalPageCount: number;
  @Input() currentPageIndex: number;
  @Input() itemsPerPage: number;
  @Input() isZeroBasedIndex = false;

  pager: Pager = {
    totalItems: undefined,
    currentPageIndex: undefined,
    itemsPerPage: undefined,
    totalPageCount: undefined,
    startPage: undefined,
    endPage: undefined,
    firstPage: undefined,
    lastPage: undefined,
    pages: undefined,
    dots: undefined,
  };

  private firstPage: number;
  private pageSize: number;

  ngOnInit() {
    this.firstPage = this.isZeroBasedIndex ? 0 : 1;
    this.pageSize = 10;

    if (this.currentPageIndex) {
      this.initPaginationData();
    } else {
      this.setPage(this.firstPage, false);
    }
  }

  ngOnChanges(_changes: SimpleChanges) {
    this.initPaginationData();
  }

  initPaginationData() {
    if (this.isZeroBasedIndex && this.currentPageIndex === 0) {
      this.setPage(this.currentPageIndex, false);
    }
    if (this.currentPageIndex) {
      this.setPage(this.currentPageIndex, false);
    } else {
      this.setPage(this.pager.currentPageIndex, false);
    }
  }

  setPage(page: number, shouldEmitChange = true) {
    if (page < this.firstPage || page > this.pager.totalPageCount) {
      return;
    }
    this.pager = this.getPager(this.totalItems, page, this.itemsPerPage);
    if (shouldEmitChange) {
      this.pageChange.emit(this.pager.currentPageIndex);
    }
  }
  private getPager(totalItems: number, currentPageIndex: number = this.firstPage, itemsPerPage: number = this.pageSize) {
    let totalPageCount = this.totalPageCount;

    if (totalPageCount === undefined && !!totalItems && !!itemsPerPage) {
      totalPageCount = Math.ceil(totalItems / itemsPerPage);
    }

    if (this.isZeroBasedIndex) {
      totalPageCount--;
    }

    if (totalItems === 0) {
      totalPageCount = 0;
    }
    const dots = this.getDots(totalPageCount, currentPageIndex);
    const startEndPages = this.getStartEndPages(currentPageIndex, totalPageCount, dots);
    const startPage = startEndPages.startPage;
    const endPage = startEndPages.endPage;
    const pages = [];
    for (let i = startPage; i < endPage + 1; i++) {
      pages.push(i);
    }
    return {
      totalItems: totalItems,
      currentPageIndex: currentPageIndex,
      itemsPerPage: itemsPerPage,
      totalPageCount: totalPageCount,
      startPage: startPage,
      endPage: endPage,
      firstPage: this.firstPage,
      lastPage: totalPageCount,
      pages: pages,
      dots: dots,
    };
  }

  private getStartEndPages(
    currentPage: number = this.firstPage,
    totalPages: number,
    dots: DotsVisibility,
  ): { startPage: number; endPage: number } {
    let startPage: number;
    let endPage: number;
    if (totalPages <= (this.isZeroBasedIndex ? 6 : 7)) {
      startPage = this.firstPage;
      endPage = totalPages;
    } else {
      if (!dots.beginning && dots.end) {
        startPage = this.firstPage;
        endPage = this.isZeroBasedIndex ? 4 : 5;
      } else if (dots.beginning && !dots.end) {
        startPage = this.isZeroBasedIndex ? totalPages - 4 : totalPages - 5;
        endPage = totalPages;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }
    return {
      startPage: startPage,
      endPage: endPage,
    };
  }

  private getDots(totalPages: number, currentPage: number): DotsVisibility {
    if (totalPages <= (this.isZeroBasedIndex ? 6 : 7)) {
      return { beginning: false, end: false };
    } else {
      const beginning = currentPage > (this.isZeroBasedIndex ? 3 : 4);
      const end = currentPage <= (this.isZeroBasedIndex ? totalPages - 4 : totalPages - 5);
      return { beginning: beginning, end: end };
    }
  }
}
