/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, EventEmitter, Inject, NgZone, OnDestroy, Output, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAuthState } from '../../store/user-auth.state';
import { Observable, Subscription } from 'rxjs';
import { Organization } from '../../../shared/models';
import { AUTH_SERVICE, BasicAuthService } from '../../services/basic-auth.service';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { InvisibleReCaptchaComponent, ReCaptchaV3Service, ScriptService } from 'ngx-captcha';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { MaintenanceService } from '../../../shared/services/maintenance/maintenance.service';

@Component({
  selector: 'ptl-invitation-join',
  templateUrl: './invitation-join.component.html',
  styleUrls: ['./invitation-join.component.scss'],
})
export class InvitationJoinComponent implements OnDestroy {
  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  /** Emits on back icon click. */
  @Output() backClicked = new EventEmitter<void>();

  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;

  invitationId: string;
  invitationToken: string;
  reCaptchaElement = null;
  reCaptchaSiteKeyV2 = environment.reCaptchaSiteKeyV2;
  joinReady = false;
  showMaintenanceOverlay: boolean;

  private organizationDataSubscription: Subscription;
  private validatedEmail: string;
  private organizationDomain: string;

  constructor(
    store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    @Inject(AUTH_SERVICE) private authService: BasicAuthService,
    private captchaService: ReCaptchaV3Service,
    private scriptService: ScriptService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private maintenanceService: MaintenanceService,
  ) {
    this.showMaintenanceOverlay = this.maintenanceService.showMaintenanceMessage();
    const params = this.router.parseUrl(this.router.url).queryParamMap;
    this.invitationToken = params.get('token');
    this.invitationId = this.route.snapshot.paramMap.get('invitationId');
    this.organizationDataSubscription = this.organizationData$.pipe(filter((data) => !!data)).subscribe((data) => {
      this.organizationDomain = data.domain;
      if (this.invitationId) {
        this.authService.validateInvitationToken(this.invitationId, this.invitationToken).subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            this.validatedEmail = value.email;
            this.joinReady = true;
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.organizationDataSubscription?.unsubscribe();
  }

  onJoinButtonClick() {
    this.captchaElem.execute();
  }

  resetCaptcha(): void {
    this.cdr.detectChanges();
  }

  proceedJoin(captchaResponseV2: string) {
    this.scriptService.cleanup();
    const request = {
      email: this.validatedEmail?.trim(),
      organizationDomain: this.organizationDomain,
      captchaResponseV3: null,
      captchaResponseV2: captchaResponseV2,
    };
    this.captchaService.execute(environment.reCaptchaSiteKeyV3, 'invitationUserJoin', (captchaResponseV3: string) => {
      request.captchaResponseV3 = captchaResponseV3;
      this.authService.joinOrganizationWithInvitationToken(request, this.invitationId, this.invitationToken).subscribe((response) => {
        if (response.isSuccess) {
          this.snackBar.open(this.translocoService.translate('translations.invitations.message.success.join'), '', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          RedirectHelper.redirectByUrl(this.ngZone, this.router, this.route, '/signin');
        } else {
          this.snackBar.open(this.translocoService.translate('translations.invitations.message.error.join'), '', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
    });
  }
}
