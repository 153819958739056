/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MomentCard } from '../../../../../models';
import { DeleteMoment } from '../../../../../store/moment/moment-view-all/moment-view-all.actions';
import { Select, Store } from '@ngxs/store';
import { UserAuthState } from '../../../../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';
import { UserDetails } from '../../../../../../../user-auth/models';
import { ViewMoment } from '../../../../../../../app-frame/store/app-frame.actions';

@Component({
  selector: 'ptl-masonry-text-card',
  templateUrl: './masonry-text-card.component.html',
  styleUrls: ['./masonry-text-card.component.scss'],
})
export class MasonryTextCardComponent implements OnInit {
  /** Card data. */
  @Input() card: MomentCard;
  @Input() archiveMoment: boolean;
  @Input() selected;

  @Output() cardClick = new EventEmitter<MomentCard>();
  @Output() removeCard = new EventEmitter<string>();

  @Select(UserAuthState.userDetailsData)
  userDetailsData$: Observable<UserDetails>;

  colorId = 0;

  constructor(private store: Store) {}

  ngOnInit() {
    this.pickBgColorNumber(this.card._id);
  }

  onRemoveMoment(id: string) {
    this.removeCard.emit(id);
  }

  onArchiveMoment(id: string) {
    this.store.dispatch(new DeleteMoment(id));
  }

  onViewClick() {
    if (this.card.viewType !== 'COLLECTOR') {
      this.store.dispatch(new ViewMoment(this.card._id));
    }
  }

  onButtonClick() {
    this.cardClick.emit(this.card);
  }

  // 0 to 4 number generator based on the card._id
  private pickBgColorNumber(id: string) {
    // grabs the last 3 number from id, and sums them up
    const lastThreeNumbersOfIdArray = id.match(/[0-9]/g).map(Number).reverse().slice(0, 3);
    const sumOfArray = lastThreeNumbersOfIdArray.reduce((a, b) => a + b, 0);

    // calculates the 0 to 4 range of the sum, max sum is 27
    this.colorId = Math.floor((sumOfArray / 27.01) * 5);
  }
}
