/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../shared/store';
import { LearnerViewDataService } from './data.service';
import { MediumEditorData, Resource } from '../../../shared/models';
import { Injectable } from '@angular/core';
import { EditorContentType, Form, MediaUrlData, TextBoxFormContent } from '../../../editor/models';
import { delay } from 'rxjs/operators';
import { CardWithProgress, LearnerFormAnswerRequest, LearnerFormUpdatePermission, LearnerResourceProgress } from '../models';
import { CardCompletionRequirements } from '../../../shared/models/completion-logic/completion-logic.model';
import { PrerequisitesRequest, RequirementsMode } from '../../playlist/models';
import { SubmitPlaylistSummary } from 'src/app/shared/models/playlist/playlist-report.model';

/* eslint-disable max-len */
@Injectable()
export class MockLearnerViewDataService implements LearnerViewDataService {
  private dummyExistingResource: Resource = {
    _id: 'uid1',
    organization: {
      _id: 'company1',
      name: 'University of Leicester',
    },
    likes: {
      vote: false,
      totalLikes: 45,
    },
    formattedUri: '',
    content: {
      headline: 'Nice headline',
      sections: [
        {
          uid: 'main sections uid',
          dynamicContent: [
            {
              uid: 'contentUid1',
              type: 'IMAGE_URL' as EditorContentType,
              contentUrl: '',
              contentHtml: '<div><span>Nice one</span></div>',
            } as MediaUrlData,
            {
              uid: 'contentUid2',
              type: 'MEDIA_URL',
              contentUrl: 'https://www.youtube.com/watch?v=2tch4J_pP9o',
              contentHtml:
                '<div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.2493%;">' +
                '<iframe src="https://www.youtube.com/embed/2tch4J_pP9o?rel=0&amp;showinfo=0" style="border: 0; top: 0; ' +
                'left: 0; width: 100%; height: 100%; position: absolute;" allowfullscreen scrolling="no" ' +
                'allow="autoplay; encrypted-media"></iframe></div>',
              position: 'RIGHT',
              mediaType: 'VIDEO',
            } as MediaUrlData,
            {
              uid: 'contentUid3',
              type: 'PARAGRAPH' as EditorContentType,
              content:
                '<p>Wind chills have dipped well below zero, freezing the little town about 15 miles ' +
                'northwest of Ann Arbor. Hell has officially frozen over.</p>' +
                '<p>It’s so cold in Hell that some residents are hopping on their snowmobiles and heading to an emergency' +
                ' warming center at the Hell Saloon to stay toasty during the dangerously cold weather.</p>',
            } as MediumEditorData,
            {
              uid: 'contentUid4',
              type: 'FORM' as EditorContentType,
              sections: [
                {
                  uid: 'formSectionUid',
                  content: {
                    type: 'TEXTBOX',
                    title: 'What do you think about this?',
                    wordLimit: 200,
                    answerType: 'NORMAL',
                  } as TextBoxFormContent,
                },
              ],
            } as Form,
          ],
        },
        {
          uid: 'section UId',
          dynamicContent: [
            {
              uid: 'contentUid5',
              type: 'MEDIA_URL',
              contentUrl: 'https://www.youtube.com/watch?v=2tch4J_pP9o',
              contentHtml:
                '<div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.2493%;">' +
                '<iframe src="https://www.youtube.com/embed/2tch4J_pP9o?rel=0&amp;showinfo=0" style="border: 0; top: 0; ' +
                'left: 0; width: 100%; height: 100%; position: absolute;" allowfullscreen scrolling="no" ' +
                'allow="autoplay; encrypted-media"></iframe></div>',
              position: 'LEFT',
              mediaType: 'VIDEO',
            } as MediaUrlData,
            {
              uid: 'contentUid6',
              type: 'PARAGRAPH' as EditorContentType,
              content:
                '<p>Wind chills have dipped well below zero, freezing the little town about 15 miles ' +
                'northwest of Ann Arbor. Hell has officially frozen over.</p>' +
                '<p>It’s so cold in Hell that some residents are hopping on their snowmobiles and heading to an emergency' +
                ' warming center at the Hell Saloon to stay toasty during the dangerously cold weather.</p>',
            } as MediumEditorData,
          ],
        },
      ],
    },
    tags: [
      {
        _id: 'tagId',
        title: 'assignment',
      },
    ],
    permission: {
      canEdit: false,
      canPublish: true,
      canDoMemberExports: true,
      canViewAnalytics: true,
    },
    status: 'PUBLISHED',
    lastUpdatedOn: new Date().toISOString(),
    childOfInstitutionalLibrary: false,
    settings: {
      publication: {
        author: {
          name: 'James Allen',
          uid: 'fooBar',
          organization: 'Uni A',
          displayConfig: {
            authorName: true,
            organizationName: true,
          },
          orgLogoUrl: 'https://static.yuna.potential.ly/public/images/icons/potentially_logo.png',
          userImageUrl: 'assets/profile_image.png',
        },
        uri: 'uri',
      },
    },
    subHeader: null,
  };

  getCardWithProgress(
    isProjectResource: boolean,
    playlistUri: string,
    resourceUri: string,
    publisherUri: string,
    packageUri: string,
    pageUri: string,
    languageCode: string,
  ): ObservableResult<CardWithProgress> {
    return ObservableResult.ofSuccess({
      card: this.dummyExistingResource,
      progress: {
        status: 'NOT_STARTED',
        learnerFormAnswers: [],
      } as LearnerResourceProgress,
    }).pipe(delay(1500));
  }

  validateFormUpdate(playlistUid: string, resourceUid: string, formUid: string): ObservableResult<LearnerFormUpdatePermission> {
    return ObservableResult.ofSuccess();
  }

  saveFormAnswer(
    playlistUid: string,
    resourceUid: string,
    formAnswer: LearnerFormAnswerRequest,
  ): ObservableResult<LearnerResourceProgress> {
    return ObservableResult.ofSuccess({
      status: 'NOT_STARTED',
      learnerFormAnswers: [formAnswer],
    } as LearnerResourceProgress);
  }

  submitForm(playlistUid: string, resourceUid: string, formId: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  submitFormAndPlaylist(playlistUid: string, resourceUid: string, formId: string, request: SubmitPlaylistSummary): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeFileFromForm(playlistUid: string, resourceUid: string, formUid: string, filePath: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  loadCompletionActivities(playlistUri: string, resourceUri: string): ObservableResult<CardCompletionRequirements> {
    return ObservableResult.ofSuccess({
      activities: [
        {
          activity: 'Some description',
          required: true,
          activityId: '111',
        },
      ],
      cardRequired: false,
      prerequisites: [],
    });
  }

  setCardCompletionRequired(playlistUid: string, resourceUid: string, required: boolean): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  setActivityRequired(playlistId: string, resourceUid: string, activityId: string, required: boolean): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateCompletionPrerequisites(plyalistUri: string, resourceUri: string, requestBody: PrerequisitesRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateCompletionRequirementsMode(playlistId: string, mode: RequirementsMode, minRequiredCard: number): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
