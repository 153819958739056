<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<mat-card
  class="page-card"
  *ngIf="!!card"
  (click)="onCardClick()"
  (keydown.enter)="onCardClick()"
  (contextmenu)="onContextMenu($event)"
  [attr.aria-label]="'translations.global.ariaLabels.open' | transloco: { title: card.title }"
  tabindex="0"
  [ngStyle]="{ cursor: inHomeLayoutEditor ? 'default' : '' }"
>
  <div
    class="page-card__context-menu"
    [ngStyle]="{ left: contextMenuPosition.x, top: contextMenuPosition.y }"
    [matMenuTriggerFor]="contextMenu"
  ></div>
  <mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="item">
      <button mat-menu-item (click)="onOpenInNewTab()">
        <span>{{ 'translations.openCardInANewTab' | transloco }}</span>
      </button>
    </ng-template>
  </mat-menu>

  <div class="page-card__top-icon">
    <div class="page-card__completed-icon" *ngIf="card.completed">
      <mat-icon aria-hidden="true" class="check-icon" title="Completed"> check_circle_outline </mat-icon>
    </div>
  </div>

  <div class="page-card__center">
    <ng-container *ngIf="card.thumbnail; else placeholderImage">
      <img class="center__image" alt="" width="266" height="150" [src]="card.thumbnail" />
    </ng-container>
    <ng-template #placeholderImage>
      <img
        class="center__empty-image-placeholder"
        alt=""
        width="266"
        height="150"
        [src]="'/assets/card-placeholders/card-placeholder-' + ((index % 4) + 1) + '.png'"
      />
    </ng-template>
  </div>

  <div class="page-card__footer">
    <div class="page-card__footer-badge">
      <mat-icon
        aria-hidden="true"
        class="mat__icon"
        *ngIf="!authorDetails?.displayConfig.organizationName && !authorDetails?.displayConfig.authorName"
      >
        insert_drive_file_outline
      </mat-icon>
      <ng-container *ngIf="authorDetails?.displayConfig.organizationName || authorDetails?.displayConfig.authorName">
        <img class="item__author-image" alt="" width="50" height="50" [src]="imageUrl" *ngIf="hasAuthorImage" />
        <div [title]="authorDetails.name" class="item__author-initials" *ngIf="!hasAuthorImage">
          <p>{{ authorNameFirstChar }}</p>
        </div>
      </ng-container>
    </div>
    <div class="page-card__info">
      <div class="page-card__info-box">
        <div class="page-card__header" [title]="card?.title" #titleElement>{{ card?.title }}</div>
        <p class="page-card__description" [title]="card?.subHeader">
          {{ card?.subHeader }}
        </p>
      </div>
    </div>
  </div>
</mat-card>
