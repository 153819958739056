/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { ObservableResult } from '../../../../shared/store';
import { RestClientService } from '../../../../shared/services/rest-client.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import {
  CreateOrganizationRoleRequest,
  OrganizationPrivilege,
  OrganizationRoles,
  UpdateOrganizationRoleRequest,
} from '../../../../shared/models/organization';
import { UserRoleType } from '../../../../user-auth/models';
import { RolesDataService } from './roles-data.service';
import { Page } from '../../../../shared/models/page';
import { Members } from '../../../../shared/models/admin/members.model';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ApiRolesDataService implements RolesDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  loadOrganizationRoles(organizationUid: string): ObservableResult<OrganizationRoles[]> {
    return this.client
      .get<OrganizationRoles[]>(Location.joinWithSlash(environment.apiRootUrl || '', `organizations/${organizationUid}/roles`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetRoles'))),
      );
  }

  loadOrganizationRoleById(roleUid: string): ObservableResult<OrganizationRoles> {
    return this.client.get<OrganizationRoles>(Location.joinWithSlash(environment.apiRootUrl || '', `users/roles/${roleUid}`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetRole'))),
    );
  }

  updateOrganizationRole(roleUid: string, request: UpdateOrganizationRoleRequest): ObservableResult<OrganizationRoles> {
    return this.client
      .patch<OrganizationRoles>(Location.joinWithSlash(environment.apiRootUrl || '', `users/roles/${roleUid}`), request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdateRole'))),
      );
  }

  createOrganizationRole(request: CreateOrganizationRoleRequest): ObservableResult<OrganizationRoles> {
    return this.client.post<OrganizationRoles>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/roles'), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorCreateRole'))),
    );
  }

  loadOrganizationPrivileges(roleType: UserRoleType): ObservableResult<OrganizationPrivilege[]> {
    return this.client
      .get<OrganizationPrivilege[]>(Location.joinWithSlash(environment.apiRootUrl || '', `users/roles/privileges?type=${roleType}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetRolePrivileges'))),
      );
  }

  assignRoleToUser(roleUid: string, userUid: string): ObservableResult<void> {
    return this.client.patch<void>(Location.joinWithSlash(environment.apiRootUrl || '', `users/${userUid}/roles/${roleUid}`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAssignRoleToUser'))),
    );
  }

  removeRoleFromUser(roleUid: string, userUid: string): ObservableResult<void> {
    return this.client.delete<void>(Location.joinWithSlash(environment.apiRootUrl || '', `users/${userUid}/roles/${roleUid}`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRemoveRoleFromUser'))),
    );
  }

  assignRoleToMultipleUsers(roleUid: string, request: string[]): ObservableResult<void> {
    return this.client.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `users/roles/${roleUid}/assign`), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAssignRoleToUsers'))),
    );
  }

  assignMultipleRolesToMultipleUsers(roleUids: string[], userUids: string[]): ObservableResult<void> {
    return this.client
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/roles/assign'), {
        users: userUids,
        roles: roleUids,
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAssignRoleToUsers'))),
      );
  }

  removeRoleFromMultipleUsers(roleUid: string, request: string[]): ObservableResult<void> {
    return this.client.post<void>(Location.joinWithSlash(environment.apiRootUrl || '', `users/roles/${roleUid}/unassign`), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRemoveRoleFromUsers'))),
    );
  }

  removeMultipleRolesFromMultipleUsers(roleUids: string[], userUids: string[]): ObservableResult<void> {
    return this.client
      .post<void>(Location.joinWithSlash(environment.apiRootUrl || '', 'users/roles/unassign'), {
        users: userUids,
        roles: roleUids,
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRemoveRoleFromUsers'))),
      );
  }

  loadMembersByRoleId(roleUid: string, page: number, size: number, term?: string): ObservableResult<Page<Members>> {
    const params = {
      page: page.toString(),
      size: size ? size.toString() : '10',
    };
    if (term) {
      params['term'] = term;
    }
    return this.client
      .get<Page<Members>>(Location.joinWithSlash(environment.apiRootUrl || '', `/users/search/roles/${roleUid}`), params)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetMembers'))),
      );
  }

  loadRolesByPage(page: number, size: number): ObservableResult<Page<OrganizationRoles>> {
    return this.client
      .get<Page<OrganizationRoles>>(Location.joinWithSlash(environment.apiRootUrl || '', `users/roles/search?page=${page}&size=${size}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetRoles'))),
      );
  }

  loadRolesByPageWithSearch(searchText: string, page: number, size: number): ObservableResult<Page<OrganizationRoles>> {
    return this.client
      .get<
        Page<OrganizationRoles>
      >(Location.joinWithSlash(environment.apiRootUrl || '', `users/roles/search?term=${searchText}&page=${page}&size=${size}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetRoles'))),
      );
  }
}
