/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

// -------------------------------------------------------------------------
import {
  AchievementsSectionRequest,
  FolioSectionItemRequest,
  GrowthTrackerSectionRequest,
  MomentsSectionRequest,
  ProjectsSectionRequest,
} from './folio.state.model';
import { SectionReorder } from '../../../shared/models/page/page.model';

export class LoadUserFolio {
  static readonly type = '[Folio] Load user folio';
}

export class LoadPublicUserFolio {
  static readonly type = '[Folio] Load public user folio by publicId';

  constructor(public publicId: string) {}
}

export class ClearUserFolio {
  static readonly type = '[Folio] Clear user folio';
}

export class UpdateUserFolioStatus {
  static readonly type = '[Folio] Update user folio status';

  constructor(public isLive: boolean) {}
}

export class UpdateUserFolioCover {
  static readonly type = '[Folio] Update folio cover image';

  constructor(public imageFile: File) {}
}

export class DeleteUserFolioCover {
  static readonly type = '[Folio] Delete folio cover image';
}

export class ReorderFolioSection {
  static readonly type = '[Folio] Reorder user folio status';

  constructor(public sections: SectionReorder) {}
}

export class UpdateUserFolioLink {
  static readonly type = '[Folio] Update user folio link';

  constructor(public link: string) {}
}

export class UpdateUserFolioIntroduction {
  static readonly type = '[Folio] Update user folio introduction';

  constructor(public shortDescription: string) {}
}

export class SwitchDisplayHiddenElements {
  static readonly type = '[Folio] switch display elements in edit.';

  constructor(public mode: boolean) {}
}

export class FolioMomentsUpdate {
  static readonly type = '[Folio] Update folio moments.';
}

export class UpdateFolioSection {
  static readonly type = '[Folio] Update folio section';

  constructor(
    public sectionUid: string,
    public request: GrowthTrackerSectionRequest | MomentsSectionRequest | ProjectsSectionRequest | AchievementsSectionRequest,
  ) {}
}

export class EnableFolioSectionItemVisibility {
  static readonly type = '[Folio] Enable folio section item visibility';

  constructor(
    public sectionUid: string,
    public request: FolioSectionItemRequest,
  ) {}
}

export class DisableFolioSectionItemVisibility {
  static readonly type = '[Folio] Disable folio section item visibility';

  constructor(
    public sectionUid: string,
    public request: FolioSectionItemRequest,
  ) {}
}
