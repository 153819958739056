/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Form } from '../../../models';

@Component({
  selector: 'ptl-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent {
  /** Receives the input data object */
  private _form: Form;
  private _canRemoveItem = true;

  @Input()
  set form(value: Form) {
    if (typeof value === 'string') {
      this._form = JSON.parse(decodeURIComponent(value));
    } else {
      this._form = value;
    }
  }

  get form() {
    return this._form;
  }

  @Input()
  set canRemoveItem(value) {
    if (typeof value === 'string') {
      this._canRemoveItem = JSON.parse(decodeURIComponent(value));
    } else {
      this._canRemoveItem = value;
    }
  }

  get canRemoveItem() {
    return this._canRemoveItem;
  }

  /** Emits removing of the formContent event */
  @Output() removeFormContent = new EventEmitter<void>();

  /** Emits the update of the formContent event */
  @Output() updateFormContent = new EventEmitter<Form>();

  onFormElementSaved(data: Form) {
    this.form = data;
    this.updateFormContent.emit(this.form);
  }

  onFormElementRemoved() {
    this.removeFormContent.emit();
  }
}
