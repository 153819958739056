/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { Injectable } from '@angular/core';
import { Page as Pagination } from '../../models/page';
import { UpcomingEventCard, UpcomingEventFilter, UpcomingEventsTag } from '../../models/event-card/event-card.model';
import { SharedUpcomingDataService } from './upcoming-data.service';

@Injectable()
export class MockSharedUpcomingDataService implements SharedUpcomingDataService {
  getUpcomingEventsCards(
    page: number,
    size: number,
    frameworkId?: string,
    tagId?: string,
  ): ObservableResult<Pagination<UpcomingEventCard>> {
    return ObservableResult.ofSuccess();
  }

  getUpcomingEventsTags(page: number, size: number, term?: string): ObservableResult<Pagination<UpcomingEventsTag>> {
    return ObservableResult.ofSuccess();
  }

  hasUpcomingEvents(): ObservableResult<boolean> {
    return ObservableResult.ofSuccess(true);
  }

  getUpcomingEventsByFilter(page: number, size: number, filter: UpcomingEventFilter): ObservableResult<Pagination<UpcomingEventCard>> {
    return ObservableResult.ofSuccess();
  }

  getEventsEngagements(page: number, size: number, filter: UpcomingEventFilter): ObservableResult<Pagination<UpcomingEventCard>> {
    return ObservableResult.ofSuccess();
  }
}
