<div class="question-form form-section" (click)="expandForm()" (keydown.enter)="$event.stopPropagation()">
  <form
    class="form-block"
    tabindex="0"
    [attr.aria-label]="'translations.global.ariaLabels.thisIsTextboxForm' | transloco"
    [formGroup]="questionForm"
    (ngSubmit)="saveForm()"
  >
    <div class="form-section__box form-block-title-box form-section__box--no-border">
      <input
        #formTitleInput
        type="text"
        class="form-block-input-field no-border full-width no-padding medium bold"
        required
        (input)="hasError = false"
        formControlName="title"
        [placeholder]="'translations.newQuestion' | transloco"
      />

      <span class="setting-text extra-small" *ngIf="!(expanded || forceExpand)">{{
        'translations.global.button.settings' | transloco
      }}</span>
    </div>

    <ng-container *ngIf="expanded || forceExpand">
      <div class="form-section__box form-section__box--no-border">
        <label class="small bold" for="selectionLimit">
          {{ 'translations.editor.toolbarNew.option.questionForm.settings.addNewFormList' | transloco }}
        </label>
        <div class="add-list-item">
          <input
            matInput
            #listInput
            type="text"
            [value]="listTitle"
            [matAutocomplete]="auto"
            (keyup)="onListNameInput($event)"
            (keydown.enter)="onAddList(listInput.value)"
          />

          <mat-autocomplete
            #auto="matAutocomplete"
            [panelWidth]="450"
            [displayWith]="getOptionText"
            (optionSelected)="getSelectedList($event)"
          >
            <mat-option
              *ngFor="let listItem of formLists"
              [disabled]="listItem._id === optionsListUid"
              [id]="listItem._id"
              [value]="listItem"
            >
              {{ listItem.title | getDataByLangCode }}
            </mat-option>
          </mat-autocomplete>
          <button
            mat-flat-button
            color="primary"
            class="form-button-save small btn-with-loader"
            type="button"
            title="Add List"
            [disabled]="!listInput.value || !isListNameDirty"
            [attr.aria-label]="'translations.global.ariaLabels.deleteTextBoxForm' | transloco"
            (click)="onAddList(listInput.value)"
          >
            <svg class="svg svg-icon icon-left" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
            </svg>
            <span>
              {{ optionsListUid ? ('translations.global.button.update' | transloco) : ('translations.global.button.add' | transloco) }}
            </span>
            <mat-spinner class="is-right" *ngIf="saveInProgress" [diameter]="20"></mat-spinner>
          </button>
        </div>
      </div>

      <div *ngIf="!!optionsListUid" class="form-section__box form-section__box--no-border">
        <label class="small bold" for="selectionLimit">
          {{ 'translations.editor.toolbarNew.option.questionForm.settings.options' | transloco }}
        </label>
        <div class="option-list">
          <div class="option-list__item" *ngFor="let formQuestion of formQuestionList; let index = index; trackBy: optionTrackBy">
            <input
              #optionInput
              matInput
              type="text"
              class="border-bottom"
              (keydown)="setAsTouched(formQuestion)"
              (keydown.enter)="updateOption(formQuestion, optionInput.value)"
              [value]="formQuestion.value | getDataByLangCode"
            />
            <svg
              *ngIf="!formQuestion.isTouched"
              class="svg delete"
              role="img"
              [attr.aria-label]="'translations.global.ariaLabels.icons.save' | transloco"
              (click)="removeOption(index, formQuestion.uid)"
            >
              <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
            </svg>

            <button
              mat-flat-button
              color="primary"
              class="form-button-save small btn-with-loader"
              type="button"
              title="Add List"
              *ngIf="formQuestion.isTouched"
              (click)="updateOption(formQuestion, optionInput.value)"
            >
              {{ 'translations.global.button.update' | transloco }}
            </button>
          </div>
        </div>

        <div class="add-item">
          <input
            class="border-bottom subject-input"
            [ngClass]="{ error: !!optionsListUid && formQuestionList.length === 0 && subjectInput.value === '' }"
            #subjectInput
            matInput
            type="text"
            [maxLength]="80"
            [placeholder]="'translations.publisher.subscribers.addNewSubjects' | transloco"
            (keydown.enter)="onAddItem(subjectInput.value)"
          />
          <button
            mat-flat-button
            color="primary"
            class="form-button-save small btn-with-loader option-add"
            type="button"
            title="Add List"
            *ngIf="subjectInput.value"
            (click)="onAddItem(subjectInput.value)"
          >
            {{ 'translations.global.button.add' | transloco }}
          </button>
        </div>
      </div>

      <div class="form-section__wrapper">
        <h2 class="form-section__box form-section__box--title">
          {{ 'translations.editor.toolbarNew.option.questionForm.settings.formSettings' | transloco }}
        </h2>
        <div class="form-section__box form-block-presentation-mode-box">
          <label class="small bold" for="presentation-mode">
            {{ 'translations.editor.toolbarNew.option.questionForm.settings.selectPresentationMode' | transloco }}
          </label>
          <mat-radio-group id="presentation-mode" (change)="getSelectedPresentationMode($event)" formControlName="presentationMode">
            <mat-radio-button
              class="pass-criteria-option"
              [value]="PresentationMode.CHECKBOX"
              color="primary"
              name="presentationModeMustPass"
              tabIndex="0"
            >
              <span class="small">
                {{ 'translations.editor.toolbarNew.option.questionForm.settings.presentationMode.checkbox' | transloco }}
              </span>
            </mat-radio-button>

            <mat-radio-button
              class="pass-criteria-option"
              [value]="PresentationMode.SELECTABLE_DROP_DOWN"
              color="primary"
              name="presentationModeMustPass"
              tabIndex="0"
            >
              <span class="small">
                {{ 'translations.editor.toolbarNew.option.questionForm.settings.presentationMode.selectableDropDown' | transloco }}
              </span>
            </mat-radio-button>

            <mat-radio-button
              class="pass-criteria-option"
              [value]="PresentationMode.RADIO"
              color="primary"
              name="presentationModeMustPass"
              tabIndex="0"
            >
              <span class="small">
                {{ 'translations.editor.toolbarNew.option.questionForm.settings.presentationMode.radio' | transloco }}
              </span>
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div
          *ngIf="presentationMode !== PresentationMode.RADIO"
          class="form-section__box form-block-selection-box form-section__box--no-border"
        >
          <label class="small bold" for="selectionLimit">
            {{ 'translations.editor.toolbarNew.option.questionForm.settings.selectionLimit' | transloco }}
          </label>
          <input
            (change)="getMaxSelection($event)"
            id="selectionLimit"
            matInput
            type="number"
            min="1"
            required
            formControlName="maxSelection"
          />
        </div>
      </div>

      <div class="form-block-buttons">
        <button
          mat-flat-button
          class="form-button-remove small f_delete-block"
          type="button"
          [title]="'translations.global.button.delete' | transloco"
          color="warn"
          *ngIf="canRemoveItem"
          [attr.aria-label]="'translations.global.ariaLabels.deleteTextBoxForm' | transloco"
          (click)="removeForm()"
        >
          <svg class="svg svg-icon icon-left" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
          </svg>
          <span>{{ 'translations.global.button.delete' | transloco }}</span>
        </button>
        <button
          mat-flat-button
          class="form-button-save small btn-with-loader f_update-block"
          [title]="!form?.uid ? ('translations.addToForm' | transloco) : ('translations.global.button.update' | transloco)"
          color="primary"
          type="submit"
          [disabled]="questionForm.invalid || !isFormDirty || formQuestionList.length === 0"
          [attr.aria-label]="'translations.global.ariaLabels.addTextBoxForm' | transloco"
        >
          <svg class="svg svg-icon icon-left" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
          </svg>
          <span>{{ !form?.uid ? ('translations.addToForm' | transloco) : ('translations.global.button.update' | transloco) }}</span>
          <mat-spinner class="is-right" *ngIf="saveFormInProgress" [diameter]="20"></mat-spinner>
        </button>
        <button
          mat-flat-button
          class="form-button-save small f_cancel-block"
          [title]="'translations.global.button.cancel' | transloco"
          color="accent"
          type="button"
          (click)="collapseForm($event)"
          [attr.aria-label]="'translations.global.ariaLabels.collapseForm' | transloco"
        >
          <span>{{ 'translations.global.button.cancel' | transloco }}</span>
        </button>
      </div>
    </ng-container>
  </form>
</div>
