<!--
  ~ Copyright (C) 2019 - present by Potentially
  ~
  ~ Please see distribution for license.
  -->

<div class="separator-container f_separator-content" tabindex="0" (click)="showToolbar = true" (keydown.enter)="showToolbar = true">
  <div class="separator-toolbar f_separator-toolbar" *ngIf="canRemoveItem && showToolbar">
    <div class="separator-toolbar-inner">
      <ul class="separator-toolbar-items">
        <li
          class="separator-toolbar-item f_delete-block"
          [attr.aria-label]="'translations.global.ariaLabels.removeItem' | transloco"
          tabindex="0"
          (click)="onRemoveSeparator($event)"
          (keydown.enter)="onRemoveSeparator($event)"
        >
          <svg class="svg" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
          </svg>
          <span class="label small">{{ 'translations.editor.toolbarNew.floating.option.delete' | transloco }}</span>
        </li>
      </ul>
    </div>
  </div>

  <hr class="separator" />
</div>
