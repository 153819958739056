<div class="custom-mat-select-panel-wrap custom-mat-select-options">
  <div class="custom-mat-select-panel">
    <ptl-app-group-list
      class="custom-mat-optgroup"
      [option]="option"
      [i]="i"
      [selectedElement]="selectedElement"
      [uId]="uId"
      (groupSelectedData)="onGroupSelect($event)"
    ></ptl-app-group-list>
  </div>
</div>
