/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { EmbedCode, ImagePosition } from '../../../../models';
import { cloneDeep } from 'lodash-es';
import { HtmlSanitizer } from '../../../../../shared/helpers/sanitizer/html-sanitizer';

@Component({
  selector: 'ptl-embed-code',
  templateUrl: './embed-code.component.html',
  styleUrls: ['./embed-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmbedCodeComponent implements AfterViewInit {
  private _contentItem: EmbedCode;
  private _canRemoveItem = true;

  @Input()
  set contentItem(value: EmbedCode | undefined) {
    if (typeof value === 'string') {
      this._contentItem = JSON.parse(decodeURIComponent(value));
    } else {
      this._contentItem = value;
    }
  }

  get contentItem() {
    return this._contentItem;
  }

  @Input()
  set canRemoveItem(value) {
    if (typeof value === 'string') {
      this._canRemoveItem = JSON.parse(decodeURIComponent(value));
    } else {
      this._canRemoveItem = value;
    }
  }

  get canRemoveItem() {
    return this._canRemoveItem;
  }

  @Input() newEditor = false;

  /** Emits removing of the embed content event */
  @Output() removeEmbedContent = new EventEmitter<void>();

  /** Emits the update of the embed content event */
  @Output() updateEmbedContent = new EventEmitter<EmbedCode>();

  /** Outputs the new position of the embed content. */
  @Output() updateEmbedPosition = new EventEmitter<string>();

  @ViewChild('htmlCode', { static: false }) public htmlCode: ElementRef;

  showToolbar: boolean;
  contentCode: string;
  showEmbedPopup: boolean;
  position: ImagePosition = 'CENTER';

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.htmlCode) {
      this.htmlCode.nativeElement.innerHTML = '';
    }
    if (this.contentItem) {
      if (this.contentItem.code) {
        this.parseAndAppendHtml(this.contentItem.code);
        this.contentCode = cloneDeep(this.contentItem.code);
      } else {
        this.showEmbedPopup = true;
      }
      if (this.contentItem.position) {
        this.position = this.contentItem.position;
      }
    }
    this.cd.detectChanges();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const targetElement = event.target as Element;
    if (!targetElement.closest('.f_embed-toolbar') && !targetElement.closest('.f_embed-preview')) {
      this.showToolbar = false;
    }
  }

  triggerUpdate() {
    this.contentItem.code = HtmlSanitizer.sanitizeEmbedCode(this.contentCode);
    this.updateEmbedContent.emit(this.contentItem);
    this.showEmbedPopup = false;
    this.renderContent();
  }

  setMediaPosition(position: ImagePosition): void {
    this.position = position;
    this.contentItem.position = this.position;
    this.updateEmbedPosition.emit(position);
  }

  removeMedia(): void {
    this.removeEmbedContent.emit();
  }

  showEmbedCode(event: MouseEvent) {
    event.stopPropagation();
    this.showToolbar = false;
    this.showEmbedPopup = true;
  }

  hideEmbedCode() {
    if (this.htmlCode.nativeElement.innerHTML === '') {
      this.removeMedia();
    }
    this.showEmbedPopup = false;
  }

  private renderContent() {
    this.htmlCode.nativeElement.innerHTML = '';
    this.parseAndAppendHtml(this.contentItem.code);
    this.cd.detectChanges();
  }

  private parseAndAppendHtml(html: string) {
    if (html && !!html.length && this.htmlCode) {
      const sanitized = HtmlSanitizer.sanitizeEmbedCode(html);
      const fragment = document.createRange().createContextualFragment(sanitized);
      this.htmlCode.nativeElement.appendChild(fragment);
    }
  }
}
