<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<button
  mat-stroked-button
  class="toolbar-action-button"
  *ngIf="!inputActive"
  [matTooltip]="'translations.editor.toolbar.button.addLink' | transloco"
  [attr.aria-label]="'translations.global.ariaLabels.addLink' | transloco"
  (click)="addLink()"
>
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" aria-hidden="true">
    <path
      d="M37 4.004c-2.305 0-4.61.878-6.365 2.633l-5 5c-2.492 2.492-3.204 6.09-2.156 9.226l3.591-3.592a4.95 4.95 0 011.393-2.806l5-5A4.993 4.993 0 0137 8.002c1.28 0 2.561.488 3.535 1.463a5.006 5.006 0 010 7.07l-5 5a4.954 4.954 0 01-2.806 1.393l-3.594 3.593a8.974 8.974 0 009.229-2.158l5-5c3.51-3.509 3.508-9.217 0-12.726A8.975 8.975 0 0037 4.004zM30.96 16.98a2 2 0 00-1.374.606l-12 12a2 2 0 102.828 2.828l12-12a2 2 0 00-1.453-3.434zm-12.51 6.043a8.957 8.957 0 00-6.813 2.612l-5 5c-3.51 3.509-3.51 9.22 0 12.728A8.974 8.974 0 0013 45.994a8.972 8.972 0 006.363-2.633l5-5c2.492-2.492 3.206-6.09 2.158-9.226l-3.593 3.594a4.95 4.95 0 01-1.393 2.806l-5 5a5.004 5.004 0 01-7.07 0 5.006 5.006 0 010-7.07l5-5a4.96 4.96 0 012.806-1.395l3.592-3.591a9.072 9.072 0 00-2.414-.456z"
    />
  </svg>
  {{ 'translations.editor.toolbar.button.addLink' | transloco }}
</button>

<form class="media-form" *ngIf="inputActive" [formGroup]="mediaUrlData">
  <mat-form-field class="media-form__add-url">
    <input
      matInput
      type="url"
      required
      formControlName="contentUrl"
      [placeholder]="'translations.mediaContent.label.uploadImagePlaceholder' | transloco"
      [readonly]="mediaEmbedableContentLoading"
      (keydown.enter)="onEnter($event)"
    />
    <mat-error *ngIf="mediaUrlData.controls['contentUrl'].invalid">{{
      'translations.mediaContent.message.error.invalidUrl' | transloco
    }}</mat-error>
    <mat-spinner class="media-form__loading-spinner" *ngIf="mediaEmbedableContentLoading" [diameter]="25"></mat-spinner>
  </mat-form-field>
</form>
