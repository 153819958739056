/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { LinkToFeatureData } from '../../models/page/page.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ClearAllBreadcrumbs } from '../../breadcrumbs/store/breadcrumbs.actions';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { FindItemInSidebarTree } from '../../side-nav-tree/store/side-nav.actions';

@Component({
  selector: 'ptl-playlist-featured-link',
  templateUrl: './playlist-featured-link.component.html',
  styleUrls: ['./playlist-featured-link.component.scss'],
})
export class PlaylistFeaturedLinkComponent implements OnInit {
  /** Link data */
  @Input() linkData: LinkToFeatureData;
  /** Card index in cards data which was got from API. */
  @Input() index: number;

  @Input() inHomeLayoutEditor: boolean;

  @Input() type: Type;
  @Input() size: CardSize;
  @Input() isAdmin: boolean;

  /** Emits when a card is removed. */
  @Output() linkRemove = new EventEmitter<number>();
  /** Emits when a card is removed. */
  @Output() linkEdit = new EventEmitter<LinkToFeatureData>();

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };
  disabled = false;
  isEditPage = false;

  private linkUri: string;

  constructor(
    private router: Router,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    if (this.router.url.split('/').pop().indexOf('edit') === 0) {
      this.isEditPage = true;
    }

    this.linkUri = this.linkData.link ? this.linkData.link : this.linkData.composedUri;
    if (this.type === 'VIEW' && !this.isEditPage) {
      if (this.linkData.isOpenPage || this.linkData.hasTargetedAccess) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    } else {
      if (this.isAdmin) {
        this.disabled = false;
      } else if (this.linkData.hasAdminRights) {
        this.disabled = false;
      } else {
        if (this.linkData.hasTargetedAccess) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      }
    }
  }

  onRemoveClick(event: MouseEvent) {
    event.stopPropagation();
    this.linkRemove.next(this.index);
  }

  onEditClick() {
    this.linkEdit.next(this.linkData);
  }

  onContextMenu(event: MouseEvent) {
    if (this.linkData.link) {
      event.preventDefault();
      const target = event.currentTarget as HTMLElement;
      const rect = target.getBoundingClientRect();
      this.contextMenuPosition.x = event.clientX - rect.x + 'px';
      this.contextMenuPosition.y = event.clientY - rect.y + 'px';
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
  }

  openLink(openInNewTab: boolean) {
    if (this.disabled) {
      return;
    }

    if (this.inHomeLayoutEditor) {
      return;
    }

    const target = openInNewTab ? '_blank' : this.linkUri.indexOf(window.location.hostname) !== -1 ? '_self' : '_blank';
    if (this.linkUri.indexOf('http') > -1 || this.linkUri.indexOf('www.') > -1) {
      RedirectHelper.redirectByUrl(
        this.ngZone,
        this.router,
        this.activatedRoute,
        this.linkData.formattedLink ? this.linkData.formattedLink : this.linkUri,
        null,
        target,
      );
    } else {
      this.store.dispatch(new ClearAllBreadcrumbs());
      const link = this.linkData.formattedLink ? this.linkData.formattedLink : this.linkUri;
      if (this.linkData.destination === 'PLAYLIST') {
        this.store.dispatch(new FindItemInSidebarTree(link));
        RedirectHelper.redirectByParams(
          this.ngZone,
          this.router,
          this.activatedRoute,
          {
            playlistUri: this.linkUri,
            formattedUri: this.linkData.formattedLink,
          },
          'PLAYLIST',
        );
        return;
      }
      if (this.linkData.destination === 'PAGE') {
        this.store.dispatch(new FindItemInSidebarTree(this.linkData.itemUid));
        RedirectHelper.redirectByParams(
          this.ngZone,
          this.router,
          this.activatedRoute,
          {
            pageUri: this.linkUri,
            formattedUri: this.linkData.formattedLink,
          },
          'PAGE',
        );
        return;
      }
      if (this.linkData.destination === 'CARD') {
        RedirectHelper.redirectByUrl(
          this.ngZone,
          this.router,
          this.activatedRoute,
          `/cards/${this.linkData.formattedLink ? this.linkData.formattedLink : this.linkUri}`,
        );
        return;
      }
      if (this.linkData.destination === 'WEBLINK') {
        RedirectHelper.redirectByUrl(
          this.ngZone,
          this.router,
          this.activatedRoute,
          `http://${this.linkData.formattedLink ? this.linkData.formattedLink : this.linkUri}`,
          null,
          target,
        );
      }
    }
  }
}

type Type = 'VIEW' | 'EDIT';
type CardSize = 'STANDARD' | 'SLIM';
