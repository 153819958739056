/*
 * Copyright (C) 2023 - present by Potentially
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { Organization, Resource, PlaylistPublicationSettings, Playlist, UserSubmissionMode } from '../../../../../shared/models';
import { Observable, Subject, Subscription, combineLatest, debounceTime, filter, takeUntil, of } from 'rxjs';
import { EventCardsHelper } from 'src/app/shared/helpers/event-cards-helper';
import {
  AndRequest,
  BooleanFilter,
  BooleanFilterType,
  BooleanQueryType,
  MembersBooleanSearchRequest,
  OrRequest,
} from '../../../../../shared/models/admin/boolean-filters.model';
import {
  CardHeaders,
  DistanceTravelMode,
  DistanceTravelRequest,
  FormHeaders,
  CheckedMembersData,
} from '../../../../../shared/models/admin/members.model';
import { TranslocoService } from '@ngneat/transloco';
import { LoadableState, ObservableResult } from '../../../../../shared/store';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { RedirectHelper } from '../../../../resource/store/editor/content/helpers/redirect.helper';
import { MEMBERS_DATA_SERVICE, MembersDataService } from '../../../services/members/members-data.service';
import { UpdateCardFilters, UpdateMemberFilters, UpdatePlaylistFilters } from '../../members/store/members.action';
import { MembersState } from '../../members/store/members.state';
import { CardFilter, PlaylistFilter } from '../../members/store/members.state.model';
import { AdminMembersAction, AdminMembersActionType, AssessmentExportData, MembersListColumns } from '../model/members.model';
import { AlumniCsvUploadDialogComponent } from './alumni-csv-upload-dialog/alumni-csv-upload-dialog.component';
import { AssessmentExportDialogComponent } from './assessment-export-dialog/assessment-export-dialog.component';
import { CheckInDialogComponent } from './check-in-dialog/check-in-dialog.component';
import { ImportUsersDialogComponent } from './import-users-dialog/import-users-dialog.component';
import { ResourceAdminState } from '../../../../resource/store/admin/resource-admin.state';
import { SendMessageDialogComponent } from './send-message-dialog/send-message-dialog.component';
import { PlaylistResourceEditorPublicationState } from 'src/app/page-modules/playlist/store/create/publication/playlist-publication.state';
import { UsersCsvUploadDialogComponent } from './users-csv-upload-dialog/users-csv-upload-dialog.component';
import { LanguageCodeHelper } from '../../../../../shared/helpers/language-code-helper';
import { EditRoleDialogComponent } from './edit-role-dialog/edit-role-dialog.component';
import { EditGroupDialogComponent } from './edit-group-dialog/edit-group-dialog.component';
import { ReviewAction, ReviewAdminRequest } from '../../../../reviews/models';
import { REVIEWS_DATA_SERVICE, ReviewsDataService } from '../../../../reviews/services/data.service';
import { CloseReviewsModalComponent } from '../../../../reviews/components/view/submissions-controls/close-reviews-modal/close-reviews-modal.component';
import { DialogService } from '../../../../../shared/helpers/dialog/dialog.service';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from '../../../../../shared/services/toast-notifications/toast-service';
import { PlaylistViewState } from '../../../../playlist/store/view/playlist-view.state';
import { LTI_COURSE_DATA_SERVICE, LtiCourseDataService } from '@app/app/page-modules/lti/services/lti-course-data.service';
import { ContentHelper } from '@app/app/shared/helpers/content-helper';
import { SubmissionMode } from '../../../../playlist/models/submission-logic.model';

export type MembersViewAvailableFilters = 'GROUP' | 'ROLE' | 'ATTENDANCE' | 'ACTIVITY' | 'COMPLETION' | 'REVIEW_STATUS';

@Component({
  selector: 'ptl-admin-member-control-panel',
  templateUrl: './admin-member-control-panel.component.html',
  styleUrls: ['./admin-member-control-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminMembersControlPanelComponent implements OnInit, OnDestroy, OnChanges {
  @Input() columns: MembersListColumns[];
  @Input() isAdminPage: boolean;
  @Input() isPlaylistPage: boolean;
  @Input() isCardPage: boolean;
  @Input() isAssessment: boolean;
  @Input() isEvent: boolean;
  @Input() checkedMembers: CheckedMembersData[] = [];
  @Input() totalMembersCount: number;
  @Input() allMembersCount: number;
  @Input() booleanSearchRequest: MembersBooleanSearchRequest;
  @Input() playlistUid: string;
  @Input() cardUid: string;
  @Input() exportEnabled: boolean;
  @Input() exportName: string;
  @Input() isWaitingListEnabled: boolean;
  @Input() cardHeaders: CardHeaders[];
  @Input() formHeaders: FormHeaders[];
  @Input() eventFinished: boolean;
  @Input() isAllSelected: boolean;
  @Input() isWaitingListOperationDisabled: boolean;
  @Input() courseUid?: string;

  @Output() reviewersChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() filterChanged = new EventEmitter<{ andRequest: AndRequest; resetPageNumber: boolean }>();
  @Output() csvFileUploadFinished: EventEmitter<number> = new EventEmitter<number>();
  @Output() triggerUserImport: EventEmitter<{ file: File; headers: string[] }> = new EventEmitter<{ file: File; headers: string[] }>();

  @Output() refreshList = new EventEmitter<number>();
  @Output() memberActionTriggered = new EventEmitter<AdminMembersAction>();

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @Select(UserAuthState.canManageUserImportPrivileges)
  canManageUserImportPrivileges$: Observable<boolean>;

  @Select(ResourceAdminState.resource)
  private resource$: Observable<Resource>;

  @Select(MembersState.memberFilters)
  private filters$: Observable<BooleanFilter[]>;

  @Select(MembersState.cardFilters)
  private cardFilters$: Observable<CardFilter[]>;

  @Select(MembersState.playlistFilters)
  private playlistFilters$: Observable<PlaylistFilter[]>;

  @Select(UserAuthState.userHasSuperAdminRole)
  private isUserSuperAdmin$: Observable<boolean>;

  @Select(UserAuthState.userHasAdminRole)
  private isUserAdmin$: Observable<boolean>;

  @Select(PlaylistResourceEditorPublicationState.publicationSettings)
  private playlistPublicationSettings$: Observable<PlaylistPublicationSettings>;

  @Select(PlaylistViewState.playlistState)
  private playlist$: Observable<LoadableState<Playlist>>;

  reviewActions = ReviewAction;

  filters: BooleanFilter[] = [];
  currentFilter: MembersViewAvailableFilters;
  newFilter: MembersViewAvailableFilters;
  filterLabels: { type: MembersViewAvailableFilters; value: string }[];
  nameRequest: OrRequest;

  exportStarted: boolean;
  advancedExportAvailable = false;
  importDisabledTooltipText: string;

  cardPublished = false;
  playlistPublished = false;
  limitedTickets = false;
  remainingTickets: number;
  ticketsQuantity: number;
  remainingTicketsInfoClass: string = undefined;
  actionButtonsVisible: boolean;
  canManageReviews: boolean;
  isIframeMode: boolean;
  isInitialStateLoad = true;
  isImportDisabled = false;

  private importUsersDialogRef: MatDialogRef<ImportUsersDialogComponent>;

  private alumniUploadDialogRef: MatDialogRef<AlumniCsvUploadDialogComponent>;

  private usersUploadDialogRef: MatDialogRef<UsersCsvUploadDialogComponent>;

  private assessmentExportUsersDialogRef: MatDialogRef<AssessmentExportDialogComponent>;

  private filtersSubscription: Subscription;

  private nameSubscription: Subscription;
  private nameSubject$ = new Subject<void>();

  private subscriptionEnd$ = new EventEmitter<void>();
  private resource: Resource;
  private contentSubscription: Subscription;
  private organizationDataSubscription: Subscription;
  private orgDomain: string;
  private playlistSubmissionMode: UserSubmissionMode;
  private isTicketExist: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService,
    private store: Store,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    @Inject(REVIEWS_DATA_SERVICE) private reviewsDataService: ReviewsDataService,
    @Inject(MEMBERS_DATA_SERVICE) private membersDataService: MembersDataService,
    @Inject(LTI_COURSE_DATA_SERVICE) private ltiCourseDataService: LtiCourseDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
  ) {
    this.isIframeMode = ContentHelper.isFrameMode();
    this.nameSubscription = this.nameSubject$.pipe(debounceTime(500)).subscribe(() => this.applyFilters());
  }

  ngOnInit(): void {
    combineLatest([this.isUserAdmin$, this.isUserSuperAdmin$])
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe((data) => {
        this.advancedExportAvailable = this.checkIfUserCanViewExports(data[0], data[1]);
      });

    this.playlist$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((playlist) => {
      if (playlist && playlist.data) {
        this.canManageReviews = playlist?.data?.userPlaylistSubmissionSummary?.userReviewSummary?.reviewType !== 'AUTOMATIC';
        this.playlistSubmissionMode = playlist?.data?.userPlaylistSubmissionSummary?.submissionMode;
        this.isTicketExist = !!playlist?.data?.publicationSettings?.enrollment?.actionTrigger?.configuration?.tickets;
      }
    });

    this.playlistPublicationSettings$.subscribe((publicationSettings) => {
      if (publicationSettings) {
        this.playlistPublished = publicationSettings.published;
        if (this.playlistPublished) {
          this.importDisabledTooltipText = undefined;
        } else {
          this.importDisabledTooltipText = this.translocoService.translate(
            'translations.membersNew.controlPanel.import.importDisabledTooltipTextPlaylist',
          );
        }
        this.isImportDisabled = !this.playlistPublished;
        this.cdr.detectChanges();
      }
    });
    if (this.isCardPage) {
      this.contentSubscription = this.resource$.subscribe((resource) => {
        if (resource) {
          this.resource = resource;
          this.cardPublished = resource.status === 'PUBLISHED';
          if (this.cardPublished) {
            this.importDisabledTooltipText = undefined;
          } else {
            this.importDisabledTooltipText = this.translocoService.translate(
              'translations.membersNew.controlPanel.import.importDisabledTooltipText',
            );
          }
          if (resource.tickets?.availability === 'LIMITED') {
            this.limitedTickets = true;
            this.remainingTickets = resource.tickets.remainingTickets;
            this.ticketsQuantity = resource.tickets.quantity;
            this.remainingTicketsInfoClass = EventCardsHelper.getTextColorClassBasedOnRemainingTickets(resource.tickets);
          }
          this.isImportDisabled = !this.cardPublished;
          this.cdr.detectChanges();
        }
      });
    }
    this.organizationDataSubscription = this.organizationData$.subscribe((data) => {
      this.orgDomain = data.domain;
    });
    this.buildAvailableFilters();
    this.initFilters();
  }

  ngOnDestroy(): void {
    this.contentSubscription?.unsubscribe();
    this.organizationDataSubscription?.unsubscribe();
    this.nameSubscription?.unsubscribe();
    this.filtersSubscription?.unsubscribe();
    this.subscriptionEnd$.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.checkedMembers) {
      if (!this.actionButtonsVisible) {
        this.actionButtonsVisible = this.checkedMembers.length > 0;
      }
    }
  }

  async reviewAction(operationType: string): Promise<void> {
    if (!this.getCheckedReviewUids()) {
      return;
    }

    let response: ObservableResult<void>;

    if (operationType !== ReviewAction.CLOSE) {
      const isConfirmed = await this.showConfirmDialog(operationType);
      if (!isConfirmed) {
        return;
      }

      if (this.isAllSelected) {
        response = this.doAllItemsOperation(operationType);
      } else {
        response = this.doSelectedItemsOperation(operationType);
      }
      response.subscribe(({ isSuccess, value, error }) => {
        if (isSuccess) {
          this.reviewersChanged.emit();
        } else {
          this.toastService.showFail(error);
        }
      });
    } else {
      this.handleCloseAction(operationType);
    }
  }

  doAllItemsOperation(operationType: string) {
    if (operationType === ReviewAction.REOPEN) {
      return this.reviewsDataService.reOpenReview({ reviewUids: this.getCheckedReviewUids() });
    }

    return of();
  }

  doSelectedItemsOperation(operationType: string) {
    if (operationType === ReviewAction.REOPEN) {
      return this.reviewsDataService.reOpenReview({ reviewUids: this.getCheckedReviewUids() });
    }

    return of();
  }

  showConfirmDialog(key) {
    const keyLowerCase = key.toLowerCase();
    const upperCaseFirstLetter = keyLowerCase.charAt(0).toUpperCase() + keyLowerCase.slice(1);
    const message = this.translocoService.translate('translations.dialog.title.reviewsBulk' + upperCaseFirstLetter);
    const config = {};
    return this.dialogService.showConfirmDialog(message, this.translocoService, config);
  }

  private getCheckedMembersIds(): string[] {
    return this.checkedMembers.map((item) => item._id);
  }

  private getCheckedReviewUids(): string[] {
    return this.checkedMembers.filter((item) => !!item.reviewUid).map((item) => item.reviewUid);
  }

  private handleCloseAction(operationType: string): void {
    const dialogRef = this.dialog.open(CloseReviewsModalComponent, {
      width: '37.5rem',
      disableClose: true,
      maxHeight: '80vh',
      position: {
        top: '37vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: ['ptl-mat-dialog'],
      backdropClass: 'dialog-backdrop',
      data: {
        playlistSubmissionMode: this.playlistSubmissionMode,
        playlistId: this.playlistUid,
      },
    });

    const dialogSubscription = dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (data.confirmed) {
          if (data.reviewCloseReasonSettings) {
            data.reviewCloseReasonSettings.reviewUids = this.getCheckedReviewUids();
          }
          const closeResponse = this.handleCloseWithAcceptance(data.reviewCloseReasonSettings, data.withAcceptance);
          closeResponse.subscribe((value) => {
            if (value) {
              this.reviewersChanged.emit();
            }
          });
        }
      }
      dialogSubscription.unsubscribe();
    });
  }

  openEditRoleDialog(): void {
    if (!this.checkedMembers.length) {
      return;
    }

    this.ngZone.run(() => {
      const dialogRef = this.dialog.open(EditRoleDialogComponent, {
        width: '90vw',
        minWidth: '15.625rem',
        maxWidth: '46.875rem',
        maxHeight: '80vh',
        restoreFocus: true,
        position: {
          top: '10vh',
        },
        direction: LanguageCodeHelper.getBodyLanguageDir(),
        panelClass: 'ptl-mat-dialog',
        backdropClass: 'dialog-backdrop',
        data: {
          checkedMemberIds: this.getCheckedMembersIds(),
        },
      });
      dialogRef.componentInstance.refreshList.subscribe(() => this.reviewersChanged.emit());
      const dialogSubscription = dialogRef.afterClosed().subscribe(() => {
        dialogSubscription.unsubscribe();
      });
    });
  }

  openEditGroupDialog(): void {
    if (!this.checkedMembers.length) {
      return;
    }

    this.ngZone.run(() => {
      const dialogRef = this.dialog.open(EditGroupDialogComponent, {
        width: '90vw',
        minWidth: '15.625rem',
        maxWidth: '46.875rem',
        maxHeight: '80vh',
        restoreFocus: true,
        position: {
          top: '10vh',
        },
        direction: LanguageCodeHelper.getBodyLanguageDir(),
        panelClass: 'ptl-mat-dialog',
        backdropClass: 'dialog-backdrop',
        data: {
          checkedMemberIds: this.getCheckedMembersIds(),
        },
      });
      dialogRef.componentInstance.refreshList.subscribe((value) => this.reviewersChanged.emit());
      const dialogSubscription = dialogRef.afterClosed().subscribe(() => {
        dialogSubscription.unsubscribe();
      });
    });
  }

  invokeEventAction(actionType: AdminMembersActionType): void {
    if (!this.checkedMembers.length) {
      return;
    }

    if (this.isEvent) {
      this.memberActionTriggered.emit({
        type: actionType,
        memberIds: this.getCheckedMembersIds(),
        allMembersRefresh: false,
      });
    }
  }

  buildAvailableFilters(): void {
    const filterLabels = [];
    if (this.columnsExists(['GROUPS']) && !this.isIframeMode) {
      filterLabels.push({ type: 'GROUP', value: this.translocoService.translate('translations.reviews.filter.group') });
    }
    if (this.columnsExists(['ROLES'])) {
      filterLabels.push({ type: 'ROLE', value: this.translocoService.translate('translations.filter.role.name') });
    }
    if (this.columnsExists(['WILL_ATTEND', 'HAS_ATTENDED']) || (this.columnsExists(['ENROL']) && this.isTicketExist)) {
      filterLabels.push({ type: 'ATTENDANCE', value: this.translocoService.translate('translations.filter.attendance.name') });
    }
    if (this.columnsExists(['LAST_ACTIVE'])) {
      filterLabels.push({ type: 'ACTIVITY', value: this.translocoService.translate('translations.filter.activity.nameAlt') });
    }
    if (this.columnsExists(['REVIEW_STATUS'])) {
      filterLabels.push({ type: 'REVIEW_STATUS', value: this.translocoService.translate('translations.filter.review.name') });
    }
    if (!this.isEvent) {
      filterLabels.push({ type: 'COMPLETION', value: this.translocoService.translate('translations.filter.completion.name') });
    }
    this.filterLabels = filterLabels;
  }

  columnsExists(columnsToVerify: MembersListColumns[]): boolean {
    if (this.columns) {
      return columnsToVerify.every((c) => this.columns.includes(c));
    }
    return false;
  }

  filterSelected(filterType: BooleanFilterType): void {
    this.currentFilter = filterType as MembersViewAvailableFilters;
    if (!this.filters.map((f) => f.type).includes(this.currentFilter)) {
      this.newFilter = this.currentFilter;
    }
  }

  nameUpdated(r: OrRequest) {
    this.nameRequest = r;
    this.nameSubject$.next();
  }

  onRemoveFilter(type: MembersViewAvailableFilters): void {
    this.currentFilter = undefined;
    this.newFilter = undefined;
    this.filters = this.filters.filter((f) => f.type !== type);
    this.updateFilters();
  }

  onSaveFilter(f: BooleanFilter): void {
    this.currentFilter = undefined;
    this.newFilter = undefined;
    this.filters = this.filters.filter((currentFilter) => currentFilter.type !== f.type);
    this.filters = [...this.filters, f].sort((f1, f2) => {
      const order = ['GROUP', 'ROLE', 'ATTENDANCE', 'REVIEW_STATUS', 'ACTIVITY', 'COMPLETION'];
      const idx1 = order.findIndex((t) => f1.type === t);
      const idx2 = order.findIndex((t) => f2.type === t);
      return idx1 - idx2;
    });
    this.updateFilters();
  }

  openAlumniUploadCsv(): void {
    this.alumniUploadDialogRef = this.dialog.open(AlumniCsvUploadDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '46.875rem',
      maxHeight: '80vh',
      restoreFocus: true,
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
    });
    this.alumniUploadDialogRef.componentInstance.alumniUploadFinished.subscribe((value) => this.csvFileUploadFinished.emit(value));
  }

  openUsersUploadCsv(): void {
    this.usersUploadDialogRef = this.dialog.open(UsersCsvUploadDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '46.875rem',
      maxHeight: '80vh',
      restoreFocus: true,
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
    });
    this.usersUploadDialogRef.componentInstance.usersUploadFinished.subscribe((value) => this.triggerUserImport.emit(value));
  }

  private handleCloseWithAcceptance(closeReviewData: ReviewAdminRequest, withAcceptance: boolean): ObservableResult<void> {
    return this.reviewsDataService.closeReview(closeReviewData, withAcceptance);
  }

  private updateFilters(): void {
    this.isInitialStateLoad = false;

    if (this.isCardPage) {
      this.store.dispatch(new UpdateCardFilters(this.cardUid, this.filters));
    } else if (this.isPlaylistPage) {
      this.store.dispatch(new UpdatePlaylistFilters(this.playlistUid, this.filters));
    } else {
      this.store.dispatch(new UpdateMemberFilters(this.filters));
    }
  }

  private applyFilters(): void {
    const args = this.filters.flatMap((f) => f.request);
    if (this.nameRequest) {
      args.push(this.nameRequest);
    }
    const request = {
      type: BooleanQueryType.AND,
      args: args,
    } as AndRequest;

    this.filterChanged.emit({ andRequest: request, resetPageNumber: !this.isInitialStateLoad });
  }

  private initFilters(): void {
    if (this.isCardPage) {
      this.filtersSubscription = this.cardFilters$.pipe(filter((data) => !!data)).subscribe((cardFilters) => {
        this.filters = cardFilters.find((cardFilter) => cardFilter.cardUid === this.cardUid)?.value ?? [];

        this.applyFilters();
      });
    } else if (this.isPlaylistPage) {
      this.filtersSubscription = this.playlistFilters$.pipe(filter((data) => !!data)).subscribe((playlistFilters) => {
        this.filters = playlistFilters.find((playlistFilter) => playlistFilter.playlistUid === this.playlistUid)?.value ?? [];

        this.applyFilters();
      });
    } else {
      this.filtersSubscription = this.filters$.pipe(filter((data) => !!data)).subscribe((filters) => {
        this.filters = filters;

        this.applyFilters();
      });
    }
  }

  exportUsersButtonClicked(): void {
    if (this.isAssessment) {
      this.ngZone.run(() => {
        this.assessmentExportUsersDialogRef = this.dialog.open(AssessmentExportDialogComponent, {
          width: '90vw',
          minWidth: '15.625rem',
          maxWidth: '46.875rem',
          maxHeight: '80vh',
          restoreFocus: true,
          position: {
            top: '10vh',
          },
          direction: LanguageCodeHelper.getBodyLanguageDir(),
          panelClass: 'ptl-mat-dialog',
          backdropClass: 'dialog-backdrop',
        });
        this.assessmentExportUsersDialogRef.componentInstance.exportCalled.subscribe((value) => this.exportUsers(value));
      });
    } else {
      this.exportUsers();
    }
  }

  openAdvancedExport(): void {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/admin/advanced-export');
  }

  exportUsers(assessmentExportData?: AssessmentExportData): void {
    const request = this.getExportRequestData();
    this.exportStarted = true;
    let membersObservable: ObservableResult<string>;
    if (this.isPlaylistPage) {
      membersObservable = this.membersDataService.booleanExportPlaylistMembers(request, this.playlistUid);
    } else if (this.isCardPage) {
      if (this.isAssessment && assessmentExportData?.distanceTravelEnabled) {
        if (assessmentExportData.firstStartDate && assessmentExportData.firstEndDate) {
          request.distanceTravelled = this.initializeIntervalsIfEmpty(request);
          request.distanceTravelled.includePercentage = assessmentExportData.includePercentage;
          request.distanceTravelled.intervals.push({
            startDate: this.formatDate(assessmentExportData.firstStartDate),
            endDate: this.formatDate(assessmentExportData.firstEndDate),
            mode: DistanceTravelMode[assessmentExportData.firstType],
          });
        }
        if (assessmentExportData.secondStartDate && assessmentExportData.secondEndDate) {
          request.distanceTravelled = this.initializeIntervalsIfEmpty(request);
          request.distanceTravelled.includePercentage = assessmentExportData.includePercentage;
          request.distanceTravelled.intervals.push({
            startDate: this.formatDate(assessmentExportData.secondStartDate),
            endDate: this.formatDate(assessmentExportData.secondEndDate),
            mode: DistanceTravelMode[assessmentExportData.secondType],
          });
        }
        membersObservable = this.membersDataService.booleanExportCardMembers(request, this.playlistUid, this.cardUid);
      } else if (this.isEvent) {
        membersObservable = this.membersDataService.booleanExportEventCardMembers(request, this.playlistUid, this.cardUid);
      } else {
        membersObservable = this.membersDataService.booleanExportCardMembers(request, this.playlistUid, this.cardUid);
      }
    } else {
      membersObservable = this.membersDataService.booleanExportMembers(request);
    }

    membersObservable.subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.downloadExportedFile(value);
      }
      this.exportStarted = false;
    });
  }

  private downloadExportedFile(value): void {
    const exportName = this.exportName ? this.exportName : 'members';
    const dateFormat = this.getExportedDateFormat();
    const exportedFileName = exportName + '_export_' + dateFormat + '.csv';
    const csvContent = 'data:application/octet-stream;base64,' + btoa(unescape(encodeURIComponent(value)));
    const link = document.createElement('a');
    link.id = 'csvButton';
    link.setAttribute('href', csvContent);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', exportedFileName);
    document.body.appendChild(link); // Required for FF
    link.click();
    link.parentNode.removeChild(link);
  }

  exportUsersFilteredByGroup(): void {
    this.exportStarted = true;
    this.ltiCourseDataService.exportPlaylistMembers(this.playlistUid, this.courseUid).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.downloadExportedFile(value);
      }
      this.exportStarted = false;
    });
  }

  private checkIfUserCanViewExports(isAdmin: boolean, isSuperAdmin: boolean): boolean {
    return isAdmin || isSuperAdmin;
  }

  private initializeIntervalsIfEmpty(request: MembersBooleanSearchRequest): DistanceTravelRequest {
    if (!request.distanceTravelled) {
      return (request.distanceTravelled = { intervals: [] });
    } else {
      return request.distanceTravelled;
    }
  }

  private formatDate(date: Date): string {
    const dateString = date.toString();
    const timezone = dateString.toString().substr(dateString.indexOf('GMT') + 3, 5);
    return moment(date).utcOffset(timezone, true).format('YYYY-MM-DD');
  }

  private getExportedDateFormat(): string {
    const date = new Date();
    return date.getFullYear() + '_' + (date.getMonth() + 1) + '_' + date.getDate() + '_' + date.getHours() + '_' + date.getMinutes();
  }

  private getExportRequestData(): MembersBooleanSearchRequest {
    return { ...this.booleanSearchRequest };
  }

  importUsersCsv(): void {
    if ((this.isCardPage && this.cardPublished) || (this.isPlaylistPage && this.playlistPublished)) {
      this.ngZone.run(() => {
        this.importUsersDialogRef = this.dialog.open(ImportUsersDialogComponent, {
          width: '90vw',
          minWidth: '15.625rem',
          maxWidth: '46.875rem',
          maxHeight: '80vh',
          restoreFocus: true,
          position: {
            top: '10vh',
          },
          direction: LanguageCodeHelper.getBodyLanguageDir(),
          panelClass: 'ptl-mat-dialog',
          backdropClass: 'dialog-backdrop',
          data: {
            cardUid: this.cardUid,
            playlistUid: this.playlistUid,
            eventFinished: this.eventFinished,
            isEvent: this.isEvent,
            isCardPage: this.isCardPage,
            isPlaylistPage: this.isPlaylistPage,
          },
        });
        this.importUsersDialogRef.componentInstance.csvFileUploadFinished.subscribe((value) => this.csvFileUploadFinished.emit(value));
      });
    }
  }

  openCheckInDialog(): void {
    const start = this.resource.tickets ? this.resource.tickets.time.start : null;
    const end = this.resource.tickets ? this.resource.tickets.time.end : null;
    this.dialog.open(CheckInDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '46.875rem',
      maxHeight: '80vh',
      restoreFocus: true,
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
      data: {
        eventTitle: this.resource.content.headline,
        eventUrl: this.getEventUrl(),
        eventStartDate: start ? new Date(start.split(',')[0]) : null,
        eventEndDate: end ? new Date(end.split(',')[0]) : null,
      },
    });
  }

  get getSubmissionMode(): SubmissionMode {
    if (this.isPlaylistPage) {
      return this.playlistSubmissionMode;
    } else {
      return 'PROGRESSIVE';
    }
  }

  private getEventUrl(): string {
    const publisherUri = this.activatedRoute.snapshot.paramMap.get('publisherUri');
    const packageUri = this.activatedRoute.snapshot.paramMap.get('packageUri');
    const playlistUri = this.activatedRoute.snapshot.paramMap.get('playlistUri');
    const resourceUri = this.activatedRoute.snapshot.paramMap.get('resourceUri');
    const params = {
      publisherUri: publisherUri,
      packageUri: packageUri,
      playlistUri: playlistUri,
      resourceUri: resourceUri,
      pageNumberUri: 'page/1',
    };
    if (this.resource.participationCode) {
      params['queryUriParam'] = `?code=${this.resource.participationCode}`;
    }
    const eventUri = RedirectHelper.getRedirectUrl(this.activatedRoute, params, 'EVENT');
    return `https://${this.orgDomain}${eventUri}`;
  }

  getRemainingTickets(): string {
    return this.remainingTickets.toString();
  }

  openSendMessageDialog(): void {
    this.ngZone.run(() => {
      this.dialog.open(SendMessageDialogComponent, {
        width: '90vw',
        minWidth: '15.625rem',
        maxWidth: '46.875rem',
        maxHeight: '80vh',
        restoreFocus: true,
        position: {
          top: '10vh',
        },
        direction: LanguageCodeHelper.getBodyLanguageDir(),
        panelClass: 'ptl-mat-dialog',
        backdropClass: 'dialog-backdrop',
        data: {
          checkedMemberIds: this.getCheckedMembersIds(),
          query: this.booleanSearchRequest.query,
          allMembers: this.totalMembersCount === this.allMembersCount,
          filteredCount: this.totalMembersCount,
          playlistUid: this.playlistUid,
          cardUid: this.cardUid,
        },
      });
    });
  }
}
