/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectHelper } from '../../../../../resource/store/editor/content/helpers/redirect.helper';
import { EventCardsHelper } from 'src/app/shared/helpers/event-cards-helper';
import { UpcomingEventCard } from 'src/app/shared/models/event-card/event-card.model';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ptl-upcoming-event',
  templateUrl: './upcoming-event.component.html',
  styleUrls: ['./upcoming-event.component.scss'],
})
export class UpcomingEventComponent implements OnInit {
  @Input() event: UpcomingEventCard;
  @Input() index: number;
  @Input() openInNewTab: boolean;
  @ViewChild('eventBoxImage') private eventBoxImage: ElementRef;

  dateDescription: string;
  locationDescription: string;
  ticketsLeftLabel: 'ALMOST_FULL' | 'FULL';
  isPortraitImage = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.dateDescription = this.getDate();
    this.locationDescription = this.getLocation();
    const remainingTicketsPercentage = this.event?.remainingTicketsPercentage;
    if (remainingTicketsPercentage || remainingTicketsPercentage === 0) {
      if (remainingTicketsPercentage <= 0) {
        this.ticketsLeftLabel = 'FULL';
      } else if (remainingTicketsPercentage <= 20) {
        this.ticketsLeftLabel = 'ALMOST_FULL';
      }
    }
  }

  loadImg(): void {
    const img: HTMLImageElement = this.eventBoxImage.nativeElement;
    this.isPortraitImage = img.naturalHeight > img.naturalWidth;
  }

  getHeadline() {
    if (this.event) {
      return this.event.headline;
    }
    return undefined;
  }

  getDate() {
    if (this.event) {
      return EventCardsHelper.getEventFormattedDate(this.event.time);
    }
    return undefined;
  }

  getLocation() {
    if (this.event) {
      if (this.event.eventType === 'ONLINE') {
        return this.translocoService.translate('translations.upcomingEventsV2.onlineEventTypeName');
      } else if (this.event.location) {
        return this.event.location;
      }
    }
    return undefined;
  }

  redirectToEvent() {
    if (this.openInNewTab) {
      RedirectHelper.redirectByParams(
        this.ngZone,
        this.router,
        this.activatedRoute,
        {
          playlistUri: this.event.playlistUri,
          resourceUri: this.event.cardUri,
          pageNumberUri: 'page/1',
        },
        'RESOURCE',
        '_blank',
      );
      return;
    }

    RedirectHelper.redirectByParams(
      this.ngZone,
      this.router,
      this.activatedRoute,
      {
        playlistUri: this.event.playlistUri,
        resourceUri: this.event.cardUri,
        pageNumberUri: 'page/1',
      },
      'RESOURCE',
    );
  }
}
