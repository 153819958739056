import { Action, Selector, State, StateContext } from '@ngxs/store';
import { dataLoadedState, defaultLoadableState, errorState, LoadableState, loadingState } from '../../../../../shared/store';
import { Inject, Injectable, NgZone } from '@angular/core';
import { ResourceTag } from '../../../../../shared/models';
import * as MomentTagActions from './moment-tag.actions';
import { CORE_TAG_DATA_SERVICE, TagDataService } from '../../../../../shared/services/tags/tag-data.service';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TagErrorObject } from '../../../../playlist/models';
import { SnackbarHelper } from '../../../../../shared/helpers/snackbar-helper';

/** The moment tags related state. */
interface MomentTagsStateModel {
  /** The details of the tags which are filtered in create/update moment pages. */
  filteredTags: ResourceTag[];
  /** The details of the tags which should be added to moment after create/update action. */
  addTagState: LoadableState<ResourceTag>;
}

@State<MomentTagsStateModel>({
  name: 'momentTags',
  defaults: {
    filteredTags: [],
    addTagState: defaultLoadableState(),
  },
})
@Injectable()
export class MomentTagState {
  @Selector()
  static filteredTags({ filteredTags }: MomentTagsStateModel): ResourceTag[] {
    return filteredTags;
  }

  @Selector()
  static addTagState({ addTagState }: MomentTagsStateModel): LoadableState<ResourceTag> {
    return addTagState;
  }

  constructor(
    @Inject(CORE_TAG_DATA_SERVICE) private tagService: TagDataService,
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
  ) {}

  @Action(MomentTagActions.CreateTag)
  createTag({ getState, patchState }: StateContext<MomentTagsStateModel>, { title }: MomentTagActions.CreateTag) {
    patchState({
      addTagState: loadingState(),
    });

    const currentFilteredTags = getState().filteredTags;
    let foundTag = null;
    if (currentFilteredTags) {
      foundTag = currentFilteredTags.find((tag) => tag.title === title);
    }

    if (!foundTag) {
      return this.tagService.createTag({ title: title }).pipe(
        tap(({ isSuccess, value, error }) => {
          if (isSuccess) {
            patchState({
              addTagState: dataLoadedState(value),
            });
          } else {
            const errorObject: TagErrorObject = JSON.parse(error);

            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, errorObject.errorMessage);

            patchState({
              addTagState: errorState(errorObject.errorMessage),
            });
          }
        }),
      );
    } else {
      patchState({
        addTagState: dataLoadedState(foundTag),
      });
      return undefined;
    }
  }

  @Action(MomentTagActions.FindTagsByTitle)
  findTagsByTitle({ getState, patchState }: StateContext<MomentTagsStateModel>, { title }: MomentTagActions.FindTagsByTitle) {
    return this.tagService.findTagsWithTitleTerm(title).pipe(
      tap(({ isSuccess, value }) => {
        if (isSuccess) {
          patchState({
            filteredTags: value,
          });
        }
      }),
    );
  }
}
