<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  #htmlEl
  class="image-preview-element
  image-preview-element-{{ content?.type }}
  image-preview-element-position-{{ content?.position || 'CENTER' }}"
  *ngIf="content?.type === 'MEDIA_URL'"
></div>

<div
  class="image-preview-element
  image-preview-element-{{ content?.type }}
  image-preview-element-position-{{ content?.position || 'CENTER' }}"
  *ngIf="content?.type === 'MEDIA_UPLOAD'"
  role="banner"
  tabindex="0"
  [attr.aria-label]="
    !!uploadedImage
      ? (content?.caption ?? ('translations.global.ariaLabels.previewImage' | transloco))
      : ('translations.global.ariaLabels.uploadImage' | transloco)
  "
  [ngClass]="'image-preview-element--' + content?.type"
>
  <ptl-media-upload-image-preview [uploadedImage]="uploadedImage" [link]="content?.url" [caption]="content?.caption" (openUrl)="openUrl()">
  </ptl-media-upload-image-preview>
</div>
