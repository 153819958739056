import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SupportedLanguage } from '../../models/languages/languages.model';
import { AvailableLanguage } from '../../models';
import { DialogService } from '../../helpers/dialog/dialog.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'ptl-add-language-settings',
  templateUrl: './add-language-settings.component.html',
  styleUrls: ['./add-language-settings.component.scss'],
})
export class AddLanguageSettingsComponent {
  @Input() availableOrgLanguages: SupportedLanguage[] = [];
  @Input() availableLanguages: AvailableLanguage[] = [];
  @Input() activeLanguages: SupportedLanguage[] = [];
  @Input() showNewLanguages = false;
  @Input() skeletonViewActive: boolean;
  @Input() chosenLanguage = new FormControl();

  @Output() languageRemove = new EventEmitter<string>();
  @Output() toggleEnable = new EventEmitter<{ event: MatSlideToggleChange; languageCode: string }>();
  @Output() languageSave = new EventEmitter<string>();

  constructor(
    private translocoService: TranslocoService,
    private dialogService: DialogService,
  ) {}

  onLanguageSave() {
    this.languageSave.emit(this.chosenLanguage.value);
  }

  onLanguageRemove(languageCode: string) {
    this.dialogService
      .showConfirmDialog(this.translocoService.translate('translations.dialog.title.removeLanguage'), this.translocoService)
      .then((confirmed) => {
        if (confirmed) {
          this.languageRemove.emit(languageCode);
        }
      });
  }

  onToggleEnable(event: MatSlideToggleChange, languageCode: string) {
    this.toggleEnable.emit({ event: event, languageCode: languageCode });
  }

  checkIfIsEnabled(supportedLanguage: SupportedLanguage): boolean {
    if (supportedLanguage.isDefault) {
      return true;
    }

    return this.availableLanguages.some((lang) => lang.code === supportedLanguage.language.code && lang.enabled);
  }
}
