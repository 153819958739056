/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { Page } from '../../../shared/models/page';
import { ReviewerSummary, UserReviewsCount } from '../../../shared/models/review/review.model';
import { ObservableResult } from '../../../shared/store';
import { ReviewSettingsDetails } from '../../playlist/models/reviews-settings.model';
import {
  CommentRequest,
  ReviewAdminRequest,
  ReviewerAdminRequest,
  ReviewItem,
  ReviewRequest,
  ReviewSummaryDetailed,
  SubmissionsReviewRequest,
} from '../models';
import { ReviewsDataService } from './data.service';

@Injectable()
export class MockReviewsDataService implements ReviewsDataService {
  getReviews(page: number, pageSize: number): ObservableResult<Page<ReviewItem>> {
    return ObservableResult.ofSuccess();
  }

  getReviewItem(itemUri: string): ObservableResult<ReviewItem> {
    return ObservableResult.ofSuccess();
  }

  getReviewSummaryDetailed(reviewItemUri: string, learnerUserUid: string): ObservableResult<ReviewSummaryDetailed> {
    return ObservableResult.ofSuccess();
  }

  addComment(reviewUid: string, cardUid: string, formUid: string, request: CommentRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  editComment(reviewUid: string, noteUid: string, request: CommentRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  deleteComment(reviewUid: string, noteUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  rejectReview(reviewUid: string, request: ReviewRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  approveReview(reviewUid: string, request: ReviewRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  requestChangeOfReview(reviewUid: string, playlistUid: string, request: ReviewRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  requestResubmission(request: SubmissionsReviewRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  requestAllResubmission(reviewUid: string, request: SubmissionsReviewRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  acceptReviews(request: SubmissionsReviewRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  acceptAllReviews(reviewUid: string, request: SubmissionsReviewRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  rejectReviewRequest(request: SubmissionsReviewRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  rejectAllReviewRequest(reviewUid: string, request: SubmissionsReviewRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  closeReview(request: ReviewAdminRequest, isAccepted: boolean): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  reOpenReview(request: ReviewAdminRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateReviewers(reviewUid: string, request: ReviewerAdminRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  retrieveReviewers(reviewUid: string): ObservableResult<ReviewerSummary[]> {
    return ObservableResult.ofSuccess();
  }

  getUserReviewsCount(): ObservableResult<UserReviewsCount> {
    return ObservableResult.ofSuccess();
  }

  getReviewSettingsDetail(): ObservableResult<ReviewSettingsDetails> {
    return ObservableResult.ofSuccess();
  }

  undoReview(reviewUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  cancelDecision(reviewUid: string, reviewEventUid: string, commentForLearner?: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
