/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { EmbedCode, MediaUrlData, MediumEditorData } from '../../../models';
import { HtmlSanitizer } from '../../../helpers/sanitizer/html-sanitizer';

@Component({
  selector: 'ptl-resource-html-preview',
  templateUrl: './html-preview.component.html',
  styleUrls: ['./html-preview.component.scss', './html-preview-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceHtmlPreviewComponent implements OnChanges {
  /** Receives the EditorContent object */
  @Input() content: Pick<MediaUrlData, 'position' | 'type'> | undefined;
  @ViewChild('htmlEl', { static: true }) public htmlEl?: ElementRef;
  hidePreview = false;
  isFocusable = false;

  ngOnChanges(changes: SimpleChanges) {
    this.htmlEl.nativeElement.innerHTML = '';
    this.content = changes.content.currentValue;
    this.hidePreview = false;
    if (this.content) {
      if (this.content.type === 'MEDIA_URL') {
        this.parseAndAppendHtml((this.content as MediaUrlData).contentHtml);
      } else if (this.content.type === 'PARAGRAPH') {
        this.parseAndAppendHtml((this.content as MediumEditorData).content);
      } else if (this.content.type === 'SEPARATOR') {
        this.parseAndAppendHtml((this.content as MediumEditorData).content);
      } else if (this.content.type === 'EMBED_CODE') {
        this.parseAndAppendHtml(HtmlSanitizer.sanitizeEmbedCode((this.content as EmbedCode).code));
      } else if (this.content.type === 'TABLE') {
        this.parseAndAppendHtml((this.content as MediumEditorData).content);
      } else {
        this.hidePreview = true;
      }
    } else {
      this.hidePreview = true;
    }
  }

  private parseAndAppendHtml(html: string) {
    if (html && !!html.length && this.htmlEl) {
      // createContextualFragment enables the execution of <script> if present in our html
      const fragment = document.createRange().createContextualFragment(html);
      this.htmlEl.nativeElement.appendChild(fragment);
      this.isFocusable = true;
    } else {
      this.hidePreview = true;
    }
  }
}
