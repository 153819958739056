import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { Form, FormContent, OptionOrder, PresentationMode, TextBoxFormContent } from '@app/app/shared/models';
import { QuestionFormList, SubListItem } from '@app/app/shared/models/lists/list.model';
import { SHARED_LISTS_DATA_SERVICE, SharedListsDataService } from '@app/app/shared/services/lists/lists-data.service';
import { TranslocoService } from '@ngneat/transloco';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { SnackbarHelper } from '@app/app/shared/helpers/snackbar-helper';
import { Result } from '@app/app/shared/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, takeUntil } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { canTriggerSearch } from 'src/app/shared/helpers/content-helper';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'ptl-form-question',
  templateUrl: './form-question.component.html',
  styleUrls: ['../form.component.scss', './form-question.component.scss'],
})
export class FormQuestionComponent implements OnInit, AfterViewInit, OnDestroy {
  private _form: Form<
    FormContent & {
      presentationMode: string;
      optionOrder: string;
      maxSelection: number;
      optionsListUid: string;
    }
  >;

  private _canRemoveItem: boolean | undefined;
  private _position: number | string | undefined;

  @Input()
  set form(value) {
    if (typeof value === 'string') {
      this._form = JSON.parse(decodeURIComponent(value));
    } else {
      this._form = value;
    }
  }

  get form() {
    return this._form;
  }

  /** Position in the Form sections */
  @Input()
  set position(value: number | string | undefined) {
    if (typeof value === 'string') {
      this._position = JSON.parse(decodeURIComponent(value));
    } else {
      this._position = value;
    }
  }

  get position() {
    return this._position;
  }

  @Input()
  set canRemoveItem(value) {
    if (typeof value === 'string') {
      this._canRemoveItem = JSON.parse(decodeURIComponent(value));
    } else {
      this._canRemoveItem = value;
    }
  }

  get canRemoveItem() {
    return this._canRemoveItem;
  }

  @ViewChild('subjectInput') private subjectInput: ElementRef;
  @ViewChild('formTitleInput') private formTitleInput: ElementRef<HTMLInputElement>;

  /** Emits content data on saveForm() */
  @Output() formElementAdded: EventEmitter<Form> = new EventEmitter<Form>();

  /** Emits removing event of this form with index */
  @Output() formElementRemoved: EventEmitter<void> = new EventEmitter<void>();

  hasError = false;
  saveInProgress = false;
  saveFormInProgress = false;
  isFormDirty = false;
  isListNameDirty = false;
  formQuestionList = [];
  questionForm: FormGroup;
  optionsListUid: string;
  listTitle = '';
  presentationMode: string;
  newOptionText: string;
  expanded = true;
  forceExpand = false;

  rootUid: string;
  formLists: QuestionFormList[];

  private subscriptionEnd$: EventEmitter<void> = new EventEmitter<void>();
  private searchInputSubject$ = new Subject<string>();
  protected readonly PresentationMode = PresentationMode;
  protected readonly OptionOrder = OptionOrder;

  constructor(
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private translocoService: TranslocoService,
    @Inject(SHARED_LISTS_DATA_SERVICE) private sharedListsDataService: SharedListsDataService,
  ) {
    this.questionForm = this.formBuilder.group({
      uid: '',
      title: ['', [Validators.required]],
      presentationMode: ['CHECKBOX', [Validators.required]],
      answerType: ['RICH', [Validators.required]],
      optionOrder: [OptionOrder.DOCUMENT, [Validators.required]],
      optionsListUid: [''],
      maxSelection: [1, [Validators.required]],
    });

    this.searchInputSubject$
      .pipe(takeUntil(this.subscriptionEnd$), debounceTime(300))
      .subscribe((searchTerm) => this.getListName(searchTerm));
  }

  ngOnInit() {
    this.presentationMode = this.form?.content?.presentationMode || PresentationMode.CHECKBOX;
    this.setFormContent(this.form);
    this.optionsListUid = this.form?.content?.['optionsListUid'];

    if (this.optionsListUid) {
      this.expanded = false;
      this.forceExpand = false;
      this.getFormListByOptionsUid(this.optionsListUid);
    }

    this.questionForm.valueChanges.pipe(takeUntil(this.subscriptionEnd$)).subscribe(() => {
      this.isFormDirty = true;
    });
    this.forceExpand = this.form?.content?.forceExpand;
  }

  ngAfterViewInit(): void {
    const titleInput = this.formTitleInput?.nativeElement;
    if (this.forceExpand && titleInput) {
      setTimeout(() => {
        const titleLength = titleInput.value.length;
        titleInput.focus();
        titleInput.setSelectionRange(titleLength, titleLength);
      });
    }
  }

  onListNameInput(event: KeyboardEvent) {
    if (canTriggerSearch(event)) {
      this.isListNameDirty = true;
      this.searchInputSubject$.next(event.target['value']);
    }
  }

  getListName(searchTerm: string) {
    this.sharedListsDataService.searchListsByTerm(searchTerm).subscribe((questionFormData: Result<QuestionFormList>) => {
      if (questionFormData.isSuccess) {
        this.formLists = questionFormData.value['content'];
      }
    });
    this.listTitle = searchTerm;
  }

  getSelectedList(listItem: MatAutocompleteSelectedEvent) {
    this.getListData(listItem.option.value);
  }

  onAddItem(value: string) {
    this.newOptionText = '';
    const trimmedValue = value.trim();

    if (!trimmedValue) {
      return;
    }

    this.newOptionText = trimmedValue;

    const parentUid = this.rootUid;
    this.sharedListsDataService
      .addElementToFormQuestionList(this.optionsListUid, {
        parentUid,
        value: trimmedValue,
      })
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe((res) => {
        this.handleAddItemResponse(res as { isSuccess: boolean; value: QuestionFormList });
        this.isFormDirty = true;
      });

    this.subjectInput.nativeElement.value = '';
  }

  onAddList(text: string) {
    this.saveInProgress = true;
    const trimmedText = text.trim();
    if (trimmedText.length === 0) {
      return;
    }

    if (this.optionsListUid) {
      this.updateListTitle(this.optionsListUid, { title: trimmedText });
    } else {
      this.createFormQuestionTextListAndSubscribe(trimmedText);
    }
  }

  optionTrackBy(option) {
    return option.uid;
  }

  removeOption(index: number, uid: string) {
    this.sharedListsDataService
      .deleteFormQuestionListElement(this.optionsListUid, uid)
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe((res) => {
        if (res.isSuccess) {
          this.formQuestionList.splice(index, 1);
          this.showSnackBarMessage('formOptionRemoved');
        }
      });
  }

  saveForm() {
    this.saveFormInProgress = true;
    const updateTitleData = { title: this.listTitle };

    this.sharedListsDataService
      .updateFormQuestionListTitle(this.optionsListUid, updateTitleData)
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe((res) => {
        this.handleUpdateListTitleResponse(res);
        this.handleSaveFormResponse();
        this.saveFormInProgress = false;
        this.showSnackBarMessage('formSaved');
      });
  }

  outputData() {
    const outputData: Form = {
      ...this.form,
      newAddedForm: false,
      content: {
        ...this.questionForm.value,
        type: 'QUESTION_FORM',
        optionsListUid: this.optionsListUid,
      } as TextBoxFormContent,
    };

    this.formElementAdded.emit(outputData);
  }

  expandForm() {
    this.expanded = true;
  }

  collapseForm(event: PointerEvent) {
    event.stopPropagation();
    this.expanded = false;
    this.forceExpand = false;
  }

  getSelectedPresentationMode(event: MatRadioChange) {
    this.presentationMode = event.value;
    this.questionForm.patchValue({ presentationMode: event.value });

    if (event.value === PresentationMode.RADIO) {
      this.questionForm.patchValue({ maxSelection: 1 });
    }
  }

  getMaxSelection(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      const inputValue: string = event.target.value;
      this.questionForm.patchValue({ maxSelection: inputValue });
    }
  }

  removeForm() {
    this.formElementRemoved.emit();
  }

  ngOnDestroy() {
    this.subscriptionEnd$?.emit();
  }

  setAsTouched(item: SubListItem) {
    item.isTouched = true;
  }

  updateOption(item: SubListItem, value: string) {
    this.sharedListsDataService
      .updateFormQuestionListElementByElementUid(this.optionsListUid, item.uid, { value })
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe({
        next: (res) => {
          this.handleFormListResponse(res);
          this.showSnackBarMessage('formSaved');
        },
        error: (error) => this.handleFormListError(error),
      });
  }

  private handleAddItemResponse(res: Result<QuestionFormList>) {
    this.formQuestionList = res.value.root.sublist.map((item: SubListItem) => ({ ...item, isTouched: false }));

    this.questionForm.patchValue({
      optionsListUid: [res.value['_id']],
    });
  }

  private getFormListByOptionsUid(optionsListUid: string) {
    this.sharedListsDataService
      .getFormListByOptionUid(optionsListUid)
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe({
        next: (res) => this.handleFormListResponse(res),
        error: (error) => this.handleFormListError(error),
      });
  }

  private handleFormListResponse(res: Result<QuestionFormList>) {
    if (res.isSuccess) {
      this.getListData(res.value);
      this.isFormDirty = true;
    }
  }

  getOptionText(listItem: QuestionFormList) {
    const titleData = LanguageCodeHelper.getDataByLanguageCode(listItem.title);
    return titleData.value;
  }

  private getListData(value: QuestionFormList) {
    const { root, title } = value;
    this.optionsListUid = value._id;
    this.rootUid = root.uid;
    this.formQuestionList = root.sublist;
    const languageData = LanguageCodeHelper.getDataByLanguageCode(title);
    this.listTitle = languageData.value;
    this.isFormDirty = true;
  }

  private handleFormListError(error: Result<{ isSucess: false }>) {
    console.log(error);
  }

  private updateListTitle(optionsListUid: string, data: { title: string }) {
    this.saveInProgress = true;
    this.sharedListsDataService
      .updateFormQuestionListTitle(optionsListUid, data)
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe((res: Result<QuestionFormList>) => {
        this.handleUpdateListTitleResponse(res);
        this.saveInProgress = false;
        this.showSnackBarMessage('formListNameSaved');
      });
  }

  private createFormQuestionTextListAndSubscribe(text: string) {
    this.saveInProgress = true;
    this.sharedListsDataService
      .createFormQuestionTextList(text)
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe((response: Result<QuestionFormList>) => {
        const listUid = response.value._id;
        this.optionsListUid = listUid;
        this.rootUid = response.value.root.uid;
        this.questionForm.patchValue({
          optionsListUid: [listUid],
        });

        this.saveInProgress = false;
        this.showSnackBarMessage('formListNameSaved');
      });
  }

  private showSnackBarMessage(messageKey: string, panelClass: string = ''): void {
    const translationKey = `translations.editor.toolbarNew.option.questionForm.${messageKey}`;
    SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translocoService, translationKey, 1000, 'bottom', panelClass);
  }

  private handleUpdateListTitleResponse(res: Result<QuestionFormList>) {
    if (res.isSuccess) {
      this.listTitle = LanguageCodeHelper.getDataByLanguageCode(res.value.title).value;
    }
  }

  private handleSaveFormResponse() {
    this.hasError = !this.questionForm.valid;

    if (!this.hasError) {
      this.expanded = false;
      this.forceExpand = false;
      this.outputData();
    }
  }

  private setFormContent(form: Form) {
    this.expanded = form?.newAddedForm ?? false;
    this.questionForm.patchValue({
      ...form?.content,
      optionOrder: form?.content?.['optionOrder'] || OptionOrder.DOCUMENT,
    });
  }
}
