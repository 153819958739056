/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ImageUploadContent, MediaContent, MediaUploadData, SingleUrlContent, StreamVideoContent } from '../../../../models';

@Component({
  selector: 'ptl-media-uploads',
  templateUrl: './media-uploads.component.html',
  styleUrls: ['./media-uploads.component.scss'],
})
export class MediaUploadsComponent implements OnChanges {
  private _mediaUploadData: MediaUploadData | undefined;
  private _canRemoveItem = true;

  @Input()
  set mediaUploadData(value: MediaUploadData | undefined) {
    if (typeof value === 'string') {
      this._mediaUploadData = JSON.parse(decodeURIComponent(value));
    } else {
      this._mediaUploadData = value;
    }
  }

  get mediaUploadData() {
    return this._mediaUploadData;
  }

  @Input()
  set canRemoveItem(value) {
    if (typeof value === 'string') {
      this._canRemoveItem = JSON.parse(decodeURIComponent(value));
    } else {
      this._canRemoveItem = value;
    }
  }

  get canRemoveItem() {
    return this._canRemoveItem;
  }

  @Input() newEditor = false;
  @Input() bytePlaceholder = false;

  @Output() removeMediaContent = new EventEmitter<void>();
  @Output() updateMediaPosition = new EventEmitter<string>();
  @Output() updateMediaCaption = new EventEmitter<string>();
  @Output() updateMediaHyperlink = new EventEmitter<{ url: string; openUrlInTab: boolean }>();
  @Output() fileUploadFinished = new EventEmitter<boolean>();

  @Output() mediaContentReplaced = new EventEmitter<MediaContent>();
  @Output() regularLinkReplaced = new EventEmitter<string>();

  isProgressBarVisible = false;
  mediaUploadSrc: string;
  showByteContent = false;

  ngOnChanges(): void {
    let mediaUrlSet = false;
    if ((this.bytePlaceholder || !this.newEditor) && this.mediaUploadData?.file) {
      this.showByteContent = this.bytePlaceholder;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.mediaUploadSrc = (event.target as FileReader).result as string;
      };
      reader.readAsDataURL(this.mediaUploadData.file);
      mediaUrlSet = true;
      this.isProgressBarVisible = true;
    } else if (this.newEditor && this.mediaUploadData?.['hasFile']) {
      this.isProgressBarVisible = true;
    }

    if (!mediaUrlSet) {
      this.mediaUploadSrc = this.getMediaUploadSrc();
    }
  }

  contentUploadProgress(progress: number): void {
    if (progress === 100) {
      setTimeout(() => {
        this.isProgressBarVisible = false;
        this.fileUploadFinished.emit(true);
      }, 500);
    }
  }

  removeMedia(): void {
    this.removeMediaContent.emit();
  }

  onUpdateMediaPosition(position: string): void {
    this.updateMediaPosition.emit(position);
  }

  onReplaceMediaContent(media: MediaContent): void {
    this.mediaContentReplaced.emit(media);
  }

  onReplaceRegularLink(link: string): void {
    this.regularLinkReplaced.emit(link);
  }

  onUpdateMediaCaption(caption: string): void {
    this.updateMediaCaption.emit(caption);
  }

  onUpdateMediaHyperlink(form: { url: string; openUrlInTab: boolean }): void {
    this.updateMediaHyperlink.emit(form);
  }

  get getIsProgressBarVisible(): boolean {
    return this.bytePlaceholder ? false : this.isProgressBarVisible;
  }

  private getMediaUploadSrc(): string | null {
    if (!this.mediaUploadData?.content) return null;

    const mediaUploadContent = this.mediaUploadData.content;
    switch (mediaUploadContent.type) {
      case 'IMAGE_UPLOAD_CONTENT': {
        const imageUploadContent = mediaUploadContent as ImageUploadContent;
        switch (this.mediaUploadData.position) {
          case 'FULL_WIDTH':
            return imageUploadContent.extraLarge;
          case 'CENTER':
          case 'LEFT':
          case 'RIGHT':
          default:
            return imageUploadContent.original;
        }
      }
      case 'SINGLE_URL_CONTENT':
      case 'STREAM_VIDEO_CONTENT_DETAILS': {
        const urlUploadContent = mediaUploadContent as SingleUrlContent | StreamVideoContent;
        return urlUploadContent.url;
      }
    }
  }
}
