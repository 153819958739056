/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface FAQ {
  question: string;
  answer: string;
  open: boolean;
  types?: AnalyticsHelpType[];
}

interface MetricHelpItem {
  label: string;
  description: string;
  types?: AnalyticsHelpType[];
}

export enum AnalyticsHelpType {
  PLAYLIST_ANALYTICS = 'PLAYLIST_ANALYTICS',
  PAGE_ANALYTICS = 'PAGE_ANALYTICS',
  ASSESSMENT_ANALYTICS = 'ASSESSMENT_ANALYTICS',
}

@Component({
  selector: 'ptl-analytics-help',
  templateUrl: './analytics-help.component.html',
  styleUrls: ['./analytics-help.component.scss'],
})
export class AnalyticsHelpComponent implements OnInit {
  faqs: FAQ[] = [];
  filteredFaqs: FAQ[] = [];
  metrics: MetricHelpItem[] = [];
  filteredMetrics: MetricHelpItem[] = [];
  analyticsHelpType: AnalyticsHelpType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { analyticsHelpType: AnalyticsHelpType.PLAYLIST_ANALYTICS },
    private translocoService: TranslocoService,
    private dialogRef: MatDialogRef<AnalyticsHelpComponent>,
  ) {
    this.analyticsHelpType = data.analyticsHelpType;
  }

  ngOnInit() {
    this.loadAnalyticsData();
    this.filterFaqs();
    this.filterMetrics();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  loadAnalyticsData() {
    this.faqs = [
      {
        question: this.translocoService.translate('translations.analytics.help.membersCountQuestion'),
        answer: this.translocoService.translate('translations.analytics.help.membersCountAnswer'),
        open: false,
        types: [AnalyticsHelpType.PAGE_ANALYTICS, AnalyticsHelpType.PLAYLIST_ANALYTICS],
      },
      {
        question: this.translocoService.translate('translations.analytics.help.startedUserCourseQuestion'),
        answer: this.translocoService.translate('translations.analytics.help.startedUserCourseAnswer'),
        open: false,
        types: [AnalyticsHelpType.PAGE_ANALYTICS, AnalyticsHelpType.PLAYLIST_ANALYTICS],
      },
      {
        question: this.translocoService.translate('translations.analytics.help.completedUserCourseQuestion'),
        answer: this.translocoService.translate('translations.analytics.help.completedUserCourseAnswer'),
        open: false,
        types: [AnalyticsHelpType.PAGE_ANALYTICS, AnalyticsHelpType.PLAYLIST_ANALYTICS],
      },
      {
        question: this.translocoService.translate('translations.analytics.help.courseEnrollmentsVsEventRegistrationsQuestion'),
        answer: this.translocoService.translate('translations.analytics.help.courseEnrollmentsVsEventRegistrationsAnswer'),
        open: false,
        types: [AnalyticsHelpType.PLAYLIST_ANALYTICS],
      },
      {
        question: this.translocoService.translate('translations.analytics.help.engagementEvaluationQuestion'),
        answer: this.translocoService.translate('translations.analytics.help.engagementEvaluationAnswer'),
        open: false,
        types: [AnalyticsHelpType.PAGE_ANALYTICS, AnalyticsHelpType.PLAYLIST_ANALYTICS],
      },
      {
        question: this.translocoService.translate('translations.analytics.help.howToExport'),
        answer: this.translocoService.translate('translations.analytics.help.howToExportAnswer'),
        open: false,
        types: [AnalyticsHelpType.PAGE_ANALYTICS, AnalyticsHelpType.PLAYLIST_ANALYTICS],
      },
      {
        question: this.translocoService.translate('translations.analytics.help.availableFiltersQuestion'),
        answer: this.translocoService.translate('translations.analytics.help.availableFiltersAnswer'),
        open: false,
        types: [AnalyticsHelpType.PLAYLIST_ANALYTICS],
      },
      {
        question: this.translocoService.translate('translations.analytics.help.pageAvailableFiltersQuestion'),
        answer: this.translocoService.translate('translations.analytics.help.pageAvailableFiltersAnswer'),
        open: false,
        types: [AnalyticsHelpType.PAGE_ANALYTICS],
      },
      {
        question: this.translocoService.translate('translations.analytics.help.assessmentResultsQuestion'),
        answer: this.translocoService.translate('translations.analytics.help.assessmentResultsAnswer'),
        open: false,
        types: [AnalyticsHelpType.ASSESSMENT_ANALYTICS],
      },
      {
        question: this.translocoService.translate('translations.analytics.help.assessmentAverageScoresQuestion'),
        answer: this.translocoService.translate('translations.analytics.help.assessmentAverageScoresAnswer'),
        open: false,
        types: [AnalyticsHelpType.ASSESSMENT_ANALYTICS],
      },
      {
        question: this.translocoService.translate('translations.analytics.help.assessmentDatapointQuestion'),
        answer: this.translocoService.translate('translations.analytics.help.assessmentDatapointAnswer'),
        open: false,
        types: [AnalyticsHelpType.ASSESSMENT_ANALYTICS],
      },
      {
        question: this.translocoService.translate('translations.analytics.help.dataNotUpdatingQuestion'),
        answer: this.translocoService.translate('translations.analytics.help.dataNotUpdatingAnswer'),
        open: false,
        types: [AnalyticsHelpType.PAGE_ANALYTICS, AnalyticsHelpType.PLAYLIST_ANALYTICS, AnalyticsHelpType.ASSESSMENT_ANALYTICS],
      },
      {
        question: this.translocoService.translate('translations.analytics.help.dateRangeFilterQuestion'),
        answer: this.translocoService.translate('translations.analytics.help.dateRangeFilterAnswer'),
        open: false,
        types: [AnalyticsHelpType.PAGE_ANALYTICS, AnalyticsHelpType.PLAYLIST_ANALYTICS, AnalyticsHelpType.ASSESSMENT_ANALYTICS],
      },
    ];

    this.metrics = [
      {
        label: this.translocoService.translate('translations.members.title'),
        description: this.translocoService.translate('translations.analytics.help.membersCountDescription'),
        types: [AnalyticsHelpType.PAGE_ANALYTICS, AnalyticsHelpType.PLAYLIST_ANALYTICS],
      },
      {
        label: this.translocoService.translate('translations.analytics.metric.startedUserCourses'),
        description: this.translocoService.translate('translations.analytics.help.startedUserCoursesDescription'),
        types: [AnalyticsHelpType.PAGE_ANALYTICS, AnalyticsHelpType.PLAYLIST_ANALYTICS],
      },
      {
        label: this.translocoService.translate('translations.analytics.metric.completedUserCourses'),
        description: this.translocoService.translate('translations.analytics.help.completedUserCoursesDescription'),
        types: [AnalyticsHelpType.PAGE_ANALYTICS, AnalyticsHelpType.PLAYLIST_ANALYTICS],
      },
      {
        label: this.translocoService.translate('translations.analytics.metric.cards'),
        description: this.translocoService.translate('translations.analytics.help.cardsMetricDescription'),
        types: [AnalyticsHelpType.PAGE_ANALYTICS],
      },
      {
        label: this.translocoService.translate('translations.events'),
        description: this.translocoService.translate('translations.analytics.help.eventsMetricDescription'),
        types: [AnalyticsHelpType.PAGE_ANALYTICS],
      },
      {
        label: this.translocoService.translate('translations.analytics.metric.registrations'),
        description: this.translocoService.translate('translations.analytics.help.registrationsMetricDescription'),
        types: [AnalyticsHelpType.PAGE_ANALYTICS],
      },
      {
        label: this.translocoService.translate('translations.analytics.metric.participated'),
        description: this.translocoService.translate('translations.analytics.help.participatedMetricDescription'),
        types: [AnalyticsHelpType.PAGE_ANALYTICS],
      },
      {
        label: this.translocoService.translate('translations.analytics.metric.waitlisted'),
        description: this.translocoService.translate('translations.analytics.help.waitlistedMetricDescription'),
        types: [AnalyticsHelpType.PAGE_ANALYTICS],
      },
    ];
  }

  filterFaqs() {
    this.filteredFaqs = this.faqs.filter((faq) => !faq.types || faq.types.includes(this.analyticsHelpType));
  }

  filterMetrics() {
    this.filteredMetrics = this.metrics.filter((metric) => !metric.types || metric.types.includes(this.analyticsHelpType));
  }

  toggleFAQ(index: number) {
    this.filteredFaqs[index].open = !this.filteredFaqs[index].open;
  }
}
