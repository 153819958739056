<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="!!card">
  <mat-card
    class="card-box is-playlist"
    [class.not-visible]="type === 'FOLIO_PROJECT' && !projectVisible"
    [class.disabled]="disabled"
    (click)="onCardClick()"
    (keydown.enter)="onCardClick()"
    [attr.aria-label]="'translations.global.ariaLabels.open' | transloco: { title: card?.header }"
    tabindex="0"
    role="region"
    (contextmenu)="onContextMenu($event)"
  >
    <div
      class="context-menu"
      [ngStyle]="{ left: contextMenuPosition.x, top: contextMenuPosition.y }"
      [matMenuTriggerFor]="contextMenu"
    ></div>
    <mat-menu #contextMenu="matMenu">
      <ng-template matMenuContent let-item="item">
        <button
          mat-menu-item
          (click)="onOpenInNewTab()"
          tabindex="-1"
          [attr.aria-label]="'translations.global.ariaLabels.openInANewTab' | transloco"
        >
          <span>{{ 'translations.global.button.openInANewTab' | transloco }}</span>
        </button>
      </ng-template>
    </mat-menu>

    <button
      *ngIf="type === 'EDIT' && !disabled"
      mat-icon-button
      class="remove-btn"
      [title]="'translations.global.button.remove' | transloco"
      (click)="onRemoveClick()"
    >
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>

    <div class="card-box-image-box">
      <ng-container *ngIf="card.imageUrl || card.thumbnail || card.image; else placeholderImage">
        <img
          class="card-box-image"
          alt=""
          width="270"
          height="150"
          [src]="card.imageUrl ? card.imageUrl : card.thumbnail ? card.thumbnail : card.image ? card.image : card.imageUrl.medium"
        />
      </ng-container>
      <ng-template #placeholderImage>
        <img
          class="card-box-image card-box-image-placeholder"
          alt=""
          width="270"
          height="150"
          [src]="'/assets/card-placeholders/card-placeholder-' + ((index % 4) + 1) + '.png'"
        />
      </ng-template>

      <div class="card-box-author-box">
        <mat-icon
          class="dummy-icon"
          aria-hidden="true"
          *ngIf="!card.authorDetails?.displayConfig?.organizationName && !card.authorDetails?.displayConfig?.authorName"
        >
          insert_drive_file_outline
        </mat-icon>
        <ng-container *ngIf="card.authorDetails?.displayConfig?.organizationName || card.authorDetails?.displayConfig?.authorName">
          <img
            class="author-image"
            alt=""
            width="50"
            height="50"
            [src]="card.authorDetails?.displayConfig?.organizationName ? card.authorDetails.orgLogoUrl : card.authorDetails.userImageUrl"
            *ngIf="
              (card.authorDetails.userImageUrl && card.authorDetails?.displayConfig?.authorName) ||
              card.authorDetails?.displayConfig?.organizationName
            "
          />
          <div
            tabindex="0"
            [attr.aria-label]="card.authorDetails.name"
            [title]="card.authorDetails.name"
            class="author-initials"
            *ngIf="
              !card.authorDetails.userImageUrl &&
              card.authorDetails?.displayConfig?.authorName &&
              !card.authorDetails?.displayConfig?.organizationName
            "
          >
            {{ card.authorDetails.name.charAt(0) }}
          </div>
        </ng-container>
      </div>

      <div class="card-labels-box">
        <div class="label-item required extra-small" *ngIf="card?.required">
          {{ 'translations.global.label.mandatory' | transloco }}
        </div>
        <div
          class="label-item unpublished extra-small"
          *ngIf="cardStatus && cardStatus !== 'PUBLISHED'"
          role="status"
          [attr.aria-label]="'translations.global.ariaLabels.cardStatusUnpublished' | transloco: { cardName: card?.header }"
        >
          {{ 'translations.cardStatusUnpublished' | transloco }}
        </div>
      </div>
    </div>

    <div class="card-box-info-box">
      <div class="card-box-title small bold">
        {{ cardTitle }}
      </div>

      <div class="card-box-description extra-small" *ngIf="cardSubheader">
        {{ cardSubheader }}
      </div>

      <div
        role="note"
        class="card-box-event-title card-box-deadline extra-small"
        title="{{ 'translations.global.label.deadline' | transloco }} {{ card.deadline | dateFormat: 'D MMMM yyyy' }}"
        *ngIf="card?.deadline && !card.completed && completedCount < totalCount"
      >
        <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.calendar' | transloco">
          <use ogSvgUnify="assets/sprite/sprite.svg#calendar"></use>
        </svg>
        {{ 'translations.global.label.deadline' | transloco }} {{ card.deadline | dateFormat: 'D MMMM yyyy' }}
      </div>
    </div>

    <div class="card-box-footer-box">
      <div class="card-box-progress-box" *ngIf="!(isProject || isPublicProjectView || type === 'FOLIO_PROJECT')">
        <ptl-card-progress-circle
          [index]="index"
          [showOrder]="false"
          [completed]="card?.completed"
          [started]="card?.started"
          [completedCount]="completedCount"
          [totalCount]="totalCount"
        >
        </ptl-card-progress-circle>
      </div>

      <div class="card-box-buttons">
        <div *ngIf="type === 'FOLIO_PROJECT' && isFolioEditMode">
          <button (click)="$event.stopPropagation(); onProjectVisibilityChanged()" mat-icon-button class="visibility-icon">
            <svg
              class="svg"
              role="img"
              *ngIf="!projectVisible"
              [attr.aria-label]="'translations.global.ariaLabels.icons.openedEye' | transloco"
            >
              <use ogSvgUnify="assets/sprite/sprite.svg#visibility-off"></use>
            </svg>
            <svg
              class="svg"
              role="img"
              *ngIf="projectVisible"
              [attr.aria-label]="'translations.global.ariaLabels.icons.closedEye' | transloco"
            >
              <use ogSvgUnify="assets/sprite/sprite.svg#visibility"></use>
            </svg>
          </button>
        </div>

        <div *ngIf="!(type === 'FOLIO_PROJECT' && isFolioEditMode)">
          <div class="notes-icon" *ngIf="card?.notes">
            <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.comment' | transloco">
              <use ogSvgUnify="assets/sprite/sprite.svg#comment"></use>
            </svg>
            <span class="note-count extra-small">{{ card.notes }}</span>
          </div>

          <ptl-like-info-new
            *ngIf="!(isProject || isPublicProjectView || type === 'FOLIO_PROJECT')"
            class="card-box__like-info"
            [likeDetails]="card?.likes"
            [infoType]="'PLAYLIST'"
            [title]="cardTitle"
            [uid]="card.playlistId ?? card.id ?? card._id"
            [editMode]="inHomeLayoutEditor"
          >
          </ptl-like-info-new>
        </div>
      </div>
    </div>
  </mat-card>
</ng-container>
