<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<button
  *ngIf="!addSomeoneManuallySearchEnabled"
  class="add-someone-manually-btn"
  [disabled]="addUserManuallyButtonDisabled"
  [class.is_disabled_tooltip]="addUserManuallyButtonDisabled"
  [matTooltip]="addUserManuallyDisabledText"
  [attr.aria-label]="'translations.global.ariaLabels.addUserManually' | transloco"
  type="button"
  (click)="addSomeoneManuallyEnabled(true)"
>
  <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.plus' | transloco">
    <use ogSvgUnify="assets/sprite/sprite.svg#plus"></use>
  </svg>
  <span class="small">{{ 'translations.membersNew.list.table.addUserManually' | transloco }}</span>
</button>

<div *ngIf="addSomeoneManuallySearchEnabled" class="add-someone-manually-search-field">
  <ptl-user-search-autocomplete-new
    #userSearchAutocomplete
    class="search-field"
    [loadingMembers]="loadingMembers"
    [members]="searchedMembers"
    (searchInputChanged)="onSearchInputChange($event)"
    (searchLoadedMore)="onSearchLoadingMore($event)"
    (memberSelected)="onMemberSelected($event)"
  >
  </ptl-user-search-autocomplete-new>
  <button
    mat-icon-button
    class="delete-button"
    [attr.aria-label]="'translations.global.ariaLabels.addUserManuallyHideSearch' | transloco"
    type="button"
    (click)="addSomeoneManuallyEnabled(false)"
  >
    <mat-icon aria-hidden="true">close</mat-icon>
  </button>
</div>
