/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Page } from '../../models/page';
import { LearnerPlaylistSummary } from '../../models/playlist/learner-playlist-summary.model';
import { ObservableResult } from '../../store';
import { LearnerPlaylistDataService } from './data.service';
import { LearnerPlaylistReportItem, SubmitPlaylistSummary } from '../../models/playlist/playlist-report.model';
import { FavoritePlaylistSummary, UserPlaylistSubmissionSummary } from '../../models';
import { createDummyFavoritePlaylistSummary } from '../../../page-modules/mock-utils';
import { ReviewerDetails } from '../../../page-modules/playlist/models';
import { UserSearch } from '@app/app/shared/models/admin/members.model';

/** Serves mock data. */
export class MockLearnerPlaylistDataService implements LearnerPlaylistDataService {
  addPlaylistToFavorites(playlistId: string) {
    return ObservableResult.ofSuccess();
  }
  removePlaylistFromFavorites(playlistId: string) {
    return ObservableResult.ofSuccess();
  }

  getRecentPlaylists(page: number, pageSize?: number): ObservableResult<Page<LearnerPlaylistSummary>> {
    return ObservableResult.ofSuccess();
  }

  getFavoritePlaylists(page: number, pageSize: number): ObservableResult<Page<FavoritePlaylistSummary>> {
    return ObservableResult.ofSuccess({
      currentPageNumber: 0,
      pageSize: 1,
      totalNumberOfElement: 1,
      content: [createDummyFavoritePlaylistSummary()],
    });
  }

  getReport(playlistId: string): ObservableResult<LearnerPlaylistReportItem[]> {
    return ObservableResult.ofSuccess();
  }

  getReportByUserUid(playlistId: string, userUid: string): ObservableResult<LearnerPlaylistReportItem[]> {
    return ObservableResult.ofSuccess();
  }

  getPlaylistSubmissionSummary(playlistId: string): ObservableResult<UserPlaylistSubmissionSummary> {
    return ObservableResult.ofSuccess();
  }

  submitPlaylistSummary(playlistId: string, request?: SubmitPlaylistSummary): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  resubmitPlaylist(playlistId: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  loadPlaylistReviewers(playlistId: string, page: number, size: number, term?: string): ObservableResult<Page<ReviewerDetails>> {
    return ObservableResult.ofSuccess({
      currentPageNumber: 0,
      pageSize: 1,
      totalNumberOfElement: 1,
      content: [],
    });
  }

  shareSubmissionWithMentor(playlistId: string, mentorUserUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  findMentorsWithSubmissionAccess(learnerPlaylistUid: string): ObservableResult<UserSearch[]> {
    return ObservableResult.ofSuccess();
  }

  removeMentorAccess(playlistId: string, mentorUserUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
