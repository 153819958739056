/*
 * Copyright (C) 2025 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Component } from '@angular/core';
import { AdminMembersComponent } from '@app/app/page-modules/admin/components/members/members.component';

@Component({
  selector: 'ptl-v3-admin-members',
  templateUrl: './v3-members.component.html',
  styleUrls: ['./v3-members.component.scss'],
})
export class V3AdminMembersComponent extends AdminMembersComponent {}
