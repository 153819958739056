/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { PlaylistCreationStateModel } from '../../playlist-creation.state.model';
import { EditorContent } from '@app/app/shared/models';
import { cloneDeep } from 'lodash-es';
import { PlaylistResourceModel } from '@app/app/page-modules/playlist/models/playlist-resource.model';
import { EditorBlockHelper } from '@app/app/shared/helpers/EditorBlockHelper';

export class PlaylistBlockHelper {
  static addBlockToSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    newBlock: EditorContent,
    sectionUid: string,
  ): void {
    this.ensureSectionExists(state.playlist.data, sectionUid);

    const sectionContent = this.getSectionContent(state.playlist.data, sectionUid);
    const updatedSectionContent = EditorBlockHelper.addBlockToDynamicContentArray(newBlock, sectionContent);

    this.patchSection(state, patchState, sectionUid, updatedSectionContent);
  }

  static updateBlockAtSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    updatedBlock: EditorContent,
    sectionUid: string,
  ): void {
    this.ensureSectionExists(state.playlist.data, sectionUid);

    const sectionContent = this.getSectionContent(state.playlist.data, sectionUid);
    const updatedSectionContent = EditorBlockHelper.updateBlockAtDynamicContentArray(updatedBlock, sectionContent);

    this.patchSection(state, patchState, sectionUid, updatedSectionContent);
  }

  static removeBlockFromSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    blockUid: string,
    sectionUid: string,
  ): void {
    this.ensureSectionExists(state.playlist.data, sectionUid);

    const sectionContent = this.getSectionContent(state.playlist.data, sectionUid);
    const updatedSectionContent = EditorBlockHelper.removeBlockAtDynamicContentArray(blockUid, sectionContent);

    this.patchSection(state, patchState, sectionUid, updatedSectionContent);
  }

  static blockBackendResponseAppliedAtSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    blocksUid: string[],
    sectionUid: string,
  ): void {
    this.ensureSectionExists(state.playlist.data, sectionUid);

    const sectionContent = this.getSectionContent(state.playlist.data, sectionUid);
    const updatedSectionContent = EditorBlockHelper.blockBackendResponseAppliedAtDynamicContent(blocksUid, sectionContent);

    this.patchSection(state, patchState, sectionUid, updatedSectionContent);
  }

  private static getSectionContent(playlist: PlaylistResourceModel, sectionUid: string): EditorContent[] {
    if (this.isMainSection(playlist, sectionUid)) {
      return playlist.mainSection.content;
    } else {
      const standardSectionIndex = playlist.standardSections.findIndex((it) => it.uid === sectionUid);
      if (standardSectionIndex === -1) throw new Error(`Standard section ${sectionUid} not found!`);
      return playlist.standardSections[standardSectionIndex].content;
    }
  }

  private static patchSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    sectionUid: string,
    updatedSectionContent: EditorContent[],
  ) {
    if (this.isMainSection(state.playlist.data, sectionUid)) this.patchMainSection(patchState, state, updatedSectionContent);
    else this.patchStandardSection(patchState, state, sectionUid, updatedSectionContent);
  }

  private static patchMainSection(
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    state: PlaylistCreationStateModel,
    updatedMainSectionContent: EditorContent[],
  ): void {
    patchState({
      playlist: {
        ...state.playlist,
        data: {
          ...state.playlist.data,
          mainSection: {
            ...state.playlist.data.mainSection,
            content: updatedMainSectionContent,
          },
          contentChanged: true,
        },
      },
    });
  }

  private static patchStandardSection(
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    state: PlaylistCreationStateModel,
    sectionUid: string,
    updatedStandardSectionContent: EditorContent[],
  ): void {
    const sectionIndex = state.playlist.data.standardSections.findIndex((it) => it.uid === sectionUid);
    if (sectionIndex === -1) throw new Error(`Standard section ${sectionUid} not found!`);
    const updatedStandardSections = cloneDeep(state.playlist.data.standardSections);
    updatedStandardSections[sectionIndex].content = updatedStandardSectionContent;

    patchState({
      playlist: {
        ...state.playlist,
        data: {
          ...state.playlist.data,
          standardSections: updatedStandardSections,
          contentChanged: true,
        },
      },
    });
  }

  private static ensureSectionExists(playlist: PlaylistResourceModel, sectionUid: string): void {
    const standardSectionsUids = playlist.standardSections.map((it) => it.uid);
    if (playlist.mainSection.uid !== sectionUid && !standardSectionsUids.includes(sectionUid)) {
      throw new Error(`Section ${sectionUid} not found on playlist!`);
    }
  }

  private static isMainSection(playlist: PlaylistResourceModel, sectionUid: string): boolean {
    return playlist.mainSection.uid === sectionUid;
  }
}
