import { Pipe, PipeTransform } from '@angular/core';
import { EditorContent } from '@app/app/shared/models';

@Pipe({
  name: 'isNonEmptyEditorContent',
})
export class IsNonEmptyEditorContentPipe implements PipeTransform {
  transform(value: EditorContent[]): boolean {
    return value && value.some((item) => !this.isEmptyText(item.content));
  }

  private isEmptyText(html: string): boolean {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    tempDiv.querySelectorAll('br').forEach((br) => br.remove());
    return tempDiv.textContent.trim() === '';
  }
}
