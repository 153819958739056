<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<mat-card class="skeleton__item-action-container">
  <div class="skeleton-top-container" [class.reversed]="!isNew">
    <div class="left-side">
      <mat-card-subtitle class="slide-row skeleton__content-text big"></mat-card-subtitle>
      <mat-card-subtitle class="slide-row skeleton__content-text big"></mat-card-subtitle>
    </div>

    <div class="right-side">
      <div class="skeleton__content-image"></div>
    </div>
  </div>
  <div class="skeleton__content-text-container">
    <ul class="skeleton__content-text-list">
      <li class="slide-row skeleton__content-text small"></li>
      <li class="slide-row skeleton__content-text big"></li>
      <li class="slide-row skeleton__content-text big"></li>
      <li class="slide-row skeleton__content-text big"></li>
    </ul>
  </div>
</mat-card>
