/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../../../shared/store';
import { InjectionToken } from '@angular/core';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const PLAYLIST_PUBLICATION_DATA_SERVICE = new InjectionToken<PublicationDataService>('[playlist-editor] PublicationDataService');

/** The service handling playlist publication operations. */
export interface PublicationDataService {
  /**
   * Publishes a playlist.
   *
   * @param playlistUid the UID of the playlist to publish
   */
  publishPlaylist(playlistUid: string): ObservableResult<void>;

  /**
   * Unpublishes a playlist.
   *
   * @param playlistUid the UID of the playlist to unpublish
   */
  unpublishPlaylist(playlistUid: string): ObservableResult<void>;
}
