/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { EditorContent, EditorContentRemovalEvent, EditorContentUpdateEvent, MediumEditorToolbarEvent } from '../../models';
import { EditorContentComponent } from './editor-content/editor-content.component';

@Component({
  selector: 'ptl-editor-content-view',
  templateUrl: './editor-content-view.component.html',
  styleUrls: ['./editor-content-view.component.scss'],
})
export class EditorContentViewComponent {
  /** Receives the content */
  @Input() content: EditorContent[];

  /** Receives the placeholder text */
  @Input() placeholder: string;

  @Input() editorOptions;
  @Input() disableAutoFocus: boolean;
  @Input() upload: boolean;
  @Input() toolbarOpen: boolean;
  @Input() canRemoveItem: boolean;
  @Input() emptyContentDisable: boolean;

  @Input() chartSectionUid: string;

  @Input() bytePlaceholder: boolean;

  /** Emits on content update. */
  @Output() contentUpdate = new EventEmitter<EditorContentUpdateEvent>();

  /** Emits on content removal. */
  @Output() contentRemoval = new EventEmitter<EditorContentRemovalEvent>();

  /** Emits data on new content from child components */
  @Output() contentCreated = new EventEmitter<EditorContent>();

  /** Emits data on new content from child components */
  @Output() contentUploaded = new EventEmitter<EditorContent>();

  /** Emits regular link replaced */
  @Output() regularLinkReplaced = new EventEmitter<string>();

  /** Emits editor-toolbar show/hide event */
  @Output() toolbarUpdate = new EventEmitter<MediumEditorToolbarEvent>();

  /** Outputs the various click and change events */
  @Output() inputFocus = new EventEmitter<void>();

  /** Outputs the various click and change events */
  @Output() inputBlur = new EventEmitter<void>();

  @ViewChild('editorContent') editorContent: EditorContentComponent;

  onContentRemoval(contentRemovalEvent: EditorContentRemovalEvent) {
    this.contentRemoval.emit(contentRemovalEvent);
  }

  onUpdateContentItem(contentUpdateEvent: EditorContentUpdateEvent) {
    this.contentUpdate.emit(contentUpdateEvent);
    this.addEmptyParagraphContent();
  }

  onToolbarUpdate(event: MediumEditorToolbarEvent) {
    this.toolbarUpdate.emit(event);
  }

  onUploadContent(event: EditorContent) {
    this.contentUploaded.emit(event);
  }

  onReplaceRegularLink(link: string) {
    this.regularLinkReplaced.emit(link);
  }

  onEmptyContentFocus() {
    if (!this.content || !this.content.length) {
      this.contentCreated.emit({ type: 'PARAGRAPH', content: '' } as EditorContent);
    }
  }

  addEmptyParagraphContent() {
    if (!this.content || !this.content.length || (!!this.content.length && this.content[this.content.length - 1].type !== 'PARAGRAPH')) {
      this.contentCreated.emit({ type: 'PARAGRAPH', content: '' } as EditorContent);
    }
  }

  onAddEmptyParagraphAtIndex(index: number) {
    if (this.content) {
      const nextIndex = index + 1;
      if (nextIndex < this.content.length && this.content[nextIndex].type === 'PARAGRAPH') {
        return;
      }

      this.contentCreated.emit({
        type: 'PARAGRAPH',
        content: '',
        index: nextIndex,
        forceIndex: true,
      } as EditorContent);
    }
  }

  insertLinkBeforeBreakpoint(link: string) {
    this.editorContent.insertLinkBeforeBreakpoint(link);
  }

  onInputFocus() {
    this.inputFocus.emit();
  }

  onInputBlur() {
    this.inputBlur.emit();
  }

  bubbleInsertSplit(closestParagraph: Element, contentUid: string) {
    this.editorContent.bubbleInsertSplit(closestParagraph, contentUid);
  }
}
