/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, Input, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { FrameworkTrackerSection, FrameworkTrackerSectionRequest } from '../../../../../shared/models/page/page.model';
import { ResourceTag } from '@app/app/shared/models';
import { ADMIN_DATA_SERVICE, AdminDataService } from '@app/app/page-modules/admin/services/data.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { AddFrameworkTagsDialogComponent } from '@app/app/shared/components/add-framework-tags-dialog/add-framework-tags-dialog.component';

@Component({
  selector: 'ptl-page-framework-tracker-section',
  templateUrl: './page-framework-tracker-section.component.html',
  styleUrls: ['./page-framework-tracker-section.component.scss'],
})
export class PageFrameworkTrackerSectionComponent implements OnInit, AfterViewInit {
  /** Section title. */
  @Input() title: string;
  @Input() section: FrameworkTrackerSection;
  @Input() pageId: string;
  @Input() edit: boolean;
  @Input() afterSectionUid: string;
  @Input() openAddFrameworkModal: boolean;

  @Output() closeNewSection = new EventEmitter<boolean>();

  viewInitialized = false;
  tags: ResourceTag[] = [];

  private sectionAutomaticSaveSubject$ = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    @Inject(ADMIN_DATA_SERVICE) private dataService: AdminDataService,
  ) {}

  ngOnInit(): void {
    if (this.section) {
      this.tags = this.section.learnerTags.map((value) => value.tag);
    }

    this.sectionAutomaticSaveSubject$.pipe(debounceTime(300)).subscribe(() => {
      this.onSubmit();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.viewInitialized = true;

      if (this.openAddFrameworkModal) {
        this.openFrameworkPanel();
        this.openAddFrameworkModal = false;
      }

      this.cd.detectChanges();
    }, 500);
  }

  generateColorStyle(index): string {
    const colors = ['red', 'green', 'blue', 'olive', 'orange', 'brown', 'magenta', 'purple', 'teal', 'indigo'];

    return colors[index % colors.length];
  }

  onRemoveTag(index: number) {
    this.tags.splice(index, 1);
    this.section?.learnerTags.splice(index, 1);
    this.onSubmit(false);
  }

  onSubmit(autoSave = true): void {
    const sectionBody: FrameworkTrackerSectionRequest = {
      type: 'FRAMEWORK_TRACKER',
      title: this.title ? this.title : 'Framework tracker',
      content: [],
      tags: this.tags.map((tag) => tag._id),
      afterSection: this.afterSectionUid,
    };
    if (this.section) {
      this.dataService.updateSection(this.pageId, this.section.uid, sectionBody).subscribe(({ isSuccess }) => {
        if (isSuccess && autoSave) {
          this.closeNewSection.emit();
        }
      });
    } else {
      this.dataService.addSection(this.pageId, sectionBody).subscribe(({ isSuccess }) => {
        if (isSuccess && autoSave) {
          this.closeNewSection.emit();
        }
      });
    }
  }

  onAddTags(tags: ResourceTag[]) {
    for (const tag of tags) {
      if (this.tags.length < 10) {
        if (!this.tags.find((value) => value._id === tag._id)) {
          this.tags.push(tag);
        }
      } else {
        break;
      }
    }
    this.sectionAutomaticSaveSubject$.next();
  }

  openFrameworkPanel() {
    if (this.tags.length >= 10) {
      return;
    }

    const dialogRef = this.dialog.open(AddFrameworkTagsDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '37.5rem',
      maxHeight: '80vh',
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
      data: { isDialogPage: true },
    });

    const dialogSubscription = dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.onAddTags(data);
      }

      dialogSubscription.unsubscribe();
    });
  }
}
