/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LikeInfoDetails } from '../../../models/like-info/like-info.model';
import { ResourcePublishedStatus, ResourceType } from '../../../models';
import { DialogService } from '../../../helpers/dialog/dialog.service';
import { TranslocoService } from '@ngneat/transloco';
import { ContentHelper } from '../../../helpers/content-helper';
import { developmentDomains } from '../../../helpers/development-domains.helper';

type LikeInfoType = 'PLAYLIST' | 'CARD';
type Orientation = 'HORIZONTAL' | 'VERTICAL' | 'HORIZONTAL_LARGE';

@Component({
  selector: 'ptl-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss'],
})
export class CardFooterComponent implements OnInit {
  @Input() uid: string;
  @Input() infoType: LikeInfoType;
  @Input() title: string;
  @Input() likeDetails: LikeInfoDetails;
  @Input() editMode: boolean;

  @Input() orientation: Orientation;
  @Input() isAdmin: boolean;
  @Input() syndicated: boolean;
  @Input() canEdit: boolean;
  @Input() canClone: boolean;
  @Input() canViewMembers: boolean;
  @Input() status: ResourcePublishedStatus;
  @Input() displayedType: ResourceType;
  @Input() organizationDomain: string;

  @Output() cloneClicked = new EventEmitter<void>();
  @Output() editClicked = new EventEmitter<string>();
  @Output() membersClicked = new EventEmitter<string>();

  totalLikes: number;
  vote: boolean;

  isIframeMode: boolean;
  isDevelopmentDomains: boolean;

  constructor(
    private dialogService: DialogService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.isIframeMode = ContentHelper.isFrameMode();
    this.isDevelopmentDomains = developmentDomains(this.organizationDomain);
  }

  onCloneClick() {
    this.dialogService
      .showConfirmDialog(this.translocoService.translate('translations.dialog.title.copyCard'), this.translocoService)
      .then((confirmed) => {
        if (confirmed) {
          this.cloneClicked.emit();
        }
      });
  }

  onEditClick(uid: string) {
    this.editClicked.emit(uid);
  }

  onMembersClick(uid: string) {
    this.membersClicked.emit(uid);
  }
}
