/*
 * Copyright (C) 2021 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Organization, Playlist } from '../../../../../shared/models';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'ptl-discovery-share',
  templateUrl: './share-discovery.component.html',
  styleUrls: ['./share-discovery.component.scss'],
})
export class ShareDiscoveryComponent {
  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  activePage = 0;
  selectedPlaylist: Playlist;

  constructor(private dialogRef: MatDialogRef<ShareDiscoveryComponent>) {}

  onCloseDialog() {
    this.dialogRef.close();
  }

  onPlaylistSelected(playlist: Playlist) {
    this.selectedPlaylist = playlist;
    this.setPage(1);
  }

  requestReviewPlaylist(playlistUid: string) {
    // TODO use playlistUid and make request
    this.setPage(2);
  }

  setPage(page: number) {
    this.activePage = page;
  }
}
