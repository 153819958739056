/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CORE_PLAYLIST_DATA_SERVICE, PlaylistDataService } from '../../../page-modules/playlist/services/create/core/data.service';
import { AccessTokenService } from '../../services/access-token.service';
import { USER_IS_LOGGED_IN } from '../../services/api-basic-auth.service';
import { AUTH_SERVICE, BasicAuthService } from '../../services/basic-auth.service';
import * as UserAuthActions from '../../store/user-auth.actions';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { UserAuthState } from '../../store/user-auth.state';
import { LTI_COURSE_UID, LTI_PROVIDER } from '../../../page-modules/lti/services/lti-course-data.service';

@Component({
  template: '',
})
export class LtiLaunchComponent implements OnInit {
  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    @Inject(AUTH_SERVICE) private authService: BasicAuthService,
    @Inject(CORE_PLAYLIST_DATA_SERVICE) private playlistDataService: PlaylistDataService,
    private router: Router,
    private ngZone: NgZone,
    private readonly accessTokenService: AccessTokenService,
  ) {
    this.accessTokenService.removeAccessToken();
  }

  ngOnInit() {
    const requestId = this.activatedRoute.snapshot.queryParamMap.get('requestId');
    if (requestId) {
      this.authService.getLtiToken(requestId, false).subscribe(
        ({ isSuccess, value }) => {
          if (isSuccess) {
            this.accessTokenService.saveAccessToken(value.access_token);
            localStorage.setItem(USER_IS_LOGGED_IN, 'true');
            this.store
              .dispatch([
                new UserAuthActions.FetchUserDetails(),
                new UserAuthActions.RedirectToAuthenticatedAppSuccess(),
                new UserAuthActions.GetHomePageTitleAndUri(),
              ])
              .toPromise()
              .then(() => {
                this.saveAdditionalParametersIntoLocalStorage();
                const courseUid = this.activatedRoute.snapshot.queryParamMap.get('courseUid');
                const blackboardCourseUid = this.activatedRoute.snapshot.queryParamMap.get('blackboardCourseUid');
                const isBlackboardCourseBadgeLaunch =
                  courseUid && blackboardCourseUid && this.activatedRoute.snapshot.queryParamMap.get('provider') === 'BLACKBOARD';
                const isDeepLinkingCourseBadgeLaunch = this.activatedRoute.snapshot.queryParamMap.get('badgeUid') && courseUid;
                if (isBlackboardCourseBadgeLaunch || isDeepLinkingCourseBadgeLaunch) {
                  RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/lti/course/badges', {
                    queryParams: {
                      blackboardCourseUid: blackboardCourseUid,
                      courseUid: courseUid,
                      requestId: requestId,
                    },
                  });
                  return;
                }

                const playlistUri = this.activatedRoute.snapshot.queryParamMap.get('playlist');

                if (playlistUri) {
                  this.playlistDataService.getPlaylistDetails(playlistUri, null, null, null, null).subscribe((data) => {
                    if (data.isSuccess) {
                      RedirectHelper.redirectByParams(
                        this.ngZone,
                        this.router,
                        this.activatedRoute,
                        {
                          playlistUri: playlistUri,
                        },
                        'PLAYLIST',
                      );
                    } else {
                      this.redirectToHomePage();
                    }
                  });
                } else {
                  const destination = this.activatedRoute.snapshot.queryParamMap.get('destination');

                  if (destination) {
                    if (destination === 'skills' || destination === 'folio') {
                      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/folio');
                    } else if (destination === 'submitSkills') {
                      const coursePlaylistUid = this.activatedRoute.snapshot.queryParamMap.get('coursePlaylistUid');
                      RedirectHelper.redirectByUrl(
                        this.ngZone,
                        this.router,
                        this.activatedRoute,
                        `/lti/skills/submit?coursePlaylistUid=${coursePlaylistUid}`,
                      );
                    } else {
                      this.redirectToHomePage();
                    }
                  } else {
                    this.redirectToHomePage();
                  }
                }
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/lti/registration/error');
          }
        },
        (_) => {
          RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/lti/registration/error');
        },
      );
    } else {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/lti/registration/error');
    }
  }

  private saveAdditionalParametersIntoLocalStorage() {
    const ltiProvider = this.activatedRoute.snapshot.queryParamMap.get('provider');
    const courseUid = this.activatedRoute.snapshot.queryParamMap.get('courseUid');
    if (ltiProvider) {
      localStorage.setItem(LTI_PROVIDER, ltiProvider);
    }
    if (courseUid) {
      localStorage.setItem(LTI_COURSE_UID, courseUid);
    }
  }

  private redirectToHomePage() {
    const homePageUri = this.store.selectSnapshot(UserAuthState.homePageUri);
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, homePageUri);
  }
}
