/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AlertType } from '../../models/alert/alert.model';

@Component({
  selector: 'ptl-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() message: string;

  @Input() duration = 10000;

  @Input() textClickable = false;

  @Input() hideAfterDuration = true;

  @Input() type: AlertType;

  @Output() actionClicked = new EventEmitter<void>();

  @Output() closeClicked = new EventEmitter<void>();

  private alertHideTimeout: string | number | NodeJS.Timeout;

  ngOnInit() {
    if (this.hideAfterDuration) {
      this.alertHideTimeout = setTimeout(() => {
        this.onCloseClicked();
      }, this.duration);
    }
  }

  ngOnDestroy() {
    clearTimeout(this.alertHideTimeout);
  }

  onActionClicked() {
    if (this.textClickable) {
      this.actionClicked.emit();
    }
  }

  onCloseClicked() {
    this.closeClicked.emit();
  }
}
