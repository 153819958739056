/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Output, Input } from '@angular/core';
import { canTriggerSearch } from '../../helpers/content-helper';
import { UserSearch } from '../../models/admin/members.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'ptl-user-search-autocomplete',
  templateUrl: './user-search-autocomplete.component.html',
  styleUrls: ['./user-search-autocomplete.component.scss'],
})
export class UserSearchAutocompleteComponent {
  @Input() members: UserSearch[];
  @Input() label: string;
  @Input() loadingMembers: boolean;
  @Input() placeholder?: string = null;
  @Input() showSearchIcon = false;

  @Output() searchInputChanged = new EventEmitter<string>();
  @Output() searchLoadedMore = new EventEmitter<string>();
  @Output() memberSelected = new EventEmitter<UserSearch>();

  canLoadMore: boolean;
  isLoadingMore: boolean;

  private searchString: string;

  onTextPaste(event: ClipboardEvent) {
    if (event.type === 'paste') {
      setTimeout(() => {
        this.searchString = (event.target as HTMLInputElement).value;
        this.searchInputChanged.emit(this.searchString);
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent) {
    if (canTriggerSearch(event)) {
      this.searchString = (event.target as HTMLInputElement).value;
      this.searchInputChanged.emit(this.searchString);
    }
  }

  disabledAutocomplete(): string {
    return '';
  }

  onMemberSelected({ option }: MatAutocompleteSelectedEvent) {
    const data = option.value as UserSearch;
    this.memberSelected.emit(data);
  }

  onAutocompleteScroll() {
    if (!this.canLoadMore) {
      return;
    }
    if (!this.isLoadingMore) {
      this.searchLoadedMore.emit(this.searchString);
      this.isLoadingMore = true;
    }
  }
}
