<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<main
  class="main"
  [ngStyle]="{
    'background-image': (organizationData$ | async)?.landingPage.backgroundImage
      ? 'url(&quot;' + (organizationData$ | async)?.landingPage.backgroundImage + '&quot;)'
      : '',
    'background-color': (organizationData$ | async)?.landingPage.useBackgroundColourFill
      ? (organizationData$ | async)?.landingPage.backgroundColour
      : '',
  }"
  [class.dynamic-background-color]="(organizationData$ | async)?.landingPage.useBackgroundColourFill"
  [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'"
>
  <div class="maintenance-layer" *ngIf="showMaintenanceOverlay">
    <div class="maintenance-layer-inner" [innerHTML]="'translations.maintenance.overlayText' | transloco"></div>
  </div>

  <div class="login__form-container" [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
    <mat-card class="main__login" [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
      <img
        class="logo__image"
        width="240"
        height="80"
        [class.full-background]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
        src="{{ (organizationData$ | async)?.landingPage.landingLogo || (organizationData$ | async)?.logo }}"
        alt="{{ (organizationData$ | async)?.name }} Logo"
      />

      <div class="login__university-info">
        <h1 class="title" *ngIf="!userPreviouslyLoggedIn">{{ (organizationData$ | async)?.name }}</h1>
        <h1 class="title" *ngIf="userPreviouslyLoggedIn">{{ 'translations.public.title.welcome' | transloco }}</h1>
        <p class="description">{{ (organizationData$ | async)?.landingPage.welcomeMessage }}</p>
      </div>

      <div class="login__form-inner" [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
        <form
          class="login-form"
          [formGroup]="authenticationDetails"
          (ngSubmit)="submitForm()"
          [attr.aria-label]="'translations.global.ariaLabels.signInForm' | transloco"
          novalidate
        >
          <mat-form-field class="login-form__email form__field" appearance="outline">
            <mat-label [attr.aria-label]="'translations.global.ariaLabels.emailField' | transloco">{{
              'translations.global.input.label.email' | transloco
            }}</mat-label>
            <input matInput required type="email" name="email" formControlName="email" />
            <mat-error *ngIf="authenticationDetails.controls.email.hasError('required')">
              {{ 'translations.global.input.error.enterEmail' | transloco }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form__field" appearance="outline">
            <mat-label [attr.aria-label]="'translations.global.ariaLabels.passwordField' | transloco">{{
              'translations.global.input.label.password' | transloco
            }}</mat-label>
            <input matInput required name="password" formControlName="password" [type]="showPasswordSelected ? 'text' : 'password'" />
            <div class="password__visibility">
              <i
                tabindex="0"
                class="material-icons password__show-icon"
                [attr.aria-label]="'translations.global.ariaLabels.showOrHidePassword' | transloco"
                (click)="showPasswordSelected = !showPasswordSelected"
                (keydown.enter)="showPasswordSelected = !showPasswordSelected"
              >
                visibility
              </i>
            </div>
            <mat-error *ngIf="authenticationDetails.controls.password.hasError('required')">
              {{ 'translations.global.input.error.enterPassword' | transloco }}
            </mat-error>
          </mat-form-field>

          <div class="login-form__actions">
            <button mat-flat-button type="submit" class="login-form__submit" color="primary" [disabled]="loginInProgress$ | async">
              <span class="uppercase" *ngIf="(loginInProgress$ | async) !== true">
                {{ 'translations.public.button.login' | transloco }}
              </span>
              <span class="submit__loading" *ngIf="loginInProgress$ | async">
                <span class="submit__logging-in">{{ 'translations.public.button.loggingIn' | transloco }}</span>
                <mat-spinner [diameter]="20"></mat-spinner>
              </span>
            </button>

            <div class="login-form__actions-bottom">
              <a
                tabindex="0"
                class="actions__forgot-password"
                href="javascript:void(0)"
                (click)="onForgotPassword()"
                (keydown.enter)="onForgotPassword()"
              >
                {{ 'translations.public.button.forgotPassword' | transloco }}
              </a>
              <a
                tabindex="0"
                class="actions__sign-up"
                href="javascript:void(0)"
                *ngIf="(organizationData$ | async)?.allowRegistrations"
                (click)="onNotMemberClick()"
                (keydown.enter)="onNotMemberClick()"
              >
                {{ 'translations.public.button.signUp' | transloco }}
              </a>
            </div>
          </div>
        </form>
        <ng-container *ngIf="isSocialAuthType">
          <span class="social-buttons-headline" [attr.aria-label]="'translations.global.ariaLabels.orSignUpWith' | transloco">{{
            'translations.public.label.signInWith' | transloco
          }}</span>
          <div class="social-buttons">
            <button class="linkedin-button" title="Sign in with LinkedIn" mat-raised-button (click)="signInUsingLinkedIn()">
              <img alt="" src="assets/social/linkedin-white.svg" width="291" height="79" />
            </button>
            <button class="google-button" title="Sign in with Google" mat-raised-button (click)="signInUsingGoogle()">
              <img alt="" src="assets/social/google-colored.svg" width="265" height="87" />
            </button>
          </div>
        </ng-container>
      </div>

      <div
        *ngIf="false"
        class="login__powered-content"
        [attr.aria-label]="'translations.global.ariaLabels.poweredBy' | transloco: { organizationName: (organizationData$ | async)?.name }"
        [class.full-background]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
        [class.not-form-auth]="!isFormAuthType"
      >
        {{ 'translations.global.label.poweredBy' | transloco }}
        <a
          href="https://potential.ly"
          [class.is_light]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
          class="login__powered-content-link"
          [attr.aria-label]="'translations.global.ariaLabels.open' | transloco: { title: (organizationData$ | async)?.name }"
        >
          <img
            class="login__powered-image"
            width="40"
            height="40"
            src="https://static.yuna.potential.ly/public/images/icons/potentially_logo.png"
            alt=""
          />
          {{ 'translations.public.title.logoText' | transloco }}
        </a>
      </div>
    </mat-card>
  </div>
</main>
