import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[ogImageSource]',
})
export class ImageSourceDirective {
  @Input() imageSrc: string;
  @Input() multipleImageIndex: number;

  private loadImageTryCount = 0;

  @HostListener('error', ['$event'])
  onErrorLoadingImage(event: Event) {
    this.loadImageTryCount++;
    if (this.loadImageTryCount > 1) {
      return;
    }
    if (this.multipleImageIndex) {
      const imagesArray = this.imageSrc.split(',');
      (event.target as HTMLEmbedElement).src = imagesArray[this.multipleImageIndex];
    } else {
      (event.target as HTMLEmbedElement).src = this.imageSrc;
    }
  }
}
