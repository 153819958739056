/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import {
  EditorContent,
  EditorContentRemovalEvent,
  EditorContentUpdateEvent,
  MediaType,
  MediaUploadData,
  MediaUrlData,
  MediumEditorData,
  MediumEditorToolbarEvent,
} from '../../../models';
import { MediaContent } from '../../../../shared/models';
import { ImageCropDialogComponent } from '../../../../shared/components/image-crop-dialog/image-crop-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ALLOWED_DOCUMENT_EXTENSIONS } from '@app/app/editor/components/editor-toolbar/file-upload/file-upload.component';
import { TinymceEditorComponent } from './tinymce-editor/tinymce-editor.component';
import { LanguageCodeHelper } from '../../../../shared/helpers/language-code-helper';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'ptl-editor-content',
  templateUrl: './editor-content.component.html',
  styleUrls: ['./editor-content.component.scss'],
})
export class EditorContentComponent implements OnInit {
  /** Receives the content */
  @Input() content: EditorContent[];

  @Input() editorOptions;
  @Input() disableAutoFocus: boolean;
  @Input() upload: boolean;
  @Input() toolbarOpen: boolean;
  @Input() canRemoveItem: boolean;
  @Input() placeholder: string;
  @Input() emptyContentDisable: boolean;

  @Input() chartSectionUid: string;

  @Input() bytePlaceholder: boolean;

  /** Outputs creating of the component */
  @Output() uploadContentItem = new EventEmitter<EditorContent>();

  /** Outputs removing of the component */
  @Output() removeContentItem = new EventEmitter<EditorContentRemovalEvent>();

  /** Outputs updating of the contentItem */
  @Output() updateContentItem = new EventEmitter<EditorContentUpdateEvent>();

  /** Outputs creating of empty paragraph */
  @Output() createEmptyParagraph = new EventEmitter<number>();

  /** Emits editor-toolbar show/hide event */
  @Output() toolbarUpdate = new EventEmitter<MediumEditorToolbarEvent>();

  /** Outputs the various click and change events */
  @Output() inputFocus = new EventEmitter<void>();

  /** Outputs the various click and change events */
  @Output() inputBlur = new EventEmitter<void>();

  @Output() replaceRegularLink = new EventEmitter<string>();

  @ViewChildren(TinymceEditorComponent) tinymceEditors: QueryList<TinymceEditorComponent>;

  private activeEditorIndex = -1;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    // Create empty paragraph on start
    if (!this.content || (this.content && !this.content.length)) {
      this.createEmptyParagraph.emit(-1);
    }
  }

  onContentRemoval(index: number, contentToRemove: EditorContent) {
    this.removeContentItem.emit({
      index,
      content: contentToRemove,
    });
  }

  onContentUpdate(index: number, newItem: EditorContent, oldItem: EditorContent) {
    if (newItem.type === 'PARAGRAPH') {
      const newContent = (newItem as MediumEditorData).content;
      const oldContent = (oldItem as MediumEditorData).content;
      if (newContent === '' && oldContent !== '') {
        this.onContentRemoval(index, newItem);
        return;
      }
      const regexWithSplit = /<split [^>]*><\/split><p><br [^>]*><\/p>/g;
      if (
        (!this.emptyContentDisable && newContent === '<p><br data-mce-bogus="1"></p>' && oldContent === '') ||
        (newContent === '<p><br></p>' && oldContent === '') ||
        (regexWithSplit.test(newContent) && oldContent === '<p>&nbsp;</p>')
      ) {
        this.onContentRemoval(index, newItem);
        return;
      }
    }
    this.updateContentItem.emit({
      index,
      forceUpdate: false,
      update: { oldContent: oldItem, newContent: newItem },
    });
  }

  isContentUrl({ type }: MediaUrlData): boolean {
    return type === 'MEDIA_URL';
  }

  isContentUpload({ type, mediaType }: MediaUploadData): boolean {
    return (
      type === 'MEDIA_UPLOAD' &&
      (mediaType === 'VIDEO' || mediaType === 'IMAGE' || mediaType === 'DOCUMENT' || mediaType === 'AUDIO' || mediaType === 'STREAM_VIDEO')
    );
  }

  isEmptyPlaceholderShown(contentItem: EditorContent, contentIndex: number): boolean {
    const mediaContentItem = contentItem as MediaUploadData;
    const type = mediaContentItem.type;
    const mediaType = mediaContentItem.mediaType;
    const position = mediaContentItem.position;

    if (
      type === 'EXTERNAL_CONTENT_SUMMARY' ||
      (type === 'MEDIA_UPLOAD' &&
        (mediaType === 'VIDEO' ||
          mediaType === 'STREAM_VIDEO' ||
          mediaType === 'IMAGE' ||
          mediaType === 'DOCUMENT' ||
          mediaType === 'AUDIO'))
    ) {
      return !position || position === 'FULL_WIDTH' || position === 'CENTER';
    }

    if (contentItem.type === 'SEPARATOR') {
      return this.isEmptyPlaceholderEnabled(contentIndex);
    }

    return contentItem.type !== 'PARAGRAPH';
  }

  isEmptyPlaceholderEnabled(contentIndex: number): boolean {
    if (this.content && contentIndex < this.content.length) {
      if (this.content[contentIndex + 1]) {
        return contentIndex !== this.content.length - 1 && this.content[contentIndex + 1].type !== 'PARAGRAPH';
      }
      return true;
    }

    return false;
  }

  trackByFn(index: number, { uid }) {
    return uid || index;
  }

  insertLinkBeforeBreakpoint(link: string): void {
    if (this.activeEditorIndex !== -1) {
      const index = this.content.slice(0, this.activeEditorIndex + 1).filter((item) => item.type === 'PARAGRAPH').length - 1;

      if (this.tinymceEditors && this.tinymceEditors?.toArray().length) {
        this.tinymceEditors.toArray()[index].insertLinkBeforeBreakpoint(link);
      }
    }
  }

  onToolbarUpdate(toolbarEvent: MediumEditorToolbarEvent, index: number): void {
    if (this.activeEditorIndex === index && toolbarEvent.type === 'HIDE_TOOLBAR') {
      this.activeEditorIndex = -1;
    } else if (toolbarEvent.type === 'SHOW_TOOLBAR') {
      this.activeEditorIndex = index;
    }
    this.toolbarUpdate.emit(toolbarEvent);
  }

  onUpdateMediaPosition(index: number, position: string, oldItem: EditorContent): void {
    this.updateContentItem.emit({
      index,
      forceUpdate: true,
      update: { oldContent: oldItem, newContent: { ...oldItem, position: position } as MediaContent },
    });
  }

  onUpdateMediaCaption(index: number, caption: string, oldItem: EditorContent): void {
    this.updateContentItem.emit({
      index,
      forceUpdate: true,
      update: { oldContent: oldItem, newContent: { ...oldItem, caption: caption } as MediaContent },
    });
  }

  onReplaceMedia(index: number, media: MediaContent, oldItem: EditorContent): void {
    this.updateContentItem.emit({
      index,
      forceUpdate: true,
      update: { oldContent: oldItem, newContent: { ...media, uid: oldItem.uid } },
    });
  }

  onUpdateMediaHyperlink(
    index: number,
    hyperlink: {
      url: string;
      openUrlInTab: boolean;
    },
    oldItem: EditorContent,
  ): void {
    this.updateContentItem.emit({
      index,
      forceUpdate: true,
      update: {
        oldContent: oldItem,
        newContent: { ...oldItem, url: hyperlink.url, openUrlInTab: hyperlink.openUrlInTab } as MediaContent,
      },
    });
  }

  onReplaceRegularLink(link: string): void {
    this.replaceRegularLink.emit(link);
  }

  onInputFocus() {
    this.inputFocus.emit();
  }

  onInputBlur() {
    this.inputBlur.emit();
  }

  onFileDrag(event: DragEvent, preventFocus = false) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.upload || this.toolbarOpen) {
      event.dataTransfer.dropEffect = 'none';
    }

    if (!preventFocus) {
      const currentTarget = event.currentTarget as HTMLElement;
      currentTarget.focus({ preventScroll: true });
    }
  }

  onFileDrop(event: DragEvent, index: number) {
    event.preventDefault();
    event.stopPropagation();

    const droppedFile = event.dataTransfer.files?.length ? event.dataTransfer.files[0] : undefined;

    setTimeout(() => {
      this.emitContentUpload(droppedFile, index);
    }, 200);
  }

  onEmptyPlaceholderFocus(index: number) {
    this.createEmptyParagraph.emit(index);
  }

  bubbleInsertSplit(closestParagraph: Element, contentUid: string) {
    this.tinymceEditors?.forEach((editor) => {
      if (editor.contentData.uid === contentUid) {
        editor.insertBreakpoint(closestParagraph);
      }
    });
  }

  private emitContentUpload(file: File, index: number) {
    if (file && this.upload && !this.toolbarOpen) {
      const fileUploadData: MediaUploadData = {
        type: 'MEDIA_UPLOAD',
        file: file,
        uuid4: uuidv4(),
        mediaType: this.getFileType(file),
      };

      if (fileUploadData.mediaType === 'IMAGE') {
        const dialogRef = this.dialog.open(ImageCropDialogComponent, {
          width: '90vw',
          minWidth: '15.625rem',
          maxWidth: '37.5rem',
          maxHeight: '80vh',
          position: {
            top: '10vh',
          },
          direction: LanguageCodeHelper.getBodyLanguageDir(),
          panelClass: 'ptl-mat-dialog',
          backdropClass: 'dialog-backdrop',
          data: {
            imageFile: file,
            cropperShape: 'rectangle',
          },
        });

        const dialogSubscription = dialogRef.afterClosed().subscribe((data) => {
          if (data) {
            fileUploadData.file = data.croppedImageFile;
            this.uploadContentItem.emit({
              ...fileUploadData,
              index: index,
            });
          }
          dialogSubscription.unsubscribe();
        });
      } else {
        const validExtensions = ALLOWED_DOCUMENT_EXTENSIONS.split(',');

        if (
          fileUploadData.mediaType === 'VIDEO' ||
          fileUploadData.mediaType === 'STREAM_VIDEO' ||
          fileUploadData.mediaType === 'AUDIO' ||
          validExtensions.find((extension) => file.name.toLowerCase().endsWith(extension))
        ) {
          this.uploadContentItem.emit({
            ...fileUploadData,
            index: index,
          });
        }
      }
    }
  }

  private getFileType(file: File): MediaType {
    if (file.type.match('image.*')) {
      return 'IMAGE';
    } else if (file.type.match('video.*')) {
      return 'VIDEO';
    } else if (file.type.match('audio.*')) {
      return 'AUDIO';
    }

    return 'DOCUMENT';
  }
}
