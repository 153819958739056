/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { InjectionToken } from '@angular/core';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const LIKE_INFO_DATA_SERVICE = new InjectionToken<LikeInfoDataService>('[like-info] LikeInfoDataService');

/** The service handling tag CRUD operations. */
export interface LikeInfoDataService {
  votePlaylist(playlistUid: string): ObservableResult<number>;
  unvotePlaylist(playlistUid: string): ObservableResult<number>;
  voteResource(resourceUid: string): ObservableResult<number>;
  unvoteResource(resourceUid: string): ObservableResult<number>;
}
