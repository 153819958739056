/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ResourceTag } from '../../models';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { canTriggerSearch } from '../../helpers/content-helper';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CORE_TAG_DATA_SERVICE, TagDataService } from '../../services/tags/tag-data.service';

@Component({
  selector: 'ptl-add-tags',
  templateUrl: './add-tags.component.html',
  styleUrls: ['./add-tags.component.scss'],
})
export class AddTagsComponent implements OnInit, OnDestroy {
  frameworkTagsPanelOpen: boolean;
  loadingTags: boolean;
  filteredTags: ResourceTag[] = [];

  @Input() tags: ResourceTag[] = [];
  @Input() inputPlaceholder = '';
  @Output() tagAdd = new EventEmitter<ResourceTag>();
  @Output() tagDelete = new EventEmitter<ResourceTag>();

  private searchInputSubject = new Subject<string>();
  private searchInputSubscription: Subscription;
  private newTagCreateInProgress: boolean;
  private showFrameworkTagsPanel: boolean;

  constructor(
    @Inject(CORE_TAG_DATA_SERVICE) private tagService: TagDataService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.searchInputSubscription = this.searchInputSubject.pipe(debounceTime(500)).subscribe((searchValue) => this.fireSearch(searchValue));
  }

  ngOnDestroy() {
    this.searchInputSubscription?.unsubscribe();
  }

  onSearchInputChange(event: KeyboardEvent) {
    if (canTriggerSearch(event)) {
      const searchValue = (event.target as HTMLInputElement).value;
      this.searchInputSubject.next(searchValue);
    }
  }

  onTagSelect({ option }: MatAutocompleteSelectedEvent) {
    const tag: ResourceTag = option.value;
    const questionTagIds = this.getTagIds();

    this.filteredTags = [];
    this.loadingTags = true;
    this.loadingTags = false;

    if (questionTagIds && questionTagIds.includes(tag._id ?? tag.id)) {
      return;
    }

    this.tags = this.tags.concat(tag);
    this.tagAdd.emit(tag);
  }

  onAddTagBulk(tagsToAdd: ResourceTag[]) {
    const questionTagIds = this.getTagIds();

    if (!questionTagIds) {
      this.tags = this.tags.concat(...tagsToAdd);
      tagsToAdd.forEach((tag) => {
        this.tagAdd.emit(tag);
      });
    } else {
      tagsToAdd.forEach((tag) => {
        if (!questionTagIds.includes(tag._id ?? tag.id)) {
          this.tags = this.tags.concat(tag);
          this.tagAdd.emit(tag);
        }
      });
    }
  }

  onToggleFrameworkTagsPanel(value: boolean) {
    this.frameworkTagsPanelOpen = value;
  }

  disabledAutocomplete(): string {
    return '';
  }

  onTextPaste(event: ClipboardEvent) {
    if (event.type === 'paste') {
      setTimeout(() => {
        const searchValue = (event.target as HTMLInputElement).value;
        this.searchInputSubject.next(searchValue);
      }, 0);
    }
  }

  deleteTag(tagIndex: number) {
    this.tagDelete.emit(this.tags[tagIndex]);
    this.tags = this.tags.filter((_, i) => i !== tagIndex);
  }

  onSearchInputEnter(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();

    const searchValue = (event.target as HTMLInputElement).value;
    this.newTagCreateInProgress = true;
    this.loadingTags = true;
    this.tagService.createTag({ title: searchValue }).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.loadingTags = false;
        (event.target as HTMLInputElement).value = '';
        const newTag = { ...value, id: value._id };
        this.tags = this.tags.concat(newTag);
        this.tagAdd.emit(newTag);
      } else {
        this.loadingTags = false;
      }
      this.newTagCreateInProgress = false;
    });
  }

  onShowFrameworkTagsPanel(show: boolean) {
    this.showFrameworkTagsPanel = show;
  }

  private getTagIds(): string[] {
    if (!this.tags) {
      return [];
    }

    return this.tags.map((item) => {
      return item._id ?? item.id;
    });
  }

  private fireSearch(searchValue: string) {
    this.loadingTags = true;
    if (!searchValue) {
      this.loadingTags = false;
      return;
    }
    if (this.newTagCreateInProgress) {
      return;
    }
    this.tagService.findTagsWithTitleTerm(searchValue).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.filteredTags = value;
      }
      this.loadingTags = false;
      this.cd.detectChanges();
    });
  }
}
