<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="public-badge-section-outer" [class.iframe-mode]="isIframeMode">
  <div class="skeleton-container" *ngIf="skeletonViewActive; else elseTemplate">
    <ptl-skeleton-view [state]="{ loading: true }" [viewType]="'badge-new'"> </ptl-skeleton-view>
  </div>

  <ng-template #elseTemplate>
    <h1 class="cdk-visually-hidden">{{ 'translations.folio.title.badges' | transloco }}</h1>

    <div class="badge-container">
      <div class="top-container-action" *ngIf="!isIframeMode">
        <button
          class="top-back-button"
          (click)="backToPreviousState()"
          mat-icon-button
          [attr.aria-label]="'translations.global.ariaLabels.backToPreviousPage' | transloco"
        >
          <mat-icon aria-hidden="true">arrow_back</mat-icon>
        </button>
      </div>

      <mat-card class="public-badge-section">
        <div class="action-buttons" *ngIf="!isIframeMode">
          <ng-container *ngIf="isUserOwner">
            <button class="share-linkedin" (click)="shareLinkedInCertificate()">
              <svg class="svg" role="img" [attr.aria-label]="'linkedin'">
                <use ogSvgUnify="assets/sprite/sprite.svg#linkedin"></use>
              </svg>
              <span class="text extra-small bold">{{ 'translations.addBadgeToLinkedin' | transloco }}</span>
            </button>
          </ng-container>
          <button mat-icon-button class="share-button" (click)="shareBadge()">
            <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.share' | transloco">
              <use ogSvgUnify="assets/sprite/sprite.svg#share"></use>
            </svg>
          </button>
        </div>
        <div class="public-badge-section-inner" *ngIf="badgeData">
          <div class="badge-summary">
            <div class="public-badge-info-box">
              <div class="public-badge-info">
                <div class="public-badge-issued large" tabindex="0">
                  {{ badgeData.issuedBy }}
                </div>

                <div class="public-badge-awarded">
                  {{ badgeData.userName }}
                </div>

                <div class="awarded-date small">
                  {{ badgeData.issuedOn | dateFormat: 'DD MMMM y' }}
                </div>

                <div class="badge-title medium bold" *ngIf="isIframeMode">
                  {{ badgeData.title }}
                </div>
              </div>
            </div>
            <div class="public-badge-image-box">
              <img class="image" width="100" height="100" src="{{ badgeData.imageUrl }}" alt="" />
            </div>
          </div>
          <div class="badge-title large" *ngIf="!isIframeMode">
            {{ badgeData.title }}
          </div>
          <div class="badge-description" *ngIf="!isIframeMode">
            <ptl-resource-preview class="thin-preview" [previewContent]="badgeData.description"></ptl-resource-preview>
          </div>
          <div class="badge-completed-container" *ngIf="!isIframeMode && badgeData.completedCriteriaDetails">
            <div class="badge-completed-inner">
              <div class="badge-completed-header">
                <div
                  class="playlist-name medium bold"
                  [innerHTML]="
                    (badgeData.completedCriteriaDetails?.includedCards.length > 0
                      ? 'translations.achievementEarnedDueTo'
                      : 'translations.achievementEarnedDueToAlt'
                    ) | transloco: { name: badgeData.completedCriteriaDetails.headline }
                  "
                ></div>
              </div>

              <div class="separator-line" *ngIf="badgeData.completedCriteriaDetails?.includedCards.length > 0"></div>

              <div class="badge-completed-resource-items">
                <div
                  class="badge-completed-resource-item"
                  [class.centered]="!cardResource?.includedCards?.length"
                  *ngFor="let cardResource of badgeData.completedCriteriaDetails?.includedCards; let index = index"
                >
                  <div class="resource-item-image">
                    <img
                      class="image"
                      src="{{ cardResource.thumbnail ?? '/assets/card-placeholders/card-placeholder-' + ((index % 4) + 1) + '.png' }}"
                      [alt]="cardResource.headline + ' Image'"
                    />
                  </div>

                  <div class="resource-content">
                    <div class="headline-container">
                      <div class="resource-item-title medium bold">
                        {{ cardResource.headline }}
                      </div>
                      <div class="resource-completed-date">
                        <svg class="svg positive" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.check' | transloco">
                          <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
                        </svg>
                        <div class="completed-date small">
                          {{ cardResource.completedOn | dateFormat: 'DD MMM yyyy' }}
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="cardResource?.includedCards?.length">
                      <ul class="resource-nested-items-container">
                        <li class="resource-nested-item extra-small" *ngFor="let nestedItems of cardResource?.includedCards">
                          {{ nestedItems.headline }}
                        </li>
                      </ul>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 class="public-badge-empty" *ngIf="!badgeData">{{ 'translations.folio.label.emptyPublicBadge' | transloco }}</h3>
      </mat-card>
    </div>
  </ng-template>
</div>
