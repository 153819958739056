/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, HostListener, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FileEditorContent } from '../../../models';

@Component({
  selector: 'ptl-resource-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceDocumentPreviewComponent implements OnChanges {
  /** Receives the EditorContent object */
  @Input() content: FileEditorContent | undefined;
  @Input() isEditingContent = false;
  @Input() pdfDataUrl: string | undefined;

  allowFullScreen = false;
  showFullScreenButton = false;
  downloadInProgress = false;
  isProgressBarVisible = false;
  source: SafeResourceUrl = null;

  constructor(private sanitizer: DomSanitizer) {}

  @HostListener('document:fullscreenchange', [])
  onFullScreenChange() {
    const fullscreenElement = document.fullscreenElement;
    if (fullscreenElement === null) {
      this.showFullScreenButton = false;
    }
  }

  @HostListener('document:webkitfullscreenchange', [])
  onWebkitFullScreenChange() {
    const fullscreenElement = document.fullscreenElement;
    if (fullscreenElement === null) {
      this.showFullScreenButton = false;
    }
  }

  ngOnChanges(): void {
    this.initSource();
  }

  iframeLoad(): void {
    this.isProgressBarVisible = false;
  }

  initSource(): void {
    let documentUrl = '';
    if (this.content?.type === 'MEDIA_UPLOAD' && this.content.content?.url) {
      if (!this.content.content.url.split('?')[0].endsWith('.pdf')) {
        documentUrl = 'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(this.content.content.url);
        this.source = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
        this.allowFullScreen = false;
      } else {
        this.allowFullScreen = !this.isEditingContent && !/Edge/.test(navigator.userAgent); /* Edge can open in new tab */
        this.isProgressBarVisible = true;
        documentUrl = this.content.content.url + '#view=FitH';
        this.source = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
      }
    } else {
      this.allowFullScreen = false;
      if (this.pdfDataUrl) {
        documentUrl = this.pdfDataUrl;
        this.source = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
      }
    }
  }

  showFullScreen(element: HTMLElement) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
      // @ts-ignore
    } else if (element.webkitRequestFullscreen) {
      // @ts-ignore
      element.webkitRequestFullscreen();
      // @ts-ignore
    } else if (element.msRequestFullscreen) {
      // @ts-ignore
      element.msRequestFullscreen();
    }
    this.showFullScreenButton = true;
  }

  exitFromFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen().then((_) => (this.showFullScreenButton = false));
    }
  }
}
