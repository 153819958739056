<div class="ai-add-dialog">
  <div class="top-bar">
    <h2 class="title">{{ 'translations.global.dataForms.aiAssistant.settings.addAiAssistant' | transloco }}</h2>
    <button (click)="onCloseDialog()" class="close-dialog" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-form-field class="search-templates-field" appearance="outline">
    <mat-icon matPrefix>search</mat-icon>
    <input
      matInput
      [placeholder]="'translations.global.label.search' | transloco"
      [(ngModel)]="searchString"
      (paste)="onTextPaste($event)"
      (keyup)="onSearchInputChange($event)"
    />
    <mat-spinner class="template-search__spinner" *ngIf="loadingSearch" [diameter]="16"> </mat-spinner>
  </mat-form-field>

  <div class="ai-template-list">
    <div
      class="template-list-item"
      *ngFor="let listItm of templateList"
      (click)="selectTemplate(listItm.assistantSettingsUid)"
      (keydown.enter)="selectTemplate(listItm.assistantSettingsUid)"
    >
      <h2>{{ listItm.title }}</h2>
      <p>{{ listItm.instructions }}</p>
    </div>
  </div>
</div>
