/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import { ObservableResult } from '@app/app/shared/store';
import { Page } from '@app/app/shared/models/page';
import {
  Mentee,
  MenteeAssignment,
  Mentor,
  MentorshipImportReport,
  MentorshipStatus,
} from '@app/app/page-modules/admin/components/mentors/admin-mentors.model';
import { MembersBooleanSearchRequest } from '@app/app/shared/models/admin/boolean-filters.model';
import { UserSearch } from '@app/app/shared/models/admin/members.model';

export const MENTOR_MENTEE_DATA_SERVICE = new InjectionToken<MentorMenteeDataService>('[Mentor Mentee] DataService');

export interface PendingMenteesDecisionRequest {
  approvedUsers: Set<string>;
  rejectedUsers: Set<string>;
}

export interface MentorMenteeDataService {
  searchByMentor(page: number, size: number, request?: MembersBooleanSearchRequest): ObservableResult<Page<Mentor>>;
  searchByMentee(page: number, size: number, request?: MembersBooleanSearchRequest): ObservableResult<Page<Mentee>>;
  findMenteesForMentor(page: number, size: number, mentorUserUid: string, term?: string): ObservableResult<Page<Mentee>>;
  findMentorsForMentee(page: number, size: number, menteeUserUid: string, term?: string): ObservableResult<Page<Mentor>>;
  findMentors(page: number, size: number, term?: string, statuses?: MentorshipStatus[]): ObservableResult<Page<Mentor>>;
  assignMenteeToMentor(mentorUserUid: string, menteeUserUid: string, status: MentorshipStatus): ObservableResult<MenteeAssignment>;
  deleteMenteeFromMentor(mentorUserUid: string, menteeUserUid: string): ObservableResult<void>;
  importMentees(menteesFile: File, sendNotifications: boolean): ObservableResult<MentorshipImportReport>;
  postPendingMenteesDecision(decision: PendingMenteesDecisionRequest): ObservableResult<void>;
  findPendingMentees(): ObservableResult<UserSearch[]>;
}
