<!--
  ~ Copyright (C) 2025 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="control-panel-container">
  <div class="settings-icon">
    <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.tune' | transloco">
      <use ogSvgUnify="assets/sprite/sprite.svg#settings"></use>
    </svg>
  </div>

  <div class="control-panel__first-row">
    <mat-menu #manageReviewersMenu>
      <button
        mat-menu-item
        [attr.aria-label]="'translations.global.ariaLabels.tapToCloseSubmissions' | transloco"
        [class.is_disabled]="!checkedMembers.length"
        (click)="reviewAction(reviewActions.CLOSE)"
      >
        <span class="small">{{ 'translations.reviews.submissionsControls.buttons.closeSelectedReviews' | transloco }}</span>
      </button>
      <mat-divider class="export-divider"></mat-divider>
      <button
        mat-menu-item
        [attr.aria-label]="'translations.global.ariaLabels.tapToReOpenSubmissions' | transloco"
        [class.is_disabled]="!checkedMembers.length"
        (click)="reviewAction(reviewActions.REOPEN)"
      >
        <span class="small">{{ 'translations.reviews.submissionsControls.buttons.reopenSelectedReviews' | transloco }}</span>
      </button>
    </mat-menu>

    <mat-menu #attendanceMenu class="attendance-menu">
      <button
        mat-menu-item
        class="attendance-menu-item"
        [attr.aria-label]="'translations.global.ariaLabels.willNotAttend' | transloco"
        (click)="invokeEventAction('WILL_NOT_ATTEND')"
      >
        <span class="small">{{ 'translations.membersNew.actionBar.willNotAttend' | transloco }}</span>
      </button>
      <mat-divider class="export-divider"></mat-divider>
      <button
        mat-menu-item
        class="attendance-menu-item"
        [attr.aria-label]="'translations.global.ariaLabels.willAttend' | transloco"
        (click)="invokeEventAction('WILL_ATTEND')"
      >
        <span class="small">{{ 'translations.membersNew.actionBar.willAttend' | transloco }}</span>
      </button>
      <mat-divider class="export-divider"></mat-divider>
      <button
        *ngIf="isWaitingListEnabled"
        mat-menu-item
        class="attendance-menu-item"
        [class.is_disabled]="isWaitingListOperationDisabled"
        [disabled]="isWaitingListOperationDisabled"
        [attr.aria-label]="'translations.global.ariaLabels.waitingList' | transloco"
        (click)="invokeEventAction('WAITING_LIST')"
      >
        <span class="small">{{ 'translations.membersNew.actionBar.waitingList' | transloco }}</span>
      </button>
      <mat-divider class="export-divider"></mat-divider>
      <button
        mat-menu-item
        class="attendance-menu-item"
        [attr.aria-label]="'translations.global.ariaLabels.haveAttended' | transloco"
        (click)="invokeEventAction('HAVE_ATTENDED')"
      >
        <span class="small">{{ 'translations.membersNew.actionBar.haveAttended' | transloco }}</span>
      </button>
    </mat-menu>
  </div>

  <div class="control-panel-action-buttons">
    <ng-container *ngIf="isPlaylistPage && canManageReviews">
      <button
        mat-stroked-button
        [matMenuTriggerFor]="checkedMembers.length ? manageReviewersMenu : null"
        type="button"
        [class.is_disabled_tooltip]="!checkedMembers.length"
        matTooltipClass="medium-tooltip"
        [disabled]="!checkedMembers.length"
        [matTooltip]="checkedMembers.length ? '' : ('translations.membersNew.actionBar.manageReviewStatus' | transloco)"
        [attr.aria-label]="'translations.global.ariaLabels.manageReviewStatus' | transloco"
      >
        <span class="small bold">{{ 'translations.membersNew.actionBar.manageReviewStatus' | transloco }}</span>
      </button>
    </ng-container>

    <button
      mat-stroked-button
      *ngIf="isAdminPage"
      type="button"
      [class.is_disabled_tooltip]="!checkedMembers.length"
      [disabled]="!checkedMembers.length"
      matTooltipClass="medium-tooltip"
      [matTooltip]="checkedMembers.length > 0 ? '' : ('translations.membersNew.actionBar.noMemberSelect' | transloco)"
      (click)="openEditRoleDialog()"
      [attr.aria-label]="'translations.global.ariaLabels.editRole' | transloco"
    >
      <span class="small bold">{{ 'translations.membersNew.actionBar.editRole' | transloco }}</span>
    </button>
    <button
      mat-stroked-button
      type="button"
      *ngIf="!isIframeMode"
      [class.is_disabled_tooltip]="!checkedMembers.length"
      [disabled]="!checkedMembers.length"
      matTooltipClass="medium-tooltip"
      [matTooltip]="checkedMembers.length > 0 ? '' : ('translations.membersNew.actionBar.noMemberSelect' | transloco)"
      (click)="openEditGroupDialog()"
      [attr.aria-label]="'translations.global.ariaLabels.editGroup' | transloco"
    >
      <span class="small bold">{{ 'translations.membersNew.actionBar.editGroup' | transloco }}</span>
    </button>
    <button
      mat-stroked-button
      type="button"
      (click)="openSendMessageDialog()"
      [attr.aria-label]="'translations.global.ariaLabels.sendMessage' | transloco"
    >
      <div class="button-content">
        <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.mail' | transloco">
          <use ogSvgUnify="assets/sprite/sprite.svg#mail"></use>
        </svg>
        <span class="small bold">{{ 'translations.membersNew.controlPanel.message' | transloco }}</span>
      </div>
    </button>
    <button *ngIf="isEvent" mat-stroked-button class="qr-code-btn" type="button" (click)="openCheckInDialog()">
      <div class="button-content">
        <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.qrCode' | transloco">
          <use ogSvgUnify="assets/sprite/sprite.svg#qr-code"></use>
        </svg>
        <span class="small bold">{{ 'translations.qrcode.checkIn' | transloco }}</span>
      </div>
    </button>
    <button
      *ngIf="!isCardPage && !isPlaylistPage && !isEvent && (canManageUserImportPrivileges$ | async) === true"
      mat-stroked-button
      class="upload-users-btn"
      [attr.aria-label]="'translations.global.ariaLabels.usersUpload' | transloco"
      (click)="openUsersUploadCsv()"
      (keydown.enter)="openUsersUploadCsv()"
    >
      <div class="button-content">
        <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.upload' | transloco">
          <use ogSvgUnify="assets/sprite/sprite.svg#upload"></use>
        </svg>
        <span class="small bold">{{ 'translations.membersNew.controlPanel.usersUpload.name' | transloco }}</span>
      </div>
    </button>
    <button
      *ngIf="!isCardPage && !isPlaylistPage && (organizationData$ | async)?.type !== 'FOLIO'"
      mat-stroked-button
      class="alumni-upload-btn"
      [attr.aria-label]="'translations.global.ariaLabels.userAlumniUpload' | transloco"
      (click)="openAlumniUploadCsv()"
      (keydown.enter)="openAlumniUploadCsv()"
    >
      <div class="button-content">
        <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.upload' | transloco">
          <use ogSvgUnify="assets/sprite/sprite.svg#upload"></use>
        </svg>
        <span class="small bold">{{ 'translations.membersNew.controlPanel.alumniUpload.name' | transloco }}</span>
      </div>
    </button>

    <ng-container *ngIf="isEvent">
      <button
        mat-stroked-button
        [matMenuTriggerFor]="checkedMembers.length >= 1 ? attendanceMenu : null"
        type="button"
        [class.is_disabled_tooltip]="!checkedMembers.length"
        matTooltipClass="medium-tooltip"
        [disabled]="!checkedMembers.length"
        [matTooltip]="checkedMembers.length > 0 ? '' : ('translations.membersNew.actionBar.noMemberSelect' | transloco)"
        [attr.aria-label]="'translations.global.ariaLabels.attendance' | transloco"
      >
        <span class="small bold">{{ 'translations.membersNew.actionBar.attendance' | transloco }}</span>
      </button>
    </ng-container>
  </div>
</div>
