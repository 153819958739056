<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="add-tags-container">
  <div class="add-tags-search-input-container">
    <div class="tags-section-form-content">
      <div class="tags-section-add-tag-form">
        <div class="tags-section-add-tag-field">
          <input
            matInput
            [matAutocomplete]="auto"
            type="text"
            [placeholder]="inputPlaceholder ?? ('translations.addNewTags' | transloco)"
            [attr.aria-label]="'translations.global.ariaLabels.addNewTags' | transloco"
            (paste)="onTextPaste($event)"
            (keyup)="onSearchInputChange($event)"
            (keydown.enter)="onSearchInputEnter($event)"
            (focus)="onShowFrameworkTagsPanel(false)"
          />

          <mat-autocomplete
            [panelWidth]="450"
            #auto="matAutocomplete"
            [displayWith]="disabledAutocomplete"
            (optionSelected)="onTagSelect($event)"
          >
            <mat-option *ngFor="let option of filteredTags" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-spinner *ngIf="loadingTags" [diameter]="14"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
  <div class="add-tags-framework-container">
    <button
      mat-stroked-button
      type="button"
      color="potentially-secondary"
      class="add-frameworks-button"
      tabindex="0"
      [attr.aria-label]="'translations.global.ariaLabels.addFromFramework' | transloco"
      (click)="onToggleFrameworkTagsPanel(true)"
    >
      <svg class="svg svg-icon icon-left">
        <use ogSvgUnify="assets/sprite/sprite.svg#framework"></use>
      </svg>
      <span>{{ 'translations.framework.button.framework' | transloco }}</span>
    </button>
  </div>
</div>

<div class="chips-list" *ngIf="tags">
  <div class="chip-item has-action-btn chip-interactive" *ngFor="let tag of tags; let i = index" [matTooltip]="tag.title">
    <span class="chip-text">
      {{ tag.title }}
    </span>
    <span
      tabindex="0"
      class="chip-item-icon"
      [attr.aria-label]="'translations.global.ariaLabels.removeTag' | transloco"
      (click)="deleteTag(i)"
      (keydown.enter)="deleteTag(i)"
    >
      <svg class="svg" role="img" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </span>
  </div>
</div>
<ptl-add-framework-tags
  *ngIf="frameworkTagsPanelOpen"
  class="add-framework-tags-container f_add-framework-tags-container"
  [momentTags]="tags"
  [isDialogPage]="true"
  (closeFrameworkTags)="onToggleFrameworkTagsPanel($event)"
  (addTagBulk)="onAddTagBulk($event)"
>
</ptl-add-framework-tags>
