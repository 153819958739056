/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { RestClientService } from '../rest-client.service';
import { ObservableResult } from '../../store';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { LikeInfoDataService } from './like-info-data.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ApiLikeInfoDataService implements LikeInfoDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  votePlaylist(playlistUid: string): ObservableResult<number> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/likes`);
    return this.client.patch<number>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorVotingPlaylist'))),
    );
  }

  unvotePlaylist(playlistUid: string): ObservableResult<number> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/likes`);
    return this.client.delete<number>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorUnVotingPlaylist'))),
    );
  }

  voteResource(resourceUid: string): ObservableResult<number> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/likes`);
    return this.client.patch<number>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorVotingCard'))),
    );
  }

  unvoteResource(resourceUid: string): ObservableResult<number> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/likes`);
    return this.client.delete<number>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService?.translate('translations.errors.errorUnVotingCard'))),
    );
  }
}
