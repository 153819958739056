<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="source">
  <div
    class="preview-element-document-container"
    [ngClass]="!isEditingContent ? 'preview-element-document-container-position-' + (content?.position ? content?.position : 'CENTER') : ''"
  >
    <div #previewElement class="preview-element-document" [style.borderWidth]="isEditingContent ? '0' : ''">
      <ng-container *ngIf="allowFullScreen">
        <button
          class="exit-button"
          *ngIf="!showFullScreenButton"
          title="Show full screen"
          mat-icon-button
          (click)="showFullScreen(previewElement)"
        >
          <mat-icon aria-hidden="true" inline="true">crop_free</mat-icon>
        </button>
        <button
          class="exit-button"
          *ngIf="showFullScreenButton"
          title="Exit from full screen"
          mat-icon-button
          (click)="exitFromFullScreen()"
        >
          <mat-icon aria-hidden="true" inline="true">fullscreen_exit</mat-icon>
        </button>
      </ng-container>
      <iframe
        tabindex="0"
        title=""
        (load)="iframeLoad()"
        [class.preview-element-document--disabled]="isEditingContent"
        [class.allow-full-screen]="allowFullScreen"
        [src]="source"
      ></iframe>
      <div class="preview-element-footer" *ngIf="allowFullScreen">
        <button *ngIf="!showFullScreenButton" title="Show in full screen" mat-icon-button (click)="showFullScreen(previewElement)">
          <mat-icon aria-hidden="true" inline="true">crop_free</mat-icon>
        </button>
        <button *ngIf="showFullScreenButton" title="Exit from full screen" mat-icon-button (click)="exitFromFullScreen()">
          <mat-icon aria-hidden="true" inline="true">fullscreen_exit</mat-icon>
        </button>
      </div>
    </div>
    <div class="document-preview-element__caption-box" *ngIf="content?.caption">
      <p tabindex="0" class="caption-text">
        {{ content?.caption }}
      </p>
    </div>
  </div>
</ng-container>
