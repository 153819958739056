<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="dialog-header">
  <div class="dialog-header__top">
    <img src="{{ (organizationData$ | async)?.logo }}" class="dialog-header__logo" alt="" width="88" height="67" />

    <i
      class="material-icons dialog-header__close-icon"
      [mat-dialog-close]="false"
      [attr.aria-label]="'translations.global.ariaLabels.closePublishPage' | transloco"
      tabindex="0"
      >close</i
    >
  </div>
</div>

<div class="report-wrapper">
  <div class="report-wrapper__inner">
    <div class="sticky-panel beta">
      <ul class="content">
        <ng-container *ngFor="let section of reportSections">
          <li
            *ngIf="section.type === 'STANDARD_SECTION' || (section.type === 'CONDITIONAL_SECTION' && report?.reportMode === 'FULL')"
            [attr.aria-label]="sectionTitle(section)"
            (click)="scrollTo(section)"
            (keydown.enter)="scrollTo(section)"
          >
            {{ sectionTitle(section) }}
          </li>
        </ng-container>
      </ul>
    </div>

    <mat-card class="report-view beta">
      <div class="report-view__headline">
        <div class="report-view__org-logo" *ngIf="report?.showLogo">
          <img class="logo__image" [src]="(organizationData$ | async)?.logo" alt="" />
        </div>

        <h2 class="report-view__title beta">{{ member?.about.firstName }} {{ member?.about.lastName }}</h2>
      </div>

      <div class="report-view__headline-info">
        <div class="title" *ngIf="report?.showTitle" tabindex="0" [attr.aria-label]="report.title">
          {{ report.title }}
        </div>
        <div
          class="completed"
          tabindex="0"
          [attr.aria-label]="
            'translations.global.ariaLabels.completed' | transloco: { progress: (report?.completedOn | dateFormat: 'DD MMMM yyyy HH:mm') }
          "
        >
          {{ 'translations.global.label.completed' | transloco }} {{ report?.completedOn | dateFormat: 'DD MMMM yyyy HH:mm' }}
        </div>

        <div class="report-view__pdf-btn-container">
          <button
            *ngIf="reportSections?.length"
            title="Download report"
            mat-icon-button
            class="report-view__pdf-btn"
            type="button"
            [attr.aria-label]="'translations.global.ariaLabels.downloadReport' | transloco"
            (click)="downloadReport()"
          >
            <img *ngIf="!downloadInProgress" alt="Download report" src="assets/icons/pdf-file-dark.svg" width="640" height="640" />
            <mat-spinner *ngIf="downloadInProgress" [diameter]="20"></mat-spinner>
          </button>
        </div>
      </div>

      <ng-container *ngFor="let section of reportSections; let first = first; let i = index; trackBy: trackByFn">
        <ptl-diagnostics-report-standard-section *ngIf="section.type === 'STANDARD_SECTION'" [isFirst]="first" [section]="section">
        </ptl-diagnostics-report-standard-section>

        <ptl-diagnostics-report-conditional-section
          *ngIf="section.type === 'CONDITIONAL_SECTION'"
          [class.is_print-hidden]="report?.reportMode !== 'FULL'"
          [isFirst]="first"
          [section]="section"
        >
        </ptl-diagnostics-report-conditional-section>

        <ptl-diagnostics-report-resource-section
          *ngIf="section.type === 'RESOURCE_SECTION'"
          [isFirst]="first"
          [sectionIndex]="i"
          [section]="section"
        >
        </ptl-diagnostics-report-resource-section>
      </ng-container>
    </mat-card>
  </div>
</div>
