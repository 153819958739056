<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="container" *ngIf="!!userSummary">
  <img class="profile-image" *ngIf="userSummary.imageUrl" [src]="userSummary.imageUrl" width="50" height="50" alt="" />
  <div class="profile-initials" *ngIf="!userSummary.imageUrl">
    <span>{{ userInitial }}</span>
  </div>
</div>
