<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div tabindex="0" class="slider-main-container" [attr.aria-labelledby]="'translations.sliderQuestionAria' | transloco">
  <div class="question-aria" id="sliderQuestionAria">
    {{ 'translations.sliderQuestionAria' | transloco: { leftText: leftText, rightText: rightText } }}
  </div>

  <span #ariaMiddle class="question-aria">{{
    'translations.sliderQuestionAriaMiddle'
      | transloco
        : {
            leftText: leftText,
            rightText: rightText,
          }
  }}</span>
  <span #ariaLeft class="question-aria">{{ 'translations.sliderQuestionAriaLeft' | transloco: { leftText: leftText } }}</span>
  <span #ariaRight class="question-aria">{{ 'translations.sliderQuestionAriaRight' | transloco: { rightText: rightText } }}</span>
  <span #ariaLeftEnd class="question-aria">{{ 'translations.sliderQuestionAriaLeftEnd' | transloco: { leftText: leftText } }}</span>
  <span #ariaRightEnd class="question-aria">{{ 'translations.sliderQuestionAriaRightEnd' | transloco: { rightText: rightText } }}</span>

  <ptl-resource-preview
    class="question-slider-info"
    [previewContent]="[{ type: 'PARAGRAPH', content: question.instructions ? getTranslatedValue(question.instructions) : null }]"
  >
  </ptl-resource-preview>
  <div class="question-card__slider-wrapper">
    <mat-slider
      [class.checked-slider-image]="sliderHasChanged"
      class="question-card__slider"
      color="primary"
      [thumbLabel]="question.showScore"
      [min]="leftScore"
      [max]="rightScore"
      [(ngModel)]="selectedValue"
      [step]="question.slideStepBy && question.slideStepBy > 0 ? question.slideStepBy : 0.01"
      [disabled]="disabled"
      [attr.aria-label]="ariaLabelText"
      [attr.aria-labelledby]="'translations.sliderQuestionAria' | transloco"
      (change)="sliderChanged($event)"
    >
    </mat-slider>

    <div class="question-card__slider-ranges-container">
      <div class="question-card__slider-ranges">
        <ng-container *ngIf="question.slideStepBy && question.slideStepBy > 0">
          <div class="question-card__slider-range-item" *ngFor="let item of [].constructor(question.slideStepBy); let i = index"></div>
        </ng-container>

        <ng-container *ngIf="!question.slideStepBy || question.slideStepBy === 0">
          <div class="question-card__slider-range-item"></div>
          <div class="question-card__slider-range-item"></div>
          <div class="question-card__slider-range-item"></div>
        </ng-container>
      </div>
      <div class="scores-range" *ngIf="question.showScore">
        <div class="min-score small">{{ question.min }}</div>
        <div class="max-score small">{{ question.max }}</div>
      </div>
    </div>
  </div>

  <div class="slider-text-container">
    <div class="question-card-slider-info question-card-slider-left">
      <div class="question-card-slider-text medium bold left-text">{{ leftText }}</div>

      <div class="question-card__slider-instruction-item" *ngIf="leftInstruction">
        <ptl-resource-preview
          *ngIf="leftInstruction && leftInstruction?.length && leftInstruction[0].content !== ''"
          [previewContent]="leftInstruction"
          class="thin-preview"
        ></ptl-resource-preview>
      </div>
    </div>

    <div class="question-card-slider-info question-card-slider-right">
      <div class="question-card-slider-text medium bold left-text">{{ rightText }}</div>

      <div class="question-card__slider-instruction-item" *ngIf="rightInstruction">
        <ptl-resource-preview
          *ngIf="rightInstruction && rightInstruction?.length && rightInstruction[0].content !== ''"
          [previewContent]="rightInstruction"
          class="thin-preview"
        ></ptl-resource-preview>
      </div>
    </div>
  </div>
</div>
