/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import { ObservableResult } from '../../../../shared/store';
import {
  CreateUserGroups,
  UserGroupMember,
  UserGroups,
  UserGroupUploadResult,
} from '../../../../shared/models/admin/group/user-groups.model';
import { Page } from '../../../../shared/models/page';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const USER_GROUPS_DATA_SERVICE = new InjectionToken<UserGroupsDataService>('[Admin] UserGroupsDataService');

/** The admin data service. */
export interface UserGroupsDataService {
  createUserGroup(request: CreateUserGroups): ObservableResult<UserGroups>;

  updateUserGroup(userGroupUid: string, request: CreateUserGroups): ObservableResult<UserGroups>;

  loadUserGroup(userGroupUid: string, playlistUid?: string): ObservableResult<UserGroups>;

  searchUserGroups(page: number, size: number, term?: string, playlistUid?: string): ObservableResult<Page<UserGroups>>;

  bulkUploadUsers(csvFile: File, groupId: string): ObservableResult<UserGroupUploadResult>;

  loadSystemGroups(): ObservableResult<string[]>;

  loadReviewerGroupTypes(playlistUid: string): ObservableResult<string[]>;

  loadUserGroupsMembers(userGroupUid: string, page: number, size: number, term?: string): ObservableResult<Page<UserGroupMember>>;

  getUserGroupsByType(type: string, playlistUid?: string): ObservableResult<UserGroups[]>;

  getAllUserGroups(playlistUid?: string): ObservableResult<UserGroups[]>;

  assignMultipleGroupsToMultipleUsers(groupUids: string[], userUids: string[]): ObservableResult<void>;

  removeMultipleGroupsFromMultipleUsers(groupUids: string[], userUids: string[]): ObservableResult<void>;
}
