import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BOOKING_DATA_SERVICE, BookingDataService } from '../../../../../../shared/services/booking/booking-data.service';
import {
  BookableAppointment,
  BookableInterval,
  BookableResource,
  BookableResourceRequest,
  BookableSlotAdmin,
} from '../../../../../../shared/models/editor/booking-content.model';
import { Store } from '@ngxs/store';
import { Subject, Subscription } from 'rxjs';
import { UserSearch } from '../../../../../../shared/models/admin/members.model';
import { MEMBERS_DATA_SERVICE, MembersDataService } from '../../../../../../page-modules/admin/services/members/members-data.service';
import { UserSearchAutocompleteComponent } from '../../../../../../shared/components/user-search-autocomplete/user-search-autocomplete.component';
import { MatSelect } from '@angular/material/select';
import { debounceTime } from 'rxjs/operators';
import { BookingFormContent, Form } from '../../../../../../shared/models';
import { TranslocoService } from '@ngneat/transloco';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from '../../../../../../shared/services/toast-notifications/toast-service';

@Component({
  selector: 'ptl-form-booking-resource',
  styleUrls: ['./form-booking-resource.component.scss'],
  templateUrl: './form-booking-resource.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormBookingResourceComponent implements OnDestroy, OnChanges {
  @Input() form: Form | undefined;
  @Input() bookableResources: BookableResource[];
  @Input() timeIntervals: BookableInterval[];
  @Input() currentLanguage: string;
  @Input() cardUid: string;
  @Input() appointment: BookableAppointment;
  @Input() saveInProgress: boolean;
  @Output() resourceUpdated = new EventEmitter<boolean>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() scheduleUpdated = new EventEmitter<{ resourceUid: string; scheduleUid: string; slots: BookableSlotAdmin[] }>();

  @ViewChildren('matSelectRef') matSelectRefs: QueryList<MatSelect>;
  @ViewChild('userSearchAutocomplete') private userSearchAutocomplete: UserSearchAutocompleteComponent;

  bookingForm: FormGroup;
  loadingMembers: boolean;
  members: UserSearch[];
  activeResource: string;
  isSaved = false;
  resourceHasError: boolean;
  appointmentHasError: boolean;

  private searchInputSubject = new Subject<string>();
  private searchInputSubscription: Subscription;
  private resourceCreationSubscription: Subscription;
  private resourcesLoadingSubscription: Subscription;
  private userSearchSubscription: Subscription;
  private userSearchPage = 0;
  private userSearchPageSize = 20;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private cd: ChangeDetectorRef,
    @Inject(BOOKING_DATA_SERVICE) private bookingDataService: BookingDataService,
    @Inject(MEMBERS_DATA_SERVICE) private membersDataService: MembersDataService,
    private translocoService: TranslocoService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
  ) {
    this.searchInputSubscription = this.searchInputSubject
      .pipe(debounceTime(500))
      .subscribe((searchValue) => this.fireSearch(searchValue, true));
  }

  ngOnDestroy() {
    this.searchInputSubscription?.unsubscribe();
    this.resourceCreationSubscription?.unsubscribe();
    this.resourcesLoadingSubscription?.unsubscribe();
    this.userSearchSubscription?.unsubscribe();
  }

  onSearchInputChange(searchValue: string) {
    this.searchInputSubject.next(searchValue);
  }

  onSearchLoadingMore(searchValue: string) {
    this.fireSearch(searchValue, false);
  }

  onMemberSelected(data: UserSearch) {
    // TODO: Make the maxSimultaneousBookings parameter customizable
    if (this.bookableResources.some((resource) => resource.targetResourceSummary.uid === data.uid)) {
      this.onResourceError();
    } else {
      const request: BookableResourceRequest = {
        bookableAppointmentUid: (this.form.content as BookingFormContent).bookableAppointmentId,
        maxSimultaneousBookings: 1,
        userUid: data.uid,
      };
      this.onRequestProcessing();
      this.resourceCreationSubscription = this.bookingDataService
        .createBookableResource(request, this.cardUid, this.currentLanguage)
        .subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            this.toastService.showSuccess(this.translocoService.translate('translations.bookings.label.success.resourceSaved'));
            this.isLoading.emit(false);
            this.bookableResources.push(value);
            this.cd.detectChanges();
          } else {
            this.onResourceError();
          }
        });
    }
  }

  ngOnChanges() {
    if (this.saveInProgress) {
      this.activeResource = null;
      this.cd.detectChanges();
    }
  }

  onResourceRefresh() {
    this.resourceUpdated.emit();
  }

  onResourceDeleted(resourceUid: string) {
    this.bookableResources = this.bookableResources.filter((resource) => resource.id !== resourceUid);
    this.toastService.showSuccess(this.translocoService.translate('translations.bookings.label.success.resourceDeleted'));
    this.cd.detectChanges();
  }

  updateResourcesRequests(request: { resourceUid: string; scheduleUid: string; slots: BookableSlotAdmin[] }) {
    this.scheduleUpdated.emit(request);
  }

  setIsLoading(value: boolean) {
    this.isLoading.emit(value);
    this.cd.detectChanges();
  }

  private onResourceError() {
    this.resourceHasError = true;
  }

  private onRequestProcessing() {
    this.isLoading.emit(true);
    this.appointmentHasError = false;
  }

  private fireSearch(searchValue: string, override: boolean) {
    this.userSearchPage = override ? 0 : this.userSearchPage + 1;
    this.loadingMembers = true;
    if (!searchValue) {
      this.loadingMembers = false;
      return;
    }
    this.cd.detectChanges();
    this.userSearchSubscription = this.membersDataService
      .searchUsers(this.userSearchPage, this.userSearchPageSize, searchValue)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          if (override) {
            this.members = value.content;
          } else {
            this.members = this.members.concat(value.content);
          }
        }
        this.userSearchAutocomplete.canLoadMore = value.totalNumberOfElement > this.members.length;
        this.userSearchAutocomplete.isLoadingMore = false;
        this.loadingMembers = false;
        this.cd.detectChanges();
      });
  }
}
