/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

/* eslint-disable max-len */
/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
import { InjectionToken } from '@angular/core';
import {
  AddCompetenceLevelRequest,
  CompetenceLevel,
  CompetenceModel,
  CreateCompetenceRequest,
} from '../../models/competence/competence.model';
import { ObservableResult } from '../../store';

export const CORE_COMPETENCE_DATA_SERVICE = new InjectionToken<CompetenceDataService>('[competence] coreCompetenceDataService');

/** The service handling competence CRUD operations. */
export interface CompetenceDataService {
  /**
   * Creates a competence
   */
  createCompetence(request: CreateCompetenceRequest): ObservableResult<CompetenceModel>;

  /**
   * Updates a competence
   */
  updateCompetence(competenceUid: string, request: CreateCompetenceRequest): ObservableResult<CompetenceModel>;

  /**
   * Add competence level to specified competence {competenceUid}
   */
  addCompetenceLevel(competenceUid: string, request: AddCompetenceLevelRequest): ObservableResult<CompetenceModel>;

  /**
   * Updates competence level for specified competence {competenceUid} and competence level {competenceLevelUid}
   */
  updateCompetenceLevel(
    competenceUid: string,
    competenceLevelUid: string,
    request: AddCompetenceLevelRequest,
  ): ObservableResult<CompetenceLevel>;

  /**
   * Gets competence by Uid
   */
  getCompetenceById(competenceUid: string): ObservableResult<CompetenceModel>;

  /**
   * Gets all competencies for current organization including competences which are set to global
   */
  getOrganizationCompetenciesIncludingGlobal(global: boolean): ObservableResult<CompetenceModel[]>;

  /**
   * Assigns competence to a tag
   */
  connectCompetenceToTag(tagUid: string, competencyUid: string): ObservableResult<void>;
}
