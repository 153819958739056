import { AfterViewInit, Component, EventEmitter, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Members } from '@app/app/shared/models/admin/members.model';
import { Select } from '@ngxs/store';
import { ResourceAdminState } from '@app/app/page-modules/resource/store/admin/resource-admin.state';
import { Observable } from 'rxjs';
import { Playlist, Resource } from '@app/app/shared/models';
import { PlaylistViewState } from '@app/app/page-modules/playlist/store/view/playlist-view.state';
import { LoadableState } from '@app/app/shared/store';
import { RedirectHelper } from '@app/app/page-modules/resource/store/editor/content/helpers/redirect.helper';
import { ActivatedRoute, Router } from '@angular/router';
import { SendMessageDialogComponent } from '@app/app/page-modules/admin/components/members/admin-member-control-panel/send-message-dialog/send-message-dialog.component';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { MembersBooleanSearchRequest } from '@app/app/shared/models/admin/boolean-filters.model';
import { takeUntil } from 'rxjs/operators';
import { MemberInfoDialogData } from '@app/app/page-modules/admin/components/members/model/members.model';

@Component({
  templateUrl: './member-info-dialog.component.html',
  styleUrls: ['./member-info-dialog.component.scss'],
})
export class MemberInfoDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  member: Members;
  isPlaylistPage: boolean;
  isCardPage: boolean;
  parentUrl: string;

  @Select(ResourceAdminState.resource)
  resource$: Observable<Resource>;

  @Select(PlaylistViewState.playlistState)
  playlist$: Observable<LoadableState<Playlist>>;

  private booleanSearchRequest: MembersBooleanSearchRequest;
  private subscriptionEnd$ = new EventEmitter<void>();
  private playlistUid: string;
  private cardUid: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<MemberInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: MemberInfoDialogData,
  ) {
    if (data) {
      this.member = this.data.member;
      this.isPlaylistPage = this.data.isPlaylistPage;
      this.isCardPage = this.data.isCardPage;
      this.parentUrl = this.data.parentUrl;
      this.booleanSearchRequest = this.data.query;
    }
  }

  ngOnInit(): void {
    this.playlist$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((playlist) => {
      if (playlist && playlist.data) {
        this.playlistUid = playlist.data._id;
      }
    });

    this.resource$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((card) => {
      if (card) {
        this.cardUid = card._id;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionEnd$.emit();
  }

  ngAfterViewInit(): void {
    (document.querySelector('.f_close-btn') as HTMLElement)?.focus();
  }

  closeDialog() {
    const overlayPane = document.querySelector('.f_slide-in-dialog');

    if (overlayPane) {
      overlayPane.classList.add('slide-out');
    }

    setTimeout(() => {
      this.dialogRef.close();
    }, 300);
  }

  getMemberCompletionStatus(member: Members): 'IN_PROGRESS' | 'COMPLETED' | 'NOT_STARTED' {
    if (member.cardStatus === 'COMPLETED' || member.playlistStatus === 'COMPLETED') {
      return 'COMPLETED';
    } else if (member.cardStatus === 'IN_PROGRESS' || member.playlistStatus === 'IN_PROGRESS') {
      return 'IN_PROGRESS';
    }
    return 'NOT_STARTED';
  }

  openMemberDetailPage(userUid: string) {
    this.closeDialog();
    const url = `${this.parentUrl}/${userUid}/user-record`;

    if (this.isPlaylistPage || this.isCardPage) {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, url.toString(), null);
    } else {
      window.open(url.toString(), '_blank');
    }
  }

  openSendMessageDialog(): void {
    this.closeDialog();
    this.ngZone.run(() => {
      this.dialog.open(SendMessageDialogComponent, {
        width: '90vw',
        minWidth: '15.625rem',
        maxWidth: '46.875rem',
        maxHeight: '80vh',
        restoreFocus: true,
        position: {
          top: '10vh',
        },
        direction: LanguageCodeHelper.getBodyLanguageDir(),
        panelClass: 'ptl-mat-dialog',
        backdropClass: 'dialog-backdrop',
        data: {
          checkedMemberIds: [this.member._id],
          query: this.booleanSearchRequest.query,
          allMembers: null,
          filteredCount: null,
          playlistUid: this.playlistUid,
          cardUid: this.cardUid,
        },
      });
    });
  }
}
