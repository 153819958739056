import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'singularPluralNoun',
})
export class SingularPluralNounPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    return value > 1 ? 's' : '';
  }
}
