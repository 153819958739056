/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  AvailableLanguage,
  CardsLayoutType,
  EditorContent,
  ImageUploadContent,
  PlaylistCardShort,
  ResourceAuthorDetails,
  ResourceSubheader,
  ResourceTag,
  ReviewStatusSummary,
} from '..';
import { LikeInfoDetails } from '../like-info/like-info.model';
import { PlaylistPublishedStatus } from './learner-playlist-summary.model';
import { ContentStorePlaylistPermissions } from '../content-store/content-store.model';
import { DataFormSummary } from '../data-form/data-form.model';
import { RequirementsMode, ReviewerDetails } from '../../../page-modules/playlist/models';
import {
  EventParticipationStatus,
  EventReminder,
  EventTicketsType,
} from '@app/app/page-modules/resource/store/admin/resource-event-admin.state.model';

export interface Playlist {
  /** The playlist UID. */
  _id: string;
  /** The playlist UID. */
  playlistId?: string;
  /** The playlist name. */
  title: string;
  /** The playlist name. */
  header?: string;
  /** The main(top) section of the playlist. */
  mainSection: PlaylistMainSection;
  /** The playlist standard sections. */
  standardSections: PlaylistStandardSection[];
  /** The playlist author details. */
  authorDetails: ResourceAuthorDetails;
  /** Defines the publication settings. */
  publicationSettings?: PlaylistPublicationSettings;
  lastModifiedDate?: string;
  syndicationActivationDate?: string;
  permission: ResourcePermission;
  uri: string;
  formattedUri: string;
  syndicated?: boolean;
  thumbnail?: string;
  image?: string;
  syndication?: {
    publisher: {
      organization: {
        name: string;
      };
    };
    license: { masterId?: string; permission?: ContentStorePlaylistPermissions };
    status: SyndicationStatus;
    activatedOn: Date;
    deactivatedOn: Date;
  };
  lastUpdatedOn: string;
  status: PlaylistPublishedStatus;
  adminRights: PlaylistAdminRights[];
  tags: ResourceTag[];
  feedTags?: ResourceTag[];
  categories: ResourceTag[];
  completed: boolean;
  completedOn: string | null;
  likes: LikeInfoDetails;
  favorite: boolean;
  embedded: boolean;
  type: PlaylistType;
  timeRequired?: number;
  subHeader: string;
  completedCardsCount: number;
  totalCardsCount: number;
  availableLanguages?: AvailableLanguage[];
  draft: PlaylistDraft;
  dataForms: DataFormSummary[];
  userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  enrollmentStatus?: UserEnrollmentStatus;
  mentorAccessToSubmissions?: boolean;
}

/** The main section of the playlist. */
export interface PlaylistMainSection {
  /** The cards included in the main section. */
  cards: PlaylistCardShort[];
  /** Any dynamic content. */
  content: EditorContent[];
  /** The url details of the main image. */
  imageUrl?: ImageUploadContent;
  /** The playlist name. */
  title: string;
  /** Main section cards layout type */
  cardsLayoutType: CardsLayoutType;
  /** The layout type */
  layoutType: PlaylistLayoutType;
  /** The playlist section UID. */
  uid: string;

  /** Frontend specific */
  imageToUpload?: File;
}

/** Defines the playlist section structure. */
export interface PlaylistStandardSection {
  /** The playlist section UID. */
  uid: string;
  /** The section title. */
  title?: string;
  /** The cards included in the section. */
  cards: PlaylistCardShort[];
  /** Section cards layout type */
  cardsLayoutType: CardsLayoutType;
  /** Any dynamic content. */
  content: EditorContent[];
  /** The layout type */
  layoutType?: string;
}

/** Defines default Playlist titles. */
export enum PlaylistDefaultText {
  START_HERE = 'Start here',
  CONTINUE = 'Continue',
  UPCOMING = 'Upcoming',
  YOUR_PLAYLIST = 'Your playlists',
}

/** Defines the playlist section structure. */
export interface FavoritePlaylist {
  /** The favorite playlist section image. */
  image: string;
  /** The favorite playlist id. */
  playlistId: string;
  /** The favorite playlist id. */
  id?: string;
  /** The section title. */
  title: string;
  organizationName: string;
  favorite: boolean;
  completed: boolean;
  uri: string;
  authorDetails?: ResourceAuthorDetails;
  likes?: LikeInfoDetails;
  thumbnail?: string;
  subHeader?: ResourceSubheader[];
  description?: string;
}

/** Defines the playlist search structure. */
export interface SearchPlaylist {
  description: string;
  subHeader: string;
  id: string;
  thumbnail?: string;
  title: string;
  uri: string;
  syndicated: boolean;
  hasAdminRights: boolean;
  status: PlaylistPublishedStatus;
}

export interface ReviewerDashboardPermission {
  accessForGroups: string[];
}

export interface ResourcePermission {
  canEdit: boolean | undefined;
  canPublish: boolean | undefined;
  canEditGoal?: boolean;
  canEditBadge?: boolean;
  canManageTargeting?: boolean;
  canViewAnalytics: boolean | undefined;
  canDoMemberExports: boolean | undefined;
  canManageCollaboration?: boolean | undefined;
  canClone?: boolean;
  reviewerDashboardPermission?: ReviewerDashboardPermission | undefined;
}

export interface FavoritePlaylistSummary {
  title: string;
  formattedUri: string;
  uri: string;
}

export interface LinkablePlaylistSummary {
  header: string;
  subHeader?: string;
  id: string;
  organizationLogo?: string;
  status?: { type: PlaylistPublishedStatus; on: Date; by: string };
  syndicated?: boolean;
  thumbnail?: string;
  formattedUri?: string;
  uri?: string;
}

export interface PlaylistPublicationSettings {
  /** Frontend specific. */
  published?: boolean;
  target?: TargetDetails;
  publishedDate?: Date;
  enrollment?: TargetEnrollmentDetails;
}

export interface TargetDetails {
  groups: PlaylistTargetGroup[];
  users: PlaylistTargetUser[];
  sectors?: string[];
}

export interface PlaylistTargetSector {
  code: string;
  name: string;
}

export interface PlaylistTargetUserOrGroup {
  _id?: string;
  title: string;
  type: PlaylistTargetUserOrGroupType;
}

export interface PlaylistTargetGroup {
  _id: string;
  name: string;
  forceSubscribe: boolean;
}

export interface PlaylistTargetUser {
  _id: string;
  name: string;
}

export interface PlaylistTargetUserOrGroupRemove {
  id: string;
  type: PlaylistTargetUserOrGroupType;
}

export interface PlaylistAdminRights {
  uid: string;
  firstName: string;
  lastName: string;
  email: string;
  permission: ResourcePermission;
  imageUrl: string;
  author: boolean;
  isEnabled: boolean;
  isWorkspaceAdmin: boolean;
}

export interface PlaylistAssociateAdmin {
  uid: string;
  name: string;
  permission: ResourcePermission;
}

export interface PlaylistDraft {
  editor: ResourceAuthorDetails;
  startedOn: Date;
  uri: string;
}

export interface UserPlaylistSubmissionSummary {
  lateSubmissions: boolean;
  requirementsMode: RequirementsMode;
  submissionDeadline: Date;
  submissionMode: UserSubmissionMode;
  submittedOn: Date;
  totalCompleted: number;
  totalRequired: number;
  userReviewSummary: UserReviewSummary;
  allowSubmissionDownload: boolean;
  hasAnyFormSubmitted: boolean;
  submissionsLocked: boolean;
}

export interface UserReviewSummary {
  reviewType: UserReviewType;
  reviewers: ReviewerDetails[];
  status?: ReviewStatusSummary;
  maxSelectableReviewers?: number;
  areReviewersFixed: boolean;
  canSelectNewReviewers: boolean;
}

export interface PlaylistSectionsReorder {
  dragIndex: number;
  dropIndex: number;
}

export type UserReviewType = 'AUTOMATIC' | 'REVIEWER' | 'PEER_REVIEW';

export type UserSubmissionMode = 'PROGRESSIVE' | 'SINGLE';

export type PlaylistTargetUserOrGroupType = 'GROUP' | 'MEMBER';

export type PlaylistType = 'STANDARD' | 'PERSONALITY_INDICATOR' | 'INSTITUTIONAL_LIBRARY' | 'PROJECT' | 'LTI_COURSE' | 'SCORM';

export type SyndicationStatus = 'ACTIVE' | 'INACTIVE';

export interface EnrollmentTicketsConfiguration {
  tickets?: EventTicketsDetails;
  time?: EventTime;
  reminders?: EventReminder[];
  participationCode?: string;
  eventLocation?: string;
}

/** Defines the Main section layout types. */
export type PlaylistLayoutType = 'TOP_BANNER' | 'ASIDE_BANNER';

export interface EnrollmentTicketsConfigurationDetails {
  eventLocation?: string;
  tickets?: EventTicketsDetails;
  time?: EventTime;
  reminders?: EventReminder[];
}

export interface EventTicketsDetails {
  availability?: EventTicketsType;
  quantity?: number;
  time?: EventTime;
  remainingTickets?: number;
  waitingList: boolean;
}

export interface EventTime {
  start: string; // Using string type for date format "yyyy-MM-dd HH:mm"
  end?: string;
  timeZone: string;
}

export interface TargetEnrollmentDetails {
  actionTrigger?: ActionTriggerDetails;
  mode: EnrollmentMode;
  groups?: PlaylistTargetGroup[];
}

export interface ActionTriggerDetails {
  mode: EnrollmentActionType;
  dataFormUid?: string;
  configuration?: EnrollmentTicketsConfigurationDetails;
}

export enum EnrollmentActionType {
  ENGAGEMENT = 'ENGAGEMENT',
  DATA_FORM = 'DATA_FORM',
  REQUEST_FOR_APPROVAL = 'REQUEST_FOR_APPROVAL',
  PAYMENT = 'PAYMENT',
  TICKET = 'TICKET',
  OPEN = 'OPEN',
  CUSTOM_GATE = 'CUSTOM_GATE',
  ADMIN_ENROLLED = 'ADMIN_ENROLLED',
}

export interface EnrollmentSettingsRequest {
  enrollmentMode: EnrollmentMode;
  enrollmentAction: EnrollmentActionType;
  dataFormUid?: string;
  configuration?: EnrollmentTicketsConfiguration;
  groups?: string[];
}

export interface EnrollmentSettingsUpdateRequest extends EnrollmentSettingsRequest {
  playlistId: string;
}

export enum EnrollmentMode {
  ANYONE = 'ANYONE',
  GROUP = 'GROUP',
  INVITE = 'INVITE',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export interface UserEnrollmentStatus {
  participationCode?: string;
  participationStatus: EventParticipationStatus;
  participationStatusOn?: string;
  waitingListSpot?: number;
  groups?: PlaylistTargetGroup[];
}
