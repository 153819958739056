<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div>
  <div class="question-aria" id="gridQuestionAria">
    {{ 'translations.gridQuestionAria' | transloco }}
  </div>

  <ptl-resource-preview class="question-description" [previewContent]="getPreviewContent()"> </ptl-resource-preview>

  <div class="question-card__options question-card__options-per-row question-card__options-per-row-{{ question?.optionsPerRow }}">
    <ng-container *ngFor="let option of options; index as i">
      <div
        class="question-card__option"
        [class.none-selected]="isNoneOfTheseSelected && !option.deselectOthers"
        tabindex="0"
        [attr.aria-label]="
          option.selected
            ? ('translations.global.ariaLabels.questionSelectedOption'
              | transloco: { number: i + 1, name: getTranslatedValue(option.values) })
            : ('translations.global.ariaLabels.questionOption' | transloco: { number: i + 1, name: getTranslatedValue(option.values) })
        "
        (click)="selectAnswer(option)"
        (keydown.space)="selectAnswer(option)"
        [class.is-selected]="option.selected"
        (keydown.enter)="selectAnswer(option)"
      >
        <span class="question-card-title">
          {{ getTranslatedValue(option.values) }}
        </span>

        <div class="question-card__option-info" *ngIf="option.instructions?.length && option.instructions[0].content !== ''">
          <ptl-resource-preview class="thin-preview" [previewContent]="option.instructions"></ptl-resource-preview>
        </div>
      </div>
    </ng-container>
  </div>
</div>
