import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'smartDateFormat',
})
export class SmartDateFormatPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  transform(value: Date, format: string): string {
    const date = new Date(value);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate()) {
      return this.translocoService.translate('translations.global.calendar.today');
    } else if (
      date.getFullYear() === yesterday.getFullYear() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getDate() === yesterday.getDate()
    ) {
      return this.translocoService.translate('translations.global.calendar.yesterday');
    } else {
      return new DatePipe('en-US').transform(value, format);
    }
  }
}
