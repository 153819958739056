import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlifyText',
})
export class UrlifyTextPipe implements PipeTransform {
  transform(text) {
    /* eslint-disable no-useless-escape */
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text?.replace(urlRegex, function (url) {
      return '<a href="' + url + '">' + url + '</a>';
    });
  }
}
