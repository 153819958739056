import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from 'src/app/page-modules/account/services/account-data.service';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from 'src/app/shared/services/toast-notifications/toast-service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  templateUrl: './users-csv-upload-dialog.component.html',
  styleUrls: ['./users-csv-upload-dialog.component.scss'],
})
export class UsersCsvUploadDialogComponent {
  csvFile: File;
  fileUploaded = false;
  filesHovered = false;
  uploadedFileName: string;
  csvHeader: string[];

  csvInvalid = false;
  isLoading = false;

  @Output() usersUploadFinished = new EventEmitter<{ file: File; headers: string[] }>();

  constructor(
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
    private dialogRef: MatDialogRef<UsersCsvUploadDialogComponent>,
    private translocoService: TranslocoService,
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  uploadUsers() {
    if (this.isLoading) {
      return;
    }
    this.csvInvalid = false;
    if (!this.csvFile) {
      this.csvInvalid = !this.csvFile;
      return;
    }
    if (!this.validCsvFile()) {
      this.csvInvalid = true;
      return;
    }

    this.usersUploadFinished.emit({ file: this.csvFile, headers: this.csvHeader });
    this.closeDialog();
  }

  onFilesDropped(fileList: FileList): void {
    if (fileList.length === 1) {
      this.uploadFile(fileList.item(0));
    } else {
      this.toastService.showFail(this.translocoService.translate('translations.uploadFileCountLimitError'));
    }
  }

  onFileChanged(eventData: Event): void {
    this.uploadFile((eventData.target as HTMLInputElement).files[0]);
  }

  trackByFn(index: number) {
    return index;
  }

  private validCsvFile() {
    /* eslint-disable */
    const pattern = '^.+\.(xlsx|xls|csv)$';
    /* eslint-enable */
    const csvRegexp = RegExp(pattern);
    return csvRegexp.test(this.csvFile.name);
  }

  private uploadFile(file: File) {
    this.csvInvalid = false;
    this.fileUploaded = true;
    if (file) {
      this.fileUploaded = true;
      this.uploadedFileName = file.name;
    } else {
      this.fileUploaded = false;
      this.uploadedFileName = '';
    }
    this.csvFile = file;

    this.readCSVFile(this.csvFile);
  }

  private readCSVFile(inputFile: File) {
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const content = e.target.result;

      if (typeof content === 'string') {
        const lines = content.split('\n');
        this.csvHeader = lines[0].split(',');
      }
    };

    // Read the file as text
    fileReader.readAsText(inputFile);
  }
}
