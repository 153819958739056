/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DATE_PICKER_FORMATS, DateRangeAdapter } from '@app/app/shared/utils/date-picker.utils';

@Component({
  selector: 'ptl-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: DateRangeAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_PICKER_FORMATS,
    },
  ],
})
export class DatePickerComponent {
  @Input() date: Date;
  @Input() maxDate: Date;

  @Output() dateChange = new EventEmitter<Date>();

  onDateChanged(): void {
    this.dateChange.emit(this.date);
  }
}
