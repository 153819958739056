/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpEvent, HttpUploadProgressEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, pairwise, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { UnauthenticateUser } from '../../user-auth/store/user-auth.actions';
import { CloseMobileNavMenu } from '../../app-frame/store/app-frame.actions';
import { UserAuthState } from '../../user-auth/store/user-auth.state';
import { Router, RoutesRecognized } from '@angular/router';
import { FINALIZATION_URL } from '../../user-auth/models';
import { AccessTokenService } from '../../user-auth/services/access-token.service';
import jwtDecode from 'jwt-decode';
import { REDIRECT_AFTER_LOGIN } from '../constants/constants';

/**
 * Service for calling the API.
 */
@Injectable()
export class RestClientService {
  private afterLoginCurrentUrl: string;
  private wasUnAuthenticated: boolean;

  constructor(
    private httpClient: HttpClient,
    private store: Store,
    private router: Router,
    private accessTokenService: AccessTokenService,
  ) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */

  /**
   * Makes a GET request.
   *
   * @param url - the URL to call.
   * @param params - the query parameters, optional.
   * @param headers - the request headers, optional.
   * @returns an observable which completes with the request.
   */
  get<T>(url: string, params?: { [name: string]: string }, headers?: { [name: string]: string | string[] }): Observable<HttpResponse<T>> {
    // Create http headers, using Auth header and cache headers
    const httpHeaders = this.mergeHeaders(headers ? new HttpHeaders(headers) : undefined);

    return this.observableWithRetry<T>(() =>
      this.httpClient.get<T>(url, {
        headers: httpHeaders,
        params: params,
        observe: 'response',
      }),
    );
  }

  /**
   * Makes a POST request.
   *
   * @param url - the URL to call.
   * @param body - the request body, optional.
   * @param params - the query parameters, optional.
   * @param headers - the request headers, optional.
   * @returns an observable which completes with the request
   */
  post<T = any>(
    url: string,
    body?: any,
    params?: { [name: string]: string },
    headers?:
      | string
      | {
          [name: string]: string | string[];
        },
    responseType?: any,
  ): Observable<HttpResponse<T>> {
    // Create http headers, using Auth header and cache headers
    const httpHeaders = this.mergeHeaders(headers ? new HttpHeaders(headers) : undefined);

    return this.observableWithRetry<T>(() => {
      return this.httpClient.post<T>(url, body, {
        observe: 'response',
        headers: httpHeaders,
        params: params,
        responseType: responseType,
      });
    });
  }

  /**
   * Makes a PATCH request.
   *
   * @param url - the URL to call.
   * @param body - the request body, optional.
   * @param params - the query parameters, optional.
   * @param headers - the request headers, optional.
   * @returns an observable which completes with the request
   */
  patch<T = any>(
    url: string,
    body?: any,
    params?: { [name: string]: string },
    headers?:
      | string
      | {
          [name: string]: string | string[];
        },
  ): Observable<HttpResponse<T>> {
    // Create http headers, using Auth header and cache headers
    const httpHeaders = this.mergeHeaders(headers ? new HttpHeaders(headers) : undefined);

    return this.observableWithRetry<T>(() =>
      this.httpClient.patch<T>(url, body, {
        observe: 'response',
        headers: httpHeaders,
        params: params,
      }),
    );
  }

  /**
   * Makes a DELETE request.
   *
   * @param url - the URL to call.
   * @param params - the query parameters, optional.
   * @param headers - the request headers, optional.
   * @param body - the request body, optional.
   * @returns an observable which completes with the request
   */
  delete<T = any>(
    url: string,
    params?: { [name: string]: string },
    headers?: { [name: string]: string | string[] } | HttpHeaders,
    body?: any,
  ): Observable<HttpResponse<T>> {
    return this.observableWithRetry<T>(() =>
      this.httpClient.delete<T>(url, {
        observe: 'response',
        headers,
        params: params,
        body: body,
      }),
    );
  }

  /**
   * Makes a PUT request.
   *
   * @param url - the URL to call.
   * @param body - the request body, optional.
   * @param params - the query parameters, optional.
   * @param headers - the request headers, optional.
   * @returns an observable which completes with the request
   */
  put<T = any>(
    url: string,
    body?: any,
    params?: { [name: string]: string },
    headers?: { [name: string]: string | string[] } | HttpHeaders,
    responseType?: any,
  ): Observable<HttpResponse<T>> {
    return this.observableWithRetry<T>(() =>
      this.httpClient.put<T>(url, body, {
        observe: 'response',
        headers,
        params: params,
        responseType: responseType,
      }),
    );
  }

  putWithReportProgress<T = any>(
    url: string,
    body?: any,
    params?: { [name: string]: string },
    headers?: { [name: string]: string | string[] } | HttpHeaders,
    responseType?: any,
    reportProgress?: boolean,
  ): Observable<HttpEvent<HttpUploadProgressEvent>> {
    return this.httpClient.put<HttpUploadProgressEvent>(url, body, {
      observe: 'events',
      headers,
      params,
      responseType,
      reportProgress,
    });
  }

  /**
   * Merges different headers into a single headers instance.
   *
   * @param headersToMerge - the headers to merge.
   * @returns the merged headers instance.
   */
  private mergeHeaders(...headersToMerge: HttpHeaders[]): HttpHeaders {
    let mergedHeaders = new HttpHeaders();
    headersToMerge
      .filter((headers) => !!headers)
      .forEach(
        (headers) =>
          (mergedHeaders = headers
            .keys()
            .map((key) => ({ key, value: headers.get(key) }))
            .reduce((headersAcc, header) => headersAcc.set(header.key, header.value), mergedHeaders)),
      );

    return mergedHeaders;
  }

  /**
   * Adds retry logic ot the request function.
   * If the request fails with error code 401 or 403(i.e. unauthorized, possibly because of token expiry)
   * the logic will try to rauthenticate the user and send the request again.
   *
   * If the reauthentication fails or the request fails again user will be logged out.
   *
   * @param requestFunction contains the request logic
   */
  private observableWithRetry<T>(requestFunction: () => Observable<HttpResponse<T>>): Observable<HttpResponse<T>> {
    return requestFunction().pipe(
      catchError((error: any) => {
        this.router.events
          .pipe(
            filter((evt: any) => evt instanceof RoutesRecognized),
            pairwise(),
          )
          .subscribe((events: RoutesRecognized[]) => {
            this.afterLoginCurrentUrl = events[1].urlAfterRedirects;
          });

        if (error.status !== 401) {
          this.wasUnAuthenticated = false;
        }

        if (!error.url.includes('/logout') && error.status === 401) {
          let redirectUri: string;
          if (error.url.includes(FINALIZATION_URL)) {
            const decodedToken: { email: string } = jwtDecode(this.accessTokenService.getAccessToken());
            redirectUri = 'unverified-email';
            if (decodedToken && decodedToken.email) {
              redirectUri += `?email=${decodedToken.email}`;
            }
          } else {
            if (!localStorage.getItem(REDIRECT_AFTER_LOGIN)) {
              UserAuthState.setPreviousUrlAfterLogin(this.afterLoginCurrentUrl);
            }
          }
          if (!this.wasUnAuthenticated) {
            this.wasUnAuthenticated = true;
            return this.store.dispatch(new UnauthenticateUser(redirectUri)).pipe(
              switchMap(() => {
                this.store.dispatch(new CloseMobileNavMenu());
                return throwError(error);
              }),
            );
          } else {
            return throwError(error);
          }
        }
        return throwError(error);
      }),
    );
  }
}
