/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

import { EditorContent, LanguageValue } from '../../../shared/models';
import { defaultLoadableState, LoadableState } from '../../../shared/store';
import { SocialShareType } from '@app/app/shared/models/public-share/public-share.model';
import { FolioLearningExperience } from '../../learning-experiences/learning-experience.model';

export const INITIAL_FOLIO_STATE: FolioStateModel = {
  folio: defaultLoadableState(),
  displayHiddenItems: true,
  folioMomentsUpdate: true,
};

export interface FolioStateModel {
  folio: LoadableState<FolioModel>;
  displayHiddenItems: boolean;
  folioMomentsUpdate: boolean;
}

export interface FolioModel {
  _id: string;
  socialHandles: string[];
  publicId: string;
  moments: string[];
  isLive: boolean;
  learningHistory: string[];
  bio: FolioUserBio;
  badges: FolioBadges[];
  sections: FolioSection[];
  coverImageUrl: string;
}

export interface FolioUserBio {
  uid: string;
  firstName: string;
  lastName: string;
  introduction: string;
  email: string;
  organizationName: string;
  profileImage: string;
  userWorkspaceUid: string;
}

export interface FolioBadges {
  badgeId: string;
  badgeUid?: string;
  organizationId: string;
  imageUrl: string;
  issuedOn: Date;
  userBadgeUid?: string;
  issuedBy: string;
  orgLogo?: string;
  color?: string;
  title: LanguageValue[];
  userWorkspacesUid: string;
  visible: boolean;
  issuedBadge?: FolioBadges;
}

export interface PublicFolioBadge {
  color: string;
  description: EditorContent[];
  imageUrl: string;
  issuedBy: string;
  issuedOn: Date;
  orgLogo: string;
  publisher: string;
  title: string;
  uid: string;
  userImageUrl: string;
  userName: string;
  liveFolio: boolean;
  publicFolioHandle: string;
  issuerDomain: string;
  completedCriteriaDetails: CompleteCriteria;
  userUid: string;
}

export interface CompleteCriteria {
  headline: string;
  thumbnail: string;
  cardType: string;
  completedOn: Date;
  includedCards: CompleteCriteria[];
}

export enum FolioSectionType {
  GROWTH_TRACKER = 'GROWTH_TRACKER',
  MOMENTS = 'MOMENTS',
  PROJECTS = 'PROJECTS',
  ACHIEVEMENTS = 'ACHIEVEMENTS',
  EVENTS = 'EVENTS',
  LEARNING_EXPERIENCES = 'LEARNING_EXPERIENCES',
}

export enum FolioSectionItemType {
  GROWTH_TRACKER = 'tags',
  MOMENTS = 'moments',
  PROJECTS = 'projects',
  ACHIEVEMENTS = 'badges',
  CERTIFICATES = 'certificates',
  EVENTS = 'EVENTS',
}

export interface AchievementCertificateBlock {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  block?: any;
  uid: string;
  visible: boolean;
  name?: string;
}

export interface AchievementCertificate {
  filename: string | null;
  thumbnail: string | null;
  url: string | null;
  visible: boolean;
  uid: string;
  type: string;
  code: string;
}

export interface FolioSection {
  type: FolioSectionType;
  isVisible: boolean;
  isEditable: boolean;
  uid: string;
}

export const VALID_FOLIO_SECTIONS = [
  FolioSectionType.GROWTH_TRACKER,
  FolioSectionType.MOMENTS,
  FolioSectionType.ACHIEVEMENTS,
  FolioSectionType.PROJECTS,
  FolioSectionType.LEARNING_EXPERIENCES,
];

export interface GrowthTrackerSection extends FolioSection {
  type: FolioSectionType.GROWTH_TRACKER;
  tags: string[];
}

export interface FolioMomentsSection extends FolioSection {
  type: FolioSectionType.MOMENTS;
  moments: string[];
}

export interface FolioProjectsSection extends FolioSection {
  type: FolioSectionType.PROJECTS;
  projects: string[];
}

export interface FolioAchievementsSection extends FolioSection {
  type: FolioSectionType.ACHIEVEMENTS;
  badges: FolioBadges[];
  certificates: AchievementCertificateBlock[];
}

export interface FolioEventsSection extends FolioSection {
  type: FolioSectionType.EVENTS;
  events: string[];
}

export interface FolioLearningExperiencesSection extends FolioSection {
  type: FolioSectionType.LEARNING_EXPERIENCES;
}

export interface FolioShowcaseRequest {
  isLive?: boolean;
  shortDescription?: string;
  publicId?: string;
}

export interface FolioCoverUpdateRequest {
  imageName: string;
  contentLength: number;
}

export interface FolioSectionRequest {
  type?: FolioSectionType;
  isVisible?: boolean;
  uid?: string;
}

export interface GrowthTrackerSectionRequest extends FolioSectionRequest {
  type?: FolioSectionType.GROWTH_TRACKER;
  tags?: string[];
}

export interface MomentsSectionRequest extends FolioSectionRequest {
  type?: FolioSectionType.MOMENTS;
  moments?: string[];
}

export interface ProjectsSectionRequest extends FolioSectionRequest {
  type?: FolioSectionType.PROJECTS;
  projects?: string[];
}

export interface AchievementsSectionRequest extends FolioSectionRequest {
  type?: FolioSectionType.ACHIEVEMENTS;
  badges?: FolioBadges[];
}

export interface LearningExperiencesSectionRequest extends FolioSectionRequest {
  type: FolioSectionType.LEARNING_EXPERIENCES;
}

export interface UploadCertificateRequest {
  uid: string;
  dynamicContent: EditorContent[];
}

export interface FolioSectionItemRequest {
  type: FolioSectionItemType;
  uid: string;
}

export interface FolioMyActivity {
  template: string;
  tags: ActivityTag[];
  type: string;
  createdAt: Date;
}

export interface ActivityTag {
  id: string;
  title: string;
}

export interface FolioShareSocialItem {
  name: SocialShareType;
  title: string;
}
