/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { ObservableResult } from '../../../../shared/store';
import { AllContentDataService } from './all-content-data.service';
import { Page } from '../../../../shared/models/page';
import { AllContentCard, AllContentPage, AllContentPlaylist } from '../../../../shared/models/all-content/all-content.model';

@Injectable()
export class MockAllContentDataService implements AllContentDataService {
  loadAllPages(page: number, size: number, term: string): ObservableResult<Page<AllContentPage>> {
    return ObservableResult.ofSuccess();
  }

  loadAllPlaylists(page: number, size: number, term: string): ObservableResult<Page<AllContentPlaylist>> {
    return ObservableResult.ofSuccess();
  }

  loadAllCards(page: number, size: number, term: string): ObservableResult<Page<AllContentCard>> {
    return ObservableResult.ofSuccess();
  }
}
