import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'disableTag',
})
export class DisableTagPipe implements PipeTransform {
  transform(tagId: string, selectedTagIds: string[], isDisabled = false): boolean {
    return isDisabled ? selectedTagIds.includes(tagId) : false;
  }
}
