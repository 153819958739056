/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Playlist, Resource, ResourceTag } from '../../../../shared/models';
import { CurrentLanguage } from '../../../../shared/models/languages/languages.model';

export interface ResourceAdminStateModel {
  resource: Resource;
  saveStatus: AutoSaveStatus;
  parentPlaylist?: Playlist;
  filteredTags?: ResourceTag[];
  currentLanguage: CurrentLanguage;
  contentChanged?: boolean;
}

export type AutoSaveStatusFailureReason = 'duplication' | 'error' | '';

export interface AutoSaveStatus {
  autoSaveInProgress: boolean;
  saveFailureReason?: AutoSaveStatusFailureReason;
}

export const INITIAL_STATE: ResourceAdminStateModel = {
  resource: undefined,
  saveStatus: {
    autoSaveInProgress: false,
    saveFailureReason: '',
  },
  filteredTags: [],
  parentPlaylist: undefined,
  currentLanguage: undefined,
  contentChanged: false,
};
