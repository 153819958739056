<!--
  ~ Copyright (C) 2025 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<mat-dialog-content>
  <div class="help-dialog__content">
    <div class="top-row">
      <p>{{ 'translations.global.label.helpLabel' | transloco }}</p>
      <button class="close-btn" (click)="closeDialog()" (keydown.enter)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <table class="metrics-table" *ngIf="filteredMetrics && filteredMetrics.length > 0">
      <tr>
        <th>{{ 'translations.analytics.title.metricLabel' | transloco }}</th>
        <th>{{ 'translations.sideNav.title.description' | transloco }}</th>
      </tr>
      <tr *ngFor="let metric of filteredMetrics">
        <td>{{ metric.label }}</td>
        <td>{{ metric.description }}</td>
      </tr>
    </table>

    <div class="faq-section">
      <p class="faq-title">{{ 'translations.analytics.title.faq' | transloco }}</p>

      <div class="faq-item" *ngFor="let faq of filteredFaqs; let i = index">
        <div class="faq-question" (click)="toggleFAQ(i)" (keydown.enter)="toggleFAQ(i)">
          <mat-icon>{{ faq.open ? 'expand_less' : 'expand_more' }}</mat-icon>
          {{ faq.question }}
        </div>
        <div class="faq-answer" *ngIf="faq.open" [innerHTML]="faq.answer">
          {{ faq.answer }}
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
