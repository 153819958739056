<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ptl-tinymce-editor-dir
  #tinyEditor
  class="tinymce-editor"
  [class.is_first]="firstEditor"
  [options]="editorOptions"
  [editorPlaceholder]="this.firstEditor ? placeholder : ''"
  [(editorModel)]="editorInput"
  [lastEditor]="lastEditor"
>
</ptl-tinymce-editor-dir>
