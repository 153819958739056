/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { ReviewNoteUpdateRequest } from '../../models/admin/members.model';
import { ObservableResult } from '../../store';
import { SharedReviewDataService } from './review-data.service';

@Injectable()
export class MockSharedReviewDataService implements SharedReviewDataService {
  reviewFormReSubmit(reviewId: string, cardUid: string, formUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  reviewApprove(reviewId: string, cardUid: string, itemId: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  unlockForm(cardUid: string, formId: string, learnerCardUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  reviewAddNote(reviewId: string, cardUid: string, itemUid: string, request: ReviewNoteUpdateRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  remindToSubmitForm(reviewUid: string, cardUid: string, formUid: string, message: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
