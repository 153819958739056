/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { Injectable } from '@angular/core';
import { CollectorDataService } from './collector-data.service';
import {
  Collector,
  CollectorUpdateRequest,
  LearnerCollectorSummary,
  LearnerLoggedItemRequest,
  LearnerLoggedItemSummary,
} from '../../models/editor/collector-content.model';
import { SubmitPlaylistSummary } from '../../models/playlist/playlist-report.model';

@Injectable()
export class MockCollectorDataService implements CollectorDataService {
  updateCollector(cardUid: string, collectorUid: string, request: CollectorUpdateRequest): ObservableResult<Collector> {
    return ObservableResult.ofSuccess();
  }

  getCollector(cardUid: string, collectorUid: string): ObservableResult<Collector> {
    return ObservableResult.ofSuccess();
  }

  deleteCollector(cardUid: string, collectorUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  getLearnerCollectorSummary(playlistUid: string, collectorUid: string): ObservableResult<LearnerCollectorSummary> {
    return ObservableResult.ofSuccess();
  }

  getLearnerCollectorSummaryAsReviewer(
    playlistUid: string,
    collectorUid: string,
    reviewUid: string,
  ): ObservableResult<LearnerCollectorSummary> {
    return ObservableResult.ofSuccess();
  }

  addLogItem(learnerCollectorUid: string, request: LearnerLoggedItemRequest): ObservableResult<LearnerLoggedItemSummary> {
    return ObservableResult.ofSuccess();
  }

  deleteLogItem(learnerCollectorUid: string, learnerEvidenceUid: string): ObservableResult<LearnerLoggedItemSummary> {
    return ObservableResult.ofSuccess();
  }

  updateLogItem(
    learnerCollectorUid: string,
    learnerEvidenceUid: string,
    request: LearnerLoggedItemRequest,
  ): ObservableResult<LearnerLoggedItemSummary> {
    return ObservableResult.ofSuccess();
  }

  submitLearnerCollectorSummary(playlistUid: string, learnerCollectorUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  submitLearnerCollectorSummaryAndPlaylist(
    playlistUid: string,
    learnerCollectorUid: string,
    request: SubmitPlaylistSummary,
  ): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
