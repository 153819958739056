<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div *ngIf="reviewEvents?.length > 0" class="chips-list" [matMenuTriggerFor]="reviewEventsListPopup">
  <div class="chip-item chip-interactive pointer no-margin">
    <span class="chip-text">{{ 'translations.cards.form.footer.reviewEvents.title' | transloco: { count: reviewEvents.length } }}</span>
  </div>
</div>
<mat-menu class="mat-menu" #reviewEventsListPopup="matMenu">
  <div class="review-event-list-content">
    <ptl-review-event
      *ngFor="let reviewEvent of reviewEvents"
      [ngSwitch]="reviewEvent.type"
      [reviewEvent]="reviewEvent"
      [playlistSubmissionDeadline]="playlistSubmissionDeadline"
      [userUid]="userUid"
    >
    </ptl-review-event>
  </div>
</mat-menu>
