<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<mat-card
  *ngIf="!!event"
  role="listitem"
  class="card-box"
  (click)="onCardClick()"
  (keydown.enter)="onCardClick()"
  [attr.aria-label]="'translations.global.ariaLabels.open' | transloco: { title: event.headline }"
  tabindex="0"
>
  <div class="card-box-image-box">
    <ng-container *ngIf="event.imageUrl; else placeholderImage">
      <img class="card-box-image" alt="" width="270" height="150" [src]="event.imageUrl" />
    </ng-container>
    <ng-template #placeholderImage>
      <img
        class="card-box-image card-box-image-placeholder"
        alt=""
        width="270"
        height="150"
        [src]="'/assets/card-placeholders/card-placeholder-' + ((index % 4) + 1) + '.png'"
      />
    </ng-template>

    <div class="card-box-author-box">
      <mat-icon
        class="dummy-icon"
        aria-hidden="true"
        *ngIf="!authorDetails?.displayConfig.organizationName && !authorDetails?.displayConfig.authorName"
      >
        insert_drive_file_outline
      </mat-icon>
      <ng-container *ngIf="authorDetails?.displayConfig.organizationName || authorDetails?.displayConfig.authorName">
        <img
          class="author-image"
          alt=""
          width="50"
          height="50"
          [src]="authorDetails.displayConfig.organizationName ? authorDetails.orgLogoUrl : authorDetails.userImageUrl"
          *ngIf="(authorDetails.userImageUrl && authorDetails.displayConfig.authorName) || authorDetails.displayConfig.organizationName"
        />
        <div
          tabindex="0"
          [attr.aria-label]="authorDetails.name"
          [title]="authorDetails.name"
          class="author-initials"
          *ngIf="!authorDetails.userImageUrl && authorDetails.displayConfig.authorName && !authorDetails.displayConfig.organizationName"
        >
          {{ authorDetails.name.charAt(0) }}
        </div>
      </ng-container>
    </div>
  </div>

  <div class="card-box-info-box">
    <div class="card-box-title small bold" [title]="event.headline" [attr.aria-label]="event.headline">
      {{ event.headline }}
    </div>

    <div *ngIf="eventText" class="card-box-event-title extra-small" [title]="eventText" [attr.aria-label]="eventText">
      <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.calendar' | transloco">
        <use ogSvgUnify="assets/sprite/sprite.svg#calendar"></use>
      </svg>
      {{ eventText }}
    </div>
  </div>

  <div class="card-box-footer-box">
    <div class="card-box-progress-box"></div>

    <div class="card-box-buttons">
      <ptl-like-info-new
        class="card-box__like-info"
        [likeDetails]="event.likesDetails"
        [infoType]="'CARD'"
        [title]="event.headline"
        [uid]="event.uid"
        [editMode]="false"
      >
      </ptl-like-info-new>
    </div>
  </div>
</mat-card>
