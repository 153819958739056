/*
 * Copyright (C) 2024 - present by Potentially
 *
 * Please see distribution for license.
 */

export enum RoleFilterTypes {
  ORGANIZATION = 'ORGANIZATION',
  MEMBERS = 'MEMBERS',
}

export interface ExploreSection {
  name: string;
  title: string;
}
