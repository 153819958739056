/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BookingFormContent, Form, UserPlaylistSubmissionSummary } from '@app/app/shared/models';
import { RefreshLearnerViewCardStatus, SaveFormAnswer, SubmitForm } from '@app/app/page-modules/resource/store/learner-view.actions';
import { Store } from '@ngxs/store';
import { Subscription, timer } from 'rxjs';
import { DialogService } from '@app/app/shared/helpers/dialog/dialog.service';
import { FormSavedEvent } from '@app/app/shared/components/resource-preview/form-preview/form-preview-event.model';
import * as PlaylistViewActions from '@app/app/page-modules/playlist/store/view/playlist-view.state.actions';
import { ContentHelper } from '@app/app/shared/helpers/content-helper';
import { LocalTimeHelper } from '@app/app/shared/helpers/local-time-helper';
import { LearnerFormAnswer } from '@app/app/page-modules/resource/models';
import { BOOKING_DATA_SERVICE, BookingDataService } from '@app/app/shared/services/booking/booking-data.service';
import * as moment from 'moment';
import { AvailableSlot, BookableSlot, BookedAppointment, BookingRequest } from '@app/app/shared/models/editor/booking-content.model';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from '@app/app/shared/services/toast-notifications/toast-service';
import { TranslocoService } from '@ngneat/transloco';
import { LEARNER_VIEW_DATA_SERVICE, LearnerViewDataService } from 'src/app/page-modules/resource/services/data.service';
import { CalendarHelper } from '@app/app/shared/helpers/calendar-helper';

@Component({
  selector: 'ptl-form-preview-booking',
  templateUrl: './form-booking.component.html',
  styleUrls: ['./form-booking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPreviewBookingComponent implements OnInit, OnChanges, OnDestroy {
  /** Receives the booking type object */
  @Input() formData: Form<BookingFormContent>;
  @Input() learnerFormAnswer: LearnerFormAnswer;
  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  @Input() playlistUri: string;
  @Input() playlistUid: string;
  @Input() resourceUri: string;
  @Input() resourceUid: string;
  @Input() publisherUri: string;
  @Input() packageUri: string;
  @Input() pageUri: string;
  @Input() languageCode: string;

  existingUserAnswer: boolean;
  submitted = false;
  canEdit = false;
  canNotEditReason: string;
  userAnswer = 'false';
  isChecked = false;
  defaultFrom = moment().format('YYYY-MM-DD');
  defaultTo = moment().add(32, 'days').format('YYYY-MM-DD');
  availableSlots: AvailableSlot[] = [];
  availableResourceSlots: BookableSlot[] = [];
  chosenBookableResourceUid: string = null;
  chosenStart: string;
  chosenEnd: string;
  calendarActiveDate: Date = null;
  @ViewChild('calendar') calendar: MatCalendar<Date>;
  visibleSlots: BookableSlot[] = [];
  bookedAppointment: BookedAppointment = null;
  bookableAppointmentUid: string = null;
  bookingName: string;
  isLoading = false;
  hasError = false;
  hasAppointmentLoadingError = false;
  hasBookingPlacementError = false;
  canReBook = false;
  validationInProgress = false;
  validationProcessed = false;
  exportSubscription: Subscription;
  private formSubmissionSubscription: Subscription;
  private bookingPlacementSubscription: Subscription;
  private bookingCancellationSubscription: Subscription;
  private slotsRetrievalSubscription: Subscription;
  private bookingLoadingSubscription: Subscription;
  private appointmentLoadingSubscription: Subscription;

  constructor(
    private store: Store,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    @Inject(BOOKING_DATA_SERVICE) private bookingDataService: BookingDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
    @Inject(LEARNER_VIEW_DATA_SERVICE) private dataService: LearnerViewDataService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    const content = this.formData?.content as BookingFormContent;
    if (content?.bookableAppointmentId == null) {
      // TODO: Then hide the form completely
    } else {
      this.userAnswer = content.userAnswer;
      this.bookableAppointmentUid = content.bookableAppointmentId;
      this.submitted = this.userPlaylistSubmissionSummary?.submittedOn ? true : content.submitted;

      if (content.userAnswer?.length > 0) {
        this.existingUserAnswer = true;
        this.onRequestProcessing();
        this.loadBooking(content.userAnswer, content.bookableAppointmentId);
      } else {
        this.onRequestProcessing();
        this.loadSlots(content.bookableAppointmentId);
      }
    }
    this.checkSubmissionMode();

    if (content?.bookableAppointmentId) {
      this.loadBookingAppointment(content.bookableAppointmentId);
    }
  }

  ngOnDestroy() {
    this.formSubmissionSubscription?.unsubscribe();
    this.bookingPlacementSubscription?.unsubscribe();
    this.bookingCancellationSubscription?.unsubscribe();
    this.slotsRetrievalSubscription?.unsubscribe();
    this.bookingLoadingSubscription?.unsubscribe();
    this.appointmentLoadingSubscription?.unsubscribe();
    this.exportSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.playlistUid && this.resourceUid && this.formData?.uid) {
      this.checkSubmissionMode();
    } else {
      if (changes.learnerFormAnswer || changes.userPlaylistSubmissionSummary) {
        this.checkSubmissionMode();
      }
    }
  }

  onCheckboxChange() {
    const event: FormSavedEvent = {
      formUid: this.formData.uid,
      answer: this.isChecked ? 'true' : 'false',
    };
    this.existingUserAnswer = true;
    this.store.dispatch(new SaveFormAnswer(this.playlistUid, event));
  }

  submit(): void {
    this.submitted = true;
    this.checkSubmissionMode();
    this.store
      .dispatch(new SubmitForm(this.playlistUid, this.formData.uid))
      .toPromise()
      .then(() => {
        this.formSubmissionSubscription = timer(1000).subscribe(() => {
          this.store.dispatch(new PlaylistViewActions.RefreshUserPlaylistSubmissionSummary(this.playlistUid));
          this.store.dispatch(
            new RefreshLearnerViewCardStatus(
              this.playlistUri,
              this.resourceUri,
              this.publisherUri,
              this.packageUri,
              this.pageUri,
              this.languageCode,
            ),
          );
        });
      });
  }

  getLocalDateTime(date: Date): string {
    return LocalTimeHelper.getLocalDateTime(date).toString();
  }

  placeBooking(event: MouseEvent) {
    // TODO placing booking should submit form, why it's only saving answer? should we ignore here validation?
    // maybe we should allow only cancelation of booking after e.g submission of playlist or deadline pass @rafal
    event.preventDefault();
    const bookingRequest = this.prepareBookingRequest();
    const bookableResourceUid = this.chosenBookableResourceUid;
    this.onRequestProcessing();
    this.bookingPlacementSubscription = this.bookingDataService
      .placeBooking(bookableResourceUid, bookingRequest, this.languageCode)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.bookedAppointment = value;
          this.isLoading = false;
          this.store
            .dispatch(
              new SaveFormAnswer(this.playlistUid, {
                formUid: this.formData.uid,
                answer: value.id,
              }),
            )
            .toPromise()
            .then(() => {
              this.submit();
            });
          this.existingUserAnswer = true;
          this.toastService.showSuccess(this.translocoService.translate('translations.bookings.label.success.bookingPlaced'));
          this.canReBook = false;
          this.chosenBookableResourceUid = null;
          this.chosenStart = null;
          this.chosenEnd = null;
          this.cd.detectChanges();
        } else {
          this.onBookingPlacementError();
        }
      });
  }

  onCalendarChange(event) {
    this.activateDay(event);
  }

  get isSubmissionDisabled(): boolean {
    return !this.existingUserAnswer;
  }

  chooseBookableResource(bookableResourceUid: string) {
    this.chosenBookableResourceUid = bookableResourceUid;
    this.availableResourceSlots = this.availableSlots.find((value) => value.resource.bookableResourceUid === bookableResourceUid).slots;

    this.setResourceFirstDate();

    if (this.availableResourceSlots.length && !this.visibleSlots.length) {
      const firstFeatureDay = this.availableResourceSlots.find((item) => moment(item.startsAt).isAfter());
      if (firstFeatureDay) {
        const startDay = moment(firstFeatureDay.startsAt).date();
        setTimeout(() => {
          const highlightedDates = document.querySelectorAll('.highlighted-date');
          for (let i = 0; i < highlightedDates.length; i++) {
            const dateItem = highlightedDates[i];
            if (dateItem.getAttribute('aria-label').startsWith('' + startDay)) {
              (dateItem as HTMLElement).click();
              break;
            }
          }
        }, 0);
      }
    }
  }

  chooseAvailableDateTimeSlot(start: string, end: string) {
    this.chosenStart = start;
    this.chosenEnd = end;
  }

  dateClass = (date: Date): MatCalendarCellCssClasses => {
    if (this.calendarActiveDate !== null && this.isSameDate(this.calendarActiveDate, date)) {
      return 'activated-date';
    } else if (this.availableResourceSlots?.length > 0) {
      return this.availableResourceSlots.map((value) => new Date(value.startsAt)).some((d) => this.isSameDate(d, date))
        ? 'highlighted-date'
        : '';
    } else {
      return '';
    }
  };

  isSameDate(date1: Date, date2: Date): boolean {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
  }

  backToCalendarView() {
    this.calendarActiveDate = null;
    this.chosenStart = null;
    this.chosenEnd = null;
  }

  backToResourcesView() {
    this.calendarActiveDate = null;
    this.chosenStart = null;
    this.chosenEnd = null;
    this.chosenBookableResourceUid = null;
  }

  get getBookedAppointmentDuration(): string {
    if (this.bookedAppointment) {
      const startTime = moment(this.bookedAppointment.startsAt);
      const endTime = moment(this.bookedAppointment.endsAt);

      const duration = moment.duration(endTime.diff(startTime));
      const hours = duration.hours();
      const minutes = duration.minutes();
      let message = '';
      if (hours) {
        message += `${hours}h `;
      }
      if (minutes) {
        message += `${minutes}m `;
      }
      return message;
    }
    return '';
  }

  cancelBooking(bookingUid: string) {
    this.onRequestProcessing();
    this.bookingCancellationSubscription = this.bookingDataService
      .cancelBooking(bookingUid, this.languageCode)
      .subscribe(({ isSuccess }) => {
        if (isSuccess) {
          this.canReBook = false;
          this.availableSlots = [];
          this.loadBooking(this.bookedAppointment.id, this.bookableAppointmentUid);
          this.toastService.showSuccess(this.translocoService.translate('translations.bookings.label.success.bookingCanceled'));
        } else {
          this.onRequestError();
        }
        this.isLoading = false;
      });
  }

  enableReBooking() {
    this.canReBook = true;
    this.isLoading = true;
    if (this.availableSlots.length > 0) {
      this.setResourceFirstDate();
      this.cd.detectChanges();
    } else {
      this.loadSlots(this.bookableAppointmentUid);
    }
  }

  toDate(momentDate: string): Date {
    return moment(momentDate).toDate();
  }

  downloadEvent(bookingUid: string) {
    this.isLoading = true;
    this.exportSubscription = this.bookingDataService.getBookingICalEvent(bookingUid).subscribe(
      (data) => {
        CalendarHelper.prepareICalFileDownload(data, 'booking-event');
        this.isLoading = false;
        this.cd.detectChanges();
      },
      (error) => {
        this.toastService.showFail(this.translocoService.translate('translations.bookings.label.errors.eventExportError'));
        console.error('Error downloading the ICS file:', error);
      },
    );
  }

  private loadSlots(bookableAppointmentId: string) {
    this.slotsRetrievalSubscription = this.bookingDataService
      .getBookableSlots(bookableAppointmentId, this.defaultFrom, this.defaultTo, this.languageCode)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.availableSlots = value;
          if (value.length > 0) {
            this.chooseBookableResource(value[0].resource.bookableResourceUid);
          }
          if (this.calendar) {
            this.calendar.updateTodaysDate();
          }
          this.cd.detectChanges();
        } else {
          this.onAppointmentLoadingError();
        }
        this.isLoading = false;
      });
  }

  private loadBooking(bookingUid: string, bookableAppointmentId: string) {
    this.bookingLoadingSubscription = this.bookingDataService
      .getBooking(bookingUid, this.languageCode)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.isLoading = false;
          this.bookedAppointment = value;
          if (this.bookedAppointment?.isCanceled && this.canEdit) {
            this.loadSlots(bookableAppointmentId);
          }
          this.cd.detectChanges();
        } else {
          this.onAppointmentLoadingError();
        }
      });
  }

  private loadBookingAppointment(appointmentId: string) {
    this.appointmentLoadingSubscription = this.bookingDataService
      .getBookableAppointment(appointmentId, this.languageCode)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.bookingName = value.name;
          this.cd.detectChanges();
        }
      });
  }

  private setResourceFirstDate() {
    if (!this.calendar) {
      return;
    }
    this.calendar.updateTodaysDate();
    if (this.availableResourceSlots.length > 0) {
      this.calendar.activeDate = new Date(this.availableResourceSlots[0].startsAt);
      this.activateDay(new Date(this.availableResourceSlots[0].startsAt));
    }
    this.isLoading = false;
  }

  private activateDay(event) {
    if (this.availableResourceSlots.some((date) => this.isSameDate(new Date(date.startsAt), event))) {
      this.calendarActiveDate = event;
      this.visibleSlots = this.availableResourceSlots.filter(
        (slot) => this.isSameDate(event, new Date(slot.startsAt)) && this.isSameDate(event, new Date(slot.endsAt)),
      );
      this.calendar.updateTodaysDate();
      this.cd.detectChanges();
    }
  }

  private prepareBookingRequest() {
    const requestFormat = 'YYYY-MM-DD HH:mm';
    const startTime = moment(this.chosenStart).format(requestFormat);
    const endTime = moment(this.chosenEnd).format(requestFormat);
    const timeZoneOffset = moment(this.chosenStart).format('Z');
    return new BookingRequest(startTime, endTime, timeZoneOffset);
  }

  private checkSubmissionMode(): void {
    if (!this.learnerFormAnswer) {
      if (!this.playlistUid || !this.resourceUid || !this.formData?.uid) {
        return;
      }
      if (!this.validationInProgress && !this.validationProcessed) {
        this.validationInProgress = true;
        this.dataService.validateFormUpdate(this.playlistUid, this.resourceUid, this.formData?.uid).subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            this.canEdit = value.canBeUpdated;
            this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translocoService, value);
            this.validationProcessed = true;
          }
          this.checkSubmissionLocked();
          this.cd.detectChanges();
          this.validationInProgress = false;
        });
      }
    } else {
      this.canEdit = this.learnerFormAnswer?.updatePermission?.canBeUpdated;
      this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translocoService, this.learnerFormAnswer?.updatePermission);
      this.checkSubmissionLocked();
      this.cd.detectChanges();
    }
  }

  private checkSubmissionLocked() {
    if (this.userPlaylistSubmissionSummary?.submissionsLocked) {
      this.canEdit = false;
      this.canReBook = false;
      this.canNotEditReason = this.translocoService.translate('translations.formUpdateDisabledReasons.submissionClosed');
    }
  }

  private onRequestProcessing() {
    this.isLoading = true;
    this.hasError = false;
  }

  private onAppointmentLoadingError() {
    this.isLoading = false;
    this.hasAppointmentLoadingError = true;
    this.onRequestError();
  }

  private onBookingPlacementError() {
    this.hasBookingPlacementError = true;
    this.onRequestError();
  }

  private onRequestError() {
    this.isLoading = false;
    this.cd.detectChanges();
  }
}
