<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="form-dialog-backdrop"
  tabindex="0"
  role="button"
  [attr.aria-label]="'translations.global.ariaLabels.enterToCloseModal' | transloco"
  (keydown.enter)="closeFullscreenMode()"
  (click)="closeFullscreenMode()"
  *ngIf="fullscreenEnabled"
></div>
<div class="form-wrapper private-note-container" [class.fullscreen-enabled]="fullscreenEnabled">
  <div
    class="form-preview"
    role="form"
    tabindex="0"
    [attr.aria-label]="
      'translations.global.ariaLabels.textBoxFormTitle'
        | transloco
          : {
              title: formData?.content?.title,
              state: (submitted ? 'translations.global.ariaLabels.update' : 'translations.global.ariaLabels.markAsDone') | transloco,
            }
    "
    [class.form-preview--active]="inputFocused"
    [class.form-preview--saved]="submitted"
  >
    <div class="form-preview__top">
      <div class="form-preview__top-title">
        <div class="title medium bold">{{ formData?.content?.title }}</div>
      </div>

      <div class="form-preview__top-icon-box">
        <button
          class="fullscreen-btn-container"
          role="button"
          tabindex="0"
          [attr.aria-label]="
            fullscreenEnabled
              ? ('translations.global.ariaLabels.collapseForm' | transloco)
              : ('translations.global.ariaLabels.expandForm' | transloco)
          "
          (click)="changeFullscreenMode()"
          *ngIf="canEdit"
        >
          <svg class="svg" aria-hidden="true" *ngIf="!fullscreenEnabled">
            <use ogSvgUnify="assets/sprite/sprite.svg#fullscreen-expand"></use>
          </svg>

          <svg class="svg" aria-hidden="true" *ngIf="fullscreenEnabled">
            <use ogSvgUnify="assets/sprite/sprite.svg#fullscreen-collapse"></use>
          </svg>
        </button>

        <div *ngIf="submitted" class="form-preview__top-icon non-editable" [matTooltip]="canNotEditReason">
          <mat-icon *ngIf="canEdit" aria-hidden="true">check</mat-icon>
          <svg *ngIf="!canEdit" class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.lock' | transloco">
            <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
          </svg>
        </div>
        <div *ngIf="!submitted && !canEdit" class="form-preview__top-icon non-saved non-editable" [matTooltip]="canNotEditReason">
          <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.lock' | transloco">
            <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
          </svg>
        </div>
        <button
          *ngIf="canEdit"
          tabindex="0"
          class="form-preview__top-icon editable"
          (click)="triggerEdit()"
          [ngClass]="canEdit ? 'pointer' : ''"
        >
          <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.edit' | transloco">
            <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
          </svg>
        </button>
      </div>
    </div>
    <div class="form-preview__input" #formInputPreview>
      <ptl-tiny-medium-editor
        [attr.aria-label]="'translations.global.ariaLabels.textBoxForm' | transloco"
        [class.is_disabled]="!canEdit"
        [content]="existingUserAnswer"
        [inputType]="formData?.content?.answerType"
        [editClicked]="editClicked"
        (inputFocus)="onInputFocus()"
        (inputBlur)="deactivateForm($event)"
        (textLength)="onTextLengthUpdate($event)"
        (mediumEditorUpdate)="onMediumEditorUpdate($event)"
      ></ptl-tiny-medium-editor>
    </div>
    <div class="form-preview-notes" *ngIf="formData?.content?.notes?.length" [class.form-preview--active]="inputFocused">
      <div class="note-item" *ngFor="let note of formData?.content?.notes" tabindex="0">
        <div class="note-item-inner">
          <div class="note-message small">
            <ptl-resource-preview class="thin-preview" [previewContent]="note.message"> </ptl-resource-preview>
          </div>
        </div>
        <div class="note-added-by extra-small italic">{{ note.addedBy }}, {{ note.addedOn | dateFormat: 'DD MMM yyyy HH:mm' }}</div>
      </div>
    </div>

    <div class="form-preview__footer">
      <div class="form-preview__footer-buttons">
        <span class="autosave-label extra-small saving" *ngIf="autoSaveInProgress">{{
          'translations.global.label.autoSaving' | transloco
        }}</span>
        <span class="autosave-label extra-small" *ngIf="autoSaved">
          <mat-icon aria-hidden="true">check</mat-icon>
          {{ 'translations.global.label.saved' | transloco }}</span
        >
      </div>

      <div class="info-text extra-small">
        {{ 'translations.privateNoteOnlyYouCanSee' | transloco }}
      </div>
    </div>
  </div>
</div>
<ptl-form-update-footer
  [submitted]="submitted"
  [canEdit]="canEdit"
  [learnerFormAnswer]="learnerFormAnswer"
  [formType]="formData?.content?.type"
  [playlistSubmissionDeadline]="userPlaylistSubmissionSummary?.submissionDeadline"
>
</ptl-form-update-footer>
