/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LEARNER_PLAYLIST_DATA_SERVICE, LearnerPlaylistDataService } from '@app/app/shared/services/learner-playlist/data.service';
import { UserSearch } from '@app/app/shared/models/admin/members.model';
import {
  MENTOR_MENTEE_DATA_SERVICE,
  MentorMenteeDataService,
} from '@app/app/page-modules/admin/services/mentor-mentee/mentor-mentee-data.service';
import { forkJoin } from 'rxjs';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from '@app/app/shared/services/toast-notifications/toast-service';
import { TranslocoService } from '@ngneat/transloco';
import { Mentor } from '@app/app/page-modules/admin/components/mentors/admin-mentors.model';

@Component({
  selector: 'ptl-mentor-give-access-dialog',
  templateUrl: './mentor-give-access-dialog.component.html',
  styleUrls: ['./mentor-give-access-dialog.component.scss'],
})
export class MentorGiveAccessDialogComponent implements OnInit {
  selectedUser: UserSearch;
  allMentors: UserSearch[] = [];
  filteredMentors: UserSearch[] = [];
  mentorsWithAccess: UserSearch[] = [];
  isLoading = false;
  pageIndex = 0;
  pageSize = 256;
  searchQuery = '';
  private learnerPlaylistUid: string;
  private menteeUserUid: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      menteeUserUid: string;
      learnerPlaylistUid: string;
    },
    @Inject(MENTOR_MENTEE_DATA_SERVICE) private mentorMenteeService: MentorMenteeDataService,
    @Inject(LEARNER_PLAYLIST_DATA_SERVICE) private learnerPlaylistDataService: LearnerPlaylistDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
    private translocoService: TranslocoService,
    public dialogRef: MatDialogRef<MentorGiveAccessDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.learnerPlaylistUid = this.data.learnerPlaylistUid;
    this.menteeUserUid = this.data.menteeUserUid;
    this.loadMentors();
  }

  onMemberSelected(user: UserSearch) {
    this.selectedUser = user;
  }

  deselectUser() {
    this.selectedUser = null;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSearchChanged(searchValue: string) {
    this.searchQuery = searchValue;
    const lower = searchValue.toLowerCase();
    this.filteredMentors = this.allMentors.filter(
      (m) =>
        m.firstName?.toLowerCase().includes(lower) || m.lastName?.toLowerCase().includes(lower) || m.email?.toLowerCase().includes(lower),
    );
  }

  shareSubmissionWithMentor() {
    if (!this.selectedUser) return;

    this.learnerPlaylistDataService.shareSubmissionWithMentor(this.learnerPlaylistUid, this.selectedUser.uid).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        this.mentorsWithAccess.push(this.selectedUser);
        this.allMentors = this.allMentors.filter((m) => m.uid !== this.selectedUser.uid);
        this.onSearchChanged(this.searchQuery);
        this.showSuccess('translations.successes.successSharingSubmission', this.selectedUser);
        this.deselectUser();
      } else {
        this.showError('translations.errors.errorUpdatePlaylistSubmissionSettings');
      }
    });
  }

  revokeAccess(mentor: UserSearch) {
    this.learnerPlaylistDataService.removeMentorAccess(this.learnerPlaylistUid, mentor.uid).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        this.mentorsWithAccess = this.mentorsWithAccess.filter((m) => m.uid !== mentor.uid);
        this.allMentors.push(mentor);
        this.onSearchChanged(this.searchQuery);
        this.showSuccess('translations.successes.successRevokingSubmissionAccess');
      } else {
        this.showError('translations.errors.errorUpdatePlaylistSubmissionSettings');
      }
    });
  }

  private loadMentors(): void {
    this.isLoading = true;

    forkJoin({
      mentorsWithAccess: this.learnerPlaylistDataService.findMentorsWithSubmissionAccess(this.learnerPlaylistUid),
      allMentorsResult: this.mentorMenteeService.findMentorsForMentee(this.pageIndex, this.pageSize, this.menteeUserUid, this.searchQuery),
    }).subscribe(({ mentorsWithAccess, allMentorsResult }) => {
      if (mentorsWithAccess.isSuccess) {
        this.mentorsWithAccess = mentorsWithAccess.value;
      }

      if (allMentorsResult.isSuccess) {
        const all = allMentorsResult.value.content.map(this.toUserSearch);
        this.allMentors = this.excludeMentorsWithAccess(all, this.mentorsWithAccess);
        this.filteredMentors = this.allMentors;
      }

      this.isLoading = false;
    });
  }

  private toUserSearch(mentor: Mentor): UserSearch {
    return {
      email: null,
      externalId: null,
      firstName: mentor.firstName,
      image: mentor.imageUrl,
      lastName: mentor.lastName,
      uid: mentor.id,
    };
  }

  private excludeMentorsWithAccess(all: UserSearch[], existing: UserSearch[]): UserSearch[] {
    const existingUids = new Set(existing.map((m) => m.uid));
    return all.filter((m) => !existingUids.has(m.uid));
  }

  private showSuccess(key: string, user?: UserSearch) {
    const text = this.translocoService.translate(key);
    this.toastService.showSuccess(user ? text.replace('{name}', `${user.firstName} ${user.lastName}`) : text);
  }

  private showError(key: string) {
    this.toastService.showFail(this.translocoService.translate(key));
  }
}
