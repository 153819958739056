<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<mat-card
  class="resource-card"
  [class.resource-group-card]="card?.type === 'GROUP'"
  tabindex="0"
  (click)="onCardClick(card); $event.preventDefault()"
  [attr.aria-label]="'translations.global.ariaLabels.open' | transloco: { title: card?.header }"
  (keydown.enter)="onCardClick(card); $event.preventDefault()"
>
  <div class="resource-card__top-icons">
    <div class="resource-card__completed-icon">
      <mat-icon *ngIf="card?.completed; else notCompleted" class="check-icon completed" aria-hidden="true" title="Completed">
        check_circle_outline
      </mat-icon>
      <ng-template #notCompleted>
        <mat-icon *ngIf="!card?.started" class="check-icon in-progress" aria-hidden="true" title="In progress"> timelapse </mat-icon>
        <mat-icon *ngIf="card?.started" class="check-icon started" aria-hidden="true" title="Started"> outlined_flag </mat-icon>
      </ng-template>
    </div>
    <ptl-required-icon *ngIf="card" [required]="card?.required" [offsetTop]="35" [offsetLeft]="0"> </ptl-required-icon>
  </div>

  <div class="resource-card__center resource-group-card__images-{{ card?.type === 'GROUP' ? getGroupCardImages(card?.image).length : '' }}">
    <div class="center__image-box" *ngIf="card?.type !== 'GROUP'">
      <ng-container *ngIf="card?.image || card?.thumbnail; else placeholderImage">
        <img class="center__image" alt="" width="266" height="150" [src]="card?.thumbnail" ogImageSource [imageSrc]="card?.image" />
      </ng-container>
    </div>
    <div class="center__image-box" *ngIf="card?.type === 'GROUP'">
      <ng-container *ngIf="card?.image || card?.thumbnail; else placeholderImage">
        <img
          *ngFor="let image of getGroupCardImages(card?.thumbnail); let index = i"
          class="center__image"
          alt=""
          width="266"
          height="150"
          [src]="image"
          ogImageSource
          [imageSrc]="card?.image"
          [multipleImageIndex]="index"
        />
      </ng-container>
    </div>
    <ng-template #placeholderImage>
      <img
        class="resource-card__empty-image-placeholder"
        alt=""
        width="266"
        height="150"
        [src]="'/assets/card-placeholders/card-placeholder-' + ((cardIndex % 4) + 1) + '.png'"
      />
    </ng-template>
  </div>

  <div class="resource-card__footer">
    <div class="resource-card__footer-badge">
      <mat-icon
        class="mat__icon"
        aria-hidden="true"
        *ngIf="!authorDetails?.displayConfig.organizationName && !authorDetails?.displayConfig.authorName"
      >
        insert_drive_file_outline
      </mat-icon>
      <ng-container *ngIf="authorDetails?.displayConfig.organizationName || authorDetails?.displayConfig.authorName">
        <img
          class="item__author-image"
          alt=""
          width="50"
          height="50"
          [src]="authorDetails.displayConfig.organizationName ? authorDetails.orgLogoUrl : authorDetails.userImageUrl"
          *ngIf="(authorDetails.userImageUrl && authorDetails.displayConfig.authorName) || authorDetails.displayConfig.organizationName"
        />
        <div
          [title]="authorDetails.name"
          class="item__author-initials"
          *ngIf="!authorDetails.userImageUrl && authorDetails.displayConfig.authorName && !authorDetails.displayConfig.organizationName"
        >
          {{ authorDetails.name.charAt(0) }}
        </div>
      </ng-container>
    </div>
    <div class="resource-card__info">
      <p class="resource-card__event ellipsis" *ngIf="eventText">
        {{ eventText }}
      </p>
      <div class="resource-card__title-container">
        <p class="resource-card__title" [title]="card?.header">
          {{ card?.header }}
        </p>
      </div>
      <p class="resource-card__description ellipsis" [title]="subheaderText">
        {{ subheaderText }}
      </p>
    </div>
    <ptl-card-progress
      [completed]="card?.completed"
      [started]="card?.started"
      [completedCount]="card?.completedItemsCount"
      [totalCount]="card?.totalItemsCount"
    >
    </ptl-card-progress>

    <ptl-card-footer
      [isAdmin]="false"
      [canEdit]="false"
      [canViewMembers]="false"
      [organizationDomain]="(organizationData$ | async)?.domain"
      [uid]="card?._id"
      [title]="card?.header"
      [status]="card?.status"
      [syndicated]="card?.syndicated"
      [likeDetails]="card?.likes"
      [infoType]="card?.type !== 'PLAYLIST' ? 'CARD' : 'PLAYLIST'"
      [displayedType]="card?.type"
      [editMode]="false"
      [canClone]="false"
    >
    </ptl-card-footer>
  </div>
</mat-card>

<a class="resource-card-print" *ngIf="card" tabindex="0" [href]="getCardUrl(card, false)">
  {{ card?.header }}
</a>
