<div
  tabindex="0"
  role="region"
  [class.is_hidden]="!content?.html"
  class="external-preview__container
     external-preview__container-position-{{ content?.position || 'CENTER' }}"
  #htmlContent
>
  <div *ngIf="!folioAttachmentPreviewView" class="external-preview-element__caption-box">
    <p
      tabindex="0"
      class="caption-text"
      [attr.aria-label]="'translations.global.ariaLabels.openUrl' | transloco: { url: content?.url }"
      (click)="openUrl()"
      (keydown.enter)="openUrl()"
    >
      {{ content?.url }}
    </p>
  </div>
</div>
