/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { catchError, switchMap } from 'rxjs/operators';
import {
  AiAssistantService,
  AIAssistantSettings,
  AIAssistantSettingsRequest,
  AIAssistantSettingsTemplateDTO,
  ExchangeRequest,
  LearnerAIAssistantSettings,
  OpenAiMessage,
} from './ai-assistant.service';
import { RestClientService } from '../../../shared/services/rest-client.service';
import { DEFAULT_LANGUAGE_CODE } from '../../../shared/services/languages/language.service';
import { ObservableResult } from '../../../shared/store';
import { environment } from '../../../../environments/environment';
import { Page } from '../../../shared/models/page';
import { LanguageCodeHelper } from '../../../shared/helpers/language-code-helper';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ApiAiAssistantDataService implements AiAssistantService {
  private ACCEPT_LANGUAGE_HEADER = 'Accept-Language';

  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  exchange(request: ExchangeRequest, languageCode?: string): ObservableResult<OpenAiMessage> {
    return this.client
      .post<OpenAiMessage>(
        Location.joinWithSlash(environment.apiRootUrl || '', 'cards/forms/assistants/exchange'),
        request,
        languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAiAssistant'))),
      );
  }

  getAIAssistantSettingsLearnerView(aiAssistantSettingsUid: string, languageCode?: string): ObservableResult<LearnerAIAssistantSettings> {
    return this.client
      .get<LearnerAIAssistantSettings>(
        Location.joinWithSlash(environment.apiRootUrl || '', `cards/forms/assistants/settings/${aiAssistantSettingsUid}`),
        null,
        languageCode ? { ACCEPT_LANGUAGE_HEADER: languageCode } : { ACCEPT_LANGUAGE_HEADER: DEFAULT_LANGUAGE_CODE },
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAiAssistant'))),
      );
  }

  createAssistantSettings(
    cardUid: string,
    request: AIAssistantSettingsRequest,
    languageCode?: string,
  ): ObservableResult<AIAssistantSettings> {
    return this.client
      .post<AIAssistantSettings>(
        Location.joinWithSlash(environment.apiRootUrl || '', `/cards/${cardUid}/assistants/create`),
        request,
        languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAiAssistant'))),
      );
  }

  getAssistantSettingsById(cardUid: string, assistantSettingsUid: string, languageCode?: string): ObservableResult<AIAssistantSettings> {
    return this.client
      .get<AIAssistantSettings>(
        Location.joinWithSlash(environment.apiRootUrl || '', `/cards/${cardUid}/assistants/${assistantSettingsUid}`),
        null,
        languageCode ? { ACCEPT_LANGUAGE_HEADER: languageCode } : { ACCEPT_LANGUAGE_HEADER: DEFAULT_LANGUAGE_CODE },
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAiAssistant'))),
      );
  }

  updateAssistantSettings(
    cardUid: string,
    assistantSettingsUid: string,
    request: AIAssistantSettingsRequest,
    languageCode?: string,
  ): ObservableResult<AIAssistantSettings> {
    return this.client
      .put<AIAssistantSettings>(
        Location.joinWithSlash(environment.apiRootUrl || '', `/cards/${cardUid}/assistants/update/${assistantSettingsUid}`),
        request,
        languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAiAssistant'))),
      );
  }

  deleteAssistantSettings(cardUid: string, assistantSettingsUid: string): ObservableResult<void> {
    return this.client
      .delete<void>(Location.joinWithSlash(environment.apiRootUrl || '', `/cards/${cardUid}/assistants/${assistantSettingsUid}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAiAssistant'))),
      );
  }

  searchAIAssistantSettingsTemplates(
    cardUid: string,
    phrase: string,
    page: number = 0,
    size: number = 10,
    languageCode?: string,
  ): ObservableResult<Page<AIAssistantSettingsTemplateDTO>> {
    let params = {
      page: page.toString(),
      size: size.toString(),
    };

    if (phrase.length !== 0) {
      params = undefined;
    }
    return this.client
      .post<
        Page<AIAssistantSettingsTemplateDTO>
      >(Location.joinWithSlash(environment.apiRootUrl || '', `/cards/${cardUid}/assistants/templates/search?phrase=${phrase}`), undefined, params, languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAiAssistant'))),
      );
  }

  createAIAssistantSettingsFromTemplate(
    cardUid: string,
    aiAssistantSettingsTemplateUid: string,
    languageCode?: string,
  ): ObservableResult<AIAssistantSettings> {
    return this.client
      .post<AIAssistantSettings>(
        Location.joinWithSlash(environment.apiRootUrl || '', `/cards/${cardUid}/assistants/create/${aiAssistantSettingsTemplateUid}`),
        languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAiAssistant'))),
      );
  }
}
