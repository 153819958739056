<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<main
  class="main"
  [ngStyle]="{
    'background-image': (organizationData$ | async)?.landingPage.backgroundImage
      ? 'url(&quot;' + (organizationData$ | async)?.landingPage.backgroundImage + '&quot;)'
      : '',
    'background-color': (organizationData$ | async)?.landingPage.useBackgroundColourFill
      ? (organizationData$ | async)?.landingPage.backgroundColour
      : '',
  }"
  [class.dynamic-background-color]="(organizationData$ | async)?.landingPage.useBackgroundColourFill"
  [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'"
>
  <div class="maintenance-layer" *ngIf="showMaintenanceOverlay">
    <div class="maintenance-layer-inner" [innerHTML]="'translations.maintenance.overlayText' | transloco"></div>
  </div>

  <div class="email__form-container" [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
    <mat-card class="main__email" [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
      <img
        class="logo__image"
        width="240"
        height="80"
        [class.full-background]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
        src="{{ (organizationData$ | async)?.landingPage.landingLogo || (organizationData$ | async)?.logo }}"
        alt="{{ (organizationData$ | async)?.name }} Logo"
      />
      <div class="email__university-info">
        <h1 *ngIf="!isRegistered" class="title">{{ 'translations.public.title.socialEmailVerification' | transloco }}</h1>
        <h1 *ngIf="isRegistered" class="title">{{ 'translations.public.title.checkYourEmail' | transloco }}</h1>
      </div>
      <img class="email__envelope-image" *ngIf="isRegistered" alt="Envelope" src="assets/envelope.svg" width="450" height="340" />

      <div class="right__form" [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
        <form
          [ngStyle]="{ display: isRegistered ? 'none' : '' }"
          class="email-form"
          [formGroup]="emailForm"
          (ngSubmit)="resendEmailVerification()"
          [attr.aria-label]="'translations.global.ariaLabels.emailVerificationForm' | transloco"
          novalidate
        >
          <mat-form-field class="form__field" appearance="outline">
            <mat-label [attr.aria-label]="'translations.global.ariaLabels.emailField' | transloco">{{
              'translations.global.input.label.email' | transloco
            }}</mat-label>
            <input matInput required name="v-email" type="email" autocomplete="off" formControlName="email" />
            <mat-error *ngIf="emailForm?.controls.email.hasError('required')">
              {{ 'translations.global.input.error.enterEmail' | transloco }}
            </mat-error>
          </mat-form-field>
          <div class="email-form__actions">
            <span class="cdk-visually-hidden" #buttonTitle>{{
              'translations.public.button.socialEmailVerificationResend' | transloco
            }}</span>
            <button mat-flat-button type="submit" class="email-form__submit uppercase" color="primary" [disabled]="isLoading">
              <span class="submit__loading">
                <span class="submit__logging-in">{{ buttonTitle.innerText }}</span>
                <mat-spinner [diameter]="20" *ngIf="isLoading"></mat-spinner>
              </span>
            </button>
          </div>
          <ngx-invisible-recaptcha
            #captchaElem
            [siteKey]="reCaptchaSiteKeyV2"
            (success)="proceedIfValid($event)"
            (reset)="resetCaptcha()"
            [ngModel]="reCaptchaElement"
            [ngModelOptions]="{ standalone: true }"
            [useGlobalDomain]="false"
          >
          </ngx-invisible-recaptcha>
        </form>
        <div *ngIf="isRegistered">
          <div class="email__activation-message">
            <p class="description" [innerHTML]="'translations.public.description.activationEmail' | transloco: { email: email }"></p>
          </div>
          <button mat-flat-button type="button" class="email-form__login uppercase" color="primary" (click)="onLoginClick()">
            {{ 'translations.public.button.login' | transloco }}
          </button>
          <div class="email__activation-message">
            <div class="description">
              <i>{{ 'translations.public.description.cantFindActivationEmail' | transloco }}</i>
              <br />
              <br />
              <span
                class="email-resend-button"
                tabindex="0"
                (click)="resendEmailVerification()"
                (keydown.enter)="resendEmailVerification()"
              >
                {{ 'translations.public.button.resendActivationEmail' | transloco }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="false"
        class="email__powered-content"
        [attr.aria-label]="'translations.global.ariaLabels.poweredBy' | transloco: { organizationName: (organizationData$ | async)?.name }"
        [class.full-background]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
      >
        {{ 'translations.global.label.poweredBy' | transloco }}
        <a
          href="https://potential.ly"
          [class.is_light]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
          class="login__powered-content-link"
          [attr.aria-label]="'translations.global.ariaLabels.open' | transloco: { title: (organizationData$ | async)?.name }"
        >
          <img
            class="login__powered-image"
            width="40"
            height="40"
            src="https://static.yuna.potential.ly/public/images/icons/potentially_logo.png"
            alt=""
          />
          {{ 'translations.public.title.logoText' | transloco }}
        </a>
      </div>
    </mat-card>
  </div>
</main>
