<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div
  class="form-preview"
  tabindex="0"
  role="group"
  [attr.aria-label]="
    'translations.global.ariaLabels.quizFormBoxTitle'
      | transloco
        : {
            title: !title ? ('translations.quiz.title.quiz' | transloco) : title,
            state: quizButtonStateMessage,
          }
  "
  [class.form-preview--saved]="quizTaken && learnerFormAnswer?.submitted"
>
  <div class="form-preview__top">
    <div class="form-preview__top-title" *ngIf="summary">
      <div class="title medium bold">{{ !title ? ('translations.quiz.title.quiz' | transloco) : title }}</div>
      <div
        *ngIf="!quizTaken && summary"
        class="form-preview__top-limits small"
        [innerHTML]="
          'translations.answerQuestionsToPass' | transloco: { requiredCount: summary.totalRequired, allCount: summary.totalQuestions }
        "
      ></div>
    </div>
    <div class="form-preview__top-icon-box">
      <div *ngIf="quizTaken" class="form-preview__top-icon non-editable" [matTooltip]="canNotEditReason">
        <mat-icon *ngIf="canEdit" aria-hidden="true">{{ quizTaken ? 'check' : 'linear_scale' }}</mat-icon>
        <svg *ngIf="!canEdit" class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.lock' | transloco">
          <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
        </svg>
      </div>
      <div *ngIf="!quizTaken && !canEdit" class="form-preview__top-icon non-saved non-editable" [matTooltip]="canNotEditReason">
        <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.lock' | transloco">
          <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
        </svg>
      </div>
    </div>
  </div>

  <div class="form-preview-notes" *ngIf="content?.content?.notes?.length">
    <div class="note-item" *ngFor="let note of content?.content?.notes" tabindex="0">
      <div class="note-item-inner">
        <div class="note-message small">
          <ptl-resource-preview class="thin-preview" [previewContent]="note.message"> </ptl-resource-preview>
        </div>
      </div>
      <div class="note-added-by extra-small italic">
        {{ note.addedBy }}, {{ getLocalDateTime(note.addedOn) | date: 'dd MMM yyyy HH:mm' }}
      </div>
    </div>
  </div>

  <div *ngIf="summary" class="form-preview__footer">
    <ng-container *ngIf="quizTaken && summary">
      <div class="form-preview__input" [ngClass]="quizCompleted ? 'positive' : 'warn'">
        <div class="icon-wrapper">
          <svg
            *ngIf="quizCompleted"
            class="svg positive"
            role="img"
            [attr.aria-label]="'translations.global.ariaLabels.icons.check' | transloco"
          >
            <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
          </svg>
          <svg
            *ngIf="!quizCompleted && !quizPassed"
            class="svg warn"
            role="img"
            [attr.aria-label]="'translations.global.ariaLabels.icons.warning' | transloco"
          >
            <use ogSvgUnify="assets/sprite/sprite.svg#warning"></use>
          </svg>
        </div>

        <div class="quiz-info">
          <div *ngIf="quizCompleted" class="quiz-info-text bold positive">
            {{ 'translations.quiz.label.quizPassed' | transloco }}
          </div>
          <div *ngIf="!quizCompleted && !quizPassed" class="quiz-info-text bold warn">
            {{
              'translations.quiz.label.toPassQuiz'
                | transloco
                  : {
                      requiredCount: summary.totalRequired,
                      allCount: summary.totalQuestions,
                      percentage: requiredToPassPercent,
                    }
            }}
          </div>
          <div class="quiz-info-text" [class.positive]="quizCompleted" [class.warn]="!quizCompleted && !quizPassed">
            <span>{{ 'translations.yourScore' | transloco }}</span>
            <span class="quiz-info-score" [class.positive]="quizCompleted" [class.warn]="!quizCompleted && !quizPassed"
              >{{ summary?.correctAnswersCount }}/{{ summary?.totalQuestions }} ({{ correctAnswersPercent }}%)</span
            >
          </div>
        </div>
      </div>
    </ng-container>

    <div class="form-preview__footer-buttons">
      <ng-container *ngIf="!quizTaken && canEdit">
        <button
          *ngIf="!resume"
          class="quiz-button uppercase"
          color="accent"
          mat-stroked-button
          [attr.aria-label]="'translations.global.ariaLabels.startTakingQuiz' | transloco"
          (click)="showDialog()"
        >
          {{ 'translations.start' | transloco }}
        </button>
        <button
          *ngIf="resume"
          class="quiz-button"
          color="accent"
          mat-stroked-button
          [attr.aria-label]="'translations.global.ariaLabels.continueTakingQuiz' | transloco"
          (click)="showDialog()"
        >
          {{ 'translations.resume' | transloco }}
        </button>
      </ng-container>

      <ng-container *ngIf="quizTaken">
        <button
          *ngIf="quizTaken"
          color="accent"
          class="quiz-button uppercase"
          mat-stroked-button
          [attr.aria-label]="'translations.global.ariaLabels.viewQuizResult' | transloco"
          (click)="showDialog()"
        >
          {{ 'translations.viewResults' | transloco }}
        </button>
        <button
          *ngIf="canRetake && !resume"
          class="quiz-button quiz-retake-btn"
          mat-flat-button
          [attr.aria-label]="'translations.global.ariaLabels.retakeQuiz' | transloco"
          (click)="retake()"
        >
          {{ 'translations.quiz.label.retakeQuiz' | transloco }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
<ptl-form-update-footer
  [submitted]="quizTaken && learnerFormAnswer?.submitted"
  [canEdit]="canEdit"
  [learnerFormAnswer]="learnerFormAnswer"
  [formType]="content?.content?.type"
  [playlistSubmissionDeadline]="userPlaylistSubmissionSummary?.submissionDeadline"
  [quizTaken]="quizTaken"
  [canRetakeQuiz]="canRetake"
  [canResumeQuiz]="resume"
>
</ptl-form-update-footer>
