<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  [attr.role]="!hidePreview ? 'region' : null"
  [tabindex]="!hidePreview ? 0 : -1"
  class="preview-element
    preview-element-type-{{ content?.type }}
    preview-element-position-{{ content?.position || 'CENTER' }}"
  #htmlEl
></div>
