<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<main
  class="main"
  [ngStyle]="{
    'background-image': (organizationData$ | async)?.landingPage.backgroundImage
      ? 'url(&quot;' + (organizationData$ | async)?.landingPage.backgroundImage + '&quot;)'
      : '',
    'background-color': (organizationData$ | async)?.landingPage.useBackgroundColourFill
      ? (organizationData$ | async)?.landingPage.backgroundColour
      : '',
  }"
  [class.dynamic-background-color]="(organizationData$ | async)?.landingPage.useBackgroundColourFill"
  [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'"
>
  <div class="maintenance-layer" *ngIf="showMaintenanceOverlay">
    <div class="maintenance-layer-inner" [innerHTML]="'translations.maintenance.overlayText' | transloco"></div>
  </div>

  <div class="email__form-container" [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
    <mat-card class="main__email" [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
      <img
        class="logo__image"
        width="240"
        height="80"
        [class.full-background]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
        src="{{ (organizationData$ | async)?.landingPage.landingLogo || (organizationData$ | async)?.logo }}"
        alt="{{ (organizationData$ | async)?.name }} Logo"
      />
      <div class="email__university-info">
        <h1 class="title">{{ 'translations.public.title.resetEmail' | transloco }}</h1>
      </div>

      <div class="right__form" [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
        <form
          class="email-form"
          [formGroup]="emailForm"
          (ngSubmit)="updateEmail()"
          [attr.aria-label]="'translations.global.ariaLabels.resetEmailForm' | transloco"
          novalidate
        >
          <mat-form-field class="form__field" appearance="outline">
            <mat-label [attr.aria-label]="'translations.global.ariaLabels.emailField' | transloco">{{
              'translations.global.input.label.newEmail' | transloco
            }}</mat-label>
            <input matInput required name="n-email" type="email" autocomplete="off" formControlName="email" />
            <mat-error *ngIf="emailForm?.controls.email.hasError('required')">
              {{ 'translations.global.input.error.enterEmail' | transloco }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form__field" appearance="outline">
            <mat-label [attr.aria-label]="'translations.global.ariaLabels.confirmEmailField' | transloco">{{
              'translations.global.input.label.confirmEmail' | transloco
            }}</mat-label>
            <input matInput required name="n-email" type="email" autocomplete="off" formControlName="newEmail" />
            <mat-error *ngIf="emailForm?.controls.newEmail.hasError('required')">
              {{ 'translations.global.input.error.enterValidConfirmEmail' | transloco }}
            </mat-error>
            <mat-error *ngIf="emailForm?.controls.newEmail.hasError('mustMatch')">
              {{ 'translations.global.input.error.emailsNotEqual' | transloco }}
            </mat-error>
          </mat-form-field>
          <ngx-invisible-recaptcha
            #captchaElem
            [siteKey]="reCaptchaSiteKeyV2"
            (success)="proceedIfValid($event)"
            (reset)="resetCaptcha()"
            [ngModel]="reCaptchaElement"
            [ngModelOptions]="{ standalone: true }"
            [useGlobalDomain]="false"
          >
          </ngx-invisible-recaptcha>
          <div class="email-form__actions">
            <button
              mat-flat-button
              type="submit"
              class="email-form__submit uppercase"
              title="Update"
              [attr.aria-label]="'translations.global.ariaLabels.updateEmail' | transloco"
              color="primary"
            >
              {{ 'translations.global.button.confirm' | transloco }}
            </button>
          </div>
        </form>
      </div>
      <div
        *ngIf="false"
        class="email__powered-content"
        [attr.aria-label]="'translations.global.ariaLabels.poweredBy' | transloco: { organizationName: (organizationData$ | async)?.name }"
        [class.full-background]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
      >
        {{ 'translations.global.label.poweredBy' | transloco }}
        <a
          href="https://potential.ly"
          [class.is_light]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
          class="login__powered-content-link"
          [attr.aria-label]="'translations.global.ariaLabels.open' | transloco: { title: (organizationData$ | async)?.name }"
        >
          <img
            class="login__powered-image"
            width="40"
            height="40"
            src="https://static.yuna.potential.ly/public/images/icons/potentially_logo.png"
            alt=""
          />
          {{ 'translations.public.title.logoText' | transloco }}
        </a>
      </div>
    </mat-card>
  </div>
</main>
