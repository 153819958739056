/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { LoadableState } from '../../store';
import { Subscription, timer } from 'rxjs';
import { ImageDimensions } from '../../models/images/unsplash-images.model';

@Component({
  selector: 'ptl-skeleton-view',
  templateUrl: './skeleton-view.component.html',
  styleUrls: ['./skeleton-view.component.scss'],
})
export class SkeletonViewComponent implements AfterViewInit, OnDestroy {
  /** Defines loadable state */
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  @Input() state!: LoadableState<any>;
  /** Defines skeleton view type ( list / action ) */
  @Input() viewType!: string;

  @Input() countInput?: number;

  @Input() hideBackButton: boolean;

  @Input() imageDimensions: ImageDimensions;

  @Output() init = new EventEmitter();

  @ViewChild('skeletonContainer') skeletonContainer!: ElementRef;

  items: number[] = [];
  showSkeletonView = false;
  observableTimer!: Subscription;

  private count = 0;

  private listItemWidth = 270;
  private listItemHeight = 260;

  private actionItemHeight = 305;

  ngAfterViewInit() {
    // Timer prevents showing the skeleton view if the API action lasts less 200ms
    this.observableTimer = timer(200).subscribe(() => {
      this.showSkeletonView = true;

      if (!this.skeletonContainer) {
        return;
      }

      this.setElementsCount();
      if (this.countInput) {
        this.items = new Array(this.countInput);
      } else {
        this.items = new Array(this.count);
      }
      this.init.emit();
    });
  }

  ngOnDestroy() {
    if (this.observableTimer) {
      this.observableTimer.unsubscribe();
    }
  }

  private setCount(value: number) {
    this.count = value;
  }

  private setElementsCount() {
    const containerHeight = this.getDataContainerHeight();
    const availableRowsCount = this.getAvailableRowsCount(containerHeight);
    const availableColumnsCount = this.getAvailableColumnsCount();

    this.setCount(availableRowsCount * availableColumnsCount);
  }

  private getDataContainerHeight(): number {
    const headerHeight = this.skeletonContainer.nativeElement.getBoundingClientRect().top;
    const containerHeight = window.innerHeight - headerHeight;

    return containerHeight > 0 ? containerHeight : 100;
  }

  private getAvailableRowsCount(containerHeight: number): number {
    switch (this.viewType) {
      case 'action':
        return +Math.ceil(containerHeight / this.actionItemHeight).toFixed(0);
      default:
        return +Math.ceil(containerHeight / this.listItemHeight).toFixed(0);
    }
  }

  private getAvailableColumnsCount(): number {
    const containerWidth = this.getDataContainerWidth();

    switch (this.viewType) {
      case 'action':
        return 1;
      default:
        return +Math.ceil(containerWidth / this.listItemWidth).toFixed(0);
    }
  }

  private getDataContainerWidth(): number {
    return this.skeletonContainer.nativeElement.offsetWidth;
  }
}
