/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from '../../../page-modules/account/services/account-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarHelper } from '../../../shared/helpers/snackbar-helper';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../store/user-auth.state';
import { Organization } from '../../../shared/models';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { TokenType } from '../../models';
import { marker } from '@jsverse/transloco-keys-manager/marker';

@Component({
  template: '',
})
export class FolioAccountVerificationComponent implements OnInit, OnDestroy {
  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;
  private organizationType: string;
  private organizationTypeSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
    private router: Router,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.organizationTypeSubscription = this.organizationData$.subscribe((organization) => {
      if (organization) {
        this.organizationType = organization.type;
      }
    });
    const path = this.activatedRoute.snapshot.routeConfig.path;
    this.verifyConfirmationEmail(path);
  }

  ngOnDestroy() {
    this.organizationTypeSubscription.unsubscribe();
  }

  private verifyConfirmationEmail(path: string) {
    const hostname = document.location.hostname;
    const tokenType = this.getTokenType(path);
    const request = { token: this.activatedRoute.snapshot.queryParamMap.get('token'), type: tokenType };
    this.accountService.verifyWorkspaceEmail(request).subscribe(({ isSuccess, error }) => {
      if (isSuccess) {
        SnackbarHelper.showTranslatableSnackBar(
          this.ngZone,
          this.snackBar,
          this.translocoService,
          marker('translations.public.message.success.emailVerified'),
        );
      } else {
        SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
      }
      if (!this.isFolioDomain(hostname) && this.organizationType === 'EDUCATIONAL') {
        if (tokenType === TokenType.ALUMNI_ACCOUNT_VERIFICATION) {
          RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/graduate/signin');
        } else {
          RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/signin');
        }
      } else {
        RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/signin');
      }
    });
  }

  private getTokenType(path: string): string {
    let tokenType = null;
    if (path.includes('verify-alumni')) {
      tokenType = TokenType.ALUMNI_ACCOUNT_VERIFICATION;
    } else if (path.includes('verify-folio')) {
      tokenType = TokenType.FOLIO_ACCOUNT_VERIFICATION;
    } else if (path.includes('verify-pre-arrival')) {
      tokenType = TokenType.PRE_ARRIVAL_ACCOUNT_INVITATION;
    } else if (path.includes('verify-pre-arrival-with-folio')) {
      tokenType = TokenType.PRE_ARRIVAL_ACCOUNT_INVITATION_WITH_FOLIO;
    }
    return tokenType;
  }

  private isFolioDomain(hostname: string): boolean {
    return hostname.startsWith('yuna.dev.potential.ly') || hostname.startsWith('yuna.potential.ly') || hostname.startsWith('localhost');
  }
}
