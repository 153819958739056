/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { UserInfo } from '@app/app/shared/models/analytics/analytics.model';
import { defaultAvatarPath } from '@app/app/page-modules/playlist/components/create/analytics/chart-config.utils';
import { RedirectHelper } from '@app/app/page-modules/resource/store/editor/content/helpers/redirect.helper';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ptl-most-engaged-users-chart',
  templateUrl: './most-engaged-users-chart.component.html',
  styleUrls: ['../common-analytics-chart-styles.scss', './most-engaged-users-chart.component.scss'],
})
export class MostEngagedUsersChartComponent implements OnChanges {
  @Input() userEngagements: Record<string, number>;
  @Input() usersInfo: UserInfo[];
  @Input() fixedHeight = false;
  @Input() showPlaceholder = false;

  mostEngagedUsersLabels = [];
  maxEngagement = 0;
  sortOrder: 'asc' | 'desc' = 'desc';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userEngagements'] || changes['usersInfo']) {
      this.updateLeaderboard();
    }
  }

  toggleSortOrder(): void {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.updateLeaderboard();
  }

  openInNewTab(userId: string): void {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, `/admin/members/${userId}/user-record`, null, '_blank');
  }

  private updateLeaderboard(): void {
    if (!this.userEngagements || !this.usersInfo) {
      this.mostEngagedUsersLabels = [];
      this.maxEngagement = 0;
      return;
    }

    this.mostEngagedUsersLabels = Object.entries(this.userEngagements)
      .filter(([_, engagement]) => engagement > 0)
      .map(([userId, engagement]) => {
        const userInfo = this.usersInfo.find((user) => user.userId === userId);
        return {
          userId: userId,
          firstName: userInfo?.firstName || userId,
          lastName: userInfo?.lastName || '',
          imageUrl: userInfo?.imageUrl || defaultAvatarPath,
          engagement,
        };
      })
      .sort((a, b) => (this.sortOrder === 'desc' ? b.engagement - a.engagement : a.engagement - b.engagement));

    this.maxEngagement = Math.max(...this.mostEngagedUsersLabels.map((user) => user.engagement), 0);
  }
}
