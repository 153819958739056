/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component } from '@angular/core';

@Component({
  selector: 'ptl-skeleton-page-item',
  templateUrl: './skeleton-page-item.component.html',
  styleUrls: ['./skeleton-page-item.component.scss'],
})
export class SkeletonPageItemComponent {}
