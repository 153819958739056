/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { GeneratedReportResourceSection, PlaylistCardShort, ResourceAuthorDetails, ResourceTag } from '../../../../../../shared/models';
import { CORE_RESOURCE_DATA_SERVICE, ResourceDataService } from '../../../../../resource/services/editor/core/data.service';
import Swiper, { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'ptl-diagnostics-report-resource-section',
  templateUrl: './resource-section.component.html',
  styleUrls: ['./resource-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosticsReportResourceSectionComponent implements OnInit {
  @Input() section: GeneratedReportResourceSection;

  @Input() sectionIndex: number;

  @Input() isFirst: boolean;

  @ViewChild('swiperComponent') swiperComponent: SwiperComponent;

  cards: PlaylistCardShort[] = [];
  size = 10;
  page = 0;
  tagIds: string[];
  isLoadingMore: boolean;
  canLoadMore = true;
  authorDetails: ResourceAuthorDetails;

  activeSlideIndex: number;
  swiperConfig: SwiperOptions = {};

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(CORE_RESOURCE_DATA_SERVICE) private resourceDataService: ResourceDataService,
  ) {}

  ngOnInit() {
    const prevButtonClass = `.arrow-${this.sectionIndex}-prev`;
    const nextButtonClass = `.arrow-${this.sectionIndex}-next`;
    this.swiperConfig = {
      slidesPerView: 1,
      spaceBetween: 10,
      watchOverflow: true,
      allowTouchMove: false,
      navigation: {
        nextEl: nextButtonClass,
        prevEl: prevButtonClass,
      },
      breakpoints: {
        480: {
          slidesPerView: 2,
        },
        900: {
          slidesPerView: 3,
        },
        1301: {
          slidesPerView: 4,
        },
      },
    };

    this.tagIds = this.getTagIds(this.section.tags);
    this.loadCards(this.page, this.size, this.tagIds, false);
  }

  onLoadMore() {
    if (!this.canLoadMore) {
      return;
    }
    if (!this.isLoadingMore) {
      this.page++;
      this.loadCards(this.page, this.size, this.tagIds, true);
    }
  }

  trackByFn(index: number, { uid }) {
    return uid || index;
  }

  onSlideChange(event: Swiper[]) {
    this.activeSlideIndex = event[0].activeIndex;
    const slidePerView = this.getSlidesPerView();
    if (Math.abs(this.activeSlideIndex + slidePerView - this.cards.length) < 2) {
      this.onLoadMore();
    }
  }

  private getSlidesPerView() {
    if (window.innerWidth >= 1301) {
      return 4;
    } else if (window.innerWidth >= 900) {
      return 3;
    }
    if (window.innerWidth >= 480) {
      return 2;
    } else {
      return 1;
    }
  }

  private getTagIds(tags: ResourceTag[]) {
    const ids = [];
    tags.map((tag) => ids.push(tag.id));
    return ids;
  }

  private loadCards(page: number, size: number, tagIds: string[], isLoadMore: boolean) {
    this.resourceDataService.getResourceCardsByTags(page, size, tagIds).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.cards = this.cards.concat(value.content);
        this.cdr.detectChanges();
        if (isLoadMore) {
          this.swiperComponent.swiperRef.slideTo(this.activeSlideIndex, 0);
        }
        this.canLoadMore = value.totalNumberOfElement > this.cards.length;
      }

      this.isLoadingMore = false;
    });
  }
}
