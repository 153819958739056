<form [formGroup]="assistantSettingsForm">
  <div class="assistant-settings">
    <div class="editor-item">
      <h2 class="editor-item-title small bold">{{ 'translations.global.dataForms.aiAssistant.assistantTitle' | transloco }}</h2>
      <p class="editor-item-description extra-small">
        {{ 'translations.global.dataForms.aiAssistant.thisWillBeVisible' | transloco }}
      </p>
      <input
        [ngClass]="{
          'has-error':
            (isSaveClicked && assistantSettingsForm.controls['title'].invalid) ||
            (assistantSettingsForm.controls['title'].touched && assistantSettingsForm.controls['title'].hasError('required')),
        }"
        formControlName="title"
        [placeholder]="'translations.global.dataForms.aiAssistant.settings.addYourTitle' | transloco"
        class="editor-item-input"
        matInput
        tabindex="0"
        type="text"
        autocomplete="off"
      />
    </div>

    <div class="editor-item">
      <h2 class="editor-item-title small bold">
        {{ 'translations.global.dataForms.aiAssistant.instructions' | transloco }}
      </h2>
      <p class="editor-item-description extra-small">
        {{ 'translations.global.dataForms.aiAssistant.initialMessage' | transloco }}
      </p>
      <textarea
        [placeholder]="'translations.global.dataForms.aiAssistant.settings.addYourText' | transloco"
        matInput
        class="editor-item-input"
        formControlName="instructions"
        [ngClass]="{
          'has-error':
            (isSaveClicked && assistantSettingsForm.controls['instructions'].invalid) ||
            (assistantSettingsForm.controls['instructions'].touched && assistantSettingsForm.controls['instructions'].hasError('required')),
        }"
      ></textarea>
    </div>

    <div class="editor-item">
      <h2 class="editor-item-title small bold">{{ 'translations.global.dataForms.aiAssistant.promptScenario' | transloco }}</h2>
      <p class="editor-item-description extra-small">{{ 'translations.global.dataForms.aiAssistant.providePrompt' | transloco }}</p>
      <textarea
        [placeholder]="'translations.global.dataForms.aiAssistant.settings.addYourText' | transloco"
        matInput
        class="editor-item-input"
        formControlName="prompt"
        [ngClass]="{
          'has-error':
            (isSaveClicked && assistantSettingsForm.controls['prompt'].invalid) ||
            (assistantSettingsForm.controls['prompt'].touched && assistantSettingsForm.controls['prompt'].hasError('required')),
        }"
      >
      </textarea>
    </div>

    <div class="editor-item">
      <h2 class="editor-item-title small bold">{{ 'translations.global.dataForms.aiAssistant.prompts' | transloco }}</h2>

      <div formArrayName="actions">
        <div *ngFor="let action of actions().controls; let i = index" [formGroupName]="i">
          <div class="actions-row-container">
            <input formControlName="uid" type="hidden" />
            <input
              formControlName="description"
              [placeholder]="'translations.global.ariaLabels.addDescription' | transloco"
              class="actions-input-description"
              matInput
              tabindex="0"
              type="text"
              autocomplete="off"
            />

            <input
              formControlName="prompt"
              [placeholder]="'translations.global.dataForms.aiAssistant.settings.addPrompt' | transloco"
              class="actions-input-prompt"
              matInput
              tabindex="0"
              type="text"
              autocomplete="off"
            />

            <button
              mat-icon-button
              type="button"
              class="remove"
              [attr.aria-label]="'translations.global.ariaLabels.remove' | transloco"
              (click)="removeAction(i)"
            >
              <svg class="svg svg-icon" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div class="settings-controls">
        <button
          mat-button
          mat-stroked-button
          class="add-new-question-btn"
          type="button"
          tabindex="0"
          [attr.aria-label]="'translations.global.dataForms.aiAssistant.clickToAddPrompt' | transloco"
          (click)="addAction()"
        >
          <svg class="svg svg-icon icon-left" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#plus"></use>
          </svg>
          <span>{{ 'translations.global.dataForms.aiAssistant.clickToAddPrompt' | transloco }}</span>
        </button>
        <mat-slide-toggle
          class="mat-slide-toggle save-as-toggle mat-primary"
          color="primary"
          formControlName="isTemplate"
          [checked]="isTemplate"
          (change)="onSaveAsTemplate($event)"
        >
          {{ 'translations.global.dataForms.aiAssistant.settings.saveAsTemplate' | transloco }}
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</form>
