import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FileUploadHelper } from '../../../../shared/helpers/file-upload-helper';
import { EditorContent, ImageUploadContent, MediaUploadData, SingleUrlContent, StreamVideoContent } from '../../../../shared/models';
import { FileUploadService } from '../../../../shared/services/file-upload/file-upload.service';
import { ObservableResult } from '../../../../shared/store';

export class MomentFileUploadHelpers {
  static uploadMomentFiles(
    userUid: string,
    organizationId: string,
    momentContent: EditorContent[],
    fileUploadService: FileUploadService,
    resultContent: EditorContent[],
    onSuccess?: VoidFunction,
  ): ObservableResult<void> {
    const newContentUploadObservables = momentContent
      .filter(({ type, uid }) => type.endsWith('UPLOAD') && !uid)
      .map((content) => {
        const indexOfContent = momentContent.indexOf(content);
        const fileUrl = this.fileUrl(resultContent, indexOfContent);
        return fileUploadService.uploadFile(fileUrl, (content as MediaUploadData).file, '', false);
      });

    const singleObservable = newContentUploadObservables.length ? merge(...newContentUploadObservables) : ObservableResult.ofSuccess();

    return singleObservable.pipe(
      tap(() => {
        if (onSuccess) {
          return onSuccess();
        }
      }),
    );
  }

  private static fileUrl(resultContent: EditorContent[], indexOfContent): string {
    const mediaUploadData = resultContent[indexOfContent] as MediaUploadData;
    if (mediaUploadData.mediaType === 'IMAGE') {
      const content = mediaUploadData.content as ImageUploadContent;
      return FileUploadHelper.filePath(content.original);
    } else if (mediaUploadData.mediaType === 'STREAM_VIDEO') {
      const content = mediaUploadData.content as StreamVideoContent;
      return FileUploadHelper.filePath(content.url);
    } else {
      const content = mediaUploadData.content as SingleUrlContent;
      return FileUploadHelper.filePath(content.url);
    }
  }
}
