<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="share-link-container">
  <div class="share-link-container-inner">
    <div class="share-link-heading">
      <div class="large title">Share</div>
      <button
        mat-icon-button
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.closeFolioHelpModal' | transloco"
        (click)="closeDialog()"
      >
        <svg class="close-svg svg svg-icon" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
        </svg>
      </button>
    </div>

    <div class="copy-share-link-container">
      <div class="share-input">
        <input type="text" id="copyLink" [value]="shareUrl" readonly />
        <button
          tabindex="0"
          class="copy-btn"
          color="accent"
          mat-icon-button
          [attr.aria-label]="'translations.global.ariaLabels.tapToCopyLink' | transloco"
          (click)="shareAction(socialShareType.COPY)"
        >
          <svg class="svg svg-icon" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#copy"></use>
          </svg>
        </button>
      </div>
    </div>

    <div class="share-socials-items-container">
      <div class="share-social-items-list">
        <div *ngFor="let socialItem of socialShareItems" class="share-social-item">
          <button
            tabindex="0"
            type="button"
            [attr.aria-label]="
              'translations.global.ariaLabels.tapToShareLinkInSocial'
                | transloco
                  : {
                      url: shareUrl,
                      social: socialItem.title,
                    }
            "
            class="social-item-button {{ socialItem.name }}"
            (click)="shareAction(socialItem.name)"
          >
            <svg class="svg" role="img" [attr.aria-label]="socialItem.name + ' icon'" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#{{ socialItem.name }}"></use>
            </svg>
          </button>
          <div class="social-item-title small">{{ socialItem.title }}</div>
        </div>
        <div class="share-social-item">
          <button
            tabindex="0"
            type="button"
            [class.is-active]="embedCodeVisible"
            [attr.aria-label]="
              'translations.global.ariaLabels.tapToShareLinkInSocial'
                | transloco
                  : {
                      url: shareUrl,
                      social: 'Embed',
                    }
            "
            class="social-item-button embed-code"
            (click)="embedCodeVisible = !embedCodeVisible"
          >
            <svg class="svg" role="img" aria-label="embed-code icon" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#embed-code"></use>
            </svg>
          </button>
          <div class="social-item-title small">Embed</div>
        </div>
      </div>
    </div>

    <div class="embed-code-container" *ngIf="embedCodeVisible">
      <div class="embed-code-area">{{ contentCode }}</div>
      <div class="copy-btn-container">
        <button
          tabindex="0"
          class="copy-btn"
          color="accent"
          mat-icon-button
          [attr.aria-label]="'translations.global.ariaLabels.tapToCopyLink' | transloco"
          (click)="copyAction()"
        >
          <svg class="svg svg-icon" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#copy"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
