/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Router } from '@angular/router';

export class ProjectHelper {
  static isProjectByUrl(router: Router): boolean {
    return router.url?.indexOf('/projects/') !== -1;
  }

  static isProjectPublicView(): boolean {
    return document.location.pathname.includes('/public/projects/');
  }
}
