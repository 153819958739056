/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { ObservableResult } from '../../store';
import { CreateLanguage, Language, OrganizationLanguageSummary } from '../../models/languages/languages.model';
import { LanguagesDataService } from './languages-data.service';
import { DEFAULT_LANGUAGE_CODE } from './language.service';

@Injectable()
export class MockLanguagesDataService implements LanguagesDataService {
  private languages: Language[] = [
    {
      code: DEFAULT_LANGUAGE_CODE,
      name: 'English',
      orientation: 'LEFT_TO_RIGHT',
    },
  ];

  private organizationLanguages: OrganizationLanguageSummary = {
    organizationId: 'someId',
    defaultLanguage: {
      code: DEFAULT_LANGUAGE_CODE,
      name: 'English',
      orientation: 'LEFT_TO_RIGHT',
    },
    supportedLanguages: [
      {
        language: {
          code: DEFAULT_LANGUAGE_CODE,
          name: 'English',
          orientation: 'LEFT_TO_RIGHT',
        },
        isActive: true,
        isDefault: true,
      },
    ],
  };

  getLanguages(): ObservableResult<Language[]> {
    return ObservableResult.ofSuccess(this.languages);
  }

  getActiveOrganizationLanguages(organizationId: string): ObservableResult<Language[]> {
    return ObservableResult.ofSuccess(this.organizationLanguages.supportedLanguages.map((it) => it.language));
  }

  getOrganizationLanguages(organizationId: string): ObservableResult<OrganizationLanguageSummary> {
    return ObservableResult.ofSuccess(this.organizationLanguages);
  }

  createOrganizationLanguages(organizationId: string, code: CreateLanguage): ObservableResult<OrganizationLanguageSummary> {
    return ObservableResult.ofSuccess(this.organizationLanguages);
  }

  deleteOrganizationLanguages(organizationId: string, code: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  languageActivation(organizationId: string, code: string, isActive: boolean): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  setDefaultLanguage(organizationId: string, code: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
