/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { MentorMenteeDataService, PendingMenteesDecisionRequest } from './mentor-mentee-data.service';
import {
  Mentee,
  MenteeAssignment,
  Mentor,
  MentorshipImportReport,
  MentorshipStatus,
} from '@app/app/page-modules/admin/components/mentors/admin-mentors.model';
import { Page } from '@app/app/shared/models/page';
import { ObservableResult } from '@app/app/shared/store';
import { MembersBooleanSearchRequest } from '@app/app/shared/models/admin/boolean-filters.model';
import { UserSearch } from '@app/app/shared/models/admin/members.model';

@Injectable()
export class MockMentorMenteeDataService implements MentorMenteeDataService {
  searchByMentor(page: number, size: number, request?: MembersBooleanSearchRequest): ObservableResult<Page<Mentor>> {
    return ObservableResult.ofSuccess();
  }

  searchByMentee(page: number, size: number, request?: MembersBooleanSearchRequest): ObservableResult<Page<Mentee>> {
    return ObservableResult.ofSuccess();
  }

  findMenteesForMentor(page: number, size: number, mentorUserUid: string, term?: string): ObservableResult<Page<Mentee>> {
    return ObservableResult.ofSuccess();
  }

  findMentorsForMentee(page: number, size: number, menteeUserUid: string, term?: string): ObservableResult<Page<Mentor>> {
    return ObservableResult.ofSuccess();
  }

  findMentors(page: number, size: number, term?: string, statuses?: MentorshipStatus[]): ObservableResult<Page<Mentor>> {
    return ObservableResult.ofSuccess();
  }

  assignMenteeToMentor(mentorUserUid: string, menteeUserUid: string, status: MentorshipStatus): ObservableResult<MenteeAssignment> {
    return ObservableResult.ofSuccess();
  }

  deleteMenteeFromMentor(mentorUserUid: string, menteeUserUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  importMentees(menteesFile: File, sendNotifications: boolean): ObservableResult<MentorshipImportReport> {
    return ObservableResult.ofSuccess();
  }

  postPendingMenteesDecision(decision: PendingMenteesDecisionRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  findPendingMentees(): ObservableResult<UserSearch[]> {
    return ObservableResult.ofSuccess();
  }
}
