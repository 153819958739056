/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import { CardCompletionRequirements } from '../../../shared/models/completion-logic/completion-logic.model';
import { ObservableResult } from '../../../shared/store';
import { CardWithProgress, LearnerFormAnswerRequest, LearnerFormUpdatePermission, LearnerResourceProgress } from '../models';
import { Resource } from '../../../shared/models';
import { PrerequisitesRequest, RequirementsMode } from '../../playlist/models';
import { SubmitPlaylistSummary } from 'src/app/shared/models/playlist/playlist-report.model';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const LEARNER_VIEW_DATA_SERVICE = new InjectionToken<LearnerViewDataService>('[Learner view] DataService');

/** The discovery data service. */
export interface LearnerViewDataService {
  /**
   * Gets the details for a resource together with the user progress on that resource..
   */
  getCardWithProgress(
    isProjectResource: boolean,
    playlistUri: string,
    resourceUri: string,
    publisherUri: string,
    packageUri: string,
    pageUri: string,
    languageCode: string,
  ): ObservableResult<CardWithProgress>;

  validateFormUpdate(playlistUid: string, resourceUid: string, formUid: string): ObservableResult<LearnerFormUpdatePermission>;

  /**
   * Adds an answer for a given form.
   */
  saveFormAnswer(playlistUid: string, resourceUid: string, formAnswer: LearnerFormAnswerRequest): ObservableResult<LearnerResourceProgress>;

  submitForm(playlistUid: string, resourceUid: string, formId: string): ObservableResult<void>;

  /**
   * Adds an answer for a given form and playlist to review.
   */
  submitFormAndPlaylist(playlistUid: string, resourceUid: string, formId: string, request: SubmitPlaylistSummary): ObservableResult<void>;

  removeFileFromForm(playlistUid: string, resourceUid: string, formUid: string, filePath: string): ObservableResult<void>;

  /**
   * Loads all activities by card URI in playlistUri matching playlist
   */
  loadCompletionActivities(playlistUri: string, resourceUri: string): ObservableResult<CardCompletionRequirements>;

  /**
   * Sets Card Completion cardRequired property
   */
  setCardCompletionRequired(playlistUid: string, resourceUid: string, required: boolean): ObservableResult<void>;

  /**
   * Sets Card Completion activity required property
   */
  setActivityRequired(playlistId: string, resourceUid: string, activityId: string, required: boolean): ObservableResult<void>;

  /**
   * Updates Card completion prerequisites property
   */
  updateCompletionPrerequisites(plyalistUri: string, resourceUri: string, requestBody: PrerequisitesRequest): ObservableResult<void>;

  /**
   * Updates Card completion requirements mode
   */
  updateCompletionRequirementsMode(playlistId: string, mode: RequirementsMode, minRequiredCard: number): ObservableResult<void>;
}
