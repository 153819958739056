/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ShareBadgeUrlComponent } from '@app/app/shared/components/share-badge-url/share-badge-url.component';
import { ContentHelper } from '@app/app/shared/helpers/content-helper';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { SnackbarHelper } from '@app/app/shared/helpers/snackbar-helper';
import { FOLIO_BADGE_PREFIX } from '@app/app/shared/paths/folio/folio-paths';
import { EMPTY, Observable, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { FOLIO_DATA_SERVICE, FolioDataService } from '../../../services/data.service';
import { PublicFolioBadge } from '../../../store/folio.state.model';
import * as moment from 'moment/moment';
import { ShareService } from '@app/app/shared/services/share/share.service';
import { Select } from '@ngxs/store';
import { UserAuthState } from '@app/app/user-auth/store/user-auth.state';
import { UserDetailsSummary } from '@app/app/user-auth/models';

@Component({
  selector: 'ptl-public-badge-content',
  templateUrl: './public-badge-content-new.component.html',
  styleUrls: ['./public-badge-content-new.component.scss'],
})
export class PublicBadgeContentComponent implements OnInit, AfterViewInit, OnDestroy {
  skeletonViewActive = true;
  badgeData: PublicFolioBadge;
  isIframeMode: boolean;

  @Select(UserAuthState.userDetailsData)
  userDetails$: Observable<UserDetailsSummary>;

  private subscriptionEnd$ = new EventEmitter<void>();
  private authUserId: string;

  constructor(
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private shareService: ShareService,
    @Inject(FOLIO_DATA_SERVICE) private folioDataService: FolioDataService,
  ) {
    this.isIframeMode = ContentHelper.isFrameMode();
  }

  ngOnInit() {
    document.documentElement.style.setProperty('--dynamic-color', null);

    this.loadUserBadgeData();
    this.loadAuthUserId();
  }

  ngAfterViewInit() {
    this.overrideBody();
  }

  ngOnDestroy() {
    this.subscriptionEnd$.emit();
  }

  get isUserOwner(): boolean {
    return this.authUserId && this.badgeData?.userUid === this.authUserId;
  }

  loadAuthUserId() {
    this.userDetails$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((data) => {
      this.authUserId = data.uid;
    });
  }

  overrideBody() {
    if (this.isIframeMode) {
      const body = document.querySelector('body');

      this.renderer.setStyle(body, 'background-color', 'transparent');
    }
  }

  backToPreviousState() {
    this.location.back();
  }

  shareBadge() {
    const badgeId = this.activatedRoute.snapshot.paramMap.get('userBadgeId');
    const shareUrl = `${FOLIO_BADGE_PREFIX}${badgeId}`;

    this.dialog.open(ShareBadgeUrlComponent, {
      width: '30rem',
      maxHeight: '80vh',
      position: {
        top: '25vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: ['ptl-mat-dialog'],
      backdropClass: 'dialog-backdrop',
      data: {
        shareUrl: shareUrl,
      },
    });
  }

  shareLinkedInCertificate(): void {
    const { title, publisher, issuedOn, uid } = this.badgeData;
    const issueMonth = moment(issuedOn).format('MM');
    const issueYear = moment(issuedOn).format('YYYY');
    const badgeId = this.activatedRoute.snapshot.paramMap.get('userBadgeId');
    const shareUrl = `${FOLIO_BADGE_PREFIX}${badgeId}`;

    this.shareService.shareCertificateToLinkedin(title, publisher, issueMonth, issueYear, uid, shareUrl);
  }

  private loadUserBadgeData() {
    const badgeId = this.activatedRoute.snapshot.paramMap.get('userBadgeId');
    this.folioDataService
      .getFolioUserPublicBadge(badgeId)
      .pipe(
        switchMap(({ isSuccess, value, error }) => {
          if (isSuccess) {
            return of({ isSuccess, value });
          } else {
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
            this.skeletonViewActive = false;
            return EMPTY;
          }
        }),
      )
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.badgeData = value;
          this.skeletonViewActive = false;
        }
      });
  }
}
