import { animate, animateChild, query, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UpdateNotificationsCount } from 'src/app/app-frame/store/header-counts.action';
import { RedirectHelper } from '../../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { Notification } from 'src/app/shared/models/notifications/notifications.model';
import { ToastType } from 'src/app/shared/models/toast-notifications/toast-info';
import { NOTIFICATIONS_DATA_SERVICE, NotificationsDataService } from 'src/app/shared/services/notifications/notifications-data.service';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from 'src/app/shared/services/toast-notifications/toast-service';

@Component({
  selector: 'ptl-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('slideIn', [
      state(
        '*',
        style({
          transform: 'translateY(0) scale(1) rotateY(0)',
          opacity: 1,
          filter: 'blur(0) saturate(100%)',
        }),
      ),
      state(
        'void',
        style({
          transform: 'translateY(20px) scale(1.1) rotateY(5deg)',
          opacity: 0,
          filter: 'blur(2px) saturate(50%)',
        }),
      ),
      transition('void => *', animate('.3s ease-in-out')),
    ]),
    trigger('slideOut', [
      state(
        '*',
        style({
          transform: 'translateX(0)  scale(1)',
          opacity: 1,
        }),
      ),
      state(
        'void',
        style({
          transform: 'translateX(100%) scale(.7)',
          opacity: 0,
        }),
      ),
      transition('* => void', animate('.2s ease')),
    ]),
    trigger('triggerChildAnimation', [transition(':enter, :leave', [animate('0s'), query('*', [animateChild()])])]),
  ],
})
export class ToastComponent implements OnInit {
  @Input()
  type: ToastType;
  @Input()
  message: string;
  @Input()
  createdAt: Date;
  @Input()
  header?: string;
  @Input()
  notification?: Notification;
  @Input()
  delay?: number;

  wasDismissed = false;
  wasRead = false;

  constructor(
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
    @Inject(NOTIFICATIONS_DATA_SERVICE) private notificationsService: NotificationsDataService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.dissmiss(false);
    }, this.delay);
  }

  redirectToNotificationDetails() {
    if (this.isNotificationReferenceAvailable()) {
      this.markNotificationAsRead();
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, this.notification.formattedUri);
    }
  }

  dissmiss(markAsRead: boolean) {
    if (this.type === ToastType.PUSH_NOTIFICATION && markAsRead) {
      this.markNotificationAsRead();
    }
    if (!this.wasDismissed) {
      this.toastService.remove(this.createdAt);
    }
    this.wasDismissed = true;
  }

  private markNotificationAsRead() {
    if (this.isNotificationReferenceAvailable()) {
      if (!this.wasRead) {
        this.notificationsService
          .markNotificationAsRead(this.notification.id, true)
          .subscribe(() => this.store.dispatch(new UpdateNotificationsCount()));
        this.wasRead = true;
      }
    }
  }

  isNotificationReferenceAvailable() {
    if (this.notification) {
      return true;
    }
    return false;
  }

  protected readonly ToastType = ToastType;
}
