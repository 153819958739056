/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

// -------------------------------------------------------------------------
export class SetReviewDetails {
  static readonly type = '[Reviews] Set review details';

  constructor(
    public reviewUid: string,
    public cardUid: string,
    public userUid: string,
    public playlistUid: string,
    public learnerFirstName: string,
    public learnerLastName: string,
    public learnerImage: string,
  ) {}
}

export class GetReviewDetails {
  static readonly type = '[Reviews] Get review details';
}
