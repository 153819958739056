<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<div class="completion-filter-container">
  <mat-card *ngIf="expanded" class="completion-filter-settings">
    <div class="completion-filter-settings__header">
      <button mat-button [matMenuTriggerFor]="completionTypeMenu" class="isBtn">
        <span class="small bold"> {{ 'translations.filter.has' | transloco }} {{ getCompletionTypeTranslation() }} </span>
        <mat-icon aria-hidden="true" class="icon">expand_more</mat-icon>
      </button>
      <span class="completion-filter-delete" (click)="deleteFilter()" (keydown.enter)="deleteFilter()">
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
        </svg>
      </span>
    </div>
    <div class="completion-filter-settings__content">
      <div class="completion-filter-settings__filter-type-box" [class.with-border]="filterType !== 'EVERYTHING'">
        <mat-select
          class="small bold filter-type-select-field"
          [attr.aria-label]="'translations.global.ariaLabels.selectCompletionFilterType' | transloco"
          [(ngModel)]="filterType"
        >
          <mat-option *ngIf="!isAdminPage" [value]="'EVERYTHING'" class="small filter-type-option">
            {{ 'translations.filter.completion.filterType.everything' | transloco }}
          </mat-option>
          <mat-option
            [value]="'CERTAIN_ACTIVITIES'"
            class="small filter-type-option"
            [disabled]="!shouldDisplayActivitiesFilters"
            [class.is-disabled]="!shouldDisplayActivitiesFilters"
          >
            {{ 'translations.filter.completion.filterType.certainActivities' | transloco }}
          </mat-option>
          <mat-option
            [value]="'AT_LEAST'"
            class="small filter-type-option"
            [disabled]="!shouldDisplayActivitiesFilters"
            [class.is-disabled]="!shouldDisplayActivitiesFilters"
          >
            {{ 'translations.filter.completion.filterType.atLeast' | transloco }}
          </mat-option>
          <mat-option
            [value]="'AT_MOST'"
            class="small filter-type-option no-border"
            [disabled]="!shouldDisplayActivitiesFilters"
            [class.is-disabled]="!shouldDisplayActivitiesFilters"
          >
            {{ 'translations.filter.completion.filterType.atMost' | transloco }}
          </mat-option>
        </mat-select>
      </div>
      <div class="completion-filter-settings__content-body" *ngIf="filterType !== 'EVERYTHING'">
        <div *ngIf="filterType === 'AT_LEAST' || filterType === 'AT_MOST'" class="at-certain-activities-body">
          <input
            class="required-resource-count-field small"
            type="number"
            min="0"
            [max]="maxResourceCount"
            [class.error]="resourceCountError"
            [attr.aria-label]="'translations.global.ariaLabels.specifyQuantityOfActivities' | transloco"
            autocomplete="off"
            (ngModelChange)="onInputChange()"
            [(ngModel)]="resourceCount"
          />
          <span class="small"
            >{{ 'translations.filter.completion.of' | transloco }} {{ maxResourceCount }}
            {{ isPlaylistPage && !isCardPage ? ('translations.filter.completion.cardPlural' | transloco) : '' }}
            {{ isCardPage && !isPlaylistPage ? ('translations.filter.completion.formPlural' | transloco) : '' }}
            {{ isAdminPage ? ('translations.filter.completion.playlistPlural' | transloco) : '' }}</span
          >
        </div>
        <div *ngIf="filterType === 'CERTAIN_ACTIVITIES'" class="certain-activities-body" [class.with-padding]="isAdminPage">
          <div *ngIf="isAdminPage">
            <mat-form-field class="playlist-search-field" appearance="outline">
              <mat-icon matPrefix>search</mat-icon>
              <input
                matInput
                [(ngModel)]="playlistSearchString"
                [attr.aria-label]="'translations.global.search.label.playlists' | transloco"
                placeholder="{{ 'translations.global.search.label.playlists' | transloco }}"
                (focus)="onFocus()"
                (paste)="onTextPaste($event)"
                (keyup)="onSearchInputChange($event)"
              />
            </mat-form-field>
          </div>
          <div *ngFor="let resource of certainResources" class="certain-activities__item">
            <div
              class="certain-activities__checkmark checkmark"
              [class.is_checked]="isResourceChecked(resource)"
              [attr.aria-label]="
                isResourceChecked(resource)
                  ? ('translations.global.ariaLabels.uncheckActivityCompletionFilter' | transloco: { activityName: resource.label })
                  : ('translations.global.ariaLabels.checkActivityCompletionFilter' | transloco: { activityName: resource.label })
              "
              (click)="changeActivity(resource)"
              (keydown.enter)="changeActivity(resource)"
            >
              <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.check' | transloco">
                <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
              </svg>
            </div>
            <span class="certain-activities__label" [matTooltip]="resource.label" matTooltipClass="medium-tooltip">{{
              resource.label
            }}</span>
          </div>
          <div class="playlist-search-spinner-box" *ngIf="loadingSearch">
            <mat-spinner [diameter]="25"></mat-spinner>
          </div>
          <div *ngIf="canLoadMore" class="see-more-btn-container">
            <button [disabled]="loadingSearch" mat-stroked-button class="see-more-btn" type="button" (click)="loadNextPageOfPlaylists()">
              <div class="button-content">
                <span class="small bold">{{ 'translations.filter.completion.loadMore' | transloco }}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="completion-filter-settings__footer">
      <button mat-stroked-button class="completion-filter-settings__btn" (click)="applyFilter()">
        {{ 'translations.global.button.apply' | transloco }}
      </button>
    </div>
  </mat-card>
</div>

<mat-menu #completionTypeMenu="matMenu">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      (click)="setCompletionType('COMPLETED')"
      tabindex="0"
      class="is-item"
      [attr.aria-label]="'translations.global.ariaLabels.hasCompleted' | transloco"
    >
      <span class="small">
        {{ 'translations.filter.has' | transloco }} {{ 'translations.filter.completion.type.completed' | transloco }}
      </span>
    </button>
    <button
      mat-menu-item
      (click)="setCompletionType('NOT_COMPLETED')"
      tabindex="0"
      class="is-item"
      [attr.aria-label]="'translations.global.ariaLabels.hasNotCompleted' | transloco"
    >
      <span class="small">
        {{ 'translations.filter.has' | transloco }} {{ 'translations.filter.completion.type.notCompleted' | transloco }}
      </span>
    </button>
    <button
      mat-menu-item
      (click)="setCompletionType('STARTED')"
      tabindex="0"
      class="is-item"
      [attr.aria-label]="'translations.global.ariaLabels.hasStarted' | transloco"
    >
      <span class="small">
        {{ 'translations.filter.has' | transloco }} {{ 'translations.filter.completion.type.started' | transloco }}
      </span>
    </button>
    <button
      mat-menu-item
      (click)="setCompletionType('NOT_STARTED')"
      tabindex="0"
      class="is-item"
      [attr.aria-label]="'translations.global.ariaLabels.hasNotStarted' | transloco"
    >
      <span class="small">
        {{ 'translations.filter.has' | transloco }} {{ 'translations.filter.completion.type.notStarted' | transloco }}
      </span>
    </button>
  </ng-template>
</mat-menu>
