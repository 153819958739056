/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

/* eslint-disable max-len */
import { Store } from '@ngxs/store';
import { PlaylistCreationStateModel } from '../../playlist-creation.state.model';
import * as PlaylistAdminActions from '../../playlist-creation.actions';
import { EditorHelper } from '../../../../../../shared/helpers/editor.helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlaylistDataService } from '../../../../services/create/core/data.service';
import { NgZone } from '@angular/core';
import { SnackbarHelper } from '../../../../../../shared/helpers/snackbar-helper';
import { cloneDeep } from 'lodash-es';
import { UpdateSidebarItemChildren } from '../../../../../../shared/side-nav-tree/store/side-nav.actions';

export class PlaylistNewSectionActionHelpers {
  static createSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    playlistDataService: PlaylistDataService,
    snackBar: MatSnackBar,
    ngZone: NgZone,
  ) {
    const resourceId = state.playlist.data._id;

    return playlistDataService.createPlaylistSection(resourceId).subscribe(({ isSuccess, value, error }) => {
      if (isSuccess) {
        patchState({
          playlist: {
            ...state.playlist,
            data: {
              ...state.playlist.data,
              standardSections: state.playlist.data.standardSections.concat(value),
              contentChanged: true,
            },
          },
        });
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 50);
      } else {
        SnackbarHelper.showSnackBar(ngZone, snackBar, error);
      }
    });
  }

  static deleteNewSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    { cards, sectionUid }: PlaylistAdminActions.DeleteNewSection,
    dataService: PlaylistDataService,
    snackBar: MatSnackBar,
    ngZone: NgZone,
  ) {
    const resourceId = state.playlist.data._id;
    const playlist = cloneDeep(state.playlist.data);
    if (cards === false) {
      let sectionIndex = playlist.standardSections.findIndex((section) => section.uid === sectionUid);
      const deletedSectionCards = playlist.standardSections[sectionIndex]?.cards;
      if (deletedSectionCards) {
        if (sectionIndex > 0) {
          sectionIndex = sectionIndex - 1 < 0 ? 0 : sectionIndex - 1;
          playlist.standardSections[sectionIndex].cards = playlist.standardSections[sectionIndex].cards.concat(deletedSectionCards);
        } else {
          playlist.mainSection.cards = playlist.mainSection.cards.concat(deletedSectionCards);
        }
      }
    }
    playlist.standardSections = playlist.standardSections.filter((section) => section.uid !== sectionUid);

    return dataService.deletePlaylistSection(resourceId, sectionUid, cards).subscribe(({ isSuccess, error }) => {
      if (isSuccess) {
        patchState({
          playlist: {
            ...state.playlist,
            data: {
              ...playlist,
              contentChanged: true,
            },
          },
        });
      } else {
        SnackbarHelper.showSnackBar(ngZone, snackBar, error);
      }
    });
  }

  static reorderPlaylistSections(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    { reorder }: PlaylistAdminActions.ReorderPlaylistSections,
    dataService: PlaylistDataService,
    snackBar: MatSnackBar,
    ngZone: NgZone,
  ) {
    const resourceId = state.playlist.data._id;

    // roderder section
    const draggedSection = state.playlist.data.standardSections[reorder.dragIndex];
    const standardSections = state.playlist.data.standardSections;
    standardSections.splice(reorder.dragIndex, 1);
    standardSections.splice(reorder.dropIndex, 0, draggedSection);

    const request = {
      sections: [],
    };
    for (const section of standardSections) {
      request.sections.push(section.uid);
    }
    return dataService.reorderPlaylistSections(resourceId, request).subscribe(({ isSuccess, error }) => {
      if (isSuccess) {
        patchState({
          playlist: {
            ...state.playlist,
            data: {
              ...state.playlist.data,
              standardSections: standardSections,
              contentChanged: true,
            },
          },
        });
      } else {
        SnackbarHelper.showSnackBar(ngZone, snackBar, error);
      }
    });
  }

  static addDynamicContentToNewSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    { sectionIndex, content }: PlaylistAdminActions.AddDynamicContentToNewSection,
  ) {
    patchState({
      playlist: {
        ...state.playlist,
        data: {
          ...state.playlist.data,
          standardSections: state.playlist.data.standardSections.map((section, idx) =>
            idx === sectionIndex
              ? {
                  ...section,
                  content: EditorHelper.addDynamicContentWithBreakpointCheck(section.content, content),
                }
              : section,
          ),
          contentChanged: true,
        },
      },
    });
  }

  static updateDynamicContentOfNewSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.UpdateDynamicContentOfNewSection,
  ) {
    const { sectionIndex, updatedContentIndex, updateContent } = action;

    patchState({
      playlist: {
        ...state.playlist,
        data: {
          ...state.playlist.data,
          standardSections: state.playlist.data.standardSections.map((section, idx) =>
            idx === sectionIndex
              ? {
                  ...section,
                  content: section.content.map((content, contentIndex) => (contentIndex === updatedContentIndex ? updateContent : content)),
                }
              : section,
          ),
          contentChanged: true,
        },
      },
    });
  }

  static removeDynamicContentFromNewSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    { sectionIndex, dynamicContentIndex }: PlaylistAdminActions.RemoveDynamicContentFromNewSection,
  ) {
    patchState({
      playlist: {
        ...state.playlist,
        data: {
          ...state.playlist.data,
          standardSections: state.playlist.data.standardSections.map((section, idx) =>
            idx === sectionIndex
              ? {
                  ...section,
                  content: EditorHelper.removeDynamicContentByIndexAndJoinParagraphContent(dynamicContentIndex, section.content),
                }
              : section,
          ),
          contentChanged: true,
        },
      },
    });
  }

  static removeAllContentFromNewSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    { sectionIndex }: PlaylistAdminActions.RemoveAllContentFromNewSection,
  ) {
    patchState({
      playlist: {
        ...state.playlist,
        data: {
          ...state.playlist.data,
          standardSections: state.playlist.data.standardSections.map((section, idx) =>
            idx === sectionIndex ? { ...section, content: [], cards: undefined, title: undefined } : section,
          ),
          contentChanged: true,
        },
      },
    });
  }

  static fireNewSectionPaletteAction(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    { sectionIndex, action }: PlaylistAdminActions.FireNewSectionPaletteAction,
    store: Store,
  ) {
    if (action === 'NEW_PLAYLIST') {
      return store.dispatch(new PlaylistAdminActions.SavePlaylistAndNavigateToSubPlaylistCreation(sectionIndex));
    }

    if (action === 'NEW_SECTION') {
      return store.dispatch(new PlaylistAdminActions.CreateSection());
    }

    if (action === 'NEW_CARD') {
      return store.dispatch(new PlaylistAdminActions.CreateNewCardInSection(sectionIndex));
    }

    if (action === 'NEW_EVENT_CARD') {
      return store.dispatch(new PlaylistAdminActions.CreateNewEventCardInSection(sectionIndex));
    }

    if (action === 'DIAGNOSTICS') {
      return store.dispatch(new PlaylistAdminActions.CreateNewDiagnosticsSection(sectionIndex));
    }

    patchState({
      playlist: {
        ...state.playlist,
        data: {
          ...state.playlist.data,
          contentChanged: true,
        },
      },
    });
    return undefined;
  }

  static removeCardFromSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    { sectionIndex, playlistId, sectionId, cardId }: PlaylistAdminActions.RemoveCardFromSection,
    dataService: PlaylistDataService,
    snackBar: MatSnackBar,
    ngZone: NgZone,
    store: Store,
  ) {
    return dataService.removeCardFromSection(playlistId, sectionId, cardId).subscribe(({ isSuccess, error }) => {
      if (isSuccess) {
        patchState({
          playlist: {
            ...state.playlist,
            data: {
              ...state.playlist.data,
              standardSections: state.playlist.data.standardSections.map((section, idx) =>
                idx === sectionIndex
                  ? {
                      ...section,
                      cards: section.cards.filter((card) => card._id !== cardId),
                    }
                  : section,
              ),
              contentChanged: true,
            },
          },
        });
        store.dispatch(new UpdateSidebarItemChildren(playlistId, false));
      } else {
        SnackbarHelper.showSnackBar(ngZone, snackBar, error);
      }
    });
  }
}
