/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BooleanFilter, BooleanFilterType } from '@app/app/shared/models/admin/boolean-filters.model';
import { SubmissionMode } from '@app/app/page-modules/playlist/models/submission-logic.model';
import { marker } from '@jsverse/transloco-keys-manager/marker';

@Component({
  selector: 'ptl-control-panel-filter-chips',
  templateUrl: './control-panel-filter-chips.component.html',
  styleUrls: ['./control-panel-filter-chips.component.scss'],
})
export class ControlPanelFilterChipsComponent {
  @Input() filters: BooleanFilter[] = [];
  @Input() currentFilter: BooleanFilterType;
  @Input() newFilter: BooleanFilterType;
  @Input() playlistUid: string;
  @Input() playlistUids: string[];
  @Input() submissionMode: SubmissionMode;
  @Input() supportedFilters: BooleanFilterType[] = [];

  @Output() filterSelected = new EventEmitter<BooleanFilterType>();
  @Output() filterDeleted = new EventEmitter<BooleanFilterType>();
  @Output() filterSaved = new EventEmitter<BooleanFilter>();

  getFilterText(filterType: string): string {
    const translations = {
      REVIEW_STATUS: marker('translations.filter.review.name'),
      GROUP: marker('translations.reviews.filter.group'),
      ROLE: marker('translations.filter.role.name'),
      ATTENDANCE: marker('translations.filter.attendance.name'),
      ACTIVITY: marker('translations.filter.activity.name'),
    };
    return translations[filterType];
  }

  isFilterSupported(filterType: BooleanFilterType): boolean {
    return this.supportedFilters.includes(filterType);
  }
}
