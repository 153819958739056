import { EditorContent } from '@app/app/shared/models';
import { cloneDeep } from 'lodash-es';

export class EditorBlockHelper {
  static addBlockToDynamicContentArray(newBlock: EditorContent, dynamicContent: EditorContent[]): EditorContent[] {
    const updatedDynamicContent: EditorContent[] = cloneDeep(dynamicContent);

    if (newBlock.appendToBlock) {
      const appendToBlockIndex = dynamicContent.findIndex((it) => it.uid === newBlock.appendToBlock);
      if (appendToBlockIndex === -1) throw new Error(`Append to block ${newBlock.appendToBlock} not found!`);
      updatedDynamicContent.splice(appendToBlockIndex + 1, 0, newBlock);
    } else {
      updatedDynamicContent.splice(0, 0, newBlock);
    }

    return updatedDynamicContent;
  }

  static updateBlockAtDynamicContentArray(updatedBlock: EditorContent, dynamicContent: EditorContent[]): EditorContent[] {
    const blockIndex = dynamicContent.findIndex((it) => it.uid === updatedBlock.uid);
    if (blockIndex === -1) throw new Error(`Block with id ${updatedBlock.uid} not found dynamic content array ${dynamicContent}!`);

    const updatedDynamicContent: EditorContent[] = cloneDeep(dynamicContent);
    updatedDynamicContent.splice(blockIndex, 1, updatedBlock);

    return updatedDynamicContent;
  }

  static removeBlockAtDynamicContentArray(blockUid: string, dynamicContent: EditorContent[]): EditorContent[] {
    const blockToRemoveIndex = dynamicContent.findIndex((it) => it.uid === blockUid);
    if (blockToRemoveIndex === -1) throw new Error(`Block ${blockUid} to remove not found!`);

    const updatedDynamicContent: EditorContent[] = cloneDeep(dynamicContent);
    updatedDynamicContent.splice(blockToRemoveIndex, 1);

    return updatedDynamicContent;
  }

  static blockBackendResponseAppliedAtDynamicContent(blocksUid: string[], dynamicContent: EditorContent[]): EditorContent[] {
    const updatedDynamicContent: EditorContent[] = cloneDeep(dynamicContent);
    updatedDynamicContent.map((block) => {
      if (blocksUid.includes(block.uid)) {
        block.waitsForBackendResponse = false;
      }
    });
    return updatedDynamicContent;
  }
}
