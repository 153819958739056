/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { OrganizationsDataService, UpdateOrganizationResponse } from './data.service';
import { CreateOrganizationRequest, UpdateOrganizationRequest, StatusUpdateRequest } from '../models';
import { ObservableResult } from '../../../../../shared/store';
import { Organization, Playlist } from '../../../../../shared/models';
import { Page } from '../../../../../shared/models/page';
import { getDummyOrganizationData } from '../../../../../shared/models/organization/organization-mock-data';

export class MockOrganizationsDataService implements OrganizationsDataService {
  readonly dummyOrg: Organization = getDummyOrganizationData();

  createOrganization(request: CreateOrganizationRequest): ObservableResult<Organization> {
    return ObservableResult.ofSuccess(this.dummyOrg);
  }

  addOwnerForOrganization(orgId: string, ownerId: string): ObservableResult<Organization> {
    return ObservableResult.ofSuccess({
      ...this.dummyOrg,
      ownerId,
    });
  }

  updateOrganization(id: string, request: UpdateOrganizationRequest): ObservableResult<UpdateOrganizationResponse> {
    return ObservableResult.ofSuccess({
      ...this.dummyOrg,
      ...request,
    });
  }

  loadOrganizations(): ObservableResult<Organization[]> {
    return ObservableResult.ofSuccess([this.dummyOrg]);
  }

  updateOrganizationStatus(id: string, { status }: StatusUpdateRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  getOrganizationDetails(id: string): ObservableResult<Organization> {
    return ObservableResult.ofSuccess(this.dummyOrg);
  }

  getGlobalPlaylists(term: string, page: number, size: number): ObservableResult<Page<Playlist>> {
    return ObservableResult.ofSuccess();
  }
}
