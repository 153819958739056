/*
 * Copyright (C) 2023 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, NgZone, OnDestroy, Output, ViewChild } from '@angular/core';
import { CheckedMembersData, Members, MembersSearchOrder } from '@app/app/shared/models/admin/members.model';
import { AdminMembersAction, MembersListColumns } from '../model/members.model';
import { AdminMemberTableComponent } from './admin-member-table/admin-member-table.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectHelper } from '@app/app/page-modules/resource/store/editor/content/helpers/redirect.helper';
import { Store } from '@ngxs/store';
import { PaginationFilter } from '@app/app/page-modules/admin/components/members/store/members.state.model';
import {
  UpdateCardPaginationFilters,
  UpdateMemberPaginationFilters,
  UpdatePlaylistPaginationFilters,
} from '@app/app/page-modules/admin/components/members/store/members.action';
import { MembersBooleanSearchRequest } from '@app/app/shared/models/admin/boolean-filters.model';

@Component({
  selector: 'ptl-admin-member-list',
  templateUrl: './admin-member-list.component.html',
  styleUrls: ['./admin-member-list.component.scss'],
})
export class AdminMemberListComponent implements OnDestroy {
  @Input() columns: MembersListColumns[];
  @Input() isPlaylistPage: boolean;
  @Input() isCardPage: boolean;
  @Input() isEvent: boolean;
  @Input() checkedMembers: CheckedMembersData[];
  @Input() members: Members[];
  @Input() membersTotalCount: number;
  @Input() isWaitingListEnabled: boolean;
  @Input() isWaitingListOperationDisabled: boolean;
  @Input() orderInput: MembersSearchOrder;
  @Input() paginationLength = 0;
  @Input() cardUid: string;
  @Input() playlistUid: string;
  @Input() booleanSearchRequest: MembersBooleanSearchRequest;
  @Input() currentPage = 0;
  @Input() pageSize = 10;

  @Output() orderChanged = new EventEmitter<{ order: MembersSearchOrder; page: number; pageSize: number }>();
  @Output() membersChecked = new EventEmitter<CheckedMembersData[]>();
  @Output() memberActionTriggered = new EventEmitter<AdminMembersAction>();
  @Output() refreshList = new EventEmitter<number>();

  @ViewChild('defaultTable') defaultTable: AdminMemberTableComponent;

  isLoaderActive = false;

  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private store: Store,
    private activatedRoute: ActivatedRoute,
  ) {}
  ngOnDestroy(): void {
    this.subscriptionEnd$?.emit();
  }

  updatePagination(): void {
    const paginationFilter: PaginationFilter = {
      page: this.currentPage,
      pageSize: this.pageSize,
      order: this.orderInput,
    };

    if (this.isCardPage) {
      this.store.dispatch(new UpdateCardPaginationFilters(this.cardUid, paginationFilter));
    } else if (this.isPlaylistPage) {
      this.store.dispatch(new UpdatePlaylistPaginationFilters(this.playlistUid, paginationFilter));
    } else {
      this.store.dispatch(new UpdateMemberPaginationFilters(paginationFilter));
    }
  }

  setIsLoaderActive(active: boolean) {
    this.isLoaderActive = active;
  }

  setPageSize(size: number) {
    this.pageSize = size;
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
  }

  openUserRecord(userUid: string) {
    const url = this.router.createUrlTree([userUid, 'user-record'], { relativeTo: this.activatedRoute });

    if (this.isPlaylistPage || this.isCardPage) {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, url.toString(), null);
    } else {
      window.open(url.toString(), '_blank');
    }
  }

  orderChangedTrigger(data: { order: MembersSearchOrder; page: number; pageSize: number }): void {
    this.orderInput = data.order;
    this.currentPage = data.page;
    this.pageSize = data.pageSize;
    this.updatePagination();
  }

  membersCheckedTrigger(event) {
    this.membersChecked.emit(event);
  }

  memberActionTrigger(event) {
    this.memberActionTriggered.emit(event);
  }
}
