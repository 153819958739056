<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="instruction-box">
  <div class="instruction-container" [class.is-opened]="isOpened">
    <div class="instruction-container-inner" #instructionContainerInner>
      <ptl-editor
        class="thin instruction no-outline"
        (inputFocus)="isOpened = true"
        [placeholder]="'translations.enterInstruction' | transloco"
        [link]="false"
        [upload]="false"
        [separator]="false"
        (contentRemoval)="isOpened = true; onContentRemoval($event)"
        (contentUpdate)="isOpened = true; onContentUpdate($event)"
        [content]="content"
      >
      </ptl-editor>
    </div>
  </div>

  <button
    *ngIf="showMoreButtonsAreVisible"
    mat-button
    class="more-btn"
    type="button"
    [attr.aria-label]="'translations.global.ariaLabels.removeOption' | transloco"
    (click)="toggleMore()"
  >
    <ng-container *ngIf="isOpened">
      <span>{{ 'translations.global.button.less' | transloco }}</span>
    </ng-container>
    <ng-container *ngIf="!isOpened">
      <span>{{ 'translations.global.button.more' | transloco }}</span>
    </ng-container>
  </button>
</div>
