/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../../shared/store';
import { Injectable } from '@angular/core';
import { UnsplashImagesDataService } from './unsplash-images-data.service';
import { UnsplashPhotoList, UsableUnsplashPhoto } from '../../../../shared/models/images/unsplash-images.model';

@Injectable()
export class MockUnsplashImagesDataService implements UnsplashImagesDataService {
  search(phrase: string, page: number): ObservableResult<UnsplashPhotoList> {
    return ObservableResult.ofSuccess();
  }

  download(url: string): ObservableResult<UsableUnsplashPhoto> {
    return ObservableResult.ofSuccess();
  }
}
