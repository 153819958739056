/*
 * Copyright (C) 2025 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { ObservableResult } from '@app/app/shared/store';
import { Page } from '@app/app/shared/models/page';
import { RestClientService } from '@app/app/shared/services/rest-client.service';
import { TranslocoService } from '@ngneat/transloco';
import {
  MentorMenteeDataService,
  PendingMenteesDecisionRequest,
} from '@app/app/page-modules/admin/services/mentor-mentee/mentor-mentee-data.service';
import {
  Mentee,
  MenteeAssignment,
  Mentor,
  MentorshipImportReport,
  MentorshipStatus,
} from '@app/app/page-modules/admin/components/mentors/admin-mentors.model';
import { Location } from '@angular/common';
import { environment } from '@app/environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { MembersBooleanSearchRequest } from '@app/app/shared/models/admin/boolean-filters.model';
import { UserSearch } from '@app/app/shared/models/admin/members.model';

@Injectable()
export class ApiMentorMenteeDataService implements MentorMenteeDataService {
  constructor(
    private restClient: RestClientService,
    private translocoService: TranslocoService,
  ) {}
  searchByMentor(page: number, size: number, request?: MembersBooleanSearchRequest): ObservableResult<Page<Mentor>> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `mentorships/admin/search-mentors?page=${page}&size=${size}`);

    return this.restClient.post<Page<Mentor>>(url, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorSearchMentors'))),
    );
  }

  searchByMentee(page: number, size: number, request?: MembersBooleanSearchRequest): ObservableResult<Page<Mentee>> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `mentorships/admin/search-mentees?page=${page}&size=${size}`);

    return this.restClient.post<Page<Mentee>>(url, request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorSearchMentees'))),
    );
  }

  findMenteesForMentor(page: number, size: number, mentorUserUid: string, term?: string): ObservableResult<Page<Mentee>> {
    const params = {};
    if (term) {
      params['term'] = term;
    }
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '',
      `mentorships/mentors/${mentorUserUid}/mentees?page=${page}&size=${size}`,
    );

    return this.restClient.get<Page<Mentee>>(url, params).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRetrieveMentees'))),
    );
  }

  findMentorsForMentee(page: number, size: number, menteeUserUid: string, term?: string): ObservableResult<Page<Mentor>> {
    const params = {};
    if (term) {
      params['term'] = term;
    }
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '',
      `mentorships/mentees/${menteeUserUid}/mentors?page=${page}&size=${size}`,
    );

    return this.restClient.get<Page<Mentor>>(url, params).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRetrieveMentors'))),
    );
  }

  findMentors(page: number, size: number, term?: string, statuses?: MentorshipStatus[]): ObservableResult<Page<Mentor>> {
    const params = {};
    if (term) {
      params['term'] = term;
    }
    if (statuses && statuses.length > 0) {
      params['statuses'] = statuses;
    }

    const url = Location.joinWithSlash(environment.apiRootUrl || '', `mentorships/mentors?page=${page}&size=${size}`);

    return this.restClient.get<Page<Mentor>>(url, params).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRetrieveMentors'))),
    );
  }

  assignMenteeToMentor(mentorUserUid: string, menteeUserUid: string, status: MentorshipStatus): ObservableResult<MenteeAssignment> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `mentorships/mentors/${mentorUserUid}/mentees/${menteeUserUid}`);
    const requestBody = { status };

    return this.restClient.put<MenteeAssignment>(url, requestBody).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorAssignMentee'))),
    );
  }

  deleteMenteeFromMentor(mentorUserUid: string, menteeUserUid: string): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `mentorships/mentors/${mentorUserUid}/mentees/${menteeUserUid}`);

    return this.restClient.delete<void>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRemoveMentee'))),
    );
  }

  importMentees(menteesFile: File, sendNotifications: boolean): ObservableResult<MentorshipImportReport> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', 'mentorships/admin/import-mentees');

    const formData = new FormData();
    formData.append('file', menteesFile, menteesFile.name);
    formData.append('sendNotifications', String(sendNotifications));

    return this.restClient.post<MentorshipImportReport>(url, formData).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorImportMentee'))),
    );
  }

  postPendingMenteesDecision(decision: PendingMenteesDecisionRequest): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', 'mentorships/pending');

    return this.restClient.post<void>(url, decision).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatePendingMenteesStatus'))),
    );
  }

  findPendingMentees(): ObservableResult<UserSearch[]> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `mentorships/pending`);

    return this.restClient.get<UserSearch[]>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRetrieveMentees'))),
    );
  }
}
