/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { NgZone } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ProjectHelper } from '../../../../../project/helpers/project.helper';

export class RedirectHelper {
  static redirectByUrl(
    ngZone: NgZone,
    router: Router,
    activatedRoute: ActivatedRoute,
    url: string,
    extras?: NavigationExtras,
    target?: string,
  ) {
    if (!extras) {
      this.redirectByFormattedUrl(ngZone, router, url, target);
    } else {
      ngZone.run(() => {
        router.navigate(url ? [url] : [], extras);
      });
    }
  }

  static redirectByParams(
    ngZone: NgZone,
    router: Router,
    activatedRoute: ActivatedRoute,
    uriParams: UriCustomParams,
    cardType: RedirectCardType,
    target?: string,
  ) {
    if (cardType === 'PAGE') {
      this.redirectToPage(
        ngZone,
        router,
        activatedRoute,
        uriParams.pageUri,
        uriParams.formattedUri,
        uriParams.extraUriParam,
        uriParams.queryUriParam,
        uriParams.openEditView,
        target,
      );
      return;
    }

    if (
      cardType === 'PLAYLIST' ||
      cardType === 'PERSONALITY_INDICATOR' ||
      cardType === 'INSTITUTIONAL_LIBRARY' ||
      cardType === 'CAREER_HUB' ||
      cardType === 'TARGET_CONNECT'
    ) {
      this.redirectToPlaylist(
        ngZone,
        router,
        activatedRoute,
        uriParams.playlistUri,
        uriParams.formattedUri,
        uriParams.extraUriParam,
        uriParams.queryUriParam,
        uriParams.openEditView,
        target,
      );
      return;
    }

    if (cardType === 'CARD') {
      this.redirectToCard(
        ngZone,
        router,
        activatedRoute,
        uriParams.resourceUri,
        uriParams.formattedUri,
        uriParams.pageNumberUri,
        uriParams.extraUriParam,
        uriParams.queryUriParam,
        uriParams.openEditView,
        target,
      );
      return;
    }

    if (cardType === 'ASSESSMENT' || cardType === 'RESOURCE' || cardType === 'EVENT' || cardType === 'WEBLINK') {
      this.redirectToResource(
        ngZone,
        router,
        activatedRoute,
        cardType,
        uriParams.playlistUri,
        uriParams.resourceUri,
        uriParams.formattedUri,
        uriParams.pageNumberUri,
        uriParams.extraUriParam,
        uriParams.queryUriParam,
        uriParams.openEditView,
        target,
      );
      return;
    }

    if (cardType === 'PROJECT') {
      this.redirectToProject(
        ngZone,
        router,
        uriParams.folioPublicId,
        uriParams.projectPublicView,
        uriParams.playlistUri,
        uriParams.formattedUri,
        uriParams.extraUriParam,
        uriParams.queryUriParam,
        uriParams.openEditView,
        target,
      );
      return;
    }

    if (cardType === 'PROJECT_CARD') {
      this.redirectToProjectCard(
        ngZone,
        router,
        uriParams.folioPublicId,
        uriParams.projectPublicView,
        uriParams.playlistUri,
        uriParams.resourceUri,
        uriParams.formattedUri,
        uriParams.extraUriParam,
        uriParams.queryUriParam,
        uriParams.openEditView,
        target,
      );
      return;
    }
  }

  static redirectToHomePage(ngZone: NgZone, router: Router, activatedRoute: ActivatedRoute, homePageUri: string) {
    if (homePageUri === '/') {
      this.redirectByUrl(ngZone, router, activatedRoute, '/');
    } else {
      this.redirectByFormattedUrl(ngZone, router, '/pages/' + homePageUri);
    }
  }

  static getRedirectUrl(activatedRoute: ActivatedRoute, uriParams: UriCustomParams, cardType: RedirectCardType) {
    if (cardType === 'PAGE') {
      return this.getPageRedirectUrl(
        activatedRoute,
        uriParams.pageUri,
        uriParams.formattedUri,
        uriParams.extraUriParam,
        uriParams.queryUriParam,
        uriParams.openEditView,
      );
    }

    if (
      cardType === 'PLAYLIST' ||
      cardType === 'PERSONALITY_INDICATOR' ||
      cardType === 'INSTITUTIONAL_LIBRARY' ||
      cardType === 'CAREER_HUB' ||
      cardType === 'TARGET_CONNECT'
    ) {
      return this.getPlaylistRedirectUrl(
        activatedRoute,
        uriParams.playlistUri,
        uriParams.formattedUri,
        uriParams.extraUriParam,
        uriParams.queryUriParam,
        uriParams.openEditView,
      );
    }

    if (cardType === 'CARD') {
      return this.getCardRedirectUrl(
        activatedRoute,
        uriParams.resourceUri,
        uriParams.pageNumberUri,
        uriParams.formattedUri,
        uriParams.extraUriParam,
        uriParams.queryUriParam,
        uriParams.openEditView,
      );
    }

    if (cardType === 'ASSESSMENT' || cardType === 'RESOURCE' || cardType === 'EVENT' || cardType === 'WEBLINK') {
      return this.getResourceRedirectUrl(
        activatedRoute,
        cardType,
        uriParams.playlistUri,
        uriParams.resourceUri,
        uriParams.formattedUri,
        uriParams.pageNumberUri,
        uriParams.extraUriParam,
        uriParams.queryUriParam,
        uriParams.openEditView,
      );
    }

    if (cardType === 'PROJECT') {
      return this.getProjectRedirectUrl(
        uriParams.folioPublicId,
        uriParams.projectPublicView,
        uriParams.playlistUri,
        uriParams.formattedUri,
        uriParams.extraUriParam,
        uriParams.queryUriParam,
        uriParams.openEditView,
      );
    }

    if (cardType === 'PROJECT_CARD') {
      return this.getProjectCardRedirectUrl(
        uriParams.folioPublicId,
        uriParams.projectPublicView,
        uriParams.playlistUri,
        uriParams.resourceUri,
        uriParams.formattedUri,
        uriParams.extraUriParam,
        uriParams.queryUriParam,
        uriParams.openEditView,
      );
    }
    return '';
  }

  private static redirectToPage(
    ngZone: NgZone,
    router: Router,
    activatedRoute: ActivatedRoute,
    pageUri: string,
    formattedUri: string,
    extraUriParam: string,
    queryUriParam: string,
    openEditView: boolean,
    target?: string,
  ) {
    const url = this.getPageRedirectUrl(activatedRoute, pageUri, formattedUri, extraUriParam, queryUriParam, openEditView);
    this.redirectByFormattedUrl(ngZone, router, url, target);
  }

  private static getPageRedirectUrl(
    activatedRoute: ActivatedRoute,
    pageUri: string,
    formattedUri: string,
    extraUriParam: string,
    queryUriParam: string,
    openEditView: boolean,
  ) {
    const urlPrefix = this.getRedirectUrlPrefix();
    let url = '/pages';
    if (pageUri) {
      url += `/${pageUri}`;
    }
    if (formattedUri) {
      url = formattedUri;
    }
    if (urlPrefix !== '') {
      url = urlPrefix + url;
    }
    if (openEditView) {
      url += '/edit';
    }
    return this.concatUrlParams(url, null, extraUriParam, queryUriParam);
  }

  private static redirectToPlaylist(
    ngZone: NgZone,
    router: Router,
    activatedRoute: ActivatedRoute,
    playlistUri: string,
    formattedUri: string,
    extraUriParam: string,
    queryUriParam: string,
    openEditView: boolean,
    target?: string,
  ) {
    const url = this.getPlaylistRedirectUrl(activatedRoute, playlistUri, formattedUri, extraUriParam, queryUriParam, openEditView);
    this.redirectByFormattedUrl(ngZone, router, url, target);
  }

  private static getPlaylistRedirectUrl(
    activatedRoute: ActivatedRoute,
    playlistUri: string,
    formattedUri: string,
    extraUriParam: string,
    queryUriParam: string,
    openEditView: boolean,
  ) {
    const urlPrefix = this.getRedirectUrlPrefix();
    let url = '/playlists';
    if (playlistUri) {
      url += `/${playlistUri}`;
    }
    if (formattedUri) {
      url = formattedUri;
    }
    if (urlPrefix !== '') {
      url = urlPrefix + url;
    }
    if (openEditView) {
      url += '/edit';
    }
    return this.concatUrlParams(url, null, extraUriParam, queryUriParam);
  }

  private static redirectToCard(
    ngZone: NgZone,
    router: Router,
    activatedRoute: ActivatedRoute,
    cardUri: string,
    formattedUri: string,
    pageNumberUri: string,
    extraUriParam: string,
    queryUriParam: string,
    openEditView: boolean,
    target?: string,
  ) {
    const url = this.getCardRedirectUrl(activatedRoute, cardUri, formattedUri, pageNumberUri, extraUriParam, queryUriParam, openEditView);
    this.redirectByFormattedUrl(ngZone, router, url, target);
  }

  private static getCardRedirectUrl(
    activatedRoute: ActivatedRoute,
    cardUri: string,
    formattedUri: string,
    pageNumberUri: string,
    extraUriParam: string,
    queryUriParam: string,
    openEditView: boolean,
  ) {
    const urlPrefix = this.getRedirectUrlPrefix();
    let url = '/cards';
    if (cardUri) {
      url += `/${cardUri}`;
    }
    if (formattedUri) {
      url = formattedUri;
    }
    if (urlPrefix !== '') {
      url = urlPrefix + url;
    }
    if (openEditView) {
      if (pageNumberUri) {
        pageNumberUri = pageNumberUri + '/edit';
      } else {
        pageNumberUri = '/edit';
      }
    }
    return this.concatUrlParams(url, pageNumberUri, extraUriParam, queryUriParam);
  }

  private static redirectToResource(
    ngZone: NgZone,
    router: Router,
    activatedRoute: ActivatedRoute,
    cardType: RedirectCardType,
    playlistUri: string,
    resourceUri: string,
    formattedUri: string,
    pageNumberUri: string,
    extraUriParam: string,
    queryUriParam: string,
    openEditView: boolean,
    target?: string,
  ) {
    const url = this.getResourceRedirectUrl(
      activatedRoute,
      cardType,
      playlistUri,
      resourceUri,
      formattedUri,
      pageNumberUri,
      extraUriParam,
      queryUriParam,
      openEditView,
    );
    this.redirectByFormattedUrl(ngZone, router, url, target);
  }

  private static redirectToProject(
    ngZone: NgZone,
    router: Router,
    folioPublicId: string,
    projectPublicView: boolean,
    playlistUri: string,
    formattedUri: string,
    extraUriParam: string,
    queryUriParam: string,
    openEditView: boolean,
    target?: string,
  ) {
    const url = this.getProjectRedirectUrl(
      folioPublicId,
      projectPublicView,
      playlistUri,
      formattedUri,
      extraUriParam,
      queryUriParam,
      openEditView,
    );
    this.redirectByFormattedUrl(ngZone, router, url, target);
  }

  private static redirectToProjectCard(
    ngZone: NgZone,
    router: Router,
    folioPublicId: string,
    projectPublicView: boolean,
    playlistUri: string,
    resourceUri: string,
    formattedUri: string,
    extraUriParam: string,
    queryUriParam: string,
    openEditView: boolean,
    target?: string,
  ) {
    const url = this.getProjectCardRedirectUrl(
      folioPublicId,
      projectPublicView,
      playlistUri,
      resourceUri,
      formattedUri,
      extraUriParam,
      queryUriParam,
      openEditView,
    );
    this.redirectByFormattedUrl(ngZone, router, url, target);
  }

  private static getProjectRedirectUrl(
    folioPublicId: string,
    projectPublicView: boolean,
    playlistUri: string,
    formattedUri: string,
    extraUriParam: string,
    queryUriParam: string,
    openEditView: boolean,
  ) {
    let url = `/folio/${folioPublicId}`;
    const isProjectPublicView = ProjectHelper.isProjectPublicView() || projectPublicView;
    url += isProjectPublicView ? '/public/projects' : '/projects';
    if (playlistUri) {
      url += `/${playlistUri}`;
    }

    if (formattedUri) {
      url = formattedUri;
    }
    if (openEditView) {
      url += '/edit';
    }
    return this.concatUrlParams(url, null, extraUriParam, queryUriParam);
  }

  private static getProjectCardRedirectUrl(
    folioPublicId: string,
    projectPublicView: boolean,
    playlistUri: string,
    resourceUri: string,
    formattedUri: string,
    extraUriParam: string,
    queryUriParam: string,
    openEditView: boolean,
  ) {
    const folioPrefix = `/folio/${folioPublicId}`;
    let url = folioPrefix;
    const isProjectPublicView = ProjectHelper.isProjectPublicView() || projectPublicView;
    url += isProjectPublicView ? '/public/projects' : '/projects';
    if (playlistUri) {
      url += `/${playlistUri}`;
    }
    if (resourceUri) {
      url += `/cards/${resourceUri}/page/1`;
    }

    if (formattedUri) {
      url = isProjectPublicView ? `${folioPrefix}/public${formattedUri}` : `${folioPrefix}${formattedUri}`;
      url += '/page/1';
    }
    if (openEditView) {
      url += '/edit';
    }
    return this.concatUrlParams(url, null, extraUriParam, queryUriParam);
  }

  private static getResourceRedirectUrl(
    activatedRoute: ActivatedRoute,
    cardType: RedirectCardType,
    playlistUri: string,
    resourceUri: string,
    formattedUri: string,
    pageNumberUri: string,
    extraUriParam: string,
    queryUriParam: string,
    openEditView: boolean,
  ) {
    const urlType = cardType === 'ASSESSMENT' ? 'assessments' : 'cards';
    const urlPrefix = this.getRedirectUrlPrefix();
    let url = `/playlists/${playlistUri}/${urlType}/${resourceUri}`;

    if (formattedUri) {
      url = formattedUri;
    }
    url = url.replace('?includeProgress=true', '');
    url = url.replace(/\/page\/\d+/, '');
    if (urlPrefix !== '') {
      url = urlPrefix + url;
    }
    if (openEditView) {
      if (pageNumberUri) {
        pageNumberUri = pageNumberUri + '/edit';
      } else {
        pageNumberUri = '/edit';
      }
    }
    return this.concatUrlParams(url, pageNumberUri, extraUriParam, queryUriParam);
  }

  private static formatUriParam(param: string) {
    return param.startsWith('/') ? param : `/${param}`;
  }

  private static formatUriQueryParam(queryParam: string) {
    return queryParam.startsWith('?') ? queryParam : `?${queryParam}`;
  }

  private static formatCorrectUrl(url: string) {
    let formattedUrl = url.replace(/\/\//g, '/');

    // fix broken http:// or https:// prefix
    if (formattedUrl.indexOf(':/') !== -1) {
      formattedUrl = formattedUrl.replace(':/', '://');
    }

    return formattedUrl;
  }

  private static concatUrlParams(url: string, pageNumberUri: string, extraUriParam: string, queryUriParam: string) {
    if (pageNumberUri) {
      url += this.formatUriParam(pageNumberUri);
    }

    if (extraUriParam) {
      url += this.formatUriParam(extraUriParam);
    }
    if (queryUriParam) {
      url += this.formatUriQueryParam(queryUriParam);
    }
    return url;
  }

  private static redirectByFormattedUrl(ngZone: NgZone, router: Router, url: string, target?: string) {
    const formattedUrl = this.formatCorrectUrl(url);

    if (target) {
      window.open(formattedUrl, target);
      return;
    }
    ngZone.run(() => {
      router.navigateByUrl(formattedUrl);
    });
  }

  private static getRedirectUrlPrefix(): string {
    let prefix = '';
    if (location.pathname.startsWith('/my-content')) {
      prefix = '/my-content';
    }
    return prefix;
  }
}

export interface UriCustomParams {
  // content package uri
  packageUri?: string;

  // publisher uri
  publisherUri?: string;

  // page uri
  pageUri?: string;

  // playlist uri
  playlistUri?: string;

  // assessment/resource uri
  resourceUri?: string;

  // formatted uri is coming from backend if exists then we can use only pageNumberUri, extraUriParam, queryUriParam
  formattedUri?: string;

  // assessment/resource/group page number uri (example: page/1)
  pageNumberUri?: string;

  // additional uri params can be used for all link (example: edit)
  extraUriParam?: string;

  // additional query uri params can be used for all link (example: ?create=1)
  queryUriParam?: string;

  // parameter that will decide open edit view of page or learner view
  openEditView?: boolean;

  // folio public id
  folioPublicId?: string;

  // project public view
  projectPublicView?: boolean;
}

export type RedirectCardType =
  | 'PAGE'
  | 'PLAYLIST'
  | 'CARD'
  | 'RESOURCE'
  | 'ASSESSMENT'
  | 'CONTENT_PACKAGE'
  | 'PERSONALITY_INDICATOR'
  | 'INSTITUTIONAL_LIBRARY'
  | 'CAREER_HUB'
  | 'TARGET_CONNECT'
  | 'WEBLINK'
  | 'EVENT'
  | 'PROJECT'
  | 'PROJECT_CARD';
