/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../shared/store';
import { InjectionToken } from '@angular/core';
import { LinkablePlaylistSummary, SearchPlaylist } from '../../../shared/models';
import { Page } from '../../../shared/models/page';
import { GlobalSearch, GlobalSearchPlaylist } from '../../../shared/models/global-search/global-search.model';
import { SearchExploreRequest } from '@app/app/shared/models/page/page.model';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const GLOBAL_SEARCH_DATA_SERVICE = new InjectionToken<GlobalSearchDataService>('[GlobalSearch] Data Service');

/** The discovery data service. */
export interface GlobalSearchDataService {
  searchContent(page: number, size: number, term: string): ObservableResult<Page<GlobalSearch>>;

  searchExplore(page: number, size: number, request: SearchExploreRequest): ObservableResult<Page<GlobalSearchPlaylist>>;

  searchExploreDynamicSection(
    sectionName: string,
    page: number,
    size: number,
    request: SearchExploreRequest,
  ): ObservableResult<Page<GlobalSearchPlaylist>>;

  /** Loads the resources based on search term. */
  searchPlaylists(page: number, term: string): ObservableResult<Page<SearchPlaylist>>;

  searchLinkablePlaylists(
    page: number,
    term: string,
    excludedId?: string,
    pageSize?: number,
  ): ObservableResult<Page<LinkablePlaylistSummary>>;
}
