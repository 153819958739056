import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SidebarSection } from '../../models/sidebar-tree/sidebar-tree.model';
import { TranslocoService } from '@ngneat/transloco';
import { TranslationHelper } from '@app/app/shared/helpers/translation-helper';

@Component({
  selector: 'ptl-admins-side-nav',
  templateUrl: './admins-side-nav.component.html',
  styleUrls: ['./admins-side-nav.component.scss'],
})
export class AdminsSideNavComponent implements OnInit, OnChanges {
  @Input() sections: SidebarSection[];

  @Input() loaderActive: boolean;

  @Output() itemLinkClick = new EventEmitter<string>();

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    if (this.sections) {
      this.translateSideNavSection(this.sections);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sections && changes.sections.currentValue && !changes.sections.firstChange) {
      this.translateSideNavSection(this.sections);
    }
  }

  onLinkClick(event: string): void {
    this.itemLinkClick.emit(event);
  }

  private translateSideNavSection(sections: SidebarSection[]) {
    sections.map((section) => {
      if (section.titleId) {
        section.title = TranslationHelper.translateWithFallback(
          `translations.sideNav.title.${section.titleId}`,
          section.title,
          this.translocoService,
        );
      }
      if (section.staticLinks) {
        section.staticLinks.map((item) => {
          if (item.titleId) {
            item.title = TranslationHelper.translateWithFallback(
              `translations.sideNav.title.${item.titleId}`,
              item.title,
              this.translocoService,
            );
          }
        });
      }
    });
  }
}
