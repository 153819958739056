/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Page } from '../../../../shared/models/page';
import { RestClientService } from '../../../../shared/services/rest-client.service';
import { TranslocoService } from '@ngneat/transloco';
import { ObservableResult } from '../../../../shared/store';
import { Moment, MomentRequest } from '../../models';
import { FolioBadges, UploadCertificateRequest } from '../../store/folio.state.model';
import { MomentDataService } from './data.service';
import { EditorContent } from '../../../../shared/models';
import { ItemWithUploadUrl } from '../../../../shared/models/ItemWithUploadUrl';

export class ApiMomentDataService implements MomentDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  createMoment(request: MomentRequest): ObservableResult<Moment> {
    return this.client.post<Moment>(Location.joinWithSlash(environment.apiRootUrl || '', 'moments'), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorCreatingMoment'))),
    );
  }

  /**
   * Updates a moment.
   *
   * @param momentUid the UID of the moment to update
   * @param request the moment details to use when updating
   */
  updateMoment(momentUid: string, request: MomentRequest): ObservableResult<Moment> {
    return this.client.patch<Moment>(Location.joinWithSlash(environment.apiRootUrl || '', `moments/${momentUid}`), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorUpdatingMoment'))),
    );
  }

  /**
   * Gets the details for a moment with a given UID.
   *
   * @param momentUid the UID of the moment to retrieve
   */
  getMomentDetails(momentUid: string): ObservableResult<Moment> {
    return this.client.get<Moment>(Location.joinWithSlash(environment.apiRootUrl || '', `moments/${momentUid}`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetMoment'))),
    );
  }

  /**
   * Deletes a moment with a given UID.
   *
   * @param momentUid the UID of the moment to delete
   */
  deleteMoment(momentUid: string): ObservableResult<void> {
    return this.client.delete<Moment>(Location.joinWithSlash(environment.apiRootUrl || '', `moments/${momentUid}`)).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorDeleteMoment'))),
    );
  }

  /**
   * Restores a moment with a given UID.
   *
   * @param momentUid the UID of the moment to restore
   */
  restoreMoment(momentUid: string): ObservableResult<void> {
    return this.client.post<Moment>(Location.joinWithSlash(environment.apiRootUrl || '', `moments/${momentUid}/restore`)).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorRestoreMoment'))),
    );
  }

  /**
   * Fetches all moments for the user.
   */
  getMoments(page = 0, size = 10, languageCode?: string): ObservableResult<Page<Moment>> {
    return this.client
      .get<
        Page<Moment>
      >(Location.joinWithSlash(environment.apiRootUrl || '', `moments/search?page=${page}&size=${size}`), null, languageCode ? { 'Accept-Language': languageCode } : null)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetMoments'))),
      );
  }

  getBadges(languageCode?: string): ObservableResult<FolioBadges[]> {
    return this.client
      .get<
        FolioBadges[]
      >(Location.joinWithSlash(environment.apiRootUrl || '', 'folios/badges'), null, languageCode ? { 'Accept-Language': languageCode } : null)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetBadges'))),
      );
  }

  /**
   * Searches by moment filters for the user.
   */
  searchMoments(momentType: string, orderBy: string, searchKey: string, page = 0, size = 10): ObservableResult<Page<Moment>> {
    let searchRoute = 'moments/search';

    if (momentType !== 'ALL') {
      searchRoute += `/${momentType.toLowerCase()}`;
    }

    searchRoute += `?order=${orderBy}&page=${page}&size=${size}`;
    if (searchKey) {
      searchRoute += `&term=${searchKey}`;
    }

    return this.client.get<Page<Moment>>(Location.joinWithSlash(environment.apiRootUrl || '', searchRoute)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetMoments'))),
    );
  }

  getArchivedMoments(orderBy: string, searchKey: string, page = 0, size = 10): ObservableResult<Page<Moment>> {
    let searchRoute = `moments/search/archived?order=${orderBy}&page=${page}&size=${size}`;
    if (searchKey) {
      searchRoute += `&term=${searchKey}`;
    }

    return this.client.get<Page<Moment>>(Location.joinWithSlash(environment.apiRootUrl || '', searchRoute)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetArchivedMoments'))),
    );
  }

  uploadExternalCertificateFile(
    request: EditorContent,
    languageCode?: string,
  ): ObservableResult<ItemWithUploadUrl<UploadCertificateRequest>> {
    const requestToSend = {
      block: request,
    };
    return this.client
      .put<
        ItemWithUploadUrl<UploadCertificateRequest>
      >(Location.joinWithSlash(environment.apiRootUrl || '', 'folios/achievements/certificates'), requestToSend)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetMoments'))),
      );
  }

  removeCertificate(uid: string): ObservableResult<void> {
    return this.client.delete<void>(Location.joinWithSlash(environment.apiRootUrl || '', `folios/achievements/certificates/${uid}`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetMoments'))),
    );
  }
}
