<!--
  ~ Copyright (C) 2021 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="search-playlist-container-title">
  {{ 'translations.contentStore.title.description' | transloco }}
</div>

<div class="content-store-prepare-submit-content">
  <ptl-content-store-prepare-submit-card-info [cardData]="contentCardInfo"></ptl-content-store-prepare-submit-card-info>

  <div class="author-box">
    <div class="author-info">
      <h2>{{ 'translations.framework.list.label.author' | transloco }}</h2>
      <ptl-profile-image [userSummary]="userSummary" *ngIf="userSummary"></ptl-profile-image>
      <span class="author-name" *ngIf="userSummary">{{ userSummary.firstName }}</span>
    </div>
    <div class="section-item">
      <h2>{{ 'translations.category.title.main' | transloco }}</h2>
      <span>{{
        (contentType === 'PAGE'
          ? 'translations.contentStore.label.pageTargetQuestion'
          : 'translations.contentStore.label.playlistTargetQuestion'
        ) | transloco
      }}</span>
      <div class="review-await__tag-box">
        <span>{{ 'translations.framework.label.browse' | transloco }} </span>
        <span
          class="review-await__framework-btn"
          tabindex="0"
          (click)="frameworkPanelOpen = true"
          (keydown.enter)="frameworkPanelOpen = true"
          [attr.aria-label]="'translations.global.ariaLabels.browseFrameworks' | transloco"
        >
          <span class="review-await__framework-icon"></span>
          <span>{{ 'translations.framework.button.frameworks' | transloco }}</span>
        </span>
        <div *ngIf="frameworkPanelOpen" class="review-await__framework-panel">
          <ptl-add-framework-tags
            [selectContentStoreTags]="true"
            [isDialogPage]="false"
            (addTagBulk)="addTag($event)"
            (closeFrameworkTags)="frameworkPanelOpen = false"
          >
          </ptl-add-framework-tags>
        </div>
      </div>
      <div class="review-await__tags-container">
        <span class="review-await__tag" *ngFor="let tag of tags; let i = index">
          <span class="title" [title]="tag.title">{{ tag.title }}</span>
          <span
            class="remove"
            title="Remove"
            tabindex="0"
            (click)="removeTag(i)"
            (keydown.enter)="removeTag(i)"
            [attr.aria-label]="'translations.global.ariaLabels.removeTag' | transloco"
            >x</span
          >
        </span>
      </div>
    </div>

    <div class="section-item">
      <h2>{{ 'translations.contentStore.summary.permissions.title' | transloco }}</h2>

      <div class="checkboxes-block">
        <mat-radio-group [(ngModel)]="request.permission">
          <mat-radio-button value="COPY" name="permission" class="checkbox-item" color="accent">
            <span
              class="option-title"
              [innerHTML]="
                (contentType === 'PLAYLIST'
                  ? 'translations.contentStore.permissions.copy'
                  : 'translations.contentStore.permissions.copyPage'
                ) | transloco
              "
            >
            </span>
          </mat-radio-button>

          <mat-radio-button
            *ngIf="contentType === 'PLAYLIST'"
            value="SYNDICATE_WITH_ACCREDITATION"
            name="permission"
            class="checkbox-item"
            color="accent"
          >
            <span class="option-title" [innerHTML]="'translations.contentStore.permissions.syndicate' | transloco"></span>
          </mat-radio-button>

          <mat-radio-button value="SYNDICATE_ONLY" name="permission" class="checkbox-item" color="accent">
            <span
              class="option-title"
              [innerHTML]="
                (contentType === 'PLAYLIST'
                  ? 'translations.contentStore.permissions.syndicateOnly'
                  : 'translations.contentStore.permissions.syndicateOnlyPage'
                ) | transloco
              "
            >
            </span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="section-item">
      <h2>{{ 'translations.contentStore.label.communityAgreement' | transloco }}</h2>

      <div class="checkboxes-block">
        <mat-checkbox #communityAgreementGuidelines class="checkbox-item" [aria-label]="'Community agreement guidelines'" color="primary">
          <span
            [innerHTML]="
              (contentType === 'PLAYLIST'
                ? 'translations.contentStore.permissions.communityAgreementGuidelines'
                : 'translations.contentStore.permissions.communityAgreementGuidelinesPage'
              ) | transloco
            "
          >
          </span>
        </mat-checkbox>

        <mat-checkbox
          [(ngModel)]="request.isSensitive"
          class="checkbox-item"
          [aria-label]="'Community agreement content sensitive'"
          color="primary"
        >
          <span
            [innerHTML]="
              (contentType === 'PLAYLIST'
                ? 'translations.contentStore.permissions.communityAgreementContentSensitive'
                : 'translations.contentStore.permissions.communityAgreementContentSensitivePage'
              ) | transloco
            "
          >
          </span>
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>

<div class="content-store-prepare-submit-footer-content">
  <button mat-raised-button class="uppercase cancel-btn" (click)="cancelShare()">
    {{ 'translations.global.button.cancel' | transloco }}
  </button>
  <button
    mat-flat-button
    [disabled]="(!playlistSummary && !pageSummary) || !communityAgreementGuidelines.checked"
    class="uppercase share-btn"
    (click)="submitShare()"
  >
    {{ 'translations.contentStore.button.addToContentStore' | transloco }}
  </button>
</div>
