<!--
  ~ Copyright (C) 2025 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<mat-card class="publication-settings-section">
  <div class="publication-settings-section-left">
    <div class="publication-settings-section-title medium bold">
      {{ 'translations.playlist.target.label.publicationSettingsTargetAudience' | transloco }}
    </div>
    <div class="publication-settings-manage-btn">
      <button
        (click)="openTargetAudienceTab()"
        [attr.aria-label]="'translations.global.ariaLabels.tapToManageTargetAudience' | transloco"
        class="save-button"
        mat-flat-button
        color="potentially"
      >
        {{ 'translations.global.button.manage' | transloco }}
      </button>
    </div>
  </div>
  <div class="publication-settings-section-info chips-list">
    <span
      class="title small"
      [innerHTML]="
        'translations.playlist.target.label.listInteractedWith'
          | transloco
            : {
                name: playlist?.title,
              }
      "
    >
    </span>
    <span class="small">
      {{ 'translations.playlist.target.label.ItIsVisibleFor' | transloco }}
    </span>
    <ng-container *ngIf="selectedOrganizationOnly; else selectedItemsTemplate">
      <span
        class="small"
        [innerHTML]="
          'translations.playlist.target.label.allUsersInWorkspace'
            | transloco
              : {
                  name: organizationName,
                }
        "
      ></span>
    </ng-container>

    <ng-template #selectedItemsTemplate>
      <ng-container *ngIf="selectedGroups">
        {{ 'translations.playlist.target.label.membersOfGroup' | transloco }}
        <ptl-audience-chip-list [items]="groups" [limit]="2" />
      </ng-container>

      <ng-container *ngIf="selectedGroups && (selectedMembers || selectedSectors)"> & </ng-container>

      <ng-container *ngIf="selectedMembers">
        {{ 'translations.playlist.target.label.targetUsers' | transloco }}
        <ptl-audience-chip-list [items]="members" [limit]="2" />
      </ng-container>

      <ng-container *ngIf="selectedMembers && selectedSectors"> & </ng-container>

      <ng-container *ngIf="selectedSectors">
        {{ 'translations.playlist.target.label.sectors' | transloco }}
        <ptl-audience-chip-list [items]="sectors" [limit]="2" />
      </ng-container>
    </ng-template>
  </div>
</mat-card>
