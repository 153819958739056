/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ptl-skeleton-badge',
  templateUrl: './skeleton-badge.component.html',
  styleUrls: ['./skeleton-badge.component.scss'],
})
export class SkeletonBadgeComponent {
  @Input() isNew = false;
}
