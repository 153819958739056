<h2 class="cdk-visually-hidden" tabindex="0" [attr.aria-label]="'translations.global.ariaLabels.closeLogItem' | transloco"></h2>
<button
  mat-button
  [mat-dialog-close]="false"
  class="dialog__close"
  [attr.aria-label]="'translations.global.ariaLabels.closeLogItem' | transloco"
>
  <i class="material-icons" aria-hidden="true">close</i>
</button>
<p class="close-reviews-text">
  {{ 'translations.dialog.title.reviewsBulkClose' | transloco }}
</p>
<mat-slide-toggle *ngIf="data?.playlistSubmissionMode === 'SINGLE'" color="primary" class="mat-slide-custom" [(ngModel)]="acceptSelected">
  <span class="inner-text">{{ 'translations.dialog.title.reviewsRequirementsSubmitted' | transloco }}</span>
</mat-slide-toggle>

<form *ngIf="reasonListUid" class="close-reasons-section">
  <div class="text-block">
    <p class="reasons-label">{{ 'translations.dialog.title.reviewsCloseReasonTitle' | transloco }}</p>
    <button class="open-reasons-btn" mat-button [matMenuTriggerFor]="menu">
      <ng-container *ngIf="selectedReasonText">
        {{ selectedReasonText | getDataByLangCode }}
      </ng-container>
      <ng-container *ngIf="!selectedReasonText">
        {{ 'translations.playlist.goals.reviewCloseReasons.buttonTitles.selectFromList' | transloco }}
      </ng-container>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <button
        *ngFor="let reason of reasonList"
        (click)="getSelectedReasonUid(reason)"
        class="close-reason-btn filter-item"
        aria-label="Example icon-button with a menu"
        mat-menu-item
      >
        {{ reason.value | getDataByLangCode }}
      </button>
    </mat-menu>
  </div>
  <textarea (input)="getFreeText($event)" *ngIf="allowFreeText" rows="4"></textarea>
</form>
<div class="close-reviews-btn-container">
  <button mat-stroked-button type="button" (click)="closeDialog()" (keydown.enter)="closeDialog()" class="mat-button-base">
    {{ 'translations.global.button.close' | transloco }}
  </button>
  <button
    mat-flat-button
    type="button"
    color="primary"
    (click)="onConfirmButton()"
    (keydown.enter)="onConfirmButton()"
    class="mat-button-base confirm-button"
  >
    {{ 'translations.global.button.confirm' | transloco }}
  </button>
</div>
