/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, Inject } from '@angular/core';
import { MomentCard } from '../../../models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './public-moment-content.component.html',
  styleUrls: ['./public-moment-content.component.scss'],
})
export class FolioPublicMomentContentComponent {
  skeletonViewActive = true;
  moment: MomentCard;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { moment: MomentCard },
    public dialogRef: MatDialogRef<FolioPublicMomentContentComponent>,
  ) {
    if (data) {
      this.moment = this.data.moment;
    }
  }
}
