import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlaylistViewState } from '../../../page-modules/playlist/store/view/playlist-view.state';
import { ResourceAdminState } from '../../../page-modules/resource/store/admin/resource-admin.state';
import { TranslocoService } from '@ngneat/transloco';

const MAX_SIZE_MB = 500;
const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;
const SUPPORTED_MIME_TYPES = [
  'video/mp4',
  'application/mp4',
  'application/x-mp4',
  'video/quicktime',
  'video/x-quicktime',
  'video/webm',
  'video/x-m4v',
  'video/x-msvideo',
  'video/vnd.avi',
  'video/avi',
  'video/msvideo',
  'video/m4v',
];
const SUPPORTED_VIDEO_EXTENSIONS = ['.mp4', '.mov', '.avi', '.webm', '.m4v'];
const SUPPORTED_VIDEO_FORMATS = 'MP4, MOV, AVI, WebM, M4V';

@Component({
  selector: 'ptl-video-upload-dialog',
  templateUrl: './video-upload-dialog.component.html',
  styleUrls: ['./video-upload-dialog.component.scss'],
})
export class VideoUploadDialogComponent implements OnInit, OnDestroy {
  @Select(PlaylistViewState.playlistId)
  private playlistId$: Observable<string>;

  @Select(ResourceAdminState.resourceUid)
  private resourceUid$: Observable<string>;

  @ViewChild('uploadFolioInput') uploadFolioInput?: ElementRef;

  filesHovered = false;
  uploadErrorText: string | null = null;
  maxFileSizeMB = MAX_SIZE_MB;
  supportedFormats = SUPPORTED_VIDEO_FORMATS;

  private playlistUid: string;
  private resourceUid: string;
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    public dialogRef: MatDialogRef<VideoUploadDialogComponent>,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.playlistId$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((playlistUid) => {
      this.playlistUid = playlistUid;
    });

    this.resourceUid$.pipe(takeUntil(this.subscriptionEnd$)).subscribe((resourceUid) => {
      this.resourceUid = resourceUid;
    });
  }

  ngOnDestroy(): void {
    this.subscriptionEnd$.emit();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  triggerFileSelection() {
    this.uploadErrorText = null;
    if (this.uploadFolioInput?.nativeElement) {
      this.uploadFolioInput.nativeElement.click();
    }
  }

  onFileSelected(eventData: Event): void {
    const input = eventData.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.validateAndUploadFile(input.files[0]);
    }
  }

  onFileDropped(fileList: FileList): void {
    if (fileList.length === 1) {
      this.validateAndUploadFile(fileList.item(0));
    }
  }

  private isValidVideoFile(file: File): boolean {
    const fileExtension = this.getFileExtension(file.name);
    if (!SUPPORTED_MIME_TYPES.includes(file.type)) {
      this.uploadErrorText = this.translocoService.translate('translations.errors.errorUnsupportedFileType');
      return false;
    } else if (!SUPPORTED_VIDEO_EXTENSIONS.includes(fileExtension)) {
      this.uploadErrorText = this.translocoService.translate('translations.errors.errorUnsupportedFileExtension');
      return false;
    } else if (file.size > MAX_SIZE_BYTES) {
      this.uploadErrorText = this.translocoService.translate('translations.errors.errorFileSizeExceedsLimit');
      return false;
    }
    return true;
  }

  private validateAndUploadFile(file: File | null): void {
    this.uploadErrorText = null;
    this.filesHovered = false;
    if (file && this.isValidVideoFile(file)) {
      this.dialogRef.close(file);
    }
  }

  private getFileExtension(fileName: string): string {
    return fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
  }
}
