<main class="error-content">
  <div class="error-content-inner">
    <div class="logo-container">
      <img
        src="https://static.yuna.potential.ly/public/images/icons/potentially_logo.png"
        alt="Potential.ly Logo"
        width="150"
        height="151"
      />
      <span class="logo-text">{{ 'translations.public.lti.title.logoText' | transloco }}</span>
    </div>

    <div class="error-title">{{ 'translations.public.lti.title.oops' | transloco }}</div>
    <h1 class="error-sub-title">{{ 'translations.public.lti.title.error' | transloco }}</h1>
    <p class="error-description" [innerHTML]="'translations.public.lti.description.LTIRegistration' | transloco"></p>
  </div>
</main>
