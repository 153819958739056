/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { catchError, switchMap } from 'rxjs/operators';
import { PlaylistResourceCardsDataService } from './data.service';
import { ObservableResult } from '../../../../../shared/store';
import { LinkableCardSummary } from '../../../../../shared/models';
import { RestClientService } from '../../../../../shared/services/rest-client.service';
import { environment } from '../../../../../../environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { Page } from '../../../../../shared/models/page';

export class ApiCardsDataService implements PlaylistResourceCardsDataService {
  constructor(
    private client: RestClientService,
    private translocoService: TranslocoService,
  ) {}

  searchLinkableCards(page: number, size: number, headlineFragment: string): ObservableResult<Page<LinkableCardSummary>> {
    return this.client
      .get<Page<LinkableCardSummary>>(Location.joinWithSlash(environment.apiRootUrl || '', 'cards/linkable'), {
        page: page.toString(),
        size: size.toString(),
        term: headlineFragment,
      })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translocoService.translate('translations.errors.errorGetResource'))),
      );
  }
}
