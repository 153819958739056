import { Directive, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';

@Directive({
  selector: '[ogLongPress]',
})
export class LongPressDirective implements OnDestroy {
  @Output() longPress = new EventEmitter();

  private isLongPressing = false;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  private longPressTimeout: any;

  @HostListener('touchstart') onMouseDown() {
    this.isLongPressing = false;
    this.longPressTimeout = setTimeout(() => {
      this.isLongPressing = true;
      this.longPress.emit();
    }, 500);
  }

  @HostListener('touchend') onMouseUp() {
    clearTimeout(this.longPressTimeout);
    if (this.isLongPressing) {
      this.isLongPressing = false;
    } else {
      clearTimeout(this.longPressTimeout);
    }
  }

  ngOnDestroy() {
    clearTimeout(this.longPressTimeout);
  }
}
