/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/** Represents state which needs to be loaded. */
export interface LoadableState<T> {
  /** True if the data is loading. */
  loading: boolean;

  /** The data, if loaded successfully. */
  data?: T;

  /** The error message, if loaded unsuccessfully. */
  error?: string;

  /** Uncompleted data forms, if loaded unsuccessfully because of targeting. */
  dataForms?: string[];
}

/** Returns the default loadable state.(i.e. not loading, no data and no errors). */
export function defaultLoadableState(): LoadableState<any> {
  return {
    loading: false,
    data: undefined,
    error: undefined,
    dataForms: undefined,
  };
}

/** Returns a loadable state with the loading flag set to true. */
export function loadingState(): LoadableState<any> {
  return {
    loading: true,
    data: undefined,
    error: undefined,
    dataForms: undefined,
  };
}

/** Returns a loadable state with an error. */
export function errorState(error: string, dataForms?: string[]): LoadableState<any> {
  return {
    loading: false,
    data: undefined,
    error,
    dataForms: dataForms,
  };
}

/** Returns a loadable state with an error. */
export function dataLoadedState(data: any): LoadableState<any> {
  return {
    loading: false,
    data,
    error: undefined,
    dataForms: undefined,
  };
}
