/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import { IMessage } from '@stomp/rx-stomp';
import { Observable } from 'rxjs';
import { WebSocketTopic } from '../../models/websocket/websocket.model';

/** Injection token for the websocket service, used by angular to decide whether to inject Mock or API service at runtime. */
export const WEBSOCKET_SERVICE = new InjectionToken<WebsocketService>('[resource] WebsocketService');

/** The service handling WebSocket connection and subscribtion to topics */
export interface WebsocketService {
  openConnection(): void;

  subscribeToTopic(topic: WebSocketTopic): Observable<IMessage>;

  closeConnection(): void;
}
