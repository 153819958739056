/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpCenterItem, HelpCenterViewsType, ViewTopics } from '../../models/help-center/help-center.model';
import { HELP_CENTER_DATA_SERVICE, HelpCenterDataService } from '../../services/help-center/help-center-data.service';
import { Organization, OrganizationFeature, PlaylistCardShort } from '../../models';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { Observable, combineLatest } from 'rxjs';
import { AccessTokenService } from '../../../user-auth/services/access-token.service';

@Component({
  selector: 'ptl-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
})
export class HelpCenterComponent implements OnInit, OnDestroy {
  @Input() viewsType: HelpCenterViewsType;
  @Input() hasActionBar: boolean;

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  @Select(UserAuthState.helpCenterFeatureFlag)
  private helpCenterFeatureFlag$: Observable<OrganizationFeature>;

  helpCenterData: HelpCenterItem[] = [];
  showHelpCenter: boolean;
  expanded: boolean;

  private page = 0;
  private size = 20;
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly accessTokenService: AccessTokenService,
    @Inject(HELP_CENTER_DATA_SERVICE) private helpCenterDataService: HelpCenterDataService,
  ) {}

  ngOnInit() {
    const userToken = this.accessTokenService.getAccessToken();

    combineLatest([this.organizationData$, this.helpCenterFeatureFlag$]).subscribe(([organization, helpCenterEnabled]) => {
      if (organization && helpCenterEnabled && userToken) {
        this.loadHelpCenterData();
      } else {
        this.showHelpCenter = false;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionEnd$?.emit();
  }

  openHelpCenter(): void {
    this.expanded = !this.expanded;
  }

  navigateTo(url: string) {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, url, null, '_blank');
    this.expanded = false;
  }

  private loadHelpCenterData() {
    const tagids = this.getTagIdsByView(this.viewsType);
    this.helpCenterDataService.getHelpCenterTopics(this.page, this.size, tagids).subscribe(({ isSuccess, value }) => {
      if (isSuccess && value) {
        this.helpCenterData = this.formatData(value.content);
        this.showHelpCenter = true;
      }
    });
  }

  private formatData(cards: PlaylistCardShort[]): HelpCenterItem[] {
    const data = [];
    for (const card of cards) {
      data.push({
        title: card.header,
        subHeader: card.subHeader,
        thumbnail: card.thumbnail,
        formattedUri: card.formattedUri,
      });
    }
    return data;
  }

  private getTagIdsByView(view: HelpCenterViewsType): string {
    const data = this.getViewTopics();

    const currentView = data.find((item) => item.view === view);
    if (currentView) {
      return currentView.help_topics.join(',');
    }
    return '';
  }

  private getViewTopics(): ViewTopics[] {
    return [
      {
        view: 'page',
        help_topics: [
          'weVpNbFDSU5wRrYO',
          'd81W60lNU3Msuv5v',
          'QdmGxdQcwisHU8YN',
          'JVsuLUocqQEc8Jxc',
          'Tt2FL2twFlIz7CY9',
          'lKst8CbADwGCABlT',
          'R3XHE75tSLLEJicL',
          'h98TYH6NRciL9umI',
          'BRiWFUyetkguTygx',
          'NS6ETQ2PJo2GU3bt',
          'ZlKoEC4FpycQXHuL',
        ],
      },
      {
        view: 'page_admin',
        help_topics: ['cGrhJXwOHjISvo0H', 'Emp4cYqJyps4zntG', 'JRorV96Hi5GEzjRe', 'U8IYFhnWlioNkkiK'],
      },
      {
        view: 'page_admin_summary',
        help_topics: ['JRorV96Hi5GEzjRe'],
      },
      {
        view: 'page_admin_targeting',
        help_topics: ['cGrhJXwOHjISvo0H'],
      },
      {
        view: 'page_admin_collaboration',
        help_topics: ['Emp4cYqJyps4zntG'],
      },
      {
        view: 'workspace_admin',
        help_topics: [
          'LO74kBmWgOGUTDAe',
          'fwobdnGPLUD3w7rL',
          'cs7w2vgbCzQVXVSF',
          'BR0eOEhU3fT4Tnk7',
          'cNIb7gVWk2vlrBQM',
          'jbMXWy8vtHJ2Et85',
          'mCpK8CSXmPIGgj9S',
          'sMyFE2RvIt2cTHFn',
          'zgvxCTeWvifR27yX',
          'JgqmaStxZ0DPcf6o',
          'ALAFshLcDLC6f2XF',
          'kcTHPvlkbAazWQyy',
          '4dsFbx3axnGQOlRs',
          'n4uLtIe4qX5NZLL4',
          'LXfVoWIT7YsQdTlN',
          'akmxlqexj4m9Es6C',
          'bjfTUhlcbdeG0KST',
        ],
      },
      {
        view: 'reviews',
        help_topics: ['lKst8CbADwGCABlT'],
      },
      {
        view: 'playlist',
        help_topics: ['J6HfOZymDwhAZivo'],
      },
      {
        view: 'playlist_admin',
        help_topics: [
          'ttzINkhl39oTPLqc',
          '6WAeNz9s3OJgYViq',
          'ktwK3r6N78EQl3l3',
          'jkGRXlEVkE0j6HQ4',
          'v0OHqe5YPVuW96NA',
          'eilF16I6hLUSZDUI',
          'ssDyPvwSjolIDDPY',
          'n7lqlPy57uSQ9LZS',
          'VgvneiMTabygdvdU',
          'MqldTTjqjY31NWjG',
        ],
      },
      {
        view: 'resource_card',
        help_topics: [
          'v5MKYNd3YyDqxoJ3',
          '5neGfzFpGh0NsfYf',
          'qCmT3gZlUBAS9BxL',
          'DKkHdXHcVoUn44Vn',
          '8FJKmJxD3nxrEyRa',
          'Ko3SKDQxTH5QwsdM',
          'SkumZ8nUijBNI5hK',
          '7HyDl6Sbjp1ULgmZ',
          'I2zbeqCSR9ffxaw7',
          'C1c3XJ2sdqv8zzc3',
        ],
      },
      {
        view: 'assessment_card',
        help_topics: [
          '8FJKmJxD3nxrEyRa',
          'Ko3SKDQxTH5QwsdM',
          'SkumZ8nUijBNI5hK',
          '7HyDl6Sbjp1ULgmZ',
          'I2zbeqCSR9ffxaw7',
          'C1c3XJ2sdqv8zzc3',
          'Gv8sA4xUyg6Ksyyy',
          'fFRUMYXjStFzTaUr',
          'iaNjGh3mzWRRIbAA',
          'I2ZZuD9N2Z2BvKc0',
        ],
      },
      {
        view: 'event_card',
        help_topics: [
          '8FJKmJxD3nxrEyRa',
          'Ko3SKDQxTH5QwsdM',
          'SkumZ8nUijBNI5hK',
          '7HyDl6Sbjp1ULgmZ',
          'I2zbeqCSR9ffxaw7',
          'C1c3XJ2sdqv8zzc3',
          'o1e1o3SSH69H4zDn',
          'fRlyAFMpsmjAQTMl',
          'JtOV7eSSsgYoviRi',
          'nTQuYBWyKD74xVtY',
        ],
      },
      {
        view: 'card_admin',
        help_topics: [
          'dtTH1vVhHx7BKrOf',
          'D5eUecdtyxiwZRTZ',
          'LNiRv6FJQpCtGmrq',
          '8FJKmJxD3nxrEyRa',
          'Ko3SKDQxTH5QwsdM',
          'SkumZ8nUijBNI5hK',
          '7HyDl6Sbjp1ULgmZ',
          'I2zbeqCSR9ffxaw7',
          'C1c3XJ2sdqv8zzc3',
        ],
      },
      {
        view: 'profile',
        help_topics: [
          'QEC5b6lRQHItV9fM',
          'l4jIOmqllj8GKO42',
          '7Ee31Q10XvEvOtyg',
          'tfzI9wCKePGe08zA',
          'gQ9OCQy0dR7oOwDN',
          'HFK8RX8GRHUVbBQL',
          'ZqLzDNvoq9y6svvV',
        ],
      },
      {
        view: 'my_pages',
        help_topics: ['h98TYH6NRciL9umI', '6RGheFHsnEWCDdCw'],
      },
    ];
  }
}
