/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { InjectionToken } from '@angular/core';
import { Page as Pagination } from '../../models/page';
import { PlaylistCardShort } from '../../models';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const HELP_CENTER_DATA_SERVICE = new InjectionToken<HelpCenterDataService>('[Help center] HelpCenterDataService');

/** The service handling booking-related operations. */
export interface HelpCenterDataService {
  getHelpCenter(page: number, size: number): ObservableResult<Pagination<PlaylistCardShort>>;

  getHelpCenterTopics(page: number, size: number, tagId?: string, term?: string): ObservableResult<Pagination<PlaylistCardShort>>;
}
