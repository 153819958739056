import { EditorContent, ImageUploadContent, MediaUploadData, SingleUrlContent } from '../models';
import { ImageUploadNameResolver } from './image-upload-name-resolver';
import { FileUploadHelper } from './file-upload-helper';
import { v4 as uuidv4 } from 'uuid';

const createMediaUploadInstanceForAlreadyUploadedContent = (mediaUpload: MediaUploadData) => {
  const mediaUploadContent = (mediaUpload as MediaUploadData).content;
  const fullUrl =
    mediaUpload.mediaType === 'IMAGE' ? (mediaUploadContent as ImageUploadContent).original : (mediaUploadContent as SingleUrlContent).url;
  const fileName = getFileName(fullUrl);
  return {
    uid: mediaUpload.uid,
    name: fileName,
    type: mediaUpload.type,
    mediaType: mediaUpload.mediaType,
    position: mediaUpload.position,
    main: mediaUpload.main,
    caption: mediaUpload.caption,
    url: mediaUpload.url,
    replace: mediaUpload.replace,
    openUrlInTab: mediaUpload.openUrlInTab,
  } as MediaUploadData;
};

const createMediaUploadInstanceForFreshContent = (mediaUpload: MediaUploadData) => {
  let formattedName = FileUploadHelper.formatFileNameForUpload(mediaUpload.file.name);

  if (mediaUpload['mediaType'] === 'IMAGE') {
    formattedName = ImageUploadNameResolver.resolveImageName(mediaUpload.file.name);
  } else {
    const uuid = uuidv4();
    formattedName = uuid + '_' + formattedName;
  }

  return {
    uid: mediaUpload.uid,
    name: formattedName,
    replace: mediaUpload.replace,
    realName: mediaUpload.file.name,
    contentLength: mediaUpload.file.size,
    type: mediaUpload.type,
    mediaType: mediaUpload.mediaType,
    position: mediaUpload.position,
    main: mediaUpload.main,
    caption: mediaUpload.caption,
    url: mediaUpload.url,
    openUrlInTab: mediaUpload.openUrlInTab,
  } as MediaUploadData;
};

export function getFileName(url: string): string {
  // Impacts uploaded files to S3, change modification like alignment once updating
  const endIndex = url.includes('master.m3u8') ? url.indexOf('/master.m3u8') : url.indexOf('?X-Amz-Algorithm');
  const protectedFileName = url.substring(0, endIndex);
  const indexOfTheFileNameBeginning = protectedFileName.length > 0 ? protectedFileName.lastIndexOf('/') + 1 : url.lastIndexOf('/') + 1;
  const name =
    protectedFileName.length > 0 ? protectedFileName.substring(indexOfTheFileNameBeginning) : url.substring(indexOfTheFileNameBeginning);
  return name;
}

export class EditorTransformer {
  private static regex = /<p>((?:(?!<p>).)*?)<split .*?\bclass="[^"]*\bis_hidden\b[^"]*"><\/split>((?:(?!<p>).)*?)<\/p>/g;

  public static transformEditorContentToMatchApiExpectations(dynamicContent: EditorContent): EditorContent {
    if (dynamicContent.type.endsWith('UPLOAD')) {
      const mediaUploadData = dynamicContent as MediaUploadData;
      // If a new image is being uploaded the image name is formatted (space characters are replaced with '_')
      if (!dynamicContent.uid || (dynamicContent.uid && mediaUploadData.file)) {
        return createMediaUploadInstanceForFreshContent(mediaUploadData);
      } else {
        return createMediaUploadInstanceForAlreadyUploadedContent(mediaUploadData);
      }
    }
    if (dynamicContent.type === 'PARAGRAPH') {
      dynamicContent.content = dynamicContent.content.replace(/<p><\/p>/g, '');
      dynamicContent.content = dynamicContent.content.replace(/<p>(<split[^>]*>\s*<\/split>)<\/p>/g, '');
      dynamicContent.content = dynamicContent.content.replace(this.regex, '');
    }
    return dynamicContent;
  }

  public static addNameFieldToExistingMediaUploadBlocks(dynamicContent: EditorContent): EditorContent {
    if (dynamicContent.type.endsWith('UPLOAD') && !dynamicContent['file']) {
      const mediaUploadData = dynamicContent as MediaUploadData;
      const mediaUploadContent = mediaUploadData.content;
      const fullUrl =
        mediaUploadData.mediaType === 'IMAGE'
          ? (mediaUploadContent as ImageUploadContent).original
          : (mediaUploadContent as SingleUrlContent).url;
      const fileName = getFileName(fullUrl);
      return {
        ...dynamicContent,
        name: fileName,
      } as MediaUploadData;
    } else {
      return dynamicContent;
    }
  }
}
