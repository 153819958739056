<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ptl-classof-auth>
  <div class="class-of__login-form-container">
    <div class="class-of__login-form-container-inner">
      <ng-container *ngIf="!isForgotForm">
        <form
          class="class-of__login-form"
          [formGroup]="loginFrom"
          (ngSubmit)="onSaveClick()"
          [attr.aria-label]="'translations.global.ariaLabels.classOf2020SignInForm' | transloco"
          novalidate
        >
          <div class="back-container">
            <h2 class="back-container-label">{{ 'translations.public.title.signIn' | transloco }}</h2>
          </div>

          <mat-form-field class="email-field form-field" appearance="outline">
            <mat-label [attr.aria-label]="'translations.global.ariaLabels.emailField' | transloco">{{
              'translations.global.input.label.email' | transloco
            }}</mat-label>
            <input matInput required type="email" name="email" formControlName="email" />
            <mat-error *ngIf="loginFrom?.controls.email.hasError('required')">
              {{ 'translations.global.input.error.enterEmail' | transloco }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field" appearance="outline">
            <mat-label [attr.aria-label]="'translations.global.ariaLabels.passwordField' | transloco">{{
              'translations.global.input.label.password' | transloco
            }}</mat-label>
            <input matInput required name="password" formControlName="password" [type]="showPasswordSelected ? 'text' : 'password'" />
            <div class="password-visibility">
              <i
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.showOrHidePassword' | transloco"
                class="material-icons password-show-icon"
                (click)="showPasswordSelected = !showPasswordSelected"
                (keydown.enter)="showPasswordSelected = !showPasswordSelected"
              >
                visibility
              </i>
            </div>
            <mat-error *ngIf="loginFrom?.controls.password.hasError('required')">
              {{ 'translations.global.input.error.enterPassword' | transloco }}
            </mat-error>
          </mat-form-field>

          <div class="login-form-actions">
            <button mat-flat-button type="submit" class="login-form-submit" color="warn" [disabled]="loginInProgress$ | async">
              <span class="uppercase" *ngIf="(loginInProgress$ | async) !== true">{{
                'translations.public.button.login' | transloco
              }}</span>
              <span class="submit-loading" *ngIf="loginInProgress$ | async">
                <span class="submit-logging-in">{{ 'translations.public.button.loggingIn' | transloco }}</span>
                <mat-spinner [diameter]="20"></mat-spinner>
              </span>
            </button>
          </div>

          <div class="login-form-links">
            <a
              tabindex="0"
              class="login-form-link-item"
              href="javascript:void(0)"
              (click)="switchForgotForm()"
              (keydown.enter)="switchForgotForm()"
            >
              {{ 'translations.public.button.forgotPassword' | transloco }}
            </a>
          </div>
        </form>

        <div class="social-buttons-headline">
          <span [attr.aria-label]="'translations.global.ariaLabels.orSingInWith' | transloco">{{
            'translations.public.label.signInWith' | transloco
          }}</span>
        </div>
        <div class="social-buttons">
          <button class="linkedin-button" title="Sing in with LinkedIn" mat-raised-button (click)="signInUsingLinkedIn()">
            <img alt="" src="assets/social/linkedin-white.svg" width="291" height="79" />
          </button>
          <button class="google-button" title="Sing in with Google" mat-raised-button (click)="signInUsingGoogle()">
            <img alt="" src="assets/social/google-colored.svg" width="265" height="87" />
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="isForgotForm">
        <form class="class-of__login-form" [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotSubmit()" novalidate>
          <div class="back-container">
            <i
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.backToSignInForm' | transloco"
              class="material-icons back-container-icon"
              (click)="onBackClicked()"
              (keydown.enter)="onBackClicked()"
            >
              keyboard_backspace
            </i>

            <h2 class="back-container-label">{{ 'translations.public.label.forgotPassword' | transloco }}</h2>
          </div>

          <mat-form-field class="email-field form-field" appearance="outline">
            <mat-label [attr.aria-label]="'translations.global.ariaLabels.emailField' | transloco">{{
              'translations.global.input.label.email' | transloco
            }}</mat-label>
            <input matInput required type="email" name="email" formControlName="email" />
            <mat-error *ngIf="forgotPasswordForm?.controls.email.hasError('required')">
              {{ 'translations.global.input.error.enterEmail' | transloco }}
            </mat-error>
          </mat-form-field>

          <div class="login-form-actions">
            <button mat-flat-button type="submit" class="login-form-submit" color="warn" [disabled]="forgotIsProgress">
              <span class="uppercase" *ngIf="!forgotIsProgress">{{ 'translations.public.button.resetPassword' | transloco }}</span>
              <span class="submit-loading" *ngIf="forgotIsProgress">
                <span class="submit-logging-in">{{ 'translations.public.button.resettingPassword' | transloco }}</span>
                <mat-spinner [diameter]="20"></mat-spinner>
              </span>
            </button>
          </div>
        </form>
      </ng-container>

      <div class="class-of__login-footer" *ngIf="!isForgotForm">
        {{ 'translations.public.label.dontHaveAccount' | transloco }}
        <span tabindex="0" class="class-of__login-footer-link" (click)="switchRegisterForm()" (keydown.enter)="switchRegisterForm()">
          {{ 'translations.public.button.signUpHere' | transloco }}
        </span>
      </div>
    </div>
  </div>
</ptl-classof-auth>
