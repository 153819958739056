/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Inject, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { switchMap, take, tap } from 'rxjs/operators';
import { BreadcrumbsState } from '../../../../shared/breadcrumbs/store/breadcrumbs.state';
import { ContentHelper } from '../../../../shared/helpers/content-helper';
import { SnackbarHelper } from '../../../../shared/helpers/snackbar-helper';
import {
  ApprovalRequest,
  EditorContent,
  Playlist,
  Resource,
  ResourceAuthorDetails,
  ResourceCardType,
  ResourcePublishedStatus,
  ResourceSection,
  ResourceTag,
} from '../../../../shared/models';
import { CurrentLanguage } from '../../../../shared/models/languages/languages.model';
import { SHARED_CARD_DATA_SERVICE, SharedCardDataService } from '../../../../shared/services/card/card-data.service';
import { FileUploadService, RESOURCES_FILE_UPLOAD_DATA_SERVICE } from '../../../../shared/services/file-upload/file-upload.service';
import { SUBHEADER_DATA_SERVICE, SubheaderDataService } from '../../../../shared/services/subheader/subheader-data.service';
import { CORE_TAG_DATA_SERVICE, TagDataService } from '../../../../shared/services/tags/tag-data.service';
import { DEFAULT_LANGUAGE_CODE, LanguageService } from '../../../../shared/services/languages/language.service';
import { UpdateSidebarItemData } from '../../../../shared/side-nav-tree/store/side-nav.actions';
import { LoadableState, ObservableResult } from '../../../../shared/store';
import { UserDetailsSummary } from '../../../../user-auth/models';
import { UserAuthState, UserAuthStateModel } from '../../../../user-auth/store/user-auth.state';
import { TagErrorObject } from '../../../playlist/models';
import { PlaylistViewState } from '../../../playlist/store/view/playlist-view.state';
import { UpdateCardUri } from '../../../playlist/store/view/playlist-view.state.actions';
import { CORE_RESOURCE_DATA_SERVICE, ResourceDataService } from '../../services/editor/core/data.service';
import { BasicActionHelpers } from '../editor/content/helpers/basic.helpers';
import { ResourceAdminExistingSectionActionHelpers } from '../editor/content/helpers/existing-section-action.helpers';
import { ResourceAdminMainSectionActionHelpers } from '../editor/content/helpers/main-section-action.helpers';
import { RedirectCardType, RedirectHelper } from '../editor/content/helpers/redirect.helper';
import { SaveHelper } from '../editor/content/helpers/save.helper';
import * as ResourceAdminActions from './resource-admin.actions';
import { AutoSaveStatus, INITIAL_STATE, ResourceAdminStateModel } from './resource-admin.state.model';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from '@app/app/shared/services/toast-notifications/toast-service';
import { BlockSaveHelper } from '@app/app/page-modules/resource/store/editor/content/helpers/block-save-helper';
import { TranslocoService } from '@ngneat/transloco';
import { marker } from '@jsverse/transloco-keys-manager/marker';

@State<ResourceAdminStateModel>({
  name: 'resourceAdminState',
  defaults: INITIAL_STATE,
})
@Injectable()
export class ResourceAdminState {
  private autoSaveInterval = null;
  private autoSaveIntervalDuration = 3000;

  @Selector()
  static currentLanguage({ currentLanguage }: ResourceAdminStateModel): CurrentLanguage {
    return currentLanguage;
  }

  @Selector()
  static resource({ resource }: ResourceAdminStateModel): Resource {
    return resource;
  }

  @Selector()
  static isWebLinkCard({ resource }: ResourceAdminStateModel): boolean {
    return resource.cardType === 'WEBLINK';
  }

  @Selector()
  static cardType({ resource }: ResourceAdminStateModel): ResourceCardType {
    return resource.cardType;
  }

  @Selector()
  static tags({ resource }: ResourceAdminStateModel): ResourceTag[] {
    return resource.tags;
  }

  @Selector()
  static filteredTags({ filteredTags }: ResourceAdminStateModel): ResourceTag[] {
    return filteredTags;
  }

  @Selector()
  static mainSectionDynamicContent({ resource }: ResourceAdminStateModel): EditorContent[] {
    return resource.content.sections[0].dynamicContent;
  }

  @Selector()
  static mainSection({ resource }: ResourceAdminStateModel): ResourceSection {
    return resource.content.sections[0];
  }

  @Selector()
  static existingSections({ resource }: ResourceAdminStateModel): ResourceSection[] {
    return resource.content.sections;
  }

  @Selector()
  static autoSaveInProgress({ saveStatus }: ResourceAdminStateModel): boolean {
    return saveStatus.autoSaveInProgress;
  }

  @Selector()
  static saveStatus({ saveStatus }: ResourceAdminStateModel): AutoSaveStatus {
    return saveStatus;
  }

  @Selector()
  static headline({ resource }: ResourceAdminStateModel): string {
    return resource.content.headline;
  }

  @Selector()
  static subheader({ resource }: ResourceAdminStateModel): string {
    return resource.subHeader;
  }

  @Selector()
  static parentPlaylist({ parentPlaylist }: ResourceAdminStateModel): Playlist {
    return parentPlaylist;
  }

  @Selector()
  static authorDetails({ resource }: ResourceAdminStateModel): ResourceAuthorDetails {
    return resource.authorDetails;
  }

  @Selector()
  static resourceUid({ resource }: ResourceAdminStateModel): string {
    return resource._id;
  }

  @Selector([UserAuthState])
  static canEdit({ resource }: ResourceAdminStateModel, userAuthState: UserAuthStateModel): boolean {
    if (ContentHelper.isFrameMode()) {
      return false;
    }
    if (userAuthState.userDetails.data) {
      if (resource.authorDetails.uid === userAuthState.userDetails.data.uid) {
        return true;
      } else {
        return resource.permission.canEdit;
      }
    }
    return false;
  }

  @Selector()
  static canExportMembers({ resource }: ResourceAdminStateModel): boolean {
    return resource.permission.canDoMemberExports;
  }

  @Selector()
  static canViewAnalytics({ resource }: ResourceAdminStateModel): boolean {
    return resource.permission.canViewAnalytics;
  }

  @Selector()
  static canManageTargeting({ resource }: ResourceAdminStateModel): boolean {
    return resource.permission.canManageTargeting;
  }

  @Selector()
  static canPublish({ resource }: ResourceAdminStateModel): boolean {
    return resource.permission.canPublish;
  }

  @Selector()
  static contentStateChanged({ contentChanged }: ResourceAdminStateModel): boolean {
    return contentChanged;
  }

  @Selector([UserAuthState])
  static canReview({ resource }: ResourceAdminStateModel, userAuthState: UserAuthStateModel): boolean {
    const isUnderReview = resource.approvalMethod.type === 'REQUIRES_REVIEW';
    if (userAuthState.userDetails.data) {
      if (resource.authorDetails.uid === userAuthState.userDetails.data.uid) {
        return isUnderReview;
      } else {
        for (const reviewer of resource.approvalMethod.reviewers) {
          if (reviewer.uid === userAuthState.userDetails.data.uid) {
            return isUnderReview;
          }
        }
      }
      if (this.ifHasAdminRole(userAuthState.userDetails) || this.ifHasSuperAdminRole(userAuthState.userDetails)) {
        return isUnderReview;
      }
    }
    return false;
  }

  private static ifHasSuperAdminRole(userDetails: LoadableState<UserDetailsSummary>): boolean {
    return !!userDetails.data.roles.find((role) => role.type === 'SUPER_ADMIN');
  }

  private static ifHasAdminRole(userDetails: LoadableState<UserDetailsSummary>): boolean {
    return !!userDetails.data.roles.find((role) => role.type === 'ADMIN' || role.type === 'ACCOUNT_OWNER');
  }

  constructor(
    private router: Router,
    @Inject(CORE_RESOURCE_DATA_SERVICE) private resourceDataService: ResourceDataService,
    @Inject(SHARED_CARD_DATA_SERVICE) private cardService: SharedCardDataService,
    @Inject(SUBHEADER_DATA_SERVICE) private subheaderService: SubheaderDataService,
    @Inject(RESOURCES_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService,
    @Inject(CORE_TAG_DATA_SERVICE) private tagService: TagDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
    private languageService: LanguageService,
    private translocoService: TranslocoService,
    private snackBar: MatSnackBar,
    private store: Store,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
  ) {}

  @Action(ResourceAdminActions.ResetResourceAdminState)
  resetState({ patchState }: StateContext<ResourceAdminStateModel>) {
    return patchState(INITIAL_STATE);
  }

  @Action(ResourceAdminActions.LoadResourceDetails)
  loadResourceDetails(
    { patchState }: StateContext<ResourceAdminStateModel>,
    {
      isProjectResource,
      playlistUri,
      resourceUri,
      publisherUri,
      packageUri,
      pageUri,
      languageCode,
    }: ResourceAdminActions.LoadResourceDetails,
  ) {
    patchState({
      resource: null,
    });
    return this.resourceDataService
      .getResourceDetails(isProjectResource, playlistUri, resourceUri, publisherUri, packageUri, pageUri, languageCode)
      .pipe(
        tap(({ isSuccess, value, error }) => {
          if (isSuccess) {
            patchState({
              resource: value,
            });
          } else {
            patchState({
              resource: null,
            });
          }
        }),
      );
  }

  @Action(ResourceAdminActions.SetEventType)
  setEventType({ getState, patchState }: StateContext<ResourceAdminStateModel>, { type }: ResourceAdminActions.SetEventType) {
    const resource = getState().resource;
    return patchState({
      resource: {
        ...resource,
        eventTypeData: type,
        eventType: type.type,
      },
    });
  }

  @Action(ResourceAdminActions.SetEventTime)
  setEventTime({ getState, patchState }: StateContext<ResourceAdminStateModel>, { time }: ResourceAdminActions.SetEventTime) {
    const resource = getState().resource;
    return patchState({
      resource: {
        ...resource,
        time: time,
      },
    });
  }

  @Action(ResourceAdminActions.SetEventReminders)
  setEventReminders(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { reminders }: ResourceAdminActions.SetEventReminders,
  ) {
    const resource = getState().resource;
    return patchState({
      resource: {
        ...resource,
        reminders: reminders,
      },
    });
  }

  @Action(ResourceAdminActions.SetEventTickets)
  setEventTickets({ getState, patchState }: StateContext<ResourceAdminStateModel>, { tickets }: ResourceAdminActions.SetEventTickets) {
    const resource = getState().resource;
    return patchState({
      resource: {
        ...resource,
        tickets: tickets,
      },
    });
  }

  @Action(ResourceAdminActions.SetContentThumbnail)
  setContentThumbnail(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { thumbnail }: ResourceAdminActions.SetContentThumbnail,
  ) {
    const resource = getState().resource;
    return patchState({
      resource: {
        ...resource,
        thumbnail: thumbnail,
      },
    });
  }

  @Action(ResourceAdminActions.AddDynamicContentToMainSection)
  addDynamicContentToMainSection(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    action: ResourceAdminActions.AddDynamicContentToMainSection,
  ) {
    const state = getState();
    ResourceAdminMainSectionActionHelpers.addDynamicContentToMainSection(state, patchState, action, this.store);
  }

  // Editor Js
  @Action(ResourceAdminActions.AddDynamicBlockContentToSection)
  addDynamicBlockContentToMainSection(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { content, sectionUid, languageCode }: ResourceAdminActions.AddDynamicBlockContentToSection,
  ) {
    const state = getState();
    const cardUid = state.resource._id;

    patchState({
      saveStatus: {
        autoSaveInProgress: true,
      },
    });

    return BlockSaveHelper.saveBlockItem(
      cardUid,
      sectionUid,
      content,
      languageCode,
      this.resourceDataService,
      this.fileUploadService,
      this.toastService,
      this.translocoService,
      getState,
      patchState,
    );
  }

  @Action(ResourceAdminActions.RemoveDynamicBlockFromSection)
  removeDynamicBlockFromMainSection(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { blockUid, sectionUid }: ResourceAdminActions.RemoveDynamicBlockFromSection,
  ) {
    const state = getState();
    const cardUid = state.resource._id;

    patchState({
      saveStatus: {
        autoSaveInProgress: true,
      },
    });

    return BlockSaveHelper.deleteBlockItem(
      cardUid,
      sectionUid,
      blockUid,
      this.resourceDataService,
      this.toastService,
      this.translocoService,
      getState,
      patchState,
    );
  }

  @Action(ResourceAdminActions.BackendResponseAppliedToBlocks)
  backendResponseAppliedToBlock(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { blocksUid, sectionUid }: ResourceAdminActions.BackendResponseAppliedToBlocks,
  ) {
    return BlockSaveHelper.backendResponseAppliedToBlocks(blocksUid, sectionUid, getState, patchState);
  }

  @Action(ResourceAdminActions.UpdateDynamicBlockOnSection)
  updateDynamicBlockOfMainSection(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { blockContent, sectionUid, languageCode }: ResourceAdminActions.UpdateDynamicBlockOnSection,
  ) {
    const state = getState();
    const cardUid = state.resource._id;
    patchState({
      saveStatus: {
        autoSaveInProgress: true,
      },
    });

    return BlockSaveHelper.updateBlockItem(
      cardUid,
      sectionUid,
      blockContent,
      languageCode,
      this.resourceDataService,
      this.fileUploadService,
      this.toastService,
      this.translocoService,
      getState,
      patchState,
    );
  }

  @Action(ResourceAdminActions.UpdateDynamicContentOfMainSection)
  updateDynamicContentOfMainSection(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    action: ResourceAdminActions.UpdateDynamicContentOfMainSection,
  ) {
    ResourceAdminMainSectionActionHelpers.updateDynamicContentOfMainSection(getState(), patchState, action, this.store);
  }

  @Action(ResourceAdminActions.RemoveDynamicContentFromMainSection)
  removeExistingDynamicContentFromMainSection(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    action: ResourceAdminActions.RemoveDynamicContentFromMainSection,
  ) {
    ResourceAdminMainSectionActionHelpers.removeDynamicContentFromMainSection(getState(), patchState, action, this.store);
  }

  @Action(ResourceAdminActions.RemoveAllContentFromMainSection)
  removeAllContentFromMainSection(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    action: ResourceAdminActions.RemoveAllContentFromMainSection,
  ) {
    ResourceAdminMainSectionActionHelpers.removeAllContentFromMainSection(getState(), patchState, action, this.store);
  }

  @Action(ResourceAdminActions.CreateEmptySection)
  createSection({ getState, patchState }: StateContext<ResourceAdminStateModel>) {
    ResourceAdminExistingSectionActionHelpers.createNewEmptySection(
      getState(),
      patchState,
      this.resourceDataService,
      this.snackBar,
      this.ngZone,
      this.translocoService,
    );
  }

  @Action(ResourceAdminActions.AddDynamicContentToExistingSection)
  addDynamicContentToExistingSection(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    action: ResourceAdminActions.AddDynamicContentToExistingSection,
  ) {
    ResourceAdminExistingSectionActionHelpers.addDynamicContentToExistingSection(getState(), patchState, action, this.store);
  }

  @Action(ResourceAdminActions.UpdateDynamicContentOfExistingSection)
  updateExistingDynamicContentOfExistingSection(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    action: ResourceAdminActions.UpdateDynamicContentOfExistingSection,
  ) {
    ResourceAdminExistingSectionActionHelpers.updateDynamicContentOfExistingSection(getState(), patchState, action, this.store);
  }

  @Action(ResourceAdminActions.RemoveDynamicContentFromExistingSection)
  removeDynamicContentFromExistingSection(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    action: ResourceAdminActions.RemoveDynamicContentFromExistingSection,
  ) {
    ResourceAdminExistingSectionActionHelpers.removeDynamicContentFromExistingSection(getState(), patchState, action, this.store);
  }

  @Action(ResourceAdminActions.RemoveAllContentFromExistingSection)
  removeAllContentFromExistingSection(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    action: ResourceAdminActions.RemoveAllContentFromExistingSection,
  ) {
    ResourceAdminExistingSectionActionHelpers.removeAllContentFromExistingSection(getState(), patchState, action, this.store);
  }

  @Action(ResourceAdminActions.DeleteExistingSection)
  deleteExistingSection(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    action: ResourceAdminActions.DeleteExistingSection,
  ) {
    ResourceAdminExistingSectionActionHelpers.deleteExistingSection(
      getState(),
      patchState,
      action,
      this.resourceDataService,
      this.snackBar,
      this.ngZone,
      this.translocoService,
    );
  }

  @Action(ResourceAdminActions.UpdateHeadline)
  updateHeadline({ getState, patchState }: StateContext<ResourceAdminStateModel>, { headline }: ResourceAdminActions.UpdateHeadline) {
    const state = getState();
    const resourceUid = state.resource._id;
    const languageCode = state.currentLanguage?.supportedLanguage?.language?.code;
    patchState({
      saveStatus: {
        autoSaveInProgress: true,
      },
    });
    this.resourceDataService.updateCardHeadline(resourceUid, { headline: headline }, languageCode).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        BasicActionHelpers.updateResourceHeadline(getState(), patchState, headline);
        const cards = this.store.selectSnapshot(PlaylistViewState.resourceAllCards);
        const updatedCard = cards?.find((card) => card._id === resourceUid);
        if (updatedCard) {
          updatedCard.header = headline;
        }
        if (!languageCode || languageCode === DEFAULT_LANGUAGE_CODE) {
          this.store.dispatch(new UpdateSidebarItemData(resourceUid, { title: headline }));
        }
      }
      patchState({
        saveStatus: {
          autoSaveInProgress: false,
        },
      });
    });
  }

  @Action(ResourceAdminActions.ChangeAutoSaveStatus)
  changeAutoSaveStatus({ patchState }: StateContext<ResourceAdminStateModel>, { status }: ResourceAdminActions.ChangeAutoSaveStatus) {
    patchState({
      saveStatus: {
        autoSaveInProgress: status,
      },
    });
  }

  @Action(ResourceAdminActions.SetWebLinkContent)
  setWebLinkContent({ getState, patchState }: StateContext<ResourceAdminStateModel>, { content }: ResourceAdminActions.SetWebLinkContent) {
    const state = getState();
    const mainSectionUid = state.resource.content.sections[0]?.uid;
    const languageCode = state.currentLanguage?.supportedLanguage?.language?.code;
    const contentUid = state.resource.content.sections[0]?.dynamicContent[0].uid;
    state.resource.content.sections[0].dynamicContent[0] = { ...content, uid: contentUid };
    this.store.dispatch(new ResourceAdminActions.SaveSection(mainSectionUid, state.resource, languageCode));
    setTimeout(() => {
      this.store.dispatch(new ResourceAdminActions.UpdateHeadline(content.title));
    }, 250);
    setTimeout(() => {
      this.store.dispatch(new ResourceAdminActions.UpdateSubheader(content.description));
    }, 500);
    return patchState({
      resource: {
        ...getState().resource,
        content: {
          ...getState().resource.content,
          sections: state.resource.content.sections,
        },
      },
    });
  }

  @Action(ResourceAdminActions.UpdateTimeRequired)
  updateTimeRequired(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { timeRequired }: ResourceAdminActions.UpdateTimeRequired,
  ) {
    const state = getState();
    const resourceUid = state.resource._id;
    this.resourceDataService.updateTimeRequired(resourceUid, timeRequired).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        patchState({
          resource: {
            ...state.resource,
            timeRequired: timeRequired,
          },
        });
        SnackbarHelper.showTranslatableSnackBar(
          this.ngZone,
          this.snackBar,
          this.translocoService,
          marker('translations.cards.publish.message.success.timeEstimateUpdated'),
        );
      } else {
        SnackbarHelper.showTranslatableSnackBar(
          this.ngZone,
          this.snackBar,
          this.translocoService,
          marker('translations.cards.publish.message.error.timeEstimateUpdated'),
        );
      }
    });
  }

  @Action(ResourceAdminActions.DeleteResource)
  deleteResource(_: StateContext<ResourceAdminStateModel>, { resourceUid }: ResourceAdminActions.DeleteResource) {
    const playlist = this.store.selectSnapshot(PlaylistViewState.playlistState)?.data;
    const playlistUid = playlist?._id;
    const sectionUid = this.getCurrentSectionUidOfCard(playlist, resourceUid);

    return BasicActionHelpers.deleteResourceAdmin(
      playlistUid,
      sectionUid,
      resourceUid,
      this.resourceDataService,
      this.store,
      this.snackBar,
      this.ngZone,
      this.translocoService,
    );
  }

  // methods related to tags are duplicated in playlist-creation
  @Action(ResourceAdminActions.FilterTags)
  filterTags({ getState, patchState }: StateContext<ResourceAdminStateModel>, { text }: ResourceAdminActions.FilterTags) {
    const updateState = (newFilteredTags) => {
      patchState({
        filteredTags: newFilteredTags,
      });
    };
    if (text) {
      return this.tagService.findTagsWithTitleTerm(text).pipe(tap(({ value }) => updateState(value)));
    } else {
      return updateState([]);
    }
  }

  @Action(ResourceAdminActions.AssociateTag)
  associateTag({ getState, patchState }: StateContext<ResourceAdminStateModel>, { resourceId, title }: ResourceAdminActions.AssociateTag) {
    const state = getState();
    const foundTag = state.filteredTags?.find((tag) => tag.title === title);
    if (!foundTag) {
      return this.tagService.createTag({ title: title }).pipe(
        switchMap(({ isSuccess, value, error }) => {
          if (isSuccess) {
            return this.addTagToResource(resourceId, value, patchState, state);
          } else {
            const errorObject: TagErrorObject = JSON.parse(error);
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, errorObject.errorMessage);

            return ObservableResult.ofError(errorObject.errorMessage);
          }
        }),
      );
    } else {
      return this.addTagToResource(resourceId, foundTag, patchState, state);
    }
  }

  @Action(ResourceAdminActions.AssociateTagsBulk)
  associateTagsBulk(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { resourceId, tags }: ResourceAdminActions.AssociateTagsBulk,
  ) {
    return this.addTagsBulk(tags, resourceId, patchState, getState);
  }

  @Action(ResourceAdminActions.DissociateTag)
  removeTag({ getState, patchState }: StateContext<ResourceAdminStateModel>, { resourceId, tag }: ResourceAdminActions.DissociateTag) {
    return this.cardService.dissociateTag(resourceId, tag._id).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          patchState({
            resource: {
              ...getState().resource,
              tags: getState().resource.tags.filter((t) => t !== tag),
            },
          });
        } else {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone,
            this.snackBar,
            this.translocoService,
            marker('translations.framework.message.error.dissociateTagFailed'),
          );
        }
      }),
    );
  }

  @Action(ResourceAdminActions.UpdateApprovalMethod)
  updateApprovalMethod(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { resourceId, request }: ResourceAdminActions.UpdateApprovalMethod,
  ) {
    const state = getState();

    const reviewers = [];

    if (request.reviewers) {
      for (const reviewer of request.reviewers) {
        reviewers.push(reviewer.uid);
      }
    }

    const approvalRequest: ApprovalRequest = {
      approvalType: request.type,
      minReviews: request.minReviewsRequired,
      closeReviews: request.closeReviews,
      playlistUid: request.playlistUid,
    };
    return this.cardService.updateApprovalMethod(resourceId, approvalRequest).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          patchState({
            resource: {
              ...state.resource,
              approvalMethod: {
                ...request,
                reviewers: request.reviewers ? request.reviewers : state.resource?.approvalMethod.reviewers,
              },
            },
          });
        } else {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone,
            this.snackBar,
            this.translocoService,
            marker('translations.approvalUpdateFailed'),
          );
        }
      }),
    );
  }

  @Action(ResourceAdminActions.AssociateReviewer)
  associateReviewer(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { resourceId, reviewer }: ResourceAdminActions.AssociateReviewer,
  ) {
    const state = getState();

    return this.cardService.associateReviewer(resourceId, reviewer.uid).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          patchState({
            resource: {
              ...state.resource,
              approvalMethod: {
                ...state.resource.approvalMethod,
                reviewers: [...state.resource.approvalMethod.reviewers, reviewer],
              },
            },
          });
        } else {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone,
            this.snackBar,
            this.translocoService,
            marker('translations.cards.review.message.error.associateReviewerFailed'),
          );
        }
      }),
    );
  }

  @Action(ResourceAdminActions.DissociateReviewer)
  dissociateReviewer(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { resourceId, reviewer }: ResourceAdminActions.DissociateReviewer,
  ) {
    const state = getState();
    return this.cardService.dissociateReviewer(resourceId, reviewer.uid).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          patchState({
            resource: {
              ...state.resource,
              approvalMethod: {
                ...state.resource.approvalMethod,
                reviewers: state.resource.approvalMethod.reviewers.filter((r) => r.uid !== reviewer.uid),
              },
            },
          });
        } else {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone,
            this.snackBar,
            this.translocoService,
            marker('translations.cards.review.message.error.dissociateReviewerFailed'),
          );
        }
      }),
    );
  }

  @Action(ResourceAdminActions.PublishCard)
  publishCard({ getState, patchState }: StateContext<ResourceAdminStateModel>, { resourceId }: ResourceAdminActions.PublishCard) {
    const state = getState();
    return this.cardService.publishCard(resourceId).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          patchState({
            resource: {
              ...state.resource,
              status: 'PUBLISHED' as ResourcePublishedStatus,
            },
          });
        } else {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone,
            this.snackBar,
            this.translocoService,
            marker('translations.cardPublishFailed'),
          );
        }
      }),
    );
  }

  @Action(ResourceAdminActions.UnPublishCard)
  unPublishCard({ getState, patchState }: StateContext<ResourceAdminStateModel>, { resourceId }: ResourceAdminActions.UnPublishCard) {
    const state = getState();
    return this.cardService.unpublishCard(resourceId).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          patchState({
            resource: {
              ...state.resource,
              status: 'UNPUBLISHED' as ResourcePublishedStatus,
            },
          });
        } else {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone,
            this.snackBar,
            this.translocoService,
            marker('translations.cardUnpublishFailed'),
          );
        }
      }),
    );
  }

  @Action(ResourceAdminActions.RedirectToPlaylistOrResourcePage)
  redirectToPlaylistOrResourcePage(
    _: StateContext<ResourceAdminStateModel>,
    { resourceUri, cardType }: ResourceAdminActions.RedirectToPlaylistOrResourcePage,
  ) {
    const lastBreadcrumb = this.store.selectSnapshot(BreadcrumbsState.lastBreadcrumb);
    if (lastBreadcrumb && resourceUri) {
      const splittedUrl = lastBreadcrumb.url.split('/');
      RedirectHelper.redirectByParams(
        this.ngZone,
        this.router,
        this.activatedRoute,
        {
          playlistUri: splittedUrl[2],
          resourceUri: resourceUri,
        },
        cardType as RedirectCardType,
      );
    } else {
      const homePageUri = this.store.selectSnapshot(UserAuthState.homePageUri);
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, homePageUri);
    }
  }

  @Action(ResourceAdminActions.UpdateSubheader)
  updateSubheader({ getState, patchState }: StateContext<ResourceAdminStateModel>, { newSubheader }: ResourceAdminActions.UpdateSubheader) {
    const state = getState();

    this.subheaderService
      .updateCardSubheader(state.resource._id, { subHeader: newSubheader }, state.currentLanguage?.supportedLanguage?.language?.code)
      .pipe(take(1))
      .subscribe(({ isSuccess, error }) => {
        if (isSuccess) {
          patchState({
            resource: {
              ...getState().resource,
              subHeader: newSubheader,
            },
          });
        }
      });
  }

  @Action(ResourceAdminActions.UpdateUri)
  updateUri({ getState, patchState }: StateContext<ResourceAdminStateModel>, { resourceId, newUri }: ResourceAdminActions.UpdateUri) {
    const state = getState();
    this.cardService.updateCardUri(resourceId, newUri).subscribe(({ isSuccess, error }) => {
      if (isSuccess) {
        this.updateCardUrlState(state.resource.settings.publication.uri, newUri, resourceId);
        this.store.dispatch(
          new UpdateSidebarItemData(resourceId, {
            uri: newUri,
            oldUri: state.resource.settings.publication.uri,
          }),
        );
        patchState({
          resource: {
            ...state.resource,
            settings: {
              ...state.resource.settings,
              publication: {
                ...state.resource.settings.publication,
                uri: newUri,
              },
            },
          },
        });
      } else {
        SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
      }
    });
  }

  @Action(ResourceAdminActions.InitAutoSaveSections)
  initAutoSaveSections(
    { patchState }: StateContext<ResourceAdminStateModel>,
    { sectionUid, resource, languageCode }: ResourceAdminActions.InitAutoSaveSections,
  ) {
    patchState({
      saveStatus: {
        autoSaveInProgress: true,
      },
    });

    this.resetAutoSaveInterval();
    this.autoSaveInterval = setTimeout(() => {
      this.store.dispatch(new ResourceAdminActions.SaveSection(sectionUid, resource, languageCode));
    }, this.autoSaveIntervalDuration);
  }

  @Action(ResourceAdminActions.SetResourceCurrentLanguage)
  setResourceCurrentLanguage(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { supportedLanguage, index }: ResourceAdminActions.SetResourceCurrentLanguage,
  ) {
    patchState({
      currentLanguage: { supportedLanguage: supportedLanguage, index } as CurrentLanguage,
    });
  }

  @Action(ResourceAdminActions.ResetResourceCurrentLanguage)
  resetResourceCurrentLanguage(
    { patchState }: StateContext<ResourceAdminStateModel>,
    {
      isProjectResource,
      playlistUri,
      resourceUri,
      publisherUri,
      packageUri,
      pageUri,
      activeLanguageCode,
    }: ResourceAdminActions.ResetResourceCurrentLanguage,
  ) {
    patchState({
      currentLanguage: null,
    });
    if (activeLanguageCode !== this.languageService.getLanguage() && activeLanguageCode !== undefined) {
      this.store.dispatch(
        new ResourceAdminActions.LoadResourceDetails(
          isProjectResource,
          playlistUri,
          resourceUri,
          publisherUri,
          packageUri,
          pageUri,
          DEFAULT_LANGUAGE_CODE,
        ),
      );
    }
  }

  @Action(ResourceAdminActions.UpdateDiagnosticsRetake)
  updateDiagnosticsRetake(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { request }: ResourceAdminActions.UpdateDiagnosticsRetake,
  ) {
    const state = getState();

    return patchState({
      resource: {
        ...state.resource,
        retakeCriteria: request,
      },
    });
  }

  @Action(ResourceAdminActions.RandomizeQuestions)
  randomizeQuestions(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { randomize }: ResourceAdminActions.RandomizeQuestions,
  ) {
    const state = getState();

    return patchState({
      resource: {
        ...state.resource,
        randomizeQuestions: randomize,
      },
    });
  }

  @Action(ResourceAdminActions.SaveUpdates)
  saveUpdates({ getState, patchState }: StateContext<ResourceAdminStateModel>, { preventRedirection }: ResourceAdminActions.SaveUpdates) {
    this.resetAutoSaveInterval();
    return SaveHelper.saveUpdates(
      getState(),
      patchState,
      this.subheaderService,
      this.store,
      this.snackBar,
      this.ngZone,
      this.translocoService,
      preventRedirection,
    );
  }

  @Action(ResourceAdminActions.SaveSection)
  saveSection(
    { getState, patchState }: StateContext<ResourceAdminStateModel>,
    { sectionUid, resource, languageCode }: ResourceAdminActions.SaveSection,
  ) {
    const currentLanguageCode = languageCode ? languageCode : getState().currentLanguage?.supportedLanguage?.language?.code;
    return SaveHelper.saveSectionItem(
      this.snackBar,
      this.ngZone,
      this.translocoService,
      sectionUid,
      getState,
      patchState,
      this.resourceDataService,
      resource,
      this.fileUploadService,
      currentLanguageCode,
    );
  }

  @Action(ResourceAdminActions.SetAutoSaveInProgress)
  setAutoSaveInProgerss({ patchState }: StateContext<ResourceAdminStateModel>, autoSave: boolean) {
    patchState({
      saveStatus: {
        autoSaveInProgress: autoSave,
      },
    });
  }

  @Action(ResourceAdminActions.ClearAutoSaveInterval)
  clearAutoSaveInterval(_: StateContext<ResourceAdminStateModel>) {
    this.resetAutoSaveInterval();
  }

  private resetAutoSaveInterval() {
    clearTimeout(this.autoSaveInterval);
  }

  private getCurrentSectionUidOfCard(playlist: Playlist, resourceUid: string): string | undefined {
    if (!playlist) {
      return undefined;
    }
    const mainSectionCards = playlist.mainSection.cards;
    const standardSections = playlist.standardSections;

    if (mainSectionCards.filter((value) => value._id === resourceUid).length) {
      return playlist.mainSection.uid;
    }

    for (const standardSection of standardSections) {
      if (standardSection.cards.filter((value) => value._id === resourceUid).length) {
        return standardSection.uid;
      }
    }
    return undefined;
  }

  private addTagToResource(
    resourceId: string,
    tag: ResourceTag,
    patchState: (val: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
    state: ResourceAdminStateModel,
  ): ObservableResult<void> {
    return this.cardService.associateTag(resourceId, tag._id).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          patchState({
            resource: {
              ...state.resource,
              tags: [...state.resource.tags, tag],
            },
          });
        } else {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone,
            this.snackBar,
            this.translocoService,
            marker('translations.framework.message.error.associateTagFailed'),
          );
        }
      }),
    );
  }

  private addTagsBulk(
    tags: ResourceTag[],
    resourceId: string,
    patchState: (val: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
    getState: () => ResourceAdminStateModel,
    index = 0,
  ) {
    if (index < tags.length) {
      this.addTagToResource(resourceId, tags[index], patchState, getState()).subscribe(() => {
        this.addTagsBulk(tags, resourceId, patchState, getState, index + 1);
      });
    }
  }

  private updateCardUrlState(oldUri: string, newUri: string, resourceUid: string) {
    const cardOldUri = oldUri + '/page';
    const cardNewUrl = newUri + '/page';
    const url = location.href.replace(cardOldUri, cardNewUrl);
    window.history.replaceState('', '', url);
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, location.pathname);
    this.store.dispatch(new UpdateCardUri(resourceUid, newUri));
  }
}
