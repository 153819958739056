<div class="right-sidebar">
  <div class="member-sidebar">
    <div class="top-section">
      <div class="close-button-container">
        <button
          class="f_close-btn close-btn"
          (click)="closeDialog()"
          [attr.aria-label]="'translations.global.ariaLabels.closeMembersInfoModal' | transloco"
          mat-icon-button
        >
          <svg class="close-svg svg svg-icon" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
          </svg>
        </button>
      </div>
      <div class="header">
        <div class="header-icon-container">
          <img
            class="profile-img"
            src="{{ member.imageUrl ? member.imageUrl : '../../../../../../../../assets/profile_image.png' }}"
            alt="{{ member.firstName }} {{ member.lastName }}"
          />
        </div>
        <div class="header-action-container">
          <button
            (click)="openMemberDetailPage(member._id)"
            [attr.aria-label]="'translations.global.ariaLabels.viewProfile' | transloco"
            color="accent"
            mat-stroked-button
          >
            <svg class="svg" role="img">
              <use ogSvgUnify="assets/sprite/sprite.svg#open-in-new"></use>
            </svg>
            {{ 'translations.viewProfile' | transloco }}
          </button>
          <button
            (click)="openSendMessageDialog()"
            [attr.aria-label]="'translations.global.ariaLabels.openMessage' | transloco"
            color="accent"
            mat-stroked-button
          >
            <svg class="svg" role="img">
              <use ogSvgUnify="assets/sprite/sprite.svg#message"></use>
            </svg>
            {{ 'translations.message' | transloco }}
          </button>
        </div>
      </div>

      <div class="member-name">{{ member.firstName + ' ' + member.lastName }}</div>
      <ng-container *ngIf="isPlaylistPage">
        <div class="activity-on-text">
          {{ 'translations.global.label.activityOn' | transloco }} <b>{{ (playlist$ | async).data?.title }}</b>
        </div>
      </ng-container>
      <ng-container *ngIf="isCardPage">
        <div class="activity-on-text">
          {{ 'translations.global.label.activityOn' | transloco }} <b>{{ (resource$ | async).content?.headline }}</b>
        </div>
      </ng-container>
      <div class="last-active-on-text">
        <span>{{ 'translations.global.label.lastActive' | transloco }}</span
        >: {{ member.lastActiveOn | dateFormat: 'D MMM yyyy' }}
      </div>

      <button [attr.aria-label]="'translations.global.ariaLabels.review' | transloco" mat-stroked-button class="review-button">
        {{ 'translations.global.button.review' | transloco }}
      </button>
    </div>

    <ng-container *ngIf="isPlaylistPage">
      <div class="cards-table-container">
        <table class="cards-table">
          <thead>
            <tr>
              <th class="card-head-title">
                {{ 'translations.membersNew.info.cards' | transloco }}
              </th>
              <th class="card-head-title">
                <div class="card-right-side">
                  <div class="card-grade-column">
                    {{ 'translations.membersNew.info.grade' | transloco }}
                  </div>
                  /
                  <div class="card-review-column">
                    {{ 'translations.membersNew.info.review' | transloco }}
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let card of member.cards; let j = index">
              <tr>
                <td>
                  <div class="card-status-info">
                    <div class="card-complete-status">
                      <svg
                        class="svg"
                        role="img"
                        aria-hidden="true"
                        [ngClass]="{
                          positive: getMemberCompletionStatus(member) === 'COMPLETED',
                          warn: getMemberCompletionStatus(member) === 'IN_PROGRESS',
                          negative: getMemberCompletionStatus(member) === 'NOT_STARTED',
                        }"
                      >
                        <use ogSvgUnify="assets/sprite/sprite.svg#ellipse_6"></use>
                      </svg>
                    </div>
                    <div class="card-text-info">
                      <div class="card-name">{{ card.header }}</div>
                      <div class="card-date">{{ card.completedOn | dateFormat: 'D MMM yyyy HH:mm' }}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="card-right-side">
                    <div class="card-grade-column">
                      <ng-container *ngIf="!card.underReview">
                        <span *ngIf="card.completed" class="completed-container">
                          <mat-icon aria-hidden="true" class="mat-icon completed">check</mat-icon>
                        </span>
                        <span *ngIf="!card.completed && card.startedOn">
                          <mat-icon aria-hidden="true" class="mat-icon warn">loop</mat-icon>
                        </span>
                      </ng-container>
                    </div>
                    <div class="card-review-column">
                      <span *ngIf="card.underReview" class="small">
                        {{ 'translations.global.label.review' | transloco }}
                      </span>
                      <span *ngIf="!card.underReview" class="small">
                        {{ 'translations.global.label.na' | transloco }}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>
