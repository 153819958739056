<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div *ngIf="engagementsData.length > 0" class="chart-dashboard">
  <div class="header-row">
    <div class="chart-dashboard-title-items">
      <p class="chart-dashboard-title">
        {{ 'translations.analytics.charts.mostEngagements.title' | transloco }}
      </p>
      <mat-icon matTooltip="{{ 'translations.analytics.help.engagementEvaluationAnswer' | transloco }}"> info_filled </mat-icon>
    </div>
    <button class="btn-sort" (click)="toggleSortOrder()" [attr.aria-label]="'translations.global.ariaLabels.toggleSorting' | transloco">
      <mat-icon>{{ sortOrder === 'desc' ? 'arrow_downward' : 'arrow_upward' }}</mat-icon>
    </button>
  </div>

  <div class="chart-wrapper">
    <div class="engagements-chart">
      <div *ngFor="let data of engagementsData" class="chart-bar">
        <div
          class="chart-label clickable"
          (keyup.enter)="openInNewTab(data.label)"
          (click)="openInNewTab(data.label)"
          matTooltip="{{ data.label }}"
        >
          {{ data.label }}
        </div>
        <div class="chart-bar-wrapper" matTooltip="{{ data.label }}">
          <div class="chart-bar-fill" [style.width.%]="(data.count / maxEngagement) * 100"></div>
        </div>
        <div class="chart-bar-value">{{ data.count }}</div>
      </div>
    </div>
  </div>
</div>
