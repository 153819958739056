<ng-container *ngFor="let currentElem of currentElements">
  <div class="options-group">
    <ng-container *ngIf="currentElem?.value?.id">
      <div
        class="custom-mat-option"
        [attr.aria-label]="currentElem.value.name"
        [class.bolder]="currentElem?.sublist?.length"
        [class.is_selected]="selectedElement[uId] === currentElem.value.id"
        (click)="$event.stopPropagation(); onGroupSelect($event, i, currentElem.uid, currentElem?.sublist, currentElem.value)"
        (keydown.enter)="$event.stopPropagation(); onGroupSelect($event, i, currentElem.uid, currentElem?.sublist, currentElem.value)"
      >
        <span
          [style.padding-right.rem]="languageDirection === 'ltr' ? (groupNumber * 10 + 5) / 16 : ''"
          [style.padding-left.rem]="languageDirection === 'rtl' ? (groupNumber * 10 + 5) / 16 : ''"
          >{{ currentElem.value.name }}</span
        >
      </div>
    </ng-container>
    <ptl-app-group-list
      [option]="currentElem.sublist"
      [i]="i"
      [groupNumber]="groupNumber + 1"
      [selectedElement]="selectedElement"
      [uId]="uId"
      (groupSelectedData)="onGroupSelect($event, i, currentElem.uid, currentElem.sublist, currentElem.value)"
    ></ptl-app-group-list>
  </div>
</ng-container>
