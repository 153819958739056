/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { FilterSavedEvent, PredefinedRangeId } from '@app/app/shared/components/date-range-picker/date-range-picker.component';

@Component({
  selector: 'ptl-datapoint-selection-dialog',
  templateUrl: './datapoint-selection-dialog.component.html',
  styleUrls: ['./datapoint-selection-dialog.component.scss'],
})
export class DatapointSelectionDialogComponent implements OnInit {
  @Input() startDate: Date | null = null;
  @Input() endDate: Date | null = null;
  @Output() applySelectionEmitter = new EventEmitter<{
    dateRange: DateRange<Date>;
    selectedRangeId?: PredefinedRangeId;
    selectedRangeLabel?: string;
  }>();
  @Output() closeDialog = new EventEmitter<void>();

  private isClickDetectionActive = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.activateClickDetection();
  }

  onDateRangeSelected($event: FilterSavedEvent): void {
    if ($event.startDate && $event.endDate) {
      const dateRange = new DateRange($event.startDate, $event.endDate);
      this.applySelectionEmitter.emit({
        dateRange: dateRange,
        selectedRangeId: $event.selectedRangeId,
        selectedRangeLabel: $event.selectedRangeLabel,
      });
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKeydown(): void {
    this.closeDialog.emit();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.isClickDetectionActive) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    const clickedInsideCalendar = (event.target as HTMLElement).closest('.mat-calendar-body-cell');
    if (!clickedInside && !clickedInsideCalendar) {
      this.closeDialog.emit();
    }
  }

  private activateClickDetection(): void {
    setTimeout(() => {
      this.isClickDetectionActive = true;
    }, 300);
  }
}
