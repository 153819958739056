<mat-form-field class="user-search-input-field" appearance="outline">
  <mat-icon matPrefix>search</mat-icon>
  <input
    matInput
    [matAutocomplete]="auto"
    [attr.aria-label]="'translations.global.ariaLabels.addNewMember' | transloco"
    [placeholder]="placeholder"
    (paste)="onTextPaste($event)"
    (keyup)="onSearchInputChange($event)"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    class="user-search-result-autocomplete"
    [displayWith]="disabledAutocomplete"
    [thresholdPercent]="0.9"
    (optionsScroll)="onAutocompleteScroll()"
    (optionSelected)="onMemberSelected($event)"
  >
    <mat-option *ngFor="let member of users" [value]="member" class="search-result-user-option">
      <div class="search-result-user-image">
        <img src="{{ member.imageUrl || 'assets/profile_image.png' }}" alt="{{ member.firstName }} {{ member.lastName }}" />
      </div>
      <div class="search-result-user-bio">
        <div class="search-result-user-name small bold">{{ member.firstName }} {{ member.lastName }}</div>
      </div>
    </mat-option>

    <div class="loading-more-indicator" *ngIf="isLoadingMore">
      <mat-spinner class="loading-more-indicator__spinner" [diameter]="30"></mat-spinner>
    </div>
  </mat-autocomplete>
  <mat-icon aria-hidden="true" matSuffix class="flex">
    <mat-spinner *ngIf="loading" [diameter]="18"></mat-spinner>
  </mat-icon>
</mat-form-field>
