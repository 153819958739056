<!--
  ~ Copyright (C) 2021 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ogBetaFeature>
  <div class="title">
    {{ 'translations.contentStore.label.whatYouLikeToShare' | transloco }}
  </div>

  <div class="share-content-top" [class.page-selected]="contentType === 'PAGE'">
    <mat-radio-group class="share-content-options" [(ngModel)]="contentType">
      <div class="share-content-option">
        <mat-radio-button class="share-content-radio" value="PLAYLIST" tabIndex="0" (change)="onChangeContentType()" color="accent">
          <span class="share-content-radio-text">
            {{ 'translations.contentStore.radio.shareAPlaylist' | transloco }}
          </span>
        </mat-radio-button>
      </div>

      <div class="share-content-option">
        <mat-radio-button class="share-content-radio" value="PAGE" tabIndex="0" (change)="onChangeContentType()" color="accent">
          <span class="share-content-radio-text">
            {{ 'translations.contentStore.radio.shareAPage' | transloco }}
          </span>
        </mat-radio-button>
      </div>
    </mat-radio-group>

    <mat-form-field class="search-playlist-field" appearance="outline">
      <input
        matInput
        [(ngModel)]="searchString"
        [placeholder]="
          (contentType === 'PAGE' ? 'translations.contentStore.label.searchPage' : 'translations.contentStore.label.searchPlaylist')
            | transloco
        "
        [ngModelOptions]="{ standalone: true }"
        (paste)="onTextPaste($event)"
        (keyup)="onSearchInputChange($event)"
      />

      <mat-icon aria-hidden="true" matSuffix class="flex">
        <mat-spinner *ngIf="loadingSearch" [diameter]="20"> </mat-spinner>
      </mat-icon>
    </mat-form-field>
  </div>
</ng-container>

<ng-container *ogNotBetaFeature>
  <div class="title">
    {{ 'translations.contentStore.label.selectPlaylist' | transloco }}
  </div>

  <mat-form-field appearance="outline">
    <input
      matInput
      [(ngModel)]="searchString"
      [ngModelOptions]="{ standalone: true }"
      (paste)="onTextPaste($event)"
      (keyup)="onSearchInputChange($event)"
    />

    <mat-icon aria-hidden="true" matSuffix class="flex">
      <mat-spinner *ngIf="loadingSearch" [diameter]="20"> </mat-spinner>
    </mat-icon>
  </mat-form-field>
</ng-container>

<table class="share-content-table" *ngIf="searchResult.length && contentType === 'PLAYLIST'">
  <thead>
    <tr>
      <th class="status">{{ 'translations.managePlaylist.list.label.liveStatus' | transloco }}</th>
      <th class="name">{{ 'translations.contentStore.label.playlistTitle' | transloco }}</th>
      <th class="target">{{ 'translations.managePlaylist.list.label.targetedAt' | transloco }}</th>
      <th class="mod-date">
        <a href="javascript:void(0)">{{ 'translations.managePlaylist.list.label.modifiedDate' | transloco }}</a>
      </th>
      <th class="action">{{ 'translations.framework.list.label.action' | transloco }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let result of searchResult">
      <td class="status">
        <span class="indicator" [class.inactive]="result.status?.type !== 'PUBLISHED'"></span>
      </td>
      <td class="name">
        {{ result.title }}
      </td>
      <td class="target">
        {{ 'translations.members.title' | transloco }}
      </td>
      <td class="mod-date">
        {{ result.playlistLastUpdatedOn | dateFormat: 'dddd, D MMMM, H:mm' }}
      </td>
      <td class="action">
        <button
          mat-stroked-button
          (click)="selectContent(result)"
          [disabled]="result.status?.type !== 'PUBLISHED' || result.syndicationStatus === 'ACTIVE'"
        >
          {{ 'translations.global.button.select' | transloco }}
        </button>
      </td>
    </tr>
  </tbody>
</table>

<table class="share-content-table" *ngIf="searchResult.length && contentType === 'PAGE'">
  <thead>
    <tr>
      <th class="name">{{ 'translations.contentStore.label.pageTitle' | transloco }}</th>
      <th class="action">{{ 'translations.framework.list.label.action' | transloco }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let result of searchResult">
      <td class="name">
        {{ result.title }}
        <div class="description" *ngIf="result.subHeader">{{ result.subHeader }}</div>
      </td>
      <td class="action">
        <button
          mat-stroked-button
          *ngIf="result?.type === 'STANDARD_PAGE'"
          [disabled]="result.activeSyndication"
          (click)="selectContent(result)"
        >
          {{ 'translations.global.button.select' | transloco }}
        </button>
      </td>
    </tr>
  </tbody>
</table>

<div class="share-content-footer" *ngIf="searchResult.length">
  <ptl-pagination-block
    [itemsPerPage]="pageSize"
    [totalPageCount]="totalPages"
    [currentPageIndex]="page"
    [showPageSize]="true"
    [isZeroBasedIndex]="true"
    (sizeChanged)="onSizeChange($event)"
    (pageChanged)="onPageChange($event)"
  ></ptl-pagination-block>
</div>
