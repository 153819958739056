/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { OrganizationRetrievalDataService } from './organization-retrieval-data.service';
import { Organization, JiscOrganization } from '../../models';
import { ObservableResult } from '../../store';
import { Page } from '../../models/page/page.model';
import { Page as Pagination } from '../../models/page';
import { Publisher } from '../../models/content-store/content-store.model';
import { getDummyOrganizationData } from '../../models/organization/organization-mock-data';

export class MockOrganizationRetrievalDataService implements OrganizationRetrievalDataService {
  private readonly publisher: Publisher = {
    _id: 'someUid',
    organizationUid: 'someUid',
    image: '',
    name: 'Potential.ly',
    description: [{ uid: '', content: '<p>publisher bio information</p>', type: 'PARAGRAPH' }],
    links: { website: 'https://my.potential.ly', linkedIn: '', twitter: '' },
    createdOn: new Date(),
    updatedOn: new Date(),
    email: 'some@emial.com',
    type: 'ORGANIZATION',
  };

  findOrganizationForDomain(domain: string): ObservableResult<Organization> {
    return ObservableResult.ofSuccess(getDummyOrganizationData());
  }

  addDomainToWhitelist(domain: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeDomainFromWhitelist(domain: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  clearWhitelistDomains(): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addDomainToBlacklist(domain: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeDomainFromBlacklist(domain: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  clearBlacklistDomains(): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  searchJiscOrganizations(page: number, size: number, term: string): ObservableResult<Pagination<JiscOrganization>> {
    return ObservableResult.ofSuccess();
  }

  /** Gets the default home page of the organization */
  getHomePage(isAlumni?: boolean, languageCode?: string): ObservableResult<Page> {
    return ObservableResult.ofSuccess();
  }

  getCurrentOrganizationPublisher(): ObservableResult<Publisher> {
    return ObservableResult.ofSuccess(this.publisher);
  }
}
