<!--
  ~ Copyright (C) 2021 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="head-section">
  <h1
    [attr.aria-label]="
      (isContentStore ? 'translations.global.ariaLabels.contentStore' : 'translations.global.ariaLabels.discovery') | transloco
    "
  >
    {{ (isContentStore ? 'translations.sideNav.title.contentStore' : 'translations.sideNav.title.discovery') | transloco }}
  </h1>
  <h2 [attr.aria-label]="'translations.global.ariaLabels.discoverAndSharePlaylists' | transloco">
    {{ 'translations.discoverAndSharePlaylists' | transloco }}
  </h2>
  <div
    class="action-box"
    [hidden]="
      (organizationData$ | async)?.type !== 'FOLIO' && (organizationData$ | async)?.domain.toLowerCase() !== 'jisc.yuna.dev.potential.ly'
    "
  >
    <div *ogBetaFeature>{{ 'translations.shareYorContentWithOthers' | transloco }}</div>
    <div *ogNotBetaFeature>{{ 'translations.shareYorPlaylistsWithOthers' | transloco }}</div>
    <button class="uppercase" mat-button (click)="shareDiscovery()" *ngIf="!isContentStore">
      {{ 'translations.shareToDiscovery' | transloco }}
    </button>
    <button class="uppercase" mat-button (click)="addToContentStore()" *ngIf="isContentStore">
      {{ 'translations.contentStore.button.addToContentStore' | transloco }}
    </button>
  </div>
</div>

<div class="authors-search-section">
  <div class="authors-search-section__search-container">
    <div class="authors-search-section__select-container">
      <span class="authors-search-section__select-author" style="visibility: hidden">{{
        'translations.framework.list.label.author' | transloco
      }}</span>
      <mat-icon aria-hidden="true">search</mat-icon>
      <mat-select style="visibility: hidden" class="authors-search-section__select">
        <mat-option *ngFor="let id of [1, 2, 3]" [value]="id">Author {{ id }}</mat-option>
      </mat-select>
    </div>

    <mat-form-field class="authors-search-section__search" appearance="outline">
      <mat-label></mat-label>
      <input
        id="searchContentInput"
        matInput
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.searchContent' | transloco"
        [matAutocomplete]="auto"
        [(ngModel)]="searchString"
        (paste)="onTextPaste($event)"
        (keyup)="onSearchInputChange($event)"
      />

      <mat-autocomplete
        [panelWidth]="450"
        class="authors-search-section__autocomplete"
        #auto="matAutocomplete"
        (optionSelected)="openPlaylistDetails($event.option.value)"
      >
        <mat-option *ngFor="let searchResult of searchResults; let i = index" [value]="searchResult.id" class="playlist-result-option">
          <div
            class="search-result-playlist-image"
            [style.background-image]="
              searchResult.thumbnail
                ? 'url(' + searchResult.thumbnail + ')'
                : 'url(/assets/card-placeholders/card-placeholder-' + ((i % 4) + 1) + '.png)'
            "
          ></div>
          <span class="playlist-title">{{ searchResult.title }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>
