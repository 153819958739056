<!--
  ~ Copyright (C) 2025 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="user-search-dialog-container">
  <div class="dialog-top-info">
    <h1 class="dialog-title" *ngIf="!selectedUser">
      {{ 'translations.mentors.label.selectMentor' | transloco }}
    </h1>
    <div class="admin-mentors__search" *ngIf="!selectedUser">
      <mat-form-field appearance="outline" class="search">
        <mat-icon matPrefix>search</mat-icon>
        <input
          matInput
          [(ngModel)]="searchQuery"
          (input)="onSearchChangeDebounced()"
          maxlength="64"
          [attr.aria-label]="'translations.content.label.searchByName' | transloco"
          placeholder="{{ 'translations.content.label.searchByName' | transloco }}"
        />
      </mat-form-field>
    </div>
    <button
      class="close-dialog-btn"
      mat-icon-button
      color="primary"
      (click)="closeDialog()"
      [attr.aria-label]="'translations.global.ariaLabels.closeDialog'"
    >
      <svg class="svg" role="img">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>

  <div class="search-results-container" *ngIf="!selectedUser">
    <mat-spinner *ngIf="isLoading" class="loading-spinner"></mat-spinner>
    <ng-container *ngIf="!isLoading">
      <div *ngFor="let user of mentors" class="user-item" (click)="selectUser(user)" (keydown.enter)="selectUser(user)">
        <img [src]="user.imageUrl || 'assets/profile_image.png'" alt="{{ user.firstName }} {{ user.lastName }}" class="avatar" />
        <span class="user-name">{{ user.firstName }} {{ user.lastName }}</span>
      </div>

      <p *ngIf="mentors?.length === 0" class="empty-state-text">
        {{ 'translations.mentors.info.noMentorsAvailable' | transloco }}
      </p>
    </ng-container>
  </div>

  <ng-container *ngIf="selectedUser">
    <div class="user-item-selected" (click)="selectUser(selectedUser)" (keydown.enter)="selectUser(selectedUser)">
      <img
        [src]="selectedUser.imageUrl || 'assets/profile_image.png'"
        alt="{{ selectedUser.firstName }} {{ selectedUser.lastName }}"
        class="avatar"
      />
      <span class="user-name">{{ selectedUser.firstName }} {{ selectedUser.lastName }}</span>
    </div>
  </ng-container>

  <div class="dialog-footer-buttons" *ngIf="selectedUser">
    <button
      mat-flat-button
      type="button"
      color="accent"
      [attr.aria-label]="'translations.global.button.cancel' | transloco"
      (click)="deselectUser()"
      (keydown.enter)="deselectUser()"
    >
      {{ 'translations.global.button.cancel' | transloco }}
    </button>
    <button
      mat-flat-button
      type="button"
      color="accent"
      [attr.aria-label]="'translations.global.button.submit' | transloco"
      (click)="onSubmitClick()"
      (keydown.enter)="onSubmitClick()"
    >
      {{ submitButtonLabel }}
    </button>
  </div>
</div>
