<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="sidebar-section {{ section.class }}" *ngFor="let section of sections">
  <div class="sidebar-item-separator" *ngIf="section.displayTopLineSeparator"></div>

  <div class="sidebar-item-headline extra-small bold" *ngIf="section.titleId">
    <div class="sidebar-item-headline-inner">
      <svg
        class="icon-svg icon svg"
        aria-hidden="true"
        *ngIf="section.icon && section.icon.svg"
        [ngClass]="section.icon.color ? 'item__icon--' + section.icon.color : 'no-icon'"
        [innerHTML]="section.icon.svg | safeHtml"
      ></svg>
      <i
        class="material-icons icon"
        aria-hidden="true"
        *ngIf="section.icon && section.icon.name"
        [ngClass]="section.icon.color ? 'item__icon--' + section.icon.color : 'no-icon'"
      >
        {{ section.icon.name }}
      </i>
      <i
        class="{{ section.icon.class }} icon"
        aria-hidden="true"
        *ngIf="section.icon && section.icon.class"
        [ngClass]="section.icon.color ? 'item__icon--' + section.icon.color : 'no-icon'"
      >
        <span *ngFor="let extraClass of section.icon.extraClasses" [ngClass]="extraClass"></span>
      </i>
      <span>{{ section.title }}</span>
    </div>
  </div>

  <ng-container *ngFor="let link of section.staticLinks">
    <a
      class="sidebar-static-item"
      tabindex="0"
      (click)="onLinkClick(link.uri)"
      [routerLink]="link.uri && !link.preventRedirection ? link.uri : null"
      [routerLinkActiveOptions]="link.uri.startsWith('members') ? { exact: false } : { exact: true }"
      [routerLinkActive]="'is_active'"
    >
      <div class="sidebar-static-link small">
        <ng-container *ngIf="loaderActive && link.hasLoader">
          <mat-spinner class="loader" [diameter]="20"></mat-spinner>
        </ng-container>
        <i *ngIf="!link.icon" class="icon no-icon"></i>
        <svg
          class="icon-svg icon svg"
          role="img"
          [attr.aria-label]="'translations.global.ariaLabels.icons.menuIconName' | transloco"
          *ngIf="link.icon && link.icon.svg"
          [ngClass]="link.icon.color ? 'item__icon--' + link.icon.color : 'no-icon'"
          [innerHTML]="link.icon.svg | safeHtml"
        ></svg>
        <i
          class="material-icons icon"
          aria-hidden="true"
          *ngIf="link.icon && link.icon.name"
          [ngClass]="link.icon.color ? 'item__icon--' + link.icon.color : 'no-icon'"
        >
          {{ link.icon.name }}
        </i>
        <i
          class="{{ link.icon.class }} icon"
          aria-hidden="true"
          *ngIf="link.icon && link.icon.class"
          [ngClass]="link.icon.color ? 'item__icon--' + link.icon.color : 'no-icon'"
        >
          <span *ngFor="let extraClass of link.icon.extraClasses" [ngClass]="extraClass"></span>
        </i>
        <span>{{ link.title }}</span>
      </div>
    </a>
  </ng-container>

  <div class="sidebar-item-separator" *ngIf="section.displayBottomLineSeparator"></div>
</div>

<ng-container *ngIf="sections && sections?.length">
  <ng-content></ng-content>
</ng-container>
