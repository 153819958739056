/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Store } from '@ngxs/store';
import { PlaylistCreationStateModel } from '../../playlist-creation.state.model';
import * as PlaylistAdminActions from '../../playlist-creation.actions';
import { PlaylistDataService } from '../../../../services/create/core/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgZone } from '@angular/core';
import { EditorHelper } from '../../../../../../shared/helpers/editor.helper';
import { SnackbarHelper } from '../../../../../../shared/helpers/snackbar-helper';
import { UpdateSidebarItemChildren } from '../../../../../../shared/side-nav-tree/store/side-nav.actions';

export class PlaylistMainSectionActionHelpers {
  static updateContentAtMainSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    { updatedContentIndex, updateContent }: PlaylistAdminActions.UpdateContentAtMainSection,
  ) {
    patchState({
      playlist: {
        ...state.playlist,
        data: {
          ...state.playlist.data,
          mainSection: {
            ...state.playlist.data.mainSection,
            content: state.playlist.data.mainSection.content.map((content, contentIndex) =>
              contentIndex === updatedContentIndex ? updateContent : content,
            ),
          },
          contentChanged: true,
        },
      },
    });
  }

  static removeContentAtMainSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    { dynamicContentIndex }: PlaylistAdminActions.RemoveContentAtMainSection,
  ) {
    patchState({
      playlist: {
        ...state.playlist,
        data: {
          ...state.playlist.data,
          mainSection: {
            ...state.playlist.data.mainSection,
            content: EditorHelper.removeDynamicContentByIndexAndJoinParagraphContent(
              dynamicContentIndex,
              state.playlist.data.mainSection.content,
            ),
          },
          contentChanged: true,
        },
      },
    });
  }

  static addContentAtMainSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    { content }: PlaylistAdminActions.AddContentAtMainSection,
  ) {
    patchState({
      playlist: {
        ...state.playlist,
        data: {
          ...state.playlist.data,
          mainSection: {
            ...state.playlist.data.mainSection,
            content: EditorHelper.addDynamicContentWithBreakpointCheck(state.playlist.data.mainSection.content, content),
          },
          contentChanged: true,
        },
      },
    });
  }

  static setPlaylistCoverImage(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    { image }: PlaylistAdminActions.SetPlaylistCoverImage,
  ) {
    patchState({
      playlist: {
        ...state.playlist,
        data: {
          ...state.playlist.data,
          mainSection: {
            ...state.playlist.data.mainSection,
            imageToUpload: image,
            imageUrl: undefined,
          },
          contentChanged: true,
        },
      },
    });
  }

  static setPlaylistUploadUrl(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    { imageUploadContent }: PlaylistAdminActions.SetPlaylistCoverImageUrl,
  ) {
    patchState({
      playlist: {
        ...state.playlist,
        data: {
          ...state.playlist.data,
          mainSection: {
            ...state.playlist.data.mainSection,
            imageToUpload: undefined,
            imageUrl: imageUploadContent,
          },
          contentChanged: true,
        },
      },
    });
  }

  static fireMainSectionPaletteAction(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    { action }: PlaylistAdminActions.FireMainSectionPaletteAction,
    store: Store,
  ) {
    if (action === 'NEW_PLAYLIST') {
      return store.dispatch(new PlaylistAdminActions.SavePlaylistAndNavigateToSubPlaylistCreation());
    }

    if (action === 'NEW_SECTION') {
      return store.dispatch(new PlaylistAdminActions.CreateSection());
    }

    if (action === 'NEW_CARD') {
      return store.dispatch(new PlaylistAdminActions.CreateNewCardInMainSection());
    }

    if (action === 'NEW_EVENT_CARD') {
      return store.dispatch(new PlaylistAdminActions.CreateNewEventCardInMainSection());
    }

    if (action === 'DIAGNOSTICS') {
      return store.dispatch(new PlaylistAdminActions.CreateNewDiagnosticsCardMainSection());
    }

    patchState({
      playlist: {
        ...state.playlist,
        data: {
          ...state.playlist.data,
          contentChanged: true,
        },
      },
    });
    return undefined;
  }

  static removeCardFromMainSection(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    { playlistUid, sectionUid, uid }: PlaylistAdminActions.RemoveCardFromMainSection,
    dataService: PlaylistDataService,
    snackBar: MatSnackBar,
    ngZone: NgZone,
    store: Store,
  ) {
    return dataService.removeCardFromSection(playlistUid, sectionUid, uid).subscribe(({ isSuccess, error }) => {
      if (isSuccess) {
        patchState({
          playlist: {
            ...state.playlist,
            data: {
              ...state.playlist.data,
              mainSection: {
                ...state.playlist.data.mainSection,
                cards: state.playlist.data.mainSection.cards.filter((card) => card._id !== uid),
              },
              contentChanged: true,
            },
          },
        });
        store.dispatch(new UpdateSidebarItemChildren(playlistUid, false));
      } else {
        SnackbarHelper.showSnackBar(ngZone, snackBar, error);
      }
    });
  }
}
