<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<div class="dialog-header">
  <div class="dialog-header__top">
    <h1 class="dialog-header-title" tabindex="0" [attr.aria-label]="'translations.global.ariaLabels.sharePage' | transloco">
      {{ 'translations.pages.title.share' | transloco }}
    </h1>

    <i
      class="material-icons dialog-header__close-icon"
      [mat-dialog-close]="false"
      [attr.aria-label]="'translations.global.ariaLabels.closePublishPage' | transloco"
      tabindex="0"
      >close</i
    >
  </div>
</div>

<div class="share-dialog__container">
  <div class="share-dialog__input-container">
    {{ shareLink }}
    <div
      class="share-button"
      tabindex="0"
      [attr.aria-label]="'translations.global.ariaLabels.copyLink' | transloco"
      (click)="onCopy()"
      (keydown.enter)="onCopy()"
    >
      <i class="ptl-icon-copy" aria-hidden="true"></i>
    </div>
  </div>
</div>
