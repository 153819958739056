/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { marker } from '@jsverse/transloco-keys-manager/marker';

@Component({
  selector: 'ptl-completion-user-count-chart',
  templateUrl: './completion-user-count-chart.component.html',
  styleUrls: ['../common-analytics-chart-styles.scss', './completion-user-count-chart.component.scss'],
})
export class CompletionUserCountChartComponent implements OnChanges {
  @Input() relevantCardLevelsCompletions: Record<number, number>;
  @Input() requirementsMode: 'SPECIFIC' | 'PART_OF';
  private translocoService = inject(TranslocoService);

  totalUsers = 0;
  pieSegments: { label: string; value: number; percentage: number; color: string }[] = [];
  baseColors = ['#4c4cff', '#00bfa5', '#ff4081', '#ff9100'];

  tooltipVisible = false;
  tooltipContent: { label: string; value: number; percentage: number } | null = null;
  tooltipX = 0;
  tooltipY = 0;

  showTooltip(event: MouseEvent, segment: { label: string; value: number; percentage: number }): void {
    this.tooltipVisible = true;
    this.tooltipContent = segment;
    this.tooltipX = event.clientX + 10;
    this.tooltipY = event.clientY + 10;
  }

  hideTooltip(): void {
    this.tooltipVisible = false;
    this.tooltipContent = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['relevantCardLevelsCompletions']) {
      this.buildPieChartData();
    }
  }

  private buildPieChartData(): void {
    if (!this.relevantCardLevelsCompletions) {
      this.pieSegments = [];
      this.totalUsers = 0;
      return;
    }

    const completions = Object.entries(this.relevantCardLevelsCompletions)
      .map(([level, count]) => ({
        label: this.getTotalCompletionsLabel(level),
        value: count,
        numericLabel: Number(level),
      }))
      .sort((a, b) => a.numericLabel - b.numericLabel);

    this.totalUsers = completions.reduce((sum, segment) => sum + segment.value, 0);

    if (completions.length <= 9) {
      this.pieSegments = completions.map((segment, index) => ({
        label: segment.label,
        value: segment.value,
        percentage: (segment.value / this.totalUsers) * 100,
        color: this.getColor(index),
      }));
      return;
    }

    const others = completions.slice(0, -9);
    const topCompletions = completions.slice(-9);
    const othersValue = others.reduce((sum, segment) => sum + segment.value, 0);
    const maxOthersNumericLabel = Math.max(...others.map((segment) => segment.numericLabel), 0);
    const othersLabel = this.getOthersLabel(String(maxOthersNumericLabel));

    this.pieSegments = [
      ...topCompletions.map((segment, index) => ({
        label: segment.label,
        value: segment.value,
        percentage: (segment.value / this.totalUsers) * 100,
        color: this.getColor(index),
      })),
      {
        label: othersLabel,
        value: othersValue,
        percentage: (othersValue / this.totalUsers) * 100,
        color: this.getColor(9),
      },
    ].sort((a, b) => b.label.localeCompare(a.label, undefined, { numeric: true }));
  }

  private getColor(index: number): string {
    if (index < this.baseColors.length) {
      return this.baseColors[index];
    }
    const hue = (index * 137.5) % 360;
    return `hsl(${hue}, 70%, 60%)`;
  }

  calculateOffset(index: number): number {
    return this.pieSegments.slice(0, index).reduce((sum, segment) => sum + segment.percentage, 0);
  }

  private getTotalCompletionsLabel(count: string): string {
    const isSingle = count === '1';
    const key =
      this.requirementsMode === 'SPECIFIC'
        ? isSingle
          ? marker('translations.analytics.charts.totalCompletions.requiredCard')
          : marker('translations.analytics.charts.totalCompletions.requiredCards')
        : isSingle
          ? marker('translations.analytics.charts.totalCompletions.card')
          : marker('translations.analytics.charts.totalCompletions.cards');

    return isSingle ? this.translocoService.translate(key) : this.translocoService.translate(key).replace('{count}', count);
  }

  private getOthersLabel(count: string): string {
    return this.translocoService
      .translate('translations.analytics.charts.totalCompletions.otherCompletionsLabel')
      .replace('{count}', count);
  }
}
