/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { InjectionToken } from '@angular/core';
import {
  Collector,
  CollectorUpdateRequest,
  LearnerCollectorSummary,
  LearnerLoggedItemRequest,
  LearnerLoggedItemSummary,
} from '../../models/editor/collector-content.model';
import { SubmitPlaylistSummary } from '../../models/playlist/playlist-report.model';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const COLLECTOR_DATA_SERVICE = new InjectionToken<CollectorDataService>('[resource] CollectorDataService');

/** The service handling collector-related CRUD operations. */
export interface CollectorDataService {
  updateCollector(
    cardUid: string,
    collectorUid: string,
    request: CollectorUpdateRequest,
    languageCode?: string,
  ): ObservableResult<Collector>;

  getCollector(cardUid: string, collectorUid: string, languageCode?: string): ObservableResult<Collector>;

  deleteCollector(cardUid: string, collectorUid: string): ObservableResult<void>;

  /** Get Learner Collector Summary for current logged-in user */
  getLearnerCollectorSummary(playlistUid: string, collectorUid: string, languageCode?: string): ObservableResult<LearnerCollectorSummary>;

  /** Get Learner Collector Summary for user who created a review. */
  getLearnerCollectorSummaryAsReviewer(
    playlistUid: string,
    collectorUid: string,
    reviewUid: string,
  ): ObservableResult<LearnerCollectorSummary>;

  addLogItem(
    learnerCollectorUid: string,
    request: LearnerLoggedItemRequest,
    languageCode?: string,
  ): ObservableResult<LearnerLoggedItemSummary>;

  deleteLogItem(learnerCollectorUid: string, learnerEvidenceUid: string): ObservableResult<LearnerLoggedItemSummary>;

  updateLogItem(
    learnerCollectorUid: string,
    learnerEvidenceUid: string,
    request: LearnerLoggedItemRequest,
    languageCode?: string,
  ): ObservableResult<LearnerLoggedItemSummary>;

  submitLearnerCollectorSummary(playlistUid: string, learnerCollectorUid: string): ObservableResult<void>;

  submitLearnerCollectorSummaryAndPlaylist(
    playlistUid: string,
    learnerCollectorUid: string,
    request: SubmitPlaylistSummary,
  ): ObservableResult<void>;
}
