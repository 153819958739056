export enum EditorType {
  BASIC,
  RESOURCE_CARD,
  ASSESSMENT_STANDARD_SECTION,
  PAGE_CALLOUT_SECTION,
}

export enum EditorStatus {
  NOT_READY = 'not-ready',
  READY = 'ready',
}

/* Tools ****************************************/
export enum DefaultInlineToolType {
  Bold = 'bold',
  Italic = 'italic',
}

export enum CustomInlineToolType {
  AlignmentLeft = 'AlignmentLeft',
  AlignmentCenter = 'AlignmentCenter',
  AlignmentRight = 'AlignmentRight',
  TextColor = 'TextColor',
  TextMarker = 'TextMarker',
  Header = 'Header',
  Link = 'Link',
  Backquote = 'Backquote',
}

type InlineToolType = DefaultInlineToolType | CustomInlineToolType;

export enum BlockToolType {
  ParagraphBlockTool = 'ParagraphBlockTool',
  ExternalContentBlockTool = 'ExternalContentBlockTool',
  ImageBlockTool = 'ImageBlockTool',
  StreamVideoBlockTool = 'StreamVideoBlockTool',
  AudioBlockTool = 'AudioBlockTool',
  FileBlockTool = 'FileBlockTool',
  EmbedCodeBlockTool = 'EmbedCodeBlockTool',
  SeparatorBlockTool = 'SeparatorBlockTool',
  TextboxFormBlockTool = 'TextboxFormBlockTool',
  ConnectedTextboxFormBlockTool = 'ConnectedTextboxFormBlockTool',
  QuestionFormBlockTool = 'QuestionFormBlockTool',
  PrivateNoteFormBlockTool = 'PrivateNoteFormBlockTool',
  CheckboxFormBlockTool = 'CheckboxFormBlockTool',
  UserFileUploadFormBlockTool = 'UserFileUploadFormBlockTool',
  BookingFormBlockTool = 'BookingFormBlockTool',
  MediaEmbedsBlockTool = 'MediaEmbedsBlockTool',
  QuizFormBlockTool = 'QuizFormBlockTool',
  CollectorFormBlockTool = 'CollectorFormBlockTool',
  ChartBlockTool = 'ChartBlockTool',
}

export type EditorJsToolType = InlineToolType | BlockToolType;

/* Events ****************************************/
export enum EditorEventType {
  CONTENT_CREATED = 'ptl-content-created',
  CONTENT_UPDATED = 'ptl-content-updated',
  CONTENT_DELETED = 'ptl-content-deleted',
  SET_AUTO_SAVE_IN_PROGRESS = 'ptl-init-auto-save',
  RENDER_DIALOG_OPENED = 'ptl-render-dialog-opened',
}

export type EditorEvent = {
  event: EditorEventType;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  handler: (data: any) => void;
};
