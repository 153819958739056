import { AfterViewInit, Component, ElementRef, HostListener, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Answer,
  AnswerRequest,
  DataForm,
  DataFormWithAnswers,
  FormElement,
  FormElementDependency,
  FormQuestion,
} from '../../../models/data-form/data-form.model';
import {
  CheckboxFormContent,
  FirstNameFormContent,
  GroupElement,
  LastNameFormContent,
  ListFormContent,
  NestedChildData,
  Organization,
  SliderFormContent,
  TextBoxFormContent,
} from '../../../models';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RedirectHelper } from '../../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { UserAuthState } from '../../../../user-auth/store/user-auth.state';
import { Select } from '@ngxs/store';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { filter } from 'rxjs/operators';

@Component({
  templateUrl: './data-forms-dialog.component.html',
  styleUrls: ['./data-forms-dialog.component.scss'],
})
export class DataFormsDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @Select(UserAuthState.homePageUri)
  homePageUri$: Observable<string>;

  private dialogRefSubscription: Subscription;
  private dialogRefBackdropSubscription: Subscription;
  private homePageUri: string;
  private checkboxListOptions: string[] = [];
  public dataForm: DataForm;
  public data: DataFormWithAnswers[];
  public currentData: DataFormWithAnswers;
  public currentIndex = 0;
  public canClose: boolean;
  @ViewChild('nextStep') nextStep: ElementRef;
  @ViewChild('checkbox') checkbox: MatCheckbox;
  public formsCount = 1;
  public submittedFormCount = 0;
  public submittedFormData: { answers: AnswerRequest[]; pageLocation: Location; userFormUid: string }[] = [];
  public userFormUid: string;
  public userAnswers: Answer[];
  // if canSkip is true, canClose must also be true
  public canSkip: boolean;
  customSelectSelectedText = {};
  selectedElement: { uId: string } = {} as { uId: string };
  activeCustomSelect: number;
  retake: boolean;

  constructor(
    private dialogRef: MatDialogRef<DataFormsDialogComponent>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: { canClose: boolean; retake: boolean; response: DataFormWithAnswers[] },
  ) {
    this.data = data.response;
    if (!this.data) {
      return;
    }

    this.formsCount = this.data.length;

    this.currentData = this.data[this.currentIndex];
    this.dataForm = this.currentData.dataForm;
    this.canClose = data.canClose;
    this.retake = data.retake;
    this.canSkip = !this.retake && !this.currentData.dataForm.mandatory;
    this.userFormUid = this.currentData.userForm._id;
    this.userAnswers = this.currentData.userForm.answers;
  }

  questions: FormQuestion[] = [];
  controls = [];
  formAnswers: FormGroup;
  answerTypes = {
    TEXTBOX: 'TEXT',
    CHECKBOX: 'CHECKBOX',
    LIST: 'LIST',
    GROUP_LIST: 'GROUP',
    SLIDER: 'FLOAT',
    FIRST_NAME: 'FIRST_NAME',
    LAST_NAME: 'LAST_NAME',
  };

  reRenderView() {
    this.questions = [];
    this.formAnswers.reset();
    this.controls = [];
    this.customSelectSelectedText = [];
    this.selectedElement = {} as { uId: string };

    if (!this.data[this.currentIndex + 1]) {
      this.currentIndex = 0;
    } else {
      this.currentIndex++;
    }

    this.currentData = this.data[this.currentIndex];
    this.dataForm = this.currentData.dataForm;
    this.canSkip = !this.currentData.dataForm.mandatory;
    this.userFormUid = this.currentData.userForm._id;
    this.userAnswers = this.currentData.userForm.answers;
    this.ngOnInit();
  }

  ngOnInit() {
    this.homePageUri$.pipe(filter((data) => !!data)).subscribe((data) => (this.homePageUri = data));
    if (this.dataForm) {
      this.dataForm.elements.map((element) => {
        this.controls.push(this.controlFor(element));
      });

      this.formAnswers = this.fb.group({
        answers: this.fb.array(this.controls),
      });

      this.dataForm.elements.forEach((element) => {
        const question = DataFormsDialogComponent.buildFormQuestion(element);
        this.questions.push(question);
      });
    }
    if (this.userAnswers) {
      this.prefillUserAnswers(this.userAnswers);
    }
  }

  ngAfterViewInit(): void {
    this.dialogRefBackdropSubscription = this.dialogRef.backdropClick().subscribe(() => {
      if (this.canSkip) {
        this.onSkip();
      } else {
        this.onClose();
      }
    });

    this.dialogRefSubscription = this.dialogRef.keydownEvents().subscribe((event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        if (this.canSkip) {
          this.onSkip();
        } else {
          this.onClose();
        }
      }
    });
  }

  onSave() {
    this.controls.forEach((control) => control.markAsUntouched());
    if (!this.formAnswers.valid) {
      this.controls.forEach((control) => control.markAsTouched());
      return;
    }

    this.submittedFormCount++;
    this.submittedFormData.push({ answers: this.getAnswers(), pageLocation: location, userFormUid: this.userFormUid });
    this.nextStep.nativeElement.click();
    this.formAnswers.reset();

    if (this.formsCount === this.submittedFormCount && this.submittedFormData.length) {
      this.dialogRef.close(this.submittedFormData);
    } else {
      this.reRenderView();
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const targetElement = event.target as Element;
    if (!targetElement.closest('.f_custom-select')) {
      this.activeCustomSelect = null;
    }
  }

  onClose() {
    if (this.canClose) {
      this.submittedFormCount++;
      if (!this.retake) {
        RedirectHelper.redirectToHomePage(this.ngZone, this.router, this.activatedRoute, this.homePageUri);
      }
      this.dialogRef.close({ userFormUid: this.userFormUid });
    }
  }

  onSkip() {
    if (this.canSkip) {
      this.submittedFormCount++;
      this.dialogRef.close({ userFormUid: this.userFormUid });
    }
  }

  ngOnDestroy() {
    this.dialogRefBackdropSubscription?.unsubscribe();
    this.dialogRefSubscription?.unsubscribe();
  }

  getDataFromChildrenEvent(event: NestedChildData | GroupElement[], result = []): GroupElement[] {
    if ('fromChild' in event && event.fromChild === true) {
      result.push(event?.data);
      return this.getDataFromChildrenEvent(event?.data?.$event, result);
    } else {
      // @ts-ignore
      if (event?.uid) {
        result.push(event);
      }
      return result;
    }
  }

  openCustomSelect(index: number) {
    if (this.activeCustomSelect === index) {
      this.activeCustomSelect = null;
      return;
    }
    this.activeCustomSelect = index;
  }

  onGroupSelect($event: NestedChildData, index?: number, uid?: string) {
    const data = this.getDataFromChildrenEvent($event);
    const ids = [];
    data.forEach((selectedItem) => {
      ids.push(selectedItem.value.id);
    });
    (this.formAnswers.get('answers') as FormArray).at(index).patchValue(ids ?? '');
    this.updateHidden(uid);
    this.customSelectSelectedText[uid] = data[data.length - 1].value.name;
    this.selectedElement[uid] = data[data.length - 1].value.id;
    this.activeCustomSelect = null;
  }

  onChecked($event: MatCheckboxChange | KeyboardEvent, uid: string) {
    if ($event instanceof KeyboardEvent && $event.code === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      this.checkbox.checked = !this.checkbox.checked;
    }
    this.updateHidden(uid);
  }

  onCheckListItem(event: MatCheckboxChange | KeyboardEvent, value: string) {
    if (event instanceof KeyboardEvent && event.code === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
    }

    const optionIndex = this.checkboxListOptions.indexOf(value);

    if (optionIndex === -1) {
      this.checkboxListOptions.push(value);
    } else {
      this.checkboxListOptions.splice(optionIndex, 1);
    }
  }

  private controlFor(element: FormElement): FormControl {
    const require = Validators.required;
    const isRequired = element.config.mandatory;

    switch (element.details.type) {
      case 'TEXTBOX':
        return this.fb.control('', isRequired ? [require] : null);
      case 'FIRST_NAME':
        return this.fb.control('', isRequired ? [require] : null);
      case 'LAST_NAME':
        return this.fb.control('', isRequired ? [require] : null);
      case 'CHECKBOX':
        return this.fb.control(false, isRequired ? [require] : null);
      case 'GROUP_LIST':
        return this.fb.control('', isRequired ? [require] : null);
      case 'LIST':
        return this.fb.control('', isRequired ? [require] : null);
      case 'SLIDER':
        return this.fb.control((element.details as SliderFormContent).min, isRequired ? [require] : null);
      default:
        return this.fb.control('', isRequired ? require : null);
    }
  }

  private static buildFormQuestion(element: FormElement): FormQuestion {
    const uid = element.uid;
    const content = element.details;
    const type = content.type;
    const isRequired = element.config.mandatory;
    const deps = element.config.dependsOn.map((d) => {
      return { dep: d, fulfilled: false };
    });
    let title = '';
    let options = [];
    let placeholder = '';

    switch (type) {
      case 'TEXTBOX':
        title = (content as TextBoxFormContent).title;
        break;
      case 'CHECKBOX':
        title = (content as CheckboxFormContent).title;
        break;
      case 'GROUP_LIST': {
        const groupListForm = content as ListFormContent;
        const root = groupListForm.list.root as GroupElement;
        title = groupListForm.title;
        options = root.sublist;
        placeholder = root.value.name;
        break;
      }
      case 'LIST': {
        const listForm = content as ListFormContent;
        title = listForm.title;
        options = listForm.list.root.sublist;
        break;
      }
      case 'SLIDER':
        title = (content as SliderFormContent).title;
        break;
      case 'FIRST_NAME':
        title = (content as FirstNameFormContent).title;
        break;
      case 'LAST_NAME':
        title = (content as LastNameFormContent).title;
        break;
      default:
        break;
    }

    if (isRequired) {
      title += ' *';
    }

    return {
      uid: uid,
      type: type,
      title: title,
      required: isRequired,
      placeholder: placeholder,
      options: options,
      hidden: deps.length > 0,
      deps: deps,
    };
  }

  private getAnswers(): AnswerRequest[] {
    const answers = [];
    this.questions.forEach((question, index) => {
      if (!question.hidden) {
        const type = this.answerTypes[question.type];
        const formElementUid = question.uid;

        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        let answer: any;

        if (question.type === 'LIST') {
          answer = this.checkboxListOptions;
        } else {
          answer = this.formAnswers.value.answers[index];
        }

        answers.push({
          type: type,
          formElementUid: formElementUid,
          content: answer,
        });
      }
    });
    return answers;
  }

  private updateHidden(uid: string) {
    this.questions = this.questions.map((question, index) => this.updateHiddenQuestion(question, uid, index));
  }

  private updateHiddenQuestion(question: FormQuestion, uid: string, index: number): FormQuestion {
    question.deps = question.deps.map(({ dep, fulfilled }) => {
      if (dep.formElementUid === uid) {
        return { dep: dep, fulfilled: this.check(dep) };
      } else {
        return { dep: dep, fulfilled: fulfilled };
      }
    });
    const isHidden = !question.deps.every((dep) => dep.fulfilled);
    question.hidden = isHidden;
    this.formRequiredFields(index, question.required, isHidden);
    return question;
  }

  private check(dep: FormElementDependency): boolean {
    const question = this.questions.find((q) => q.uid === dep.formElementUid);
    const answers = this.formAnswers.get('answers') as FormArray;
    if (question) {
      if (question.type === 'GROUP_LIST') {
        const selectBoxIndex = this.questions.findIndex((q) => q.uid === dep.formElementUid);
        const selectBoxValue = answers.at(selectBoxIndex).value;
        if (Array.isArray(dep.value)) {
          return dep.value.some((v) => selectBoxValue.includes(v));
        } else {
          return selectBoxValue.includes(dep.value);
        }
      } else if (question.type === 'CHECKBOX') {
        const checkboxIndex = this.questions.findIndex((q) => q.uid === dep.formElementUid);
        const checkboxValue = answers.at(checkboxIndex).value;
        return dep.value === checkboxValue;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private prefillUserAnswers(answers: Answer[]) {
    const formAnswersArray = this.formAnswers.get('answers') as FormArray;
    answers.forEach((answer) => {
      const index = this.questions.findIndex((question) => question.uid === answer.formElementUid);
      if (index !== -1) {
        if (answer.element.type === 'GROUP') {
          this.prefillGroupList(index, answer, formAnswersArray);
        } else {
          formAnswersArray.at(index).patchValue(answer.element.value);
        }
        this.updateHidden(answer.formElementUid);
      }
    });
  }

  private formRequiredFields(questionIndex, questionRequired, questionIsHidden) {
    const answers = this.formAnswers.get('answers') as FormArray;
    const control = answers.controls[questionIndex];
    if (questionIsHidden) {
      control.removeValidators(Validators.required);
      control.updateValueAndValidity();
    } else {
      if (questionRequired) {
        control.setValidators(Validators.required);
        control.markAsUntouched();
        control.updateValueAndValidity();
      }
    }
  }

  private prefillGroupList(index: number, answer: Answer, formAnswersArray: FormArray) {
    const values = answer.element.values;
    formAnswersArray.at(index).patchValue(values);
    const selectedGroupId = values[values.length - 1];
    this.customSelectSelectedText[answer.formElementUid] = this.findSelectedText(answer.formElementUid, selectedGroupId);
    this.selectedElement[answer.formElementUid] = answer.formElementUid;
  }

  private findSelectedText(formElementUid: string, groupId: string): string {
    const formElement = this.dataForm.elements.find((element) => element.uid === formElementUid);
    const root = (formElement.details as ListFormContent).list.root as GroupElement;
    return this.findGroupName(root, groupId);
  }

  private findGroupName(groupElement: GroupElement, groupId: string): string | null {
    if (groupElement.value.id === groupId) {
      return groupElement.value.name;
    }

    for (const subElement of groupElement.sublist) {
      const name = this.findGroupName(subElement, groupId);
      if (name !== null) {
        return name;
      }
    }

    return null;
  }
}
