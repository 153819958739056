<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="collector-preview__section"
  [class.form-preview--saved]="learnerCollectorSummary?.status === 'COMPLETED'"
  [class.form-preview--requires-resubmission]="requiresResubmission"
  role="form"
  tabindex="0"
  [attr.aria-label]="'translations.global.ariaLabels.collectorForm' | transloco"
>
  <div class="collector-preview__section-top">
    <div class="collector-preview__section-icon">
      <mat-icon aria-hidden="true">card_travel</mat-icon>
    </div>
    <div class="collector-preview__section-info">
      <div class="collector-preview__headline">
        <h2 class="collector-preview__section-title">{{ title }}</h2>
        <ptl-resource-preview [previewContent]="instruction"></ptl-resource-preview>
      </div>

      <div *ngIf="expanded" class="collector-preview__log-info">
        <div
          class="collector-preview__progress-box"
          role="status"
          [attr.aria-label]="'translations.global.ariaLabels.completed' | transloco: { progress: learnerCollectorSummary?.progress + '%' }"
        >
          <div class="collector-preview__progress-range">
            <div class="collector-preview__progress-range-inner" [ngStyle]="{ width: learnerCollectorSummary?.progress + '%' }"></div>
          </div>
          <span class="collector-preview__progress">{{ learnerCollectorSummary?.progress }}%</span>
        </div>
        <div class="collector-preview__log-info-table">
          <div class="log-info-table" role="table">
            <div class="log-info-table-row" role="row">
              <div class="log-info-table-cell header-name-cell"></div>
              <div class="log-info-table-cell header-name-time">
                <span *ngIf="requiredTable.type !== 'TIME_LOG'">{{ 'translations.collector.completionTable.moments' | transloco }}</span>
                <span *ngIf="requiredTable.type === 'TIME_LOG'">{{ 'translations.collector.completionTable.logs' | transloco }}</span>
              </div>
            </div>
            <div
              class="log-info-table-row"
              tabindex="0"
              role="row"
              [attr.aria-label]="
                'translations.global.ariaLabels.totalRequirementForComplete' | transloco: { totalCount: requiredTable.totalCount }
              "
            >
              <div class="log-info-table-cell name-cell" role="cell">
                {{ 'translations.collector.completionTable.totalRequired' | transloco }}
              </div>
              <div class="log-info-table-cell name-required" role="cell">{{ requiredTable.totalCount }}</div>
            </div>
            <div
              class="log-info-table-row"
              tabindex="0"
              role="row"
              [attr.aria-label]="
                'translations.global.ariaLabels.alreadyLogged' | transloco: { completedCount: requiredTable.completedCount }
              "
            >
              <div class="log-info-table-cell name-cell" role="cell">
                {{ 'translations.collector.completionTable.logsLogged' | transloco }}
              </div>
              <div class="log-info-table-cell name-required green" role="cell">{{ requiredTable.completedCount }}</div>
            </div>
            <div
              class="log-info-table-row"
              tabindex="0"
              role="row"
              [attr.aria-label]="
                'translations.global.ariaLabels.remainingToLog' | transloco: { remainingCount: requiredTable.remainingCount }
              "
            >
              <div class="log-info-table-cell name-cell" role="cell">
                {{ 'translations.collector.completionTable.logsRemaining' | transloco }}
              </div>
              <div class="log-info-table-cell name-required orange" role="cell">{{ requiredTable.remainingCount }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="collector-preview__submit-status-label" *ngIf="!expanded">
        <div
          *ngIf="learnerCollectorSummary?.status === 'COMPLETED'"
          class="form-preview__top-icon non-editable"
          [matTooltip]="canNotEditReason"
        >
          <mat-icon
            *ngIf="canEdit"
            aria-hidden="true"
            [attr.aria-label]="'translations.global.ariaLabels.collectorFormSubmitted' | transloco"
            >check
          </mat-icon>
          <svg *ngIf="!canEdit" class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.lock' | transloco">
            <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
          </svg>
        </div>
        <div
          *ngIf="learnerCollectorSummary?.status !== 'COMPLETED' && !canEdit"
          class="form-preview__top-icon non-saved non-editable"
          [matTooltip]="canNotEditReason"
        >
          <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.lock' | transloco">
            <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
          </svg>
        </div>
        <button
          *ngIf="canEdit"
          tabindex="0"
          class="form-preview__top-icon editable pointer"
          (click)="expandCollectorInfo(true)"
          (keydown.enter)="expandCollectorInfo(true)"
        >
          <svg class="svg" role="img" [attr.aria-label]="'translations.global.ariaLabels.icons.edit' | transloco">
            <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="expanded" class="collector-preview__section-list">
    <button
      mat-stroked-button
      tabindex="0"
      class="collector-preview__section-add-moment uppercase"
      color="accent"
      title="Add from existing moments"
      [attr.aria-label]="'translations.global.ariaLabels.logNewItem' | transloco"
      [disabled]="!canEdit"
      (click)="addEditLogItem()"
    >
      {{ 'translations.collector.button.logNewItem' | transloco }}
    </button>

    <ptl-collector-table
      [learnerCollectorSummary]="learnerCollectorSummary"
      [requiresResubmission]="requiresResubmission"
      (logItemEdit)="editLoggedItem($event)"
      (logItemDelete)="openDeleteLogItemPopUp($event)"
    >
    </ptl-collector-table>
  </div>
  <div class="collector-preview__footer">
    <button
      *ngIf="!expanded"
      mat-stroked-button
      class="collector-preview__expand-btn uppercase"
      title="Show the collector evidences"
      color="accent"
      (click)="expandCollectorInfo(true)"
    >
      {{ 'translations.global.button.seeMore' | transloco }}
    </button>
    <button
      *ngIf="expanded && (learnerCollectorSummary?.status !== 'COMPLETED' || requiresResubmission) && canEdit"
      mat-flat-button
      [disabled]="isSubmissionDisabled"
      class="collector-preview__submit-btn uppercase"
      color="primary"
      (click)="submit()"
    >
      <span *ngIf="requiresResubmission">{{ 'translations.cards.form.button.resubmit' | transloco }}</span>
      <span *ngIf="!requiresResubmission">{{ 'translations.cards.form.button.markAsDone' | transloco }}</span>
    </button>
  </div>

  <div class="form-preview-notes" *ngIf="formData?.content?.notes?.length">
    <div class="note-item" *ngFor="let note of formData?.content?.notes" tabindex="0">
      <div class="note-item-inner">
        <div class="note-message small">
          <ptl-resource-preview class="thin-preview" [previewContent]="note.message"> </ptl-resource-preview>
        </div>
      </div>
      <div class="note-added-by extra-small italic">{{ note.addedBy }}, {{ note.addedOn | dateFormat: 'DD MMM yyyy HH:mm' }}</div>
    </div>
  </div>

  <button title="Collapse" *ngIf="expanded" class="collector-preview__collapse-btn" (click)="expandCollectorInfo(false)">
    <mat-icon aria-hidden="true">keyboard_arrow_up</mat-icon>
  </button>
</div>

<ptl-form-update-footer
  [submitted]="learnerCollectorSummary?.status === 'COMPLETED'"
  [canEdit]="canEdit"
  [learnerFormAnswer]="learnerFormAnswer"
  [formType]="formData?.content?.type"
  [playlistSubmissionDeadline]="userPlaylistSubmissionSummary?.submissionDeadline"
>
</ptl-form-update-footer>
