import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { formatDate } from '@angular/common';
import {
  LearnerCollectorSummary,
  LearnerLoggedItem,
  LearnerLoggedItemRequestTimeLogPeriod,
  SubmittedMoment,
} from '../../../../../models/editor/collector-content.model';
import { MatDialog } from '@angular/material/dialog';
import { CollectorDetailDialogComponent, CollectorDetailDialogData } from './collector-detail-dialog/collector-detail-dialog.component';
import { EditorContent, ResourceTag } from '../../../../../models';
import {
  CORE_MOMENT_DATA_SERVICE,
  MomentDataService,
} from '../../../../../../page-modules/folio/shared-moment-module/services/data.service';
import { Moment } from '../../../../../../page-modules/folio/models';
import { cloneDeep } from 'lodash-es';
import { LocalTimeHelper } from '../../../../../helpers/local-time-helper';
import { LanguageCodeHelper } from '../../../../../helpers/language-code-helper';

@Component({
  selector: 'ptl-collector-table',
  templateUrl: './form-collector-table.component.html',
  styleUrls: ['./form-collector-table.component.scss'],
})
export class FormCollectorTableComponent implements OnInit {
  @Input() learnerCollectorSummary: LearnerCollectorSummary;

  @Input() requiresResubmission: boolean;

  @Input() staticView: boolean;

  @Output() logItemEdit = new EventEmitter<LearnerLoggedItem>();

  @Output() logItemDelete = new EventEmitter<LearnerLoggedItem>();

  expandedIndex: number;

  constructor(
    private dialog: MatDialog,
    @Inject(CORE_MOMENT_DATA_SERVICE) private momentDataService: MomentDataService,
  ) {}

  ngOnInit() {
    if (this.learnerCollectorSummary) {
      this.learnerCollectorSummary = this.getSummaryFormattedData(this.learnerCollectorSummary);
    }
  }

  editLoggedItem(loggedItem: LearnerLoggedItem) {
    this.logItemEdit.emit(loggedItem);
  }

  deleteLoggedItem(loggedItem: LearnerLoggedItem) {
    this.logItemDelete.emit(loggedItem);
  }

  expandLog(index: number) {
    if (this.expandedIndex === index) {
      this.expandedIndex = undefined;
      return;
    }
    this.expandedIndex = index;
  }

  showLoggedItemForm(loggedItem: LearnerLoggedItem) {
    const logTemplate = cloneDeep(this.learnerCollectorSummary.config.logTemplate);
    for (const template of logTemplate) {
      const form = loggedItem.answers.find((item) => item.formId === template.uid);
      if (form) {
        template.content.submitted = true;
        template.content.userAnswer = form.answer;
      }
    }
    this.openDialog(undefined, logTemplate, loggedItem.title, loggedItem.loggedOn, loggedItem.submittedTimeLogPeriod, loggedItem.tags);
  }

  showLoggedItemMoment(moment: SubmittedMoment, loggedItem: LearnerLoggedItem) {
    this.momentDataService.getMomentDetails(moment.uid).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.openDialog(value, undefined, loggedItem.title, loggedItem.loggedOn, loggedItem.submittedTimeLogPeriod, loggedItem.tags);
      }
    });
  }

  getLocalDateTime(date) {
    return LocalTimeHelper.getLocalDateTime(date);
  }

  private openDialog(
    moment: Moment,
    content: EditorContent[],
    logTitle: string,
    loggedDate: Date,
    timestamp: LearnerLoggedItemRequestTimeLogPeriod,
    tags: ResourceTag[],
  ) {
    const data: CollectorDetailDialogData = {
      moment: moment ? moment : undefined,
      logTemplate: content ? content : undefined,
      logTitle,
      loggedDate,
      timestamp,
      tags,
    };

    this.dialog.open(CollectorDetailDialogComponent, {
      width: '100%',
      maxWidth: '56.25rem',
      maxHeight: '80vh',
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
      data,
    });
  }

  private getDateDisplayString(submittedTimeLogPeriod: LearnerLoggedItemRequestTimeLogPeriod) {
    let result = '-- to --';

    if (submittedTimeLogPeriod && submittedTimeLogPeriod.from && submittedTimeLogPeriod.to) {
      result =
        formatDate(submittedTimeLogPeriod.from, 'yyyy MMMM dd', 'en') +
        ' to ' +
        formatDate(submittedTimeLogPeriod.to, 'yyyy MMMM dd', 'en');
    }

    return result;
  }

  private getSummaryFormattedData(data: LearnerCollectorSummary) {
    for (const logItem of data.loggedItems) {
      if (logItem.submittedTimeLogPeriod) {
        logItem.submittedTimeLogPeriod.formattedDate = this.getDateDisplayString(logItem.submittedTimeLogPeriod);
      }
    }
    return data;
  }
}
