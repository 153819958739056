/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'ptl-choice-question-type',
  templateUrl: './choice-question-type.component.html',
  styleUrls: ['../add-question-section-new.component.scss', './choice-question-type.component.scss'],
})
export class ChoiceQuestionTypeComponent implements OnChanges {
  @Input() question: FormGroup;
  @Input() isEditingForm: boolean;
  @Input() languageCode: string | undefined;
  @Input() isDefaultLanguage: boolean;

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
  ) {}

  ngOnChanges() {
    if (this.question) {
      this.populateForm();
    }
  }

  addQuestionOption(data?: { value: string; score: number }): FormGroup {
    const dynamicContentRequest = this.fb.array([
      this.fb.group({
        content: '',
        type: 'PARAGRAPH',
        uid: '',
      }),
    ]);

    return this.fb.group({
      type: 'CHOICE_OPTION',
      instructions: dynamicContentRequest,
      value: this.fb.group({
        languageCode: this.languageCode,
        value: [data ? data.value : '', [Validators.required, Validators.maxLength(33)]],
      }),
      score: [data ? data.score : undefined, Validators.required],
    });
  }

  removeOption(index) {
    (this.question.controls['options'] as FormArray).removeAt(index);
  }

  addNewOption(focus: boolean = false) {
    (this.question.controls['options'] as FormArray).push(this.addQuestionOption());

    if (focus) {
      setTimeout(() => {
        const lastElement = this.question.controls['options']['controls'].length - 1;
        const inputElement = this.renderer.selectRootElement(`#input${lastElement}`);

        inputElement.focus();
      }, 500);
    }
  }

  dropOption(moveEvent: CdkDragDrop<FormArray[]>) {
    moveItemInArray((this.question.controls['options'] as FormArray).controls, moveEvent.previousIndex, moveEvent.currentIndex);
    this.question.controls['options'].updateValueAndValidity();
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  makeEditableFieldActive(item: any, event: Event, isActive: boolean = true) {
    if (!isActive) {
      item.editable = false;
      return;
    }

    item.editable = true;
    const targetElement = event.target as Element;
    const questionTitleContainer = targetElement.closest('.f_add-question-section-title');

    if (!questionTitleContainer) {
      return;
    }

    setTimeout(() => {
      const questionTitleInput = questionTitleContainer.querySelector('.f_question-title-input') as HTMLElement;
      questionTitleInput?.focus();
    }, 0);
  }

  private populateForm() {
    // if no uid, clear current arrays and add a blank one
    if (!this.question.value.uid) {
      while ((this.question.controls['options'] as FormArray).length !== 0) {
        (this.question.controls['options'] as FormArray).removeAt(0);
      }
      this.addNewOption();
    }
  }
}
