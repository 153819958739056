<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="segment-chart-graph">
  <div class="segment-bar-chart">
    <div class="segment-bar-chart-container">
      <div class="min-icon icon-item" *ngIf="sectionIcons?.minIcon && sectionIcons?.minIcon?.iconImageUrl">
        <img src="{{ sectionIcons.minIcon.iconImageUrl }}" alt="" width="30" height="30" />
      </div>
      <div class="segment-bar-chart-progress">
        <div
          class="segment-bar-chart-progress-item"
          *ngFor="let item of [].constructor(barItemsCount); let i = index"
          [ngClass]="{ active: i < (percentageValue / 100) * barItemsCount - 1 }"
        >
          <div [ngStyle]="{ 'width.%': calculateWidth(i) }" class="partially-filled"></div>
        </div>
      </div>
      <div class="max-icon icon-item" *ngIf="sectionIcons?.maxIcon && sectionIcons?.maxIcon?.iconImageUrl">
        <img src="{{ sectionIcons.maxIcon.iconImageUrl }}" alt="" width="30" height="30" />
      </div>
    </div>
    <div class="segment-bar-chart-title medium">
      <ng-container *ngIf="isView">
        <ng-container *ngIf="isPercentageEnabled">
          {{
            'translations.diagnostics.labels.competentYouScoredPercentage'
              | transloco
                : {
                    score: '75%',
                  }
          }}
        </ng-container>
        <ng-container *ngIf="!isPercentageEnabled">
          {{
            'translations.diagnostics.labels.competentYouScored'
              | transloco
                : {
                    score: '7',
                    from: '10',
                  }
          }}
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!isView">
        <ng-container *ngIf="isPercentageEnabled">
          {{
            'translations.diagnostics.labels.youScoredPercentage'
              | transloco
                : {
                    score: this.percentageValue,
                  }
          }}
        </ng-container>
        <ng-container *ngIf="!isPercentageEnabled">
          {{
            'translations.diagnostics.labels.youScored'
              | transloco
                : {
                    score: score,
                    from: maxScore,
                  }
          }}
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
